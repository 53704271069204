import React, { useEffect, useState } from "react";
import { Text, useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { FormikInput } from "../../components/Input/FormikInput";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAdditionalDetails,
  clearStepperIndex,
  setIsSubmitLoading,
  setLastId,
} from "./slice/postMaterialsSlice";
import { useNavigate } from "react-router-dom";
import { ConfirmMediaPopUp } from "../../components/MyBuisinessDealershipPopUp/postDealershipStepsPopUp/ConfirmMediaPopUp";
import { QrPopUp } from "../../components/ScanQrCodeModal/QrPopUp";
import { MultipleImageUpload } from "../../components/MultipleImageUpload/MultipleImageUpload";
import { UploadMediaPopUp } from "../../components/UploadMediaPopUp/UploadMediaPopUp";
import {
  closePhotoModal,
  openPhotoModal,
  openVideoModal,
  closeVideoModal,
  setPhotoTypeList,
  setVideoTypeList,
  setImageData,
  setVideoData,
} from "./slice/postMaterialsSlice";
import { FormikDropdown } from "../../components/Dropdown/FormikDropdown";
import { v4 as uuid } from "uuid";
import {
  convertFromBase64,
  convertToBase64,
} from "../../../utils/formatConverter/convertToBase64";
import {
  deleteMaterialImage,
  deleteMaterialVideo,
  getMaterialDataForUpdateStep2ById,
  getPhotoTypes,
  getVideotypes,
  postMaterialDetails2,
  postMaterialImages,
  postMaterialVideos,
  updateMaterialStep2,
} from "../../services/materials/materials";
import { useParams } from "react-router-dom";
import { registerNumbersAndDecimal } from "../../../utils/form/form";
import { getSessionStorageItems } from "../../helpers/sessionStorage/sessionStorage";
import { getAdditionalDetailsForUpdate } from "./thunks/getAdditionalDetailsForUpdate";
import { SinglePurposePopup } from "../../components/SinglePurposePopup/SinglePurposePopUp";
import editPopUpIcon from "../../../assets/webapp/pop-up/edit-popup.svg";
import { apiErrors } from "../../../constants/apiErrors";

export const MaterialAdditionalDetails = ({ mode, materialid }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const {
    isPhotoOpen,
    isVideoOpen,
    photoTypeList,
    videoTypeList,
    imageData,
    videoData,
    lastId,
    AdditionalDetails,
    Height,
    HeigthtUnit,
    LengthUnit,
    Radius,
    RadiusUnit,
    SLength,
    Weight,
    WeightUnit,
    Width,
    WidthUnit,
  } = useSelector((state) => state.postMaterials);

  const { materialTypeValue } = useParams();
  const MaterialId = getSessionStorageItems("LastId");
  const MaterialTypeValue = convertFromBase64(materialTypeValue);

  const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState();
  // Media Data
  const [imageList, setImageList] = useState();
  const [videoList, setVideoList] = useState();

  const [mediaEdited, setMediaEdited] = useState(false);
  const [isUploadingMedia, setIsMediaUploading] = useState(false);
  //units
  const weightUnitList = [
    { id: "KG", value: "kg" },
    { id: "Tonnes", value: "tonnes" },
  ];

  const dimentionList = [
    { id: "CM", value: "cm" },
    { id: "M", value: "m" },
  ];

  const initialValues = {
    weight: Weight ? Weight : "",
    weightUnit: WeightUnit ? WeightUnit : "",
    length: SLength ? SLength : "",
    lengthUnit: LengthUnit ? LengthUnit : "",
    width: Width ? Width : "",
    widthUnit: WidthUnit ? WidthUnit : "",
    height: Height ? Height : "",
    heightUnit: HeigthtUnit ? HeigthtUnit : "",
    radius: Radius ? Radius : "",
    radiusUnit: RadiusUnit ? RadiusUnit : "",
    additionalDetails: AdditionalDetails ? AdditionalDetails : "",
  };
  const validationSchema = Yup.object({
    weight: Yup.number()
      .typeError("Must be a number")
      .min(0, "Must be greater than or equal to 0")
      .max(9999999.99, "Must be less than or equal to 9999999.99"),
    length: Yup.number()
      .typeError("Must be a number")
      .min(0, "Must be greater than or equal to 0")
      .max(9999999.99, "Must be less than or equal to 9999999.99"),
    width: Yup.number()
      .typeError("Must be a number")
      .min(0, "Must be greater than or equal to 0")
      .max(9999999.99, "Must be less than or equal to 9999999.99"),
    height: Yup.number()
      .typeError("Must be a number")
      .min(0, "Must be greater than or equal to 0")
      .max(9999999.99, "Must be less than or equal to 9999999.99"),
    radius: Yup.number()
      .typeError("Must be a number")
      .min(0, "Must be greater than or equal to 0")
      .max(9999999.99, "Must be less than or equal to 9999999.99"),
  });

  useEffect(() => {
    getMaterialByID();
  }, [materialid]);

  const getMaterialByID = async () => {
    if (materialid) {
      dispatch(getAdditionalDetailsForUpdate(convertFromBase64(materialid)));
    } else {
      dispatch(clearAdditionalDetails());
    }
  };

  useEffect(() => {
    getAllMaterialsDetails();
  }, []);

  useEffect(() => {
    getVideoTypeList();
  }, []);

useEffect(() => {
    getPhotoTypeList();
  }, []);

  useEffect(() => {
    if (photoTypeList?.length > 0) {
      if (imageData) {
        const imageDetails = imageData.map((element) => ({
          id: element.ImageTypeId,
          value:
            photoTypeList.find((item) => item.id === element.ImageTypeId)
              ?.value || "",
          url: `${process.env.REACT_APP_CDN_PVT_URL}${element.ImageFileRef}`,
          imageId: element.ImageId,
        }));
        setImageList(imageDetails);
      } else {
        setImageList(null);
      }
    }
  }, [photoTypeList,imageData]);

  useEffect(() => {
    if (videoTypeList?.length > 0) {
      if (videoData) {
        const videoDetails = videoData.map((element) => ({
          id: element.VideoTypeId,
          value:
            videoTypeList.find((item) => item.id === element.VideoTypeId)
              ?.value || "",
          url: `${process.env.REACT_APP_CDN_PVT_URL}${element.VideoFileRef}`,
          videoId: element.VideoId,
        }));

        setVideoList(videoDetails);
      } else {
        setVideoList(null);
      }
    }
  }, [videoTypeList, videoData]);

  const getAllMaterialsDetails = async () => {
    const data = await getMaterialDataForUpdateStep2ById(MaterialId);
    if (data?.Status === 200) {
      dispatch(
        setImageData(JSON.parse(data?.Data?.MaterialData[0]?.ImageList))
      );
      dispatch(
        setVideoData(JSON.parse(data?.Data?.MaterialData[0]?.VideoList))
      );
    } else {
      dispatch(setImageData(null));
      dispatch(setVideoData(null));
    }
  };

  //submit
  const handleSubmit = async (value) => {
    if (!imageData) {
      toast({
        title: "",
        description: "Please select a photo",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    const valueUnitPairs = [
      { value: value.weight, unit: value.weightUnit, name: "Weight" },
      { value: value.length, unit: value.lengthUnit, name: "Length" },
      { value: value.width, unit: value.widthUnit, name: "Width" },
      { value: value.height, unit: value.heightUnit, name: "Height" },
      { value: value.radius, unit: value.radiusUnit, name: "Radius" },
    ];

    const missingPairs = valueUnitPairs.filter(
      (pair) => (pair.value && !pair.unit) || (!pair.value && pair.unit)
    );

    if (missingPairs.length > 0) {
      const errorMessage = missingPairs
        .map(
          (pair) => `${pair.name} ${!pair.unit ? "unit" : "value"} is missing`
        )
        .join(", ");
      toast({
        title: "",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      if (materialid) {
      
        const isFormEdited = JSON.stringify(initialValues) !== JSON.stringify(value)

        if (!isFormEdited && !mediaEdited) {
          setIsConfirmationPopupOpen(true);
        } else {
          const payload = {
            MaterialDetails: {
              MaterialId: MaterialId,
              Weight: parseInt(value.weight),
              WeightUnit: value.weightUnit,
              SLength: parseInt(value.length),
              LengthUnit: value.lengthUnit,
              Width: parseInt(value.width),
              WidthUnit: value.widthUnit,
              Height: parseInt(value.height),
              HeigthtUnit: value.heightUnit,
              Radius: parseInt(value.radius),
              RadiusUnit: value.radiusUnit,
              AdditionalDetails: value.additionalDetails,
            },
          };

          dispatch(setIsSubmitLoading(true));

          const data = await updateMaterialStep2(JSON.stringify(payload));
          if (data.Status === 204) {
            toast({
              title: "",
              description: `Material updated Successfully`,
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });

            dispatch(clearStepperIndex());
            dispatch(setImageData(null));
            dispatch(setVideoData(null));
            dispatch(setLastId(null));
            navigate(`/materials/?type=${convertToBase64(MaterialTypeValue)}`);
          } else {
            toast({
              title: "Error",
              description: "Error while updating",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
          }
          dispatch(setIsSubmitLoading(false));
        }
      } else {
        const payload = {
          MaterialId: MaterialId,
          Weight: parseInt(value.weight) || null,
          WeightUnit: value.weightUnit || null,
          SLength: parseInt(value.length) || null,
          LengthUnit: value.lengthUnit || null,
          Width: parseInt(value.width) || null,
          WidthUnit: value.widthUnit || null,
          Height: parseInt(value.height) || null,
          HeigthtUnit: value.heightUnit || null,
          Radius: parseInt(value.radius) || null,
          RadiusUnit: value.radiusUnit || null,
          AdditionalDetails: value.additionalDetails || null,
        };

        dispatch(setIsSubmitLoading(true));

        const data = await postMaterialDetails2(payload);

        if (data.Status === 201) {
          toast({
            title: "",
            description: `Material Added Successfully`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });

          dispatch(clearStepperIndex());
          dispatch(setImageData(null));
          dispatch(setVideoData(null));
          dispatch(setLastId(null));
          navigate(`/materials/?type=${convertToBase64(MaterialTypeValue)}`);
        } else {
          toast({
            title: "Error",
            description: "Error",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
        dispatch(setIsSubmitLoading(false));
      }
    }
  };

  // Typelists
  const getPhotoTypeList = async () => {
    const data = await getPhotoTypes();
    if (data?.Status === 200) {
      const photoList = data?.Data?.PhotoTypeList.map((element) => ({
        id: element.PhotoTypeId,
        value: element.PhotoTypeValue,
      }));
      dispatch(setPhotoTypeList(photoList));
      return photoList;
    } else {
      dispatch(setPhotoTypeList(null));
      return null;
    }
  };

  const getVideoTypeList = async () => {
    const data = await getVideotypes();
    if (data?.Status === 200 && Object.keys(data?.Data).length > 0) {
      const videoList = data?.Data?.VideoTypeList.map((element) => ({
        id: element.VideoTypeId,
        value: element.VideoTypeValue,
      }));

      dispatch(setVideoTypeList(videoList));
      return videoList;
    } else {
      dispatch(setPhotoTypeList(null));
      return null;
    }
  };

  //upload Media
  const handleUploadImage = async (values) => {
    setIsMediaUploading(true);
    const id = values?.selectValue;
    const idToSent = await photoTypeList.find((item) => item?.value === id);

    let payload = {
      MaterialId: parseInt(MaterialId),
      ImageTypeId: idToSent?.id,
      ImageFile: values.document,
    };

    try {
      const data = await postMaterialImages(payload);
      if (data?.Status === 201) {
        toast({
          title: "Successfully Uploaded",
          position: "top-right",
          status: "success",
        });
        setMediaEdited(true);
        getAllMaterialsDetails();
        dispatch(closePhotoModal());
      } else {
        toast({
          title: "Error In Uploading",
          position: "top-right",
          status: "error",
        });
      }
    } catch (error) {
      if (error?.request?.status === apiErrors.INTERNAL_SERVER_ERROR) {
        toast({
          title: "Some error has occurred. Please try again later",
          position: "top-right",
          status: "error",
        });
      }
    } finally {
      setIsMediaUploading(false);
    }
  };

  const handleUploadVideo = async (values) => {
    setIsMediaUploading(true);
    const id = values?.selectValue;
    const idToSent = await videoTypeList.find((item) => item?.value === id);

    let payload = {
      MaterialId: parseInt(MaterialId),
      VideoTypeId: idToSent?.id,
      VideoFile: values.document,
    };

    try {
      const data = await postMaterialVideos(payload);
      if (data?.Status === 201) {
        toast({
          title: "Successfully Uploaded",
          position: "top-right",
          status: "success",
        });
        setMediaEdited(true);
        getAllMaterialsDetails();
        dispatch(closeVideoModal());
      } else {
        toast({
          title: "Error In Uploading",
          position: "top-right",
          status: "error",
        });
      }
    } catch (error) {
      if (error?.request?.status === apiErrors.INTERNAL_SERVER_ERROR) {
        toast({
          title: "Some error has occurred. Please try again later",
          position: "top-right",
          status: "error",
        });
      }
    } finally {
      setIsMediaUploading(false);
    }
  };

  //deleting Media
  const handleImageDelete = async (value) => {
    const data = await deleteMaterialImage(MaterialId, value);
    getAllMaterialsDetails();
    if (data?.Status === 204) {
      toast({
        title: "Successfully Deleted",
        position: "top-right",
        status: "success",
      });
      setMediaEdited(true);
      getAllMaterialsDetails();
    } else {
      toast({
        title: "Failed to delete",
        position: "top-right",
        status: "error",
      });
    }
  };

  const handleVideoDelete = async (value) => {
    const data = await deleteMaterialVideo(MaterialId, value);
    if (data?.Status === 204) {
      toast({
        title: "Successfully Deleted",
        position: "top-right",
        status: "success",
      });
      setMediaEdited(true);
      getAllMaterialsDetails();
    } else {
      toast({
        title: "Failed to delete",
        position: "top-right",
        status: "error",
      });
    }
  };

  return (
    <>
      <ConfirmMediaPopUp />
      <QrPopUp />
      <SinglePurposePopup
        isOpen={isConfirmationPopupOpen}
        iconUrl={editPopUpIcon}
        bodyText="No Fields have been edited.Do you want to proceed?"
        continueAction={() => {
          dispatch(clearStepperIndex());
          dispatch(setImageData(null));
          dispatch(setVideoData(null));
          dispatch(setLastId(null));
          navigate(`/materials/?type=${convertToBase64(MaterialTypeValue)}`);
          setIsConfirmationPopupOpen(false);
        }}
        cancelAction={() => {
          setIsConfirmationPopupOpen(false);
        }}
      />

      <Formik
        initialValues={{...initialValues}}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue }) => (
          <Form
            style={{ display: "flex", gap: "2em", flexWrap: "wrap" }}
            id="post-material-step-two"
          >
            {/* Image Field */}
            <div className="media-display-container">
              <UploadMediaPopUp
                modalTitle="Upload Photo"
                documentType="Photo"
                isModalOpen={isPhotoOpen}
                mediaComponentTitle="Upload a photo and select a name"
                mediaExtentionsAllowed={["jpg", "jpeg", "img"]}
                maxFileSizeinMb={5}
                handleSubmit={(values) => {
                  handleUploadImage(values);
                }}
                onCloseButtonClick={() => {
                  dispatch(closePhotoModal());
                }}
                dropdownValues={photoTypeList}
                valuesToDeactivate={imageList}
                isLoading={isUploadingMedia}
              />
              <MultipleImageUpload
                title="Store Photos"
                isRequired={true}
                maxWidth="100%"
                onAddButtonClick={() => {
                  dispatch(openPhotoModal());
                }}
                mediaArray={imageList}
                deleteAction={async (value) => {
                  await handleImageDelete(value);
                }}
                isDisabled={
                  Array.isArray(photoTypeList) &&
                  Array.isArray(imageList) &&
                  imageList.length === photoTypeList.length
                }
              />
            </div>

            {/* Video Field */}
            <div className="media-display-container">
              <UploadMediaPopUp
                modalTitle="Upload Video"
                documentType="Video"
                mediaComponentTitle="Upload a video and select a name"
                mediaExtentionsAllowed={["mp4", "mkv", "avi"]}
                maxFileSizeinMb={10}
                isModalOpen={isVideoOpen}
                onCloseButtonClick={() => {
                  dispatch(closeVideoModal());
                }}
                handleSubmit={(values) => {
                  handleUploadVideo(values);
                }}
                dropdownValues={videoTypeList}
                valuesToDeactivate={videoList}
                isLoading={isUploadingMedia}
              />
              <MultipleImageUpload
                title="Store Videos"
                maxWidth="100%"
                type="video"
                onAddButtonClick={() => {
                  dispatch(openVideoModal());
                }}
                mediaArray={videoList}
                deleteAction={async (value) => {
                  await handleVideoDelete(value);
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                columnGap: "2em",
                flexDirection: "column",
              }}
            >
              <Text fontSize={"20px"} fontWeight={"600"} color={"#141619"}>
                Weight
              </Text>
              <div style={{ display: "flex", width: "100%", columnGap: "2em" }}>
                <FormikInput
                  label=""
                  name="weight"
                  value={values.weight}
                  placeholder="Enter Weight"
                  maxWidth="364px"
                  onKeyDown={registerNumbersAndDecimal}
                  onChange={(e) => {
                    setFieldValue("weight", e.target.value);
                  }}
                  maxLength={5}
                />

                <FormikDropdown
                  name="weightUnit"
                  label=""
                  isRequired={false}
                  maxWidth="160px"
                  value={values.weightUnit}
                  defaultValue="kg"
                  onChange={(e) => {
                    setFieldValue("weightUnit", e.target.value);
                  }}
                >
                  {weightUnitList &&
                    weightUnitList.map((unit) => (
                      <option key={uuid()} value={unit.id}>
                        {unit.value}
                      </option>
                    ))}
                </FormikDropdown>
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <Text fontSize={"20px"} fontWeight={"600"} color={"#141619"}>
                Dimensions
              </Text>
              <div>
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", columnGap: "2em" }}>
                    <FormikInput
                      label={""}
                      name="length"
                      value={values.length}
                      placeholder="Enter Lenght"
                      maxWidth="450px"
                      maxLength={5}
                      onKeyDown={registerNumbersAndDecimal}
                      onChange={(e) => {
                        setFieldValue("length", e.target.value);
                      }}
                    />
                    <FormikDropdown
                      name="lengthUnit"
                      label=""
                      isRequired={false}
                      maxWidth="160px"
                      value={values.lengthUnit}
                      onChange={(e) => {
                        setFieldValue("lengthUnit", e.target.value);
                      }}
                    >
                      {dimentionList &&
                        dimentionList.map((unit) => (
                          <option key={uuid()} value={unit.id}>
                            {unit.value}
                          </option>
                        ))}
                    </FormikDropdown>

                    <FormikInput
                      label={""}
                      name="width"
                      value={values.width}
                      placeholder="Enter Width"
                      maxWidth="450px"
                      maxLength={5}
                      onKeyDown={registerNumbersAndDecimal}
                      onChange={(e) => {
                        setFieldValue("width", e.target.value);
                      }}
                    />
                    <FormikDropdown
                      name="widthUnit"
                      label=""
                      isRequired={false}
                      maxWidth="160px"
                      value={values.widthUnit}
                      onChange={(e) => {
                        setFieldValue("widthUnit", e.target.value);
                      }}
                    >
                      {dimentionList &&
                        dimentionList.map((unit) => (
                          <option key={uuid()} value={unit.id}>
                            {unit.value}
                          </option>
                        ))}
                    </FormikDropdown>
                  </div>

                  <div style={{ display: "flex", columnGap: "2em" }}>
                    <FormikInput
                      label={""}
                      name="height"
                      placeholder="Enter Height"
                      maxWidth="450px"
                      value={values.height}
                      maxLength={5}
                      onKeyDown={registerNumbersAndDecimal}
                      onChange={(e) => {
                        setFieldValue("height", e.target.value);
                      }}
                    />
                    <FormikDropdown
                      name="heightUnit"
                      label=""
                      isRequired={false}
                      maxWidth="160px"
                      value={values.heightUnit}
                      onChange={(e) => {
                        setFieldValue("heightUnit", e.target.value);
                      }}
                    >
                      {dimentionList &&
                        dimentionList.map((unit) => (
                          <option key={uuid()} value={unit.id}>
                            {unit.value}
                          </option>
                        ))}
                    </FormikDropdown>

                    <FormikInput
                      label={""}
                      name="radius"
                      value={values.radius}
                      placeholder="Enter Radius"
                      onKeyDown={registerNumbersAndDecimal}
                      maxWidth="450px"
                      maxLength={5}
                      onChange={(e) => {
                        setFieldValue("radius", e.target.value);
                      }}
                    />
                    <FormikDropdown
                      name="radiusUnit"
                      label=""
                      isRequired={false}
                      maxWidth="160px"
                      value={values.radiusUnit}
                      onChange={(e) => {
                        setFieldValue("radiusUnit", e.target.value);
                      }}
                    >
                      {dimentionList &&
                        dimentionList.map((unit) => (
                          <option key={uuid()} value={unit.id}>
                            {unit.value}
                          </option>
                        ))}
                    </FormikDropdown>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                columnGap: "2em",
                flexDirection: "column",
              }}
            >
              <Text fontSize={"20px"} fontWeight={"600"} color={"#141619"}>
                Additional Details
              </Text>
              <FormikInput
                textArea={true}
                name="additionalDetails"
                value={values.additionalDetails}
                placeholder="E.g. Part number, Material, Quantity, etc"
                maxWidth="100%"
                maxLength={100}
                onChange={(e) => {
                  setFieldValue("additionalDetails", e.target.value);
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
