import "./SectionFour.css"

import img1 from "../../../assets/carousel1_images/Container.png";
import img2 from "../../../assets/carousel1_images/Container (2).png";
import img3 from "../../../assets/carousel1_images/Container (3).png";
import img4 from "../../../assets/carousel2_images/Container (5).png";
import img5 from "../../../assets/carousel2_images/Container (6).png";
// import img6 from "../../../assets/carousel2_images/Container (7).png"; alt=""
import "./Aboutmaincss.css"
import { ImageSlider } from "../../components/ImageSlider/ImageSlider";
import { Text } from "@chakra-ui/react";

function Sectionfour() {
    return (
        <div className="sectn">
            
                <Text 
                fontSize={["1.8em","1.8em","1.8em","2.8em","2.8em","2.8em"]}
                className="who-we-are-div">
                    Who We Are
                </Text>
                <Text
                fontSize={["0.8em","0.8em","0.8em","1em","1em","1em"]}
                maxW={"350px"}
                minW={"220px"}
                margin={"auto"}
                style={{textAlign: "center", color: "var(--light-10)", marginBottom:"1em"}}>
                An ordinary team making an extraordinary effort to shape a 129 billion-dollar global industry.
                </Text>
                <ImageSlider slideHeight="320px" height="200px" gap="1.5em" imageArray={[`${process.env.REACT_APP_CDN_STATIC_URL}Website/About/ImageSlider/UpperSlider/upperSlider-1.png`,`${process.env.REACT_APP_CDN_STATIC_URL}Website/About/ImageSlider/UpperSlider/upperSlider-2.png`,`${process.env.REACT_APP_CDN_STATIC_URL}Website/About/ImageSlider/UpperSlider/upperSlider-3.png`,`${process.env.REACT_APP_CDN_STATIC_URL}Website/About/ImageSlider/UpperSlider/upperSlider-4.png`, `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/ImageSlider/UpperSlider/upperSlider-5.png`]}/>
                <ImageSlider slideHeight="320px" height="200px" gap="1.5em" imageArray={[`${process.env.REACT_APP_CDN_STATIC_URL}Website/About/ImageSlider/LowerSlider/lowerSlider-1.png`, `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/ImageSlider/LowerSlider/lowerSlider-2.png`, `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/ImageSlider/LowerSlider/lowerSlider-3.png`, `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/ImageSlider/LowerSlider/lowerSlider-4.png`, `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/ImageSlider/LowerSlider/lowerSlider-5.jpg`]}/>

        </div>
                
           
    );
}

export default Sectionfour;