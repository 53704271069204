export const STORAGE_CONSTANTS = {

    ALL_COUNTRIES: "ALL_COUNTRIES",
    ALL_LANGUAGES: "ALL_LANGUAGES",
    ALL_BUSINESS_TYPES: "ALL_BUSINESS_TYPES",
    ALL_USER_TYPES: "ALL_USER_TYPES",
    ALL_E9_ROLES: "ALL_E9_ROLES",
    APP_DEFAULT_LANGUAGE : "APP_DEFAULT_LANGUAGE",
    
    SELECTED_COUNTRY: "SELECTED_COUNTRY",
    SELECTED_LANGUAGE: "SELECTED_LANGUAGE",
    SELECTED_USER_TYPE: "SELECTED_USER_TYPE",
    SELECTED_BUSINESS_TYPE: "SELECTED_BUSINESS_TYPE",
    SELECTED_E9_ROLES: "SELECTED_E9_ROLES",
    HAS_TAX_VERIFICATION_DOCUMENT: "HAS_TAX_VERIFICATION_DOCUMENT",
    TAX_VERIFICATION_DOCUMENT: "TAX_VERIFICATION_DOCUMENT",
    
    FIRST_NAME: "FIRST_NAME",
    LAST_NAME: "LAST_NAME",
    EMAIL: "EMAIL",
    MOBILE_NUMBER: "MOBILE_NUMBER",
    SUBJECT: "SUBJECT",
    DESCRIPTION: "DESCRIPTION",
    ENTERPRISE_NAME: "ENTERPRISE_NAME",

    ACCOUNT_LIST: "ACCOUNT_LIST",
    SELECTED_ACCOUNT: "SELECTED_ACCOUNT",



    OTP: "OTP",
    NEW_PASSWORD: "NEW_PASSWORD",
    
    TOKEN: "TOKEN",
    IS_AUTH: "IS_AUTH",
    EXP_TIME: "EXP_TIME",
    ISSUE_AT_TIME: "ISSUE_AT_TIME",
    ACCOUNT_DETAILS: "ACCOUNT_DETAILS",
    USER_SELECTED_BUSINESS_TYPE : "USER_SELECTED_BUSINESS_TYPE",
    USER_SELECTED_USER_TYPE: "USER_SELECTED_USER_TYPE",
    ROUTE_ARRAY : "ROUTE_ARRAY",
    ROUTE_INDEX: "ROUTE_INDEX",
    DOCUMENT_FLOW: "DOCUMENT_FLOW",
    DOCUMENT_PAGE_INDEX: "DOCUMENT_PAGE_INDEX",
    ASSET_ID: "ASSET_ID",

    COMPANY_NAME: "COMPANY_NAME",
    USER_DETAILS: "USER_DETAILS",
    CURRENCY_DETAILS: "CURRENCY_DETAILS",

    OPERATOR_PARTNER_MARKETPLACE_IMAGE_URL: "OPERATOR_PARTNER_MARKETPLACE_IMAGE_URL",
    SELECTED_PREMIUM_TYPE : "SELECTED_PREMIUM_TYPE_ID",
    POSTED_PREMIUM_TYPE : "POSTED_PREMIUM_TYPE_ID",
    PREMIUM_ORDER_ID : "PREMIUM_ORDER_ID",
    PREMIUM_TRANSACTION_DETAILS_ID : "PREMIUM_TRANSACTION_DETAIL_ID"
}