import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import { localimageDetails as staticImageDetails } from "../../../../../../../staticData/staticData";
import {getPhotoType} from "../../../../../../../ApiEquip9/CommonApi";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { deleteMaterialImage, getMaterialAdditionalDetails } from "../../../../../../services/materials/materials";
export const EditShowPhotos = ({ photoUploaded, setPhotoUploaded }) => {
  const userData = useSelector((state) => state.user);
  const [localimageDetails, setLocalImageDetails] =
    useState(staticImageDetails);
  const [editPhoto, seteditPhoto] = useState(null);

  useEffect(() => {
    const fetchMaterialStep2 = async () => {
      try {
        const response = await getMaterialAdditionalDetails(
          userData.token,
          parseInt(localStorage.getItem("EDIT_MATERIAL_ID"))
        );
        // console.log(response, "responseMaterila");
        seteditPhoto(response?.data?.Data?.MaterialData[0]?.ImageList);
      } catch (err) {
        throw new Error(err);
      }
    };
    fetchMaterialStep2();
  }, []);

  const convertObjectintoPhoto = JSON.parse(editPhoto);
  // console.log("convertObjectintoPhoto",convertObjectintoPhoto)
  const { data: getphotoType } = useQuery("getphotoType", () =>
  getPhotoType(userData.token)
);

const PhotoId=getPhotoType?.data?.Data?.PhotoTypeList;
const PhotoName=getPhotoType?.data?.Data?.PhotoTypeList?.PhotoTypeValue;

  const handleDelete = (id) => {
    const updatedPhotos = localimageDetails.map((item) => {
      if (item.id === id) {
        return { ...item, src: "" };
      }
      return item;
    });
    localStorage.removeItem(`photoType_${id}`);
    setLocalImageDetails(updatedPhotos);
  };

  useEffect(() => {
    const updatedPhotos = localimageDetails.map((item) => {
      const storedPhoto = localStorage.getItem(`photoType_${item.id}`);
      return { ...item, src: storedPhoto || "" };
    });
    setLocalImageDetails(updatedPhotos);
  }, []);

  useEffect(() => {
    if (photoUploaded) {
      const updatedPhotos = localimageDetails.map((item) => {
        const storedPhoto = localStorage.getItem(`photoType_${item.id}`);
        return { ...item, src: storedPhoto || "" };
      });
      setLocalImageDetails(updatedPhotos);
      setPhotoUploaded(false);
    }
  }, [photoUploaded, setPhotoUploaded, localimageDetails]);


  const nameMapping = {
    1: "Front Photo",
    2: "Side Photo",
    3: "Back Photo",
}

const materialId= parseInt(localStorage.getItem("EDIT_MATERIAL_ID"));
const  convertMaterialId=btoa(materialId);

const handleDeleteApi=async(imageTypeId)=>{
  const data= await deleteMaterialImage(materialId,imageTypeId)
  window.location.reload();
}
  return (
    <div
      style={{
        marginTop: "22px",
        display: "flex",
        flexDirection: "row",
        gap: "32px",
      }}
    >
      {convertObjectintoPhoto?.length > 0 ? (
        <>
          {convertObjectintoPhoto?.map((item, index) => 
          {
 
              return(
                <div className="store-preview-image" key={index}>
                  <div className="image-tag">
                    <p className="front-text">{nameMapping[item?.ImageTypeId]}</p>
                  </div>
                  <img
                    style={{ border: "1px solid rgba(74, 74, 74, 0.41)" }}
                    alt={item.alt}
                    className="image-preview"
                    src={`${process.env.REACT_APP_CDN_PVT_URL}${item.ImageFileRef}`}
                  />
                  <div
                    className="image-tag-delete"
                    onClick={() => handleDeleteApi(item?.ImageTypeId)}
                  >
                    <ReactSVG
                      style={{
                        cursor: "pointer",
                        marginLeft: "75px",
                        marginTop: "5px",
                      }}
                      src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/garbage.svg`}
                    />
                  </div>
                </div>
              )
          }
            )}
        </>
      ) : (
        <>

{localimageDetails?.map((item, index) => (
        <div className="store-preview-image" key={index}>
          <div className="image-tag">
            <p className="front-text">{item.name}</p>
          </div>
          <img
            style={{ border: "1px solid rgba(74, 74, 74, 0.41)" }}
            alt={item.alt}
            className="image-preview"
            src={
              item.src ||
              `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/placeHolderimage.png`
            }
          />
          <div
            className="image-tag-delete"
            onClick={() => handleDelete(item?.id)}
          >
            <ReactSVG
              style={{
                cursor: "pointer",
                marginLeft: "75px",
                marginTop: "5px",
              }}
              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/garbage.svg`}
            />
          </div>
        </div>
      ))}
        </>
      )}
  
    </div>
  );
};
