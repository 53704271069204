import { API_CONSTANTS } from "../../../constants/apiConstants";
import {fetchHandler} from "../../../index";
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData";

export const getQuizzes = async (limit = 10, offset = 0) => {
    try {
      const { data } = await fetchHandler(
        `${API_CONSTANTS.GET_QUIZ_LIST}/${limit}/${offset}`,
        {},
        {},
        "",
        API_CONSTANTS.HTTP_GET,
        { isAuthRequired: true }
      );
  
      if (data.Status === 200) {
        return data;
      }
      return null;
    } catch (error) {
      throw error;
    }
  };

  export const getQuizQuestionsByQuizId = async (quizId) => {
    try {
      const { data } = await fetchHandler(
        `${API_CONSTANTS.GET_QUIZ_QUESTIONS_BY_QUIZ_ID}/${quizId}`,
        {},
        {},
        "",
        API_CONSTANTS.HTTP_GET,
        { isAuthRequired: true }
      );
  
      if (data.Status === 200) {
        return data;
      }
      return null;
    } catch (error) {
      throw error;
    }
  };

  export const getQuizAnswersByQuizQuestId = async (quizQuestId) => {
    try {
      const { data } = await fetchHandler(
        `${API_CONSTANTS.GET_QUIZ_ANSWERS_BY_QUIZ_QUEST_ID}/${quizQuestId}`,
        {},
        {},
        "",
        API_CONSTANTS.HTTP_GET,
        { isAuthRequired: true }
      );
  
      if (data.Status === 200) {
        return data;
      }
      return null;
    } catch (error) {
      throw error;
    }
  };

  export const updateUserQuizHistoryQuizEndTime = async (payload) => {
    try {
      const { data } = await fetchHandler(
        `${API_CONSTANTS.UPDATE_USER_QUIZ_HISTORY_QUIZ_END_TIME}`,
        {},
        {},
        JSON.stringify(payload),
        API_CONSTANTS.HTTP_PUT,
        { isAuthRequired: true }
      );
      return data;
     
    } catch (error) {
      throw error;
    }
  };

  export const createUserQuizResponseDetails = async (payload) => {
    try {
      const { data } = await fetchHandler(
        `${API_CONSTANTS.CREATE_USER_QUIZ_RESPONSE_DETAILS}`,
        {},
        {},
        payload,
        API_CONSTANTS.HTT_POST,
        { isAuthRequired: true }
      );
  
      if (data.Status === 201) {
        return data;
      }
      return null;
    } catch (error) {
      throw error;
    }
  };

  export const getQuizTermsAndConditions = async (countryId = 99, businessTypeId = 4) => {
    try {
      const { data } = await fetchHandler(
        `${API_CONSTANTS.GET_TERMS_AND_CONDITIONS_CMS_PAGE_DATA}/${countryId}/${businessTypeId}`,
        {},
        {},
        "",
        API_CONSTANTS.HTTP_GET,
        { isAuthRequired: true }
      );
  
      if (data.Status === true) {
        return data;
      }
      return null;
    } catch (error) {
      throw error;
    }
  };

  export const getQuizWinnerList = async (quizId) => {
    try {
      const { data } = await fetchHandler(
        `${API_CONSTANTS.GET_QUIZ_WINNERS_LIST_BY_QUIZ_ID}/${quizId}`,
        {},
        {},
        "",
        API_CONSTANTS.HTTP_GET,
        { isAuthRequired: true }
      );
  
      if (data.Status === 200) {
        return data;
      }
      return null;
    } catch (error) {
      throw error;
    }
  };

  export const getMyPlayedQuizzes = async (limit = 10, offset = 0) => {
    try {
      const { data } = await fetchHandler(
        `${API_CONSTANTS.GET_USER_MY_QUIZZES_LIST}/${limit}/${offset}`,
        {},
        {},
        "",
        API_CONSTANTS.HTTP_GET,
        { isAuthRequired: true }
      );
  
      if (data.Status === 200) {
        return data;
      }
      return null;
    } catch (error) {
      throw error;
    }
  };


  export const createUserQuizHistoryDetails = async (payload) => {
    try {
      const { data } = await fetchHandler(
        `${API_CONSTANTS.CREATE_USER_QUIZ_HISTORY_DETAILS}`,
        {},
        {},
        convertToFormData(payload),
        API_CONSTANTS.HTT_POST,
        { isAuthRequired: true }
      );
  
      if (data.Status === 201) {
        return data;
      }
      return null;
    } catch (error) {
      throw error;
    }
  };