import { ArrowBackIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

export const CtaModal = ({
  header,
  body,
  primaryButtonText = "Primary",
  secondayButtonText = "",
  onPrimaryButtonClick = () => {},
  onSecondayButtonClick = () => {},
  onCloseIconClick = () => {},
  isClosable = true,
  isOpen=false
}) => {
  return (
    <Modal isCentered isOpen={isOpen}>
      <Box>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader position="relative" height="100%" width="100%">
            {header}
            {isClosable && (
              <Button
                onClick={onCloseIconClick}
                position="absolute"
                top="-1.3rem"
                right="0.7rem"
                borderRadius="50%"
                bgColor="#FFF"
                color="#2c2c2c"
                height="42px"
                width="42px"
                boxShadow="0px 4px 10px 0px #00000030"
              >
                <CloseIcon height="9px" width="9px" />
              </Button>
            )}
          </ModalHeader>
          <ModalBody>{body}</ModalBody>
          <ModalFooter
            justifyContent={secondayButtonText ? "space-between" : "center"}
          >
            {secondayButtonText && (
               <Button variant="ghost" gap={"15px"}>
                <Box>
                 <ArrowBackIcon />
               </Box>
               <Text>{secondayButtonText}</Text>
             </Button>
            )}
            <Button variant="gradient" minW="45px" onClick={onPrimaryButtonClick}>
              {primaryButtonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Box>
    </Modal>
  );
};
