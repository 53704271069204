import React from "react";
import { Flex, background } from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import "../../components/MobileHeader/MobileHeader.css";
import Hemberger from "../../../assets/logo/hemburger.svg";
import {
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import Logo from "../../../assets/logo/equip9_logo_dark.png";
import { DrawerOptions } from "../../../staticData/staticData";
export const MobileHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  return (
    <>
      <Flex>
        <div className="backGround">
          <div className="drawer-icon">
            <div className="image">
              <ReactSVG
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Website/Home/MobileIcon/LogoEquip9.svg`}
              />
            </div>
            <div className="hermburger">
              <ReactSVG onClick={onOpen} src={Hemberger} />
            </div>
          </div>
        </div>
      </Flex>
      <Flex>
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
          size="xs"
        >
          <DrawerOverlay />
          <DrawerContent bg="#333333 ">
            <div className="exitButton" onClick={onClose}>
              <ReactSVG
                
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Website/Home/DrawerIcon/DrawerExit.svg`}
              />
            </div>
            <div className="sepratorDrawer"></div>
            <DrawerBody>
              <div className="DrawerOptions">
                {DrawerOptions?.length > 0 ? DrawerOptions.map((item, list) => (
                  <>
                    <Link to={item.Route}>
                      <div className="drawerOption">
                        <ReactSVG src={item.icon} />
                        <p className="DrawerHeroHeading">{item.OptionName}</p>
                      </div>
                    </Link>
                  </>
                )) : <p>No Data</p>}
              </div>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </>
  );
};
