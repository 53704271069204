
export const initialState = {

    acceptModal : false,
    uemSentCardData : null,
    remSentCardData : null,

    remReceivedCardData : null,
    uemReceivedCardData : null,

    dealershipSentCardData : null,
    dealershipReceivedCardData : null,

    materialSentCardData:[],
    materialReceivedCardData:[],
    materialTypesData:[],
    isMaterialData:false,
    materialData:null,
    initialLoading:false,
    
    AssetImageBaseReference : "",
    transactionModeDetails : null ,
    receivedCount : "",
    getContactRequestReceivedList : null,
    E9MarketPlaceId : "",
    ModelName : "",
    EquipmentTypeValue : "",
    assetId :"",
    OEMName : "",
    ImageFileReference : "",
    equipmentData : null,

    // Accep Handling
    getRentalDurationData : null,
    getRequestTypeData : null,
    contactMessage : "",
    equipmentAvaibility : null,


}