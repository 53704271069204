import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMyPlayedQuizzes } from "../../../services/quiz/quiz";

export const myQuizInitialListThunk = createAsyncThunk(
  "myQuiz/getInitialCards",
  async (_, {getState}) => {
    const cardCount = getState().quiz.cardCount
    try {
      const data = await getMyPlayedQuizzes(cardCount,0);
      return data;
    } catch (error) {
      throw error;
    }
  }
);
