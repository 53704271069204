import "./MarketplaceHeader.css";
import { v4 as uuid } from "uuid";

export const MarketplaceHeader = ({
  heading = "",
  filterButtonSelected = false,
  filterButtonClickAction = () => {},
  filterImageButtonClickAction=()=>{},
  filterImagesArray=[],
  filterButtonRequired = true,
  filterImagesReference,
  selectedImageFilterButton
}) => {
  return (
    <div className="marketplace-header__position-wrapper">
      <div className="marketplace-header__container">
        <h1 className="marketplace-header__heading">{heading}</h1>
        <button
          className={`marketplace-header__filter-button ${
            filterButtonSelected
              ? "marketplace-header__filter-button--selected"
              : ""
          }`}
          style={{display : !filterButtonRequired ? "none" : ""}}
          onClick={filterButtonClickAction}
        >
          {filterButtonSelected ? "Clear Filter(s)" : "Filter"}
        </button>
      </div>

      {/* filterByIcon */}
      <div className="marketplace-header__filter-img-btn-container">
      {filterImagesArray?.length > 0 ? (
          filterImagesArray.map((filterImage) => (
            <button
              key={uuid()}
              onClick={() => filterImageButtonClickAction(filterImage.EquipmentTypeValue)}
              className="marketplace-header__filter-img-btn"
              
            >
              <img
                src={`${process.env.REACT_APP_CDN_PVT_URL}${filterImagesReference}${filterImage.EquipmentIcon}`}
                alt="filter icon"
                className={selectedImageFilterButton===filterImage.EquipmentTypeValue?'marketplace-header__selected-img':''}
              />
              <p style={{width:"100%",fontSize:"12px"}}>
              {filterImage.EquipmentTypeValue}
              </p>
            </button>
          ))
        ) : (
          <></>
        )}
        </div>

    </div>
  );
};
