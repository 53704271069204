import * as Yup from "yup";

export const OPERATOR_NOT_CERTIFIED_VALIDATIONS = Yup.object({
  equipmentType: Yup.string().required("Required"),
  industryType: Yup.string().required("Required"),
  equipmentMake: Yup.string().required("Required"),
});

export const MAINTENANCE_NOT_CERTIFIED_VALIDATIONS = Yup.object({
  equipmentType: Yup.string().required("Required"),
  industryType: Yup.string().required("Required"),
  equipmentMake: Yup.string().required("Required"),
  // expertise: Yup.array().required("Required"),
});

export const MAINTENANCE_CERTIFIED_VALIDATIONS = Yup.object().shape({
  equipmentType: Yup.string().required("Required"),
  industryType: Yup.string().required("Required"),
  equipmentMake: Yup.string().required("Required"),
  // expertise: Yup.array().required("Required"),
  certificateNumber: Yup.string()
    .required("Required")
    .min(5, "Minimum 5 characters required")
    .max(25, "Maximum 25 characters allowed"),
  certificateIssuer: Yup.string()
    .required("Required")
    .min(5, "Minimum 5 characters required")
    .max(30, "Maximum 30 characters allowed"),
  certificateImage: Yup.mixed()
    .required("Required")
    .test(
      "fileSize",
      "File should not be greater than 5 MB",
      (value) => !value || value.size <= 1000000 * 5
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) =>
        value && ["image/jpg", "image/jpeg", "image/png","application/pdf"].includes(value.type)
    ),
    certificateIssueDate: Yup.string().required("Required")
});
export const OPERATOR_CERTIFIED_VALIDATIONS = Yup.object({
  equipmentType: Yup.string().required("Required"),
  industryType: Yup.string().required("Required"),
  equipmentMake: Yup.string().required("Required"),
  certificateNumber: Yup.string()
    .required("Required")
    .min(5, "Minimum 5 characters required")
    .max(25, "Maximum 25 characters allowed"),
  certificateIssuer: Yup.string()
    .required("Required")
    .min(5, "Minimum 5 characters required")
    .max(30, "Maximum 30 characters allowed"),
  certificateImage: Yup.mixed()
    .required("Required")
    .test(
      "fileSize",
      "File should not be greater than 5 MB",
      (value) => !value || value.size <= 1000000 * 5
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) =>
        value && ["image/jpg", "image/jpeg", "image/png","application/pdf"].includes(value.type)
    ),
    certificateIssueDate: Yup.string().required("Required")
});
