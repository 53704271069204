import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_CONSTANTS } from "../../../../../constants/apiConstants";
import { getAllBusinessTypes } from "../../../../services/masterData/masterData";

export const getBusinessTypesThunk = createAsyncThunk(
    'businessTypes/get',
    async (_, { rejectWithValue }) => {
      try {
        const data = await getAllBusinessTypes();        
        if (data.Status === 200) {
          return { data: data.Data.BusinessTypeList, message: data.Message.SuccessMessage };
        } else {
          return rejectWithValue({ message: data.message.FailMessage });
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );