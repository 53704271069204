import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import { FormikInput } from "../../../../components/Input/FormikInput";
import { API_CONSTANTS } from "../../../../../constants/apiConstants";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { registerNumbersOnly } from "../../../../../utils/form/form";
import * as Yup from "yup";
import { convertToFormData } from "../../../../helpers/dataConversion/convertToFormData";
import { RadioParent } from "../../../../components/RadioParent/RadioParent";
import { useNavigate } from "react-router-dom";
import { resetMaintenanceSearchSlice, setIsLoading } from "../../slice/maintenanceJobPostSlice";
import { LocationInput } from "../../../../components/LocationInput/LocationInput";
import { AccordianElement } from "../../../../components/AccordianElement/AccordianElement";
import { getPayCycleTypes, getProvidentFundTypes, getSalaryTypes } from "../../../../services/salary/salary";
import { getFoodBenefitTypes } from "../../../../services/foodBenefits/foodBenefits";
import { getAccomodationTypes } from "../../../../services/accomodation/accomodation";
import { createMaintenanceJobDetailsForFullTime } from "../../../../services/createMaintenanceJob/createMaintenanceJob";


export const FullTimeMaintenanceForm = () => {
  const { token, currencyDetails, UserTypeValue, accountDetails } = useSelector(
    (state) => state.user
  );
  const {
    jobType,
    maintenenaceType,
    repairType,
    equipmentType,
    equipmentMake,
    equipmentModel,
    equipmentAge,
    serviceDetails
  } = useSelector((state) => state.maintenanceSearch);
  const [salaryTypeList, setSalaryTypeList] = useState(null);
  const [providentFundList, setProvidentFundList] = useState(null);
  const [payCycleList, setPayCycleList] = useState(null);
  const [foodBenefitList, setFoodBenefitList] = useState(null);
  const [accomodationTypeList, setAccomodationTypeList] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast()

  const getSalaryTypeList = async () => {
    try {
      const data = await getSalaryTypes();
      return setSalaryTypeList(data);
    } catch (error) {
      setSalaryTypeList(null);
      return error;
    }
  };
  const getProvidentFundList = async () => {
    try {
      const data = await getProvidentFundTypes();
      return setProvidentFundList(data);
    } catch (error) {
      setProvidentFundList(null);
      return error;
    }
  };

  const getPayCycleList = async () => {
    try {
      const data = await getPayCycleTypes();
      return setPayCycleList(data);
    } catch (error) {
      setPayCycleList(null);
      return error;
    }
  };

  const getAccomodationTypeList = async () => {
    try {
      const data = await getAccomodationTypes();
      return setAccomodationTypeList(data);
    } catch (error) {
      setAccomodationTypeList(null);
      return error;
    }
  };
  const getFoodBenefitsList = async () => {
    try {
      const data = await getFoodBenefitTypes();
      return setFoodBenefitList(data);
    } catch (error) {
      setFoodBenefitList(null);
      return error;
    }
  };

  useEffect(() => {
    (async () => {
      await getSalaryTypeList();
      await getProvidentFundList();
      await getPayCycleList();
      await getFoodBenefitsList();
      await getAccomodationTypeList();
    })();
  }, []);

  return (
    <Formik
      initialValues={{
        _jobTitle: equipmentType
          ? `${equipmentType.value} Mechanic`
          : "",
        _salaryType: "",
        _providentFund: "",
        _foodBenefits: "",
        _accomodationProvided: "",
        _inhandSalary: "",
        _payCycle: "",
      }}
      validationSchema={Yup.object().shape({
        _jobTitle: Yup.string().required("Job Title is Required"),
        _salaryType: Yup.string().required("Salary Type is Required"),
        _providentFund: Yup.string(),
        _foodBenefits: Yup.string().required("Food Benefits is Required"),
        _accomodationProvided: Yup.string().required(
          "Accomodation Provided is Required"
        ),
        _inHandSalary: Yup.number().required("In-Hand Salary is Required"),
        _payCycle: Yup.number().required("Pay Cycle is Required"),
      })}
      onSubmit={async (values) => {
        const postBody = {
          ServiceTypeId: parseInt(serviceDetails.ServiceTypeId),
          MaintenanceCategoryId: jobType.id,
          MaintenanceTypeId: maintenenaceType,
          EquipmentNickName: "",
          EquipmentTypeId: equipmentType.id,
          EquipmentMakeId: equipmentMake,
          EquipmentModelId: equipmentModel,
          EquipmentAgeId: equipmentAge,
          JobTitle: values._jobTitle,
          WorkLocation: values._jobLocation.name,
          WorkLocationLatitude:values._jobLocation.lat ,
          WorkLocationLongitude: values._jobLocation.lng,
          Salary: parseFloat(values._inHandSalary).toFixed(2),
          SalaryType: values._salaryType,
          PFProvided: values._providentFund,
          PayCycleId: values._payCycle,
          FoodBenefitsId:values._foodBenefits,
          AccomodationId: parseInt(values._accomodationProvided),
          OverTimeRate: values._overTimeSalary ? values._overTimeSalary : "",
        };
        if(UserTypeValue === "Company"){
          postBody.TaxNumber = accountDetails.CompanyDetails.TaxNumber
        }
        const postFullTimeJob = async () => {
          try{
            dispatch(setIsLoading(true));
            const data = await createMaintenanceJobDetailsForFullTime(postBody);

              if(data.Status === 201){
                toast({
                  description : data.Message.SuccessMessage,
                  position: "top-right",
                  status: "success"
                })
                dispatch(resetMaintenanceSearchSlice())
                navigate("/dashboard")
              }
            }
            catch(error){
            }
            finally{
              dispatch(setIsLoading(false));
            }
        };
        await postFullTimeJob();
      }}
      enableReinitialize
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <form id="full-time-maintenance-form" onSubmit={handleSubmit}>
          <Accordion allowMultiple>
            <Flex flexDirection="column" gap="0.5em">
            <AccordianElement title="Job Details" paddingBottom="1em">
                
                  <Flex wrap="wrap" gap="1em" justifyContent="space-between">
                    <FormikInput
                      label="Job Title"
                      name="_jobTitle"
                      isRequired={true}
                      placeholder="Enter Job Title ..."
                      maxWidth="450px"
                      maxLength={50}
                    />
                    <Field name="_jobLocation">
                    {({field}) => (
                      <LocationInput
                        name={field.name}
                        label="Job Location"
                        isRequired={true}
                        onChange={(value) =>
                          setFieldValue("_jobLocation", value)
                        }
                        maxWidth="450px"
                        width="100%"
                        height="52px"
                      />
                    )}
                  </Field>
                  </Flex>
              
              </AccordianElement>
              <AccordionItem bgColor="#FFFFFF">
                <h2>
                  <AccordionButton>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      color="#1E3264"
                      textTransform="uppercase"
                      fontSize="22px"
                      fontWeight="600"
                    >
                      In-Hand Salary Details
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex wrap="wrap" gap="1em" justifyContent="space-between">
                  <Field name="_salaryType">
                      {({ field }) => {
                        return (
                          <RadioParent
                            name={field.name}
                            options={salaryTypeList}
                            maxWidth="450px"
                            isFormik={true}
                            label="Salary Type"
                            isRequired={true}
                          />
                        );
                      }}
                    </Field>
                    <FormikInput
                      label="In-Hand Salary"
                      name="_inHandSalary"
                      isRequired={true}
                      placeholder="Enter in-hand salary here..."
                      maxWidth="450px"
                      onKeyDown={registerNumbersOnly}
                      maxLength={6}
                      prefixText={
                        currencyDetails.CurrencyValue
                          ? currencyDetails.CurrencyValue
                          : ""
                      }
                    />
                    <FormikInput
                      label="Over Time Rate per hour (if)"
                      name="_overTimeSalary"
                      isRequired={false}
                      placeholder="Enter overtime rate/hour here..."
                      maxWidth="450px"
                      onKeyDown={registerNumbersOnly}
                      maxLength={3}
                      prefixText={
                        currencyDetails.CurrencyValue
                          ? currencyDetails.CurrencyValue
                          : ""
                      }
                    />
                     
                     <Field name="_providentFund">
                      {({ field }) => {
                        return (
                          <RadioParent
                          name={field.name}
                            label="Is Provident Fund (pf) required?"
                            isRequired={true}
                            options={providentFundList}
                            maxWidth="450px"
                            isFormik={true}
                          />
                        );
                      }}
                    </Field>
                    <Field name="_payCycle">
                      {({ field }) => {
                        return (
                          <RadioParent
                            name={field.name}
                            options={payCycleList}
                            maxWidth="450px"
                            isFormik={true}
                            label="Pay Cycle(Week)"
                            isRequired={true}
                          />
                        );
                      }}
                    </Field>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem bgColor="#FFFFFF">
                <h2>
                  <AccordionButton>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      color="#1E3264"
                      textTransform="uppercase"
                      fontSize="22px"
                      fontWeight="600"
                    >
                      Food and Accomodation
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex wrap="wrap" gap="1em" justifyContent="space-between">
                  <Field name="_foodBenefits">
                      {({ field }) => {
                        return (
                          <RadioParent
                            name={field.name}
                            options={foodBenefitList}
                            maxWidth="450px"
                            isFormik={true}
                            label="Food Benefits"
                            isRequired={true}
                          />
                        );
                      }}
                    </Field>
                    <Field name="_accomodationProvided">
                      {({ field }) => {
                        return (
                          <RadioParent 
                            name={field.name}
                            options={accomodationTypeList}
                            maxWidth="450px"
                            isFormik={true}
                            label="Accomodation Provided"
                            isRequired={true}
                          />
                        );
                      }}
                    </Field>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
            </Flex>
          </Accordion>
        </form>
      )}
    </Formik>
  );
};
