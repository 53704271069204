import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeConfirmMediaModal, increaseStepperIndex } from "../../../features/MyBusinessDealershipDetail/slice/myBusinessDealershipSlice";
import { CustomGeneralModal } from "../../CustomGeneralModal/CustomGeneralModal";


export const ConfirmMediaPopUp = () => {

    const dispatch = useDispatch();
    const postDealershipData = useSelector(
        (state) => state.myBusinessDealership
    );

    const handleAddDealership = ()=>{
        dispatch(closeConfirmMediaModal())
    }

    return (
        <>
        {/* <Modal isOpen={postDealershipData.isConfirmMediaOpen} isCentered >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Box textAlign="right">
                        <Button
                            variant="ghost"
                            onClick={() => {
                                dispatch(closeConfirmMediaModal());
                            }}
                            boxShadow="none"
                        >
                            <CloseIcon />
                        </Button>
                    </Box>
                    <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
                    <img src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/Postdealership/chat.svg`} style={{ maxHeight: "40px", maxWidth: "40px" }} />
                
                    </Flex>
                </ModalHeader>

                <ModalBody>
                    <Text 
                    color = "#002961"
                    textAlign = "center"
                    fontSize={ "28px"}
                    fontWeight= "700"
                    >Do you know that adding Photos and Videos gives you better Visibility</Text>
                    <Flex alignItems={"center"} justifyContent={"space-between"} margin={"1em"}>
                    <Button backgroundColor={"#F79C00"} color={"white"} onClick={()=>dispatch(increaseStepperIndex())}>
                        Skip
                    </Button>
                    <Button backgroundColor={"#F79C00"} color={"white"} onClick={()=>dispatch(closeConfirmMediaModal())}>
                        Upload
                    </Button>
                    </Flex>

                </ModalBody>
            </ModalContent>
        </Modal> */}
        <CustomGeneralModal
        isOpen={postDealershipData.isConfirmMediaOpen}
        hasCloseButton={true}
        onCloseButtonClick={() => {
            dispatch(closeConfirmMediaModal());
        }}
        iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/Postdealership/chat.svg`}
        heading={<p style={{color:"#002961"}}>Do you know that adding Photos and Videos gives you better Visibility</p>}
        primaryActionText={"Upload"}
        primaryAction={()=>dispatch(closeConfirmMediaModal())}
        secondaryActionText={"Skip"}
        secondaryAction={()=>dispatch(increaseStepperIndex())}
        />
        </>
    );
};
