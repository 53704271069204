import { Box, Flex, Text } from "@chakra-ui/layout";
import { Progress } from "@chakra-ui/progress";

export const ProgressBarCard = ({
  title = "Title",
  totalCount = 1,
  progressCount = 0,
  colorVariant = ""
}) => {
  return (
    <Flex
      flexDirection="column"
      gap="0.25em"
      color="#FFFFF"
      boxShadow="0px 3px 7px 3px #2c2c2c20"
      border="1px solid #E3E5E8"
      width="242px"
      minW="242px"
      padding="0.5em"
      borderRadius="4px"
      paddingBottom="1em"
    >
      <Text fontSize="24px" fontWeight="700">
        {progressCount} / {totalCount}
      </Text>
      <Text fontSize="18px" lineHeight="26px">
        {title}
      </Text>
      <Box width="95%">
        <Progress
        variant={colorVariant}
          value={totalCount===0?0:(progressCount / totalCount) * 100}
          borderRadius="8px"
          background= "#EDF2F7"
        />
      </Box>
    </Flex>
  );
};
