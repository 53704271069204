import React from "react";
import {
    Box,
    Button,
    Flex,
    FormErrorMessage,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "@chakra-ui/icons";

import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup"
import { closeAddStorePopUp, setStoreFlag, setStoreId } from "../slice/myStoreSlice";
import { FormikInput } from "../../../components/Input/FormikInput";
import { ImageUpload } from "../../../components/ImageUpload/ImageUpload";
import { postStoreDetails } from "../../../services/store/storeServices";




export const AddStorePopUp = ({media}) => {
    const dispatch = useDispatch();
    const { isStorePopUpOpen , storeData } = useSelector((state) => state.myStore);


    const navigate = useNavigate();
    const toast = useToast();

    const initialValues = {
        storeName: storeData ? storeData[0]?.StoreName : "",
        myStoreLogo: storeData ? storeData[0]?.StoreLogo : "",
    };

    const validationSchema = Yup.object().shape({
        storeName: Yup.string().required("Warehouse name must be provided").max(15, "Warehouse name must be at most 15 characters"),
    })


    const handleStore = (values) => {
        const payload = {
            StoreName : values.storeName,
            MyStoreLogo : values.myStoreLogo
        }
        try {
            (async() => {
                const response = await postStoreDetails(payload);
                if(response){
                    dispatch(setStoreId(response.StoreId));
                    toast({
                        title : response.message,
                        position : "top-right",
                        status : "success"
                    })
                    dispatch(setStoreFlag());
                    dispatch(closeAddStorePopUp());
                }else{
                    toast({
                        title : response.message,
                        position:"top-right",
                        status : "error"
                    })
                }
                dispatch(setStoreFlag());
                dispatch(closeAddStorePopUp());
                
            })()
        } catch (error) {
        }
    }

    return (
        <Modal isOpen={isStorePopUpOpen} isCentered >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Box textAlign="right">
                        <Button
                            variant="ghost"
                            onClick={() => {
                                dispatch(closeAddStorePopUp());
                            }}
                            boxShadow="none"
                        >
                            <CloseIcon />
                        </Button>
                    </Box>
                    <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
                        <img src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/my-business/my-store/add-store.svg`} style={{ maxHeight: "60px", maxWidth: "60px" }} />
                        <Heading
                            fontSize="28px"
                            fontWeight="600"
                            fontFamily="Assistant"
                            lineHeight="52.32px"
                        >
                            Personalize your Store!
                        </Heading>
                        <Text
                        color =  "#767676"
                        text-align =  "center" 
                        font-size =  "20px"
                        font-weight =  "400"
                        >Set the name of your warehouse, add your logo and
                            select a user as the warehouse manager</Text>
                    </Flex>
                </ModalHeader>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleStore} >
                    {({ values, setFieldValue ,errors,touched,}) => (
                        <Form>
                            <ModalBody height={"360px"} display={"flex"} flexDirection={"column"} justifyContent={"space-evenly"} overflow={"scroll"}>
                                      <FormikInput
                                            label="Your Warehouse Name"
                                            name="storeName"
                                            placeholder="Enter Warehouse Name ..."
                                            maxWidth="450px"
                                            isRequired={true}
                                        />

                                        <FormErrorMessage />

                                <ImageUpload
                              label="Upload Logo"
                              name="myStoreLogo"
                              helperText=""
                              acceptType="image/jpeg , image/png"
                              formikProps={{
                                setFieldValue,
                                errors,
                                touched,
                              }}
                            
                            />

                            </ModalBody>
                            <ModalFooter alignItems={"center"} justifyContent={"center"} borderRadius={"0px 0px 12px 12px"} boxShadow={"0px 1px 15px 0px rgba(44, 44, 44, 0.16)"}>
                                <Flex alignItems={"center"} justifyContent={"center"}  >
                                    <Button backgroundColor={"#F79C00"} color={"white"} width={"128px"} height={"45px"} type="submit">
                                        Next
                                    </Button>
                                </Flex>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>

    );
};
