import React, { useEffect, useState } from "react";
import { V5SidebarLayout } from "../../../../layout/V5SidebarLayout/V5SidebarLayout";
import CommonHeader from "../../../../components/CommonHeader/CommonHeader";
import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getRequirementRequestListThunk } from "../../thunk/industryLeads/getRequirementRequestListThunk";
import { getResponseTypeDetails } from "../../../../services/materials/materials";
import { AddToMarketplacePopUp } from "../../../../components/AddToMarketplacePopUp/AddToMarketplacePopUp";
import { getFreeLeadsForCompanyThunk } from "../../../freeLeads/thunks/getFreeLeadsForCompanyThunk";
import { getFreeLeadsForIndividualThunk } from "../../../freeLeads/thunks/getFreeLeadsForUserThunk";
import { getIndustryLeadThunk } from "../../../purchasedLeadsCheck/thunks/getIndustryLeadThunk";
import { convertFromBase64 } from "../../../../../utils/formatConverter/convertToBase64";
import { setMarketplaceModal } from "../../slice/myLeadsIndustryRequirementSlice";
import {
  industryFreeReedemTransaction,
  industrySubscriptionReedemTransaction,
  updateRequirementConnectStatus,
} from "../../../../services/industryRequirement/industryRequirement";
import { convertToFormData } from "../../../../helpers/dataConversion/convertToFormData";
import { toast } from "react-toastify";
import { getPremiumLeadsByIdThunk } from "../../../premiumLeads/thunks/getPremiumLeadsById";
import { postE9PremiumRedeem } from "../../../../services/premium/premium";

const IndustryRequestDetails = () => {
  const { requestReceivedList, addToMarketplace } = useSelector(
    (state) => state.myLeadsIndustry
  );
  const [responseType, setResponseType] = useState();
  const { requestId, serviceTypeId } = useParams();
  const dispatch = useDispatch();

  const [freeListingCount, setFreeListingCount] = useState(null);
  const { freeLeadsData } = useSelector((state) => state.freeLeads);
  const userData = useSelector((state) => state.user);
  const userTypeValue = userData?.UserTypeValue?.toLowerCase();
  const userCredentialId =
    userData?.accountDetails?.UserDetails?.CredentialDetails?.UserCredentialId;
  const { industry } = useSelector((state) => state.purchasedLeads);
  const [reqConnectId, setReqConnectId] = useState();
  const [reqResTypeValue, setReqResTypeValue] = useState();
  const toast = useToast();
  const { isPremium, premiumId } =
  useSelector((state) => state.premium);
  const {premiumLeadsById} = useSelector( state => state?.premiumLeads);
  const { remainingLeads } = useSelector(
    (state) => state.premiumLeads.premiumLeadsById
  );

  useEffect(() => {
    dispatch(getRequirementRequestListThunk(requestId));
    getResponseType();
  }, [requestId]);

  useEffect(() => {
    if (isPremium && premiumId) {
      dispatch(getPremiumLeadsByIdThunk(serviceTypeId));
    }
  }, [isPremium, premiumId]);

  const getResponseType = async () => {
    const data = await getResponseTypeDetails();
    if (data?.ResponseTypes.length > 0) {
      setResponseType(data?.ResponseTypes);
    }
  };
  const renderCards = (array) => {
    const renderJSX =
      array && array?.length > 0 ? (
        array.map((data) => (
          <Flex direction="column" gap="2em">
            <Box
              width="530px"
              border="1px solid #E3E5E8"
              boxShadow="0px 6px 15px -3px #00000029"
              padding="1em 1em"
              display="flex"
              flexDirection="column"
              gap="0.50em"
              borderRadius="4px"
            >
              <Flex justifyContent="space-between" alignItems="center">
                <Text color="#959596" fontSize="14px" fontWeight="600"></Text>
                <Button
                  borderRadius="100px"
                  width="110px"
                  height="30px"
                  backgroundColor={
                    IsVerified ? "#319F0060" : "rgba(214, 0, 0, 0.40)"
                  }
                >
                  <Text color="#fff" fontSize="12px" fontWeight="600">
                    {IsVerified ? "Verified" : "Not Verified"}
                  </Text>
                </Button>
              </Flex>

              <Box display="flex" gap="1em">
                <Box maxH="120px" minH="110px" maxW="130px" minW="120px">
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/DelarshipDefaultImage.png`}
                  />
                </Box>

                <Flex direction="column" gap="1em">
                  <Box>
                    <Text color="#2C2C2C" fontSize="16px" fontWeight="600">
                      {data?.UserName} - {data?.BusinessTypeValue} has requested
                      to contact you for your Requirement
                    </Text>
                    <Text color="#767676" fontSize="14px" fontWeight="600">
                      Request receives on: {data?.ReceivedOn}
                    </Text>
                  </Box>
                  <Flex justifyContent="space-between">
                    <Button
                      leftIcon={
                        <img
                          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/phone.svg`}
                        />
                      }
                      color="#002961"
                      fontWeight="700"
                      border="none"
                      backgroundColor="transparent"
                      boxShadow="none"
                      _hover={{
                        backgroundColor: "none",
                      }}
                    >
                      {data?.mobileNumber}
                    </Button>


                      {data?.ResponseTypeValue?.toLowerCase() === "request sent" ? (
                    <Flex gap="1em">
                       <Button
                        backgroundColor="transparent"
                        color="#D60000"
                        border="1px solid #D60000"
                        onClick={() => {
                            handleReject(data?.RequirementConnectId,data?.ResponseTypeValue);
                        }}
                      >
                        Reject
                      </Button>
                      <Button
                        backgroundColor="transparent"
                        color="#319F00"
                        border="1px solid #319F00"
                        onClick={() => {
                          setReqConnectId(data?.RequirementConnectId);
                          setReqResTypeValue(data?.ResponseTypeValue);
                          dispatch(setMarketplaceModal(true));
                        }}
                      >
                        Accept
                      </Button>
                    </Flex>
                      ) : (
                        data?.ResponseTypeValue?.toLowerCase() === "request rejected" ? (
                          <Button
                          backgroundColor="transparent"
                          border='none'
                          boxShadow='none'
                          color="#D60000"
                          fontSize='14px'
                          fontStyle='600'
                          _hover={{
                            backgroundColor:'transparent',
                          boxShadow : 'none'

                          }}
                          leftIcon={
                            <img src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/cross.svg`}/>
                          }
                          >
                            {data?.ResponseTypeValue}
                          </Button>
                        ) : (
                          <Button
                          backgroundColor="transparent"
                          border='none'
                          boxShadow='none'
                          color="#319F00"
                          fontSize='14px'
                          fontStyle='600'
                          _hover={{
                            backgroundColor:'transparent',
                          boxShadow :'none'

                          }}
                          leftIcon={
                            <img src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/tick.svg`}/>
                          }
                          >
                            {data?.ResponseTypeValue}
                          </Button>
                        )
                      )}
                     

                  </Flex>
                </Flex>
              </Box>
            </Box>
          </Flex>
        ))
      ) : (
        <Text>No Records Found</Text>
      );
    return renderJSX;
  };

  // Add To Marketplace Start

  useEffect(() => {
    handleLeadsCheck();
  }, [freeLeadsData, userTypeValue]);

  useEffect(() => {
    getSubscriptionLeadsCheck();
  }, [requestReceivedList]);

  const handleLeadsCheck = async () => {
    if (!freeLeadsData || freeLeadsData.length <= 0) {
      if (userTypeValue.toLowerCase() === "Company".toLowerCase()) {
        dispatch(getFreeLeadsForCompanyThunk());
      } else {
        dispatch(getFreeLeadsForIndividualThunk());
      }
    } else {
      freeSubscriptionCount();
    }
  };

  const freeSubscriptionCount = () => {
    const industryListing = freeLeadsData.find(
      (category) =>
        category.SubscriptionTypeValue === "Contact Based" &&
        category.ServiceTypeValue === "Requirement Connect"
    );
    setFreeListingCount({
      leadsRemaining: industryListing.ContactRemaining,
      totalLeads: industryListing.FreeLeadsProvided,
    });
    return {
      leadsRemaining: industryListing.ContactRemaining,
      totalLeads: industryListing.FreeLeadsProvided,
    };
  };

  const getSubscriptionLeadsCheck = async () => {
    dispatch(getIndustryLeadThunk(serviceTypeId));
  };

  const handleAddToMarketplace = async (selectedLeadType) => {
    if (selectedLeadType) {
      const leadType = selectedLeadType.toLowerCase();
      switch (leadType) {
        case "free":
          handleFreeTransaction();
          break;
        case "subscription":
          handleSubscriptionTransaction();
          break;
          case "premium":
            handlePremiumTransaction();
            break;
        default:
          break;
      }
    }
  };

  // Reedem Handling
  const handleFreeTransaction = async () => {
    const findLead = await freeLeadsData?.find(
      (leads) => leads?.ServiceTypeValue === "Requirement Connect"
    );

    const payload = {
      ServiceTypeId: findLead?.ServiceTypeId?.toString(),
      FreeLeadConsumed: 1,
    };
    const data = convertToFormData(payload);
    try {
      const response = await industryFreeReedemTransaction(data);
      if (response?.Status === 201) {
        handleAccept();
        handleLeadsCheck();
      }
    } catch (error) {}
  };

  const handleSubscriptionTransaction = async () => {
    const reedeemPayload = [];
    reedeemPayload.push({
      TransactionId: industry?.selectedSubscription?.E9TransactionId,
      UserSubsServicePurchasedId:
        industry?.selectedSubscription?.UserSubsServicePurchasedId,
      ServiceId: industry?.selectedSubscription?.ServiceTypeId,
      LeadPurchased: industry?.selectedSubscription?.PurchasedCount,
      LeadRedeemed: 1,
      LeadRemaining: industry?.selectedSubscription?.LeadRemaining - 1,
    });

    try {
      const data = await industrySubscriptionReedemTransaction(
        JSON.stringify(reedeemPayload)
      );
      if (data?.data?.Status === 201) {
        getSubscriptionLeadsCheck();
        handleAccept();
      }
    } catch (error) {}
  };

  console.log("premiumLeadsById?.premiumDetails",premiumLeadsById?.premiumDetails)
  const handlePremiumTransaction = async () => {
    // const transactionId  = await premiumLeadsById?.premiumDetails && premiumLeadsById?.premiumDetails?.find(id => id?.E9Services?.toString()?.toLowerCase() === 'requirement connect') ;

    const Payload = {
      UserCredentialId:
        userData?.accountDetails?.UserDetails?.CredentialDetails
          ?.UserCredentialId,
      E9PremiumTransactionDetailsId:
        premiumLeadsById?.premiumDetails?.E9PremiumTransactionDetailsId,
      PaymentPlanId: premiumLeadsById?.premiumDetails?.PaymentPlanId,
      ServiceTypeId: premiumLeadsById?.premiumDetails?.ServiceTypeId,
      UniqueRefId: reqConnectId,
      RedeemCount: 1,
    };
    // console.log("PAYLOAD",Payload)
    try {
      const {data} = await postE9PremiumRedeem(Payload);
      console.log("PREMIUM HANDLE",data)
      if (data?.Status === 201) {
        handleAccept();

      } else {
      }
    } catch (error) {}
  };

  const handleAccept = async () => {
    try {
      const payload = {
        RequirementConnectId: reqConnectId?.toString(),
        ResponseTypeId:
          reqResTypeValue?.toLowerCase() === "Request Accepted"?.toLowerCase()
            ? 3
            : 2,
        ResponseTypeValue: reqResTypeValue?.toString(),
        OwnerUserCredentialId: userCredentialId?.toString(),
      };
      const data = await updateRequirementConnectStatus(payload);
      if (data?.Status === 204) {
        dispatch(setMarketplaceModal(false));
        dispatch(getRequirementRequestListThunk(requestId));
        toast({
          status: "success",
          description: "Connect request updated",
          position: "top-right",
          isClosable: true,
        });
      } else {
        dispatch(setMarketplaceModal(false));
        dispatch(getRequirementRequestListThunk(requestId));
      }
    } catch (error) {}
  };
  const handleReject = async (connectId,resTypeValue) => {
    try {
      const payload = {
        RequirementConnectId: connectId?.toString(),
        ResponseTypeId: "3",
        ResponseTypeValue: "Request Rejected",
        OwnerUserCredentialId: userCredentialId?.toString(),
      };
      const data = await updateRequirementConnectStatus(payload);
      if (data?.Status === 204) {
        dispatch(setMarketplaceModal(false));
        dispatch(getRequirementRequestListThunk(requestId));
        toast({
          status: "success",
          description: "Connect request updated",
          position: "top-right",
          isClosable: true,
        });
      } else {
        dispatch(getRequirementRequestListThunk(requestId));
      }
    } catch (error) {}
  };
  // Add To Marketplace End
  const IsVerified = true;
  return (
    <V5SidebarLayout>

      <CommonHeader header="Received Requests" />
      <Flex gap='2em' flexWrap='wrap' padding='1em 1em'>
      {renderCards(requestReceivedList)}
      </Flex>

      {/* Add To Marketplace */}
      <AddToMarketplacePopUp
        isOpen={addToMarketplace}
        onCloseButtonClick={() => {
          dispatch(setMarketplaceModal(false));
        }}
        freeListingCount={
          freeListingCount && freeListingCount.totalLeads
            ? freeListingCount.leadsRemaining
            : 0
        }
        subscriptionListingCount={
          industry?.leadsRemaining ? industry?.leadsRemaining : 0
        }
        onSubmitButtonClick={handleAddToMarketplace}
        primaryButtonText = "Connect"
        heading="Industry Requirement"
        iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/input.svg`}
        premiumListingCount={remainingLeads ? remainingLeads : 0}
      />
    </V5SidebarLayout>
  );
};

export default IndustryRequestDetails;
