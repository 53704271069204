import { Field, Formik } from "formik";
import { Box, Flex, Text } from "@chakra-ui/layout";

import { FormikDropdown } from "../../../components/Dropdown/FormikDropdown";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  increaseStepperIndex,
  setEquipmentAge,
  setEquipmentMake,
  setEquipmentModel,
  setEquipmentNickname,
  setEquipmentType,
  setIsLoading,
  setOpServiceCategory,
} from "../slice/operatorSearchSlice";
import { RadioParent } from "../../../components/RadioParent/RadioParent";
import * as Yup from "yup";
import {
  getEquipmentAges,
  getEquipmentMakesById,
  getEquipmentModelById,
  getEquipmentNickNameList,
  getEquipmentTypes,
  getNicknameEquipmentList,
} from "../../../services/equipments/equipments";
import { useLocation, useParams } from "react-router-dom";
import { convertFromBase64 } from "../../../../utils/formatConverter/convertToBase64";
import { getE9ServiceCategoryThunk } from "../../e9Services/thunks/getE9ServiceCategoryDetailsThunk";

export const EquipmentDetailsForm = () => {
  const { equipmentType, equipmentModel, equipmentMake, equipmentAge ,equipmentNickname} =
    useSelector((state) => state.operatorSearch);
  const [equipmentNickNameList, setEquipmentNickNameList] = useState(null);
  const [equipmentTypeList, setEquipmentTypeList] = useState(null);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null);
  const [equipmentMakeList, setEquipmentMakeList] = useState(null);
  const [selectedEquipmentMake, setSelectedEquipmentMake] = useState(null);
  const [equipmentModelList, setEquipmentModelList] = useState(null);
  const [equipmentAgeList, setEquipmentAgeList] = useState(null);
  const [selectedNickEquipment,setSelectedNickEquipment] = useState(null);
  const [updatedEquipmentList,setUpdatedEquipmentList] = useState(null);
  const dispatch = useDispatch();
  const {nickname,eqTypeId,eqMakeId,eqModelId} = useParams();
  const { categoryList } = useSelector(
    (state) => state.e9Services
  );

  const initialValues = {
    _equipmentNickname: equipmentNickname ? equipmentNickname : "",

    _equipmentType: equipmentType ? equipmentType?.id : "",
    _equipmentMake: equipmentMake ? equipmentMake : "",
    _equipmentModel: equipmentModel ? equipmentModel : "",
    _equipmentAge: equipmentAge ? equipmentAge : "",
  }

  const getAllEquipmentNickNameList = async () => {
    try {
      const data = await getEquipmentNickNameList();
      if(data?.Status === true && data?.Data?.NickNameList.length > 0){
        setEquipmentNickNameList(data?.Data?.NickNameList)
      }
    } catch (error) {
      return error;
    }
  };

  const getSelectedNicknameEquipmentList = async () =>{
    dispatch(setEquipmentNickname(selectedNickEquipment?.NickName));
    try {
      const data = await getNicknameEquipmentList();

      if(data?.AssetDetails.length > 0){
        const equipment = await data?.AssetDetails.find((equipment)=> equipment?.AssetId === selectedNickEquipment?.AssetId);
        if(equipment){
          setUpdatedEquipmentList(equipment);
          updateEquipmentDetails(equipment);
        }
      }
    } catch (error) {
      
    }
  }
  const updateEquipmentDetails = (equipment) =>{
    const requiredEquipmentType = equipmentTypeList.find(
      ({ id }) => id === parseInt(equipment?.EquipmentTypeId)
    );

    dispatch(setEquipmentMake(parseInt(equipment?.EquipmentMakeId)));
    dispatch(setEquipmentType(requiredEquipmentType));
    dispatch(setEquipmentModel(parseInt(equipment?.EquipmentModelId)));
    setSelectedEquipmentType(requiredEquipmentType?.id);
    setSelectedEquipmentMake(equipment?.EquipmentMakeId);

  }

  const setNicknameByUrl = async () =>{

    let requiredEquipmentType = ""
    const equipmentTypeId = convertFromBase64(eqTypeId) ;
    
    if(equipmentTypeList){
       requiredEquipmentType = equipmentTypeList.find(
        ({ id }) => id === parseInt(equipmentTypeId)

      );
    }

    dispatch(setEquipmentNickname(convertFromBase64(nickname)));
    dispatch(setEquipmentMake(parseInt(convertFromBase64(eqMakeId))));
    dispatch(setEquipmentType(requiredEquipmentType));
    dispatch(setEquipmentModel(parseInt(convertFromBase64(eqModelId))));
    setSelectedEquipmentType(convertFromBase64(eqTypeId));
    setSelectedEquipmentMake(convertFromBase64(eqMakeId));
  }
  const getServiceTypeDetails = () =>{
    dispatch(getE9ServiceCategoryThunk());
    if(nickname){
      dispatch(setOpServiceCategory(categoryList?.[0]?.data?.[0]));
    }

  }
  const getAllEquipmentType = async () => {
    try {
      const data = await getEquipmentTypes();
      return setEquipmentTypeList(data);
    } catch (error) {
      setEquipmentTypeList(null);
      return error;
    }
  };

  const getAllEquipmentAgeList = async () => {
    try {
      const data = await getEquipmentAges();
      return setEquipmentAgeList(data);
    } catch (error) {
      setEquipmentAgeList(null);
      return error;
    }
  };

  const getEquipmentMakeList = async (equipmentType) => {
    try {
      const data = await getEquipmentMakesById(equipmentType);
      return setEquipmentMakeList(data);
    } catch (error) {
      setEquipmentMakeList(null);
      return error;
    }
  };

  const getEquipmentModelsList = async (equipmentMake) => {
    try {
      const data = await getEquipmentModelById(equipmentMake);
      return setEquipmentModelList(data);
    } catch (error) {
      setEquipmentModelList(null);
      return error;
    }
  };

  useEffect(() => {
    (async () => {
      await getAllEquipmentNickNameList();
      await getAllEquipmentAgeList();
      await getAllEquipmentType();
    })();
  }, [nickname]);

  useEffect(() => {
    if (selectedEquipmentType) {
      (async () => {
        await getEquipmentMakeList(selectedEquipmentType);
      })();
    }
  }, [selectedEquipmentType]);

  useEffect(() => {
    if (selectedEquipmentMake) {
      (async () => {
        await getEquipmentModelsList(selectedEquipmentMake);
      })();
    }
  }, [selectedEquipmentMake]);

  useEffect(()=>{
    if(selectedNickEquipment){
      getSelectedNicknameEquipmentList();
    }
  },[selectedNickEquipment])


  useEffect(()=>{

    if(nickname){
      setNicknameByUrl();
      getServiceTypeDetails();
    }
  },[nickname,equipmentTypeList])
  return (
    <Box>
      <Text
        color="#1E3264"
        textTransform="uppercase"
        fontSize="22px"
        fontWeight="600"
      >
        Equipment Details
      </Text>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          _equipmentType: Yup.string().required("Equipment Type is required."),
          _equipmentMake: Yup.string().required("Equipment Make is required."),
          _equipmentModel: Yup.string().required(
            "Equipment Model is required."
          ),
          _equipmentAge: Yup.number().required("Equipment Age is required"),
        })}
        onSubmit={(values) => {
          const requiredEquipmentType = equipmentTypeList.find(
            ({ id }) => id === parseInt(values._equipmentType)
          );
          dispatch(setEquipmentMake(parseInt(values._equipmentMake)));
          dispatch(setEquipmentType(requiredEquipmentType));
          dispatch(setEquipmentModel(parseInt(values._equipmentModel)));
          dispatch(setEquipmentAge(parseInt(values._equipmentAge)));
          dispatch(increaseStepperIndex());
        }}
        enableReinitialize = {true}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <form onSubmit={handleSubmit} id="equipment-details-form">
            <Flex wrap="wrap" justifyContent="space-between" rowGap="2em">
              <FormikDropdown
                name="_equipmentNickname"
                label="Equipment Nickname"
                maxWidth="450px"
                value={values._equipmentNickname}
                isRequired={false}
                isDisabled={!equipmentNickNameList}
                onChange={(e) => {
                  const selectedNickname = e.target.value;
                  setFieldValue("_equipmentNickname", selectedNickname);
    
                  const selectedEquipment = equipmentNickNameList.find(
                    (item) => item.NickName === selectedNickname
                  );
    
                  if (selectedEquipment) {
                    setSelectedNickEquipment(selectedEquipment);
                    getSelectedNicknameEquipmentList(values);
                  }
                }}
              >
                {equipmentNickNameList && equipmentNickNameList.length > 0
                  ? equipmentNickNameList.map(({ AssetId, NickName }) => (
                      <option key={uuid()} value={NickName}>
                        {NickName}
                      </option>
                    ))
                  : []}
                </FormikDropdown>
              <FormikDropdown
                name="_equipmentType"
                label="Equipment Type"
                value={values._equipmentType}
                maxWidth="450px"
                isRequired
                isDisabled={!equipmentTypeList}
                onChange={(e) => {
                  setFieldValue("_equipmentType", e.target.value);
                  setFieldValue("_equipmentMake", "");
                  setFieldValue("_equipmentModel", null);
                  setSelectedEquipmentType(e.target.value);
                  setSelectedEquipmentMake(null);
                }}
              >
                {equipmentTypeList && equipmentTypeList.length > 0
                  ? equipmentTypeList.map(({ id, value }) => (
                      <option key={uuid()} value={id}>
                        {value}
                      </option>
                    ))
                  : []}
              </FormikDropdown>
              <FormikDropdown
                name="_equipmentMake"
                label="Equipment Make"
                maxWidth="450px"
                value={values._equipmentMake}
                isRequired
                isDisabled={!equipmentMakeList}
                onChange={(e) => {
                  setFieldValue("_equipmentMake", e.target.value);
                  setFieldValue("_equipmentModel", "");
                  setSelectedEquipmentMake(e.target.value);
                }}
              >
                {equipmentMakeList && equipmentMakeList.length > 0
                  ? equipmentMakeList.map(({ id, value }) => (
                      <option key={uuid()} value={id}>
                        {value}
                      </option>
                    ))
                  : []}
              </FormikDropdown>
              <FormikDropdown
                name="equipmentModel"
                label="Equipment Model"
                maxWidth="450px"
                isRequired
                isDisabled={!equipmentModelList}
                value={values._equipmentModel}
                onChange={(e) => {
                  setFieldValue("_equipmentModel", e.target.value);
                }}
              >
                {equipmentModelList && equipmentModelList.length > 0
                  ? equipmentModelList.map(({ id, value }) => (
                      <option key={uuid()} value={id}>
                        {value}
                      </option>
                    ))
                  : []}
              </FormikDropdown>

              <Field name="_equipmentAge">
                {({ field }) => {
                  return (
                    <RadioParent
                      name={field.name}
                      options={equipmentAgeList}
                      maxWidth="450px"
                      isFormik={true}
                      label="Equipment Age"
                      isRequired={true}
                    />
                  );
                }}
              </Field>
            </Flex>
          </form>
        )}
      </Formik>
    </Box>
  );
};
