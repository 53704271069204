import { VideoContainer } from "../../components/VideoContainer/VideoContainer";
import Banner1 from "../../../assets/banner-images/Banner-1.png";
import Banner2 from "../../../assets/banner-images/Banner-2.png";
import Banner3 from "../../../assets/banner-images/Banner-3.png";
import HightLightSection1Image1 from "../../../assets/highlighted-elements/section-1/image-1.png";
import HightLightSection1Image2 from "../../../assets/highlighted-elements/section-1/image-2.png";
import HightLightSection1Image3 from "../../../assets/highlighted-elements/section-1/image-3.png";
import HightLightSection2Image1 from "../../../assets/highlighted-elements/section-2/image-1.png";
import HightLightSection2Image2 from "../../../assets/highlighted-elements/section-2/image-2.png";
import HightLightSection2Image3 from "../../../assets/highlighted-elements/section-2/image-3.png";
import HightLightSection3Image1 from "../../../assets/highlighted-elements/section-3/image-1.png";
import HightLightSection3Image2 from "../../../assets/highlighted-elements/section-3/image-2.png";
import HightLightSection3Image3 from "../../../assets/highlighted-elements/section-3/image-3.png";
import { IMAGE_CONSTANTS } from "../../constants/imageConstants";
import { Banner } from "../../components/Banner/Banner";
import { Showcase } from "../../components/Showcase/Showcase";
import { WebPageLayout } from "../../Layout/WebpageLayout";
import  React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSelectedCountryThunk } from "../../../webapp/features/global/appConfig/thunk/setSelectedCountryThunk";
import { Helmet } from "react-helmet";
import "../Home/Home.css";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { searchOperatorBlock,ConnectDealerBlock,SerachSkilled, defaultEnglishJson } from "../../../staticData/staticData";
import {
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';

import { MobileResponsiveFooter } from "../../components/MobileResposiveFooter/MobileResponsiveFooter";
import { MobileHeader } from "../../components/MobileHeader/MobileHeader";
import { getAllLanguageJsonThunk } from "../../../webapp/features/global/mtData/thunk/getAllLanguagesThunk";
import { setLanguage } from "../../../webapp/features/global/appConfig/slice/appConfigSlice";
import languageConstant from "../../../constants/languageConstant";
import homesitemap from "./seo/Home.xml";

export const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedCountryThunk("91"));
      dispatch(getAllLanguageJsonThunk("english.json"));
      dispatch(setLanguage(defaultEnglishJson));
      dispatch(setLanguage(defaultEnglishJson))
  }, []);


  return (
    <>
        <WebPageLayout>
          <Helmet>
            <title>
              Operator: Operating heavy equipment, such as bulldozers,
              excavators, and cranes, requires specialized training and
              certification. Operators are responsible for safely maneuvering
              and using these machines for construction, mining, and other
              industrial applications. To become an operator, one typically
              needs to complete a training program and obtain the necessary
              licenses and certifications.
            </title>
            <link
              rel="Operator"
              href="https://equip9.com/login/login-form"
            />
            <title>
              Mechanic: Heavy equipment mechanics are skilled professionals who
              maintain, repair, and troubleshoot heavy machinery. They play a
              crucial role in ensuring that equipment functions optimally and
              safely. Becoming a heavy equipment mechanic often involves
              vocational training and apprenticeships.
            </title>
            <link
              rel="Mechanic"
              href="https://equip9.com/login/login-form"
            />
            <title>
              Heavy Equipment Rental: Many companies specialize in renting out
              heavy equipment to construction, mining, and other industries.
              They offer a range of machinery on a short-term or long-term
              basis, allowing businesses to access the equipment they need
              without the upfront costs of purchasing.
            </title>
            <link
              rel="Heavy Equipment"
              href="https://equip9.com/login/login-form"
            />
            <title>
              Used Heavy Equipment Dealership: Some businesses focus on buying,
              refurbishing, and selling used heavy equipment. These dealerships
              offer cost-effective alternatives to buying new machinery.
            </title>
            <link
              rel="Used Heavy Equipment Dealership"
              href="https://equip9.com/login/login-form"
            />
            <title>
              Spare Parts for Heavy Equipment: Maintaining heavy equipment often
              requires replacing worn or damaged parts. Companies that
              specialize in heavy equipment spare parts supply components and
              accessories needed for repairs and maintenance.
            </title>
            <link
              rel="Spare Parts for Heavy Equipmentr"
              href="https://equip9.com/login/login-form"
            />
            <title>
              E-Learning for Heavy Equipment Operation: E-learning platforms and
              training programs are available to teach individuals how to
              operate heavy equipment safely and effectively. These courses
              typically cover equipment operation techniques, safety protocols,
              and certification preparation.
            </title>
            <link
              rel="E-Learning for Heavy Equipment Operation"
              href="https://equip9.com/login/login-form"
            />
            <title>
              Scrapping of Heavy Equipment: When heavy equipment reaches the end
              of its usable life, it may need to be scrapped or recycled. This
              involves dismantling the machinery, salvaging valuable components,
              and disposing of hazardous materials in an environmentally
              responsible manner.
            </title>
            <link
              rel="Scrapping of Heavy Equipment"
              href="https://equip9.com/login/login-form"
            />
            <title>
              Finances for Heavy Equipment: Financing heavy equipment purchases
              is a common practice in the industry. Businesses often seek loans
              or leasing arrangements to acquire expensive machinery. Financial
              institutions and specialized lenders offer various financing
              options tailored to the needs of heavy equipment buyers.
            </title>
            <link
              rel="Finances for Heavy Equipmen"
              href="https://equip9.com/login/login-form"
            />
            <tile>
              Dealership of Heavy Equipment: Dealerships specializing in heavy
              equipment sales provide a wide range of new and used machinery
              options to businesses and individuals. They typically have
              relationships with manufacturers and may offer maintenance and
              service support for the equipment they sell.
            </tile>
            <link
              rel="Dealership of Heavy Equipment"
              href="https://equip9.com/login/login-form"
            />
          </Helmet>
          <Helmet>
            <title>
              One Industry | One App: Operators, Rentals,Mechanics, Spare Parts
              and More
            </title>
            <meta
              name="description"
              content="Our all-in-one platform connects operators, rentals, mechanics, and more in a single app. Explore used equipment, e-learning, and spare parts with ease."
            />
          </Helmet>
          <Helmet>
            <title>
              Aggregating the heavy equipment industry. Creating a comprehensive
              platform that aggregates various aspects of the heavy equipment
              industry can provide significant value to operators, mechanics,
              rental businesses, dealerships, finaniers, and scrappers.
            </title>
          </Helmet>
          <Helmet>
            <title>EQUIP9: Your Ultimate Heavy Equipment Lead Generator</title>
            <meta name="keywords" content="heavy equipment"/>
            <meta name="description" content="Equip9: Connect with excavator operators, JCB operators, 
            JCB rental Owners, Daily Equipment Rental, And JCB resellers of heavy equipment. " />
            <h1>Find skilled professionals to operate and maintain your heavy equipment.</h1>
            <h2>Operator, mechanic, rental service, dealer, or involved in any facet of the 
              heavy equipment sector, EQUIP9 is your partner in growth</h2>
            <h3>Discover a Comprehensive Collection of Heavy Equipment Materials 
              and Scraps in a Single Location</h3>

            <link rel="sitemap" type="application/xml" title="Sitemap" href={homesitemap} />
              

          </Helmet>
          <VideoContainer
            height={"600px"}
            width={"100vw"}
            videoUrl={`${process.env.REACT_APP_CDN_STATIC_URL}${IMAGE_CONSTANTS.HERO_SECTION_VIDEO_LINK}`}
            type="video/mp4"
            heading="ONE INDUSTRY | ONE APP"
            subHeading="Operators | Rentals | Mechanics | Used Equipment | E-Learning | Dealers | Financiers | Spare Parts | Scrapping"
            message="Join the Heavy Equipment Revolution Today"
          />

          {/* SECTION */}
          <div
            style={{
              backgroundColor: "var(--dark-50)",
              color: "var(--light-10)",
            }}
          >
            <Banner
              headingText="The Heavy Equipment"
              highlightedText="Industry"
              description="Operators | Rentals | Mechanics | Used Equipment | E-Learning | Dealers | Financiers | Spare Parts | Scrapping"
              bannerImage={Banner1}
            />

            <Showcase
              heading="SEARCH YOUR NEXT OPERATOR"
              subheading="Certified Workforce Management System"
              description="Search Operator | Post Free Job | Verified Documents"
              mainImage={`${process.env.REACT_APP_CDN_STATIC_URL}${IMAGE_CONSTANTS.OPERATORS_MARKETPLACE_SCREEN}`}
              highlightAreaArray={[
                {
                  image: HightLightSection1Image1,
                  stats: "20000+",
                  category: "Operators Registered",
                },
                {
                  image: HightLightSection1Image2,
                  stats: "27+",
                  category: "Equipment Type",
                },
                {
                  image: HightLightSection1Image3,
                  stats: "10",
                  category: "Free Search",
                },
              ]}
            />
          </div>
          {/* SECTION */}
          <div
            style={{
              backgroundColor: "var(--dark-50)",
              color: "var(--light-10)",
            }}
          >
            <Banner
              headingText="AGGREGATED DIGITAL"
              highlightedText="MARKETPLACE"
              description="AI Powered | Mobile | Web | Global Connect"
              bannerImage={Banner2}
            />
            <Showcase
              heading="CONNECT WITH  OWNERS, DEALERS, & OEMs"
              subheading="One App for all your Needs"
              description="Fastest Connect | Verified Equipment Documents | Qualified Leads"
              mainImage={`${process.env.REACT_APP_CDN_STATIC_URL}${IMAGE_CONSTANTS.MARKETPLACE_SCREEN}`}
              highlightAreaArray={[
                {
                  image: HightLightSection2Image1,
                  stats: "2000+",
                  category: "Listed Equipments",
                },
                {
                  image: HightLightSection2Image2,
                  stats: "190",
                  category: "Cities",
                },
                {
                  image: HightLightSection2Image3,
                  stats: "5000+",
                  category: "Business Owners",
                },
              ]}
            />
          </div>
          {/* SECTION */}
          <div
            style={{
              backgroundColor: "var(--dark-50)",
              color: "var(--light-10)",
            }}
          >
            {/* BANNER */}

            <Banner
              headingText="ZERO BROKERAGE"
              highlightedText="ZERO COMMISSION"
              description="Connect With Verified Owners  | Free Equipment Listing | Create Digital Store"
              bannerImage={Banner3}
            />
            <Showcase
              heading="SEARCH SKILLED MECHANICS"
              subheading="Lower the Downtime of your Equipment"
              description="Reliable Maintenance Partner | Quick Connect | Doorstep Service"
              mainImage={`${process.env.REACT_APP_CDN_STATIC_URL}${IMAGE_CONSTANTS.MECHANICS_MARKETPLACE_SCREEN}`}
              highlightAreaArray={[
                {
                  image: HightLightSection3Image1,
                  stats: "3780+",
                  category: "Mechanics Registered",
                },
                {
                  image: HightLightSection3Image2,
                  stats: "50+",
                  category: "Maintenance Agencies",
                },
                {
                  image: HightLightSection3Image3,
                  stats: "10",
                  category: "Free Search",
                },
              ]}
            />
          </div>
        </WebPageLayout>
    </>
  );
};
