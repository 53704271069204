import { ReactSVG } from "react-svg";
import { boxShadow } from "../../theme/themeConstants/themeConstants";

export const OverallRatingIcon = ({color="#1E3264", boxShadow,ratings,padding, maxHeight = "30px",textcolour="#fff"}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        gap: "0.25em",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: color,
        maxHeight: maxHeight,
        borderRadius: "4px",
        padding:padding,
        boxShadow:boxShadow
        

      }}
    >
      <ReactSVG
        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/star.svg`}
      />
      <p style={{ color:textcolour, fontWeight: 500 }}>{ratings ? ratings : 0}</p>
    </div>
  );
};
