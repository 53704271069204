import { createSlice } from "@reduxjs/toolkit";
import { premiumTypeThunk } from "../thunks/getPremiumTypeThunk";
import { getPremiumDetailsThunk } from "../thunks/getPremiumDetailsThunk";
import {
  getSessionStorageItems,
  setSessionStorageItems,
} from "../../../helpers/sessionStorage/sessionStorage";
import { STORAGE_CONSTANTS } from "../../../../constants/storageConstants";

export const buyPremiumSlice = createSlice({
  name: "purchasePremium",
  initialState: {
    premiumTypes: [],
    selectedPremiumTypeId: getSessionStorageItems(
      STORAGE_CONSTANTS.SELECTED_PREMIUM_TYPE
    )
      ? parseInt(
          getSessionStorageItems(STORAGE_CONSTANTS.SELECTED_PREMIUM_TYPE)
        )
      : NaN,
    confirmedPremiumTypeId: getSessionStorageItems(
      STORAGE_CONSTANTS.SELECTED_PREMIUM_TYPE
    )
      ? parseInt(getSessionStorageItems(STORAGE_CONSTANTS.POSTED_PREMIUM_TYPE))
      : NaN,
    selectedPremiumCost: NaN,
    selectedPremiumDiscount: NaN,
    selectedPremiumDiscountPrecent: NaN,
    selectedPremiumBenefitsList: [],
    selectedPremiumPrice: NaN,
    selectedPremiumMonthlyCost: NaN,
    premiumTransactionDetailId: getSessionStorageItems(
      STORAGE_CONSTANTS.PREMIUM_TRANSACTION_DETAILS_ID
    )
      ? parseInt(
          getSessionStorageItems(
            STORAGE_CONSTANTS.PREMIUM_TRANSACTION_DETAILS_ID
          )
        )
      : null,
    premiumOrderId: getSessionStorageItems(STORAGE_CONSTANTS.PREMIUM_ORDER_ID)
      ? getSessionStorageItems(STORAGE_CONSTANTS.PREMIUM_ORDER_ID)
      : null,
    isPremiumTypesLoading: false,
    isPremiumBenefitsLoading: false,
  },
  reducers: {
    setSelectedPremiumTypeId: (state, { payload }) => {
      state.selectedPremiumTypeId = payload;
      setSessionStorageItems(STORAGE_CONSTANTS.SELECTED_PREMIUM_TYPE, payload);
    },
    setSelectedPremiumCost: (state, { payload }) => {
      state.selectedPremiumCost = payload;
    },
    setSelectedPremiumDiscount: (state, { payload }) => {
      state.selectedPremiumDiscount = payload;
    },
    setSelectedPremiumDiscountPercentage: (state, { payload }) => {
      state.selectedPremiumDiscountPrecent = payload;
    },
    setSelectedPremiumBenefitsList: (state, { payload }) => {
      state.selectedPremiumBenefitsList = payload;
    },
    setSelectedPremiumPrice: (state, { payload }) => {
      state.selectedPremiumPrice = payload;
    },
    setSelectedPremiumMonthlyCost: (state, { payload }) => {
      state.selectedPremiumMonthlyCost = payload;
    },
    setConfirmedPremiumTypeId: (state, { payload }) => {
      state.confirmedPremiumTypeId = payload;
      setSessionStorageItems(STORAGE_CONSTANTS.POSTED_PREMIUM_TYPE, payload);
    },
    setPremiumTransactionDetailId: (state, { payload }) => {
      state.premiumTransactionDetailId = payload;
      setSessionStorageItems(
        STORAGE_CONSTANTS.PREMIUM_TRANSACTION_DETAILS_ID,
        payload
      );
    },
    setPremiumOrderId: (state, { payload }) => {
      state.premiumOrderId = payload;
      setSessionStorageItems(STORAGE_CONSTANTS.PREMIUM_ORDER_ID, payload);
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(premiumTypeThunk.pending, (state) => {
        state.premiumTypes = [];
        state.isPremiumTypesLoading = true;
        state.isPremiumBenefitsLoading = true;
      })
      .addCase(premiumTypeThunk.fulfilled, (state, { payload }) => {
        state.premiumTypes = payload;
        state.isPremiumTypesLoading = false;
        if (payload && payload.length > 0) {
          const defaultPlan = payload.find((plan) => plan.IsDefault);
          if (isNaN(state.selectedPremiumTypeId)) {
            state.selectedPremiumTypeId = defaultPlan.PaymentPlanId;
          }
        }
      })
      .addCase(getPremiumDetailsThunk.pending, (state) => {
        state.isPremiumBenefitsLoading = true;
        state.selectedPremiumCost = NaN;
        state.selectedPremiumDiscount = NaN;
        state.selectedPremiumMonthlyCost = NaN;
        state.selectedPremiumDiscountPrecent = NaN;
        state.selectedPremiumBenefitsList = [];
        state.selectedPremiumPrice = NaN;
      })
      .addCase(getPremiumDetailsThunk.fulfilled, (state, { payload }) => {
        state.selectedPremiumCost = payload.TotalCost;
        state.selectedPremiumDiscount = payload.Discount;
        state.selectedPremiumDiscountPrecent = payload.DiscountPercentage;
        state.selectedPremiumBenefitsList = payload.E9PremiumBenefitsList;
        state.selectedPremiumPrice = payload.TotalPrice;
        state.selectedPremiumMonthlyCost = payload.MonthlyCost;
        state.isPremiumBenefitsLoading = false;
      })
      .addCase(getPremiumDetailsThunk.rejected, (state) => {
        state.selectedPremiumCost = NaN;
        state.selectedPremiumDiscount = NaN;
        state.selectedPremiumDiscountPrecent = NaN;
        state.selectedPremiumBenefitsList = [];
        state.selectedPremiumPrice = NaN;
        state.selectedPremiumMonthlyCost = NaN;
        state.isPremiumBenefitsLoading = false;
      });
  },
});

export const {
  setSelectedPremiumTypeId,
  setSelectedPremiumBenefitsList,
  setSelectedPremiumCost,
  setSelectedPremiumDiscount,
  setSelectedPremiumDiscountPercentage,
  setSelectedPremiumMonthlyCost,
  setSelectedPremiumPrice,
  setConfirmedPremiumTypeId,
  setPremiumOrderId,
  setPremiumTransactionDetailId,

} = buyPremiumSlice.actions;

export default buyPremiumSlice.reducer;
