import { createAsyncThunk } from "@reduxjs/toolkit";
import { sendForgotPasswordOTP } from "../../../services/forgetPassward/forgetPassward";

export const sendForgetPasswordThunk = createAsyncThunk("post/generateForgetPasswordOtp", async(body, {rejectWithValue, getState}) => {
   try{ 
    const data = await sendForgotPasswordOTP(body);

    if(data.Status){
        return {otp: data.Data.OTPValue, accountList: data.Data.AccountList }
    }
    else{
        return rejectWithValue(data.Message.FailMessage)
    }
}
catch(error){
    return rejectWithValue(error.FailMessage)
}
})


