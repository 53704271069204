import { getSessionStorageItems } from "../../../helpers/sessionStorage/sessionStorage";

export const mpSearchInitialState = {
    serviceDetails : getSessionStorageItems("MAINTENANCE_SEARCH_SERVICE_DETAILS") || null,
    jobType: getSessionStorageItems("MAINTENANCE_SEARCH_JOB_TYPE") || null,
    maintenenaceType: getSessionStorageItems("MAINTENANCE_SEARCH_MAINTENANCE_TYPE") || null,
    repairType: getSessionStorageItems("MAINTENANCE_SEARCH_REPAIR_TYPE") || null,
    equipmentType: getSessionStorageItems("MAINTENANCE_SEARCH_EQUIPMENT_TYPE") || null,
    equipmentMake: getSessionStorageItems("MAINTENANCE_SEARCH_EQUIPMENT_MAKE") || null,
    equipmentModel: getSessionStorageItems("MAINTENANCE_SEARCH_EQUIPMENT_MODEL") || null,
    equipmentAge: getSessionStorageItems("MAINTENANCE_SEARCH_EQUIPMENT_AGE") || null,
    // FullTime Maintenenace JOB 
    jobTitle: getSessionStorageItems("MAINTENANCE_SEARCH_JOB_TITLE") || null,
    salaryType: getSessionStorageItems("MAINTENANCE_SEARCH_SALARY_TYPE") || null,
    inHandSalary: getSessionStorageItems("MAINTENANCE_SEARCH_INHAND_SALARY") || null,
    overtimePay: getSessionStorageItems("MAINTENANCE_SEARCH_OVERTIME_PAY") || null,
    foodBenefits: getSessionStorageItems("MAINTENANCE_SEARCH_FOOD_BENEFITS") || null,
    accomodationProvided: getSessionStorageItems("MAINTENANCE_SEARCH_ACCOMODATION_PROVIDED") || null,
    stepperIndex: getSessionStorageItems("MAINTENANCE_SEARCH_STEPPER_INDEX") ? parseInt(getSessionStorageItems("MAINTENANCE_SEARCH_STEPPER_INDEX")) : 0,
    stepperRoute: [{ name: "Step 1" }, { name: "Step 2" }],
    equipmentNickname : getSessionStorageItems("MAINTENANCE_SEARCH_EQ_NICKNAME") || "",
    isLoading:false
}