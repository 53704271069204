import { createSlice } from "@reduxjs/toolkit";
import { getSubscriptionTypeThunk } from "../thunks/getSubscriptionTypeThunk";
import {
  setLocalStorageItems,
} from "../../../helpers/localStorage/localStorage";
import { createCompanySubscriptionTransactionThunk } from "../thunks/createCompanySubscriptionTransactionThunk";
import { subscriptionInitialState } from "../constants/initialState/subscriptionInitialState";
import { getCompanySubscriptionDetails } from "../thunks/getCompanySubscriptionDetails";
import { getCompanySubscriptionTransactionDetailsThunk } from "../thunks/getCompanySubscriptionTransactionDetailsThunk";
import { getUserTaxDetailsThunk } from "../thunks/getUserTaxDetailsThunks";
import { createUserSubscriptionTransactionDetails } from "../thunks/createUserSubscriptionTransactionDetails";
import { getUserSubscriptionDetailsThunk } from "../thunks/getUserSubscriptionDetails";
import { getUserSubscriptionTransactionDetailsThunk } from "../thunks/getUserSubscriptionTransactionDetails";
import { setSessionStorageItems } from "../../../helpers/sessionStorage/sessionStorage";

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: subscriptionInitialState,
  reducers: {
    setSubscriptionTableData: (state, { payload }) => {
      state.subscriptionTableData = payload;
      setLocalStorageItems("SUBSCRIPTION_SERVICES", payload);
    },
    setSelectedSubcriptionType: (state, { payload }) => {
      state.selectedSubscriptionType = payload;
      setLocalStorageItems("SUBSCRIPTION_TYPE", payload);
    },
    setTranactionState: (state, { payload }) => {
      state.transactionState = payload;
      setLocalStorageItems(
        "SUBSCRIPTION_TRANSACTION_STATE",
        state.transactionState
      );
    },
    updateLeadsCount: (state, { payload }) => {
      state.leadsCount[payload.name] = payload.quantitySelected;
      // console.log("leadCount update", state.leadsCount);
      localStorage.setItem("LEADS_COUNT", JSON.stringify(state.leadsCount));
    },

    setSubscriptionTableData: (state, { payload }) => {
      state.subscriptionTableData = payload;
    },

    addItemToSubscriptionTable: (state, { payload }) => {
      const sameSubscriptionItem = state.subscriptionTableData.filter(
        (item) => payload.name === item.name
      );
      if (!sameSubscriptionItem) {
        state.subscriptionTableData.push(payload);
      }
    },
    removeItemFromSubscriptionTable: (state, { payload }) => {
      state.subscriptionTableData = state.subscriptionTableData.filter(
        (item) => payload.name !== item.name
      );
    },
    editSubscriptionTypeCount: (state, { payload }) => {
      const indexOfData = state.subscriptionTableData.findIndex(
        (item) => item.ServiceTypeValue === payload.name
      );
      if (indexOfData >= 0) {
        state.subscriptionTableData[indexOfData].quantitySelected =
          payload.quantitySelected;
      }
      setLocalStorageItems(
        "SUBSCRIPTION_SERVICES",
        state.subscriptionTableData
      );
    },
    editSubscriptionTypeStatus: (state, { payload }) => {
      const indexOfData = state.subscriptionTableData.findIndex(
        (item) => item.ServiceTypeValue === payload.name
      );
      if (indexOfData >= 0) {
        state.subscriptionTableData[indexOfData].isSelected =
          payload.isSelected;
      }
      setLocalStorageItems(
        "SUBSCRIPTION_SERVICES",
        state.subscriptionTableData
      );
    },
    setLastE9TransactionId: (state, { payload }) => {
      state.lastE9TransactionId = payload;
      setLocalStorageItems("LAST_E9_TRANSACTION_ID", payload);
    },
    setUserSubsTransOfferDetailId : (state, {payload}) => {
      state.userSubsTransOfferDetailId = payload;
      setSessionStorageItems("UserSubsTransOfferDetailId", payload)
    },
    resetCart: (state) => {
      state.subscriptionTableData = [];
      state.orderId = null;
      setLocalStorageItems("SUBSCRIPTION_ORDER_ID", null)
      setLocalStorageItems("SUBSCRIPTION_SERVICES", null);
    },
    setUpdatedNetPayable: (state,{payload}) =>{
      state.updatedNetPayble = payload;
    },
    setUpdatedGstTax: (state,{payload}) =>{
      state.updatedGstTax = payload;
    },
    setUpdatedTotalAmount: (state,{payload}) =>{
      state.updatedTotalAmount = payload;
    },
    setAddressModal : (state,{payload}) => {
      state.addressModal = payload
    },
    resetCheckoutDetails: (state) => {
      state.checkoutSubsctiptionDetails = null;
      state.checkoutTransactionDetails = null;
      state.lastE9TransactionId = null;
      state.checkoutTaxDetails = null;
      state.selectedSubscriptionType = null;
      state.transactionState = null;
      setLocalStorageItems("SUBSCRIPTION_TRANSACTION_STATE", null);
      setLocalStorageItems("SUBSCRIPTION_TYPE", null);
      setLocalStorageItems("LAST_E9_TRANSACTION_ID", null);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptionTypeThunk.pending, (state) => {
        state.isLoading = true;
        state.isError = null;
        state.errorMessage = null;
      })
      .addCase(getSubscriptionTypeThunk.fulfilled, (state, { payload }) => {
        state.subscriptionTableData = payload;
        setLocalStorageItems("SUBSCRIPTION_SERVICES", payload);
        state.isLoading = false;
        state.isError = null;
        state.errorMessage = null;
      })
      .addCase(createCompanySubscriptionTransactionThunk.pending, (state) => {
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(
        createCompanySubscriptionTransactionThunk.fulfilled,
        (state, { payload }) => {
          state.isLoading = false;
          state.isError = false;
          state.orderId = payload;
          setLocalStorageItems("SUBSCRIPTION_ORDER_ID", payload);
        }
      )
      .addCase(createUserSubscriptionTransactionDetails.pending, (state) => {
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(
        createUserSubscriptionTransactionDetails.fulfilled,
        (state, { payload }) => {
          state.isLoading = false;
          state.isError = false;
          state.orderId = payload;
          setLocalStorageItems("SUBSCRIPTION_ORDER_ID", payload);
        }
      )
      .addCase(
        getCompanySubscriptionDetails.fulfilled,
        (state, { payload }) => {
          state.checkoutSubsctiptionDetails = payload;
        }
      )
      .addCase(
        getCompanySubscriptionTransactionDetailsThunk.fulfilled,
        (state, { payload }) => {
          state.checkoutTransactionDetails = payload;
        }
      )
      .addCase(
        getUserSubscriptionDetailsThunk.fulfilled,
        (state, { payload }) => {
          state.checkoutSubsctiptionDetails = payload;
        }
      )
      .addCase(
        getUserSubscriptionTransactionDetailsThunk.fulfilled,
        (state, { payload }) => {
          state.checkoutTransactionDetails = payload;
        }
        
      )
      .addCase(getUserTaxDetailsThunk.fulfilled, (state, { payload }) => {
        state.checkoutTaxDetails = payload;
      });
  },
});

export const {
  setSelectedSubcriptionType,
  setTranactionState,
  updateLeadsCount,
  addItemToSubscriptionTable,
  removeItemFromSubscriptionTable,
  editSubscriptionTypeCount,
  editSubscriptionTypeStatus,
  setLastE9TransactionId,
  resetCart,
  resetCheckoutDetails,
  setUserSubsTransOfferDetailId,
  setUpdatedNetPayable,
  setUpdatedGstTax,
  setUpdatedTotalAmount,
  setAddressModal
} = subscriptionSlice.actions;
