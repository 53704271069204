import axios from "axios";
import { API_CONSTANTS } from "../constants/apiConstants";
import { fetchHandler } from "../index";
import { convertToFormData } from "../webapp/helpers/dataConversion/convertToFormData";

export const countrylist = async () => {
  try {
    const response = await fetchHandler(`/Country`,{},{},{},API_CONSTANTS.HTTP_GET,
    { isAuthRequired: false })
    return response;
  }
  catch (error) {
    throw error.response;
  }
}

export const getYearofExpirence = async () => {
  try {
    const response =await fetchHandler(`/GetYearOfExperience`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true})
    return response;
  }
  catch (error) {
    throw error.response;
  }
}

export const city = async (StateId) => {
  try {
    const response = await fetchHandler(`/City?StateId=${StateId}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:false})

    return response;

  }
  catch (error) {
    throw error.response;
  }
}
// State List
export const StateCountryId = async ( CountryId) => {
  try {
    const response = await fetchHandler(`/State?CountryId=${CountryId}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:false});
    return response;
  }
  catch (error) {
    throw error.response;
  }
}

export const PersonalDetails = async (payload) => {
  try {
    const response = await fetchHandler(
      `${API_CONSTANTS.UPDATE_PERSONAL_DETAIL}`,{},{},payload,API_CONSTANTS.HTTP_PUT,{isAuthRequired:true});
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const getReferredType = async () => {
  try {
    const response = await fetchHandler(
      `${API_CONSTANTS.GET_REFERRED_TYPE}`);
    return response;
  } catch (error) {
    throw error.response;
  }
}

export const userUploadIndentityDocument = async (payload) => {
    try {
      
      const response = await fetchHandler(
        `/UploadUserIdentityDocument`,{},{},payload,API_CONSTANTS.HTT_POST);
      return response;
    } catch (error) {
      throw error.response;
    }
  };

export const GetAllUserRolesList = async () => {
  try {
    const response = await fetchHandler(
      `/GetAllUserRolesList`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const getVerificationDocumentType = async () => {
  try {
    const response = await fetchHandler(
      `/GetVerificationDocumentType`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const getUploadedVerificationDocumentList = async () => {
  try {
    const response = await fetchHandler(`/GetUploadedVerificationDocumentList`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});


    return response;


  }
  catch (error) {
    throw error.response;
  }

};

export const getUpdateE9UserRole = async (payload, token) => {
  try {
    const response = await fetchHandler(`/UpdateE9UserRole`,{},{},payload,API_CONSTANTS.HTTP_PUT,{isAuthRequired:true});

    return response;
  }
  catch (error) {
    throw error.response;
  }
}


export const deleteVerificationDocuments = async (UserVerificationDocumentId) => {
  try {
    const response = await fetchHandler(`/DeleteVerificationDocument/${UserVerificationDocumentId}`,{},{},{},API_CONSTANTS.HTTP_DELETE);
    return response;

  }
  catch (error) {
    throw error.response;
  }
}

// Get All Equipment 
export const getAllEquipmentTypes = async () => {
  try {
    const response = await fetchHandler(`/${API_CONSTANTS.GET_ALL_EQUIPMENTS_TYPE}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});

    return response;
  }
  catch (error) {
    throw error.response;
  }

}


export const getIndustryMake = async (EquipmentTypeId) => {
  try {
    const response = await fetchHandler(`/Make/${EquipmentTypeId}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (error) {
    throw error.response;
  }

}


export const getAllIndustryEquipmentTypes = async (EquipmentTypeId) => {
  try {
    const response = await fetchHandler(`/GetAllIndustry?EquipmentTypeId=${EquipmentTypeId}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (error) {
    throw error.response;
  }
}

// get country type



// get Eduction type

export const educationType = async () => {
  try {
    const response = await fetchHandler(`/EducationType`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});

    return response;

  }
  catch (error) {
    throw error.response;
  }
}



export const getEducationalDetail = async () => {
  try {
    const response = await fetchHandler(`/GetEducationalDocument`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});

    return response;

  }
  catch (error) {
    throw error.response;
  }
}


export const updateEducationalDocument = async (payload) => {
  try {
    const body = convertToFormData(payload)
    const response = await fetchHandler(`/UpdateEducationalDocument`,{},{},body,API_CONSTANTS.HTT_POST,{isAuthRequired:true});

    return response;

  }
  catch (error) {
    throw error.response;

  }
}


export const deleteEducationalDocument = async (EducationalDocumentId) => {
  try {
    const response = await fetchHandler(`/DeleteEducationalDocument/${EducationalDocumentId}`,{},{},{},API_CONSTANTS.HTTP_DELETE);

    return response;
  } catch (error) {
    console.error(error);
    throw error.response;
  }
}

export const GetAllUserEquipments = async () => {
  try {
    const response = await fetchHandler(`/GetAllUserEquipments`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});

    return response;
  }
  catch (error) {
    throw error.response;
  }

}

export const editEquipment = async (UserEquipmentId) => {
  try {
    const response = await fetchHandler(`/EditEquipment/${UserEquipmentId}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});

    return response;
  }
  catch (error) {
    throw error.response;
  }
}


export const deleteEquipment = async ( UserEquipmentId) => {
  try {
    const response = await fetchHandler(`/DeleteUserEquipment/${UserEquipmentId}`,{},{},{},API_CONSTANTS.HTTP_DELETE,{isAuthRequired:true});
    return response;

  }
  catch (error) {
    throw error.response;
  }
}

// get License Id list
export const getLicenseTypeId = async (token) => {
  try {
    const response = await fetchHandler(`/GetLicenseTypeList`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});

    return response
  }
  catch (error) {
    throw error.response;
  }
}

export const submitLicenseDetail = async (payload) => {
  try {
    const body = convertToFormData(payload)
    const response = await fetchHandler(`/AddEquipmentLicense`,{},{},body,API_CONSTANTS.HTT_POST);
    return response;
  }
  catch (error) {
    throw error.response;
  }
}


export const getLicenseDetails = async () => {
  try {
    const response = await fetchHandler(`/GetEquipmentLicenceDetails`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});

    return response;

  }
  catch (error) {
    throw error.response;
  }
}

export const deleteLicenseDetail = async (EquipmentLicenseId) => {
  try {
    const response = await fetchHandler(`/DeleteEquipmentLicense/${EquipmentLicenseId}`,{},{},{},API_CONSTANTS.HTTP_DELETE);
    return response;
  }
  catch (error) {
    throw error.response;
  }
}

// add  Add Exporece 

export const addExpirence = async (payload) => {
  try {
    const body  = convertToFormData(payload);
    const response = await fetchHandler(`/AddExperienceDetails`,{},{},body,API_CONSTANTS.HTT_POST);

    return response;

  }
  catch (error) {
    throw error.response;
  }
}


export const getWorkDetail = async () => {
  try {
    const response = await fetchHandler(`/GetAllExperienceDetails/10/0`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});

    return response

  }
  catch (error) {
    throw error.response;
  }
}

export const deleteworkdetail = async ( WorkCompanyExperienceId) => {
  try {
    const response = await fetchHandler(`/DeleteExperienceDetails/${WorkCompanyExperienceId}`,{},{},{},API_CONSTANTS.HTTP_DELETE);

    return response;
  }
  catch (error) {
    throw error.response;
  }
}


export const EditEquipment = async ( EquipmentTypeId) => {
  try {
    const response = await fetchHandler(`/EditEquipment/${EquipmentTypeId}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});

    return response;

  }
  catch (error) {
    throw error.response;
  }
}


export const AddorUpdateUserEquipment = async (payload) => {
  try {
    const body = convertToFormData(payload);
    const response = await fetchHandler(`/AddOrUpdateUserEquipmentLicenseDetails`,{},{},body,API_CONSTANTS.HTT_POST);

    return response;
  }
  catch (error) {
    throw error.response;
  }
}

export const getUserEquipmentLicenceAddress = async () => {
  try {
    const response = await fetchHandler(`/GetUserEquipmentLicenceAddress`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (error) {
    throw error.response;
  }
}


export const editExpirence = async ( WorkCompanyExperienceId) => {
  try {
    const response = await fetchHandler(`/EditExperienceDetails/${WorkCompanyExperienceId}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});

    return response;
  }
  catch (error) {
    throw error.response;
  }
}


export const updateExpirenceDetail = async ( payload) => {
  try {
    const body = convertToFormData(payload)
    const response = await fetchHandler(`/UpdateExperienceDetails`,{},{},body,API_CONSTANTS.HTT_POST);

    return response;
  }
  catch (error) {
    throw error.response;
  }
}


export const companyDesignation = async () => {
  try {
    const response = await fetchHandler(`/GetCompanyDesignationType`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});

    return response;
  }
  catch (error) {
    throw error.response;
  }
}


export const createInviteTeam = async (payload) => {
  try {
    const body = convertToFormData(payload)
    const respose = await fetchHandler(`/CreateInviteTeamMemberDetails`,{},{},body,API_CONSTANTS.HTT_POST);

    return respose;

  }
  catch (error) {
    throw error.response;
  }
}


export const getSubscriptionAndFreeServiceList = async () => {
  try {
    const response = await fetchHandler(`/GetSubscriptionAndFreeServiceList`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (err) {
    throw err.response;
  }

}


export const getCompanyTeamMembersList = async (token) => {
  try {
    const response = await fetchHandler(`/GetCompanyTeamMembersList`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (err) {
    throw err.response;
  }
}




// update Team Member Verification Staus
export const updateTeamVerificationStatus = async ( payload) => {
  try {
    const response = await fetchHandler(`/UpdateCompanyTeamMemberVerificationStatus`,{},{},payload,API_CONSTANTS.HTTP_PUT);
    return response;
  }
  catch (err) {
    throw err.response;
  }
}

// UpdateCompanyTeamMemberStatus

export const updateCompanyTeamMemberStatus = async (payload) => {

  try {
    const response = await fetchHandler(`/UpdateCompanyTeamMemberStatus`,{},{},payload,API_CONSTANTS.HTTP_PUT);
    return response;
  }
  catch (error) {
    throw error.response;
  }

}


export const getAlltypeofEquipmentTy = async () => {
  try {
    const response = await fetchHandler(`/GetAllEquipmentType`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (err) {
    throw err.response;
  }

}

export const getIndustryMaker = async ( EquipmentTypeId) => {
  try {
    const response = await fetchHandler(`/Make/${EquipmentTypeId}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (err) {
    throw err.response;
  }
}

export const getAllEquipmentTypeswithEquipId = async ( EquipmentTypeId) => {
  try {
    const response = await fetchHandler(`/GetAllIndustry?EquipmentTypeId=${EquipmentTypeId}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (err) {
    throw err.response;
  }
}


export const updateProfilePicture = async (token, payload) => {
  try {
    const body = convertToFormData(payload)
    const response = await fetchHandler(`/UploadUserProfilePicture`,{},{},body,API_CONSTANTS.HTT_POST);
    return response;
  }
  catch (err) {
    throw err.response;
  }
}


export const savePersonalDetail = async ( payLoad) => {
  try {
    
    const body = convertToFormData(payLoad)
    const response = await fetchHandler(`/SaveCompanyPersonalDetails`,{},{},body,API_CONSTANTS.HTT_POST);
    return response;
  }
  catch (err) {
    throw err.response;
  }

}

export const createUserTypeRole = async ( payload) => {
  try {
    const body = convertToFormData(payload)
    const response = await axios.postForm(`/CreateE9UserRole`,{},{},body,API_CONSTANTS.HTT_POST);
    return response;
  }
  catch (err) {
    throw err.response;
  }
}


export const jobDescriptionUpcoming = async (JobId) => {
  try {
    const response = await fetchHandler(`/GetOPMPUpcomingJobDetailsForPartnerByJobId/${JobId}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});

    return response
  }
  catch (error) {
    throw error.response;
  }
}


export const MPCancelledQuickJobList = async () => {
  try {
    const response = await fetchHandler(`/GetQuickMaintenanceCancelledJobList/3/0`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});

    return response;
  }
  catch (error) {
    throw error.response
  }
}


// GetQuickMaintenanceCancelledJobList/Limit/Offset
// GetOperatorCancelledJobList/Limit/Offset


// Dealership MarketPlace


export const GetE9DealershipMpConnectStatusWithTax = async (taxNuber, mpGuid, token) => {
  try {
    const response = await fetchHandler(
      `/${API_CONSTANTS.GET_E9_DEALERSHIP_MP_CONNECT_STATUS}?TaxNumber=${taxNuber}&DealershipMpGuid=${mpGuid}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  } catch (error) {
    throw error.response;
  }
};

// materials marketplace

export const PostMaterialContactDetails = async (payload, token) => {
  try {
    const response = await fetchHandler(
      `/${API_CONSTANTS.POST_MATERIAL_CONTACT_DETAILS}`,{},{},{},API_CONSTANTS.HTT_POST);
    return response;
  } catch (error) {
    throw error.response;

  }

}

export const GetResponseTypeDetails = async () => {
  try {
    const response = await fetchHandler(`/${API_CONSTANTS.GET_RESPONSE_TYPE_DETAILS}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response
  } catch (error) {
    throw error.response
  }
}



// My Buisiness 


export const createDealershipDetails = async (payload) => {
  try {
    const body = convertToFormData(payload)
    const response = await fetchHandler(`/${API_CONSTANTS.CREATE_DEALERSHIP_DETAILS}`,{},{},body,API_CONSTANTS.HTT_POST);
    return response;
  }
  catch (err) {
  }
}

// Need To Delete in Materials
export const getPhotoType = async () => {
  try {
    const response = await fetchHandler(`/${API_CONSTANTS.GET_PHOTO_TYPE}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  } catch (error) {
  }
}




export const SupportTicketReson = async () => {
  try {
    const response = await fetchHandler(`/SupportTicket/SupportTicketReason`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});

    return response;
  }
  catch (err) {
    throw err.response;
  }
}

export const getCancelledMPjobDescription = async ( JobId) => {
  try {
    const response = await fetchHandler(`/GetOPMPUpcomingJobDetailsForPartnerByJobId/${JobId}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response
  }
  catch (error) {
    throw error.response;
  }
}


export const GetMaterialType = async () => {
  try {
    const response = await fetchHandler(`/GetMaterialType`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (error) {
    throw error.response;
  }
}


export const createMaterial = async ( payload) => {
  try {
    const body = convertToFormData(payload)
    const response = await fetchHandler(`/CreateMaterialStore`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (error) {
    throw error.response;
  }
}

export const GetMaterialStore = async () => {
  try {
    const response = await fetchHandler(`/GetMaterialStore`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (error) {
    throw error.response;
  }
}




export const getEquipmentType = async () => {
  try {
    const response = await fetchHandler(`/GetAllEquipmentType`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (error) {
    throw error.response;
  }
}





/***************Analytics*************/

export const getMaterialConnectsRevievedforMarketplace = async ( MaterialTypeId) => {
  try {
    const response = await fetchHandler(`/GetMaterialRequestReceivedCount?MaterialTypeId=${MaterialTypeId}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (error) {
    throw error.response;
  }
}

export const getMaterialConnectsCountforMarketplace = async (MaterialTypeId) => {
  try {
    const response = await fetchHandler(`/GetMaterialConnectsCount?MaterialTypeId=${MaterialTypeId}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (error) {
    throw error.response;
  }
}

// GetMaterialConnectsPendingCount

export const getMaterialConnectsPendingCountforMarketplace = async ( MaterialTypeId) => {
  try {
    const response = await fetchHandler(`/GetMaterialConnectsPendingCount?MaterialTypeId=${MaterialTypeId}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (error) {
    throw error.response;
  }
}

//GetMaterialLikesCount

export const getMaterialLikesCountforMarketplace = async (MaterialTypeId) => {
  try {
    const response = await fetchHandler(`${process.env.REACT_APP_API_ENDPOINT}/GetMaterialLikesCount?MaterialTypeId=${MaterialTypeId}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (error) {
    throw error.response;
  }
}





export const getPhototype = async () => {
  try {
    const response = await fetchHandler(`/GetPhotoType`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (error) {
    throw error.response;
  }
}

export const getVideotype = async () => {
  try {
    const response = await fetchHandler(`/GetVideoType`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (error) {
    throw error.response;
  }
}

export const MaterialDelete = async (MaterialId) => {
  try {
    const response = await fetchHandler(`/DeleteMaterial/${MaterialId}`,{},{},{},API_CONSTANTS.HTTP_DELETE);
    return response;

  }
  catch (error) {
    throw error.response;
  }

}


export const getE9FreeMaterialAvilableforUser = async () => {
  try {
    const response = await fetchHandler(`/GetE9FreeServiceAvailableForUser`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true})
    return response;

  }
  catch (error) {
    throw error.response;
  }
}


export const getMaterialType = async () => {
  try {
    const response = await fetchHandler(`/${API_CONSTANTS.GET_MATERIAL_TYPE}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  } catch (error) {
    throw error.response;
  }
}


export const deleteMaterialStep2 = async ( materialId, imageTypeId) => {
  // return 'hi';
  try {
    const response = await fetchHandler(`DeleteMaterialImages/${materialId}/${imageTypeId}`,{},{},{},API_CONSTANTS.HTTP_DELETE);
    return response;
  }
  catch (error) {
    throw error.response;
  }
}


export const deleteMaterailVideos = async (materialId, videoTypeId) => {
  try {
    const response = await fetchHandler(`/DeleteMaterialVideos/${materialId}/${videoTypeId}`,{},{},{},API_CONSTANTS.HTTP_DELETE);
    return response;
  }
  catch (error) {
    throw error.response;
  }
};





export const postmaterialMarketPlace = async (payload) => {
  try {
    const body = convertToFormData(payload)
    const response = await axios.postForm(`/PostMaterialMarketplace`,{},{},body,API_CONSTANTS.HTT_POST);
    return response;
  }
  catch (error) {
    throw error.response;
  }
}


export const updateMaterialVideos = async (payload) => {
  try {
    const response = await fetchHandler(`/UpdateMaterialVideos`,{},{},payload,API_CONSTANTS.HTT_POST,{isAuthRequired:true});
    return response;
  }
  catch (err) {
    throw err.response;
  }
}


export const getMyOrdersRequestReceivedList = async () => {
  try {
    const response = await fetchHandler(`/GetMyOrdersRequestReceivedList/10/0?MarketPlaceTypeValue=Used Equipment`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (error) {
    throw error.response;
  }
}


export const getMyOrdersRequestSentList = async () => {
  try {
    const response = await fetchHandler(`/GetMyOrdersRequestSentList/LIMIT/OFFSET?MarketPlaceTypeValue=Used Equipment`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (error) {
    throw error.response;
  }
}


export const getMyOrdersRentalRequestSentList = async () => {
  try {
    const response = await fetchHandler(`GetMyOrdersRequestReceivedList/10/0?MarketPlaceTypeValue=Used Equipment`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (error) {
    throw error.response;
  }
}



export const getMyOrdersRentalRequestRecieved = async () => {
  try {
    const response = await fetchHandler(`/GetMyOrdersRequestReceivedList/10/0?MarketPlaceTypeValue=Used Equipment`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
    return response;
  }
  catch (error) {
    throw error.response;
  }
}

export const getDescription=async(AssetId)=>{
  try{
    const response=await fetchHandler(`/GetE9MarketPlaceDetailsById/${AssetId}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true})
    return response;
  }
  catch(error){
   throw error.response;
  }
}

































