import { WebPageLayout } from "../Layout/WebpageLayout";
import { MobileHeader } from "../components/MobileHeader/MobileHeader";
import { MobileResponsiveFooter } from "../components/MobileResposiveFooter/MobileResponsiveFooter";
import useWindowWidth from "../../sharedComponent/isMobile/CheckMobile";
import { Flex } from "@chakra-ui/react";
import "./Terms.css";
function CookiesNotice() {
  const isMobile=useWindowWidth();
  return (
    <div>
      {isMobile < 768 ?(<>
       <MobileHeader />
        <Flex>
           <div className="resposive-terms">
        <h2 className="terms-heading">Cookies Notice</h2>
        <div className="terms-content">
          <h3 style={{fontSize:"1.2rem",marginBottom:"12px"}}>
            COOKIES NOTICE GLOBAL
          </h3>
          <h5>Effective Date: 01 November, 2020</h5>
          <p>We and our affiliates, third parties, and other partners use cookies
          and other identification technologies on our websites, mobile
          applications, email communications, advertisements, and other online
          services (collectively, the "Services") for a number of purposes,
          including: authenticating users, remembering user preferences and
          settings, determining the popularity of content, delivering and
          measuring the effectiveness of advertising campaigns, analyzing site
          traffic and trends, and generally understanding the online behaviours
          and interests of people who interact with our Services. You can read
          more here about the types of cookies we use, why we use them, and how
          you can exercise your choices.</p>

          <h5 className="rating-h5">
            Cookies and Related Technologies Overview
          </h5>
          <p   >Cookies are small text files that are stored on your browser or device
          by websites, apps, online media, and advertisements that are used to
          remember your browser or device during and across website visits. We
          also utilise other technologies that may identify you or the devices
          you use. For example, “pixel tags” (also called beacons) are small
          blocks of code installed on (or called by) a web page, app, or
          advertisement which can retrieve certain information about your device
          and browser, including for example: device type, operating system,
          browser type and version, website visited, time of visit, referring
          website, IP address, advertising identifiers, and other similar
          information, including the small text file (the cookie) that uniquely
          identifies the device. Pixels provide the means by which third parties
          can set and read browser cookies from a domain that they do not
          themselves operate and collect information about visitors to that
          domain, typically with the permission of the domain owner. “Local
          storage” refers generally to other places on a browser or device where
          information can be stored by websites, ads, or third parties (such as
          HTML5 local storage and browser cache). “Software Development Kits”
          (also called SDKs) function like pixels and cookies, but operate in
          the mobile app context where pixels and cookies cannot always
          function. The primary app developer can install pieces of code (the
          SDK) from partners in the app, and thereby allow the partner to
          collect certain information about user interaction with the app and
          information about the user device and network information.{" "}
          </p>
        </div>
        <h5 className="rating-h5"  style={{color:'#ffffff' ,fontWeight:"800"}}>Advertising Synchronisation & Relevancy</h5>
        <p style={{color:'#ffffff'}}>In order to facilitate the most relevant ads possible, EQUIP9™ works
        with various service providers who assist us in delivering similar ads
        to end users across devices and platforms. For example, we work with
        social media advertising services to provide you with relevant ads based
        on your EQUIP9™ activity through their media channels. We may also use
        service providers to provide you with a similar ad on a mobile website
        or mobile application as with a traditional website ad. See below for
        more information on your choices to limit these types of advertising.</p>

        <h5  className="rating-h5" style={{color:'#ffffff',fontWeight:"800"}}>Your Choices</h5>
        <p style={{color:'#ffffff'}}>You have the right to choose whether or not to accept cookies. However,
        they are an important part of how our Services work, so you should be
        aware that if you choose to refuse or remove cookies, this could affect
        the availability and functionality of the Services.
        </p>
        <div className="terms-link">
          <a href="mailto:contact@equip9.com">contact@equip9.com</a>

        </div>
        <br/><br/>
      </div>
        </Flex>
       <MobileResponsiveFooter />
      </>):(<>
        <WebPageLayout>
      <div className="terms">
        <h2 className="terms-heading">Cookies Notice</h2>
        <div className="terms-content">
          <h3 className="rating-h3" style={{ marginBottom: "2.5rem" }}>
            COOKIES NOTICE GLOBAL
          </h3>
          <h5>Effective Date: 01 November, 2020</h5>
          <p>We and our affiliates, third parties, and other partners use cookies
          and other identification technologies on our websites, mobile
          applications, email communications, advertisements, and other online
          services (collectively, the "Services") for a number of purposes,
          including: authenticating users, remembering user preferences and
          settings, determining the popularity of content, delivering and
          measuring the effectiveness of advertising campaigns, analyzing site
          traffic and trends, and generally understanding the online behaviours
          and interests of people who interact with our Services. You can read
          more here about the types of cookies we use, why we use them, and how
          you can exercise your choices.</p>

          <h5 className="rating-h5">
            Cookies and Related Technologies Overview
          </h5>
          <p   >Cookies are small text files that are stored on your browser or device
          by websites, apps, online media, and advertisements that are used to
          remember your browser or device during and across website visits. We
          also utilise other technologies that may identify you or the devices
          you use. For example, “pixel tags” (also called beacons) are small
          blocks of code installed on (or called by) a web page, app, or
          advertisement which can retrieve certain information about your device
          and browser, including for example: device type, operating system,
          browser type and version, website visited, time of visit, referring
          website, IP address, advertising identifiers, and other similar
          information, including the small text file (the cookie) that uniquely
          identifies the device. Pixels provide the means by which third parties
          can set and read browser cookies from a domain that they do not
          themselves operate and collect information about visitors to that
          domain, typically with the permission of the domain owner. “Local
          storage” refers generally to other places on a browser or device where
          information can be stored by websites, ads, or third parties (such as
          HTML5 local storage and browser cache). “Software Development Kits”
          (also called SDKs) function like pixels and cookies, but operate in
          the mobile app context where pixels and cookies cannot always
          function. The primary app developer can install pieces of code (the
          SDK) from partners in the app, and thereby allow the partner to
          collect certain information about user interaction with the app and
          information about the user device and network information.{" "}
          </p>
        </div>
        <h5 className="rating-h5"  style={{color:'#ffffff' ,fontWeight:"800"}}>Advertising Synchronisation & Relevancy</h5>
        <p style={{color:'#ffffff'}}>In order to facilitate the most relevant ads possible, EQUIP9™ works
        with various service providers who assist us in delivering similar ads
        to end users across devices and platforms. For example, we work with
        social media advertising services to provide you with relevant ads based
        on your EQUIP9™ activity through their media channels. We may also use
        service providers to provide you with a similar ad on a mobile website
        or mobile application as with a traditional website ad. See below for
        more information on your choices to limit these types of advertising.</p>

        <h5  className="rating-h5" style={{color:'#ffffff',fontWeight:"800"}}>Your Choices</h5>
        <p style={{color:'#ffffff'}}>You have the right to choose whether or not to accept cookies. However,
        they are an important part of how our Services work, so you should be
        aware that if you choose to refuse or remove cookies, this could affect
        the availability and functionality of the Services.
        </p>
        <div className="terms-link">
          <a href="mailto:contact@equip9.com">contact@equip9.com</a>
        </div>
      </div>
    </WebPageLayout>
      </>) }
    </div>
  );
}

export default CookiesNotice;
