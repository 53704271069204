import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { userDetailsThunk } from "../../../global/user/thunks/userDetailThunk";
import { QrPopUp } from "../../../../components/ScanQrCodeModal/QrPopUp";
import { getDashboardDetailsThunk } from "../../thunks/getDashboardDetailsThunk";
import { openQrModal } from "../../slice/dashboardSlice";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { JobCard } from "../../../../components/JobCard/JobCard";
import { DashboardFallbackCard } from "../../../../components/DashboardFallbackCard/DashboardFallbackCard";
import { Sticky } from "../../../../components/Sticky/Sticky";
import { DASHBOARD_BUTTONS } from "../../../../../constants/renderData";
import { DashboardButton } from "../../../../components/DashboardButton/DashboardButton";
import { subString } from "../../../../helpers/dataConversion/substring";
import { resetFilters as rentalResetFilters } from "../../../rentalEquipmentMarketplace/slice/rentalEquipmentMartketplace";
import { resetFilters as usedRentalFilters } from "../../../usedEquipmentMarketplace/slice/usedEquipmentMarketplaceSlice";
import { resetFilters as operatorJobsResetFilters } from "../../../operatorJobs/slice/operatorJobsNearMeSlice";
import { resetFilters as maintenanceJobsResetFilters } from "../../../maintenanceJobs/slice/maintenancePartnerJobsSlice";
import { resetFilters as operatorResetFilters } from "../../../operatorNearMe/slice/operatorNearMeSlice";
import { resetFilters as maintenanceResetFilters } from "../../../maintenancePartnerNearMe/slice/maintenancePartnerSlice";
import { NativeCarousel } from "../../../../components/Carousel/NativeCarousel";
import { getCurrencyForUserThunk } from "../../../global/user/thunks/getCurrencyForUserThunk";
import {
  resetCart,
  resetCheckoutDetails,
} from "../../../subscription/slice/subscriptionSlice";
import {
  Box,
  Flex,
  Skeleton,
  Text,
  Tooltip,
  Image,
  useToast,
  CloseButton,
  Button,
} from "@chakra-ui/react";
import { DashboardLoader } from "../../../../components/loaders/dashboardLoader/DashboardLoader";
import { resetEquipmentListingSlice } from "../../../equipmentListing/slice/equipmentListingSlice";
// import { getPostLoginAdvertisments } from "../../../../services/advertisement/advertisement";
import { getPostLoginAdvertisementThunk } from "../../../advertisement/thunks/getPostLoginAdvertisementThunk";
import { fetchHandler } from "../../../../../index";
import { API_CONSTANTS } from "../../../../../constants/apiConstants";
import { primaryKeyEncryption } from "../../../../helpers/dataConversion/primaryKeyEncryption";
import { V5SidebarLayout } from "../../../../layout/V5SidebarLayout/V5SidebarLayout";
import { V5DashboardAssetCard } from "../../../../components/V5DashboardAssetCard/V5DashboardAssetCard";
import { ArrowRightIcon } from "@chakra-ui/icons";
import { V5DashboardPartnerCard } from "../../../../components/V5DashboardPartnerCard/V5DashboardPartnerCard";
import { Helmet } from "react-helmet";
import { NAVIGATION_CONSTANTS } from "../../../../../constants/naigationContants";
import { convertToBase64 } from "../../../../../utils/formatConverter/convertToBase64";
import {
  getMyPremiumDetails,
  getUserPremiumDetails,
} from "../../../../services/premium/premium";
import { setIsPremium, setPremiumId } from "../../../premium/premiumSlice";
// import { getPremiumDetailsThunk } from "../../../buyPremium/thunks/getPremiumDetailsThunk";
import { getPremiumUserDetailsThunk } from "../../../premium/thunks/getPremiumUserDetailsThunk";
import premiumImage from "../../../../../assets/webapp/premium/premium-bg.png";
import { v4 as uuid } from "uuid";

export const Dashboard = () => {
  const dashboard = useSelector((state) => state.dashboard);
  const userData = useSelector((state) => state.user);
  const advertisements = useSelector((state) => state.advertisement);
  const [appLanguage, setAppLanguage] = useState();
  const { defaultLanguage } = useSelector((state) => state.masterData);
  const {
    isPremium,
    premiumId,
    premiumDetails,
    premiumDetailsLoading,
    premiumDaysRemainingToExpire,
  } = useSelector((state) => state.premium);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const assetId = new URLSearchParams(window.location.search).get("id");
  const [urlParam, setUrlParam] = useSearchParams();

  useEffect(() => {
    const id = sessionStorage.getItem("DEFERRED_MARKETPLACE");
    const urlAssetId = urlParam.get(NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID);
    if (id && !urlAssetId) {
      setUrlParam(`${NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID}=${id}`);
    }
  }, []);

  const decryptAssetId = async (assetId) => {
    try {
      const { data } = await fetchHandler(
        API_CONSTANTS.DECRYPT_SHARE_DATA,
        {},
        { "content-type": "application/json" },
        { shareData: assetId.split(" ").join("+") },
        API_CONSTANTS.HTT_POST,
        { isAuthRequired: false }
      );
      // console.log(data.Data)
      return data.Data;
    } catch (error) {}
  };

  useEffect(()=>{
    // console.log("PREMIUM DAYS",premiumDaysRemainingToExpire)
  },[premiumDaysRemainingToExpire])
  const renderBusinessOwnerButtons = () => {
    const buttons = DASHBOARD_BUTTONS.BUSINESS_OWNER.map((button) => (
      <DashboardButton
        onClick={() => {
          !button.route
            ? dispatch(openQrModal())
            : (button.buttonText === "E9 Services" ||
                button.buttonText === "My Business" ||
                button.buttonText.toLowerCase() === "My Leads".toLowerCase()) &&
              userData.UserTypeValue === "Company" &&
              !userData.accountDetails?.CompanyDetails?.TaxNumber
            ? toast({
                status: "error",
                description: "Please update GST Number to access the feature.",
                position: "top-right",
                isClosable: true,
              })
            : (button.buttonText === "E9 Services" ||
                button.buttonText === "My Business" ||
                button.buttonText === "My Leads") &&
              userData.UserTypeValue === "Company" &&
              !userData.accountDetails?.CompanyDetails?.IsE9VerifiedTaxNumber
            ? toast({
                status: "error",
                description:
                  "Company verification under review for the user verified from admin.",
                position: "top-right",
                isClosable: true,
              })
            : navigate(`${button.route}`);
        }}
        buttonText={button.buttonText}
        imageUrl={button.image}
      />
    ));
    return buttons;
  };

  const renderEquipmentOwnerButtons = () => {
    const buttons = DASHBOARD_BUTTONS.EQUIPMENT_OWNER.map((button) => (
      <DashboardButton
        onClick={() => {
          !button.route
            ? dispatch(openQrModal())
            : (button.buttonText === "E9 Services" ||
                button.buttonText === "My Business" ||
                button.buttonText === "My Leads") &&
              userData.UserTypeValue === "Company" &&
              !userData.accountDetails?.CompanyDetails?.TaxNumber
            ? toast({
                status: "error",
                description: "Please update GST Number to access the feature.",
                position: "top-right",
                isClosable: true,
              })
            : (button.buttonText === "E9 Services" ||
                button.buttonText === "My Business" ||
                button.buttonText === "My Leads") &&
              userData.UserTypeValue === "Company" &&
              !userData.accountDetails?.CompanyDetails?.IsE9VerifiedTaxNumber
            ? toast({
                status: "error",
                description: "Company is under review. Please try again later",
                position: "top-right",
                isClosable: true,
              })
            : navigate(`${button.route}`);
        }}
        buttonText={button.buttonText}
        imageUrl={button.image}
      />
    ));
    return buttons;
  };

  const renderMaintenancePartnerButtons = () => {
    const buttons = DASHBOARD_BUTTONS?.MAINTENANCE_PARTNER?.map((button) => (
      <DashboardButton
        onClick={() => {
          !button.route
            ? dispatch(openQrModal())
            : (button.buttonText === "E9 Services" ||
                button.buttonText === "My Business") &&
              userData.UserTypeValue === "Company" &&
              !userData.accountDetails?.CompanyDetails?.TaxNumber
            ? toast({
                status: "error",
                description: "Please update GST Number to access the feature.",
                position: "top-right",
                isClosable: true,
              })
            : (button.buttonText === "E9 Services" ||
                button.buttonText === "My Business") &&
              userData.UserTypeValue === "Company" &&
              !userData.accountDetails?.CompanyDetails?.IsE9VerifiedTaxNumber
            ? toast({
                status: "error",
                description: "Company is under review. Please try again later",
                position: "top-right",
                isClosable: true,
              })
            : navigate(`${button.route}`);
        }}
        buttonText={button.buttonText}
        imageUrl={button.image}
      />
    ));
    return buttons;
  };

  const renderOperatorPartnerButtons = () => {
    const buttons = DASHBOARD_BUTTONS?.OPERATOR_PARTNER?.map((button) => (
      <DashboardButton
        onClick={() =>
          !button.route ? dispatch(openQrModal()) : navigate(`${button.route}`)
        }
        buttonText={button.buttonText}
        imageUrl={button.image}
      />
    ));
    return buttons;
  };

  const renderDashboardButtons = (businessTypeValue) => {
    switch (businessTypeValue.toLowerCase()) {
      case "business owner":
        return renderBusinessOwnerButtons();
      case "equipment owner":
        return renderEquipmentOwnerButtons();
      case "maintenance partner":
        return renderMaintenancePartnerButtons();
      case "operator partner":
        return renderOperatorPartnerButtons();
      default:
        <></>;
    }
  };

  const verifyUserType = (userType, marketplaceType) => {
    if (
      (marketplaceType === "partner" ||
        marketplaceType === "equipment" ||
        marketplaceType === "dealership" ||
        marketplaceType === "material") &&
      (userData?.BusinessTypeValue.toLowerCase() === "business owner" ||
        userData?.BusinessTypeValue.toLowerCase() === "equipment owner")
    ) {
      return true;
    } else if (
      marketplaceType === "job" &&
      (userData?.BusinessTypeValue.toLowerCase() === "maintenance partner" ||
        userData?.BusinessTypeValue.toLowerCase() === "operator partner")
    ) {
      return true;
    } else {
      return false;
    }
  };
  const redirectMarketplace = (data) => {
    console.log("SHARE DATA",data);

    const subCategory = data?.marketplaceSubCategory.toString().toLowerCase();
    const userType = verifyUserType(data?.marketplaceSubCategory, data?.marketplaceSubCategory);

    if (userType === true) {
      switch (subCategory) {
        case "used":
          sessionStorage.removeItem("DEFERRED_MARKETPLACE");
          navigate(
            `/marketplace/MarketPlaceUEM/Cards/${primaryKeyEncryption(
              data.assetId
            )}`
          );
          break;
        case "rental":
          sessionStorage.removeItem("DEFERRED_MARKETPLACE");
          navigate(
            `/marketplace/MarketPlaceREM/Cards/${primaryKeyEncryption(
              data.assetId
            )}`
          );
          break;
        case "operator":
          sessionStorage.removeItem("DEFERRED_MARKETPLACE");
          navigate(`/near-me/MarketPlaceOP/Card/${data?.assetId}`);
          break;
        case "mechanic":
          sessionStorage.removeItem("DEFERRED_MARKETPLACE");
          navigate(`/near-me/MarketPlaceMP/Card/${data?.assetId}`);

        case "material":
          console.log("INSIDE MATERIAL")
          sessionStorage.removeItem("DEFERRED_MARKETPLACE");
          navigate(
            `/marketplace/MaterialMarketplace/material-details/${convertToBase64(
              data?.assetId
            )}/${convertToBase64(data?.MarketplaceType)}`
          );
          break;
        case "opjobs":
          sessionStorage.removeItem("DEFERRED_MARKETPLACE");
          navigate(`/jobs-near-me/AllJobsNearMeOP/Card/${data?.assetId}`);
          break;
        case "mpjobs":
          sessionStorage.removeItem("DEFERRED_MARKETPLACE");
          navigate(`/jobs-near-me/AllJobsNearMeMP/Card/${data?.assetId}`);
          break;
        case "dealership":
          const likeStatus = true;
          sessionStorage.removeItem("DEFERRED_MARKETPLACE");
          navigate(
            `/marketplace/DealershipMarketplaceHome/dealership-detail/${convertToBase64(
              data?.assetId
            )}/${convertToBase64(
              data?.SecondaryId
            )}/${likeStatus}/${convertToBase64(
              userData.accountDetails?.CompanyDetails?.TaxNumber
            )}`
          );
          break;
        case "nearmeoperator":
          sessionStorage.removeItem("DEFERRED_MARKETPLACE");
          navigate(`/near-me/MarketPlaceMP/Card/${data?.assetId}`);
          break;
        case "nearmemaintanence":
          sessionStorage.removeItem("DEFERRED_MARKETPLACE");
          navigate(`/near-me/MarketPlaceMP/Card/${data?.assetId}`);
          break;
        default:
          break;
      }
    } else {
      sessionStorage.removeItem("DEFERRED_MARKETPLACE");
      navigate("/not-authorized");
    }
  };

  const handlePremiumUserDetails = async () => {
    try {
      const data = await getUserPremiumDetails();
      dispatch(setIsPremium(data.Data.IsUserPremium));
      dispatch(setPremiumId(data.Data.PremiumUserId));
    } catch (error) {
      setIsPremium(false);
      setPremiumId("");
      // console.log(error)
    }
  };
  useEffect(() => {
    (async () => {
      if (assetId) {
        const data = await decryptAssetId(assetId);
        if (data?.assetId) {
          redirectMarketplace(data);
        }
      }
    })();
  }, [assetId]);

  useEffect(() => {
    setAppLanguage(defaultLanguage);
  }, [defaultLanguage]);

  useEffect(() => {
    // user
    dispatch(userDetailsThunk());
    dispatch(getCurrencyForUserThunk());
    //advertisement
    dispatch(getPostLoginAdvertisementThunk());
    dispatch(getDashboardDetailsThunk());

    // Reset all filters on dashboard
    dispatch(rentalResetFilters());
    dispatch(usedRentalFilters());
    dispatch(operatorJobsResetFilters());
    dispatch(maintenanceJobsResetFilters());
    dispatch(operatorResetFilters());
    dispatch(maintenanceJobsResetFilters());
    dispatch(maintenanceResetFilters());
    // CART and checkout reset
    dispatch(resetEquipmentListingSlice());
    dispatch(resetCart());
    dispatch(resetCheckoutDetails());
  }, []);

  useEffect(() => {
    if (!userData.accountDetails) {
      dispatch(userDetailsThunk());
      dispatch(getCurrencyForUserThunk());
    }
  }, [userData]);

  useEffect(() => {
    handlePremiumUserDetails();
  }, []);

  useEffect(() => {
    if (isPremium) {
      // getPremiumUserDetails()
      dispatch(getPremiumUserDetailsThunk());
    }
  }, [isPremium]);

  return (
    <>
      <Helmet>
        <link
          rel="MAINTENANCE PARTNER -Dashboard page"
          href="https://www.equip9.com/dashboard"
        />
        <meta
          name="keywords"
          content="Equip9: Construction Equipment Suppliers Hub"
        />

        <meta
          name="description"
          content="Discover Equip9, where business owners, equipment owners, 
      mechanics, and operators connect for heavy equipment needs."
        />
        <h1>Welcome to Equip9: Your Construction Equipment Supplier Hub</h1>
        <h2>Explore a Wide Range of Construction Equipment Options</h2>
        <h3>Connect with Top Suppliers on Equip9's Dashboard</h3>
      </Helmet>

      <QrPopUp />
      <V5SidebarLayout defaultLanguage={appLanguage}>
        {isPremium && premiumDaysRemainingToExpire <= 7 && premiumDaysRemainingToExpire != null ? (
          <Flex
            bgGradient="linear(to-r, #FED401, #F79C00)"
            minHeight="60px"
            width="95%"
            margin="0 auto"
            padding="0 38px"
            marginBottom="0.25rem"
            alignItems="center"
            justifyContent="space-between"
            position="relative"
          >
            <Text color="#FFF" fontSize="24px" fontWeight="700">
              Your Subscription is Ending Soon
            </Text>
            <CloseButton position="absolute" color="#FFF" right="0" top="0px" />
            <Button
              background="#FFF"
              color="#F79C00"
              fontWeight="700"
              fontSize="20px"
              onClick={() => navigate("/subscription/subscription-type")}
            >
              Renew
            </Button>
          </Flex>
        ) : (
          <></>
        )}
        <div>
          <NativeCarousel
            images={advertisements ? advertisements.data?.requiredImgUrl : []}
            redirectionUrl={
              advertisements ? advertisements.data?.requiredRedirectUrl : []
            }
            speed="3000"
            height="350px"
            width="100%"
          />
        </div>
        <Box padding={"1rem 1.2rem"}>
          <Flex justifyContent="space-between" overflowX="scroll" gap="1rem">
            {renderDashboardButtons(userData?.BusinessTypeValue)}
          </Flex>

          {isPremium ? (
            <p
              style={{
                textTransform: "uppercase",
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "31.39px",
                letterSpacing: "0.01em",
              }}
            >
              YOUR PREMIUM SERVICES
            </p>
          ) : (
            <></>
          )}
          {/* <Box>
            <Image src={premiumImage}/>
          </Box> */}
          <Flex
            gap="1rem"
            overflowX="scroll"
            padding="1rem 0.1rem"
            position="relative"
            _before={{
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              bgImage: isPremium ? `url(${premiumImage})` : "none",
              bgRepeat: "no-repeat",
              bgSize: "cover",
              filter: "blur(2px)",
              zIndex: -1,
            }}
          >
            {isPremium && premiumDetailsLoading ? (
              new Array(10).fill(1).map(() => (
                <Flex
                  flexDirection="column"
                  gap="0.5rem"
                  height="80px"
                  width="175px"
                  border="1px solid #E2E8F0"
                  padding="4px 20px 4px 12px"
                  borderRadius="8px"
                >
                  <Skeleton height="24px" width="100px" />{" "}
                  <Skeleton height="16px" width="21px" />{" "}
                  <Skeleton height="16px" width="60px" />
                </Flex>
              ))
            ) : isPremium && premiumDetails ? (
              premiumDetails.map(({ E9Services, RemainingCount }) => (
                <Flex
                  key={uuid()}
                  flexDirection="column"
                  gap="0.25rem"
                  minHeight="70px"
                  minWidth="165px"
                  width="100%"
                  border="1px solid #000000"
                  padding="4px 20px 4px 12px"
                  borderRadius="8px"
                  cursor={"pointer"}
                  onClick={()=>{
                    navigate("/subscription/my-premium")
                  }}
                >
                  <Text fontSize="14px" fontWeight="600">
                    {E9Services}
                  </Text>
                  <Text fontSize="16px" fontWeight="600">
                    {RemainingCount}
                  </Text>
                  <Text fontSize="12px" fontWeight="600">
                    Remaining
                  </Text>
                </Flex>
              ))
            ) : (
              <></>
            )}
          </Flex>

          {dashboard.isLoading ? (
            <DashboardLoader />
          ) : dashboard.dashboardData && !dashboard.isLoading ? (
            dashboard?.dashboardData?.map((section) => {
              // //("Section" , section);
              return (
                <div
                  style={{
                    width: "100%",
                    margin: "1em 0",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      // padding: "1em",
                      display: "flex",
                      justifyContent:
                        section.DashboardCategoryValue === "Sticky"
                          ? ""
                          : "space-between",
                    }}
                  >
                    {section.DashboardCategoryValue !== "Sticky" && (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "20px",
                          fontWeight: 700,
                          lineHeight: "31.39px",
                          letterSpacing: "0.01em",
                        }}
                      >
                        {section.DispalyLabelName}
                      </p>
                    )}
                    {section.DashboardCategoryValue === "Sticky" ||
                    !section.Data ? (
                      <></>
                    ) : (section.Data &&
                        section.DashboardCategoryValue === "MarketPlace" &&
                        (section.ServiceTypeValue === "Used Equipment" ||
                          section.ServiceTypeValue === "Rental Equipment" ||
                          section.ServiceTypeValue === "Dealership")) ||
                      section?.ServiceTypeValue?.toLowerCase() ===
                        "Material Contact".toLowerCase() ? (
                      <Link
                        to={`/marketplace/${section.RedirectPath}`}
                        style={{
                          color: "#002961",
                          fontWeight: 600,
                          fontWeight: 600,
                        }}
                      >
                        View All <ArrowRightIcon />
                      </Link>
                    ) : section.Data &&
                      section.DashboardCategoryValue === "NearMe" &&
                      (section.ServiceTypeValue === "Maintenance Search" ||
                        section.ServiceTypeValue === "Operator Search") ? (
                      <Link
                        to={`/near-me/${section.RedirectPath}`}
                        style={{ color: "#002961", fontWeight: 600 }}
                      >
                        View All <ArrowRightIcon />
                      </Link>
                    ) : section.Data &&
                      section.DashboardCategoryValue === "Jobs" &&
                      section.DashboardServiceTypeValue === "Posted Jobs" ? (
                      <Link
                        to={`/jobs-near-me/${section.RedirectPath}`}
                        style={{ color: "#002961", fontWeight: 600 }}
                      >
                        View All <ArrowRightIcon />
                      </Link>
                    ) : (
                      <Link
                        onClick={() => dispatch(openQrModal())}
                        style={{ color: "#002961", fontWeight: 600 }}
                      >
                        View All <ArrowRightIcon />
                      </Link>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "2em",
                      padding:
                        section.DashboardCategoryValue === "Sticky"
                          ? ""
                          : "1.5rem 0.25rem",
                      overflowY:
                        section.DashboardCategoryValue === "Sticky"
                          ? ""
                          : "scroll",
                      // maxWidth: "calc(100vw - 240px)",
                      width: "100%",
                      alignItems:
                        section.DashboardCategoryValue === "Sticky"
                          ? "center"
                          : "",
                      justifyContent:
                        section.DashboardCategoryValue === "Sticky"
                          ? "center"
                          : "",
                    }}
                  >
                    {section.Data &&
                    section.Data.length > 0 &&
                    section.DashboardServiceTypeValue === "Equipment Search" ? (
                      section?.Data?.map((item) => (
                        <V5DashboardAssetCard
                          key={item?.AssetUniqueId}
                          minW={[
                            "400px",
                            "400px",
                            "344px",
                            "295px",
                            "262px",
                            "272px",
                          ]}
                          maxW="275px"
                          width="100%"
                          height="272px"
                          display="flex"
                          flexDirection="column"
                          boxShadow="0px 6px 15px -3px #00000029"
                          cursor="pointer"
                          onClick={() =>
                            (section.Data &&
                              section.DashboardCategoryValue ===
                                "MarketPlace" &&
                              (section.ServiceTypeValue === "Used Equipment" ||
                                section.ServiceTypeValue ===
                                  "Rental Equipment" ||
                                section.ServiceTypeValue === "Dealership")) ||
                            section?.ServiceTypeValue?.toLowerCase() ===
                              "Material Contact".toLowerCase()
                              ? navigate(`/marketplace/${section.RedirectPath}`)
                              : section.Data &&
                                section.DashboardCategoryValue === "NearMe" &&
                                (section.ServiceTypeValue ===
                                  "Maintenance Search" ||
                                  section.ServiceTypeValue ===
                                    "Operator Search")
                              ? navigate(`/near-me/${section.RedirectPath}`)
                              : section.Data &&
                                section.DashboardCategoryValue === "Jobs" &&
                                section.DashboardServiceTypeValue ===
                                  "Posted Jobs"
                              ? navigate(
                                  `/jobs-near-me/${section.RedirectPath}`
                                )
                              : (() => {
                                  return null;
                                })()
                          }
                        >
                          <V5DashboardAssetCard.Header
                            width="100%"
                            height="150px"
                          >
                            <V5DashboardAssetCard.Image
                              src={`${process.env.REACT_APP_CDN_PVT_URL}${item?.ImageFileReference}`}
                            />
                          </V5DashboardAssetCard.Header>
                          <V5DashboardAssetCard.Body
                            display="flex"
                            height="122px"
                            flexDirection="column"
                            padding="1em 1.5em"
                          >
                            <V5DashboardAssetCard.Heading
                              tooltip={item?.HeaderTitle}
                            >
                              {subString(22, item?.HeaderTitle)}
                            </V5DashboardAssetCard.Heading>

                            <V5DashboardAssetCard.HighlightedText>
                              {section.ServiceTypeValue &&
                              section.ServiceTypeValue.toString().includes(
                                "Equipment"
                              )
                                ? item?.SubHeader1
                                : item?.SubHeader3}
                            </V5DashboardAssetCard.HighlightedText>
                            <Tooltip label={item?.SubHeader2}>
                              <V5DashboardAssetCard.Location>
                                {section?.ServiceTypeValue.toString().toLowerCase() ===
                                "Dealership".toLowerCase()
                                  ? subString(
                                      20,
                                      item?.GoogleLocation?.City,
                                      item?.GoogleLocation?.State,
                                      item?.GoogleLocation?.Country
                                    )
                                  : subString(20, item?.SubHeader2)}
                              </V5DashboardAssetCard.Location>
                            </Tooltip>
                            <V5DashboardAssetCard.Text>
                              {section.ServiceTypeValue.toString().toLowerCase() ===
                                "Dealership".toLowerCase() ||
                              section.ServiceTypeValue.toString().toLowerCase() ===
                                "Material Contact".toLowerCase()
                                ? item?.SubHeader1
                                : item?.SubHeader3}
                            </V5DashboardAssetCard.Text>
                          </V5DashboardAssetCard.Body>
                        </V5DashboardAssetCard>
                      ))
                    ) : section.Data &&
                      section.DashboardCategoryValue === "Sticky" ? (
                      <Sticky
                        imageUrl={`${process.env.REACT_APP_CDN_PVT_URL}${section.ImageBaseUrl}${section.Data.ImageFileReference}`}
                        heading={section.Data.HeadingTitle}
                        highlightedText={section.Data.SubHeading1}
                        subHeading={section.Data.SubHeading2}
                        onClick={() => {
                          dispatch(openQrModal());
                        }}
                      />
                    ) : section.Data &&
                      section.Data.length > 0 &&
                      section.DashboardCategoryValue === "Jobs" ? (
                      section?.Data?.map((item) => (
                        <JobCard
                          heading={item?.JobTitle}
                          salary={item?.Salary}
                          location={`${item?.CityName}, ${item?.StateName}, ${item?.CountryName}`}
                          lastDate={item?.JobExpiryDate.split(",")[1]
                            .split("-")
                            .join(" ")}
                          statusMessage={item?.UserJobResponseTypeValue}
                          onClick={() =>
                            navigate(`/jobs-near-me/${section.RedirectPath}`)
                          }
                        />
                      ))
                    ) : section.Data &&
                      section.Data.length > 0 &&
                      section.DashboardCategoryValue === "NearMe" ? (
                      section?.Data?.map((item) => (
                        <V5DashboardPartnerCard
                          onButtonClick={() => {
                            navigate(`/near-me/${section.RedirectPath}`);
                          }}
                        >
                          <V5DashboardPartnerCard.Image
                            src={
                              item?.ProfilePictureFileReference
                                ? `${process.env.REACT_APP_CDN_PVT_URL}${section.ImageBaseUrl}${item?.ProfilePictureFileReference}`
                                : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Avatar.png`
                            }
                          />
                          <Flex
                            flexDirection="column"
                            gap="0.75em"
                            marginTop="1em"
                            alignItems="center"
                          >
                            <V5DashboardPartnerCard.Heading>
                              {subString(15, item?.PartnerName)}
                            </V5DashboardPartnerCard.Heading>

                            <V5DashboardPartnerCard.Text color="#002961">
                              {subString(10, item?.EquipmentTypeValue)}
                            </V5DashboardPartnerCard.Text>
                            <V5DashboardPartnerCard.Text>{`${item?.ExperienceYear}.${item?.ExperienceMonth} Years Experienced`}</V5DashboardPartnerCard.Text>
                          </Flex>
                        </V5DashboardPartnerCard>
                      ))
                    ) : (
                      <DashboardFallbackCard />
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            ""
          )}
        </Box>
      </V5SidebarLayout>
    </>
  );
};
