import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  PinInput,
  PinInputField,
  Text,
  useToast,
  Box,
  FormErrorMessage,
  Spinner,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { resetOtp, setMobileNumber } from "../../slice/otpSlice";
import { getDefaultAppLanguage } from "../../../global/mtData/helpers/localStorage";

export const VerifyOtpHoc = ({
  resendOtpFunction = () => {},
  onOtpMatch = () => {},
  onResendOtpError = () => {},
}) => {
  const otpData = useSelector((state) => state.otp);
  const toast = useToast();
  const dispatch = useDispatch();
  const [timerSeconds, setTimerSeconds] = useState(otpData.otpTimerSeconds);
  const [otpInput, setOtpInput] = useState(["", "", "", ""]);
  const [showOtpErrorMessage, setShowOtpErrorMessage] = useState(false);
  let id;
  const defaultLanguage = getDefaultAppLanguage();

  const otpInputHandler = (e, index) => {
    const pinCodeArray = [...otpInput];
    if (!e.target.value) {
      pinCodeArray[index] = "";
    } else {
      pinCodeArray[index] = e.target.value;
    }
    return setOtpInput(pinCodeArray);
  };

  const handleResendOtpButton = () => {
    resendOtpFunction();
    setTimerSeconds(otpData.otpTimerSeconds);
    setShowOtpErrorMessage(false);
  };

  const handleSubmitButton = () => {
    const enteredOtp = otpInput.join("");
    if (parseInt(enteredOtp) !== parseInt(otpData.otp)) {
      setShowOtpErrorMessage(true);
    } else {
      onOtpMatch(otpData.mobileNumber);
      dispatch(setMobileNumber(null));
    }
  };

  const minutesConvertor = (timer) => {
    const minutes = Math.floor(timer / 60);
    return minutes > 9 ? minutes : `0${minutes}`;
  };

  const secondsConvertor = (timer) => {
    const seconds = timer % 60;
    return seconds > 9 ? seconds : `0${seconds}`;
  };

  useEffect(() => {
    if (timerSeconds === 0) {
      dispatch(resetOtp());
    }
  });

  useEffect(() => {
    id = setInterval(() => {
      if (timerSeconds > 0) {
        setTimerSeconds((prevState) => prevState - 1);
      }
    }, 1000);
    return () => {
      clearInterval(id);
    };
  });

  useEffect(() => {
    if (otpData.error) {
      toast({
        status: "error",
        title: `${otpData.error}`,
        isClosable: true,
        position: "top-right",
      });
      onResendOtpError();
    }
  }, [otpData.error]);

  return  (
    <Flex
      flexDirection="column"
      gap={showOtpErrorMessage ? "1em" : "2em"}
      margin="0 auto"
      justifyContent="space-between"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <Box
      maxW={["90%", "350px", "400px"]}
      marginTop="3em"
      >
      <FormControl isInvalid={showOtpErrorMessage}>
        <Flex justifyContent="space-between" alignItems="center">
          <FormLabel>{defaultLanguage?.Enter_OTP_Code}{" "} 
            {/* {otpData?.otp} */}
            </FormLabel>
          <Text marginBottom="9px" fontWeight="700" color="#2c2c2c80" fontSize={{ base: "sm", md: "md" }}>
            Time Remaining:{" "}
            <span
              style={{
                color:
                  timerSeconds > 60
                    ? "#f67c00"
                    : timerSeconds !== 0
                    ? "#D60000"
                    : "",
              }}
            >
              {`${minutesConvertor(timerSeconds)} : ${secondsConvertor(timerSeconds)}`}
            </span>
          </Text>
        </Flex>
        <Flex justifyContent="space-between" gap="0.3em">
          <PinInput placeholder="_">
            {otpInput.map((otp, index) => (
              <PinInputField
                onChange={(e) => otpInputHandler(e, index)}
                key={index}
                h="52px"
                w={["100%", "85px"]} 
                border="1px solid #2c2c2c14"
                focusBorderColor="#f79c00"
                margin="0"
                padding="0"
                _focusVisible={{
                  border : "1px solid #f79c00",
                boxShadow: "0 0 0 1px #f79c00"
                }}
                
              />
            ))}
          </PinInput>
        </Flex>
        <FormErrorMessage fontSize="16px">Otp does not match</FormErrorMessage>
      </FormControl>

      {otpData?.loading ? (
        <Button
        display="block"
        m="0 auto"
        textDecoration="none"
        marginTop="1.5em"
        boxShadow="none"
        w="76px"
        h="21px"
        variant="link"
        fontWeight="600"
      >
        <Spinner />
      </Button>
      ):(
        <Button
        display="block"
        m="0 auto"
        textDecoration="none"
        marginTop="1.5em"
        boxShadow="none"
        w="76px"
        h="21px"
        variant="link"
        fontWeight="600"
        color={timerSeconds ? "#2c2c2c80" : "#1E3264"}
        isDisabled={timerSeconds ? true : false}
        onClick={handleResendOtpButton}
      >
        {defaultLanguage?.Resend_OTP} <ChevronRightIcon />
      </Button>
      )}
      </Box>
      <Box 
      boxShadow="rgba(44, 44, 44, 0.06) 0px -10px 15px"
      w="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor="#fff"
      height="78px"
      >
        <Button
          isDisabled={otpInput.includes("")}
          onClick={handleSubmitButton}
          display="block"
          variant="gradient"
          maxW={["90%", "350px", "400px"]}
          minW={["90%", "350px", "400px"]}
        >
          {defaultLanguage?.Submit}
        </Button>
  
      </Box>
    </Flex>
  );
  
};
