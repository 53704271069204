import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button } from "@chakra-ui/button";
import { Flex, Text, Heading } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setIsPasswordChangedSuccessModelOpen,
} from "../../../../slice/forgotPasswordSlice";
import PasswordSuccessImage from "../../../../../../../assets/webapp/forgotPassword/Password_updated.png";

export const PasswordChangeSuccessModal = () => {
  const forgotPasswordData = useSelector((state) => state.forgotPassword);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Modal
      isOpen={forgotPasswordData.isPasswordChangedSuccessModelOpen}
      isCentered
    >
      <ModalOverlay />
      <ModalContent h={{ base: "auto", md: "294px" }}>
        {" "}
        {/* Responsive height */}
        <ModalBody mt="2.5em" textAlign="center">
          <Flex
            w="80%"
            flexDirection="column"
            alignItems="center"
            margin="0 auto"
            gap="0.5em"
          >
            <Image
              w={{ base: "120px", md: "180px" }}
              h={{ base: "33px", md: "50px" }}
              src={PasswordSuccessImage}
            />
            <Heading fontSize={{ base: "20px", md: "24px" }}>
              Password Updated!
            </Heading>
            <Text fontSize={{ base: "16px", md: "18px" }} color="#2c2c2c70">
              Please use your new password to login…
            </Text>
          </Flex>
        </ModalBody>
        <ModalFooter
          h={{ base: "auto", md: "78px" }}
          justifyContent="center"
          alignItems="center"
          boxShadow="0px -10px 15px 0px #2C2C2C0F"
        >
          <Button
            maxW={{ base: "none", md: "347px" }}
            w="100%"
            variant="gradient"
            onClick={() => {
              dispatch(setIsPasswordChangedSuccessModelOpen(false));
              navigate("/login/login-form");
            }}
          >
            Done
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
