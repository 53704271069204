import React, { useEffect, useState } from "react";
import { NewSideMenuLayout } from "../../layout/NewSideMenuLayout/NewSideMenuLayout";
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Toast,
  useToast,
} from "@chakra-ui/react";
import "./TabPannelCard.css";
import TabSentCard from "./TabSentCard";
import TabReceivedCard from "./TabReceivedCard";

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { getUsedEquipmentSentDataThunk } from '../../features/myLeads/thunk/getUsedEquipmentSentDataThunk'
import { useDispatch, useSelector } from 'react-redux'
import { getUemReceivedData } from '../../features/myLeads/thunk/getUemReceivedData'
import { getRemSentDataThunk } from '../../features/myLeads/thunk/getRemSentDataThunk'
import { getRemReceivedDataThunk } from '../../features/myLeads/thunk/getRemReceivedDataThunk'
import { getTransactionModeDetailsThunk } from '../../features/myLeads/thunk/getTransactionModeDetailsThunk'
import { getMaterialType, updateAssetContactRequest, updateE9MarketplaceContactRedeemedDetails } from '../../services/myLeads/myLeads'
import { convertToBase64 } from '../../../utils/formatConverter/convertToBase64'
import { setAssetId, setEquipmentData, setEquipmentTypeValue, setImageFileReference, setMaterialData, setModelName, setOEMName } from '../../features/myLeads/Slice/myLeadsSlice'
import { openQrModal } from '../../features/dashboard/slice/dashboardSlice'
import { QrPopUp } from '../ScanQrCodeModal/QrPopUp'
import { getDealershipReceivedDataThunk, getDealershipSentDataThunk, getMyLeadsDealershipConnectList } from '../../features/myLeads/thunk/getDealershipSentDataThunk'
import { primaryKeyEncryption } from '../../helpers/dataConversion/primaryKeyEncryption'
import { getMaterialSentDataThunk, getMyLeadsMaterialConnectListThunk } from '../../features/myLeads/thunk/getMaterialSentDataThunk'
import { getMaterialReceiveDataThunk } from '../../features/myLeads/thunk/getMaterialReceiveDataThunk'
import { UpdateMaterialContactDetails, getMaterialTypes, getResponseTypeDetails } from '../../services/materials/materials'
import { MarketplaceHoc } from '../../features/marketplace/hoc/MarketplaceHoc/MarketplaceHoc'
import { V5SidebarLayout } from "../../layout/V5SidebarLayout/V5SidebarLayout";

const TabContainer = ({
}) => {

    const {
        uemSentCardData, uemReceivedCardData,
        remSentCardData, remReceivedCardData,
        dealershipSentCardData, dealershipReceivedCardData,
       transactionModeDetails,
        receivedCount,materialSentCardData,materialReceivedCardData,materialTypesData,initialLoading
    } = useSelector((state) => state.myLeads);
    const [responseTypeDetails,setResponseTypeDetails] = useState(null);

    const { accountDetails, token } = useSelector(state => state.user);
    const {defaultLanguage} = useSelector(state => state.masterData);
    const [marketplaceType, setMarketplaceType] = useState();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();

  useEffect(() => {
    handleCardsOnLocation();
    getTransactionData();
  }, [location]);

    useEffect(() => {
        getTransactionData()
    }, [])
    useEffect(() => {

    }, [uemSentCardData])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getResponseTypeDetails();
                setResponseTypeDetails(data?.ResponseTypes);
            } catch (error) {
                console.error("Error fetching response type details:", error);
            }
        };
        fetchData();
    }, []);

    const getMaterialSendReceiveData=async (materialTypeValue,type)=>{
        const materialTypes = await getMaterialType();
        const materialTypeId=materialTypes.find((obj)=>(obj.MaterialTypeValue===materialTypeValue))?.MaterialTypeId

    if (type === "sent") {
      dispatch(getMaterialSentDataThunk(materialTypeId));
    } else if (type === "received") {
      dispatch(getMaterialReceiveDataThunk(materialTypeId));
    }
  };

  const handleCardsOnLocation = () => {
    const searchParams = new URLSearchParams(location.search);
    if (
      searchParams.get("marketplace-type") === "used-equipment" &&
      searchParams.get("request-type") === "sent"
    ) {
      setMarketplaceType(searchParams.get("marketplace-type"));
      dispatch(getUsedEquipmentSentDataThunk());
    } else if (
      searchParams.get("marketplace-type") === "used-equipment" &&
      searchParams.get("request-type") === "received"
    ) {
      setMarketplaceType(searchParams.get("marketplace-type"));
      dispatch(getUemReceivedData());
    } else if (
      searchParams.get("marketplace-type") === "rental-equipment" &&
      searchParams.get("request-type") === "sent"
    ) {
      setMarketplaceType(searchParams.get("marketplace-type"));
      dispatch(getRemSentDataThunk());
    } else if (
      searchParams.get("marketplace-type") === "rental-equipment" &&
      searchParams.get("request-type") === "received"
    ) {
      setMarketplaceType(searchParams.get("marketplace-type"));
      dispatch(getRemReceivedDataThunk());
    } else if (
      searchParams.get("marketplace-type") === "dealership" &&
      searchParams.get("request-type") === "sent"
    ) {
      setMarketplaceType(searchParams.get("marketplace-type"));
      dispatch(getDealershipSentDataThunk());
    } else if (
      searchParams.get("marketplace-type") === "dealership" &&
      searchParams.get("request-type") === "received"
    ) {
      setMarketplaceType(searchParams.get("marketplace-type"));
      dispatch(getDealershipReceivedDataThunk());
    } else if (
      materialTypesData.includes(searchParams.get("marketplace-type")) &&
      searchParams.get("request-type") === "sent"
    ) {
      setMarketplaceType(searchParams.get("marketplace-type"));
      getMaterialSendReceiveData(searchParams.get("marketplace-type"), "sent");
    } else if (
      materialTypesData.includes(searchParams.get("marketplace-type")) &&
      searchParams.get("request-type") === "received"
    ) {
      setMarketplaceType(searchParams.get("marketplace-type"));
      getMaterialSendReceiveData(
        searchParams.get("marketplace-type"),
        "received"
      );
    } else {
      navigate("/my-leads");
    }
  };
  const getTransactionData = async () => {
    dispatch(getTransactionModeDetailsThunk());
  };

  const handleRevokeReedem = async (data) => {
    const revokeRequest = await responseTypeDetails.find(
      (response) => response.ResponseTypeValue === "Request Revoked"
    );

    const reedemPayload = {
      E9MarketplaceContactRedeemedDetails: {
        E9MarketPlaceContactId: data?.E9MarketPlaceContactId,
        ResponseTypeValue: revokeRequest?.ResponseTypeValue,
        TransactionModeId: "",
        SenderUserCredentialId:
          accountDetails?.UserDetails?.CredentialDetails?.UserCredentialId,
      },
    };

    return reedemPayload;
  };

    const handleRevokeRequest = async (data) => {
        const revokeRequest = await responseTypeDetails.find((response) => response.ResponseTypeValue === "Request Revoked");
        let payload = {
            E9MarketPlaceId: data.E9MarketPlaceId,
            ReceiverUserCredentialId: data.ReceiverUserCredentialId,
            ContactStatus: 0,
            RequestTypeId: revokeRequest?.ResponseTypeId ? 4 : 1,
        }
        let formBody = [];
        for (let property in payload) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(payload[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
          try {
              const data = await updateAssetContactRequest(formBody);
              if(data?.Status === 204){
                  const reedemPayload =  await handleRevokeReedem(data);
                  try {
                    const response = await updateE9MarketplaceContactRedeemedDetails(JSON.stringify(reedemPayload));
                    if(response?.Status === 204){
                        toast({
                            title:"Contact Revoked Successfully",
                            position:'top-right',
                            status:"success"
                        })
                     dispatch(getUsedEquipmentSentDataThunk());
                     dispatch(getRemSentDataThunk());
                    }{
                        Toast({
                            title:"Unable To Revoke Contact",
                            position:'top-right',
                            status:'error'
                        })
                     dispatch(getUsedEquipmentSentDataThunk());
                     dispatch(getRemSentDataThunk());
                    }
                } catch (error) {
        
                }
              }
          } catch (error) {

          }
    }

    const handleRequestRecivedList = (E9MarketPlaceId,data) => {
        dispatch(setEquipmentData(data));
        navigate(`/my-leads/accept-reject/${convertToBase64(E9MarketPlaceId)}`)
    }
    // Dealership Connect
    const handleDealershipConnect = async(id,dealerName,dealershipUniqueId) =>{
        dispatch(getMyLeadsDealershipConnectList(id));
        navigate(`/my-leads/dealership/${convertToBase64(id)}/${dealerName}/${convertToBase64(dealershipUniqueId)}`)
    }
    const handleNavigation = (id) =>{
        navigate(`/marketplace/MarketPlaceUEM/Cards/${primaryKeyEncryption(id)}`);
    }

    const  handleMaterialRevoke =async (item)=>{

        const materialTypeList= await getMaterialTypes();
        const materialTypeId = materialTypeList.find(material => material.MaterialTypeValue === marketplaceType)?.MaterialTypeId;

        const payload={
            MaterialTypeId:materialTypeId,
            MaterialId:item.MaterialId,
            OwnerId:item.ReceiverUserCredentialId,
            ResponseTypeId:item.ResponseTypeValue==="Request Sent"? 4 : null
        }
     
        const data= await UpdateMaterialContactDetails(payload);
        if(data?.Status===204){
           
            toast({
                title: "",
                description: `${data?.Message?.SuccessMessage}`,
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "top-right",
              });
              getMaterialSendReceiveData(marketplaceType,'sent');
        }else{
            toast({
                title: "",
                description: `${data?.Message?.SuccessMessage}`,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
              });
        }
       
    }

    const handleMaterialConnect =async (data)=>{
        const payload={
            materialId:data?.MaterialId,
            materialTypeValue:marketplaceType
        }
        navigate(`/my-leads/accept-reject/${convertToBase64(JSON.stringify(payload))}`, { state: { materialAllData:data } })
    }
    
    const renderTabSentCards = (array) => {
        const renderJSX =
            array && array.length > 0 ? (
                array.map((data) => (
                    <>
                        {marketplaceType === 'dealership' ? (
                            <TabSentCard
                                assetId={data?.DealershipUniqueId}
                                equipmentTypeValue={data?.DealerName}
                                oemName={data?.EquipmentMakeValue}
                                modelName={data?.ProductEquipmentMakeValue}
                                isVerified={data?.IsVerified}
                                equipmentImage={data?.ImageList?.length > 0 ? data?.ImageList[0]?.ImageFileRef : ""}
                                city={data?.GoogleLocation?.City}
                                state={data?.GoogleLocation?.State}
                                receiverContactNumber={data?.MobileNumber}
                                isDealership="true"
                            />
                        ) : (
                            materialTypesData.includes(marketplaceType) ? (
                            <TabSentCard
                            equipmentTypeId = {data?.EquipmentId}
                            onNavigateClick = {()=>{dispatch(openQrModal())}}
                            assetId={data?.MaterialUniqueId}
                            equipmentTypeValue={data?.EquipmentTypeValue}
                            oemName={data?.OEMName}
                            modelName={data?.ModelName}
                            isVerified={data?.IsVerified}
                            equipmentImage={
                                data?.ImageList
                                ? JSON.parse(data.ImageList).find(obj => obj.ImageTypeId === 1)?.ImageFileRef
                                : null
                            }
                            isMaterial='true'
                            productName={data?.ProductName}
                            requestSentOn={data?.RequestSentOn}
                            city={data?.City}
                            state={data?.State}
                            receiverContactNumber={data?.mobileNumber}
                            requestType={data?.ResponseTypeValue}
                            // repliedValue={data?.RepliedValue}
                            onRevokeRequest = {() => {
                                handleMaterialRevoke(data)
                            }}
                            />
                            ) : (
                            <TabSentCard
                                onNavigateClick = {()=> handleNavigation(data?.E9MarketPlaceId)}
                                assetId={data?.AssetUniqueId}
                                equipmentTypeValue={data?.EquipmentTypeValue}
                                oemName={data?.OEMName}
                                modelName={data?.ModelName}
                                isVerified={data?.IsVerified}
                                equipmentImage={data?.ImageFileReference}
                                city={data?.CityName}
                                state={data?.StateName}
                                receiverContactNumber={data?.ReceiverContactNumber}
                                requestType={data?.ResponseTypeValue}
                                repliedValue={data?.RepliedValue}
                                onRevokeRequest = {() => handleRevokeRequest(data)}
                            />
                            )
                        )
                    }
                    </>
                ))
            ) : (
                <p>No Records Found</p>
            );

    return renderJSX;
  };

  const renderTabReceivedCards = (array) => {
    const renderJSX =
      array && array.length > 0 ? (
        array.map((data) => (
          <>
            {marketplaceType === "dealership" ? (
              <TabReceivedCard
                assetId={data?.DealershipUniqueId}
                equipmentTypeValue={data?.DealerName}
                oemName={data?.EquipmentMakeValue}
                modelName={data?.ProductEquipmentMakeValue}
                isVerified={data?.IsVerified}
                equipmentImage={
                  data?.ImageList?.length > 0
                    ? data?.ImageList[0]?.ImageFileRef
                    : ""
                }
                city={data?.GoogleLocation?.City}
                state={data?.GoogleLocation?.State}
                receiverContactNumber={data?.MobileNumber}
                isDealership="true"
                requestCount={data?.RequestsCount}
                likesCount={data?.LikesCount}
                onLikeButtonClick={() => dispatch(openQrModal())}
                requestReceived={() =>
                  handleDealershipConnect(
                    data?.DealershipMpGuid,
                    data?.DealerName,
                    data?.DealershipUniqueId
                  )
                }
              />
            ) : materialTypesData.includes(marketplaceType) ? (
              <TabReceivedCard
                assetId={data?.MaterialUniqueId}
                equipmentTypeValue={data?.EquipmentTypeValue}
                equipmentTypeId={data?.EquipmentId}
                oemName={data?.OEMName}
                modelName={data?.ModelName}
                isVerified={data?.IsVerified}
                equipmentImage={
                  data?.ImageList
                    ? JSON.parse(data.ImageList).find(
                        (obj) => obj.ImageTypeId === 1
                      )?.ImageFileRef
                    : null
                }
                isMaterial="true"
                productName={data?.ProductName}
                requestSentOn={data?.RequestSentOn}
                city={data?.City}
                state={data?.State}
                receiverContactNumber={data?.mobileNumber}
                requestReceived={() => {
                  handleMaterialConnect(data);
                }}
                onLikeButtonClick={() => {
                  dispatch(openQrModal());
                }}
                requestCount={data?.TotalRequest}
                likesCount={data?.TotalLikes}
              />
            ) : (
              <TabReceivedCard
                assetId={data?.AssetUniqueId}
                equipmentTypeValue={data?.EquipmentTypeValue}
                oemName={data?.OEMName}
                modelName={data?.ModelName}
                isVerified={data?.IsVerified}
                equipmentImage={data?.ImageFileReference}
                city={data?.CityName}
                state={data?.StateName}
                receiverContactNumber={data?.ReceiverContactNumber}
                requestReceived={() =>
                  handleRequestRecivedList(data?.E9MarketPlaceId, data)
                }
                totalCount={receivedCount}
                onLikeButtonClick={() => dispatch(openQrModal())}
                requestCount={data?.ContactRequestCount}
                likesCount={data?.LikesCount}
              />
            )}
          </>
        ))
      ) : (
        <p>No Records Found</p>
      );

    return renderJSX;
  };
  return (
    <V5SidebarLayout>
      <QrPopUp />
      <Tabs isFitted variant="enclosed">
        <TabList>
          <Link
            style={{ width: "100%" }}
            to={`/leads?marketplace-type=${marketplaceType}&request-type=sent`}
          >
            <Tab
              _selected={{ color: "white", bg: "#F79C00", width: "100%" }}
              width="100%"
            >
              {defaultLanguage?.Sent}
            </Tab>
          </Link>

          <Link
            style={{ width: "100%" }}
            to={`/leads?marketplace-type=${marketplaceType}&request-type=received`}
          >
            <Tab
              _selected={{ color: "white", bg: "#F79C00", width: "100%" }}
              width="100%"
            >
              {defaultLanguage?.Received}
            </Tab>
          </Link>
        </TabList>

        <div>
          <TabPanels>
            <TabPanel paddingLeft={"3em"}>
              <MarketplaceHoc
                marketplaceName="myLeads"
                dataArray={
                  marketplaceType === "used-equipment"
                    ? uemSentCardData
                    : marketplaceType === "rental-equipment"
                    ? remSentCardData
                    : marketplaceType === "dealership"
                    ? dealershipSentCardData
                    : materialTypesData.includes(marketplaceType)
                    ? materialSentCardData
                    : null
                }
                hasPagination={false}
                initialLoading={initialLoading}
                cardsRenderFunction={renderTabSentCards}
              />
            </TabPanel>

            <TabPanel paddingLeft={"3em"}>
              <MarketplaceHoc
                marketplaceName="myLeads"
                dataArray={
                  marketplaceType === "used-equipment"
                    ? uemReceivedCardData
                    : marketplaceType === "rental-equipment"
                    ? remReceivedCardData
                    : marketplaceType === "dealership"
                    ? dealershipReceivedCardData
                    : materialTypesData.includes(marketplaceType)
                    ? materialReceivedCardData
                    : null
                }
                hasPagination={false}
                initialLoading={initialLoading}
                cardsRenderFunction={renderTabReceivedCards}
              />
            </TabPanel>
          </TabPanels>
        </div>
      </Tabs>
    </V5SidebarLayout>
  );
};

export default TabContainer;
