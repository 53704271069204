import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetUserDealershipListCompany } from "../../../services/dealershipListing/dealershipListing";


export const dealershipListingCompanyLoadMoreThunk = createAsyncThunk("dealershipListingCompanyLoadMore/getLoadCards",async(_,{getState}) =>{
    try {
        const userData = getState().user
        const {cardCount,paginationCount} = getState().dealershipAssests
        
        const data = await GetUserDealershipListCompany(cardCount,cardCount * paginationCount , userData?.accountDetails?.CompanyDetails?.TaxNumber);
        if(data?.Status === 200){
            return data?.Data?.UserDealershipList;
        }
        return null ;
    } catch (error) {
        
    }
})