import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

export const AllMarketplacesCard = ({
  name,
  count,
  icon,
  onnavigationclick = () => {},
}) => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      onClick={onnavigationclick}
      cursor={"pointer"}
    >
      <Flex
        borderRadius={"4px 4px 0 0"}
        padding={"18px 1px"}
        gap={"10px"}
        border={"1px solid #E3E5E8"}
        direction={"column"}
        w={"110px"}
        h={"130px"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box>
          <img
            src={icon}
            alt={`${name} logo`}
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </Box>

        <Text
          fontSize={"14px"}
          fontWeight={"600"}
          color={"#141619"}
          textAlign={"center"}
        >
          {name}
        </Text>
      </Flex>

      <Flex
        backgroundColor={"#FFDD47"}
        h={"25px"}
        w={"130px"}
        borderRadius={"8px"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Text fontSize={"10px"} fontWeight={"700"} color={"#141619"}>
          {count} listings
        </Text>
      </Flex>
    </Flex>
  );
};
