import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFilterE9DealershipMarketPlaceList } from "../../../services/dealershipMarketplace/dealershipMarketplace";

export const dealershipInitialFilteredDataThunk = createAsyncThunk("dealership/initialFilteredData",async(_, {getState}) => {
   
    const token = getState().user.token;

    const {filterByCountry, filterByState, filterByEquipmentMake, cardCount} = getState().dealershipMarketplace
    // console.log("from thunk");
    // console.log({filterByCountry, filterByState, filterByEquipmentMake})

    let query = ""
    

    if(filterByCountry){
        query += `&CountryName=${filterByCountry}`
    }
    if(filterByState){
        query+= `&StateName=${filterByState}`
    }
   
    if(filterByEquipmentMake){
        query+= `&EquipmentMakeId=${filterByEquipmentMake}`
    }

    try{
        
        const data = await getFilterE9DealershipMarketPlaceList(cardCount,0,query);

        if(data.Status === 200){
           if(data.Data.Success){
            return data.Data.FilterE9DealershipMpList;
           }
           else{
            return []
           }
        }
    }
    catch(error){
    }

    
})