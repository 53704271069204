import { createAsyncThunk } from "@reduxjs/toolkit";
import { userDetailsThunk } from "../../global/user/thunks/userDetailThunk";
import { saveCompanyAddressDetails } from "../../../services/subscription/subscription";


export const saveCompanyAddressThunk =  createAsyncThunk("/subscription/saveCompanyAddress", async(body, {getState, dispatch, rejectWithValue}) => {

const {TaxNumber} = getState().user.accountDetails.CompanyDetails

try{

    const data = await saveCompanyAddressDetails(body,TaxNumber);
    
    if(data.Status === 204 || data.Status === 201){  
        try{
        const data = await dispatch(userDetailsThunk()).unwrap();
    }
    catch(error){
        console.log(error);
    }  
    }
    else{
        rejectWithValue({error: "Some error occured."})
    }
}
catch(error){
    console.log(error)
}
}) 