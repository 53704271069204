import { createAsyncThunk } from "@reduxjs/toolkit";
import fetchHandler from "../../../../../../webapp/services/fetchHandler";
import { API_CONSTANTS } from "../../../../../../constants/apiConstants";

export const generatorsLoadMoreDeferredThunk = createAsyncThunk("deferredGenerators/generatorsLoadMore",async(_,{getState}) =>{

    const {materialTypeId , cardCount , paginationCount} = getState().generatorsDeferred;


    try {
        const {data} = await fetchHandler(
          "/deferredLogin/marketplace",
          { category : "material" , materialTypeId : materialTypeId , limit : cardCount , offset : cardCount * paginationCount },
          
          "",
          "",
          API_CONSTANTS.HTTP_GET,
          { isAuthRequired: false }
      )

      if(data){

        return data?.Data?.MaterialMarketplaceList
      }
      return data ;
      } catch (error) {
        
      }
})