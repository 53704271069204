import React from "react";
import {
    AlertDialog,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Box,
    Flex,
    Text,
    Divider,
  } from "@chakra-ui/react";
import { ReactSVG } from "react-svg";

const JobDetailsCard = ({title,iconSrc,children}) => {
  return (
    <Box overflowY={"scroll"}>
      <Flex alignItems="center">
        <Flex w="100%" alignItems="center" gap="0.5em">
          <Box minWidth="max-content">
            <Flex gap="0.5em">
              <ReactSVG
                style={{ alignContent: "center" }}
                src={`${process.env.REACT_APP_CDN_STATIC_URL}${iconSrc}`}
              />
              <Text color="#011f6b" fontWeight="semibold" fontSize="2xl">
                {title}
              </Text>
            </Flex>
          </Box>
          <Flex alignItems="center" w="100%">
            <Divider borderColor="#011f6b" borderWidth="0.1em" />
          </Flex>
        </Flex>
      </Flex>
      <Box>
        <Flex
          gap="2em"
          flexDirection="row"
          overflowX="scroll"
          alignItems='center'
          padding='1em 1em'
        >
            {children}
        </Flex>
      </Box>
    </Box>
  );
};

export default JobDetailsCard;
