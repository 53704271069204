import { createAsyncThunk } from "@reduxjs/toolkit";
import { convertToFormData } from "../../../helpers/dataConversion/convertToFormData";
import { generateOtpForRegister } from "../../../services/masterData/masterData";

export const generateOtpThunk = createAsyncThunk(
    "/otp/generate", 
    async(body,{rejectWithValue}) => {
       try{    
           const data = await generateOtpForRegister(convertToFormData(body));
           if(!data.Status && data.Message && data.Message.FailMessage){
            console.log("Forgot",data);
            const errorMessage = data.Message.FailMessage;
            throw {FailMessage : errorMessage}
           }
          else{
            return data.Data.OTP
          }
        }
        catch(error){
            console.log("Forgot error",error);

            if(!error.response){
               
                return rejectWithValue(error)
            }
            return rejectWithValue(error.response.data.Message);
        }
    }
)