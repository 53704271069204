import React from "react";
import { NewSideMenuLayout } from "../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { Flex } from "@chakra-ui/react";
import { AcceptRejectModal } from "./PopUp/AcceptRejectModal";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { convertFromBase64 } from "../../../utils/formatConverter/convertToBase64";

const DealershipConnectCard = () => {
  const { getContactRequestReceivedList } = useSelector(
    (state) => state.myLeads
  );
  const { dealerName, dealershipUniqueId } = useParams();
  const renderDealershipCards = (array) => {
    const renderJSX =
      array && array.length > 0 ? (
        array.map((data) => (
          <div className="my-leads-tab-card-container-received">
            <div className="my-leads-tab-card-body">
              <div className="my-leads-tab-card-img-container">
                <img
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/DelarshipDefaultImage.png`}
                  alt="tab-img"
                  height="100%"
                />
              </div>
              <div className="my-leads-tab-card-tab-details">
                <h1 className="my-leads-header">{data?.Message}</h1>
                <p className="">
                  Request Received On : {data?.UpdatedDateTime}{" "}
                </p>
                <h1 className="my-leads-price">
                  Contact :{data?.MobileNumber}
                </h1>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No Records Found</p>
      );

    return renderJSX;
  };

  return (
    <NewSideMenuLayout>
      <AcceptRejectModal />
      <div
        style={{
          margin: "0px 52px",
          backgroundColor: "#92929204",
          paddingTop: "1em",
          display: "flex",
          flexDirection: "column",
          gap: "2em",
          overflow: "scroll",
        }}
      >
        <Flex direction="column">
          <div>
            <h1 className="my-business-store-headline">Dealership Name</h1>
          </div>
          <Flex justifyContent="space-between">
            <p className="my-business-store-subheading">
              {dealerName ? dealerName : "NA"}
            </p>
            <p className="my-business-store-subheading">
              {dealershipUniqueId
                ? convertFromBase64(dealershipUniqueId)
                : "NA"}
            </p>
          </Flex>
        </Flex>

        <Flex gap="1em" height="100%" wrap="wrap" padding="1em 1em">
          {getContactRequestReceivedList
            ? renderDealershipCards(getContactRequestReceivedList)
            : null}
        </Flex>
      </div>
    </NewSideMenuLayout>
  );
};

export default DealershipConnectCard;
