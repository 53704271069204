import { createSlice } from "@reduxjs/toolkit";
import { getDeferredUemThunk } from "../thunk/getDeferredUemThunk";
import { uemInitialState } from "../constants/uemInitialState";
import { uemLoadMoreDeferred } from "../thunk/uemLoadMoreDeferred";

export const uemDeferedSlice = createSlice({
    name:"uemDeferred",
    initialState: uemInitialState,
    reducers:{
        setData: (state,{payload})=>{
            state.data = payload
        }
    },

    extraReducers:(builder) =>{
        builder
        // UEM Marketplce
        .addCase(getDeferredUemThunk.pending, (state)=>{
            state.data = null;
            state.initialLoading = true;
            state.paginationLoading = false;
            state.error = false;
            state.errorMessage = false;
            state.paginationCount = 1;
            state.endOfData = false
        })
        .addCase(getDeferredUemThunk.fulfilled,(state,{payload}) =>{
            console.log("Fullfilled",payload);
            state.paginationCount = 1;
            state.initialLoading = false;
            state.error = false;
            state.errorMessage = null;
            state.data = payload?.EquipmentList;
            state.assetImageReference = payload?.data?.AssetImageBaseReference
            if(payload?.EquipmentList?.length < state.cardCount){
              state.endOfData = true
            }
        })

        .addCase(uemLoadMoreDeferred.pending, (state)=>{
            state.paginationLoading = true;
            state.error = false ;
            state.errorMessage = null;
         })
         .addCase(uemLoadMoreDeferred.fulfilled, (state,{payload})=>{
            console.log("Load More",payload);
            state.error = false ;
            state.errorMessage = null ;
            state.paginationLoading = false;
            state.assetImageReference = payload?.data?.AssetImageBaseReference
            if(payload?.EquipmentList?.length >= state.cardCount){
                state.data = [...state?.data , ...payload?.EquipmentList];
                state.paginationCount += 1;
            }else{
                state.endOfData = true;
         }
         })
    }
})

export const {
    setData
}= uemDeferedSlice.actions;