import { createSlice, current } from "@reduxjs/toolkit";
import { initialState } from "../constants/initialState";
import { getRemInitialDataThunk } from "../thunks/getRemInitialDataThunk";
import { getRemLoadMoreDataThunk } from "../thunks/getRemLoadMoreDataThunk";
import { getRemInitialFilteredDataThunk } from "../thunks/getRemInitialFilteredDataThunk";
import { getRemLoadMoreFilteredDataThunk } from "../thunks/getRemLoadMoreFilteredDataThunk";
import { getRemFilterImagesDataThunk } from "../thunks/getRemFilterImagesDataThunk";

export const rentalEquipmentMarketplaceSlice = createSlice({
  name: "rentalEquipmentMarketplace",
  initialState: initialState,
  reducers: {
    openRentalDurationModal:(state)=>{
      state.isOpenRentalDurationModal=true;
    },
    closeRentalDurationModal:(state)=>{
      state.isOpenRentalDurationModal=false;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setUserImageReference: (state, action) => {
      state.userImageReference = action.payload;
    },
    setAssetImageReference: (state, action) => {
      state.assetImageReference = action.payload;
    },
    setFilteredDataRequired: (state, action) => {
      state.filteredDataRequired = action.payload;
    },
    setFilterModalOpen: (state, action) => {
      state.filterModalOpen = action.payload;
    },
    setFilterByCountry: (state, action) => {
      state.filterByCountry = action.payload;
    },
    setFilterByState: (state, action) => {
      state.filterByState = action.payload;
    },
    setFilterByVerificationStatus: (state, action) => {
      state.filterByVerificationStatus = action.payload;
    },
    setFilterByEquipmentType: (state, action) => {
      state.filterByEquipmentType = action.payload;
    },

    sendConnectRequest: (state, {payload}) => {
      // console.log(payload)
      const marketplaceDetails = []
    const index = state.data.findIndex((card) => (parseInt(card.E9MarketPlaceId) === parseInt(payload)))
    // console.log(index)


    const selectedObject = current(state.data)[index]

    state.data[index] = selectedObject
    
    // console.log(selectedObject)
      state.data[index] = {...state.data[index], ResponseTypeValue: "Request Sent"}
    },
    sendRevokeRequest : (state, {payload}) => {
      const marketplaceDetails = []
      const index = state.data.findIndex((card) => (parseInt(card.E9MarketPlaceId) === parseInt(payload)))
      // console.log(index)
  
  
      const selectedObject = current(state.data)[index]
  
      state.data[index] = selectedObject
      
      // console.log(selectedObject)
        state.data[index] = {...state.data[index], ResponseTypeValue: ""}
    },
    resetFilters : (state) => {
        state.filterByEquipmentType = null;
        state.filterByCountry = null;
        state.filterByState = null;
        state.filterByVerificationStatus = null;
        state.filteredDataRequired = false;
        state.filterModalOpen = false;
      },
      updateE9MarketPlaceContactId :(state,{payload})=>{
        const item = state.data.find(d => d.AssetUniqueId == payload.AssetUniqueId);
        if(item) {
          item.E9MarketPlaceContactId = payload.LastE9MarketplaceContactId
        }
      },
  },
  extraReducers: (builder) => {
    builder
        .addCase(getRemFilterImagesDataThunk.pending, (state) => {
          state.equipmentTypeFilterList=null;
          state.filterIconImageReference=null;
        })
        .addCase(getRemFilterImagesDataThunk.fulfilled, (state,action) => {
          state.equipmentTypeFilterList=action.payload.GetEquipmentTypeFilterList;
          state.filterIconImageReference=action.payload.EquipmentTypeIconBaseReference;
        })
      .addCase(getRemInitialDataThunk.pending, (state) => {
        state.data = null;
        state.initialLoading = true;
        state.paginationLoading = false;
        state.error = false;
        state.errorMessage = false;
        state.endOfData = false;
      })
      .addCase(getRemInitialDataThunk.fulfilled, (state, action) => {
        state.data = action.payload.RentalEquipmentList;
        state.assetImageReference = action.payload.AssetImageBaseReference;
        state.userImageReference = action.payload.UserProfilePicBaseReference;
        state.paginationCount = 1;
        state.initialLoading = false;
        state.error = false;
        state.errorMessage = null;
        if (action.payload.RentalEquipmentList.length < state.cardCount) {
          state.endOfData = true;
        }
      })
      .addCase(getRemLoadMoreDataThunk.pending, (state) => {
        state.paginationLoading = true;
        state.error = false;
        state.errorMessage = null;
        state.endOfData = false;
      })
      .addCase(getRemLoadMoreDataThunk.fulfilled, (state, action) => {
        state.error = false;
        state.errorMessage = null;
        state.data = [...(state.data ?? []), ...action.payload];
        state.paginationLoading = false;
        if (action.payload.length >= state.cardCount) {
          state.paginationCount += 1;
        } else {
          state.endOfData = true;
        }
      })
      .addCase(getRemInitialFilteredDataThunk.pending, (state) => {
        state.initialLoading = true;
        state.paginationLoading = false;
        state.error = false;
        state.errorMessage = null;
        state.error = false;
        state.errorMessage = null;
      })
  
  .addCase(getRemInitialFilteredDataThunk.fulfilled, (state, action) => {
    state.data = action.payload.GetFilteredAllRentalEquipments;
    state.assetImageReference = action.payload.AssetImageBaseReference;
    state.userImageReference = action.payload.UserProfilePicBaseReference;
    state.paginationCount = 1;
    state.initialLoading = false;
    state.paginationLoading = false;
    state.error = false;
    state.errorMessage = null;
    if(Object.keys(action.payload).length > 0 && action.payload.GetFilteredAllRentalEquipments.length >= state.cardCount){
      state.endOfData = false;
    }
    else{
        state.endOfData = true;
    }
  })
  .addCase(getRemLoadMoreFilteredDataThunk.pending, (state) => {
    state.paginationLoading = true;
    state.error = false;
    state.errorMessage = null;
    state.error = false;
    state.errorMessage = null;
  })
  .addCase(getRemLoadMoreFilteredDataThunk.fulfilled, (state, action) => {
    state.error = false;
    state.errorMessage = null;
    state.paginationLoading = false;
    state.data = [...state.data, ...action.payload];
    if (action.payload.length >= state.cardCount) {
      state.paginationCount += 1;
    } else {
      state.endOfData = true;
    }
  })
},
});

export const {
  openRentalDurationModal,
  closeRentalDurationModal,
  setData,
  setAssetImageReference,
  setUserImageReference,
  setFilterByCountry,
  setFilterByEquipmentType,
  setFilterByState,
  setFilterByVerificationStatus,
  setFilterModalOpen,
  setFilteredDataRequired,
  sendConnectRequest,
  sendRevokeRequest,
  resetFilters,
  updateE9MarketPlaceContactId
} = rentalEquipmentMarketplaceSlice.actions;
