import { memo } from "react";
import { QuantityCounter } from "../QuantityCounter/QuantityCounter";
import "./SubscriptionTableRow.css";
import { useDispatch, useSelector } from "react-redux";
import { editSubscriptionTypeCount} from "../../features/subscription/slice/subscriptionSlice";
import { Button } from "@chakra-ui/react";

export const SubscriptionTableRow = memo(({
  name = "",
  pricePerUnit = 0,
  currencySymbol = "",
  vaildityDays = 0,
  freeServiceCount = 0,
  minimumQuantity = 0,
  maximumQuantity = 0,
  iterationValue = 0,
  discountPercentage = 0,
  checkedAction = () => {},
  unCheckedAction = () => {},
  selected = false
}) => {
   

    const {subscriptionTableData} = useSelector((state) => (state.subscription));
    const dispatch = useDispatch();

   
     
    const preSelectedValue = () => {
      const valueSelected = subscriptionTableData.find((cartItem) => (cartItem.ServiceTypeValue === name))
      if(valueSelected && valueSelected.quantitySelected){
       return valueSelected.quantitySelected
      }
      else{
        return minimumQuantity
      }
    }

    


     
       const checkboxClickHandler = (e) => {
       
            if(selected){
              return checkedAction()
            }
            else{
              return unCheckedAction()
            }
       }
    
  return (
    <tr className={`subscription-table__row ${selected ? "subscription-table__row--selected" : ""}`}>
      <td className="subscription-table__data subscription-table__data--heading">
        <p>{name}</p>
        {discountPercentage ? <div style={{backgroundColor: "#319F00", color: "#FFFFFF", maxWidth: "max-content", borderRadius: "1em", padding: "4px 8px 5px 8px", marginTop: "0.25em"}}>
          <p style={{lineHeight: "16px", fontSize:"12px" }}><span style={{fontWeight: 400}}>{`${discountPercentage}% `}</span>Discount</p>
        </div>: <></>}
        
      </td>
      <td className="subscription-table__data subscription-table__data--price-unit">
       {currencySymbol ? currencySymbol : ""} {pricePerUnit} / ea
      </td>
      <td className="subscription-table__data subscription-table__data">
        {vaildityDays} Days
      </td>
      <td className="subscription-table__data" style={{ textAlign: "center", width: "10%"}}>
        {freeServiceCount >= 10 ? freeServiceCount : `0${freeServiceCount}`}
      </td>
      <td className="subscription-table__data subscription-table__data--cta">
        <QuantityCounter
          mininmumQuantity={minimumQuantity}
          maximumQuantity={maximumQuantity}
          changeValue={iterationValue}
          valuefunction={(value) => {
            dispatch(editSubscriptionTypeCount({name, quantitySelected: value}))
          }}
          preSelectedValue={preSelectedValue()}
        />
         </td>
        <td>
          <div style={{display: "flex", justifyContent: "center"}}>
        <div className={`subscription-table__price ${selected ? "subscription-table__price--selected" : ""}`} style={{justifyContent: discountPercentage ? "" : "center"}}>
          <p
            style={{
              color: "#1E3264",
              textDecoration: "line-through",
              fontSize: "14px",
            }}
            >
            {discountPercentage ? `${currencySymbol ? currencySymbol : ""}${pricePerUnit * preSelectedValue()}` : ""}
          </p>
          <p style={{ fontSize: "18px" }}>
          {currencySymbol ? currencySymbol : ""}  {discountPercentage
              ? (
                pricePerUnit *
                (1 - discountPercentage / 100) *
                preSelectedValue()
                ).toFixed(2)
                : (pricePerUnit * preSelectedValue()).toFixed(2)}
          </p>
          </div>
        </div>
                </td>
                <td>
         <div style={{textAlign:"center"}}>
      
          <Button width="125px" border= "1px solid #F79C00" color= "#F79C00" backgroundColor= "#FFF" textTransform="uppercase" onClick={checkboxClickHandler}>{selected ?  "Added" : "Add"} </Button>
          </div>
          </td>
     
    </tr>
  );
});
