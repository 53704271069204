import React from 'react'
import './MaterialHeaderCard.css'
import shareIcon from "../../../assets/webapp/dashboard/share.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { useDispatch,useSelector } from 'react-redux';
import { openQrModal } from "../../features/dashboard/slice/dashboardSlice";
import { ReactSVG } from "react-svg";
import { Like } from '../Like/Like';
import { subString } from '../../helpers/dataConversion/substring';
import { Tooltip } from '@chakra-ui/react';




const MaterialHeaderCard = ({
    imageUrl,
    productName,
    maxPriceRange,
    minPriceRange,
    location,
    isOriginal,
    likeStatus,
    onLikeClick=()=>{},
    onShareClick=()=>{},
    materialData

}) => {

    const {currencyDetails } = useSelector((state)=>state.user)
    const dispatch = useDispatch();

    return (
        <div className="material-header-card-container">

            <Swiper
                pagination={{ clickable: true }}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
                style={{ height: "226px", width: "579px" }}
            >
                <div className="material-header-card-img">
                    <img
                        style={{ height: "100%", width: "100%", objectFit: "cover" }}
                        alt="image"
                        src={`${process.env.REACT_APP_CDN_PVT_URL}${imageUrl}`}
                        className="material-header-card-image"
                    />
                </div>
                {imageUrl && imageUrl.length > 0 ? (
                    imageUrl?.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img
                                style={{ height: "100%", width: "100%", objectFit: "fill" }}
                                alt="image"
                                src={`${process.env.REACT_APP_CDN_PVT_URL}${image.ImageFileRef}`}
                                className="dealership-header-card-image"
                            />
                        </SwiperSlide>
                    ))
                ) : (
                    <SwiperSlide>
                    <img
                      style={{ height: "100%", width: "100%", objectFit: "fill" }}
                      src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/DelarshipDefaultImage.png`}
                      className="dealership-header-card-image"
                    />
                  </SwiperSlide>
                )}
            </Swiper>
            
            <div className="material-body-card-container">
                <div className="material-body-card-container-left">
                    <div>
                        <Tooltip label={productName?.length>35 ? productName :null}>
                        <span className="material-body-text1">
                        {subString(35,productName)}
                        </span>
                        </Tooltip>
                       
                    </div>
                    <div>
                    <span className="material-body-text2">
                    {subString(
                          50,
                          ` PNo-${[
                            materialData
                            ?.ProductNumber ?     materialData
                            .ProductNumber : "NA",
                            materialData
                            ?.EquipmentTypeValue ?    materialData
                            .EquipmentTypeValue :"NA",
                            materialData
                            ?.OEMName?     materialData
                            .OEMName:"NA",
                            materialData
                            ?.ModelName?     materialData
                            .ModelName:"NA",
                          ].join(" • ")}`
                        )}
                        </span>
                    </div>
                    <div>
                        <span className="material-body-text2">
                        {currencyDetails?.CurrencyValue}<span>{minPriceRange}{"/-"}</span>&nbsp;to&nbsp;{currencyDetails?.CurrencyValue}{maxPriceRange}{"/-"}
                        </span>
                    </div>
                    <div >
                        <div className="material-details-section">
                            <div >
                                <span className="material-body-text3">{location ? location : "NA"}</span>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="material-body-card-container-right">
                    <button className="material-btn "
                    onClick={onShareClick}
                    >

                            <ReactSVG
                    src={shareIcon}
                    beforeInjection={(svg) => {
                      svg.setAttribute("style", "height: 20px");
                    }}
                  />
                    </button>
                    <button className="material-btn ">
                    <Like
                    isLiked={likeStatus}
                    onClick={onLikeClick}
                    />
                    </button>
                </div>
            </div>
           


        </div >
    )
}

export default MaterialHeaderCard
