import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Box, Button, Flex, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDealershipData,
  deleteRegionPayload,
  setAllDealersshipDataById,
  setFinanceProvided,
  setIsLoading,
  setManagerId,
  setRegionModalOpen,
  setRegionPayload,
} from "../../MyBusinessDealershipDetail/slice/myBusinessDealershipSlice";
import { RadioParent } from "../../../components/RadioParent/RadioParent";
import {
  getDealershipDetailsById,
  getResponseOptionType,
} from "../../../services/postDealership/postDealership";
import { RegionModal } from "../../../components/MyBuisinessDealershipPopUp/postDealershipStepsPopUp/RegionModal";
import { useNavigate, useParams } from "react-router-dom";
import { getSessionStorageItems } from "../../../helpers/sessionStorage/sessionStorage";
import { updateDealershipStepThreeThunk } from "../../MyBusinessDealershipDetail/thunk/updateDealershipStepThreeThunk";
import { postDealershipStepTwoThunk } from "../../MyBusinessDealershipDetail/thunk/postDealershipStepTwoThunk";

const AddDealershipStepThree = () => {
  const { serviceTypeId } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const [getResponseOptionTypeList, setGetResponseOptionTypeList] =
    useState("");
  const [responseTypeValue, setResponseTypeValue] = useState();
  const { postGuuid, getDealershipDataById, financeProvided, managerId } =
    useSelector((state) => state.myBusinessDealership);
  const [getAllRegion, setGetAllRegion] = useState();
  const editGuuid = getSessionStorageItems("EDIT_DEALERSHIP_GUUID");
  let regionPayload = JSON.parse(
    localStorage.getItem("POST_DEALERSHIP_REGION")
  );

  const validationSchema = Yup.object({});

  const initialValues = {
    Finance: financeProvided
      ? financeProvided
      : getDealershipDataById?.DealershipData?.FinanceProvidedId,
    dealershipManager: managerId
      ? managerId
      : getDealershipDataById?.DealershipData?.ManagerFullName,
  };
  useEffect(() => {
    (async () => {
      const data = await getDealershipDetailsById(editGuuid);
      if (data?.Status === 200) {
        dispatch(setAllDealersshipDataById(data?.Data?.DealershipDetails));
        const regionPayload = JSON.parse(
          data?.Data?.DealershipDetails?.RegionDetails
        );
        dispatch(setRegionPayload(regionPayload));
      }
    })();
  }, [editGuuid, regionPayload]);

  useEffect(() => {
    (async () => {
      const data = await getResponseOptionType();
      if (data?.Data?.Success === true) {
        setGetResponseOptionTypeList(data?.Data?.ResponseOptionType);

        const requiredData = data?.Data?.ResponseOptionType.map((options) => ({
          id: options.ResponseOptionId,
          value: options.ResponseValue,
        }));
        return setResponseTypeValue(requiredData);
      }
    })();
  }, []);

  useEffect(() => {}, [regionPayload]);

  const handleSubmit = async (values) => {

    dispatch(setFinanceProvided(values.Finance));
    dispatch(setManagerId(values.dealershipManager));

    if (editGuuid) {
      // editPostDealership(values)
      try {
        dispatch(setIsLoading(true));
        await dispatch(updateDealershipStepThreeThunk()).unwrap();
        toast({
          title: "Details Saved Successfully",
          position: "top-right",
          status: "success",
        });
        dispatch(clearDealershipData());
        sessionStorage.clear();
        localStorage.removeItem("POST_DEALERSHIP_IMAGE_PAYLOAD");
        localStorage.removeItem("POST_DEALERSHIP_REGION");
        localStorage.removeItem("POST_DEALERSHIP_VIDEO_PAYLOAD");
        localStorage.removeItem("POST_DEALERSHIP_IMAGE_LIST");
        sessionStorage.removeItem("DEALERSHIP_PHOTO_TYPE_LIST");
        localStorage.removeItem("POST_DEALERSHIP_VIDEO_LIST");
        sessionStorage.removeItem("DEALERSHIP_VIDEO_TYPE_LIST");
        sessionStorage.removeItem("POST_DEALERSHIP_STEPPER_INDEX");
        navigate(`/my-business-dealerships-listing/${serviceTypeId}`, {
          replace: true,
        });
      } catch (error) {
        toast({
          title: "Failed ",
          position: "top-right",
          status: "error",
        });
        dispatch(clearDealershipData());
        sessionStorage.clear();
        localStorage.removeItem("POST_DEALERSHIP_REGION");
        localStorage.removeItem("POST_DEALERSHIP_IMAGE_LIST");
        sessionStorage.removeItem("DEALERSHIP_PHOTO_TYPE_LIST");
        localStorage.removeItem("POST_DEALERSHIP_VIDEO_LIST");
        sessionStorage.removeItem("DEALERSHIP_VIDEO_TYPE_LIST");
        sessionStorage.removeItem("POST_DEALERSHIP_STEPPER_INDEX");
        navigate(`/my-business-dealerships-listing/${serviceTypeId}`, {
          replace: true,
        });
      }finally{
        dispatch(setIsLoading(false));
      }
    } else {
      try {
        dispatch(setIsLoading(true));
        await dispatch(postDealershipStepTwoThunk()).unwrap();
        toast({
          title: "Details Saved Successfully",
          position: "top-right",
          status: "success",
        });
        dispatch(clearDealershipData());
        sessionStorage.clear();
        localStorage.removeItem("POST_DEALERSHIP_REGION");
        navigate(`/my-business-dealerships-listing/${serviceTypeId}`, {
          replace: true,
        });
      } 
      catch (error) {
        toast({
          title: "Failed ",
          position: "top-right",
          status: "error",
        });
        dispatch(clearDealershipData());
        sessionStorage.clear();
        dispatch(clearDealershipData());
        sessionStorage.clear();
        localStorage.removeItem("POST_DEALERSHIP_REGION");
        localStorage.removeItem("POST_DEALERSHIP_IMAGE_LIST");
        sessionStorage.removeItem("DEALERSHIP_PHOTO_TYPE_LIST");
        localStorage.removeItem("POST_DEALERSHIP_VIDEO_LIST");
        sessionStorage.removeItem("DEALERSHIP_VIDEO_TYPE_LIST");
        navigate(`/my-business-dealerships-listing/${serviceTypeId}`, {
          replace: true,
        });
      }finally{
        dispatch(setIsLoading(false));
      }
    }
  };

  return (
    <>
      <RegionModal />
      {/* <Flex className="form-one-demo" flexDirection={"column"} gap={"1em"}>
        <div
          style={{
            margin: "0px 52px",
            backgroundColor: "#92929204",
            paddingTop: "1em",
          }}
        >
          <div>
            <h1 style={{ fontSize: "32px", fontWeight: 700 }}>
              Additional Details
            </h1>
            <p className="my-business-text-description">
              Upload Your Region and Other Details
            </p>
          </div>
        </div>

        <Flex flexDirection="column" gap="1em" height="100%">
          <Box maxH="347px" h="100%" overflowY={"scroll"}> */}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form id="post-dealership-step-three">
            <Flex
              alignItems={"center"}
              justifyContent={"space-between"}
              flexWrap={"wrap"}
              padding={"2em"}
              gap={"1em"}
            >
              <div className="region-details-element-container">
                <div className="additional-details-photos-container">
                  <h1 className="additional-details-header-text">Region</h1>
                  <Button
                    className="additional-details-button"
                    onClick={() => dispatch(setRegionModalOpen())}
                  >
                    <img
                      className="additional-details-btn-img"
                      src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/Postdealership/add.svg`}
                    />
                    Add
                  </Button>
                </div>

                <div
                  className="rigions-display-container"
                  style={{ overflowY: "scroll" }}
                >
                  {regionPayload && regionPayload?.length > 0 ? (
                    regionPayload.map((region) => (
                      <Flex direction="column" alignItems="center">
                        <div className="media-container">
                          <div className="media-heading">
                            <h1 className="media-heading-text">
                              {region?.states?.value}
                            </h1>
                          </div>

                          <div className="region-body-container">
                            {region?.cities.length > 0 ? (
                              region?.cities.map(
                                (city, index) =>
                                  city && (
                                    <li className="region-body" key={index}>
                                      {city?.value}
                                    </li>
                                  )
                              )
                            ) : (
                              <li></li>
                            )}
                          </div>
                        </div>
                        <div className="region-footer-container">
                          <Button
                            backgroundColor="transparent"
                            onClick={() =>
                              dispatch(deleteRegionPayload(region?.states))
                            }
                          >
                            <img
                              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/delete.svg`}
                              style={{
                                maxHeight: "40px",
                                maxWidth: "40px",
                              }}
                            />
                          </Button>
                        </div>
                      </Flex>
                    ))
                  ) : (
                    <div className="media-container">
                      <div className="media-heading">
                        <h1 className="media-heading-text">Region</h1>
                      </div>
                      <div className="media-body">Add Region</div>
                    </div>
                  )}
                </div>
              </div>

              <div className="additional-details-element-container">
                <Field name="financeValue">
                  {({ field }) => {
                    return (
                      <>
                        <h1 className="additional-details-header-text">
                          Finance
                        </h1>
                        <RadioParent
                          name="Finance"
                          defaultValue={values.Finance}
                          options={responseTypeValue}
                          maxWidth="450px"
                          isFormik={true}
                          label="Do You Provide Finance?"
                        />{" "}
                      </>
                    );
                  }}
                </Field>
              </div>
            </Flex>
          </Form>
        )}
      </Formik>
      {/* </Box>
        </Flex>
      </Flex> */}
    </>
  );
};

export default AddDealershipStepThree;
