import { Text } from "@chakra-ui/react";
import { CustomGeneralModal } from "../../../components/CustomGeneralModal/CustomGeneralModal";

export const ByPremiumPopup = ({ isOpen, primaryAction,secondaryAction }) => {
  return (
    <>
      <CustomGeneralModal
        isOpen={isOpen}
        primaryActionText={"Buy"}
        primaryAction={primaryAction}
        secondaryActionText={"No Thanks"}
        secondaryAction={secondaryAction}
        hasCloseButton={true}
        onCloseButtonClick={secondaryAction}
        isArrow={true}
        iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/e9-services/byPremium.svg`}
        heading={"Buy Premium"}
        subHeading={<Text fontSize={"16px"} fontWeight={"700"} color={"#002961"}>You need Premium Subscription to Contact Owner.Continue to buy.</Text>}
      ></CustomGeneralModal>
    </>
  );
};
