import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOwnerMarketplaceList } from "../../../services/ownerMarketplace/ownerMarketplace";

export const getLoadMoreDataThunk = createAsyncThunk(
  "owners/loadMoreData",
  async (_, { getState }) => {
    const cardCount = getState().ownersMarketplace.cardCount;
    const pageNumber = getState().ownersMarketplace.paginationCount;

    try {
      const data = await getOwnerMarketplaceList(
        cardCount,
        pageNumber * cardCount
      );

      if (data.Status === 200) {
        if (data.Data.E9OwnerMarketplaceList.length > 0) {
          return data.Data.E9OwnerMarketplaceList;
        } else {
          return [];
        }
      }
    } catch (error) {}
  }
);
