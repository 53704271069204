import { API_CONSTANTS } from "../../../constants/apiConstants";
import { convertToIdValuePairs } from "../../helpers/dataConversion/convertToIdValuePairs";
import { fetchHandler } from "../../../index";

export const getAccomodationTypes = async () => {
  try {
    const { data } = await fetchHandler(
      API_CONSTANTS.GET_ACCOMODATION_TYPE_LIST
    );
    if (data.Status === 200 && Object.keys(data.Data).length > 0) {
      const requiredData = data.Data.AccomodationList.map(
        ({ AccomodationId, AccomodationValue }) =>
          convertToIdValuePairs(AccomodationId, AccomodationValue)
      );
      return requiredData;
    }
    return null;
  } catch (error) {
    throw error;
  }
};
