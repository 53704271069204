import { useState, useEffect, memo } from "react";
import "./QuantityCounter.css";
export const QuantityCounter = memo(
  ({
    mininmumQuantity = 0,
    maximumQuantity = 0,
    changeValue = 1,
    preSelectedValue = 0,
    valuefunction = () => {},
  }) => {
    const [count, setCount] = useState(
      preSelectedValue > 0 ? preSelectedValue : mininmumQuantity
    );

    useEffect(() => {
      valuefunction(count);
    }, [count]);

    const handleIncrement = (e) => {
      if (count + changeValue >= maximumQuantity) {
        return setCount(maximumQuantity);
      }
      e.target.parentElement.classList.add("counter__increment");
      e.target.previousElementSibling.classList.add("counter__increment__text");
      setCount((prevCount) => prevCount + changeValue);
    };
    const handleDecrement = (e) => {
      if (count - changeValue <= mininmumQuantity) {
        return setCount(mininmumQuantity);
      }
      e.target.parentElement.classList.add("counter__decrement");
      e.target.nextElementSibling.classList.add("counter__decrement__text");
      setCount((prevCount) => prevCount - changeValue);
    };

    return (
      <div
        style={{
          border: "1px solid #F79C00CC",
          borderRadius: "4px",
          display: "flex",
          maxWidth: "100px",
          minWidth: "100px",
          width: "100%",
          maxHeight: "30px",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.25em 0.35em",
        }}
        onAnimationEnd={(e) => {
          e.target.classList.remove("counter__decrement");
          e.target.classList.remove("counter__increment");
        }}
      >
        <button
          disabled={count === mininmumQuantity}
          style={{
            background: "transparent",
            color: "#D60000",
            fontWeight: "600",
            fontSize: "18px",
          }}
          onClick={handleDecrement}
        >
          -
        </button>
        <p
          onAnimationEnd={(e) => {
            e.target.classList.remove("counter__increment__text");
            e.target.classList.remove("counter__decrement__text");
          }}
        >
          {count}
        </p>
        <button
          style={{
            background: "transparent",
            color: "#319F00",
            fontWeight: "600",
            fontSize: "18px",
          }}
          background="#ffff"
          onClick={handleIncrement}
        >
          +
        </button>
      </div>
    );
  }
);
