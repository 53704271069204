import { Flex } from "@chakra-ui/react"

export const V5SidebarItem = ({onClick, children}) => {
    return <Flex
    
      width="100%"
      alignItems="center"
      gap="1em"
      padding="0 1em"
      borderTopRightRadius="1rem"
      borderBottomRightRadius="1rem"
      height="60px"
      minH="60px"
      cursor="pointer"
      _hover={{
        bgColor: "#fffbf5",
        color: "#f79c00",
        fontWeight: "600",
      }}
      onClick={onClick}
    >
      {children}
    </Flex>
       
}