import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMaterialRequestReceivedCountById } from "../../../services/materials/materials";

export const getMaterialRequestReceivedCountByIdThunk=createAsyncThunk("my-material/get-request-received-count",async (materialTypeId)=>{
    try {
        const data= await getMaterialRequestReceivedCountById(materialTypeId);
        return data;
    } catch (error) {
        throw(error);
    }

})