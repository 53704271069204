import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  useRadioGroup,
} from "@chakra-ui/react";
import { v4 as uuid } from "uuid";
import { useField } from "formik";
import { ReactSVG } from "react-svg";
import { RadioCard } from "../RadioCard/RadioCard";
import doubleTick from "../../../assets/webapp/common/double-tick.svg";

export const RadioParent = ({
  name = "",
  defaultValue = "",
  options = [],
  isFormik = false,
  minWidth = "374px",
  maxWidth = "374px",
  height="52px",
  label,
  isRequired,
  isDisabled,
  errorMessage = " ",
  onChange = () => {}
}) => {
  const [field, meta, helpers] = useField(name);

  const handleChange = (value) => {
    onChange(value)
    helpers.setValue(value);
  };

  return (
    <FormControl
      isDisabled={isDisabled}
      isInvalid={meta.touched && meta.error}
      isRequired={isRequired}
      maxW={maxWidth}
    >
      <FormLabel>{label}</FormLabel>
      <Flex
        maxW={maxWidth}
        w="100%"
        h={height}
        minW={minWidth}
        border="1px solid #E2E8F0"
        borderRadius="4px"
        justifyContent={options && options.length > 0 ? "" : "center"}
        alignItems={options && options.length > 0 ? "" : "center"}
      >
        {options && options.length > 0 ? (
          options.map((element, index) => {

            const { id, value } = element;
            const isChecked = defaultValue !== "" ? parseInt(defaultValue) === parseInt(id) : parseInt(field.value) === parseInt(id) ;
            const radio = {
              value: id,
              // checked: parseInt(field.value) === parseInt(id),
              checked: isChecked,
              onChange: () => handleChange(id),
            };

            return (
              <RadioCard
                key={uuid()}
                {...radio}
                width={`${100 / options.length}%`}
                borderRightRequired={index !== options.length - 1}
              >
                {radio.checked ? (
                  <Flex alignContent="center" justifyContent="center" gap="0.25em">
                    <ReactSVG
                      src={doubleTick}
                      beforeInjection={(svg) => {
                        svg.setAttribute("style", "height: 20px; width: 18px");
                      }}
                    />
                    <Text color={radio.checked ? "#2c2c2c" : "#2c2c2c90"} fontSize="14px" fontWeight={radio.checked ? 600 : 500}>{value}</Text>
                  </Flex>
                ) : (
                    <Text color={radio.checked ? "#2c2c2c" : "#2c2c2c90"} fontSize="14px" fontWeight={radio.checked ? 600 : 500}>{value}</Text>
                )}
              </RadioCard>
            );
          })
        ) : (
          <Text fontStyle="italic">Options not available.</Text>
        )}
      </Flex>
      <FormErrorMessage>
        {meta.touched && meta.error ? meta.error : errorMessage}
      </FormErrorMessage>
    </FormControl>
  );
};