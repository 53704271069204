import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllTopOperatorPartnersList } from "../../../services/jobsPosted/jobsPosted";

export const getInitialDataThunk = createAsyncThunk("nearMeOp/initialData", async(_, {getState}) => {
    try{
    const cardCount = getState().operatorPartnerNearMe.cardCount;
    const pageNumber =  getState().operatorPartnerNearMe.paginationCount;
   
        const data = await getAllTopOperatorPartnersList(cardCount,(pageNumber * cardCount));

        if(data.Status === 200 && data.Data.TotalRecords > 0){
            return {data: data.Data.NearMeDetails, imageBaseUrl: data.Data.ImageBaseUrl}
        }
    }
    catch(error){
    }
})