import { useSelector } from "react-redux";
import "./MaterialDetailsCard.css";

export const MaterialDetailsCard = ({
  heading = "",
  description = "",
  imgSrc = "",
  videoUrl = "",
  formatType = false,
  minPrice = "",
  maxPrice = "",
  onDescriptionClick = () => {},
  descriptionCursorRequired = false,
}) => {
  const { currencyDetails } = useSelector((state) => state.user);

  return (
    <div className="materials-card">
      {imgSrc && (
        <img
          className="materials-description-card-img"
          style={{ height: "60px" }}
          src={imgSrc}
        />
      )}

      {videoUrl && (
        <div className="materials-description-card-video">
          <video style={{ height: "100%", width: "100%" }} controls>
            <source src={videoUrl} type="video/mp4" />
          </video>
        </div>
      )}
      {formatType === true ? (
        <h1 className="materials-card__heading">
          {currencyDetails?.CurrencyValue? currencyDetails?.CurrencyValue : <>&#x20B9;</>}
          {minPrice}/- &nbsp; To &nbsp; {currencyDetails?.CurrencyValue? currencyDetails?.CurrencyValue : <>&#x20B9;</>}
          {maxPrice}/-
        </h1>
      ) : (
        <h1 className="materials-card__heading">{heading}</h1>
      )}
      <p>{formatType}</p>
      <p
        className="materials-description-card__description"
        onClick={onDescriptionClick}
      >
        {description}
      </p>
    </div>
  );
};
