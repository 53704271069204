import { Card } from "@chakra-ui/react";
import { forwardRef } from "react";

export const V5Card = forwardRef(({
    minHeight,
    height,
    maxHeight,
    minWidth,
    maxWidth,
    width,
    padding,
    margin,
    border,
    borderColor,
    backgroundColor = "#FFFFFF",
    boxShadow, 
    onClick = () => {},
    value,
    isSelected = false,
    children,
  }, ref) => {
    return (
      <Card
        as="button"
        type="button"
        display="block"
        minHeight={minHeight}
        height={height}
        maxHeight={maxHeight}
        minWidth={minWidth}
        width={width}
        maxWidth={maxWidth}
        padding={padding}
        margin={margin}
        border={isSelected ? "1px solid #F67c00" : border ? border : "1px solid transparent"}
        borderColor={isSelected ? "#F67c00" : borderColor ? borderColor : "transparent"}
        backgroundColor={isSelected ? "#FFFBF5" : backgroundColor}
        bgColor={isSelected ? "#FFFBF5" : backgroundColor}
        boxShadow={boxShadow}
        value={value}
        onClick={onClick}
        ref={ref}
      >
        {children}
      </Card>
    );
  });
  
