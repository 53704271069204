import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  Heading,
  Box,
  Container,
  Text,
  Wrap,
  Flex,
  Button,
  useToast,
} from "@chakra-ui/react";
import {
  getAllLanguageJsonThunk,
  getAllLanguagesThunk,
} from "../../../global/mtData/thunk/getAllLanguagesThunk";
import { getCountriesThunk } from "../../../global/mtData/thunk/getCountriesThunk";
import { SelectCardWrapper } from "../../../../components/SelectCardWrapper/SelectCardWrapper";
import {
  setCountry,
  setLanguage,
} from "../../../global/appConfig/slice/appConfigSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { convertToBase64 } from "../../../../../utils/formatConverter/convertToBase64";
import { APP_CONFIG_LOCAL_STORE } from "../../../global/appConfig/constants/localStoreConstants";
import { getDefaultAppLanguage } from "../../../global/mtData/helpers/localStorage";
import { setSelectedCountryThunk } from "../../../global/appConfig/thunk/setSelectedCountryThunk";
import { V5AdDividerLayout } from "../../../../layout/v5AdDividerLayout/V5AdDividerLayout";
import { NAVIGATION_CONSTANTS } from "../../../../../constants/naigationContants";

export const V5SelectLanguage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const masterData = useSelector((state) => state.masterData);
  const appConfig = useSelector((state) => state.appConfig);

  const allLanguages = localStorage.getItem(
    APP_CONFIG_LOCAL_STORE.ALL_LANGUAGES
  );
  const [localLanguage, setLocalLanguage] = useState();
  const defaultLanguage = getDefaultAppLanguage();
  const appLanguageType = localStorage.getItem(
    APP_CONFIG_LOCAL_STORE.APP_LANGUAGE
  );
  const assetIdParam = searchParams.get(
    NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID
  );

  const languageButtonClickHandler = (e) => {
    const selectedLanguage = localLanguage.find(
      (language) => parseInt(e.target.value) === language.LanguageOptionTypeId
    );
    if (selectedLanguage?.IsDisabled !== 1) {
      dispatch(setLanguage(selectedLanguage));
      dispatch(
        getAllLanguageJsonThunk(selectedLanguage?.JsonFileName.toLowerCase())
      );
    } else {
      toast({
        title: "This language is not supported yet.",
        status: "info",
        isClosable: true,
        position: "top-right",
      });
    }
  };
  useEffect(() => {
    if (!defaultLanguage) {
      dispatch(setSelectedCountryThunk("91"));
      dispatch(getAllLanguageJsonThunk("english.json"));
    }
  }, [!defaultLanguage]);

  useEffect(() => {
    dispatch(getCountriesThunk());
    dispatch(getAllLanguagesThunk(convertToBase64("IND")));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllLanguagesThunk(convertToBase64("IND")));
  }, [appConfig.country]);

  useEffect(() => {
    if (masterData.Countries && !appConfig.Country) {
      const defaultCountry = masterData.Countries.find(
        (country) => parseInt(country.CountryIsdCode) === 91
      );
      dispatch(setCountry(defaultCountry));
    }
  }, [masterData.Countries]);
  useEffect(() => {
    if (allLanguages) {
      setLocalLanguage(JSON.parse(allLanguages));
    }
  }, [allLanguages]);
  return (
    <V5AdDividerLayout
      isDisabled={appConfig.language ? false : true}
      buttonText="Continue"
      onSubmitClick={() =>
        navigate(
          assetIdParam
            ? `/welcome/welcome?${NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID}=${assetIdParam}`
            : "/welcome/welcome"
        )
      }
      hasFooter={true}
    >
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        height={"100%"}
      >
        <Container>
          <Box>
            <Heading fontWeight="normal" fontSize="6xl" mb="2" mt="1em">
              {defaultLanguage?.Hello} &#128075;
            </Heading>
            <Text fontSize="xl">
              {defaultLanguage?.Select_the_language_that_youre_comfortable_with}
            </Text>
          </Box>

          <Wrap mt="2em" maxH="300px" overflowY="scroll">
            {localLanguage && localLanguage.length > 0 ? (
              localLanguage.map((language) => (
                <SelectCardWrapper
                  minWidth="115px"
                  minHeight="75px"
                  height="100%"
                  width="100%"
                  maxHeight="75px"
                  maxWidth="115px"
                  key={language.LanguageOptionTypeId}
                  value={language.LanguageOptionTypeId}
                  onClick={languageButtonClickHandler}
                  isSelected={
                    parseInt(language.LanguageOptionTypeId) ===
                    appConfig.language?.LanguageOptionTypeId
                      ? true
                      : false
                  }
                  fontWeight={700}
                  fontSize="16px"
                  backgroundcolor="#FEFEFE"
                  border="2px solid #F5F5F5"
                >
                  {language.LanguageOptionValue}
                </SelectCardWrapper>
              ))
            ) : (
              <>
                <h1>Loading ... </h1>
              </>
            )}
          </Wrap>
        </Container>
        {/* <Box>
          <Flex
            justifyContent="center"
            alignItems="center"
            bgColor="#fff"
            h="78px"
            style={{ boxShadow: "0px -10px 15px #2c2c2c0f" }}
          >
            <Button
              variant="gradient"
              fontWeight="semibold"
              width="80%"
              margin="0 auto"
              maxW="347px"
              isDisabled=
              {appConfig.language ? false : true}>Continue
            </Button>
          </Flex>
        </Box> */}
      </Flex>
    </V5AdDividerLayout>
  );
};
