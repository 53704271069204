import { APP_CONFIG_LOCAL_STORE } from "../../constants/localStoreConstants"
import { getLocalStorageItems } from "../../../../../helpers/localStorage/localStorage"

export const getCountryFromLocalStore = () => {
    if(getLocalStorageItems(APP_CONFIG_LOCAL_STORE.APP_COUNTRY)){
        return getLocalStorageItems(APP_CONFIG_LOCAL_STORE.APP_COUNTRY)
    }
    return null
}

export const getLanguageFromLocalStore = () => {
    if(getLocalStorageItems(APP_CONFIG_LOCAL_STORE.APP_LANGUAGE)){
        return getLocalStorageItems(APP_CONFIG_LOCAL_STORE.APP_LANGUAGE)
    }
    return null
}