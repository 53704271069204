import { useEffect, useState } from "react";
import { getQuizTermsAndConditions } from "../../../services/quiz/quiz";
import { V5SidebarLayout } from "../../../layout/V5SidebarLayout/V5SidebarLayout";
import { Flex, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";

export const QuizTermsAndConditions = () => {

  const {country} = useSelector((state)=>state.appConfig);
  const {accountDetails} = useSelector((state)=>state.user);


  const [quizdata, setQuizdata] = useState(null);

  useEffect(() => {
    (async () => {
      const data = await getQuizTermsAndConditions(country?.CountryId,);
      setQuizdata(data?.Data?.PageTypeData?.DynamicPageContent);
    })();   
  }, []);

  return (
    <>
      <V5SidebarLayout>
        <Flex
         
          direction={"column"}
          margin={"0 52px 20px 52px"}
          gap={"1em"}
        >
          <Flex justifyContent={"center"} w={"100%"}>
            <Text color={"#1A1E21"} fontSize={"32px"} fontWeight={"700"}>
              Terms & Condition
            </Text>
          </Flex>

          <Text color={"#2c2c2c"} fontSize={"18px"} fontWeight={"700"} textDecoration="underline"> Terms and condition</Text>
         
          <Flex dangerouslySetInnerHTML={{ __html: quizdata }} gap={"14px"} direction={"column"} />
  
        </Flex>
      </V5SidebarLayout>
    </>
  );
};
