import React, { useState } from "react";
import { FilterPopUp } from "../../marketplace/hoc/FilterPopUp/FilterPopUp";
import { QrPopUp } from "../../../components/ScanQrCodeModal/QrPopUp";
import { v4 as uuid } from "uuid";
import { MarketPlaceLayout } from "../../../layout/dealershipMarketplaceLayout/MarketPlaceLayout";
import { MarketplaceHeader } from "../../../components/MarketplacHeader/MarketplaceHeader";
import { MarketplaceHoc } from "../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sparePartsInitialData } from "../thunk/sparePartsInitialDataSlice";
import { MaterialCard } from "../../../components/MaterialsCard/MaterialCard";
import {
  setFilterByCountry,
  setFilterByState,
  setFilterModalOpen,
  setFilteredDataRequired,
  setLikedData,
  setLikedListdata,
  setMaterialResponseTypeId,
  setMaterialsLocation,
  setVideoList,
  setfilterByEquipmentMakeId,
  setfilterByEquipmentTypeId,
  setisOriginal,
  updateResponseTypeId,
  updateRemoveFromMarketPlace,
} from "../slice/sparePartsMaterialMarketplace";
import { sparePartLoadMoreDataThunk } from "../thunk/sparePartLoadMoreDataThunk";
import { resetFilters } from "../slice/sparePartsMaterialMarketplace";
import { sparePartInitialFilteredDataThunk } from "../thunk/sparePartInitialFilteredDataThunk";
import { MARKETPLACE_TYPES } from "../../../constants/e9Constants";
import { sparePartLoadMoreFilteredDataThunk } from "../thunk/sparePartLoadMoreFilteredDataThunk";
import { useLocation, useNavigate } from "react-router-dom";
import {
  convertFromBase64,
  convertToBase64,
} from "../../../../utils/formatConverter/convertToBase64";
import {
  contactSeller,
  getMaterialLikeList,
  getResponseTypeDetails,
  postMaterialLikes,
  postMaterialTransaction,
  removeMaterialFromMarketplace,
  updateMaterialTransaction,
} from "../../../services/materials/materials";
import { useToast } from "@chakra-ui/react";
import { getTransactionModeDetailsThunk } from "../../myLeads/thunk/getTransactionModeDetailsThunk";
import { getCheckUserLead } from "../../userLeadsCheck/helper/userLeadsCheck";
import { setUserLeadsData } from "../../userLeadsCheck/slices/userLeadsCheckSlice";

export const SparePartMaterialMarketplace = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const toast = useToast();

  const materialTypeId = convertFromBase64(queryParams.get("materialTypeId"));

  const dispatch = useDispatch();
  const { data, likeListData } = useSelector((state) => state.sparePartsMaterialMarketplace);
  const materialMarketplace = useSelector((state) => state.sparePartsMaterialMarketplace);
  const { UserTypeValue, accountDetails } = useSelector((state) => state.user);
  const { transactionModeDetails } = useSelector((state) => state.myLeads);
  const { data:userLeads} = useSelector((state) => state.userLeads);


  const UserCredentialId = accountDetails?.UserDetails?.CredentialDetails?.UserCredentialId;


  useEffect(() => {
    sessionStorage.clear();
  }, []);

  useEffect(() => {
    dispatch(getTransactionModeDetailsThunk());
    dispatch(sparePartsInitialData());
  }, []);

  useEffect(() => {
    mergeLike();
  }, [likeListData, data]);

  const [responseTypeDetailsByResponseIds,setResponseTypeDetailsByResponseIds]= useState({});

  useEffect(() => {
    const fetchData = async () => {
        try {
            const data = await getResponseTypeDetails();
            const obj={};
            data.ResponseTypes.forEach(({ResponseTypeId, ResponseTypeValue}) => {
              obj[ResponseTypeId] = ResponseTypeValue;            
            })
            setResponseTypeDetailsByResponseIds(obj);
        } catch (error) {
            console.error("Error fetching response type details:", error);
        }
    };
    fetchData();
}, []);

  const mergeLike = async () => {
      const likedData = (data || []).map((material) => {
        const matchingLike = (likeListData || []).find(
          (likedItem) => likedItem.MaterialId === material.MaterialId
        );

        if (matchingLike) {
          return {
            ...material,
            likeStatus: true,
          };
        } else {
          return {
            ...material,
            likeStatus: false,
          };
        }
      });

      dispatch(setLikedData(likedData));
  };

  useEffect(() => {
    (async () => {
      await getLikeList();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const userLeadsData = await getCheckUserLead(8,"material",UserCredentialId);
      dispatch(setUserLeadsData(userLeadsData));
    })();
  }, []);

  const getLikeList = async () => {
    const data = await getMaterialLikeList(materialTypeId);

    if (data) {
      dispatch(setLikedListdata(data?.Data?.LikeList));
    } else {
      dispatch(setLikedListdata([]));
    }
  };

  const handleLike = async (MaterialId) => {
    const body = {
      MaterialTypeId: materialTypeId,
      MaterialId: MaterialId,
    };
    try {
      const data = await postMaterialLikes(body);
      if (data?.Status === 201 || data?.Status === 204) {
        getLikeList();
      } else {
        console.error(data);
      }
    } catch (error) {
      throw error;
    }
  };

  const filterFunction = (values) => {
    dispatch(setfilterByEquipmentMakeId(values.equipmentMake));
    dispatch(setfilterByEquipmentTypeId(values.equipmentTypeId));
    dispatch(setisOriginal(values.isOriginal));
    dispatch(setFilterByCountry(values.country));
    dispatch(setFilterByState(values.state));
    if (
      values.equipmentMake ||
      values.equipmentTypeId ||
      values.isOriginal ||
      values.country ||
      values.state
    ) {
      dispatch(setFilteredDataRequired(true));
    } else {
      dispatch(setFilteredDataRequired(false));
    }
    dispatch(sparePartInitialFilteredDataThunk());
    dispatch(setFilterModalOpen(false));
  };

  
  const removeFromMarketPlace = async ({MaterialId}) => {
    try {
      const data = await removeMaterialFromMarketplace(materialTypeId,MaterialId);
      if (data?.Status === 204) {
        dispatch(updateRemoveFromMarketPlace({MaterialId}));
      } else {
        console.error(data);
      }
    } catch (error) {
      throw error;
    }
  };

  const updateTransactionStatus = async (connectData) => {

    const Payload = {
      MaterialTypeId: materialTypeId,
      MaterialId: connectData?.MaterialId,
      ResponseTypeId: 1,
      ReceiverUserCredentialId: connectData?.OwnerUserCredentialId,
      MaterialMarketplaceId: connectData?.MaterialMarketplaceId,
      ServiceTypeId: 9,
    };

    const freeContactRemaining =userLeads?.freeData?.LeadRemaining
    const premiumContactRemaining=userLeads?.premium?.LeadRemaining
    const subscriptionContactRemaining=userLeads?.subscription?.LeadRemaining
  
    const getTransactionModeId = () => {
      if (freeContactRemaining > 0) {
        const foundItem = transactionModeDetails.find(
          (item) => item.TransactionModeValue === "Free"
        );
        return foundItem?.TransactionModeId || null;
      }
  
      if (premiumContactRemaining > 0) {
        const foundItem = transactionModeDetails.find(
          (item) => item.TransactionModeValue === "Premium"
        );
        return foundItem?.TransactionModeId || null;
      }
  
      if (subscriptionContactRemaining > 0) {
        const foundItem = transactionModeDetails.find(
          (item) => item.TransactionModeValue === "Subscription"
        );
        return foundItem?.TransactionModeId || null;
      }
      return null;
    };
    Payload.TransactionModeId = getTransactionModeId();

    try {
      const res = postMaterialTransaction(Payload);
      if (res) {
        const userLeadsData=await getCheckUserLead(8,"material",UserCredentialId);
        dispatch(setUserLeadsData(userLeadsData));
      }
    } catch (error) {
      console.error("Error posting material transaction:", error);
    }
  };
  

  const revokeTransationStatus = async (connectData) => {

    const Payload = {
      MaterialTypeId: materialTypeId,
      MaterialId: connectData?.MaterialId,
      ResponseTypeId: 4,
      ReceiverUserCredentialId: connectData?.OwnerUserCredentialId,
      MaterialMarketplaceId: connectData?.MaterialMarketplaceId,
      ServiceTypeId: 9,
      TransactionModeId: null,
    };
   
    try {
      const res =await updateMaterialTransaction(Payload);
      if (res) {
        const userLeadsData=await getCheckUserLead(8,"material",UserCredentialId);
        dispatch(setUserLeadsData(userLeadsData));
      }
    } catch (error) {}
  };

  const handleContactSeller = async (item) => {
 
    if (UserTypeValue?.toLowerCase() === "company" && !accountDetails?.CompanyDetails?.TaxNumber)
      toast({
        status: "error",
        description: "Please update GST Number to Contact Owner.",
        position: "top-right",
        isClosable: true,
      });
    else {
      let totalCount = 0;

      const freeContactRemaining =userLeads?.freeData?.LeadRemaining
      const premiumContactRemaining=userLeads?.premium?.LeadRemaining
      const subscriptionContactRemaining=userLeads?.subscription?.LeadRemaining
        

      totalCount = freeContactRemaining + premiumContactRemaining + subscriptionContactRemaining;

      if (totalCount > 0) {
        const {
          ResponseTypeId,
          MaterialId,
          OwnerUserCredentialId,
          MaterialUniqueId,
        } = item;
        const connectData = {
          MaterialTypeId: materialTypeId,
          MaterialId: MaterialId,
          OwnerId: OwnerUserCredentialId,
          ResponseTypeId,
        };
      
        var resTypeId = ResponseTypeId == null || ResponseTypeId == 4 ? 1 : 4;
        try {
          const { data, payload } = await contactSeller(connectData);
          if (data?.Status === 201 || data?.Status === 204) {
            dispatch(
              updateResponseTypeId({
                MaterialUniqueId,
                ResponseTypeId: payload.ResponseTypeId,
              })
            );
            const userLeadsData=await getCheckUserLead(8,"material",UserCredentialId);
            dispatch(setUserLeadsData(userLeadsData));

            if (resTypeId === 1) {
              updateTransactionStatus(item);
            } else {
              revokeTransationStatus(item);
            }
          } else {
            console.error(data);
          }
        } catch (error) {
          throw error;
        }
      } else {
        toast({
          status: "error",
          description: "No more leads are available.",
          position: "top-right",
          isClosable: true,
        });
      }
    }
  };

  const renderSparePartCard = (array) => {
    const renderJsx =
      array && array.length > 0 ? (
        array.map((data) => {
          const isMyAsset = UserCredentialId === data.OwnerUserCredentialId || accountDetails?.CompanyDetails?.TaxNumber===data?.TaxNumber;
          return (
            <>
              <MaterialCard
                key={uuid()}
                imageUrl={
                  data.ImageList &&
                  data.ImageList.length > 0 &&
                  `${process.env.REACT_APP_CDN_PVT_URL}${
                    JSON.parse(data?.ImageList)[0].ImageFileRef
                  }`
                }
                isVerified={data.IsVerified}
                productNumber={data.productName}
                productName={data.ProductName}
                isOriginal={data?.IsOriginal}
                equipmentTypeValue = {data.EquipmentTypeValue ? data.EquipmentTypeValue : "N.A."}
                oemName = {data.OEMName ? data.OEMName : "N.A."}
                modelName = {data.ModelName ? data.ModelName : "N.A."}
                minPriceRange={data.MinPriceRange}
                maxPriceRange={data.MaxPriceRange}
                city={data.City}
                state={data.State}
                onClick={() => {
                  const OwnerUserCredentialId = convertToBase64(
                    data?.OwnerUserCredentialId
                  );
                  const materialId = convertToBase64(data?.MaterialId);
                  dispatch(setVideoList(data?.VideoList));
                  dispatch(setMaterialsLocation(data?.Location));
                  dispatch(setMaterialResponseTypeId(data?.ResponseTypeId));
                  navigate(
                    `/marketplace/MaterialMarketplace/material-details/${materialId}/${OwnerUserCredentialId}`,
                    {
                      state: {
                        materialTypeId: materialTypeId,
                        likeStatus: data.likeStatus,
                        isMyAsset:isMyAsset,
                        materialData:data
                      },
                    }
                  );
                }}
                likeStatus={data.likeStatus}
                onLikeClick={async () => {
                  await handleLike(data.MaterialId);
                }}
                ResponseTypeId={data.ResponseTypeId}
                onContactSeller={() => isMyAsset ? removeFromMarketPlace(data) : handleContactSeller(data)}
                responseTypeDetailsByResponseIds={responseTypeDetailsByResponseIds}
                isMyAsset={isMyAsset}
                shareData={data}
              />
            </>
          );
        })
      ) : (
        <p className="dealership-not-available">No Material Listed</p>
      );
    return renderJsx;
  };

  return (
    <>
      <FilterPopUp
        marketplaceType={MARKETPLACE_TYPES.MATERIALS_MARKETPLACE}
        isModalOpen={materialMarketplace.filterModalOpen}
        initialEquipmentMakeValue={materialMarketplace.filterByEquipmentMake}
        initialCountryValue={materialMarketplace.filterByCountry}
        initialStateValue={materialMarketplace.filterByState}
        onResetFilterClick={() => {
          dispatch(resetFilters());
        }}
        onSubmitButtonClick={filterFunction}
        onCloseButtonClick={() => {
          dispatch(setFilterModalOpen(false));
        }}
      />

      <QrPopUp />
      <MarketPlaceLayout>
        <MarketplaceHeader
          heading="Spare Parts Store"
          filterButtonSelected={materialMarketplace.filteredDataRequired}
          filterButtonClickAction={() => dispatch(setFilterModalOpen(true))}
        />
        <MarketplaceHoc
          marketplaceType="Material contact"
          dataArray={ materialMarketplace.likedData }
          endOfData={materialMarketplace?.endOfData}
          initialLoading={materialMarketplace?.initialLoading}
          paginationLoading={materialMarketplace?.paginationLoading}
          loadMoreAction={() => {
            materialMarketplace.filteredDataRequired
              ? dispatch(sparePartLoadMoreFilteredDataThunk())
              : dispatch(
                  sparePartLoadMoreDataThunk(
                    materialMarketplace.paginitionCount
                  )
                );
          }}
          cardsRenderFunction={renderSparePartCard}
        />
      </MarketPlaceLayout>
    </>
  );
};