import { createAsyncThunk } from "@reduxjs/toolkit";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";
import { getFilterMaterialMarketplaceList } from "../../../services/materials/materials";


export const generatorsInitialFiteredDataThunk = createAsyncThunk("generators/initialFilteredData",async(_, {getState}) => {
   
    const generatorId = convertToBase64('4')
    const {filterByIsOriginal ,filterByEquipmentTypeId,filterByEquipmentMakeId, filterByCountry, filterByState, cardCount} = getState().generatorsMaterialMarketplace


    let query = ""

    if(generatorId){
        query+= `MaterialTypeId=${generatorId}`
    }
    if(filterByIsOriginal){
        query+= `&IsOriginal=${filterByIsOriginal}`
    }
    if(filterByEquipmentTypeId){
        query+= `&EquipmentTypeId=${filterByEquipmentTypeId}`
    }
    if(filterByEquipmentMakeId){
        query+= `&OEMId=${filterByEquipmentMakeId}`
    }
    if(filterByCountry){
        query += `&Country=${filterByCountry}`
    }
    if(filterByState){
        query+= `&State=${filterByState}`
    }
   

    try{
        
        const data = await getFilterMaterialMarketplaceList(cardCount,0,query)

        if(data.Status === 200){
           if(data.Data.Status){
            return data.Data;
           }
           else{
            return []
           }
        }
    }
    catch(error){
    }

    
})