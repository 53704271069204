import "./MyBusinessAssetCss.css";

export const MyBusinessDetailCard = ({
  description = "",
  descriptionCursorRequired = true,
  imageUrl = "",
  onSubmitButtonClick = () => {},
}) => {
  return (
    <div
      className="new-description-business-card"
      style={{ cursor: "pointer" }}
      onClick={() => onSubmitButtonClick()}
    >
      <div className="new-description-business-card-img">
        <img
          style={{ height: "100%", width: "100%", objectFit: "contain" }}
          src={imageUrl}
        />
      </div>
      <p className="my-Business-card-view-description">{description}</p>
    </div>
  );
};
