import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMaterialDataForUpdateStep2ById } from "../../../services/materials/materials";



export const getAdditionalDetailsForUpdate=createAsyncThunk("edit-material/get-additional-details",async (materialId)=>{
    try {
        const data= await getMaterialDataForUpdateStep2ById(materialId);
        return data;
    } catch (error) {
        throw(error);
    }

})