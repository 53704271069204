import { API_CONSTANTS } from "../../../constants/apiConstants"
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData"
import {fetchHandler} from "../../../index"

export const createQuickMaintenanceJob = async(body) => {
    try{
        const {data} = await fetchHandler(API_CONSTANTS.CREATE_MAINTENANCE_JOB_DETAIL, {}, {}, convertToFormData(body), API_CONSTANTS.HTT_POST);
        if(data.Status === 201 && Object.keys(data.Data).length > 0){
            return {data :data.Data, message: data.Message.SuccessMessage}
        }
        else{
            const error = new Error();
            error.message = data.Message.FailMessage
            throw error;
        }
    }
    catch(error){
        throw error
    }
}

export const createMaintenanceJobDetailsForFullTime = async(body) => {
    try{
        const {data} = await fetchHandler("/CreateMaintenanceJobDetailsForFullTime", {}, {}, convertToFormData(body), API_CONSTANTS.HTT_POST);
       return data;
    }
    catch(error){
        throw error
    }
}

export const addImageToMaintenanceJobDetail = async(body) => {
    try{
        const {data} = await fetchHandler(API_CONSTANTS.ADD_IMAGE_TO_MAINTENANCE_JOB_DETAIL, {}, {}, convertToFormData(body), API_CONSTANTS.HTT_POST)  
        if(data.Status){
            return {id: data.Data.MaintenanceJobDetailImageId, message: data.Message.SuccessMessage}
        }
        else{
            const error = new Error();
            error.message = data.Message.FailMessage
            throw error;
        }
    }
    catch(error){
        throw error
    }
}