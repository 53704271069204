import { getSessionStorageItems } from "../../../helpers/sessionStorage/sessionStorage";

export const initialState = {
  stepperIndex: getSessionStorageItems("POST_DEALERSHIP_STEPPER_INDEX")
    ? parseInt(getSessionStorageItems("POST_DEALERSHIP_STEPPER_INDEX"))
    : 0,
  stepperRoute: [{ name: "Step 1" }, { name: "Step 2" }, { name: "Step 3" }],

  addToMarketplaceModal: false,
  isMaketplaceDelete: false,
  isOpenWelcomeModal: false,
  isAddDealershipModalOpen: false,
  isVirtualDealershipOpen: false,
  isDeleteOpen: false,
  isPhotoOpen: false,
  isCertificateOpen: false,
  isVideoOpen: false,
  isRegionOpen: false,
  isConfirmMediaOpen: false,
  guuid: "",

  // Step 1
  oemID: getSessionStorageItems("POST_DEALERSHIP_OEM_ID") || "",
  inndustryId: getSessionStorageItems("POST_DEALERSHIP_OEM_INDUSTRY_ID") || "",
  equipmentStatusId:
    getSessionStorageItems("POST_DEALERSHIP_DEALER_TYPE_ID") || "",
  name: getSessionStorageItems("POST_DEALERSHIP_NAME") || "",
  email: getSessionStorageItems("POST_DEALERSHIP_EMAIL") || "",
  website: getSessionStorageItems("POST_DEALERSHIP_WEBSITE") || "",
  contact: getSessionStorageItems("POST_DEALERSHIIP_CONTACT") || "",
  location: getSessionStorageItems("POST_DEALERSHIP_LOCATION") || "",
  latitude: getSessionStorageItems("POST_DEALERSHIP_LATITUDE") || "",
  longitude: getSessionStorageItems("POST_DEALERSHIP_LONGITUDE") || "",
  isdCode: getSessionStorageItems("POST_DEALERSHIP_ISD_CODE") || "",
  postGuuid: getSessionStorageItems("POST_DEALERSHIP_POST_GUUID") || "",
  taxNumber: getSessionStorageItems("POST_DEALERSHIP_TAX_NUMBER") || "",
  editGuuid: getSessionStorageItems("EDIT_DEALERSHIP_GUUID") || "",

  // Step 2
  getDealershipDetails: null,
  getImageList: localStorage.getItem("POST_DEALERSHIP_IMAGE_LIST") || "",
  getVideoList: null,
  getCertificateList: null,

  // Step 3
  regionPayload: [],
  financeProvided: "",
  managerID: "",

  // Images State
  photoTypeList: getSessionStorageItems("DEALERSHIP_PHOTO_TYPE_LIST") || "",

  // Videos State
  videoTypeList: getSessionStorageItems("DEALERSHIP_VIDEO_TYPE_LIST") || "",

  // Edit Dealership
  getDealershipDataById: null,
  isLoading:false
};
