import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_CONSTANTS } from "../../../../../constants/apiConstants";
import fetchHandler from "../../../../services/fetchHandler";

export const userDetailsThunk =  createAsyncThunk("/user/getUserDetails", async(_,{getState, dispatch, rejectWithValue}) => {
   
    try{
        const {data} = await fetchHandler("/GetUserDetails")
        if(data.Status){
          return {CompanyDetails: data.CompanyDetails, CompanyOwnerDetails: data.CompanyOwnerDetails, UserDetails: data.Data}
        }
        else{
            rejectWithValue(data.Message.FailMessage)
        }
    }
    catch(error){
        rejectWithValue(error.message)
    }
}) 