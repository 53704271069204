import fetchHandler from "../fetchHandler";

export const getFreeLeadsForIndividual = async () => {
  try {
    const { data } = await fetchHandler("/GetE9FreeServiceAvailableForUser");
    if (data.Status === 200 && Object.keys(data.Data).length > 0) {
      return data.Data.E9FreeServicesForUserList;
    }
    else{
      const error = new Error();
      error.message = data.Message.FailMessage
    }
  } catch (error) {
    throw error;
  }
};

export const getFreeLeadsAvailableForCompany = async(taxNumber) => {
    try{
        if(!taxNumber){
            throw new Error("Parameter is required and cannot be empty")
        }
        const {data} = await fetchHandler("/GetE9FreeServiceAvailableForCompany", {TaxNumber : btoa(taxNumber)});
        if (data.Status === 200 && Object.keys(data.Data).length > 0) {
            return data.Data.E9FreeServicesForUserList;
          }
          else{
            const error = new Error();
            error.message = data.Message.FailMessage
          }
    }
    catch(error){
        throw error
    }
}

export const getFreeLeadsAvailableForDealership = async (serviceId) =>{
  const {data} =await fetchHandler(`/GetE9FreeRedeemedDetails?ServiceTypeId=${serviceId}`) ;
  if(data?.Status === 200 ){
    return data?.Data
  }
  return null;
}

export const getPurchasedLeadsById = async (serviceId) =>{
  const {data} = await fetchHandler(`/GetPurchasedLeadsById?ServiceTypeId=${serviceId}`);
}