import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import {
  getVerificationDocumentType,
  countrylist,
  StateCountryId,
  city,
  getYearofExpirence,
  addExpirence,
  editExpirence,
  updateExpirenceDetail

} from "../../..../../../../../../../../../../ApiEquip9/CommonApi";
import { useToast } from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import * as Yup from "yup";
// import "./WorkExpirencePop.css";
import { Checkbox } from "@chakra-ui/react";
import { expirenceMonths } from "../../../../../../../../../../staticData/staticData";
import  './EditWorkExpirence.css';
// import { ImageUpload } from "../../../../../../../../../../components/ImageUpload/ImageUpload";
export const EditExpirence = ({ isOpen, onClose, triggerRefresh ,workCompanyId}) => {
  const userData = useSelector((state) => state.user);
  const loginData = useSelector((state) => state.login);

  const isdCode=userData?.accountDetails?.UserDetails?.CredentialDetails?.IsdCode;
  const [imagePreview, setImagePreview] = useState(null);
  const [fileType, setFileType] = useState("");
  const [isCurrentCompany, setIsCurrentCompany] = useState(0);
  // const [selectedCountry, setSelectedCountry] = useState("");
  // const [selectedState, setSelectedState] = useState("");
  const [cityList, setCityList] = useState([]);
  // const [selectedCity, setSelectedCity] = useState("");
  const [xxx,setXxx] = useState(null);
  const toast = useToast();
  const [data, setData] = useState(null);
  const queryClient = useQueryClient();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [countryListData, setCountryListData] = useState(null);
  const [stateListData, setStateListData] = useState(null);
  const [cityListData, setCityListData] = useState(null);

    const [formInitialValues, setFormInitialValues] = useState({
      CompanyName: "",
      AddressLine1: "",
      ReferenceName: "",
      ReferenceMobileNumber: "",
      VerificationDocumentNumber: "",
      CountryId:"",
      StateId:"",
      CityId:"",
      Zipcode: "",
      // DocumentFileReference: null,
      ReferenceEmail: "",
    })

    useEffect(()=>{
        const fetchData = async () => {
            try {
              const response = await editExpirence(userData.token,workCompanyId);
             
              setData(response?.data?.Data?.WorkCompanyExperience);
              setXxx(response?.data?.Data?.WorkCompanyExperience);
              const fetchedDataa = response?.data?.Data?.WorkCompanyExperience;
              setFormInitialValues({
                CompanyName: fetchedDataa.CompanyName || "",
                AddressLine1: fetchedDataa.AddressLine1 || "",
                ReferenceName: fetchedDataa.ReferenceName || "",
                ReferenceMobileNumber: fetchedDataa.ReferenceMobileNumber || "",
                VerificationDocumentNumber: fetchedDataa.VerificationDocumentNumber || "",
                Zipcode: fetchedDataa.Zipcode || "",
                ReferenceEmail: fetchedDataa.ReferenceEmail || "",
                CountryId:fetchedDataa.CountryId||"",
                StateId:fetchedDataa.StateId||"",
                CityId:fetchedDataa.CityId||"",
                ExperienceInYear: fetchedDataa.ExperienceInYear||"",
                ExperienceInMonth: fetchedDataa.ExperienceInMonth||""

              });
            } catch (error) {
            }
          };
      
          fetchData();
    },[workCompanyId,userData.token])

    useEffect(() => {
        if (data) {
            setImagePreview(data?.AttachSalarySlipFileReference ||'')
            setIsCurrentCompany(data?.IsCurrentCompany || 0);
            setSelectedCountry(data?.CountryId?.toString());
            setSelectedState(data.StateId?.toString());
            setSelectedCity(data?.CityId?.toString());
        }
    }, [data]);





   
  const handleSubmit = async (values, { resetForm }) => {
    const Payload = {
       WorkCompanyExperienceId :workCompanyId,
      CompanyName: values.CompanyName,
      ExperienceInYear: values.ExperienceInYear,
      ExperienceInMonth: values.ExperienceInMonth,
      AddressLine1: values.AddressLine1,
      IsCurrentCompany: isCurrentCompany,
      CountryId: selectedCountry,
      StateId: selectedState,
      CityId: selectedCity,
      Zipcode: values.Zipcode,
      ReferenceName: values.ReferenceName,
      ReferenceEmail: values.ReferenceEmail,
      ReferenceMobileNumber: values.ReferenceMobileNumber,
      IsdCode: isdCode,
    //   SalarySlip: values.SalarySlip,
    };
    // //  alert("OK")
    //  alert("OK")
    // // const response = await userUploadIndentityDocument(Payload, userData.token);
    const response = await  updateExpirenceDetail(userData.token, Payload);


    if (response?.data?.APIResponseCode === 200) {
      toast({
        title: "",
        description: `${response.data.Message.SuccessMessage}`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      queryClient.invalidateQueries("getWorkDetails");
      triggerRefresh();
      onClose();
      resetForm();
      setImagePreview(null);
    } else {
      toast({
        title: "Error while uploading",
        description: ` ${response?.data?.Message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right"
      });
    }
    return response;
  };

  const validationSchema = Yup.object({
    CompanyName: Yup.string()
      .required("Company Name is required")
      .min(5, "Company Name cannot be shorter than 5 characters")
      .max(100, "Company Name cannot be longer than 12 characters"),
    ExperienceInYear: Yup.string().required("Plese Enter the year of exprence"),
    ExperienceInMonth: Yup.string().required(
      "Plese Enter the month of exprence"
    ),
   
    CountryId: Yup.string().required("Please select  the country"),
    StateId: Yup.string().required("Plese select the state"),
    CityId: Yup.string().required("Please select the city"),
    Zipcode: Yup.string()
    .matches(/^\d{6}$/, "PIN code must be exactly 6 digits"),
    ReferenceName: Yup.string()
      .required("Please Enter the reference name")
      .min(4, "Referece Name cannot bn e shorter than 5 characters").max(16,'eferece Name cannot be longer than 5 characters'),
      ReferenceEmail:Yup.string().email('Invalid email').required('Please enter the reference email'),
      ReferenceMobileNumber: Yup.string()
      .required('Please enter a mobile number')
      .matches(/^[1-9]\d{9}$/, {
          message: "Please enter a valid mobile number.",
          excludeEmptyString: false
      })

  });

  const { data: GetVerficationDicType } = useQuery(
    "getVerificationDocument",
    () => getVerificationDocumentType(userData.token)
  );
  const GetDocList = GetVerficationDicType?.data?.Data;
  // County
  // const { data: GetcountryList } = useQuery("countryList", () =>
  //   countrylist(userData.token)
  // );
  // const GetCountrylist = GetcountryList?.data?.Data?.CountriesList;

  // // state
  // const { data: getStateCountryId } = useQuery(
  //   ["getStateList", selectedCountry],
  //   () => StateCountryId(userData.token, selectedCountry),
  //   {
  //     enabled: !!selectedCountry,
  //   }
  // );

  // const GetStateList = getStateCountryId?.data?.Data?.StatesList;



  // // city
  // const { data: getCitylist } = useQuery(["getCitylist", selectedState], () =>
  //   city(userData.token, selectedState)
  // );

  // useEffect(() => {
  //   if (getCitylist?.data?.Data?.CitiesList) {
  //     setCityList(getCitylist?.data?.Data?.CitiesList);
  //   }
  // }, [getCitylist]);-----

/******************** */
const { data: fetchedCountryList } = useQuery("countryListt", () =>
countrylist(userData.token)
);
useEffect(() => {
setCountryListData(fetchedCountryList?.data?.Data?.CountriesList);
// setStateListData(null)
// setCityListData(null);
}, [fetchedCountryList]);


//
const { data: fetchedStateList } = useQuery(
  ["getStateListt", selectedCountry],
  () => StateCountryId(userData.token, selectedCountry),
  {
    enabled: !!selectedCountry,
  }
);
useEffect(() => {
  setStateListData(fetchedStateList?.data?.Data?.StatesList);
  setCityListData(null);
}, [fetchedStateList, selectedCountry]);


const { data: fetchedCityList } = useQuery(
  ["getCitylistt", selectedState],
  () => city(userData.token, selectedState),
  {
    enabled: !!selectedState,
  }
);
useEffect(() => {
  setCityListData(fetchedCityList?.data?.Data?.CitiesList);
}, [fetchedCityList]);


/************************ */
  
  //   const cityList=getCitylist?.data?.Data?.CitiesList;

  // getYearExpirence
  const { data: getExpirenceofExpirece } = useQuery("getYearExpirence", () =>
    getYearofExpirence(userData.token)
  );

  const getYearExpirence = getExpirenceofExpirece?.data?.Data.YearOfExperience;



  return (
    isOpen && (
      <div className="popup-card-overlay">
        <Formik
            initialValues={formInitialValues}
          onSubmit={(values,resetForm)=>{
            values = {...values,...xxx};
            handleSubmit(values,resetForm)

            // validationSchema={validationSchema} 
          }}
          enableReinitialize
           validationSchema={validationSchema} 
        > 
          {({ isSubmitting, setFieldValue }) => (
            <Form className="popup-card-edit ">
              <div>
                <button className="popup-card-close" onClick={onClose}>
                  <ReactSVG
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Cross.svg`}
                  />
                </button>
                <h1>Add Company Details</h1>
                <div className="popup-card-inner">
                  <div>
                    <label htmlFor="inputField">
                      Company Name <span className="asterisk">*</span>
                    </label>
                    <Field type="text" name="CompanyName"
                     
                    value={data ? xxx?.CompanyName : "" }
                    onChange={(e) => {
                        setXxx((prevUserDetails=>{
                            return {...prevUserDetails,CompanyName: e.target.value }
                        }));
                        setFieldValue("CompanyName", e.target.value);
                    }} />
                    <ErrorMessage
                      name="CompanyName"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div>
                    <label htmlFor="inputField">
                      Company Address <span className="asterisk">*</span>
                    </label>
                    <Field type="text" name="AddressLine1"
                    value={data ? xxx?.AddressLine1 : "" }
                    onChange={(e) => {
                        setXxx((prevUserDetails=>{
                            return {...prevUserDetails,AddressLine1: e.target.value }
                        }));
                    }} />
                    <ErrorMessage
                      name="AddressLine1"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* option field */}
                  <div className="OptionField">
                    <div style={{marginTop:'23px'}}>
                      <label htmlFor="selectField">
                        Select Country<span className="asterisk">*</span>
                      </label>
                      <Field
                        as="select"
                        name="CountryId"
                        value={selectedCountry}
                        onChange={(e) => {
                            // setXxx((prevUserDetails=>{
                            //     return {...prevUserDetails,CountryId: e.target.value }
                            // }));
                            setSelectedCountry(e.target.value);
                            setFieldValue("CountryId", e.target.value);
                        }}
                      >
                        <option value="">Select an option</option>
                        {countryListData?.map((item, index) => (
                          <option key={index.id} value={item.CountryId}>
                            {item.CountryName}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                      name="CountryId"
                      component="div"
                      className="error-message"
                    />
                    </div>
                    <div style={{marginTop:'23px'}}>
                      <label htmlFor="selectField">
                        Select State<span className="asterisk">*</span>
                      </label>
                      <Field
                        as="select"
                        name="StateId"
                        value={selectedState}
                        onChange={(e) => {
                          setSelectedState(e.target.value);
                          setFieldValue("StateId", e.target.value);
                          // ExperienceInYear
                        }}
                      >
                        <option value="">Select an option</option>
                        {stateListData?.map((item, index) => (
                          <option key={index.id} value={item.StateId}>
                            {item.StateName}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                      name="StateId"
                      component="div"
                      className="error-message"
                    />
                    </div>
                  </div>
                  <div className="OptionField">
                    <div>
                      <label htmlFor="selectField">
                        Select City<span className="asterisk">*</span>
                      </label>
                      <Field
                        as="select"
                        name="CityId"
                        value={selectedCity}
                        onChange={(e) => {
                          const currentValue = e.target.value;
                          setSelectedCity(currentValue);
                          setFieldValue("CityId", e.target.value);
                        }}
                      >
                        <option value="">Select an option</option>
                        {cityListData?.map((item, index) => (
                          <option key={index.id} value={item.CityId}>
                            {item.CityName}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                      name="CityId"
                      component="div"
                      className="error-message"
                    />
                    </div>
                    <div>
                      <label htmlFor="selectField">
                        Select Pincode<span className="asterisk">*</span>
                      </label>
                      <Field type="text" name="Zipcode"  value={data?xxx?.Zipcode:''}
                        onChange={(e) => {
                            setXxx((prevUserDetails=>{
                                return {...prevUserDetails,Zipcode: e.target.value }
                            }));
                            setFieldValue("Zipcode", e.target.value);
                        }} />

                   <ErrorMessage
                        name="Zipcode"
                        component="div"
                        className="error-message"
                      />   
                    </div>
                  </div>
                  <div>
                    <label htmlFor="inputField">
                      Total Work Expirence With Company{" "}
                      <span className="asterisk">*</span>
                    </label>
                    <div className="OptionField">
                      <div>
                        <label htmlFor="selectField">
                          Year<span className="asterisk">*</span>
                        </label>
                        <Field as="select" name="ExperienceInYear"
                         value={data?xxx?.ExperienceInYear:''}
                         onChange={(e) => {
                            setXxx((prevUserDetails=>{
                                return {...prevUserDetails,ExperienceInYear: e.target.value }
                                
                            }));
                            setFieldValue("ExperienceInYear", e.target.value);
                        }}>
                          <option value="">Select an option</option>
                          {getYearExpirence.map((item, index) => (
                            <option
                              key={index.id}
                              value={item.YearOfExperienceId}
                            >
                              {item.YearOfExperienceValue}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                        name="ExperienceInYear"
                        component="div"
                        className="error-message"
                      /> 
                      </div>
                      <div>
                        <label htmlFor="selectField">
                          Month<span className="asterisk">*</span>
                        </label>
                        <Field  as="select" name="ExperienceInMonth"
                        value={data?xxx?.ExperienceInMonth:''}
                          onChange={(e) => {
                            setXxx((prevUserDetails=>{
                                return {...prevUserDetails,ExperienceInMonth: e.target.value }
                            }));

                            setFieldValue("ExperienceInMonth", e.target.value);
                        }}>
                        <option value="">Select an option</option>
                        {expirenceMonths?.map((item, index) => (
                        <option key={index.id} value={item.MonthrOfExperienceId}>
                          {item.MonthsOfExperienceValue}
                        </option>
                      ))}
                        </Field>
                        <ErrorMessage
                        name="ExperienceInMonth"
                        component="div"
                        className="error-message"
                      />  

                      </div>
                    </div>
                  </div>
                </div>
                <Checkbox
                  size="lg"
                  colorScheme="orange"
                  //    defaultChecked
                  name="IsCurrentCompany"
                  isChecked={isCurrentCompany}
                  onChange={(e) =>
                    setIsCurrentCompany(isCurrentCompany => !isCurrentCompany)
                    // setXxx((prevUserDetails=>{
                    //     return {...prevUserDetails,isCurrentCompany: e.target.value }
                    // })),
                    
                  }
                >
                  is it your current company?
                </Checkbox>
                <ErrorMessage
                  name="VerificationDocumentName"
                  component="div"
                  className="error-message"
                />
                <div>
                  <label htmlFor="inputField">
                    Reference Name <span className="asterisk">*</span>
                  </label>
                  <Field type="text" name="ReferenceName" 
                  onChange={(e) => {
                    setXxx((prevUserDetails=>{
                        return {...prevUserDetails,ReferenceName: e.target.value }
                    }));
                    setFieldValue('ReferenceName',e.target.value)
                }}
                   value={data?xxx?.ReferenceName:''}
                  />
                  <ErrorMessage
                    name="ReferenceName"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div>
                  <label htmlFor="inputField">
                    Reference Mobile Number <span className="asterisk">*</span>
                  </label>
                  <Field type="text" name="ReferenceMobileNumber"
                  onChange={(e) => {
                    setXxx((prevUserDetails=>{
                        return {...prevUserDetails,ReferenceMobileNumber: e.target.value }
                    }));
                    setFieldValue('ReferenceMobileNumber',e.target.value)
                }}
                    value={data?xxx?.ReferenceMobileNumber:''} />
                  <ErrorMessage
                    name="ReferenceMobileNumber"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div>
                  <label htmlFor="inputField">
                    Reference Email Id <span className="asterisk">*</span>
                  </label>
                  <Field type="text" name="ReferenceEmail"
                      value={data?xxx?.ReferenceEmail:''}
                      onChange={(e) => {
                        setXxx((prevUserDetails=>{
                            return {...prevUserDetails,ReferenceEmail: e.target.value }
                        }));
                        setFieldValue('ReferenceEmail',e.target.value)
                    }} />'
                  <ErrorMessage
                    name="ReferenceEmail"
                    component="div"
                    className="error-message"
                  />
                </div>
                   
                {/* <label htmlFor="inputField"> */}
                <div>Upload your salary slip <span className="asterisk">*</span></div>
                  {/* </label> */}
                <div className="file-input-container">
                  <label
                    htmlFor="custom-file-input"
                    className="custom-file-button"
                  >
                    Upload
                  </label>
                  <input
                    className="custom-file-input"
                    id="custom-file-input"
                    type="file"
                    value={data?xxx?.AttachSalarySlipFileReferenc:''}
                    accept="image/jpeg, image/png, application/pdf"
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      setFileType(file.type); // Set the file type here
                      setFieldValue("SalarySlip", file);
                      setXxx((prevUserDetails=>{
                        return {...prevUserDetails,SalarySlip: event.target.value }
                    }));
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        setImagePreview(reader.result);

                      };
                      if (file) {
                        reader.readAsDataURL(file);
                      }
                      toast({
                        title: "File Uploaded successfully",
                        description: "You have selected a file for upload.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right',
                      });
                    }}
                  />
                </div>
                <div className="preview-container">
                  {imagePreview && fileType === "application/pdf" ? (
                    <embed
                      src={`${process.env.REACT_APP_CDN_PVT_URL}SalarySlip/${imagePreview}`}
                    // src={imagePreview}
                      type="application/pdf"
                      width="100%"
                      height="200px"
                      
                    />
                  ) : (
                    <img
                    src={`${process.env.REACT_APP_CDN_PVT_URL}SalarySlip/${imagePreview}`}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "200px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: " 44px",
                      }}
                    />
                  )}
                </div>
                <ErrorMessage
                  name="SalarySlip"
                  component="div"
                  className="error-message"
                />
                <button type="submit" disabled={isSubmitting}>
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  );
};
