import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllTopMaintenancePartnersList } from "../../../services/jobsPosted/jobsPosted";

export const getLoadMoreDataThunk = createAsyncThunk("nearMeOp/loadMoreData", async(_, {getState}) => {
  
    const cardCount = getState().maintenancePartnerNearMe.cardCount;
    const pageNumber =  getState().maintenancePartnerNearMe.paginationCount;
 

    try{
        const data = await getAllTopMaintenancePartnersList(cardCount,(pageNumber * cardCount));   

       if(data.Status === 200){
        if(data.Data.TotalRecords > 0){

            return data.Data.NearMeDetails;
        }
        else{
            return []
        }
       }
    }
    catch(error){
    }
})