import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { PlaceSuggestions } from "../PlaceSuggestions/PlaceSuggestions";
import { ModalHeader } from "react-bootstrap";
import { CloseIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { setModalClose } from "../../features/location/slice/slice";

export const PlaceSuggestionsModal = ({onChange = () => {}}) => {
  const { isModalOpen } = useSelector((state) => (state.location));
  const dispatch = useDispatch();
  return (
    <Modal isCentered isOpen={isModalOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">
          <Box textAlign="right">
            <Button bgColor="#FFFFFF" boxShadow="none" dropShadow="none" onClick={() => dispatch(setModalClose())}> 
              <CloseIcon />
            </Button>
          </Box>
          <Box>
            <Text fontWeight="semibold" fontSize="2xl" textAlign="center">
              Search Location
            </Text>
          </Box>
        </ModalHeader>
        <ModalBody maxH="430px" minH="430px" overflowY="scroll">
          <PlaceSuggestions
            onChange={(value) => {
                onChange(value)
              dispatch(setModalClose());
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
