import { createSlice } from "@reduxjs/toolkit";
import { opLoadMoreDeferred } from "../thunk/opLoadMoreDeferred";
import { opJobInitialState } from "../constants/opJobInitialState";
import { getOpJobDeferredThunk } from "../thunk/getOpJobDeferredThunk";

export const opJobDeferredSlice = createSlice({
    name:"opJobDeferred",
    initialState: opJobInitialState,
    reducers:{
        setData: (state,{payload})=>{
            state.data = payload
        }
    },

    extraReducers:(builder) =>{
        builder
        .addCase(getOpJobDeferredThunk.pending, (state)=>{
            state.data = null;
            state.initialLoading = true;
            state.paginationLoading = false;
            state.error = false;
            state.errorMessage = false;
            state.paginationCount = 1;
            state.endOfData = false
        })
        .addCase(getOpJobDeferredThunk.fulfilled,(state,{payload}) =>{
            state.paginationCount = 1;
            state.initialLoading = false;
            state.error = false;
            state.errorMessage = null;
            state.data = payload?.JobsDetails;
            if(payload?.JobsDetails?.length < state.cardCount){
              state.endOfData = true
            }
        })
        .addCase(opLoadMoreDeferred.pending, (state)=>{
            state.paginationLoading = true;
            state.error = false ;
            state.errorMessage = null;
         })
         .addCase(opLoadMoreDeferred.fulfilled, (state,{payload})=>{
            state.error = false ;
            state.errorMessage = null ;
            state.paginationLoading = false;
            state.assetImageReference = payload?.AssetImageBaseReference
            if(payload?.JobsDetails?.length >= state.cardCount){
                state.data = [...state?.data , ...payload?.JobsDetails];
                state.paginationCount += 1;
            }else{
                state.endOfData = true;
         }
         })
    }
})

export const {
    setData
}= opJobDeferredSlice.actions;