// import Stepper from 'react-stepper-horizontal';
import { Text, useToast } from "@chakra-ui/react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { MultiAdDivider } from "../../../../layout/multiAdLayout/MultiAdLayout";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import {
  REGISTRATION_FORM_VALIDATION_NOT_OPERATOR,
  REGISTRATION_FORM_VALIDATION_OPERATOR,
} from "./constants/formValidation";
import { FormikDoubleInputField } from "../../../../components/Input/FormikDoubleInput";
import { FormikPasswordInput } from "../../../../components/Input/FormikPasswordInput";
import { Box, Button, Flex } from "@chakra-ui/react";
import { FormikInput } from "../../../../components/Input/FormikInput";
import { registrationThunk } from "../../thunk/registrationThunk";
import { hashToMd5 } from "../../../../helpers/dataConversion/convertToMd5";
import { getDefaultAppLanguage } from "../../../global/mtData/helpers/localStorage";
import { V5MultiAdLayout } from "../../../../layout/multiAdLayout/V5MultiAdLayout";
import { NAVIGATION_CONSTANTS } from "../../../../../constants/naigationContants";
import { useState } from "react";
export const RegistrationForm = () => {
  const registrationData = useSelector((state) => state.registration);
  const appConfig = useSelector((state) => state.appConfig);
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultLanguage = getDefaultAppLanguage();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const assetIdParam = searchParams.get(
    NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID
  );

  const createRegistrationObject = (mobileNumber, password) => {
    return {
      UserType: registrationData.selectedUserType.UserTypeValue,
      BusinessType: registrationData.selectedBusinessType.BusinessTypeValue,
      CountryCode: appConfig.country.CountryIsdCode,
      MobileNumber: mobileNumber,
      IsRegisterWith: 0,
      Password: hashToMd5(password),
    };
  };

  const operatorDetailsObject = (firstName, lastName) => {
    return { FirstName: firstName, LastName: lastName };
  };

  const notOperatorDetailsObject = (firstName, lastName, enterpriseName) => ({
    FirstName: firstName,
    LastName: lastName,
    EnterpriseName: enterpriseName,
  });

  return (
    <V5MultiAdLayout
      heading={defaultLanguage?.Fill_Your_Account_Details_for_Easy_Setup}
      subHeading={defaultLanguage?.It_wont_take_long}
      stepper={false}
    >
      <Flex
        flexDirection="column"
        gap="1em"
        maxW="374px"
        margin="0 auto"
        maxH="350px"
        overflowY="scroll"
        // p="0.5em 1em"
      >
        {registrationData.selectedBusinessType ? (
          <Formik
            initialValues={
              registrationData.selectedBusinessType.BusinessTypeValue ===
              "Operator Partner"
                ? { firstName: "", lastName: "", password: "" }
                : {
                    enterpriseName: "",
                    firstName: "",
                    lastName: "",
                    password: "",
                  }
            }
            validationSchema={
              registrationData.selectedBusinessType.BusinessTypeValue ===
              "Operator Partner"
                ? REGISTRATION_FORM_VALIDATION_OPERATOR
                : REGISTRATION_FORM_VALIDATION_NOT_OPERATOR
            }
            onSubmit={async (values) => {
              try {
                setLoading(true)
                const response = await dispatch(
                  registrationThunk({
                    registrationBody: createRegistrationObject(
                      registrationData.mobileNumber,
                      values.password
                    ),
                    userDetailsBody: !values.enterpriseName
                      ? operatorDetailsObject(values.firstName, values.lastName)
                      : notOperatorDetailsObject(
                          values.firstName,
                          values.lastName,
                          values.enterpriseName
                        ),
                  })
                ).unwrap();
                toast({
                  status: "success",
                  title: "User Registered Successfully",
                  position: "top-right",
                  isClosable: "true",
                });

                navigate(
                  assetIdParam
                    ? `/login/login-form?${NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID}=${assetIdParam}`
                    : "/login/login-form"
                );
              } catch (error) {
                toast({
                  status: "error",
                  title: `${error}`,
                  position: "top-right",
                  isClosable: true,
                });
              }
              finally{
                setLoading(false)
              }
            }}
          >
            {({ values, handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                style={{ margin: "0 auto" }}
                id="registration-form"
              >
                <Flex flexDirection="column" gap="1em">
                  {registrationData.selectedBusinessType.BusinessTypeValue !==
                    "Operator Partner" && (
                    <FormikInput
                      isRequired={true}
                      name="enterpriseName"
                      label={
                        registrationData.selectedBusinessType.UserTypeValue ===
                        "Individual"
                          ? "Enterprise Name"
                          : "Company Name"
                      }
                      placeholder={
                        registrationData.selectedBusinessType.UserTypeValue ===
                        "Individual"
                          ? "Eg: Enterprise Name..."
                          : "Eg: Company Name..."
                      }
                      maxLength="30"
                    />
                  )}
                  <FormikDoubleInputField
                    isRequired={true}
                    firstInputName="firstName"
                    secondInputName="lastName"
                    firstLabel={
                      registrationData.selectedBusinessType
                        .BusinessTypeValue === "Operator Partner"
                        ? "Operator Name"
                        : "Name"
                    }
                    secondLabel="Hidden"
                    firstPlaceholder="Enter First Name ..."
                    secondPlaceholder="Enter Last Name ..."
                    maxLength="15"
                  />
                  <FormikPasswordInput
                    isRequired={true}
                    name="password"
                    placeholder="Must have atleast six characters..."
                    label="Set Password"
                  />
                </Flex>
              </form>
            )}
          </Formik>
        ) : (
          <Text>Please Select Business Type and User Type Values</Text>
        )}
        <Link
          to="/login/login-form"
          style={{ color: "#f79c00", fontWeight: 700 }}
        >
          <Box textAlign="center">
            <Text fontWeight="semibold" color="#2c2c2c80">
              Already have an account? {defaultLanguage?.LogIn}
            </Text>
          </Box>
        </Link>
      </Flex>

      <Box>
        <Flex
          justifyContent="center"
          alignItems="center"
          bgColor="#fff"
          h="78px"
          style={{ boxShadow: "0px -10px 15px #2c2c2c0f" }}
        >
          <Button
            type="submit"
            variant="gradient"
            fontWeight="semibold"
            width="80%"
            margin="0 auto"
            maxW="347px"
            form="registration-form"
            isLoading={loading}
          >
            {defaultLanguage?.SignUP}
          </Button>
        </Flex>
      </Box>
    </V5MultiAdLayout>
  );
};
