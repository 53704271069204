import { createSlice } from "@reduxjs/toolkit";
import { getInitialDataThunk } from "../thunks/getInitialDataThunk";
import { getLoadMoreDataThunk } from "../thunks/getLoadMoreDataThunk";
import { operatorPartnerInitialState } from "../constants/inititalState";
import { getOperatorPartnerInitialFilteredDataThunk } from "../thunks/getOperatorPartnerFIlteredInitialDataThunk";
import { getOperatorPartnerFilteredLoadMoreDataThunk } from "../thunks/getOperatorPartnerFilteredLoadMoreDataThunk";
import { setLocalStorageItems } from "../../../helpers/localStorage/localStorage";
import { STORAGE_CONSTANTS } from "../../../../constants/storageConstants";

export const operatorNearMeSlice = createSlice({
  name: "operateNearMe",
  initialState: operatorPartnerInitialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setImageBaseUrl: (state, action) => {
      state.imageBaseUrl = action.payload;
    },
    setPaginationCount: (state, action) => {
      state.paginationCount = action.payload;
    },
    setEndOfData: (state, action) => {
      state.endOfData = action.payload;
    },
    setInitialLoading: (state, action) => {
      state.initialLoading = action.payload;
    },
    setPaginationLoading: (state, action) => {
      state.paginationLoading = action.payload;
    },
    setFilterModalOpen: (state, action) => {
      state.filterModalOpen = action.payload;
    },
    setFilteredDataRequired: (state, action) => {
      state.filteredDataRequired = action.payload;
    },
    setFilterByVerificationStatus: (state, action) => {
      state.filterByVerificationStatus = action.payload;
    },
    setFilterByEquipmentType: (state, action) => {
      state.filterByEquipmentType = action.payload;
    },
    setFilterByLicenseType: (state, action) => {
      state.filterByLicenseType = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    resetFilters: (state) => {
      state.filterByEquipmentType = "";
      state.filterByLicenseType = "";
      state.filterByVerificationStatus = "";
      state.filteredDataRequired = false;
      state.filterModalOpen = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInitialDataThunk.pending, (state, action) => {
        state.data = null;
        state.imageBaseUrl = null;
        state.paginationCount = 0;
        state.endOfData = false;
        state.cardCount = 12;
        state.initialLoading = true;
        state.paginationLoading = false;
        state.error = null;
        state.errorMessage = null;
      })
      .addCase(getInitialDataThunk.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.imageBaseUrl = action.payload.imageBaseUrl;
        setLocalStorageItems(STORAGE_CONSTANTS.OPERATOR_PARTNER_MARKETPLACE_IMAGE_URL, action.payload.imageBaseUrl)
        state.paginationCount = 1;
        state.initialLoading = false;
        state.paginationLoading = false;
        state.error = null;
        state.errorMessage = null;
        if (action.payload.data.length < state.cardCount) {
          state.endOfData = true;
        }
      })
      .addCase(getLoadMoreDataThunk.pending, (state) => {
        state.paginationLoading = true;
        state.error = null;
        state.errorMessage = null;
      })
      .addCase(getLoadMoreDataThunk.fulfilled, (state, action) => {
        state.data = [...state.data, ...action.payload];
        state.paginationCount += 1;
        state.paginationLoading = false;
        state.initialLoading = false;
        state.error = null;
        state.errorMessage = null;
        if (action.payload.length < state.cardCount) {
          state.endOfData = true;
        }
      })
      .addCase(getOperatorPartnerInitialFilteredDataThunk.pending, (state) => {
        state.data = null;
        state.paginationCount = 0;
        state.endOfData = false;
        state.cardCount = 12;
        state.initialLoading = true;
        state.paginationLoading = false;
        state.error = null;
        state.errorMessage = null;
      })
      .addCase(getOperatorPartnerInitialFilteredDataThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.paginationCount = 1;
        state.initialLoading = false;
        state.paginationLoading = false;
        state.error = null;
        state.errorMessage = null;
        if (action.payload.length < state.cardCount) {
          state.endOfData = true;
        }
      })
      .addCase(getOperatorPartnerFilteredLoadMoreDataThunk.pending, (state) => {
        state.paginationLoading = true;
        state.error = null;
        state.errorMessage = null;
      })
      .addCase(getOperatorPartnerFilteredLoadMoreDataThunk.fulfilled, (state, action) => {
        state.data = [...state.data, ...action.payload];
        state.paginationCount += 1;
        state.paginationLoading = false;
        state.initialLoading = false;
        state.error = null;
        state.errorMessage = null;
        if (action.payload.length < state.cardCount) {
          state.endOfData = true;
        }
      })
  },
});

export const {
  setData,
  setImageBaseUrl,
  setPaginationCount,
  setEndOfData,
  setInitialLoading,
  setPaginationLoading,
  setFilterByEquipmentType,
  setFilterByLicenseType,
  setFilterByVerificationStatus,
  setFilterModalOpen,
  setFilteredDataRequired,
  setError,
  setErrorMessage,
  resetFilters
} = operatorNearMeSlice.actions;
