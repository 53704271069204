import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {useState } from "react";
// import RatingStar from "../../../../../assets/webapp/dashboard/rating-star-gold.png";
import "./DashboardNavBar.css";
import { ReactSVG } from "react-svg";
import { useLocation,useNavigate  } from 'react-router-dom';
import { QrPopUp } from "../ScanQrCodeModal/QrPopUp";
import { openQrModal } from "../../features/dashboard/slice/dashboardSlice";
// import {dashboardRedirection} from "../../";


export const DashboardNavBar = () => {
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const toggleCard = (index) => {
    setExpandedIndex(index === expandedIndex ? -1 : index);
  };
  
  const location = useLocation();
  const navigate = useNavigate();

/*********Dynamic Path *************** */ 
  const dynamicPathPrefix = "/material/";
  const isDynamicPath = location.pathname.startsWith(dynamicPathPrefix);

  // upcoming/job-description
  const dynamicPathUpcoming = "/upcoming/job-description";
  const isDynamicPathforUpcoming = location.pathname.startsWith(dynamicPathUpcoming);

  // ongoing/mp-job-description
  const dynamicPathongoing = "/ongoing/mp-job-description";
  const isDynamicPathforongoing = location.pathname.startsWith(dynamicPathongoing);

  //compelted
  const dynamicpathCompleted='/completed/mp-job-description';
  const isDynamicPathforCompleted=location.pathname.startsWith(dynamicpathCompleted);

//  upcoming 
const dynamicpathUpcoming='/upcoming/job-description';
const isDynamicPathforUpcomingOP=location.pathname.startsWith(dynamicpathUpcoming);

// Material Store List

const staticPathMaterialStoreList='/materials-store-list';
const staticPathhMaterialStoreList=location.pathname.startsWith(staticPathMaterialStoreList);

const staticPathMaterialSpareParts='/marketplace/MaterialMarketplace/material-details';
const isstaticPathMaterialSpareParts=location.pathname.startsWith(staticPathMaterialSpareParts)

  const goToDashboard = () => {
    navigate(-1);  // Replace with your dashboard's path if it's different
};

const goToMaterialList=()=>{
  navigate('/materials-store-list')
}

const goToJobStatus=()=>{
  navigate(-1);
}

const routemybusiness=()=>{
   navigate('/my-business');
  // history.goBack()
}



const renderWelcomeMessage = () => {

  if (isDynamicPath) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: "6px", cursor: "pointer", marginLeft: '-1px' }} onClick={goToMaterialList}>
        <ReactSVG style={{ marginTop: '12px' }}
          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Arrow-Left.svg`}
        />
        <div>Material Details</div> 
      </div>
    );
  }


  if (isDynamicPathforUpcoming) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: "6px", cursor: "pointer", marginLeft: '-1px' }} onClick={goToJobStatus}>
        <ReactSVG style={{ marginTop: '12px' }}
          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Arrow-Left.svg`}
        />
        <div>Upcoming Job</div> 
      </div>
    );
  }

  if(isDynamicPathforongoing){
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: "6px", cursor: "pointer", marginLeft: '-1px' }} onClick={goToJobStatus}>
        <ReactSVG style={{ marginTop: '12px' }}
          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Arrow-Left.svg`}
        />
        <div>Ongoing Job</div> 
      </div>
    );
  }

  if(isDynamicPathforCompleted){
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: "6px", cursor: "pointer", marginLeft: '-1px' }} onClick={goToJobStatus}>
        <ReactSVG style={{ marginTop: '12px' }}
          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Arrow-Left.svg`}
        />
        <div>Completed Job</div> 
      </div>
    );
  }


  if (isDynamicPathforUpcomingOP) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: "6px", cursor: "pointer", marginLeft: '-1px' }} onClick={goToJobStatus}>
        <ReactSVG style={{ marginTop: '12px' }}
          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Arrow-Left.svg`}
        />
        <div>Upcoming Job</div> 
      </div>
    );
  }


  if(staticPathhMaterialStoreList){
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: "6px", cursor: "pointer", marginLeft: '-1px' }} onClick={routemybusiness}>
        <ReactSVG style={{ marginTop: '12px' }}
          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Arrow-Left.svg`}
        />
        <div>Material Store</div> 
      </div>
    );
  }

  if(isstaticPathMaterialSpareParts){
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: "6px", cursor: "pointer", marginLeft: '-1px' }} onClick={()=>{navigate(-1)}}>
        <ReactSVG style={{ marginTop: '12px' }}
          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Arrow-Left.svg`}
        />
        <div>Material Details</div> 
      </div>
    );
  }

  const pathToMessageMapping = {
    "/business-expertise": "E9 Role",
    "/my-profile-details": "Personal Details",
    "/work-expirence": "Company Details",
    "/equipment-details": "Equipment Details",
    "/license-details": "License Details",
    "/verification-detail": "Verification Details ",
    "/operatorpatner-education-details": "Education details",
    "/company-profile": "Subscription Details",
    "/company-member-details":"Company Member Details",
    "/subcription-details":"Subscription Details",
    "/raisesupport":"Customer Support",
    "/settings":"Settings",
    "/privacy-settings":"Privacy",
    "/change-password":"Change Password",
    "/delete-account":"Account Settings",
    "/jobs-near-me/AllJobsNearMeMP":"Maintenance Jobs",
    "/jobs-near-me/AllJobsNearMeOP":"Operator Jobs",
    "/jobs-near-me/AllJobsNearMeMP/Card/145":"Job Details",
    "/job-status":"Job Status",
    "/my-business":"My Business",
    "/my-profile":"My Profile",
    "/marketplace/DealershipMarketplaceHome":"Dealership Marketplace",
    "/marketplace/MaterialMarketplace":"Material Marketplace",
    "/materials/spare-parts":"SPARE PARTS"
    // "marketplace/MaterialMarketplace":"Material Marketplace"
  };

  if (pathToMessageMapping[location.pathname]) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: "6px", cursor: "pointer",marginLeft:'-1px'}} onClick={goToDashboard}>
        <ReactSVG style={{ marginTop: '12px' }}
          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Arrow-Left.svg`}
        />
        <div>{pathToMessageMapping[location.pathname]}</div>
      </div>
    );
  }

  return `WELCOME ${userData?.accountDetails ? `${userData?.accountDetails?.UserDetails?.PersonalDetails?.FirstName} ${userData?.accountDetails?.UserDetails?.PersonalDetails?.LastName}` : "User"} `;
};

// useEffect(() => {
  
//     dispatch(userDetailsThunk());
//   }, []);

  return (

    <>
    <QrPopUp />
    <div className="NavbarContainer">
      <div className="Navbar">
        <div className="Navbar-section">
          <p className="name">
          {/* {renderWelcomeMessage()} */}
          {`WELCOME ${userData?.accountDetails ? `${userData?.accountDetails?.UserDetails?.PersonalDetails?.FirstName} ${userData?.accountDetails?.UserDetails?.PersonalDetails?.LastName}` : "User"} `}
          </p>
          <div className="nav-notification  center-content">
            <ReactSVG
              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/SOS.svg`}
              alt="img"
              className="list-icon"
              onClick={()=>{
                dispatch(openQrModal());
              }}
            />
            <ReactSVG
              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Notification.svg`}
              alt="img"
              className="list-icon"
              onClick={()=>{
                dispatch(openQrModal());
              }}
            />
            {/* <img
              className="userimage"
              src={
                userData?.accountDetails?.UserDetails?.ProfilePicture
                  ?.ProfilePictureFileReference
                  ? `${process.env.REACT_APP_CDN_PVT_URL}UserProfilePicture/${userData?.accountDetails?.UserDetails?.ProfilePicture?.ProfilePictureFileReference}`
                  : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Avatar.png`
              }
              onClick={()=>{
                dispatch(openQrModal());
              }}
            /> */}
            {/* <ReactSVG
              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/arrowDown.svg`}
              alt="img"
              className="list-icon"
              // onClick={() => toggleCard(expandedIndex === -1 ? 0 : -1)}
              onClick={()=>{
                dispatch(openQrModal());
              }}
            /> */}
            {/* <Toggle data={dashboardRedirection} expandedIndex={expandedIndex} toggleCard={toggleCard} /> */}
          </div>

        </div>
      </div>
    </div>
    </>
  );
};

