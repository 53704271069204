import React from "react";

export const TabAcceptRejectCard = ({
  message = "",
  RequestSentOn,
  assetId = "",
  equipmentTypeValue = "",
  oemName = "",
  modelName = "",
  isVerified = "",
  equipmentImage = "",
  city = "",
  state = "",
  receiverContactNumber = "",
  requestType = "",
  repliedValue = "",
  isMaterialLead = "",
  onAcceptRequestClick = () => {},
  onRejectRequestClick = () => {},
}) => {
  // const {getContactRequestReceivedList} = useSelector((state) => state.myLeads);

    return (
        <div className='my-leads-tab-card-container-received'>
            <div className='my-leads-tab-card-body'>
                <div className='my-leads-tab-card-img-container'>
                    <img src={isMaterialLead===1 && equipmentImage ? `${process.env.REACT_APP_CDN_PVT_URL}${equipmentImage}` : equipmentImage ? `${process.env.REACT_APP_CDN_PVT_URL}AssetsImage/${equipmentImage}` :`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/DelarshipDefaultImage.png`} alt='tab-img' className={"my-leads-tab-card__img-container__img"}/>
                    
                </div>
                <div className='my-leads-tab-card-tab-details'>
                    <h1 className='my-leads-header'>{message}</h1>
                    {isMaterialLead===1 && <p>Request Received On:{new Date(RequestSentOn).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '-')}</p>}
                    <h1 className='my-leads-price'>Contact : {receiverContactNumber}</h1>
                    <div className='my-leads-tab-card-contact'>
                        
                            <div style={{
                               display: requestType === "Request Rejected" ? "none" : "",
                               width:"100%"
                                }}>
                                <button className="my-leads-request-reject-button" onClick={onRejectRequestClick} style={{
                                    color: requestType === 'Request Accepted' ? '#319f00' : '',
                                    borderColor: requestType === 'Request Accepted' ? "#319f00" : "",
                                    float: "right"
                                }}
                                disabled={requestType === "Request Accepted" || requestType === "Request Rejected"}
                                >
                                    {requestType === "Request Accepted" ? requestType : "Reject"}
                                </button>
                            </div>
                            <div style={{width:"100%",
                             display: requestType === "Request Accepted" ? "none" : ""
                            }}
                             >
                                <button className="my-leads-request-accept-button" onClick={onAcceptRequestClick}
                                style={{
                                    color: requestType === 'Request Rejected' ? '#d60000':"",
                                    borderColor: requestType === 'Request Rejected' ? "#d60000" : '',
                                    float: "right"
                                }}
                                disabled={requestType === "Request Accepted" || requestType === "Request Rejected"}
                                >
                                    {requestType === 'Request Rejected' ? requestType : 'Accept'}
                                </button>
                            </div>
                     
                    </div>
                </div>
            </div>

      {/* <div style={{display: !repliedValue ? "none" : ""}}>
                <Divider borderColor="#011f6b" borderWidth="1px" />
                <Flex alignItems="center" w="100%" justifyContent="space-between" h="100%">
                    <p>They Replied</p>
                    <p>{repliedValue}</p>
                </Flex>

            </div> */}
    </div>
  );
};

export default TabAcceptRejectCard;
