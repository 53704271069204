import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPurchasedLeadsById } from "../../../services/leadCheck/leadCheck";

export const getMaterialLeadCheckThunk = createAsyncThunk("/leadCheck/material", async(_, {rejectWithValue}) => {
    try{
      const data = await getPurchasedLeadsById(9);
      return data
    }
    catch(error){
       rejectWithValue(error)
       throw error
    }
})