import React, { useState } from "react";
import { SideMenuLayout } from "../../../../../layout/sideMenuLayout/SideMenuLayout";
import { DashboardNavBar } from "../../../../../components/DashboardNavBar/DashboardNavBar";
import { useSelector } from "react-redux";
import { MidSection } from "../../../../../../sharedComponent/midSection/MidSection";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Equip9GetTicket } from "./Equip9SupprtGetTicket/Equip9SupprtGetTicket";
import { useQuery, useQueryClient, useMutation } from "react-query";
// Equip9GetTicket
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from "@chakra-ui/react";
import "./Equip9Suppport.css";
import { TicketRaiseSupport } from "../Equip9Support/Equip9SupportRasieTicket/Equip9SupportRaiseTicket";
import { getRaisedTicket } from "../../../../../services/e9support/e9support";


export const Equip9Support = () => {
  const {defaultLanguage} = useSelector(state => state.masterData);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const userData = useSelector((state) => state.user);
  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const { data: getRaisedTicketData } = useQuery("getRaisedTicketdata", () =>
    getRaisedTicket()
  );
  const raisedTicketCount =
    getRaisedTicketData?.data?.Data?.SupportTicket?.length;

  return (
    <>
      <SideMenuLayout>
        <DashboardNavBar />
        <MidSection>
          {/* <ProfileVerification /> */}
          <div className="supprt-outline">
            <Breadcrumb
              spacing="8px"
              separator={<ChevronRightIcon color="#F79C00" />}
            >
              <BreadcrumbItem>
                <BreadcrumbLink href="/dashboard">Home</BreadcrumbLink>
              </BreadcrumbItem>

              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink href="#">Equip9TM support</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <div className="hero-raiseticket">
              <p className="heroheadline-raise">{defaultLanguage?.Raise_a_ticket}</p>
              <div className="RaiseComplaint">
                <p className="heroSubheadline-raise">
                  {raisedTicketCount>0?(<>Total {`${raisedTicketCount}`} Support Ticket(s) Raised</>):(<>Total {`${0}`} Support Ticket(s) Raised</>)}
                  
                </p>
                <hr className="seprator-hr-ticket"></hr>
                <div className="add-verificat" onClick={openPopup}>
                  <p className="add-verification-detail">{defaultLanguage?.Raise_a_ticket}</p>
                </div>
              </div>
            </div>

            <div>
              <div className="RaiseComplaint">
                <TicketRaiseSupport isOpen={isPopupOpen} onClose={closePopup} />
                <Equip9GetTicket />
              </div>
            </div>
          </div>
        </MidSection>
      </SideMenuLayout>
    </>
  );
};
