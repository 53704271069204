import "./LicenseDetailCard.css";
import React, { useState, useEffect, useRef } from "react";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  // getUploadedVerificationDocumentList,
  deleteLicenseDetail,
  // deleteVerificationDocuments,
  getLicenseDetails,
} from "../../../../../../../../../../ApiEquip9/CommonApi";
import { LoadingSkelaton } from "../../../../../../../../../components/LoadingSkaleton/LoadingSkelton";

export const GetLicenseDetails = ({ triggerRefresh }) => {
  const userData = useSelector((state) => state.user);
  const queryClient = useQueryClient();
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [isDocumentPopupOpen, setIsDocumentPopupOpen] = useState(false);
  const [documentImageUrl, setDocumentImageUrl] = useState("");
  // const [documentPath, setDocumentPath] = useState("");
  const [documents, setDocuments] = useState([]);
  const [documentType, setDocumentType] = useState("");
  const yesButtonRef = useRef(null);
  const noButtonref = useRef(null);

  const {
    data: getLicensedetails,
    isLoading,
    isError,
  } = useQuery("getLicensedetails", () => getLicenseDetails(userData.token));
  const GetLicenseDetails =
    getLicensedetails?.data?.Data?.AllMotorVehicleLicense;
  useEffect(() => {
    if (getLicensedetails?.data?.Data?.VerificationDocuments) {
      setDocuments(getLicensedetails.data.Data.VerificationDocuments);
    }
  }, [getLicensedetails]);

  const deleteVerificationMutation = useMutation(
    (EquipmentLicenseId) =>
      deleteLicenseDetail(EquipmentLicenseId, userData.token),
    {
      onMutate: async (deletedId) => {
        await queryClient.cancelQueries("getLicensedetails");

        setDocuments((prevDocs) =>
          prevDocs?.filter((item) => item.EquipmentLicenseId !== deletedId)
        );

        const previousData = queryClient.getQueryData("getLicensedetails");

        if (previousData) {
          queryClient.setQueryData("getLicensedetails", (oldData) => {
            const newData = { ...oldData };
            newData.data.Data.AllMotorVehicleLicense =
              newData.data.Data.AllMotorVehicleLicense.filter(
                (item) => item.EquipmentLicenseId !== deletedId
              );
            return newData;
          });
        }
        return { previousData };
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          queryClient.setQueryData("getLicensedetails", context.previousData);
          setDocuments(context.previousData.data.Data.VerificationDocuments);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries("getLicensedetails");
        triggerRefresh();
      },
    }
  );

  const openDocumentPopup = (imageUrl) => {
    const fileExtension = imageUrl.split(".").pop().toLowerCase();

    if (["jpg", "jpeg", "png"].includes(fileExtension)) {
      setDocumentType("image");
    } else if (fileExtension === "pdf") {
      setDocumentType("pdf");
    } else {
      return;
    }

    setDocumentImageUrl(imageUrl);
    setIsDocumentPopupOpen(true);
  };

  const closeDocumentPopup = () => {
    setIsDocumentPopupOpen(false);
  };

  const openConfirmationDialog = (EquipmentLicenseId) => {
    setDocumentToDelete(EquipmentLicenseId);
    setConfirmationDialog(true);
  };

  const closeConfirmationDialog = () => {
    setDocumentToDelete(null);
    setConfirmationDialog(false);
  };

  const deleteDocument = async () => {
    if (documentToDelete) {
      await deleteVerificationMutation.mutateAsync(documentToDelete);
      closeConfirmationDialog();
    }
  };

  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    return `${day}-${month}-${year.slice(-2)}`;
  };

  return (
    <>
      {isLoading ? (
        <div style={{ margin: "33px 33px" }}>
          <LoadingSkelaton />
        </div>
      ) : isError ? (
        <div>Error fetching data</div>
      ) : (
        <>
          <div className="card-layou">
            {getLicensedetails?.data?.Data?.AllMotorVehicleLicense?.length ? (
              <>
                {GetLicenseDetails?.map((item) => (
                  <React.Fragment key={item.EquipmentLicenseId}>
                    <div>
                      <div>
                        <div className="get-verify-ca">
                          <div>
                            <div>
                              <div></div>
                              <div className="added-date">
                                <div className="added-date-and-verified-license">
                                  <div>
                                    <span className="added-on">Added On:</span>
                                    <span className="added-on-date">
                                      {formatDate(item.AddedOn.split(" ")[0])}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    height: "23px",
                                    width: "75px",
                                    borderRadius: "23px",
                                    fontSize: "10px",
                                    textAlign: "center",
                                    paddingTop: "5px",
                                    background:
                                      item.IsE9Verified === 0
                                        ? "rgba(214, 0, 0, 0.376)"
                                        : "#319f00",
                                    color: "white",
                                  }}
                                >
                                  {item.IsE9Verified === 0
                                    ? "Not Verified"
                                    : "Verified"}
                                </div>
                              </div>
                              <div className="Doc-classname">
                                <div className="get-image">
                                  <img
                                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Rectangle.png`}
                                  />
                                </div>
                                <div className="Doc-support-block-license">
                                  <div className="get-document-name">
                                    <div className="hero-document">
                                      License Number
                                    </div>
                                    <div className="value-document-name">
                                      {item.LicenseNumber}
                                    </div>
                                  </div>
                                  <div className="get-licese-type-value">
                                    <div className="hero-document">
                                      LicenseTypeValue
                                    </div>
                                    <div className="value-document-name">
                                      {item.LicenseTypeValue}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div>
                            <div></div>
                          </div>
                        </div>
                      </div>

                      <div></div>
                      <div className="get-document-viewDetail">
                        <div
                          className="view-document-detai"
                          onClick={() =>
                            openDocumentPopup(
                              `${process.env.REACT_APP_CDN_PVT_URL}UserLicense/${item.LicenseFileReference}`
                            )
                          }
                        >
                          View Document
                        </div>
                        <div
                          className="get-delete-doc"
                          onClick={() =>
                            openConfirmationDialog(item.EquipmentLicenseId)
                          }
                        >
                          <ReactSVG
                            src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/garbage.svg`}
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </>
            ) : (
              <>
                <div className="no-license-detail">No License added yet</div>
              </>
            )}
          </div>
          {confirmationDialog && (
            <div className="modal-backdrop">
              <div className="confirmation-dialog">
                <div className="deleteHeading">
                  <ReactSVG
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/HeadDelete.svg`}
                  />
                </div>
                <div style={{ marginTop: "12px" }}>
                  <p className="conformation-text">
                    Are you sure you want to delete this document?
                  </p>
                </div>

                <button
                  ref={yesButtonRef}
                  style={{
                    background: "#C6C7C8",
                    transition: "0.3s",
                  }}
                  onMouseOver={() => {
                    yesButtonRef.current.style.background =
                      "linear-gradient(90deg, var(--primary), var(--tertiary))";
                  }}
                  onMouseOut={() => {
                    yesButtonRef.current.style.background = "#C6C7C8";
                  }}
                  onClick={deleteDocument}
                >
                  Yes
                </button>

                <button
                  ref={noButtonref}
                  style={{
                    background: "#C6C7C8",
                    transition: "0.3s",
                  }}
                  onMouseOver={() => {
                    noButtonref.current.style.background =
                      "linear-gradient(90deg, var(--primary), var(--tertiary))";
                  }}
                  onMouseOut={() => {
                    noButtonref.current.style.background = "#C6C7C8";
                  }}
                  onClick={closeConfirmationDialog}
                >
                  No
                </button>
              </div>
            </div>
          )}

          {isDocumentPopupOpen && (
            <div className="document-popup-container">
              <div className="document-popup">
                <p style={{ font: "normal normal bold 18px/22px Assistant" }}>
                  License Detail
                </p>
                {documentType === "pdf" ? (
                  <embed
                    src={documentImageUrl}
                    type="application/pdf"
                    width="100%"
                    height="200px"
                  />
                ) : (
                  <img
                    src={documentImageUrl}
                    alt="Document"
                    style={{ width: "300px", height: "200px" }}
                  />
                )}
                <button className="close-button" onClick={closeDocumentPopup}>
                  <ReactSVG
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Cross.svg`}
                  />
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
