import { Box, Checkbox, Flex, Heading, Text } from "@chakra-ui/react";
import { V5FixedFooterSidebarLayout } from "../../../layout/V5FixedFooterSidebarLayout/V5FixedFooterSidebarLayout";
import fetchHandler from "../../../services/fetchHandler";
import { useDispatch, useSelector } from "react-redux";
import {
  getPremiumCostBreakup,
  getReviewDetails,
} from "../../../services/premium/premium";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { getTransactionState } from "../../../services/subscription/subscription";
import { loadRazorpayScript } from "../../subscription/helpers/razorpay";
import { convertToFormData } from "../../../helpers/dataConversion/convertToFormData";
import { API_CONSTANTS } from "../../../../constants/apiConstants";
import { FormInput } from "../../../components/Input/Input";
import { useNavigate } from "react-router-dom";

export const PremiumCheckout = () => {
  const { premiumTransactionDetailId, premiumOrderId } = useSelector(
    (state) => state.buyPremium
  );
  const { transactionState } = useSelector((state) => state.subscription);
  const { currencyDetails, accountDetails, UserTypeValue } = useSelector(
    (state) => state.user
  );
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [costBreakupDetails, setCostBreakupDetails] = useState(null);
  const [gstRequired, setGstRequired] = useState(false);
  const [gstNumber, setGstNumber] = useState("");
  const [gstErrors, setGstErrors] = useState(" ");

  const userCredentialId = useSelector(
    (state) =>
      state.user.accountDetails.UserDetails.CredentialDetails.UserCredentialId
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getTransactionReviewDetails = async (transactionId) => {
    try {
      const data = await getReviewDetails(transactionId);
      setTransactionDetails(data);
    } catch (error) {}
  };

  const getPremiumCostBreakupDetails = async (transactionId) => {
    try {
      const data = await getPremiumCostBreakup(transactionId);
      setCostBreakupDetails(data);
    } catch (error) {}
  };

  const handleProceedToPay = async () => {
    try {
      const url =
        UserTypeValue === "Company"
          ? "/CreateCompanyE9StagingTransactionOrder"
          : "/CreateStagingOrderId";

      const { data } = await fetchHandler(
        url,
        {},
        {},
        convertToFormData({
          UserTranRefId: premiumTransactionDetailId,
          TransactionModeId: 4,
          TotalAmountAfterTax: parseFloat(
            costBreakupDetails.TotalAmountPayable
          ).toFixed(2),
          CurrencySortForm: "INR",
        }),
        API_CONSTANTS.HTT_POST
      );

      if (data.Status === 201) {
        if (gstNumber) {
          const payload_tax = {
            VirtualSubsOrderId: data.Data.RazorPayOrderId,
            TaxNumber: gstNumber,
          };

          const response = await fetchHandler(
            "/UpdateUserSubscriptionTransactionTaxNumber",
            {},
            {},
            payload_tax,
            API_CONSTANTS.HTTP_PUT,
            { isAuthRequired: true }
          );

          const options = {
            description: "Premium Transaction",
            // image: Constant.ICON_EQUIP9_LOGO,
            currency: "INR",
            key: process.env.REACT_APP_RAZORYPAY_KEY_ID,
            amount: costBreakupDetails.TotalAmountPayable * 100,
            name: "Equip9",
            modal: {
              confirm_close: true,
            },
            order_id: data.Data.RazorPayOrderId,
            theme: { color: "#F67C00" },
            prefill: {
              name: accountDetails.UserDetails.PersonalDetails.EnterpriseName,
              contact: `91${accountDetails.UserDetails.CredentialDetails.MobileNumber}`,
            },
            handler: async (razorpayResponse) => {
              navigate("/premium/payment-status");
            },
            notes: {
              SubscriptionTypeId: costBreakupDetails.SubscriptionTypeId, // Subscription ID at the them of selecting Premium,
              E9OrderId: premiumOrderId,
              UserCredentialId: userCredentialId,
              E9PremiumTransactionDetailsId: premiumTransactionDetailId,
            },
          };
          const razorpay = new window.Razorpay(options);
          razorpay.open();
        } else {
          const options = {
            description: "Premium Transaction",
            // image: Constant.ICON_EQUIP9_LOGO,
            currency: "INR",
            key: process.env.REACT_APP_RAZORYPAY_KEY_ID,
            amount: costBreakupDetails.TotalAmountPayable * 100,
            name: "Equip9",
            modal: {
              confirm_close: true,
            },
            order_id: data.Data.RazorPayOrderId,
            theme: { color: "#F67C00" },
            prefill: {
              name: accountDetails.UserDetails.PersonalDetails.EnterpriseName,
              contact: `91${accountDetails.UserDetails.CredentialDetails.MobileNumber}`,
            },
            handler: async (razorpayResponse) => {
              navigate("/premium/payment-status");
            },
            notes: {
              SubscriptionTypeId: costBreakupDetails.SubscriptionTypeId, // Subscription ID at the them of selecting Premium,
              E9OrderId: premiumOrderId,
              UserCredentialId: userCredentialId,
              E9PremiumTransactionDetailsId: premiumTransactionDetailId,
            },
          };
          const razorpay = new window.Razorpay(options);
          razorpay.open();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadRazorpayScript();
  }, []);

  useEffect(() => {
    if (premiumTransactionDetailId) {
      getTransactionReviewDetails(premiumTransactionDetailId);
      getPremiumCostBreakupDetails(premiumTransactionDetailId);
    }
  }, [premiumTransactionDetailId]);

  useEffect(() => {
    if (!transactionState) {
      dispatch(getTransactionState());
    }
  }, []);

  useEffect(() => {
    if (UserTypeValue === "Company") {
      setGstNumber(accountDetails?.CompanyDetails?.TaxNumber);
    } else {
      setGstNumber("");
    }
  }, []);
  return (
    <V5FixedFooterSidebarLayout
      primaryButtonText={
        transactionState && transactionState.length > 0
          ? transactionState[2].TransactionStateValue
          : ""
      }
      primaryButtonVariant="gradient"
      primaryButtonMaxWidth="380px"
      primaryButtonWidth="100%"
      primaryButtonClickAction={handleProceedToPay}
    >
      <Box padding="0 52px">
        <Box paddingBottom="1rem">
          <Heading>Premium Checkout</Heading>
          <Text fontWeight="400" color="#767676" fontSize="20px">
            Review Details Of Your Purchased Service
          </Text>
        </Box>
        <Flex gap="1rem">
          <Flex
            flex="1"
            flexDirection="column"
            gap="12px"
            padding="0.75rem"
            borderRadius="4px"
            boxShadow="0px 0px 4px 0px #00000025"
          >
            <Text color="#F79C00" fontWeight="700" fontSize="18px">
              Your Selected Premium Plan
            </Text>
            <Flex width="100%" bgColor="#4A5A821A" justifyContent="center">
              <Text color="#002961" fontWeight="700">
                {`Order No: `}
                <span style={{ fontWeight: "600" }}>{`${premiumOrderId}`}</span>
              </Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Text>Subscription Type</Text>
              <Text fontWeight="600" fontSize="18px">
                Premium
              </Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Text>Premium Type</Text>
              <Text fontWeight="600" fontSize="18px">
                {transactionDetails && transactionDetails.PaymentPlanValue
                  ? transactionDetails.PaymentPlanValue
                  : ""}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Text>Total Cost</Text>
              <Text fontWeight="600" fontSize="18px">
                {currencyDetails?.CurrencyValue}{" "}
                {costBreakupDetails && costBreakupDetails.TotalCost
                  ? costBreakupDetails.TotalCost
                  : ""}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Text>Discount</Text>
              <Text fontWeight="600" fontSize="18px" color="#319F00">
                {"- "}
                {currencyDetails?.CurrencyValue}{" "}
                {costBreakupDetails && costBreakupDetails.Discount
                  ? costBreakupDetails.Discount
                  : 0}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontWeight="600" fontSize="18px" color="#002961">
                Sub Total
              </Text>
              <Text fontWeight="600" fontSize="18px" color="#002961">
                {currencyDetails?.CurrencyValue}{" "}
                {costBreakupDetails && costBreakupDetails.SubTotal
                  ? costBreakupDetails.SubTotal
                  : ""}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Text>Offers</Text>
              <Text fontWeight="600" fontSize="18px" color="#319F00">
                {currencyDetails?.CurrencyValue}{" "}
                {costBreakupDetails && costBreakupDetails.OfferAmount
                  ? costBreakupDetails.OfferAmount
                  : 0}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Text>Net Payable</Text>
              <Text fontWeight="600" fontSize="18px" color="#319F00">
                {currencyDetails?.CurrencyValue}{" "}
                {costBreakupDetails && costBreakupDetails.NetPayable
                  ? costBreakupDetails.NetPayable
                  : 0}
              </Text>
            </Flex>
            {costBreakupDetails && costBreakupDetails.TaxDetails ? (
              costBreakupDetails.TaxDetails.map(
                ({ TaxType, TaxPercentage, TaxValue }) => (
                  <Flex
                    key={uuid()}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text>{`${TaxType} ( ${TaxPercentage}% )`}</Text>
                    <Text fontSize="18px">
                      {currencyDetails?.CurrencyValue} {TaxValue}
                    </Text>
                  </Flex>
                )
              )
            ) : (
              <></>
            )}
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontWeight="700" fontSize="22px">
                Total Amount
              </Text>
              <Text fontWeight="700" fontSize="22px" color="#002961">
                {currencyDetails?.CurrencyValue}{" "}
                {costBreakupDetails && costBreakupDetails.TotalAmountPayable
                  ? costBreakupDetails.TotalAmountPayable
                  : 0}
              </Text>
            </Flex>
          </Flex>
          <Flex
            flex="1"
            flexDirection="column"
            gap="1rem"
            padding="0.75rem"
            borderRadius="4px"
            boxShadow="0px 0px 4px 0px #00000025"
          >
            <Flex justifyContent="space-between" width="100%">
              <Text fontWeight="700" fontSize="22px">
                Total Amount
              </Text>
              <Text fontWeight="700" fontSize="22px" color="#002961">
                {currencyDetails?.CurrencyValue}
                {costBreakupDetails && costBreakupDetails.TotalAmountPayable
                  ? costBreakupDetails.TotalAmountPayable
                  : 0}
              </Text>
            </Flex>
            <Flex
              flexDirection="column"
              backgroundColor="#FFD48A80"
              padding="25px 20px"
              gap="1rem"
            >
              {UserTypeValue === "Company" ? (
                <>
                  <Checkbox isChecked={true} fontWeight={600}>
                    I have a GSTIN Number Company
                  </Checkbox>
                  <FormInput
                    isDisabled={gstNumber}
                    type="text"
                    name="taxNumber"
                    label=""
                    placeholder="Enter valid GST Number..."
                    maxLength={15}
                    backgroundColor="#FFF"
                    // errorMessage={gstErrors}
                    value={gstNumber}
                  />
                </>
              ) : (
                <>
                  <Checkbox
                    isChecked={gstRequired}
                    onChange={(e) => {
                      setGstNumber("");
                      setGstRequired((prevState) => !prevState);
                    }}
                    fontWeight={600}
                  >
                    I have a GSTIN Number
                  </Checkbox>
                  <FormInput
                    isDisabled={!gstRequired}
                    type="text"
                    name="taxNumber"
                    label=""
                    placeholder="Enter valid GST Number..."
                    maxLength={15}
                    backgroundColor="#FFF"
                    // errorMessage={gstErrors}
                    onChange={(e) => {
                      setGstErrors(" ");
                      setGstNumber(e.target.value);
                    }}
                    value={gstNumber}
                  />
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </V5FixedFooterSidebarLayout>
  );
};
