import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFilteredPartners } from "../../../services/jobsPosted/jobsPosted";

export const getUemInititalFilteredDataThunk = createAsyncThunk("uem/initialFilteredData",async(_, {getState}) => {
    const selectedCountry = getState().usedEquipmentMarketplace.filterByCountry;
    const selectedState = getState().usedEquipmentMarketplace.filterByState;
    const selectedVerificationStatus = getState().usedEquipmentMarketplace.filterByVerificationStatus;
    const selectedEquipmentType = getState().usedEquipmentMarketplace.filterByEquipmentType;
    const cardCount  = getState().usedEquipmentMarketplace.cardCount;
   

    let query = ""

    if(selectedCountry){
        query += `&FilterByLocationCountry=${selectedCountry}`
    }
    if(selectedState){
        query+= `&FilterByLocationState=${selectedState}`
    }
    if(selectedVerificationStatus){
        query += `&FilterByVerificationStatus=${selectedVerificationStatus}`
    }
    if(selectedEquipmentType){
        query+= `&FilterByEquipmentType=${selectedEquipmentType}`
    }

    try{
    
        const data = await getFilteredPartners(cardCount,0,`?FilterType=Used Equipment${query}`);

        if(data.Status === 200){
            return data.Data
        }
    }
    catch(error){
    }

    
})