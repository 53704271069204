import {
  Accordion,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";

import { RadioParent } from "../../../components/RadioParent/RadioParent";
import { FormikDropdown } from "../../../components/Dropdown/FormikDropdown";
import { resetOperatorSearch, setInsuranceType, setIsLoading, setJobDuration, setJobDurationType, setLunchHours, setShiftType, setTravelAllowance, setWorkType, setWorkingHours } from "../slice/operatorSearchSlice";
import { createOperatorJobDetailsThunk } from "../thunks/createOperatorJobDetails";
import { useNavigate } from "react-router-dom";
import { getJobDurations, getLunchBreakTypes, getWorkingHourTypes, getWorkingShiftTypes } from "../../../services/shift/shift";
import { getNatureOfWorkTypes, getTravelAllowanceTypes } from "../../../services/natureOfWork/natureOfWork";
import { getInsuranceTypes } from "../../../services/insurances/insurances";
import { AccordianElement } from "../../../components/AccordianElement/AccordianElement";
import { jobDurationTypeList } from "../../../../staticData/staticData";

export const ShiftTypeForm = () => {
  const { token } = useSelector((state) => state.user);
  const {
    shiftType,
    workingHours,
    lunchHours,
    jobDuration,
    jobDurationType,
    workType,
    insurance,
    travelAllowance,
  } = useSelector((state) => state.operatorSearch);
  const [workingShiftTypeList, setWorkingShiftTypeList] = useState(null);
  const [jobDurationList, setJobDurationList] = useState(null);
  const [workingHourTypeList, setWorkingHourTypeList] = useState(null);
  const [lunchHourTypeList, setLunchHourTypeList] = useState(null);
  const [workNatureTypeList, setWorkNatureTypeList] = useState(null);
  const [travelAllowanceTypeList, setTravelAllowanceTypeList] = useState(null);
  const [insuranceTypeList, setInsuranceTypeList] = useState(null);
  const dispatch = useDispatch()
  const toast = useToast();
  const navigate = useNavigate()

  const getWorkingShiftTypeList = async () => {
    try {
      const data = await getWorkingShiftTypes();
      setWorkingShiftTypeList(data)
    }
    catch (error) {
      setWorkingShiftTypeList(null)
      return error;
    }
  };

  const getJobDurationList = async () => {
    try {
      const data = await getJobDurations()
      setJobDurationList(data)
    }
    catch (error) {
      setJobDurationList(null)
      return error;
    }
  };

  const getWorkingHourTypeList = async () => {
    try {
      const data = await getWorkingHourTypes();
      setWorkingHourTypeList(data)
    }
    catch (error) {
      setJobDurationList(null)
      return error;
    }
  };
  const getLunchHourTypeList = async () => {
    try {
      const data = await getLunchBreakTypes();
      setLunchHourTypeList(data)
    }
    catch (error) {
      setLunchHourTypeList(null)
      return error;
    }
  };
  const getInsuranceTypeList = async () => {
    try {
      const data = await getInsuranceTypes();
      setInsuranceTypeList(data)
    }
    catch (error) {
      setInsuranceTypeList(null);
      return error;
    }
  };

  const getNatureOfWorkList = async () => {
    try {
      const data = await getNatureOfWorkTypes();
      setWorkNatureTypeList(data)
    }
    catch (error) {
      setWorkNatureTypeList(null)
      return error;
    }
  };
  const getTravelAllowanceList = async () => {
    try {
      const data = await getTravelAllowanceTypes();
      setTravelAllowanceTypeList(data)
    }
    catch (error) {
      setTravelAllowanceTypeList(null)
      return error;
    }
  };

  useEffect(() => {
    (async () => {
      await getWorkingShiftTypeList();
      await getJobDurationList();
      await getWorkingHourTypeList();
      await getLunchHourTypeList();
      await getNatureOfWorkList();
      await getTravelAllowanceList();
      await getInsuranceTypeList();
    })();
  }, []);
  return (
    <Formik
      initialValues={{
        _shiftType: shiftType ? shiftType : "",
        _workingHours: workingHours ? workingHours : "",
        _lunchHours: lunchHours ? lunchHours : "",
        _jobDuration: jobDuration ? jobDuration : "",
        _workType: workType ? workType : "",
        _insurance: insurance ? insurance : "",
        _travelAllowance: travelAllowance ? travelAllowance : "",
        _jobDurationType: jobDurationType ? jobDurationType : "",
      }}
      validationSchema={Yup.object().shape({
        _shiftType: Yup.number().required("Shift Type is Required"),
        _workingHours: Yup.string().required("Working Hours is Required"),
        _lunchHours: Yup.string().required("Lunch Hours is Required"),
        _jobDuration: Yup.string().required("Job Duration is Required"),
        _workType: Yup.string().required("Work Type is Required"),
        _insurance: Yup.number().required("Insurance is Required"),
        _travelAllowance: Yup.number().required("Travel Allowance is Required"),
        _jobDurationType: Yup.string().required("Job Duration Type is Required"),
      })}
      onSubmit={async (values) => {

        dispatch(setShiftType(values._shiftType))
        dispatch(setWorkingHours(values._workingHours))
        dispatch(setLunchHours(values._lunchHours))
        dispatch(setJobDuration(values._jobDuration))
        dispatch(setJobDurationType(values._jobDurationType))
        dispatch(setWorkType(values._workType))
        dispatch(setInsuranceType(values._insurance))
        dispatch(setTravelAllowance(values._travelAllowance))
      
        try {
          dispatch(setIsLoading(true))
          await dispatch(createOperatorJobDetailsThunk()).unwrap()
          toast({
            title: "Job Created Successfully!",
            position: "top-right",
            status: "success"

          })
          dispatch(resetOperatorSearch())
          navigate("/dashboard")
        }
        catch (error) {
          toast({
            title: "Some Error Happeneed, Please Try again.",
            position: "top-right",
            status: "error"

          })
        }
        finally{
          dispatch(setIsLoading(false))
        }
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <form onSubmit={handleSubmit} id="shift-type-form">
          <Accordion allowMultiple>
            <Flex flexDirection="column" gap="1em">
              <AccordianElement title="Working Shift">
                <Flex wrap="wrap" gap="1em" justifyContent="space-between">
                  <Field name="_shiftType">
                    {({ field }) => (
                      <RadioParent
                        name={field.name}
                        label="Shift Type"
                        options={workingShiftTypeList}
                        maxWidth="450px"
                        isFormik={true}
                        isRequired={true}
                      />
                    )}
                  </Field>
                  <FormikDropdown
                    name="_workingHours"
                    label="Working Hours"
                    value={values._workingHours}
                    maxWidth="230px"
                    isRequired={false}
                    isDisabled={!workingHourTypeList}
                    onChange={(e) => {
                      setFieldValue("_workingHours", e.target.value);
                    }}
                  >
                    {workingHourTypeList && workingHourTypeList.length > 0
                      ? workingHourTypeList.map(
                        ({ id, value }) => (
                          <option key={uuid()} value={id}>
                            {value}
                          </option>
                        )
                      )
                      : []}
                  </FormikDropdown>
                  <FormikDropdown
                    name="_lunchHours"
                    label="Lunch Hours"
                    value={values._lunchHours}
                    maxWidth="230px"
                    isRequired
                    isDisabled={!lunchHourTypeList}
                    onChange={(e) => {
                      setFieldValue("_lunchHours", e.target.value);
                    }}
                  >
                    {lunchHourTypeList && lunchHourTypeList.length > 0
                      ? lunchHourTypeList.map(
                        ({ id, value }) => (
                          <option key={uuid()} value={id}>
                            {value}
                          </option>
                        )
                      )
                      : []}
                  </FormikDropdown>
                  <FormikDropdown
                    name="_jobDuration"
                    label="Job Duration"
                    value={values._jobDuration}
                    maxWidth="450px"
                    isRequired
                    isDisabled={!jobDurationList}
                    onChange={(e) => {
                      setFieldValue("_jobDuration", e.target.value);
                    }}
                  >
                    {jobDurationList && jobDurationList.length > 0
                      ? jobDurationList.map(
                        ({ id, value }) => (
                          <option key={uuid()} value={id}>
                            {value}
                          </option>
                        )
                      )
                      : []}
                  </FormikDropdown>
                  <FormikDropdown
                    name="_jobDurationType"
                    label="Year/Month"
                    value={values._jobDurationType}
                    maxWidth="450px"
                    isRequired
                    onChange={(e) => {
                      setFieldValue("_jobDurationType", e.target.value);
                    }}
                  >
                   {jobDurationTypeList && jobDurationTypeList.length > 0
                      ? jobDurationTypeList.map(
                        ({ id, value }) => (
                          <option key={uuid()} value={value}>
                            {value}
                          </option>
                        )
                      )
                      : []}
                  </FormikDropdown>
                </Flex>
              </AccordianElement>
              <AccordianElement title="Nature of Work">
                <Flex wrap="wrap" gap="1em" justifyContent="space-between">
                  <FormikDropdown
                    name="_workType"
                    label="Work Type"
                    value={values._workType}
                    maxWidth="450px"
                    isRequired={true}
                    isDisabled={!workNatureTypeList}
                    onChange={(e) => {
                      setFieldValue("_workType", e.target.value);
                    }}
                  >
                    {workNatureTypeList && workNatureTypeList.length > 0
                      ? workNatureTypeList.map(
                        ({ id, value }) => (
                          <option key={uuid()} value={id}>
                            {value}
                          </option>
                        )
                      )
                      : []}
                  </FormikDropdown>

                  <Field name="_travelAllowance">
                    {({ field }) => (
                      <RadioParent
                        label="Travel Allowance ?"
                        options={travelAllowanceTypeList}
                        name={field.name}
                        maxWidth="450px"
                        isFormik={true}
                        isRequired={true}
                      />
                    )}
                  </Field>

                  <Field name="_insurance">
                    {({ field }) => (
                      <RadioParent
                        label="Insurance"
                        options={insuranceTypeList}
                        name={field.name}
                        maxWidth="450px"
                        isFormik={true}
                        isRequired={true}
                      />
                    )}
                  </Field>
                </Flex>
              </AccordianElement>
            </Flex>
          </Accordion>
        </form>
      )}
    </Formik>
  );
};
