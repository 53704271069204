import { useDispatch, useSelector } from "react-redux";
import { CustomInfoModal } from "../../../components/CustomInfoModal/CustomInfoModal";
import { SetIsQuizNotPlayedByAnyoneModalOpen } from "../slice/quizSlice";

export const QuizNotPlayedByAnyonePopup = () => {
  const { isQuizNotPlayedByAnyonePopupOpen, selectedQuiz } = useSelector((state) => state.quiz);
  const dispatch = useDispatch();
  const onButtonClick = () => {
    dispatch(SetIsQuizNotPlayedByAnyoneModalOpen(false));
  };

  return (
    <>
      <CustomInfoModal
        isOpen={isQuizNotPlayedByAnyonePopupOpen}
        iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/popup/circularArrow.svg`}
        heading={selectedQuiz?.QuizName}
        subHeading={`No Quiz Winners Available`}
        primaryAction={onButtonClick}
        primaryActionText={"Okay"}
        hasCloseButton={true}
        onCloseButtonClick={onButtonClick}
        headerSectionMargin={"0 70px"}
      ></CustomInfoModal>
    </>
  );
};
