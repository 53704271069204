import React from "react";
import { getUserEquipmentLicenceAddress } from "../../../../../../../../../../../ApiEquip9/CommonApi";
import { useSelector } from "react-redux";
import { useQuery} from "react-query";
import "./ShowAdditionalDetails.css";
export const ShowAdditionalDetails = () => {
  const userData = useSelector((state) => state.user);
  const { data: additionalDetails } = useQuery("getAdditonlDetails", () =>
    getUserEquipmentLicenceAddress(userData.token)
  );
  const AdditionalDetails = additionalDetails?.data?.Data?.Address;

  return (
    <>
      <div className="card-layou">
        <div className="get-verify-license">
          <p className="hero-additioonal-details">How old is your License</p>
          <div className="get-country-state-data">
            <p>
              <span className="hero-document-additional">In Years: &nbsp;</span>
              <span className="value-document-name-additional">
                {AdditionalDetails?.ExperienceYear}
              </span>
            </p>
            <p>
              <span className="hero-document-additional">In Month: &nbsp;</span>
              <span className="value-document-name-additional">
                {AdditionalDetails?.ExperienceMonth}
              </span>
            </p>
          </div>
          <br />
          <p className="hero-additioonal-details">License Issued At</p>
          <div className="get-expirence-data">
            <p>
              <span className="hero-document-additional">Country: &nbsp;</span>
              <span className="value-document-name-additional">
                {AdditionalDetails?.CountryName}
              </span>
            </p>
            <p>
              <span className="hero-document-additional">State: &nbsp;</span>
              <span className="value-document-name-additional">
                {AdditionalDetails?.StateName}
              </span>
            </p>
            <p>
              <span className="hero-document-additional">City: &nbsp;</span>
              <span className="value-document-name-additional">
                {AdditionalDetails?.CityName}
              </span>
            </p>
          </div>
        </div>
      </div> 
    </>
  );
};
