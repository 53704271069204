import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { EquipmentRentalLayout } from "../../layout/equipmentRentalLayout/EquipmentRentalLayout";
import { ReactSVG } from "react-svg";
import {
  Flex,
  Box,
  Button,
  Divider,
  Text,
  Modal,
  ModalContent,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  Heading,
  useToast,
} from "@chakra-ui/react";
import { DetailsCard } from "../DetailsCard/DetailsCard";
import shareIcon from "../../../assets/webapp/dashboard/share.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "./AssetDetailsPage.css";
import { QrPopUp } from "../ScanQrCodeModal/QrPopUp";
import { openQrModal } from "../../features/dashboard/slice/dashboardSlice";
import { Like } from "../Like/Like";
import {
  sendLikeRequestToEquipment,
  sendRentalDurationRequest,
} from "../../services/equipmentMarketplace/equipmentMarketplace";
import { getResponseOptionType } from "../../services/postDealership/postDealership";
import { getFreeLeadsForCompanyThunk } from "../../features/freeLeads/thunks/getFreeLeadsForCompanyThunk";
import { getFreeLeadsForIndividualThunk } from "../../features/freeLeads/thunks/getFreeLeadsForUserThunk";
import { getUemConnectLeadCheckThunk } from "../../features/purchasedLeadsCheck/thunks/getUemConnectLeadsCheckThunk";
import { getRemConnectLeadCheckThunk } from "../../features/purchasedLeadsCheck/thunks/getRemConnectLeadsCheckThunk";
import { NoLeadsPopUp } from "../NoLeadsPopUp/NoLeadsPopUp";
import { RentalDurationPopUp } from "../RentalDurationPopUp/RentalDurationPopUp";
import {
  closeRentalDurationModal,
  openRentalDurationModal,
} from "../../features/rentalEquipmentMarketplace/slice/rentalEquipmentMartketplace";
import { updateAssetContactRequest } from "../../services/myLeads/myLeads";
import { getE9MarketPlaceDetailsById } from "../../services/equipments/equipments";
import { getInitialUserAssetsThunk } from "../../features/equipmentStore/thunks/getInitialUserAssetsThunk";
import { V5SidebarLayout } from "../../layout/V5SidebarLayout/V5SidebarLayout";
import { V5FixedFooterSidebarLayout } from "../../layout/V5FixedFooterSidebarLayout/V5FixedFooterSidebarLayout";
import { getCheckUserLead } from "../../features/userLeadsCheck/helper/userLeadsCheck";
import { setUserLeadsData } from "../../features/userLeadsCheck/slices/userLeadsCheckSlice";
import { getTransactionModeDetailsThunk } from "../../features/myLeads/thunk/getTransactionModeDetailsThunk";
import { createE9MarketPlaceContactRedeemDetails, updateE9MarketplaceContactRedeemedDetails } from "../../services/userAssets/userAssets";
import { encryptShareData } from "../../services/share/share";
import { useClipboard } from "../../hooks/useClipboard";
import { primaryKeyEncryption } from "../../helpers/dataConversion/primaryKeyEncryption";
import { convertToBase64 } from "../../../utils/formatConverter/convertToBase64";

export const AssetDetailsPage = () => {
  const { assetId } = useParams();
  const { pathname } = useLocation();
  const {copyToClipboard} = useClipboard();


  const userData = useSelector((state) => state.user);
  const { data:userLeads} = useSelector((state) => state.userLeads);
  const [assetData, setAssetData] = useState(null);
  const [likeStatus, setLikeStatus] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [noLeadsModalOpen, setNoleadsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [requiredMarketplaceFreeData, setRequiredMarketplaceFreeData] =
    useState(null);
  const [marketplaceType, setMarketplaceType] = useState(null);
  const { freeLeadsData } = useSelector((state) => state.freeLeads);
  const { leadsRemaining: uemLeads } = useSelector(
    (state) => state.purchasedLeads.usedEquipment
  );
  const { leadsRemaining: remLeads } = useSelector(
    (state) => state.purchasedLeads.rentalEquipment
  );
  const { data: companyEquipments } = useSelector(
    (state) => state.equipmentStore
  );
  const { accountDetails, UserTypeValue } = useSelector(
    (state) => state.user
  );
  const { transactionModeDetails } = useSelector((state) => state.myLeads);

  const userCredentialId = accountDetails?.UserDetails?.CredentialDetails?.UserCredentialId;
  const userTypeValue = userData.UserTypeValue;
  const toast = useToast();
  const [companyEquipmentIds, setCompanyEquipmentIds] = useState(null);

  const navigate = useNavigate();
  const getCurrentYear = () => {
    const date = new Date();
    const year = date.getFullYear();
    return year;
  };

  const getResponseTypeList = async () => {
    try {
      const data = await getResponseOptionType();
    } catch (error) {}
  };

  const handleLike = async (action) => {
    try {
      const data = await sendLikeRequestToEquipment({
        E9MarketPlaceId: assetData.E9MarketPlaceDetails.E9MarketPlaceId,
        LikeStatus: action ? 1 : 0,
      });
    } catch (error) {}
  };

  const getMarketplaceDetails = async () => {
    const data = await getE9MarketPlaceDetailsById(assetId);

    setAssetData(data.Data);
    if (
      data.Data &&
      data.Data.E9MarketPlaceDetails &&
      data.Data.E9MarketPlaceDetails.LikeStatus
    ) {
      setLikeStatus(data.Data.E9MarketPlaceDetails.LikeStatus);
    }
  };

  const createRedeemDetails= async (E9MarketPlaceContactId,item)=>{

    if (E9MarketPlaceContactId == null) {
      toast({
        title: "",
        description: "Redeem Failed",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return ;
    }

    const Payload = {
      E9MpContactId: E9MarketPlaceContactId,
      ResponseTypeId: 1,
      ServiceTypeId: marketplaceType === "rental" ? 5 : 4 ,
    };

    
    const freeContactRemaining =userLeads?.freeData?.LeadRemaining
    const premiumContactRemaining=userLeads?.premium?.LeadsData?.[0]?.RemainingCount
    const subscriptionContactRemaining=userLeads?.subscription?.LeadRemaining

    const getTransactionModeId = () => {
      if (freeContactRemaining > 0) {
        const foundItem = transactionModeDetails.find(
          (item) => item.TransactionModeValue === "Free"
        );
        return foundItem?.TransactionModeId || null;
      }
  
      if (premiumContactRemaining > 0) {
        const foundItem = transactionModeDetails.find(
          (item) => item.TransactionModeValue === "Premium"
        );
        return foundItem?.TransactionModeId || null;
      }
  
      if (subscriptionContactRemaining > 0) {
        const foundItem = transactionModeDetails.find(
          (item) => item.TransactionModeValue === "Subscription"
        );
        return foundItem?.TransactionModeId || null;
      }
      return null;
    };
    Payload.TransactionModeId = getTransactionModeId();

    const response= await createE9MarketPlaceContactRedeemDetails(Payload);

  }

  const updateRedeemDetails= async (E9MarketPlaceContactId,item)=>{
        
    if (E9MarketPlaceContactId == null) {
      toast({
        title: "",
        description: "Redeem Failed",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return ;
    }

    var raw_payload = {
      E9MarketplaceContactRedeemedDetails: {
        E9MarketPlaceContactId: item?.E9MarketPlaceContactId,
        ResponseTypeValue: item?.RequestTypeId === 4 ? "Request Sent" : "Request Revoked",
        TransactionModeId: "",
        SenderUserCredentialId: userCredentialId,
      },
    };

    const response = await updateE9MarketplaceContactRedeemedDetails(raw_payload);

  }


  const handleContactSend = async (
    equipmentMarketplaceId,
    recieverCredentials,
    equipmentData
  ) => {
    const body = {
      E9MarketPlaceId: equipmentMarketplaceId,
      ReceiverUserCredentialId: recieverCredentials,
      ContactStatus: 1,
      RequestTypeId: 1,
    };
    let payload = [];
    for (const key in body) {
      const requiredKey = encodeURIComponent(key);
      const requiredValue = encodeURIComponent(body[key]);
      payload.push(`${requiredKey}=${requiredValue}`);
    }

    const finalPayload = payload.join("&");
    try {
      const data = await updateAssetContactRequest(finalPayload);
      const newState = { ...assetData };
      newState.E9MarketPlaceDetails.ResponseTypeValue = "Request Sent";
      newState.E9MarketPlaceDetails.E9MarketPlaceContactId = newState.E9MarketPlaceDetails.E9MarketPlaceContactId ? newState.E9MarketPlaceDetails.E9MarketPlaceContactId : data?.Data?.LastE9MarketplaceContactId ;
      setAssetData(newState);

      if (data.Status === 201 || data.Status === 204) {  
           
        if(data?.Data?.LastE9MarketplaceContactId){
          createRedeemDetails(data?.Data?.LastE9MarketplaceContactId,equipmentData);
        }else{
          const data = await getE9MarketPlaceDetailsById(assetId);
          setAssetData(data.Data);
          updateRedeemDetails(data?.Data?.E9MarketPlaceDetails?.E9MarketPlaceContactId,data?.Data?.E9MarketPlaceDetails);
        }
        
      }

    } catch (error) {
      // Handle errors here
    }
  };

  const handleRevoke = async (equipmentMarketplaceId, recieverCredentials,equipmentData) => {
    const body = {
      E9MarketPlaceId: equipmentMarketplaceId,
      ReceiverUserCredentialId: recieverCredentials,
      ContactStatus: 0,
      RequestTypeStatus: 4,
    };
    let payload = [];
    for (const key in body) {
      const requiredKey = encodeURIComponent(key);
      const requiredValue = encodeURIComponent(body[key]);
      payload.push(`${requiredKey}=${requiredValue}`);
    }

    const finalPayload = payload.join("&");
    try {
      const data = await updateAssetContactRequest(finalPayload);

      const newState = { ...assetData };
      newState.E9MarketPlaceDetails.ResponseTypeValue = "Request Revoked";
      setAssetData(newState);
      if (data.Status === 201 || data.Status === 204) {
        updateRedeemDetails(equipmentData?.E9MarketPlaceContactId,equipmentData);
    }
    } catch (error) {}
  };

  const handleRentalDuration = async (value) => {
    try {
      const data = await sendRentalDurationRequest({
        RentDurationId: value.RentalDurationId,
        E9MarketplaceId: assetData.E9MarketPlaceDetails.E9MarketPlaceId,
      });

      // await handleContactSend();
      // setSelectedEquipment(null);
      // setRecieversId(null);
      // dispatch(closeRentalDurationModal());
    } catch (error) {}
  };

  const handleRentalDurationPopup = async (value) => {
    try {
      // handle Rental duration
      await handleRentalDuration(value);
      // handle contact send
      await handleContactSend(
        assetData.E9MarketPlaceDetails.E9MarketPlaceId,
        assetData.E9MarketPlaceDetails.AssetOwnerUserCredentialId
      );
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(closeRentalDurationModal());
    }
  };

  //  Get Free leads on load
  useEffect(() => {
    getMarketplaceDetails();
    getResponseTypeList();
  }, []);
  getCurrentYear();
  useEffect(() => {
    if (userTypeValue && userTypeValue === "Company") {
      dispatch(getFreeLeadsForCompanyThunk());
    } else if (userTypeValue) {
      dispatch(getFreeLeadsForIndividualThunk());
    } else {
      return null;
    }
  }, []);

  //  Set Free Leads Data
  useEffect(() => {
    if (freeLeadsData && pathname.split("/")[2] === "MarketplaceUEM") {
      const requiredData = freeLeadsData.find(
        ({ ServiceTypeValue, SubscriptionTypeValue }) =>
          ServiceTypeValue === "Used Equipment" &&
          SubscriptionTypeValue === "Contact Based"
      );
      setRequiredMarketplaceFreeData(requiredData);
      setMarketplaceType("used");
    } else if (freeLeadsData) {
      const requiredData = freeLeadsData.find(
        ({ ServiceTypeValue, SubscriptionTypeValue }) =>
          ServiceTypeValue === "Rental Equipment" &&
          SubscriptionTypeValue === "Contact Based"
      );
      setRequiredMarketplaceFreeData(requiredData);
      setMarketplaceType("rental");
    } else {
      setRequiredMarketplaceFreeData(null);
    }
  }, [freeLeadsData]);

  //  Get Subscription Data
  useEffect(() => {
    if (requiredMarketplaceFreeData) {
      if (marketplaceType && marketplaceType === "used") {
        dispatch(
          getUemConnectLeadCheckThunk(requiredMarketplaceFreeData.ServiceTypeId)
        );
      } else if (marketplaceType) {
        dispatch(
          getRemConnectLeadCheckThunk(requiredMarketplaceFreeData.ServiceTypeId)
        );
      } else {
        return null;
      }
    }
  }, [marketplaceType, requiredMarketplaceFreeData]);

  useEffect(() => {
    if (
      userTypeValue &&
      userTypeValue.toLowerCase() === "company".toLowerCase()
    ) {
      dispatch(getInitialUserAssetsThunk());
    }
  }, []);

  useEffect(() => {
    if (companyEquipments) {
      const assetIds = companyEquipments.map(({ AssetId }) => AssetId);
      setCompanyEquipmentIds(assetIds);
    }
  }, [companyEquipments, assetData]);

  useEffect(()=>{
    (async ()=>{
      const userLeadsData=await getCheckUserLead(marketplaceType === "rental"? 5 : 4 ,"",userCredentialId);
      dispatch(setUserLeadsData(userLeadsData));
      dispatch(getTransactionModeDetailsThunk());
    })()
    
  },[marketplaceType])

  const encryptAssetDetails = async (E9MarketPlaceId) => {
    try {
      // const payload = {
      //   marketplaceCategory: "equipment",
      //   marketplaceSubCategory: "used",
      //   assetId: E9MarketPlaceId,
      // };

      // const shareLink = await encryptShareData(payload);
      await copyToClipboard(
        `${process.env.REACT_APP_WEBSITE_URL}share?category=${"equipment"}&subCategory=${"used"}&id=${convertToBase64(E9MarketPlaceId)}`
      );

      toast({
        title: "Link Copied!",
        description: "Share link has been successfully copied to clipboard.",
        status: "success",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        description: "Failed to share. Please try again.",
        position: "top-right",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };


  return (
    <>
      <QrPopUp />
      <NoLeadsPopUp
        isOpen={noLeadsModalOpen}
        onButtonClick={() => {
          setNoleadsModalOpen(false);
        }}
        onCloseClick={() => {
          setNoleadsModalOpen(false);
        }}
      />
      <RentalDurationPopUp onSubmit={handleRentalDurationPopup} />
      <Modal isCentered isOpen={modalOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex justifyContent="space-between">
              <Heading size="26px">
                {selectedDocument && selectedDocument.name
                  ? selectedDocument.name
                  : "Document"}
              </Heading>
              <ReactSVG
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Cross.svg`}
                onClick={() => setModalOpen(false)}
                style={{ cursor: "pointer" }}
              />
            </Flex>
          </ModalHeader>
          <ModalBody>
            {assetData && assetData.AssetDocumentReference ? (
              <img
                src={`${process.env.REACT_APP_CDN_PVT_URL}${
                  assetData.AssetDocumentReference
                }${selectedDocument ? selectedDocument.url : ""}`}
                style={{
                  height: "90%",
                  width: "90%",
                  borderRadius: "8px",
                  margin: "0 auto",
                  marginBottom: "1em",
                }}
              />
            ) : (
              <></>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <V5FixedFooterSidebarLayout
        primaryButtonBorderColor={
          assetData?.E9MarketPlaceDetails?.UserCredentialId ===
            userData?.accountDetails?.UserDetails?.CredentialDetails
              ?.UserCredentialId ||
          (companyEquipmentIds &&
            assetData &&
            companyEquipmentIds.includes(
              parseInt(assetData?.AssetDetails?.AssetId)
            ))
            ? "#011f6b"
            : assetData?.E9MarketPlaceDetails?.ResponseTypeValue ===
              "Request Rejected"
            ? "#D60000"
            : assetData?.E9MarketPlaceDetails?.ResponseTypeValue ===
                "Request Sent" ||
              assetData?.E9MarketPlaceDetails?.ResponseTypeValue ===
                "Request Accepted"
            ? "#319F00"
            : "#F67C00"
        }
        primaryButtonTextColor={
          assetData?.E9MarketPlaceDetails?.UserCredentialId ===
            userData?.accountDetails?.UserDetails?.CredentialDetails
              ?.UserCredentialId ||
          (companyEquipmentIds &&
            assetData &&
            companyEquipmentIds.includes(
              parseInt(assetData?.AssetDetails?.AssetId)
            ))
            ? "#011f6b"
            : assetData?.E9MarketPlaceDetails?.ResponseTypeValue ===
              "Request Rejected"
            ? "#D60000"
            : assetData?.E9MarketPlaceDetails?.ResponseTypeValue ===
                "Request Sent" ||
              assetData?.E9MarketPlaceDetails?.ResponseTypeValue ===
                "Request Accepted"
            ? "#319F00"
            : "#F67C00"
        }
        primaryButtonText={
          assetData?.E9MarketPlaceDetails?.UserCredentialId ===
            userData?.accountDetails?.UserDetails?.CredentialDetails
              ?.UserCredentialId ||
          (companyEquipmentIds &&
            assetData &&
            companyEquipmentIds.includes(
              parseInt(assetData?.AssetDetails?.AssetId)
            ))
            ? "My Store"
            : assetData?.E9MarketPlaceDetails?.ResponseTypeValue &&
              assetData?.E9MarketPlaceDetails?.ResponseTypeValue !==
                "Request Revoked"
            ? assetData?.E9MarketPlaceDetails?.ResponseTypeValue
            : "Contact Owner"
        }
        primaryButtonClickAction={ 
          userTypeValue.toLowerCase() === "company" && !accountDetails?.CompanyDetails?.TaxNumber ?
            ()=>  toast({
                status: "error",
                description: "Please update GST Number to Contact Owner.",
                position: "top-right",
                isClosable: true,
              })
           :
            assetData?.E9MarketPlaceDetails?.UserCredentialId ===
            userData?.accountDetails?.UserDetails?.CredentialDetails
              ?.UserCredentialId ||
            (companyEquipmentIds &&
            assetData &&
            companyEquipmentIds.includes(
              parseInt(assetData?.AssetDetails?.AssetId)
            ))
            ? () => navigate("/equipment-store")
            : marketplaceType === "used" &&
              uemLeads === 0 &&
              freeLeadsData &&
              freeLeadsData.ContactRemaining === 0 && !userLeads?.premium?.LeadsData[0]?.RemainingCount
            ? () => {
                setNoleadsModalOpen(true);
              }
            : marketplaceType === "rental" &&
              remLeads === 0 &&
              freeLeadsData &&
              freeLeadsData.ContactRemaining === 0 && !userLeads?.premium?.LeadsData[0]?.RemainingCount
            ? () => {
                setNoleadsModalOpen(true);
              }
            : assetData?.E9MarketPlaceDetails?.ResponseTypeValue ===
              "Request Sent"
            ? () => {
                handleRevoke(
                  assetData?.E9MarketPlaceDetails?.E9MarketPlaceId,
                  assetData?.E9MarketPlaceDetails?.AssetOwnerUserCredentialId,
                  assetData?.E9MarketPlaceDetails
                );
              }
            : assetData?.E9MarketPlaceDetails?.E9MarketPlaceUniqueId.split(
                "-"
              )[0] === "UEM" &&
              (assetData?.E9MarketPlaceDetails?.ResponseTypeValue ===
                "Request Revoked" ||
                !assetData?.E9MarketPlaceDetails?.ResponseTypeValue)
            ? () => {
              
                handleContactSend(
                  assetData?.E9MarketPlaceDetails?.E9MarketPlaceId,
                  assetData?.E9MarketPlaceDetails?.AssetOwnerUserCredentialId,
                  assetData?.E9MarketPlaceDetails
                );
              }
            : assetData?.E9MarketPlaceDetails?.E9MarketPlaceUniqueId.split(
                "-"
              )[0] === "RM" &&
              (assetData?.E9MarketPlaceDetails?.ResponseTypeValue ===
                "Request Revoked" ||
                !assetData?.E9MarketPlaceDetails?.ResponseTypeValue)
            ? () => {
                dispatch(openRentalDurationModal());
              }
            : (() => null)()
          
        }
        secondaryText={
          !assetData
            ? "NA"
            : assetData?.E9MarketPlaceDetails?.SellerContactNumber &&
              assetData?.E9MarketPlaceDetails?.ResponseTypeValue ===
                "Request Accepted"
            ? `+${assetData?.E9MarketPlaceDetails?.SellerContactNumber}`
            : `+${assetData?.E9MarketPlaceDetails?.SellerContactNumber.toString().slice(
                0,
                3
              )}XXXXX${assetData?.E9MarketPlaceDetails?.SellerContactNumber?.toString().slice(
                -2
              )}`
        }
      >
        {/* <div style={{ margin: "0 52px"}}> */}
        <Box h="100%" overflowY="scroll" padding="1em 1.5em">
          <div className="assets-header-card-container">
            <div
              style={{
                width: "430px",
                border: "1px solid #EEEEEE",
              }}
            >
              <Swiper
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
                style={{ height: "226px", width: "430px" }}
              >
                {assetData?.AssetVideoDetails &&
                Object.keys(assetData?.AssetVideoDetails).length > 0 ? (
                  Object.entries(assetData?.AssetVideoDetails).map(
                    ([key, value], index) => (
                      <SwiperSlide key={index}>
                        <video controls preload="none" width="100%">
                          <source
                            src={`${process.env.REACT_APP_CDN_PVT_URL}${assetData?.AssetVideoBaseReference}${value}`}
                          />
                        </video>
                      </SwiperSlide>
                    )
                  )
                ) : (
                  <></>
                )}

                {assetData &&
                Object.keys(assetData?.AssetImageDetails).length > 0 ? (
                  Object.entries(assetData?.AssetImageDetails).map(
                    ([key, value], index) => (
                      <SwiperSlide key={index}>
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "fill",
                          }}
                          alt="image"
                          src={`${process.env.REACT_APP_CDN_PVT_URL}${assetData?.AssetImageBaseReference}${value}`}
                          className="dealership-header-card-image"
                        />
                      </SwiperSlide>
                    )
                  )
                ) : (
                  <></>
                )}
              </Swiper>
            </div>

            <div className="assets-body-card-container">
              <div className="assets-body-card-container-left">
                <div>
                  <h1 className="assets-card-text">
                    {assetData && assetData.AssetDetails
                      ? assetData.AssetDetails.EquipmentTypeValue
                      : "Equipment Type"}
                  </h1>
                </div>
              </div>

              <div class="assets-body-card-container-right">
                <button
                  className="material-btn"
                  onClick={ async (e) => {
                    e.stopPropagation(); 
                    await encryptAssetDetails(primaryKeyEncryption(assetData?.E9MarketPlaceDetails?.E9MarketPlaceId));
                  }}
                >
                  <ReactSVG
                    src={shareIcon}
                    beforeInjection={(svg) => {
                      svg.setAttribute("style", "height: 20px");
                    }}
                  />
                </button>
                <div>
                  {assetData ? (
                    <Like
                      isLiked={
                        assetData?.E9MarketPlaceDetails?.LikeStatus
                          ? true
                          : false
                      }
                      height="20px"
                      width=""
                      onClick={(val) => handleLike(val)}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>

          {assetData ? (
            <div>
              <div>
                <Box margin="0 auto">
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Equipment Details
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box>
                    <Flex
                      gap="2em"
                      flexDirection="row"
                      overflowX="scroll"
                      padding="1rem 0.5rem"
                    >
                      <DetailsCard
                        heading={
                          assetData && assetData.AssetDetail
                            ? assetData?.AssetDetails?.EquipmentTypeValue
                            : ""
                        }
                        description="Equipment Type"
                      />
                      <DetailsCard
                        heading={
                          assetData ? assetData?.AssetDetails?.OEMName : ""
                        }
                        description="Equipment Make"
                      />
                      <DetailsCard
                        heading={
                          assetData ? assetData?.AssetDetails?.ModelName : ""
                        }
                        description="Equipment Model"
                      />
                      <DetailsCard
                        heading={`${
                          getCurrentYear() -
                          assetData?.AssetDetails?.AssetManufacturingYear
                        } Years`}
                        description="Equipment Age"
                      />
                      <DetailsCard
                        heading={
                          assetData
                            ? assetData?.AssetDetails?.AssetManufacturingYear
                            : ""
                        }
                        description="Manufacturing Year"
                      />
                      <DetailsCard
                        heading={
                          assetData
                            ? assetData?.AssetDetails?.AssetCurrentMeterReading
                            : ""
                        }
                        description="Meter Reading / Hours of Working"
                      />
                    </Flex>
                  </Box>
                </Box>

                {/* LOCATION */}
                <Box margin="0 auto">
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Equipment Location
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box>
                    <Flex
                      gap="2em"
                      flexDirection="row"
                      overflowX="scroll"
                      padding="1rem 0.5rem"
                    >
                      <DetailsCard
                        heading={`${
                          assetData?.AssetDetails?.CityName
                            ? assetData?.AssetDetails?.CityName + ","
                            : ""
                        } ${
                          assetData?.AssetDetails?.StateName
                            ? assetData?.AssetDetails?.StateName + ","
                            : ""
                        } ${
                          assetData?.AssetDetails?.CountryName
                            ? assetData?.AssetDetails?.CountryName
                            : ""
                        } ${
                          assetData?.AssetDetails?.AssetLocationZipCode
                            ? "," +
                              assetData?.AssetDetails?.AssetLocationZipCode
                            : ""
                        }`}
                        description="Equipment Location"
                      />
                    </Flex>
                  </Box>
                </Box>

                {/* Insurance */}

                <Box margin="0 auto">
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Insurance Details
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box>
                    <Flex
                      gap="2em"
                      flexDirection="row"
                      overflowX="scroll"
                      padding="1rem 0.5rem"
                    >
                      <DetailsCard
                        heading={
                          assetData?.AssetDetails?.InsuranceTypeValue &&
                          assetData?.AssetDetails?.InsuranceTypeValue.toLowerCase() ===
                            "Yes".toLowerCase()
                            ? "Yes"
                            : "No"
                        }
                        description="Active Status"
                      />
                      <DetailsCard
                        heading={
                          assetData?.AssetDetails?.AssetInsuranceValidityDate
                        }
                        description="Valid Upto"
                      />
                    </Flex>
                  </Box>
                </Box>

                {/* PURCHASE DETAILS */}

                <Box margin="0 auto">
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Purchasing Details
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box>
                    <Flex gap="2em" overflowX="scroll" padding="1rem 0.5rem">
                      <DetailsCard
                        heading={
                          assetData?.AssetPurchaseDetails?.IsUnderLoan
                            ? "Yes"
                            : "No"
                        }
                        description="Is Currently Under Loan ?"
                      />
                      <DetailsCard
                        heading={assetData?.AssetPurchaseDetails?.PurchasedYear}
                        description="Year"
                      />
                      <DetailsCard
                        heading={assetData?.AssetPurchaseDetails?.CountryName}
                        description="Registerd Country"
                      />
                      <DetailsCard
                        heading={assetData?.AssetPurchaseDetails?.StateName}
                        description="Registered State"
                      />
                      <DetailsCard
                        heading={
                          assetData?.AssetPurchaseDetails
                            ?.AssetOwnershipTypeValue
                        }
                        description="Ownership"
                      />
                      <DetailsCard
                        heading={
                          assetData?.AssetPurchaseDetails?.RentalShiftValue
                        }
                        description="Rental Shift"
                      />
                    </Flex>
                  </Box>
                </Box>

                {/* ENGINE DETAILS PAGE */}

                <Box margin="0 auto">
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Engine Details
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box>
                    <Flex gap="2em" overflowX="scroll" padding="1rem 0.5rem">
                      <DetailsCard
                        heading={
                          assetData?.AssetDetails?.EquipmentEngineMakeValue
                            ? assetData?.AssetDetails?.EquipmentEngineMakeValue
                            : "NA"
                        }
                        description="Engine Make"
                      />
                      <DetailsCard
                        heading={
                          assetData?.AssetDetails?.AssetChassisNumber
                            ? assetData?.AssetDetails?.AssetChassisNumber
                            : "NA"
                        }
                        description="Chassis Number"
                      />
                      <DetailsCard
                        heading={
                          assetData?.AssetDetails?.FuelTypeValue
                            ? assetData?.AssetDetails?.FuelTypeValue
                            : "NA"
                        }
                        description="Fuel Type"
                      />
                      <DetailsCard
                        heading={
                          assetData?.AssetDetails?.AssetAutoEngineShutdownId
                            ? "Yes"
                            : "No"
                        }
                        description="Auto Engine Shutdown"
                      />
                    </Flex>
                  </Box>
                </Box>

                {/* EQUIPMENT DOCUMENTS */}

                <Box margin="0 auto">
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Equipment Documents
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box>
                    <Flex
                      gap="2em"
                      flexDirection="row"
                      overflowX="scroll"
                      padding="1rem 0.5rem"
                    >
                      {assetData?.AssetDocumentDetails &&
                      assetData?.AssetDocumentDetails.length > 0 ? (
                        assetData?.AssetDocumentDetails.map((document) => (
                          <DetailsCard
                            heading={`${document?.AssetDocumentTypeValue}`}
                            description={
                              assetData?.E9MarketPlaceDetails
                                ?.IsDocumentPrivate ||
                              assetData?.E9MarketPlaceDetails
                                ?.OwnerMarketplaceDocumentPrivacyEnabled
                                ? "Document Locked by user"
                                : "View Document"
                            }
                            onDescriptionClick={
                              assetData?.E9MarketPlaceDetails
                                ?.IsDocumentPrivate ||
                              assetData?.E9MarketPlaceDetails
                                ?.OwnerMarketplaceDocumentPrivacyEnabled
                                ? () => {}
                                : () => {
                                    setSelectedDocument({
                                      url: document?.DocumentFileReference,
                                      name: document?.AssetDocumentTypeValue,
                                    });
                                    setModalOpen(true);
                                  }
                            }
                            descriptionCursorRequired={
                              !(
                                assetData?.E9MarketPlaceDetails
                                  ?.IsDocumentPrivate ||
                                assetData?.E9MarketPlaceDetails
                                  ?.OwnerMarketplaceDocumentPrivacyEnabled
                              )
                            }
                          />
                        ))
                      ) : (
                        <p>No documents Found</p>
                      )}
                    </Flex>
                  </Box>
                </Box>
              </div>
            </div>
          ) : (
            <p>Equipment Data Not Found.</p>
          )}
        </Box>
        {/* </div> */}


      </V5FixedFooterSidebarLayout>
    </>
  );
};
