export const IMAGE_CONSTANTS = {
/** HOME SECTION STARTS HERE */
//   HERO SECTION VIDEO LINK
      HERO_SECTION_VIDEO_LINK: "Website/Home/Hero-section-video.mp4",
  MARKETPLACE_SCREEN: "Website/Home/Marketplace.png",
  OPERATORS_MARKETPLACE_SCREEN: "Website/Home/Operator-screen.png",
  MECHANICS_MARKETPLACE_SCREEN: "Website/Home/Mechaincs-screen.png",
/** HOME SECTION ENDS HERE */

 /** ABOUT SECTION STARTS HERE */

 // CLIENT IMAGES
 SPAURA: "Website/About/Clients/Client-SpauraInfrastructureLtd.png",
 DYNAMIC: "Website/About/Clients/Client-DynamicCraneEngineersPrivateLtd.png",
 RDC: "Website/About/Clients/Client-RdcConcretePrivateLtd.png",

 //MEMBERS IMAGE FOLDER 
  MEMBERS_IMAGE_FOLDER : "Website/About/Members/",
  MEMBERS_INTERNS: "Website/About/Members/Intern/",
  MEMBERS_ASSOCIATES: "Website/About/Members/Associate/",
  MEMBERS_CONSULTANT: "Website/About/Members/Consultant/",
  MEMBERS_SENIOR_CONSULTANT: "Website/About/Members/SeniorConsultant/",
  MEMBERS_FOUNDER: "Website/About/Members/Founder/"

 /** ABOUT SECTION ENDS HERE */
}


export const IMAGE_CONSTANTSS=[
  {
    name: "Website/About/Clients/Client-SpauraInfrastructureLtd.png"
  },
  {
    namee: "Website/About/Clients/Client-DynamicCraneEngineersPrivateLtd.png",
  },
  {
    name: "Website/About/Clients/Client-RdcConcretePrivateLtd.png",
  }
  

]