export const MarketplaceLabel = ({ type = "rental" }) => {
  return (
    <div
      style={{
        padding: "5px 5px 4px 10px",
        display: "flex",
        gap: "0.25em",
        background: type === "rental" ? "#3389FF66" : "#4866004D",
        borderRadius: "5px 0px 0px 5px",
        height: '25px'
      }}
    >
      <p style={{fontSize: "12px" , fontWeight: "700"}}>{type === "rental" ? "Rental" : "Used"}</p>
    </div>
  );
};
