import styled from "styled-components"
import { Flexbox } from "../../CommonComponents/Flexbox/Flexbox"
import AppleSticker from "../../../assets/third-party-stickers/app-store-badge.png"
import GooglePlaySticker from "../../../assets/third-party-stickers/google-play-badge.png";
import useWindowWidth from "../../../sharedComponent/isMobile/CheckMobile";
import { Flex, Text } from "@chakra-ui/react";

const VideoWrapper = styled.div`
    position:relative;
    height:${({height}) => height || "100px" };
    width:${({width}) => width || "100px" };
    border: 3px solid var(--primary);
    border-left: none;
    border-right: none;
    min-height: 100vh;
    color: var(--light-10);
`

const Video = styled.video`
   position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
    
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  padding: 1.875em 4.4em ;
  z-index: 1;
`;

export const VideoContainer = ({videoUrl, format, height, width, heading, subHeading, message}) => { 
   
return(
    <>
    <VideoWrapper className="VideoWrapper" height={height} width={width}>
        
        <Video className="Video" autoPlay muted loop>
            <source src={videoUrl} type={format} aria-label="Construction site with heavy equipment including excavators, backhoes, and cranes, lifting and dropping blocks with operators and drivers working on machinery." />
            Sorry, your browser doesn't support videos.
        </Video>
        <Flex style={{
           position: "absolute",
           top: 0,
           left: 0,
           width: "100%",
           height: "100%",
           backgroundColor: "rgba(0, 0, 0, 0.5)",
           /* align-items: center; */
        //    padding: 1.875em 4.4em ;
           zIndex: 1
        }} direction="column" justifyContent={"space-between"} padding={["0.7em 0.7em","0.7em 0.7em","1.5em 2em","1.875em 4.4em","1.875em 4.4em"]}>
        <Flexbox  zIndex="1" gap = "0"  >
            <Text fontWeight={"700"} fontSize={["1.75em" , "1.75em","","3em","4.25em","4.25em"]} minW={"180px"} textAlign={"center"}>{heading.split(' ')[0]} <span style={{color:"#FECA00"}}>{heading.split(' ')[1]}</span> {heading.split(' ')[2]} {heading.split(' ')[3]} <span style={{color:"#FECA00"}}>{heading.split(' ')[4]}</span></Text>
            <Text fontWeight={"700"} fontSize={["1em","1em","1.2em","1.5em","1.5em",]} minW={"180px"} textAlign={"center"}>{subHeading}</Text>
        </Flexbox>
        <Flexbox zIndex="1" gap="0">
            <Text fontSize={["1em","1em","1.5em","2em","2em","2em",]}>{message}</Text>
         <div style={{display: "flex", gap: "1.25em", alignItems:"center"}}>
             <a href="https://apps.apple.com/us/app/equip9/id1538560258" target="_blank"><img src={AppleSticker} alt="ios-download-link"/></a>
             <a href="https://play.google.com/store/apps/details?id=com.equip9&hl=en_IN&gl=US" target="_blank"><img src={GooglePlaySticker} alt="android-download-link"/></a>
         </div>
        </Flexbox>
        </Flex>
    </VideoWrapper>
    </>
)
}