import {fetchHandler} from "../../../index";

export const getPostLoginAdvertisments = async () => {
  try {
    const { data } = await fetchHandler(`/DigitalAdvertisement/10/0`, {
      isAuthRequired: 1,
    });

    if (data.Status === 200 && Object.keys(data.Data).length > 0) {

      const requiredImgUrl = data.Data.DigitalAdsList.map((advertisment) => (`${process.env.REACT_APP_CDN_PVT_URL}${data.Data.AdsBaseUrl}${advertisment.DigitalAdRef}`));

      const requiredRedirectUrl = data.Data.DigitalAdsList.map((advertisment) => (`${advertisment.AdUrl}`));

    const requiredData = {
        requiredImgUrl,
        requiredRedirectUrl
      };
      return requiredData;
    }
    throw new Error("Failed to fetch post-login advertisements");
  } catch (error) {
    throw error;
  }
};
