import { createAsyncThunk } from "@reduxjs/toolkit";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";
import { getFilterMaterialMarketplaceList } from "../../../services/materials/materials";

export const oilInitialFilteredDataThunk = createAsyncThunk("oilMaterials/initialFilteredData",async(_, {getState}) => {
   
   
    const oilId = convertToBase64('2')
    const {filterByIsOriginal ,filterByEquipmentTypeId,filterByEquipmentMakeId, filterByCountry, filterByState, cardCount} = getState().oilMaterialMarketplace


    let query = ""

    if(oilId){
        query+= `MaterialTypeId=${oilId}`
    }
    if(filterByIsOriginal){
        query+= `&IsOriginal=${filterByIsOriginal}`
    }
    if(filterByEquipmentTypeId){
        query+= `&EquipmentTypeId=${filterByEquipmentTypeId}`
    }
    if(filterByEquipmentMakeId){
        query+= `&OEMId=${filterByEquipmentMakeId}`
    }
    if(filterByCountry){
        query += `&Country=${filterByCountry}`
    }
    if(filterByState){
        query+= `&State=${filterByState}`
    }
   
// console.log("Thunk Values ==>",query);
// console.log("Thunk Values Filter Spare parts ===> ",{filterByIsOriginal ,filterByEquipmentTypeId,filterByEquipmentMakeId, filterByCountry, filterByState, cardCount});

    try{
        

        const data = await getFilterMaterialMarketplaceList(cardCount,0,query)


        if(data.Status === 200){
           if(data.Data.Status){
            return data.Data;
           }
           else{
            return []
           }
        }
    }
    catch(error){
    }

    
})