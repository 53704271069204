export default {
    LanguageOptionTypeId : "1",
    Hello: "Hello!",
    Select_the_language_that_youre_comfortable_with: "Select the language that you're comfortable with.",
    Continue: "Continue",
    Welcome_to_EQUIP9: "Welcome to EQUIP9™️",
    Create_an_Account: "Create an Account",
    Continue_to_Login: "Continue to Login",
    Lets_Login: "Lets Login!",
    Forgot_Password: "Forgot Password?",
    Dont_worry_We_are_here_to_help_you: "Don't worry! We are here to help you.",
    Fill_Your_Account_Details_for_Easy_Setup: "Fill Your Account Details for Easy Setup",
    It_wont_take_long: "It won't take long...",
    Password_Updated: "Password Updated!",
    Please_use_your_new_password_to_login: "Please use your new password to login...",
    Dont_have_an_account_Signup: "Don't have an account?Signup",
    I_am: "I am...?",
    You_can_select_any_one_of_the_following: "You can select any one of the following",
    Next: "Next",
    // Lets_Get_You_Registered: "Let's Get You Registered",
    // Its_quick_and_easy: "It's quick and easy!",
    Send_OTP: "Send OTP",
    Verify_Your_Mobile_Number: "Verify Your Mobile Number",
    Weve_sent_you_the_OTP_on: "We've sent you the OTP on 1234567890",
    Enter_OTP_Code: "Enter OTP Code",
    Submit: "Submit",
    Resend_OTP: "Resend OTP",
    Please_Enter_OTP: "Please Enter OTP",
    Get_Help: "Get Help",
    EQUIP9_team_will_connect_with_you: "EQUIP9TM team will connect with you",
    // Submit: "Submit",
    Fill_Your_Account_Details_For_Easy_Setup: "Fill Your Account Details For Easy Setup",
    It_wont_Take_Much_Long: "It won't Take Much Long",
    SignUP: "SignUp",
    Congratulations_Registration_Completed: "Congratulations! Registration Completed…",
    Your_account_has_been_successfully_created_at_Equip9: "Your account has been successfully created at Equip9TM.",
    Okay: "Okay",
    Profile_Picture: "Profile Picture",
    Please_upload_your_selfie_below: "Please upload your selfie below.",
    Skip: "Skip",
    // Next: "Next",
    Open_camera_to_click_a_selfie: "Open camera to click a selfie",
    Upload_from_gallery: "Upload from gallery",
    Business_Expertise: "Business Expertise",
    Please_select_your_expertise_area: "Please select your expertise area.",
    E9_Roles: "E9 Roles",
    Add_Your_Equipment_Skill: "Add Your Equipment Skill",
    Equipment_Details: "Equipment Details",
    Helps_us_identify_the_right_jobs_for_you: "Helps us identify the right jobs for you",
    Upload_Verification_Documents: "Upload Verification Documents",
    Please_give_your_document_details_for_verification: "Please give your document details for verification",
    Verification_Document: "Verification Document",
    Upload_Driving_License: "Upload Driving License",
    License_Details: "License Details",
    Please_add_your_total_experience_details: "Please add your total experience details",
    Please_provide_all_your_company_details: "Please provide all your company details.",
    Total_Experience: "Total Experience",
    Job_status: "Job status",
    // E9_Roles: "E9 Roles",
    Please_select_your_Role: "Please select your Role.",
    // Personal_Details: "Personal Details",
    Verification_Details: "Verification Details",
    // Equipment_Details: "Equipment Details",
    Education_Details: "Education Details",
    Jobs_available_for_you: "Jobs available for you",
    Top_Jobs_available: "Top 10 Jobs available",
    New_Foreign_Jobs: "New Foreign Jobs",
    Job_Status: "Job Status",
    Are_you_sure_you_want_to_apply_for_this_job: "Are you sure you want to apply for this job",
    Applied: "Applied",
    Job_Details: "Job Details",
    New_job_request: "New job request",
    Total_jobs_near_you: "Total 5 jobs near you.",
    Refer_New_Member: "Refer New Member",
    Save_Change: "Save Change",
    No_Thanks: "No Thanks",
    Add_Company_Details: "Add Company Details",
    Add_Your_Licence: "Add Your Licence",
    Add_your_Equipment: "Add your Equipment",
    Add_Education_Details: "Add Education Details",
    Add_Verification_Details: "Add Verification Details",
    Filters: "Filters",
    Reject: "Reject",
    Apply: "Apply",
    Invite_Your_Friends: "Invite Your Friends",
    Your_Experience: "Your Experience",
    Overseas_Opportunity: "Overseas Opportunity",
    TOP_OPERATORS_NEAR_ME: "TOP OPERATORS NEAR ME",
    TOP_MAINTENANCE_NEAR_ME: "TOP MAINTENANCE NEAR ME",
    Upcoming: "Upcoming",
    Ongoing: "Ongoing",
    Completed: "Completed",
    Cancelled: "Cancelled",
    Your_Equipment_Expertise: "Your Equipment Expertise",
    save_changes: "save changes",
    Dashboard: "Dashboard",
    Profile: "Profile",
    My_Subscription: "My Subscription",
    My_Transactions: "My Transactions",
    my_premium: "My_premium",
    Quiz: "Quiz",
    Refer_a_friend: "Refer a friend",
    Settings: "Settings",
    EQUIP9_Support: "EQUIP9™️ Support",
    Legal_Policy: "Legal Policy",
    Logout: "Logout",
    See_all: "See all",
    Home: "Home",
    Offers: "Offers",
    Community: "Community",
    You_can_post_all_your_content_for_the_heavy_equipment_industry: "You Can Post All Your Content For The Heavy Equipment Industry",
    App_Notification: "App Notification",
    My_Content: "My Content",
    Add: "Add",
    Want_To_Spread_Your_Content: "Want To Spread Your Content?",
    Choose_Your_Content_Type: "Choose Your Content Type",
    Select_Any_One: "Select Any One",
    Project: "Project",
    Article: "Article",
    Video: "Video",
    Add_Your_Project: "Add Your Project",
    You_can_add_your_own_project_here: "You Can Add Your Own Project Here",
    Selected_Audience: "Selected Audience",
    Title_of_your_project: "Title of your project",
    Add_Your_Projects_Link: "Add Your Project's Link",
    Select_or_upload_your_image: "Select or upload your image",
    Upload_image: "Upload image",
    post: "post",
    Availability: "Availability",
    PRIVACY: "PRIVACY",
    APP_SECURITY: "APP SECURITY",
    ACCOUNT: "ACCOUNT",
    EXPECTED_IN_HAND_SALARY: "EXPECTED IN HAND SALARY",
    In_hand_salary_here: "In Hand Salary Here",
    JOB_VISIBILITY: "JOB VISIBILITY",
    Select_Your_Visibility: "Select Your Visibility",
    country: "country",
    Equip9_Quizzes: "Equip9 Quizzes",
    Play_today_and_win_exciting_Prizes: "Play Today And Win Exciting Prizes",
    No_Job_Available_Yet: "No Job Available ..Yet",
    OOPS_No_Upcoming_Jobs: "OOPS! No Upcoming Jobs!",
    OOPS_No_Ongoing_Jobs: "OOPS! No Ongoing Jobs!",
    OOPS_No_Completed_Jobs: "OOPS! No Completed Jobs",
    OOPS_No_Cancelled_Jobs: "OOPS! No Cancelled Jobs!",
    Your_Role: "Your Role",
    Personal_Details: "Personal Details",
    Work_Experience: "Work Experience",
    Educational_Details: "Educational Details",
    Your_Verification_Details: "Your Verification Details",
    // Add_Verification_Details: "Add Verification Details",
    // Filters: "Filters",
    apply_filters: "apply_filters",
    Nature_of_Your_Business: "Nature of Your Business?",
    // You_can_select_any_one_of_the_following: "You Can Select Any One Of The Following",
    Lets_Get_You_Registered: "Let's Get You Registered",
    Its_quick_and_easy: "It's Quick And Easy!",
    // Send_OTP: "Send OTP",
    // Verify_Your_Mobile_Number: "Verify Your Mobile Number",
    // Weve_sent_you_the_OTP_on: "We've Sent You The OTP On 8208399431.",
    Fill_Your_Account_Details: "Fill Your Account Details",
    // Invite_Your_Friends: "Invite Your Friends",
    Signup: "Signup",
    // Congratulations_Registration_Completed: "Congratulations Registration Completed...",
    // Your_account_has_been_successfully_created_at_Equip9: "Your Account Has Been Successfully Created At Equip9.",
    Best_Offers_in_our_country: "Best Offers in our country",
    EXPECTED_PER_VISIT_COST: "EXPECTED PER VISIT COST",
    Select_Designation: "Select Designation",
    please_select_your_designation_in_your_company: "Please Select Your Designation In Your Company",
    Business_Identification_Document: "Business Identification Document",
    Please_upload_company_verification_document: "Please Upload Company Verification Document",
    New_Job_Request: "New Job Request",
    // Job_Status: "Job Status",
    // Your_Experience: "Your Experience",
    // Overseas_Opportunity: "Overseas Opportunity",
    My_Projects: "My Projects",
    E9_Services: "E9 Services",
    My_Store: "My Store",
    My_Leads: "My Leads",
    Marketplace: "Marketplace",
    Your_recent_added_projects_will_be_here: "Your Recent Added Projects Will Be Here",
    Add_Project: "Add Project",
    Optimize_your_Business_with_Us: "Optimize Your Business With Us",
    Search_Service: "Search Service",
    Listing_Service: "Listing Service",
    Welcome_to_Your_Store: "Welcome to Your Store",
    Add_your_Assets_to_your_Store: "Add your Assets to your Store",
    List_on_Marketplace_to_sell_or_Rent: "List on Marketplace to sell or Rent",
    View_Lead_in_My_Leads_Section: "View Lead in \"My Leads\" Section",
    companys_Store: "company's Store",
    Equipment_in_your_fleet: "0 Equipment in your fleet",
    Free_Listing_Remaining: "Free Listing Remaining",
    Subscription_Remaining: "Subscription Remaining",
    My_Fleet: "My Fleet",
    On_Rental_Marketplace: "On Rental Marketplace",
    On_Used_Marketplace: "On Used Marketplace",
    On_Sale: "On Sale",
    On_Rental: "On Rental",
    On_Both: "On Both",
    Add_Inventory: "Add Inventory",
    Add_Asset: "Add Asset",
    Lead_Details: "Lead Details",
    Keep_Track_Of_All_Your_Orders_Here: "Keep Track Of All Your Orders Here",
    Workforce: "Workforce",
    Equipment: "Equipment",
    Materials: "Materials",
    // My_Leads: "My Leads",
    Sent: "Sent",
    Received: "Received",
    Digital_Marketplace: "Digital Marketplace",
    Connecting_Equipment_To_Your_Business: "Connecting Equipment To Your Business",
    Operator_Partners_Near_Me: "Operator Partners Near Me",
    Total_Operators_Partners_Listed: "Total 225 Operators Partners Listed",
    Select_Filter: "Select Filter",
    Apply_Filter: "Apply Filter",
    // Filters: "Filters",
    Maintenance_Partners_Near_Me: "Maintenance Partners Near Me",
    Total_Maintenance_partners_Listed: "Total 278 Maintenance partners Listed",
    Partner_Marketplace: "Partner Marketplace",
    E9_Marketplace: "E9 Marketplace",
    Used_Equipment: "Used Equipment",
    Total_used_Equipment_Listed: "Total 130 Used Equipment Listed",
    Contact_Owner: "Contact Owner",
    Contact_Partner: "Contact Partner",
    spare_parts: "spare parts",
    See_job_Details: "See job Details",
    New_Partners: "New Partners",
    confirmed_partners: "Confirmed Partners",
    ongoing: "Ongoing",
    completed: "Completed",
    cancelled: "Cancelled",
    search_result: "Search Result",
    Partner_result_As_per_your_search_parameters: "Partner Result As Per Your Search Parameters",
    Checkout: "Checkout",
    How_would_you_like_to_proceed: "How Would You Like To Proceed",
    Your_selected_partners: "Your Selected Partners",
    Total_selection: "Total selection",
    Proceed_to_pay: "Proceed to pay",
    Rate_partner: "Rate partner",
    // Upcoming: "Upcoming",
    // ongoing: "ongoing",
    // Completed: "Completed",
    // cancelled: "cancelled",
    Start_job_otp: "Start job otp",
    Kindly_enter_otp_to_start_your_work: "Kindly enter otp to start your work",
    Start_job: "Start job",
    End_job: "End job",
    Kindly_enter_otp_to_end_your_work: "Kindly enter otp to end your work",
    See_work_update: "See work update",
    Rate_owner: "Rate owner",
    Business_owner: "Business owner",
    Digital_Services: "Digital Services",
    Add_Your_Store_Manager: "Add Your Store Manager",
    set_your_store_manager: "Set your store manager",
    select_a_user_as_the_warehouse_manager: "Select a user as the warehouse manager",
    Phone_number_of_manager: "Phone number of manager",
    choose_a_marketplace_to_list: "Choose a marketplace to list",
    Additional_details: "Additional details",
    Below_details_are_required_on_E9_marketplace: "Below details are required on E9 marketplace",
    Rental_Equipment: "Rental Equipment",
    // Used_Equipment: "Used Equipment",
    Total_Rental_Equipments_Listed: "Total 87 Rental Equipments Listed",
    Total_Used_Equipments_LIsted: "Total 87 Used Equipments LIsted",
    My_Asset: "My Asset",
    You_can_view_all_your_posted_contents_here: "You can view all your posted contents here",
    choose_your_audience: "Choose your audience",
    select_at_least_one: "Select at least one",
    previous_step: "Previous step",
    you_can_add_your_own_project_here: "You can add your own project here",
    contacted: "Contacted",
    my_jobs: "My jobs",
    jobs_posted: "Jobs posted",
    operator_search: "Operator search",
    maintenance_search: "Maintenance search",
    equipment_listing: "Equipment listing",
    Equip9_offers: "Equip9 offers",
    Brand_Offers: "Brand Offers",
    Redeem_Now: "Redeem Now",
    yes: "yes",
    No_thanks: "No thanks",
    Are_You_sure_you_want_to_log_out: "Are You sure you want to log out?",
    Free_Contacts: "Free Contacts",
    Redeem: "Redeem",
    Redeem_your_Free_Service_Today: "Redeem your Free Service Today!",
    Update_Your_Address: "Update Your Address",
    update: "Update",
    Choose_Your_Current_Sbuscription: "Choose Your Current Subscription",
    choose_your_subscription_plan: "Choose your subscription plan",
    we_have_category_as_listed_below: "We have 2 category as listed below",
    checkout: "Checkout",
    proceed_to_pay: "Proceed to pay",
    apply: "Apply",
    proceed: "Proceed",
    pay_now: "Pay now",
    My_free_contacts: "My free contacts",
    purchased_subscriptions: "Purchased subscriptions",
    Buy_New_Subscription: "Buy New Subscription",
    My_transactions: "My transactions",
    pay_per_use: "Pay per use",
    subscriptions: "Subscriptions",
    refund_payment: "Refund payment",
    Raise_a_complaint: "Raise a complaint",
    Raise_a_ticket: "Raise a ticket",
    total_support_tickets_raised: "Total 2 support tickets raised",
    personal_datails: "Personal Details",
    add_verification_details: "Add verification details",
    Add_verification_document: "Add verification document",
    Add_Assets: "Add Assets",
    used_equipment_marketplace: "Used equipment marketplace",
    top_operators_near_me: "Top operators near me",
    rental_equipment_marketplace: "Rental equipment marketplace",
    top_maintenance_partners: "Top maintenance partners",
    first_things_first: "First things, first",
    Lets_get_you_into_Equip9: "Let's get you into Equip9",
    Hello_Guest: "Hello Guest!",
    welcome_to_Equip9: "Welcome to Equip9",
    Lets_Know_more_about_you: "Let's Know more about you",
    Let_Us_Know_Your_Interest: "Let Us Know Your Interest",
    select_Upto_3_interest: "Select up to 3 interests",
    // Dashboard: "Dashboard",
    Switch_To_main_user: "Switch To main user",
    you_need_to_switch_to_a_main_user_to_access_all_the_features_of_EQUIP9: "You need to switch to a main user to access all the features of EQUIP9™️",
    Reactivate: "Reactivate",
    Accept: "Accept",
    // Reject: "Reject",
    contact_buyer: "Contact Buyer",
    view_partners: "View Partners",
    cancel_partners: "Cancel Partners",
    // search_result: "Search Result",
    partners_result_as_per_your_search_parameters: "Partners result as per your search parameters",
    Request_Sent: "Request Sent",
    offer_code: "Offer code",
    paste_this_code_while_checking_out: "Paste this code while checking out",
    welcome_Guest: "welcome Guest",
    LANDING_PAGE_CREATE: "LANDING PAGE CREATE",
    select_up_to_interest: "Select Upto interest",
    ReEnter_New_password: "ReEnter New password",
    Enter_New_Password: "Enter New Password",
    choose_any_one_account_to_continue: "Choose any one account to continue",
    Enter_your_mobile_number_and_reset_your_password: "Enter your mobile number and reset your password",
    Dealership_Name: "Dealership Name",
    dealers: "Dealers",
    // spare_parts: "Spare Parts",
    dealerships: "Dealerships",
    dealers_listed: "Dealers Listed",
    state: "State",
    city: "City",
    contact_dealer: "Contact Dealer",
    contact_dealership: "Contact Dealership",
    to_view_the_details_please_contact_this_dealership: "To view the details please contact this dealership",
    dealership_details: "Dealership Details",
    locations: "Locations",
    regions: "Regions",
    e9_marketplace: "E9 marketplace",
    connecting_Equipment_To_Your_Business: "Connecting Equipment To Your Business",
    // Workforce: "Workforce",
    operator: "Operator",
    maintenance: "Maintenance ",
    used_equipments: "Used Equipments",
    rental_equipments: "Rental Equipments",
    contact_details: "Contact Details",
    products: "Products",
    contact_owner: "Contact Owner",
    select_filter: "Select Filter",
    equipment_type: "Equipment Type",
    equipment_make: "Equipment Make",
    industry_type: "Industry Type",
    select_country: "Select Country ",
    select_state: "Select State",
    select_city: "Select City",
    reset_filters: "Reset Filters",
    likes: "Likes ",
    my_leads: "My Leads",
    requirement: "Requirement",
    post_your_requirement: "Post your requirement",
    // jobs_posted: "Jobs posted",
    my_Business: "My Business",
    equipment_store: "Equipment store",
    dealership: "Dealership",
    finances: "Finances",
    material: "Material",
    scrapping: "Scrapping",
    welcome_to_the_materials_store: "Welcome to the materials store",
    step: "Step",
    add_your_material_type: "Add your material type ",
    list_your_products_and_add_to_the_marketplace: "List your products and add to the marketplace ",
    view_leads_in_my_leads_section: "View leads in my leads section",
    seller_details: "Seller Details",
    details_help_you_to_attract_more_customers: "Details help you to attract more customers",
    add_details: "Add Details",
    seller_information: "Seller Information",
    material_store_list: "Material Store List",
    add_your_materials_and_list_them_on_marketplace: "Add your materials and list them on marketplace ",
    no_material_store_added_yet: "No material store added yet",
    spare_parts_store: "Spare Parts Store",
    view: "View",
    oil_store: "Oil Store",
    generators_store: "Generators Store",
    materials_store: "Materials Store",
    choose_your_material_type: "Choose your material type",
    oil: "Oil ",
    concrete: "Concrete",
    generators: "Generators ",
    safety_equipments: "Safety Equipments",
    seller_type: "Seller Type",
    manufacturer: "Manufacturer",
    retailer: "Retailer",
    wholesaler: "Wholesaler",
    auth: "Auth",
    email: "Email",
    website: "Website",
    additional_details: "Additional Details",
    upload_seller_stores_images_videos_and_documents: "Upload seller stores images videos and documents",
    store_videos: "Store Videos",
    store_photos: "Store Photos",
    OEM_Authorization_certificate: "OEM/Authorization certificate",
    upload_photo: "Upload Photo",
    upload_a_photo_and_select_a_name: "Upload a photo and select a name",
    name_of_your_photo: "Name of your photo",
    upload_video: "Upload video",
    upload_a_video_and_select_a_name: "Upload a video and select a name",
    name_of_your_video: "Name of your video",
    no_fields_have_been_edited: "No fields have been edited ",
    do_you_want_to_proceed: "Do you want to proceed ",
    i_want_to_proceed: "I want to proceed",
    edited_sucesfully: "Edited Sucesfully",
    generator_store: "Generator Store",
    // seller_information: "Seller Information",
    add_your_spare_parts_and_list_them_on_marketplace: "Add your spare parts and list them on marketplace ",
    spare_parts_details: "Spare Parts Details",
    add_your_material_list_here: "Add Your Material List Here",
    product_number: "Product Number",
    enter_spare_part_name_here: "Enter Spare Part Name Here",
    price_range: "Price Range ",
    enter_min_amount: "Enter Min Amount",
    enter_max_amount: "Enter Max Amount",
    available_location: "Available Location",
    enter_location_here: "Enter Location Here",
    use_current_location: "Use Current Location",
    // equipment_type: "Equipment Type",
    for_any_equipment: "For Any Equipment",
    for_specific_equipment: "For Specific Equipment",
    specify_equipment_type: "Specify Equipment Type",
    for_any_oem: "For Any OEM",
    for_specific_OEM: "For Specific OEM",
    equipment_model: "Equipment Model",
    for_any_model: "For Any Model",
    for_specific_model: "For Specific Model",
    specify_equipment_model: "Specify Equipment Model",
    specify_equipment_make: "Specify Equipment Make",
    original_product: "Original Product",
    product_photo: "Product Photo",
    product_related_video: "Product Related Video",
    weight: "Weight",
    dimensions: "Dimensions",
    enter_weight: "Enter Weight",
    enter_height: "Enter Height",
    enter_length: "Enter Length",
    enter_width: "Enter Width",
    enter_radius: "Enter Radius",
    spare_parts_list: "Spare Parts List",
    free_listing_remaining: "Free Listing Remaining",
    subscription_remaining: "Subscription Remaining",
    requests_received_on_marketplace: "Requests received on marketplace",
    connects_on_marketplace: "Connects on marketplace",
    connects_pending: "Connects Pending",
    part_details: "Part details",
    size: "Size",
    your_current_subscription: "Your current subscription",
    free_listing_available: "Free listing available",
    added_to_the_marketplace: "Added to the marketplace",
    your_spare_parts_have_been_added_to_the_marketplace: "Your spare parts have been added to the marketplace",
    long_term: "Long term",
    choose_your_rental_duration: "Choose your rental duration",
    equipment_availability: "Equipment availability",
    i_have_the_equipment: "I have the equipment",
    confirm_availability_of_the_requested_equipment: "Confirm availability of the requested equipment",
    no_but_i_have_the_similar_equipment: "No but i have the similar equipment",
    no_i_dont_have_the_equipment: "No i don't have the equipment",
    offer_similar_equipment_as_an_alternative: "Offer similar equipment as an alternative",
    decline_the_request: "Decline the request",
    it_is_the_daily_rental_request: "It is the daily rental request",
    is_your_equipment_currently_available: "Is your equipment currently available",
    logistic: "Logistic",
    // scrapping: "Scrapping",
    welcome_to_the_virtual_dealership: "Welcome to the virtual dealership",
    create_your_dealership: "Create your dealership",
    list_your_products_and_services: "List your products and services",
    view_lead_in_my_leads_section: "View lead in my leads section",
    all_your_dealerships_here: "All your dealerships here",
    add_dealership_here: "Add dealership here",
    virtual_dealership: "Virtual dealership",
    choose_your_OEM: "Choose your OEM",
    choose_your_industry: "Choose your industry",
    agriculture: "Agriculture",
    choose_your_dealership_type: "Choose your dealership type",
    used: "Used",
    new: "New",
    // dealership_details: "Dealership details",
    dealership_name: "Dealership name",
    dealership_location: "Dealership location",
    upload_dealership_images_and_videos: "Upload dealership images and videos",
    dealership_certificate: "Dealership certificate",
    // store_photos: "Store photos",
    // store_videos: "Store videos",
    add_your_regions_and_finance_details: "Add your regions and finance details",
    // regions: "Regions",
    maharashtra: "Maharashtra",
    do_you_provide_finance: "Do you provide finance",
    may_be: "May be",
    add_dealership_manager: "Add dealership manager",
    select_only_for_company: "Select only for company",
    select_your_region: "Select your region",
    do_you_know_that_adding_photos_and_videos_gives_you_better_visibility: "Do you know that adding photos and videos gives you better visibility",
    add_your_dealership_manager: "Add your dealership manager",
    dealership_photos: "Dealership Photos",
    dealership_videos: "Dealership Videos",
    add_product: "Add Product",
    product_details: "Product Details",
    add_product_to_the_agriculture_category: "Add product to the agriculture category",
    // product_photo: "Product photo",
    equipment_brochure: "Equipment brochure",
    equipment_video: "Equipment video",
    add_photo: "Add photo",
    add_a_photo_and_specify_the_type_of_photo: "Add a photo and specify the type of photo",
    specify_photo_type: "Specify photo type",
    you_have_not_added_any_products_to_this_dealership: "You have not added any products to this dealership",
    are_you_sure_you_want_to_add_to_marketplace: "Are you sure you want to add to marketplace",
    product_added_successfully: "Product added successfully",
    the_product_has_been_added_to_your_dealership: "The product has been added to your dealership",
    // free_listing_available: "Free listing available",
    spare_parts_listed: "Spare parts listed",
    to_view_the_details_please_contact_this_owner: "To view the details please contact this owner",
    engine_piston: "Engine piston",
    // part_details: "Part details",
    are_you_sure_you_want_to_remove_this_spare_part_from_marketplace: "Are you sure you want to remove this spare part from marketplace",
    Added: "Added",
    On_Marketplace: "On Marketplace",
    E9_Verified: "E9 Verified",
    Not_Verified: "Not verified",
    Promoted: "Promoted",
    Newly_Added: "Newly Added",
    Original: "Original",
    Search_Your_Location: "Search Your Location",
    Add_Your_Material_Details_Here: "Add Your Material Details Here",
    Chars: "Chars",
    Part_Number_Material_Quantity_etc_Additional_Detail_Placeholder: "Part Number Material Quantity etcAdditional Detail Placeholder",
    I_Want_to_Edit: "I want to edit",
    Spare_Parts_Store: "Spare Parts Store",
    Oil_Store: "Oil Store",
    Generators_Store: "Generator Store",
    Oil: "Oil",
    Concrete: "Concrete",
    Generators: "Generators",
    Safety_Equipments: "Safety Equipments",
    connecting_equipment_to_your_business: "Connecting equipment to your business",
    owner_marketplace: "Owner marketplace",
    Buy_Premium: "Buy Premium",
    you_need_a_premium_subscription_tocontact_the_owner: "You need a premium subscription to contact the owner",
    buy_to_continue: "Buy To Continue",
    buy: "Buy",
    owner_profile: "Owner Profile",
    Live_Ads: "Live Ads",
    your_advertisement_that_currently_live_on_App: "Your advertisement that currently live on App",
    On_log_in: "On log in",
    On_dashboard: "On dashboard",
    on_rental_marketplace: "On rental marketplace",
    Drafts: "Drafts",
    Waiting_for_approval: "Waiting for approval",
    Live: "Live",
    advertisement_that_is_being_verified_by_equip_9_team: "Advertisement that is being verified by equip 9 team",
    Saved_to_drafts: "Saved to drafts",
    Grow_your_business_with_Equip9: "Grow your business with Equip 9",
    Are_you_sure_you_want_to_delete_this_advertisement_permanently: "Are you sure you want to delete this advertisement permanently",
    Are_you_sure_you_want_to_pause_5_advertisements: "Are you sure you want to pause 5 advertisements",
    Are_you_sure_you_want_to_repost_5_advertisements: "Are you sure you want to repost 5 advertisements",
    Post_your_Advertisements: "Post your Advertisements",
    Analytics: "Analytics",
    Check_who_viewed_and_liked_your_advertisement: "Check who viewed and liked your advertisement",
    Add_your_advertisement_details: "Add your advertisement details",
    Advertisement_title: "Advertisement title",
    Advertisement_redirection_link: "Advertisement redirection link",
    please_enter_the_advertisement_title_here: "Please enter the advertisement title here",
    Please_enter_the_advertisement_link_here: "Please enter the advertisement link here",
    chose_your_audience: "Chose your audience",
    // select_at_least_one: "Select at least one",
    set_dates: "Set Dates",
    set_your_start_date_and_end_date: "Set your start date and end date",
    select_geographical_location: "Select geographical location",
    select_locations_to_display_your_advertisement: "Select locations to display your advertisement",
    select_location: "Select Location",
    set_display_location: "Set Display Location",
    select_where_to_display_your_advertisement_on_app: "Select where to display your advertisement on app",
    where_to_display_advertisement: "Where to display advertisement",
    Before_login: "Before Login",
    welcome_to_industry_requirements: "Welcome to industry requirements",
    view_requirements_in_the_industry: "View requirements in the industry",
    List_your_requirements_and_post_it: "List your requirements and post it",
    requirements: "Requirements",
    Requirements_in_the_industry: "Requirements in the industry",
    see_the_requirements_posted_by_E9_users: "See the requirements posted by E9 users",
    // Analytics: "Analytics",
    My_requirements: "My Requirements",
    posts: "Posts",
    what_are_you_looking_for: "What are you looking for",
    Posted_on: "Posted On",
    do_you_want_to_connect_with_this_user: "Do you want to connect with this user",
    are_you_sure_you_want_to_delete_this_requirement_permanently: "Are you sure you want to delete this requirement permanently",
    industry_requirement: "Industry Requirement",
    // your_current_subscription: "Your current subscription",
    free_contacts_available: "Free contacts available",
    buy_subscription: "Buy Subscription",
    you_have_exhausted_your_subscription: "You have exhausted your subscription",
    buy_more_to_continue: "Buy more to continue",
    report_issue: "Report Issue",
    please_provide_details_of_any_reports_or_issues_you_have_encountered: "Please provide details of any reports or issues you have encountered",
    report_an_issue: "Report an issue",
    select_input_method: "Select Input Method",
    requirement_category: "Requirement Category",
    select_your_need: "Select Your Need",
    i_am_in_search_of_operator: "I am in search of operator",
    i_am_in_search_of_rental_equipment: "I am in search of rental equipment",
    I_am_in_search_of_something_else: "I am in search of something else",
    tell_us_your_requirement: "Tell us your requirement",
    please_type_here: "Please type here",
    if_you_have_any_photo_upload_it: "If you have any photo upload it",
    if_you_have_any_video_upload_it: "If you have any video upload it",
    congratulations_your_requirement_posted: "Congratulations your requirement posted",
    add_to_marketplace: "Add to marketplace",
    remove_from_marketplace: "Remove from marketplace",
    request_accepted: "Request Accepted",
    request_rejected: "Request Rejected",
    request_received: "Request Received",
    height: "Height",
    width: "Width",
    length: "Length",
    radius: "Radius",
    contact_seller: "Contact Seller",
    rquests_received_on: "Rquest Received On",
    requests: "Requests",
    name_of_the_product: "Name of the product",
    number_of_the_product: "Number of the product",
    // contact_seller: "Contact seller",
    my_business: "My Business",
    manage_your_business_with_equip9: "Manage your business with equip9",
    enter_material_name_here: "Enter Material Name Here",
    request_received_on: "Request Received On",
    owners_not_found: "owners not found",
    business_nearby: "business nearby",
    role: "role",
    no_data_found: "no data found",
    load_more: "load more",
    owners_listed: "owners listed",
    // owner_profile: "owner profile",
    your_profile: "your profile",
    verified: "Verified",
    no_requests_found_send_a_request_to_view_an_update_here: "No requests found send a request to view an update here",
    when_someone_sends_you_requests_they_will_be_updated_here_and_you_will_see_them: "when someone sends you requests they will be updated here and you will see them",
    we_apologize_for_the_inconvenience_but_we_could_not_locate_nearby_operators_for_you_in_the_meantime_you_can_browse_through_other_top_operators: "We apologize for the inconvenience, but we couldn't locate nearby Operators for you. In the meantime, you can browse through other Top Operators.",
    we_apologize_for_the_inconvenience_but_we_could_not_locate_nearby_maintenance_partners_for_you_in_the_meantime_you_can_browse_through_other_top_maintenance_partners: "We apologize for the inconvenience, but we couldn't locate nearby Maintenance Partners for you. In the meantime, you can browse through other Top Maintenance Partners",
    years_experience: "Years Experience",
    no_operator_parnter_found: "No operator parnter found",
    no_maintance_parnter_found: "No Maintenance Parnter Found",
    by_equipment_type: "By Equipment Type",
    by_location: "By Location",
    view_similar: "View Similar",
    similar_profile: "Similar Profile",
    invoice: "Invoice",
    expires: "Expires",
    Purchased: "Purchased",
    current_plan: "Current Plan",
    e9_services: "E9 Services",
    total: "Total",
    remaining: "Remaining",
    redeem: "Redeem",
    buy_now: "Buy Now",
    no_premium_purchased_yet: "No Premium Purchased Yet",
    no_subscription_available_yet: "No Subscription Available Yet",
    payment_successful: "PAYMENT SUCCESSFUL",
    congratulations: "Congratulations",
    you_are_premium_member_now: "You Are Premium Member Now",
    explore: "Explore",
    payment_failed: "PAYMENT FAILED",
    something_went_wrong: "Something Went Wrong",
    please_try_again: "Please Try Again",
    try_again: "Try Again",
    premium_benefits: "Premium Benefits",
    count: "Count",
    price: "Price",
    please_select_the_plan: "Please Select The Plan",
    over_all_cost: "Over All Cost",
    premium_benefit: "Premium Benefit",
    final_price: "Final Price",
    off: "Off",
    your_selected_premium_plan: "Your Selected Premium Plan",
    offers: "OFFERS",
    promocode: "PROMOCODE",
    no_offers_available: "No Offers Available",
    see_all: "See All",
    enter_promocode_here: "Enter Promocode Here",
    cost_breakup: "Cost Breakup",
    subscription_type: "Subscription Type",
    premium_benefit_plan: "Premium Benefit Plan",
    total_cost: "Total Cost",
    discount: "Discount",
    sub_total: "Sub  Total",
    net_payable: "Net Payable",
    amount_payable: "Amount Payable",
    hide_details: "Hide Details",
    become_a_premium_member: "Become A Premium Member",
    join_us: "Join Us",
    your_premium_services: "YOUR PREMIUM SERVICES",
    order_no: "Order No",
    // requirements: "Requirements",
    your_subscription_is_ending_soon: "Your Subscription Is Ending Soon",
    renew: "Renew",
    review_detail: "Review Detail",
    premium: "Premium",
    upcoming_plan: "Upcoming Plan",
    starting_from: "Starting From",
    we_are_processing_recommendations_stay_tuned_for_an_enhanced_experience: "We Are Processing Recommendations Stay Tuned For An Enhanced Experience",
    // welcome_to_industry_requirements: "welcome to industry requirements",
    // view_requirements_in_the_industry: "view requirements in the industry",
    list_your_requirements_and_post_them: "list your requirements and post them",
    personalise_your_store: "Personalise Your Store",
    personalise_your_store_subheading: "Personalise Your Store Subheading",
    your_warehouse_name: "Your Warehouse Name",
    upload_logo: "Upload Logo",
    save_details: "Save Details",
    store_manager: "Store Manager",
    requirement_in_industry: "Requirement in Industry",
    see_the_requirements_posted_by_e9_users: "See the Requirements Posted by E9 Users",
    my_requirements: "My Requirements",
    see_the_requirements_posted_by_you: "See the Requirements Posted by You",
    coming_soon: "Coming Soon",
    analytics: "Analytics",
    // posts: "Posts",
    requirement_deleted_successfully: "Requirement Deleted Successfully",
    no_requirement_posted_by_you: "No Requirement Posted by You",
    // are_you_sure_you_want_to_delete_this_requirement_permanently: "Are You Sure You Want to Delete This Requirement Permanently?",
    post_reported_successfully: "Post Reported Successfully",
    request_sent_successfully: "Request Sent Successfully",
    no_requirement_in_industry: "No Requirement in Industry",
    connect: "Connect",
    // do_you_want_to_connect_with_this_user: "Do You Want to Connect With This User",
    requirement_images: "Requirement Images",
    new_post_available: "New Post Available",
    posted_on: "Posted On",
    expires_on: "Expires On",
    read_less: "Read Less",
    read_more: "Read More",
    view_leads: "View Leads",
    add_video: "Add Video",
    // add_photo: "Add Photo",
    no_images_added_yet: "No Images Added Yet",
    // requirement_category: "Requirement Category",
    // select_your_need: "Select Your Need",
    // tell_us_your_requirement: "Tell Us Your Requirement",
    please_type_requirement_here: "Please Type Requirement Here",
    if_you_have_a_photo_please_upload_it: "If You Have a Photo Please Upload It",
    congratulations_your_requirement_is_posted: "Congratulations Your Requirement Is Posted",
    requirement_is_required: "Requirement Is Required",
    no_request_sent_yet: "No Request Sent Yet",
    no_request_received_yet: "No Request Received Yet"
  };