import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMaterialConnectsPendingCountById } from "../../../services/materials/materials";


export const getMaterialConnectsPendingCountByIdThunk=createAsyncThunk("my-material/get-connects-pending-count",async (materialTypeId)=>{
    try {
        const data= await getMaterialConnectsPendingCountById(materialTypeId);
        return data;
    } catch (error) {
        throw(error);
    }

})