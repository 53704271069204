import "./MyLeadsDetailCard.css"

export const MyLeadsDetailCard = (
  { description = "", descriptionCursorRequired = true , imageUrl = "",    onSubmitButtonClick = () => {},}
  
  
) => {

  return (
    <div className="my-leads-card-container" style={{cursor:"pointer"}} onClick ={() => onSubmitButtonClick()}>
      <div className="my-leads-card-img">
      <img style={{height:"100%" , width:"100%" , objectFit:"contain"}} src={imageUrl} />
      </div>
      <p
        className="my-leads-card-description"
      >
        {description}
      </p>
    </div>
  ) 
};
