import { Formik } from "formik";
import * as Yup from "yup";
import { MultiAdDivider } from "../../../../layout/multiAdLayout/MultiAdLayout";
import { FormikPasswordInput } from "../../../../components/Input/FormikPasswordInput";
import { Box, Flex, Button, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { handlePasswordUpdateThunk } from "../../thunk/handlePasswordUpdateThunk";
import { hashToMd5 } from "../../../../helpers/dataConversion/convertToMd5";
import { ChooseAccountModalPassword } from "./component/ChoosAccountModal/ChooseAccountModal";
import {
  setNewPassword,
  setIsContinueModelOpen,
} from "../../slice/forgotPasswordSlice";
import { PasswordChangeSuccessModal } from "./component/VerificattionSuccessModal/PasswordChangeSuccessModal";
import { V5MultiAdLayout } from "../../../../layout/multiAdLayout/V5MultiAdLayout";

export const EnterNewPassword = () => {
  const dispatch = useDispatch();
  const forgetPasswordOtpData = useSelector((state) => state.forgotPassword);
  const { defaultLanguage } = useSelector((state) => state.masterData);
  return (
    <>
      <ChooseAccountModalPassword />
      <PasswordChangeSuccessModal />
      <V5MultiAdLayout
        heading="Fill your account details for easy setup"
        subHeading="It won't take much long ..."
      >
        <Flex
          margin="0 auto"
          w="347px"
          direction="column"
          gap="1em"
          maxH="330px"
          overflowY="scroll"
        >
          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            validationSchema={Yup.object({
              password: Yup.string()
                .required("Password Required")
                .min(6, "Minimum 6 characters required"),
              confirmPassword: Yup.string()
                .required("Confirm Password Required")
                .oneOf([Yup.ref("password"), null], "Password must match"),
            })}
            onSubmit={(values) => {
              if (forgetPasswordOtpData.accountList.length > 1) {
                dispatch(setNewPassword(values.password));
                dispatch(setIsContinueModelOpen(true));
              } else {
                dispatch(setNewPassword(hashToMd5(values.password)));
                dispatch(handlePasswordUpdateThunk(hashToMd5(values.password)));
              }
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} id="new-password">
                <Flex direction="column" gap="1em">
                  <FormikPasswordInput
                    isRequired={true}
                    name="password"
                    label="Enter New Password"
                    placeholder="Minimum 6 digits allowed..."
                  />
                  <FormikPasswordInput
                    isRequired={true}
                    name="confirmPassword"
                    label="Re-Enter New Password"
                    placeholder="Minimum 6 digits allowed..."
                  />
                </Flex>
              </form>
            )}
          </Formik>
        </Flex>
        <Box>
          <Flex
            justifyContent="center"
            alignItems="center"
            bgColor="#fff"
            h="78px"
            style={{ boxShadow: "0px -10px 15px #2c2c2c0f" }}
          >
            <Button
              variant="gradient"
              fontWeight="semibold"
              width="80%"
              margin="0 auto"
              maxW="347px"
              type="submit"
              form="new-password"
            >
              Continue
            </Button>
          </Flex>
        </Box>
      </V5MultiAdLayout>
    </>
  );
};
