import React, { useState, useEffect } from 'react';


const GeolocationComponent = () => {
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { lat: '', lng: '' },
  });



  const onSuccess = (location) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
    });
  };

  const onError = (error) => {
    setLocation({
      loaded: true,
      error,
    });
  };

  useEffect(() => {
    if (!('geolocation' in navigator)) {
      onError({
        code: 0,
        message: 'Geolocation not supported',
      });
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, []);

  return (
    <div>
      {location.loaded ? (
        <>

        </>
        // <ul>
        //   <li>Latitude: {location.coordinates.lat}</li>
        //   <li>Longitude: {location.coordinates.lng}</li>
        // </ul>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default GeolocationComponent;
