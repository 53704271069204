import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../constants/initialState";
import { setSessionStorageItems } from "../../../helpers/sessionStorage/sessionStorage";
import { postDealershipStepOneThunk } from "../thunk/postDealershipStepOne";
import { updateDealershipStepOneThunk } from "../thunk/updateDealershipStepOneThunk";
import { postDealershipImageThunk } from "../thunk/postDealershipImageThunk";

export const myBusinessDealershipSlice = createSlice({
  name: "myBusinessDealership",
  initialState: initialState,

  reducers: {

    clearDealershipData:  (state) => {
       Object.assign(state, initialState)
    },
    clearStepperIndex:  (state) => {
      state.stepperIndex = 0 ;
    },
    openAddToMarketplace : (state) =>{
      state.addToMarketplaceModal = true
    },
    closeAddToMarketplace : (state) =>{
      state.addToMarketplaceModal = false
    },
    openDealershipModal: (state) => {
      state.isOpenWelcomeModal = true;
    },
    closeDealershipModal: (state) => {
      state.isOpenWelcomeModal = false;
    },
    openConfirmMediaModal: (state) => {
      state.isConfirmMediaOpen = true;
    },
    closeConfirmMediaModal: (state) => {
      state.isConfirmMediaOpen = false;
    },

    openAddDealershipModal: (state) => {
      state.isAddDealershipModalOpen = true;
    },
    closeAddDealershipModal: (state) => {
      state.isAddDealershipModalOpen = false;
    },
    openVirtualDealershipModal: (state) => {
      state.isVirtualDealershipOpen = true;
    },
    closeVirtualDealershipModal: (state) => {
      state.isVirtualDealershipOpen = false;
    },

    openDeleteModal: (state) => {
      state.isDeleteOpen = true;
    },
    closeDeleteModal: (state) => {
      state.isDeleteOpen = false;
    },
    openPhotoModal: (state) => {
      state.isPhotoOpen = true
    },
    closePhotoModal: (state) => {
      state.isPhotoOpen = false;
    },
    openVideoModal: (state) => {
      state.isVideoOpen = true
    },
    closeVideoModal: (state) => {
      state.isVideoOpen = false;
    },
    openCertificateModal: (state) => {
      state.isCertificateOpen = true
    },
    closeCertificateModal: (state) => {
      state.isCertificateOpen = false;
    },
    setRegionModalOpen: (state) => {
      state.isRegionOpen = true
    },
    setRegionModalClose: (state) => {
      state.isRegionOpen = false
    },

    setBusinessGuuid: (state, action) => {
      state.guuid = action.payload;
      setSessionStorageItems("POST_DEALERSHIP_GUUID", action.payload)
    },

    // Payload Step 1
    setOemId: (state, action) => {
      state.oemID = action.payload;
      setSessionStorageItems("POST_DEALERSHIP_OEM_ID", action.payload);
    },
    setIndustryId: (state, action) => {
      state.inndustryId = action.payload;
      setSessionStorageItems("POST_DEALERSHIP_OEM_INDUSTRY_ID", action.payload)
    },
    setEquipmentStatusId: (state, action) => {
      state.equipmentStatusId = action.payload;
      setSessionStorageItems("POST_DEALERSHIP_DEALER_TYPE_ID", action.payload)
    },
    setDealerName: (state, action) => {
      state.name = action.payload;
      setSessionStorageItems("POST_DEALERSHIP_NAME", action.payload);
    },
    setEmail: (state, action) => {
      state.email = action.payload;
      setSessionStorageItems("POST_DEALERSHIP_EMAIL", action.payload)
    },
    setContact: (state, action) => {
      state.contact = action.payload;
      setSessionStorageItems("POST_DEALERSHIP_CONTACT", action.payload)
    },
    setWebsite: (state, action) => {
      state.website = action.payload;
      setSessionStorageItems("POST_DEALERSHIP_WEBSITE", action.payload);
    },
    setLocation: (state, action) => {
      state.location = action.payload;
      setSessionStorageItems("POST_DEALERSHIP_LOCATION", action.payload)
    },
    setLatitude: (state, action) => {
      state.latitude = action.payload;
      setSessionStorageItems("POST_DEALERSHIP_LATITUDE", action.payload)
    },
    setLongitude: (state, action) => {
      state.longitude = action.payload;
      setSessionStorageItems("POST_DEALERSHIP_LONGITUDE", action.payload)
    },
    setISDCode: (state, action) => {
      state.isdCode = action.payload;
      setSessionStorageItems("POST_DEALERSHIP_ISD_CODE", action.payload)
    },
    setPostGuuid: (state, action) => {
      state.postGuuid = action.payload;
      setSessionStorageItems("POST_DEALERSHIP_POST_GUUID", action.payload)
    },
    setTaxNumber: (state, action) => {
      state.taxNumber = action.payload;
      setSessionStorageItems("POST_DEALERSHIP_TAX_NUMBER", action.payload)
    },
    setEditGuuid: (state, { payload }) => {
      state.editGuuid = payload;
      setSessionStorageItems("EDIT_DEALERSHIP_GUUID", payload)
    },

    // Payload Step 2

    setDealershipDetails: (state, action) => {
      state.getDealershipDetails = action.payload
    },
    setImageList: (state, action) => {
      state.getImageList = action.payload
    },
    setVideoList: (state, action) => {
      state.getVideoList = action.payload
    },
    setCertificateListById: (state, {payload}) => {
      state.getCertificateList = payload
    },


    // Step 3
    
    setRegionPayload: (state, { payload }) => {
      if(payload){
        const presentState = state.regionPayload;
        const newRegionPayload = [...state.regionPayload];
      
        payload.forEach((item) => {
          const existingStateIndex = newRegionPayload.findIndex(
            (region) => region.states.id === item.states.id
          );
      
          if (existingStateIndex !== -1) {
            const existingCityIds = newRegionPayload[existingStateIndex].cities.map(
              (city) => city.id
            );
      
            newRegionPayload[existingStateIndex] = {
              ...newRegionPayload[existingStateIndex],
              cities: [
                ...newRegionPayload[existingStateIndex].cities,
                ...item.cities.filter((city) => !existingCityIds.includes(city.id)),
              ],
            };
          } else {
            newRegionPayload.push({
              states: { id: item?.states?.id, value: item?.states?.value },
              cities: [{ id: item.cities[0]?.id, value: item.cities[0]?.value }],
            });
          }
        });
  
        localStorage.setItem('POST_DEALERSHIP_REGION', JSON.stringify(newRegionPayload));
        return {
          ...state,
          regionPayload: newRegionPayload,
        };
      }
    },
    deleteRegionPayload: (state, { payload }) => {
      if (payload) {
        const newState = state.regionPayload.filter(region => region.states.id !== payload?.id);
        localStorage.setItem('POST_DEALERSHIP_REGION', JSON.stringify(newState));
        return {
          ...state,
          regionPayload: newState,
        };
      }
    },
    
    
    increaseStepperIndex: (state) => {
      if (state.stepperRoute.length <= state.stepperIndex) {
        return
      }
      state.stepperIndex += 1;
      setSessionStorageItems(
        "POST_DEALERSHIP_STEPPER_INDEX",
        (state.stepperIndex)
      );
    },
    decreaseStepperIndex: (state) => {
      if (state.stepperIndex === 0) {
        return;
      }
      if (state.stepperIndex < 0) {
        state.stepperIndex = 0;
      }
      state.stepperIndex -= 1;
      setSessionStorageItems(
        "POST_DEALERSHIP_STEPPER_INDEX",
        state.stepperIndex
      );
    },
  
    
    //  Edit Dealership 
    setAllDealersshipDataById : (state, {payload}) => {
      state.getDealershipDataById = payload ;
    },
    setFinanceProvided : (state, {payload}) => {
      state.financeProvided = payload ;
    },
    setManagerId : (state, {payload}) => {
      state.managerID = payload ;
    },
    setGetAllImageById : (state, {payload}) => {
      state.getImageList = payload ; 
      localStorage.setItem("POST_DEALERSHIP_IMAGE_LIST",JSON.stringify(payload))
    },
    setPhotoTypeList : (state, {payload}) => {
      state.photoTypeList = payload ;
      sessionStorage.setItem("DEALERSHIP_PHOTO_TYPE_LIST",JSON.stringify(payload));
    },

    // Videos Handling
    setGetAllVideosById : (state, {payload}) => {
      state.getVideoList = payload ; 
      localStorage.setItem("POST_DEALERSHIP_VIDEO_LIST",JSON.stringify(payload))
    },
    setVideoTypeList : (state, {payload}) => {
      state.videoTypeList = payload ;
      sessionStorage.setItem("DEALERSHIP_VIDEO_TYPE_LIST",JSON.stringify(payload));
    },
    openRemoveMarketplaceModal : (state) =>{
      state.isMaketplaceDelete =true
    },
    closeRemoveMarketplaceModal : (state) => {
      state.isMaketplaceDelete = false
    },
    setIsLoading :(state, { payload })=>{
      state.isLoading=payload
    }
    
  },

  extraReducers: (builder) => {
    builder.addCase(postDealershipStepOneThunk.fulfilled, (state, { payload }) => {
      state.postGuuid = payload.dealershipId;
      setSessionStorageItems("POST_DEALERSHIP_POST_GUUID", payload.dealershipId)

    })

    builder.addCase(postDealershipImageThunk.fulfilled, (state, { payload }) => {

    })

    builder.addCase(updateDealershipStepOneThunk.fulfilled, (state, { payload }) => {
    })

  }

});

export const {

  // Common 
  setData,
  openDealershipModal,
  closeDealershipModal,
  openAddDealershipModal,
  closeAddDealershipModal,
  openVirtualDealershipModal,
  closeVirtualDealershipModal,
  openDeleteModal,
  closeDeleteModal,
  openPhotoModal,
  closePhotoModal,
  openVideoModal,
  closeVideoModal,
  openCertificateModal,
  closeCertificateModal,
  setRegionModalOpen,
  setRegionModalClose,
  setBusinessGuuid,
  openConfirmMediaModal,
  closeConfirmMediaModal,
  openAddToMarketplace,
  closeAddToMarketplace,

  // Step 1
  setOemId,
  setIndustryId,
  setEquipmentStatusId,
  setDealerName,
  setEmail,
  setContact,
  setWebsite,
  setLocation,
  setLatitude,
  setLongitude,
  setISDCode,
  setPostGuuid,
  setTaxNumber,
  setEditGuuid,

  // Step 2
  setDealershipDetails,
  setImageList,
  setVideoList,
  setCertificateListById,

  // Step 3
  setRegionPayload,
  deleteRegionPayload,
  increaseStepperIndex,
  decreaseStepperIndex,
  clearStepperIndex,
  clearDealershipData,

  // Edit Dealership
  setAllDealersshipDataById,
  setFinanceProvided,
  setManagerId,
  setGetAllImageById,
  setPhotoTypeList,

  // Videos Handling
  setGetAllVideosById,
  setVideoTypeList,

  // Marketplace Remove
  openRemoveMarketplaceModal,
  closeRemoveMarketplaceModal,

  setIsLoading

} = myBusinessDealershipSlice.actions