import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "@chakra-ui/icons";
import { closeRentalDurationModal } from "../../features/rentalEquipmentMarketplace/slice/rentalEquipmentMartketplace";
import { SelectCardWrapper } from "../SelectCardWrapper/SelectCardWrapper";
import RentalDurationIcon from "../../../../src/assets/webapp/subscription/RentalDurationPopUpIcon.svg";
import { getRentalDuration } from "../../services/equipments/equipments";
import { CustomInfoModal } from "../CustomInfoModal/CustomInfoModal";
import { CustomModal } from "../CustomModal/CustomModal";

export const RentalDurationPopUp = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const rentalEquipmentMarketplaceData = useSelector(
    (state) => state.rentalEquipmentMarketplace
  );
  const [rentalDurations, setRentalDurations] = useState(null);
  const [selectedRentalDuration, setSelectedRentalDuration] = useState(null);

  useEffect(() => {
    (async () => {
      const data = await getRentalDuration();

      if (data?.Status === 200) {
        setRentalDurations(data?.Data?.RentalDurationData);
      }
    })();
  }, []);

  return (
    // <Modal isOpen={rentalEquipmentMarketplaceData.isOpenRentalDurationModal} isCentered >
    // <ModalOverlay />
    // <ModalContent>
    //         <ModalHeader>
    //             <Box textAlign="right">
    //                 <Button
    //                     variant="ghost"
    //                     onClick={() => {
    //                         dispatch(closeRentalDurationModal());
    //                         setSelectedRentalDuration(null);
    //                     }}
    //                     boxShadow="none"
    //                 >
    //                     <CloseIcon />
    //                 </Button>
    //             </Box>
    //             <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
    //                 <img src={RentalDurationIcon} />
    //                 <Heading
    //                     fontSize="28px"
    //                     fontWeight="700"
    //                     fontFamily="Assistant"
    //                     lineHeight="52.32px"
    //                     color={"#002961"}
    //                 >
    //                 Rent Duration
    //                 </Heading>
    //                 <p style={{
    //                     color:"#2C2C2C"
    //                 }}>Choose your Rental Duration</p>
    //             </Flex>

    //         </ModalHeader>

    //         <ModalBody height={"310px"} display={"flex"} flexDirection={"column"} justifyContent={"space-evenly"}>
    //         <Flex direction="column" gap="0.50em" justifyContent={"center"} alignItems={"center"}>
    //                 {rentalDurations ? rentalDurations.map((rentalDuration) => (
    //                         <SelectCardWrapper
    //                             width="374px"
    //                             height="80px"
    //                             key={rentalDuration.RentalDurationId
    //                             }
    //                             value={rentalDuration.EquipAvailabilityValue}
    //                             isSelected={selectedRentalDuration === rentalDuration?.
    //                                 RentalDurationId
    //                                 }
    //                             onClick={() => setSelectedRentalDuration(rentalDuration?.
    //                                 RentalDurationId
    //                                 )}
    //                             display="flex"
    //                             flexdirection="column"
    //                             gap={"0.2em"}
    //                         >
    //                             <Heading width="fit-content" fontSize="20px" fontWeight="semibold">
    //                                 {rentalDuration.RentDurationValue}
    //                             </Heading>
    //                             <Text width="fit-content" fontSize="16px" color="#002961">
    //                                 {rentalDuration.RentDurationType}
    //                             </Text>
    //                         </SelectCardWrapper>
    //                 )) : null}
    //         </Flex>
    //         </ModalBody>

    //                 <ModalFooter alignItems={"center"} justifyContent={"end"} borderRadius={"0px 0px 12px 12px"} boxShadow={"0px -10px 15px 0px rgba(44, 44, 44, 0.16)"}>
    //                     <Flex justifyContent={"center"} gap={"2rem"} >
    //                         <Button variant="gradient" width={"128px"} height={"45px"} onClick={()=>{
    //                              dispatch(closeRentalDurationModal());
    //                              onSubmit(rentalDurations[selectedRentalDuration-1]);
    //                              setSelectedRentalDuration(null);
    //                             }}
    //                             isDisabled={selectedRentalDuration === null}
    //                             >
    //                             Submit
    //                         </Button>
    //                     </Flex>
    //                 </ModalFooter>
    // </ModalContent>
    // </Modal>

    
        <CustomModal
         isOpen={rentalEquipmentMarketplaceData.isOpenRentalDurationModal}
        hasCloseButton={true}
        w={""}
        onCloseButtonClick={() => {
          dispatch(closeRentalDurationModal());
          setSelectedRentalDuration(null);
        }}  
        >

        <CustomModal.Header>
          <Flex flexDirection="column" gap={"14px"}>
        
            <Flex justifyContent="center" alignItems="center" padding={"5px"}>
              <img style={{ height: "50px" }} src={RentalDurationIcon} />
            </Flex>
      
          <Text textAlign="center" fontWeight={700} fontSize="20px" color={"#1A1E21"}>
           {"Rent Duration"}
            </Text>
           <Text textAlign="center" fontWeight={400} fontSize="16px" color={"#959596"}>
            {"Choose your Rental Duration"}
            </Text>
          </Flex>
        </CustomModal.Header>

        <CustomModal.Body>
        <Flex
        direction="column"
        gap="0.50em"
        justifyContent={"center"}
        alignItems={"center"}
        margin={"0 50px"}
      >
        {rentalDurations
          ? rentalDurations.map((rentalDuration) => (
              <SelectCardWrapper
              padding="5px 20px"
                width="338px"
                height="57px"
                key={rentalDuration.RentalDurationId}
                value={rentalDuration.EquipAvailabilityValue}
                isSelected={
                  selectedRentalDuration === rentalDuration?.RentalDurationId
                }
                onClick={() =>
                  setSelectedRentalDuration(rentalDuration?.RentalDurationId)
                }
                display="flex"
                flexdirection="column"
                gap={"0.2em"}
              >
                <Heading
                  width="fit-content"
                  fontSize="16px"
                  fontWeight="semibold"
                >
                  {rentalDuration.RentDurationValue}
                </Heading>
                <Text 
                width="fit-content" fontSize="14px" color="#002961">
                  {rentalDuration.RentDurationType}
                </Text>
              </SelectCardWrapper>
            ))
          : null}
      </Flex>
        </CustomModal.Body>
     

      <CustomModal.Footer
        justifyContent="center"
        alignItems="center"
        maxHeight={"60px"}
        boxShadow={"0px -10px 15px 0px #2C2C2C28"}
      >
        <Button
          variant="gradient"
          width={"128px"}
          height={"45px"}
          onClick={() => {
            dispatch(closeRentalDurationModal());
            onSubmit(rentalDurations[selectedRentalDuration - 1]);
            setSelectedRentalDuration(null);
          }}
          isDisabled={selectedRentalDuration === null}
        >
          Submit
        </Button>
      </CustomModal.Footer>
        </CustomModal>
  
  );
};
