import { useNavigate, useSearchParams } from "react-router-dom";
import { BrandLoader } from "../../../../components/loaders/brandLoader/BrandLoader";
import { useEffect, useState } from "react";
import {
  createE9MarketplaceAssetDetails,
  getAssetMarketplaceListedDetails,
  getUserAssetById,
} from "../../../../services/userAssets/userAssets";
import { useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFreeLeadsAvailableForCompany,
  getFreeLeadsForIndividual,
} from "../../../../services/freeLeads/freeLeads";
import { fetchHandler } from "../../../../..";
import { convertToFormData } from "../../../../helpers/dataConversion/convertToFormData";
import { API_CONSTANTS } from "../../../../../constants/apiConstants";
import { getPurchasedLeadsById } from "../../../../services/leadCheck/leadCheck";
import { createE9RedeemedTransactionDetails } from "../../../../services/redeem/redeem";
import { equipmentListingPemiumLeadsThunk } from "../../../premiumLeads/thunks/getEquipmentListingPremiumLeads";
import { getPremiumRedeemCountById } from "../../../../services/premium/premium";

export const CreateE9MarketplaceDetails = () => {
  const [assetDetails, setAssetDetails] = useState(null);
  const [marketplaceDetails, setMarketplaceDetails] = useState(null);
  const [freeLeadsData, setFreeLeadsData] = useState(null);
  const [lastId, setLastId] = useState(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const toast = useToast();
  const { UserTypeValue, accountDetails } = useSelector((state) => state.user);
  const { isPremium, premiumId } = useSelector((state) => state.premium);
  const { totalLeads, remainingLeads } = useSelector(
    (state) => state.premiumLeads.equipmentListing
  );
  const dispatch = useDispatch();
  // const {selectedSubscription} = useSelector((state) => (state.subscriptionLeadsCheck))

  //  Get Asset Details to be added to marketplace
  const getUserAssetDetails = async (id) => {
    try {
      const data = await getUserAssetById(id);
      setAssetDetails(data);
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        isClosable: true,
        position: "top-right",
        status: "error",
        id: "asset-error",
      });
      setAssetDetails(null);
      navigate("/equipment-store");
    }
  };

  const getAssetMarketplaceDetails = async (id) => {
    try {
      const data = await getAssetMarketplaceListedDetails(id);
      //console.log(data)
      const selectedMarketplaceDetails = data.find(({ MarketPlaceValue }) =>
        MarketPlaceValue.toLowerCase()
          .split(" ")
          .includes(searchParams.get("marketplaceType"))
      );
      //console.log(selectedMarketplaceDetails)
      setMarketplaceDetails(selectedMarketplaceDetails);
    } catch (error) {
      //console.log(error);
      setMarketplaceDetails(null);
      navigate("/equipment-store");
    }
  };

  const getFreeLeadsForCompany = async (taxNumber) => {
    try {
      const data = await getFreeLeadsAvailableForCompany(taxNumber);
      // console.log(data)
      //   Filter on the basis of marketplace type
      const equipmentListingLeads = data.find(
        ({ ServiceTypeValue, SubscriptionTypeValue }) =>
          SubscriptionTypeValue === "Contact Based" &&
          ServiceTypeValue === "Equipment Listing"
      );
      // console.log(equipmentListingLeads);
      if (equipmentListingLeads) {
        //    createE9Marketplaedetails
        setFreeLeadsData(equipmentListingLeads);
      } else {
        setFreeLeadsData(null);

        toast({
          title: "Error",
          description: "Some error occured. Please try again.",
          isClosable: true,
          position: "top-right",
          status: "error",
        });
        // navigate("/equipment-store");
      }
    } catch (error) {}
  };

  const getFreeLeadsAvailableForIndividual = async () => {
    try {
      const data = await getFreeLeadsForIndividual();
      //console.log(data)
      const equipmentListingLeads = data.find(
        ({ ServiceTypeValue, SubscriptionTypeValue }) =>
          SubscriptionTypeValue === "Contact Based" &&
          ServiceTypeValue === "Equipment Listing"
      );
      if (equipmentListingLeads) {
        //console.log(equipmentListingLeads)

        setFreeLeadsData(equipmentListingLeads);
      }
    } catch (error) {
      setFreeLeadsData(null);

      toast({
        title: "Error",
        description: "Some error occured. Please try again.",
        isClosable: true,
        position: "top-right",
        status: "error",
      });
      navigate("/equipment-store");
    }
  };

  const getFreeLeads = async () => {
    if (UserTypeValue === "Company") {
      //console.log("COMPANY FREE")
      await getFreeLeadsForCompany(accountDetails.CompanyDetails.TaxNumber);
    } else {
      //console.log("INDIVIDUAL FREE")
      await getFreeLeadsAvailableForIndividual();
    }
  };

  const getSubscriptionDetailsToConsume = async (id) => {
    try {
      const data = await getSubscriptionDetails(id);
      const subscriptionToConsume = selectSubscription(data);
      // setSelectedSubscription(subscriptionToConsume);
      return subscriptionToConsume;
    } catch (error) {}
  };

  const getSubscriptionDetails = async (id) => {
    try {
      const data = await getPurchasedLeadsById(id);
      const requiredData = data.filter(
        (data) => data.LeadRemaining > 0 && data.DaysRemaining > 0
      );
      return requiredData;
    } catch (error) {}
  };

  const selectSubscription = (subscriptionDetails) => {
    if (!subscriptionDetails) {
      return null;
    }
    if (subscriptionDetails && subscriptionDetails.length === 1) {
      return subscriptionDetails[0];
    }

    const subscriptionWithLeastDays = subscriptionDetails.sort(
      (a, b) => a.DaysRemaining - b.DaysRemaining
    );
    return subscriptionWithLeastDays[0];
  };

  const createE9MarketplaceDetails = async (body) => {
    // console.log("inside marketplace details")

    try {
      const data = await createE9MarketplaceAssetDetails(body);
      setLastId(data);
    } catch (error) {
      setLastId(null);
      // console.log(error)
      //  console.log("Error while creating marketplace details")
      toast({
        title: "Error",
        description: "Some error occured. Please try again.",
        isClosable: true,
        position: "top-right",
        status: "error",
      });
    }
  };

  const redeemFreeLead = async () => {
    try {
      //console.log("inside free redeem")
      const { data } = await fetchHandler(
        "/CreateE9FreeRedeemedTransaction",
        {},
        {},
        convertToFormData({ ServiceTypeId: 3, FreeLeadConsumed: 1 }),
        API_CONSTANTS.HTT_POST
      );
      // naviagte the user to maretplace on the basis of marketplace type
      if (searchParams.get("marketplaceType") === "used") {
        navigate("/marketplace/MarketPlaceUEM");
      } else {
        navigate("/marketplace/MarketPlaceRM");
      }
    } catch (error) {}
  };

  const redeemSubscriptionLeads = async (body) => {
    try {
      const data = await createE9RedeemedTransactionDetails(body);
      if (searchParams.get("marketplaceType") === "used") {
        navigate("/marketplace/MarketPlaceUEM");
      } else {
        navigate("/marketplace/MarketPlaceRM");
      }
    } catch (error) {
      //console.log(error);
    }
  };


  const getPremiumLeadsToConsume = async(serviceTypeId) => {
    try{
      const data = await getPremiumRedeemCountById(serviceTypeId)
      console.log(data);
      return data;
    }
    catch(error){

    }
  }

  const redeemPremiumLeads = async (body) => {
    try {
      // get premium Leads
      // redeem premiumLeads
      const { data } = await fetchHandler(
        "/PostE9PremiumRedeem",
        {},
        {},
        convertToFormData(body),
        API_CONSTANTS.HTT_POST
      );
     if(data.Status === 201 && data.Data && Object.keys(data.Data).length > 0){
      if (searchParams.get("marketplaceType") === "used") {
        navigate("/marketplace/MarketPlaceUEM");
      } else {
        navigate("/marketplace/MarketPlaceRM");
      }
     }
     else{
      toast({
        title: "Error",
        description: "Some error occured. Please try again.",
        isClosable: true,
        position: "top-right",
        status: "error",
      });
      navigate("/equipment-store");
     }
    } catch (error) {}
  };

  const handlePremium = async (body) => {
    await redeemPremiumLeads({...body, UniqueRefId : lastId})
  };

  useEffect(() => {
    if (searchParams.get("assetId")) {
      getUserAssetDetails(searchParams.get("assetId"));
    } else {
      // console.log("error from assetId in params")
      toast({
        title: "Error",
        description: "Some error occured. Please try again.",
        isClosable: true,
        position: "top-right",
        status: "error",
      });
      navigate("/equipment-store");
    }
  }, [searchParams.get("assetId")]);

  useEffect(() => {
    if (assetDetails && Object.keys(assetDetails).length > 0) {
      getAssetMarketplaceDetails(assetDetails.AssetDetails.AssetId);
    }
  }, [assetDetails]);

  useEffect(() => {
    // console.log("get free leads")
    getFreeLeads();
  }, []);


 

  useEffect(() => {
    // REDEEM PROCESS TO BE SHARED
    // console.log("redeem process initiated")
    const initiateRedeemProcess = async () => {
      if (searchParams.get("leadType") === "subscription") {
        // console.log("inside subscription redeem");
        // debugger
        const selectedSubscription = await getSubscriptionDetailsToConsume(
          freeLeadsData.ServiceTypeId
        );
        //console.log(selectedSubscription)
        if (selectedSubscription) {
          createE9MarketplaceDetails({
            AssetId: assetDetails.AssetDetails.AssetId,
            MarketPlaceTypeId: marketplaceDetails.MarketplaceId,
            IsDocumentPrivate:
              parseInt(searchParams.get("isDocumentPrivate")) === 0 ? 0 : 1,
          });
          const body = {
            TransactionId: selectedSubscription.E9TransactionId,
            UserSubsServicePurchasedId:
              selectedSubscription.UserSubsServicePurchasedId,
            ServiceId: selectedSubscription.ServiceTypeId,
            LeadPurchased: selectedSubscription.PurchasedCount,
            LeadRedeemed: 1,
            LeadRemaining: selectedSubscription.LeadRemaining - 1,
          };

          await redeemSubscriptionLeads([body]);
        }
      } else if (
        searchParams.get("leadType") === "free" &&
        freeLeadsData.ContactRemaining > 0
      ) {
        //console.log("free lead selected")
        await createE9MarketplaceDetails({
          AssetId: assetDetails.AssetDetails.AssetId,
          MarketPlaceTypeId: marketplaceDetails.MarketplaceId,
          IsDocumentPrivate:
            parseInt(searchParams.get("isDocumentPrivate")) === 0 ? 0 : 1,
        });

        await redeemFreeLead();
      } 
       else if (!searchParams.get("leadType")) {
        toast({
          title: "Error",
          description: "Some error occured. Please try again.",
          isClosable: true,
          position: "top-right",
          status: "error",
        });
        navigate("/equipment-store");
      }
    };
    // console.log(freeLeadsData)
    // console.log(assetDetails)
    // console.log(marketplaceDetails)
    if (freeLeadsData && assetDetails && marketplaceDetails) {
      // console.log("redeem initiated")
      initiateRedeemProcess();
    }
  }, [freeLeadsData, assetDetails, marketplaceDetails]);

  useEffect(() => {
    const initiatePremiumRedeemProcess = async () => {
      const leadType = searchParams.get("leadType");
      if (leadType === "premium") {
        const premiumLeadDetails = await getPremiumLeadsToConsume(3);
        if (premiumLeadDetails.RemainingCount > 0) { 
          await createE9MarketplaceDetails({
            AssetId: assetDetails.AssetDetails.AssetId,
            MarketPlaceTypeId: marketplaceDetails.MarketplaceId,
            IsDocumentPrivate: parseInt(searchParams.get("isDocumentPrivate")) === 0 ? 0 : 1,
          });
          const body = {
            E9PremiumTransactionDetailsId: premiumLeadDetails.E9PremiumTransactionDetailsId,
            ServiceTypeId: premiumLeadDetails.ServiceTypeId,
            PaymentPlanId: premiumLeadDetails.PaymentPlanId,
            RedeemCount: 1,
          };
          
          await handlePremium(body);
        } else {
          toast({
            title: "Error",
            description: "No premium leads remaining.",
            isClosable: true,
            position: "top-right",
            status: "error",
          });
          navigate("/equipment-store");
        }
      }
    };
  
    if (assetDetails && marketplaceDetails) {
      initiatePremiumRedeemProcess();
    }
  }, [assetDetails, marketplaceDetails, searchParams]);
  

  return (
    <BrandLoader
      description={`Please don't close the tab. ${
        !assetDetails
          ? "Verifying equipment details"
          : !marketplaceDetails
          ? "Verifying marketplace details."
          : "Verifying Leads"
      }`}
    />
  );
};
