import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMyRequirementLoadMoreList } from "../../../services/industryRequirement/industryRequirement";


export const loadMoreMyRequirement = createAsyncThunk('loadMoreMyRequirement',async (_,{getState})=>{
    try {
        const {cardCount,paginationCount} = getState().industryRequirement ;
        const data = await getMyRequirementLoadMoreList(cardCount,paginationCount);
        return data ;
    } catch (error) {
        
    }
})