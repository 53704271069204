import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMyOrdersRequestSentList } from "../../../services/myLeads/myLeads";

export const getRemSentDataThunk = createAsyncThunk(
  "myLeads/sentRemRequestData",
  async (_, { getState }) => {
    try {
      let id = "Rental Equipment";
      const data = await getMyOrdersRequestSentList(id);
      return data;
    } catch (error) {}
  }
);
