import { API_CONSTANTS } from "../../../constants/apiConstants";
import {fetchHandler} from "../../../index"
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData";


export const changePassword = async (payload) => {
    try {
    
    const {data}= await fetchHandler(API_CONSTANTS.CHANGE_PASSWORD,{},{},payload,API_CONSTANTS.HTTP_PUT)

    return data;
  
    } catch (error) {
      throw error.response;
    }
  }

  export const deleteAccount = async () => {
    try {
  
        const {data}= await fetchHandler("/DeleteUserAccount",{},{},{},API_CONSTANTS.HTTP_DELETE,{isAuthRequired:true});
        return data;
    }
    catch (err) {
      throw err.response;
    }
  }

  export const GetPrivacyOptionsForUser = async () => {

    try {

    const {data}=await fetchHandler(`${API_CONSTANTS.GET_PRIVACY_OPTIONS_FOR_USER}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});

    return data;

    } catch (error) {
      throw error;
    }
  }


  export const SetUserPrivacySettings = async (payload) => {
    try {
      const {data}= await fetchHandler(`${API_CONSTANTS.SET_USER_PRIVACY_SETTINGS}`,{},{},convertToFormData(payload),API_CONSTANTS.HTT_POST)
      return data;
  
    }
    catch (error) {
      throw error.response;
  
    }
  }