import { Flex } from "@chakra-ui/layout";
import { ImageUpload } from "../ImageUpload/ImageUpload";

export const DoubleImageUpload = ({
  firstImageLabel,
  secondImageLabel,
  firstImageHelperText,
  secondImageHelperText,
  showFirstImageLabel,
  showSecondImageLabel,
  firstImageRequired,
  secondImageRequired,
  firstImageInputName,
  secondImageInputName,
  firstImageFormikProps,
  secondImageFormikProps,
  isDisabled,
}) => {
  return (
    <Flex gap="1em">
      <ImageUpload
        name={firstImageInputName}
        label={firstImageLabel}
        showLabel={showFirstImageLabel}
        helperText={firstImageHelperText}
        isRequired={firstImageRequired}
        formikProps={firstImageFormikProps}
        isDisabled={isDisabled}
      />
      <ImageUpload
        name={secondImageInputName}
        label={secondImageLabel}
        showLabel={showSecondImageLabel}
        helperText={secondImageHelperText}
        isRequired={secondImageRequired}
        formikProps={secondImageFormikProps}
        isDisabled={isDisabled}
      />
    </Flex>
  );
};
