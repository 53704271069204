import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPostLoginAdvertisments } from "../../../services/advertisement/advertisement";

export const getPostLoginAdvertisementThunk = createAsyncThunk(
  "dashboard/getPostLoginAdvertisement",
  async () => {
    try {
      return await getPostLoginAdvertisments();
    } catch (error) {
      throw error;
    }
  }
);
