import { createAsyncThunk } from "@reduxjs/toolkit";
import { getReceivedRequirementList } from "../../../../services/industryRequirement/industryRequirement";

export const getReceivedRequirementCardThunk = createAsyncThunk('/getReceivedRequirementCards',async ()=>{
    try{
        const data = await getReceivedRequirementList() ;
        return data ;
    }catch(error){

    }
}) 