import { useDispatch, useSelector } from "react-redux"
import { useEffect, useRef, useState } from "react"
import { Box, Flex, Button, Heading, Text, useToast, Image} from "@chakra-ui/react"
import { v4 as uuid } from "uuid"
import { useNavigate, useSearchParams } from "react-router-dom"
import { MultiAdDivider } from "../../../../layout/multiAdLayout/MultiAdLayout"
import { API_CONSTANTS } from "../../../../../constants/apiConstants"
import { SelectCardWrapper } from "../../../../components/SelectCardWrapper/SelectCardWrapper"
import { imageSelector } from "../../../../helpers/categoryImageHandler/categoryImageHandler"
import { increaseRouteIndex, setRouteIndex } from "../../../global/user/slice/userSlice"
import { increasePageIndex } from "../../slice/docFlowSlice"
import { getCompanyDesignationType, updateUserCompanyDesignationDetails } from "../../../../services/documentFlow/documentFlow"
import { getDefaultAppLanguage } from "../../../global/mtData/helpers/localStorage"
import { V5MultiAdLayout } from "../../../../layout/multiAdLayout/V5MultiAdLayout"
import { V5Scroll } from "../../../../components/V5Scroll/V5Scroll"
import { V5Card } from "../../../../components/VrCard/V5Card"
import { NAVIGATION_CONSTANTS } from "../../../../../constants/naigationContants"

export const SelectDesignation = () => {
   
   const userData = useSelector((state) => state.user);
   const navigate = useNavigate();
   const toast = useToast();
   const dispatch = useDispatch()
   const [allDesignations, setAllDesignations] = useState(null);
   const [selectedDesignation, setSelectedDesignation] = useState(null)
   const defaultLanguage = getDefaultAppLanguage();
   const [loading, setLoading] = useState(false)

   const [urlParam, setUrlParam] = useSearchParams()

   useEffect(() => {
     const id = sessionStorage.getItem("DEFERRED_MARKETPLACE");
     const urlAssetId = urlParam.get(NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID)
     if(id && !urlAssetId){
      setUrlParam(`${NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID}=${id}`)
     }
    }, [])

   const cardRefs = useRef([]);

   

    const getAllDesignations = async() => {
      try {
        const data= await getCompanyDesignationType();
        setAllDesignations(data.Data.CompanyDesignationTypeList);
      } catch (error) {
        
      }

    }

    const handleRefClick = (e,index) => {
        e.preventDefault()
        let value = e.target.value;
        if(!value){
            value = (cardRefs.current[index].value).toLowerCase()
           
        }
        e.stopPropagation()
        return setSelectedDesignation(parseInt(value))
    }

    const postSelectedDesignation = async() => {
      try{
        setLoading(true)
        const payload=JSON.stringify({
          UserCompanyDesignation: {
          CompanyDesignationTypeId: selectedDesignation,
        }})
        const data=await updateUserCompanyDesignationDetails(payload)

        if(data.Status === 204){
          toast({
            status: "success",
            title: data.Message.SuccessMessage,
            position: "top-right",
            isClosable: "true"
          })
          dispatch(increasePageIndex());
          // dispatch(increaseRouteIndex());
         
        }
        else{
          toast({
            status: "error",
            title: data.Message.FailMessage,
            position: "top-right",
            isClosable: "true"
          })
        }
      }
      catch(error){
        return error
      }
      finally{
        setLoading(false)
      }
      }
      

    useEffect(() => {
        (async() => {
            await getAllDesignations()
        })()
    }, [])
    return(
        
    <V5MultiAdLayout
    heading={defaultLanguage?.Select_Designation}
    subHeading={defaultLanguage?.please_select_your_designation_in_your_company}
    stepper={true}
    >
        <V5Scroll maxHeight="350px" overflowX="unset" height="">
          <Flex
            wrap="wrap"
            width="100%"
            padding="0.25em"
            justifyContent="space-evenly"
            alignItems="flex-end"
            rowGap="1em"
          >
            {allDesignations && allDesignations.length > 0 ? allDesignations.map((designation,index) => (
              <V5Card
                boxShadow="0px 0px 8px 0px #00000011"
                value={designation.CompanyDesignationTypeId}
                ref={(ref) => (cardRefs.current[index] = ref)}
                isSelected = {parseInt(designation?.CompanyDesignationTypeId) === parseInt(selectedDesignation)}
                >
               
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="space-around"
                  maxHeight={[
                    "193.89px",
                    "171.69px",
                    "171.69px",
                    "211pxpx",
                    "211px",
                  ]}
                  height={"100%"}
                  minHeight={[
                    "193.89px",
                    "171.69px",
                    "171.69px",
                    "211pxpx",
                    "211px",
                  ]}
                  maxWidth={[
                    "173.68px",
                    "153.75px",
                    "154.6px",
                    "190px",
                    "191px",
                  ]}
                  width="95%"
                  minWidth={[
                    "173.68px",
                    "153.75px",
                    "154.6px",
                    "190px",
                    "191px",
                  ]}
                  padding="0.5em 0.75em"
                  onClick={(e) => handleRefClick(e, index)}
                >

                  {/* <Image
                    src={imageSelector("business owner")}
                    alt={"business owner"}
                    height="48px"
                    width="48px"
                    onClick={(e) => handleRefClick(e, index)}
                  /> */}
                  {imageSelector(
                    designation?.CompanyDesignationTypeName,
                    (e) => {
                      handleRefClick(e,index);
                    },
                    {height:"48px",width:"48px"}
                  )}
                  <Heading width="fit-content" fontSize="xl" fontWeight="semibold"
                  onClick={(e) => handleRefClick(e, index)}
                  >
                    {designation.CompanyDesignationTypeName}
                  </Heading>
                  <Text width="fit-content" fontSize="xs" color="#2c2c2c80"
                  onClick={(e) => handleRefClick(e, index)}
                  >
                    {designation.CompanyDesignationDescription ? designation.CompanyDesignationDescription : "N.A."}
                  </Text>
                </Flex>
              </V5Card>
            )) : <></>}
          </Flex>
        </V5Scroll>
      <Box>
        <Flex
          justifyContent="center"
          alignItems="center"
          bgColor="#fff"
          h="78px"
          style={{ boxShadow: "0px -10px 15px #2c2c2c0f" }}
        >
          <Button
            type="submit"
            variant="gradient"
            fontWeight="semibold"
            width="400PX"
            margin="0 auto"
            maxW="400px"
            // form="registration-form"
            isDisabled={!selectedDesignation}
            onClick={postSelectedDesignation}
            isLoading={loading}
          >
            <p style={{fontSize:'22px'}}>{defaultLanguage?.Next}</p>
          </Button>
        </Flex>
      </Box>
    
    </V5MultiAdLayout>
    )
} 