import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../features/global/user/slice/userSlice";
import { resetDocumentFlow } from "../../features/documentFlow/slice/docFlowSlice";
import { Link } from "react-router-dom";
import { AlertDialogFooter, Box, Button, Flex, Text } from "@chakra-ui/react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import {
  sidebarData,
  businessOwnerIndividualData,
  businessOwnerCompanyData,
  equipmentOwnerIndividualData,
  equipmentOwnerCompanyData,
  maintenancePartnerCompanyData,
  maintenancePartnerIndividualData,
  OperatorPatner,
} from "../../../staticData/staticData";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { API_CONSTANTS } from "../../../constants/apiConstants";
import { openQrModal } from "../../features/dashboard/slice/dashboardSlice";
import "./NewSideMenu.css";
import { CtaModal } from "../../components/Modal/CtaModal/CtaModal";
import { ArrowBackIcon, CloseIcon } from "@chakra-ui/icons";

export const NewSideMenu = ({ defaultLanguage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expandedItems, setExpandedItems] = useState([]);

  const userData = useSelector((state) => state.user);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const onCloseLogoutModal = () => setIsLogoutModalOpen(false);
  const cancelRef = React.useRef();

  const handleItemClick = (index) => {
    if (expandedItems.includes(index)) {
      setExpandedItems(expandedItems.filter((item) => item !== index));
    } else {
      setExpandedItems([...expandedItems, index]);
    }
  };

  const handleLogoutClick = () => {
    setIsLogoutModalOpen(true);
  };

  const confirmLogout = () => {
    setIsLogoutModalOpen(false);
    dispatch(logoutUser());
    dispatch(resetDocumentFlow());
    navigate("/");
  };
  const handleOptionClick = (profileName, route) => {
    // let stringWithoutSpaces = profileName.replace(/\s/g, '');
    // console.log(stringWithoutSpaces.toLowerCase())

    if (route) {
      return navigate(route);
    }
    if (profileName === "Logout") {
      handleLogoutClick();
    } else if (
      profileName.toLowerCase() === "my leads" ||
      profileName.toLowerCase() === "my transactions" ||
      profileName.toLowerCase() === "refer a friend"
    ) {
      dispatch(openQrModal());
    }
  };

  const BusinessTypeValue = userData.BusinessTypeValue?.toLowerCase();
  const UserTypeValue = userData.UserTypeValue?.toLowerCase();

  // condition for types of business
  const dataToRender = () => {
    const dataMappings = {
      [`${API_CONSTANTS.BUSINESS_OWNER}-${API_CONSTANTS.INDIVIDUAL}`]:
        businessOwnerIndividualData,
      [`${API_CONSTANTS.BUSINESS_OWNER}-${API_CONSTANTS.COMPANY}`]:
        businessOwnerCompanyData,
      [`${API_CONSTANTS.EQUIPMENT_OWNER}-${API_CONSTANTS.INDIVIDUAL}`]:
        equipmentOwnerIndividualData,
      [`${API_CONSTANTS.EQUIPMENT_OWNER}-${API_CONSTANTS.COMPANY}`]:
        equipmentOwnerCompanyData,
      [`${API_CONSTANTS.MAINTENCE_PATNER}-${API_CONSTANTS.COMPANY}`]:
        maintenancePartnerCompanyData,
      [`${API_CONSTANTS.MAINTENCE_PATNER}-${API_CONSTANTS.INDIVIDUAL}`]:
        maintenancePartnerIndividualData,
      [`${API_CONSTANTS.OPERATOR_PATNER}-${API_CONSTANTS.INDIVIDUAL}`]:
        OperatorPatner,
    };

    const key = `${BusinessTypeValue}-${UserTypeValue}`;
    return dataMappings[key] || sidebarData;
  };

  return (
    <div className="new-sidebar-element-container">
      <ul className="new-sidebar-menu-items">
        {dataToRender().length > 0
          ? dataToRender().map((item, index) => (
              <li
                className={`new-parent ${
                  expandedItems.includes(index) ? "new-expanded" : ""
                }`}
                key={index}
              >
                <div
                  className="new-listparent"
                  onClick={() => handleItemClick(index)}
                >
                  <ReactSVG
                    src={item.iconhover}
                    alt="img"
                    className="new-list-icon"
                    style={{
                      ":hover": {
                        filter:
                          "invert(58%) sepia(48%) saturate(4908%) hue-rotate(16deg) brightness(115%) contrast(106%)",
                      },
                    }}
                  />
                  <Link
                    to={item.route || "#"}
                    className="new-icon-name"
                    onClick={() =>
                      handleOptionClick(item.profileName, item.route)
                    }
                  >
                    {item.profileName}
                  </Link>
                  {item.additionalOptions && (
                    <div className="new-expand-icon">
                      {expandedItems.includes(index) ? (
                        <ReactSVG
                          className="new-expand-icon-property"
                          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/forward.svg`}
                        />
                      ) : (
                        <ReactSVG
                          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/arrowDown.svg`}
                          alt="img"
                          className="new-expand-icon-property"
                        />
                      )}
                    </div>
                  )}
                </div>
                {expandedItems.includes(index) && item.additionalOptions && (
                  <ul className="new-additional-options">
                    {item.additionalOptions.map((option, optionIndex) => (
                      <li
                        className="new-options"
                        key={optionIndex}
                        onClick={() => {
                          handleItemClick(optionIndex);
                          handleOptionClick(option.addName, option.route);
                        }}
                      >
                        <Link to={option.route || "#"}>{option.addName}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))
          : null}
      </ul>

      <AlertDialog
        isOpen={isLogoutModalOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseLogoutModal}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent display={"flex"} justifyContent={"center"} alignItems={"center"}>

          <Box 
          position={"absolute"} 
          top={"-20px"} 
          right={"15px"} 
         >
            <Button
              onClick={onCloseLogoutModal}
              boxShadow="0px 1.5px 3px 0px #0000000F"
              borderRadius={"50%"}
              backgroundColor={"white"}
              height={"42px"}
              width={"42px"}
            >
              <CloseIcon width={"9px"} stroke={"#141619"} />
            </Button>
          </Box>
            <AlertDialogBody
              style={{ textAlign: "center" }}
              fontSize="lg"
              fontWeight="bold"
              margin={"40px 0 0 0"}
              maxW={"288px"}
              gap={"10px"}
            >
              <Flex  direction="column" gap={"10px"} w={"100%"}>
               <div className="Delete-icon">
                <ReactSVG
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Logout.svg`}
                />
              </div>
              <div>Are you sure you want to log out?</div>
              </Flex>
            </AlertDialogBody>

            <AlertDialogFooter w={"400px"}>
            <Flex
          justifyContent={"space-between"}
          width="100%"
        >
         
            <Button
              outline="none"
              boxShadow="none"
              background="#fff"
              color="#2c2c2c90"
              ref={cancelRef}
              onClick={onCloseLogoutModal}
            >
              <Flex gap={"15px"}>
                <Box>
                  {" "}
                  <ArrowBackIcon />
                </Box>
                <Text>No</Text>
              </Flex>
            </Button>

          <Button variant="gradient" 
          onClick={confirmLogout} 
          ref={cancelRef}>
          Yes
          </Button>
        </Flex>
            </AlertDialogFooter>

            {/* <Button colorScheme="orange" onClick={confirmLogout} ml={3}>
                Yes
              </Button> */}
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};
