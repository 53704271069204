import { Flex, Text, Box, Heading } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/toast";
import { SelectCardWrapper } from "../../../../components/SelectCardWrapper/SelectCardWrapper";
import { imageSelector } from "../../../../helpers/categoryImageHandler/categoryImageHandler";
import {
  handleSuccessModal,
  selectedAccount,
  handleOpenContinueModal,
  clearLoginData,
} from "../../slice/loginSlice";
import { useRef } from "react";
import { V5Card } from "../../../../components/VrCard/V5Card";
import { subString } from "../../../../helpers/dataConversion/substring";
import { CustomModal } from "../../../../components/CustomModal/CustomModal";

export const ChooseAccountModal = () => {
  const loginData = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const toast = useToast();
  const cardRefs = useRef([]);

  const cardClickHandler = (e) => {
    const selectCard = loginData.accountList.find(
      (account) => account.BusinessTypeValue === e.target.value
    );
    dispatch(selectedAccount(selectCard));
  };
  const handleRefClick = (e, index) => {
    e.preventDefault();
    const selectedBusinessType = loginData.accountList.find(
      (item) =>
        item.BusinessTypeValue.toLowerCase() ===
        cardRefs.current[index].value.toLowerCase()
    );
    dispatch(selectedAccount(selectedBusinessType));
    e.stopPropagation();
  };

  return (
    <CustomModal isOpen={loginData.isContinueModalOpen} hasCloseButton={true} onCloseButtonClick={()=>{
      dispatch(handleOpenContinueModal(false));
    }}  w="800px" maxW={"800px"}>
      <CustomModal.Header textAlign={"center"}>
        <Text fontWeight="bold" fontSize="27px">
          Continue login as:
        </Text>
      </CustomModal.Header>
      <CustomModal.Body maxW={"800px"} w={"100%"}>
        <Flex
          margin="0 auto"
          gap="1em"
          overflowX="scroll"
          w="100%"
          justifyContent="center"
          padding="0.5em 0"
        >
          {loginData.accountList?.map((accountList, index) => {
            const { BusinessTypeValue, UserCredentialId } = accountList;
            return (
              <V5Card
                boxShadow="0px 0px 8px 0px #00000011"
                value={BusinessTypeValue}
                key={UserCredentialId}
                // ref={(ref) => (cardRefs.current[index] = ref)}
                // isSelected={
                //   BusinessTypeValue ===
                //   registrationData.selectedBusinessType?.BusinessTypeValue
                // }
                ref={(ref) => (cardRefs.current[index] = ref)}
                isSelected={
                  loginData?.selectedAccount?.BusinessTypeValue ===
                  BusinessTypeValue
                    ? true
                    : false
                }
                onClick={(e) => handleRefClick(e, index)}
              >
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  gap={"16px"}
                  maxHeight={[
                    "193.89px",
                    "171.69px",
                    "171.69px",
                    "211px",
                    "211px",
                  ]}
                  height={"100%"}
                  minHeight={[
                    "193.89px",
                    "171.69px",
                    "171.69px",
                    "211px",
                    "211px",
                  ]}
                  maxWidth={[
                    "173.68px",
                    "153.75px",
                    "154.6px",
                    "190px",
                    "191px",
                  ]}
                  width="95%"
                  minWidth={[
                    "173.68px",
                    "153.75px",
                    "154.6px",
                    "190px",
                    "191px",
                  ]}
                  padding="0.5em 0.75em"
                  onClick={(e) => handleRefClick(e, index)}
                >
                  <Box maxW="70px" maxH="70px" onClick={(e) => (handleRefClick(e, index))}>
                    {imageSelector(
                      BusinessTypeValue,
                      (e) => {
                        handleRefClick(e, index);
                      },
                      { height: "48px", width: "48px" }
                    )}
                  </Box>
                  <Heading
                    width="fit-content"
                    fontSize="xl"
                    fontWeight="semibold"
                    onClick={(e) => handleRefClick(e, index)}
                  >
                    {subString(25, BusinessTypeValue)}
                  </Heading>
                  {/* <Text
                        width="fit-content"
                        fontSize="xs"
                        // onClick={(e) => handleRefClick(e, index)}
                      >
                        {subString(30, BusinessTypeDescription)}
                      </Text> */}
                </Flex>
              </V5Card>
            );
          })}
        </Flex>
      </CustomModal.Body>

      <CustomModal.Footer boxShadow="0px -10px 15px 0px #2C2C2C0F" 
      justifyContent={"center"}
      maxH={"60px"}>
        <Button
        minW={"192px"}
          variant="gradient"
          onClick={() => {
            if (loginData?.selectedAccount) {
              if (loginData?.selectedAccount.IsRejected) {
                toast({
                  title: "Your account is rejected.",
                  isClosable: true,
                  position: "top-right",
                  status: "error",
                });
                return dispatch(clearLoginData());
              } else {
                dispatch(handleOpenContinueModal(false));
                dispatch(handleSuccessModal(true));
              }
            }
          }}
        >
          Continue
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
};
