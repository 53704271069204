import { useEffect } from "react";
import { NewSideMenuLayout } from "../../../../layout/NewSideMenuLayout/NewSideMenuLayout";

import { useDispatch, useSelector } from "react-redux";
import { getInitialPostedJobsThunk } from "../../thunks/getInitialPostedJobsThunk";
import { Card } from "../../../../components/Card/Card";
import { openQrModal } from "../../../dashboard/slice/dashboardSlice";
import { Flex, Text } from "@chakra-ui/layout";
import { getInitialJobsPostedForCompanyThunk } from "../../thunks/getInitialJobsPostedForCompanyThunk";
import { subString } from "../../../../helpers/dataConversion/substring";
import { v4 as uuid } from "uuid";
import { V5SidebarLayout } from "../../../../layout/V5SidebarLayout/V5SidebarLayout";
import { MarketplaceHoc } from "../../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { useNavigate } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { convertToBase64 } from "../../../../../utils/formatConverter/convertToBase64";

export const MyJobs = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.jobsPosted);
  const { UserTypeValue } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const cardsRenderFunction = (array = []) => {
    const cardJsx =
      array && array.length > 0 ? (
        array.map((card) => {
          const {
            TotalApplicantsCount,
            JobStatusValue,
            JobId,
            JobPostedDateTime,
            JobTitle,
            JobTypeId,
            JobExpiryTime,
            EquipmentTypeName,
            EquipmentMakeName,
            EquipmentModelName,
            WorkLocation,
          } = card;
          return (
            <div
              style={{
                border: "1px solid #E3E5E8",
                minWidth: "559px",
                maxWidth: "559px",
                minHeight: "190px",
                maxHeight:"190px",
                position: "relative",
                padding: "26px 20px 20px 20px",
                marginTop:"10px",
                cursor : "pointer"
              }}
              onClick={() =>  
                JobExpiryTime===0 ? null :
                navigate(`/job-cycle?orderId=${JobId}&jobType=${JobTypeId}&partnerType=new`)}
            >
              <div
                style={{
                  position: "absolute",
                  background: "#FFD48A80",
                  color: "#141619",
                  fontWeight: 600,
                  fontSize: "14px",
                  padding: "3px 8px",
                  width: "156px",
                  borderRadius: "13px",
                  height: "24px",
                  top: "-10px",
                  left: "10px",
                }}
              >
                {TotalApplicantsCount ? TotalApplicantsCount : 0} New Patners
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <p
                      style={{
                        color: "#002961",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      OrderId : {JobId} 
                   
                    <span
                      style={{
                        color: "#767676",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                     {" | "} Posted On: {JobPostedDateTime ? `${JobPostedDateTime.split(",")[0]}, ${JobPostedDateTime.split(",")[1]}` : ""}
                    </span>
                    </p>
                  </div>
                  <div style={{backgroundColor: JobStatusValue === "Cancelled" ? "#D6000080" : JobExpiryTime===0 ? "#767676": "#319F0080", color : "#FFF", padding : "1px 10px", borderRadius: "20px", fontSize:"14px"}}>
                  {JobStatusValue === "Cancelled" ? JobStatusValue : JobExpiryTime===0 ? "Expired": `Expires in ${JobExpiryTime} Days` }
                  </div>
                </div>
                <div style={{display:"flex", flexDirection:"column"}}>
                  <p style={{fontSize:"18px", fontWeight:700}}>{subString(27, JobTitle)}</p>
                  <p style={{fontSize:"16px", fontWeight:600}}>{subString(
                    27,
                    `${EquipmentTypeName} ${EquipmentMakeName} ${EquipmentModelName}`
                  )}</p>
                  <p style={{color: "#767676", fontSize:"16px"}}>{subString(70, WorkLocation)}</p>
                </div>
                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                  { <p style={{color:JobExpiryTime === 0 ? "#767676" :"#002961", fontSize:"16px", fontWeight:600}}>Total {TotalApplicantsCount} Partner</p>}
                  {JobStatusValue!=="Cancelled" && <Button variant="outline" border="1px solid" borderColor={JobExpiryTime === 0 ? "#767676" : "#002961"} color ={JobExpiryTime === 0 ? "#767676" : "#002961"} fontWeight ={600} 
                  onClick={(e) => {
                    e.stopPropagation()
                    if (JobExpiryTime === 0) {
                      return; 
                    }
                    navigate(
                      `/my-jobs/job-description/${convertToBase64(
                        JobId
                      )}`)
                    }}>
                     See Job Details
                  </Button>}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <p>No Jobs Available</p>
      );
    return cardJsx;
  };

  useEffect(() => {
    if (UserTypeValue === "Company") {
      dispatch(getInitialJobsPostedForCompanyThunk());
    } else {
      dispatch(getInitialPostedJobsThunk());
    }
  }, []);
  return (
    <V5SidebarLayout>
      <div
        style={{
          margin: "0px 52px",
          backgroundColor: "#92929204",
          paddingTop: "1em",
        }}
      >
        <div>
          <h1 style={{ fontSize: "32px", fontWeight: 700 }}>My Jobs</h1>
        </div>

        <MarketplaceHoc
        marketplaceType="myJobs"
          dataArray={data}
          cardsRenderFunction={cardsRenderFunction}
        />
      </div>
    </V5SidebarLayout>
  );
};
