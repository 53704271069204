import React from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "@chakra-ui/icons";
import { closeConnectDealershipModal } from "../../features/dealershipMarketplace/slice/dealershipMarketplaceSlice";
import { CustomGeneralModal } from "../CustomGeneralModal/CustomGeneralModal";

export const ConnectDealershipModal = ({ onAccept = () => {} }) => {
  const dispatch = useDispatch();
  const dealershipMarketplaceData = useSelector(
    (state) => state.dealershipMarketplace
  );
  const { accountDetails, UserTypeValue } = useSelector(
    (state) => state.user
  );
  const toast = useToast();

  return (
    <CustomGeneralModal
    isOpen={dealershipMarketplaceData.isOpenConnectDealershipModal}
    iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/connectIcon.svg`}
    hasCloseButton={true}
    onCloseButtonClick={() => {
      dispatch(closeConnectDealershipModal());
    }}
    heading={" Contact Dealership"}
    subHeading={"To view details, please contact this dealership"}
    primaryActionText={"Contact"}
    primaryAction={() => {
      if(UserTypeValue?.toLowerCase() === "company" && !accountDetails?.CompanyDetails?.TaxNumber)
      toast({
        status: "error",
        description: "Please update GST Number to Contact Owner.",
        position: "top-right",
        isClosable: true,
      }) 
      else{
      dispatch(closeConnectDealershipModal());
      onAccept();
      }
    }}
    secondaryActionText={"No Thanks"}
    secondaryAction={() => {
      dispatch(closeConnectDealershipModal());
    }}
    isArrow={false}
    footerShadow={true}
    />
  );
};
