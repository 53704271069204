import jobPostedSvg from "../../../../assets/webapp/leads/job-posted.svg";
import { useNavigate } from "react-router-dom";
import {
  EquipmentList,
  otherList,
  workforce,
} from "../../../../staticData/staticData";
import { NewSideMenuLayout } from "../../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import { v4 as uuid } from "uuid";
import { MyLeadsDetailCard } from "../../../components/MyLeads/MyLeadsDetailCard";
import { useEffect, useState } from "react";
import { getMaterialType } from "../../../services/myLeads/myLeads";
import { useDispatch } from "react-redux";
import { QrPopUp } from "../../../components/ScanQrCodeModal/QrPopUp";
import { openQrModal } from "../../dashboard/slice/dashboardSlice";
import { setMaterialTypeData } from "../Slice/myLeadsSlice";
import "./MyLeads.css";
import { V5SidebarLayout } from "../../../layout/V5SidebarLayout/V5SidebarLayout";

export const MyLeads = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [materialType, setMaterialType] = useState();

  useEffect(() => {
    storeDetails();
  }, []);

  const storeDetails = async () => {
    const response = await getMaterialType();
    if (response) {
      setMaterialType(response);
      dispatch(setMaterialTypeData(response));
    }
  };

  return (
    <V5SidebarLayout>
      <QrPopUp />
      <Flex
        flexDirection={"column"}
        margin={"0px 52px"}
        gap={"1em"}
        overflow={"scroll"}
      >
        <div
          style={{
            backgroundColor: "#92929204",
            paddingTop: "1em",
          }}
        >
          <div>
            <h1 className="my-leads-headline">Lead Details</h1>
            <p className="my-leads-subheading">
              Keep Track of All Your Orders Here.
            </p>
          </div>
        </div>

        {/* WorkForce */}
        <div className="my-leads-element">
          <Flex alignItems="center">
            <Flex w="100%" alignItems="center" gap="0.5em">
              <Box minWidth="max-content">
                <Text color="#011f6b" fontWeight="semibold" fontSize="2xl">
                  Workforce
                </Text>
              </Box>
              <Flex alignItems="center" w="100%">
                <Divider borderColor="#011f6b" borderWidth="0.1em" />
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="2em"
            flexDirection="row"
            overflowX="scroll"
            padding="1em 0"
          >
            {workforce ? (
              workforce.map((item) => (
                <MyLeadsDetailCard
                  key={uuid()}
                  imageUrl={jobPostedSvg}
                  routePath={item?.route}
                  onSubmitButtonClick={() => {
                    item.name.toLowerCase() === "jobs posted"
                      ? navigate("/my-jobs")
                      : navigate(
                          `/owners-leads?marketplace-type=${item.route}&request-type=sent`
                        );
                  }}
                  // onSubmitButtonClick={() => (navigate(item?.route))}
                  description={item.name}
                />
              ))
            ) : (
              <p>No data available.</p>
            )}
          </Flex>
        </div>

        {/* Equipment */}
        <div className="my-leads-element">
          <Flex alignItems="center">
            <Flex w="100%" alignItems="center" gap="0.5em">
              <Box minWidth="max-content">
                <Text color="#011f6b" fontWeight="semibold" fontSize="2xl">
                  Equipment
                </Text>
              </Box>
              <Flex alignItems="center" w="100%">
                <Divider borderColor="#011f6b" borderWidth="0.1em" />
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="2em"
            flexDirection="row"
            overflowX="scroll"
            padding="1em 0"
          >
            {EquipmentList ? (
              EquipmentList.map((item) => (
                <>
                  <MyLeadsDetailCard
                    key={uuid()}
                    imageUrl={jobPostedSvg}
                    onSubmitButtonClick={() =>
                      navigate(
                        `/leads?marketplace-type=${item.route}&request-type=sent`
                      )
                    }
                    description={item.name}
                  />
                </>
              ))
            ) : (
              <p>No data available.</p>
            )}
          </Flex>
        </div>

        {/* Others */}
        <div className="my-leads-element">
          <Flex alignItems="center">
            <Flex w="100%" alignItems="center" gap="0.5em">
              <Box minWidth="max-content">
                <Text color="#011f6b" fontWeight="semibold" fontSize="2xl">
                  Others
                </Text>
              </Box>
              <Flex alignItems="center" w="100%">
                <Divider borderColor="#011f6b" borderWidth="0.1em" />
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="2em"
            flexDirection="row"
            overflowX="scroll"
            padding="1em 0"
          >
            {otherList ? (
              otherList.map((item) => (
                <MyLeadsDetailCard
                  key={uuid()}
                  imageUrl={jobPostedSvg}
                  routePath={item?.route}
                  onSubmitButtonClick={() =>
                    navigate(
                      `/industry-requirement-leads?marketplace-type=${item.route}&request-type=sent`
                    )
                  }
                  description={item.name}
                />
              ))
            ) : (
              <p>No data available.</p>
            )}
          </Flex>
        </div>

        {/* Materials */}
        <div className="my-leads-element">
          <Flex alignItems="center">
            <Flex w="100%" alignItems="center" gap="0.5em">
              <Box minWidth="max-content">
                <Text color="#011f6b" fontWeight="semibold" fontSize="2xl">
                  Materials
                </Text>
              </Box>
              <Flex alignItems="center" w="100%">
                <Divider borderColor="#011f6b" borderWidth="0.1em" />
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="2em"
            flexDirection="row"
            overflowX="scroll"
            padding="1em 0"
          >
            {materialType ? (
              materialType.map((item) => (
                <MyLeadsDetailCard
                  key={uuid()}
                  imageUrl={`${process.env.REACT_APP_CDN_PVT_URL}MobileAppAssets/Material/${item.MaterialTypeIconFileRef}`}
                  routePath={item?.route}
                  onSubmitButtonClick={() =>
                    navigate(
                      `/leads?marketplace-type=${item.MaterialTypeValue}&request-type=sent`
                    )
                  }
                  description={item.MaterialTypeValue}
                />
              ))
            ) : (
              <p>No data available.</p>
            )}
          </Flex>
        </div>
      </Flex>
    </V5SidebarLayout>
  );
};
