import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import defaultIcon from "../../../assets/webapp/marketplace/defaultAssetImage.png";
import { subString } from "../../helpers/dataConversion/substring";
import { v4 as uuid } from "uuid";

import "./MaterialStoreCard.css";
import { Tooltip } from "@chakra-ui/react";

export const MaterialStoreCard = ({
  imageUrl = [],
  IsVerified,
  IsOriginal,
  ProductNumber,
  ProductName,
  EquipmentTypeValue,
  OEMName,
  ModelName,
  Location,
  MaxPriceRange,
  MinPriceRange,
  MaterialUniqueId,
  onCardClick,
  onDelete,
  onEdit,
  onAddMarketPlace,
  IsOnMarketplace,
}) => {


  const latestImageObjects = {};
  imageUrl.forEach(obj => latestImageObjects[obj.ImageTypeId] = obj);
  const latestImageArray = Object.values(latestImageObjects).filter(obj => obj !== null);

  return (
    <>
      <div className="materialStorecard-container">
        <div className="materialStorecard-container__additional-info">
        {subString(
            35,
            ` PNo-${[
              ProductNumber ? ProductNumber : "NA",
              EquipmentTypeValue ?EquipmentTypeValue :"NA",
              OEMName? OEMName:"NA",
              ModelName? ModelName:"NA",
            ].join(" • ")}`
          )}
        </div>

        <div className="materialStorecard-container__overlay">
          <div className="materialStorecard-container__overlay-container">
            <p
              style={{
                background: IsVerified ? "#319F0060" : "#D6000060",
              }}
              className="materialStorecard-container__overlay-container-badge"
            >
              {IsVerified ? "Verified" : "Not Verified"}
            </p>

            {IsOriginal ? (
              <p className="materialStorecard-container__overlay-container-badge original-badge">
                {IsOriginal ? "Original" : ""}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="materialStorecard-container__img-container">
          <Swiper
                  pagination={{ clickable: true }}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                  style={{ height: "100%", width: "100%" }}
                >
                  {latestImageArray && latestImageArray.length > 0 ? (
                    latestImageArray.map((imageObj, index) => (
                      <div key={uuid()}>
                        {imageObj.ImageFileRef &&
                        imageObj.ImageId &&
                        imageObj.ImageTypeId ? (
                          <SwiperSlide key={uuid()}>
                            <img
                              src={`${process.env.REACT_APP_CDN_PVT_URL}${imageObj.ImageFileRef}`}
                              alt={`Image ${index}`}
                            />
                          </SwiperSlide>
                        ) : null}
                      </div>
                    ))
                  ) : (
                    <SwiperSlide>
                      <img src={defaultIcon} alt={`default`} />
                    </SwiperSlide>
                  )}
                </Swiper>
          </div>
        </div>

        <div
          className="materialStorecard-container__basic-info"
          onClick={onCardClick}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div style={{ display: "flex" }}>
                <Tooltip label={ProductName?.length>25 ?ProductName :null}>
                <p style={{ fontSize: "22px", fontWeight: "700" }}>
                  {subString(25, ProductName)}
                </p>
                </Tooltip>
              </div>
              <p style={{ fontWeight: "700", fontSize: "18px" }}>
                <span>{`₹${MinPriceRange}`}/-</span> to{" "}
                <span>{`₹${MaxPriceRange}`}/-</span>{" "}
              </p>
            </div>

            <p style={{ fontSize: "18px", fontWeight: "400" }}>
              {subString(35, ` ${[Location]}`)}
            </p>
          </div>
        </div>

        <div className="materialStorecard-container__action-section">
          <div
            style={{ gap: "10px", display: "flex", justifyContent: "flex-end" }}
          >
            <button
              className="materialStorecard-container__action-section__delete-button"
              onClick={onDelete}
            >
              <img
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/delete.svg`}
              />
            </button>
            <button
              className="materialStorecard-container__action-section__edit-button"
              onClick={onEdit}
            >
              <img
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/edit.svg`}
              />
            </button>
          </div>

          <button
            className="materialStorecard-container__action-section__add-to-marketplace-button"
            onClick={onAddMarketPlace}
            style={{
              color: IsOnMarketplace ? "#002961" : "",
              border: IsOnMarketplace ? "1px solid #002961" : "",
            }}
          >
            {IsOnMarketplace === 0
              ? "Add to Marketplace"
              : "Remove from  Marketplace"}
          </button>
        </div>
      </div>
    </>
  );
};
