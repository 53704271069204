import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMyOrdersRequestReceivedList } from "../../../services/myLeads/myLeads";

export const getRemReceivedDataThunk = createAsyncThunk(
  "myLeads/getRemReceivedData",
  async (_, { getState }) => {
    try {
      let id = "Rental Equipment";
      const data = await getMyOrdersRequestReceivedList(id);
      return data;
    } catch (error) {}
  }
);
