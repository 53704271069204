import React, { useState, useEffect } from 'react';
import './css/V5AddCarousel.css';

const V5AdvertisementCarousel = ({ maxWidth, maxHeight, dataArray, speed }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const transitionSpeed = speed ? speed : 5000 ;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % dataArray.length);
    }, transitionSpeed);

    return () => clearInterval(interval);
  }, [dataArray.length, transitionSpeed]); 

  return (
    <div
      className="v5-carousel-main-container"
      style={{ maxWidth, maxHeight, height: '100vh', width: '100vw' }}
    >
      <div className="v5-carousel-container">
        {dataArray && dataArray.length > 0 ? (
          dataArray.map((image, index) => (

            <div
              key={index}
              className={`v5-carousel-image-container ${index === currentIndex ? 'active' : ''}`}
            >
               
              <img
                src={image.url}
                alt={`Carousel item ${index}`}
                className={`v5-carousel-image`}
              />
            </div>
          ))
        ) : (
          <p>No Data Available</p>
        )}
      </div>
    </div>
  );
};

export default V5AdvertisementCarousel;
