import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import "./ChooseMaterialStorePopup.css";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "@chakra-ui/icons";
import { SelectCardWrapper } from "../SelectCardWrapper/SelectCardWrapper";
import { closeMaterialTypeModal } from "../../features/materials/slice/materialsSlice";
import {
  createMaterialStore,
  getMaterialTypes,
} from "../../services/materials/materials";
import { getMaterialStoreThunk } from "../../features/materials/thunks/getMaterialStoreThunk";
import { CustomModal } from "../CustomModal/CustomModal";
import { CustomGeneralModal } from "../CustomGeneralModal/CustomGeneralModal";

export const ChooseMaterialStorePopup = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const materialsData = useSelector((state) => state.materials);
  const [materialTypes, setMaterialTypes] = useState(null);
  const [selectedmaterialType, setSelectedmaterialType] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await getMaterialTypes();
      setMaterialTypes(response);
    })();
  }, []);

  const onSubmit = async (value) => {
    const data = await createMaterialStore(value);
    if (data?.Status === 201) {
      toast({
        title: "",
        description: `${data?.Message.SuccessMessage}`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      dispatch(getMaterialStoreThunk());
    } else {
      toast({
        title: "",
        description: `${data?.Message.SuccessMessage}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <CustomGeneralModal
    isOpen={materialsData.isChooseMaterialtypeModelOpen}
    iconUrl={`${process.env.REACT_APP_CDN_PVT_URL}MobileAppAssets/bi_materials.png`}
    hasCloseButton={true}
    onCloseButtonClick={() => {
        dispatch(closeMaterialTypeModal());
        setSelectedmaterialType(null);
      }}
      heading= {"Materials Store"}
      subHeading={"Choose your Material Type"}
      primaryActionText={"Submit"}
      primaryButtonIsDisable={selectedmaterialType === null}
      buttonMinWidth={"192px"}
      primaryAction={() => {
                    dispatch(closeMaterialTypeModal());
                    onSubmit(selectedmaterialType);
                    setSelectedmaterialType(null);
                  }}
    >
       <Flex
            direction="column"
            gap="0.5em"
            overflowY={"scroll"}
           
          >
            <Flex 
            height={"210px"}
            alignItems={"center"}
            flexDirection={"column"}
            margin={"0 50px"}
            >
            {materialTypes
              ? materialTypes.map((materialType) => (
                  <SelectCardWrapper
                  backgroundcolor="white"
                  border="1px solid #E3E5E8"
                    width="338px"
                    height="60px"
                    key={materialType.MaterialTypeId}
                    value={materialType.EquipAvailabilityValue}
                    isSelected={
                      selectedmaterialType === materialType?.MaterialTypeId
                    }
                    onClick={() =>
                      setSelectedmaterialType(materialType?.MaterialTypeId)
                    }
                    icon={
                      <img
                        className="choose-material-type-popup__selector-image "
                        src={`${process.env.REACT_APP_CDN_PVT_URL}MobileAppAssets/Material/${materialType.MaterialTypeIconFileRef}`}
                        alt="material type icon"
                      />
                    }
                  >
                    <Heading
                      width="fit-content"
                      fontSize="20px"
                      fontWeight="600"
                      marginLeft={"3em"}
                    >
                      {materialType.MaterialTypeValue}
                    </Heading>
                  </SelectCardWrapper>
                ))
              : null}
              </Flex>
          </Flex>

    </CustomGeneralModal>
  );
};
