import { useEffect, useState } from "react";
import { API_CONSTANTS } from "../../../constants/apiConstants";
import fetchHandler from "../../../webapp/services/fetchHandler";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BrandLoader } from "../../../webapp/components/loaders/brandLoader/BrandLoader";
import { isError } from "lodash";
import { useSelector } from "react-redux";
import {
  primaryKeyDecryption,
  primaryKeyEncryption,
} from "../../../webapp/helpers/dataConversion/primaryKeyEncryption";
import { decryptShareData } from "../../../webapp/services/share/share";
import { convertFromBase64, convertToBase64 } from "../../../utils/formatConverter/convertToBase64";

export const ShareLoader = () => {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const [tokenDetails, setTokenDetails] = useState(null);
  const [error, setError] = useState(false);
  const { token, isAuthenticated,accountDetails  } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const isUserLoggedIn = () => token && isAuthenticated;
  const taxNumber = accountDetails?.CompanyDetails?.TaxNumber;
  const decryptShareToken = async (id,category,subCategory) => {
    try {
      // const data  = await  decryptShareData({shareData: assetId.split(" ").join("+")});
      const data= {
        marketplaceCategory:category,
        marketplaceSubCategory:subCategory,
        assetId:convertFromBase64(id),
      }
      setTokenDetails(data);
      return data;
    } catch (error) {
      console.log(error)
      setError(true);
    }
  };

  const getOperatorDetails = async (id) => {
    try {
      const { data } = await fetchHandler(
        `/share/asset?category=partners&id=${id}`,
        "",
        "",
        "",
        API_CONSTANTS.HTTP_GET,
        { isAuthRequired: false }
      );
      // return data.Data
      if (!data.Data || Object.keys(data.Data) < 1) {
        return setError(true);
      }
      isUserLoggedIn()
        ? navigate(`/near-me/MarketPlaceOP/Card/${id}`)
        : navigate(`/deferred/marketplace/partners/operator`);
    } catch (error) {
      setError(true);
      console.log(error);
    }
  };

  useEffect(() => {
    const id = urlSearchParams.get("id");
    const category = urlSearchParams.get("category");
    const subCategory = urlSearchParams.get("subCategory");

    (async()=>{
      if (id && category && subCategory) {
        const assetDetails = await decryptShareToken(id,category,subCategory);
      }
    })()
  }, []);

  useEffect(() => {
    (async()=>{
      if (tokenDetails) {
        redirectToMarketplace(tokenDetails);
      }
    })()


  }, [tokenDetails]);

  const redirectToMarketplace = async (data) => {
switch (data.marketplaceCategory) {
  case "workforce":
    switch (data.marketplaceSubCategory) {
      case "op":
        isUserLoggedIn()
        ? navigate(`/near-me/MarketPlaceOP/Card/${data?.assetId}`)
        : navigate(`/deferred/marketplace/partners/Cards/nearmeoperator/${primaryKeyEncryption(data?.assetId)}`);
        
        break;
      case "mp":
        isUserLoggedIn()
        ? navigate(`/near-me/MarketPlaceMP/Card/${data?.assetId}`)
        : navigate(`/deferred/marketplace/partners/Cards/nearmemaintanence/${primaryKeyEncryption(data?.assetId)}`);
        break;
      default:
        navigate('/default')
        break;
    }
    break;

  case "equipment":
    switch (data.marketplaceSubCategory) {
      case "used":
        isUserLoggedIn()
        ? navigate(`/marketplace/MarketPlaceUEM/Cards/${data?.assetId}`)
        : navigate(`/deferred/marketplace/Cards/equipment/rental/${data?.assetId}`);
        break;
      case "rental":
        isUserLoggedIn()
        ? navigate(`/marketplace/MarketPlaceUEM/Cards/${data?.assetId}`)
        : navigate(`/deferred/marketplace/Cards/equipment/rental/${data?.assetId}`);
        break;
    
      default:
        navigate("/default")
        break;
    }
    break;

  case "dealership":
        isUserLoggedIn()?
        navigate(
          `/marketplace/DealershipMarketplaceHome/dealership-detail/${convertToBase64(
            data?.marketplaceSubCategory
          )}/ ${convertToBase64(data?.assetId)}
          /${convertToBase64(false)}/${convertToBase64(taxNumber)}`
        ) :
        navigate(
          `/deferred/marketplace/DealershipMarketplace/dealership-detail/${convertToBase64(
            data?.marketplaceSubCategory
          )}/ ${convertToBase64(data?.assetId)}`
        ) 
        break;

  case "material":
    const material = 'material' ;
    isUserLoggedIn()
    ? navigate(`/marketplace/MaterialMarketplace/material-details/${convertToBase64(data?.marketplaceSubCategory)}/${convertToBase64(data?.assetId)}`)
    : navigate(`/deferred/marketplace/materials-marketplace/material-detail/${material}/${primaryKeyEncryption(data?.marketplaceSubCategory)}/${primaryKeyEncryption(data?.assetId)}`);
    break;

  case "job":
    switch (data.marketplaceSubCategory) {
      case "op":
        isUserLoggedIn()
        ? navigate(`/jobs-near-me/AllJobsNearMeMP/Card/${data?.assetId}`)
        : navigate(`/deferred/marketplace/Job/operator/${data?.assetId}`);
        break;
      case "mp":
        isUserLoggedIn()
        ? navigate(`/jobs-near-me/AllJobsNearMeMP/Card/${data?.assetId}`)
        : navigate(`/deferred/marketplace/Job/maintenance/${data?.assetId}`);
        break;
      default:
      navigate('/default');
        break;
    }
    break;

  default:
    navigate('/default')
    break;
}
  }

  return <BrandLoader />;
};
