import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOwnerMarketplaceReceivedList } from "../../../services/ownerMarketplace/ownerMarketplace";


export const getOwnersReceivedCardsThunk = createAsyncThunk('/getReceived' , async () =>{
    try{
        const data = await getOwnerMarketplaceReceivedList();
        return data ;
    }catch (error){

    }
})