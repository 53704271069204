import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useQuery, useQueryClient } from "react-query";
import { useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import * as Yup from "yup";
import {
  getLicenseTypeId,
  countrylist,
  StateCountryId,
  city,
  getYearofExpirence,
  AddorUpdateUserEquipment,
  getUserEquipmentLicenceAddress,
} from "../../../../../../../../../../ApiEquip9/CommonApi";
import { expirenceMonths } from "../../../../../../../../../../staticData/staticData";
import "./AdditionalDetails.css";
// import { Token } from "@mui/icons-material";
export const AdditionalDetails = ({ onClose }) => {
  const userData = useSelector((state) => state.user);
  const [imagePreview, setImagePreview] = useState(null);
  // const [fileType, setFileType] = useState("");
  // const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [countryListData, setCountryListData] = useState(null);
  const [stateListData, setStateListData] = useState(null);
  const [cityListData, setCityListData] = useState(null);
  const [userDetails, setuserDetails] = useState(null);
  const [fromUserDetails, setFromUserDetails] = useState(null);
  const toast = useToast();
  const queryClient = useQueryClient();
  const initialValues = {
    LicenseTypeId: "",
    LicenseNumber: "",
    UserLicenseImageFront: null,
    // Remark:''
  };

  const validationSchema = Yup.object().shape({
    LicenseTypeId: Yup.string().required("License Type is required"),
    LicenseNumber: Yup.string()
      .required("License is required")
      .min(2, "minimum 2 characters required")
      .max(16, "charcter are excedding limit")
      .matches(/^[a-zA-Z0-9 ]+$/, "Special characters not allowed"),
    UserLicenseImageFront: Yup.mixed().required("File not selected"),
  });

  const { data: getExpirenceofExpirece } = useQuery("getYearExpirence", () =>
    getYearofExpirence()
  );
  const getYearExpirence = getExpirenceofExpirece?.data?.Data.YearOfExperience;

  /*********************************************************** */
  // Country
  const { data: fetchedCountryList } = useQuery("countryList", () =>
    countrylist(userData.token)
  );
  useEffect(() => {
    setCountryListData(fetchedCountryList?.data?.Data?.CountriesList);
  }, [fetchedCountryList]);

  const { data: fetchedStateList } = useQuery(
    ["getStateList", selectedCountry],
    () => StateCountryId(userData.token, selectedCountry),
    {
      enabled: !!selectedCountry,
    }
  );
  useEffect(() => {
    setStateListData(fetchedStateList?.data?.Data?.StatesList);
    setCityListData(null);
  }, [fetchedStateList, selectedCountry]);

  const { data: fetchedCityList } = useQuery(
    ["getCitylist", selectedState],
    () => city(userData.token, selectedState),
    {
      enabled: !!selectedState,
    }
  );
  useEffect(() => {
    setCityListData(fetchedCityList?.data?.Data?.CitiesList);
  }, [fetchedCityList]);

  useEffect(() => {
    if (userDetails) {
      setSelectedCountry(
        userDetails.CountryId ? userDetails.CountryId?.toString() : ""
      );
      setSelectedState(
        userDetails.StateId ? userDetails.StateId?.toString() : ""
      );
      setSelectedCity(userDetails.CityId ? userDetails.CityId?.toString() : "");
    }
  }, [userDetails]);

  const handleSubmit = async (values, { resetForm }) => {
    // refetch();
    const Payload = {
      ExperienceYear: values.ExperienceInYear
        ? values.ExperienceInYear
        : values.ExperienceYear,
      ExperienceMonth: values
        ? values?.ExperienceMonth
        : values.ExperienceInMonth,
      CountryId: selectedCountry,
      StateId: selectedState,
      CityId: selectedCity,
    };
    try {
      const response = await AddorUpdateUserEquipment(userData.token, Payload);
      toast({
        title: "",
        description: `${response?.data?.Message?.SuccessMessage}`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      queryClient.resetQueries("getAdditonlDetails");
      //   triggerRefresh();
      onClose();
      resetForm();
      setImagePreview(null);
      return response;
    } catch (err) {
      toast({
        title: "",
        description: `Please Provide valid input`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      resetForm();
      return null;
    }
  };

  return (
    <div className="popup-card-overlay">
      <Formik
        initialValues={initialValues}
        onSubmit={(values, resetFrom) => {
          values = { ...values, ...fromUserDetails };
          handleSubmit(values, resetFrom);
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form className="popup">
            <button className="popup-card-close" onClick={onClose}>
              <ReactSVG
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Cross.svg`}
              />
            </button>
            <p className="hero-heading-text">Additional License Details</p>
            <p className="hero-sub-heading">
              Provide Additional License Details
            </p>
            <br />
            <div className="old-license-detail">
              <p className="text-license">HOW OLD IS YOUR LICENSE</p>
              <hr className="seprator"></hr>
            </div>
            <br />

            <div className="popup-card-inner">
              <div className="oldLicense">
                <div>
                  <label htmlFor="selectField">
                    In Years<span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    as="select"
                    name="ExperienceInYear"
                    // value={userDetails ? fromUserDetails?.ExperienceYear : ""}
                    onChange={(e) => {
                      setFromUserDetails((prevUserDetails) => {
                        return {
                          ...prevUserDetails,
                          ExperienceYear: e.target.value,
                        };
                      });
                    }}
                  >
                    <option value="">Select an option</option>
                    {getYearExpirence?.map((item, index) => (
                      <option key={index} value={item.YearOfExperienceId}>
                        {item.YearOfExperienceValue}
                      </option>
                    ))}
                  </Field>
                </div>
                <div>
                  <label htmlFor="selectField">
                    In Months<span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    as="select"
                    name="ExperienceInMonth"
                    // value={
                    //   fromUserDetails ? fromUserDetails?.ExperienceMonth : ""
                    // }
                    onChange={(e) => {
                      setFromUserDetails((prevUserDetails) => {
                        return {
                          ...prevUserDetails,
                          ExperienceMonth: e.target.value,
                        };
                      });
                    }}
                  >
                    <option value="">Select an option</option>
                    {expirenceMonths?.map((item, index) => (
                      <option key={index} value={item.MonthrOfExperienceId}>
                        {item.MonthsOfExperienceValue}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
              <ErrorMessage
                name="LicenseTypeId"
                component="div"
                className="error-message"
              />
            </div>
            <br />
            <div className="old-license-detail">
              <p className="text-license">LICENSE ISSUED AT?</p>
              <hr className="seprator-2"></hr>
            </div>
            <br />
            <div>
              <div className="oldLicense">
                <div>
                  <label htmlFor="selectField">
                    Select Country<span className="asterisk">*</span>
                  </label>
                  <Field
                    as="select"
                    name="CountryId"
                    // value={userDetails ? fromUserDetails?.CountryId : ""}
                    onChange={(e) => {
                      setSelectedCountry(e.target.value);
                      setFieldValue("country", e.target.value);
                    }}
                  >
                    <option value="">Select an option</option>
                    {countryListData?.map((item, index) => (
                      <option key={index} value={item.CountryId}>
                        {item.CountryName}
                      </option>
                    ))}
                  </Field>
                </div>
                <div>
                  <label htmlFor="selectField">
                    Select State<span className="asterisk">*</span>
                  </label>
                  <Field
                    as="select"
                    name="StateId"
                    // value={userDetails ? fromUserDetails?.StateId : ""}
                    onChange={(e) => {
                      setSelectedState(e.target.value);
                      setFieldValue("state", e.target.value);
                    }}
                  >
                    <option value="">Select an option</option>
                    {stateListData?.map((item, index) => (
                      <option key={index} value={item.StateId}>
                        {item.StateName}
                      </option>
                    ))}
                  </Field>
                </div>
                <div>
                  <label htmlFor="selectField">
                    Select City<span className="asterisk">*</span>
                  </label>
                  <Field
                    as="select"
                    name="CityId"
                    // value={userDetails ? fromUserDetails?.CityId : ""}
                    onChange={(e) => {
                      setSelectedCity(e.target.value);
                      setFieldValue("city", e.target.value);
                    }}
                  >
                    <option value="">Select an option</option>
                    {cityListData?.map((item, index) => (
                      <option key={index} value={item.CityId}>
                        {item.CityName}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
              <ErrorMessage
                name="LicenseTypeId"
                component="div"
                className="error-message"
              />
            </div>
            <div></div>
            <div></div>
            <br />
            <br />
            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
