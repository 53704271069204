import { createAsyncThunk } from "@reduxjs/toolkit";
import { getEquipmentTypeFilterList } from "../../../services/equipments/equipments";

export const getRemFilterImagesDataThunk=createAsyncThunk(
    "rem/getFilterImages",async(_,{getState})=>{
        try{
          
            const data= await getEquipmentTypeFilterList();
            
            if(data.Status === 200 && Object.keys(data.Data).length > 0)
            {
                return data.Data
            }
        }
        catch(error){
        }
    }
)