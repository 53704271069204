import { Skeleton } from "@chakra-ui/react";
import { useEffect, useRef } from "react";

export const MarketplaceHoc = ({
  marketplaceName = "Marketplace Name",
  marketplaceType = "",
  dataArray,
  cardsRenderFunction = () => {},
  filterButtonImageReference,
  filterButtonArray,
  loading,
  endOfData,
  loadMoreAction = () => {},
  initialLoading,
  paginationLoading,
  isNewNavbar = false,
  maxHeight = "",
  hasPagination = true,
}) => {
  const containerRef = useRef();

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = (entries) => {
      const entry = entries[0];
      if (entry.isIntersecting && !paginationLoading && dataArray?.length > 0) {
        loadMoreAction();
      }
    };

    const intersectionObserver = new IntersectionObserver(handleScroll, {
      threshold: 1,
    });

    if (container && dataArray?.length > 0) {
      intersectionObserver.observe(container);
    }

    return () => {
      if (container) {
        intersectionObserver.unobserve(container);
      }
    };
  }, [paginationLoading, loadMoreAction, dataArray]);

  return (
    <>
      <div
        style={
          marketplaceName === "myLeads"
            ? {
                display: "grid",
                gridTemplateColumns: "auto auto",
                rowGap: "1em",
              }
              : marketplaceType === "myJobs"
              ? {
                display: "grid",
                gridTemplateColumns: "auto auto",
                rowGap: "1em",
              }  
            : marketplaceType === "partners"
            ? {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                flexWrap: "wrap",
                gap: "1em",
                padding: "1em 1em",
              }
            : marketplaceType === "Dealership Listing"
            ? {
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr) ",
                gap: "2em 1em",
              }
            : marketplaceType === "industry"
            ? {
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                rowGap: "2em",
                columnGap: "1em",
              }
            : marketplaceName === "Deferred Marketplace"
            ? {
                paddingRight: "52px",
                paddingLeft: "52px",
                paddingBottom: "1em",
                minHeight: "100vh",
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                rowGap: "2em",
                columnGap: "1em",
              }
            : {
                paddingRight: "52px",
                paddingLeft: "52px",
                paddingTop: isNewNavbar ? "1em" : "",
                paddingBottom: "1em",
                minHeight: isNewNavbar
                  ? ""
                  : marketplaceType === "jobs"
                  ? ""
                  : "100vh",
                marginTop: isNewNavbar ? "" : "80px",
                display: "grid",
                gridTemplateColumns:
                  marketplaceType === "jobs" || marketplaceType === "partner"
                    ? "auto auto"
                    : marketplaceType === "Material contact" ||
                      marketplaceType === "Dealerships"
                    ? "auto auto auto"
                    : "repeat(3, 1fr)",
                rowGap: "2em",
                columnGap: "1em",
                maxHeight: "",
                overflowY: isNewNavbar ? "scroll" : "",
                overflowX: isNewNavbar ? "hidden" : "",
              }
        }
      >
        {initialLoading ? (
          marketplaceType === "industry" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                gap: "1em",
              }}
            >
              <Skeleton height="230px" borderRadius={"10px"}></Skeleton>
              <Skeleton height="230px" borderRadius={"10px"}></Skeleton>
              <Skeleton height="230px" borderRadius={"10px"}></Skeleton>
              <Skeleton height="230px" borderRadius={"10px"}></Skeleton>
            </div>
          ) : marketplaceName === "myLeads" ? (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                columnGap: "6em",
                rowGap: "1em",
              }}
            >
              <Skeleton
                height="230px"
                width="550px"
                borderRadius={"10px"}
              ></Skeleton>
              <Skeleton
                height="230px"
                width="550px"
                borderRadius={"10px"}
              ></Skeleton>
              <Skeleton
                height="230px"
                width="550px"
                borderRadius={"10px"}
              ></Skeleton>
              <Skeleton
                height="230px"
                width="550px"
                borderRadius={"10px"}
              ></Skeleton>
            </div>
          ) : (
            <>
              <Skeleton height="345px" width="375px"></Skeleton>
              <Skeleton height="345px" width="375px"></Skeleton>
              <Skeleton height="345px" width="375px"></Skeleton>
              <Skeleton height="345px" width="375px"></Skeleton>
              <Skeleton height="345px" width="375px"></Skeleton>
              <Skeleton height="345px" width="375px"></Skeleton>
              <Skeleton height="345px" width="375px"></Skeleton>
              <Skeleton height="345px" width="375px"></Skeleton>
              <Skeleton height="345px" width="375px"></Skeleton>
            </>
          )
        ) : (
          cardsRenderFunction(dataArray)
        )}
      </div>
      {hasPagination ? (
        <div
          style={{
            display: "flex",
            flexWrap: paginationLoading ? "wrap" : "",
            width: "100%",
            margin: "2em auto",
            justifyContent: paginationLoading ? "" : "center",
            alignItems: "center",
            gap: paginationLoading ? "1em" : "",
            paddingRight: paginationLoading ? "52px" : "",
            paddingLeft: paginationLoading ? "52px" : "",
          }}
        >
          {paginationLoading ? (
            <>
              <Skeleton height="345px" width="375px"></Skeleton>
              <Skeleton height="345px" width="375px"></Skeleton>
              <Skeleton height="345px" width="375px"></Skeleton>
              <Skeleton height="345px" width="375px"></Skeleton>
              <Skeleton height="345px" width="375px"></Skeleton>
              <Skeleton height="345px" width="375px"></Skeleton>
              <Skeleton height="345px" width="375px"></Skeleton>
              <Skeleton height="345px" width="375px"></Skeleton>
              <Skeleton height="345px" width="375px"></Skeleton>
            </>
          ) : endOfData ? (
            <></>
          ) : (
            <div ref={containerRef}></div>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
