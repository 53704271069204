import { createAsyncThunk } from "@reduxjs/toolkit";
import {fetchHandler} from "../../../../index"
import { getE9MarketPlaceRentalEquipmentList } from "../../../services/equipments/equipments";


export const getRemInitialDataThunk = createAsyncThunk("/rem/initialDataThunk", async(_, {getState}) => {

const cardCount = getState().usedEquipmentMarketplace.cardCount

try{

    const data = await getE9MarketPlaceRentalEquipmentList(cardCount,0)
    
    if(data.Status === 200 && Object.keys(data.Data).length > 0){

        return data.Data
    }
    

   
}
catch(error){
}
})