import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GetReceivedRequestDetailsByMaterialId,
  GetSentRequestDetails,
} from "../../../services/materials/materials";

export const getMaterialSentDataThunk = createAsyncThunk(
  "myLeads/materialSentRequestData",
  async (materialTypeId, { getState }) => {
    try {
      const data = await GetSentRequestDetails(materialTypeId);
      return data;
    } catch (error) {}
  }
);

export const getMyLeadsMaterialConnectListThunk = createAsyncThunk(
  "myLeads/connectMaterialList",
  async (id) => {
    try {
      const data = await GetReceivedRequestDetailsByMaterialId(id?.materialId);
      if (data?.Data?.ReceivedRequestDetailsByMaterialId) {
        data.Data.ReceivedRequestDetailsByMaterialId.forEach((obj) => {
          obj.materialTypeValue = id?.materialTypeValue;
        });
      }

      return data;
    } catch (error) {}
  }
);
