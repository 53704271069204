import { createSlice } from "@reduxjs/toolkit";

const locationSlice = createSlice({
  name: "location",
  initialState: {
    isModalOpen: false,
  },
  reducers: {
    setModalOpen: (state) => {
      state.isModalOpen = true;
    },
    setModalClose: (state) => {
      state.isModalOpen = false;
    },
  },
});

export default locationSlice.reducer;

export const { setModalOpen, setModalClose } = locationSlice.actions;
