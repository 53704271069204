import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateDealershipDetails } from "../../../services/postDealership/postDealership";
import { getSessionStorageItems } from "../../../helpers/sessionStorage/sessionStorage";



export const postDealershipStepTwoThunk = createAsyncThunk(
    "myBusiness/postDealershipStepTwo", async (_, { getState }) => {

        const {financeProvided,managerID} = getState().myBusinessDealership;
        const regionPayload = JSON.parse(localStorage.getItem("POST_DEALERSHIP_REGION"));
        const postGuuid = getSessionStorageItems("POST_DEALERSHIP_POST_GUUID");
      

        const payLoad = JSON.stringify({
            DealershipDetails: {
                DealersShipGuid: postGuuid,
                Region: JSON.stringify(regionPayload),
                FinanceProvideId : financeProvided,
                ManagerId : managerID
            }
        });

        try {
            const data  = await updateDealershipDetails(payLoad)
            if (data?.Status === 201 && data?.Data?.Success &&
                Object.keys(data.Data).length > 0) {
                return { message: data?.Message?.SuccessMessage, dealershipId: data.Data.DealersShipGuid }
            } else {
                return { message: data?.Message?.FailMessage }
            }
        } catch (error) {

        }

    }
)