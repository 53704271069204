import {
  getCompanyNameFromLocalStore,
  getEnterpriseNameFromLocalStore,
  getFirstNameFromLocalStore,
  getLastNameFromLocalStore,
  getMobileNumberFromLocalStore,
  getSelectedBusinessTypeFromLocalStore,
  getSelectedUserTypeFromLocalStore,
} from "../localStorage/localStorage";

export const initialState = {
  selectedBusinessType: getSelectedBusinessTypeFromLocalStore() || null,
  selectedUserType: getSelectedUserTypeFromLocalStore() || null,
  firstName: getFirstNameFromLocalStore() || null,
  lastName: getLastNameFromLocalStore() || null,
  mobileNumber: getMobileNumberFromLocalStore() || null,
  enterpriseName: getEnterpriseNameFromLocalStore() || null,
  companyName: getCompanyNameFromLocalStore() || null,
};
