import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTransactionModeDetails } from "../../../services/myLeads/myLeads";

export const getTransactionModeDetailsThunk = createAsyncThunk(
  "myLeads/transactionModeDetailsThunk",
  async (_, {}) => {
    try {
      const data = await getTransactionModeDetails();
      return data;
    } catch (error) {}
  }
);
