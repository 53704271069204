import { useEffect } from "react";
import { NewSideMenuLayout } from "../../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Box, Text, Divider } from "@chakra-ui/react";
import { SelectCardWrapper } from "../../../components/SelectCardWrapper/SelectCardWrapper";
import { ReactSVG } from "react-svg";
import icon from "../../../../assets/webapp/subscription/e9-list-item-icon.svg";
import { v4 as uuid } from "uuid";
import { QrPopUp } from "../../../components/ScanQrCodeModal/QrPopUp";
import { openQrModal } from "../../dashboard/slice/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { getE9ServiceCategoryThunk } from "../thunks/getE9ServiceCategoryDetailsThunk";
import { E9ServicePageLoader } from "../../../components/loaders/e9ServicePageLoader/E9ServicePageLoader";
import { resetOperatorSearch, setOpServiceCategory } from "../../operatorSearch/slice/operatorSearchSlice";
import { resetMaintenanceSearchSlice, setMpServiceCategory } from "../../maintenanceJobPost/slice/maintenanceJobPostSlice";
import { e9ServiceIconHandling } from "../helpers/iconHandling";
import { Helmet } from "react-helmet";
import { V5SidebarLayout } from "../../../layout/V5SidebarLayout/V5SidebarLayout";

export const E9Services = () => {
  const { categoryList, isLoading } = useSelector(
    (state) => state.e9Services
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const defaultLanguage = useSelector( state => state.masterData)

  // Clear Session Storage
  useEffect(()=>{dispatch(resetOperatorSearch())},[])


  useEffect(()=>{dispatch(resetMaintenanceSearchSlice())},[])

  const handleOperatorClick = (serviceValue) => {
    dispatch(setOpServiceCategory(serviceValue));
    navigate("/e9-services/operator-search/job-post");
  };

  const handleMaintenanceClick = (serviceValue) => {
    dispatch(setMpServiceCategory(serviceValue));
    navigate("/e9-services/maintenance-search/job-post");
  }

  useEffect(() => {
    dispatch(getE9ServiceCategoryThunk());
  }, []);
  return (
    <V5SidebarLayout>
      <Helmet>
      <link
        rel="Business Owner Login,  Service"
        href="equip9.com/e9-services"
      />
      <meta name="keywords" content="heavy equipment service"/>
      <title>Heavy Equipment Service: Grow Your Business Today</title>
      <meta name="description" content="Maximize efficiency with our professional heavy equipment service. 
      Discover how we drive growth for business owners. Get started today!"/>
      <h1>Heavy Equipment Service: Streamlining Operations for Business Owners</h1>
      <h2>Discover the Advantages of Our Heavy Equipment Service for Your Business?</h2>
      <h3>Transform Your Operations with Professional Heavy Equipment Service</h3>
      </Helmet>
      <QrPopUp />
      <div
        style={{
          margin: "0px 52px",
          backgroundColor: "#92929204",
          paddingTop: "1em",
        }}
      >
        <div style={{ marginBottom: "20px" }}>
          <h1 style={{ fontSize: "32px", fontWeight: 700 }}>{defaultLanguage?.E9_Services}</h1>
          <p style={{ fontSize: "22px", color: "#2c2c2c80" }}>
            Optimise your business with us.
          </p>
        </div>
        <div>
          {isLoading ? (
            <E9ServicePageLoader />
          ) : categoryList && categoryList.length > 0 ? (
            categoryList.map((service) => {
              const { name, data } = service;
              // console.log(data)
              return (
                <div key={uuid()} style={{ marginBottom: "1.5em" }}>
                  <Flex alignItems="center">
                    <Flex
                      w="100%"
                      alignItems="center"
                      gap="0.5em"
                      marginBottom="1em"
                    >
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          {name}
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <div
                    style={{ display: "flex", flexWrap: "wrap", gap: "0.75em" }}
                  >
                    {data && data.length > 0 ? (
                      data.map((element) => (
                        <SelectCardWrapper
                          key={uuid()}
                          width="145px"
                          height="205.5px"
                          display="flex"
                          flexdirection="column"
                          alignItems="center"
                          justifyContent="space-around"
                          boxShadow="0px 10px 30px #2c2c2c14"
                          backgroundcolor="#FFFFFF"
                          onClick={() => {
                            element.ServiceTypeValue.toLowerCase() ===
                            "Operator Search".toLowerCase()
                              ? handleOperatorClick(element)
                              : element.ServiceTypeValue.toLowerCase() ===
                                "Maintenance Search".toLowerCase()
                              ?  handleMaintenanceClick(element) :
                              element.ServiceTypeValue.toLowerCase() === "Equipment Listing".toLowerCase()
                              ? navigate("/e9-services/equipment-listing")
                              : dispatch(openQrModal());
                          }}
                        >
                          <ReactSVG
                            src={e9ServiceIconHandling(element.ServiceTypeValue) ? `${process.env.REACT_APP_CDN_STATIC_URL}${e9ServiceIconHandling(element.ServiceTypeValue)}`:
                              icon}
                            beforeInjection={(svg) => {
                              svg.setAttribute(
                                "style",
                                `height: ${e9ServiceIconHandling(element.ServiceTypeValue) ? "45px" : "40px" }; width: 40px`
                              );
                            }}
                          />
                          <Text fontWeight="600">
                            {element.ServiceTypeValue}
                          </Text>
                        </SelectCardWrapper>
                      ))
                    ) : (
                      <p>No listings available</p>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <p>No Services available please try again later</p>
          )}
        </div>
      </div>
    </V5SidebarLayout>
  );
};
