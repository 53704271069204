import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Box,
  Flex,
  Text,
  Checkbox,
  CheckboxGroup,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

const EquipmentExpertiseModal = ({ isOpen, onButtonClick , onSubmitButtonClick, data }) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected([]);
  }, [data]);

  const handleCheckboxChange = (item) => {
    setSelected((prev) => {
      if (prev.some(selectedItem => selectedItem.MaintenanceExpertiseTypeId === item.MaintenanceExpertiseTypeId)) {
        return prev.filter(selectedItem => selectedItem.MaintenanceExpertiseTypeId !== item.MaintenanceExpertiseTypeId);
      } else {
        return [...prev, item];
      }
    });
  };

  return (
    <Modal isOpen={isOpen} isCentered onClose={onButtonClick}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="right">
            <Button variant="ghost" boxShadow="none" onClick={onButtonClick}>
              <CloseIcon />
            </Button>
          </Box>
          <Box>
            <Text fontSize="28px" textAlign="center">
              Select Expertise Area
            </Text>
          </Box>
        </ModalHeader>
        <ModalBody>
          <CheckboxGroup value={selected}>
            {data?.map((item) => (
              <Checkbox
                key={item.MaintenanceExpertiseTypeId}
                isChecked={selected.some(selectedItem => selectedItem.MaintenanceExpertiseTypeId === item.MaintenanceExpertiseTypeId)}
                onChange={() => handleCheckboxChange(item)}
                display={"flex"}
              >
                {item.MaintenanceExpertiseTypeValue}
              </Checkbox>
              
            ))}
          </CheckboxGroup>

          <Flex justify="center" mt={4}>
            <Button variant="gradient" onClick={() => onSubmitButtonClick(selected)}>
              Submit
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EquipmentExpertiseModal;
