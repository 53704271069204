import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Box, Text, useToast, Button, Flex } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { GenerateOtpHoc } from "../../../otp/hoc/generateOtpHoc/GenerateOtpHoc";
import { generateOtpThunk } from "../../../otp/thunk/otpThunk";
import useWindowWidth from "../../../../../sharedComponent/isMobile/CheckMobile";
import { setSelectedCountryThunk } from "../../../global/appConfig/thunk/setSelectedCountryThunk";
import { getAllLanguageJsonThunk } from "../../../global/mtData/thunk/getAllLanguagesThunk";
import { useEffect, useState } from "react";
import { V5MultiAdLayout } from "../../../../layout/multiAdLayout/V5MultiAdLayout";
import { NAVIGATION_CONSTANTS } from "../../../../../constants/naigationContants";
export const GenerateOtp = () => {
  const dispatch = useDispatch();
  const appConfig = useSelector((state) => state.appConfig);
  const registrationData = useSelector((state) => state.registration);
  // const otpData = useSelector((state) => state.otp)
  const toast = useToast();
  const navigate = useNavigate();
  const { defaultLanguage } = useSelector((state) => state.masterData);
  const [searchParams, setSearchParams] = useSearchParams();
  const assetIdParam = searchParams.get(NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!defaultLanguage) {
      dispatch(setSelectedCountryThunk("91"));
      dispatch(getAllLanguageJsonThunk("english.json"));
    }
  }, [!defaultLanguage]);

  const onOtpValidation = async (values) => {
    try {
      setLoading(true)
      const response = await dispatch(
        generateOtpThunk({
          UserTypeId: registrationData.selectedUserType.UserTypeId,
          BusinessTypeId: registrationData.selectedBusinessType.BusinessTypeId,
          MobileNumber: values.mobileNumber,
          CountryIsdCode: appConfig.country.CountryIsdCode,
        })
      ).unwrap();
    
      navigate(assetIdParam ? `/register/verify-otp?${NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID}=${assetIdParam}` : "/register/verify-otp");
    } catch (error) {
      toast({
        status: "error",
        title: `${error?.FailMessage}`,
        position: "top-right",
        isClosable: true,
      });
    }
    finally{
      setLoading(false)
    }
  };
  return (
    <V5MultiAdLayout
      heading={defaultLanguage?.Lets_Get_You_Registered}
      subHeading={defaultLanguage?.Its_quick_and_easy}
      justifyContent=""
      gap="2em"
    >
      <Box maxW="500px" margin="0 auto">
        <GenerateOtpHoc onSuccessfulValidation={onOtpValidation} isLoading={loading} />

        <div
          style={{
            color: "#2c2c2c80",
            textAlign: "center",
            marginTop: "23px",
          }}
        >
          By continuing, I agree to the{" "}
          <Link
            to="/terms-and-conditions"
            style={{
              color: "#f67900",
              textDecoration: "underline",
              fontWeight: "semibold",
            }}
          >
            Terms & Conditions
          </Link>
        </div>
        <Text
          textAlign="center"
          color="#2c2c2c80"
          fontWeight="semibold"
          mt="1em"
        >
          Already have an account?{" "}
          <Link to="/login/login-form" style={{ color: "#f67900" }}>
            {" "}
            Login
          </Link>
        </Text>
      </Box>
    </V5MultiAdLayout>
  );
};
