import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPremiumRedeemCountById } from "../../../services/premium/premium";

export const equipmentListingPemiumLeadsThunk = createAsyncThunk("/premiumLeads/equipmentListing", async() => {
    try{
      const data = await getPremiumRedeemCountById(3);
      console.log(data)
      return data;
    }
    catch(error){
        throw error
    }
})