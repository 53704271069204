import React, { useState } from "react";
import { SideMenuLayout } from "../../../../../../../../layout/sideMenuLayout/SideMenuLayout";
import { DashboardNavBar } from "../../../../../../../../components/DashboardNavBar/DashboardNavBar";
import { MidSection } from "../../../../../../../../../sharedComponent/midSection/MidSection";
import { OpEducationCard } from "./OpEducationPopCard/OpEducationPopCard";
import { OpgetEducationDetails } from "./OpgetEducationDetails/OpgetEducationDetails";
import { ProfileVerification } from "../../../../../../../../components/ProfileVerication/ProfileVerification";
import "./OpEducationDetail.css";

export const OpEducationDetails = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const triggerRefresh = () => {
    setRefreshKey((prev) => prev + 1);
  };
  return (
    <>
      <SideMenuLayout>
        <DashboardNavBar />
        <MidSection>
          <ProfileVerification />

          <div className="verification-details">
            <p className="your-verification-details">Education Details</p>
            <hr className="seprator-education"></hr>
            <div className="add-verification-education" onClick={openPopup}>
            <p className="add-verification-detail-text-education">
              + Add Education Details
            </p>
          </div>
          </div>
          

          <OpEducationCard
            isOpen={isPopupOpen}
            onClose={closePopup}
            triggerRefresh={triggerRefresh}
          />
          <OpgetEducationDetails triggerRefresh={triggerRefresh} />
        </MidSection>
      </SideMenuLayout>
    </>
  );
};
