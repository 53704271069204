import { createSlice } from "@reduxjs/toolkit";
import { getInitialUserAssetsThunk } from "../thunks/getInitialUserAssetsThunk";

export const equipmentStoreSlice = createSlice({
    name: "equipmentStoreSlice",
    initialState: {
        data: [], 
        cardCount: 0,
        paginitionCount: 12,
        isInitialLoading: false,
        isLoadMoreLoading: false,
        imageBaseReference: null


    },
    reducers: {
        setData: (state, {payload}) => {
            state.data = payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getInitialUserAssetsThunk.pending, (state) => {
            state.isInitialLoading = true;
            state.data = [];
            state.cardCount = 0
        })
        .addCase(getInitialUserAssetsThunk.fulfilled, (state, {payload}) => {
            state.isInitialLoading = false;
            state.data = payload.data;
            state.imageBaseReference = payload.imageBaseReference;
            state.cardCount = 1
        })
    }

})

export default equipmentStoreSlice.reducer;