import { createSlice } from "@reduxjs/toolkit";
import { getInitialUsedEquipmentsThunk } from "../thunks/getInitialUsedEquipmentSlice";
import { getUsedEquipmentAnalyticsThunk } from "../thunks/getUsedEquipmentAnalyticsThunk";
// import { getInitialUsedEquipmentsThunk } from "../thunks/getInitalUsedEquipmentsThunk";

const usedEquipmentStoreSlice = createSlice({
    name: "rentalequipmentStoreSlice",
    initialState: {
        usedEquipmentData: [], 
        usedEquipmentAnalytics: [],
        usedEquipmentCardCount: 0,
        usedEquipmentPaginitionCount: 12,
        usedEquipmentIsInitialLoading: false,
        usedEquipmentIsLoadMoreLoading: false,
        usedEquipmentsAnalyticsLoading: false,
        imageBaseReference: null


    },
    reducers: {
        setusedEquipmentData: (state, {payload}) => {
            state.data = payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getInitialUsedEquipmentsThunk.pending, (state) => {
            state.usedEquipmentIsInitialLoading = true;
            state.usedEquipmentData = [];
            state.usedEquipmentPaginitionCount = 0
        })
        .addCase(getInitialUsedEquipmentsThunk.fulfilled, (state, {payload}) => {
            state.usedEquipmentIsInitialLoading = false;
            state.usedEquipmentData = payload;
            // state.imageBaseReference = payload.imageBaseReference;
            // state.cardCount = 1
        })
        .addCase(getUsedEquipmentAnalyticsThunk.pending, (state) => {
            state.usedEquipmentsAnalyticsLoading = true
        })
        .addCase(getUsedEquipmentAnalyticsThunk.fulfilled, (state, {payload}) => {
            state.usedEquipmentsAnalyticsLoading = false
            state.usedEquipmentAnalytics = payload
        })
    }

})

export default usedEquipmentStoreSlice.reducer;