import { DOCUMENT_FLOW_PAGES } from "../../../constants/navigationConstants";
import { getDocument } from "./individualDataPromise";

export const OWNER_COMPANY_ROUTE_CALL_PROMISES = [
  { url: "/GetUserAllUploadedDocument", documentName: "Businennss Identification" },
];

export const OWNER_COMPANY_CALLS = async (array, token) => {
  try {
    const promises = array.map((object) => getDocument(object.url));
    const responses = await Promise.allSettled(promises);
    const responseData = responses.map((response) => {
       
      if (response.status === "fulfilled") {
        return response.value.Data;
      }
    });

    const [verificationDocument] = responseData;
    return {verificationDocument};
  } catch (error) {
  }
};

export const generateOwnerCompanyRouting = (documentObject) => {
  const routeArray = [];
  if (!documentObject.Designation) {
    routeArray.push(DOCUMENT_FLOW_PAGES.OWNER_COMPANY.DESIGNATION);
  }
  if (!documentObject.ProfilePicture) {
    routeArray.push(DOCUMENT_FLOW_PAGES.OWNER_COMPANY.PROFILE_PICTURE);
  }
  if (!documentObject.E9UserRole) {
    routeArray.push(DOCUMENT_FLOW_PAGES.OWNER_COMPANY.E9_ROLE);
  }
   if (
    documentObject.expertiseDocument &&
    documentObject.expertiseDocument.length === 0
  ) {
    routeArray.push(DOCUMENT_FLOW_PAGES.OWNER_COMPANY.EQUIPMENT_SKILL);
  }
  if(!documentObject.BusinessIdentificationDocument){
    routeArray.push(DOCUMENT_FLOW_PAGES.OWNER_COMPANY.BUSINESS_IDENTIFICATION_DOCUMENT);
 }
  if(documentObject.verificationDocument && Array.from(Object.keys(documentObject.verificationDocument)).length === 0){
    routeArray.push(DOCUMENT_FLOW_PAGES.OWNER_COMPANY.VERIFICATION_DOCUMENT)
   }
   return routeArray
};

// /document-flow/upload-business-identification