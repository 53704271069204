import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetCommunityDetailsById } from "../../../services/community/community";

export const myContentPostsThunk = createAsyncThunk(
  "myContent/getCards",
  async (_, {getState}) => {
   

    try {
      const data = await GetCommunityDetailsById();
      return data;
    } catch (error) {
      throw error;
    }
  }
);