import React from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { formatNumberIndianStyle } from "../../../../staticData/CommonMethod";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import './Completed.css';
import { MPcompletedQuickjobs, MPcompletedfulltimejobs, OPcompletedjob } from "../../../services/jobsPosted/jobsPosted";
import { openQrModal } from "../../dashboard/slice/dashboardSlice";
import { Flex } from "@chakra-ui/react";
export const Completedjob = () => {

  const userData = useSelector((state) => state.user);
  const businessTypeValue = userData.BusinessTypeValue;
  const navigate = useNavigate();
  const dipatch = useDispatch();

  /************useQuery***********************/
  const { data: mpfulltimecompletedjobs } = useQuery("MPfulltimecompletedjobs",() => MPcompletedfulltimejobs());
  const { data: mpQuickmecompletedjobs } = useQuery("MPquickcompletedjobs",() => MPcompletedQuickjobs());
  const { data: opcompletedjobs } = useQuery("OPcompletedjobskey",() => OPcompletedjob());

 

  /**********Rendering list******************/
  const MPFullTimeCompletedJobs =mpfulltimecompletedjobs?.data?.Data?.FullTimeMaintenanceCompletedJobList ||[];
  const MPQuickCompetedJobs = mpQuickmecompletedjobs?.data?.Data?.QuickMaintenanceCompletedJobList || [];
  const OPCompletedJobs=opcompletedjobs?.data?.Data?.OperatorCompletedJobList;

  /*******combined job List *****************/
  const  mpQuickFullltimejobs=[...MPFullTimeCompletedJobs ,...MPQuickCompetedJobs];


  return (
    <div>
      <div>
        {businessTypeValue === "Operator Partner" ? (
          <div>
            {OPCompletedJobs?.length > 0 ? (
              <div className="cards-container">
                {OPCompletedJobs?.map((item) => (
                  <React.Fragment key={item.UserVerificationDocumentId}>
                    <div
                      className="onGoingCard-layout"
                      onClick={() =>
                        dipatch(openQrModal())
                        // navigate(
                        //   `/completed/mp-job-description/${item.JobId}`
                        // )
                      }
                    >
                      <div>
                     
                        <Flex justifyContent={"space-between"}>
                        <div className="jobNumber">
                          <span>Job Id:</span>
                          <span>{item.JobId}</span>
                        </div>
                        <div className="verfication-badge-completed">
                            <div className="badge-layout-completed">
                              <p className="userResposeTypeValue-completed">
                                {item.UserJobResponseTypeValue}
                              </p>
                            </div>
                          </div>
                        </Flex>

                        <div className="jobTitle-font">
                          <div>{item.JobTitle}</div>
                        </div>
                        <div>
                          <div className="Equipment-category">
                            {" "}
                            <span> {item?.EquipmentType || "NA"} </span>
                            &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                            <span> {item?.EquipmentMake || "NA"} </span>
                            &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                            <span>{item?.EquipmentModel || "NA"}</span>
                          </div>
                        </div>

                        <div className="worklocation">
                          <p>{item.WorkLocation}</p>
                        </div>
                        <div className="payment-layout">
                          <p className="paymentfont">
                            &#8377;&nbsp;
                            {formatNumberIndianStyle(
                              item?.Salary ? item?.Salary : "NA"
                            )}
                            /
                            <span className="paymentfont">
                              {item?.SalaryType ? item?.SalaryType : " "}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            ) : (
              <div className="no-data-found">
              <div style={{marginTop:"15%"}}>
              <ReactSVG
              style={{marginLeft:"86px"}}
              src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/NoCancelledjob.svg`}
               />
              <p className="no-material-added-yet-upcoming ">OOPS! NO Completed Jobs!</p>
              </div>  
              </div>
            )}
          </div>
        ) : (
          <div>
            {mpQuickFullltimejobs?.length > 0 ? (
              <>
                <div className="cards-container">
                  {mpQuickFullltimejobs?.map((item) => (
                    <React.Fragment key={item.UserVerificationDocumentId}>
                      <div
                        className="onGoingCard-layout"
                        onClick={() =>
                          dipatch(openQrModal())
                          // navigate(
                          //   `/completed/mp-job-description/${item.JobId}`
                          // )
                        }
                      >
                        <div>
                          <div className="verfication-badge">
                            <div className="badge-layout-completed">
                              <p className="userResposeTypeValue-completed">
                                {item.UserJobResponseTypeValue}
                              </p>
                            </div>
                          </div>
                          <div className="jobNumber">
                            <span>Job Id:</span>
                            <span>{item.JobId}</span>
                          </div>

                          <div className="jobTitle-font">
                            <div>{item.JobTitle}</div>
                          </div>
                          <div>
                            <div className="Equipment-category">
                              {" "}
                              <span>{item.EquipmentType}</span>
                              &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                              <span>{item.EquipmentMake}</span>
                              &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                              <span>{item.EquipmentModel}</span>
                            </div>
                          </div>

                          <div className="worklocation">
                            <p>{item.WorkLocation}</p>
                          </div>
                          <div className="payment-layout">
                            <p className="paymentfont">
                              &#8377;&nbsp;
                              {formatNumberIndianStyle(item.Salary)}/
                              <span className="paymentfont">
                                {item.SalaryType}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </>
            ) : (
              <div className="no-data-found">
            <div style={{marginTop:"15%"}}>
              <ReactSVG
              style={{marginLeft:"31px"}}
              src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/NoongoingJObs.svg`}
               />
              <p className="no-material-added-yet-upcoming ">OOPS! NO Ongoing Jobs!</p>
              </div>  
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
