import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import emptyStar from "../../../../assets/webapp/leads/unrated-star.svg";
import filledStar from "../../../../assets/webapp/leads/filled-star.svg";
import checkedIcon from "../../../../assets/webapp/pop-up/pop-up-checked-icon.svg";

export const ViewRatings = ({
  isOpen,
  data = [
    { name: "Behaviour", rating: 0, totalRating: 5 },
    { name: "Punctuality", rating: 0, totalRating: 5 },
    { name: "Work", rating: 0, totalRating: 5 },
  ],
  handleOkayButtonClick = () => {},
  handleCloseButtonClick = () => {}
}) => {
  return (
    <Modal isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
            
            <Flex
            flexDirection="column"
            gap="0.5rem"
            justifyContent="center"
            alignItems="center"
            paddingTop="1rem"
          >
            <Image src={checkedIcon} height="42px" width="82px" />
            <Text fontWeight={700} fontSize="24px" color="#1A1E21">
            View Partner Ratings
            </Text>
          </Flex>
            </ModalHeader>
        <ModalBody>
          <Flex flexDirection="column" gap="1.5rem">
            {data && data.length > 0 ? (
              data.map((element) => (
                <Flex key={element.name} justifyContent="space-between">
                  <Text minW="100px" fontSize="18px" fontWeight={700} color="#002961">{element.name}</Text>
                  <Flex gap="0.5rem">
                    {new Array(element.rating).fill(null).map(() => (
                      <Image src={filledStar} />
                    ))}
                    {new Array(element.totalRating - element.rating)
                      .fill(null)
                      .map(() => (
                        <Image src={emptyStar} />
                      ))}
                  </Flex>
                  <Text color="#002961" fontSize="18px"  fontWeight={700}>
                    <span style={{color:"#F79C00"}}>{element.rating}</span> / {element.totalRating}
                  </Text>
                </Flex>
              ))
            ) : (
              <Text>No Ratings Available</Text>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
            <Button variant="gradient" onClick={handleOkayButtonClick}>Okay</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
