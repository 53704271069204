import { createAsyncThunk } from "@reduxjs/toolkit";
import fetchHandler from "../../../../webapp/services/fetchHandler";
import { API_CONSTANTS } from "../../../../constants/apiConstants";


export const getDeferredRemThunk = createAsyncThunk("getRemDeferredMarketplace/getInitialCards",async(_,{getState}) =>{

    try {
        const {category,subCategory,cardCount,paginationCount} = getState().remDeferred;
        const {data} = await fetchHandler(
            "/deferredLogin/marketplace",
            { category : category,subcategory : subCategory,limit : cardCount,offset : 0  },
            
            "",
            "",
            API_CONSTANTS.HTTP_GET,
            { isAuthRequired: false }
        )
        if(data?.Status === 200){

            return data?.Data;
        }
        return null ;
    } catch (error) {
        
    }
})