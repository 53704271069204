import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MarketPlaceLayout } from "../../layout/dealershipMarketplaceLayout/MarketPlaceLayout";
import { v4 as uuid } from "uuid";
import {
  Flex,
  Box,
  Divider,
  Text,
  useToast,
  Button,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { convertFromBase64 } from "../../../utils/formatConverter/convertToBase64";
import { API_CONSTANTS } from "../../../constants/apiConstants";
import downloadIcon from "../../../assets/webapp/marketplace/download.svg"
import { DealershipDetailsCard } from "../DealershipDetailsCard/DealershipDetailsCard";
import DealrshipHeaderCard from "../DealershipDetailsCard/DealershipHeaderCard";
import DealershipProductCard from "../DealershipProductCard/DealershipProductCard";
import "./DealershipConnnectCss.css"
import { openConnectDealershipModal, setConnectDealership } from "../../features/dealershipMarketplace/slice/dealershipMarketplaceSlice";
import { StyledLink } from "../../../website/CommonComponents/Button/StyledButton";
import { DealershipDownloadCard } from "../DealershipDetailsCard/DealershipDownloadCard";
import "./DealershipConnnectCss.css";
import { getLocalStorageItems } from "../../helpers/localStorage/localStorage";
import { ConnectDealershipModal } from "./ConnectDealershipModal";
import defaultImage from "../../../assets/webapp/marketplace/defaultAssetImage.png";
import { getDealershipDetailById, getDealershipDetailsById } from "../../services/postDealership/postDealership";
import { createE9DsMpContactDetails, getE9DealershipMpConnectStatus, getUserDsProductList, sendLikeToDealership } from "../../services/dealershipMarketplace/dealershipMarketplace";

export const DealershipDetail = () => {

  const { guid, mpguid,likeStatus,taxNumber} = useParams();
  const decodedGuid = convertFromBase64(guid);
  const decodedMpGuid = convertFromBase64(mpguid);
  const decodedTaxNumber = convertFromBase64(taxNumber);
  const userData = useSelector((state) => state.user);
  const [assetData, setAssetData] = useState(null);
  const [regionData, setRegionData] = useState(null);
  const [product, setProductsData] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const { connectDealership } = useSelector((state) => state.dealershipMarketplace);
  const gstTaxNumber = userData?.accountDetails?.CompanyDetails?.TaxNumber;
  const { UserDetails } = getLocalStorageItems("USER_DETAILS")
  const toast = useToast()
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      // const token = userData.token;
      // const authHeaders = new Headers();
      // authHeaders.append("token", token);
      // const response = await fetch(
      //   `${process.env.REACT_APP_API_ENDPOINT
      //   }${API_CONSTANTS.GET_DEALERSHIP_DETAILS_BY_ID}/?DealersShipGuid=${decodedGuid}`,
      //   {
      //     headers: authHeaders,
      //   }
      // // );
      // const data = await response.json();
      const data = await getDealershipDetailsById(decodedGuid);
      if (data?.Status === 200) {
        setAssetData(data.Data?.DealershipDetails?.DealershipData);
        setRegionData(JSON.parse(data.Data?.DealershipDetails?.RegionDetails));
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
     
      try {
        const data = await getUserDsProductList(10,0,`?DealershipGuid=${decodedGuid}`);

        setProductsData(data.Data?.UserDsProductList);
      } catch (error) {
        
      }
    })();
  }, []);

  useEffect(() => {
    const fetchConnectStatus = async () => {
      try {
        const data = await getE9DealershipMpConnectStatus(decodedMpGuid);
        if (data?.Data?.Success === true) {
          if (data?.Data?.E9DealershipMpConnectStatus?.IsConnected === 1)
            setIsConnected(true)
          dispatch(setConnectDealership(true))
        }
      } catch (error) {
      }
    }
    fetchConnectStatus();
  }, [isConnected, connectDealership]);

  const handleContactOwner = async () => {
    try {
      const connectData = {
        MarketplaceGuid: convertFromBase64(mpguid),
        OwnerUserCredentialId: assetData?.OwnerUserCredentialId,
        DealershipUniqueId: assetData?.DealershipUniqueId,
        DealershipName: assetData?.DealerName
      }
      const data = await createE9DsMpContactDetails(connectData);
      if (data?.Data?.Success === true) {
        setIsConnected(true)
        dispatch(setConnectDealership(true))
        toast({
          title: "",
          description: `Contacted successfully`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
  
      }
      
    } catch (error) {
      
    }

  };

  const handleBlurClick = () => {
    dispatch(openConnectDealershipModal());
  }
  const handleLike =async (body) =>{
    try {
      const response = await sendLikeToDealership(body);
    } catch (error) {
      
    }
  } 
  const renderDealershipDetail = (data) => {
    if (data) {
      return (
        <>
          <div className="dealership-header-container" style={{ marginBottom: "2em" }}>
            <DealrshipHeaderCard
              imageUrl={assetData?.ImageList}
              isPromoted={assetData?.IsPromoted}
              location={assetData?.Location}
              dealerName={assetData?.DealerName}
              financeAvailable={assetData?.FinanceProvidedValue}
              dealershipUniqueId={assetData?.DealershipUniqueId}
              updatedDateTime={assetData?.UpdatedDateTime}
              IsVerified={assetData?.IsVerified}
              like = {likeStatus}
              onHandleLikeClick={ async(value) => {
                await handleLike({
                  MarketplaceGuid: decodedMpGuid,
                  OwnerUserCredentialId: assetData?.OwnerUserCredentialId,
                  LikeStatus: value ? 1 : 0,
                  DealershipUniqueId : assetData?.DealershipUniqueId
                })
              }}
            />
          </div>

          {/* Connect Dealership */}
          {UserDetails?.CredentialDetails?.UserCredentialId === assetData?.OwnerUserCredentialId || decodedTaxNumber === gstTaxNumber ?
            (
              <>
                <div className="dealership-certificate-region" style={{ display: "flex", gap: "2em" }}>
                  <Box className="dealership-main-container">
                    <Flex alignItems="center">
                      <Flex w="100%" alignItems="center" gap="0.5em">
                        <Box minWidth="max-content">
                          <Text
                            color="#011f6b"
                            fontWeight="semibold"
                            fontSize="2xl"
                          >
                            Dealership Certificate
                          </Text>
                        </Box>
                      </Flex>
                    </Flex>
                    <Box height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                      <Flex
                        gap="2em"
                        flexDirection="row"
                        overflowX="scroll"
                        padding="1em 0"
                      >
                        {
                          assetData?.CertificateList ? assetData?.CertificateList?.map((certificate) => (
                            <>
                              {certificate.CertificateFileRef ? (
                                <DealershipDownloadCard
                                  key={uuid()}
                                  imgSrc={downloadIcon}
                                  description="Download"
                                  onDownloadClick={() => {
                                    window.open(`${process.env.REACT_APP_CDN_PVT_URL}${certificate.CertificateFileRef}`, "_blank")
                                  }}
                                />
                              ) : (
                                <p className="dealership-not-available">Not Available</p>
                              )}
                            </>
                          )) : (
                            <p className="dealership-not-available">Not Available</p>
                          )
                        }
                      </Flex>
                    </Box>
                  </Box>

                  <Box className="dealership-main-container">
                    <Flex alignItems="center">
                      <Flex w="100%" alignItems="center" gap="0.5em">
                        <Box minWidth="max-content">
                          <Text
                            color="#011f6b"
                            fontWeight="semibold"
                            fontSize="2xl"
                          >

                          </Text>
                        </Box>
                      </Flex>
                    </Flex>
                    <div className="dealership-divider" height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    </div>
                  </Box>

                  {/* Contact Details */}
                  <Box width="100%" className="dealership-main-container">
                    <Flex alignItems="center">
                      <Flex w="100%" alignItems="center" gap="0.5em">
                        <Box minWidth="max-content">
                          <Text
                            color="#011f6b"
                            fontWeight="semibold"
                            fontSize="2xl"
                          >
                            Contact
                          </Text>
                        </Box>
                        <Flex alignItems="center" w="100%">
                          <Divider borderColor="#011f6b" borderWidth="0.1em" />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Box>
                      <Flex
                        gap="2em"
                        flexDirection="row"
                        overflowX="scroll"
                        padding="1em 0"
                      >
                        {assetData && (
                          <div style={{ width: "100%" }}>
                            <div className="dealership-contact-container"><h1 className="dealership-contact-header">Address : </h1><p className="dealership-contact-description-text">{assetData?.Location ? assetData?.Location : "NA"}</p></div>
                            <div className="dealership-contact-container"><h1 className="dealership-contact-header">Contact : </h1><p className="dealership-contact-description-text">{assetData?.OwnerMobileNumber ? assetData?.OwnerMobileNumber : "NA"}</p></div>
                            <div className="dealership-contact-container"><h1 className="dealership-contact-header">Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : </h1><p className="dealership-contact-description-text">{assetData?.Email ? assetData?.Email : "NA"}</p></div>
                            <div className="dealership-contact-container"><h1 className="dealership-contact-header">Website : </h1><p className="dealership-contact-description-text">{assetData?.Website ? assetData?.Website : "NA"}</p></div>
                          </div>
                        )}
                      </Flex>
                    </Box>
                  </Box>

                </div>
                {/* Regions */}
                <Box width="100%" className="dealership-main-container">
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Regions
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box height={"100%"} display={"flex"} alignItems={!regionData || regionData.length === 0 ? "center" : ""}
                    justifyContent={!regionData || regionData.length === 0 ? "center" : ""}>
                    <Flex
                      gap="2em"
                      flexDirection="row"
                      overflowX="scroll"
                      padding="1em 0"
                    >
                      {regionData ?
                        (
                          regionData.map((item) => (
                            <>
                              <DealershipDetailsCard
                              
                                key={uuid()}
                                heading={item?.states?.value || 'NA'}
                                description={item.cities.map((city) => city.value).join(', ')}
                              />
                            </>
                          ))
                        ) : (
                          <p className="dealership-not-available">No Regions Available</p>
                        )}
                    </Flex>
                  </Box>

                </Box>

                {/* Videos */}
                <Box width="100%" className="dealership-main-container">
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Dealership Videos
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box height={"100%"} display={"flex"} alignItems={!assetData || !assetData.VideoList || assetData.VideoList.length === 0 ? "center" : ""}
                    justifyContent={!assetData || !assetData.VideoList || assetData.VideoList.length === 0 ? "center" : ""}>
                    <Flex
                      gap="2em"
                      flexDirection="row"
                      overflowX="scroll"
                      padding="1em 0"
                    >
                      {
                        !assetData || !assetData.VideoList || assetData.VideoList.length === 0 ? (
                          <p className="dealership-not-available">No videos Available</p>
                        ) : (
                          assetData.VideoList.map((video) => (
                            <>
                              <DealershipDetailsCard
                                key={uuid()}
                                videoUrl={`${process.env.REACT_APP_CDN_PVT_URL}${video.VideoFileRef}`}
                                videoType={video?.VideoTypeValue}
                              />
                            </>
                          ))
                        )
                      }

                    </Flex>
                  </Box>
                </Box>

                {/* Products */}
                <Box width="100%" className="dealership-main-container">
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Products
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box height={"100%"} display={"flex"} alignItems={product?.length === 0 ? "center" : ""}
                    justifyContent={product?.length === 0 ? "center" : ""}>
                    <Flex
                      gap="2em"
                      flexDirection="row"
                      overflowX="scroll"
                      padding="1em 0"
                    > {
                        product?.length === 0 ? (
                          <p className="dealership-not-available">No Products Available</p>
                        ) : <>
                          {product?.map((product) => (
                            <DealershipProductCard
                              key={uuid()}
                              imageUrl={
                                product.ImageList?.length > 0 && product.ImageList[0].ImageFileRef
                              }
                              location={product?.GoogleLocation?.FormattedAddress}
                              equipmentName={product?.EquipmentModelValue}
                              financeAvailable={product?.FinanceProvidedValue}
                              productUniqueId={product?.ProductUniqueId}
                              updatedDateTime={product?.UpdatedDateTime}
                              equipmentMakeValue={product?.EquipmentMakeValue}
                            />
                          ))}
                        </>
                      }

                    </Flex>
                  </Box>
                </Box>

              </>
            ) :
            isConnected === true && connectDealership === true ? (
              <>
                <div className="dealership-certificate-region" style={{ display: "flex", gap: "2em" }}>
                  <Box className="dealership-main-container">
                    <Flex alignItems="center">
                      <Flex w="100%" alignItems="center" gap="0.5em">
                        <Box minWidth="max-content">
                          <Text
                            color="#011f6b"
                            fontWeight="semibold"
                            fontSize="2xl"
                          >
                            Dealership Certificate
                          </Text>
                        </Box>
                      </Flex>
                    </Flex>
                    <Box height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                      <Flex
                        gap="2em"
                        flexDirection="row"
                        overflowX="scroll"
                        padding="1em 0"
                      >
                        {
                          assetData?.CertificateList ? assetData?.CertificateList?.map((certificate) => (
                            <>
                              {certificate.CertificateFileRef ? (
                                <DealershipDownloadCard
                                  key={uuid()}
                                  imgSrc={downloadIcon}
                                  description="Download"
                                  onDownloadClick={() => {
                                    window.open(`${process.env.REACT_APP_CDN_PVT_URL}${certificate.CertificateFileRef}`, "_blank")
                                  }}
                                />
                              ) : (
                                <p className="dealership-not-available">Not Available</p>
                              )}
                            </>
                          )) : (
                            <p className="dealership-not-available">Not Available</p>
                          )
                        }
                      </Flex>
                    </Box>
                  </Box>

                  <Box className="dealership-main-container">
                    <Flex alignItems="center">
                      <Flex w="100%" alignItems="center" gap="0.5em">
                        <Box minWidth="max-content">
                          <Text
                            color="#011f6b"
                            fontWeight="semibold"
                            fontSize="2xl"
                          >

                          </Text>
                        </Box>
                      </Flex>
                    </Flex>
                    <div className="dealership-divider" height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    </div>
                  </Box>

                  {/* Contact Details */}
                  <Box width="100%" className="dealership-main-container">
                    <Flex alignItems="center">
                      <Flex w="100%" alignItems="center" gap="0.5em">
                        <Box minWidth="max-content">
                          <Text
                            color="#011f6b"
                            fontWeight="semibold"
                            fontSize="2xl"
                          >
                            Contact
                          </Text>
                        </Box>
                        <Flex alignItems="center" w="100%">
                          <Divider borderColor="#011f6b" borderWidth="0.1em" />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Box>
                      <Flex
                        gap="2em"
                        flexDirection="row"
                        overflowX="scroll"
                        padding="1em 0"
                      >
                        {assetData && (
                          <div style={{ width: "100%" }}>
                            <div className="dealership-contact-container"><h1 className="dealership-contact-header">Address : </h1><p className="dealership-contact-description-text">{assetData?.Location ? assetData?.Location : "NA"}</p></div>
                            <div className="dealership-contact-container"><h1 className="dealership-contact-header">Contact : </h1><p className="dealership-contact-description-text">{assetData?.OwnerMobileNumber ? assetData?.OwnerMobileNumber : "NA"}</p></div>
                            <div className="dealership-contact-container"><h1 className="dealership-contact-header">Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : </h1><p className="dealership-contact-description-text">{assetData?.Email ? assetData?.Email : "NA"}</p></div>
                            <div className="dealership-contact-container"><h1 className="dealership-contact-header">Website : </h1><p className="dealership-contact-description-text">{assetData?.Website ? assetData?.Website : "NA"}</p></div>
                          </div>
                        )}
                      </Flex>
                    </Box>
                  </Box>

                </div>

                {/* Regions */}
                <Box width="100%" className="dealership-main-container">
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Regions
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box height={"100%"} display={"flex"} alignItems={!regionData || regionData.length === 0 ? "center" : ""}
                    justifyContent={!regionData || regionData.length === 0 ? "center" : ""}>
                    <Flex
                      gap="2em"
                      flexDirection="row"
                      overflowX="scroll"
                      padding="1em 0"
                    >
                      {regionData ?
                        (
                          regionData.map((item) => (
                            <>
                              <DealershipDetailsCard
                                key={uuid()}
                                heading={item?.states?.value || 'NA'}
                                description={item.cities.map((city) => city.value).join(', ')}
                              />
                            </>
                          ))
                        ) : (
                          <p className="dealership-not-available">No Regions Available</p>
                        )}
                    </Flex>
                  </Box>

                </Box>

                {/* Videos */}
                <Box width="100%" className="dealership-main-container">
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Dealership Videos
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box height={"100%"} display={"flex"} alignItems={!assetData || !assetData.VideoList || assetData.VideoList.length === 0 ? "center" : ""}
                    justifyContent={!assetData || !assetData.VideoList || assetData.VideoList.length === 0 ? "center" : ""}>
                    <Flex
                      gap="2em"
                      flexDirection="row"
                      overflowX="scroll"
                      padding="1em 0"
                    >
                      {
                        !assetData || !assetData.VideoList || assetData.VideoList.length === 0 ? (
                          <p className="dealership-not-available">No videos Available</p>
                        ) : (
                          assetData.VideoList.map((video) => (
                            <>
                              <DealershipDetailsCard
                                key={uuid()}
                                videoUrl={`${process.env.REACT_APP_CDN_PVT_URL}${video.VideoFileRef}`}
                                videoType={video?.VideoTypeValue}
                              />
                            </>
                          ))
                        )
                      }

                    </Flex>
                  </Box>
                </Box>

                {/* Products */}
                <Box width="100%" className="dealership-main-container">
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Products
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box height={"100%"} display={"flex"} alignItems={product?.length === 0 ? "center" : ""}
                    justifyContent={product?.length === 0 ? "center" : ""}>
                    <Flex
                      gap="2em"
                      flexDirection="row"
                      overflowX="scroll"
                      padding="1em 0"
                    > {
                        product?.length === 0 ? (
                          <p className="dealership-not-available">No Products Available</p>
                        ) : <>
                          {product?.map((product) => (
                            <DealershipProductCard
                              key={uuid()}
                              imageUrl={
                                product.ImageList?.length > 0 && product.ImageList[0].ImageFileRef
                              }
                              location={product?.GoogleLocation?.FormattedAddress}
                              equipmentName={product?.EquipmentModelValue}
                              financeAvailable={product?.FinanceProvidedValue}
                              productUniqueId={product?.ProductUniqueId}
                              updatedDateTime={product?.UpdatedDateTime}
                              equipmentMakeValue={product?.EquipmentMakeValue}
                            />
                          ))}
                        </>
                      }

                    </Flex>
                  </Box>
                </Box>

              </>
            ) : (
              <div onClick={handleBlurClick} style={{
                cursor: "pointer"
              }}>
                <div className="dealership-certificate-region" style={{ display: "flex", gap: "2em" }}>
                  <Box className="dealership-main-container">
                    <Flex alignItems="center">
                      <Flex w="100%" alignItems="center" gap="0.5em">
                        <Box minWidth="max-content">
                          <Text
                            color="#011f6b"
                            fontWeight="semibold"
                            fontSize="2xl"
                          >
                            Dealership Certificate
                          </Text>
                        </Box>
                      </Flex>
                    </Flex>
                    <Box height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"} className="dealership-blur-pointer">
                      <Flex
                        gap="2em"
                        flexDirection="row"
                        overflowX="scroll"
                        padding="1em 0">
                        <DealershipDownloadCard
                          imgSrc={downloadIcon}
                          description="Download"
                        />
                      </Flex>
                    </Box>
                  </Box>

                  <Box className="dealership-main-container">
                    <Flex alignItems="center">
                      <Flex w="100%" alignItems="center" gap="0.5em">
                        <Box minWidth="max-content">
                          <Text
                            color="#011f6b"
                            fontWeight="semibold"
                            fontSize="2xl">
                          </Text>
                        </Box>
                      </Flex>
                    </Flex>
                    <div className="dealership-divider" height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    </div>
                  </Box>

                  {/* Contact Details */}
                  <Box width="100%" className="dealership-main-container">
                    <Flex alignItems="center">
                      <Flex w="100%" alignItems="center" gap="0.5em">
                        <Box minWidth="max-content">
                          <Text
                            color="#011f6b"
                            fontWeight="semibold"
                            fontSize="2xl">
                            Contact
                          </Text>
                        </Box>
                        <Flex alignItems="center" w="100%">
                          <Divider borderColor="#011f6b" borderWidth="0.1em" />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Box>
                      <Flex
                        gap="2em"
                        flexDirection="row"
                        overflowX="scroll"
                        padding="1em 0"
                      >
                        {assetData && (
                          <div style={{ width: "100%" }}>
                            <div className="dealership-contact-container"><h1 className="dealership-contact-header">Address : </h1><p className="dealership-contact-description-text dealership-blur-pointer">{"sample address"}</p></div>
                            <div className="dealership-contact-container"><h1 className="dealership-contact-header">Contact : </h1><p className="dealership-contact-description-text dealership-blur-pointer" >{"sample Contact"}</p></div>
                            <div className="dealership-contact-container"><h1 className="dealership-contact-header">Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : </h1><p className="dealership-contact-description-text dealership-blur-pointer" >{"sample@gmail.com"}</p></div>
                            <div className="dealership-contact-container"><h1 className="dealership-contact-header">Website : </h1><p className="dealership-contact-description-text dealership-blur-pointer" >{"www.sample.com"}</p></div>
                          </div>
                        )}
                      </Flex>
                    </Box>
                  </Box>

                </div>

                {/* Regions */}
                <Box width="100%" className="dealership-main-container">
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Regions
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box height={"100%"} display={"flex"} className="dealership-blur-pointer">
                    <Flex
                      gap="2em"
                      flexDirection="row"
                      overflowX="scroll"
                      padding="1em 0"
                    >
                      <DealershipDetailsCard
                        heading={"City"}
                        description={"State"} />
                    </Flex>
                  </Box>

                </Box>

                {/* Videos */}
                <Box width="100%" className="dealership-main-container">
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Dealership Videos
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box height={"100%"} display={"flex"}
                    className="dealership-blur-pointer">
                    <Flex
                      gap="2em"
                      flexDirection="row"
                      overflowX="scroll"
                      padding="1em 0"
                    >

                      <DealershipDetailsCard
                        videoUrl={defaultImage}
                        videoType={"NA"} />

                    </Flex>
                  </Box>
                </Box>

                {/* Products */}
                <Box width="100%" className="dealership-main-container">
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Products
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box height={"100%"} display={"flex"} className="dealership-blur-pointer">
                    <Flex
                      gap="2em"
                      flexDirection="row"
                      overflowX="scroll"
                      padding="1em 0"
                    >
                      <DealershipProductCard
                        imageUrl={defaultImage} />

                    </Flex>
                  </Box>
                </Box>

              </div>
            )}

        </>
      )
    }
    else {
      return <p>No Dealership Available</p>
    }
  }

  return (
    <>
      <ConnectDealershipModal onAccept={handleContactOwner} />
      <MarketPlaceLayout>
        <div style={{ margin: "0 52px", marginTop: "110px" }}>

          <div>{renderDealershipDetail(assetData)}</div>
        </div>
      </MarketPlaceLayout>
    </>
  );
};

