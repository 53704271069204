import { Flex } from "@chakra-ui/react";

export const QuizStatusTag = ({ status }) => {
  return (
    <Flex
      borderRadius={"1.25em"}
      backgroundColor={status.toLowerCase()==="expired" ? "#1A1E2180": "#319F00A6"}
      padding={"3px 10px"}
      fontSize={"14px"}
      fontWeight={"600"}
      color={"#FEFEFE"}
    >
      {status}
    </Flex>
  );
};
