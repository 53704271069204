import React from 'react'
import likeIconUnliked from "../../../assets/webapp/marketplace/like-icon-unliked.svg"
import { subString } from '../../helpers/dataConversion/substring'


const TabReceivedCard = ({
    assetId = "",
    equipmentTypeValue = "",
    equipmentTypeId = "",
    oemName = "",
    modelName = "",
    isVerified = "",
    equipmentImage = "",
    city = "",
    state = "",
    receiverContactNumber = "",
    requestReceived = () => {},
    onLikeButtonClick = () => {},
    totalCount = "",
    isDealership="",
    isMaterial="",
    productName="",
    requestSentOn,
    requestCount = "",
    likesCount = "",

}) => {
    return (
        <div className='my-leads-tab-card-container'>
            <div className='my-leads-tab-card-status'>
            <p>{assetId}</p>
                    <div
                        className="my-leads-status-badge"
                        style={{ background: isVerified ? "#319F0060" : "#D6000060" }}
                    >
                        <p className="status-verification-badge-text">
                            {isVerified ? "Verified" : "Not Verified"}
                        </p>
                    </div>
            </div>

            <div className='my-leads-tab-card-body'>
                <div className='my-leads-tab-card-img-container'>
                    {isDealership === 'true' || isMaterial==="true"? 
                    <img src={equipmentImage ? `${process.env.REACT_APP_CDN_PVT_URL}${equipmentImage}` :`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/DelarshipDefaultImage.png`} alt='tab-img'  style={{width:"100%" , height:"120px"}}/> :
                    <img src={equipmentImage ? `${process.env.REACT_APP_CDN_PVT_URL}AssetsImage/${equipmentImage}` :`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/DelarshipDefaultImage.png`} alt='tab-img'  style={{width:"100%" , height:"120px"}}/>
                    }
                    </div>
                <div className='my-leads-tab-card-tab-details'>
                    <h1 className='my-leads-header'>{`${isMaterial==='true'? subString(25,productName)+" •":""}${equipmentTypeValue ? equipmentTypeValue :"NA"} •${oemName ? oemName : "NA"} •${modelName ? modelName : "NA"}`}</h1>
                    <h1 className='my-leads-location'>Location:  {city},{state}</h1>
                    {isMaterial && <h1 className='my-leads-location'>Request received on : {new Date(requestSentOn).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '-')}</h1>}
                    {/* <h1 className='my-leads-price'>{receiverContactNumber}</h1> */}
                    <div className='my-leads-tab-card-contact'>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px", justifyContent: "space-between", width: "100%" }}>
                            <div style={{width:"100%"}}>
                                <button className="my-leads-like-button" onClick={onLikeButtonClick} >
                                    <img src={likeIconUnliked} style={{height:"14px"}}/>
                                    {likesCount} Likes
                                </button>
                            </div>
                            <div style={{width:"100%"}}>
                                <button className="my-leads-request-button"  onClick={requestReceived} >
                                <img src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/phone.svg`} style={{height:"14px"}}/>
                                    {requestCount} {isDealership === 'true' ? "Connects" : "Request"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TabReceivedCard