import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../helpers/initialState/initialState";
import { setMobileNumberInLocalStorage, setSelectedBusinessTypeInLocalStore, setSelectedUserTypeInLocalStore } from "../helpers/localStorage/localStorage";
import { registrationThunk} from "../thunk/registrationThunk";


export const registrationSlice = createSlice({
    name : "registration",
    initialState,
    reducers : {
        setSelectedBusinessType : (state, action) => {
          state.selectedBusinessType = action.payload
          setSelectedBusinessTypeInLocalStore(action.payload)
        },

        setSelectedUserType : (state, action) => {
            state.selectedUserType = action.payload;
            setSelectedUserTypeInLocalStore(action.payload)
        },
        setRegistrationMobileNumber : (state, action) => {
            state.mobileNumber = action.payload;
            setMobileNumberInLocalStorage(action.payload)
        }
    },
    extraReducers: (builder) => {
        builder.addCase(registrationThunk.fulfilled, (state, action) => {
            state.selectedBusinessType = null;
            state.selectedUserType = null;
            state.mobileNumber = null
            setSelectedBusinessTypeInLocalStore("");
            setSelectedUserTypeInLocalStore("");
            setMobileNumberInLocalStorage("");
        })
    }
})

export const {setSelectedBusinessType, setSelectedUserType, setRegistrationMobileNumber} = registrationSlice.actions;
export default registrationSlice.reducer