import { createSlice } from "@reduxjs/toolkit";
import { getAllE9RolesThunk } from "../thunks/getAllE9RolesThunk";
import { createDocumentFlowThunk } from "../../login/thunks/createDocumentFlowThunk";
import {
  getLocalStorageItems,
  setLocalStorageItems,
} from "../../../helpers/localStorage/localStorage";
import { STORAGE_CONSTANTS } from "../../../../constants/storageConstants";

export const documentFlowSlice = createSlice({
  name: "documentFlow",
  initialState: {
    allE9Roles: null,
    selectedE9Role: null,
    hasTaxVerificationDocument: getLocalStorageItems(
      STORAGE_CONSTANTS.HAS_TAX_VERIFICATION_DOCUMENT
    )
      ? Boolean(
          parseInt(
            getLocalStorageItems(
              STORAGE_CONSTANTS.HAS_TAX_VERIFICATION_DOCUMENT
            )
          )
        )
      : false,
    taxVerificationNumber: getLocalStorageItems(
      STORAGE_CONSTANTS.TAX_VERIFICATION_DOCUMENT
    )
      ? getLocalStorageItems(STORAGE_CONSTANTS.TAX_VERIFICATION_DOCUMENT)
      : null,
    documentFlowArray: getLocalStorageItems(STORAGE_CONSTANTS.DOCUMENT_FLOW)
      ? getLocalStorageItems(STORAGE_CONSTANTS.DOCUMENT_FLOW)
      : null,
    documentPageIndex: parseInt(
      getLocalStorageItems(STORAGE_CONSTANTS.DOCUMENT_PAGE_INDEX)
    )
      ? parseInt(getLocalStorageItems(STORAGE_CONSTANTS.DOCUMENT_PAGE_INDEX))
      : 0,
      assetIdDocFlow : getLocalStorageItems(STORAGE_CONSTANTS.ASSET_ID),
    loading: false,
    success: false,
    error: null,
    errorMessage: null,
  },
  reducers: {
    setAllE9Roles: (state, action) => {
      state.allE9Roles = action.payload;
    },
    setSelectedE9Roles: (state, action) => {
      state.selectedE9Role = action.payload;
    },
    setHasTaxVerificationDocument: (state, action) => {
      state.hasTaxVerificationDocument = Boolean(parseInt(action.payload));
      setLocalStorageItems(
        STORAGE_CONSTANTS.HAS_TAX_VERIFICATION_DOCUMENT,
        action.payload
      );
    },
    setTaxVerificationNumber: (state, action) => {
      state.taxVerificationNumber = action.payload;
      setLocalStorageItems(
        STORAGE_CONSTANTS.TAX_VERIFICATION_DOCUMENT,
        action.payload
      );
    },
    setDocumentFlowIndex: (state, action) => {
      state.documentPageIndex = action.payload;
      setLocalStorageItems(
        STORAGE_CONSTANTS.DOCUMENT_PAGE_INDEX,
        action.payload
      );
    },
    setDocumentFlowArray: (state, action) => {
      state.documentFlowArray = action.payload;
      setLocalStorageItems(STORAGE_CONSTANTS.DOCUMENT_FLOW, action.payload);
    },
    increasePageIndex: (state) => {
      state.documentPageIndex += 1;
      setLocalStorageItems(
        STORAGE_CONSTANTS.DOCUMENT_PAGE_INDEX,
        state.documentPageIndex
      );
    },
    decreasePageIndex: (state) => {
      if (state.documentPageIndex) {
        state.documentPageIndex -= 1;
        setLocalStorageItems(
          STORAGE_CONSTANTS.DOCUMENT_PAGE_INDEX,
          state.documentPageIndex
        );
      }
    },
    setAssetIdDocFlow : (state, {payload}) => {
      state.assetIdParam =  payload;
        setLocalStorageItems(STORAGE_CONSTANTS.ASSET_ID, payload)
    },
    resetDocumentFlow: (state) => {
      state.documentFlowArray = null;
      state.documentPageIndex = null;
      state.assetIdDocFlow = null;
      setLocalStorageItems(STORAGE_CONSTANTS.DOCUMENT_PAGE_INDEX, null);
      setLocalStorageItems(STORAGE_CONSTANTS.DOCUMENT_FLOW, null);
      setLocalStorageItems(STORAGE_CONSTANTS.ASSET_ID, null);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllE9RolesThunk.pending, (state) => {
        state.allE9Roles = null;
        state.selectedE9Role = null;
        state.loading = true;
        state.success = false;
        state.error = false;
        state.errorMessage = null;
      })
      .addCase(getAllE9RolesThunk.rejected, (state, action) => {
        state.allE9Roles = null;
        state.selectedE9Role = null;
        state.loading = false;
        state.success = false;
        state.error = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllE9RolesThunk.fulfilled, (state, action) => {
        state.allE9Roles = action.payload;
        state.selectedE9Role = null;
        state.loading = false;
        state.success = true;
        state.error = false;
        state.errorMessage = null;
      })
      .addCase(createDocumentFlowThunk.pending, (state) => {
        state.allE9Roles = null;
        state.selectedE9Role = null;
        state.loading = true;
        state.success = false;
        state.error = false;
        state.errorMessage = null;
        state.documentFlowArray = null;
        state.documentPageIndex = null;
        setLocalStorageItems(STORAGE_CONSTANTS.DOCUMENT_FLOW, null);
        setLocalStorageItems(STORAGE_CONSTANTS.DOCUMENT_PAGE_INDEX, null);
      })
      .addCase(createDocumentFlowThunk.fulfilled, (state, action) => {
        state.documentFlowArray = action.payload;
        state.documentPageIndex = 0;
        setLocalStorageItems(STORAGE_CONSTANTS.DOCUMENT_FLOW, action.payload);
        setLocalStorageItems(STORAGE_CONSTANTS.DOCUMENT_PAGE_INDEX, 0);
      });
  },
});

export const {
  setAllE9Roles,
  setSelectedE9Roles,
  setDocumentFlowIndex,
  setHasTaxVerificationDocument,
  setTaxVerificationNumber,
  setAssetIdDocFlow,
  increasePageIndex,
  decreasePageIndex,
  setDocumentFlowArray,
  resetDocumentFlow,
} = documentFlowSlice.actions;

export default documentFlowSlice.reducer;
