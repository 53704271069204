import React from "react";
import { useSelector } from "react-redux";
export const ProfileVerification = () => {
  const userData = useSelector((state) => state.user);
  const BoUserType =
    userData?.accountDetails?.UserDetails?.BusinessTypeValue.toLowerCase();
  const BOUserTypeValue = "Business Owner".toLowerCase();
  const OpUserType =
    userData?.accountDetails?.UserDetails?.BusinessTypeValue.toLowerCase();
  const OpTypeValue = "Operator Partner".toLowerCase();

  const verification =
    userData?.accountDetails?.UserDetails?.E9VerificationMessage?.toLowerCase();
  const verificationByThirdParty = userData?.accountDetails?.UserDetails?.ThirdPartyVerificationMessage?.toLowerCase();
  const e9Verification = "Verified".toLowerCase();
  const isThirdPartyVerification = "Verified".toLowerCase();
  return (
    <>
      {verification !==e9Verification  || isThirdPartyVerification!== e9Verification  ? (
        <div className="Not-Verfied-tag">
          <p className="Verifi-tex">
            Your profile is not verified. Please complete and update your
            profile for verifications.
          </p>
        </div>
      ) : (
        <div className="Verfied-tag">
          <p className="Verifi-text">Your profile is verified.</p>
        </div>
      )}
    </>
  );
};

// || verificationByThirdParty ===isThirdPartyVerification
