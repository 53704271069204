import React, { useEffect,useState } from "react";
import { Input, List, ListItem, Skeleton } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import {
  setModalOpen,
} from "../../features/location/slice/slice";
import { v4 as uuid } from "uuid";
import { useGoogleMaps } from "../../hooks/useGoogleMaps";

export const PlaceSuggestions = ({ onChange = () => {} }) => {
  const [value, setValue] = useState(null);

  const {
    suggestionsList,
    getSuggestions,
    loadingSuggestions: suggestionsLoading,
    scriptLoaded,
    placeDetails,
    getPlaceDetails,
    loadingPlaceDetails: placeDetailsLoading,
  } = useGoogleMaps();
  
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (placeDetails) {
      handleValueChange();
    }
  }, [placeDetails]);

  const handleInputChange = async (e) => {
    setValue(e.target.value);
    if (e.target.value) {
      setShowSuggestions(true);
      await getSuggestions(e.target.value);
    }
  };

  const handleValueChange = () => {
    if (placeDetails && value) {
    //   console.log({ ...placeDetails, value });
      onChange({
          lat: placeDetails.location.lat,
          lng: placeDetails.location.lng,
          name: value,
        });
    }
  };

  //   useEffect(() => {});

  return (
    <div>
      {scriptLoaded ? (
        <Input
          type="text"
          onClick={() => dispatch(setModalOpen())}
          value={value}
          placeholder={
            placeDetailsLoading ? "Loading" : "Click to enter location"
          }
          onChange={handleInputChange}
        />
      ) : (
        <Skeleton />
      )}
      <List gap="0.25em">
        {suggestionsLoading ? (
          <ListItem
            _hover={{ bg: "gray.100" }}
            my={1}
            p={2}
            bgColor="#FFFFFF"
            fontSize="16px"
            cursor="pointer"
            fontStyle="italic"
            color="#2c2c2c80"
            textAlign="center"
          >
            Getting Suggestions ...
          </ListItem>
        ) : (
          suggestionsList &&
          showSuggestions &&
          value &&
          suggestionsList.map((suggestion) => (
            <ListItem
              key={uuid()}
              _hover={{ bg: "gray.100" }}
              my={1}
              p={2}
              bgColor="#FFFFFF"
              fontSize="18px"
              cursor="pointer"
              onClick={async (e) => {
                const selectedValue = suggestionsList.find(
                  (suggestion) => suggestion.description === e.target.innerText
                );
                setSelectedSuggestion(selectedValue.place_id);
                // console.log(selectedValue);
                setValue(selectedValue.description);
                setShowSuggestions(false);
                try {
                  const data = await getPlaceDetails(selectedValue.place_id);
                //   console.log(data);
                } catch (error) {
                //   console.log(error);
                } 
              }}
            >
              {suggestion.description}
            </ListItem>
          ))
        )}
      </List>
    </div>
  );
};
