import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPremiumRedeemCountById } from "../../../services/premium/premium";

export const getDealershipListingPremiumLeadsThunk = createAsyncThunk("/premiumLeads/dealershipListing", async() => {
    try{
      const data = await getPremiumRedeemCountById(7);
      return data;
    }
    catch(error){
        throw error
    }
})