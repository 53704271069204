import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllOperatorJobsWaitingForYou } from "../../../services/jobsPosted/jobsPosted";

export const getLoadMoreOperatorJobsThunk = createAsyncThunk(
  "op/loadMoreData",
  async (_, { getState }) => {
    const {cardCount ,paginitionCount } = getState().operatorJobsNearMe;
    try {
      const data = await getAllOperatorJobsWaitingForYou(cardCount, cardCount * paginitionCount);

      if (data.Status === 200) {
        return data.Data;
      }
    } catch (error) {}
  }
);
