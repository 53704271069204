import { useEffect, useRef, useState } from "react";

export const useGoogleMaps = () => {
  const [suggestionsList, setSuggestionsList] = useState(null);
  const [placeDetails, setPlaceDetails] = useState(null);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [loadingPlaceDetails, setLoadingPlaceDetails] = useState(false);
  const [error, setError] = useState(null);
  const autocompleteService = useRef(null);
  const placesService = useRef(null);
  const scriptLoaded = useRef(false);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const url = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_LOCATION_API_KEY}&libraries=places`;

      if (!scriptLoaded.current) {
        const script = document.createElement("script");
        script.src = url;
        script.async = true;
        script.defer = true;

        script.onload = () => {
          scriptLoaded.current = true;
          initServices();
        };

        document.body.appendChild(script);
      } else {
        initServices();
      }
    };

    loadGoogleMapsScript();

    return () => {
      // Cleanup script or other resources if needed
    };
  }, []);

  const initServices = () => {
    autocompleteService.current = new window.google.maps.places.AutocompleteService();
    placesService.current = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
  };

  const getSuggestions = async (input) => {
    setLoadingSuggestions(true);
    try {
      const predictions = await getAutocompletePredictions(input);
      setSuggestionsList(predictions);
    } catch (error) {
      setError(error);
    } finally {
      setLoadingSuggestions(false);
    }
  };

  const getPlaceDetails = async (placeId) => {
    setLoadingPlaceDetails(true);
    try {
      const data = await getPlaceDetailPromise(placeId);
      setPlaceDetails(data);
      return data;
    } catch (error) {
      setError(error);
      setPlaceDetails(null);
      return error;
    } finally {
      setLoadingPlaceDetails(false);
    }
  };

  const getAutocompletePredictions = (inputText) => {
    return new Promise((resolve, reject) => {
      autocompleteService.current.getPlacePredictions(
        {
          input: inputText,
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            resolve(predictions);
          } else {
            reject(status);
          }
        }
      );
    });
  };

  const getPlaceDetailPromise = (placeId) => {
    return new Promise((resolve, reject) => {
      placesService.current.getDetails(
        {
          placeId: placeId,
        },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            resolve({
              placeId: placeId,
              location: place.geometry.location.toJSON(),
            });
          } else {
            reject({ status, message: "Place details request failed" });
          }
        }
      );
    });
  };

  return {
    suggestionsList,
    placeDetails,
    loadingSuggestions,
    loadingPlaceDetails,
    error,
    getSuggestions,
    getPlaceDetails,
    scriptLoaded,
  };
};