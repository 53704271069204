import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetUserDealershipListCompany } from "../../../services/dealershipListing/dealershipListing";



export const dealershipListingCompanyInitialThunk = createAsyncThunk("dealershipListingCompanyInitial/getInitialCards",async(_,{getState}) =>{
    try {
        const userData = getState().user

        const data = await GetUserDealershipListCompany(12,0,userData?.accountDetails?.CompanyDetails?.TaxNumber);
        if(data?.Status === 200){
            return data?.Data?.UserDealershipList;
        }
        return null ;
    } catch (error) {
        
    }
})