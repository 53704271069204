import { convertToIdValuePairs } from "../../helpers/dataConversion/convertToIdValuePairs";
import fetchHandler from "../fetchHandler";

export const getAllResponseTypes = async () => {
  try {
    const { data } = await fetchHandler("/GetResponseTypeDetails");
    if (data.Status === 200 && Object.keys(data.Data).length > 0) {
      const requiredArray = data.Data.ResponseTypes.map(
        ({ ResponseTypeId, ResponseTypeValue }) =>(
          convertToIdValuePairs(ResponseTypeId, ResponseTypeValue))
      );
      return requiredArray;
    } else {
      const newError = new Error();
      newError.message = data.Message.FailMessage;
      throw newError;
    }
  } catch (error) {
    throw error;
  }
};
