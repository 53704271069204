import { Field, Formik } from "formik";
import { getOwnershipTypes } from "../../../services/equipments/equipments";
import { useEffect, useState } from "react";
import { RadioParent } from "../../../components/RadioParent/RadioParent";
import * as Yup from "yup";
import { FormikInput } from "../../../components/Input/FormikInput";
import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import { registerNumbersOnly } from "../../../../utils/form/form";
import { useDispatch, useSelector } from "react-redux";
import { FormikDropdown } from "../../../components/Dropdown/FormikDropdown";
import { getStatesById } from "../../../services/states/states";
import {
  setRegisteredCountry,
  setRegisteredState,
  setOwnershipStatus,
  increaseStepperIndex,
  setIsLoading,
} from "../slice/equipmentListingSlice";
import { createPurchasingDetailsThunk } from "../thunks/createPurchasingDetailsThunk";
import { useNavigate } from "react-router";
import { updateAssetPurchasingDetailsThunk } from "../thunks/updateAssetPurchasingDetailsThunk";
import { useSearchParams } from "react-router-dom";

export const EquipmentPurchaseDetailsForm = ({ editMode = false }) => {
  const [ownershipTypeList, setOwnershipTypeList] = useState(null);
  const [statesList, setStatesList] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const { Countries } = useSelector((state) => state.masterData);
  const {
    purchasingYear,
    registeredCountry,
    registeredState,
    ownerShipStatus,
    manufacturingYear,
    ownershipName,
    purchasingStateName,
    purchasingCountryName,
  } = useSelector((state) => state.addEquipment);
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const getStatesList = async (id) => {
    try {
      const data = await getStatesById(id);
      setStatesList(data);
      return data;
    } catch (error) {
      setStatesList(null);
    }
  };
  const getEquipmentOwnershipTypes = async () => {
    try {
      const data = await getOwnershipTypes();
      setOwnershipTypeList(data);
      return data;
    } catch (error) {}
  };
  const handlePostPurchaseDetails = async (body) => {
    try {
      const data = await dispatch(createPurchasingDetailsThunk(body)).unwrap();
      toast({
        status: "success",
        title: data.message,
        position: "top-right",
        isClosable: true,
      });
      dispatch(increaseStepperIndex());
    } catch (error) {
      toast({
        status: "error",
        title: error.message,
        position: "top-right",
        isClosable: true,
      });
    }
  };
  const handleUpdatePurchaseDetails = async ({assetId, assetDetails}) => {
    try {
      const data = await dispatch(
        updateAssetPurchasingDetailsThunk({assetId, assetDetails})
      ).unwrap();
      toast({
        status: "success",
        title: data.message,
        position: "top-right",
        isClosable: true,
      });
      dispatch(increaseStepperIndex());
    } catch (error) {
      toast({
        status: "error",
        title: error.message,
        position: "top-right",
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    getEquipmentOwnershipTypes();
  }, []);
  useEffect(() => {
    if (selectedCountry) {
      getStatesList(selectedCountry);
    } else {
      setStatesList(null);
    }
  }, [selectedCountry]);

  useEffect(() => {
    const getExistingOwnershipType = async (ownershipName) => {
      try {
        const data = await getEquipmentOwnershipTypes();
        // console.log(data);
        const selectedOwnershipType = data.find(
          ({ value }) => value === ownershipName
        );
        // console.log(selectedOwnershipType);
        dispatch(setOwnershipStatus(selectedOwnershipType.id));
      } catch (error) {
      }
    };
    if (editMode && ownershipName) {
      getExistingOwnershipType(ownershipName);
    }
  }, [ownershipName, editMode]);

  useEffect(() => {
    const getExistingCountry = async (countryName) => {
      try {
        const data = Countries.find(
          ({ CountryName }) => CountryName === countryName
        );
        if (data) {
          setSelectedCountry(data.CountryId);
          dispatch(setRegisteredCountry(data.CountryId));
        }
      } catch (error) {}
    };

    if (editMode && purchasingCountryName) {
      getExistingCountry(purchasingCountryName);
    }
  }, [purchasingCountryName]);

  //  for existing State
  useEffect(() => {
    const getExistingState = async (stateName) => {
      try {
        const data = await getStatesById(selectedCountry);
        const requriedState = data.find(({ value }) => value === stateName);
        if (requriedState) {
          dispatch(setRegisteredState(requriedState.id));
        }
      } catch (error) {}
    };

    if (editMode && selectedCountry && purchasingStateName) {
      getExistingState(purchasingStateName);
    }
  }, [purchasingStateName, selectedCountry]);
  return (
    <Box bgColor="#FFFFFF" padding="1em 0.75em">
      <Text
        color="#1E3264"
        textTransform="uppercase"
        fontSize="22px"
        fontWeight="600"
      >
        Purchasing Details
      </Text>
      <Formik
        initialValues={{
          _ownershipType: ownerShipStatus || "",
          _registeredCountry: registeredCountry || "",
          _registeredState: registeredState || "",
          _purchasingYear: purchasingYear ? parseInt(purchasingYear) : "",
        }}
        validationSchema={Yup.object({
          _purchasingYear: Yup.number().min(
            parseInt(manufacturingYear),
            `Purchasing year cannot be less than manufacturing year (${manufacturingYear})`
          ),
        })}
        onSubmit={async (values) => {
          dispatch(setIsLoading(true));
          let body = {}
          body.PurchasedPrice = "";
          if (values._ownershipType) {
            body.OwnershipTypeId = values._ownershipType;
            // dispatch(setOwnershipStatus(values._ownershipType));
          }
          if (values._registeredCountry) {
            body.CountryIdPurchasedFrom = values._registeredCountry;
            // dispatch(setRegisteredCountry(values._registeredCountry));
          }
          if (values._registeredState) {
            body.RegisteredStateId = values._registeredState;
            // dispatch(setRegisteredState(values._registeredState));
          }
          if (values._purchasingYear) {
            body.PurchasingYear = values._purchasingYear;
            // dispatch(setPurchasingYear(values._purchasingYear));
          }
          // this is update condition
          if (
            (purchasingYear ||
            registeredCountry ||
            registeredState ||
            ownerShipStatus ||
            ownershipName ||
            purchasingStateName ||
            purchasingCountryName) && searchParams.get("assetId")
          ) {
            await handleUpdatePurchaseDetails({
              assetId: searchParams.get("assetId"),
              assetDetails: body
            });
          } else if (
            values._ownershipType ||
            values._registeredCountry ||
            values._registeredState ||
            values._purchasingYear
          ) {
            await handlePostPurchaseDetails(body);
          } else {
            dispatch(increaseStepperIndex());
          }
          dispatch(setIsLoading(false));

        }}
        enableReinitialize={editMode}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <form onSubmit={handleSubmit} id="equipment-purchase-details">
            <Flex wrap="wrap" justifyContent="space-between" rowGap="1em">
              <FormikInput
                label="Purchase Year"
                name="_purchasingYear"
                onKeyDown={registerNumbersOnly}
                maxLength={4}
                maxWidth="450px"
                placeholder="Please enter purchasing year..."
              />
              <Field name="_ownershipType">
                {({ field }) => (
                  <RadioParent
                    name={field.name}
                    options={ownershipTypeList}
                    defaultValue={
                      values._ownershipType
                        ? parseInt(values._ownershipType)
                        : ""
                    }
                    label="Ownership"
                    isRequired={false}
                    isFormik={true}
                    maxWidth="450px"
                  />
                )}
              </Field>
              <FormikDropdown
                name="_registeredCountry"
                placeholder="Select"
                value={values._registeredCountry}
                isDisabled={!Countries}
                label="Registered Country"
                isRequired={false}
                maxWidth="450px"
                onChange={(e) => {
                  if (e.target.value) {
                    setFieldValue("_registeredCountry", e.target.value);
                    setSelectedCountry(e.target.value);
                  } else {
                    setFieldValue("_registeredCountry", "");
                    setSelectedCountry(null);
                  }
                  setFieldValue("_registeredState", "");
                }}
              >
                {Countries
                  ? Countries.map((country) => (
                      <option
                        value={country.CountryId}
                        key={country.CountryName}
                      >
                        {country.CountryName}
                      </option>
                    ))
                  : null}
              </FormikDropdown>

              <FormikDropdown
                name="_registeredState"
                placeholder="Select"
                value={values._registeredState}
                isDisabled={!statesList}
                isRequired={false}
                label="Registered State"
                onChange={(e) => {
                  setFieldValue("_registeredState", e.target.value);
                }}
                maxWidth="450px"
              >
                {statesList
                  ? statesList.map(({ id, value }) => (
                      <option value={id} key={value}>
                        {value}
                      </option>
                    ))
                  : null}
              </FormikDropdown>
            </Flex>
          </form>
        )}
      </Formik>
    </Box>
  );
};
