import { useCallback, useEffect, useState } from "react";
import likeIconUnliked from "../../../assets/webapp/marketplace/like-icon-unliked.svg";
import likeIcon from "../../../assets/webapp/marketplace/like-icon.svg";
import { debounce } from "lodash";
import { ReactSVG } from "react-svg";

export const Like = ({
  isLiked = true,
  height = "20px",
  width = "20px",
  onClick = () => {}
}) => {
  const [liked, setLiked] = useState(isLiked);
  const [buttonClicked, setButtonClicked] = useState(false);

 
  const debouncedOnClick = useCallback(
    debounce((newStatus,event) => {
      onClick(newStatus,event);
    }, 1000),
    []
  );

  
  const handleClick = (event) => {
    event.stopPropagation();
    const newStatus = !liked;
    setLiked(newStatus);
    setButtonClicked(true);
    debouncedOnClick(newStatus,event);
  };


  useEffect(() => {
    if (buttonClicked) {
      setButtonClicked(false);
    }
  }, [buttonClicked]);

  return (
    <button
      onClick={handleClick}
      className={liked ? "like-button" : "unliked-icon"}
    >
      <ReactSVG
        className="like-icon"
        src={liked ? likeIcon : likeIconUnliked}
        beforeInjection={(svg) => {
          svg.setAttribute("style", `height: ${height}; width: ${width}`);
        }}
        alt={liked ? "Liked" : "Not Liked"}
      />
    </button>
  );
};