import { useEffect, useState } from "react";
import { NewSideMenuLayout } from "../../../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { useSelector } from "react-redux";
import { CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  Box,
  Flex,
  Heading,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import SuccessImage from "../../../../../assets/webapp/subscription/success-desktop.png"
import { useNavigate } from "react-router-dom";
import {fetchHandler} from "../../../../../index";
import { getE9TransactionInvoice } from "../../../../services/subscription/subscription";
import { CustomInfoModal } from "../../../../components/CustomInfoModal/CustomInfoModal";


export const PaymentSuccess = () => {
  const { UserTypeValue, accountDetails } = useSelector((state) => state.user);
  const { orderId } = useSelector((state) => state.subscription);
  const [invoiceData, setInvoiceData] = useState(null);
  const [subscriptionsData, setSubscriptionsData] = useState(null)
  const navigate = useNavigate()
  const toast = useToast()
  const {defaultLanguage} = useSelector(state => state.masterData)
  useEffect(() => {
    if(UserTypeValue === "Company"){
    (async () => {
      try{
   
      const url =
        UserTypeValue.toLowerCase() === "Company".toLowerCase()
          ? `/GetCompanySubsTransHistory/1/0?TaxNumber=${btoa(
              `${accountDetails.CompanyDetails.TaxNumber}`
            )}`
          : "/GetUserSubscriptionTransactionHistory/1/0";
    

      const {data}=await fetchHandler(url);

      if(data.Status === 200){
        setSubscriptionsData(data.Data[0]);
        }
      else{
        return toast({
            status : "error",
            position: "top-right",
            title: "Error",
            description: `${data.Message.FailMessage}`
        })
    }
    }
    
    catch(error){
       return toast({
        status : "error",
        position: "top-right",
        title: "Error",
        description: "Unable to fetch Invoice"
       })
    }
    })();
}
  }, []);

  useEffect(() => {
    if(UserTypeValue !==  "Company"){
    (async () => {
  
      const data = await getE9TransactionInvoice(orderId.VirtualSubsOrderId,1);

      if(data.Status === 200)
      setInvoiceData(data.Data);
    })();
}
  }, []);
  return (
    <NewSideMenuLayout>
      <Modal isOpen={true} isCentered>
        <ModalOverlay />
        <ModalContent>
        <Box 
          position={"absolute"} 
          top={"-20px"} 
          right={"15px"} 
         >
            <Button
              onClick={() => {
                if (invoiceData || subscriptionsData) {
                  window.open(
                   UserTypeValue === "Company" ? `${process.env.REACT_APP_CDN_PVT_URL}${subscriptionsData.InvoiceFilePath}${subscriptionsData.InvoiceFileRef}` : `${process.env.REACT_APP_CDN_PVT_URL}${invoiceData.InvoiceURL}${invoiceData.InvoiceData[0].InvoiceFileRef}`
                  );
                }
                navigate("/dashboard")
              }}
              boxShadow="0px 1.5px 3px 0px #0000000F"
              borderRadius={"50%"}
              backgroundColor={"white"}
              height={"42px"}
              width={"42px"}
            >
              <CloseIcon width={"9px"} stroke={"#141619"} />
            </Button>
          </Box>
          <ModalBody>
            <Flex alignItems="center" justifyContent="center" gap="1em" direction="column" minH="200px">
              <img style={{height: "55px", width: "120px"}} src={SuccessImage} alt="payment-successful"/>
              <Heading as="h1" fontSize="34px" lineHeight="40px" fontWeight="700" color="#2c2c2c">Congratulations</Heading>
              <Flex direction="column" gap= "0.5em" alignItems="center" justifyContent="center">
              <Text as="p" fontSize="18px" color="#2c2c2cCC">Payment Successful</Text>
              <Text as="p" fontSize="14px" color="#2c2c2cCC">Invoice will be auto-downloaded once you click on Continue</Text>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter justifyContent="center" alignItems="center" boxShadow="0px -10px 15px #2C2C2C0F">
           
            <Button
              variant="gradient"
              width="80%"
              onClick={() => {
                if (invoiceData || subscriptionsData) {
                  window.open(
                   UserTypeValue === "Company" ? `${process.env.REACT_APP_CDN_PVT_URL}${subscriptionsData.InvoiceFilePath}${subscriptionsData.InvoiceFileRef}` : `${process.env.REACT_APP_CDN_PVT_URL}${invoiceData.InvoiceURL}${invoiceData.InvoiceData[0].InvoiceFileRef}`
                  );
                }
                navigate("/dashboard")
              }}
            >
              {defaultLanguage?.Continue}
            </Button>
           
          </ModalFooter>
        </ModalContent>
      </Modal>
    </NewSideMenuLayout>
  );
};
