import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../constants/initialState";


export const myBusinessFinanceSlice = createSlice({
    name : "myBusinessFinance",
    initialState : initialState,
    reducers: {
        setFinanceData : (state,action) =>{
            state.financeData = action.payload
        },
    
        //step1
        setFinanceInstitutionName : (state , action)=>{
         state.financeInstitutionName = action.payload
        },
        setFinanceLocation : (state , action)=>{
            state.financeLocation = action.payload
        },

        setFinanceContact : (state , action)=>{
            state.mobileNumber = action.payload
        },
        setFinanceEmail : (state,action) =>{
            state.email = action.payload
        },
        setFinanceWebsite : (state,action) =>{
            state.website = action.payload
        },
        setFinanceType : (state,action) =>{
            state.financeType = action.payload
        },
     
      //step2

        setIndustryType : (state,action) =>{
        state.industryType = action.payload
        },

        setRegulatoryStatus : (state,action) =>{
        state.regulatoryStatus = action.payload
        },
        
        setServiceDescription : (state,action) =>{
            state.serviceDescription = action.payload
        },
        setTermsAndCondition: (state,action) =>{
            state.termsAndConditions = action.payload
        },

        //reset
        resetFinanceState: () => initialState,
        
    },

});

export const {
    setFinanceData,

    setFinanceInstitutionName,
    setFinanceLocation,
    setFinanceContact,
    setFinanceEmail,
    setFinanceWebsite,
    setFinanceType,

    setIndustryType,
    setRegulatoryStatus,
    setServiceDescription,
    setTermsAndCondition,

    resetFinanceState
} = myBusinessFinanceSlice.actions