import { CloseIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Box, Button } from "@chakra-ui/react";

export const CustomModal = ({
  isOpen = false,
  hasCloseButton = false,
  onCloseButtonClick = () => {},
  children,
  ...rest
}) => {
  return (
    <Modal isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent {...rest}>
        {hasCloseButton && (
          <Box 
          position={"absolute"} 
          top={"-20px"} 
          right={"15px"} 
         >
            <Button
              onClick={onCloseButtonClick}
              boxShadow="0px 1.5px 3px 0px #0000000F"
              borderRadius={"50%"}
              backgroundColor={"white"}
              height={"42px"}
              width={"42px"}
            >
              <CloseIcon width={"9px"} stroke={"#141619"} />
            </Button>
          </Box>
        )}
        {children}
      </ModalContent>
    </Modal>
  );
};

const CustomModalHeader = ({ children, ...rest }) => {
  return <ModalHeader {...rest}>{children}</ModalHeader>;
};

const CustomModalBody = ({ children, ...rest }) => {
  return <ModalBody {...rest}>{children}</ModalBody>;
};

const CustomModalFooter = ({ children, ...rest }) => {
  return <ModalFooter {...rest}>{children}</ModalFooter>;
};

CustomModal.Header = CustomModalHeader;
CustomModal.Body = CustomModalBody;
CustomModal.Footer = CustomModalFooter;
