import "./ShowStorelist.css";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { GetMaterialStore } from "../../../../ApiEquip9/CommonApi";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";

export const ShowStoreList = () => {
  const userData = useSelector((state) => state.user);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  /**************use Query*****************/
  const {
    data: getmaterialstore,
    isError,
    isLoading,
  } = useQuery("getmaterialStore", () => GetMaterialStore(userData.token));

  /******************Rendering list*******/
  const result = getmaterialstore?.data?.Data?.MaterialStore;
  // console.log("result", result);

  const listresult = getmaterialstore?.data?.Data?.MaterialStore.length;

  // console.log("result",result);


  return (
    <>
      {listresult > 0 ? (
        <div className="show-card-store-option-layout">
          {result.map((item) => (
            <div className="show-card-store-layout">
              <div className="show-image-card">
                <img
                  className="show-img-config"
                  src={`${process.env.REACT_APP_CDN_PVT_URL}MobileAppAssets/Material/${item.MaterialTypeIconFileRef}`}
                />
              </div>
              <div className="show-information-layout">
                <div>
                  <p className="Spare-Parts-Store">{item?.MaterialTypeValue}</p>
                  <p className="quantity-added">{item?.AddedOnStore} Added </p>
                  <p className="added-on-market-place">
                    {item?.AddedOnMarketplace} On Marketplace
                  </p>
                  <div className="added-on-seprator"></div>
                  <div
                    className="view-store-button"
                    onClick={() =>{
                         localStorage.setItem("EditMaterialTypeId",convertToBase64 (item?.MaterialTypeId))
                      navigate(`/material/${convertToBase64 (item?.MaterialTypeId)}`, {
                        state: { MaterailTypeValue: item.MaterialTypeValue },
                      });
                      localStorage.setItem('MaterialTypeId',item?.MaterialTypeId);
                      localStorage.setItem('MaterailTypeValue',item.MaterialTypeValue)
                    }
                      
                      
                    }
                  >
                    <p className="store-view-text">View</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <div style={{display:'flex',
          justifyContent:"center",
          alignItems:"center"}} >
           <p className="no-store-created-yet">No Store Created Yet</p>
          </div>
        </>
      )}
    </>
  );
};



