import React, { useEffect, useState } from "react";
import { SideMenuLayout } from "../../layout/sideMenuLayout/SideMenuLayout";
import { DashboardNavBar } from "../../components/DashboardNavBar/DashboardNavBar";
import { MidSection } from "../../../sharedComponent/midSection/MidSection";
import { FormControl, FormLabel, Switch, useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData";
import { GetPrivacyOptionsForUser, SetUserPrivacySettings } from "../../services/settings/settings";
import { V5SidebarLayout } from "../../layout/V5SidebarLayout/V5SidebarLayout";
export const PrivacySettings = () => {
  const userData = useSelector((state) => state.user);
  const [userPrivacySetting, setUserPrivacySetting] = useState();
  const [body, setBody] = useState({});
  const toast = useToast();
  const checkProfile = userData?.BusinessTypeValue.toLowerCase();
  const handleToggle = (isChecked) => {
  const updatedBody = { ...body, IsPrivacyEnabled: isChecked ? 1 : 0 };
    setPrivacySettings(updatedBody);
    setBody(updatedBody);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetPrivacyOptionsForUser();
        setUserPrivacySetting(data?.Data);
        const { PrivacyOptionTypeId, IsPrivacyEnabled } =
          data?.Data?.PrivacyOptionList[0];
        const newData = {
          PrivacyOptionTypeId,
          IsPrivacyEnabled,
        };
        setBody(newData);
      } catch (error) {
      }
    };
    fetchData();
  }, [userData.token]);
  const setPrivacySettings = async (updatedBody) => {
    try {
      const data = await SetUserPrivacySettings(updatedBody);

      if (data?.Status === 204) {
        toast({
          status: "success",
          description: "Prefrence Saved Successfully",
          position: "top-right",
          isClosable: true,
          duration: 5000,
        });
      } else {
        toast({
          status: "error",
          description: "Error Saving Prefrence",
          position: "top-right",
          isClosable: true,
          duration: 5000,
        });
      }
    } catch (error) {
    }
  };
  return (
        <V5SidebarLayout>
          <div>
            <h5 className="collapsed-div-header">Document Privacy</h5>
            <p
              style={{
                alignSelf: "flex-start",
                marginLeft: "2%",
                fontSize: "18px",
                font: "normal normal normal 18px/28px Assistant",
                letterSpacing: "0px",
                color: "#2C2C2CCC",
                opacity: "1",
              }}
            >
              Choose to make your documents private in Marketplace.
            </p>
            <div className="privacy-setting-container">
              <FormControl
                className="collapsed-div-field"
                display="flex"
                alignItems="center"
              >
                <FormLabel htmlFor="collapsed-div-field" mb="0" color={"#002961"}>
                  Marketplace Document
                </FormLabel>

                {checkProfile === "Operator Partner".toLowerCase() ||
                checkProfile === "Maintenance Partner".toLowerCase() ? (
                  <>
                    {" "}
                    <p>No records found</p>{" "}
                  </>
                ) : (
                  <Switch
                    id="collapsed-div-field"
                    isChecked={body.IsPrivacyEnabled}
                    onChange={(e) => {
                      handleToggle(e.target.checked);
                    }}
                  />
                )}
              </FormControl>
            </div>
          </div>
          </V5SidebarLayout>
  );
};
