import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const PublicRoute = ({children}) => {
    const {token, isAuthenticated} = useSelector((state) => (state.user))
    
    if (token && isAuthenticated) {
        return <Navigate to='/dashboard' />
      }
    
      return children;
}