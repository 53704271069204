import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_CONSTANTS } from "../../../../constants/apiConstants";
import { getDocument } from "../../login/promises/individualDataPromise";
import {fetchHandler} from '../../../../index'

export const getDashboardDetailsThunk = createAsyncThunk(
  "dashboard/getDashboardDetails",
  async (_, { getState }) => {
    try {
      const {data}= await fetchHandler(API_CONSTANTS.GET_DASHBOARD_DETAILS)
      const urls = data.Data.DashboardDetails.map((item) => {
        if (item.DashboardCategoryValue && item.DashboardCategoryValue.toLowerCase() === "sticky") {
          return getDocument(`/${item.APIName}${item.APIParam}`);
        }
        return getDocument(`/${item.APIName}`);
      });
      const parallelCalls = await Promise.allSettled(urls);
      const dashboadObjectWithE9Response = data.Data.DashboardDetails.map(
        (item, index) => {
          item.status = parallelCalls[index].status;
          if (item.status === "rejected") {
            item.ImageBaseUrl = null;
            item.Data = null;
          } else if (
            item.DashboardCategoryValue.toLowerCase() ===
              "Sticky".toLowerCase() &&
            parallelCalls[index].value.Data.StickyDetails
          ) {
            item.ImageBaseUrl = parallelCalls[index].value.Data.ImageBaseUrl;
            item.Data = parallelCalls[index].value.Data.StickyDetails[0];
          }
          else if ( item.DashboardCategoryValue.toLowerCase() ===
          "MarketPlace".toLowerCase() && parallelCalls[index].value.Data.MarketPlaceDetails){
            item.ImageBaseUrl = parallelCalls[index].value.Data.ImageBaseReference;
            item.Data = parallelCalls[index].value.Data.MarketPlaceDetails;
          }
          else if (item.DashboardCategoryValue.toLowerCase() ===
          "Jobs".toLowerCase() && parallelCalls[index].value.Data.JobsDetails && parallelCalls[index].value.Data.JobsDetails.length > 0 ){
            item.ImageBaseUrl = null;
            item.Data = parallelCalls[index].value.Data.JobsDetails;
          }
          else if(item.DashboardCategoryValue.toLowerCase() === "NearMe".toLowerCase() && parallelCalls[index].value.Data.NearMeDetails &&  parallelCalls[index].value.Data.NearMeDetails.length > 0){
            item.ImageBaseUrl = parallelCalls[index].value.Data.ImageBaseUrl;
            item.Data = parallelCalls[index].value.Data.NearMeDetails;
          }
          else if (parallelCalls[index].value.Data.TotalRecords && parallelCalls[index].value.Data.TotalRecords === 0){
            item.ImageBaseUrl = null;
            item.Data = null;
          }
          else{
            item.ImageBaseUrl = null;
            item.Data = null;
          }

          return item;
        }
      );
     
     
      return dashboadObjectWithE9Response
    } catch (error) {
    }
  }
);
