import { createAsyncThunk } from "@reduxjs/toolkit";
import { convertBase64ToFile } from "../../../../utils/formatConverter/convertToBase64";
import { createDealershipImageDetails } from "../../../services/dealershipListing/dealershipListing";

export const postDealershipImageThunk = createAsyncThunk(
    "myBusiness/postDealershipStepTwo",  (_, { getState }) => {

        const { postGuuid } = getState().myBusinessDealership;
        const { token } = getState().user;
        const imagesData = localStorage.getItem("POST_DEALERSHIP_IMAGE_PAYLOAD")
        try {

            if (imagesData.length > 0) {
                const response = JSON.parse(imagesData);
                for (let i = 0; i < response.length; i++) {
                    (async()=>{

                    const payload = {
                        DealersShipGuid: postGuuid,
                        ImageTypeId: response[i].selectedPhotoId,
                        ImageFile: convertBase64ToFile(response[i].file,response[i].photoTypeValue),
                    };

                     const data  = await createDealershipImageDetails(payload)
                    if (data?.Status === 201) {
                        return { message: data?.Message?.SuccessMessage }
                    } else {
                        return { message: data?.Message?.FailMessage }
                    }
                    })()                                       
                }
            }else{
            }

        } catch (error) {
            throw error
        }
    }
)