import {
    Modal,
    ModalContent,
    ModalOverlay,
    ModalFooter,
    ModalHeader,
    ModalBody,
  } from "@chakra-ui/modal";
  import { Flex, Text } from "@chakra-ui/layout";
  import { Button } from "@chakra-ui/button";
  import { Heading, Box } from "@chakra-ui/react";
  import { useDispatch, useSelector } from "react-redux";
  import { useToast } from "@chakra-ui/toast";
  import { imageSelector } from "../../../../../../helpers/categoryImageHandler/categoryImageHandler";
import { setSelectedAccount } from "../../../../slice/forgotPasswordSlice";
import { handlePasswordUpdateThunk } from "../../../../thunk/handlePasswordUpdateThunk";
import { hashToMd5 } from "../../../../../../helpers/dataConversion/convertToMd5";
import { V5Card } from "../../../../../../components/VrCard/V5Card";
import { subString } from "../../../../../../helpers/dataConversion/substring";
import { useRef } from "react";

  
  export const ChooseAccountModalPassword = () => {
    const forgotPasswordData = useSelector((state) => state.forgotPassword);
    const dispatch = useDispatch();
    const toast = useToast(); 
    const cardRefs = useRef([]);
  
    const cardClickHandler = (e) => {
      const selectCard = forgotPasswordData.accountList.find(
        (account) => account.BusinessTypeValue === e.target.value
      );
      dispatch(setSelectedAccount(selectCard))
    };

    const handleRefClick = (e, index) => {
      e.preventDefault();
      const selectedBusinessType = forgotPasswordData.accountList.find(
        (item) =>
          item.BusinessTypeValue.toLowerCase() ===
          cardRefs.current[index].value.toLowerCase()
      );
      dispatch(setSelectedAccount(selectedBusinessType))
      e.stopPropagation();
    };



    // const continueButtonClickHandler = async() => {
    //  const response = await dispatch(handleUpdatePassword)
    // }
  
    return (
      <Modal isCentered isOpen={forgotPasswordData.isContinueModalOpen} _css={{minHeight: "800px"}}>
        <ModalOverlay />
        <ModalContent maxW="800px" w="100%">
          <ModalHeader>
            <Text fontWeight="bold" fontSize="2xl">
              Continue login as:
            </Text>
          </ModalHeader>
          <ModalBody maxW="800px" w="100%">
            <Flex
              // flexDirection="column"
              maxWidth="90%"
              margin="0 auto"
              gap="1em"
              overflowX="scroll"
              w="100%"
              justifyContent="center"
              padding="0.5em 0"
            >
              {forgotPasswordData.accountList?.map((accountList, index) => {
                const { BusinessTypeValue, UserCredentialId } = accountList;
                return (
                  <>
                   <V5Card
                    boxShadow="0px 0px 8px 0px #00000011"
                    value={BusinessTypeValue}
                    key={UserCredentialId}
                    // ref={(ref) => (cardRefs.current[index] = ref)}
                    // isSelected={
                    //   BusinessTypeValue ===
                    //   registrationData.selectedBusinessType?.BusinessTypeValue
                    // }
                    ref={(ref) => (cardRefs.current[index] = ref)}
                    isSelected={
                      forgotPasswordData.selectedAccount?.BusinessTypeValue ===
                      BusinessTypeValue
                        ? true
                        : false
                    }
                    onClick={(e) => handleRefClick(e, index)}
                  >
                    <Flex
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="space-around"
                      maxHeight={[
                        "193.89px",
                        "171.69px",
                        "171.69px",
                        "211pxpx",
                        "211px",
                      ]}
                      height={"100%"}
                      minHeight={[
                        "193.89px",
                        "171.69px",
                        "171.69px",
                        "211pxpx",
                        "211px",
                      ]}
                      maxWidth={[
                        "173.68px",
                        "153.75px",
                        "154.6px",
                        "190px",
                        "191px",
                      ]}
                      width="95%"
                      minWidth={[
                        "173.68px",
                        "153.75px",
                        "154.6px",
                        "190px",
                        "191px",
                      ]}
                      padding="0.5em 0.75em"
                      onClick={(e) => handleRefClick(e, index)}
                    >
                    <Box maxW="70px" maxH="70px">
                      {imageSelector(
                        BusinessTypeValue,
                        // (e) => {
                        //   handleRefClick(e, index);
                        // },
                        { height: "48px", width: "48px" }
                      )}
                      </Box>
                      <Heading
                        width="fit-content"
                        fontSize="xl"
                        fontWeight="semibold"
                        onClick={(e) => handleRefClick(e, index)}
                      >
                        {subString(25, BusinessTypeValue)}
                      </Heading>
                      {/* <Text
                        width="fit-content"
                        fontSize="xs"
                        // onClick={(e) => handleRefClick(e, index)}
                      >
                        {subString(30, BusinessTypeDescription)}
                      </Text> */}
                    </Flex>
                  </V5Card>
                  {/* <SelectCardWrapper
                    key={UserCredentialId}
                    fontWeight="600"
                    fontSize="20px"
                    isSelected={
                      forgotPasswordData.selectedAccount?.BusinessTypeValue ===
                      BusinessTypeValue
                        ? true
                        : false
                    }
                    value={BusinessTypeValue}
                    icon={imageSelector(BusinessTypeValue)}
                    onClick={cardClickHandler}
                  >
                    {BusinessTypeValue}
                  </SelectCardWrapper> */}
                  </>
                );
              })}
            </Flex>
          </ModalBody>
  
          <ModalFooter
            h="78px"
            justifyContent="center"
            alignItems="center"
            boxShadow="0px -10px 15px 0px #2C2C2C0F"
          >
            <Button
              maxW="347px"
              w="100%"
              variant="gradient"
              isDisabled={forgotPasswordData.selectedAccount ? false : true}
              onClick={() => {
                if (forgotPasswordData.selectedAccount) {
                 dispatch(handlePasswordUpdateThunk(hashToMd5(forgotPasswordData.newPassword)))
                }
              }}
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };
  