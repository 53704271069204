import { useEffect, useState } from "react";

/**
 * Custom React hook for retrieving the current geolocation.
 *
 * @returns {Object} An object containing latitude, longitude, error state, and the function to trigger geolocation.
 * @property {number|null} latitude - The latitude of the current location, or null if not available.
 * @property {number|null} longitude - The longitude of the current location, or null if not available.
 * @property {string|null} error - A string describing any errors that occurred during geolocation, or null if no errors.
 * @property {function} handleGeoLocation - A function to trigger the geolocation process.
 * @example
 * // Usage in a React component
 * const { latitude, longitude, error, handleGeoLocation } = useCurrentLocation();
 * // You can use latitude, longitude, and error in your component based on the geolocation results.
 * // Call handleGeoLocation whenever you want to trigger the geolocation process.
 */

export const useCurrentLocation = () => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState(null);

//   useEffect(() => {
//     handleGeoLocation();
//   }, []);

  const handleGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (location) => locationHandling(location.coords),
        (error) => {
          setError(handleError(error));
          setLongitude(null);
          setLatitude(null);
        }
      );
    } else {
      setError("Permission Not Available");
    }
  };

  const locationHandling = (position) => {
    if (position && navigator.geolocation) {
      setLongitude(position.longitude);
      setLatitude(position.latitude);
      setError(null);
    }
  };

  const handleError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        return "User denied the request for Geolocation.";

      case error.POSITION_UNAVAILABLE:
        return "Location information is unavailable.";

      case error.TIMEOUT:
        return "The request to get user location timed out.";

      case error.UNKNOWN_ERROR:
        return "An unknown error occurred.";
      default:
        return null;
    }
  };

  return { longitude, latitude, error, handleGeoLocation };
};
