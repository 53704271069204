import React,{useEffect,useState} from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ReactSVG } from "react-svg";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { editIncreaseStepperIndex } from "./EditMaterialSlice/EditMaterialSlice";
import { LocationInput } from "../../../../../LocationInput/LocationInput";

 /***** */
 import { SideMenuLayout } from "../../../../../../layout/sideMenuLayout/SideMenuLayout";
 import { DashboardNavBar } from "../../../../../DashboardNavBar/DashboardNavBar";
 import { ShowPhotos } from "../AddPhotos/ShowPhotos.";
 import { EditShowPhotos } from "../AddPhotos/EditShowPhotos";
 import { EditShowVideos } from "../AddPhotos/EditShowVideos";
 import { ShowVideos } from "../AddPhotos/ShowVideos";
 import { weightDimension,measureDimension } from "../../../../../../../staticData/staticData";
 import {useToast} from '@chakra-ui/react';
import { editClearMaterialData,editClearStepperIndex } from "./EditMaterialSlice/EditMaterialSlice";
import { clearIndexedDBData } from "../../../../../../../staticData/CommonMethod";
import { MaterailPhotoUpload } from "../AddPhotos/AddsPhotopopup";
import { MaterailVideoUpload } from "../AddPhotos/AddVideosPop";
import { convertFromBase64 } from "../../../../../../../utils/formatConverter/convertToBase64";
import { getMaterialAdditionalDetails, updateMaterialStep2 } from "../../../../../../services/materials/materials";
/*****Validation Schema */
export const EditMaterialPlaceSteps2 = () => {
  const dispatch = useDispatch();
  const [isMaterialStorePopupOpen, setIsMaterialStorePopupOpen] =
    useState(false);
  const [isMaterialStoreVidosPopupOpen, setisMaterialStoreVidosPopupOpen] =
    useState(false);
  const userData = useSelector((state) => state.user);
  const [selectWeight, setSelectWeight] = useState(null);
  const [isLength, setisLength] = useState(null);
  const [isWidth, setisWidth] = useState(null);
  const [isHeight, setisHeight] = useState(null);
  const [isRadius, setisRadius] = useState(null);
  const [refreshVideos, setRefreshVideos] = useState(0);
  const [editMaterialStep2,seteditMaterialStep2]=useState(null);
  const prams=useParams();
  // console.log(prams,"params");
  const { MaterialId } = useParams();
  
  // console.log("MaterialId",MaterialId)
  const [photoUploaded, setPhotoUploaded] = useState(false);
  const [length,setlength]=useState(null);
  const [weight,setweight]=useState(null);
  const [width,setwidth]=useState(null);
  const [radius,setradius]=useState(null);
  const [height,setheight]=useState(null);
  const [additionalDetails,setadditionalDetails]=useState(null);
  const {MaterialTypeStperId}=useParams();
  // console.log("MaterialTypeStperId",MaterialTypeStperId)
  const deCodeed=convertFromBase64(MaterialTypeStperId );
// console.log(deCodeed,"deCodeed")
 /**** Get Material Step 2 ***/
 useEffect(()=>{
  const fetchMaterialStep2=async()=>{
    try{
      // console.log("MaterialTypeStperId",MaterialTypeStperId)
      const response=await getMaterialAdditionalDetails(parseInt( deCodeed));
        seteditMaterialStep2(response?.data?.Data?.MaterialData[0]);
        return response;
    }
    catch(err){
      throw new Error(err);
    }
  }
  fetchMaterialStep2();
 },[]);


 useEffect(()=>{
  // console.log("editMaterialSteps",editMaterialStep2)
    if(editMaterialStep2){
      setlength(editMaterialStep2.LengthUnit);
      setweight(editMaterialStep2?.WeightUnit);
      setwidth(editMaterialStep2?.WidthUnit);
      setradius(editMaterialStep2?.RadiusUnit);
      setheight(editMaterialStep2?.HeigthtUnit);
    }
 },[editMaterialStep2])

//  console.log({length,weight,width,radius,height})
 
  const handlePhotoUpload = () => {
      setPhotoUploaded(true);
  }

const toast = useToast();
const navigate = useNavigate();

const triggerRefresh = () => {
  setRefreshVideos((prev) => prev + 1); // Increment the counter
};
  const Front = localStorage.getItem(`photoType_1`);
  const Back = localStorage.getItem(`photoType_2`);
  const Other = localStorage.getItem(`photoType_3`);
  const Left = localStorage.getItem(`photoType_4`);
  const Right = localStorage.getItem(`photoType_5`);

   const validateLength = (Length, lengthDimension) => {
    let error;
    if (isNaN(Length)) {
      error = 'Length  must be a number';
    }
    else if (Length && Length.length > 3) {
      error = 'Length must be less than 1000';
    }
    else if (Length && Length.length <= 3 && (!lengthDimension || lengthDimension === "Select")) {
      error = 'Length dimension is required';
    }
    return error;
   };

  const validateWeight = (weight, weightDimension) => {
    let error;
    if (isNaN(weight)) {
      error = 'Weight must be a number';
    }
    else if (weight && weight.length > 3) {
      error = 'Weight must be less than 1000';
    }
    else if (weight && weight.length <= 3 && (!weightDimension || weightDimension === "Select")) {
      error = 'Weight dimension is required';
    }
    return error;
  };
  // WidthDimension heightDimension RadiusDimesion
  const validateWidthDimension=(width,WidthDimension)=>{
    let error;
    if (isNaN(width)) {
      error = 'width must be a number';
    }
    else if (width && width.length > 3) {
      error = 'Width must be less than 1000';
    }
    else if (width && width.length <= 3 && (!WidthDimension || WidthDimension === "Select")) {
      error = 'Width dimension is required';
    }
    return error;
  }

  const validateheight=(height,heightDimension)=>
  {
    let error;
    if (isNaN(height)) {
      error = 'Height must be a number';
    }
    else if (height && height.length > 3) {
      error = 'Height must be less than 1000';
    }
    else if (height && height.length <= 3 && (!heightDimension || heightDimension === "Select")) {
      error = 'Height  dimension is required';
    }
    return error;
  }


  const validateRadiusDimesion=(radius,RadiusDimesion)=>
  {
    let error;
    if (isNaN(radius)) {
      error = 'Radius must be a number';
    }
    else if (radius && radius.length > 3) {
      error = ' Radius must be less than 1000';
    }
    else if (radius && radius.length <= 3 && (!RadiusDimesion || RadiusDimesion === "Select")) {
      error = 'Radius dimension is required';
    }
    return error;
  }




  // *******upload Video */
  const handleUploadComplete = () => {
    setRefreshVideos(true);
  };

  const onCheckImage = () => {
    const images = [
      localStorage.getItem(`photoType_1`),
      localStorage.getItem(`photoType_2`),
      localStorage.getItem(`photoType_3`),
      localStorage.getItem(`photoType_4`),
      localStorage.getItem(`photoType_5`),
    ];
    if (images.every((image) => image === null)) {
      toast({
        title: "Error",
        description: "Please upload at least one photo.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return false;
    }
    return true;
  };

  const onreset = () => {
    localStorage.removeItem(`photoType_1`);
    localStorage.removeItem(`photoType_2`);
    localStorage.removeItem(`photoType_3`);
    localStorage.removeItem(`photoType_4`);
    localStorage.removeItem(`photoType_5`);
  };

  const handleSubmiteditStep2 = async (value) => {
  
    // if (!onCheckImage()) {
    //   return;
    // }
    const payload = {
      MaterialDetails:{
        MaterialId: parseInt( MaterialTypeStperId),
      Weight:  editMaterialStep2?editMaterialStep2?.Weight: parseInt(value.ProductWeight || null),
      WeightUnit: weight || null,
      SLength:  editMaterialStep2?editMaterialStep2?.SLength: parseInt(value.ProductLength) || null,
      LengthUnit: length || null,
      Width: editMaterialStep2?editMaterialStep2?.Width:parseInt(value.ProductWidth) || null,
      WidthUnit: width || null,
      Height: editMaterialStep2?editMaterialStep2?.Height:parseInt(value.ProductHeight) || null,
      HeigthtUnit: height || null,
      Radius: editMaterialStep2?editMaterialStep2?.Radius:parseInt(value.ProductRadius) || null,
      RadiusUnit: radius || null,
      AdditionalDetail: editMaterialStep2? editMaterialStep2.AdditionalDetails:value.additionaldetails || null,
      }
    };
    const payloadObj = JSON.stringify(payload);
    const data = await updateMaterialStep2(payloadObj);
    try {
      if (data.Status === 204) {
        toast({
          title: "",
          description: `Material Updated Successfully`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });

        // dispatch(clearStepperIndex());
        // dispatch(clearMaterialData());
        sessionStorage.clear();
        localStorage.removeItem("MaterialTypeId")
        onreset();
        //  await clearIndexedDBData();
         navigate("/materials-store-list", { replace: true });
          const checkrout=atob(unescape(encodeURIComponent(localStorage.getItem("EditMaterialTypeId"))))  
         navigate(`/material/${btoa(checkrout)}`);
         
        // navigate(`/material/${MaterialTypeStperId}`);
       
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Please upload  photo.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <>
      <div className="Step-part-details-heading">
        <p className="step-2-spare-part-details-heading">{localStorage.getItem('MaterailTypeValue')} Details</p>
        <p className="step-2-spare-part-details-subhheding">
          Add your Material Details here
        </p>
        <div className="photo-galary">
          <p ><span className="Product-photo-heroheading">Product Photo</span> <span className="must" style={{marginLeft:"-2px"}}>*</span></p>
          <div
            className="Product-photo-herobutton"
            onClick={() => setIsMaterialStorePopupOpen(true)}
          >
            <p className="Product-photo-buttontext">+ Add</p>
          </div>
        </div>
        <div>
        {/* const [photoUploaded, setPhotoUploaded] = useState(false); */}
          <EditShowPhotos 
        photoUploaded={photoUploaded}
        setPhotoUploaded={setPhotoUploaded}            
          />
        </div>
        <br />
        <br />
        <div className="photo-galary">
        <p ><span className="Product-photo-heroheading">Product Videos</span> <span className="must" style={{marginLeft:"-2px"}}>*</span></p>
          <div
            className="Product-photo-herobutton"
            onClick={() => setisMaterialStoreVidosPopupOpen(true)}
          >
            <p className="Product-photo-buttontext">+ Add</p>
          </div>
        </div>
        <div>
          <EditShowVideos
            refreshVideos={refreshVideos}
            // setRefreshVideos={setRefreshVideos}
          />
        </div>
        <br /> <br />
        <div>
          <Formik
            initialValues={{
              ProductWeight: "",
              ProductLength: "",
              ProductWidth: "",
              ProductHeight: "",
              ProductRadius: "",
              additionaldetails: "",
              weightDimension: "",
              lengthDimension: "",
              WidthDimension: "",
              heightDimension: "",
              RadiusDimesion: "",
            }}
              // validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmiteditStep2(values);
              setSubmitting(false);
            }}
          >
            {({ errors, touched, isSubmitting, setFieldValue,values }) => (
              <Form id="Edit-post-material-step-two">
                <div>
                  <p className="Additional-details-text">Weight</p>
                  <div className="weight-section">
                    <Field
                      className="dimension-input-field"
                      type="text"
                      // value={weight}
                      value={editMaterialStep2?.Weight}
                      name="ProductWeight"
                      placeholder="Enter Weight"
                      onChange={(e)=>{
                        seteditMaterialStep2((prev)=>{
                          return{
                            ...prev,
                            Weight:e.target.value
                          }
                        }) 
                      }}
                      // validate={() => validateWeight(values.ProductWeight, values.weightDimension)}
                    />
                    <Field
                      as="select"
                      id="weightDimension"
                      name="weightDimension"
                      className="dimension-input-field-2"
                      value={weight}
                      onChange={(e) => {
                        setweight(e.target.value);
                        setFieldValue("weightDimension", e.target.value);
                      }}
                      // validate={validateWeightDimension}
                    >
                      <option value="">Select</option>
                      {weightDimension?.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="material-error-boundry">
                    {errors.ProductWeight && touched.ProductWeight && (
                      <div className="material-error-text-boundry">
                        {errors.ProductWeight}
                      </div>
                    )}
                    {errors.weightDimension && touched.weightDimension && (
                      <div className="material-error-text-boundry">
                        {errors.weightDimension}
                      </div>
                    )}
                  </div>
                </div>
                <div style={{ marginTop: "23px" }}>
                  <p className="Additional-details-text">Dimensions</p>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>
                      <div className="weight-section">
                        <Field
                          className="dimension-input-field"
                          type="text"
                          name="ProductLength"
                          value={editMaterialStep2?.SLength}
                          placeholder="Enter Length"
                          onChange={(e)=>{
                            seteditMaterialStep2((prev)=>{
                              return{
                                ...prev,
                                SLength:e.target.value
                              }
                            }) 
                          }}
                          // validate={() => validateLength(values.ProductLength, values.lengthDimension)}
                        />
                        <Field
                          as="select"
                          id="lengthDimension"
                          name="lengthDimension"
                          value={length}
                          className="dimension-input-field-2"
                          onChange={(e) => {
                            setlength(e.target.value);
                            setFieldValue("lengthDimension", e.target.value);
                          }}
                        >
                          <option value="">Select</option>
                          {measureDimension?.map((item, index) => (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="material-error-boundry-dimension">
                        {errors.ProductLength && touched.ProductLength && (
                          <div className="material-error-text-boundry">
                            {errors.ProductLength}
                          </div>
                        )}
                        {errors.lengthDimension && touched.lengthDimension && (
                          <div className="material-error-text-boundry">
                            {errors.lengthDimension}
                          </div>
                        )}
                      </div>
                      <div className="weight-section">
                        <Field
                          placeholder="Enter Width"
                          className="dimension-input-field"
                          type="text"
                          value={editMaterialStep2?.Width}
                          name="ProductWidth"
                          onChange={(e)=>{
                            seteditMaterialStep2((prev)=>{
                              return{
                                ...prev,
                                Width:e.target.value
                              }
                            }) 
                          }}
                          // validate={() => validateWidthDimension(values.ProductWidth, values.WidthDimension)}
                        />
                        <Field
                          as="select"
                          id="WidthDimension"
                          name="WidthDimension"
                          className="dimension-input-field-2"
                          value={width}
                          onChange={(e) => {
                            setisWidth(e.target.value);
                            setFieldValue("WidthDimension", e.target.value);
                          }}
                        >
                          <option value="">Select</option>
                          {measureDimension?.map((item, index) => (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="material-error-boundry-dimension">
                        {errors.ProductWidth && touched.ProductWidth && (
                          <div className="material-error-text-boundry">
                            {errors.ProductWidth}
                          </div>
                        )}
                        {errors.WidthDimension && touched.WidthDimension && (
                          <div className="material-error-text-boundry">
                            {errors.WidthDimension}
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="weight-section">
                        <Field
                          placeholder="Enter Height"
                          className="dimension-input-field"
                          type="text"
                          value={editMaterialStep2?.Height}
                          onChange={(e)=>{
                            seteditMaterialStep2((prev)=>{
                              return{
                                ...prev,
                                Height:e.target.value
                              }
                            }) 
                          }}
                          name="ProductHeight"
                          // validate={() => validateheight(values.ProductHeight, values.heightDimension)}
                          // validate={(value) => validateheightDimension(value, value.heightDimension)}
                        />  
                        <Field
                          as="select"
                          id="heightDimension"
                          name="heightDimension"
                          className="dimension-input-field-2"
                          value={height}
                          onChange={(e) => {
                            setheight(e.target.value);
                            setFieldValue("heightDimension", e.target.value);
                          }}
                        >
                          <option value="">Select</option>
                          {measureDimension?.map((item, index) => (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="material-error-boundry-dimension">
                        {errors.ProductWidth && touched.ProductWidth && (
                          <div className="material-error-text-boundry">
                            {errors.ProductWidth}
                          </div>
                        )}
                        {errors.heightDimension && touched.heightDimension && (
                          <div className="material-error-text-boundry">
                            {errors.heightDimension}
                          </div>
                        )}
                      </div>
                      <div className="weight-section">
                        <Field
                          placeholder="Enter Radius"
                          className="dimension-input-field"
                          type="text"
                          value={editMaterialStep2?.Radius}
                          name="ProductRadius"
                          onChange={(e)=>{
                            seteditMaterialStep2((prev)=>{
                              return{
                                ...prev,
                                Radius:e.target.value
                              }
                            }) 
                          }}

                        />
                        <Field
                          as="select"
                          id="RadiusDimesion"
                          name="RadiusDimesion"
                          value={radius}
                          className="dimension-input-field-2"
                          onChange={(e) => {
                            setradius(e.target.value);
                            setFieldValue("RadiusDimesion", e.target.value);
                          }}
                          // validate={() => validateRadiusDimesion(values.ProductRadius, values.RadiusDimesion)}
                          // validate={(value) => validateRadiusDimesion(value, value.RadiusDimesion)}
                        >
                          <option value="">Select</option>
                          {measureDimension?.map((item, index) => (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="material-error-boundry-dimension">
                        {errors.ProductRadius && touched.ProductRadius && (
                          <div className="material-error-text-boundry">
                            {errors.ProductRadius}
                          </div>
                        )}
                        {errors.RadiusDimesion && touched.RadiusDimesion && (
                          <div className="material-error-text-boundry">
                            {errors.RadiusDimesion}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="Additional-details-price">
                      <p className="Additional-details-text">
                        Additional Details
                      </p>
                      <p className="no-of-char">100 Chars</p>
                    </div>
                    <div>
                      <Field
                        as="textarea"
                        id="additionaldetails"
                        name="additionaldetails"
                        className="additional-details-textarea"
                        placeholder="Add additional details"
                        value={editMaterialStep2?.AdditionalDetails}
                        onChange={(e)=>{
                          seteditMaterialStep2((prev)=>{
                            return{
                              ...prev,
                              AdditionalDetails:e.target.value
                            }
                          }) 
                        }}

                        
                      />
                    </div>
                    {/* {errors.additionaldetails && touched.additionaldetails && (
                      <div className="material-error-text-boundry">
                        {errors.additionaldetails}
                      </div>
                    )} */}

                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
            
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <MaterailPhotoUpload
        MaterialId={deCodeed}
        isLogoutModalOpenPopUp={isMaterialStorePopupOpen}
        setIsLogoutModalOpenPopUp={setIsMaterialStorePopupOpen}
        setPhotoUploaded={setPhotoUploaded}
      />
      <MaterailVideoUpload
        MaterialId={deCodeed}
        onUploadComplete={triggerRefresh}
        isLogoutModalOpenPopUp={isMaterialStoreVidosPopupOpen}
        setIsLogoutModalOpenPopUp={setisMaterialStoreVidosPopupOpen}
      />
    </>
  );
};