import { useDispatch, useSelector } from "react-redux";
import { Flex, Text } from "@chakra-ui/react";
import { SetIsQuizTimesUpModalOpen } from "../../slice/quizSlice";
import { CustomGeneralModal } from "../../../../components/CustomGeneralModal/CustomGeneralModal";
import { useNavigate } from "react-router-dom";

export const QuizTimesUpPopup = () => {
  const { isQuizTimesUpPopupOpen, selectedQuiz,userScore } = useSelector(
    (state) => state.quiz
  );
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const onButtonClick = () => {
    dispatch(SetIsQuizTimesUpModalOpen(false));
    navigate("/e9-quizzes")
};

  return (
    <>
      <CustomGeneralModal
        isOpen={isQuizTimesUpPopupOpen}
        iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/popup/alarm.svg`}
        heading={
            <div style={{fontSize:"20px",color:"#002961"}}>
        Time Up !
        </div>}
        iconHeight="50px"
        iconWidth="115px"
        iconMaxWidth="115px"
        iconMaxHeight="50px"
        primaryAction={onButtonClick}
        primaryActionText={"Okay"}
        hasCloseButton={true}
        onCloseButtonClick={onButtonClick}
        margin={"0 60px"}
        footerShadow={true}
        isArrow={false}
      >
        <Flex direction={"column"} gap={"12px"}>
        <Flex direction={"column"} fontSize={"18px"} fontWeight={"600"} color={"#2C2C2C"} alignItems={"center"}> 
            <Text>You have scored  <span style={{color:"#002961",fontWeight:"700"}}>{userScore}/{selectedQuiz?.TotalQuestPoints}</span></Text>
        </Flex>
        <Text fontSize={"16px"} fontWeight={"600"} color={"#767676"}>Quiz results will be declared in {selectedQuiz?.DaysRemainedForResults} day!</Text></Flex>
      </CustomGeneralModal>
    </>
  );
};
