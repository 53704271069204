import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllE9DealershipMarketPlaceList } from "../../../services/dealershipMarketplace/dealershipMarketplace";

export const getDealershipLoadMoreDataThunk = createAsyncThunk("dealership/loadMoreCards",async(_, {getState})=>{
    
    const {cardCount, paginationCount} = getState().dealershipMarketplace ;
  

    try{
       
        const data = await getAllE9DealershipMarketPlaceList(cardCount,(paginationCount * cardCount))

        if(data.Status === 200 && Object.keys(data.Data).length > 0){
            return {data : data.Data.E9DsMarketplaceList} ;
            
        }
    }

    catch(error){
    }
})