export const convertObjectsToArrays = (object) => {
  if (!object) {
    throw new Error("Parameter cannnot be left empty");
  }
  const stringifiedObject = JSON.stringify(object);
  if (
    typeof object !== "object" &&
    stringifiedObject.charAt(0) !== "{" &&
    stringifiedObject.charAt(stringifiedObject.length - 1) !== "}"
  ) {
    throw new Error("Parameter only accepts objects");
  }
  if (Object.keys(object).length === 0) {
    throw new Error("Object needs to have atleast one key");
  }

  const requiredArray = Object.keys(object).map(
    (key, index) => object[`${Object.keys(object)[index]}`]
  );
  return requiredArray;
};
