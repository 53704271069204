import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_CONSTANTS } from "../../../../constants/apiConstants";
import { createUserSubscriptionTransactionDetails } from "./createUserSubscriptionTransactionDetails";
import {fetchHandler} from "../../../../index";
import { createUserSubscriptionDetails } from "../../../services/subscription/subscription";


export const createUserSubscriptionThunk = createAsyncThunk("/subscription/createUserSubscription", async(body, {getState, dispatch}) => {
   
    const {selectedSubscriptionType, transactionState} = getState().subscription;

    const apiBody = body.body.map((service) => {
      const {
        ServiceTypeId,
        ServiceContactUnitPrice,
        ServiceDiscountTypeValue,
        quantitySelected,
      } = service;

      const serviceBody = {
        SubscriptionTypeId: selectedSubscriptionType.SUBSCRIPTION_ID,
        TransactionStateId: parseInt(body.transactionStateId),
        ServiceTypeId,
        SelectedContactsCount: quantitySelected,
        SelectedContactPrice: parseFloat(
          ServiceContactUnitPrice * quantitySelected
        ).toFixed(2),
        TotalDiscountAppliedPrice: parseFloat(
          (ServiceContactUnitPrice *
            quantitySelected *
            ServiceDiscountTypeValue) /
            100
        ).toFixed(2),
        FinalCostAfterDiscount:
          parseFloat(ServiceContactUnitPrice * quantitySelected).toFixed(2) -
          parseFloat(
            (ServiceContactUnitPrice *
              quantitySelected *
              ServiceDiscountTypeValue) /
              100
          ),
      };
      return serviceBody;
    });
    
    

    try{

        const data = await createUserSubscriptionDetails(apiBody);

        if(data.Status === 200 || data.Status === 201){
          
          if (
            parseInt(body.transactionStateId) ===
            transactionState[1].TransactionStateId
          ) {
            sessionStorage.setItem("ListofSelectedService",data?.Data?.ListofSelectedService)
            await dispatch(
              createUserSubscriptionTransactionDetails({
                ...body,
                ListofSelectedService: data.Data.ListofSelectedService,
              })
            ).unwrap();
          }
        }
    }
    catch(error){

    }
})


/**
 *    SubscriptionTypeId: item.SubscriptionTypeId,
            TransactionStateId: Transaction_StateID,
            ServiceTypeId: item.ServiceTypeId,
            SelectedContactsCount: item.SelectedContactsCount,
            SelectedContactPrice: parseFloat(item.SelectedContactPrice).toFixed(
              2
            ),
            TotalDiscountAppliedPrice: parseFloat(
              item.TotalDiscountAppliedPrice
            ).toFixed(2),
            FinalCostAfterDiscount: parseFloat(
              item.FinalCostAfterDiscount
            ).toFixed(2),
          });
        });
 */