import { combineReducers} from "@reduxjs/toolkit";
import {equipmentListingPremiumLeadsSlice }from "./equipmentListingPremiumLeadsSlice";
import { materialListingPremiumLeadsSlice } from "./materialListingPremiumLeadsSlice";
import { dealershipListingPremiumLeadsSlice } from "./dealershipListingPremiumLeadsSlice";
import { getPremiumLeadsByIdSlice } from "./getPremiumLeadsByIdSlice";

export const premiumLeadsSlice = combineReducers({
    equipmentListing : equipmentListingPremiumLeadsSlice.reducer,
    materialListing : materialListingPremiumLeadsSlice.reducer,
    dealershipListing : dealershipListingPremiumLeadsSlice.reducer,
    premiumLeadsById : getPremiumLeadsByIdSlice.reducer
})




