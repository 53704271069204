import "./DetailsCard.css";

export const DetailsCard = ({
  heading = "",
  description = "",
  onDescriptionClick = () => {},
  descriptionCursorRequired = true,
}) => {
  return heading ? (
    <div className="description-card">
      <h1 className="description-card__heading">{heading}</h1>
      <p
        // className={`description-card__description ${
        //   descriptionCursorRequired
        //     ? "cursor--pointer"
        //     : "description-card__description--not-available"
        // }`}
        className={`description-card__description`}

        onClick={onDescriptionClick}
      >
        {description}
      </p>
    </div>
  ) : (
    <></>
  );
};
