import { createSlice } from "@reduxjs/toolkit";
import { getInitialDataThunk } from "../thunks/getInitialDataThunk";
import { getLoadMoreDataThunk } from "../thunks/getLoadMoreDataThunk";
import { getInitialFilteredDataThunk } from "../thunks/getInitialFilteredDataThunk";
import { getLoadMoreFilteredDataThunk } from "../thunks/getLoadMoreFilteredDataThunk";
import { getOwnersSentCardsThunk } from "../thunks/getOwnersSentCardsThunk";
import { getOwnersReceivedCardsThunk } from "../thunks/getOwnersReceivedCardsThunk";

export const ownerMarketplaceSlice = createSlice({
  name: "ownersMarketplace",
  initialState: {
    data: null,
    connectList: null,
    mergedConnectList: null,
    redeemCount:null,
    leadRemaining:null,
    paginationCount: 0,
    endOfData: false,
    cardCount: 12,
    initialLoading: false,
    paginationLoading: false,
    filterByBusinessExpertiseTypeValue: "",
    filterByCountry: "",
    filterByState: "",
    filterByCity: "",
    filteredDataRequired: null,
    filterModalOpen: false,
    error: null,
    errorMessage: null,
    sentCards : null,
    receivedCards : null,
    S3UserProfilePicBaseRef : '',
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLeadRemaining: (state, action) => {
      state.leadRemaining = action.payload;
    },
    setConnectList: (state, action) => {
      state.connectList = action.payload;
    },
    setMergedConnectList: (state, action) => {
      state.mergedConnectList = action.payload;
    },
    setPaginationCount: (state, action) => {
      state.paginationCount = action.payload;
    },
    setEndOfData: (state, action) => {
      state.endOfData = action.payload;
    },
    setInitialLoading: (state, action) => {
      state.initialLoading = action.payload;
    },
    setPaginationLoading: (state, action) => {
      state.paginationLoading = action.payload;
    },
    setFilteredDataRequired: (state, action) => {
      state.filteredDataRequired = action.payload;
    },
    setFilterModalOpen: (state, action) => {
      state.filterModalOpen = action.payload;
    },
    setFilterByCountry: (state, action) => {
      state.filterByCountry = action.payload;
    },
    setFilterByState: (state, action) => {
      state.filterByState = action.payload;
    },
    setFilterByCity: (state, action) => {
      state.filterByCity = action.payload;
    },
    setFilterBusinessExpertiseTypeValue: (state, action) => {
      state.filterByBusinessExpertiseTypeValue = action.payload;
    },
    resetFilters: (state) => {
      state.filterByCountry = null;
      state.filterByState = null;
      state.filterByBusinessExpertiseTypeValue = null;
      state.filteredDataRequired = false;
      state.filterModalOpen = false;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    updateContactStatus(state, action) {
      let data = action?.payload;
      let index = state.mergedConnectList.findIndex(
        (item) => item.UserCredentialId == data.UserCredentialId
      );
      state.mergedConnectList[index] = {
        ...data,
      };
    },
    updateReedemCount(state, action) {
      if (action.payload == "Request Revoked") {
        state.redeemCount = state.redeemCount - 1;
      } else {
        state.redeemCount = state.redeemCount + 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInitialDataThunk.pending, (state) => {
        state.data = null;
        state.imageBaseUrl = null;
        state.paginationCount = 0;
        state.endOfData = false;
        state.cardCount = 12;
        state.initialLoading = true;
        state.paginationLoading = false;
        state.error = null;
        state.errorMessage = null;
      })
      .addCase(getInitialDataThunk.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.paginationCount = 1;
        state.initialLoading = false;
        state.paginationLoading = false;
        state.error = null;
        state.errorMessage = null;
        if (action.payload.data < state.cardCount) {
          state.endOfData = true;
        }
      })
      .addCase(getLoadMoreDataThunk.pending, (state) => {
        state.paginationLoading = true;
        state.error = null;
        state.errorMessage = null;
      })
      .addCase(getLoadMoreDataThunk.fulfilled, (state, action) => {
        state.data = [...state.data, ...action.payload];
        state.paginationCount += 1;
        state.paginationLoading = false;
        state.initialLoading = false;
        state.error = null;
        state.errorMessage = null;
        if (action.payload < state.cardCount) {
          state.endOfData = true;
        }
      })
      .addCase(getInitialFilteredDataThunk.pending, (state) => {
        state.data = null;
        state.paginationCount = 0;
        state.initialLoading = true;
        state.error = null;
        state.errorMessage = null;
      })
      .addCase(getInitialFilteredDataThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.paginationCount = 1;
        state.initialLoading = false;
        state.error = false;
        state.errorMessage = null;
        if (action.payload?.length < state.cardCount) {
          state.endOfData = true;
        }
      })
      .addCase(getLoadMoreFilteredDataThunk.pending, (state) => {
        state.paginationLoading = true;
        state.error = null;
        state.errorMessage = null;
      })
      .addCase(getLoadMoreFilteredDataThunk.fulfilled, (state, action) => {
        if (action.payload && action.payload.length > 0) {
          state.data = [...state.data, ...action.payload];
        }
        state.paginationCount += 1;
        state.paginationLoading = false;
        state.initialLoading = false;
        state.error = null;
        state.errorMessage = null;
        if (action.payload.length < state.cardCount) {
          state.endOfData = true;
        }
      })
      .addCase(getOwnersSentCardsThunk.pending, (state) =>{
        state.sentCards = null ;
      })
      .addCase(getOwnersSentCardsThunk.fulfilled, (state , {payload}) =>{
        if(payload && payload?.E9OwnerMpMyLeadsSentList.length > 0){
          state.sentCards = payload?.E9OwnerMpMyLeadsSentList ;
          state.S3UserProfilePicBaseRef = payload?.S3UserProfilePicBaseRef ;
        }
      })
      .addCase(getOwnersReceivedCardsThunk.pending, (state) =>{
        state.receivedCards = null ;
      })
      .addCase(getOwnersReceivedCardsThunk.fulfilled, (state , {payload}) =>{
        if(payload && payload?.E9OwnerMpMyLeadsReceivedList.length > 0){
          state.receivedCards = payload?.E9OwnerMpMyLeadsReceivedList ;
          state.S3UserProfilePicBaseRef = payload?.S3UserProfilePicBaseRef ;
        }
      })
     
  },
});

export const {
  setData,
  setLeadRemaining,
  setConnectList,
  setMergedConnectList,
  setImageBaseUrl,
  setPaginationCount,
  setEndOfData,
  setInitialLoading,
  setPaginationLoading,
  setError,
  setErrorMessage,
  setFilterBusinessExpertiseTypeValue,
  setFilterByCountry,
  setFilterByState,
  setFilterByCity,
  setFilterModalOpen,
  setFilteredDataRequired,
  resetFilters,
  updateContactStatus,
  updateReedemCount
} = ownerMarketplaceSlice.actions;
