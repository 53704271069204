import { createSlice } from "@reduxjs/toolkit";
import { getLocalStorageItems, setLocalStorageItems } from "../../helpers/localStorage/localStorage";
import { getPremiumUserDetailsThunk } from "./thunks/getPremiumUserDetailsThunk";
import { getMyPremiumThunk } from "./thunks/getMyPremiumThunk";

const premiumSlice = createSlice({
    name : "premium",
    initialState : {
        isPremium : getLocalStorageItems("IS_PREMIUM") && getLocalStorageItems("IS_PREMIUM") === "true" ? true : false,
        premiumId : getLocalStorageItems("PREMIUM_ID") || null,
        premiumExpiryDate : null,
        premiumDaysRemainingToExpire : Number.MAX_SAFE_INTEGER,
        premiumDetails : null,
        invoiceUrl : null,
        premiumDetailsLoading : false,
        premiumAllDetails:null,
        myPremiumDetails : null ,
        isLoading : false,
    },
    reducers : {
        setIsPremium : (state, {payload}) => {
         state.isPremium = payload;
         setLocalStorageItems("IS_PREMIUM", payload)
        },
        setPremiumId : (state, {payload}) => {
            state.premiumId = payload;
            setLocalStorageItems("PREMIUM_ID", payload);
        },
        
    },
    extraReducers : (builder) => {
        builder
        .addCase(getPremiumUserDetailsThunk.pending, (state) => {
            state.premiumExpiryDate = null
            state.premiumDaysRemainingToExpire = null
            state.premiumDetails = null
            state.invoiceUrl = null
            state.premiumDetailsLoading = true
        })
        .addCase(getPremiumUserDetailsThunk.fulfilled, (state, {payload}) => {
            state.premiumAllDetails = payload
            state.premiumExpiryDate = payload.UtcExpiryDateTime
            state.premiumDaysRemainingToExpire = payload.RemainingDays
            state.premiumDetails = payload.MyPremiumDetails
            state.invoiceUrl = payload.InvoiceName
            state.premiumDetailsLoading = false
        })
        .addCase(getPremiumUserDetailsThunk.rejected, (state) => {
            state.premiumExpiryDate = null
            state.premiumDaysRemainingToExpire = null
            state.premiumDetails = null
            state.invoiceUrl = null
            state.premiumDetailsLoading = true
        })
        .addCase(getMyPremiumThunk.pending, (state) => {
            state.myPremiumDetails = null
            state.isLoading = true
        })
        .addCase(getMyPremiumThunk.fulfilled, (state , {payload}) => {
            state.isLoading = false
            state.myPremiumDetails = payload
        })
    }
})

export const {setIsPremium, setPremiumId} = premiumSlice.actions;
export default premiumSlice.reducer