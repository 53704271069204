import { DashboardNavBar } from "../../components/DashboardNavBar/DashboardNavBar"
import { SideMenuLayout } from "../sideMenuLayout/SideMenuLayout"


export const MarketPlaceLayout = ({children}) =>{
    return(
        <SideMenuLayout>
            <DashboardNavBar />
            {children}
        </SideMenuLayout>
    )
}