import React, { useState,useEffect } from "react";
import { SideMenuLayout } from "../../layout/sideMenuLayout/SideMenuLayout";
import { DashboardNavBar } from "../../components/DashboardNavBar/DashboardNavBar";
import { MidSection } from "../../../sharedComponent/midSection/MidSection";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import { useQuery, useQueryClient } from "react-query";
import { formatNumberIndianStyle } from "../../../staticData/CommonMethod";
import {
  AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
  Flex,
} from "@chakra-ui/react";
import "./UpcomingJobStaus.css";
import { ViewRatings } from "../../components/ViewRatings/ViewRatings";
import { convertFromBase64 } from "../../../utils/formatConverter/convertToBase64";
import { createPostRating, getRatingOwner } from "../../services/upcoming/upcoming";
import { getOwnerJobDetailsbyID, jobDescriptionUpcomingMP } from "../../services/jobsPosted/jobsPosted";
import { V5FixedFooterSidebarLayout } from "../../layout/V5FixedFooterSidebarLayout/V5FixedFooterSidebarLayout";
import JobDetailsCard from "./JobDetailsCard";
import { V5SidebarLayout } from "../../layout/V5SidebarLayout/V5SidebarLayout";
import Avtar from '../../../assets/webapp/doc_flow/My-profile.png'
import { CloseIcon } from "@chakra-ui/icons";
import { SinglePurposePopup } from "../../components/SinglePurposePopup/SinglePurposePopUp";
import fetchHandler from "../../services/fetchHandler";
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData";
import { API_CONSTANTS } from "../../../constants/apiConstants";


export const MyJobPostedDetailPage = () => {
  const userData = useSelector((state) => state.user);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { JobId } = useParams();
  const convertBase64UpcomingJobId=convertFromBase64(JobId);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isCancelOrderPopupOpen, setIsCancelOrderPopupOpen]= useState(false);
  const onCloseLogoutModal = () => setIsLogoutModalOpen(false);
  // View Ratings 
  
  const [isLogoutModalOpenViewRating, setIsLogoutModalOpenViewRating] =
  useState(false);
const onCloseLogoutModalViewRating = () =>
  setIsLogoutModalOpenViewRating(false);
  const cancelReff = React.useRef();
  const [rating, setRating] = useState(0);
  const [ownerMpRatingData, setOwnerMpRatingData] = useState(null);
  const [paymentonTimerating, setpaymentonTimerating] = useState(0);
  const [behaviorRating, setbehaviorRating] = useState(0);
  const [ratinglength, setratinglength] = useState(0);
  const [Error, setError] = useState(null);
  const toast = useToast();

  const ratingDescriptions = {
    1: "Terrible",
    2: "Bad",
    3: "Okay",
    4: "Good",
    5: "Great",
  };

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
  };

  const handlePaymentStarrClick = (paymentonTimerating) => {
    setpaymentonTimerating(paymentonTimerating);
  };

  const handlebehaviorStarClick = (behaviorRating) => {
    setbehaviorRating(behaviorRating);
  };

  const { data: UpcomingJobDescription } = useQuery(
    "upcomingjobDescription",
    () => jobDescriptionUpcomingMP(convertBase64UpcomingJobId)
  );
  const { data: UpcomingJobOwnerDetails } = useQuery(
    "upcomingjobownerDetails",
    () => getOwnerJobDetailsbyID(convertBase64UpcomingJobId)
  );

  const resultJobDetails =
  UpcomingJobDescription?.data?.Data?.PartnerJobDetails[0];
  const ownerDetails = UpcomingJobOwnerDetails?.data?.Data?.OwnerDetails[0];
  const userCredentialId = ownerDetails?.UserCredentialId;

  const handleSubmitRating = async () => {
    const payload = {
      BehaviourRating: behaviorRating,
      PaymentOnTime: paymentonTimerating,
      SafetyConditions: rating,
      OwnerUserCredentialId: userCredentialId,
      JobId: parseInt(convertBase64UpcomingJobId),
    };

    const data = await createPostRating(payload);

    try {
      if (data?.Status === 201) {
        toast({
          title: "",
          description: `${data?.Message?.SuccessMessage}`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });

        queryClient.invalidateQueries("upcomingjobownerDetails");
        onCloseLogoutModal();
      }
    } catch (err) {
      toast({
        title: "",
        description: `${data?.Message?.FailMessage}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  // Length  get Rating  

  useEffect(() => {
    getRatingOwner(parseInt(convertBase64UpcomingJobId), userCredentialId)
      .then((data) => {
        const ratings = data?.Data?.OwnerJobRating[0];
        if (ratings) {  
          setOwnerMpRatingData(ratings); // Storing the ratings data in state
          setratinglength(data?.Data?.OwnerJobRating);
        }
      })
      .catch((err) => {
        setError(err);
        console.error("Error fetching ratings:", err);
      });
  }, [userData.token, convertBase64UpcomingJobId, userCredentialId]);


const handleCancelOrder = async (jobId)=>{
  const payload ={
    JobId: jobId 
  }

  try {
    const {data} = await fetchHandler(`/CancelInprocessForBusinessEquipmentOwner`,{}, {}, payload, API_CONSTANTS.HTTP_PUT)
  
    if (data?.Status == true) {
      toast({
        status: "success",
        description: "Your order is successfully cancelled.",
        position: "top-right",
        isClosable: true,
      });
      navigate("/my-jobs");
    } else {
      toast({
        status: "error",
        description: "Something went wrong!",
        position: "top-right",
        isClosable: true,
      });
    }
  } catch (error) {
    toast({
      status: "error",
      description: "Something went wrong!",
      position: "top-right",
      isClosable: true,
    });
    return error;
  }
}

  return (
    <>
    <SinglePurposePopup
        isOpen={isCancelOrderPopupOpen}
        iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/my-business/materials/delete-popup.svg`}
        bodyText="Are you sure you want to cancel this order?"
        subHeadingText="This can delete your job post and all partner details"
        primaryActionText={"Yes"}
        secondaryActionText={"No Thanks"}
        isArrow={false}
        continueAction={async () => {
          await handleCancelOrder(resultJobDetails?.JobId);
         
          setIsCancelOrderPopupOpen(false);
        }}
        cancelAction={() => {
         
          setIsCancelOrderPopupOpen(false);

        }}
      />
    <V5FixedFooterSidebarLayout
    primaryButtonText="View Partners"
    primaryButtonVariant="gradient"
    primaryButtonClickAction={()=>{navigate(`/job-cycle?orderId=${resultJobDetails?.JobId}&jobType=${resultJobDetails?.JobTypeId}&partnerType=new`)}}
    secondaryText="Cancel Order"
    secondaryElement={"button"}
    secondaryButtonLeftIcon={<><CloseIcon/></>}
    secondaryButtonColor="#D60000"
    secondaryButtonBorder="1px solid #D60000"
    secondaryButtonVarient="outline"
    secondaryButtonClickAction={()=>{
      setIsCancelOrderPopupOpen(true);
    }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", margin: "0px 1em" }}
      >
        <div className="upcoming-job-title-layout" style={{marginBottom:"10px"}}>
          <p className="Job-title-upcomming">{resultJobDetails?.JobTitle}</p>
        </div>

  

        {/* ***************************EQUIPMENT DETAILS************************ */}

        <JobDetailsCard
          title="EQUIPMENT DETAILS"
          iconSrc={
            "Webapp/Advertisements/LargeAdvertisments/icons/Equipmentdetaailicon.svg"
          }
        >
            <div className="Equipment-details-type-card">
              <p className="resultDetails">
                {resultJobDetails?.EquipmentTypeName}
              </p>
              <p className="EquipmentTypeText">Equipment Type</p>
            </div>
            <div className="Equipment-details-type-card">
              <p className="resultDetails">
                {resultJobDetails?.EquipmentMakeName}
              </p>
              <p className="EquipmentTypeText">Equipment Make</p>
            </div>
            <div className="Equipment-details-type-card">
              <p className="resultDetails">
                {resultJobDetails?.EquipmentModelName}
              </p>
              <p className="EquipmentTypeText">Equipment Model</p>
            </div>

        </JobDetailsCard>

        <JobDetailsCard
          title="JOB DETAILS"
          iconSrc={
            "Webapp/Advertisements/LargeAdvertisments/icons/Ownerlocation.svg"
          }
        >
          <div className="job-layout">
            <p className="resultDetails">{resultJobDetails?.WorkLocation}</p>
            <p className="EquipmentTypeText">Job Location</p>
          </div>
        </JobDetailsCard>

        {/*******************JOB DETAILS******************** */}

        <JobDetailsCard
          title="IN HAND SALARY DETAILS"
          iconSrc={
            "Webapp/Advertisements/LargeAdvertisments/icons/Ownersalary.svg"
          }
        >
            <div className="Equipment-details-type-card-salary">
              <p className="resultDetails">
                &#8377;{" "}
                {`${formatNumberIndianStyle(resultJobDetails?.Salary)} ${
                  resultJobDetails?.SalaryTypeValue
                }`}
              </p>
              <p className="EquipmentTypeText">In Hand Salary</p>
            </div>
            <div className="Equipment-details-type-card">
              <p className="resultDetails">{resultJobDetails?.PFValue}</p>
              <p className="EquipmentTypeText">PF Provided</p>
            </div>
            <div className="Equipment-details-type-card">
              <p className="resultDetails">{resultJobDetails?.PayCycleValue}</p>
              <p className="EquipmentTypeText">Pay Cycle</p>
            </div>

        </JobDetailsCard>

        {/****************Food Accommodation********************* */}

        <JobDetailsCard
          title="FOOD & ACCOMMODATION"
          iconSrc={
            "Webapp/Advertisements/LargeAdvertisments/icons/bedAccomdation.svg"
          }
        >
            <div className="Equipment-details-type-card">
              <p className="resultDetails">
                {resultJobDetails?.FoodBenefitValue}
              </p>
              <p className="EquipmentTypeText">Food</p>
            </div>
            <div className="Equipment-details-type-card">
              <p className="resultDetails">
                {resultJobDetails?.AccomodationValue}
              </p>
              <p className="EquipmentTypeText">Accommodation</p>
            </div>

        </JobDetailsCard>

        {/**********************Working Shift****************** */}
        <JobDetailsCard
          title="WORKING SHIFT"
          iconSrc={
            "Webapp/Advertisements/LargeAdvertisments/icons/workingShoft.svg"
          }
        >
            <div className="Equipment-details-type-card">
              <p className="resultDetails">
                {`${resultJobDetails?.WorkShiftValue}`}
              </p>
              <p className="EquipmentTypeText">Shift Type</p>
            </div>
            <div className="Equipment-details-type-card">
              <p className="resultDetails">
                {`${resultJobDetails?.WorkingHourValue} + ${resultJobDetails?.LunchBreakValue}`}
              </p>
              <p className="EquipmentTypeText">Working Hours</p>
            </div>
            <div className="Equipment-details-type-card">
              <p className="resultDetails">
                {resultJobDetails?.JobDurationValue}
              </p>
              <p className="EquipmentTypeText">Job Duration</p>
            </div>

        </JobDetailsCard>

        {/******************************** */}
        <JobDetailsCard
          title="NATURE OF WORK"
          iconSrc={
            "Webapp/Advertisements/LargeAdvertisments/icons/OwnerContact.svg"
          }
        >
            <div className="Equipment-details-type-card">
              <p className="resultDetails">
                {resultJobDetails?.NatureofWorkType}
              </p>
              <p className="EquipmentTypeText">Shift Type</p>
            </div>
            <div className="Equipment-details-type-card">
              <p className="resultDetails">
                {resultJobDetails?.InsuranceTypeId}
              </p>
              <p className="EquipmentTypeText">Insurance</p>
            </div>
            <div className="Equipment-details-type-card">
              <p className="resultDetails">
                {resultJobDetails?.TravelAllowance}
              </p>
              <p className="EquipmentTypeText">Travel Allowance</p>
            </div>

        </JobDetailsCard>
      </div>

      <AlertDialog
        isOpen={isLogoutModalOpen}
        leastDestructiveRef={cancelReff}
        onClose={onCloseLogoutModal}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <ReactSVG
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/RatingStar.svg`}
                />
                <p className="rateOwnerText">Rate Owner</p>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="Rating-layout-owner">
                  <div className="Condition-heading">Saftey Condition</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "-12px",
                    }}
                  >
                    <p className="ratingText">{ratingDescriptions[rating]}</p>
                    <div className="star-rating">
                      {[1, 2, 3, 4, 5].map((starNumber) => (
                        <span
                          key={starNumber}
                          className={`star ${
                            starNumber <= rating ? "active" : ""
                          }`}
                          onClick={() => handleStarClick(starNumber)}
                        >
                          &#9733;
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="rating-section">
                    {rating}
                    <span className="outofrating">/5</span>
                  </div>
                </div>
                <div className="Rating-layout-owner">
                  <div className="Condition-heading">Payment on time</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "-8px",
                    }}
                  >
                    <p className="ratingText">
                      {ratingDescriptions[paymentonTimerating] || " "}
                    </p>
                    <div className="star-rating">
                      {[1, 2, 3, 4, 5].map((paymentstarNumber) => (
                        <span
                          key={paymentstarNumber}
                          className={`star ${
                            paymentstarNumber <= paymentonTimerating
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            handlePaymentStarrClick(paymentstarNumber)
                          }
                        >
                          &#9733;
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="rating-section">
                    {paymentonTimerating}
                    <span className="outofrating">/5</span>
                  </div>
                </div>

                <div className="Rating-layout-owner">
                  <div className="Condition-heading">Behavior</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "-12px",
                    }}
                  >
                    <p className="ratingText">
                      {ratingDescriptions[behaviorRating] || " "}
                    </p>
                    <div className="star-rating">
                      {[1, 2, 3, 4, 5].map((Rating) => (
                        <span
                          key={Rating}
                          className={`star ${
                            Rating <= behaviorRating ? "active" : ""
                          }`}
                          onClick={() => handlebehaviorStarClick(Rating)}
                        >
                          &#9733;
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="rating-section">
                    {behaviorRating}
                    <span className="outofrating">/5</span>
                  </div>
                </div>
              </div>
            </AlertDialogHeader>
            <div className="button-layout">
              <button onClick={onCloseLogoutModal} className="submit-button">
                <p className="button-text">Cancel</p>
              </button>
              <button className="submit-button" onClick={handleSubmitRating}>
                <p className="button-text">Submit</p>
              </button>
            </div>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <ViewRatings
        jobId={convertBase64UpcomingJobId}
        userCredentialId={userCredentialId}
        isLogoutModalOpenrating={isLogoutModalOpenViewRating}
        setIsLogoutModalOpenrating={setIsLogoutModalOpenViewRating}
      />
    </V5FixedFooterSidebarLayout>
    </>
  );
};
