import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomGeneralModal } from "../../../components/CustomGeneralModal/CustomGeneralModal";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { SelectCardWrapper } from "../../../components/SelectCardWrapper/SelectCardWrapper";
import { ReactSVG } from "react-svg";
import doubleTick from "../../../../assets/webapp/common/double-tick.svg";
import { GetAudience } from "../../../services/community/community";
import { useNavigate } from "react-router-dom";
import {
  setAudienceModalOpen,
  setAudienceTypesData,
  setContentTypeModalOpen,
} from "../slice/addCommunitySlice";

export const ChooseAudiencePopup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAudienceModalOpen } = useSelector((state) => state.postCommunity);
  const [audienceTypes, setAudienceTypes] = useState([]);
  const [selectedAudienceTypes, setSelectedAudienceTypes] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await GetAudience();
      setAudienceTypes(response?.Data?.Audience);
    })();
  }, []);

  const onSubmit = async (value) => {
    dispatch(setAudienceTypesData(value));
    navigate(`/community/add-post`);
  };

  const contentTypes = [
    {
      iconUrl: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/community/handshake.svg`,
      id: 1,
    },
    {
      iconUrl: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/community/handshake.svg`,
      id: 2,
    },
    {
      iconUrl: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/community/staringWheel.svg`,
      id: 5,
    },
    {
      iconUrl: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/community/maintenanceTools.svg`,
      id: 4,
    },
  ];

  const audienceTypesMergedList = audienceTypes.map((audience) => {
    const matchedContent = contentTypes.find(
      (content) => content.id === audience.AudienceId
    );
    return {
      ...audience,
      iconUrl: matchedContent ? matchedContent.iconUrl : null,
    };
  });

  return (
    <CustomGeneralModal
      isOpen={isAudienceModalOpen}
      iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/community/anouncement.svg`}
      hasCloseButton={true}
      onCloseButtonClick={() => {
        dispatch(setAudienceModalOpen(false));
        setSelectedAudienceTypes(null);
      }}
      heading={
        <Text color={"#3CC200"} fontSize={"20px"} fontWeight={"700"}>
          Want To Spread Your Content?
        </Text>
      }
      subHeading={
        <Text color={"#1A1E21"} fontSize={"16px"} fontWeight={"700"}>
          Choose Your Audience
        </Text>
      }
      extraHeading={
        <Text color={"#959596"} fontSize={"16px"} fontWeight={"600"}>
          Select at least One
        </Text>
      }
      primaryActionText={"Continue"}
      primaryButtonIsDisable={selectedAudienceTypes === null || selectedAudienceTypes?.length === 0}
      buttonMinWidth={"100px"}
      primaryAction={() => {
        dispatch(setAudienceModalOpen(false));
        onSubmit(selectedAudienceTypes);
        setSelectedAudienceTypes(null);
      }}
      secondaryActionText={"Previous Step"}
      secondaryAction={() => {
        dispatch(setAudienceModalOpen(false));
        setSelectedAudienceTypes(null);
        dispatch(setContentTypeModalOpen(true));
      }}
    >
      <Flex direction="column" gap="0.5em" overflowY={"scroll"}>
        <Flex
          height={"305px"}
          alignItems={"center"}
          flexDirection={"column"}
          margin={"0 50px"}
        >
          {audienceTypesMergedList
            ? audienceTypesMergedList.map((audience) => (
                <SelectCardWrapper
                  backgroundcolor="white"
                  border="1px solid #E3E5E8"
                  width="338px"
                  height="60px"
                  padding="5px 20px"
                  key={audience.AudienceId}
                  value={audience.AudienceName}
                  onClick={() =>
                    setSelectedAudienceTypes((prevSelectedAudienceTypes) => {
                      if (!prevSelectedAudienceTypes)
                        return [
                          {
                            audienceId: audience?.AudienceId,
                            audienceName: audience?.AudienceName,
                          },
                        ];

                      const exists = prevSelectedAudienceTypes.some(
                        (aud) => aud.audienceId === audience?.AudienceId
                      );

                      if (exists) {
                        return prevSelectedAudienceTypes.filter(
                          (aud) => aud.audienceId !== audience?.AudienceId
                        );
                      } else {
                        return [
                          ...prevSelectedAudienceTypes,
                          {
                            audienceId: audience?.AudienceId,
                            audienceName: audience?.AudienceName,
                          },
                        ];
                      }
                    })
                  }
                  isSelected={selectedAudienceTypes?.some(
                    (aud) => aud.audienceId === audience?.AudienceId
                  )}
                  icon={
                    <img src={audience?.iconUrl} alt="Audience Type icon" />
                  }
                >
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <Box textAlign={"left"}>
                      <Heading
                        width="fit-content"
                        fontSize="18px"
                        fontWeight="600"
                        color={"#2c2c2c"}
                        marginLeft={"3em"}
                      >
                        {audience.AudienceName}
                        <Text
                          color={"#002961"}
                          width="fit-content"
                          fontSize="18px"
                          fontWeight="600"
                        >
                          {audience.TotalAudience > 999
                            ? (audience.TotalAudience / 1000).toFixed(1) + "K"
                            : audience.TotalAudience.toString()}
                          {"+"}
                        </Text>
                      </Heading>
                    </Box>
                    {selectedAudienceTypes?.some(
                      (aud) => aud.audienceId === audience?.AudienceId
                    ) && (
                      <ReactSVG
                        src={doubleTick}
                        beforeInjection={(svg) => {
                          svg.setAttribute(
                            "style",
                            "height: 20px; width: 18px"
                          );
                        }}
                      />
                    )}
                  </Flex>
                </SelectCardWrapper>
              ))
            : null}
        </Flex>
      </Flex>
    </CustomGeneralModal>
  );
};
