import { QrPopUp } from "../../../../webapp/components/ScanQrCodeModal/QrPopUp";
import logo from "../../../../assets/logo/equip9_logo_dark.png";
import { MarketplaceHeader } from "../../../../webapp/components/MarketplacHeader/MarketplaceHeader";
import { MarketplaceHoc } from "../../../../webapp/features/marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { v4 as uuid } from "uuid";
import { openQrModal } from "../../../../webapp/features/dashboard/slice/dashboardSlice";
import { getMpJobDeferredThunk } from "../../Product/thunk/getMpJobDeferredThunk";
import { mpJobLoadMoreDeferred } from "../../Product/thunk/mpJobLoadMoreDeferred";
import { OpenJobMarketplaceCard } from "../job/openJobMarketplaceCard/OpenJobMarketplaceCard";
import { Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import BrandHeader from "./BrandHeader";

export const MaintenanceJobDeferredMarketplace = () => {
  const mpJobDeferred = useSelector((state) => state.mpJobDeferred);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMpJobDeferredThunk());
  }, []);

  const renderCards = (array = []) => {
    const renderJsx =
      array && array.length > 0 ? (
        array.map(( maintenanceJobs) => (
          <OpenJobMarketplaceCard
            key={uuid()}
            jobId={ maintenanceJobs.JobId}
            name={ maintenanceJobs.JobTitle}
            location={`${ maintenanceJobs.CityName},${ maintenanceJobs.StateName}, ${ maintenanceJobs.CountryName}`}
            money={ maintenanceJobs.Salary ? `₹${ maintenanceJobs.Salary}` : "N.A."}
            cycle={
               maintenanceJobs.SalaryTypeValue
                ? `${ maintenanceJobs.SalaryTypeValue}`
                : "N.A."
            }
            onClick={() => {
              // dispatch(openQrModal());
              navigate(
                `/deferred/marketplace/Job/maintenance/${maintenanceJobs.JobId}`
              );
            }}
            postedOn={
               maintenanceJobs.JobPostedDateTime
                ?  maintenanceJobs.JobPostedDateTime.split(",")[1]
                : "NA"
            }
          />
        ))
      ) : (
        <p>No data to display</p>
      );
    return renderJsx;
  };

  return (
    <>
      <QrPopUp />
      <BrandHeader />

      <div style={{ padding: "10px 52px" }}>
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          borderBottom={"1px solid #333333"}
          marginBottom={"1em"}
        >
          <h1
            style={{
              fontSize: "32px",
              fontWeight: "700",
              marginBottom: "0.5em",
            }}
          >
            {"Jobs For Maintenance Partners"}
          </h1>
        </Flex>

        <MarketplaceHoc
          marketplaceName="Deferred Marketplace"
          marketplaceType={"partners"}
          dataArray={mpJobDeferred?.data}
          assetImageBaseReference={
            mpJobDeferred ? mpJobDeferred?.AssetImageBaseReference : ""
          }
          endOfData={mpJobDeferred?.endOfData}
          loadMoreAction={() => {
            dispatch(mpJobLoadMoreDeferred(mpJobDeferred?.paginationCount));
          }}
          initialLoading={mpJobDeferred?.initialLoading}
          paginationLoading={mpJobDeferred?.paginationLoading}
          cardsRenderFunction={renderCards}
        />
      </div>
    </>
  );
};
