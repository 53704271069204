import { Card } from "../Card/Card";
import { OverallRatingIcon } from "../OverallRatingIcon/OverallRatingIcon";
import shareIcon from "../../../assets/webapp/dashboard/share.svg";
import "./NearMePartnerMarketplaceCard.css"
import { ReactSVG } from "react-svg";
import { subString } from "../../helpers/dataConversion/substring";

export const NearMePartnerMarketplaceCard = ({
  verificationStatus,
  profilePictureUrl,
  imageBaseUrl,
  rating,
  name,
  equipmentTypeValue,
  experienceYear,
  experienceMonth,
  salary,
  certificateIssuerName,
  isdCode,
  mobileNumber,
  type,
  onShare = () => {},
  onContact,
  onClick = () => {},
  onContactClick = () => {},
  isShareEnabled = true,
  isContactDisabled = true,
}) => {
  return (
    <Card type="nearMePartnerCard">
      <div
        style={{
          height: "100%",
          width: "100%",
          borderRadius: "4px",
          boxShadow: "0px 0px 4px 0px #2c2c2c80",
          padding: "0.35em 0.15em",
          cursor: "pointer"
        }}
        onClick={onClick}
      >
        {/* STATUS */}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>

          <div className="nearme-marketplace-card__verification-badge"
            style={{backgroundColor: verificationStatus ? "#319F0060" : "#D6000060"}}>
            <p className="nearme-marketplace-card__verification-badge__text">{verificationStatus ? "Verified" : "Not Verified"}</p>
          </div>
          {/* INFO SECTION */}
        </div>
        <div
          style={{
            display: "flex",
            // border: "1px solid purple",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "0.25em",
              width: "15%",
            }}
          >
            <div style={{borderRadius: "50%", border: verificationStatus ? "1px solid #319F00" : "1px solid #D60000"}}> 
              <img
                src={
                  profilePictureUrl
                    ? `${process.env.REACT_APP_CDN_PVT_URL}${imageBaseUrl}${profilePictureUrl}`
                    : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Avatar.png`
                }
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  
                }}
                onerror={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Avatar.png`;
                }}
              />
            </div>
            <div style={{ width: "42px" }}>
              <OverallRatingIcon ratings={rating} />
            </div>
          </div>
          <div
            style={{
              padding: "0 1em",
              width: "85%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <p style={{ fontSize: "18px", fontWeight: 700 }}>{name}</p>
              <p
                style={{
                  fontSize: "14px",
                  color: "#2c2c2c80",
                  textTransform: "uppercase",
                  fontWeight: 600,
                }}
              >
                {equipmentTypeValue}
              </p>
            </div>
            <div
              style={{
                borderTop: "1px solid #2c2c2c50",
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "0.25em"
              }}
            >
              <div>
                <p style={{ color: "#2c2c2c" }}>
                  {
                    (type = "maintenance"
                      ? "Per Visit Cost"
                      : "Expected Monthly")
                  }{" "}
                  :{" "}
                  <span style={{ fontWeight: 600 }}>
                    {salary ? salary : "N.A."}{" "}
                  </span>
                </p>
                <p style={{ color: "#2c2c2c" }}>
                  Experience :{" "}
                  <span style={{ fontWeight: 600 }}>
                    {experienceYear > 1 && experienceMonth > 0
                      ? `${experienceYear}+ Years`
                      : experienceYear > 1 && experienceMonth === 0
                      ? `${experienceYear} Years`
                      : experienceYear === 1 && experienceMonth > 0
                      ? `${experienceYear}+ Year`
                      : experienceYear === 1
                      ? `${experienceYear}+ Year`
                      : experienceYear === 0 && experienceMonth > 0
                      ? `${experienceMonth} Months`
                      : "N.A."}
                  </span>
                </p>
                <p style={{ color: "#2c2c2c" }}>
                  Certified By :{" "}
                  <span style={{ fontWeight: 600 }}>
                    {certificateIssuerName ? subString(22,certificateIssuerName.toString()) : "N.A."}{" "}
                  </span>
                </p>
                {isContactDisabled ?                 <p
                  style={{ fontWeight: "600" }}
                >{`+${isdCode} ${mobileNumber}`}</p> :
                null
                }
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: isShareEnabled ? "space-between" : "flex-end",
                  alignItems: "flex-end",
                }}
              >
                {isShareEnabled && <button  onClick={(e) => {
                  onShare(e)
                  e.stopPropagation()
                  }}>
                <ReactSVG
                src={shareIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute('style', 'height: 20px')}}
                 
                />
                </button>}
                <button
                  onClick={(e) => {
                    onContact(e);
                    e.stopPropagation()
                  }
                    
                  }
                  style={{
                    color: "#F79C00",
                    background: "#fff",
                    border: "1px solid #F79C00",
                    textTransform: "uppercase",
                    padding: "0.25em 0.5em",
                    borderRadius: "4px",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  Contact Partner
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
