import React from "react";
import {
  MyProfilesection,
  myprofilesectionIndividual,
  myProfilesectionEquipmentOwnerIndivdual,
  myProfilesectionEquipmentOwnerCompany,
  operatorPatner,
  maintenancePatnerIndividual,
  maintenancePatnerCompany,
} from "../../../../../../../../src/staticData/staticData";
import "./MyProfileSection.css";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { API_CONSTANTS } from "../../../../../../../constants/apiConstants";
import { Link } from "react-router-dom";

export const MyProfilesectionlist = () => {
  const userData = useSelector((state) => state.user);

  const BusinessTypeValue = userData.BusinessTypeValue.toLowerCase();
  const UserTypeValue = userData.UserTypeValue.toLowerCase();

  const dataMappings = {
    [`${API_CONSTANTS.BUSINESS_OWNER}-${API_CONSTANTS.COMPANY}`]:
      MyProfilesection,
    [`${API_CONSTANTS.BUSINESS_OWNER}-${API_CONSTANTS.INDIVIDUAL}`]:
      myprofilesectionIndividual,
    [`${API_CONSTANTS.EQUIPMENT_OWNER}-${API_CONSTANTS.COMPANY}`]:
      myProfilesectionEquipmentOwnerCompany,
    [`${API_CONSTANTS.EQUIPMENT_OWNER}-${API_CONSTANTS.INDIVIDUAL}`]:
      myProfilesectionEquipmentOwnerIndivdual,
    [`${API_CONSTANTS.OPERATOR_PARTNER}-${API_CONSTANTS.INDIVIDUAL}`]:
      operatorPatner,
    [`${API_CONSTANTS.MAINTENCE_PATNER}-${API_CONSTANTS.INDIVIDUAL}`]:
      maintenancePatnerIndividual,
    [`${API_CONSTANTS.MAINTENCE_PATNER}-${API_CONSTANTS.COMPANY}`]:
      maintenancePatnerCompany,
  };

  const dataToRender = dataMappings[`${BusinessTypeValue}-${UserTypeValue}`];

  return (
    <>
      {dataToRender && (
        <div className="MyProfileSection">
          {dataToRender.map((item, index) => (
            <Link to={item.route}>
              <div className="MyProfileSectionChild" key={index}>
                <div className="Profile">
                  <div className="imageBox">
                    <img className="image" src={item.icon} alt={item.name} />
                  </div>
                  <div className="Businessdevelopement">{item.name}</div>
                  <ReactSVG
                    className="icon"
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/forward.svg`}
                  />
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
    </>
  );
};
