export function indianNumberFormatter(num) {
    // Check if the input is a valid number
    if (num === null || typeof num !== 'number' || isNaN(num)) {
        return "NA";
    }

    // Convert number to string and split into integer and decimal parts
    let [integerPart, decimalPart] = num.toString().split('.');

    // Handle integer part
    let lastThree = integerPart.slice(-3); // Get the last three digits
    let otherNumbers = integerPart.slice(0, -3); // Get the rest of the digits
    
    // Add commas every two digits from the right of the remaining digits
    otherNumbers = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",");

    // Combine integer and decimal parts
    return otherNumbers ? otherNumbers + ',' + lastThree + (decimalPart ? '.' + decimalPart : '') : lastThree + (decimalPart ? '.' + decimalPart : '');
}