import { getDocument } from "../promises/individualDataPromise";
import { DOCUMENT_FLOW_PAGES } from "../../../constants/navigationConstants";

export const OWNER_INDIVIDUAL_ROUTE_CALL_PROMISES = [
  { url: "/GetAllUserEquipments", documentName: "Expertise" },
  { url: "/GetUserAllUploadedDocument", documentName: "Verification Document" },
];

export const OWNER_INDIVIDUAL_CALLS = async (array, token) => {
  try {
    const promises = array.map((object) => getDocument(object.url));
    const responses = await Promise.allSettled(promises);
    const responseData = responses.map((response) => {
      if (response.status === "fulfilled") {
        return response.value.Data;
      }
    });

    const [expertiseDocument, verificationDocument] = responseData;
    return { expertiseDocument, verificationDocument };
  } catch (error) {
  }
};

export const generateOwnerIndividualRouting = (documentObject) => {
  const routeArray = [];
  if (!documentObject.ProfilePicture) {
    routeArray.push(DOCUMENT_FLOW_PAGES.OWNER_INDIVIDUAL.PROFILE_PICTURE);
  }
  if (!documentObject.E9UserRole) {
    routeArray.push(DOCUMENT_FLOW_PAGES.PARTNER.E9_ROLE);
  }

  if (
    documentObject.verificationDocument &&
    Array.from(Object.keys(documentObject.verificationDocument)).length === 0
  ) {
  
    routeArray.push(DOCUMENT_FLOW_PAGES.OWNER_INDIVIDUAL.VERIFICATION_DOCUMENT);
  }
  return routeArray;
};
