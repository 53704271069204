import React from "react";
import {
    Box,
    Button,
    Center,
    Flex,
    Heading,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "@chakra-ui/icons";
import {
    closeDealershipModal as closeWelcomeModal, openAddDealershipModal,
    // Assuming you have a separate close action for the WelcomeDealership modal
} from "../../features/MyBusinessDealershipDetail/slice/myBusinessDealershipSlice";

import "./DealershipPopUpCss.css"
import { CustomGeneralModal } from "../CustomGeneralModal/CustomGeneralModal";


export const WelcomeDealership = () => {

    const dispatch = useDispatch();
    const postDealershipData = useSelector(
        (state) => state.myBusinessDealership
    );

    const handleAddDealership = ()=>{
        dispatch(closeWelcomeModal())
    }

    return (
        <>
        <Modal isOpen={postDealershipData.isOpenWelcomeModal} isCentered >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Box textAlign="right">
                        <Button
                            variant="ghost"
                            onClick={() => {
                                dispatch(closeWelcomeModal());
                            }}
                            boxShadow="none"
                        >
                            <CloseIcon />
                        </Button>
                    </Box>
                    <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
                    <img src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/connectIcon.svg`} style={{ maxHeight: "40px", maxWidth: "40px" }} />
                    <Heading
                        fontSize="28px"
                        fontWeight="700"
                        fontFamily="Assistant"
                        lineHeight="52.32px"
                        color={"#1A1E21"}
                    >
                        Welcome to Virtual Dealership
                    </Heading>
                    </Flex>
                </ModalHeader>

                <ModalBody>
                    <div className="steps-containers" >
                        <div className="filter-steps-containner">
                            <div className="filter-steps"><img src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/Postdealership/stepOne.svg`} />
                            <h1 className="welcome-dealershiip-step">Step1</h1></div> 
                            <p className="welcome-dealershiip-step-details">Create Your Dealership</p>
                        </div>

                        <div className="filter-steps-containner">
                            <div className="filter-steps"><img src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/Postdealership/stepTwo.svg`} />
                            <h1 className="welcome-dealershiip-step">Step2</h1></div>
                            <p className="welcome-dealershiip-step-details">List Your Products & Services</p>
                        </div>

                        <div className="filter-steps-containner">
                            <div className="filter-steps"><img src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/Postdealership/stepThree.svg`} />

                            <h1 className="welcome-dealershiip-step">Step3</h1></div>
                            <p className="welcome-dealershiip-step-details">View Lead in "My Leads" Section</p>
                        </div>

                    </div>
                    <Flex alignItems={"center"} justifyContent={"center"} margin={"1em"}>
                    <Button backgroundColor={"#F79C00"} color={"white"} onClick={()=> dispatch(closeWelcomeModal())}>
                        Close
                    </Button>
                    </Flex>

                </ModalBody>
            </ModalContent>
        </Modal>
        {/* <CustomGeneralModal
        isOpen={postDealershipData.isOpenWelcomeModal}
        hasCloseButton={true}
        onCloseButtonClick={()=> dispatch(closeWelcomeModal())}
        iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/connectIcon.svg`}
        primaryActionText={"Close"}
        primaryAction={()=> dispatch(closeWelcomeModal())}
        heading={"Virtual Dealership"}
        subHeading={"Choose your Dealership Type"}
        >

        </CustomGeneralModal> */}
        </>
    );
};
