import {
  Flex,
  Step,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  StepIcon,
  Stepper,
  Text,
  useSteps,
  Progress,
} from "@chakra-ui/react";

export const RouteStepper = ({
  array,
  activeIndex = 0,
  height = "100%",
  maxH = "",
  width = "100%",
  padding = "",
}) => {
  const { activeStep } = useSteps({
    count: array ? array.length + 1 : 0,
    index: activeIndex,
  });

  
  return !array ? (
    <Flex
      justifyContent="center"
      alignItems="center"
      height={height}
      maxH={maxH}
      width={width}
      padding={padding}
    >
      <Text fontStyle="italic" textAlign="center">
        Stepper service not available
      </Text>
    </Flex>
  ) : (
    <>
      {/* Stepper */}
      <Stepper
        colorScheme="orange"
        index={activeStep}
        height={height}
        maxH={maxH}
        width={width}
        padding={padding}
      >
        {array.length > 0 &&
          array.map((item, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  incomplete={<StepNumber />}
                  active={<StepNumber sx={{
                    
                      '&[data-status = "complete"]' : {
                        background : 'linear-gradient(90deg, #f67c00, #FED401)'
                      }
                  }} />}
                  complete={<StepIcon />}
                />
              </StepIndicator>
              <StepTitle>{item ? item.name : "N.A."}</StepTitle>
              <StepSeparator />
            </Step>
          ))}
      </Stepper>
      <Progress
        role={"progressbar"}
        isAnimated={true}
        size="xs"
        colorScheme="orange"
           sx={{
          '& div' : {
            background : 'linear-gradient(90deg, #f67c00, #FED401)'
          }
        }}
        value={array ? ((parseInt(activeIndex) + 1) / array.length) * 100 : 0}
      />
    </>
  );
};
