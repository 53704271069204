import { Box, Flex, Skeleton } from "@chakra-ui/react";

export const DashboardLoader = () => {
  return (
    <Flex flexDirection="column" gap="16px" my="16px">
      <Box>
        <Skeleton height="228px" width="100%" borderRadius="8px" />
      </Box>

      <Box>
        <Flex w="100%" alignItems="center" justifyContent="space-between">
          <Box w="300px">
            <Skeleton height="31.39px" width="100%" borderRadius="4px" />
          </Box>
          <Box w="50px">
            <Skeleton height="31.39px" width="100%" borderRadius="4px" />
          </Box>
        </Flex>

        <Flex mt="16px" ml="16px" mb="32px" gap="32px">
          <Skeleton width="275px" height="300px" borderRadius="8px" />
          <Skeleton width="275px" height="300px" borderRadius="8px" />
          <Skeleton width="275px" height="300px" borderRadius="8px" />
          <Skeleton width="275px" height="300px" borderRadius="8px" />
        </Flex>
      </Box>

      <Box>
        <Flex w="100%" alignItems="center" justifyContent="space-between">
          <Box w="300px">
            <Skeleton height="31.39px" width="100%" borderRadius="4px" />
          </Box>
          <Box w="50px">
            <Skeleton height="31.39px" width="100%" borderRadius="4px" />
          </Box>
        </Flex>

        <Flex mt="16px" ml="16px" mb="32px" gap="32px">
          <Skeleton width="211px" height="300px" borderRadius="8px" />
          <Skeleton width="211px" height="300px" borderRadius="8px" />
          <Skeleton width="211px" height="300px" borderRadius="8px" />
          <Skeleton width="211px" height="300px" borderRadius="8px" />
          <Skeleton width="211px" height="300px" borderRadius="8px" />
        </Flex>
      </Box>

      <Box>
        <Flex w="100%" alignItems="center" justifyContent="space-between">
          <Box w="300px">
            <Skeleton height="31.39px" width="100%" borderRadius="4px" />
          </Box>
          <Box w="50px">
            <Skeleton height="31.39px" width="100%" borderRadius="4px" />
          </Box>
        </Flex>

        <Flex mt="16px" ml="16px" mb="32px" gap="32px">
          <Skeleton width="275px" height="300px" borderRadius="8px" />
          <Skeleton width="275px" height="300px" borderRadius="8px" />
          <Skeleton width="275px" height="300px" borderRadius="8px" />
          <Skeleton width="275px" height="300px" borderRadius="8px" />
        </Flex>
      </Box>
    </Flex>
  );
};
