import { Flex, Image, Text, Button, Tooltip } from "@chakra-ui/react";
import rightIcon from "../../../assets/webapp/dashboard/icon-right.svg";
import { ReactSVG } from "react-svg";

export const V5DashboardPartnerCard = ({
  background,
  color,
  isButtonHidden = false,
  borderRadius,
  onButtonClick = () => {},
  onClick = () => {},
  children,
}) => {
  return (
    <Flex
      maxH="213px"
      minH="213px"
      maxW={["190px", "190px", "223px", "219px", "190px", "190px"]}
      minW={["190px", "190px", "223px", "219px", "190px", "190px"]}
      h="100%"
      w="100%"
      padding="10px 0px 6px 0px"
      flexDirection="column"
      boxShadow="0px 6px 15px -3px #00000029"
      alignItems="center"
      position="relative"
      background={background}
      color={color}
      borderRadius={borderRadius}
      onClick={onClick}
    >
      {children}
      <Button
        onClick={onButtonClick}
        display={isButtonHidden ? "none" : ""}
        h="35px"
        w="35px"
        borderRadius="50%"
        bgColor="#FFF"
        border="2px solid #F79C00"
        position="absolute"
        bottom="-15px"
        left="40%"
      >
        <ReactSVG src={rightIcon} height="20px" width="20px" />
      </Button>
    </Flex>
  );
};

const CardImage = ({ src }) => {
  return <Image src={src} height="75px" width="75px" borderRadius="50px" />;
};

const CardHeading = ({ children }) => {
  return (
    <Tooltip label={children}>
      <Text fontSize="18px" lineHeight="23.75px" fontWeight="700">
        {children}
      </Text>
    </Tooltip>
  );
};

const CardText = ({ color = "#2c2c2c", children }) => {
  return (
    <Text fontSize="16px" lineHeight="18.3px" fontWeight="400" color={color}>
      {children}
    </Text>
  );
};

V5DashboardPartnerCard.Image = CardImage;
V5DashboardPartnerCard.Heading = CardHeading;
V5DashboardPartnerCard.Text = CardText;
