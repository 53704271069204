import { useEffect, useState } from "react";
import fetchHandler from "../../../services/fetchHandler";
import { Box, Divider, Flex, Image, Text, useToast } from "@chakra-ui/react";
import { NoPartnerAppliedPlaceholder } from "../../../components/NoPartnerAppliedPlaceholder/NoPartnerAppliedPloceholder";
import { MarketplaceHoc } from "../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import defaultAvatar from "../../../../assets/user.jpg";
import { Link, useSearchParams } from "react-router-dom";
import { V5SidebarLayout } from "../../../layout/V5SidebarLayout/V5SidebarLayout";
import filledStar from "../../../../assets/webapp/leads/filled-star.svg";
import ratingStar from "../../../../assets/webapp/leads/rating-star.svg";
import { GiveRatings } from "./giveRatings";
import { convertToFormData } from "../../../helpers/dataConversion/convertToFormData";
import { API_CONSTANTS } from "../../../../constants/apiConstants";
import { useDispatch, useSelector } from "react-redux";
import { ViewRatings } from "./viewRatings";
import { openQrModal } from "../../dashboard/slice/dashboardSlice";
import { QrPopUp } from "../../../components/ScanQrCodeModal/QrPopUp";

const partnerTypes = [
  {
    name: "New Partners",
    link: "new",
  },
  {
    name: "Confirmed Partners",
    link: "confirmed",
  },
  {
    name: "Ongoing Partners",
    link: "ongoing",
  },
  {
    name: "Completed Partners",
    link: "completed",
  },
  {
    name: "Cancelled Partners",
    link: "cancelled",
  },
];

export const OngoingPartners = () => {
  const [partners, setPartners] = useState([]);
  const [imageBaseUrl, setImageBaseUrl] = useState(null);
  const [params, setParams] = useSearchParams();
  const [jobId, setJobId] = useState(0);
  const [partnerType, setPartnerType] = useState("");
  const [jobType, setJobType] = useState(0);
  const [isRatingComponentOpen, setIsRatingsComponentOpen] = useState(false);
  const [ratingPartner, setRatingPartner] = useState(null);
  const toast = useToast();
  const dispatch =useDispatch();
  const {accountDetails} = useSelector(state => state.user);
  const [confirmPartnerId,setConfirmPartnerID] = useState(null);
  const [viewRatingData,setViewRatingData] = useState(null);
  const [isRated, setIsRated] = useState({});
  const [viewRatingModalOpen,setViewRatingModalOpen]=useState(false);

  const getConfirmedPartners = async () => {
    try {
      const { data } = await fetchHandler(`/GetConfirmedPartnerList/${jobId}`);
      if (data.Status === 200 && Object.keys(data.Data).length > 0) {
        setImageBaseUrl(data.Data.ProfilePicBaseUrl);
        const ongoingPartnersList = data.Data.ConfirmedPartnerList.filter(
          (partner) => partner.IsStartOTP === 1 && partner.IsEndOTP === 0
        );
        setPartners(ongoingPartnersList);
        setConfirmPartnerID(ongoingPartnersList?.[0]?.ConfirmedPartnerId)
      }
    } catch (error) {}
  };

  const createPartnerRatings = async (body) => {
  //  await getOwnerRatings(body);

    try {
      const data = await fetchHandler(
        `/CreatePartnerRatingDetails`,
        {},
        {},
        convertToFormData(body),
        API_CONSTANTS.HTT_POST
      );
      if(data?.data?.Status === 201){
        toast({
          title : "Rated Partner Successfully",
          status : 'success',
          position: 'top-right',
          isClosable: true
        })
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    if(partners){
      getOwnerRatings()
    }
  },[jobId,confirmPartnerId])

  const getOwnerRatings = async (jobId,confirmPartnerId) => {
      try {
        const {data} = await fetchHandler(
          `/GetPartnerRatingDetailsGivenByOwner/${parseInt(jobId)}/${parseInt(confirmPartnerId)}`,
          {},
          {},
          {},
          API_CONSTANTS.HTTP_GET,
          {isAuthRequired: true}
        );
  
      
          return data;
          // setViewRating(data?.Data?.PartnerRating)
      
      } catch (error) {
        console.log(error);
      }
    
  }


  const onRatingClick = async (element)=>{

    const data= await getOwnerRatings(element?.JobId,element?.ConfirmedPartnerId);
    if(data?.Status === 200){

      if (data?.Data?.PartnerRating?.length > 0){
        setViewRatingModalOpen(true);
        // setIsRated(true);
        setIsRated((prevState) => ({
          ...prevState,
          [element?.ConfirmedPartnerId]: true 
        }));

        const createRatingData = (data) => {
          return [
            { name: "Behaviour", rating: data.Behaviour, totalRating: 5 },
            { name: "Punctuality", rating: data.Punctuality, totalRating: 5 },
            { name: "Work", rating: data.Work, totalRating: 5 } // Set to 0 if undefined
          ];
        };
        const ratingData = createRatingData(data?.Data?.PartnerRating[0]);
        setViewRatingData(ratingData);

      }else{
          setRatingPartner(element.ConfirmedPartnerId);
          setIsRatingsComponentOpen(true);
      }
    }
 
  }

  const renderConfirmedPartners = (data) => {
    const renderingJsx =
      data && data.length > 0 ? (
        data.map((element) => (
          <div
            style={{
              height: "150px",
              width: "550px",
              borderRadius: "5px",
              border: "1px solid #E3E5E8",
              padding: "6px 12px",
            }}
          >
            <Flex justifyContent="flex-end">
              <Text
                fontSize="12px"
                fontWeight="600"
                color="#FFF"
                borderRadius="20px"
                padding="0px 12px"
                bgColor={
                  element.PartnerVerificationStatus ? "#319F0050" : "#D6000050"
                }
              >
                {element.PartnerVerificationStatus
                  ? "E9 Verified"
                  : "Not Verfied"}
              </Text>
            </Flex>
            <Flex gap="1rem" width="100%">
              <Flex
                flexDirection="column"
                gap=".5rem"
                justifyContent="center"
                alignItems="center"
              >
                {/* Image and rating */}
                <Image
                  src={
                    element.ProfilePic
                      ? `${process.env.REACT_APP_CDN_PVT_URL}${imageBaseUrl}${element.ProfilePic}`
                      : defaultAvatar
                  }
                  border="1px solid  #E3E5E8"
                  height="56px"
                  width="56px"
                  borderRadius={"50%"}
                  alt="operator-image"
                />
                <Flex
                  bgColor="#FFCF00"
                  color="#002961"
                  borderRadius="4px"
                  padding="1px 2px"
                  maxW="47px"
                  w="100%"
                  gap="3px"
                  alignItems="center"
                  justifyContent="center"
                  fontWeight="600"
                >
                  <Image src={ratingStar} width="11px" height="11px" />{" "}
                  <Text color="#002961" fontWeight="700">
                    {element.OverAllRating ? element.OverAllRating : 0}
                  </Text>
                </Flex>
              </Flex>
              {/* Other detrais */}
              <Flex flexDirection="column" width="100%">
                <Flex flexDirection="column" width="100%" >
                  <Text fontSize="18px" fontWeight="700">
                    {element.PartnerName}
                  </Text>
                  <Text fontSize="16px" color="#002961" fontWeight="600">
                    <Box as="span" display="flex" alignItems="center">
                      <Image
                        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/opmp/contact.png`}
                        alt="Contact Icon"
                        boxSize="16px" // You can adjust the size here
                        marginRight="0.5rem" // Add some space between the image and the text
                      />
                     + {element.MobileNumber}
                    </Box>
                  </Text>
                  <Text fontSize="16px" fontWeight="600" color='#1A1E21'>
                    End OTP :{" "}
                    <span style={{ color: "#D60000" }}>{element.EndOTP}</span>
                  </Text>
                  <Divider borderColor="#959596" width="100%" />
                </Flex>

                {/*Experience and salary  */}

                <Flex flexDirection="column">
                  <Flex gap={"0.25rem"} alignItems="center">
                    <Image src={filledStar} height="1rem" />
                    <Text
                      fontSize="18px"
                      fontWeight={700}
                      color="#002961"
                      cursor="pointer"
                      onClick={async () => {
                        await onRatingClick(element);
                      }}
                    >
                      {isRated[element?.ConfirmedPartnerId] ? "View Ratings" : "Give Ratings"}
                    
                    </Text>
                    <Image
                        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/opmp/arrowRi.png`}
                        alt="Contact Icon"
                        height='12px'
                        width='12px'
                      />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </div>
        ))
      ) : (
        <Text>No Data available</Text>
      );
    return renderingJsx;
  };

  useEffect(() => {
    if (params) {
      const paramsArray = params.toString().split("&");
      let paramsObject = {};
      paramsArray.forEach((param) => {
        const key = param.split("=")[0];
        const value = param.split("=")[1];
        paramsObject[`${key}`] = value;
      });
      console.log(paramsObject);
      setJobId(paramsObject.orderId);
      setPartnerType(paramsObject.partnerType);
      setJobType(paramsObject.jobType);
    }
  }, [params]);

  useEffect(() => {
    if (jobType && partnerType && jobId) {
      getConfirmedPartners();
    }
  }, [jobType, partnerType, jobId]);

  return (
    <V5SidebarLayout>
       <QrPopUp />
       <GiveRatings
        isOpen={isRatingComponentOpen}
        onClick={async (value) => {
          await createPartnerRatings({
            JobId: parseInt(jobId),
            BehaviourRating: value.behaviourRating,
            WorkRating: value.workRating,
            PunctualityRating: value.punctualityRating,
            ReviewComment: "rating",
            PartnerUserCredentialId: ratingPartner,
          });
          setIsRatingsComponentOpen(false)
        }}
        onNoThanksClick={() => setIsRatingsComponentOpen(false)}
      />
      <ViewRatings
      isOpen={viewRatingModalOpen}
      data={viewRatingData}
      handleOkayButtonClick={()=>{
        setViewRatingModalOpen(false);
      }}
      />
      <div style={{ marginRight: "1rem" }}>
        <Flex
          boxShadow="0px 4px 4px 0px #2c2c2c20"
          border="1px solid #E3E5E8"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          padding="1em 2.5em"
          // marginTop="1em"
          display="flex"
          justifyItems="space-between"
        >
          {partnerTypes.map(({ name, link }) => {
            return (
              <Link
                key={name}
                to={`/job-cycle?orderId=${jobId}&jobType=${jobType}&partnerType=${link}`}
                style={{
                  color:
                    partnerType && name.toLowerCase().includes(partnerType)
                      ? "#F79C00"
                      : "",
                  borderBottom:
                    partnerType && name.toLowerCase().includes(partnerType)
                      ? "2px solid #F79C00"
                      : "none",
                  fontWeight: 600,
                }}
              >
                {name}
              </Link>
            );
          })}
        </Flex>
        <Box marginTop="1rem">
          {partners ? (
            <MarketplaceHoc
              marketplaceType="jobs"
              dataArray={partners}
              cardsRenderFunction={renderConfirmedPartners}
            />
          ) : (
            <NoPartnerAppliedPlaceholder orderID={1064} />
          )}
        </Box>
      </div>
    </V5SidebarLayout>
  );
};
