import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllServiceBySUBID } from "../../../services/subscription/subscription";


export const getSubscriptionTypeThunk = createAsyncThunk("subscriptionType", async(subscriptionCategory, {getState}) => {
    try{
      
        const data = await getAllServiceBySUBID(subscriptionCategory);
        
        if(data.Status){
         const updatedSubscriptionTypeArray = data.Data.data.map((subscriptionType) => ({...subscriptionType, isSelected : false, quantitySelected: subscriptionType.ServiceMinNumber }));
        return updatedSubscriptionTypeArray;
        }
    
}
catch(error){
    console.log(error)
}

})