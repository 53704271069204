import { Button, Flex, Text } from "@chakra-ui/react";
import { V5SidebarLayout } from "../../../../layout/V5SidebarLayout/V5SidebarLayout";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myQuizInitialListThunk } from "../../thunks/myQuizInitialListThunk";
import { MarketplaceHoc } from "../../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { myQuizListLoadMoreThunk } from "../../thunks/myQuizListLoadMoreThunk";
import { QuizCard } from "../QuizCard";
import { v4 as uuid } from "uuid";


export const MyQuizzesPage = () => {
  const navigate = useNavigate();
  const dispatch=useDispatch();

  const {
    myPlayedQuizzes: initialData,
    endOfData,
    initialLoading,
    paginationLoading,
    imageBaseUrl
  } = useSelector((state) => state.quiz);

  useEffect(() => {
    dispatch(myQuizInitialListThunk());
  }, []);


  const renderCards = (array) => {
    const renderJsx =
      array && array.length > 0 ? (
        array.map((quiz) => {
          return (
            <QuizCard
              key={uuid()}
              name={quiz.QuizName}
              status={quiz.QuizStatus}
              startDate={quiz.QuizStartDate}
              expiredOnDate={quiz.ExpiringOn}
              promotedBy={quiz.PromotedBy}
              SponsoredBy={quiz.SponsoredBy}
              imageBaseUrl={imageBaseUrl}
              QuizImageReference={quiz.QuizImageReference}
              QuizPlayedUsersCount={quiz.QuizPlayedUsersCount}
              IsQuizPlayedByUser={quiz.IsQuizPlayedByUser}
              PointsScoredByUser={quiz.PointsScoredByUser}
              TotalQuestPoints={quiz.TotalQuestPoints}
              onCardClick={()=>{
                navigate(`/quiz/${quiz?.QuizId}/winners`, {
                    state: { sponseredBy: quiz?.SponsoredBy },
                  });
              }}
            />
          );
        })
      ) : (
        <p>No Quiz Record Found !</p>
      );
    return renderJsx;
  };

  return (
    <>
      <V5SidebarLayout>
        <Flex gap={"10px"} direction={"column"} margin={"0 52px"}>
          {/* header section */}
          <Flex direction={"column"}>
            <Text fontSize={"24px"} fontWeight={"700"} color={"#1A1E21"}>
              My Quizzes
            </Text>
            <Text fontSize={"20px"} fontWeight={"400"} color={"#767676"}>
              Check Your Played Quizzes here!
            </Text>
          </Flex>

          {/* action buttons */}
          <Flex justifyContent={"space-between"}>
            <Flex gap={"10px"}>
              <Button
                variant="outline"
                color={"#002961"}
                border={"1px solid #002961"}
                onClick={() => {
                  navigate("/e9-quizzes");
                }}
              >
                Equip9 Quizzes
              </Button>
              <Button
                variant="outline"
                color={"#F79C00"}
                border={"1px solid #F79C00"}
                onClick={() => {}}
              >
                Played Quizzes
              </Button>
            </Flex>
          </Flex>
        </Flex>

        {initialData ? <MarketplaceHoc
          marketplaceType="jobs"
          isNewNavbar={true}
          dataArray={initialData || []}
          endOfData={endOfData}
          loadMoreAction={() => {
            dispatch(myQuizListLoadMoreThunk());
          }}
          initialLoading={initialLoading}
          paginationLoading={paginationLoading}
          cardsRenderFunction={renderCards}
        /> : <Flex direction={"column"} fontSize={"16px"} fontWeight={"400"} color={"#484848"} margin={"0 52px"} h={"60vh"} justifyContent={"center"} alignItems={"center"}>No Quizzes Available</Flex>} 
      </V5SidebarLayout>
    </>
  );
};
