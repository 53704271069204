import { Box, Flex, Text } from "@chakra-ui/layout";
import { Formik } from "formik";
import { ImageUpload } from "../ImageUpload/ImageUpload";
import { FormikDropdown } from "../Dropdown/FormikDropdown";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { CustomInputModal } from "../CustomInputModal/CustomInputModal";

export const UploadMediaPopUp = ({
  isModalOpen = false,
  modalTitle = "Title",
  mediaComponentTitle = "",
  mediaExtentionsAllowed = ["jpg", "jpeg", "png", "pdf"],
  maxFileSizeinMb = 5,
  handleSubmit = () => {},
  dropdownValues = [],
  onCloseButtonClick = () => {},
  valuesToDeactivate = [],
  hasDropdown = true,
  isLoading = false,
  documentType,
}) => {
  const [uniqueValues, setUniqueValues] = useState(null);

  const getRepeatedOptionIndexes = (allValues = [], discardedValues = []) => {
    const newHashmap = new Map();
    const combinedArray = [...allValues, ...discardedValues];
    for (let i = 0; i < combinedArray.length; i++) {
      if (!newHashmap.has(combinedArray[i].id)) {
        newHashmap.set(combinedArray[i].id, 1);
      } else {
        let count = newHashmap.get(combinedArray[i].id);
        count++;
        newHashmap.set(combinedArray[i].id, count);
      }
    }
    const repeatedIds = Array.from(newHashmap.keys()).filter(
      (key) => newHashmap.get(key) === 2
    );
    return repeatedIds;
  };
  const uniqueOptions = (repeatedOptions) => {
    const requiredArray = dropdownValues.filter(
      ({ id }) => !repeatedOptions.includes(id)
    );
    return requiredArray;
  };

  const handleUniqueDropdownValues = () => {
    const array = getRepeatedOptionIndexes(dropdownValues, valuesToDeactivate);
    const requiredArray = uniqueOptions(array);
    if (requiredArray && requiredArray.length > 0) {
      setUniqueValues(requiredArray);
      return requiredArray;
    } else {
      setUniqueValues(null);
      return null;
    }
  };

  const allowedFormatsList = (array) => {
    const requiredFomats = array.map((format) => {
      const extention = format.split("/")[format.split("/").length - 1];
      return extention.toUpperCase();
    });
    return requiredFomats;
  };

  useEffect(() => {
    if (valuesToDeactivate && dropdownValues && hasDropdown) {
      handleUniqueDropdownValues();
    }
  }, [dropdownValues, hasDropdown, valuesToDeactivate]);
  return (
    <CustomInputModal
      isOpen={isModalOpen}
      hasCloseButton={true}
      icon={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/popup/uploadPhoto.svg`}
      onCloseButtonClick={onCloseButtonClick}
      primaryActionText={"Submit"}
      buttonAlignment="end"
      heading={modalTitle}
      subHeading={mediaComponentTitle}
      isLoading={isLoading}
    >
      <Formik
        initialValues={
          hasDropdown ? { document: "", selectValue: "" } : { document: "" }
        }
        validationSchema={Yup.object().shape(
          hasDropdown
            ? {
                document: Yup.mixed()
                  .required("Media file is reqired.")
                  .test("fileFormat", "Unsupported Format", (value) => {
                    return (
                      value &&
                      value.type &&
                      mediaExtentionsAllowed.includes(
                        value.type.split("/")[value.type.split("/").length - 1]
                      )
                    );
                  })
                  .test(
                    "fileSize",
                    `Maxinmum file size exceeded (${maxFileSizeinMb} MB)`,
                    (value) => value && value.size < 1048576 * maxFileSizeinMb
                  ),
                selectValue: Yup.string().required(
                  "Dropdown value is required."
                ),
              }
            : {
                document: Yup.mixed()
                  .required("Media file is reqired.")
                  .test(
                    "fileFormat",
                    "Unsupported Format",
                    (value) =>
                      value &&
                      value.type &&
                      mediaExtentionsAllowed.includes(
                        value.type.split("/")[value.type.split("/").length - 1]
                      )
                  )
                  .test(
                    "fileSize",
                    `Maxinmum file size exceeded (${maxFileSizeinMb} MB)`,
                    (value) => value && value.size < 1048576 * maxFileSizeinMb
                  ),
              }
        )}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        enableReinitialize
      >
        {({ values, setFieldValue, errors, touched, handleSubmit }) => (
          <form onSubmit={handleSubmit} id="inputForm">
            <Flex flexDirection="column" gap="1em" alignItems={"center"} justifyContent={"center"} w={""}>
              <ImageUpload
                name="document"
                formikProps={{
                  setFieldValue,
                  errors,
                  touched,
                }}
                // label={mediaComponentTitle}
                label={""}
                helperText=""
                fileSize={`${maxFileSizeinMb} MB`}
                formatsAllowed={allowedFormatsList(mediaExtentionsAllowed)}
              />
              {hasDropdown && (
                <FormikDropdown
                  label= {documentType ? `Select ${documentType} Type` : "Select Document Type" }
                  name="selectValue"
                  value={values.selectValue}
                  isRequired={true}
                  onChange={(e) => {
                    if (e.target.value) {
                      setFieldValue("selectValue", e.target.value);
                    } else {
                      setFieldValue("selectValue", "");
                    }
                  }}
                >
                  {uniqueValues && uniqueValues.length > 0 ? (
                    uniqueValues.map(({ id, value }) => (
                      <option id={id}>{value}</option>
                    ))
                  ) : (
                    <></>
                  )}
                  {valuesToDeactivate && valuesToDeactivate.length > 0 ? (
                    valuesToDeactivate.map(({ id, value }) => (
                      <option id={id} disabled={true}>
                        {value}
                      </option>
                    ))
                  ) : (
                    <></>
                  )}
                </FormikDropdown>
              )}
            </Flex>
          </form>
        )}
      </Formik>
    </CustomInputModal>
  );
};
