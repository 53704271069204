import { API_CONSTANTS } from "../../../constants/apiConstants"
import { convertToIdValuePairs } from "../../helpers/dataConversion/convertToIdValuePairs";
import {fetchHandler} from "../../../index"

export const getCitiesById = async(stateId) => {
 try{
    if(!stateId){
        throw new Error("State Id cannot be undefined")
    }

    const {data} = await fetchHandler(API_CONSTANTS.GET_CITY, {StateId: stateId}, "", "", API_CONSTANTS.HTTP_GET, { isAuthRequired: false });
    if(data.Status === 200 && Object.keys(data.Data).length > 0){
        const requiredData = data.Data.CitiesList.map(({CityId, CityName}) => (convertToIdValuePairs(CityId, CityName)))
        return requiredData;
    }
    return null
 }
 catch(error){
    throw error;
 }
}