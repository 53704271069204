import { API_CONSTANTS } from "../../../constants/apiConstants";
import { fetchHandler } from "../../../index";
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData";

export const getCommunityPost = async (limit = 10, offset = 0) => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.GET_COMMUNITY_DETAILS}/${limit}/${offset}`,
      {},
      {},
      "",
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: true }
    );

    if (data.Status === 200 && Object.keys(data.Data).length > 0) {
      return data;
    }
    return null;
  } catch (error) {
    throw error;
  }
};
export const postViewCommunityCount = async (id) => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.POST_COMMUNITY_VIEWS}`,
      {},
      {},
      convertToFormData({
        CommunityId: id,
      }),
      API_CONSTANTS.HTT_POST,
      { isAuthRequired: true }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const PostCommunityLikes = async (id) => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.POST_COMMUNITY_LIKES}`,
      {},
      {},
      convertToFormData({
        CommunityId: id,
      }),
      API_CONSTANTS.HTT_POST,
      { isAuthRequired: true }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const GetAudience = async () => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.GET_AUDIENCE}`,
      {},
      {},
      {},
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: true }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const GetContentType = async () => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.GET_CONTENT_TYPES}`,
      {},
      {},
      {},
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: true }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const GetThumbnailImages = async () => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.GET_THUMBNAIL_IMAGES}`,
      {},
      {},
      {},
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: true }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const GetCommunityDetailsById = async () => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.GET_COMMUNITY_DETAILS_BY_ID}`,
      {},
      {},
      "",
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: true }
    );

    if (data.Status === 200) {
      return data;
    }
    return null;
  } catch (error) {
    throw error;
  }
};

export const DeleteCommunityDetails = async (id) => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.DELETE_COMMUNITY_DETAILS}`,
      {},
      {},
      convertToFormData({
        CommunityId: id,
      }),
      API_CONSTANTS.HTT_POST,
      { isAuthRequired: true }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const PostCommunityDetails = async (payload) => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.POST_COMMUNITY_DETAILS}`,
      {},
      {},
      convertToFormData(payload),
      API_CONSTANTS.HTT_POST,
      { isAuthRequired: true }
    );

    return data;
  } catch (error) {
    throw error;
  }
};


export const PostCommunityVideo = async (payload) => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.POST_COMMUNITY_VIDEO}`,
      {},
      {},
      convertToFormData(payload),
      API_CONSTANTS.HTT_POST,
      { isAuthRequired: true }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const UpdateCommunityDetails = async (payload) => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.UPDATE_COMMUNITY_DETAILS}`,
      {},
      {},
      payload,
      API_CONSTANTS.HTT_POST,
      { isAuthRequired: true }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const DeleteCommunityVideo = async (id) => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.DELETE_COMMUNITY_VIDEO}/${id}`,
      {},
      {},
      {},
      API_CONSTANTS.HTTP_DELETE,
      { isAuthRequired: true }
    );

    return data;
  } catch (error) {
    throw error;
  }
};
