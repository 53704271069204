

import { createAsyncThunk } from "@reduxjs/toolkit";
import {getMaterialLikesCountById } from "../../../services/materials/materials";


export const getMaterialLikesCountByIdThunk=createAsyncThunk("my-material/get-likes-count",async (materialTypeId)=>{
    try {
        const data= await getMaterialLikesCountById(materialTypeId);
        return data;
    } catch (error) {
        throw(error);
    }

})