import styled from "styled-components";
import { Link } from "react-router-dom";

// const StyledButton = styled.button`
    //  display: inline-block;
    //  font-weight:700;
    //  border-radius: 0.25em;
    //  cursor: pointer;
     
    // min-height: ${({size}) => {
    // size === "large" 
    // ? "5em" 
    // : size === "small" 
    // ? "3.5625em" 
    // : size === "smaller" 
    // ? "2.75em"
    // : "4.5em"
    // }};

    // max-height: ${({size}) => {
    // size === "large" ? "5em" :
    // size === "small" ? "3.5625em" :
    // size === "smaller" ? "2.75em":
    // "4.5em"
    // }};
    
    // min-width: ${({size}) => {
    // size === "large" ? "14.1875em" :
    // size === "small" ? "9.5625em" :
    // size === "smaller" ? "7.6875em":
    // "11.4375em"
    // }};
    // max-width: ${({size}) => {
    // size === "large" ? "14.1875em" :
    // size === "small" ? "9.5625em" :
    // size === "smaller" ? "7.6875em":
    // "11.4375em"
    // }};
    // padding: ${({size}) => {
    // size === "large" ? "1.75em 3.5em 1.75em 3.5em" :
    // size === "small" ? "1.125em 1.5em 1.125em 1.5em" :
    // size === "smaller" ? "0.875em 1.125em 0.875em 1.125em":
    // "1.5em 2.25em 1.5em 2.25em"
    // }};
    // font-size: ${({size}) => {
    // size === "large" ? "1.75em 3.5em 1.75em 3.5em" :
    // size === "small" ? "1.125em 1.5em 1.125em 1.5em" :
    // size === "smaller" ? "0.875em 1.125em 0.875em 1.125em":
    // "1.5em 2.25em 1.5em 2.25em"
    // }};
    // color: ${({variant}) => {
    // variant === "outlined" ? "var(--primary)" : "var(--light-10)"
    // }} ;
    // background:${({variant}) => {
    // variant === "outlined" ? "var(--light-10)" : "var(--equip9-gradient)"
    // }};
    // border: ${({variant}) => {
    // variant === "outlined" ? "1px solid var(--primary)" : "none"
    // }};
// `

const StyledButton = styled.button`
  display: inline-block;
  font-weight: 700;
  border-radius: 0.25em;
  cursor: pointer;
  min-height: ${({ size }) =>
    size === "large"
      ? "5em"
      : size === "small"
      ? "3.5625em"
      : size === "smaller"
      ? "2.75em"
      : "4.5em"};

  max-height: ${({ size }) =>
    size === "large"
      ? "5em"
      : size === "small"
      ? "3.5625em"
      : size === "smaller"
      ? "2.75em"
      : "4.5em"};

  min-width: ${({ size }) =>
    size === "large"
      ? "14.1875em"
      : size === "small"
      ? "9.5625em"
      : size === "smaller"
      ? "7.6875em"
      : "11.4375em"};

  max-width: ${({ size }) =>
    size === "large"
      ? "14.1875em"
      : size === "small"
      ? "9.5625em"
      : size === "smaller"
      ? "7.6875em"
      : "11.4375em"};

  padding: ${({ size }) =>
    size === "large"
      ? "1.75em 3.5em 1.75em 3.5em"
      : size === "small"
      ? "1.125em 1.5em 1.125em 1.5em"
      : size === "smaller"
      ? "0.875em 1.125em 0.875em 1.125em"
      : "1.5em 2.25em 1.5em 2.25em"};

  font-size: ${({ size }) =>
    size === "large"
      ? "1.75em 3.5em 1.75em 3.5em"
      : size === "small"
      ? "1.125em 1.5em 1.125em 1.5em"
      : size === "smaller"
      ? "0.875em 1.125em 0.875em 1.125em"
      : "1.5em 2.25em 1.5em 2.25em"};

  color: ${({ variant }) =>
    variant === "outlined" ? "var(--primary)" : "var(--light-10)"};

  background: ${({ variant }) =>
    variant === "outlined" ? "var(--dark-50)" : "var(--equip9-gradient)"};

  border: ${({ variant }) =>
    variant === "outlined" ? "1px solid var(--primary)" : "none"};
    &:hover{
    color: ${({variant}) => 
    variant === "outlined" ? "var(--primary)" : "var(--equip9-gradient)"
  }
  }
`;

export const StyledLink = styled(StyledButton.withComponent(Link))`
    text-decoration: none;
    text-align: center;
    margin-left: 0.25em;
`