import React, { useState } from "react";
import "./Equip9SupprtGetTicket.css";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { LoadingSkelaton } from "../../../../../../components/LoadingSkaleton/LoadingSkelton";
import { getRaisedTicket } from "../../../../../../services/e9support/e9support";



export const Equip9GetTicket = () => {
  const userData = useSelector((state) => state.user);
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedCards, setExpandedCards] = useState({});

  const toggleIsExpanded = (index) => {
    setExpandedCards(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };
  const queryClient = useQueryClient();

  const {
    data: getRaisedTicketData,
    isLoading,
    isError,
  } = useQuery("getRaisedTicketdata", () => getRaisedTicket());
  const RaisesList = getRaisedTicketData?.data?.Data?.SupportTicket;

  const formatDateyearwise = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    return `${day}-${month}-${year.slice(-6)}`;
  };

  function formatDate(dateTimeString) {
    const parts = dateTimeString.split(",");
    const date = parts[1];
    const time = parts[2];
    return `${date}`;
  }

  return (
    <>
      {
        <div className="card-support">
            {isLoading?(<div style={{width:'90%'}}> <LoadingSkelaton/></div>):isError?(<></>):(<>
            
                {RaisesList?.length > 0 ? (
            <>
              {RaisesList.map((item, index) => (
                <>
                  <div key={index}>
                    <div className="card-information-support">
                      <div>
                        <div className="added-date-and-verified">
                          <div>
                            <span className="added-on">Posted on:</span>
                            <span className="added-on-date">
                              {formatDate(item.CreatedDateTime)}
                            </span>
                          </div>
                          <div
                            style={{
                              height: "23px",
                              width: "84px",
                              borderRadius: "23px",
                              fontSize: "10px",
                              textAlign: "center",
                              paddingTop: "5px",
                              background:
                                item.SupportTicketStatusValue.toLowerCase() ===
                                "pending"
                                  ? "rgba(214, 0, 0, 0.376)"
                                  : "#319f00",
                              color: "white",
                            }}
                          >
                               <p className="status-value">
                                {/* Refund Closed */}
                                {item.SupportTicketStatusValue}
                               </p>  
                          </div>
                        </div>
                        <div className="added-date-and-verified-support"></div>
                        <div className="support-description">
                          <p>
                            <span className="support-heading-text">
                              Ticket ID:
                            </span>
                            <span className="super-heading-name">
                              {item.SupportTicketId}
                            </span>
                          </p>
                          <p className="support-heading-text">
                            <span>Reason:</span>
                            <span className="super-heading-name">
                              {item.SupportTicketReasonValue}
                            </span>
                          </p>
                          <p className="support-heading-text">
                            <span>Subject:</span>
                            <span className="super-heading-name">
                              {item.Subject}
                            </span>
                          </p>

                          <div>
                            <p>
                              <span className="support-heading-text">
                                Description:
                              </span>
                              {expandedCards[item.SupportTicketId] ? (
                                <span className="super-heading-name">
                                  {item.Description}
                                </span>
                              ) : (
                                <span className="super-heading-name">{`${item.Description.substring(
                                  0,
                                  19
                                )}...`}</span>
                              )}
                              {item.Description.length > 5 && (
                                <button onClick={() => toggleIsExpanded(item.SupportTicketId)}>
                                  {expandedCards[item.SupportTicketId] ? <p className="expand-card">less</p> : <p className="expand-card">more</p>}
                                </button>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : (
            <>
              <p>No ticket raised</p>
            </>
          )}
            </>)}

         
        </div>
      }
    </>
  );
};
