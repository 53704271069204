import { Box, useToast, Flex, Accordion } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { FormikInput } from "../../components/Input/FormikInput";
import { LocationInput } from "../../components/LocationInput/LocationInput";
import { RadioParent } from "../../components/RadioParent/RadioParent";
import {
  clearAdditionalDetails,
  clearBasicDetails,
  increaseStepperIndex,
  setIsSubmitLoading,
} from "./slice/postMaterialsSlice";
import { useDispatch, useSelector } from "react-redux";
import { FormikDropdown } from "../../components/Dropdown/FormikDropdown";
import { v4 as uuid } from "uuid";
import {
  getEquipmentMakesById,
  getEquipmentModelById,
  getEquipmentTypes,
} from "../../services/equipments/equipments";
import {
  getMaterialStore,
  postMaterialDetails,
  updateMaterialStep1,
} from "../../services/materials/materials";
import { useParams, useNavigate } from "react-router-dom";
import { convertFromBase64 } from "../../../utils/formatConverter/convertToBase64";
import { registerNumbersAndDecimal } from "../../../utils/form/form";
import { AccordianElement } from "../../components/AccordianElement/AccordianElement";
import { setSessionStorageItems } from "../../helpers/sessionStorage/sessionStorage";
import { getBasicDetailsForUpdate } from "./thunks/getBasicDetailsForUpdate";
import { SinglePurposePopup } from "../../components/SinglePurposePopup/SinglePurposePopUp";
import editPopUpIcon from "../../../assets/webapp/pop-up/edit-popup.svg";

export const MaterialBasicDetails = ({ materialid }) => {
  const {
    ProductName,
    ProductNumber,
    Location,
    MinPriceRange,
    MaxPriceRange,
    IsOriginal,
    AnyEquipment,
    EquipmentTypeId,
    AnyOEM,
    OEMId,
    AnyModel,
    ModelId,
  } = useSelector((state) => state.postMaterials);

  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();
  const { materialTypeValue } = useParams();
  const MaterialTypeValue = convertFromBase64(materialTypeValue);

  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null);
  const [selectedMake, setSelectedMake] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedEquipmentTypeId, setSelectedEquipmentTypeId] = useState(null);
  const [selectedMakeId, setSelectedMakeId] = useState(null);
  const [equipmentTypesList, setEquipmentTypeList] = useState(null);
  const [equipmentMakeList, setEquipmentMakeList] = useState(null);
  const [equipmentModelList, setEqipmentModelList] = useState(null);
  const [materialTypeId, setMaterialTypeId] = useState(null);
  const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getMaterialStore();
      const foundItem = data.find(
        (item) => item.MaterialTypeValue === MaterialTypeValue
      );
      if (foundItem) {
        setMaterialTypeId(foundItem.MaterialTypeId);
      } else {
        navigate("/materials-type-store-list");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    getMaterialByID();
  }, [materialid]);

  const getMaterialByID = async () => {
    if (materialid) {
      dispatch(getBasicDetailsForUpdate(convertFromBase64(materialid)));
    } else {
      dispatch(clearBasicDetails());
      dispatch(clearAdditionalDetails());
    }
  };

  const getEquipmentTypeList = async () => {
    try {
      const data = await getEquipmentTypes();
      setEquipmentTypeList(data);
    } catch (error) {
      setEquipmentTypeList([]);
    }
  };

  const getEquipmentMakeList = async (id) => {
    try {
      const data = await getEquipmentMakesById(id);
      setEquipmentMakeList(data);
    } catch (error) {
      setEquipmentMakeList([]);
    }
  };

  const getEquipmentModelList = async (id) => {
    try {
      const data = await getEquipmentModelById(id);
      setEqipmentModelList(data);
    } catch (error) {
      setEqipmentModelList([]);
    }
  };

  useEffect(() => {
    const initialApiCall = async () => {
      await getEquipmentTypeList();
    };
    initialApiCall();
  }, []);

  useEffect(() => {
    const apiCall = async (id) => {
      await getEquipmentMakeList(id);
    };
    if (EquipmentTypeId) {
      if (selectedEquipmentTypeId) {
        apiCall(selectedEquipmentTypeId);
      } else {
        apiCall(EquipmentTypeId);
      }
    } else {
      apiCall(selectedEquipmentTypeId);
    }
  }, [selectedEquipmentTypeId, EquipmentTypeId]);

  useEffect(() => {
    const apiCall = async (id) => {
      await getEquipmentModelList(id);
    };
    if (OEMId) {
      if (selectedMakeId) {
        apiCall(selectedMakeId);
      } else {
        apiCall(OEMId);
      }
    } else {
      apiCall(selectedMakeId);
    }
  }, [selectedMakeId, OEMId]);

  const initialValues = {
    productName: ProductName ? ProductName : "",
    productNumber: ProductNumber ? ProductNumber : "",
    materialLocation: Location ? Location : "",
    originalProduct: IsOriginal !== null ? IsOriginal : "",
    minRange: MinPriceRange ? MinPriceRange : "",
    maxRange: MaxPriceRange ? MaxPriceRange : "",
    equipmentType: AnyEquipment !== null ? AnyEquipment : "",
    equipmentTypeValue: EquipmentTypeId ? EquipmentTypeId : "",
    equipmentMake: AnyOEM !== null ? AnyOEM : "",
    equipmentMakeValue: OEMId ? OEMId : "",
    equipmentModel: AnyModel !== null ? AnyModel : "",
    equipmentModelValue: ModelId ? ModelId : "",
  };

  const validationSchema = Yup.object({
    productName: Yup.string()
      .required("Product Name is required")
      .min(3, "Minumum 3 characters required")
      .matches(/^[a-zA-Z0-9\s]+$/, "Only alphanumeric characters are allowed"),
    productNumber: Yup.string().min(3, "Minumum 3 characters required"),
    minRange: Yup.number()
      .typeError("Must be a number")
      .required("Minimum price is required")
      .positive("Minimum price must be a positive number"),
    maxRange: Yup.number()
      .typeError("Must be a number")
      .required("Maximum price is required")
      .positive("Maximum price must be a positive number")
      .test(
        "is-greater",
        "Maximum price should be greater than minimum price",
        function (value) {
          const { minRange } = this.parent;
          return value > minRange;
        }
      ),
    originalProduct: Yup.number()
      .nullable()
      .required("Select Yes or No for Original Product"),
  });

  const handleSubmit = async (values) => {
    // 1. TODO: if values.equipmentType does not exist return a toast message
    if (values.equipmentType === "") {
      toast({
        title: "",
        description: `Equipement Type is Required`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      return;
    }
    // TODO: if we have materialId check whether form is modified by user or not
    if (materialid) {
      const isFormModified =
        ProductName !== values.productName ||
        ProductNumber !== values.productNumber ||
        Location !== values.materialLocation ||
        MinPriceRange !== values.minRange ||
        MaxPriceRange !== values.maxRange ||
        IsOriginal !== values.originalProduct ||
        AnyEquipment !== values.equipmentType ||
        EquipmentTypeId !== (values.equipmentTypeValue || 0) ||
        AnyOEM !== values.equipmentMake ||
        OEMId !== (values.equipmentMakeValue || 0) ||
        AnyModel !== values.equipmentModel ||
        ModelId !== (values.equipmentModelValue || 0);
      // TODO: if not modified open the confirmation model
      if (!isFormModified) {
        setIsConfirmationPopupOpen(true);
        // TODO: else i.e. if modifled, convert material id from base64,
      } else {
        const convertedMaterialId = convertFromBase64(materialid);

        // TODO:then create update payload
        const payload = {
          MaterialDetails: {
            MaterialId: parseInt(convertedMaterialId),
            IsOriginal: 1,
            ProductName: values.productName,
            ProductNumber: values.productNumber,
            MinPriceRange: parseInt(values.minRange),
            MaxPriceRange: parseInt(values.maxRange),
            Location: values.materialLocation.name,
            AnyEquipment: values.equipmentType,
            EquipmentTypeId: values.equipmentTypeValue,
            AnyOEM: values.equipmentMake,
            OEMId: values.equipmentMakeValue,
            AnyModel: values.equipmentModel,
            IsOriginal: values.originalProduct,
            ModelId: values.equipmentModelValue,
            Latitude: values.materialLocation.lat,
            Longitude: values.materialLocation.lng,
          },
        };
        //TODO: set button loading true
        dispatch(setIsSubmitLoading(true));
        // TODO: then send payload data via dispatch
        const data = await updateMaterialStep1(JSON.stringify(payload));
        if (data.Status === 204) {
          toast({
            title: "",
            description: data?.Message?.SuccessMessage,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          // TODO: then if status of update api is 204, update LastId in session storage
          setSessionStorageItems("LastId", convertedMaterialId);
          // TODO: Increase StepperId
          dispatch(increaseStepperIndex());
        } else {
          toast({
            title: "",
            description: data?.Message?.FailMessage,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
        // TODO: set submitting false
        dispatch(setIsSubmitLoading(false));
      }
      //  TODO: else create createProductPayload
    } else {
      const payload = {
        MaterialTypeId: materialTypeId,
        ProductName: values.productName,
        ProductNumber: values.productNumber,
        MinPriceRange: parseInt(values.minRange),
        MaxPriceRange: parseInt(values.maxRange),
        Location: values.materialLocation.name,
        AnyEquipment: values.equipmentType,
        EquipmentTypeId: values.equipmentTypeValue,
        AnyOEM: values.equipmentMake,
        OEMId: values.equipmentMakeValue,
        AnyModel: values.equipmentModel,
        IsOriginal: values.originalProduct,
        ModelId: values.equipmentModelValue,
        Latitude: values.materialLocation.lat,
        Longitude: values.materialLocation.lng,
      };
      //  TODO: setIsSubmitting(True)

      dispatch(setIsSubmitLoading(true));
      // TODO: Post api to create material
      const data = await postMaterialDetails(payload);
      if (data.Status === 201) {
        const LastId = data?.Data?.LastId;
        toast({
          title: "",
          description: data?.Message?.SuccessMessage,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        // TODO: updaqte redux and session store with last id
        // dispatch(setLastId(LastId));
        setSessionStorageItems("LastId", LastId);
        // TODO: increase stepper Index
        dispatch(increaseStepperIndex());
        // TODO: Toast message for error
      } else {
        toast({
          title: "",
          description: data?.Message?.FailMessage,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
      dispatch(setIsSubmitLoading(false));
    }
  };

  return (
    <>
      <SinglePurposePopup
        isOpen={isConfirmationPopupOpen}
        iconUrl={editPopUpIcon}
        bodyText="No Fields have been edited.Do you want to proceed?"
        continueAction={() => {
          setSessionStorageItems("LastId", convertFromBase64(materialid));
          dispatch(increaseStepperIndex());
          setIsConfirmationPopupOpen(false);
        }}
        cancelAction={() => {
          setIsConfirmationPopupOpen(false);
        }}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, initialValues }) => (
          <Form id="post-material-step-one">
            <Accordion allowMultiple>
              <Flex flexDirection="column" gap="1em">
                <AccordianElement title="Material Details">
                  <Flex wrap="wrap" justifyContent="space-between" rowGap="2em">
                    <FormikInput
                      label="Name of the Product"
                      name="productName"
                      value={initialValues?.productName}
                      placeholder="Enter Material Name Here"
                      maxWidth="450px"
                      maxLength={100}
                      isRequired={true}
                    />

                    <div>
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        Price Range <span style={{ color: "red" }}>*</span>
                      </p>
                      <div
                        style={{
                          width: "450px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormikInput
                          name="minRange"
                          placeholder="₹ Enter Min Amount"
                          maxWidth="200px"
                          maxLength={7}
                          onKeyDown={registerNumbersAndDecimal}
                        />

                        <FormikInput
                          name="maxRange"
                          placeholder="₹ Enter Max Amount"
                          maxWidth="200px"
                          maxLength={7}
                          onKeyDown={registerNumbersAndDecimal}
                        />
                      </div>
                    </div>

                    <Field name="materialLocation">
                      {({ field }) => (
                        <LocationInput
                          name={field.name}
                          label="Available Location"
                          placeholder="Enter Location Here"
                          width="450px"
                          maxWidth="450px"
                          height="52px"
                          value={
                            values.materialLocation?.name
                              ? values?.materialLocation?.name
                              : values?.materialLocation
                          }
                          isRequired={true}
                          onChange={(value) => {
                            setFieldValue("materialLocation", value);
                          }}
                        />
                      )}
                    </Field>

                    <FormikInput
                      label="Product Number"
                      name="productNumber"
                      placeholder="Number of the product"
                      maxLength={25}
                      maxWidth="450px"
                    />

                    <Field name="originalProduct">
                      {() => {
                        return (
                          <RadioParent
                            name="originalProduct"
                            options={[
                              { id: 1, value: "Yes" },
                              { id: 0, value: "No" },
                            ]}
                            maxWidth="450px"
                            isFormik={true}
                            isRequired={true}
                            label="Original Product?"
                          />
                        );
                      }}
                    </Field>
                  </Flex>
                </AccordianElement>

                <AccordianElement title="Equipement Details">
                  <Flex wrap="wrap" justifyContent="space-between" rowGap="2em">
                    <Field name="equipmentType">
                      {({ field, form }) => (
                        <RadioParent
                          name={field.name}
                          options={[
                            {
                              id: 0,
                              value: "For Any Equipment",
                            },
                            {
                              id: 1,
                              value: "For Specifice Equipment",
                            },
                          ]}
                          maxWidth="450px"
                          isFormik={true}
                          isRequired={true}
                          label="Equipment Type?"
                          onChange={(value) => {
                            setFieldValue("equipmentType", value);

                            setFieldValue("equipmentTypeValue", null);
                            setFieldValue("equipmentMakeValue", null);
                            setFieldValue("equipmentModelValue", null);

                            setFieldValue("equipmentMake", null);
                            setFieldValue("equipmentModel", null);

                            setSelectedEquipmentType(value);
                            setSelectedMake(null);
                            setSelectedModel(null);
                          }}
                        />
                      )}
                    </Field>
                    {(selectedEquipmentType === 1 ||
                      values.equipmentTypeValue) && (
                      <FormikDropdown
                        name="equipmentTypeValue"
                        label="Specify Equipment Type"
                        value={values.equipmentTypeValue}
                        isRequired={true}
                        onChange={(e) => {
                          setFieldValue("equipmentTypeValue", e.target.value);
                          setSelectedEquipmentTypeId(e.target.value);
                          setFieldValue("equipmentMakeValue", null);
                          setFieldValue("equipmentModelValue", null);
                          setFieldValue("equipmentMake", 0);
                          setSelectedMake(0);
                          setSelectedModel(0);
                        }}
                      >
                        {equipmentTypesList &&
                          equipmentTypesList.map((make) => (
                            <option key={uuid()} value={make.id}>
                              {make.value}
                            </option>
                          ))}
                      </FormikDropdown>
                    )}

                    {values.equipmentTypeValue &&
                      values.equipmentType === 1 && (
                        <Field name="equipmentMake">
                          {({ field }) => (
                            <RadioParent
                              name={field.name}
                              options={[
                                {
                                  id: 0,
                                  value: "For Any OEM",
                                },
                                {
                                  id: 1,
                                  value: "For Specifice OEM",
                                },
                              ]}
                              maxWidth="450px"
                              isFormik={true}
                              isRequired={true}
                              label="Equipment Make?"
                              onChange={(value) => {
                                setSelectedMake(value);
                                setFieldValue("equipmentMakeValue", null);
                                setFieldValue("equipmentModelValue", null);
                                setFieldValue("equipmentModel", null);
                              }}
                            />
                          )}
                        </Field>
                      )}
                    {(selectedMake === 1 || values.equipmentMakeValue) && (
                      <FormikDropdown
                        name="equipmentMakeValue"
                        label="Specify Equipment Make"
                        value={values.equipmentMakeValue}
                        isRequired={true}
                        onChange={(e) => {
                          setFieldValue("equipmentMakeValue", e.target.value);
                          setFieldValue("equipmentModelValue", null);
                          setFieldValue("equipmentModel", 0);
                          setSelectedModel(0);
                          setSelectedMakeId(e.target.value);
                        }}
                      >
                        {equipmentMakeList &&
                          equipmentMakeList.map((make) => (
                            <option key={uuid()} value={make.id}>
                              {make.value}
                            </option>
                          ))}
                      </FormikDropdown>
                    )}

                    {values.equipmentMakeValue &&
                      values.equipmentMake === 1 && (
                        <Field name="equipmentModel">
                          {({ field }) => (
                            <RadioParent
                              name={field.name}
                              options={[
                                {
                                  id: 0,
                                  value: "For Any Model",
                                },
                                {
                                  id: 1,
                                  value: "For Specifice Model",
                                },
                              ]}
                              maxWidth="450px"
                              isFormik={true}
                              isRequired={true}
                              label="Equipment Model?"
                              onChange={(value) => {
                                setSelectedModel(value);
                                setFieldValue("equipmentModelValue", null);
                              }}
                            />
                          )}
                        </Field>
                      )}
                    {(selectedModel === 1 || values.equipmentModelValue) && (
                      <FormikDropdown
                        name="equipmentModelValue"
                        label="Specify Equipment Model"
                        value={values.equipmentModelValue}
                        isRequired={true}
                        onChange={(e) => {
                          setFieldValue("equipmentModelValue", e.target.value);
                        }}
                      >
                        {equipmentModelList &&
                          equipmentModelList.map((make) => (
                            <option key={uuid()} value={make.id}>
                              {make.value}
                            </option>
                          ))}
                      </FormikDropdown>
                    )}
                  </Flex>
                </AccordianElement>
              </Flex>
            </Accordion>
          </Form>
        )}
      </Formik>
      {/* </Box> */}
    </>
  );
};
