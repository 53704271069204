import { useDispatch, useSelector } from "react-redux";
import { SetIsActiveQuizModalOpen, setSelectedAnswerData, setUserScore } from "../slice/quizSlice";
import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { CustomGeneralModal } from "../../../components/CustomGeneralModal/CustomGeneralModal";
import { subString } from "../../../helpers/dataConversion/substring";
import { useNavigate } from "react-router-dom";
import { createUserQuizHistoryDetails } from "../../../services/quiz/quiz";

export const ActiveQuizPopup = () => {
  const { isActiveQuizPopupOpen, selectedQuiz } = useSelector(
    (state) => state.quiz
  );
  const dispatch = useDispatch();
  const navigate= useNavigate();
  const onButtonClick = () => {
    dispatch(SetIsActiveQuizModalOpen(false));
  };

  const postUserQuizHistoryDetails=async ()=>{
    const payload={
      QuizId : selectedQuiz?.QuizId
    }
    await createUserQuizHistoryDetails(payload);
  }

  return (
    <>
      <CustomGeneralModal
        isOpen={isActiveQuizPopupOpen}
        iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/popup/startQuiz.svg`}
        heading={selectedQuiz?.QuizName}
        iconHeight="50px"
        iconWidth="115px"
        iconMaxWidth="115px"
        iconMaxHeight="50px"
        primaryAction={() => {
          dispatch(SetIsActiveQuizModalOpen(false));
          dispatch(setUserScore(0));
          dispatch(setSelectedAnswerData(null));
          postUserQuizHistoryDetails();
          navigate("/quiz-session");
        }}
        secondaryAction={onButtonClick}
        primaryActionText={"Play"}
        secondaryActionText={"No Thanks"}
        hasCloseButton={true}
        onCloseButtonClick={onButtonClick}
        margin={"0 60px"}
        footerShadow={true}
        isArrow={false}
      >
        <Flex direction={"column"} gap={"1.25em"}>
          <Flex
            fontSize={"16px"}
            fontWeight={"700"}
            gap={"12px"}
            color={"#1A1E21"}
            direction={"column"}
          >
            <Tooltip
              label={
                selectedQuiz?.QuizDetails?.length > 30
                  ? selectedQuiz?.QuizDetails
                  : null
              }
            >
              <Text>
                {selectedQuiz?.QuizDetails
                  ? selectedQuiz?.QuizDetails?.length > 0
                    ? subString(30, selectedQuiz?.QuizDetails)
                    : selectedQuiz?.QuizDetails
                  : "NA"}
              </Text>
            </Tooltip>
            <ul style={{ paddingLeft: "5px", color: "#002961" }}>
              <li>
                {" "}
                • Total Time: {selectedQuiz?.QuestionQuizTimerInMinutes}m
              </li>
              <li> • Total Quiz Questions: {selectedQuiz?.TotalQuestions}</li>
              <li>
                {" "}
                • Total Quiz Points Awarded: {selectedQuiz?.TotalQuestPoints}
              </li>
            </ul>
          </Flex>
          <Flex
            fontSize={"16px"}
            fontWeight={"600"}
            color={"#767676"}
            direction={"column"}
          >
            <p>By clicking on Play Quiz you are agreeing </p>
            <p>
              to the{" "}
              <span style={{ color: "#002961",cursor:"pointer" }} onClick={()=>{
                  dispatch(SetIsActiveQuizModalOpen(false));
                 navigate("/quiz/terms-and-conditions");
               
              }}> Terms and Conditions</span>
            </p>
          </Flex>
        </Flex>
      </CustomGeneralModal>
    </>
  );
};
