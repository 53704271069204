import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Input,
  Text,
  Toast,
  useClipboard,
} from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "@chakra-ui/icons";
import { closeQrModal } from "../../features/dashboard/slice/dashboardSlice";
import appleBadge from "../../../assets/qr-codes/apple-badge.svg";
import qrCode from "../../../assets/qr-codes/Equip9 - Android.png";
import googlePlayBadge from "../../../assets/qr-codes/google-play-badge.svg";
import { useNavigate } from "react-router-dom";
import { CustomModal } from "../CustomModal/CustomModal";

export const QrPopUp = () => {
  const dispatch = useDispatch();
  const dashboardData = useSelector((state) => state.dashboard);
  const placeholder = "Copy Equip9 Link Here ...";
  const { onCopy, value, setValue, hasCopied } = useClipboard("");

  const copyToClipboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(function () {
          //   console.log("Copied successfully !",text);
          setValue(text);
          const newTab = window.open(text, "_blank");
          newTab.focus();
        })
        .catch(function (err) {
          console.error("Unable to copy", err);
        });
    } else {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
        // console.log("Copied successfully!");
        setValue(text);
        const newTab = window.open(text, "_blank");
        newTab.focus();
      } catch (err) {
        console.error("Unable to copy TEXTT AREA", err);
      }
      document.body.removeChild(textarea);
    }
  };

  return (
    <CustomModal
      isOpen={dashboardData.isQrModalOpen}
      hasCloseButton={true}
      onCloseButtonClick={() => {
        dispatch(closeQrModal());
      }}
    >
      <CustomModal.Body>
        <Flex padding={"26px 0"} gap={"10px"}>
          <Flex justifyContent="center" alignItems="center" justify="center">
            <Center flexDirection="column">
              <Box
                className="modal-body"
                as="img"
                maxW={"119px"}
                src={qrCode}
                alt="QrCode"
                margin={"7px 15px"}
              />

              <Flex
                alignItems="center"
                marginTop="8px"
                w={"100%"}
                maxW={"130px"}
              >
                {/* <Input
                  placeholder={placeholder}
                  value={value}
                  mr={2}
                  isReadOnly={true}
                /> */}
                <Button onClick={onCopy} w={"100%"} gap={"7px"}>
                  <Box height={"16px"} w={"16px"}>
                    <img
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/popup/${hasCopied ? "linkCopied.svg" : "copyLink.svg"}`}
                    />
                  </Box>
                  <Text minW={"80px"}> {hasCopied ? "Link Copied!" : "Copy Link"}</Text>
                 
                </Button>
              </Flex>
            </Center>
          </Flex>

          <Flex direction={"column"} justifyContent={"space-between"}>
            <Flex direction={"column"}>
              <Heading
                fontSize="20px"
                fontWeight="700"
                fontFamily="Assistant"
                lineHeight="26.16px"
                color={"#141619"}
              >
                Download Mobile App
              </Heading>
              <Text
                fontSize="16px"
                fontWeight="400"
                fontFamily="Assistant"
                lineHeight="20.93px"
                color={"#767676"}
              >
                The web app is currently under development. In the meanwhile,
                you can get our app on the App Store or the Play Store. Simply
                open the link.
              </Text>
            </Flex>

            <Flex>
              <Box
                id="ios"
                as="img"
                src={appleBadge}
                alt="Apple Badge"
                width="115px"
                height="40px"
                cursor="pointer"
                onClick={() =>
                  copyToClipboard(
                    "https://apps.apple.com/us/app/equip9/id1538560258"
                  )
                }
              />
              <Box
                id="android"
                as="img"
                src={googlePlayBadge}
                alt="Google Play Badge"
                width="130px"
                height="40px"
                cursor="pointer"
                onClick={() =>
                  copyToClipboard(
                    "https://play.google.com/store/apps/details?id=com.equip9&hl=en_IN&gl=US"
                  )
                }
              />
            </Flex>
          </Flex>
        </Flex>
      </CustomModal.Body>
    </CustomModal>
  );
};
