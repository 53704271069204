import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Like } from "../Like/Like";
import { useSelector } from "react-redux";

const SentIndustryRequirementCard = ({
  imageData,
  ProfilePhoto,
  CreatedDateTime,
  ExpiredOn,
  UserName,
  BusinessTypeValue,
  Requirement,
  RequirementCategoryValue,
  City,
  State,
  isReceivedCard = false,
  likeCount,
  requestCount,
  ImageFileRef,
  onLikeButtonClick = () => {},
  onRequestButtonClick = () => {},
  ResponseTypeValue,
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Flex>
      <Box
        boxShadow="0px 0px 5px 0px #00000040"
        padding="0em 1em"
        margin="0em 1em"
        display="flex"
        flexDirection="column"
        gap="5px"
        borderRadius="4px"
      >
        {/* Header */}
        <Flex direction="column">
          <Flex justifyContent="space-between" width="100%" alignItems="center">
            <Box>
              <Text color="#767676">
                Posted On: {CreatedDateTime ? CreatedDateTime : "N.A"} |{" "}
                <Text as="span">
                  Expires On: {ExpiredOn ? ExpiredOn : "N.A"}
                </Text>
              </Text>
            </Box>
          </Flex>
          <Divider />
        </Flex>

        {/* Industry Body */}
        <Flex gap="1em" padding="0.5em 0.5em">
          <Box
            maxH="100px"
            maxW="150px"
            minH="100px"
            minW="150px"
            borderRadius="4px"
          >
            <img
              style={{ height: "100%", width: "100%", borderRadius: "4px" }}
              src={
                ImageFileRef
                  ? `${process.env.REACT_APP_CDN_PVT_URL}${ImageFileRef}`
                  : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/DelarshipDefaultImage.png`
              }
              alt="Requirement"
            />
          </Box>

          <Box width="100%">
            <Box display="flex" flexDirection="column" gap="1em">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Flex alignItems="center" gap="0.50em">
                  <img
                    style={{
                      maxHeight: "42px",
                      maxWidth: "42px",
                      minHeight: "42px",
                      minWidth: "42px",
                      borderRadius: "50%",
                    }}
                    src={
                      ProfilePhoto
                        ? `${process.env.REACT_APP_CDN_PVT_URL}UserProfilePicture/${ProfilePhoto}`
                        : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Avatar.png`
                    }
                  />

                  <Box>
                    <Text color="#141619" fontWeight="700" fontSize="18px">
                      {UserName ? UserName : "N.A"}
                    </Text>
                    <Text color="#484848" fontSize="16px">
                      {BusinessTypeValue ? BusinessTypeValue : "N.A"} |{" "}
                      <Text as="span" color="#767676" fontSize="12px">
                        {City ? City : "N.A"}, {State ? State : "N.A"}
                      </Text>
                    </Text>
                  </Box>
                </Flex>

                <Flex alignItems="center" direction="column">
                  <Flex gap="1em">
                    {isReceivedCard ? (
                      <>
                        <Button
                          leftIcon={
                            <img
                              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/like.svg`}
                            />
                          }
                          color="#002961"
                          fontWeight="700"
                          border="1px solid #D60000"
                          backgroundColor="transparent"
                          boxShadow="none"
                          onClick={onLikeButtonClick}
                        >
                          {likeCount ? `${likeCount} Likes` : "0 Likes"}
                        </Button>

                        <Button
                          leftIcon={
                            <img
                              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/phone.svg`}
                            />
                          }
                          color="#002961"
                          fontWeight="700"
                          border="1px solid #002961"
                          backgroundColor="transparent"
                          boxShadow="none"
                          onClick={onRequestButtonClick}
                        >
                          {requestCount
                            ? `${requestCount} Requests`
                            : "0 Requests"}
                        </Button>
                      </>
                    ) : ResponseTypeValue?.toString()?.toLowerCase() ===
                      "request accepted" ? (
                      <Button
                        backgroundColor="transparent"
                        border="none"
                        boxShadow="none"
                        color="#319F00"
                        fontSize="14px"
                        fontStyle="600"
                        _hover={{
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        }}
                        leftIcon={
                          <img
                            src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/tick.svg`}
                          />
                        }
                      >
                        {ResponseTypeValue}
                      </Button>
                    ) : ResponseTypeValue?.toString()?.toLowerCase() ===
                      "request rejected" ? (
                      <Button
                        backgroundColor="transparent"
                        border="none"
                        boxShadow="none"
                        color="#D60000"
                        fontSize="14px"
                        fontStyle="600"
                        _hover={{
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        }}
                        leftIcon={
                          <img
                            src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/cross.svg`}
                          />
                        }
                      >
                        {ResponseTypeValue}
                      </Button>
                    ) : (
                      <Text color="#F79C00">Request Sent</Text>
                    )}
                  </Flex>
                </Flex>
              </Box>

              <Box>
                <Text fontSize="16px" fontWeight="700" color="#002961">
                  {RequirementCategoryValue ? RequirementCategoryValue : "N.A"}
                </Text>
                <Flex align="center" justify="space-between">
                  <Box flex="1" overflow="hidden">
                    <Text
                      color="#767676"
                      fontSize="14px"
                      fontWeight="600"
                      noOfLines={isOpen ? undefined : 1}
                      maxWidth="920px"
                      minW="900px"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace={isOpen ? "normal" : "nowrap"}
                    >
                      {Requirement}
                    </Text>
                  </Box>
                  <Button
                    rightIcon={
                      isOpen ? (
                        <img
                          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/arrowUp.svg`}
                        />
                      ) : (
                        <img
                          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/arrowDown.svg`}
                        />
                      )
                    }
                    color="#002961"
                    border="none"
                    backgroundColor="transparent"
                    boxShadow="none"
                    _hover={{
                      border: "none",
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    }}
                    size="sm"
                    onClick={onToggle}
                  >
                    {isOpen ? "Read Less" : "Read More"}
                  </Button>
                </Flex>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default SentIndustryRequirementCard;
