import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Text, useToast } from "@chakra-ui/react";
import { MultiAdDivider } from "../../../../layout/multiAdLayout/MultiAdLayout";
import { GenerateOtpHoc } from "../../../otp/hoc/generateOtpHoc/GenerateOtpHoc";
import { sendForgetPasswordThunk } from "../../thunk/forgotPasswordThunk";
import { setSelectedCountryThunk } from "../../../global/appConfig/thunk/setSelectedCountryThunk";
import useWindowWidth from "../../../../../sharedComponent/isMobile/CheckMobile";
import { MobileHeader } from "../../../../../website/components/MobileHeader/MobileHeader";
import { MobileCarousel } from "../../../../../website/components/MobileCarosel/MobileCarosel";
import { Button, Flex } from "@chakra-ui/react";
import { getDefaultAppLanguage } from "../../../global/mtData/helpers/localStorage";
import { V5MultiAdLayout } from "../../../../layout/multiAdLayout/V5MultiAdLayout";

export const GenerateForgetPasswordOtp = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const appConfig = useSelector((state) => state.appConfig);
  const isMobile = useWindowWidth();
  const defaultLanguage = getDefaultAppLanguage();

  const onSuccessfulValidation = async (values) => {
    try {
      const response = await dispatch(
        sendForgetPasswordThunk({
          MobileNumber: values.mobileNumber,
          IsdCode: values.countryCode,
        })
      ).unwrap();
      navigate("/forgot-password/verify-otp");
    } catch (error) {
      toast({
        title: `${error}`,
        isClosable: true,
        position: "top-right",
        status: "error",
      });
      return error;
    }
  };

  useEffect(() => {
    if (!appConfig.country) {
      dispatch(setSelectedCountryThunk("91"));
    }
  }, []);
  return (
    <V5MultiAdLayout
      heading={defaultLanguage?.Fill_Your_Account_Details_for_Easy_Setup}
      subHeading={defaultLanguage?.It_wont_take_long}
      justifyContent=""
    >
      <Box maxW="400px" margin="0 auto">
        <GenerateOtpHoc onSuccessfulValidation={onSuccessfulValidation} />
      </Box>
      <Text
        fontSize="14px"
        textAlign="center"
        width="350px"
        margin="0 auto"
        marginTop="0.7em"
      >
        Just enter your mobile number above and reset your password quick and
        easy.
      </Text>
    </V5MultiAdLayout>
  );
};
