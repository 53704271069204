import { Flex, Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import emptyStar from "../../../../assets/webapp/leads/unrated-star.svg";
import filledStar from "../../../../assets/webapp/leads/filled-star.svg";

export const Rating = ({ totalRating = 5, onRatingChange = () => {} }) => {
  const [rating, setRating] = useState(0);
  const [temporaryRating, setTemporaryRating] = useState(0); 

  const handleTemporaryRating = (count) => {
    setTemporaryRating(count); 
  };

  const handleSetRating = (count) => {
    setRating(count);
  };

  const handleResetTemporaryRating = () => {
    setTemporaryRating(0); 
  };

  const handleRatingChange = () => {
    onRatingChange(rating)
  }

  useEffect(() => {
    handleRatingChange()
  }, [rating])
 
  return (
    <Flex gap="0.5em" onMouseLeave={handleResetTemporaryRating}>
      {new Array(totalRating).fill(null).map((_, index) => (
        <Image
          key={index}
          src={index < (temporaryRating || rating) ? filledStar : emptyStar}
          onMouseOver={() => handleTemporaryRating(index + 1)} 
          onClick={() => handleSetRating(index + 1)} 
          alt={`Rating star ${index + 1}`}
          cursor="pointer"
        />
      ))}
    </Flex>
  );
};
