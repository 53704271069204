import { createSlice } from "@reduxjs/toolkit";

export const remLeadsSlice = createSlice({
    name: "remSubscriptionLeads",
    initialState : {
        data : null,
        selectedSubscription : null,
        leadsRemaining: 0,
        totalLeads: 0,
        purchasedLeadsLoading : false

    },
    reducers: {
        setData : (state, {payload}) => {state.data = payload}
    },
})

export const {setData} = remLeadsSlice.actions