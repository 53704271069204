import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getEquipmentAvailability,
  getRentalDurationByE9MarketPlaceId,
} from "../../../services/myLeads/myLeads";

export const getRentalDurationThunk = createAsyncThunk(
  "myLeads/getRentalDuration",
  async (_, { getState }) => {
    try {
      const { E9MarketPlaceId } = getState().myLeads;
      const data = await getRentalDurationByE9MarketPlaceId(E9MarketPlaceId);
      // console.log("Data Rental Called",data);
      return data;
    } catch (error) {}
  }
);

export const getEquipmentAvailabilityThunk = createAsyncThunk(
  "myLeads/getEquipmentAvailability",
  async (_, { getState }) => {
    try {
      const { token } = getState().user;
      const data = await getEquipmentAvailability(token);
      return data;
    } catch (error) {}
  }
);
