import axios from "axios";
import { API_CONSTANTS } from "../../constants/apiConstants";
import { store } from "../app/store";
import { updateRefreshToken } from "../features/global/user/slice/userSlice";
import { convertDateTimeToMilliseconds } from "../features/subscription/helpers/helpers";
import { setSelectedCountryThunk } from "../features/global/appConfig/thunk/setSelectedCountryThunk";

const controller = new AbortController();

const getBrowserDetails = () => {
  //console.log("inside getBrowserDetails")
  const { userAgent, appCodeName, appName, appVersion, vendor, platform } =
    window.navigator;
    //console.log("after  window.navigator")
    let mozillaVendor; 
    if(!vendor && userAgent && userAgent.indexOf("Mozilla") !== -1){
      mozillaVendor = "Mozilla Firefox"
    }
    //console.log({
    //   "x-appcodename": appCodeName,
    //   "x-appname": appName,
    //   "x-appversion": appVersion,
    //   "x-useragent": userAgent,
    //   "x-vendor": mozillaVendor || vendor,
    //   "x-platform": platform,
    //   "x-client-type": "Web",
    // })
  return {
    "x-appcodename": appCodeName,
    "x-appname": appName,
    "x-appversion": appVersion,
    "x-useragent": userAgent,
    "x-vendor": mozillaVendor || vendor,
    "x-platform": platform,
    "x-client-type": "Web",
  };
  
};

/**
 * Generates a query string from an object of parameters.
 *
 * @param {Object} params - The parameters object used to generate the query string.
 * @returns {string} - The generated query string.
 * @throws {Error} - Throws an error if params is undefined, not an object, or an empty object.
 *
 * @example
 * const params = { key1: 'value1', key2: 'value2', key3: 'value3' };
 * const queryString = paramsHandler(params);
 * // Result: 'key1=value1&key2=value2&key3=value3'
 */

const queryParamsHandler = (params) => {
  if (
    !params ||
    typeof params !== "object" ||
    Object.keys(params).length <= 0
  ) {
    throw new Error(
      "Params cannot be undefined, string or array, it only accepts objects"
    );
  }

  const objectKeysArray = Object.keys(params);
  const query = objectKeysArray.reduce(
    (finalQuery = "", currentParam, index) => {
      if (params[index] === "") {
        return finalQuery;
      }

      if (index === objectKeysArray.length - 1) {
        return (finalQuery += `${currentParam}=${params[currentParam]}`);
      }
      return (finalQuery += `${currentParam}=${params[currentParam]}&`);
    },
    ""
  );

  return query;
};


const authAxios = axios.create();
authAxios.interceptors.request.use(
  function (config) {
    const token = config.headers.token;
    const { issuedAtTime, expiryTime } = store.getState().user;
    const isExpiryAndIssuedTimePresent = () => issuedAtTime && expiryTime;

    if (!token) {
      controller.abort();
      logout();
      // throw new Error("Token is not present.")
    }
    if (!isExpiryAndIssuedTimePresent) {
    }
    if (isTokenExpired(parseInt(issuedAtTime) + parseInt(expiryTime))) {
      return logout();
    }
    if (
      shouldRefreshToken(
        parseInt(issuedAtTime) + parseInt(expiryTime),
        parseInt(expiryTime)
      )
    ) {
      refreshToken()
        .then((response) => {
          const newHeaders = { ...config.headers, token: response };
          return { ...config, headers: newHeaders };
        })
        .catch((error) => {
          throw error;
        });
    }
    return config;
  },
  function (error) {
    return error;
  }
);

/**
 * Performs an HTTP request using Axios library with optional parameters. It includes headers on its own. Need not to mention auth token
 *
 * @param {string} url - The endpoint URL for the HTTP request. Add path params in endpoint itself @example /data/1/2.
 * @param {Object} [queryParamsObject={}] - The parameters object used to generate the query string. if not required, pass it by using empty object {}. (Don't confuse it with path params)
 * @param {Object} headers - Additional headers to include in the request.
 * @param {Object} [body={}] - The request body for POST, PUT, or other non-GET methods.
 * @param {string} [method=API_CONSTANTS.HTTP_GET] - The HTTP method for the request.
 *     Allowed values: "GET", "POST", "PUT", "DELETE".
 * @param {boolean} [isAuthRequired] - default value is true, for non - auth api mention false 
 * @returns {Promise} - A Promise that resolves to the Axios response.
 * @throws {Error} - Throws an error if the method is invalid or if paramsObject is not an object.
 
 * @example
 * const response = await fetchHandler(
 *   '/api/data/1',
 *   { key: 'value', param: 'another' },
 *   'POST',
 *   { ContentType : "text/html" },
 *   { data: 'example' }
 * );
 */
export default async function fetchHandler(
  url = "",
  queryParamsObject = {},
  headers,
  body = {},
  method = API_CONSTANTS.HTTP_GET,
  options = { isAuthRequired: true }
) {
  //console.log("inside fetch handler")
  const { token, issuedAtTime, expiryTime } = store.getState().user;

  const URL =
    queryParamsObject && Object.keys(queryParamsObject).length > 0
      ? `${process.env.REACT_APP_API_ENDPOINT}${url}?${queryParamsHandler(
          queryParamsObject
        )}`
      : `${process.env.REACT_APP_API_ENDPOINT}${url}`;

  // if (options.isAuthRequired) {
  //   if (!isTokenPresent()) {
  //     return logout();
  //   }
  //   if (!isExpiryAndIssuedTimePresent()) {
  //   }
  //   if (isTokenExpired(parseInt(issuedAtTime) + parseInt(expiryTime))) {
  //     return logout();
  //   }

  //   if (
  //     shouldRefreshToken(
  //       parseInt(issuedAtTime) + parseInt(expiryTime),
  //       parseInt(expiryTime)
  //     )
  //   ) {
  //     await refreshToken();
  //   }
  // }
//console.log("before apiHeaders")
//console.log(headers)
  const apiHeaders = { ...headers, ...getBrowserDetails() };
  if (options.isAuthRequired) {
    apiHeaders.token = token;
  }
//console.log("after api headers")

  //console.log({ url: URL,
    // method: !method ? API_CONSTANTS.HTTP_GET : method,
    // headers: apiHeaders,
    // data: !method || method === API_CONSTANTS.HTTP_GET ? null : body,})

  return options.isAuthRequired
    ? authAxios({
        url: URL,
        method: !method ? API_CONSTANTS.HTTP_GET : method,
        headers: apiHeaders,
        data: !method || method === API_CONSTANTS.HTTP_GET ? null : body,
      })
    : axios({
        url: URL,
        method: !method ? API_CONSTANTS.HTTP_GET : method,
        headers: apiHeaders,
        data: !method || method === API_CONSTANTS.HTTP_GET ? null : body,
      });
}

const refreshToken = async () => {
  try {
    const { token } = store.getState().user;
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/RefreshJWTTokenWeb`,
      {
        headers: { token, ...getBrowserDetails() },
      }
    );
    // //console.log("refreshData => ", data);
    store.dispatch(
      updateRefreshToken({
        token: data.Data.Token,
        issuedAt: convertDateTimeToMilliseconds(
          `${data.Data.InitializedTime} UTC`
        ),
        expiryTime: parseInt(data.Data.ExpiryTimeInMinute) * 60 * 1000,
      })
    );
    return data.data.token;
  } catch (error) {
    return error;
  }
};

const isTokenExpired = (totalSessionTime) => {
  const presentTime = new Date().getTime();
  return totalSessionTime < presentTime;
};

const shouldRefreshToken = (totalSessionTime, expiryTime) => {
  const presentTime = new Date().getTime();
  return (
    (((totalSessionTime - presentTime) / expiryTime) * 100).toFixed(2) <= 20
  );
};

const logout = () => {
  localStorage.clear();
  store.dispatch(setSelectedCountryThunk("91"));
  window.location.reload();
};

const getPresentGMTTime = () => {
  const gmtTimeString = new Date().toGMTString();
  return new Date(gmtTimeString).getTime();
};
