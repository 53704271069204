import { createAsyncThunk } from "@reduxjs/toolkit";
import fetchHandler from "../../../services/fetchHandler";


export const myMaterialsLoadMoreDataThunk = createAsyncThunk("Materials/getLoadMoreCards", async(materialTypeId,{getState}) => {

    const {paginationCount,cardCount} = getState().myMaterials ;
  
    try {
        const {data} = await fetchHandler(`/GetMaterialList/${cardCount}/${paginationCount*cardCount}`,{
            MaterialTypeId: materialTypeId,
        })
        if (data.Status === 200 && Object.keys(data.Data).length> 0){
            return data;
        }else{
            throw new Error("Failed to fetch Material List");
        }
    } catch (error) {
        throw error;
    }
     
})