import { Formik } from "formik";
import { NewSideMenuLayout } from "../../../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { FormikInput } from "../../../../components/Input/FormikInput";
import { Button, useToast } from "@chakra-ui/react";
// import { FormikDropdown } from "../../../../components/FormikDropdown/FormikDropdown"
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
// import { createIndividualSubscriptionThunk } from "../../thunks/createIndividualSubscriptionThunks";
import { FormikDropdown } from "../../../../components/Dropdown/FormikDropdown";
import { saveCompanyAddressThunk } from "../../thunks/saveCompanyAddressDetails";
import { addIndividualAddressThunk } from "../../thunks/addIndividualAddressThunk";
import { useNavigate } from "react-router-dom";
import { getCitiesById } from "../../../../services/cities/cities";
import { getStatesById } from "../../../../services/states/states";


export const UpdateAddress = () => {
  const { Countries } = useSelector((state) => state.masterData);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [stateList, setStateList] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [cityList, setCityList] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { UserTypeValue } = useSelector((state) => state.user);
  const {defaultLanguage} = useSelector(state => state.masterData);
  useEffect(() => {
    (async () => {
      try {
        const data = await getStatesById(selectedCountry);
          setStateList(data);
          setCityList(null);
      
      } catch (error) {
        console.log(error);
      }
    })();
  }, [selectedCountry]);

  useEffect(() => {
    (async () => {
      try {
        const data = await getCitiesById(selectedState);
        setCityList(data);
     
      } catch (error) {
        console.log(error);
      }
    })();
  }, [selectedState]);

  return (
    <NewSideMenuLayout justifyContent="space-between" gridTemplateRows="80px 1fr 80px"> 
      <div
        style={{
          margin: "0px 52px",
          backgroundColor: "#92929204",
          paddingTop: "1em",
        }}
      >
        <div style={{ marginBottom: "32px" }}>
          <h1 style={{ fontSize: "32px", fontWeight: 700 }}>
            {defaultLanguage?.Update_Your_Address}
          </h1>
        </div>
        <Formik
          initialValues={{
            addressLine1: "",
            addressLine2: "",
            country: "",
            pincode: "",
          }}
          validationSchema={Yup.object({
            addressLine1: Yup.string()
              .required("Required")
              .min(10, "Address should have 10 characters minimum"),
            addressLine2: Yup.string()
              .required("Required")
              .min(10, "Address should have 10 characters minimum"),
            country: Yup.string().required("Required"),
            state: Yup.string().required("Required"),
            city: Yup.string().required("Required"),
            pincode: Yup.string()
              .required("Required")
              .matches(/^[1-9]\d{5}$/, "Pincode should have exactly 6 numbers"),
          })}
          onSubmit={async (values) => {
            
            const body = {
              Address: `${values.addressLine1} ${values.addressLine2}`,
              CountryId: values.country,
              StateId: values.state,
              CityId: values.city,
              ZipCode: values.pincode,
            };
            if (UserTypeValue.toLowerCase() === "Company".toLowerCase()) {
              await dispatch(saveCompanyAddressThunk(body))
                .unwrap()
                .then((data) => {
                  navigate("/subscription/subscription-type");
                })
                .catch((error) => {
                  console.log(error)
                  toast({
                    title: "Some error occured",
                    description: "Unable to update address",
                    position: "top-right",
                    status: "error",
                  });
                });
            } else {
              await dispatch(addIndividualAddressThunk(body))
                .unwrap()
                .then((data) => {
                  navigate("/subscription/subscription-type");
                })
                .catch((error) => {
                  toast({
                    title: "Some error occured",
                    description: "Unable to update address",
                    position: "top-right",
                    status: "error",
                  });
                });
            }
          }}
        >
          {({ setFieldValue, values, handleSubmit }) => (
            <form onSubmit={handleSubmit} id="address-form">
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  rowGap: "1em",
                  columnGap: "1em",
                  width: "85%",
                  margin: "0 auto",
                }}
              >
                <FormikInput
                  name="addressLine1"
                  placeholder="Example 416, Wakad, Pune"
                  label="Address Line 1"
                  isRequired={true}
                  maxLength="30"
                />
                <FormikInput
                  name="addressLine2"
                  placeholder="Example Haveli"
                  label="Address Line 2"
                  isRequired={true}
                  maxLength="30"
                />
                <FormikDropdown
                  name="country"
                  placeholder="Select"
                  value={values.country}
                  isDisabled={!Countries}
                  label={defaultLanguage?.select_country}
                  onChange={(e) => {
                    setFieldValue("country", e.target.value);
                    setSelectedCountry(e.target.value);
                    setFieldValue("state", "");
                    setFieldValue("city", "");
                    setFieldValue("pincode", "");
                  }}
                >
                  {Countries
                    ? Countries.map((country) => (
                        <option
                          value={country.CountryId}
                          key={country.CountryName}
                        >
                          {country.CountryName}
                        </option>
                      ))
                    : null}
                </FormikDropdown>

                <FormikDropdown
                  name="state"
                  placeholder="Select"
                  value={values.state}
                  isDisabled={!stateList}
                  label={defaultLanguage?.select_state}
                  onChange={(e) => {
                    setSelectedState(e.target.value);
                    setFieldValue("state", e.target.value);
                    setFieldValue("city", "");
                    setFieldValue("pincode", "");
                  }}
                >
                  {stateList
                    ? stateList.map((state) => (
                        <option value={state.id} key={state.id}>
                          {state.value}
                        </option>
                      ))
                    : null}
                </FormikDropdown>

                <FormikDropdown
                  name="city"
                  placeholder="Select"
                  value={values.city}
                  isDisabled={!cityList}
                  label={defaultLanguage?.select_city}
                  onChange={(e) => {
                    setFieldValue("city", e.target.value);
                    setFieldValue("pincode", "");
                  }}
                >
                  {cityList
                    ? cityList.map((city) => (
                        <option value={city.id} key={city.id}>
                          {city.value}
                        </option>
                      ))
                    : null}
                </FormikDropdown>
                <FormikInput
                  label="Pincode"
                  placeholder="Example: 411033"
                  isRequired={true}
                  name="pincode"
                  maxLength={6}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxHeight: "78px",
        }}
      >
        <Button
          variant="gradient"
          w="347px"
          form="address-form"
          formTarget="address-form"
          type="submit"
        >
          {defaultLanguage?.Next}
        </Button>
      </div>
    </NewSideMenuLayout>
  );
};
