import React, { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { MyBusinessDetailCard } from "./MyBusinessDetailCard";
import Avatar from "../../../assets/webapp/doc_flow/My-profile.png";


import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { NewSideMenuLayout } from "../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { openQrModal } from "../../features/dashboard/slice/dashboardSlice";
import { QrPopUp } from "../ScanQrCodeModal/QrPopUp";
import { getStoreDetails } from "../../services/store/storeServices";
import { openAddManagerStorePopUp, openAddStorePopUp, removeStoreFlag, setGetStoreDetails, setStoreId } from "../../features/myStore/slice/myStoreSlice";
import { AddStorePopUp } from "../../features/myStore/screens/AddStorePopUp";
import { AddStoreManager } from "../../features/myStore/screens/AddStoreManager";
import { convertToBase64 } from "../../../utils/formatConverter/convertToBase64";
import { getDefaultAppLanguage } from "../../features/global/mtData/helpers/localStorage";
import { getMtMyBusinessTypeList } from "../../services/myBussiness/myBussiness";
import { V5SidebarLayout } from "../../layout/V5SidebarLayout/V5SidebarLayout";


export const MyBusinessAsset = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token, UserTypeValue } = useSelector((state) => state.user);
  const { storeData, storeFlag } = useSelector((state) => state.myStore);

  const [businessCategory, setBusinessCategory] = useState();
  const [s3BaseReference, setS3BaseReference] = useState();
  const [flag, setflag] = useState(false);
  const defaultLanguage = getDefaultAppLanguage();

  // const flag=false;
  const flagg = true;

  useEffect(() => {
    const fetchData = async () => {
      storeDetails();
      try {
        const data = await getMtMyBusinessTypeList()
        if (data?.Status === 200 && Object.keys(data?.Data).length > 0) {
          setBusinessCategory(data?.Data?.MyBusinessType)
          setS3BaseReference(data?.Data?.S3BaseReference)
        }
      } catch (error) {

      }
    }

    fetchData();
  }, [])

  useEffect(() => {
    if (storeFlag === true) {
      (async () => {
        await storeDetails();
      })()
      dispatch(removeStoreFlag());
    }
  }, [storeFlag]);

  const storeDetails = async () => {
    try {
      const response = await getStoreDetails(token);
      if (response) {
        dispatch(setGetStoreDetails(response));
        dispatch(setStoreId(response[response.length - 1]?.StoreId));
      }
    } catch (error) {
      
    }
  }

  const handleNavigation = (MyBusinessTypeValue, ServiceTypeId) => {
    const id = convertToBase64(ServiceTypeId);
    if (MyBusinessTypeValue.toLowerCase() === "Equipment Store".toLowerCase()) {

      navigate("/equipment-store")
    } if (MyBusinessTypeValue.toLowerCase() === "Dealerships".toLowerCase()) {
      navigate(`/my-business-dealerships-listing/${id}`)
    } if (MyBusinessTypeValue.toLowerCase() === "Finances".toLowerCase()) {
      // navigate("/my-business-finance-listing")
      dispatch(openQrModal());
    }if(MyBusinessTypeValue.toLowerCase() === "Materials".toLowerCase()){
      navigate("/materials-type-store-list",{
        state:{Flag:flagg}
      })
    } else {
      return;
    }
  };


  const handleStore = () => {
    dispatch(openAddStorePopUp());
  }
  const handleAddManager = () => {
    dispatch(openAddManagerStorePopUp());

  }


  return (

    <V5SidebarLayout>
      <AddStorePopUp media={storeData[storeData.length - 1]?.StoreLogo}/>
      <AddStoreManager />
      <QrPopUp />
      <Flex flexDirection={"column"} margin={"0px 52px"} gap={"1em"}>
        <div
          style={{
            backgroundColor: "#92929204",
            paddingTop: "1em",
          }}>
          <div >
            <h1 className="my-business-store-headline">
              {defaultLanguage?.my_business}
            </h1>
            <p className="my-business-store-subheading">{defaultLanguage?.manage_your_business_with_equip9}</p>
          </div>
        </div>

        <div className="my-business-custom-store-container">

          <div className="custom-store-element">
            <div className="custom-store-logo">
              <img src={storeData[storeData.length - 1]?.StoreLogo ? `${process.env.REACT_APP_CDN_PVT_URL}${storeData[storeData.length - 1]?.StoreLogo}` : Avatar} style={{ height: "100%", width: "100%" }} />
            </div>
            <div className="custom-store-details">
              <h1 className="custom-store-heading">{storeData[storeData.length - 1]?.StoreName ? storeData[storeData.length - 1]?.StoreName : "Company's Store"}</h1>
            </div>
            <div className="custom-store-button">
              <button>
                <button onClick={handleStore}> <img src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/edit.svg`} /> </button>
              </button>
            </div>
          </div>

          {
            UserTypeValue.toLowerCase() === "Company".toLowerCase() && storeData.length ? (
              <div className="custom-store-element">
                <div className="custom-store-logo">
                  <img src={storeData[storeData.length - 1]?.SmProfilePic ? `${process.env.REACT_APP_CDN_STATIC_URL}${storeData[storeData.length - 1]?.SmProfilePic}` : Avatar} />
                </div>
                <div className="custom-store-details">
                  <h1 className="custom-store-heading">{storeData[storeData.length - 1]?.SmName ? storeData[storeData.length - 1]?.SmName : "Add Manager"}</h1>
                  <p className="custom-store-sub-heading">{storeData[storeData.length - 1]?.SmPhone}</p>
                </div>
                <div className="custom-store-button">
                  <button>
                    <button onClick={handleAddManager}> <img src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/edit.svg`} /> </button>
                  </button>
                </div>
              </div>
            ) : (null)
          }
        </div>

        <Flex
          gap="2em"
          flexDirection="row"
          overflowX="scroll"
          padding="1em 0"
        >
          {businessCategory ? (
            businessCategory.map((item) => (
              <>
                <MyBusinessDetailCard
                  key={uuid()}
                  imageUrl={`${process.env.REACT_APP_CDN_PVT_URL}MobileAppAssets/${item.MyBusinessTypeIconFileRef}`}
                  routePath={item?.route}
                  onSubmitButtonClick={() => handleNavigation(item?.MyBusinessTypeValue, item?.ServiceTypeId)}
                  description={item.MyBusinessTypeValue}
                />
              </>
            ))
          ) : (
            <p>No data available.</p>
          )}
        </Flex>

      </Flex>
    </V5SidebarLayout>
  );
};
