import { createSlice } from "@reduxjs/toolkit";

const userLeadsCheckSlice = createSlice({
  name: "userLeads",
  initialState: {
    data: null,
  },
  reducers: {
    setUserLeadsData: (state, { payload }) => {
      state.data = payload;
    },
  },
});

export const { setUserLeadsData } = userLeadsCheckSlice.actions;
export default userLeadsCheckSlice.reducer;
