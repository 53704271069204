import { STORAGE_CONSTANTS } from "../../../../../constants/storageConstants";
import { setLocalStorageItems, getLocalStorageItems } from "../../../../helpers/localStorage/localStorage";

export const getSelectedBusinessTypeFromLocalStore = () => {
    return getLocalStorageItems(STORAGE_CONSTANTS.SELECTED_BUSINESS_TYPE);
}

export const getSelectedUserTypeFromLocalStore = () => {
    return getLocalStorageItems(STORAGE_CONSTANTS.SELECTED_USER_TYPE);
}
export const getFirstNameFromLocalStore = () => {
    return getLocalStorageItems(STORAGE_CONSTANTS.FIRST_NAME);
}

export const getLastNameFromLocalStore = () => {
    return getLocalStorageItems(STORAGE_CONSTANTS.LAST_NAME);
}

export const getMobileNumberFromLocalStore = () => {
    return getLocalStorageItems(STORAGE_CONSTANTS.MOBILE_NUMBER);
}

export const getCompanyNameFromLocalStore = () => {
    return getLocalStorageItems(STORAGE_CONSTANTS.COMPANY_NAME);
}

export const getEnterpriseNameFromLocalStore = () => {
    return getLocalStorageItems(STORAGE_CONSTANTS.ENTERPRISE_NAME);
}

export const setSelectedBusinessTypeInLocalStore = (data) => {
  return setLocalStorageItems(STORAGE_CONSTANTS.SELECTED_BUSINESS_TYPE, data);
}

export const setSelectedUserTypeInLocalStore = (data) => {
    return setLocalStorageItems(STORAGE_CONSTANTS.SELECTED_USER_TYPE, data);
}

export const setFirstNameInLocalStore = (data) => {
    return setLocalStorageItems(STORAGE_CONSTANTS.FIRST_NAME, data);
}

export const setLastNameInLocalStore = (data) => {
    return setLocalStorageItems(STORAGE_CONSTANTS.LAST_NAME , data);
}

export const setCompanyNameInLocalStore = (data) => {
    return setLocalStorageItems(STORAGE_CONSTANTS.COMPANY_NAME, data);
}

export const setEnterpriseNameInLocalStore = (data) => {
    return setLocalStorageItems(STORAGE_CONSTANTS.ENTERPRISE_NAME, data);
}

export const setMobileNumberInLocalStorage = (data) => {
    return setLocalStorageItems(STORAGE_CONSTANTS.MOBILE_NUMBER, data)
}