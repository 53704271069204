import { Field, Form, Formik } from "formik";
import { AccordianElement } from "../../../../components/AccordianElement/AccordianElement";
import { FormikDropdown } from "../../../../components/Dropdown/FormikDropdown";
import { Accordion } from "@chakra-ui/accordion";
import { API_CONSTANTS } from "../../../../../constants/apiConstants";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { Flex } from "@chakra-ui/layout";
import { RadioParent } from "../../../../components/RadioParent/RadioParent";
import {
  setEquipmentMake,
  setEquipmentModel,
  setEquipmentType,
  setJobType,
  setMaintenanceType,
  setEquipmentAge,
  increaseStepperIndex,
  setEquipmentNickname,
  setMpServiceCategory,
} from "../../slice/maintenanceJobPostSlice";
import * as Yup from "yup";
import { getEquipmentAgeList, getEquipmentMakesById, getEquipmentModelById, getEquipmentNickNameList, getEquipmentTypes, getMaintenanceCategory, getMaintenanceTypeListById, getNicknameEquipmentList, getRepairType } from "../../../../services/equipments/equipments";
import { convertFromBase64 } from "../../../../../utils/formatConverter/convertToBase64";
import { getE9ServiceCategoryThunk } from "../../../e9Services/thunks/getE9ServiceCategoryDetailsThunk";
import { useParams } from "react-router-dom";

export const EquipmentDetailsForm = () => {
  const { token } = useSelector((state) => state.user);
  const [maintenanceCategoryList, setMaintenanceCategoryList] = useState(null);
  const [selectedMaintenanceCategory, setSelectedMaintenanceCategory] = useState(null);
  const [selectedMaintenanceType, setSelectedMaintenanceType] = useState(null);
  const [maintenanceTypeList, setMaintenanceTypeList] = useState(null);
  const [repairTypeList, setRepairTypeList] = useState("");
  const [equipmentNickNameList, setEquipmentNickNameList] = useState(null);
  const [equipmentTypeList, setEquipmentTypeList] = useState(null);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null);
  const [equipmentMakeList, setEquipmentMakeList] = useState(null);
  const [selectedEquipmentMake, setSelectedEquipmentMake] = useState(null);
  const [equipmentModelList, setEquipmentModelList] = useState(null);
  const [equipmentAgeList, setEquipmentAgeList] = useState(null);
  const [selectedNickEquipment,setSelectedNickEquipment] = useState(null);
  const [updatedEquipmentList,setUpdatedEquipmentList] = useState(null);
  const {
    jobType,
    maintenenaceType,
    repairType,
    equipmentType,
    equipmentMake,
    equipmentModel,
    equipmentAge,
    equipmentNickname
  } = useSelector((state) => state.maintenanceSearch);
  const dispatch = useDispatch();
  const {nickname,eqTypeId,eqMakeId,eqModelId} = useParams();
  const { categoryList } = useSelector(
    (state) => state.e9Services
  );
  const convertedTypeId = convertFromBase64(eqTypeId);
  const convertedMakeId = convertFromBase64(eqMakeId);
  const convertedModelId = convertFromBase64(eqModelId);


  const initialValues = {
    _maintenanceCategory: jobType ? jobType : "",
    _maintenanceType: maintenenaceType ? maintenenaceType : "",
    _repairType: repairType ? repairType : "",
    _equipmentNickname: equipmentNickname?equipmentNickname:"",
    _equipmentType: equipmentType ? equipmentType : "",
    _equipmentMake: equipmentMake ? equipmentMake : "",
    _equipmentModel: equipmentModel ? equipmentModel : "",
    _equipmentAge: equipmentAge ? equipmentAge : "",
  }

  const getAllMaintenanceCategory = async () => {
    try {
      
      const data= await getMaintenanceCategory()

      if (data.Status === 200 && data.Message.SuccessMessage) {
        const requiredList = data.Data.map((element) => ({
          id: element.MaintenanceCategoryId,
          value: element.MaintenanceCategoryValue,
        }));
        setMaintenanceCategoryList(requiredList);
      } else {
        setMaintenanceCategoryList(null);
      }
    } catch (error) {
    }
  };

  const getMaintenanceTypes = async (value) => {
    try {
  
      const data = await getMaintenanceTypeListById(value);

      if (data.Status === 200 && data.Message.SuccessMessage) {
        const requiredList = data.Data.map((element) => ({
          id: element.MaintenanceTypeId,
          value: element.MaintenanceTypeValue,
        }));
        setMaintenanceTypeList(requiredList);
      } else {
        setMaintenanceTypeList(null);
      }
    } catch (error) {
    }
  };
  const getRepairTypes = async () => {
    try {
     
      const data = await getRepairType();

      if (data.Status && data.Message.SuccessMessage) {
        const requiredList = data.Data.RepairType.map((element) => ({
          id: element.RepairTypeId,
          value: element.RepairTypeValue,
        }));
        setRepairTypeList(requiredList);
      } else {
        setRepairTypeList(null);
      }
    } catch (error) {
    }
  };


  const getAllEquipmentNickNameList = async () => {
    try {
      const data = await getEquipmentNickNameList();
      if(data?.Status === true && data?.Data?.NickNameList.length > 0){
        setEquipmentNickNameList(data?.Data?.NickNameList)
      }
    } catch (error) {
      return error;
    }
  };
  const getAllEquipmentType = async () => {
   
    try {
      const data= await getEquipmentTypes();

      if (data && Object.keys(data).length > 0) {
        setEquipmentTypeList(data);
      } else {
        setEquipmentTypeList(null);
      }
    } catch (error) {
    }
  };
  const getAllEquipmentAgeList = async () => {
   
    try {
     
      const data = await getEquipmentAgeList();

      if (data.Status === 200 && Object.keys(data.Data).length > 0) {
        const requiredList = data.Data.EquipmentAgeList.map((element) => ({
          id: element.EquipmentAgeId,
          value: element.EquipmentAgeValue,
        }));
        setEquipmentAgeList(requiredList);
      } else {
        return setEquipmentAgeList(null);
      }
    } catch (error) {
    }
  };

  const getEquipmentMake = async () => {
    const headers = new Headers();
    headers.append("token", token);
    try {
      
      const data = await getEquipmentMakesById(selectedEquipmentType);
      if (data && Object.keys(data).length > 0) {
        return setEquipmentMakeList(data);
      } else {
        return setEquipmentMakeList(null);
      }
    } catch (error) {
    }
  };

  const getEquipmentModel = async () => {
    const headers = new Headers();
    headers.append("token", token);
    try {
      const data= await getEquipmentModelById(selectedEquipmentMake);

      if (data && Object.keys(data).length > 0) {
        return setEquipmentModelList(data);
      } else {
        return setEquipmentModelList(null);
      }
    } catch (error) {
    }
  };

  const getSelectedNicknameEquipmentList = async () =>{
    dispatch(setEquipmentNickname(selectedNickEquipment?.NickName));
    try {
      const data = await getNicknameEquipmentList();

      if(data?.AssetDetails.length > 0){
        const equipment = await data?.AssetDetails.find((equipment)=> equipment?.AssetId === selectedNickEquipment?.AssetId);
        if(equipment){
          setUpdatedEquipmentList(equipment);
          updateEquipmentDetails(equipment);
        }
      }
    } catch (error) {
      
    }
  }

  const updateEquipmentDetails = (equipment) =>{
    const requiredEquipmentType = equipmentTypeList.find(
      ({ id }) => id === parseInt(equipment?.EquipmentTypeId)
    );

    dispatch(setEquipmentMake(parseInt(equipment?.EquipmentMakeId)));
    dispatch(setEquipmentType(requiredEquipmentType));
    dispatch(setEquipmentModel(parseInt(equipment?.EquipmentModelId)));
    setSelectedEquipmentType(requiredEquipmentType?.id);
    setSelectedEquipmentMake(equipment?.EquipmentMakeId);

  }

  const setNicknameByUrl = () =>{

    let requiredEquipmentType = ""
    const equipmentTypeId = convertFromBase64(eqTypeId) ;
    
    if(equipmentTypeList){
       requiredEquipmentType = equipmentTypeList.find(
        ({ id }) => id === parseInt(equipmentTypeId)
      );
    }

    dispatch(setEquipmentNickname(convertFromBase64(nickname)));
    dispatch(setEquipmentMake(parseInt(convertedMakeId)));
    dispatch(setEquipmentType(requiredEquipmentType));
    dispatch(setEquipmentModel(parseInt(convertedModelId)));
    setSelectedEquipmentType(requiredEquipmentType?.id);
    setSelectedEquipmentMake(parseInt(convertedMakeId));
  }

  const getServiceTypeDetails = () =>{
    dispatch(getE9ServiceCategoryThunk());
    if(nickname){
      dispatch(setMpServiceCategory(categoryList?.[0]?.data?.[1]));
    }

  }

  useEffect(() => {
    (async () => {
      await getAllMaintenanceCategory();
      await getAllEquipmentNickNameList();
      await getAllEquipmentAgeList();
      await getAllEquipmentType();
    })();
  }, []);
  useEffect(() => {
    if (selectedMaintenanceCategory) {
      (async () => {
        await getMaintenanceTypes(selectedMaintenanceCategory);
      })();
    } else {
      setMaintenanceTypeList(null);
    }
  }, [selectedMaintenanceCategory]);

  useEffect(() => {
    if (
      selectedMaintenanceType &&
      selectedMaintenanceType.value.toLowerCase().split(" ")[0] === "Repair".toLowerCase()
    ) {
      (async () => {
       
        await getRepairTypes();
      })();
    } else {
      setRepairTypeList(null);
    }
  }, [selectedMaintenanceType]);

  useEffect(() => {
    if (selectedEquipmentType) {
      (async () => {
        await getEquipmentMake();
      })();
    }
  }, [selectedEquipmentType]);

  useEffect(() => {
    if (selectedEquipmentMake) {
      (async () => {
        await getEquipmentModel();
      })();
    }
  }, [selectedEquipmentMake]);

  useEffect(()=>{
    if(nickname){
      setNicknameByUrl();
      getServiceTypeDetails();
    }
  },[nickname,equipmentTypeList])

  useEffect(()=>{
    if(selectedNickEquipment){
      getSelectedNicknameEquipmentList();
    }
  },[selectedNickEquipment])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        _maintenanceCategory: Yup.object().required(
          "Maintenance category is required."
        ),
        _maintenanceType: Yup.string().required(
          "Maintenance Type is required."
        ),
        _equipmentType: Yup.object().required("Equipment Type is required."),
        _equipmentMake: Yup.string().required("Equipment Make is required."),
        _equipmentModel: Yup.string().required("Equipment Model is required."),
        _equipmentAge: Yup.string().required("Equipment Age is required."),
      })}
      onSubmit={(values) => {
        dispatch(setJobType(values._maintenanceCategory));
        dispatch(setMaintenanceType(values._maintenanceType));
        dispatch(setEquipmentType(values._equipmentType));
        dispatch(setEquipmentMake(values._equipmentMake));
        dispatch(setEquipmentModel(values._equipmentModel));
        dispatch(setEquipmentAge(values._equipmentAge));
        dispatch(increaseStepperIndex())
      }}
      enableReinitialize = {true}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <Form onSubmit={handleSubmit} id="equipment-details-form">
          <Accordion allowMultiple>
            <Flex flexDirection="column" gap="1em">
              <AccordianElement
                paddingBottom="1em"
                title="Maintenance Type Details"
              >
                <Flex
                  wrap="wrap"
                  justifyContent="space-between"
                  rowGap="2em"
                  alignItems="flex-end"
                >
                  <FormikDropdown
                    name="_maintenanceCategory"
                    label="Maintenance Type"
                    isRequired={true}
                    isDisabled={!maintenanceCategoryList}
                    placeholder="Select"
                    maxWidth="450px"
                    value={values._maintenanceCategory ? values._maintenanceCategory.id : ""}
                    onChange={(e) => {
                      let selectedValue = ""
                      if(e.target.value){
                        selectedValue = maintenanceCategoryList.find((element) => (parseInt(element.id) === parseInt(e.target.value)))
                      }
                      setFieldValue("_maintenanceCategory", selectedValue);
                      setFieldValue("_maintenanceType", "");
                      setSelectedMaintenanceCategory(e.target.value);
                    }}
                  >
                    {maintenanceCategoryList &&
                    maintenanceCategoryList.length > 0 ? (
                      maintenanceCategoryList.map((category) => (
                        <option key={uuid()} value={category.id}>
                          {category.value}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                  </FormikDropdown>

                  <Field name="_maintenanceType">
                    {({ field }) => {
                      return (
                        <RadioParent
                        label="Service Type"
                          name={field.name}
                          options={maintenanceTypeList}
                          maxWidth="450px"
                          isFormik={true}
                          onChange={(value) => {
                            if (value) {
                              const selectedValue = maintenanceTypeList.find(
                                (element) =>
                                  parseInt(element.id) === parseInt(value)
                              );
                              if (selectedValue.value !== "Repair Service") {
                                setRepairTypeList(null);
                                setFieldValue("_repairType", "");
                              }
                              
                              setSelectedMaintenanceType(selectedValue);
                            }
                            
                          }}
                        />
                      );
                    }}
                  </Field>
                  {repairTypeList && (
                    <FormikDropdown
                      name="_repairType"
                      label="Repair Type"
                      isRequired={true}
                      isDisabled={!repairTypeList}
                      placeholder="Select"
                      maxWidth="450px"
                      value={values._repairType}
                      onChange={(e) => {
                        setFieldValue("_repairType", e.target.value);
                      }}
                    >
                      {repairTypeList && repairTypeList.length > 0 ? (
                        repairTypeList.map((type) => (
                          <option key={uuid()} value={type.id}>
                            {type.value}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </FormikDropdown>
                  )}
                </Flex>
              </AccordianElement>

              <AccordianElement paddingBottom="1em" title="Equipment Details">
                <Flex wrap="wrap" justifyContent="space-between" rowGap="2em">
                  <FormikDropdown
                    name="_equipmentNickname"
                    label="Equipment Nickname"
                    value={values._equipmentNickname}
                    maxWidth="450px"
                    isRequired={false}
                    isDisabled={!equipmentNickNameList}
                    onChange={(e) => {
                      const selectedNickname = e.target.value;
                      setFieldValue("_equipmentNickname", selectedNickname);
        
                      const selectedEquipment = equipmentNickNameList.find(
                        (item) => item.NickName === selectedNickname
                      );
        
                      if (selectedEquipment) {
                        setSelectedNickEquipment(selectedEquipment);
                        getSelectedNicknameEquipmentList(values);
                      }
                    }}
                  >
                    {equipmentNickNameList && equipmentNickNameList.length > 0
                      ? equipmentNickNameList.map(({ AssetId, NickName }) => (
                          <option key={uuid()} value={NickName}>
                            {NickName}
                          </option>
                        ))
                      : []}
                      </FormikDropdown>
                  <FormikDropdown
                    name="_equipmentType"
                    label="Equipment Type"
                    value={values._equipmentType ? values._equipmentType.id : ""}
                    maxWidth="450px"
                    isRequired
                    isDisabled={!equipmentTypeList}
                    onChange={(e) => {
                      let selectedValue = ""
                      if(e.target.value){
                        selectedValue = equipmentTypeList.find((equipment) => (parseInt(equipment.id) === parseInt(e.target.value)))
                      }
                      setFieldValue("_equipmentType",selectedValue );
                      setFieldValue("_equipmentMake", "");
                      setFieldValue("_equipmentModel", null);
                      setSelectedEquipmentType(e.target.value);
                      setSelectedEquipmentMake(null);
                    }}
                  >
                    {equipmentTypeList && equipmentTypeList.length > 0
                      ? equipmentTypeList.map((equipment) => (
                          <option
                            key={uuid()}
                            value={equipment.id}
                          >
                            {equipment.value}
                          </option>
                        ))
                      : []}
                  </FormikDropdown>
                  <FormikDropdown
                    name="_equipmentMake"
                    label="Equipment Make"
                    maxWidth="450px"
                    value={values._equipmentMake}
                    isRequired
                    isDisabled={!equipmentMakeList}
                    onChange={(e) => {
                      setFieldValue("_equipmentMake", e.target.value);
                      setFieldValue("_equipmentModel", "");
                      setSelectedEquipmentMake(e.target.value);
                    }}
                  >
                    {equipmentMakeList && equipmentMakeList.length > 0
                      ? equipmentMakeList.map((make) => (
                          <option key={uuid()} value={make.id}>
                            {make.value}
                          </option>
                        ))
                      : []}
                  </FormikDropdown>
                  <FormikDropdown
                    name="_equipmentModel"
                    label="Equipment Model"
                    maxWidth="450px"
                    isRequired
                    isDisabled={!equipmentModelList}
                    value={values._equipmentModel}
                    onChange={(e) => {
                      setFieldValue("_equipmentModel", e.target.value);
                    }}
                  >
                    {equipmentModelList && equipmentModelList.length > 0
                      ? equipmentModelList.map((model) => (
                          <option key={uuid()} value={model.id}>
                            {model.value}
                          </option>
                        ))
                      : []}
                  </FormikDropdown>

                  <Field name="_equipmentAge">
                    {({ field }) => {
                      return (
                        <RadioParent
                          name={field.name}
                          label="Equipment Age"
                          options={equipmentAgeList}
                          maxWidth="450px"
                          isFormik={true}
                          isRequired={true}
                        />
                      );
                    }}
                  </Field>
                </Flex>
              </AccordianElement>
            </Flex>
          </Accordion>
        </Form>
      )}
    </Formik>
  );
};
