export const convertPrice = (price) => {
 if(!price || typeof(price) !== "number"){
    return 0
 }
 if(price <= 999){
    return price
 }
 if(price > 999 && price <= 99999){
    return `${(price / 1000).toFixed(2)} K` 
 }
 if(price > 99999  && price <= 9999999){
    return `${(price / 100000).toFixed(2)} Lakhs` 
 }
if(price > 9999999){
    return `${(price / 10000000).toFixed(2)} Crores`
}
}