import { createSlice } from "@reduxjs/toolkit";
import { leadsInitialState } from "../constants/leadsInitialState";
import { getSentRequirementCards } from "../thunk/industryLeads/getSentRequirementCards";
import { myLeadsSlice } from "../../myLeads/Slice/myLeadsSlice";
import { getReceivedRequirementCardThunk } from "../thunk/industryLeads/getReceivedRequirementCardThunk";
import { getRequirementRequestListThunk } from "../thunk/industryLeads/getRequirementRequestListThunk";


export const myLeadsIndustryRequirementSlice = createSlice({
    name:"myLeadsIndustry",
    initialState : leadsInitialState,
    reducers: {
        setMarketplaceModal : (state,{payload}) =>{
            state.addToMarketplace = payload ;
        }
    },

    extraReducers : ((builder)=>{
        builder.addCase(getSentRequirementCards.pending,(state)=>{
            state.sentCards = null;
            state.initialLoading = true ;
        });
        builder.addCase(getSentRequirementCards.fulfilled,(state,{payload}) =>{
            state.initialLoading = false ;
            state.sentCards = payload ;
        })
        builder.addCase(getReceivedRequirementCardThunk.pending,(state)=>{
            state.receivedCards = null;
            state.initialLoading = true ;
        });
        builder.addCase(getReceivedRequirementCardThunk.fulfilled,(state,{payload}) =>{
            state.initialLoading = false ;
            state.receivedCards = payload ;
        })
        builder.addCase(getRequirementRequestListThunk.pending,(state)=>{
            state.requestReceivedList = null;
            state.initialLoading = true ;
        });
        builder.addCase(getRequirementRequestListThunk.fulfilled,(state,{payload}) =>{
            state.initialLoading = false ;
            state.requestReceivedList = payload ;
        })
    })
})

export const {
    setMarketplaceModal
} = myLeadsIndustryRequirementSlice.actions