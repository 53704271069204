import React, { useEffect, useState } from 'react'
import { BlogBanner } from '../BlogBannner'

import "./BlogContent.css"
import { v4 as uuid } from 'uuid';
import axios from 'axios';

const BlogFirst = () => {

    const [listData, setListData] = useState(false)
    const [expandedSections, setExpandedSections] = useState([]);
    const [blogData,setBlogData] = useState();

    useEffect(()=>{
        const fetchData = async ()=>{
            const response = await axios.get("https://dev-static.equip9.com/Website/Blog/BlogJson/blogData.json")
            if(response?.status === 200){
                setBlogData(response?.data?.blogData)
                // console.log("S3 response" , response?.data?.blogData[0]);
            }
        }
        fetchData();
    },[])

    useEffect(() => {
        if(blogData){
            setListData(blogData[0]?.nestedList)
            const initialExpandedState = new Array(blogData.length).fill(false);
            setExpandedSections(initialExpandedState);
        }

    }, [blogData])


    const handleContainerClick = (id) => {

        setExpandedSections((prevExpanded) => {
            const index = blogData.findIndex((entry) => entry.id === id);
            const newExpanded = [...prevExpanded];
            newExpanded[index] = !newExpanded[index];
            return newExpanded;
        });
    };
    // console.log("S3 blog Data" , blogData);
    // console.log("List Data ----->" , listData);

    return (
        <>

            { blogData ? blogData.map((section, index) => (
                <div onClick={() => handleContainerClick(section.id)} key={section.id}>
                    <BlogBanner
                        key={section.id}
                        headingText={section.title}
                        highlightedText={section.subTitle}
                        description={section.content}
                        bannerImage={`${process.env.REACT_APP_CDN_STATIC_URL}Website/Blog/bolgOne.png`}
                        isExpanded={expandedSections[index]}
                    />

                    <div className={`blog-container-wrapper ${expandedSections[index] ? 'expanded' : ''}`}>
                        {listData && listData.map((list) => (
                            <div className="blog-section" key={uuid()}>
                                <h2 className='blog-header'>{list?.listTitle}</h2>
                                <ol className='blog-list-container' >
                                    {list.listPoints.map((listPoint) => (
                                        <li className='blog-list' key={uuid()}>
                                            <strong>{listPoint.listHeader}</strong> 
                                            <p className='blog-list-content'>
                                                {listPoint.listContent}
                                            </p>
                                        </li>
                                    ))}
                                </ol>
                            </div>
                        ))}

                        {blogData && blogData.map((data) => (
                            <div className="blog-section" key={uuid()}>
                                <h2 className='blog-header' style={{ color: "#F79C00" }}>{data?.secondTitle}</h2>
                                <p>
                                    {data?.secondContent}
                                </p>
                            </div>
                        ))}


                        {blogData && blogData.map((data) => (
                            <div className="blog-section" key={uuid()}>
                                <h2 className='blog-header' style={{ color: "#F79C00" }}>{data?.thirdTitle}</h2>
                                <p>
                                    {data?.thirdContent}
                                </p>
                            </div>
                        ))}

                        {blogData && blogData.map((data) => (
                            <div className="blog-section" style={{display:"flex" , gap:"1em", justifyContent:"space-between"}}>
                                <div><h1 className='blog-list'>Author Name :</h1><p> {data.authorName}</p></div>
                               <div> <h1 className='blog-list'>Location :</h1><p>{data.location}</p></div>
                            </div>
                        ))}
                    </div>
                </div>

            )) : (
                null
            )}
        </>
    )
}

export default BlogFirst