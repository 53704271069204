import { createAsyncThunk } from "@reduxjs/toolkit";
import {getRentalEquipmentStoreAnalytics } from "../../../services/equipmentStore/equipmentStore";

export const getRentalEquipmentAnalyticsThunk = createAsyncThunk("/equipment-store/get-analytics", async(_, {rejectWithValue}) => {
    try{
        const data = await getRentalEquipmentStoreAnalytics()
       return data
    }
    catch(error){
        rejectWithValue(error)
    }
})