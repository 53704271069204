import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllTopOperatorPartnersList } from "../../../services/jobsPosted/jobsPosted";


export const getLoadMoreDataThunk = createAsyncThunk("nearMeOp/loadMoreData", async(_, {getState}) => {

    const cardCount = getState().operatorPartnerNearMe.cardCount;
    const pageNumber =  getState().operatorPartnerNearMe.paginationCount;
    try{
       
        const data = await getAllTopOperatorPartnersList(cardCount,(pageNumber * cardCount));

       if(data.Status === 200){
        if(data.Data.TotalRecords > 0){

            return data.Data.NearMeDetails;
        }
        else{
            return []
        }
       }
    }
    catch(error){
    }
})