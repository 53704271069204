import React from "react";
import { SideMenuLayout } from "../../../../../../../layout/sideMenuLayout/SideMenuLayout";
import { DashboardNavBar } from "../../../../../../../components/DashboardNavBar/DashboardNavBar";
import { MidSection } from "../../../../../../../../sharedComponent/midSection/MidSection";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { companyDetails } from "../../../../../../../../staticData/staticData";
import "./CompanyProfile.css";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";

export const BoCompanyProfile = () => {
  const userData = useSelector((state) => state.user);
  const verification =
    userData?.accountDetails?.UserDetails?.E9VerificationMessage;
  return (
    <>
      <SideMenuLayout>
        <DashboardNavBar />
        <MidSection>
          {verification === "Not Verified" ? (
            <div
              className="Not-Verfied-tag"
              // style={{ backgroundColor: verification === 'Not Verified' ? 'green' : 'green' }}
            >
              <p className="Verifi-tex">
                Your profile is not verified.Please complete and update your
                profile for verifications
              </p>
            </div>
          ) : (
            <div className="Verfied-tag">
              <p className="Verifi-text">Your profile is verified.</p>
            </div>
          )}

          <div className="hero-heading">
            <p className="hero-company-heading">Your Company Details</p>
            <div>
              <p className="hero-company-subheading">Summary of your Company</p>
            </div>
          </div>
          <div>
            {companyDetails.map((item) => (
              <div>
                <Link to={item.route}>
                  <div className="item-block">
                    <p className="hero-item-heading">{item.name}</p>
                    <div className="icon-hero">
                      <ReactSVG
                        src={
                          "https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/forward.svg"
                        }
                      />
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </MidSection>
      </SideMenuLayout>
    </>
  );
};
