import React, { useEffect , useState } from 'react'
import { NewSideMenuLayout } from '../../layout/NewSideMenuLayout/NewSideMenuLayout'
import { Box, Flex, useToast } from '@chakra-ui/react'
import TabAcceptRejectCard from './TabAcceptRejectCard'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { convertFromBase64 } from '../../../utils/formatConverter/convertToBase64'
import { useDispatch, useSelector } from 'react-redux'
import { getContactRequestListThunk } from '../../features/myLeads/thunk/getContactRequestListThunk'
import { openAcceptModal, setE9MarketPlaceId, setMaterialData, setRequestTypeData, updateAcceptRejectMaterial } from '../../features/myLeads/Slice/myLeadsSlice'
import { getEquipmentAvailabilityThunk, getRentalDurationThunk } from '../../features/myLeads/thunk/getRentalDurationThunk'
import { AcceptRejectModal } from './PopUp/AcceptRejectModal'
import { getTransactionModeDetailsThunk } from '../../features/myLeads/thunk/getTransactionModeDetailsThunk'
import { updateContactStatusThunk } from '../../features/myLeads/thunk/updateContactStatusThunk'
import { getMyLeadsMaterialConnectListThunk } from '../../features/myLeads/thunk/getMaterialSentDataThunk'
import { UpdateAcceptRejectContactDetails, getResponseTypeDetails } from '../../services/materials/materials'
import { MarketplaceHoc } from '../../features/marketplace/hoc/MarketplaceHoc/MarketplaceHoc'
import { getCheckUserLead } from '../../features/userLeadsCheck/helper/userLeadsCheck'
import { setUserLeadsData } from '../../features/userLeadsCheck/slices/userLeadsCheckSlice'
import { updateE9MarketplaceContactRedeemedDetails } from '../../services/myLeads/myLeads'
import { postE9PremiumRedeem } from '../../services/premium/premium'


const AcceptReject = () => {

    const { transactionModeDetails } = useSelector((state) => state.myLeads);
    const { data:userLeads} = useSelector((state) => state.userLeads);



    const dispatch = useDispatch();
    const toast = useToast();
    const navigate = useNavigate();
    const { marketplaceId } = useParams();
    const location = useLocation();
    const { materialAllData } = location.state || {};

    const { getContactRequestReceivedList,equipmentData,contactMessage,isMaterialData,materialData,materialReceivedCardData,initialLoading } = useSelector(state => state.myLeads);
    const data = JSON.parse(convertFromBase64(marketplaceId))

    const [responseTypeDetails,setResponseTypeDetails] = useState([]);

    useEffect(() => {
        if(typeof data !== 'object') {
        dispatch(setE9MarketPlaceId(convertFromBase64(marketplaceId)));
        dispatch(getRentalDurationThunk());
        dispatch(getContactRequestListThunk());
        dispatch(getTransactionModeDetailsThunk());
        dispatch(getEquipmentAvailabilityThunk());
        }
    }, [])

    useEffect(() => {
        if(typeof data === 'object') {
            const materialData = materialReceivedCardData?.find(m => m.MaterialId === data?.materialId) || {}
            dispatch(setMaterialData({...materialData,materialTypeValue:data?.materialTypeValue}));
            dispatch(getMyLeadsMaterialConnectListThunk(data))
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getResponseTypeDetails();
                setResponseTypeDetails(data?.ResponseTypes);
            } catch (error) {
                console.error("Error fetching response type details:", error);
            }
        };
        fetchData();
    }, []);
    
    const handleAcceptRequestClick = async (data) => {
        dispatch(setRequestTypeData(data));
        dispatch(openAcceptModal());
    }

    const handleMaterialRequestClick=async (material,responseType,type)=>{
        const {ResponseTypeId, ResponseTypeValue} = responseType;
  
        const payload={
            MaterialTypeId:material?.MaterialTypeId,
            MaterialId:material?.MaterialId,
            SenderUserCredentialId:material?.SenderUserCredentialId,
            ResponseTypeId
        }
     
        const response= await UpdateAcceptRejectContactDetails(payload);
        if(response?.Status===204){
           
            toast({
                title: "",
                description: `${response?.Message?.SuccessMessage}`,
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "top-right",
              });
            
            dispatch(getMyLeadsMaterialConnectListThunk(data));

            //userLeads
            const userLeadsData=await getCheckUserLead(materialAllData?.ServiceTypeId,"material",materialAllData?.SenderUserCredentialId);
            dispatch(setUserLeadsData(userLeadsData));

            if(type==="reject"){
                updateRejectStatus(material, responseType);
            }else{
                updateRedeemStatus(material, responseType);
            }
        }else{
            toast({
                title: "",
                description: `${response?.Message?.SuccessMessage}`,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
              });
       
        }
    }

    const updateRejectStatus = async (item, ResponseType) => {
      var payload = {
        E9MarketplaceContactRedeemedDetails: {
          E9MarketPlaceContactId: item?.E9MaterialMarketplaceContactId,
          ResponseTypeValue: ResponseType?.ResponseTypeValue,
          TransactionModeId: item?.TransactionModeId,
          SenderUserCredentialId: item?.SenderUserCredentialId,
        },
      };
      await updateE9MarketplaceContactRedeemedDetails(payload);
    };

    const updateRedeemStatus = async (item, ResponseType) => {
      var TransactionModeId = transactionModeDetails.find(
        (tm) => tm?.TransactionModeId == item?.TransactionModeId
      );

      if (
        TransactionModeId?.TransactionModeValue?.toLowerCase()?.includes("free")
      ) {
        const free_payload = {
          E9MarketplaceContactRedeemedDetails: {
            E9MarketPlaceContactId: item?.E9MaterialMarketplaceContactId,
            ResponseTypeValue: ResponseType?.ResponseTypeValue,
            TransactionModeId: item?.TransactionModeId,
            ServiceTypeId: item?.ServiceTypeId,
            FreeLeadConsumed: 1,
            SenderUserCredentialId: item?.SenderUserCredentialId,
          },
        };

        await updateE9MarketplaceContactRedeemedDetails(free_payload);
      } else if (
        TransactionModeId?.TransactionModeValue?.toLowerCase()?.includes(
          "premium"
        )
      ) {
        const premium_payload = {
          UserCredentialId: item?.SenderUserCredentialId,
          E9PremiumTransactionDetailsId:
            userLeads?.premium?.LeadsData?.E9PremiumTransactionDetailsId,
          PaymentPlanId: userLeads?.premium?.LeadsData.PaymentPlanId,
          ServiceTypeId: userLeads?.premium?.LeadsData.ServiceTypeId,
          UniqueRefId: item?.MaterialUniqueId,
          RedeemCount: 1,
        };

        await postE9PremiumRedeem(premium_payload);
      } else {
        let subLeadsData = userLeads?.subscription?.LeadsData;
        var selected_subType = subLeadsData.filter((item) => {
          return item.LeadRemaining > 0;
        })[0];

        let redeemedNumber = selected_subType.LeadRedeemed + 1;

        const sub_payload = {
          E9MarketplaceContactRedeemedDetails: {
            E9MarketPlaceContactId: item?.E9MaterialMarketplaceContactId,
            ResponseTypeValue: ResponseType?.ResponseTypeValue,
            TransactionModeId: item?.TransactionModeId,
            ServiceTypeId: item?.ServiceTypeId,
            SenderUserCredentialId: item?.SenderUserCredentialId,
            E9TransactionId: selected_subType?.E9TransactionId,
            LeadPurchased: selected_subType?.PurchasedCount,
            LeadRedeemed: 1,
            LeadRemaining: selected_subType?.PurchasedCount - redeemedNumber,
          },
        };
        await updateE9MarketplaceContactRedeemedDetails(sub_payload);
      }
    };

    const handleRejectRequest = async (data) =>{

        dispatch(setRequestTypeData(data));
        const payload = {
            E9MarketPlaceContactResponseStatus: {
                E9MarketPlaceContactId: data?.E9MarketPlaceContactId,
                ResponseTypeId: responseTypeDetails[2]?.ResponseTypeId
            }
        }
        try {
            await dispatch(updateContactStatusThunk(payload));

            if(contactMessage){
                toast({
                    title:"Request Rejected",
                    position:"top-right",
                    status:"success"
                })
            dispatch(getContactRequestListThunk());
            }else{
                toast({
                    title:"Request Rejected",
                    position:"top-right",
                    status:"success"
                })
            dispatch(getContactRequestListThunk());
    
            }
        } catch (error) {
            
        }
    }

    const renderTabAcceptRejectCard = (array) => {
        const renderJSX =
            array && array.length > 0 ? (
                array.map((data) => (
                    <>
                        <TabAcceptRejectCard
                        message = {data?.Message}
                        RequestSentOn={data?.RequestSentOn}
                        assetId={data?.assetId}
                        equipmentTypeValue = {data?.EquipmentTypeValue}
                        oemName = {data?.OEMName}
                        modelName = {data?.ModelName}
                        isVerified = ""
                        equipmentImage = {data?.equipmentImage}
                        city = ""
                        state = ""
                        requestType={data?.ResponseTypeValue}
                        receiverContactNumber = {data?.SenderContactNumber}
                        isMaterialLead={data?.isMaterialLead}
                        onAcceptRequestClick={()=>{
                            const responseType=responseTypeDetails.find(obj => obj.ResponseTypeValue === "Request Accepted");
                            data?.isMaterialLead ? handleMaterialRequestClick(data,responseType,"accept") :handleAcceptRequestClick(data)
                        }
                        }
                        onRejectRequestClick={() =>{
                            const responseType=responseTypeDetails.find(obj => obj.ResponseTypeValue === "Request Rejected");
                            data?.isMaterialLead ? handleMaterialRequestClick(data,responseType,"reject"):handleRejectRequest(data)
                        }}
                        />
                    </>
                ))
            ) : (
                <p>No Records Found</p>
            );

        return renderJSX;
    }

   

    return (
        <NewSideMenuLayout>
            <AcceptRejectModal />
            <Box height={"500px"} margin={"0 10px"}>
            <Flex backgroundColor={"#92929204"} paddingTop={"1em"} flexDirection={"column"} gap={"2em"} overflow={"scroll"}>
                {isMaterialData === true ? (
                     <Flex direction="column" padding={"0 2em"}>
                     <div >
                         <h1 className="my-business-store-headline">{materialData?.materialTypeValue}</h1>
                     </div>
                     <Flex justifyContent="space-between">
                         <p className="my-business-store-subheading">{materialData?.EquipmentTypeValue ? materialData?.EquipmentTypeValue : "NA"} • {materialData?.OEMName ?materialData?.OEMName :"NA"} • {materialData?.ModelName ?materialData?.ModelName : "NA"}</p>
                         <p className="my-business-store-subheading">{materialData?.MaterialUniqueId ? materialData?.MaterialUniqueId : "NA"}</p>
                     </Flex>
                 </Flex>
                ) : 
                (
                    <Flex direction="column" padding={"0 2em"}>
                    <div >
                        <h1 className="my-business-store-headline">Equipment Nickname</h1>
                    </div>
                    <Flex justifyContent="space-between">
                        <p className="my-business-store-subheading">{equipmentData?.EquipmentTypeValue ? equipmentData?.EquipmentTypeValue : "For Any Equipment"} • {equipmentData?.OEMName ?equipmentData?.OEMName :"NA"} • {equipmentData?.ModelName ?equipmentData?.ModelName : "NA"}</p>
                        <p className="my-business-store-subheading">{equipmentData?.AssetUniqueId ? equipmentData?.AssetUniqueId  :  "NA"}</p>
                    </Flex>
                </Flex>
                )}      
               
            </Flex>
               
               <Box  padding={"0.5em 2em"} height={"100%"} overflow={"scroll"} >
                     {getContactRequestReceivedList ? 
                    <MarketplaceHoc
                    marketplaceName = 'myLeads'
                    dataArray={getContactRequestReceivedList}
                    hasPagination={false}
                    initialLoading={initialLoading}
                    cardsRenderFunction={renderTabAcceptRejectCard}
                    /> 
                    : 
                    null    
                    }
                </Box>
                
            </Box>
        </NewSideMenuLayout>
    )
}

export default AcceptReject