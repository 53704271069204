import { createSlice } from "@reduxjs/toolkit";
import {
  getLocalStorageItems,
  setLocalStorageItems,
} from "../../../../helpers/localStorage/localStorage";
import { handleJWTTokenThunk } from "../../../login/thunks/handleJWTTokenThunk";
import { STORAGE_CONSTANTS } from "../../../../../constants/storageConstants";
import { userDetailsThunk } from "../thunks/userDetailThunk";
import { getCurrencyForUserThunk } from "../thunks/getCurrencyForUserThunk";
import { setSessionStorageItems } from "../../../../helpers/sessionStorage/sessionStorage";
import { API_CONSTANTS } from "../../../../../constants/apiConstants";
import { getUserPremiumDetails } from "../../../../services/premium/premium";
import { getUserPremiumStatusThunk } from "../thunks/getUserPremiumStatusThunk";


export const UserSlice = createSlice({
  name: "user",
  initialState: {
    token: getLocalStorageItems(STORAGE_CONSTANTS.TOKEN) || null,
    isAuthenticated:
      Boolean(getLocalStorageItems(STORAGE_CONSTANTS.IS_AUTH)) || false,
    accountDetails:
      getLocalStorageItems(STORAGE_CONSTANTS.USER_DETAILS) || null,
    BusinessTypeValue:
      getLocalStorageItems(STORAGE_CONSTANTS.USER_SELECTED_BUSINESS_TYPE) ||
      null,
    UserTypeValue:
      getLocalStorageItems(STORAGE_CONSTANTS.USER_SELECTED_USER_TYPE) || null,
    routeArray: getLocalStorageItems(STORAGE_CONSTANTS.ROUTE_ARRAY) || null,
    routeIndex: getLocalStorageItems(STORAGE_CONSTANTS.ROUTE_INDEX)
      ? parseInt(getLocalStorageItems(STORAGE_CONSTANTS.ROUTE_INDEX))
      : 0,
    currencyDetails : getLocalStorageItems(STORAGE_CONSTANTS.CURRENCY_DETAILS) || null,
    expiryTime : getLocalStorageItems(STORAGE_CONSTANTS.EXP_TIME) || null,
    issuedAtTime: getLocalStorageItems(STORAGE_CONSTANTS.ISSUE_AT_TIME) || null,
    isPremiumUser: false,
    premiumUserId : null,
    premiumStatusLoading: false,
    loading: false,
    success: false,
    error: false,
    errorMessage: null,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      state.isAuthenticated = true;
      setLocalStorageItems(STORAGE_CONSTANTS.TOKEN, action.payload);
      setLocalStorageItems(STORAGE_CONSTANTS.IS_AUTH, true);
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = Boolean(action.payload);
      setLocalStorageItems(STORAGE_CONSTANTS.IS_AUTH, action.payload);
    },
    setAccountDetails: (state, action) => {
      state.accountDetails = action.payload;
      setLocalStorageItems(STORAGE_CONSTANTS.ACCOUNT_DETAILS, action.payload);
    },
    setUserTypeValue: (state, action) => {
      state.UserTypeValue = action.payload;
      setLocalStorageItems(
        STORAGE_CONSTANTS.USER_SELECTED_USER_TYPE,
        action.payload
      );
    },

    setBusinessTypeValue: (state, action) => {
      state.BusinessTypeValue = action.payload;
      setLocalStorageItems(
        STORAGE_CONSTANTS.USER_SELECTED_BUSINESS_TYPE,
        action.payload
      );
    },
    updateToken : (state, {payload}) => {
      state.token = payload.token;
      state.issuedAtTime = payload.issuedAt;
      state.expiryTime = payload.expiryTime;
      setLocalStorageItems(STORAGE_CONSTANTS.TOKEN, payload.token);
      setLocalStorageItems(STORAGE_CONSTANTS.ISSUE_AT_TIME, payload.issuedAt);
      setLocalStorageItems(STORAGE_CONSTANTS.EXP_TIME, payload.expiryTime);
    },
    setPremiumStatus : (state, {payload}) => {
      state.isPremiumUser = payload;
    },
    setPremiumId : (state, {payload}) => {
      state.premiumUserId = payload;
    },
    // setRoutingArray: (state, action) => {
    //   let route;
    //   if (
    //     (action.payload.BusinessTypeValue.toLowerCase() ===
    //       "Business Owner".toLowerCase() ||
    //       action.payload.BusinessTypeValue.toLowerCase() ===
    //         "Equipment Owner".toLowerCase()) &&
    //     action.payload.UserTypeValue.toLowerCase() ===
    //       "Individual".toLowerCase()
    //   ) {
    //     route = DOCUMENT_FLOW_NAVIGATION.OWNER_INDIVIDUAL;
    //   } else if (
    //     (action.payload.BusinessTypeValue.toLowerCase() ===
    //       "Business Owner".toLowerCase() ||
    //       action.payload.BusinessTypeValue.toLowerCase() ===
    //         "Equipment Owner".toLowerCase() || 
    //         action.payload.BusinessTypeValue.toLowerCase() === "Maintenance Partner".toLowerCase() ) &&
    //     action.payload.UserTypeValue.toLowerCase() === "Company".toLowerCase()
    //   ) {
    //     route = DOCUMENT_FLOW_NAVIGATION.OWNER_COMPANY;
    //   } else {
    //     route = DOCUMENT_FLOW_NAVIGATION.PARTNER;
    //   }
    //   state.routeArray = route;
    //   setLocalStorageItems(STORAGE_CONSTANTS.ROUTE_ARRAY, route);
    // },
    // resetRouteArray: (state) => {
    //   state.routeArray = null;
    //   setLocalStorageItems(STORAGE_CONSTANTS.ROUTE_ARRAY, null)
    // },
    // setRouteIndex: (state, action) => {
    //   state.routeIndex = action.payload;
    //   setLocalStorageItems(STORAGE_CONSTANTS.ROUTE_INDEX, action.payload);
    // },
    // increaseRouteIndex: (state) => {
    //   state.routeIndex += 1;
    //   setLocalStorageItems(STORAGE_CONSTANTS.ROUTE_INDEX, state.routeIndex);
    // },
    // decreaseRouteIndex: (state) => {
    //   if (state.routeIndex) {
    //     state.routeIndex -= 1;
    //     setLocalStorageItems(STORAGE_CONSTANTS.ROUTE_INDEX, state.routeIndex);
    //   }
    // },
    logoutUser: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.accountDetails = null;
      state.UserTypeValue = null;
      state.BusinessTypeValue = null;
      state.accountDetails = null;
      state.currencyDetails = null;
      state.expiryTime = null;
      state.isAuthenticated = false;

      // state.routeArray = null;
      // state.routeIndex = null;
      localStorage.removeItem(STORAGE_CONSTANTS.TOKEN);
      localStorage.removeItem(STORAGE_CONSTANTS.IS_AUTH);
      localStorage.removeItem(STORAGE_CONSTANTS.ISSUE_AT_TIME);
      localStorage.removeItem(STORAGE_CONSTANTS.EXP_TIME)
      // setLocalStorageItems(STORAGE_CONSTANTS.TOKEN, null);
      // setLocalStorageItems(STORAGE_CONSTANTS.IS_AUTH, false);
      setLocalStorageItems(STORAGE_CONSTANTS.ACCOUNT_DETAILS, null);
      setLocalStorageItems(STORAGE_CONSTANTS.USER_SELECTED_USER_TYPE, null);
      setLocalStorageItems(STORAGE_CONSTANTS.ACCOUNT_DETAILS,null);
      setLocalStorageItems(STORAGE_CONSTANTS.CURRENCY_DETAILS,null);
      // localStorage.clear();

      // localStorage.removeItem("APP_DEFAULT_LANGUAGE");
      // setLocalStorageItems(STORAGE_CONSTANTS.ROUTE_ARRAY, null);
      // setLocalStorageItems(STORAGE_CONSTANTS.ROUTE_INDEX, null);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleJWTTokenThunk.pending, (state) => {
        state.token = null;
        state.isAuthenticated = false;
        state.accountDetails = null;
        state.expiryTime = null;
        state.issuedAtTime = null;
        state.success = false;
        state.error = false;
        state.errorMessage = null;
        state.loading = true;
        setLocalStorageItems(STORAGE_CONSTANTS.EXP_TIME, "");
        setLocalStorageItems(STORAGE_CONSTANTS.ISSUE_AT_TIME, "");
        setLocalStorageItems(STORAGE_CONSTANTS.TOKEN, null);
        setLocalStorageItems(STORAGE_CONSTANTS.IS_AUTH, false);
      })
      .addCase(handleJWTTokenThunk.fulfilled, (state, action) => {
        state.token = action.payload.token;
        state.expiryTime = action.payload.expiryTime;
        state.issuedAtTime = action.payload.issuedAt;
        state.isAuthenticated = true;
        state.accountDetails = false;
        state.success = true;
        state.error = false;
        state.errorMessage = null;
        state.loading = false;
        setLocalStorageItems(STORAGE_CONSTANTS.EXP_TIME, action.payload.expiryTime);
        setLocalStorageItems(STORAGE_CONSTANTS.ISSUE_AT_TIME, action.payload.issuedAt);
        setLocalStorageItems(STORAGE_CONSTANTS.TOKEN, action.payload.token);
        setLocalStorageItems(STORAGE_CONSTANTS.IS_AUTH, true);
        // setSessionStorageItems(STORAGE_CONSTANTS.EXP_TIME, action.payload.expiryTime);
        // setSessionStorageItems(STORAGE_CONSTANTS.ISSUE_AT_TIME, action.payload.issuedAt);
      })
      .addCase(handleJWTTokenThunk.rejected, (state, action) => {
        state.token = null;
        state.accountDetails = null;
        state.expiryTime = null;
        state.isAuthenticated = false;
        state.accountDetails = null;
        state.loading = false;
        state.success = false;
        state.error = true;
        state.errorMessage = action.payload;
        setLocalStorageItems(STORAGE_CONSTANTS.EXP_TIME, "");
        setLocalStorageItems(STORAGE_CONSTANTS.ISSUE_AT_TIME, "");
        setSessionStorageItems(STORAGE_CONSTANTS.EXP_TIME, "");
        setSessionStorageItems(STORAGE_CONSTANTS.ISSUE_AT_TIME, "");
        // setLocalStorageItems(STORAGE_CONSTANTS.TOKEN, null);
        // setLocalStorageItems(STORAGE_CONSTANTS.IS_AUTH, false);
      })
      .addCase(userDetailsThunk.fulfilled, (state, action) => {
        state.accountDetails = action.payload;
        setLocalStorageItems(STORAGE_CONSTANTS.USER_DETAILS, action.payload);
      })
      .addCase(userDetailsThunk.rejected, (state, action) => {
        state.accountDetails = null;
        setLocalStorageItems(STORAGE_CONSTANTS.USER_DETAILS, "");
        state.error = true;
        state.errorMessage = action.payload;
        state.loading = false;
      })
      .addCase(getCurrencyForUserThunk.fulfilled, (state, {payload}) => {
        state.currencyDetails = payload;
        setLocalStorageItems(STORAGE_CONSTANTS.CURRENCY_DETAILS, payload);

      })
      .addCase(getUserPremiumStatusThunk.pending, (state) => {
        state.isPremiumUser = false;
        state.premiumUserId = null;
        state.premiumStatusLoading = true;
      })
      .addCase(getUserPremiumStatusThunk.fulfilled, (state, {payload}) => {
        state.isPremiumUser = true;
        if(payload.Data && payload.Data.PremiumUserId){
          state.premiumUserId = payload.Data.PremiumUserId;
        }
        state.premiumStatusLoading = false;
      })
      .addCase(getUserPremiumStatusThunk.rejected, (state) => {
        state.isPremiumUser = false;
        state.premiumUserId = null;
        state.premiumStatusLoading = false;
      })
  },
});

export const {
  setToken,
  setIsAuthenticated,
  setAccountDetails,
  setUserTypeValue,
  setBusinessTypeValue,
  setPremiumStatus,
  setPremiumId,
  // setRoutingArray,
  // resetRouteArray,
  // setRouteIndex,
  // increaseRouteIndex,
  // decreaseRouteIndex,
  logoutUser,
} = UserSlice.actions;

export const logoutReduxAction = UserSlice.actions.logoutUser;
export const updateRefreshToken = UserSlice.actions.updateToken;

export default UserSlice.reducer;
