import {
  Accordion,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { NewSideMenuLayout } from "../../../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserAssetById } from "../../../../services/userAssets/userAssets";
import { AccordianElement } from "../../../../components/AccordianElement/AccordianElement";
import { Field, Formik } from "formik";
import { FormikInput } from "../../../../components/Input/FormikInput";
import { RadioParent } from "../../../../components/RadioParent/RadioParent";
import { registerNumbersOnly } from "../../../../../utils/form/form";
import { MultipleImageUpload } from "../../../../components/MultipleImageUpload/MultipleImageUpload";
import { fetchHandler } from "../../../../..";
import { convertToFormData } from "../../../../helpers/dataConversion/convertToFormData";
import { API_CONSTANTS } from "../../../../../constants/apiConstants";
import { UploadMediaPopUp } from "../../../../components/UploadMediaPopUp/UploadMediaPopUp";
import {
  getDocumentTypes,
  getRentalShiftTypes,
  postEquipmentPurchasingDetails,
  updateEquipmentPurchasingDetails,
} from "../../../../services/equipments/equipments";
import { getSalaryTypes } from "../../../../services/salary/salary";

export const AdditionalEquipmentDetails = () => {
  const [searchParams] = useSearchParams();
  const [assetDetails, setAssetDetails] = useState(null);
  const [documentsPrivate, setDocumentsPrivate] = useState(false);
  const [documentTypeList, setDocumentsTypeList] = useState(null);
  const [rentalShiftTypes, setRentalShiftTypes] = useState(null);
  const [rentCycleTypes, setRentCycleTypes] = useState(null);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [documentModelOpen, setDocumentModelOpen] = useState(false);
  const [documentFiles, setDocumentFiles] = useState(null);
  const [uploadedImages, setUploadedImages] = useState(null);
  const [loading, setLoading] = useState(false)
  const allEquipmentImages = [
    { id: 1, value: "Front Image", key: "AssetImageFront" },
    { id: 2, value: "Right Image", key: "AssetImageRight" },
    { id: 3, value: "Left Image", key: "AssetImageLeft" },
    { id: 4, value: "Back Image", key: "AssetImageBack" },
  ];

  const toast = useToast();
  const navigate = useNavigate();

  const getAssetDetails = async (id) => {
    setLoading(true)
    const data = await getUserAssetById(id);
    setAssetDetails(data);
    setLoading(false)
  };

  const getMarketplaceDetails = async (id) => {
    // check whether equipment ios listed or not
    // const
  };

  const getSelectedDocumentObject = (objectName) => {
    if (!objectName) {
      throw new Error("parameter cannot be empty");
    }
    const selectedDocument = documentTypeList.find(
      ({ value }) => value.toLowerCase() === objectName.toLowerCase()
    );
    return selectedDocument;
  };
  const handleUploadDocuments = async (body) => {
    const requiredBody = {
      AssetDocumentTypeId: getSelectedDocumentObject(body.selectValue).id,
      AssetId: assetDetails.AssetDetails.AssetId,
      AssetDocument: body.document,
    };
    try {
      const { data } = await fetchHandler(
        `/CreateNewAssetDocumentDetails`,
        {},
        {},
        convertToFormData(requiredBody),
        API_CONSTANTS.HTT_POST
      );
      await getAssetDetails(searchParams.get("assetId")); // Thunk will be called
    } catch (error) {}
  };

  const handleDeleteDocument = async (object) => {
    try {
      const { data } = await fetchHandler(
        `/DeleteNewAssetsDocumentDetails/${object.AssetsDocumentId}`,
        {},
        {},
        {},
        API_CONSTANTS.HTTP_DELETE
      );
      // console.log(data);
      await getAssetDetails(searchParams.get("assetId"));
    } catch (error) {
      console.log(error);
    }
  };

  const getAssetDocumentTypeList = async () => {
    try {
      const data = await getDocumentTypes();
      setDocumentsTypeList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getRentalShiftList = async () => {
    try {
      const data = await getRentalShiftTypes();
      // console.log(data);
      setRentalShiftTypes(data);
    } catch (error) {
      setRentalShiftTypes(null);
    }
  };

  const getRentCycle = async () => {
    try {
      const data = await getSalaryTypes();
      // console.log(data);
      setRentCycleTypes(data);
    } catch (error) {
      setRentCycleTypes(null);
    }
  };

  const getUploadedDocuments = (assetData) => {
    if (!assetData) {
      setDocumentFiles(null);
      return null;
    }
    if (!assetData.AssetDocumentDetails) {
      setDocumentFiles(null);
      return null;
    }
    const documentLinks = assetData.AssetDocumentDetails.map((element) => ({
      id: element.AssetDocumentTypeId,
      value: element.AssetDocumentTypeValue,
      url: `${process.env.REACT_APP_CDN_PVT_URL}${assetData.AssetDocumentReference}${element.DocumentFileReference}`,
    }));
    setDocumentFiles(documentLinks);
    return documentLinks;
  };

  const getDocumentToDelete = (documentName) => {
    const selectedDocument = assetDetails.AssetDocumentDetails.find(
      ({ AssetDocumentTypeId }) => AssetDocumentTypeId === documentName
    );
    return selectedDocument;
  };

  const getUploadedImages = (assetDetails) => {
    if (!assetDetails || !assetDetails.AssetImageDetails) {
      // console.log("No Images found");
      setUploadedImages(null);
      return null;
    }
    const { AssetImageDetails } = assetDetails;
    const imageArray = Object.keys(AssetImageDetails).filter(
      (fileRef) =>
        fileRef.split("_")[2] === "FileRef" &&
        AssetImageDetails[fileRef] !== null
    );
    return createUploadedImagesArray(imageArray);
  };
  const createUploadedImageObjects = (imageKey) => {
    switch (imageKey) {
      case "Image_Front_FileRef":
        return {
          id: 1,
          value: "Front Image",
          url: `${process.env.REACT_APP_CDN_PVT_URL}AssetsImage/${assetDetails.AssetImageDetails[imageKey]}`,
        };
      case "Image_Back_FileRef":
        return {
          id: 4,
          value: "Back Image",
          url: `${process.env.REACT_APP_CDN_PVT_URL}AssetsImage/${assetDetails.AssetImageDetails[imageKey]}`,
        };
      case "Image_Left_FileRef":
        return {
          id: 3,
          value: "Left Image",
          url: `${process.env.REACT_APP_CDN_PVT_URL}AssetsImage/${assetDetails.AssetImageDetails[imageKey]}`,
        };
      case "Image_Right_FileRef":
        return {
          id: 2,
          value: "Right Image",
          url: `${process.env.REACT_APP_CDN_PVT_URL}AssetsImage/${assetDetails.AssetImageDetails[imageKey]}`,
        };
    }
  };

  const createUploadedImagesArray = (array) => {
    if (array && array.length >= 0) {
      const requiredArray = array.map((imageKey) =>
        createUploadedImageObjects(imageKey)
      );
      setUploadedImages(requiredArray);
      return requiredArray;
    }
    setUploadedImages(null);
    return null;
  };

  const createAdditionalDetails = async (body) => {
    try {
      const data = await postEquipmentPurchasingDetails(body);
      // console.log(data);
      toast({
        description: data.message,
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      navigate(
        `/my-store/create-marketplace-details?assetId=${
          assetDetails.AssetDetails.AssetId
        }&marketplaceType=${searchParams.get(
          "marketplaceType"
        )}&leadType=${searchParams.get("leadType")}&isDocumentPrivate=${
          documentsPrivate ? 1 : 0
        }`
      );
    } catch (error) {
      toast({
        description: error.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
      navigate("/equipment-store");
    }
  };

  const updateAdditionalDetails = async (body) => {
    try {
      const data = await updateEquipmentPurchasingDetails(
        searchParams.get("assetId"),
        body
      );
      // console.log(data)
      toast({
        description: data.message,
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      navigate(
        `/my-store/create-marketplace-details?assetId=${
          assetDetails.AssetDetails.AssetId
        }&marketplaceType=${searchParams.get(
          "marketplaceType"
        )}&leadType=${searchParams.get("leadType")}&isDocumentPrivate=${
          documentsPrivate ? 1 : 0
        }`
      );
    } catch (error) {
      toast({
        description: error.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
      navigate("/equipment-store");
    }
  };

  useEffect(() => {
    if (searchParams.get("assetId")) {
      getAssetDetails(searchParams.get("assetId"));
    }
  }, [searchParams]);

  useEffect(() => {
    if (assetDetails) {
      getUploadedDocuments(assetDetails);
      getUploadedImages(assetDetails);
    }
  }, [assetDetails]);

  useEffect(() => {
    getAssetDocumentTypeList();
    // console.log(searchParams.get("marketplaceType") === "rental");
    if (searchParams.get("marketplaceType") === "rental") {
      getRentalShiftList();
      getRentCycle();
    }
  }, []);
  return (
   <NewSideMenuLayout gridTemplateRows="80px 1fr">
      <UploadMediaPopUp
        isModalOpen={documentModelOpen}
        modalTitle="Upload Documents"
        mediaComponentTitle="Upload a document and Select a Name"
        mediaExtentionsAllowed={["jpg", "jpeg", "png", "pdf"]}
        maxFileSizeinMb={5}
        handleSubmit={async (values) => {
          await handleUploadDocuments(values);
          setDocumentModelOpen(false);
        }}
        dropdownValues={documentTypeList}
        valuesToDeactivate={documentFiles ? documentFiles : []}
        onCloseButtonClick={() => {
          setDocumentModelOpen(false);
        }}
      />
      <UploadMediaPopUp
        modalTitle="Upload Image"
        isModalOpen={imageModalOpen}
        mediaComponentTitle="Upload a Photos and select a Name"
        mediaExtentionsAllowed={["jpg", "jpeg", "png"]}
        maxFileSizeinMb={5}
        dropdownValues={allEquipmentImages}
        valuesToDeactivate={uploadedImages ? uploadedImages : []}
        handleSubmit={async (values) => {
          const { document, selectValue } = values;
          const selectedPhoto = allEquipmentImages.find(
            ({ value }) => value === selectValue
          );
          const setName = `IMAGE_${searchParams.get(
            "assetId"
          )}_${selectedPhoto.value.toUpperCase().split(" ").join("||")}.${
            document.type.split("/")[1]
          }`;
          const newImageObject = new File([document], setName, {
            type: document.type,
          });
          const imageKey = selectedPhoto.key;

          try {
            const { data } = await fetchHandler(
              "/CreateNewAssetImageDetails",
              {},
              {},
              convertToFormData({
                AssetId: searchParams.get("assetId"),
                [imageKey]: newImageObject,
              }),
              API_CONSTANTS.HTT_POST
            );
            await getAssetDetails(searchParams.get("assetId"));
            setImageModalOpen(false);
          } catch (error) {
          }
        }}
        onCloseButtonClick={() => {
          setImageModalOpen(false);
        }}
      />
      <div
        style={{
          margin: "0px 52px",
          backgroundColor: "#92929204",
          paddingTop: "1em",
        }}
      >
        <div style={{ marginBottom: "32px" }}>
          <h1 style={{ fontSize: "32px", fontWeight: 700 }}>
            Additional Details
          </h1>
        </div>
        <Box maxH="400px" overflowY="scroll">
          <Accordion allowToggle defaultIndex={[0]}>
            <Flex flexDirection="column" gap="1em">
              <AccordianElement title="Purchasing Details">
                <Formik
                  initialValues={{
                    purchasingPrice:
                      assetDetails &&
                      assetDetails.AssetPurchaseDetails?.PurchasedPrice
                        ? assetDetails.AssetPurchaseDetails.PurchasedPrice
                        : "",
                    underLoan: documentsPrivate,
                    expectedSalePrice: "",
                    expectedRentalShift: "",
                    expectedRentalType: "",
                  }}
                  onSubmit={async (values) => {
                    if (!documentFiles) {
                      toast({
                        status: "error",
                        description: "Atleast one document is required",
                        position: "top-right",
                        isClosable: true,
                      });
                    } else if (!uploadedImages) {
                      toast({
                        status: "error",
                        description: "Atleast one image is required",
                        position: "top-right",
                        isClosable: true,
                      });
                    } else {
                      //   console.log(values);
                      //   console.log(documentsPrivate);
                      const body = {
                        AssetId: assetDetails.AssetDetails.AssetId,
                        PurchasedPrice: values.purchasingPrice
                          ? values.purchasingPrice
                          : assetDetails.AssetPurchaseDetails &&
                            assetDetails.AssetPurchaseDetails.PurchasedPrice
                          ? assetDetails.AssetPurchaseDetails.PurchasedPrice
                          : "",
                        PurchasingYear:
                          assetDetails.AssetPurchaseDetails &&
                          assetDetails.AssetPurchaseDetails.PurchasedYear
                            ? assetDetails.AssetPurchaseDetails.PurchasedYear
                            : "",
                        CountryIdPurchasedFrom:
                          assetDetails.AssetPurchaseDetails &&
                          assetDetails.AssetPurchaseDetails
                            .CountryIdPurchasedFrom
                            ? assetDetails.AssetPurchaseDetails
                                .CountryIdPurchasedFrom
                            : "",
                        RegisteredStateId:
                          assetDetails.AssetPurchaseDetails &&
                          assetDetails.AssetPurchaseDetails.RegisteredStateId
                            ? assetDetails.AssetPurchaseDetails
                                .RegisteredStateId
                            : "",
                        OwnershipTypeId:
                          assetDetails.AssetPurchaseDetails &&
                          assetDetails.AssetPurchaseDetails.OwnershipTypeId
                            ? assetDetails.AssetPurchaseDetails.OwnershipTypeId
                            : "",
                      };
                      if (
                        searchParams.get("marketplaceType").toLowerCase() ===
                        "used".toLowerCase()
                      ) {
                        body.ExpectedRentalType =
                          assetDetails.AssetPurchaseDetails &&
                          assetDetails.AssetPurchaseDetails.ExpectedRentalType
                            ? assetDetails.AssetPurchaseDetails
                                .ExpectedRentalType
                            : "";
                        body.ExpectedRentalPrice =
                          assetDetails.AssetPurchaseDetails &&
                          assetDetails.AssetPurchaseDetails.ExpectedRentalPrice
                            ? assetDetails.AssetPurchaseDetails
                                .ExpectedRentalPrice
                            : "";
                        body.ExpectedPrice = values.expectedSalePrice
                          ? values.expectedSalePrice
                          : "";
                        body.IsUnderLoan =
                          values.underLoan || !isNaN(parseInt(values.underLoan))
                            ? values.underLoan
                            : "";
                      } else {
                        body.ExpectedRentalPrice = values.expectedSalePrice;
                        body.ExpectedRentalType = values.expectedRentalType;
                      }
                      //   console.log(body);
                      // check if purchasing details exist
                      if (
                        assetDetails.AssetPurchaseDetails &&
                        Object.keys(assetDetails.AssetPurchaseDetails).length >
                          0
                      ) {
                        // if yes update
                        await updateAdditionalDetails(body);
                      } else {
                        await createAdditionalDetails(body);
                      }
                      // if no post
                      // on success of api navigate it to create marketplace redeem list
                      // on marketplaxce redeem list create free subs first.
                    }
                  }}
                  // enableReinitialize
                >
                  {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} id="marketplace-form">
                      <Flex
                        wrap="wrap"
                        gap="1em"
                        justifyContent="space-between"
                      >
                        <FormikInput
                          name="purchasingPrice"
                          label="Purchasing Price"
                          maxWidth="450px"
                          placeholder="Enter Purchasing Price"
                          isRequired={true}
                          onKeyDown={registerNumbersOnly}
                          maxLength={9}
                        />
                        <FormikInput
                          name="expectedSalePrice"
                          label={
                            searchParams.get("marketplaceType") === "used"
                              ? "Expected Sale Price"
                              : "Expected Rental Price"
                          }
                          maxWidth="450px"
                          placeholder={
                            searchParams.get("marketplaceType") === "used"
                              ? "Enter Expected Sale Price"
                              : "Enter Expected Rental Price"
                          }
                          isRequired={true}
                          onKeyDown={registerNumbersOnly}
                          maxLength={9}
                        />
                        {/* /For rental only */}
                        {searchParams.get("marketplaceType")  === "rental" && (
                          <Field name="expectedRentalType">
                            {({ field }) => (
                              <RadioParent
                                name={field.name}
                                options={rentCycleTypes ? rentCycleTypes : []}
                                label="Rent Type"
                                isRequired={true}
                                isFormik={true}
                                maxWidth="450px"
                              />
                            )}
                          </Field>
                        )}
                        {/* For Rental only */}
                        {searchParams.get("marketplaceType") ===  "rental" && 
                          <Field name="expectedRentalShift">
                            {({ field }) => (
                              <RadioParent
                                name={field.name}
                                options={
                                  rentalShiftTypes ? rentalShiftTypes : []
                                }
                                label="Rent Shift"
                                isRequired={true}
                                isFormik={true}
                                maxWidth="450px"
                              />
                            )}
                          </Field>
                        }

                        {/* FOR USED EQUIPMENT ONLY  */}
                        {searchParams.get("marketplaceType")  === "used" && (
                          <Field name="underLoan">
                            {({ field }) => (
                              <RadioParent
                                name={field.name}
                                options={[
                                  { id: 1, value: "Yes" },
                                  { id: 0, value: "No" },
                                ]}
                                label="Is Currently Under Loan ?"
                                isRequired={false}
                                isFormik={true}
                                maxWidth="450px"
                              />
                            )}
                          </Field>
                        )}
                      </Flex>
                    </form>
                  )}
                </Formik>
              </AccordianElement>
              <Flex
                background="#D5FFCB90"
                borderRadius="0.5em"
                padding="0.25em 0.5em"
              >
                <FormControl
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  onChange={() =>
                    setDocumentsPrivate((prevState) => !prevState)
                  }
                >
                  <FormLabel htmlFor="documents-private" mb="0">
                    Make Documents Private
                  </FormLabel>
                  <Switch
                    id="documents-private"
                    colorScheme="green"
                    isChecked={documentsPrivate}
                    //   onClick={() => (setDocumentsPrivate((prevState) => (!prevState)))}
                  />
                </FormControl>
              </Flex>

              <MultipleImageUpload
                title="Equipment Documents"
                maxWidth="100%"
                mediaArray={documentFiles}
                onAddButtonClick={() => {
                  setDocumentModelOpen(true);
                }}
                deleteAction={(value) => {
                  if (value) {
                    // console.log(value);
                    const object = getDocumentToDelete(value);
                    // console.log(object);
                    handleDeleteDocument(object);
                  }
                }}
                isDisabled={
                  Array.isArray(documentFiles) &&
                  Array.isArray(documentTypeList) &&
                  documentFiles.length === documentTypeList.length
                }
              />
              <MultipleImageUpload
                title="Equipment Images"
                maxWidth="100%"
                onAddButtonClick={() => {
                  setImageModalOpen(true);
                }}
                mediaArray={uploadedImages}
                isDisabled={
                  uploadedImages &&
                  allEquipmentImages &&
                  allEquipmentImages.length === uploadedImages.length
                }
              />
            </Flex>
          </Accordion>
        </Box>
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxHeight: "78px",
          height: "78px"
        }}
      >
        <Button
          type="submit"
          form="marketplace-form"
          variant="gradient"
          w="347px"
          
          justifySelf="center"
        >
          Next
        </Button>
      </div>
    </NewSideMenuLayout>
  );
};
