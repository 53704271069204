import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../constants/initialState";
import { quizInitialListThunk } from "../thunks/quizInitialListThunk";
import { quizListLoadMoreThunk } from "../thunks/quizListLoadMoreThunk";
import { myQuizInitialListThunk } from "../thunks/myQuizInitialListThunk";
import { myQuizListLoadMoreThunk } from "../thunks/myQuizListLoadMoreThunk";

export const quizSlice = createSlice({
  name: "quiz",
  initialState: initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setSelectedQuiz: (state, action) => {
      state.selectedQuiz = action.payload;
    },
    SetIsComingSoonModalOpen: (state, action) => {
      state.isComingSoonModalOpen = action.payload;
    },
    SetIsQuizNotPlayedByAnyoneModalOpen: (state, action) => {
      state.isQuizNotPlayedByAnyonePopupOpen = action.payload;
    },
    SetIsActiveQuizModalOpen: (state, action) => {
      state.isActiveQuizPopupOpen = action.payload;
    },
    SetIsAlreadyPlayedByUserModalOpen: (state, action) => {
      state.isAlreadyPlayedByUserPopupOpen = action.payload;
    },
    SetIsQuizSubmittedModalOpen: (state, action) => {
      state.isQuizSubmittedPopupOpen = action.payload;
    },
    SetIsQuizTimesUpModalOpen: (state, action) => {
      state.isQuizTimesUpPopupOpen = action.payload;
    },
    setUserScore:(state, action) => {
      state.userScore = action.payload;
    },
    setSelectedAnswerData:(state, action) => {
      if(action.payload===null){
        state.selectedAnswerData=[];
      }else{
        state.selectedAnswerData.push(action.payload);
      }  
    },
   
  },

  extraReducers: (builder) => {
    builder
      .addCase(quizInitialListThunk.pending, (state) => {
       
        state.data = null;
        state.initialLoading = true;
        state.paginationLoading = true;
        state.error = false;
        state.errorMessage = false;
        state.paginationCount = 0;
        state.endOfData = false;
      })
      .addCase(quizInitialListThunk.fulfilled, (state, {payload}) => {
        state.data = payload?.Data?.QuizList
        state.imageBaseUrl=payload?.Data?.QuizImagesBaseUrl
        state.initialLoading = false;
        state.paginationLoading = false;
        state.paginationCount = 1;
        state.error = false;
        state.errorMessage = null;
        if (payload?.Data?.TotalRecords < state.cardCount) {
          state.endOfData = true;
        }
      })
      .addCase(quizInitialListThunk.rejected, (state, action) => {
        state.data = null;
        state.initialLoading = false;
        state.paginationLoading = false;
        state.error = true;
        state.errorMessage = action.payload;
        state.paginationCount = 0;
        state.endOfData = true;
      })
      .addCase(quizListLoadMoreThunk.pending, (state) => {
        state.paginationLoading = true;
        state.error = false;
        state.errorMessage = null;
      })
      .addCase(quizListLoadMoreThunk.fulfilled, (state, action) => {
        state.error = false;
        state.errorMessage = null;
        state.paginationLoading = false;
        if(action.payload?.Data?.TotalRecords !== 0){
          state.data = [...state.data, ...action.payload?.Data?.QuizList];
        }
        if (action.payload?.Data?.TotalRecords >= state.cardCount) {
          state.paginationCount += 1;
        } else {
          state.endOfData = true;
        }
      })
      .addCase(myQuizInitialListThunk.pending, (state) => {
       
        state.myPlayedQuizzes = null;
        state.initialLoading = true;
        state.paginationLoading = true;
        state.error = false;
        state.errorMessage = false;
        state.paginationCount = 0;
        state.endOfData = false;
      })
      .addCase(myQuizInitialListThunk.fulfilled, (state, {payload}) => {
        state.myPlayedQuizzes = payload?.Data?.MyQuizzesList
        state.imageBaseUrl=payload?.Data?.QuizImagesBaseUrl
        state.initialLoading = false;
        state.paginationLoading = false;
        state.paginationCount = 1;
        state.error = false;
        state.errorMessage = null;
        if (payload?.Data?.MyQuizzesList?.length < state.cardCount) {
          state.endOfData = true;
        }
      })
      .addCase(myQuizInitialListThunk.rejected, (state, action) => {
        state.myPlayedQuizzes = null;
        state.initialLoading = false;
        state.paginationLoading = false;
        state.error = true;
        state.errorMessage = action.payload;
        state.paginationCount = 0;
        state.endOfData = true;
      })
      .addCase(myQuizListLoadMoreThunk.pending, (state) => {
        state.paginationLoading = true;
        state.error = false;
        state.errorMessage = null;
      })
      .addCase(myQuizListLoadMoreThunk.fulfilled, (state, action) => {
        state.error = false;
        state.errorMessage = null;
        state.paginationLoading = false;
        state.myPlayedQuizzes = [...state.myPlayedQuizzes, ...action.payload?.Data?.MyQuizzesList];
        if (action.payload?.Data?.MyQuizzesList?.length >= state.cardCount) {
          state.paginationCount += 1;
        } else {
          state.endOfData = true;
        }
      })
  },
});

export const {
  setData,
  setSelectedQuiz,
  SetIsComingSoonModalOpen,
  SetIsQuizNotPlayedByAnyoneModalOpen,
  SetIsActiveQuizModalOpen,
  SetIsAlreadyPlayedByUserModalOpen,
  SetIsQuizSubmittedModalOpen,
  SetIsQuizTimesUpModalOpen,
  setUserScore,
  setSelectedAnswerData
} = quizSlice.actions;
