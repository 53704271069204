import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "../constants/initialState";
import { oilInitialDataThunk } from "../thunk/oilInitialDataThunk";
import { oilLoadMoreDataThunk } from "../thunk/oilLoadMoreDataThunk";
import { oilInitialFilteredDataThunk } from "../thunk/oilInitialFilteredDataThunk";
import { oilLoadMoreFilteredData } from "../thunk/oilLoadMoreFilteredData";
import { setSessionStorageItems } from "../../../helpers/sessionStorage/sessionStorage";

export const oilMaterialMarketplaceSlice = createSlice({
  name: "oilMaterialMarketplace",
  initialState: initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },   
    setFilteredDataRequired: (state, action) => {
      state.filteredDataRequired = action.payload;
    },
    setFilterModalOpen: (state, action) => {
      state.filterModalOpen = action.payload;
    },
    setFilterByCountry: (state, action) => {
      state.filterByCountry = action.payload;
    },
    setFilterByState: (state, action) => {
      state.filterByState = action.payload;
    },
    setfilterByEquipmentTypeId : (state, action) =>{
      state.filterByEquipmentTypeId = action.payload
    },
    setfilterByEquipmentMakeId : (state,action)=>{
      state.filterByEquipmentMakeId = action.payload
    },
    setisOriginal : (state,action)=>{
      state.filterByIsOriginal = action.payload
    },
    resetFilters: (state) => {
      state.filterByEquipmentMakeId = null;
      state.filterByEquipmentTypeId = null;
      state.filterByIsOriginal = null;
      state.filterByCountry = null;
      state.filteredDataRequired = false;
      state.filterModalOpen = false;
    },
    setVideoList : (state, {payload}) =>{
      state.videoList = payload
      setSessionStorageItems(
        "MATERIALS_VIDEO_LIST",
        state.videoList
      );  
    },
    setMaterialsLocation : (state, {payload}) =>{
      state.location = payload;
      setSessionStorageItems("MATERIALS_LOCATION",state.location)
    },
    setMaterialResponseTypeId : (state, {payload}) =>{
      state.responseTypeId = payload;
      setSessionStorageItems("MATERIALS_RESPONSE",state.responseTypeId)
    },
    setLikedListdata :(state,{payload})=>{
      state.likeListData=payload;
    },
    setLikedData :(state,{payload})=>{
      state.likedData=payload;
    },
    updateResponseTypeId :(state,{payload})=>{
      const contactedMaterial = state.likedData.find(d => d.MaterialUniqueId === payload.MaterialUniqueId);
      if(contactedMaterial) {
        contactedMaterial.ResponseTypeId = payload.ResponseTypeId
      }
    },
    updateRemoveFromMarketPlace: (state,{payload})=>{
      state.likedData = state.likedData.filter(d => d.MaterialId !== payload.MaterialId);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(oilInitialDataThunk.pending, (state) => {
        state.data = null;
        state.initialLoading = true;
        state.paginationLoading = false;
        state.error = false;
        state.errorMessage = false;
        state.paginationCount = 1;
        state.endOfData = false
      })
      .addCase(oilInitialDataThunk.fulfilled, (state, action) => {
        state.data = action.payload?.MaterialMarketplaceList;
        state.paginationCount = 1;
        state.initialLoading = false;
        state.error = false;
        state.errorMessage = null;
        if(action.payload?.MaterialMarketplaceList?.length < state.cardCount){
          state.endOfData = true
        }
      })
      .addCase(oilLoadMoreDataThunk.pending, (state)=>{
        state.paginationLoading = true;
        state.error = false ;
        state.errorMessage = null;
     })
     .addCase(oilLoadMoreDataThunk.fulfilled, (state,action)=>{
        state.error = false ;
        state.errorMessage = null ;
        state.paginationLoading = false;
        state.data = [...state.data , ...action.payload?.MaterialMarketplaceList];
        if(action.payload?.MaterialMarketplaceList?.length >= state.cardCount){
            state.paginationCount += 1;
        }else{
            state.endOfData = true;
     }
     })
     .addCase(oilInitialFilteredDataThunk.pending,(state)=>{
      state.data = null;
      state.initialLoading = true;
      state.paginationLoading = false;
      state.error = false;
      state.errorMessage = false;
      state.paginationCount = 1;
      state.endOfData = false;
   })
   .addCase(oilInitialFilteredDataThunk.fulfilled, (state, action) => {
      state.data = action.payload?.MaterialMarketplaceList;
      state.paginationCount = 1;
      state.initialLoading = false;
      state.error = false;
      state.errorMessage = null;
      if (action?.payload?.MaterialMarketplaceList?.length >= state?.cardCount) {
        state.data = [...state.data , ...action.payload.MaterialMarketplaceList];
         state.paginationCount += 1;
       } else {
         state.endOfData = true;
       }
    })
    .addCase(oilLoadMoreFilteredData.pending, (state)=>{
      state.paginationLoading = true;
      state.error = false;
      state.errorMessage = null;
      state.error = false;
      state.errorMessage = null;           
    })
    .addCase(oilLoadMoreFilteredData.fulfilled, (state, action) => {
      state.error = false;
      state.errorMessage = null;
      state.paginationLoading = false;
      if (action?.payload?.MaterialMarketplaceList?.length >= state?.cardCount) {
        state.data = [...state.data , ...action.payload.MaterialMarketplaceList];
         state.paginationCount += 1;
       } else {
         state.endOfData = true;
       }
    })
  },
});

export const {
  setData,
  setFilterModalOpen,
  setFilteredDataRequired,
  setFilterByCountry,
  setFilterByState,
  resetFilters,
  setfilterByEquipmentTypeId,
  setfilterByEquipmentMakeId,
  setisOriginal,
  setVideoList,
  setMaterialsLocation,
  setMaterialResponseTypeId,
  setLikedListdata,
  setLikedData,
  updateResponseTypeId,
  updateRemoveFromMarketPlace
} = oilMaterialMarketplaceSlice.actions;
