import React, { useEffect, useState } from "react";
import { V5SidebarLayout } from "../../../../layout/V5SidebarLayout/V5SidebarLayout";
import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSentRequirementCards } from "../../thunk/industryLeads/getSentRequirementCards";
import { MarketplaceHoc } from "../../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import SentIndustryRequirementCard from "../../../../components/IndustryRequirement/SentIndustryRequirementCard";
import { getReceivedRequirementCardThunk } from "../../thunk/industryLeads/getReceivedRequirementCardThunk";
import { getRequirementRequestListThunk } from "../../thunk/industryLeads/getRequirementRequestListThunk";

const IndustryLeads = () => {
  const { defaultLanguage } = useSelector((state) => state.masterData);
  const { sentCards, receivedCards, initialLoading } = useSelector(
    (state) => state.myLeadsIndustry
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [marketplaceType, setMarketplaceType] = useState();

  useEffect(() => {
    handleCardsOnLocation();
  }, [location]);

  const handleCardsOnLocation = () => {
    const searchParams = new URLSearchParams(location.search);
    if (
      searchParams.get("marketplace-type") === "industry-requirement" &&
      searchParams.get("request-type") === "sent"
    ) {
      setMarketplaceType(searchParams.get("marketplace-type"));
      dispatch(getSentRequirementCards());
    } else if (
      searchParams.get("marketplace-type") === "industry-requirement" &&
      searchParams.get("request-type") === "received"
    ) {
      setMarketplaceType(searchParams.get("marketplace-type"));
      dispatch(getReceivedRequirementCardThunk());
    } else {
      navigate("/dashboard");
    }
  };

  const renderSentCards = (array) => {
    const renderJSX =
      array && array.length > 0 ? (
        array.map((data) => (
          <>
            <SentIndustryRequirementCard
              CreatedDateTime={data?.PostedOn}
              ExpiredOn={data?.ExpiredOn}
              ProfilePhoto={data?.ProfilePhoto}
              UserName={data?.UserName}
              BusinessTypeValue={data?.BusinessTypeValue}
              Requirement={data?.Requirement}
              RequirementCategoryValue={data?.RequirementCategoryValue}
              City={data?.City}
              State={data?.State}
              ResponseTypeValue={data?.ResponseTypeValue}
              ImageFileRef={data?.ImageFileRef}
            />
          </>
        ))
      ) : (
        <Text>No Records Found!</Text>
      );

    return renderJSX;
  };
  const renderReceivedCards = (array) => {
    const renderJSX =
      array && array.length > 0 ? (
        array.map((data) => (
          <>
            <SentIndustryRequirementCard
              CreatedDateTime={data?.PostedOn}
              ExpiredOn={data?.ExpiredOn}
              ProfilePhoto={data?.ProfilePhoto}
              UserName={data?.UserName}
              BusinessTypeValue={data?.BusinessTypeValue}
              Requirement={data?.Requirement}
              RequirementCategoryValue={data?.RequirementCategoryValue}
              City={data?.City}
              State={data?.State}
              isReceivedCard={true}
              likeCount={data?.TotalLikes}
              requestCount={data?.TotalRequest}
              ImageFileRef={data?.ImageFileRef}
              onLikeButtonClick={() => {
                navigate(
                  `/industry-requirement-leads/likes-details/${data?.RequirementDetailsId}`
                );
              }}
              onRequestButtonClick={() => {
                navigate(
                  `/industry-requirement-leads/request-details/${data?.RequirementDetailsId}/${data?.ServiceTypeId}`
                );
              }}
            />
          </>
        ))
      ) : (
        <Text>No Records Found!</Text>
      );

    return renderJSX;
  };

  return (
    <V5SidebarLayout>
      <Tabs isFitted variant="enclosed">
        <TabList>
          <Link
            style={{ width: "100%" }}
            to={`/industry-requirement-leads?marketplace-type=${marketplaceType}&request-type=sent`}
          >
            <Tab
              _selected={{ color: "white", bg: "#F79C00", width: "100%" }}
              width="100%"
            >
              {defaultLanguage?.Sent}
            </Tab>
          </Link>

          <Link
            style={{ width: "100%" }}
            to={`/industry-requirement-leads?marketplace-type=${marketplaceType}&request-type=received`}
          >
            <Tab
              _selected={{ color: "white", bg: "#F79C00", width: "100%" }}
              width="100%"
            >
              {defaultLanguage?.Received}
            </Tab>
          </Link>
        </TabList>

        <div>
          <TabPanels>
            <TabPanel>
              <Flex gap="1em" direction="column" flexWrap="wrap">
                {renderSentCards(sentCards)}
              </Flex>
            </TabPanel>

            <TabPanel>
              <Flex gap="1em" direction="column" flexWrap="wrap">
                {renderReceivedCards(receivedCards)}
              </Flex>
            </TabPanel>
          </TabPanels>
        </div>
      </Tabs>
    </V5SidebarLayout>
  );
};

export default IndustryLeads;
