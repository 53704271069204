import React from 'react';
import { SideMenuLayout } from '../../../../../layout/sideMenuLayout/SideMenuLayout';
import { DashboardNavBar } from '../../../../../components/DashboardNavBar/DashboardNavBar';
import { MidSection } from '../../../../../../sharedComponent/midSection/MidSection';
import "../LegalPolicy/TermsAndConditions.css";

const TermsAndCondition = () => {
  return (
    <>
      <SideMenuLayout>
        <DashboardNavBar />
        <MidSection>
          <div className="black-and-white-terms">
            <h2 className="black-and-white-heading">Terms & Conditions</h2>
            <div>
              <div className="black-and-white-content">
                <h5>CONTRACTUAL RELATIONSHIP</h5>
                <p>
                  These Terms of Use (“Terms”) govern the access or use by you, an
                  individual, of applications, websites, content, products, and
                  services (the “Services”) made available by EQUIP9™️ Internet
                  Private Limited (“EQUIP9™️”) a private limited company established
                  in India , having its registered office at A-304 Dange Chowk,
                  Pune, Maharashtra-411033 along with office in other states (in
                  future) with Corporate Identification Number U51909PN2019PTC187811
                  . PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING THE
                  SERVICES. Your access and use of the Services constitute your
                  agreement to be bound by these Terms, which establishes a
                  contractual relationship between you and EQUIP9™️. If you do not
                  agree to these Terms, you may not access or use the Services.
                  These Terms expressly supersede prior agreements or arrangements
                  with you. EQUIP9™️ may immediately terminate these Terms or any
                  Services with respect to you, or generally cease offering or deny
                  access to the Services or any portion thereof, at any time for any
                  reason. Supplemental terms may apply to certain Services, such as
                  policies for a particular event, activity or promotion, and such
                  supplemental terms will be disclosed to you in connection with the
                  applicable Services. Supplemental terms are in addition to, and
                  shall be deemed a part of, the Terms for the purposes of the
                  applicable Services. Supplemental terms shall prevail over these
                  Terms in the event of a conflict with respect to the applicable
                  Services. EQUIP9™️ may amend the Terms related to the Services
                  from time to time. Amendments will be effective upon EQUIP9™’s
                  posting of such updated Terms at this location or the amended
                  policies or supplemental terms on the applicable Service. Your
                  continued access or use of the Services after such posting
                  constitutes your consent to be bound by the Terms, as amended. Our
                  collection and use of personal information in connection with the
                  Services is as provided in EQUIP9™’s Privacy Policy located at &nbsp;
                  <a href="https://www.equip9.com/" target="_blank" className="terms-link" style={{ color: 'blue', textDecoration: 'underline' }}>
                    <i className="fas fa-envelope"></i>https://www.equip9.com
                  </a> . EQUIP9™️ may provide to a claims
                  processor or an insurer any necessary information (including your
                  contact information) if there is a complaint, dispute or conflict,
                  which may include a verbal spat or any physical fight, involving
                  you and a Third Party Provider (including an EQUIP9™️’s operators,
                  EQUIP9™️’s maintenance, and EQUIP9™️’s contractor) and such
                  information or data is necessary to resolve the complaint, dispute
                  or conflict.
                </p>
              </div>

              <div className="black-and-white-content">
                <h5>THE SERVICES</h5>
                <p>
                  The Services constitute a digital platform that enables users of
                  EQUIP9™️’s mobile applications or websites provided as part of the
                  Services (each, an “Application”) to arrange and schedule
                  workforce (operator, maintenance, and contractor) and/or
                  peripheral services such as an online portal for components and spare
                  parts needed for heavy equipment, rent, buy, sell portal for new
                  and used equipment, and virtual reality-based marketplace. Unless
                  otherwise agreed by EQUIP9™️ in a separate written agreement with
                  you, the Services are made available solely for your personal,
                  non-commercial use.
                </p>
              </div>

              <div className="black-and-white-content">
                <h5>LICENSE</h5>
                <p>
                  Subject to your compliance with these Terms, EQUIP9™️ grants you a
                  limited, non-exclusive, non-sublicensable, revocable,
                  non-transferable license to: (i) access and use the Applications
                  on your personal device solely in connection with your use of the
                  Services; and (ii) access and use any content, information, and
                  related materials that may be made available through the Services,
                  in each case solely for your personal, non-commercial use. Any
                  rights not expressly granted herein are reserved by EQUIP9™️ and
                  EQUIP9™️’s licensors.
                </p>
              </div>

              <div className="black-and-white-content">
                <h5>RESTRICTIONS</h5>
                <p>
                  You may not: (i) remove any copyright, trademark, or other
                  proprietary notices from any portion of the Services; (ii)
                  reproduce, modify, prepare derivative works based upon,
                  distribute, license, lease, sell, resell, transfer, publicly
                  display, publicly perform, transmit, stream, broadcast or
                  otherwise exploit the Services except as expressly permitted by
                  EQUIP9™️; (iii) decompile, reverse engineer or disassemble the
                  Services except as may be permitted by applicable law; (iv) link
                  to, mirror or frame any portion of the Services; (v) cause or
                  launch any programs or scripts for the purpose of scraping,
                  indexing, surveying, or otherwise data mining any portion of the
                  Services or unduly burdening or hindering the operation and/or
                  functionality of any aspect of the Services; or (vi) attempt to
                  gain unauthorized access to or impair any aspect of the Services
                  or its related systems or networks.
                </p>
              </div>

              <div className="black-and-white-content">
                <h5>THIRD PARTY SERVICES AND CONTENT</h5>
                <p>
                  The Services may be made available or accessed in connection with
                  third party services and content (including advertising) that
                  EQUIP9™️ does not control. You acknowledge that different terms of
                  use and privacy policies may apply to your use of such third-party
                  services and content. EQUIP9™️ does not endorse such third-party
                  services and content and in no event shall EQUIP9™️ be responsible
                  or liable for any products or services of such third-party
                  providers. Additionally, Apple Inc., Google, Inc., Microsoft
                  Corporation and/or their applicable international subsidiaries and
                  affiliates will be third-party beneficiaries of this contract if
                  you access the Services using Applications developed for Apple
                  iOS, Android, Microsoft Windows, or Blackberry-powered mobile
                  devices, respectively. These third-party beneficiaries are not
                  parties to this contract and are not responsible for the provision
                  or support of the Services in any manner. Your access to the
                  Services using these devices is subject to terms set forth in the
                  applicable third-party beneficiary’s terms of service. OWNERSHIP.
                  The Services and all rights therein are and shall remain
                  EQUIP9™️’s property or the property of EQUIP9™️ licensors. Neither
                  these Terms nor your use of the Services convey or grant to you
                  any rights: (i) in or related to the Services except for the
                  limited license granted above; or (ii) to use or reference in any
                  manner EQUIP9™️’s company names, logos, product and service names,
                  trademarks or services marks or those of EQUIP9™️’s licensors.
                </p>
              </div>

              <div className="black-and-white-content">
                <h5>PAYMENT</h5>
                <p>
                  You understand that use of the Services may result in charges to
                  you for the services or goods you receive from an operator,
                  maintenance, or contractor or Third-Party Provider’s equipment or
                  component and spare parts (“Charges”). After you have received
                  services or goods obtained through your use of the Service,
                  EQUIP9™️ will facilitate your payment of the applicable Charges on
                  behalf of the Third-Party Provider as such Third-Party Provider’s
                  limited payment collection agent. Payment of the Charges in such
                  manner shall be considered the same as payment made directly by
                  you to the Third-Party Provider. Charges will be inclusive of
                  applicable taxes where required by law. Charges paid by you are
                  final and non-refundable, unless otherwise determined by EQUIP9™️.
                  All Charges are due immediately, and payment will be facilitated by
                  EQUIP9™️ using the preferred payment method designated in your
                  Account, after which EQUIP9™️ will send you a receipt by email. If
                  your primary Account payment method is determined to be expired,
                  invalid, or otherwise not able to be charged, you agree that
                  EQUIP9™️ may, as the Third-Party Provider’s limited payment
                  collection agent, use a secondary payment method in your Account,
                  if available. As between you and EQUIP9™️, EQUIP9™️ reserves the
                  right to establish, remove and/or revise Charges for any or all
                  services or goods obtained through the use of the Services at any
                  time in EQUIP9™️’s sole discretion. Further, you acknowledge and
                  agree that Charges applicable in certain geographical areas may
                  increase substantially due to remote areas and the nature of work.
                  EQUIP9™️ will use reasonable efforts to inform you of Charges that
                  may apply, provided that you will be responsible for Charges
                  incurred under your Account regardless of your awareness of such
                  Charges or the amounts thereof. EQUIP9™️ may from time to time
                  provide certain users with promotional offers, subscriptions, and
                  discounts that may result in different amounts charged for the
                  same or similar services or goods obtained through the use of the
                  Services, and you agree that such promotional offers,
                  subscriptions, and discounts, unless also made available to you,
                  shall have no bearing on your use of the Services or the Charges
                  applied to you. You may elect to cancel your request for services
                  or goods from an operator, maintenance, or contractor or
                  Third-Party Provider’s equipment or component and spare parts at
                  any time prior to such operator, maintenance, or contractor or
                  Third-Party Provider’s equipment or component and spare parts
                  arrival on site, in which case you may be charged a cancellation
                  fee. This payment structure is intended to fully compensate the
                  operator, maintenance, or contractor or Third-Party Provider’s
                  equipment or component and spare parts for the services or goods
                  provided. Apart from payment of services to an operator, maintenance,
                  or contractor or Third-Party Provider’s equipment or component and
                  spare parts, You understand and agree that, while you are free to
                  provide additional payment as a gratuity to any Third Party
                  Provider who provides you with services or goods obtained through
                  the Service, you are under no obligation to do so. Gratuities are
                  voluntary. After you have received services or goods obtained
                  through the Service, you will have the opportunity to rate your
                  experience and leave additional feedback about your operator,
                  maintenance, or contractor or Third-Party Provider’s equipment or
                  component and spare parts.
                </p>
              </div>

              <div className="black-and-white-link">
                <a href="https://equip9.com/contacts.html" className="terms-link" style={{ color: 'blue', textDecoration: 'underline' }}>
                  <i className="fas fa-envelope"></i>contact@equip9.com
                </a>
              </div>
            </div>
          </div>
        </MidSection>
      </SideMenuLayout>
    </>
  );
};

export default TermsAndCondition;
