import { createSlice } from "@reduxjs/toolkit";
// import { getSessionStorageItems, setSessionStorageItems } from "../../../../../../helpers/sessionStorage/sessionStorage";
import { getSessionStorageItems,setSessionStorageItems } from "../../../../../../../helpers/sessionStorage/sessionStorage";
import { object } from "prop-types";
// import { initialState } from "../../../../../../features/rentalEquipmentMarketplace/constants/initialState";
import { initialState } from "../../../../../../../features/rentalEquipmentMarketplace/constants/initialState";


export const editPostMaterialSlice = createSlice({
    name: "EditpostMaterials",
    initialState : {
        editStepperIndex: getSessionStorageItems("EDIT_POST_MATERIAL_STEPPER_INDEX") ? parseInt(getSessionStorageItems("EDIT_POST_MATERIAL_STEPPER_INDEX")) : 0,
        editStepperRoute: [{ name: "Step 1" }, { name: "Step 2" }],
    },

    reducers : {
        editIncreaseStepperIndex: (state) => {
            if(state. editStepperRoute.length <= state.editStepperIndex){
              return
            }
            state.editStepperIndex += 1;
            setSessionStorageItems(
              "EDIT_POST_MATERIAL_STEPPER_INDEX",
              (state.editStepperIndex)
            );
          },
          editDecreaseStepperIndex: (state) => {
            if (state.editStepperIndex === 0) {
              return;
            }
            if (state.editStepperIndex < 0) {
              state.editStepperIndex = 0;
            }
            state.editStepperIndex -= 1;
            setSessionStorageItems(
              "EDIT_POST_MATERIAL_STEPPER_INDEX",
              state.editStepperIndex
            );
          },
          editClearStepperIndex : (state) =>{
            state.editStepperIndex = 0
          },
          editClearMaterialData:(state)=>{
            Object.assign(state,initialState)
          }
    }
})

export const {
    editIncreaseStepperIndex,
    editDecreaseStepperIndex,
    editClearStepperIndex,
    editClearMaterialData
} = editPostMaterialSlice.actions