import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { subString } from "../../../../../webapp/helpers/dataConversion/substring";

export const OpenJobMarketplaceCard = ({
  jobId = "",
  postedOn = "",
  name = "",
  location = "",
  money = "NA",
  cycle = "NA",
  onClick = () => {},
}) => {
  return (
    <Box cursor={"pointer"}
    w={"410px"}
    maxW={"410"}
    h={"200px"}
    maxH={"200px"}
      onClick={(e) => {
        onClick();
        e.stopPropagation();
      }}
    >
      <Flex
        boxShadow={"0px 0px 12px 0px #00000029"}
        padding={"14px 0px 14px 12px"}
        borderRadius={"6px"}
        minW={"410px"}
        gap={"10px"}
        flexDirection={"column"}
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#767676"}
      >
        <Text fontSize={"16px"} fontWeight={"700"} color={"#2C2C2C"}>
          Job Id: {jobId}
        </Text>
        <Text>Posted on: {postedOn}</Text>
        <Tooltip label={name?.length > 30 ? name : null}>
        <Text fontSize={"18px"} fontWeight={"700"} color={"#2C2C2C"}>
          Job Name: {subString(30,name)}
        </Text>
        </Tooltip>
        <Text>Job Location: {location}</Text>
        <Text fontSize={"18px"} fontWeight={"700"} color={" #002961"}>
          {money} / {cycle}
        </Text>
      </Flex>
    </Box>
  );
};
