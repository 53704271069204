import React, { useEffect, useState } from 'react';


export const NativeCarousel = ({
  images = [],
  redirectionUrl=[],
  height,
  width,
  speed = 1000,
  animation, 
  hideButtons = false,
  carouselStyle: initialCarouselStyle,
  imgContainerStyle: initialImgContainerStyle,
  imgStyle: initialImgStyle,
  buttonStyle: initialButtonStyle,
  prevButtonStyle: initialPrevButtonStyle,
  nextButtonStyle: initialNextButtonStyle,

}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  /**
   * @function for Auto Slide for Images 
   */
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, speed);

    return () => {
      clearInterval(interval);
    };
  }, [images, speed]);

  const nextSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  /**
   * Default Css Values 
   */

  const defaultCarouselStyle = {
    height: height || '100vh',
    width: width || '100vw',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position : 'relative'
  };

  const defaultImageContainerStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    backgroundColor: "#2c2c2c05",
    borderRadius: "1em"
  }

  const defaultImgStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    borderRadius: "0.25em"  
  };

  const defaultButtonStyle = {
    position: 'absolute', 
    backgroundColor: 'none',
    color: 'white',
    padding: '10px',
    cursor: 'pointer',
    display: hideButtons ? 'none' : 'block' 
  };

  const defaultPrevButtonStyle = {
    ...defaultButtonStyle,
    left: 0,
  };

  const defaultNextButtonStyle = {
    ...defaultButtonStyle,
    right: 0,
  };

  /**
   * Spread default and User received values
   */

  const mergedCarouselStyle = { ...defaultCarouselStyle, ...initialCarouselStyle };
  const mergedImgContainerStyle = {...defaultImageContainerStyle, ...initialImgContainerStyle}
  const mergedImgStyle = { ...defaultImgStyle, ...initialImgStyle };
  const mergedButtonStyle = { ...defaultButtonStyle, ...initialButtonStyle };
  const mergedPrevButtonStyle = { ...defaultPrevButtonStyle, ...initialPrevButtonStyle };
  const mergedNextButtonStyle = { ...defaultNextButtonStyle, ...initialNextButtonStyle };

  return (
    <>
      <div style={mergedCarouselStyle} >
          <div className='carousel-body-button-prev'>
            <button onClick={prevSlide} style={mergedPrevButtonStyle}>
              <div className='carousel-body-icon-btn' style={{maxHeight:"30px", maxWidth:"30px"}}>
              <img  src={ `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/LeftArrow.svg`} alt='LeftArrow' style={{height:"100%" , width:"100%"}}/> 
              </div>
            </button>
          </div>

          <div className='carousel-body-img' style={mergedImgContainerStyle}>
        <img
          src={images[activeIndex]}
          alt={`Slide ${activeIndex}`}
          style={mergedImgStyle}
          onClick={()=>{
            if (redirectionUrl.length > 0) {
              window.open(redirectionUrl[activeIndex], '_blank');
            }
          }}
        />
      </div>
          <div className='carousel-body-button-next'>
            <button onClick={nextSlide} style={mergedNextButtonStyle}>
            <div className='carousel-body-icon-btn' style={{maxHeight:"30px", maxWidth:"30px"}}>
              <img src={ `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/RightArrow.svg`} alt='LeftArrow' style={{height:"100%" , width:"100%"}}/> 
              </div>
            </button>
          </div>      
      </div>


    </>
  );
};

