import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { QuestionOutlineIcon } from "@chakra-ui/icons"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import logo from "../../../assets/logo/equip9_logo_dark.png"
import { handleAccountsList, selectedAccount } from "../../features/login/slice/loginSlice"
import { setIsAuthenticated, setToken } from "../../features/global/user/slice/userSlice"
import { getDefaultAppLanguage } from "../../features/global/mtData/helpers/localStorage";

export const V5Navbar = ({height= "96px"}) => {
    const loginData = useSelector((state) => state.login);
    const userData = useSelector((state) => state.user)
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const defaultLanguage = getDefaultAppLanguage();
    
    const logoutClickHandler = () => {
     dispatch(selectedAccount(null));
     dispatch(handleAccountsList(null));
     dispatch(setToken(""));
     dispatch(setIsAuthenticated(""));
     navigate("/welcome/select-language");
    }
return(
    <Box width="100%" padding="1.5em 2em" h={height}>
        <Flex width="100%" justifyContent="space-between">
            <Link to='/'>
            <Image src={logo} w="100px" h="55px" />
            </Link>
        
         <Flex alignItems="center" gap="0.5em"> 
            
            <Link to="/get-help/get-help-form">
                <div  style={{display:'flex', flexDirection:'row'}} >
                <Text color="#1E3264" paddingBottom="0px" mb="0" fontSize="20px">{defaultLanguage?.Get_Help}</Text>
                <div style={{marginTop:'4px', marginLeft:'5px'}}>
                <QuestionOutlineIcon/>
                </div>
                
                </div>
                </Link>
           {userData.isAuthenticated === true && location.pathname.split("/")[1] === "dashboard" ? <Button bgColor="#D60000" color="#fff" onClick={logoutClickHandler}>{defaultLanguage?.Logout}</Button> : <></>}
         </Flex>
        </Flex>
    </Box>
)
}