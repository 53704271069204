import { API_CONSTANTS } from "../../../constants/apiConstants";
import { convertToIdValuePairs } from "../../helpers/dataConversion/convertToIdValuePairs";
import {fetchHandler} from "../../../index"


export const getEngineMakes = async () => {
  try {
    const { data } = await fetchHandler(
      API_CONSTANTS.GET_EQUIPMENT_ENGINE_MAKER_LIST
    );
    if (data.Status === 200 && Object.keys(data.Data).length > 0) {
      const requiredData = data.Data.GetEngineMakerList.map(
        ({ EquipmentEngineMakeId, EquipmentEngineMakeValue }) =>
          convertToIdValuePairs(EquipmentEngineMakeId, EquipmentEngineMakeValue)
      );
      return requiredData;
    }
    return null;
  } catch (error) {
    throw error;
  }
};



export const getFuelTypes = async () => {
  try {
    const { data } = await fetchHandler(API_CONSTANTS.GET_FUEL_TYPE_LIST);
    if (data.Status === 200 && Object.keys(data.Data.length > 0)) {
      const requiredData = data.Data.GetFuelTypeList.map(
        ({ FuelTypeId, FuelTypeValue }) =>
          convertToIdValuePairs(FuelTypeId, FuelTypeValue)
      );
      return requiredData;
    }
    return null;
  } catch (error) {
    throw error;
  }
};

export const getAutoEngineShutDownTypes = async () => {
  try {
    const { data } = await fetchHandler(
      API_CONSTANTS.GET_AUTO_ENGINE_SHUTDOWN_LIST
    );
    if (data.Status === 200 && Object.keys(data.Data)) {
      const requiredData = data.Data.EquipmentAutoEngineShutdownStatus.map(
        ({ EquipmentAutoEngineShutdownId, EquipmentAutoEngineShutdownValue }) =>
          convertToIdValuePairs(
            EquipmentAutoEngineShutdownId,
            EquipmentAutoEngineShutdownValue
          )
      );
      return requiredData;
    }
    return null;
  } catch (error) {
    throw error;
  }
};
