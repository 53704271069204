import { createAsyncThunk } from "@reduxjs/toolkit";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";
import { getMaterialMarketplaceList } from "../../../services/materials/materials";

export const concreteInitialDataThunk = createAsyncThunk("concrete/getInitialCards", async(_, {getState}) => {

    const concreteId = convertToBase64('3')
    const cardCount = getState().concreteMaterialMarketplace.cardCount

try{
  
    const data = await getMaterialMarketplaceList(cardCount,0,concreteId)

    
    if(data.Status === 200 && Object.keys(data.Data).length > 0){
        return data.Data
    }

   
}
catch(error){
}
})