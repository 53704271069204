import { createSlice } from "@reduxjs/toolkit";
import { dealershipInitialState } from "../constants/dealershipInitialSlice";
import { dealershipListingInitialThunk } from "../thunk/dealershipListingInitialThunk";
import { dealershipListingCompanyInitialThunk } from "../thunk/dealershipListingCompanyInitialThunk ";
import { dealershipListingCompanyLoadMoreThunk } from "../thunk/dealershipListingCompanyLoadMoreThunk";
import { dealershipListingLoadMoreThunk } from "../thunk/dealershipListingLoadMoreThunk";




export const myBuisinessDealershipAssetSlice = createSlice({
    name:"dealershipAssets",
    initialState: dealershipInitialState,
    reducers:{
        setData: (state,{payload})=>{
            state.data = payload
        }
    },

    extraReducers:(builder) =>{
        builder
        // UEM Marketplce
        .addCase(dealershipListingInitialThunk.pending, (state)=>{
            state.data = null;
            state.initialLoading = true;
            state.paginationLoading = false;
            state.error = false;
            state.errorMessage = false;
            state.paginationCount = 1;
            state.endOfData = false
        })
        .addCase(dealershipListingInitialThunk.fulfilled,(state,{payload}) =>{
            state.paginationCount = 1;
            state.initialLoading = false;
            state.error = false;
            state.errorMessage = null;
            state.data = payload;
            // state.assetImageReference = payload
            if(payload?.length < state.cardCount){
              state.endOfData = true
            }
        })

        .addCase(dealershipListingLoadMoreThunk.pending, (state)=>{
            state.paginationLoading = true;
            state.error = false ;
            state.errorMessage = null;
         })
         .addCase(dealershipListingLoadMoreThunk.fulfilled, (state,{payload})=>{
            state.error = false ;
            state.errorMessage = null ;
            state.paginationLoading = false;
            // state.assetImageReference = payload?.data?.AssetImageBaseReference
            if(payload?.length >= state.cardCount){
                state.data = [...state?.data , ...payload];
                state.paginationCount += 1;
            }else{
                state.endOfData = true;
         }
         })

        //  Company
        .addCase(dealershipListingCompanyInitialThunk.pending, (state)=>{
            state.data = null;
            state.initialLoading = true;
            state.paginationLoading = false;
            state.error = false;
            state.errorMessage = false;
            state.paginationCount = 1;
            state.endOfData = false
        })
        .addCase(dealershipListingCompanyInitialThunk.fulfilled,(state,{payload}) =>{
            state.paginationCount = 1;
            state.initialLoading = false;
            state.error = false;
            state.errorMessage = null;
            state.data = payload;
            // state.assetImageReference = payload?.data?.AssetImageBaseReference
            if(payload?.length < state.cardCount){
              state.endOfData = true
            }
        })

        .addCase(dealershipListingCompanyLoadMoreThunk.pending, (state)=>{
            state.paginationLoading = true;
            state.error = false ;
            state.errorMessage = null;
         })
         .addCase(dealershipListingCompanyLoadMoreThunk.fulfilled, (state,{payload})=>{
            state.error = false ;
            state.errorMessage = null ;
            state.paginationLoading = false;
            // state.assetImageReference = payload?.data?.AssetImageBaseReference
            if(payload?.length >= state.cardCount){
                state.data = [...state?.data , ...payload];
                state.paginationCount += 1;
            }else{
                state.endOfData = true;
         }
         })
    }
})

export const {
    setData
}= myBuisinessDealershipAssetSlice.actions;