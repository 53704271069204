import { getSessionStorageItems } from "../../../helpers/sessionStorage/sessionStorage";

export const initialState = {
    data: null,
    filteredDataRequired: false,
    filterModalOpen: false,
    filterByCountry: "",
    filterByState: "",
    filterByEquipmentTypeId: "",
    filterByEquipmentMakeId: "",
    filterByIsOriginal: "",
    paginationCount: 0,
    endOfData: false,
    cardCount: 12,
    initialLoading: false,
    paginationLoading: false,
    error: null,
    errorMessage: null,
    imageRefrenceUrl : null,
    videoList : getSessionStorageItems("MATERIALS_VIDEO_LIST"),
    location : getSessionStorageItems("MATERIALS_LOCATION"),
    responseTypeId : getSessionStorageItems("MATERIALS_RESPONSE"),
    likeListData:null,
    likedData:[]
  };
  