import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFreeLeadsAvailableForCompany } from "../../../services/freeLeads/freeLeads";

export const getFreeLeadsForCompanyThunk = createAsyncThunk(
  "/free-leads/company",
  async (_, { rejectWithValue, getState }) => {
    const {accountDetails} = getState().user
    try {
        
      const data = await getFreeLeadsAvailableForCompany(accountDetails.CompanyDetails.TaxNumber);
      return data
     
    } catch (error) {
      rejectWithValue(error);
      throw error;
    }
  }
);
