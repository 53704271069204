import { API_CONSTANTS } from "../../../constants/apiConstants"
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData"
import fetchHandler from "../fetchHandler"

export const sendRentalDurationRequest = async(body) => {
 try{
    const {data} = await fetchHandler("/PostRentalDuration", {}, {}, convertToFormData(body), API_CONSTANTS.HTT_POST) 
    if(data.Status === 201){
        return data;
    }
    else{
        const error = new Error();
        error.message = data.Message.FailMessage
        throw error
    }
 }
 catch(error){
    throw error
 }
}

export const sendLikeRequestToEquipment = async(body = {}) => {
    try{
        if(!body){
            throw new Error("Body is required.")
        }
        const convertBody = [];
        for(const key in body){
            convertBody.push(`${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`)
        }
        const requiredBody = convertBody.join("&")
        const {data} = await fetchHandler("/LikeUnlikeE9MarketPlaceAsset", {}, {"Content-Type" : "application/x-www-form-urlencoded"}, requiredBody, API_CONSTANTS.HTTP_PATCH);
        return data
    }
    catch(error){
        throw error
 }
    
}