import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "@chakra-ui/icons";
import {
  closeVirtualDealershipModal,
  setIndustryId,
  setOemId,
} from "../../features/MyBusinessDealershipDetail/slice/myBusinessDealershipSlice";

import "./DealershipPopUpCss.css";
import { getIndustryTypeList } from "../../services/postDealership/postDealership";
import { v4 as uuid } from "uuid";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { convertToBase64 } from "../../../utils/formatConverter/convertToBase64";
import { getEquipmentTypeList } from "../../services/equipments/equipments";
import { CustomInputModal } from "../CustomInputModal/CustomInputModal";

export const VirtualDealershipPopUp = () => {
  const dispatch = useDispatch();
  const postDealershipData = useSelector((state) => state.myBusinessDealership);
  const userData = useSelector((state) => state.user);
  const { serviceTypeId } = useParams();

  const [equipmentMakeList, setEquipmentMakeList] = useState();
  const [industryTypeList, setIndustryTypeList] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getEquipmentTypeList();
        if (data?.Status === 200) {
          setEquipmentMakeList(data?.Data?.EquipmentMakeList);
        }
      } catch (error) {
        // Handle errors if needed
        // console.error("Error fetching equipment makes:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getIndustryTypeList(userData.token);
        if (response?.data?.Data?.Success === true) {
          setIndustryTypeList(response?.data?.Data?.IndustryTypeList);
        }
      } catch (error) {
        // Handle errors if needed
        // console.error("Error fetching industry types:", error);
      }
    };

    fetchData();
  }, []);

  // console.log("equipmentMakeList", equipmentMakeList);
  // console.log("industryTypeList", industryTypeList);

  const handleSubmitVirtualClick = (values) => {
    // console.log("Virtual Clicked", values);
    dispatch(setOemId(values.oemId));
    dispatch(setIndustryId(values.industryId));
    dispatch(closeVirtualDealershipModal());
    navigate(`/my-business/your-dealerships/${convertToBase64(serviceTypeId)}`);
  };

  const initialValues = {
    oemId: "",
    industryId: "",
  };

  const validationSchema = Yup.object().shape({
    oemId: Yup.string().required("OEM ID Is Required"),
    industryId: Yup.string().required("Industry Is Required"),
  });

  return (

    <CustomInputModal
      isOpen={postDealershipData.isVirtualDealershipOpen}
      hasCloseButton={true}
      onCloseButtonClick={() => {
        dispatch(closeVirtualDealershipModal());
      }}
      icon={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/connectIcon.svg`}
      heading={"Virtual Dealership"}
      primaryActionText={"Next"}
    >
      <Flex direction={"column"} justifyContent={"center"} alignItems={"center"}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitVirtualClick}
      >
        {({ values, setFieldValue }) => (
          <Form id="inputForm" >
          
          <Flex direction={"column"} gap={"8px"} maxW={"350px"} w={"350px"} >
              <div>
                <FormLabel color={"#141619"} fontWeight={"600"} fontSize={"14px"} >Select OEM <p style={{color:"red",display:"inline-block"}}>*</p></FormLabel>
                <Field
                  as={Select}
                  name="oemId"
                  onChange={(e) => {
                    setFieldValue("oemId", e.target.value);
                  }}
                  fontWeight={"600"} fontSize={"16px"}
                  color={"#2c2c2c"}
                >
                  <option value="" w={"350px"} maxW={"350px"} h={"44px"}>Select OEM</option>
                  {equipmentMakeList
                    ? equipmentMakeList.map((equipment) => (
                        <option key={uuid()} value={equipment.EquipmentMakeId} w={"350px"} maxW={"350px"} h={"44px"}>
                          {equipment.EquipmentMakeValue}
                        </option>
                      ))
                    : null}
                </Field>
                <ErrorMessage
                  name="oemId"
                  component="div"
                  className="error-message"
                />
              </div>
              <div>
                <FormLabel color={"#141619"} fontWeight={"600"} fontSize={"14px"} >Select Industry <p style={{color:"red",display:"inline-block"}}>*</p></FormLabel>
                <Field
                  as={Select}
                  name="industryId"
                  onChange={(e) => {
                    setFieldValue("industryId", e.target.value);
                  }}
                  fontWeight={"600"} fontSize={"16px"}
                  color={"#2c2c2c"}
                >
                  <option value="" w={"350px"} maxW={"350px"} h={"44px"}>Select Industry</option>
                  {industryTypeList
                    ? industryTypeList.map((industry) => (
                        <option key={uuid()} value={industry.IndustryTypeId} w={"350px"} maxW={"350px"} h={"44px"}>
                          {industry.IndustryTypeValue}
                        </option>
                      ))
                    : null}
                </Field>
                <ErrorMessage
                  name="industryId"
                  component="div"
                  className="error-message"
                />
              </div>
              </Flex>
          </Form>
        )}
      </Formik>
      </Flex>
    </CustomInputModal>
  );
};
