import * as Yup from "yup"
import { REGEX_EMAIL_CHECKER } from "../../../../../../helpers/regex"

export const getHelpFormSchemaValidation = Yup.object({
    name : Yup.string().required("Required").max(30, "Maximum 30 characters allowed"),
    mobileNumber : Yup.string().required("Mobile Number Required").length(10, "Exactly 10 digits allowed"),
    countryCode: Yup.string().required("Country Code required"),
    subject: Yup.string().required("Required").min(10, "Minimum 10 characters required").max(50, "Maximum 50 characters allowed"),
    description: Yup.string().required("Required").min(20, "Minimum 20 characters allowed").max(200, "Maximim 200 characters allowed"),
    email: Yup.string().required("Required").matches(REGEX_EMAIL_CHECKER, "Invalid email")
 })