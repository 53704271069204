import {
  Flex,
  Modal,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  Image,
  Divider,
  Checkbox,
  Button,
  ModalCloseButton,
  CloseButton,
  useToast,
} from "@chakra-ui/react";
import checkedIcon from "../../../../assets/webapp/pop-up/pop-up-checked-icon.svg";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchHandler } from "../../../..";
import { API_CONSTANTS } from "../../../../constants/apiConstants";
import { convertToFormData } from "../../../helpers/dataConversion/convertToFormData";

export const LeadsPopup = ({
  isOpen = false,
  subscriptionCount = 0,
  freeLeadsCount = 0,
  premiumCount = 0,
  currentSelectionCount = 0,
  totalAmount = 0,
  onCloseButtonClick = () => {},
  id = null,
  selectedArray = null,
  jobId = null,
  preimumData = null,
  subscriptionData = null,
  temp_selectedListArray = []
}) => {
  const [isPremiumChecked, setIsPremiumChecked] = useState(false);
  const [isFreeChecked, setIsFreeChecked] = useState(false);
  const [isSubscriptionChecked, setIsSubscriptionChecked] = useState(false);
  const { currencyDetails , accountDetails} = useSelector((state) => state.user);
 const E9FreeRedeemedId =  sessionStorage.getItem("LastE9FreeRedeemedId");
 const toast = useToast();

  const submitFree = async (payload) => {
    try{
      const {data} = await fetchHandler(
        "/CreateE9FreeRedeemedTransaction",
        {},
        {},
        convertToFormData(payload),
        API_CONSTANTS.HTT_POST,
        {isAuthRequired : true}
      )

      if(data?.Status === 201){
        sessionStorage.setItem("LastE9FreeRedeemedId", data?.Data?.LastE9FreeRedeemedId);
        const transactionId = data?.Data?.LastE9FreeRedeemedId ;
        const raw_payload_EP = []; // Entity Payment
        const raw_payload_EC = []; // Entity Confirm

        if (selectedArray && selectedArray.length > 0) {
          selectedArray.forEach((data) => {
            raw_payload_EP.push({
              TransactionId: transactionId, // Last ID
              EntityId: jobId, // Job ID
              PartnerUserCredentialId: data, //OperatorPartnerId
              ServiceTypeId: id, 
              TransactionModeId: 3,
            });

            raw_payload_EC.push({
              TransactionId: transactionId,
              EntityId: jobId,
              JobCurrentStatusId: 2,
              PartnerUserCredentialId: data,
            });
          });
        }

        const response = await fetchHandler(
          "/CreateUserEntityPaymentDetails",
          {},
          {},
          JSON.stringify(raw_payload_EP),
          API_CONSTANTS.HTT_POST,
          {isAuthRequired: true}
        )

        const response2 = await fetchHandler(
          "/CreateEntityConfirmedDetails",
          {},
          {},
          JSON.stringify(raw_payload_EC),
          API_CONSTANTS.HTT_POST,
          {isAuthRequired: true}
        )

        toast({
          title : 'Partner Confirmed Sucessfully!',
          position: 'top-right',
          isClosable : true,
          status : 'success'
        })
        window.location.reload();
      }
    }catch(error){
      toast({
        title : 'Error Confirming Partner!',
        position: 'top-right',
        isClosable : true,
        status : 'error'
      })
      window.location.reload();
    }
  }
  const submitSubscription = async (payload) => {
    // Balance Contact = current selection
    try{
      const {data} = await fetchHandler(
        "/CreateE9RedeemedTransactionDetails",
        {},
        {},
        JSON.stringify(payload),
        API_CONSTANTS.HTT_POST,
        {isAuthRequired : true}
      )

      if(data?.Status === 201){
        sessionStorage.setItem("LastE9FreeRedeemedId", data?.Data?.LastE9FreeRedeemedId);
        const transactionId = data?.Data?.LastE9RedeemedTransactionId ;
        const raw_payload_EP = []; // Entity Payment
        const raw_payload_EC = []; // Entity Confirm

        if (selectedArray && selectedArray.length > 0) {
          selectedArray.forEach((data) => {
            raw_payload_EP.push({
              TransactionId: transactionId, // Last ID
              EntityId: jobId, // Job ID
              PartnerUserCredentialId: data, //OperatorPartnerId
              ServiceTypeId: id, 
              TransactionModeId: 3,
            });

            raw_payload_EC.push({
              TransactionId: transactionId,
              EntityId: jobId,
              JobCurrentStatusId: 2,
              PartnerUserCredentialId: data,
            });
          });
        }

        const response = await fetchHandler(
          "/CreateUserEntityPaymentDetails",
          {},
          {},
          JSON.stringify(raw_payload_EP),
          API_CONSTANTS.HTT_POST,
          {isAuthRequired: true}
        )

        const response2 = await fetchHandler(
          "/CreateEntityConfirmedDetails",
          {},
          {},
          JSON.stringify(raw_payload_EC),
          API_CONSTANTS.HTT_POST,
          {isAuthRequired: true}
        )

        toast({
          title : 'Partner Confirmed Sucessfully!',
          position: 'top-right',
          isClosable : true,
          status : 'success'
        })
        window.location.reload();
      }
    }catch(error){
      toast({
        title : 'Error Confirming Partner!',
        position: 'top-right',
        isClosable : true,
        status : 'error'
      })
      window.location.reload();
    }
  }
  const submitPremium = async (payload) => {
    try{
      const {data} = await fetchHandler(
        "/PostE9PremiumRedeem",
        {},
        {},
        convertToFormData(payload),
        API_CONSTANTS.HTT_POST,
        {isAuthRequired : true}
      )

      if(data?.Status === 201){
        sessionStorage.setItem("LastE9FreeRedeemedId", data?.Data?.LastE9FreeRedeemedId);
        const transactionId = data?.Data?.E9PremiumRedeemId ;
        const raw_payload_EP = []; // Entity Payment
        const raw_payload_EC = []; // Entity Confirm

        if (selectedArray && selectedArray.length > 0) {
          selectedArray.forEach((data) => {
            raw_payload_EP.push({
              TransactionId: transactionId, // Last ID
              EntityId: jobId, // Job ID
              PartnerUserCredentialId: data, //OperatorPartnerId
              ServiceTypeId: id, 
              TransactionModeId: 4,
            });

            raw_payload_EC.push({
              TransactionId: transactionId,
              EntityId: jobId,
              JobCurrentStatusId: 2,
              PartnerUserCredentialId: data,
            });
          });
        }

        console.log("selectedArray",selectedArray);


        const response = await fetchHandler(
          "/CreateUserEntityPaymentDetails",
          {},
          {},
          JSON.stringify(raw_payload_EP),
          API_CONSTANTS.HTT_POST,
          {isAuthRequired: true}
        )

        const response2 = await fetchHandler(
          "/CreateEntityConfirmedDetails",
          {},
          {},
          JSON.stringify(raw_payload_EC),
          API_CONSTANTS.HTT_POST,
          {isAuthRequired: true}
        )

        toast({
          title : 'Partner Confirmed Sucessfully!',
          position: 'top-right',
          isClosable : true,
          status : 'success'
        })
        window.location.reload();
      }
    }catch(error){
      toast({
        title : 'Error Confirming Partner!',
        position: 'top-right',
        isClosable : true,
        status : 'error'
      })
      window.location.reload();
    }
  }
  const handleCheckout = async() => {
    if(isFreeChecked){
      const payload = {
        "ServiceTypeId" : id ,
        "FreeLeadConsumed" :  currentSelectionCount
      }

      submitFree(payload);
    }else if(isSubscriptionChecked){
      let subRedeemedDetails = [];

      console.log(subscriptionData)
      subRedeemedDetails.push({
        E9TransactionId: subscriptionData?.E9TransactionId,
        E9RedeemedTransactionId: subscriptionData?.E9RedeemedTransactionId,
        UserSubsServicePurchasedId: subscriptionData?.UserSubsServicePurchasedId,
        PurchasedCount: subscriptionData?.PurchasedCount,
        LeadRedeemed: currentSelectionCount,
        LeadRemaining: subscriptionData?.LeadRemaining - currentSelectionCount,
        DaysRemaining: subscriptionData?.DaysRemaining,
        redeemedListIds: temp_selectedListArray,
      });
      const payload = {
        TransactionId: subscriptionData?.E9TransactionId,
        UserSubsServicePurchasedId: subscriptionData?.UserSubsServicePurchasedId,
        ServiceId: subscriptionData?.ServiceTypeId,
        LeadPurchased: subscriptionData?.PurchasedCount,
        LeadRedeemed: subscriptionData?.LeadRedeemed,
        LeadRemaining: subscriptionData?.LeadRemaining
      }

      submitSubscription(payload);

    }else {
      const payload = {
        "E9PremiumTransactionDetailsId" : preimumData?.LeadsData?.E9PremiumTransactionDetailsId,
        "ServiceTypeId" : preimumData?.LeadsData?.ServiceTypeId,
        "PaymentPlanId" : preimumData?.LeadsData?.PaymentPlanId,
        "UniqueRefId" : selectedArray,
        "UserCredentialId" : accountDetails?.UserDetails?.CredentialDetails?.UserCredentialId,
        "RedeemCount" : currentSelectionCount
      }
      submitPremium(payload);
    }
  }

  return (
    <Modal isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <CloseButton alignSelf="flex-end" onClick={onCloseButtonClick} />

        <ModalHeader>
          <Flex
            flexDirection="column"
            gap="0.5rem"
            justifyContent="center"
            alignItems="center"
            paddingTop="1rem"
          >
            <Image src={checkedIcon} height="42px" width="82px" />
            <Text fontWeight={700} fontSize="24px" color="#1A1E21">
              How would you like to proceed?
            </Text>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Flex flexDirection="column" gap="1rem">
            <Flex justifyContent="space-between">
              <Text fontWeight={700} color="#1A1E21">
                Current Selection
              </Text>
              <Text>{currentSelectionCount}</Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text fontWeight={700} color="#1A1E21">
                Your Free Contacts
              </Text>
              <Text>{freeLeadsCount}</Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text fontWeight={700} color="#1A1E21">
                Your Remaining Subscription
              </Text>
              <Text>{subscriptionCount}</Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text fontWeight={700} color="#1A1E21">
                Your Premium Available
              </Text>
              <Text>{premiumCount}</Text>
            </Flex>

            <Divider />
            <Flex justifyContent="space-between">
              <Text fontWeight={700} color="#002961">
                Net Payable Contacts
              </Text>
              <Text>0</Text>
            </Flex>
            <Divider />

            <Text fontWeight={700}>Choose any one payment option:</Text>

            <Flex flexDirection="column" gap="1rem">
              <Checkbox
                colorScheme="green"
                isDisabled={freeLeadsCount === 0}
                isChecked={isFreeChecked}
                onChange={(e) => {
                  if (e.target.checked) {
                    setIsFreeChecked(true);
                    setIsPremiumChecked(false);
                    setIsSubscriptionChecked(false);
                  } else {
                    setIsFreeChecked(false);
                  }
                }}
              >
                <Text
                  color={isFreeChecked ? "#319F00" : "#002961"}
                  fontWeight={700}
                >
                  Use Free Contacts
                </Text>
              </Checkbox>

              <Checkbox
                colorScheme="green"
                isDisabled={premiumCount === 0}
                isChecked={isPremiumChecked}
                onChange={(e) => {
                  if (e.target.checked) {
                    setIsFreeChecked(false);
                    setIsPremiumChecked(true);
                    setIsSubscriptionChecked(false);
                  } else {
                    setIsPremiumChecked(false);
                  }
                }}
              >
                <Text
                  color={isPremiumChecked ? "#319F00" : "#002961"}
                  fontWeight={700}
                >
                  Use Premium Contacts
                </Text>
              </Checkbox>

              <Checkbox
                colorScheme="green"
                isDisabled={subscriptionCount === 0}
                isChecked={isSubscriptionChecked}
                onChange={(e) => {
                  if (e.target.checked) {
                    setIsFreeChecked(false);
                    setIsPremiumChecked(false);
                    setIsSubscriptionChecked(true);
                  } else {
                    setIsSubscriptionChecked(false);
                  }
                }}
              >
                <Text
                  color={isSubscriptionChecked ? "#319F00" : "#002961"}
                  fontWeight={700}
                >
                  Use Contact Based
                </Text>
              </Checkbox>
            </Flex>

            <Flex justifyContent="space-between" alignItems="center">
              <Text color="#002961" fontWeight={700}>
                Total Amount
              </Text>
              <Text fontSize={"24px"} fontWeight={700} color="#002961">
                {currencyDetails.CurrencyValue} {totalAmount.toFixed(2)}
              </Text>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter
          justifyContent="center"
          boxShadow={"0px -10px 15px 0 #2c2c2c25"}
        >
          <Button variant="gradient" onClick={ ()=> handleCheckout()} isDisabled={(!isFreeChecked) && (!isSubscriptionChecked) && (!isPremiumChecked)}>Checkout</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
