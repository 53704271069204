export const getReponseTypeByResponseId = (
    responseTypeDetails = {},
    responseTypeId
  ) => {
  
    switch (responseTypeId) {
      case null:
        return "Contact Seller";
      default:
        return responseTypeDetails[responseTypeId.toString()]==="Request Revoked" ? "Contact Seller" :responseTypeDetails[responseTypeId.toString()];
    }
  };