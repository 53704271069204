import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFilteredPartners } from "../../../services/jobsPosted/jobsPosted";

export const getLoadMoreFilteredDataThunk = createAsyncThunk("mp/loadMoreFilteredData",async(_, {getState}) => {
    const selectedCountry = getState().maintenancePartnerNearMe.filterByCountry;
    const selectedState = getState().maintenancePartnerNearMe.filterByState;
    const selectedVerificationStatus = getState().maintenancePartnerNearMe.filterByVerificationStatus;
    const selectedEquipmentType = getState().maintenancePartnerNearMe.filterByEquipmentType;
    const cardCount  = getState().maintenancePartnerNearMe.cardCount;
    const paginationCount = getState().maintenancePartnerNearMe.paginationCount;
    const token = getState().user.token;

    let query = ""

        
    if(selectedEquipmentType){
        query+= `&FilterByEquipmentType=${selectedEquipmentType}`
    }
    if(selectedVerificationStatus){
        query += `&FilterByIsCertified=${selectedVerificationStatus}`
    }

    try{
        
        const data = await getFilteredPartners(cardCount,(paginationCount * cardCount),`?FilterType=Maintenance${query}`);

        if(data.Status === 200){
            if(data.Data.TotalRecords > 0){
             return data.Data.GetFilteredAllTopMpMaintenancePartners;
            }
            else{
             return []
            }
    }
}
    catch(error){
    }

    
})