import { createSlice } from "@reduxjs/toolkit";
import { getInitialRentalEquipmentsThunk } from "../thunks/getInitalRentalEquipmentsThunk";
import { getRentalEquipmentAnalyticsThunk } from "../thunks/getRentalEquipmentAnalyticsThunk";

const rentalEquipmentStoreSlice = createSlice({
    name: "rentalequipmentStoreSlice",
    initialState: {
        rentalData: [], 
        rentalEquipmentAnalytics: {},
        rentalCardCount: 0,
        rentalPaginitionCount: 12,
        rentalIsInitialLoading: false,
        rentalIsLoadMoreLoading: false,
        rentalEquipmentsAnalyticsLoading: false,
        rentalImageBaseReference: null


    },
    reducers: {
        setRentalData: (state, {payload}) => {
            state.rentalData = payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getInitialRentalEquipmentsThunk.pending, (state) => {
            state.rentalIsInitialLoading = true;
            state.rentalData = [];
            state.cardCount = 0
        })
        .addCase(getInitialRentalEquipmentsThunk.fulfilled, (state, {payload}) => {
            state.rentalIsInitialLoading= false;
            state.rentalData= payload;
            // state.imageBaseReference = payload.imageBaseReference;
            // state.cardCount = 1
        })
        .addCase(getRentalEquipmentAnalyticsThunk.pending, (state) => {
            state.rentalEquipmentsAnalyticsLoading = true
        })
        .addCase(getRentalEquipmentAnalyticsThunk.fulfilled, (state, {payload}) => {
            state.rentalEquipmentsAnalyticsLoading = false
            state.rentalEquipmentAnalytics = payload
        })
    }

})

export default rentalEquipmentStoreSlice.reducer;