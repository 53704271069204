import { Formik } from "formik";
import {
  Box,
  Flex,
  Button,
  Checkbox,
  FormControl,
  Select,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { MultiAdDivider } from "../../../../layout/multiAdLayout/MultiAdLayout";
import { FormikInput } from "../../../../components/Input/FormikInput";
import { DoubleImageUpload } from "../../../../components/DoubleImageUpload/DoubleImageUpload";
import { useDispatch, useSelector } from "react-redux";
import { increasePageIndex } from "../../slice/docFlowSlice";
import {
  createEquipmentLicence,
  createNoLicenseRecordForPartner,
  getLicenseTypeList,
} from "../../../../services/documentFlow/documentFlow";
import { V5MultiAdLayout } from "../../../../layout/multiAdLayout/V5MultiAdLayout";
import { useSearchParams } from "react-router-dom";
import { NAVIGATION_CONSTANTS } from "../../../../../constants/naigationContants";

export const UploadLicense = () => {
  const [licenseTypes, setLicenseTypes] = useState(null);
  const [hasNoLicense, setHasNoLicense] = useState(false);
  const [selectedLicenseType, setSelectedLicenseType] = useState(null);
  const toast = useToast();
  const dispatch = useDispatch();
  const [urlParam, setUrlParam] = useSearchParams()
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const id = sessionStorage.getItem("DEFERRED_MARKETPLACE");
    const urlAssetId = urlParam.get(NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID)
    if(id && !urlAssetId){
     setUrlParam(`${NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID}=${id}`)
    }
   }, [])

  const getAllLicenseTypes = async () => {
    const data = await getLicenseTypeList();
    setLicenseTypes(data.Data.LicenseTypes);
  };

  const sendNoLicense = async () => {
    const data = await createNoLicenseRecordForPartner();

    if (data.Status === 201) {
      toast({
        status: "success",
        title: `${data.Message.SuccessMessage}`,
        isClosable: true,
        position: "top-right",
      });
      dispatch(increasePageIndex());
      //dispatch(increaseRouteIndex())
    } else {
      return toast({
        status: "error",
        title: `${data.Message.FailMessage}`,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleLicenseSubmission = async (body) => {
    try {
      const data = await createEquipmentLicence(body);

      if (data.Status === 201) {
        toast({
          status: "success",
          title: `${data.Message.SuccessMessage}`,
          isClosable: true,
          position: "top-right",
        });
        dispatch(increasePageIndex());
        //dispatch(increaseRouteIndex())
      } else {
        return toast({
          status: "error",
          title: `${data.Message.FailMessage}`,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    (async () => {
      await getAllLicenseTypes();
    })();
  }, []);

  return (
    <V5MultiAdLayout
      heading="Upload License"
      subHeading="Please give your document details for verification."
      stepper={true}
    >
      <Formik
        initialValues={{
          hasNoLicense: hasNoLicense,
          licenseType: "",
          licenseNumber: null,
          licenseFront: null,
          licenseBack: null,
        }}
        validationSchema={
          hasNoLicense
            ? null
            : Yup.object().shape({
                hasNoLicense: Yup.boolean(),
                licenseType: Yup.string().required("License Type is required"),
                licenseNumber: Yup.string()
                  .required("License Number is required")
                  .min(5, "Minimum 5 characters required.")
                  .max(25, "Maximum 25 characters allowed."),
                licenseFront: Yup.mixed()
                  .required("Front Image is required")
                  .test(
                    "fileFormat",
                    "Unsupported Format",
                    (value) =>
                      value &&
                      [
                        "image/jpg",
                        "image/jpeg",
                        "image/png",
                        "application/pdf",
                      ].includes(value.type)
                  ),
                licenseBack: Yup.mixed()
                  .nullable()
                  .test(
                    "fileFormat",
                    "Unsupported Format",
                    (value) =>
                      value &&
                      [
                        "image/jpg",
                        "image/jpeg",
                        "image/png",
                        "application/pdf",
                      ].includes(value.type)
                  ),
              })
        }
        onSubmit={async (values) => {
          setLoading(true)
          if (hasNoLicense) {
            return await sendNoLicense();
          } else {
            const body = values.licenseBack
              ? {
                  LicenseTypeId: values.licenseType,
                  LicenseNumber: values.licenseNumber,
                  UserLicenseImageFront: values.licenseFront,
                  UserLicenseImageBack: values.licenseBack,
                }
              : {
                  LicenseTypeId: values.licenseType,
                  LicenseNumber: values.licenseNumber,
                  UserLicenseImageFront: values.licenseFront,
                };
            await handleLicenseSubmission(body);
            setLoading(false)
          }
        }}
      >
        {({ setFieldValue, errors, touched, handleSubmit }) => (
          <form id="upload-license" onSubmit={handleSubmit}>
            <Flex
              flexDirection="column"
              gap="1.5em"
              justifyContent={"space-between"}
              maxH="300px"
              overflowY="scroll"
              width="80%"
              margin="0 auto"
              padding="0 0.5em"
            >
              <Checkbox
                name="hasNoLicense"
                onChange={() => setHasNoLicense((prevState) => !prevState)}
                fontWeight="700"
              >
                I don't have a license
              </Checkbox>
              <Flex gap="1em" wrap={"wrap"}>
                <FormControl
                  isDisabled={hasNoLicense}
                  isRequired={true}
                  maxW="374px"
                  width="100%"
                >
                  <FormLabel>Driving License Type</FormLabel>
                  <Select
                    name="licenseType"
                    h="52px"
                    border="1px solid #2c2c2c14"
                    focusBorderColor="#2c2c2c"
                    backgroundColor={
                      selectedLicenseType ? "#ffff" : "#2c2c2c05"
                    }
                    maxW="374px"
                    value={selectedLicenseType}
                    onChange={(e) =>
                      setFieldValue("licenseType", e.target.value)
                    }
                  >
                    <option value="" isDisabled>
                      Select an option
                    </option>
                    {licenseTypes && licenseTypes.length > 0
                      ? licenseTypes.map((license) => (
                          <option
                            key={license.LicenseTypeId}
                            value={license.LicenseTypeId}
                          >
                            {license.LicenseTypeValue}
                          </option>
                        ))
                      : null}
                  </Select>
                </FormControl>
                <FormikInput
                  label="Driving License Number"
                  name="licenseNumber"
                  isDisabled={hasNoLicense}
                  isRequired={true}
                  placeholder="E.g. MH 11 12340000567"
                  maxLength={25}
                />
              </Flex>
              <Box>
                <DoubleImageUpload
                  firstImageLabel="Driving License Upload"
                  firstImageHelperText="Upload the front side of your document."
                  secondImageHelperText="Upload the back side of your document."
                  showSecondImageLabel={false}
                  secondImageRequired={false}
                  firstImageInputName="licenseFront"
                  secondImageInputName="licenseBack"
                  firstImageFormikProps={{ setFieldValue, errors, touched }}
                  secondImageFormikProps={{ setFieldValue, errors, touched }}
                  isDisabled={hasNoLicense}
                />
              </Box>
            </Flex>
          </form>
        )}
      </Formik>

      <Box>
        <Flex
          justifyContent="center"
          alignItems="center"
          bgColor="#fff"
          h="78px"
          style={{ boxShadow: "0px -10px 15px #2c2c2c0f" }}
        >
          <Button
            type="submit"
            variant="gradient"
            fontWeight="semibold"
            width="400px"
            margin="0 auto"
            maxW="400px"
            form="upload-license"
            // onClick={handleNextPageButtonClick}
            // isDisabled={!imageFile}
            isLoading={loading}
          >
            <p style={{ fontSize: "22px" }}>Next</p>
          </Button>
        </Flex>
      </Box>
    </V5MultiAdLayout>
  );
};
