import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { V5SidebarLayout } from "../../../layout/V5SidebarLayout/V5SidebarLayout";
import { MarketplaceHoc } from "../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { v4 as uuid } from "uuid";
import { CommunityPost } from "./CommunityPost";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteMyContent, filterMyContent, setDeleteModalOpen, setEditModalOpen, setMyContent } from "../slice/communitySlice";
import { myContentPostsThunk } from "../thunks/myContentPostsThunk";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { SinglePurposePopup } from "../../../components/SinglePurposePopup/SinglePurposePopUp";
import editPopUpIcon from "../../../../assets/webapp/pop-up/edit-popup.svg";
import deletePopUpIcon from "../../../../assets/webapp/pop-up/delete-popup.svg";
import { DeleteCommunityDetails } from "../../../services/community/community";
import { setAudienceTypesData, setContentType } from "../../addCommunityPost/slice/addCommunitySlice";


export const MyContentPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeButton, setActiveButton] = useState(null);
  const [selectedPostCommunityId,setSelectedPostCommunityId]=useState(null);

  const { myContentDataFiltered,isEditModalOpen,isDeleteModalOpen} = useSelector((state) => state.community);



  useEffect(() => {
    dispatch(myContentPostsThunk());
    setActiveButton({
      ContentId :1,
      ContentValue :"Project"
    });
  }, []);

  const handleContentTypeBtnClick = (contentTypeId, buttonId,buttonValue) => {
    dispatch(filterMyContent(contentTypeId));
    setActiveButton({
      ContentId :buttonId,
      ContentValue :buttonValue
    });

  };

  const handleRemoveClick=async ()=>{
    const res = await DeleteCommunityDetails(selectedPostCommunityId);
    dispatch(setDeleteModalOpen(false))
    if(res.Status===204){
      dispatch(deleteMyContent(selectedPostCommunityId));
    }
  }
  

  const renderCards = (array) => {
    const renderJsx =
      array && array.length > 0 ? (
        array.map((post) => {
          return (
            <CommunityPost
              key={uuid()}
              isMyPost={true}
              Thumbnail={post.Thumbnail}
              ContentTitle={post.ContentTitle}
              ContentValue={post.ContentValue}
              LikesCount={post.LikesCount}
              ViewCount={post.ViewCount}
              CreatedDateTime={post.CreatedDateTime}
              IsLiked={post.IsLiked}
              IsPromoted={post.IsPromoted}
              IsE9Learning={post.IsE9Learning}
              Posted={post.Posted}
              CommunityVideoFileRef={post.CommunityVideoFileRef}
              handleViewPost={() => {
                window.open(post?.ContentLink);
              }}
              onEditClick={()=>{
                dispatch(setEditModalOpen(true));
                setSelectedPostCommunityId(post.CommunityId);

              }}
              onDeleteClick={()=>{
                dispatch(setDeleteModalOpen(true));
                setSelectedPostCommunityId(post.CommunityId);
              }}
            />
          );
        })
      ) : (
        <p>No Community Posts</p>
      );
    return renderJsx;
  };

  return (
    <>
     <SinglePurposePopup
        isOpen={isDeleteModalOpen}
        iconUrl={deletePopUpIcon}
        bodyText="Are you sure you want to remove this Post from the  community?"
        primaryActionText={"Delete"}
        secondaryActionText={"Keep It"}
        continueAction={handleRemoveClick}
        cancelAction={() => {
          dispatch(setDeleteModalOpen(false))
        }}
      />
      <SinglePurposePopup
        isOpen={isEditModalOpen}
        iconUrl={editPopUpIcon}
        bodyText="Are you sure you want to edit this Post?"
        primaryActionText={"Edit"}
        secondaryActionText={"Go Back"}
        cancelAction={() => {
          dispatch(setEditModalOpen(false))
        }}
        continueAction={() => {
          dispatch(setContentType({
            ContentId : activeButton.ContentId,
            ContentValue :  activeButton?.ContentValue,
          }));
          dispatch(setAudienceTypesData(null));
          dispatch(setEditModalOpen(false))
          navigate(`/community/edit-post/${selectedPostCommunityId}`)

        }}
      />
      <V5SidebarLayout>
        <Flex gap={"10px"} direction={"column"} margin={"0 52px"}>
          {/* header section */}
          <Flex direction={"column"}>
            <Text fontSize={"24px"} fontWeight={"700"} color={"#1A1E21"}>
              Community
            </Text>
            <Text fontSize={"20px"} fontWeight={"400"} color={"#767676"}>
              You can view all your posted content here
            </Text>
          </Flex>

          {/* action buttons */}
          <Flex justifyContent={"space-between"}>
            <Flex gap={"10px"}>
              <Button
                variant="outline"
                color={"#002961"}
                border={"1px solid #002961"}
                onClick={() => {
                  navigate("/community");
                }}
                leftIcon={
                  <ReactSVG
                    alt="my_content"
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/community/communityGlobe.svg`}
                    style={{
                      filter:
                        "brightness(0) saturate(100%) invert(9%) sepia(74%) saturate(4470%) hue-rotate(201deg) brightness(91%) contrast(108%)",
                    }}
                  />
                }
              >
                Community
              </Button>
              <Button
                variant="outline"
                color={"#F79C00"}
                border={"1px solid #F79C00"}
                onClick={() => {}}
                leftIcon={
                  <img
                    alt="my_content"
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/community/myContent.svg`}
                    style={{
                      filter:
                        "brightness(0) saturate(100%) invert(53%) sepia(100%) saturate(1771%) hue-rotate(10deg) brightness(108%) contrast(102%)",
                    }}
                  />
                }
              >
                My Content
              </Button>
            </Flex>

            <Flex gap={"10px"}>
              <Button
                variant="outline"
                color={activeButton?.ContentId === 1 ? "#F79C00" : "#002961"}
                border={
                  activeButton?.ContentId === 1 ? "1px solid #F79C00" : "1px solid #002961"
                }
                onClick={() => handleContentTypeBtnClick(1, 1,"Project")}
              >
                Project
              </Button>
              <Button
                variant="outline"
                color={activeButton?.ContentId === 3 ? "#F79C00" : "#002961"}
                border={
                  activeButton?.ContentId === 3 ? "1px solid #F79C00" : "1px solid #002961"
                }
                onClick={() => handleContentTypeBtnClick(3, 3,"Article")}
              >
                Article
              </Button>
              <Button
                variant="outline"
                color={activeButton?.ContentId === 4 ? "#F79C00" : "#002961"}
                border={
                  activeButton?.ContentId === 4 ? "1px solid #F79C00" : "1px solid #002961"
                }
                onClick={() => handleContentTypeBtnClick(4, 4,"Video")}
              >
                Video
              </Button>
            </Flex>
          </Flex>

          {/* displaying your content */}
          <Box marginTop={"20px"}>
            <MarketplaceHoc
              marketplaceType="Dealership Listing"
              dataArray={myContentDataFiltered || []}
              endOfData={true}
              loadMoreAction={() => {}}
              initialLoading={false}
              paginationLoading={false}
              cardsRenderFunction={renderCards}
            />
          </Box>
        </Flex>
      </V5SidebarLayout>
    </>
  );
};
