import { createAsyncThunk } from "@reduxjs/toolkit";
import { convertToFormData } from "../../../helpers/dataConversion/convertToFormData";
import { getLoginDetails } from "../../../services/login/login";

export const handleLoginThunk = createAsyncThunk("/login",async(value, {rejectWithValue}) => {
    try{ 

        const data = await getLoginDetails(convertToFormData(value))  
        if (data.Data.TotalRecords >= 1){          
            return data.Data.AccountList
        }
        else{    
            return rejectWithValue(data.Message.FailMessage)
        }
    }
    catch(error){
         return rejectWithValue(error.message);
    }
})



