import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useQuery, useQueryClient } from "react-query";
import { useToast } from "@chakra-ui/react";
import {
  educationType,
  countrylist,
  updateEducationalDocument,
} from "../../../../../../../../../../ApiEquip9/CommonApi";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import * as Yup from "yup";
import "./OpeducationPopCard.css";
export const OpEducationCard = ({ isOpen, onClose, triggerRefresh }) => {
  const userData = useSelector((state) => state.user);
  const [imagePreview, setImagePreview] = useState(null);
  const [fileType, setFileType] = useState("");
  const toast = useToast();
  const queryClient = useQueryClient();

  const initialValues = {
    EducationTypeId: "",
    CountryId: "",
    EducationalDocument: null,
    Remark: "",
  };

  const validationSchema = Yup.object().shape({
    EducationTypeId: Yup.string().required("Education Type is required"),
    CountryId: Yup.string().required("Country is required"),
    EducationalDocument: Yup.mixed().required("File not selected"),
  });

  const OnClosepopup = () => {
    setImagePreview(null);
    onClose();
  };

  const handleSubmit = async (values, { resetForm }) => {
    const Payload = {
      EducationTypeId: parseInt(values.EducationTypeId),
      CountryId: parseInt(values.CountryId),
      EducationalDocument: values.EducationalDocument,
      Remark: "",
    };

    try {
      const response = await updateEducationalDocument(Payload, userData.token);
      toast({
        title: "",
        description: `${response.data.Message.SuccessMessage}`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      queryClient.invalidateQueries("getEducationDetails");
      triggerRefresh();
      onClose();
      resetForm();
      setImagePreview(null);
      return response;
    } catch (error) {
      toast({
        description: `${error.data.Message.FailMessage}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return null;
    }
  };

  const { data: educationtypelist } = useQuery("getEducationType", () =>
    educationType(userData.token)
  );
  const EducationTypeList = educationtypelist?.data?.Data?.EducationType;
  const { data: getcountrylist } = useQuery("getcountrylist", () =>
    countrylist(userData.token)
  );
  const CountryTypeList = getcountrylist?.data?.Data?.CountriesList;

  return (
    isOpen && (
      <div className="popup-card-over">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form className="popup-carddd">
              <button className="popup-card-close" onClick={OnClosepopup}>
                <ReactSVG
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Cross.svg`}
                />
              </button>
              <div className="certificate-icon">
                <ReactSVG
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/AddVerification.svg`}
                />
              </div>
              <h1>Add Education Detail</h1>
              <div className="popup-card-inner">
                <label htmlFor="selectField">
                  Select Education<span style={{ color: "red" }}>*</span>
                </label>
                <Field as="select" name="EducationTypeId">
                  <option value="">Select an option</option>
                  {EducationTypeList.map((item, index) => (
                    <option key={index} value={item.EducationTypeId}>
                      {item.DegreeName}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="EducationTypeId"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="popup-card-inner">
                <label htmlFor="selectField">
                  Country Name<span style={{ color: "red" }}>*</span>
                </label>
                <Field as="select" name="CountryId">
                  <option value="">Select an option</option>
                  {CountryTypeList.map((item, index) => (
                    <option key={index} value={item.CountryId}>
                      {item.CountryName}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="CountryId"
                  component="div"
                  className="error-message"
                />
              </div>
              <label htmlFor="selectField">
                Upload Education Documents
                <span style={{ color: "red" }}>*</span>
              </label>
              <div className="file-input-container-education">
                <label
                  htmlFor="custom-file-input"
                  className="custom-file-button"
                >
                  Upload
                </label>
                <input
                  className="custom-file-input"
                  id="custom-file-input"
                  type="file"
                  accept="image/jpeg, image/png, application/pdf"
                  onChange={(event) => {
                    const file = event.currentTarget.files[0];
                    setFileType(file.type); // Set the file type here
                    setFieldValue("EducationalDocument", file);
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      setImagePreview(reader.result);
                    };
                    if (file) {
                      reader.readAsDataURL(file);
                    }
                    toast({
                      title: "File Uploaded successfully",
                      description: "You have selected a file for upload.",
                      status: "success",
                      duration: 2000,
                      isClosable: true,
                      position: "top",
                    });
                  }}
                />
              </div>
              <div className="preview-container">
                {imagePreview && fileType === "application/pdf" ? (
                  <embed
                    src={imagePreview}
                    type="application/pdf"
                    width="100%"
                    height="200px"
                  />
                ) : (
                  <img
                    src={imagePreview}
                    style={{
                      width: "100%",
                      height: "20vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderStyle: "dashed",
                      borderWidth: "2px",
                      borderColor: "#A0AEC0",
                      background: "#ccc",
                    }}
                  />
                )}
              </div>
              <ErrorMessage
                name="EducationalDocument"
                component="div"
                className="error-message"
              />
              <button type="submit" disabled={isSubmitting}>
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    )
  );
};
