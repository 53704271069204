import "./ShowMarketCard.css";
import React, { useState, useRef, useEffect } from "react";
import { ReactSVG } from "react-svg";
import { useSelector, useDispatch } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import { useParams, useLocation } from "react-router-dom";
import { Tooltip } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useToast } from "@chakra-ui/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { convertFromBase64 } from "../../../../../utils/formatConverter/convertToBase64";
import { QrPopUp } from "../../../ScanQrCodeModal/QrPopUp";
import {
  editClearStepperIndex,
  editClearMaterialData,
} from "../AddMarketPlace/MarketPlaceSteps/EditMarketPlace/EditMaterialSlice/EditMaterialSlice";
import { convertToBase64 } from "../../../../../utils/formatConverter/convertToBase64";
import { postmaterialMarketPlace,MaterialDelete, } from "../../../../../ApiEquip9/CommonApi";
import { getDaysDifference } from "../../../../../staticData/CommonMethod";
import { getMaterialListById, removeMaterialFromMarketplace } from "../../../../services/materials/materials";

export const ShowMarketPlaceCard = () => {
  const userData = useSelector((state) => state.user);
  const dashboard = useSelector((state) => state.dashboard);
  const { MaterialTypeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const MAterialTypeId = convertFromBase64(
    localStorage.getItem("MaterialTypeId")
  );
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [captureId, setcaptureId] = useState(null);
  const deCodeed = convertFromBase64(MaterialTypeId);
  const toast = useToast();
  const queryClient = useQueryClient();
  const yesButtonRef = useRef(null);
  const noButtonRef = useRef(null);


  // convert into base 64
  const materialId = btoa(deCodeed);
  /*************useQuery Call*****************************/
  const { data: getmaterailCard } = useQuery("getmaterailcard", () =>
  getMaterialListById(materialId)
  );

  const resultList = getmaterailCard?.data?.Data?.MaterialList;
  // console.log(resultList);

  /**
   * The function `handleDelete` is an asynchronous function that deletes a material and displays a
   * success message if the deletion is successful, or an error message if there is an error.
   */
  const handleDelete = async (MaterialId) => {
    try {
      const result = await MaterialDelete(userData.token, btoa(MaterialId));
      if (result?.data.Status === 204) {
        toast({
          title: "",
          description: `${result?.data?.Message?.SuccessMessage}`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        queryClient.invalidateQueries("getmaterailcard");
      }
    } catch (err) {
      toast({
        title: "",
        description: `Error while deleting `,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  /**
   * The function "openConfirmationDialog" sets the "captureId" state to the provided "MaterialId" and
   * sets the "ConfirmationDialog" state to true.
   */
  const openConfirmationDialog = (MaterialId) => {
    setcaptureId(MaterialId);
    setConfirmationDialog(true);
  };
  const deleteDoc = async () => {
    handleDelete(captureId);
    setConfirmationDialog(false);
  };

  /* The above code is defining a JavaScript object called `pagination`.*/
  const pagination = {
    clickable: true,
    renderBullet: (index, className) => {
      return `<span class="${className} my-custom-bullet"></span>`;
    },
  };

  /****Edit  */

  const handleEdit = (id) => {
    const convertId = convertToBase64(id);
    localStorage.setItem("EDIT_MATERIAL_ID", id);
    navigate(`/my-business-Edit-material/${convertId}`);
  };

  /*******on Pop Up***************8*/

  const addtomarketPlace = async (materialTypeId, materialId) => {
    const payload = {
      MaterialTypeId: materialTypeId,
      MaterialId: materialId,
    };
    try{
      const postMarketPlaceresult=await postmaterialMarketPlace(userData.token,payload);
       if(postMarketPlaceresult?.data?.Status===201){
        toast({
          title: "",
          description: `${postMarketPlaceresult?.data?.Message?.SuccessMessage}`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        queryClient.invalidateQueries("getmaterailcard");
       }
       else{
        toast({
          title: "",
          description: `${postMarketPlaceresult.data?.Message?.FailMessage}`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
       }
    }
    catch(err){
      toast({
        title: "",
        description: `Error while adding to  marketPlace`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      throw new Error(err);
    }

  };

/**
 * The function `removefromMarketPlace` is an asynchronous function that removes a material from a
 * marketplace and displays a success or error message using the `toast` function.
 */
  const removefromMarketPlace = async (materialTypeId, materialId) => {
    try {
      const data = await removeMaterialFromMarketplace(
        materialTypeId,
        materialId
      );
      if (data?.Status === 204) {
        toast({
          title: "",
          description: `${data.Message.SuccessMessage}`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        /* will be refetched the next time it is requested. */
        queryClient.invalidateQueries("getmaterailcard");
      } else {
        toast({
          title: "",
          description: `Error`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (err) {
      toast({
        title: "",
        description: `Error While removing from MarketPlace`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  /**
   * The openPopUp function dispatches an action to open a pop-up.
   */
  const openPopUp = () => {
    dispatch(openPopUp());
  };

  useEffect(() => {
    dispatch(editClearStepperIndex());
    dispatch(editClearMaterialData());
  }, []);

  return (
    <div>
      <QrPopUp />
      <div className="show-store-marketplace-view-layout">
        {resultList?.length > 0 ? (
          <>
            {resultList?.map((item) => {
              let imageListArray = [];
              if (item.ImageList) {
                imageListArray = JSON.parse(item.ImageList);
              }
              return (
                <div
                  className="show-store-marketplace-card-layout"
                  style={{ position: "relative" }}
                >
                  <div
                    className="tag-verfied"
                    style={{
                      background:
                        item.IsVerified === 0
                          ? "rgba(214, 0, 0, 0.376)"
                          : "#319f00",
                      color: "white",
                    }}
                  >
                    <p className="verified-text-marketplace">
                      {item.IsVerified === 0 ? (
                        <div>Not Verified</div>
                      ) : (
                        <div>Verified</div>
                      )}
                    </p>
                    <div>
                      <p className="verified-text-marketplace">
                        {item.IsOriginal === 1 ? (
                          <div
                            style={{
                              border:
                                item.IsOriginal === 1 && "2px solid #F19020",
                              textAlign: "center",
                              height: "22px",
                              width: "83px",
                              borderRadius: "10px",
                              position: "absolute",
                              top: "112%",
                              background: "#000",
                            }}
                          >
                            Original
                          </div>
                        ) : (
                          <div>{""}</div>
                        )}
                      </p>
                    </div>
                    {getDaysDifference(item?.UpdatedDateTime) <= 5 && (
                      <div>
                        <p className="verified-text-marketplace">
                          <div
                            style={{
                              textAlign: "center",
                              height: "22px",
                              width: "83px",
                              borderRadius: "10px",
                              position: "absolute",
                              top: "532%",
                              background: "#0068F7",
                            }}
                          >
                            <p style={{ marginTop: "2px" }}>New Added</p>
                          </div>
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="market-place-image-container">
                    <Swiper
                      pagination={pagination}
                      autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                      }}
                      modules={[Autoplay, Pagination, Navigation]}
                      className="mySwiper"
                    >
                      {imageListArray?.map((imgItem, index) => (
                        <SwiperSlide key={index}>
                          <img
                            className="image-marketplace-hero"
                            src={`${process.env.REACT_APP_CDN_PVT_URL}${imgItem.ImageFileRef}`}
                            alt="Marketplace"
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  <div className="informationhero-marketplace">
                    <div className="informationhero-marketplace-description">
                      <p className="equipment-hero-info-heading">
                        {item?.ProductName}
                      </p>
                      <p className="equipment-hero-info-subheading">
                        {item?.City} , {item?.State} , {item?.Country}
                      </p>
                      <p className="equipment-hero-price-info-subheading">
                        &#8377; {item?.MinPriceRange}/- to &#8377;{" "}
                        {item?.MaxPriceRange}/
                      </p>
                      <Tooltip
                        label={`${item?.MaterialUniqueId} | ${
                          item?.ProductName
                        } | ${item?.EquipmentTypeValue} | ${
                          item?.ModelName ? `${item?.ModelName}` : " N.A "
                        } | ${
                          item?.OEMName ? `${item?.OEMName}` : " N.A  "
                        } | ${
                          item?.EquipmentTypeValue
                            ? `${item?.EquipmentTypeValue}`
                            : " N.A "
                        } `}
                      >
                        <p className="equipment-hero-info-PNO">
                          {item.MaterialUniqueId} &nbsp; | {item?.ProductName}{" "}
                          |&nbsp;{" "}
                          {item?.ModelName ? `${item?.ModelName}` : " N.A "}...
                        </p>
                      </Tooltip>

                      <p className="equipment-hero-info-address">
                        {item?.EquipmentTypeValue}
                      </p>
                      <div className="equipment-hero-info-divider">
                        <ReactSVG
                          className="garbage-option"
                          onClick={() =>
                            openConfirmationDialog(item?.MaterialId)
                          }
                          // onClick={()=>handleDelete(item?.MaterialId)}
                          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Editgarbage.svg`}
                        />
                        <ReactSVG
                          onClick={() => {
                            handleEdit(item?.MaterialId);
                          }}
                          className="edit-option"
                          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/EditUpdate.svg`}
                        />
                        {item?.IsOnMarketplace === 1 ? (
                          <>
                            <div
                              className="remove-from-marketplace-button"
                              onClick={() => {
                                removefromMarketPlace(
                                  item?.MaterialTypeId,
                                  item?.MaterialId
                                );
                              }}
                            >
                              <p className="remove-from-marketplace-button-text">
                                Remove from MarketPlace
                              </p>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="add-to-marketplace-button"
                              onClick={() => {
                                addtomarketPlace(
                                  item?.MaterialTypeId,
                                  item?.MaterialId
                                );
                              }}
                            >
                              <p className="add-to-marketplace-button-text">
                                Add to MarketPlace
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className="no-material-added-yet">
            <ReactSVG
              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/NoMaterialAddedYet.svg`}
            />
            <p className="">No Material Store Added Yet</p>
          </div>
        )}

        {confirmationDialog && (
          <div className="modal-backdrop">
            <div className="confirmation-dialog">
              <div className="deleteHeading">
                <ReactSVG
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/HeadDelete.svg`}
                />
              </div>
              <div style={{ marginTop: "12px" }}>
                <p className="conformation-text">
                  Are you sure you want to delete this document?
                </p>
              </div>

              <button
                ref={yesButtonRef}
                style={{
                  background: "#C6C7C8",
                  transition: "0.3s",
                }}
                onMouseOver={() => {
                  yesButtonRef.current.style.background =
                    "linear-gradient(90deg, var(--primary), var(--tertiary))";
                }}
                onMouseOut={() => {
                  yesButtonRef.current.style.background = "#C6C7C8";
                }}
                onClick={deleteDoc}
              >
                Yes
              </button>

              <button
                ref={noButtonRef}
                style={{
                  background: "#C6C7C8",
                }}
                onMouseOver={() => {
                  noButtonRef.current.style.background =
                    "linear-gradient(90deg, var(--primary), var(--tertiary))";
                }}
                onMouseOut={() => {
                  noButtonRef.current.style.background = "#C6C7C8";
                }}
                onClick={() => {
                  setConfirmationDialog(false);
                }}
              >
                No
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
