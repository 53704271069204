import { API_CONSTANTS } from "../../../constants/apiConstants";
import {fetchHandler} from "../../../index"
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData";

export const createPostRating = async (payload) => {
    try {
   
    const {data}= await fetchHandler("/CreateOwnerRatingDetails",{},{},convertToFormData(payload),API_CONSTANTS.HTT_POST);

    return data;
    }
    catch (error) {
      throw error.response;
    }
  }


  export const getRatingOwner = async (jobId, OwnerId) => {
    try {
 
    const {data}= await fetchHandler(`/GetOwnerRatingDetailsGivenByPartner/${jobId}/${OwnerId}`,{},{},{},API_CONSTANTS.HTTP_GET);
      console.log("DATA",data)
    return data;
    }
    catch (error) {
      throw error
    }
  }


  export const workSubmitReport = async ( payload) => {
    try {
  
    const {data}= await fetchHandler("/OperatorWorkReportSubmit",{},{},convertToFormData(payload),API_CONSTANTS.HTT_POST);

    return data;
    }
    catch (error) {
      throw error.response
    }
  }

  export const cancelJobOngoing = async (payload) => {
    try {
    const {data}=await fetchHandler("/CancelUpcomingJobForOperatorMaintenance",{},{},payload,API_CONSTANTS.HTTP_PUT);
    return data;
    }
    catch (error) {
      throw error.response
    }
  }