import {fetchHandler} from "../../../"
import { API_CONSTANTS } from "../../../constants/apiConstants"

export const createE9RedeemedTransactionDetails = async(body) => {
    try{
        const {data} = await fetchHandler("/CreateE9RedeemedTransactionDetails", {}, {}, body, API_CONSTANTS.HTT_POST)
        return data.Data
    }
    catch(error){
        throw error
    }
}