import { API_CONSTANTS } from "../../../constants/apiConstants";
import { fetchHandler } from "../../../index";
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData";

export const getOwnerMarketplaceList = async (cardCount, offset) => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.GET_E9_OWNER_MARKATPLACE_LIST}/${cardCount}/${offset}`
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export const getFilteredOwners = async (cardCount, offset, query) => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.GET_FILTERED_E9_OWNER_MARKETPLACE_LIST}/${cardCount}/${offset}?${query}`
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllRolesList = async () => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.GET_ALL_USER_ROLES_LIST}`
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const createContactRequest = async (payload) => {
  try {
    const { data } = await fetchHandler(
      API_CONSTANTS.CREATE_E9_OWNER_MARKETPLACE_CONNECT,
      {},
      {},
      convertToFormData(payload),
      API_CONSTANTS.HTT_POST
    );
    return data;
  } catch (error) {
    throw error.response;
  }
};

export const updateContactRequest = async (payload) => {
  try {
    const { data } = await fetchHandler(
      API_CONSTANTS.UPDATE_E9_OWNER_MARKETPLACE_CONNECT,
      {},
      {},
      JSON.stringify(payload),
      API_CONSTANTS.HTTP_PUT
    );
    return data;
  } catch (error) {
    throw error.response;
  }
};

export const getOwnerMpEquipDetAnalytics = async (id) => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.GET_OWNER_MP_EQUIP_DET_ANALYTICS}?OwnerUserCredentialId=${id}`,
      {},
      {},
      {},
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: true }
    );
    return data;
  } catch (err) {
    //
    throw err;
  }
};

export const getE9OwnerMpProfileDetails = async (id) => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.GET_E9_OWNER_MP_PROFILE_DETAILS}?OwnerUserCredentialId=${id}`,
      {},
      {},
      {},
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: true }
    );
    return data;
  } catch (err) {
    //
    throw err;
  }
};

export const getE9OwnerMpConnectStatusById = async (id) => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.GET_E9_OWNER_MP_CONNECT_STATUS_BY_ID}?OwnerUserCredentialId=${id}`,
      {},
      {},
      {},
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: true }
    );
    return data;
  } catch (err) {
    //
    throw err;
  }
};

export const getOwnerMpDealershipDetAnalytics = async (id) => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.GET_OWNER_MP_DEALARSHIP_DET_ANALITICS}?OwnerUserCredentialId=${id}`,
      {},
      {},
      {},
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: true }
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export const getOwnerMpMaterialsDetAnalytics = async (id) => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.GET_OWNER_MP_MATERIAL_ANALYTICS}?OwnerUserCredentialId=${id}`,
      {},
      {},
      {},
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: true }
    );
    return data;
  } catch (err) {
    throw err;
  }
};

// export const getOwnerMpDealershipDetAnalytics= async (id) => {
//   try {
//   const {data} = await fetchHandler(`${API_CONSTANTS.GET_E9_OWNER_MP_PROFILE_DETAILS}?OwnerUserCredentialId=${id}`,{},{},{},API_CONSTANTS.HTTP_GET,{isAuthRequired:true});
//   return data;

//   }
//   catch (err) {    //
//     throw err;
//   }
// }

export const GetAllMarketPlaceServices = async () => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.GET_ALL_MARKETPLACE_SERVICES}`,
      {},
      {},
      {},
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: true }
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export const GetCountE9OwnerMarketplaceList = async () => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.GET_COUNT_E9_OWNER_MARKETPLACE_LIST}`,
      {},
      {},
      {},
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: true }
    );
    return data;
  } catch (err) {
    throw err;
  }
};
export const getE9OwnerMpConnectStatusList = async (taxNumber) => {
  try {
    const queryParams = taxNumber ? { SenderTaxNumber: taxNumber } : {};

    const { data } = await fetchHandler(
      `${API_CONSTANTS.GET_E9_OWNER_MP_CONNECT_STATUS_LIST}`,
      queryParams,
      {},
      {},
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: true }
    );
    return data;
  } catch (err) {
    throw err;
  }
};


export const getPremiumRedeemCountByUserIdServiceTypeId = async (UserCredentialId,ServiceTypeId) => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.GET_PREMIUM_REDEEM_COUNT_BY_USERID_SERVICETYPEID}`,
      {
        UserCredentialId:UserCredentialId,
        ServiceTypeId:ServiceTypeId
      },
      {},
      {},
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: true }
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export const getOwnerMarketplaceSentList = async () =>{
  try{
    const {data} = await fetchHandler(API_CONSTANTS.GET_E9_OWNER_MP_MY_LEADS_SENT_LIST) ;
    if(data?.Status === 200 ){
      return data?.Data
    }
    return data ;
  }catch (error){

  }
}
export const getOwnerMarketplaceReceivedList = async () =>{
  try{
    const {data} = await fetchHandler(API_CONSTANTS.GET_E9_OWNER_MP_MY_LEADS_RECEIVED_LIST) ;
    if(data?.Status === 200 ){
      return data?.Data
    }
    return data ;
  }catch (error){

  }
}

export const updateOwnersConnect = async (payload) => {
  try{
    const {data} = await fetchHandler(
      API_CONSTANTS.UPDATE_E9_OWNERS_MARKETPLACE_CONNECT,
      {},
      {},
      payload,
      API_CONSTANTS.HTTP_PUT,
      {isAuthRequired : true}
    )
    if(data?.Status === 204){
      return data ;
    }
  } catch (error) {

  }
}
