import { API_CONSTANTS } from "../../../constants/apiConstants";
import {fetchHandler} from "../../../index"

export const getPhotoType=async ()=>{
    try {
        const {data}=await fetchHandler(`${API_CONSTANTS.GET_PHOTO_TYPE}`,{},{},{},API_CONSTANTS.HTTP_GET);
        return data;
    } catch (error) {
        throw error;
    }
}

export const getVideoType = async (token) => {
    try {
 
    const {data}=await fetchHandler(`${API_CONSTANTS.GET_VIDEO_TYPE}`,{},{},{},API_CONSTANTS.HTTP_GET);
    return data;

    } catch (error) {
      // console.log(error);
    }
  }