import './WelcomeStore.css';
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate} from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useQuery, useQueryClient} from "react-query";
import {
    AlertDialog,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
  } from "@chakra-ui/react";
export const WelcomeStore=({
    isLogoutModalWelcomeOpenPopUp,
    setIsLogoutModalWelcomeOpenPopUp,
    onOpenMaterialStorePopup
})=>{
    const userData = useSelector((state) => state.user);
    // console.log(userData)
    const queryClient = useQueryClient();
    const onCloseLogoutModal = () => setIsLogoutModalWelcomeOpenPopUp(false);
    const cancelReff = React.useRef();
    const navigate=useNavigate();

    const handleNextClick = () => {
        setIsLogoutModalWelcomeOpenPopUp(false);
        // onOpenMaterialStorePopup();
      };
    
    return(
    <div>
     <AlertDialog
        isOpen={isLogoutModalWelcomeOpenPopUp}
        leastDestructiveRef={cancelReff}
        onClose={onCloseLogoutModal}
        isCentered >
            <AlertDialogOverlay>
           <AlertDialogContent>
            <AlertDialogHeader>
            <div
                className="material-pop-up-close"
                onClick={onCloseLogoutModal}
              >
                <ReactSVG
                   style={{cursor:'pointer'}}
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Materialpopclose.svg`}
                />
              </div>
              <div className="Material-pop-header-layout">
                <ReactSVG
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MatrailStore.svg`}
                />
                <p className="Material-pop-header-heading">Welcome to Materials Store</p>
              </div>
            </AlertDialogHeader>
            <div className="welcome-material-layout">
              <div className='step-layout'>
                <div className='icon-text'>
                    <ReactSVG 
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/Postdealership/stepOne.svg`}
                     />
                    <p className='step1-heading'>Step 1:</p>
                </div>
                <div className='add-text-material-layout'>
                    <p className='add-text-material-subheading'>Add your Material Type</p>
                </div>
                <div>

                </div>
              </div>
              <div className='step-layout'>
                <div className='icon-text'>
                <ReactSVG 
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/Postdealership/stepTwo.svg`} />

                    <p className='step1-heading'>Step 2:</p>
                </div>
                <div>
                    <p className='add-text-material-subheading'>List your Products and add to Marketplace</p>
                </div>
                <div>

                </div>
              </div>
              <div className='step-layout'>
                <div className='icon-text'>
                <ReactSVG 
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/Postdealership/stepThree.svg`} />
                    <p className='step1-heading'>Step 3:</p>
                </div>
                <div>
                    <p className='add-text-material-subheading'>View Leads in “My Leads” Section</p>
                </div>
                <div>

                </div>
              </div>
              </div>
            <div className="pop-up-footer">
              <button
                className="Next-button-welcome"
                type="button"

                onClick={handleNextClick}
              >
                <p className="next-button-text">Next</p>
              </button>
            </div>
           </AlertDialogContent>
            </AlertDialogOverlay>

     </AlertDialog>
    </div>)
}