import React from 'react'
import { Flex, Grid, Box, Image } from "@chakra-ui/react"

import "../midSection/MidSection.css"

export const MidSection = ({children})=>{
    return(
        <Flex width="100vw" className='midSection'>

       
        <Box area="main"  minH="100vh" maxW="calc(100vw - 240px)" width="100%"> 
         
            {children}
            
        </Box>
    
    </Flex>
    );
}