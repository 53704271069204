import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMyPremiumDetails } from "../../../services/premium/premium";

export const getPremiumUserDetailsThunk = createAsyncThunk("/premium/getPremiumUserDetails" , async() => {
    try{
        const data = await getMyPremiumDetails();
        // console.log(data)
        return data.Data
    }
    catch(error){
        throw error
    }
})