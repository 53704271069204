import { createSlice } from "@reduxjs/toolkit";
import { getFreeLeadsForIndividualThunk } from "../thunks/getFreeLeadsForUserThunk";
import { getFreeLeadsForCompanyThunk } from "../thunks/getFreeLeadsForCompanyThunk";

export const freeLeadsSlice = createSlice({
  name: "freeLeads",
  initialState: {
    freeLeadsData: [],
    freeLeadsLoading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFreeLeadsForIndividualThunk.pending, (state) => {
        state.freeLeadsLoading = true;
      })
      .addCase(
        getFreeLeadsForIndividualThunk.fulfilled,
        (state, { payload }) => {
          state.freeLeadsLoading = false;
          state.freeLeadsData = payload;
        }
      )
      .addCase(getFreeLeadsForCompanyThunk.pending, (state) => {
        state.freeLeadsLoading = true;
      })
      .addCase(getFreeLeadsForCompanyThunk.fulfilled, (state, { payload }) => {
        state.freeLeadsLoading = false;
        state.freeLeadsData = payload;
      })
      
  },
});

export default freeLeadsSlice.reducer;
