import { Button } from "@chakra-ui/button";
import { CloseIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";

export const NoLeadsPopUp = ({
  onButtonClick = () => {},
  onCloseClick = () => {},
  isOpen = true,
  freeLeadsCount = 0,
  subscriptionCount = 0,
}) => {
  return (
    <Modal isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
            <Box textAlign="right">
                <Button background="transparent" boxShadow="none" dropShadow="none" outline="none" onClick={onCloseClick}><CloseIcon/></Button>
            </Box>
            <Flex alignItems="center" justifyContent="center">
          <Heading>Marketplace Service</Heading>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Flex flexDirection="column" gap="0.5em">
            <Flex justifyContent="space-between"><Text fontSize="18px" color="#2c2c2c">Your Current Subscription </Text> <Text>{subscriptionCount}</Text> </Flex>
            <Flex justifyContent="space-between"><Text fontSize="18px" color="#2c2c2c">Your Free Subscription </Text> <Text>{freeLeadsCount}</Text> </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button variant="gradient" onClick={onButtonClick}>
            Buy More
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
