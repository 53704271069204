import { Box } from "@chakra-ui/layout"
import { useState, useEffect } from "react"

export const ProductCarousel = ({array = []}) => {
    const [imageNumber, setImageNumber] = useState(0);
    let id;
    useEffect(() => {
         id = setTimeout(() => {
            if(imageNumber < array.length - 1){
                setImageNumber((prevState) => (prevState + 1))

            }
            else{
                setImageNumber(0)
            }
        }, 3000)
   return () => {clearInterval(id)}
    }, [imageNumber])
 return(
    <Box className="our-product-carousel" style={{ width: "100%",  height: "100%"}}>
        <img style={{ width: "100%",  height: "100%"}} src ={array[imageNumber]} alt={'Image of two cranes with a background of nut and bolts.'}/>
    </Box>
 )
}