import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMyOrdersRequestSentList } from "../../../services/myLeads/myLeads";

export const getUsedEquipmentSentDataThunk = createAsyncThunk(
  "myLeads/sentRequestData",
  async (_, { getState }) => {
    try {
      let id = "Used Equipment";
      const data = await getMyOrdersRequestSentList(id);
      return data;
    } catch (error) {}
  }
);
