import { useNavigate } from "react-router-dom";
import { BrandLoader } from "../../../components/loaders/brandLoader/BrandLoader";
import { useEffect, useState } from "react";
import { getUserPremiumStatusThunk } from "../../global/user/thunks/getUserPremiumStatusThunk";
import { useDispatch, useSelector } from "react-redux";
import SuccessImage from "../../../../assets/webapp/subscription/success-desktop.png";
import { Box, Button, Flex, Heading, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Text } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

export const PremiumPaymentStatus = () => {
    const [paymentStatus, setPaymentStatus] = useState("");
    const [paymentStatusLoading, setPaymentStatusLoading] = useState(true);
    const { isPremium, premiumId } = useSelector((state) => state.user);
    const {defaultLanguage} = useSelector(state => state.masterData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getPremiumStatus = async () => {
    try {
        setPaymentStatusLoading(false)
      const data = await dispatch(getUserPremiumStatusThunk()).unwrap();
      
      if (data.Data.IsUserPremium) {
        setPaymentStatus("success");
      } else {
        setPaymentStatus("failture");
      }
    } catch (error) {
        setPaymentStatus("failture");
    }
    finally{
        setPaymentStatusLoading(false);
    }
  };

  useEffect(() => {
    getPremiumStatus();
  }, []);
  return (
    <>
      <Modal isOpen={!paymentStatusLoading} isCentered>
        <ModalOverlay />
        <ModalContent>
          <Box position={"absolute"} top={"-20px"} right={"15px"}>
            <Button
              onClick={() => {
                navigate("/dashboard");
              }}
              boxShadow="0px 1.5px 3px 0px #0000000F"
              borderRadius={"50%"}
              backgroundColor={"white"}
              height={"42px"}
              width={"42px"}
            >
              <CloseIcon width={"9px"} stroke={"#141619"} />
            </Button>
          </Box>
          <ModalBody>
            <Flex
              alignItems="center"
              justifyContent="center"
              gap="1em"
              direction="column"
              minH="200px"
            >
              <img
                style={{ height: "55px", width: "120px" }}
                src={SuccessImage}
                alt="payment-successful"
              />
              <Heading
                as="h1"
                fontSize="34px"
                lineHeight="40px"
                fontWeight="700"
                color="#2c2c2c"
              >
                {paymentStatus === "success" ? "Congratulations" : "Oh No!.."}
              </Heading>
              <Flex
                direction="column"
                gap="0.5em"
                alignItems="center"
                justifyContent="center"
              >
                <Text as="p" fontSize="18px" color="#2c2c2cCC">
                {paymentStatus === "success" ? "Payment Successful" : "Something Went Wrong!"}
                </Text>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter
            justifyContent="center"
            alignItems="center"
            boxShadow="0px -10px 15px #2C2C2C0F"
          >
            <Button
              variant="gradient"
              width="80%"
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              {defaultLanguage?.Continue}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <BrandLoader />
    </>
  );
};
