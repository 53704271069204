import { Box, Heading, Image, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'
import defaultImage from "../../../assets/webapp/marketplace/defaultAssetImage.png"

export const V5DashboardDealershipCard = ({
    height = "",
    width = "",
    children,
    ...rest
}) => {
  return (
   <Box
   height="100%"
   width="100%"
   {...rest}
   >
    {children}
   </Box>
  )
}

const cardVerification = ({url , children , ...rest}) =>{
    return(
        <Box  height="100%" width="100%" backgroundImage={url ? `url(${url})` : `url(${defaultImage})`}  {...rest} >
        {children}
        </Box>
    )
}

const cardPrimaryText = ({tooltip , children}) => {
    return(
        <Tooltip label={tooltip}>
        <Heading color="#002961" fontSize="20px" fontWeight="700">
            {children}
        </Heading>
        </Tooltip>
    )
}

const cardLocationText = ({tooltip , children , ...rest}) => {
    return(
        <Tooltip label={tooltip}>
        <Text color="#484848" fontSize="18px" fontWeight="600" {...rest}>
            {children}
        </Text>
        </Tooltip>
    )
}

const cardCommonText = ({tooltip ,leadingText, value , ...rest}) => {
    return(
        <Tooltip label={tooltip} {...rest}>
        <Text color="#767676" fontSize="16px" fontWeight="400">{leadingText}  <Text color="#2C2C2C" fontSize="16px" fontWeight="600" as="span">{value ? value : "N.A"}</Text> </Text>
        </Tooltip>
    )
}


V5DashboardDealershipCard.Verification = cardVerification ;
V5DashboardDealershipCard.PrimaryText = cardPrimaryText ;
V5DashboardDealershipCard.LocationText = cardLocationText ;
V5DashboardDealershipCard.CommonText = cardCommonText ;
