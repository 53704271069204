import { useEffect, useState } from "react";
import fetchHandler from "../../../services/fetchHandler";
import { Box, Checkbox, Divider, Flex, Image, Text } from "@chakra-ui/react";
import { NoPartnerAppliedPlaceholder } from "../../../components/NoPartnerAppliedPlaceholder/NoPartnerAppliedPloceholder";
import { MarketplaceHoc } from "../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import defaultAvatar from "../../../../assets/user.jpg";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { V5FixedFooterSidebarLayout } from "../../../layout/V5FixedFooterSidebarLayout/V5FixedFooterSidebarLayout";
import { setSelectedArray, setSelectedPartners } from "../slice/jobCycleOwnerSlice";
import { LeadsPopup } from "./LeadsPopup";
import { getFreeLeadsForIndividual } from "../../../services/freeLeads/freeLeads";
import { getUserPurchasedLeadsById } from "../../../services/leadCheck/leadCheck";
import pointerBreicase from "../../../../assets/webapp/leads/pointer-breifcase.svg";
import filledStar from "../../../../assets/webapp/leads/filled-star.svg";
import ratingStar from "../../../../assets/webapp/leads/rating-star.svg";
import { getCheckUserLead } from "../../userLeadsCheck/helper/userLeadsCheck";
import { openQrModal } from "../../dashboard/slice/dashboardSlice";
import { QrPopUp } from "../../../components/ScanQrCodeModal/QrPopUp";

const partnerTypes = [
  {
    name: "New Partners",
    link: "new",
  },
  {
    name: "Confirmed Partners",
    link: "confirmed",
  },
  {
    name: "Ongoing Partners",
    link: "ongoing",
  },
  {
    name: "Completed Partners",
    link: "completed",
  },
  {
    name: "Cancelled Partners",
    link: "cancelled",
  },
];

export const NewPartners = () => {
  const [partners, setPartners] = useState([]);
  const [imageBaseUrl, setImageBaseUrl] = useState(null);
  const { accountDetails, UserTypeValue, currencyDetails } = useSelector((state) => state.user);
  const { selectedPartners, selectedArray} = useSelector(
    (state) => state.jobCycleOwner
  );
  const [params, setParams] = useSearchParams();
  const [jobId, setJobId] = useState(0);
  const [partnerType, setPartnerType] = useState("");
  const [jobType, setJobType] = useState(0);
  const [isLeadsPopUpOpen, setIsLeadsPopupOpen] = useState(false);
  const [freeLeads, setFreeLeads] = useState(null);
  const [subscriptionLeads, setSubscriptionLeads] = useState(null);
  const [premiumLeads, setPremiumLeads] = useState(null);
  const [finalFreeLeads,setFinalFreeLeads] = useState(null);
  const [serviceId,setServiceId] = useState(null);
  const dispatch = useDispatch();
  const navigate=useNavigate();

  const getNewPartners = async () => {
    try {
      const { data } = await fetchHandler(
        `/OperatorMaintenancePartnerList/${jobId}/${jobType}`
      );
      if (data.Status === 200 && Object.keys(data.Data).length > 0) {
        setImageBaseUrl(data.Data.ProfilePicImageBaseUrl);
        if (data.Data.OperatorPartnerAcceptedList) {
          setPartners(data.Data.OperatorPartnerAcceptedList);
        } else if (data.Data.MaintenancePartnerListAcceptedList) {
          setPartners(data.Data.MaintenancePartnerListAcceptedList);
        } else {
          setPartners([]);
        }
      }
    } catch (error) {}
  };

  const selectPartner = (id) => {
    if (!selectedPartners.includes(id)) {
      const partnerList = [...selectedPartners];
      partnerList.push(id);
      dispatch(setSelectedPartners(partnerList));
    }
  };

  const removePartner = (id) => {
    if (selectedPartners.includes(id)) {
      const filteredList = selectedPartners.filter(
        (partnerId) => partnerId !== id
      );
      dispatch(setSelectedPartners(filteredList));
    }
  };

  const getFreeLeadsByIdForIndividual = async (id) => {
    const data = await getFreeLeadsForIndividual();
    if (data) {
      const requiredFreeSubscription = data.find(
        ({ ServiceTypeId }) => ServiceTypeId === id
      );

      setFreeLeads(requiredFreeSubscription);
    }
  };

  const getSubscriptionLeads = async (id) => {
    const data = await getUserPurchasedLeadsById(id);
    if (data.Data === 0) {
    }
  };

  const getUserLeads = async (serviceId, userId) => {
    const data = await getCheckUserLead(serviceId, "normal", userId);
    setFinalFreeLeads(data?.freeData);
    setSubscriptionLeads(data?.subscription)
    setPremiumLeads(data?.premium)
  };
  const renderNewPartners = (data) => {
    const renderingJsx =
      data && data.length > 0 ? (
        data.map((element) => (
          <div
            style={{
              height: "130px",
              width: "550px",
              borderRadius: "5px",
              border: "1px solid #E3E5E8",
              borderColor: selectedPartners.includes(element.OperatorPartnerId)
                ? "#F79C00"
                : "#E3E5E8",
              backgroundColor: selectedPartners.includes(
                element.OperatorPartnerId
              )
                ? "#FFFBF5"
                : "#FFF",
              padding: "6px 12px",
            }}
          >
            <Flex justifyContent="flex-end">
              <Text
                fontSize="12px"
                fontWeight="600"
                color="#FFF"
                borderRadius="20px"
                padding="0px 12px"
                bgColor={
                  element.PartnerVerificationStatus ? "#319F0050" : "#D6000050"
                }
              >
                {element.PartnerVerificationStatus
                  ? "E9 Verified"
                  : "Not Verfied"}
              </Text>
            </Flex>
            <Flex gap=".5rem" width="100%">
              <Flex
                flexDirection="column"
                gap=".5rem"
                justifyContent="center"
                alignItems="center"
              >
                {/* Image and rating */}
                <Image
                  src={
                    element.ProfilePictureFileReference
                      ? `${process.env.REACT_APP_CDN_PVT_URL}${imageBaseUrl}${element.ProfilePictureFileReference}`
                      : defaultAvatar
                  }
                  border="1px solid  #E3E5E8"
                  height="56px"
                  width="56px"
                  borderRadius={"50%"}
                  alt="operator-image"
                />
                <Flex
                  bgColor="#FFCF00"
                  color="#002961"
                  borderRadius="4px"
                  padding="1px 2px"
                  maxW="47px"
                  w="100%"
                  gap="3px"
                  alignItems="center"
                  justifyContent="center"
                  fontWeight="600"
                >
                  <Image src={ratingStar} width="11px" height="11px" />{" "}
                  <Text color="#002961" fontWeight="700">
                    {element.OverAllRating ? element.OverAllRating : 0}
                  </Text>
                </Flex>
              </Flex>
              {/* Other detrais */}
              <Flex flexDirection="column" width="100%">
                <Flex
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Flex flexDirection="column" width="100%"  cursor={"pointer"}
                  onClick={()=>{
                    navigate(`/job-cycle/partner-profile/${element?.OperatorPartnerId}`);
                  }}>
                    <Text fontSize="18px" fontWeight="700">
                      {element.FirstName} {element.LastName}
                    </Text>
                    <Text fontSize="14px" color="#959596" fontWeight="600">
                      {element.EquipmentTypeValue}
                    </Text>
                    <Divider borderColor="#959596" width="100%" />
                  </Flex>
                  <Checkbox
                    isChecked={selectedPartners.includes(
                      element.OperatorPartnerId ? element?.OperatorPartnerId : element?.MaintenancePartnerId
                    )}
                    onChange={(e) => {
                      e.stopPropagation();
                      let id = element.OperatorPartnerId ? element?.OperatorPartnerId : element?.MaintenancePartnerId
                      if (e.target.checked) {
                        selectPartner(id);
                      } else {
                        removePartner(id);
                      }
                    }}
                  />
                </Flex>

                {/*Experience and salary  */}
                <Flex justifyContent="space-between">
                  <Flex flexDirection="column">
                    <Flex>
                      <Image src={pointerBreicase} marginRight="0.25rem" />{" "}
                      <Text fontSize="14px" color="#767676">
                        Experience :{" "}
                        {element.ExperienceYear
                          ? `${element.ExperienceYear} years`
                          : "NA"}
                      </Text>
                    </Flex>
                    <Flex>
                      <Image src={pointerBreicase} marginRight="0.25rem" />{" "}
                      <Text fontSize="14px" color="#767676">
                        Expected Monthly Salary :{" "}
                        {element.MonthlySalary ? element.MonthlySalary : "NA"}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex flexDirection="column">
                    <Text fontSize="14px" color="#959596">
                      Charges
                    </Text>
                    <Text fontSize="16px" fontWeight="600" color="#002961">
                      {currencyDetails.CurrencyValue}{" "}
                      {element.ServiceContactUnitPrice.toFixed(2)}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </div>
        ))
      ) : (
        <Text>No Data available</Text>
      );
    return renderingJsx;
  };

  useEffect(() => {
    if (params) {
      const paramsArray = params.toString().split("&");
      let paramsObject = {};
      paramsArray.forEach((param) => {
        const key = param.split("=")[0];
        const value = param.split("=")[1];
        paramsObject[`${key}`] = value;
      });
      setJobId(paramsObject.orderId);
      setPartnerType(paramsObject.partnerType);
      setJobType(paramsObject.jobType);
    }
  }, [params]);

  useEffect(() => {
    if (jobType && partnerType && jobId) {
      getNewPartners();
    }
  }, [jobType, partnerType, jobId]);

  useEffect(() => {
    if (
      accountDetails && accountDetails.UserDetails && accountDetails.UserDetails.CredentialDetails &&
      partners &&
      partners.length > 0
    ) {
      // for individual
      getFreeLeadsByIdForIndividual(partners[0].ServiceTypeId);
      setServiceId(partners[0].ServiceTypeId);
      getUserLeads(
        partners[0].ServiceTypeId,
        accountDetails.UserDetails.CredentialDetails.UserCredentialId
      );
    }
  }, [partners, accountDetails]);

  useEffect(() => {
    if (freeLeads) {
      getSubscriptionLeads(freeLeads.ServiceTypeId);
    }
  }, [freeLeads]);


  return (
    <V5FixedFooterSidebarLayout
      primaryButtonText="Continue"
      primaryButtonVariant="gradient"
      primaryButtonDisabled={selectedPartners.length === 0}
      primaryButtonClickAction={() => setIsLeadsPopupOpen(true)}
      secondaryText={`Partners Selected : ${selectedPartners.length} `}
      secondaryElement="text"
    >
       <QrPopUp />
      <LeadsPopup
        isOpen={isLeadsPopUpOpen}
        currentSelectionCount={selectedPartners.length}
        freeLeadsCount={
          finalFreeLeads && finalFreeLeads?.LeadRemaining
            ? finalFreeLeads?.LeadRemaining
            : 0
        }
        subscriptionCount={
          subscriptionLeads && subscriptionLeads.LeadRemaining
          ? subscriptionLeads.LeadRemaining
          : 0
        }
        premiumCount={
          premiumLeads && premiumLeads.LeadRemaining
          ? premiumLeads.LeadRemaining
          : 0
        }
        onCloseButtonClick = {() => setIsLeadsPopupOpen(false)}
        id = {serviceId}
        selectedArray={selectedPartners}
        jobId = {jobId}
        preimumData = {premiumLeads}
        subscriptionData = {subscriptionLeads?.LeadsData?.[0]}
      />
      <div style={{ marginRight: "1rem" }}>
        <Flex
          boxShadow="0px 4px 4px 0px #2c2c2c20"
          border="1px solid #E3E5E8"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          padding="1em 2.5em"
          // marginTop="1em"
          display="flex"
          justifyItems="space-between"
        >
          {partnerTypes.map(({ name, link }) => {
            return (
              <Link
                key={name}
                to={`/job-cycle?orderId=${jobId}&jobType=${jobType}&partnerType=${link}`}
                style={{
                  color:
                    partnerType && name.toLowerCase().includes(partnerType)
                      ? "#F79C00"
                      : "",
                  borderBottom:
                    partnerType && name.toLowerCase().includes(partnerType)
                      ? "2px solid #F79C00"
                      : "none",
                  fontWeight: 600,
                }}
              >
                {name}
              </Link>
            );
          })}
        </Flex>
        <Box marginTop="1rem">
          {partners && partners.length > 0 ? (
            <MarketplaceHoc
              marketplaceType="jobs"
              dataArray={partners}
              cardsRenderFunction={renderNewPartners}
            />
          ) : (
            <NoPartnerAppliedPlaceholder orderID={jobId} />
          )}
        </Box>
      </div>
    </V5FixedFooterSidebarLayout>
  );
};
