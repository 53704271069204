export const initialState = {
  data: null,
  initialLoading: false,
  error: null,
  errorMessage: null,
  paginationCount: 0,
  endOfData: false,
  paginationLoading: false,
  cardCount: 12,

  //MyContent
  myContentData: null,
  myContentDataFiltered: null,

  //update popup
  isEditModalOpen: false,
  isDeleteModalOpen: false,
};
