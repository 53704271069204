import { API_CONSTANTS } from "../../../constants/apiConstants";
import { convertToIdValuePairs } from "../../helpers/dataConversion/convertToIdValuePairs";
import {fetchHandler} from "../../../index"

export const getFoodBenefitTypes = async() => {
    try{
        const {data} = await fetchHandler(API_CONSTANTS.GET_FOOD_BENEFIT_LIST)
        if(data.Status === 200 && Object.keys(data.Data).length > 0){
            const requiredData = data.Data.FoodBenifitsList.map(({FoodBenefitsTypeId, FoodBenefitsTypeValue}) => (convertToIdValuePairs(FoodBenefitsTypeId, FoodBenefitsTypeValue)));
           return requiredData
        }
        return null
    }
    catch(error){
        throw error;
    }
}