import { createAsyncThunk } from "@reduxjs/toolkit";
import {getMaterialDataForUpdateStep1byId } from "../../../services/materials/materials";


export const getBasicDetailsForUpdate=createAsyncThunk("edit-material/get-basic-details",async (materialId)=>{
    try {
        const data= await getMaterialDataForUpdateStep1byId(materialId);
        return data;
    } catch (error) {
        throw(error);
    }

})