import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_CONSTANTS } from "../../../../constants/apiConstants";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";
import { fetchHandler } from "../../../../index";
import { getFilterMaterialMarketplaceList } from "../../../services/materials/materials";

export const concreteLoadMoreFilteredDataThunk = createAsyncThunk(
  "concrete/loadMoreFilteredData",
  async (_, { getState }) => {
    const concreteId = convertToBase64("3");
    const {
      filterByIsOriginal,
      filterByEquipmentTypeId,
      filterByEquipmentMakeId,
      filterByCountry,
      filterByState,
      cardCount,
      paginationCount,
    } = getState().concreteMaterialMarketplace;

    let query = "";

    if (concreteId) {
      query += `MaterialTypeId=${concreteId}`;
    }
    if (filterByIsOriginal) {
      query += `&IsOriginal=${filterByIsOriginal}`;
    }
    if (filterByEquipmentTypeId) {
      query += `&EquipmentTypeId=${filterByEquipmentTypeId}`;
    }
    if (filterByEquipmentMakeId) {
      query += `&OEMId=${filterByEquipmentMakeId}`;
    }
    if (filterByCountry) {
      query += `&Country=${filterByCountry}`;
    }
    if (filterByState) {
      query += `&State=${filterByState}`;
    }

    try {
      const data = await getFilterMaterialMarketplaceList(
        cardCount,
        paginationCount * cardCount,
        query
      );

      if (data.Status === 200) {
        if (data.Data.Status) {
          return data.Data;
        } else {
          return [];
        }
      }
    } catch (error) {}
  }
);
