import { useEffect } from "react";
import { NewSideMenuLayout } from "../../../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { getDefaultAppLanguage } from "../../../global/mtData/helpers/localStorage";
import {fetchHandler} from "../../../../../index";
import { V5SidebarLayout } from "../../../../layout/V5SidebarLayout/V5SidebarLayout";

export const MyFreeContact = () => {
  const { UserTypeValue, accountDetails } = useSelector(
    (state) => state.user
  );
  const [freeServices, setFreeServices] = useState(null);
  const defaultLanguage = getDefaultAppLanguage();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      let endpoint =
        UserTypeValue.toLowerCase() === "Company".toLowerCase()
          ? `/GetE9FreeServiceAvailableForCompany?TaxNumber=${btoa(
            accountDetails.CompanyDetails.TaxNumber
          )}`
          : `/GetE9FreeServiceAvailableForUser`;
    
      try {
        const {data}=await fetchHandler(endpoint);
        if (data.Status === 200 && Object.keys(data.Data).length > 0) {
          if (data.Data.E9FreeServicesForUserList) {
            setFreeServices(data.Data.E9FreeServicesForUserList);
          }
        }

      } catch (error) {
      }
    })();
  }, []);

  const handleRedeem = (serviceTypeValue) => {
    switch (serviceTypeValue.toLowerCase()) {

      case "Used Equipment".toLowerCase():
        navigate("/marketplace/MarketPlaceUEM")
        
        break;

      case "Equipment Listing".toLowerCase() : 
        navigate("/equipment-store")
        break;

      case "Maintenance Search".toLowerCase():
        navigate("/near-me/MarketPlaceMP")
        break;

      case "Material Listing".toLowerCase():
        navigate("/materials-type-store-list")
        break;

      case "Operator Search".toLowerCase():
        navigate("/near-me/MarketPlaceOP")
        break;

      case "Dealership Listing".toLowerCase():
        navigate("/my-business")
        break;

      case "Rental Equipment".toLowerCase():
        navigate("/marketplace/MarketPlaceRM")
        break;

      case "Material contact".toLowerCase():
        navigate("/materials-type-store-list")
        break;

      default:
        break;
    }

  }
  return (
    <V5SidebarLayout>
      <div
        style={{
          margin: "0px 52px",
          backgroundColor: "#92929204",
          paddingTop: "1em",
        }}
      >
        <div style={{ marginBottom: "32px" }}>
          <h1 style={{ fontSize: "32px", fontWeight: 700 }}>{defaultLanguage?.My_free_contacts}</h1>
          <p style={{ fontSize: "22px", color: "#2c2c2c80" }}>
            {defaultLanguage?.Redeem_your_Free_Service_Today}
          </p>
        </div>
        <div style={{ maxHeight: "calc(100vh - 230px)", overflowY: "scroll" }}>
          {freeServices ? <table
            style={{
              backgroundColor: "#ffffff",
              boxShadow: "0px 10px 30px #2c2c2c1A",
              width: "100%",
              borderRadius: "4px",
              border: "1px solid #2C2C2C1A",
              maxHeight: "370px",
              overflowY: "scroll"
            }}
          >
            <thead
              style={{
                fontSize: "18px",
                fontWeight: "600",
                textAlign: "left",
                color: "#1E3264",
                borderBottom: "1px solid #2c2c2c1a"
              }}
            >
              <th
                style={{
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  paddingLeft: "20px",
                  width: "35%",
                }}
              >
                Free E9 Services
              </th>
              <th
                style={{
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  width: "30%",
                }}
              >
                Remaining Services / Total Services
              </th>
              <th
                style={{
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  width: "35%",
                }}
              >
                Action
              </th>
            </thead>
            <tbody>
              {freeServices && freeServices.length > 0 ? (
                freeServices.map((service) => (
                  <tr
                    style={{
                      borderBottom: "1px solid #2c2c2c1a",
                      fontSize: "16px",
                    }}
                  >
                    <td
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        paddingLeft: "20px",
                        width: "35%",
                      }}
                    >
                      {service.ServiceTypeValue}
                    </td>
                    <td
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        textAlign: "center",
                        width: "30%",
                      }}
                    >
                      {service.ContactRemaining} / {service.FreeLeadsProvided}
                    </td>
                    <td>
                      <div style={{ display: "flex", gap: "1em" }}>
                        {service?.ContactRemaining === 0 ? (
                          <Button border="1px solid #F97C00" bgColor="#ffff" color="#F67C00" onClick={() => navigate("/subscription/subscription-type")}>Buy More</Button>
                        ) : (
                          <Button variant="gradient" onClick={() => handleRedeem(service?.ServiceTypeValue)}>Redeem</Button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </table> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
            <p>No Free Contact informatiiion Found.</p>
          </div>}
        </div>
      </div>
    </V5SidebarLayout>
  );
};
