import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Flex } from "@chakra-ui/react";

import { ShiftTypeForm } from "../../components/ShiftTypeForm";
import { JobDetailsForm } from "../../components/JobDetailsForm";
import { RouteStepper } from "../../../../components/Stepper/Stepper";
import { EquipmentDetailsForm } from "../../components/EquipmentDetailsForm";
import { NewSideMenuLayout } from "../../../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { decreaseStepperIndex } from "../../slice/operatorSearchSlice";
import { Helmet } from "react-helmet";
import { V5SidebarLayout } from "../../../../layout/V5SidebarLayout/V5SidebarLayout";
import { V5FixedFooterSidebarLayout } from "../../../../layout/V5FixedFooterSidebarLayout/V5FixedFooterSidebarLayout";
import { V5GridFooterSidebarLayout } from "../../../../layout/V5FixedFooterLayoutGrid/V5FixedFooterLayoutGrid";
import { V5FormFooterLayout } from "../../../../layout/V5FormFooterLayout/V5FormFooterLayout";

export const OperatorJobPost = () => {
  const { stepperIndex, stepperRoute } = useSelector(
    (state) => state.operatorSearch
  );
  const dispatch = useDispatch();
  const {isLoading}=useSelector((state) => state.operatorSearch);

    
  const renderForm = (index) => {
    switch (index) {
      case 0:
        return <EquipmentDetailsForm />;
      case 1:
        return <JobDetailsForm />;
      case 2:
        return <ShiftTypeForm />;
      default:
        return <p>No Data Available</p>;
    }
  };

  const formId = (index) => {
    console.log(index);
    switch (index) {
      case 0:
        return "equipment-details-form";
      case 1:
        return "job-details-form";
      case 2:
        return "shift-type-form";
      default:
        return "";
    }
  };
  // return stepperIndex === 0 ? (
  //   <V5FixedFooterSidebarLayout>
  //     <Helmet>
  //       <link
  //         rel="Business Owner - operator partner page"
  //         href="http://equip9.com/e9-services/operator-search/job-post"
  //       />

  //       <meta name="keywords" content="equipment operator" />
  //       <title>
  //         Find Skilled Equipment Operators: Grow Your Business Today
  //       </title>
  //       <meta
  //         name="description"
  //         content="Discover skilled equipment operators to enhance productivity and efficiency.
  //      Find your ideal maintenance partner for business growth."
  //       />
  //       <h1>Skilled Equipment Operators: Your Key to Business Success</h1>
  //       <h2>Explore Our Expert Equipment Operators for Your Business Needs</h2>
  //       <h3>Find Your Ideal Maintenance Partner for Equipment Operation</h3>
  //     </Helmet>

  //     <div
  //       style={{
  //         margin: "0px 52px",
  //         backgroundColor: "#92929204",
  //         paddingTop: "1em",
  //       }}
  //     >
  //       <div style={{ marginBottom: "32px" }}>
  //         <h1 style={{ fontSize: "32px", fontWeight: 700 }}>
  //           Find Operator Partner
  //         </h1>
  //       </div>
  //       <Flex flexDirection="column" gap="1em" height="100%">
  //         <Box h="54px" dropShadow="0px 10px 30px #2C2C2C14" bgColor="#FFFFFF">
  //           <RouteStepper
  //             array={stepperRoute}
  //             activeIndex={stepperIndex}
  //             padding="1em"
  //           />
  //         </Box>
  //         <Box maxH="347px" h="100%" overflowY="scroll" padding="1em 1.5em">
  //           {renderForm(stepperIndex)}
  //         </Box>
  //       </Flex>
  //     </div>

  //     <div
  //       style={{
  //         width: "100%",
  //         backgroundColor: "white",
  //         display: "grid",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         maxHeight: "78px",
  //         gridTemplateColumns: stepperIndex !== 0 ? "1fr 2fr" : "",
  //       }}
  //     >
  //       {stepperIndex !== 0 && (
  //         <Button
  //           border="none"
  //           background="none"
  //           boxShadow="none"
  //           dropShadow="none"
  //           _hover={{ bacckground: "none" }}
  //           maxW="max-content"
  //           onClick={() => {
  //             dispatch(decreaseStepperIndex());
  //           }}
  //         >
  //           {" "}
  //           Go Back
  //         </Button>
  //       )}
  //       <Button
  //         type="submit"
  //         variant="gradient"
  //         w="347px"
  //         form={formId(stepperIndex)}
  //       >
  //         {stepperIndex < stepperRoute.length - 1 ? "Next" : "Finish"}
  //       </Button>
  //     </div>
  //   </V5FixedFooterSidebarLayout>
  // ) : (
  //   <V5GridFooterSidebarLayout
  //     primaryButtonVariant="gradient"
  //     primaryButtonFormId={formId(stepperIndex)}
  //     primaryButtonMaxWidth="347px"
  //     primaryButtonWidth="347px"
  //     primaryButtonText={
  //       stepperIndex < stepperRoute.length - 1 ? "Next" : "Finish"
  //     }
  //     secondaryText="Go Back"
  //     secondaryButtonClick={() => {
  //       dispatch(decreaseStepperIndex());
  //     }}

  //   >
  //     <Helmet>
  //       <link
  //         rel="Business Owner - operator partner page"
  //         href="http://equip9.com/e9-services/operator-search/job-post"
  //       />

  //       <meta name="keywords" content="equipment operator" />
  //       <title>
  //         Find Skilled Equipment Operators: Grow Your Business Today
  //       </title>
  //       <meta
  //         name="description"
  //         content="Discover skilled equipment operators to enhance productivity and efficiency.
  //  Find your ideal maintenance partner for business growth."
  //       />
  //       <h1>Skilled Equipment Operators: Your Key to Business Success</h1>
  //       <h2>Explore Our Expert Equipment Operators for Your Business Needs</h2>
  //       <h3>Find Your Ideal Maintenance Partner for Equipment Operation</h3>
  //     </Helmet>

  //     <div
  //       style={{
  //         margin: "0px 52px",
  //         backgroundColor: "#92929204",
  //         paddingTop: "1em",
  //       }}
  //     >
  //       <div style={{ marginBottom: "32px" }}>
  //         <h1 style={{ fontSize: "32px", fontWeight: 700 }}>
  //           Find Operator Partner
  //         </h1>
  //       </div>
  //       <Flex flexDirection="column" gap="1em" height="100%">
  //         <Box h="54px" dropShadow="0px 10px 30px #2C2C2C14" bgColor="#FFFFFF">
  //           <RouteStepper
  //             array={stepperRoute}
  //             activeIndex={stepperIndex}
  //             padding="1em"
  //           />
  //         </Box>
  //         <Box maxH="347px" h="100%" overflowY="scroll" padding="1em 1.5em">
  //           {renderForm(stepperIndex)}
  //         </Box>
  //       </Flex>
  //     </div>

  //     <div
  //       style={{
  //         width: "100%",
  //         backgroundColor: "white",
  //         display: "grid",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         maxHeight: "78px",
  //         gridTemplateColumns: stepperIndex !== 0 ? "1fr 2fr" : "",
  //       }}
  //     >
  //       {stepperIndex !== 0 && (
  //         <Button
  //           border="none"
  //           background="none"
  //           boxShadow="none"
  //           dropShadow="none"
  //           _hover={{ bacckground: "none" }}
  //           maxW="max-content"
  //           onClick={() => {
  //             dispatch(decreaseStepperIndex());
  //           }}
  //         >
  //           {" "}
  //           Go Back
  //         </Button>
  //       )}
  //       <Button
  //         type="submit"
  //         variant="gradient"
  //         w="347px"
  //         form={formId(stepperIndex)}
  //       >
  //         {stepperIndex < stepperRoute.length - 1 ? "Next" : "Finish"}
  //       </Button>
  //     </div>
  //   </V5GridFooterSidebarLayout>
  // );
  return (
    <V5FormFooterLayout
      title="Find Operator Partner"
      stepperArray={stepperRoute}
      stepperIndex={stepperIndex}
      formId={formId(stepperIndex)}
      primaryButtonText={stepperIndex < stepperRoute.length - 1 ? "Next" : "Finish"}
      secondaryButtonText={stepperIndex > 0 ? "Go Back" : ""}
      secondaryButtonClickAction={() => {dispatch(decreaseStepperIndex())}}
      isLoading={isLoading}
    >
      {renderForm(stepperIndex)}
    </V5FormFooterLayout>
  );
};
