import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useQuery, useQueryClient } from "react-query";
import { useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import * as Yup from "yup";
import {
  getLicenseTypeId,
  submitLicenseDetail,
} from "../../../../../../../../../../ApiEquip9/CommonApi";
import "./LicenseDetailPopCard.css";

export const LicenseDetailPopCard = ({ isOpen, onClose, triggerRefresh }) => {
  const userData = useSelector((state) => state.user);
  const [imagePreview, setImagePreview] = useState(null);
  const [fileType, setFileType] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const toast = useToast();
  const queryClient = useQueryClient();

  const initialValues = {
    LicenseTypeId: "",
    LicenseNumber: "",
    UserLicenseImageFront: null,
    // Remark:''
  };

  const validationSchema = Yup.object().shape({
    LicenseTypeId: Yup.string().required("License Type is required"),
    LicenseNumber: Yup.string()
      .required("License is required")
      .min(2, "minimum 2 characters required")
      .max(16, "charcter are excedding limit")
      .matches(/^[a-zA-Z0-9 ]+$/, "Special characters not allowed"),
    UserLicenseImageFront: Yup.mixed().required("File not selected"),
  });

  const onClosePop=()=>{
    setImagePreview(null);
    onClose();
  }

  const handleSubmit = async (values, { resetForm }) => {
    const Payload = {
      LicenseTypeId: parseInt(values.LicenseTypeId),
      LicenseNumber: values.LicenseNumber,
      UserLicenseImageFront: values.UserLicenseImageFront,
    };

    try {
      const response = await submitLicenseDetail(Payload, userData.token);
      toast({
        title: "",
        description: `${response?.data?.Message?.SuccessMessage}`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      queryClient.invalidateQueries("getLicensedetails");
      triggerRefresh();
      onClose();
      resetForm();
      setImagePreview(null);
      return response;
    } catch (error) {
      toast({
        title: "",
        description: `${error?.data?.Message?.FailMessage}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return null;
    }
  };

  const { data: getLicenseTypeList } = useQuery("getLicenseTypeList", () =>
    getLicenseTypeId(userData.token)
  );
  const GetLicenseList = getLicenseTypeList?.data?.Data?.LicenseTypes;

  return (
    isOpen && (
      <div className="popup-card-overlay">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form className="popup-card-license">
              <button className="popup-card-close" onClick={onClosePop}>
                <ReactSVG
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Cross.svg`}
                />
              </button>
              <div className="">
              <h1 style={{textAlign:"center"}}>Add License Detail</h1>
              </div>
              
              <div className="popup-card-inner">
                <label htmlFor="selectField">
                  Select License Type<span style={{ color: "red" }}>*</span>
                </label>
                <Field as="select" name="LicenseTypeId">
                  <option value="">Select an option</option>
                  {GetLicenseList?.map((item, index) => (
                    <option key={index} value={item.LicenseTypeId}>
                      {item.LicenseTypeValue}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="LicenseTypeId"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="mid-space"></div>
              <div>
                <label htmlFor="inputField" >
                  License Number <span className="asterisk">*</span>
                </label>
                <Field type="text" name="LicenseNumber" placeholder="License Number"  />
                <ErrorMessage
                  name="LicenseNumber"
                  component="div"
                  className="error-message"
                />
              </div>
              <br/>
              <label htmlFor="selectField">
                  Upload License Details<span style={{ color: "red" }}>*</span>
                </label>
              <div className="file-input-container">
                <label
                  htmlFor="custom-file-input"
                  className="custom-file-button"
                >
                   <p className="upload-file-text">Upload</p>
                </label>
                <div className="mid-space"></div>
                <input
                  className="custom-file-input"
                  id="custom-file-input"
                  type="file"
                  accept="image/jpeg, image/png, application/pdf"
                  onChange={(event) => {
                    const file = event.currentTarget.files[0];
                    setFileType(file.type); // Set the file type here
                    setFieldValue("UserLicenseImageFront", file);
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      setImagePreview(reader.result);
                    };
                    if (file) {
                      reader.readAsDataURL(file);
                    }
                    toast({
                      title: "File Uploaded successfully",
                      description: "You have selected a file for upload.",
                      status: "success",
                      duration: 2000,
                      isClosable: true,
                      position: "top",
                    });
                  }}
                />
              </div>
              <div className="mid-space"></div>
              <div className="preview-container">
                {imagePreview && fileType === "application/pdf" ? (
                  <embed
                    src={imagePreview}
                    type="application/pdf"
                    width="100%"
                    height="200px"
                  />
                ) : (
                  <img
                    src={imagePreview}
                    style={{ width: '100%', height: '20vh',display:'flex',alignItems:'center',justifyContent:'center',borderStyle:"dashed",borderWidth:"2px",borderColor:"#A0AEC0",background:"#ccc" }}
                  />
                )}
                ,
              </div>
              <div className="mid-space"></div>
              <ErrorMessage
                name="UserLicenseImageFront"
                component="div"
                className="error-message"
              />
              <button type="submit" disabled={isSubmitting}>
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    )
  );
};
