import React from "react";
import "./ConformationUp.css";
import { CustomModal } from "../../../webapp/components/CustomModal/CustomModal";
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";

export const Popupconformation = ({
  isOpen,
  onClose,
  onConfirm,
  message,
  icon,
  confirmText,
  denyText,
}) => {
  if (!isOpen) return null;

  return (
    <CustomModal
      isOpen={isOpen}
      hasCloseButton={true}
      onCloseButtonClick={onClose}
      maxWidth={"400px"}
    >
      <CustomModal.Header textAlign="center">
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
          gap={"16px"}
        >
          {icon && (
            <img
              style={{
                maxHeight: "50px",
                maxWidth: "50px",
                height: "50px",
                width: "50px",
              }}
              src={icon}
            />
          )}
        </Box>
      </CustomModal.Header>
      <CustomModal.Body>
        {message && (
          <Text
            textAlign="center"
            fontWeight={700}
            fontSize="20px"
            color={"#1A1E21"}
          >
            {message}
          </Text>
        )}
      </CustomModal.Body>
      <CustomModal.Footer>
        <Flex justifyContent={"space-between"} width="100%">
        <Button variant="link"  onClick={onClose}>
            No
          </Button>

          <Button
            type="reset"
            onClick={onConfirm}
            variant="gradient"
            padding={"0 16px"}
          >
            Yes
          </Button>

        </Flex>
      </CustomModal.Footer>
    </CustomModal>
  );
};
