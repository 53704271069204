import React, { useState, useRef } from "react";
import "./OpEquipmentDetail.css";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  GetAllUserEquipments,
  deleteEquipment,
} from "../../../../../../../../../ApiEquip9/CommonApi";
import { LoadingSkelaton } from "../../../../../../../../components/LoadingSkaleton/LoadingSkelton";

export const OpEquipmentDetails = ({
  onEdit,
  setSelectedEquipmentId,
}) => {
  const userData = useSelector((state) => state.user);
  const businessValue=userData?.accountDetails?.UserDetails?.BusinessTypeValue.toLowerCase();
  const queryClient = useQueryClient();
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [equipmentToDelete, setEquipmentToDelete] = useState(null);
  const [expandedEquipmentId, setExpandedEquipmentId] = useState(null);
  const [documentType, setDocumentType] = useState("");
  const [documentImageUrl, setDocumentImageUrl] = useState("");
  const [isDocumentPopupOpen, setIsDocumentPopupOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedEquipmentTypeId, setSelectedEquipmentTypeId] = useState(null);
  const yesButtonRef = useRef(null);

  const deleteEquipmentMutation = useMutation(
    (UserEquipmentId) => deleteEquipment(userData.token, UserEquipmentId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllUserEquipment");
      },
      onError: (error) => {
        console.error("Error deleting equipment:", error);
      },
    }
  );

  const openEditPopup = () => {
    setIsEditModalOpen(true);
  };

  const closeEditPopup = () => {
    setIsEditModalOpen(false);
  };

  const handleEditClick = (equipmentId) => {
    if (setSelectedEquipmentId) {
      setSelectedEquipmentId(equipmentId);
    }
    onEdit();
  };

  const handleToggleExpand = (equipmentId) => {
    if (expandedEquipmentId === equipmentId) {
      setExpandedEquipmentId(null);
    } else {
      setExpandedEquipmentId(equipmentId);
    }
  };

  const openDocumentPopup = (imageUrl) => {
    const fileExtension = imageUrl.split(".").pop().toLowerCase();

    if (["jpg", "jpeg", "png"].includes(fileExtension)) {
      setDocumentType("image");
    } else if (fileExtension === "pdf") {
      setDocumentType("pdf");
    } else {
      return;
    }
    setDocumentImageUrl(imageUrl);
    setIsDocumentPopupOpen(true);
  };


  //

  const {
    data: getAllUserEquipment,
    isLoading,
    isError,
  } = useQuery(
    "getAllUserEquipment",
    () => GetAllUserEquipments(userData.token),
    {
      refetchInterval: 2000,
    }
  );

  const closeDocumentPopup = () => {
    setIsDocumentPopupOpen(false);
  };

  const deleteEquipmentHandler = async () => {
    if (equipmentToDelete) {
      await deleteEquipmentMutation.mutateAsync(equipmentToDelete);
      setEquipmentToDelete(null);
      setConfirmationDialog(false);
    }
  };

  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    return `${day}-${month}-${year.slice(-2)}`;
};

  return (
    <>
      {isLoading ? (
        <div style={{ margin: "33px 33px" }}>
          <LoadingSkelaton />
        </div>
      ) : isError ? (
        <div>No Work Details  added yet</div>
      ) : (
        <>
          <div className="card-layout">
            {
              getAllUserEquipment?.data?.Data?.UserEquipmentDetails?.length>0?(<>
              {getAllUserEquipment?.data?.Data?.UserEquipmentDetails?.map(
              (item) => (
                <React.Fragment key={item.UserEquipmentId}>
                  <div className="get-verify-car">
                    <div className="Edit-and-delete">
                      <div
                        onClick={() => handleEditClick(item.UserEquipmentId)}
                      >
                        <div className="edit-button">
                          <ReactSVG
                            src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Edit.svg`}
                          />
                        </div>
                      </div>
                      <div
                        className="get-delete-do"
                        onClick={() => {
                          setEquipmentToDelete(item.UserEquipmentId);
                          setConfirmationDialog(true);
                        }}
                      >
                        <ReactSVG
                          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/garbage.svg`}
                        />
                      </div>
                      <div className="verfied">
                        <div className="added-date-and-verified">
                          <div
                            style={{
                              height: "23px",
                              width: "75px",
                              borderRadius: "23px",
                              fontSize: "10px",
                              textAlign: "center",
                              paddingTop: "5px",
                              background:
                                item.IsE9Verified === 0
                                  ? "rgba(214, 0, 0, 0.376)"
                                  : "#319f00",
                              color: "white",
                            }}
                          >
                            {item.IsE9Verified === 0
                              ? "Not Verified"
                              : "Verified"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Doc-support-blockk">
                      <div className="EquipmentType">
                        
                        Equipment Type :
                        <span className="get-document-numberr">
                          {item.EquipmentTypeValue}
                        </span>{" "}
                      </div>
                      <div className="EquipmentType">
                        Industry Type:
                        <span className="get-document-numberr">
                          {item.IndustryValue}
                        </span>{" "}
                      </div>
                      <div className="EquipmentType">
                        Equipment Make:{" "}
                        <span className="get-document-numberr">
                          {item.EquipmentMakeName}
                        </span>
                      </div>
                      <div className="EquipmentType">
                        {
                        (businessValue==='maintenance partner')&&(
                               <p><span>Expertise:</span><span className="get-document-numberr">{item.ExpertiseArea
                               }</span></p>
                        )
                        } 
                        <p className="certified-detail">
                          Are you Certified for this equipment?{" "}
                          {item.AreYouCertified === 0 ? (
                            <span className="are-you-certified">No</span>
                          ) : (
                            <span className="are-you-certified">Yes</span>
                          )}
                        </p>
                        {item.AreYouCertified === 1 && (
                          <>
                            <button
                              onClick={() =>
                                handleToggleExpand(item.UserEquipmentId)
                              }
                            >
                              {expandedEquipmentId === item.UserEquipmentId ? (
                                <div className="view-document-detail-Equipment">
                                  View less
                                </div>
                              ) : (
                                <div
                                  className="view-document-detail-Equipment
                      "
                                >
                                  View more...
                                </div>
                              )}
                            </button>
                            {expandedEquipmentId === item.UserEquipmentId && (
                              <div className="extended-document-content">
                                <div className="EquipmentType">
                                  CertificateNumber:
                                  <span className="get-document-numberr">
                                    {item.CertificateNumber}
                                  </span>
                                </div>
                                <div className="EquipmentType">
                                  IssuerName:
                                  <span className="get-document-numberr">
                                    {item.IssuerName}
                                  </span>
                                </div>
                                <div className="EquipmentType">
                                  ValidDate:
                                  <span className="get-document-numberre">
                                    {formatDate(item.ValidDate.split(' ')[0])}
                                  </span>
                                </div>
                                <div
                                  className="view-document-detai"
                                  onClick={() =>
                                    openDocumentPopup(
                                      `${process.env.REACT_APP_CDN_PVT_URL}UserEquipmentCertificates/${item.CertificateFileReference}`
                                    )
                                  }
                                >
                                  View Document
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
            )}
              
              </>):(<>
              
               <p className="no-equipment-added">No Equipment Added Yet</p>
              
              </>)    
            }
           
            {confirmationDialog && (
              <div className="modal-backdrop">
                <div className="confirmation-dialog">
                  <div className='deleteHeading'>
                            <ReactSVG
                                          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/HeadDelete.svg`}
                                      />
                            </div>
                              <div style={{marginTop:"12px"}}>
                              <p className="conformation-text">
                    Are you sure you want to delete this document?
                  </p>

                              </div>
                  

                  <button
                    ref={yesButtonRef}
                    style={{
                      background: "#C6C7C8",
                      transition: "0.3s",
                    }}
                    onMouseOver={() => {
                      yesButtonRef.current.style.background =
                        "linear-gradient(90deg, var(--primary), var(--tertiary))";
                    }}
                    onMouseOut={() => {
                      yesButtonRef.current.style.background = "#C6C7C8";
                    }}
                    onClick={deleteEquipmentHandler}
                  >
                    Yes
                  </button>

                  <button
                    style={{
                      background:
                        "linear-gradient(90deg, var(--primary), var(--tertiary))",
                    }}
                    onClick={() => {
                      setEquipmentToDelete(null);
                      setConfirmationDialog(false);
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            )}

            {isDocumentPopupOpen && (
              <div className="document-popup-container">
                <div className="document-popup">
                  {documentType === "pdf" ? (
                    <embed
                      src={documentImageUrl}
                      type="application/pdf"
                      width="100%"
                      height="200px"
                    />
                  ) : (
                    <img
                      src={documentImageUrl}
                      alt="Document"
                      style={{ width: "300px", height: "200px" }}
                    />
                  )}
                  <button className="close-button" onClick={closeDocumentPopup}>
                    <ReactSVG
                      src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Cross.svg`}
                    />
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
