import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import {store} from "../src/webapp/app/store"
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga";
import { QueryClient, QueryClientProvider} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
import fetchHandler from "./webapp/services/fetchHandler";

const measurementId = process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID;
ReactGA.initialize(measurementId);

// Query Client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
    <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
  <ReactQueryDevtools initialIsOpen={false} />
</QueryClientProvider>
  </React.StrictMode>
);

export { fetchHandler}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
