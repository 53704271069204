import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPurchasedLeadsById } from "../../../services/leadCheck/leadCheck";

export const getIndustryLeadThunk = createAsyncThunk("/leadCheck/industry", async(leadsId, {rejectWithValue}) => {
    try{
        const data = await getPurchasedLeadsById(leadsId);
      return data
    }
    catch(error){
       rejectWithValue(error)
       throw error
    }
})