import { useState } from "react";
import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import checkedIcon from "../../../../assets/webapp/pop-up/pop-up-checked-icon.svg";
import { Rating } from "./rating";

export const GiveRatings = ({
  isOpen = false,
  onClick = () => {},
  onNoThanksClick = () => {},
}) => {
  const [behaviourRating, setBehaviourRating] = useState(0);
  const [workRating, setWorkRating] = useState(0);
  const [punctualityRating, setPunctualityRating] = useState(0);

  const handleRatingsClick = () => {
    onClick({behaviourRating, workRating, punctualityRating})
  }
  return (
    <Modal isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex
            flexDirection="column"
            gap="0.5rem"
            justifyContent="center"
            alignItems="center"
            paddingTop="1rem"
          >
            <Image src={checkedIcon} height="42px" width="82px" />
            <Text fontWeight={700} fontSize="24px" color="#1A1E21">
              Give Ratings
            </Text>
          </Flex>
        </ModalHeader>
        <ModalBody marginBottom="2rem">
          <Flex flexDirection="column" gap="1rem">
            <Flex justifyContent="space-between" alignItems="center">
              <Text
                minW="100px"
                fontSize="18px"
                fontWeight={700}
                color="#002961"
              >
                Behaviour
              </Text>
              <Rating onRatingChange={(value) => setBehaviourRating(value)} />
              <Text color="#002961" fontSize="18px" fontWeight={700}>
                <span style={{ color: "#F79C00" }}>{behaviourRating}</span> / 5
              </Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Text
                minW="100px"
                fontSize="18px"
                fontWeight={700}
                color="#002961"
              >
                Punctuality
              </Text>
              <Rating onRatingChange={(value) => setPunctualityRating(value)} />
              <Text color="#002961" fontSize="18px" fontWeight={700}>
                <span style={{ color: "#F79C00" }}>{punctualityRating}</span> /
                5
              </Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Text
                minW="100px"
                fontSize="18px"
                fontWeight={700}
                color="#002961"
              >
                Work
              </Text>
              <Rating onRatingChange={(value) => setWorkRating(value)} />
              <Text color="#002961" fontSize="18px" fontWeight={700}>
                <span style={{ color: "#F79C00" }}>{workRating}</span> / 5
              </Text>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter
          justifyContent="space-between"
          alignItems="center"
          boxShadow={"0px -10px 15px 0 #2c2c2c25"}
        >
          <Button variant="link" onClick={onNoThanksClick}>
            No Thanks
          </Button>
          <Button variant="gradient" onClick={handleRatingsClick}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
