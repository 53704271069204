export const GetValueFromConvertedDateTime = (value, valueType) => {
  switch (valueType.toLowerCase()) {
    case "Day".toLowerCase():
      return value.split(",")[0];
    case "Date".toLowerCase():
      return value.split(",")[1];
    default:
      return null;
  }
};

export const ConvertSecondsToTime = (seconds) => {
  var hours = Math.floor(seconds / (60 * 60));

  var divisor_for_minutes = seconds % (60 * 60);
  var minutes = Math.floor(divisor_for_minutes / 60);

  var divisor_for_seconds = divisor_for_minutes % 60;
  var seconds = Math.ceil(divisor_for_seconds);

  var ConvertedTime = {
    h: hours,
    m: minutes,
    s: seconds,
  };
  return ConvertedTime;
};
