import { API_CONSTANTS } from "../../../constants/apiConstants";
import { fetchHandler } from "../../../index";
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData";

export const getUserAssetById = async (id) => {
  try {
    if (!id) {
      throw new Error("parameter cannot be empty");
    }
    const { data } = await fetchHandler(`/GetUserAssetDetailsByAssetId/${id}`);
    if (data.Status === 200 && Object.keys(data.Data).length > 0) {
      return data.Data;
    } else {
      const error = new Error();
      error.message = data.Message.FailMessage;
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

export const getAssetMarketplaceListedDetails = async (id) => {
  try {
    if (!id) {
      throw new Error("parameter cannot be empty");
    }
    const { data } = await fetchHandler(
      `/GetAllMarketPlaceDetailsForAssetListedOn/${id}`
    );
    if (data.Status === 200 && Object.keys(data.Data).length > 0) {
      return data.Data.MarketPlaceDetailsForAsset;
    } else {
      const error = new Error();
      error.message = data.Message.FailMessage;
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

export const getUserAssets = async (pageLimit, pageNumber) => {
  try {
    if (isNaN(Number(pageLimit)) && isNaN(Number(pageNumber))) {
      throw new Error("Parameters can only be numbers");
    }
    if (pageLimit < 0 && pageNumber < 0) {
      throw new Error("Please enter whole numbers as parameters");
    }
    const { data } = await fetchHandler(`/GetUserNewAssetList/12/1`);
    if (data.Status === 200 && Object.keys(data.Data).length > 0) {
      return {
        data: data.Data.AssetDetails,
        imageBaseReference: data.Data.AssetImageBaseReference,
      };
    } else {
      const error = new Error();
      error.message = data.Message.FailMessage;
      throw error;
    }
  } catch (error) {
    return error;
  }
};

export const deleteUserAssetDetails = async (id) => {
  try {
    const { data } = await fetchHandler(
      `/DeleteNewAssetsDetails/${id}`,
      {},
      {},
      {},
      API_CONSTANTS.HTTP_DELETE
    );
  } catch (error) {
    throw error;
  }
};

export const deleteUserAssetDocuments = async (id) => {
  try {
    const { data } = await fetchHandler(
      `/DeleteNewAssetsDocumentDetails/${id}`,
      {},
      {},
      {},
      API_CONSTANTS.HTTP_DELETE
    );
  } catch (error) {
    throw error;
  }
};
export const deleteUserAssetImages = async (id) => {
  try {
    const { data } = await fetchHandler(
      `/DeleteNewAssetsImageDetails/${id}`,
      {},
      {},
      {},
      API_CONSTANTS.HTTP_DELETE
    );
  } catch (error) {
    throw error;
  }
};
export const deleteUserAssetVideos = async (id) => {
  try {
    const { data } = await fetchHandler(
      `/DeleteAssetVideoDetails/${id}`,
      {},
      {},
      {},
      API_CONSTANTS.HTTP_DELETE
    );
  } catch (error) {
    throw error;
  }
};
export const deleteAllEquipmentDetails = async (id) => {
  try {
    await deleteUserAssetDetails(id);
    await deleteUserAssetDocuments(id);
    await deleteUserAssetImages(id);
    await deleteUserAssetVideos(id);
  } catch (error) {
    throw error;
  }
};

export const createE9MarketplaceAssetDetails = async (body) => {
  try {
    if(!body){
        const typeError = new Error();
        typeError.message = "Parameter is required."
        throw typeError;
    }
    const { data } = await fetchHandler(
      "/CreateE9MarketPlaceDetails",
      {},
      {},
      convertToFormData(body),
      API_CONSTANTS.HTT_POST
    );
    if (data.Status === 201 && Object.keys(data.Data).length > 0) {
      return data.Data.LastId;
    } else {
      const error = new Error();
      error.message = data.Message.FailMessage;
      throw error;
    }
  } catch (error) {
    throw error;
  }
};


export const createE9MarketPlaceContactRedeemDetails = async (body) => {
  try {
    if(!body){
        const typeError = new Error();
        typeError.message = "Parameter is required."
        throw typeError;
    }
    const { data } = await fetchHandler(
      API_CONSTANTS.CREATE_E9_MARKET_PLACE_REDEEM_DETAILS,
      {},
      {},
      convertToFormData(body),
      API_CONSTANTS.HTT_POST
    );
   return data;
  } catch (error) {
    throw error;
  }
};

export const updateE9MarketplaceContactRedeemedDetails = async (body) => {
  try {
    if(!body){
        const typeError = new Error();
        typeError.message = "Parameter is required."
        throw typeError;
    }
    const { data } = await fetchHandler(
      API_CONSTANTS.UPDATE_E9_MARKETPLACE_REDEEM_DETAILS,
      {},
      {},
      body,
      API_CONSTANTS.HTTP_PUT
    );
   return data;
  } catch (error) {
    throw error;
  }
};
