import { createSlice } from "@reduxjs/toolkit";
import { getPostLoginAdvertisementThunk } from "../thunks/getPostLoginAdvertisementThunk";

export const  advertisementSlice = createSlice({
    name: "advertisement",
    initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
  },

  extraReducers : (builder) => {
    builder.addCase(getPostLoginAdvertisementThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
    }).addCase(getPostLoginAdvertisementThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
    }).addCase(getPostLoginAdvertisementThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
    })}
});

export default advertisementSlice.reducer

