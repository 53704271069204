import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalOverlay, Flex, ModalContent , Heading, Box,Button, Text} from "@chakra-ui/react";
import { ReactSVG } from "react-svg";


import { EquipmentRentalLayout } from "../../layout/equipmentRentalLayout/EquipmentRentalLayout";
import { subString } from "../../helpers/dataConversion/substring";
import { PartnerDetailsCard } from "./PartnerDetailsCard";
import AvatarIcon from "../../../assets/user.jpg";
import "./PartnerDetailsPage.css"
import { viewOperatorMantenancePartnerProfile } from "../../services/jobsPosted/jobsPosted";
import { CloseIcon } from "@chakra-ui/icons";

export const PartnerDetailsPage = () => {
    const {partnerId} = useParams();
    const userData = useSelector((state) => (state.user));
    const [certificateReference, setCertificateReference] = useState(null);
    const [licenseBaseReference, setLicenseReference] = useState(null);
    const [documentDetails, setDocumentDetails] = useState(null);
    const [equipmentDetails, setEquipmentDetails] = useState(null);
    const [experienceDetails, setExperienceDetails] = useState(null);
    const [licenseDetails, setLicenseDetails] = useState(null);
    const [profileDetails, setProfileDetails] = useState(null);
    const [profilePictureBaseUrl, setProfilePictureBaseUrl] = useState(null);
    const [salarySlipImageBaseUrl, setSalarySlipImageBaseUrl] = useState(null);
    const [verificationDocumentBaseUrl, setVerificationDocumentBaseUrl] = useState(null); 
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageReference, setSelectedImageReference] = useState(null);
    const [selectedDocumentName, setSelectedDocumentName] = useState(null);


    useEffect(() => {
        (async() => {
        
          const data = await viewOperatorMantenancePartnerProfile(partnerId);

           if(data.Status === 200){
               
               setDocumentDetails(data.Data.PartnerDocumentDetails);
               setEquipmentDetails(data.Data.PartnerEquipmentDetails);
               setExperienceDetails(data.Data.PartnerExperienceDetails);
               setLicenseDetails(data.Data.PartnerLicenseDetails);
               setProfileDetails(data.Data.PartnerProfileDetails);
               setCertificateReference(data.Data.CertificateImageBaseUrl);
               setLicenseReference(data.Data.LicenseImageBaseUrl);
               setProfilePictureBaseUrl(data.Data.ProfilePicImageBaseUrl);
               setVerificationDocumentBaseUrl(data.Data.VerificationDocumentImageBaseUrl);
               setSalarySlipImageBaseUrl(data.Data.SalarySlipImageBaseUrl)
            }

           
        })()
    }, [])

return(
    <>
    <Modal isCentered isOpen={modalOpen}>
        <ModalOverlay />
        <ModalContent>
        <Box
          position={"absolute"} 
          top={"-20px"} 
          right={"15px"} 
         >
            <Button
              onClick={() => setModalOpen(false)}
              boxShadow="0px 1.5px 3px 0px #0000000F"
              borderRadius={"50%"}
              backgroundColor={"white"}
              height={"42px"}
              width={"42px"}
            >
              <CloseIcon width={"9px"} stroke={"#141619"} />
            </Button>
          </Box>
          <ModalHeader textAlign={"center"}>
            <Flex alignItems={"center"} justifyContent={"center"}>
                <Text textAlign="center" fontWeight={700} fontSize="20px" color={"#1A1E21"}>{ selectedDocumentName && selectedDocumentName ? selectedDocumentName: "Document"}</Text>
              {/* <ReactSVG
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Cross.svg`}
                onClick={() => setModalOpen(false)}
                style={{ cursor: "pointer" }}
              />  */}
            </Flex>
          </ModalHeader>
          <ModalBody>
             {selectedImage ? (
              <img
                src={`${process.env.REACT_APP_CDN_PVT_URL}${selectedImageReference ? selectedImageReference : "" }${selectedImage}`}
                style={{height: "90%", width: "90%", borderRadius: "8px", margin: "0 auto", marginBottom: "1em" }}
              />
            ) : (
              <></>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <EquipmentRentalLayout>
       <Flex style={{background: "#fffbf5",padding: "1em 1.25em", gap: "1em"}} justifyContent={"space-between"} marginTop={"5em"} marginLeft={"52px"}>
        <Flex gap={"1em"} >
        <Flex style={{height: "60px", width: "60px", borderRadius: "50%"}} justifyContent={"center"} alignItems={"center"} direction={"column"} >
        {/* <img  style={{height: "100%", width: "100%", borderRadius: "50%"}}src={profilePictureBaseUrl && profileDetails ? `${process.env.REACT_APP_CDN_PVT_URL}${profilePictureBaseUrl}${profileDetails.ProfilePictureFileReference}` : AvatarIcon}/> */}
        <img
            src={
              profileDetails?.ProfilePictureFileReference
                ? `${process.env.REACT_APP_CDN_PVT_URL}${profilePictureBaseUrl}${profileDetails?.ProfilePictureFileReference}`
                : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Avatar.png`
            }
            alt="User Avatar"
            style={{height: "100%", width: "100%", borderRadius: "50%"}}
          /> 


    
              <Flex justifyContent={"center"} alignItems={"center"} gap={"3px"} w={"max-content"} backgroundColor={"#FFCF00"} padding={"1px 13px"} borderRadius={"4px"}>
                <ReactSVG
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/opmp/blueStar.svg`}
                />
                <Text fontSize={"16px"} fontWeight={"700"} color={"#1A1E21"}>
                  {
                    profileDetails?.OverAllRating
                  }
                </Text>
              </Flex>
            
           
        
          
        </Flex>
        <div style={{display: "flex", flexDirection:"column", gap: "0.25em", padding: "0 52px"}}>
            <h1 style={{fontSize: "24px", fontWeight: "700",color: "#141619"}}>{profileDetails ? `${profileDetails.FirstName} ${profileDetails.LastName}` : "NA"}</h1>
            <Flex gap={"48px"}>
              <Flex justifyContent={"center"} alignItems={"center"} gap={"10px"}>
              <img
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/e9-services/bag.svg`}
                alt="bag"
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            <p style={{fontSize: "20px", fontWeight:"600",color:"#484848"}}>Work Experience:<span style={{color:"#002961"}}> {" "}{profileDetails && (profileDetails.ExperienceYears || profileDetails.ExperienceMonths) ? `${profileDetails.ExperienceYears}.${profileDetails.ExperienceMonths}`  : 0} {" "}Years</span></p>

              </Flex>

              <Flex justifyContent={"center"} alignItems={"center"} gap={"10px"}>
              <img
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/e9-services/pocket.svg`}
                alt="bag"
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            <p style={{fontSize: "20px", fontWeight:"600",color:"#484848"}}>Expected Salary: <span style={{color:"#002961"}}>{" "}{`₹ ${profileDetails && profileDetails.MonthlySalary ? ""+profileDetails.MonthlySalary : "NA"}`}</span></p>

              </Flex>

            </Flex>
        </div>
        </Flex>
        <Flex background={profileDetails?.IsE9Verified === 1 ?"#319F00" :"#D6000080"} h={"max-content"} padding={" 0 12px"} borderRadius={"20px"} fontSize={"12px"} fontWeight={"600"} color={"#FEFEFE"}>
          {profileDetails?.IsE9Verified === 0 ? "Not verified" : "verified"}
        </Flex>
        </Flex>
        <div style={{display: "flex", flexDirection:"column", gap: "0.5em", margin: "1em 52px",padding : "10px"}}>
            <p style={{fontSize: "24px", fontWeight: 700, textTransform: "uppercase"}}>Equipment Profile</p>
            <div style={{display: "flex", gap: "0.75em", overflowX: equipmentDetails && equipmentDetails.length > 0 ?  "scroll" : ""}}>
                {equipmentDetails && equipmentDetails.length > 0 ? equipmentDetails.map((equipment) => (
                <PartnerDetailsCard
                headingKey="Equipment Type"
                descriptionKey="Equipment Make"
                headingValue={ equipment.EquipmentTypeValue ? subString(12,equipment.EquipmentTypeValue) : "NA"}
                imageLink={equipment.CertificateFileReference}
                descriptionValue={equipment.OEMName ? subString(12,equipment.OEMName) : "NA"}
                    onClick={() => {
                    setSelectedImage(equipment.CertificateFileReference);
                    setSelectedDocumentName(equipment.EquipmentTypeValue);
                    setSelectedImageReference(certificateReference)
                    setModalOpen(true)}}
                    />
                )) : <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>Nothing to show.</p></div>}
            </div>
            </div>
            <div style={{display: "flex", flexDirection:"column", gap: "0.5em", margin: "1em 52px",padding : "10px", backgroundColor: "#F5F5F5"}}>
            <p style={{fontSize: "24px", fontWeight: 700, textTransform: "uppercase"}}>License</p>
            <div style={{display: "flex", gap: "0.75em", overflowX: licenseDetails && licenseDetails.length > 0 ?  "scroll" : ""}}>
                {licenseDetails && licenseDetails.length > 0 ? licenseDetails.map((license) => (
                    
                <PartnerDetailsCard
                headingKey="License Type Type"
                descriptionKey="License Number"
                headingValue={license.LicenseTypeValue ? subString(12,license.LicenseTypeValue) : "NA"}
                descriptionValue={license.LicenseNumber ? subString(12,license.LicenseNumber.toString()) : "NA"}
                imageLink={license.LicenseFileReference}
                certificateName="View License"
                onClick={
                    () => {
                    setSelectedImage(license.LicenseFileReference);
                    setSelectedDocumentName(license.LicenseTypeValue);
                    setSelectedImageReference(licenseBaseReference);
                    setModalOpen(true)
                    }
                }

                />

                )) : <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>Nothing to show.</p></div>}
            </div>
            </div>
            <div style={{display: "flex", flexDirection:"column", gap: "0.5em", margin: "1em 52px",padding : "10px",}}>
            <p style={{fontSize: "24px", fontWeight: 700, textTransform: "uppercase"}}>Verification Document</p>
            <div style={{display: "flex", gap: "0.75em", overflowX: documentDetails && documentDetails.length > 0 ?  "scroll" : ""}}>
                {documentDetails && documentDetails.length > 0 ? documentDetails.map((document) => (
                 
                <PartnerDetailsCard 
                headingKey="License Type Type"
                descriptionKey="License Number"
                headingValue={document.VerificationDocumentName ? subString(12,document.VerificationDocumentName): "NA"}
                descriptionValue={document.DocumentNumber ? subString(12, document.DocumentNumber.toString()): "NA"}
                imageLink={document.DocumentFileReference}
                onClick={() => {
                    setSelectedImage(document.DocumentFileReference);
                    setSelectedDocumentName(document.VerificationDocumentName);
                    setSelectedImageReference(verificationDocumentBaseUrl);
                    setModalOpen(true)
                }}
                />
              
                )) : <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>Nothing to show.</p></div>}
            </div>
            </div>

            <div style={{display: "flex", flexDirection:"column", gap: "0.5em",  margin: "1em 52px",padding : "10px", backgroundColor: "#F5F5F5"}}>
            <p style={{fontSize: "24px", fontWeight: 700, textTransform: "uppercase"}}>Experience</p>
            <div style={{display: "flex", gap: "0.75em", overflowX: experienceDetails && experienceDetails.length > 0 ?  "scroll" : ""}}>
                {experienceDetails && experienceDetails.length > 0 ? experienceDetails.map((experience) => (<div style={{minWidth: "300px", border: "4px"}}>
                   <PartnerDetailsCard 
                   headingKey = "Company Name"
                   descriptionKey="Location Name"
                   subDescriptionKey="Experience"
                   headingValue={experience.CompanyName ? experience.CompanyName : "NA"}
                   descriptionValue={experience.CompanyCity  && experience.CompanyState ? `${experience.CompanyCity}, ${experience.CompanyState}` : "NA"}
                subDescriptionValue={experience.CompanyExperienceYear >= 1 || experience.CompanyExperienceMonth >= 1 ? `${experience.CompanyExperienceYear}.${experience.CompanyExperienceMonth} Years` : "NA" }
                    imageLink={experience.SalarySlip}
                    certificateName="View Salary Slip"
                    onClick = {() => {
                    setSelectedImage(experience.SalarySlip);
                    setSelectedDocumentName(experience.CompanyName);
                    setSelectedImageReference(salarySlipImageBaseUrl);
                    setModalOpen(true)
                    }}
                   />
                </div>)) : <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>Nothing to show.</p></div>}
            </div>
            </div>
      


     
      </EquipmentRentalLayout>
      </>
)
}