import { getLocalStorageItems } from "../../../../helpers/localStorage/localStorage";

export const subscriptionInitialState = {
  selectedSubscriptionType: getLocalStorageItems("SUBSCRIPTION_TYPE") || null,
  leadsCount: localStorage.getItem("LEADS_COUNT")
    ? JSON.parse(localStorage.getItem("LEADS_COUNT"))
    : {},
  subscriptionTableData: getLocalStorageItems("SUBSCRIPTION_SERVICES") || [],
  transactionState: getLocalStorageItems("SUBSCRIPTION_TRANSACTION_STATE")
    ? getLocalStorageItems("SUBSCRIPTION_TRANSACTION_STATE")
    : null,
  orderId: getLocalStorageItems("SUBSCRIPTION_ORDER_ID") || null,
  checkoutSubsctiptionDetails: null,
  checkoutTransactionDetails: null,
  checkoutTaxDetails: null,
  lastE9TransactionId: getLocalStorageItems("LAST_E9_TRANSACTION_ID") || null,
  isLoading: false,
  isError: false,
  errorMessage: null,
  userSubsTransOfferDetailId : null,
  updatedNetPayble : null,
  updatedGstTax : null,
  updatedTotalAmount : null ,
  addressModal : false,
};
