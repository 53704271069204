import { Accordion } from "@chakra-ui/accordion";
import { AccordianElement } from "../../../../components/AccordianElement/AccordianElement";
import { Field, Formik } from "formik";
import { FormikInput } from "../../../../components/Input/FormikInput";
import { Flex } from "@chakra-ui/layout";
import { ImageUpload } from "../../../../components/ImageUpload/ImageUpload";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  addImageToMaintenanceJobDetail,
  createQuickMaintenanceJob,
} from "../../../../services/createMaintenanceJob/createMaintenanceJob";
import { useToast } from "@chakra-ui/react";
import { LocationInput } from "../../../../components/LocationInput/LocationInput";
import { resetMaintenanceSearchSlice } from "../../slice/maintenanceJobPostSlice";
import { useNavigate } from "react-router-dom";

export const QuickMaintenanceForm = () => {
  const {
    jobType,
    maintenenaceType,
    repairType,
    equipmentType,
    equipmentMake,
    equipmentModel,
    equipmentAge,
    serviceDetails
  } = useSelector((state) => state.maintenanceSearch);
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {UserTypeValue, accountDetails} = useSelector((state) => (state.user));

  const postMaintenanceJobDetail = async (body) => {
    try {
      const data = await createQuickMaintenanceJob(body);
      toast({
        description: data.message,
        position: "top-right",
        isClosable: true,
        status: "success",
      });
      return data;
    } catch (error) {
      console.log(error.message);
      return toast({
        title: "Error",
        description: `${error.message}`,
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };

  const postImage = async (body) => {
    try {
      const data = await addImageToMaintenanceJobDetail(body);
      return toast({
        description: data.message,
        position: "top-right",
        isClosable: true,
        status: "success",
      });
    } catch (error) {
      return toast({
        title: "Error",
        description: `${error.message}`,
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };
  return (
    <Formik
      initialValues={{
        _equipmentImage: "",
        _jobStartDate: "",
        _location: "",
        _description: "",
        _jobLocation: "",
      }}
      validationSchema={Yup.object().shape({
        _jobStartDate: Yup.date().required("This field is required."),
        _description: Yup.string()
          .min(20, "Description requires atleast 20 characters.")
          .max(100, "Description can have atmost 100 characters"),
        _equipmentImage: Yup.mixed()
          .required("Equipment Image is required.")
          .test(
            "fileSize",
            "File should not be greater than 5 MB",
            (value) => !value || value.size <= 1000000 * 5
          )
          .test(
            "fileFormat",
            "Unsupported Format",
            (value) =>
            value && value.type && ["jpg", "jpeg", "png", "pdf"].includes(value.type.split("/")[value.type.split("/").length - 1])
          ),
      })}
      onSubmit={async (values) => {
        const body = {
          ServiceTypeId: parseInt(serviceDetails.ServiceTypeId),
          MaintenanceCategoryId: parseInt(jobType.id),
          MaintenanceTypeId: parseInt(maintenenaceType),
          RepairTypeId: repairType ? parseInt(repairType) : "",
          EquipmentTypeId: parseInt(equipmentType.id),
          EquipmentMakeId: parseInt(equipmentMake),
          EquipmentModelId: parseInt(equipmentModel),
          EquipmentAgeId: parseInt(equipmentAge),
          StartDate: values._jobStartDate.split("-").reverse().join("/"),
          AdditionalEquipmentDetail: values._description,
          WorkLocation: values._jobLocation.name,
          WorkLocationLatitude: values._jobLocation.lat,
          WorkLocationLongitude: values._jobLocation.lng,
        };
         if(UserTypeValue === "Company"){
          body.TaxNumber = accountDetails.CompanyDetails.TaxNumber
         }
        try {
          const { data } = await postMaintenanceJobDetail(body);
          // console.log(data);
          if (values._equipmentImage) {
            await postImage({
              MaintenanceJobImage: values._equipmentImage,
              MaintenanceJobDetailId: data.LastMaintenanceJobDetailId,
            });
          }
          dispatch(resetMaintenanceSearchSlice());
          navigate("/dashboard");
        } catch (error) {}
      }}
    >
      {({ setFieldValue, errors, touched, handleSubmit }) => (
        <form id="quick-maintenance-form" onSubmit={handleSubmit}>
          <Accordion allowMultiple>
            <Flex flexDirection="column" gap="1em">
              <AccordianElement title="Job Details" paddingBottom="1em">
                <Flex wrap="wrap" gap="1em" justifyContent="space-between">
                  <FormikInput
                    type="date"
                    label="Job Start Date"
                    name="_jobStartDate"
                    isRequired={true}
                    placeholder="Select Date ..."
                    maxWidth="450px"
                  />
                  <Field name="_jobLocation">
                    {({ field }) => (
                      <LocationInput
                        name={field.name}
                        label="Job Location"
                        isRequired={true}
                        onChange={(value) =>
                          setFieldValue("_jobLocation", value)
                        }
                        maxWidth="450px"
                        width="100%"
                        height="52px"
                      />
                    )}
                  </Field>
                  <FormikInput
                    label="Description"
                    name="_description"
                    textArea={true}
                    isRequired={false}
                    placeholder="Describe any issues you are facing ..."
                    maxWidth="450px"
                  />
                </Flex>
              </AccordianElement>
              <AccordianElement title="Equipment Image" paddingBottom="1em">
                <Flex wrap="wrap" gap="1em" justifyContent="space-between">
                  <ImageUpload
                    name="_equipmentImage"
                    helperText=""
                    label="Equipment Image"
                    isRequired={false}
                    formikProps={{ setFieldValue, errors, touched }}
                  />
                </Flex>
              </AccordianElement>
            </Flex>
          </Accordion>
        </form>
      )}
    </Formik>
  );
};
