import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  calculateTotalAmountWithoutServiceDiscount,
  calculateTotalDiscountValue,
  calculateTotalLeads,
} from "../helpers/helpers";
import { createUserSubscriptionTransactionDetail } from "../../../services/subscription/subscription";

export const createUserSubscriptionTransactionDetails = createAsyncThunk(
  "/subscription/createUserSubscriptionTransaction",
  async (body, { getState }) => {
    // const { accountDetails } = getState().user;
    const { selectedSubscriptionType} =
      getState().subscription;

    const apiBody = {
      TransactionStateId: parseInt(body.transactionStateId),
      SubscriptionTypeId: selectedSubscriptionType.SUBSCRIPTION_ID,
      ListofSelectedService: body.ListofSelectedService,
      TotalServiceCount:
        body.ListofSelectedService.toString().split(",").length,
      TotalContactPurchased: calculateTotalLeads(body.body),
      TotalAmountWithoutSD: calculateTotalAmountWithoutServiceDiscount(
        body.body
      ).toFixed(2),
      ServiceDiscountAmount: calculateTotalDiscountValue(body.body).toFixed(2),
      TotalAmountAfterDiscount: (
        calculateTotalAmountWithoutServiceDiscount(body.body) -
        calculateTotalDiscountValue(body.body)
      ).toFixed(2),
      NetPayableAmountWithoutTax: (
        calculateTotalAmountWithoutServiceDiscount(body.body) -
        calculateTotalDiscountValue(body.body)
      ).toFixed(2),
    };
    try {
      const data = await createUserSubscriptionTransactionDetail(apiBody);

      if (data.Status === 200 || data.Status === 201) {
        return data.Data;
      }
    } catch (error) {}
  }
);
