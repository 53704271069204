import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { subString } from "../../../../../webapp/helpers/dataConversion/substring";

export const OpenJobCard = ({
  heading,
  salary,
  location,
  lastDate,
  onClick,
}) => {
  return (
    <Flex  onClick={onClick}>
      <Box padding={"15px"} borderRadius={"5px"} minW={"380px"} backgroundColor={"white"} color={"black"} maxW={"380px"} w={"380px"} h={"150px"} maxH={"150px"}>
        <Flex flexDirection={"column"} paddingBottom={"0.5em"} borderBottom={"1px solid #E3E5E8"} gap={"10px"}>
          <Tooltip label={heading?.length >30 ? heading: null} >
          <Text fontSize={"18px"} fontWeight={"700"} color={"#2C2C2C"}>
            {subString(30,heading)}{" "}
          </Text>
          </Tooltip>
          <p>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "600",
                color: "#767676",
              }}
            >
              Salary :
            </span>

            <span
              style={{
                fontSize: "16px",
                fontWeight: "700",
                color: "#002961",
              }}
            >
              {salary}
            </span>
          </p>
          <p
            style={{
              fontSize: "12px",
              fontWeight: "600",
              color: "#767676",
            }}
          >
            <span>Location :</span> {location}
          </p>
        </Flex>
        <Flex color={"#D60000"} fontSize={"12px"} fontWeight={"600"} paddingTop={"0.5em"}>
          <p>* Last date to apply is {lastDate}</p>
        </Flex>
      </Box>
    </Flex>
  );
};
