import { Box } from "@chakra-ui/react";

export const V5Scroll = ({
  maxHeight = "100%",
  minHeight = "",
  height = "100%",
  maxWidth = "100%",
  minWidth = "100%",
  width = "100%",
  backgroundColor = "#FFFFFF",
  overflowX = "scroll",
  overflowY = "scroll",
  children,
}) => {
  return (
    <Box
      maxH={maxHeight}
      overflowX={overflowX}
      overflowY={overflowY}
      minHeight={minHeight}
      height={height}
      maxHeight={maxHeight}
      minWidth={minWidth}
      width={width}
      maxWidth={maxWidth}
      background={backgroundColor}
      bgColor={backgroundColor}
    >
      {children}
    </Box>
  );
};
