import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCompanySubscriptionDetail } from "../../../services/subscription/subscription";


export const getCompanySubscriptionDetails = createAsyncThunk("/subscription/getCompanySubscriptionDetails", async(_, {getState, rejectWithValue}) => {
  
    const {orderId} = getState().subscription
 
    try{
   
        const data = await getCompanySubscriptionDetail(orderId.VirtualSubsOrderId)

        if(data.Status === 200){
            return data.Data
        }
        else{
            return rejectWithValue({error: "Something went wrong!"})
        }
    }
    catch(error){
        return error
    }
})