import { createSlice } from "@reduxjs/toolkit";
import {
  getSessionStorageItems,
  setSessionStorageItems,
} from "../../../helpers/sessionStorage/sessionStorage";
import { getBasicDetailsForUpdate } from "../thunks/getBasicDetailsForUpdate";
import { getAdditionalDetailsForUpdate } from "../thunks/getAdditionalDetailsForUpdate";

export const postMaterialsSlice = createSlice({
  name: "postMaterials",
  initialState: {
    stepperIndex: getSessionStorageItems("POST_MATERIAL_STEPPER_INDEX")
      ? parseInt(getSessionStorageItems("POST_MATERIAL_STEPPER_INDEX"))
      : 0,
    stepperRoute: [{ name: "Step 1" }, { name: "Step 2" }],

    isDeleteOpen: false,
    isPhotoOpen: false,
    isVideoOpen: false,
    videoData: null,
    imageData: null,
    lastId: null,
    // editBasicDetails: null,
    // editAdditionalDetails: null,

    ProductName: null,
    ProductNumber: null,
    Location: null,
    MinPriceRange: null,
    MaxPriceRange: null,
    IsOriginal: null,
    AnyEquipment: null,
    EquipmentTypeId: null,
    AnyOEM: null,
    OEMId: null,
    AnyModel: null,
    ModelId: null,

    
    AdditionalDetails:null,
    Height:null,
    HeigthtUnit:null,
    LengthUnit:null,
    Radius:null,
    RadiusUnit:null,
    SLength:null,
    Weight:null,
    WeightUnit:null,
    Width:null,
    WidthUnit:null,


    // Images State
    photoTypeList: null,
    videoTypeList: null,

    //loading button
    isSubmitLoading:false
  },

  reducers: {
    increaseStepperIndex: (state) => {
      if (state.stepperRoute.length <= state.stepperIndex) {
        return;
      }
      state.stepperIndex += 1;
      setSessionStorageItems("POST_MATERIAL_STEPPER_INDEX", state.stepperIndex);
    },
    decreaseStepperIndex: (state) => {
      if (state.stepperIndex === 0) {
        return;
      }
      state.stepperIndex -= 1;
      setSessionStorageItems("POST_MATERIAL_STEPPER_INDEX", state.stepperIndex);
    },
    clearStepperIndex: (state) => {
      state.stepperIndex = 0;
      sessionStorage.removeItem("POST_MATERIAL_STEPPER_INDEX");
    },

    openDeleteModal: (state) => {
      state.isDeleteOpen = true;
    },
    closeDeleteModal: (state) => {
      state.isDeleteOpen = false;
    },
    openPhotoModal: (state) => {
      state.isPhotoOpen = true;
    },
    closePhotoModal: (state) => {
      state.isPhotoOpen = false;
    },
    openVideoModal: (state) => {
      state.isVideoOpen = true;
    },
    closeVideoModal: (state) => {
      state.isVideoOpen = false;
    },
    setPhotoTypeList: (state, { payload }) => {
      state.photoTypeList = payload;
    },
    setVideoTypeList: (state, { payload }) => {
      state.videoTypeList = payload;
    },
    setImageData: (state, { payload }) => {
      state.imageData = payload;
    },
    setVideoData: (state, { payload }) => {
      state.videoData = payload;
    },
    setLastId: (state, { payload }) => {
      state.lastId = payload;
    },
    setIsSubmitLoading: (state, { payload }) => {
      state.isSubmitLoading = payload;
    },
    clearBasicDetails: (state) => {
        state.ProductName= null;
        state.ProductNumber=null;
        state.Location=null;
        state.MinPriceRange=null;
        state.MaxPriceRange=null;
        state.IsOriginal=null;
        state.AnyEquipment=null;
        state.EquipmentTypeId=null;
        state.AnyOEM=null;
        state.OEMId=null;
        state.AnyModel=null;
        state.ModelId=null;
    },
    clearAdditionalDetails:(state)=>{
      state.AdditionalDetails=null;
      state.Height=null;
      state.HeigthtUnit=null;
      state.LengthUnit=null;
      state.Radius=null;
      state.RadiusUnit=null;
      state.SLength=null;
      state.Weight=null;
      state.WeightUnit=null;
      state.Width=null;
      state.WidthUnit=null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBasicDetailsForUpdate.pending, (state) => {
        state.ProductName= null;
        state.ProductNumber=null;
        state.Location=null;
        state.MinPriceRange=null;
        state.MaxPriceRange=null;
        state.IsOriginal=null;
        state.AnyEquipment=null;
        state.EquipmentTypeId=null;
        state.AnyOEM=null;
        state.OEMId=null;
        state.AnyModel=null;
        state.ModelId=null;
      })
      .addCase(getBasicDetailsForUpdate.fulfilled, (state, { payload }) => {
        state.ProductName= payload?.Data?.MaterialData[0]?.ProductName;
        state.ProductNumber=payload?.Data?.MaterialData[0]?.ProductNumber;
        state.Location=payload?.Data?.MaterialData[0]?.Location;
        state.MinPriceRange=payload?.Data?.MaterialData[0]?.MinPriceRange;
        state.MaxPriceRange=payload?.Data?.MaterialData[0]?.MaxPriceRange;
        state.IsOriginal=payload?.Data?.MaterialData[0]?.IsOriginal;
        state.AnyEquipment=payload?.Data?.MaterialData[0]?.AnyEquipment;
        state.EquipmentTypeId=payload?.Data?.MaterialData[0]?.EquipmentTypeId;
        state.AnyOEM=payload?.Data?.MaterialData[0]?.AnyOEM;
        state.OEMId=payload?.Data?.MaterialData[0]?.OEMId;
        state.AnyModel=payload?.Data?.MaterialData[0]?.AnyModel;
        state.ModelId=payload?.Data?.MaterialData[0]?.ModelId;
      })
      .addCase(getAdditionalDetailsForUpdate.pending, (state) => {
        state.AdditionalDetails=null;
        state.Height=null;
        state.HeigthtUnit=null;
        state.LengthUnit=null;
        state.Radius=null;
        state.RadiusUnit=null;
        state.SLength=null;
        state.Weight=null;
        state.WeightUnit=null;
        state.Width=null;
        state.WidthUnit=null;
      })
      .addCase(getAdditionalDetailsForUpdate.fulfilled, (state, { payload }) => {
        state.AdditionalDetails=payload?.Data?.MaterialData[0]?.AdditionalDetails;
        state.Height=payload?.Data?.MaterialData[0]?.Height;
        state.HeigthtUnit=payload?.Data?.MaterialData[0]?.HeigthtUnit;
        state.LengthUnit=payload?.Data?.MaterialData[0]?.LengthUnit;
        state.Radius=payload?.Data?.MaterialData[0]?.Radius;
        state.RadiusUnit=payload?.Data?.MaterialData[0]?.RadiusUnit;
        state.SLength=payload?.Data?.MaterialData[0]?.SLength;
        state.Weight=payload?.Data?.MaterialData[0]?.Weight;
        state.WeightUnit=payload?.Data?.MaterialData[0]?.WeightUnit;
        state.Width=payload?.Data?.MaterialData[0]?.Width;
        state.WidthUnit=payload?.Data?.MaterialData[0]?.WidthUnit;
      
      });
  },
});

export const {
  increaseStepperIndex,
  decreaseStepperIndex,
  clearStepperIndex,
  clearMaterialData,
  openDeleteModal,
  closeDeleteModal,
  openPhotoModal,
  closePhotoModal,
  openVideoModal,
  closeVideoModal,
  setPhotoTypeList,
  setVideoTypeList,
  setImageData,
  setVideoData,
  setLastId,
  setIsSubmitLoading,
  clearBasicDetails,
  clearAdditionalDetails
} = postMaterialsSlice.actions;
