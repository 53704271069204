import { API_CONSTANTS } from "../../../constants/apiConstants";
import { fetchHandler } from "../../../index";

export const getPurchasedLeadsById = async (ServiceTypeId) => {
  try {
    if (!ServiceTypeId) {
      throw new Error("Atleast 1 parameter is required.");
    }
    const { data } = await fetchHandler(API_CONSTANTS.GET_USER_LEADS_CHECK, {
      ServiceTypeId: ServiceTypeId,
    });
    if (data.Status === 200 && Object.keys(data.Data > 0)) {
      return data.Data.LeadsData;
    } else {
      const error = new Error();
      error.message = data.Message.FailMessage;
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

//subcription
export const getUserPurchasedLeadsById = async (ServiceTypeId) => {
  try {
    if (!ServiceTypeId) {
      throw new Error("Atleast 1 parameter is required.");
    }
    const { data } = await fetchHandler(API_CONSTANTS.GET_USER_LEADS_CHECK, {
      ServiceTypeId: ServiceTypeId,
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const getUserLeadsCheckForContactRedeemed = async (
  ServiceTypeId,
  UserCredentialId
) => {
  try {
    if (!ServiceTypeId) {
      throw new Error("Atleast 1 parameter is required.");
    }
    const { data } = await fetchHandler(
      API_CONSTANTS.GET_USER_LEADS_CHECK_FOR_CONTACT_REDEEMED,
      {
        ServiceTypeId: ServiceTypeId,
        UserCredentialId: UserCredentialId,
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

//free
export const getUserFreeRedeemDetailsById = async (serviceId) => {
  try {
    if (!serviceId) {
      throw new Error("Atleast 1 parameter is required.");
    }
    const { data } = await fetchHandler(
      `/GetE9FreeRedeemedDetails?ServiceTypeId=${serviceId}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getE9FreeRedeemedDetailsOnContactRedeemed = async (serviceId) => {
  try {
    if (!serviceId) {
      throw new Error("Atleast 1 parameter is required.");
    }
    const { data } = await fetchHandler(
      API_CONSTANTS.GET_E9_FREE_REDEEMED_DETAILS_ON_CONTACT_REDEEMED,
      {
        ServiceTypeId: serviceId,
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

//premium
export const getUserPremiumRedeemCountById = async (serviceTypeId) => {
  if (!serviceTypeId) {
    throw new Error("This function requires one parameter.");
  }
  try {
    const { data } = await fetchHandler(
      API_CONSTANTS.GET_PREMIUM_REDEEM_COUNT_BY_SERVICE_TYPE_ID,
      { ServiceTypeId: parseInt(serviceTypeId) }
    );
    if (data?.Status === 200 && data?.Data?.RedeemDetails) {
      return data?.Data?.RedeemDetails;
    } else {
      return {};
    }
  } catch (error) {
    throw error;
  }
};

export const getPremiumRedeemCountByUserIdServiceTypeId = async (
  serviceTypeId,
  UserCredentialId
) => {
  if (!serviceTypeId) {
    throw new Error("This function requires one parameter.");
  }
  try {
    const { data } = await fetchHandler(
      API_CONSTANTS.GET_PREMIUM_REDEEM_COUNT_BY_USER_ID_SERVICE_TYPE_ID,
      {
        ServiceTypeId: serviceTypeId,
        UserCredentialId: UserCredentialId,
      }
    );
    if (data?.Status === 200 && data?.Data?.RedeemDetails) {
      return data?.Data?.RedeemDetails;
    } else {
      return {};
    }
  } catch (error) {
    throw error;
  }
};
