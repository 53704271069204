import React from 'react';
import { SideMenuLayout } from '../../../../../layout/sideMenuLayout/SideMenuLayout';
import { DashboardNavBar } from '../../../../../components/DashboardNavBar/DashboardNavBar';
import { MidSection } from '../../../../../../sharedComponent/midSection/MidSection';
import "../LegalPolicy/TermsAndConditions.css";

const AboutUs = () => {
  return (
    <>
      <SideMenuLayout>
        <DashboardNavBar />
        <MidSection>
          <div className="new-terms">
            <h2 className="new-terms-heading">About Us</h2>
            <div className="new-terms-content-grid">
              <div className="new-terms-content">
                As the world is expanding fast with infrastructure development, there will be a huge shortage of human resources. Heavy Equipment will be the only option left for the future. In the coming future, we will need a smart, futuristic, and optimized use of the equipment and other services related to the Heavy Equipment Industry, and that is the reason why we thought to choose the heavy equipment industry. EQUIP9&trade;️ will be a fully aggregated 360-degree future digital platform dedicated to the heavy equipment industry and its related services.
              </div>

              <div className="new-terms-content">
                <a href="mailto:contact@equip9.com" className="terms-link">
                  <i className="fas fa-envelope"></i> First in Industry
                </a>
                EQUIP9<sup>tm</sup> is the first of its kind, an aggregated platform for the heavy equipment industry. With 360 degrees connection, we will make a uniform, transparent, and easy-to-use marketplace.
              </div>

              <div className="new-terms-content">
                <a href="https://@equip9.com" className="terms-link">
                  <i className="fas fa-globe"></i> Global Vision
                </a>
                EQUIP9<sup>tm</sup> digital platform, we vision to integrate the business of the heavy equipment industry across the globe. We see a digital marketplace that will connect small or large entities globally.
              </div>

              <div className="new-terms-contact-info">
                <h5 className="new-terms-contact-heading">Please visit our website to know more about us:</h5>
                <a href="https://equip9.com" target="_blank" className="terms-link" style={{ color: 'blue', textDecoration: 'underline' }}>
                  <i className="fas fa-link"></i> https://equip9.com
                </a>

              </div>
            </div>
          </div>
        </MidSection>
      </SideMenuLayout>
    </>
  );
};

export default AboutUs;
