import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPremiumRedeemCountById } from "../../../services/premium/premium";

export const getPremiumLeadsByIdThunk = createAsyncThunk("/premiumLeadsById", async(id) => {
    try{
      const data = await getPremiumRedeemCountById(id);
      return data;
    }
    catch(error){
        throw error
    }
})