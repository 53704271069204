import React ,{useState}from 'react';
import { SideMenuLayout } from '../../../../../../layout/sideMenuLayout/SideMenuLayout';
import { DashboardNavBar } from '../../../../../../components/DashboardNavBar/DashboardNavBar';
import './VerficationDetails.css';
import { PopupCard } from '../../../../../../../website/components/Pop-up/PopupCard';
import { MidSection } from '../../../../../../../sharedComponent/midSection/MidSection';
import { GetVerifyDetails } from './GetVerfyDetails/GetVerifyDetails';
import { useSelector } from "react-redux";
import { ProfileVerification } from '../../../../../../components/ProfileVerication/ProfileVerification';
// import { AdditionalDetails } from '../Opearatorpatner/Equipmentdeatail/LicenseDetail/AdditionalDetail/AdditionalDetail';

export const VerficationDetail=()=>{

    const {defaultLanguage} = useSelector(state => state.masterData)
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const [isAdditonalOpen,setisAdditionalOpen]=useState(false);
    const userData = useSelector((state) => state.user);
    // const loginData = useSelector((state) => state.login);
    // const verification=userData?.accountDetails?.UserDetails?.E9VerificationMessage
    // const verificationByThirdParty=userData?.accountDetails?.UserDetails?.IsE9VerifiedByAPI;



    const openPopup = () => {
      setPopupOpen(true);
    };
  
    const closePopup = () => {

      setPopupOpen(false);
    }


const openDetailPopup=()=>{
    setisAdditionalOpen(true);
}

const  closeDetailPopup=()=>{
    setisAdditionalOpen(false)
}


    const triggerRefresh = () => {  
      setRefreshKey(prev => prev + 1);
    }
    return(
        <div>
          <SideMenuLayout>
             <DashboardNavBar />
           <MidSection>
           <ProfileVerification />

                <div className="verification-details">
                  <p className='your-verification-details'>{defaultLanguage?.Verification_Details}</p>
                  <hr  className='seprator-hr'></hr>
                  <div className='add-verificat' onClick={openPopup}>
                  <p className='add-verification-detail' >+ ADD DOCUMENTS</p>
                </div>
                </div>

                {/* <div className='add-verification' onClick={openPopup}>
                  <p className='add-verification-detail' >+ ADD DOCUMENTS</p>
                </div> */}

                  <PopupCard isOpen={isPopupOpen} onClose={closePopup} triggerRefresh={triggerRefresh}  />
                  <GetVerifyDetails triggerRefresh={triggerRefresh} /> 
                  {/* {isAdditonalOpen && <AdditionalDetails onClose={closeDetailPopup} />} */}
                  </MidSection>
                 
          </SideMenuLayout>
             

        </div>
    )
}