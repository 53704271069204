const setLocalStorageItems = (key, value) => {
  if(!value){
    return localStorage.setItem(key, "")
  }
    if(typeof(value) === "object"){
       return localStorage.setItem(key, JSON.stringify(value))
    }
    return localStorage.setItem(key, value);
}

const getLocalStorageItems = (key) => {
  if(localStorage.getItem(key)){
       if(localStorage.getItem(key).indexOf("[") > -1 || localStorage.getItem(key).indexOf("{") > -1 ){
        return JSON.parse(localStorage.getItem(key));
       }
       else{
        return localStorage.getItem(key);
       }
  }
  else{
    return null;
  }
}


export {setLocalStorageItems, getLocalStorageItems};