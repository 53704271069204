import { SideMenuLayout } from "../../../../../../../../layout/sideMenuLayout/SideMenuLayout";
import { DashboardNavBar } from "../../../../../../../../components/DashboardNavBar/DashboardNavBar";
import { MidSection } from "../../../../../../../../../sharedComponent/midSection/MidSection";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { Skeleton } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  tableHeading,
} from "../../../../../../../../../staticData/staticData";
import { getSubscriptionAndFreeServiceList } from "../../../../../../../../../ApiEquip9/CommonApi";
import "./Subcription.css";
import { useQuery } from "react-query";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";

export const Subcription = () => {
  const userData = useSelector((state) => state.user);

  const {
    data: getSubcription,
    isLoading,
    isError,
  } = useQuery("getUploadVerifucation", () =>
    getSubscriptionAndFreeServiceList(userData.token)
  );
  
  const getSubcriptiondetails = getSubcription?.data?.Data?.SubscriptionDetails;
  return (
    <>
      <SideMenuLayout>
        <DashboardNavBar />
        <MidSection>
          <div className="routeSection">
            <p>
              <Breadcrumb
                spacing="8px"
                separator={<ChevronRightIcon color="gray.500" />}
              >
                <BreadcrumbItem>
                  <BreadcrumbLink href="/my-profile">Profile</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink href="#">Subscription Details</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
            </p>
          </div>
          <div className="hero-heading">
            <p className="hero-subscription">Subscription Details</p>
            <p className="hero-detail-subscription">E9 Service Summary</p>
          </div>
          <div className="subcrption-table">
            <div className="service-heading">
              {tableHeading?.map((item) => (
                <div className="table-heading">
                  <p className="E9-text">{item.name}</p>
                </div>
              ))}
            </div>
          </div>

          <div>
            <div>
              {isLoading ? (
                <>
                  {" "}
                  <div
                    style={{
                      height: "75px",
                      width: "84%",
                      margin: "33px 53px",
                    }}
                  >
                    <Skeleton height="60px" /> <br /> <Skeleton height="60px" />{" "}
                    <br /> <Skeleton height="60px" /> <br />{" "}
                    <Skeleton height="60px" /> <br /> <Skeleton height="60px" />
                  </div>
                </>
              ) : isError ? (
                <>Error...</>
              ) : (
                <>
                  {getSubcriptiondetails?.map((item, index) => (
                    <div className="subcrption-table-sub">
                      <p className="E9-text-subcription">{item.ServiceType}</p>
                      <p className="E9-text-subcription">{item.UsedCount}</p>
                      <p className="E9-text-subcription">
                        {item.TotalFreeRedeem}
                      </p>
                      <p className="E9-text-subcription">
                        {item.TotalPurchasedCount}
                      </p>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>

          {/* <div className="subcrption-table">
            <div className="service-heading">
              {MaintenceSearch.map((item, index) => (
                <div className="table-heading">
                  <p className="E9-text">{item.name}</p>
                </div>
              ))}
            </div>
          </div> */}

          {/* <div className="subcrption-table">
            <div className="service-heading">
              {equipmentListing.map((item, index) => (
                <div className="table-heading">
                  <p className="E9-text">{item.name}</p>
                </div>
              ))}
            </div>
          </div> */}

          {/* <div className="subcrption-table">
            <div className="service-heading">
              {UsedEquipmentMarketplace.map((item, index) => (
                <div className="table-heading">
                  <p className="E9-text">{item.name}</p>
                </div>
              ))}
            </div>
          </div> */}

          {/* <div className="subcrption-table">
            <div className="service-heading">
              {RentalMarketplace.map((item, index) => (
                <div className="table-heading">
                  <p className="E9-text">{item.name}</p>
                </div>
              ))}
            </div>
          </div> */}
        </MidSection>
      </SideMenuLayout>
    </>
  );
};
