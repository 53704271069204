import { FormControl, FormLabel, Select, FormErrorMessage } from "@chakra-ui/react";
import { useField } from "formik";

export const FormikDropdown = (
  {
    name = "",
    children,
    isRequired = true,
    maxWidth = "374px",
    label = "Select",
    onChange = () => {},
    value = "",
    placeholder = "Select Value",
    isDisabled=false,
    isInvalid,
    errorMessage = " ",
    defaultValue = ""
  },
 
) => {
  const [field, meta, helpers] = useField(name);
  return (
    <FormControl isDisabled={isDisabled} isInvalid={meta.touched && meta.error} isRequired={isRequired} maxW={maxWidth}>
      <FormLabel _disabled={{color: "#2c2c2c"}}>{label}</FormLabel>
      <Select
       onChange={(e) => {onChange(e)}}
       onClick={() => {helpers.setTouched(true)}}
       onFocus={() => {helpers.setTouched(true)}}
        value={value}
        defaultValue={defaultValue}
        bgColor={field.value ? "#fff" : "#2c2c2c05"}
        isInvalid={isInvalid || (meta.touched && meta.error)}
        // {...field}
        h="52px"
        fontSize="20px"
      >
        <option value="">{placeholder}</option>
        {children}
      </Select>
      <FormErrorMessage>
        {meta.touched && meta.error ? meta.error : errorMessage}
      </FormErrorMessage>
    </FormControl>
  );
};

