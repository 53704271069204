import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRentalMyStoreMarketplace } from "../../../services/equipmentStore/equipmentStore";

export const getInitialRentalEquipmentsThunk  = createAsyncThunk("/equipment-store/initial-rental-equipments-thunk", async(_, {rejectWithValue}) => {
    try{
       const data = await getRentalMyStoreMarketplace()
       return data
    }
    catch(error){

    }
})