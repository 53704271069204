import { createAsyncThunk } from "@reduxjs/toolkit";
import {fetchHandler} from "../../../../index"
import { getE9MarketPlaceRentalEquipmentList } from "../../../services/equipments/equipments";


export const getRemLoadMoreDataThunk = createAsyncThunk("/rem/loadMoreData", async(_, {getState}) => {
  
    const cardCount = getState().rentalEquipmentMarketplace.cardCount;
    const paginationCount = getState().rentalEquipmentMarketplace.paginationCount;
   
    try{
     
        const data = await getE9MarketPlaceRentalEquipmentList(cardCount,(paginationCount * cardCount))
        
        if(data.Status === 200 && Object.keys(data.Data).length > 0){
                 return data.Data.RentalEquipmentList;
        }
        else{
            return []
        }
    }
    catch(error){
    }
    
})