import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { NewSideMenuLayout } from "../../../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { Box, Button, Checkbox, Flex, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { API_CONSTANTS } from "../../../../../constants/apiConstants";
import { getCompanySubscriptionDetails } from "../../thunks/getCompanySubscriptionDetails";
import { getCompanySubscriptionTransactionDetailsThunk } from "../../thunks/getCompanySubscriptionTransactionDetailsThunk";
import { convertToFormData } from "../../../../helpers/dataConversion/convertToFormData";
import { loadRazorpayScript } from "../../helpers/razorpay";
import {
  setLastE9TransactionId,
  setUpdatedGstTax,
  setUpdatedNetPayable,
  setUpdatedTotalAmount,
  setUserSubsTransOfferDetailId,
} from "../../slice/subscriptionSlice";
import { getUserSubscriptionDetailsThunk } from "../../thunks/getUserSubscriptionDetails";
import { getUserSubscriptionTransactionDetailsThunk } from "../../thunks/getUserSubscriptionTransactionDetails";
import { FormInput } from "../../../../components/Input/Input";
import { fetchHandler } from "../../../../../index";

import {
  createUserSubscriptionTransactionOfferDetails,
  deleteSelectedUserSubscriptionTransactionOffer,
  getNewUserTaxDetails,
  getOffersForSubscription,
  updateTransactionDetails,
  updateUserSubscriptionTransactionTaxDetails,
} from "../../../../services/subscription/subscription";
import { OffersModal } from "../Offers/OffersModal";
import OffersApplied from "../Offers/OffersApplied";
import { V5GridFooterSidebarLayout } from "../../../../layout/V5FixedFooterLayoutGrid/V5FixedFooterLayoutGrid";
import { V5FixedFooterSidebarLayout } from "../../../../layout/V5FixedFooterSidebarLayout/V5FixedFooterSidebarLayout";
export const SubscriptionReview = () => {
  const { defaultLanguage } = useSelector((state) => state.masterData);
  const { token, accountDetails, UserTypeValue } = useSelector(
    (state) => state.user
  );
  const { transactionState, selectedSubscriptionType, orderId } = useSelector(
    (state) => state.subscription
  );
  const dispatch = useDispatch();
  const {
    checkoutSubsctiptionDetails,
    checkoutTransactionDetails,
    checkoutTaxDetails,
    updatedNetPayble,
    updatedGstTax,
    updatedTotalAmount,
  } = useSelector((state) => state.subscription);
  const { currencyDetails } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [promoDiscount, setPromoDiscount] = useState(0);
  const [gstRequired, setGstRequired] = useState(false);
  const [gstNumber, setGstNumber] = useState("");
  const [gstErrors, setGstErrors] = useState(" ");
  const [offersList, setOffersList] = useState(null);
  const [isOffersModalOpen, setIsOffersModalOpen] = useState(false);
  const [isOfferApplied, setIsOfferApplied] = useState(false);
  const [loading,setLoading]= useState(false);
  const toast = useToast();

  useEffect(() => {
    loadRazorpayScript();
    if (UserTypeValue.toLowerCase() === "Company".toLowerCase()) {
      dispatch(getCompanySubscriptionDetails());
      dispatch(getCompanySubscriptionTransactionDetailsThunk());
    } else {
      dispatch(getUserSubscriptionDetailsThunk());
      dispatch(getUserSubscriptionTransactionDetailsThunk());
    }
    getOffersListAvailable();
  }, []);
  // GET USER TAX DETAILS
  useEffect(() => {
    calculateUpdateTax();
  }, [checkoutTransactionDetails]);
  const getUserTaxDetails = async () => {
    try {
      const data = await getNewUserTaxDetails();

      if (data.Status === 200 && data.Data && data.Data.length > 0) {
        return data.Data[0];
      } else {
        const error = new Error();
        error.message(data.Message.FailMessage);
        throw error;
      }
    } catch (error) {}
  };
  // Get OFFER LIST
  const getOffersListAvailable = async () => {
    try {
      const data = await getOffersForSubscription();
      setOffersList(data);
      return data;
    } catch (error) {
      setOffersList(null);
      return null;
    }
  };

  //   UPDATE GST FOR INDIVIDUAL BEFORE CREATING STAGING ORDER THATS IT.

  const hasGstNumber = async (gstNumber) => {
    // if checked search for gst number
    if (!gstNumber) {
      setGstErrors("Please enter GST number");
      return false;
    } else {
      return await vaildGstNumber(gstNumber);
    }
  };
  // else proceed to pay

  const vaildGstNumber = async (gstNumber) => {
    setLoading(true);
    try {
      const data = await getUserTaxDetails();
      const taxRegex = new RegExp(data.TaxCodeRegEx);
      if (!taxRegex.test(gstNumber)) {
        setGstErrors("Please enter a vaild GST number");
        return false;
      } else {
        await updateGstNumber(gstNumber);
        return true;
      }
    } catch (error) {}
    finally{
      setLoading(false);
    }
  };

  const updateGstNumber = async (gstNumber) => {
    try {
      const { data } = await fetchHandler(
        "/UpdateUserSubscriptionTransactionTaxNumber",
        {},
        {},
        {
          VirtualSubsOrderId: orderId.VirtualSubsOrderId,
          TaxNumber: gstNumber.trim(),
        },
        API_CONSTANTS.HTTP_PUT
      );
      if (data.Status === 204) {
        toast({
          title: `${data.Message.SuccessMessage}`,
          isClosable: true,
          status: "success",
          position: "top-right",
        });
      } else {
        toast({
          title: `Could not update GST details.`,
          description: "Please contact team to update GST details.",
          isClosable: true,
          status: "error",
          position: "top-right",
        });
      }
      await handleProceedToPay();
    } catch (error) {}
  };

  const handleProceedToPay = async () => {
    // console.log({
    //   UserTranRefId: orderId.UserSubsTranDetailId,
    //   TransactionModeId: 1,
    //   TotalAmountAfterTax: checkoutTransactionDetails.TotalAmountAfterTax,
    //   CurrencySortForm: "INR",
    // });
    try {
      const url =
        UserTypeValue === "Company"
          ? "/CreateCompanyE9StagingTransactionOrder"
          : "/CreateStagingOrderId";

      const { data } = await fetchHandler(
        url,
        {},
        {},
        convertToFormData({
          UserTranRefId: orderId.UserSubsTranDetailId,
          TransactionModeId: 1,
          TotalAmountAfterTax: parseFloat(updatedTotalAmount).toFixed(2),
          CurrencySortForm: "INR",
        }),
        API_CONSTANTS.HTT_POST
      );

      if (data.Status === 201) {
        const options = {
          description: "Subscription Transaction",
          // image: Constant.ICON_EQUIP9_LOGO,
          currency: "INR",
          key: process.env.REACT_APP_RAZORYPAY_KEY_ID,
          amount: checkoutTransactionDetails.totalAmountAfterTax,
          name: "Equip9",
          modal: {
            confirm_close: true,
          },
          order_id: data.Data.RazorPayOrderId,
          theme: { color: "#F67C00" },
          prefill: {
            name: accountDetails.UserDetails.PersonalDetails.EnterpriseName,
            contact: accountDetails.UserDetails.CredentialDetails.MobileNumber,
          },
          handler: async (razorpayResponse) => {
            const {
              razorpay_order_id,
              razorpay_payment_id,
              razorpay_signature,
            } = razorpayResponse;

            try {
              const successUrl =
                UserTypeValue === "Company"
                  ? "CreateCompanyE9WebTransactionDetails"
                  : "CreateE9WebTransactionDetails";

              const { data } = await fetchHandler(
                `/${successUrl}/${razorpay_order_id}/${razorpay_payment_id}/${razorpay_signature}`
              );

              if (data.Status === 201) {
                dispatch(setLastE9TransactionId(data.Data.LastE9TransactionId));
                navigate("/subscription/payment-success");
              }
            } catch (error) {
              console.log(error);
            }
          },
        };
        const razorpay = new window.Razorpay(options);
        razorpay.open();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Apply Offers
  const handleUpdateTaxDetails = async () => {
    try {
      const { Data } = await getNewUserTaxDetails();
      let raw_payloadForTax = [];
      if (Data?.length > 0) {
        raw_payloadForTax.push({
          VirtualSubsOrderId: orderId?.VirtualSubsOrderId,
          TaxCodeType: Data[0].TaxCodeTypeId,
          SubCategoryTaxType: Data[0].TaxSubCategoryId,
          TaxPercentage: Data[0].TaxSubCategoryPercentage,
          TaxAmount: parseFloat(updatedGstTax).toFixed(2),
        });

        const url =
          UserTypeValue.toLowerCase() === "Company".toLowerCase()
            ? "/UpdateCompSubscriptionTransactionTaxDetails"
            : "/UpdateUserSubscriptionTransactionTaxDetails";

        const data = await updateUserSubscriptionTransactionTaxDetails(
          raw_payloadForTax,
          url
        );
        if (data?.Status === 204) {
          handleProceedToPay();
        }
      } else {
      }
    } catch (error) {}
  };
  const handleUpdateTransaction = async () => {
    const _listOfSelectedService = sessionStorage.getItem(
      "ListofSelectedService"
    );
    const raw_payload = {
      VirtualSubsOrderId: orderId?.VirtualSubsOrderId,
      TransactionStateId: transactionState[2].TransactionStateId,
      SubscriptionTypeId: selectedSubscriptionType?.SUBSCRIPTION_ID,
      ListofSelectedService: _listOfSelectedService, //destruct UserSubsDetailId
      TotalServiceCount: checkoutSubsctiptionDetails?.length,
      TotalContactPurchased: checkoutTransactionDetails.TotalContactPurchased,
      TotalAmountWithoutSD: parseFloat(
        checkoutTransactionDetails.TotalAmountWithoutSD
      ).toFixed(2),
      ServiceDiscountAmount: parseFloat(
        checkoutTransactionDetails.ServiceDiscountAmount
      ).toFixed(2),
      TotalAmountAfterDiscount: parseFloat(
        checkoutTransactionDetails.TotalAmountAfterDiscount
      ).toFixed(2),
      NetPayableAmountWithoutTax: parseFloat(updatedNetPayble).toFixed(2),
      TotalAmountAfterTax: parseFloat(updatedTotalAmount).toFixed(2),
    };

    const url =
      UserTypeValue.toLowerCase() === "Company".toLowerCase()
        ? "/UpdateCompSubscriptionTransactionDetails"
        : "/UpdateUserSubscriptionTransactionDetails";
    try {
      setLoading(true);
      const data = await updateTransactionDetails(raw_payload, url);
      if (data?.Status === 204) {
        handleUpdateTaxDetails();
      }
    } catch (error) {}
    finally{
      setLoading(false);
    }
  };

  const handleApplyOffers = async (offer) => {
    if (checkoutTransactionDetails?.OfferId) {
      if (checkoutTransactionDetails?.OfferId === offer?.OfferId) {
        toast({
          title: "This Offer has Already Been Applied",
          position: "top-right",
          status: "info",
        });
      } else {
        handleDeleteUserSubscriptionTransactionOfferDetails(offer);
      }
    } else {
      handleCreateUserSubscriptionTransactionOfferDetails(offer);
    }
  };

  const handleCreateUserSubscriptionTransactionOfferDetails = async (offer) => {
    const splitDiscount = offer.OfferCode.substring(
      offer.OfferCode.length - 2,
      offer.OfferCode.length
    );

    const discountPercent =
      parseFloat(checkoutTransactionDetails.TotalAmountAfterDiscount) *
      splitDiscount;
    const discountedAmount = parseFloat(discountPercent / 100).toFixed(2);

    const payload = {
      VirtualSubsOrderId: orderId?.VirtualSubsOrderId,
      OfferId: offer.OfferId,
      OfferCodeDiscount: discountedAmount,
      IsOfferApplied: 1,
    };
    const url =
      UserTypeValue.toLowerCase() === "Company".toLowerCase()
        ? "/CreateCompSubscriptionTransactionOfferDetails"
        : "/CreateUserSubscriptionTransactionOfferDetails";
    try {
      const data = await createUserSubscriptionTransactionOfferDetails(
        payload,
        url
      );
      if (data?.Status === 201) {
        toast({
          title: `${data?.Message?.SuccessMessage}`,
          position: "top-right",
          status: "success",
        });
        setPromoDiscount(parseFloat(discountedAmount));
        setIsOfferApplied(true);
        if (UserTypeValue.toLowerCase() === "Company".toLowerCase()) {
          dispatch(
            setUserSubsTransOfferDetailId(data?.Data?.SubsTransOfferDetailId)
          );
          dispatch(getCompanySubscriptionDetails());
          dispatch(getCompanySubscriptionTransactionDetailsThunk());
        } else {
          dispatch(
            setUserSubsTransOfferDetailId(
              data?.Data?.UserSubsTransOfferDetailId
            )
          );
          dispatch(getUserSubscriptionDetailsThunk());
          dispatch(getUserSubscriptionTransactionDetailsThunk());
        }
      } else {
        toast({
          title: `${data?.Message?.FailMessage}`,
          position: "top-right",
          status: "error",
        });
        setPromoDiscount(0);
        if (UserTypeValue.toLowerCase() === "Company".toLowerCase()) {
          dispatch(getCompanySubscriptionDetails());
          dispatch(getCompanySubscriptionTransactionDetailsThunk());
        } else {
          dispatch(getUserSubscriptionDetailsThunk());
          dispatch(getUserSubscriptionTransactionDetailsThunk());
        }
      }
    } catch (error) {
      toast({
        title: "Error while applying offers",
        position: "top-right",
        status: "error",
      });
      setPromoDiscount(0);
    }
  };

  const handleDeleteUserSubscriptionTransactionOfferDetails = async (offer) => {
    try {
      const payload = {
        OfferId: parseInt(sessionStorage.getItem("UserSubsTransOfferDetailId")),
        IsOfferApplied: parseInt(0),
      };

      const url =
        UserTypeValue.toLowerCase() === "Company".toLowerCase()
          ? "/DeleteSelectedCompSubscriptionTransactionOffer"
          : "/DeleteSelectedUserSubscriptionTransactionOffer";

      const data = await deleteSelectedUserSubscriptionTransactionOffer(
        payload,
        url
      );
      if (data?.Status === 204) {
        handleCreateUserSubscriptionTransactionOfferDetails(offer);
        if (UserTypeValue.toLowerCase() === "Company".toLowerCase()) {
          dispatch(getCompanySubscriptionDetails());
          dispatch(getCompanySubscriptionTransactionDetailsThunk());
        } else {
          dispatch(getUserSubscriptionDetailsThunk());
          dispatch(getUserSubscriptionTransactionDetailsThunk());
        }
      } else {
        toast({
          title: `${data?.Message?.FailMessage}`,
          position: "top-right",
          status: "error",
        });
        if (UserTypeValue.toLowerCase() === "Company".toLowerCase()) {
          dispatch(getCompanySubscriptionDetails());
          dispatch(getCompanySubscriptionTransactionDetailsThunk());
        } else {
          dispatch(getUserSubscriptionDetailsThunk());
          dispatch(getUserSubscriptionTransactionDetailsThunk());
        }
      }
    } catch (error) {}
  };

  const calculateUpdateTax = async () => {
    const taxDetails = checkoutTaxDetails;
    if (taxDetails) {
      const taxSubCatPercentage =
        checkoutTaxDetails[0]?.TaxSubCategoryPercentage / 100;
      const netPayble =
        checkoutTransactionDetails?.NetPayableAmountWithoutTax -
        checkoutTransactionDetails?.OfferCodeDiscount;
      const taxAmount = netPayble * taxSubCatPercentage;
      const totalTax = netPayble + taxAmount;

      dispatch(setUpdatedNetPayable(netPayble));
      dispatch(setUpdatedGstTax(taxAmount));
      dispatch(setUpdatedTotalAmount(totalTax));
    }
  };
  return (
    <V5FixedFooterSidebarLayout
      isLoading={loading}
      primaryButtonVariant="gradient"
      primaryButtonText={
        transactionState && transactionState[2]
          ? transactionState[2].TransactionStateValue
          : "Proceed to Payments"
      }
      primaryButtonMaxWidth="347px"
      primaryButtonWidth="100%"
      primaryButtonClickAction={() => {
       
        UserTypeValue === "Individual" && gstRequired
          ? hasGstNumber(gstNumber)
          : handleUpdateTransaction();

      }}
      
    >
      <div style={{ margin: "0px 52px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "1em",
          }}
        >
          <h1 style={{ fontSize: "32px", fontWeight: 700 }}>
            {defaultLanguage?.review_detail}
          </h1>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1.5fr 1fr",
            columnGap: "1.5em",
            alignItems: "end",
          }}
        >
          <div
            style={{
              borderRadius: "4px",
              backgroundColor: "#ffffff",
              boxShadow: "0px 10px 30px #2c2c2c14",
              border: "1px solid #2C2C2C1A",
              maxHeight: "385px",
              minHeight: "385px",
              height: "100%",
              overflowY: "scroll",
              padding: "0.75em 1.5em",
              display: "flex",
              flexDirection: "column",
              gap: "1em",
            }}
          >
            <OffersModal
              isOpen={isOffersModalOpen}
              onCloseButtonClick={() => setIsOffersModalOpen(false)}
              dataArray={offersList}
              onApplyButtonClick={handleApplyOffers}
              textValue={promoDiscount ? true : false}
            />

            <OffersApplied
              isOpen={isOfferApplied}
              currency={currencyDetails.CurrencyValue}
              amount={promoDiscount}
              onOkayButtonClick={() => setIsOfferApplied(false)}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p
                style={{ color: "#F79C00", fontWeight: 700, fontSize: "18px" }}
              >
                {selectedSubscriptionType
                  ? selectedSubscriptionType.SUBSCRIPTION
                  : ""}
              </p>
              {/* <button>Edit</button> */}
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "0.5em" }}
            >
              {checkoutSubsctiptionDetails ? (
                checkoutSubsctiptionDetails?.map((subscription) => (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    key={subscription.CompanySubsDetailId}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: 600,
                        color: "2c2c2c80",
                      }}
                    >
                      {subscription.ServiceTypeValue}
                    </p>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: 600,
                        color: "2c2c2c80",
                      }}
                    >
                      {currencyDetails
                        ? `${currencyDetails.CurrencyValue} `
                        : ""}{" "}
                      {subscription.FinalCostAfterDiscount.toFixed(2)}
                    </p>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
            {UserTypeValue.toLowerCase() !== "Company".toLowerCase() && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5em",
                  backgroundColor: "#FFEBCB50",
                  borderRadius: "4px",
                  padding: "0.75em 1em",
                }}
              >
                {/* Tax Number component */}
                {UserTypeValue === "Individual" ? (
                  <Flex flexDirection="column">
                    <Checkbox
                      isChecked={gstRequired}
                      onChange={(e) => {
                        setGstRequired((prevState) => !prevState);
                      }}
                    >
                      I have a GSTIN Number
                    </Checkbox>
                    <FormInput
                      isDisabled={!gstRequired}
                      type="text"
                      name="taxNumber"
                      label=""
                      placeholder="Enter valid GST Number..."
                      errorMessage={gstErrors}
                      onChange={(e) => {
                        setGstErrors(" ");
                        setGstNumber(e.target.value);
                      }}
                    />
                  </Flex>
                ) : (
                  <></>
                )}
                {/* OFFERS LIST WITH SEARCHBAR */}
              </div>
            )}
            <Box
              height="100%"
              width="100%"
              // border="1px solid #2c2c2c90"
              boxShadow="0px 10px 30px 8px #2c2c2c14"
              padding="0.5em 0.75em"
            >
              <Flex
                justifyContent="space-between"
                alignItems="center"
                marginBottom="1em"
              >
                <Text
                  textTransform="uppercase"
                  color="#1E3264"
                  fontSize="22px"
                  fontWeight="700"
                  letterSpacing="1px"
                >
                  {" "}
                  Offers and Promocode
                </Text>
                <Button
                  outline="none"
                  boxShadow="none"
                  background="transparent"
                  color="#F79C00"
                  _hover={{
                    background: "transparent",
                    color: "#F79C00",
                  }}
                  onClick={() => setIsOffersModalOpen(true)}
                >
                  See All
                </Button>
              </Flex>
              <Flex gap="0.5em" flexDir="column">
                {offersList && offersList.length > 0 ? (
                  offersList.slice(0, 2).map((offer) => {
                    const { Description, OfferCode, OfferId, OfferName } =
                      offer;

                    return (
                      <Flex
                        key={OfferId}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box>
                          <Box>
                            <img src=""></img>
                          </Box>{" "}
                          <Flex flexDirection="column">
                            <Text color="#2c2c2c" fontWeight="700">
                              {OfferName}
                            </Text>
                            <Text color="#2c2c2c90" fontSize="16px">
                              {Description}
                            </Text>
                          </Flex>
                        </Box>{" "}
                        <Button
                          outline="none"
                          boxShadow="none"
                          background="transparent"
                          color={`${
                            offer?.OfferId ===
                              checkoutTransactionDetails?.OfferId &&
                            promoDiscount
                              ? "#319F00"
                              : "#F79C00"
                          }`}
                          onClick={() => handleApplyOffers(offer)}
                        >
                          {offer?.OfferId ===
                            checkoutTransactionDetails?.OfferId && promoDiscount
                            ? "Applied"
                            : "Apply"}
                        </Button>
                      </Flex>
                    );
                  })
                ) : (
                  <Text>No offers available</Text>
                )}
              </Flex>
            </Box>
          </div>
          {/* Cost breakUp */}
          <div
            style={{
              borderRadius: "4px",
              backgroundColor: "#ffffff",
              boxShadow: "0px 10px 30px #2c2c2c14",
              border: "1px solid #2C2C2C1A",
              maxHeight: "478px",
              minHeight: "385px",
              maxWidth: "414px",
              height: "100%",
              width: "100%",
              overflowY: "scroll",
              padding: "0.75em 1.5em",
              display: "flex",
              flexDirection: "column",
              gap: "0.35em",
            }}
          >
            {checkoutTransactionDetails && checkoutSubsctiptionDetails ? (
              <>
                <h1
                  style={{
                    fontSize: "20px",
                    color: "#2c2c2c",
                    lineHeight: "26px",
                    fontWeight: 600,
                  }}
                >
                  Cost Breakup
                </h1>
                <div
                  style={{
                    backgroundColor: "#1E32641A",
                    borderRadius: "4px",
                    padding: "0.5em 1em",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      color: "#1E3264",
                      opacity: "90%",
                      fontWeight: 600,
                    }}
                  >
                    {" "}
                    <span style={{ fontWeight: 700 }}> Order Id :</span>{" "}
                    {orderId ? orderId.VirtualSubsOrderId : ""}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: checkoutTransactionDetails ? "column" : "",
                    justifyContent: checkoutTransactionDetails
                      ? "space-between"
                      : "center",
                    alignItems: checkoutTransactionDetails ? "" : "center",
                    gap: "0.25em",
                    borderBottom: "3px solid #2c2c2c10",
                    paddingBottom: "0.5em",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: 600 }}>Subscription Type</p>
                    <p style={{ fontWeight: 600 }}>
                      {checkoutSubsctiptionDetails[0]?.SubscriptionTypeValue}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: 600 }}>Total Services</p>
                    <p style={{ fontWeight: 600 }}>
                      {" "}
                      {checkoutTransactionDetails.TotalServiceCount}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: 600 }}>Total Contacts</p>
                    <p style={{ fontWeight: 600 }}>
                      {checkoutTransactionDetails.TotalContactPurchased}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: 600 }}>Total Cost</p>
                    <p style={{ fontWeight: 600 }}>
                      {currencyDetails
                        ? `${currencyDetails.CurrencyValue} `
                        : ""}
                      {checkoutTransactionDetails.TotalAmountWithoutSD
                        ? parseFloat(
                            `${checkoutTransactionDetails.TotalAmountWithoutSD}`
                          ).toFixed(2)
                        : ""}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: 600 }}>Discount</p>
                    <p style={{ color: "#319F00", fontWeight: 600 }}>
                      -{" "}
                      {currencyDetails
                        ? `${currencyDetails.CurrencyValue} `
                        : ""}
                      {checkoutTransactionDetails.ServiceDiscountAmount
                        ? parseFloat(
                            `${checkoutTransactionDetails.ServiceDiscountAmount}`
                          ).toFixed(2)
                        : ""}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: checkoutTransactionDetails ? "column" : "",
                    justifyContent: checkoutTransactionDetails
                      ? "space-between"
                      : "center",
                    alignItems: checkoutTransactionDetails ? "" : "center",
                    gap: "0.25em",
                    borderBottom: "3px solid #2c2c2c10",
                    paddingBottom: "0.5em",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: 600 }}>Sub Total</p>
                    <p style={{ fontWeight: 600 }}>
                      {currencyDetails
                        ? `${currencyDetails.CurrencyValue} `
                        : ""}
                      {checkoutTransactionDetails.NetPayableAmountWithoutTax
                        ? parseFloat(
                            `${checkoutTransactionDetails.NetPayableAmountWithoutTax}`
                          ).toFixed(2)
                        : ""}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: 600 }}>Promo Discount</p>
                    <p style={{ color: "#319F00", fontWeight: 600 }}>
                      -{" "}
                      {currencyDetails
                        ? `${currencyDetails.CurrencyValue} `
                        : ""}
                      {typeof promoDiscount === "number"
                        ? parseFloat(`${promoDiscount}`).toFixed(2)
                        : ""}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: checkoutTransactionDetails ? "column" : "",
                    justifyContent: checkoutTransactionDetails
                      ? "space-between"
                      : "center",
                    alignItems: checkoutTransactionDetails ? "" : "center",
                    gap: "0.25em",
                    borderBottom: "3px solid #2c2c2c10",
                    paddingBottom: "0.5em",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: 600 }}>Net Payable</p>
                    <p style={{ fontWeight: 600 }}>
                      {currencyDetails
                        ? `${currencyDetails.CurrencyValue} `
                        : ""}
                      {checkoutTransactionDetails.NetPayableAmountWithoutTax &&
                      typeof promoDiscount === "number"
                        ? parseFloat(
                            checkoutTransactionDetails.NetPayableAmountWithoutTax -
                              promoDiscount
                          ).toFixed(2)
                        : ""}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: checkoutTransactionDetails ? "column" : "",
                    justifyContent: checkoutTransactionDetails
                      ? "space-between"
                      : "center",
                    alignItems: checkoutTransactionDetails ? "" : "center",
                    gap: "0.25em",
                    borderBottom: "3px solid #2c2c2c10",
                    paddingBottom: "0.5em",
                  }}
                >
                  {checkoutTaxDetails ? (
                    checkoutTaxDetails.map((taxType) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        key={taxType.TaxSubCategoryValue}
                      >
                        <p
                          style={{ fontWeight: 600 }}
                        >{`${taxType.TaxSubCategoryValue} ( ${taxType.TaxSubCategoryPercentage}% )`}</p>
                        <p style={{ fontWeight: 600 }}>
                          {currencyDetails
                            ? `${currencyDetails.CurrencyValue} `
                            : ""}

                          {taxType.taxAmount
                            ? parseFloat(`${updatedGstTax}`).toFixed(2)
                            : ""}
                        </p>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: checkoutTransactionDetails ? "column" : "",
                    justifyContent: checkoutTransactionDetails
                      ? "space-between"
                      : "center",
                    alignItems: checkoutTransactionDetails ? "" : "center",
                    gap: "0.25em",
                    borderBottom: "3px solid #2c2c2c10",
                    paddingBottom: "0.5em",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: 700,
                        color: "#1E3264",
                      }}
                    >
                      Amount Payable
                    </p>
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: 700,
                        color: "#1E3264",
                      }}
                    >
                      {currencyDetails
                        ? `${currencyDetails.CurrencyValue} `
                        : ""}
                      {checkoutTransactionDetails.TotalAmountAfterTax -
                      promoDiscount
                        ? parseFloat(updatedTotalAmount).toFixed(2)
                        : ""}
                    </p>
                  </div>
                </div>
                {/* TotalAmountAfterTax */}
              </>
            ) : (
              <p>Nothing to Show</p>
            )}
          </div>
        </div>
      </div>
      {/* <div
        style={{
          width: "100%",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxHeight: "78px",
        }}
      >
        <Button
          variant="gradient"
          w="347px"
          //   onClick={handleProceedToPay}
          onClick={() => {
            UserTypeValue === "Individual" && gstRequired
              ? hasGstNumber(gstNumber)
              : handleUpdateTransaction();
          }}
          justifySelf="center"
          value={
            transactionState && transactionState[2]
              ? transactionState[2].TransactionStateId
              : ""
          }
          //  isDisabled={totalCartLeads() === 0}
        >
          {transactionState && transactionState[2]
            ? transactionState[2].TransactionStateValue
            : "Proceed to Payments"}
        </Button>
      </div> */}
    </V5FixedFooterSidebarLayout>
  );
};
