import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomGeneralModal } from "../../../components/CustomGeneralModal/CustomGeneralModal";
import { Flex, Heading, Text } from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import doubleTick from "../../../../assets/webapp/common/double-tick.svg";
import { SelectCardWrapper } from "../../../components/SelectCardWrapper/SelectCardWrapper";
import { GetContentType } from "../../../services/community/community";
import {
  setAudienceModalOpen,
  setContentType,
  setContentTypeModalOpen,
} from "../slice/addCommunitySlice";

export const ChooseContentTypePopup = () => {
  const dispatch = useDispatch();
  const { isContentTypeModalOpen } = useSelector(
    (state) => state.postCommunity
  );
  const [contentTypesData, setContentTypesData] = useState([]);
  const [selectedContentType, setSelectedContentType] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await GetContentType();
      setContentTypesData(response?.Data?.ContentType);
    })();
  }, []);

  const onSubmit = async (value) => {
    dispatch(setContentType(value));
  };

  const contentTypesIcons = [
    {
      iconUrl: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/community/videos.svg`,
      type: "Videos",
      id: 4,
    },
    {
      iconUrl: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/community/article.svg`,
      type: "Articles",
      id: 3,
    },
    {
      iconUrl: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/community/project.svg`,
      type: "Projects",
      id: 1,
    },
  ];

  const contentTypesMergedList = contentTypesData.map((item) => {
    const matchedContent = contentTypesIcons.find(
      (contentIcon) => contentIcon.id === item.ContentId
    );
    return {
      ...item,
      iconUrl: matchedContent ? matchedContent.iconUrl : null,
    };
  });

  return (
    <CustomGeneralModal
      isOpen={isContentTypeModalOpen}
      iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/community/anouncement.svg`}
      hasCloseButton={true}
      onCloseButtonClick={() => {
        dispatch(setContentTypeModalOpen(false));
        setSelectedContentType(null);
      }}
      heading={
        <Text color={"#3CC200"} fontSize={"20px"} fontWeight={"700"}>
          Want To Spread Your Content?
        </Text>
      }
      subHeading={
        <Text color={"#1A1E21"} fontSize={"16px"} fontWeight={"700"}>
          Choose Your Content Type
        </Text>
      }
      extraHeading={
        <Text color={"#959596"} fontSize={"16px"} fontWeight={"600"}>
          Select Any One
        </Text>
      }
      primaryActionText={"Continue"}
      primaryButtonIsDisable={selectedContentType === null}
      buttonMinWidth={"100px"}
      primaryAction={() => {
        dispatch(setContentTypeModalOpen(false));
        onSubmit(selectedContentType);
        setSelectedContentType(null);
        dispatch(setAudienceModalOpen(true));
      }}
      secondaryActionText={"No Thanks"}
      secondaryAction={() => {
        dispatch(setContentTypeModalOpen(false));
        setSelectedContentType(null);
      }}
    >
      <Flex direction="column" gap="0.5em" overflowY={"scroll"}>
        <Flex
          height={"305px"}
          alignItems={"center"}
          flexDirection={"column"}
          margin={"0 50px"}
        >
          {contentTypesMergedList
            ? contentTypesMergedList.map((contentType) => (
                <SelectCardWrapper
                  backgroundcolor="white"
                  border="1px solid #E3E5E8"
                  width="338px"
                  height="60px"
                  key={contentType.ContentId}
                  value={contentType.ContentName}
                  isSelected={
                    selectedContentType?.ContentId === contentType?.ContentId
                  }
                  onClick={() =>
                    setSelectedContentType({
                      ContentId: contentType?.ContentId,
                      ContentValue: contentType?.ContentName,
                    })
                  }
                  icon={
                    <img src={contentType.iconUrl} alt="content type icon" />
                  }
                >
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <Heading
                      width="fit-content"
                      fontSize="20px"
                      fontWeight="600"
                      marginLeft={"3em"}
                    >
                      {contentType.ContentName}
                    </Heading>

                    {selectedContentType?.ContentId ===
                      contentType?.ContentId && (
                      <ReactSVG
                        src={doubleTick}
                        beforeInjection={(svg) => {
                          svg.setAttribute(
                            "style",
                            "height: 20px; width: 18px"
                          );
                        }}
                      />
                    )}
                  </Flex>
                </SelectCardWrapper>
              ))
            : null}
        </Flex>
      </Flex>
    </CustomGeneralModal>
  );
};
