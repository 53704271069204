import { WelcomePopUp } from "../../../../components/WelcomePopup/WelcomePopup";
import { NewSideMenuLayout } from "../../../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { useState, useEffect } from "react";
import { openMaterialTypeModal } from "../../slice/materialsSlice";
import { ChooseMaterialStorePopup } from "../../../../components/ChooseMaterialStorePopup/ChooseMaterialStorePopup";
import { useDispatch, useSelector } from "react-redux";
import { getMaterialStoreThunk } from "../../thunks/getMaterialStoreThunk";
import "./MaterialTypeStoreList.css";
import { useNavigate } from "react-router-dom";
import { convertToBase64 } from "../../../../../utils/formatConverter/convertToBase64";
import { clearStepperIndex } from "../../../addMaterial/slice/postMaterialsSlice";
import { Box, Flex } from "@chakra-ui/react";
import { v4 as uuid } from "uuid";
import { V5SidebarLayout } from "../../../../layout/V5SidebarLayout/V5SidebarLayout";

export const MaterialTypeStoreList = () => {
  const [isWelcomePopupOpen, setIsWelcomePopupOpen] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const materialstoreData = useSelector((state) => state.materials.data);
  const listresult = materialstoreData?.length;

  useEffect(() => {
    dispatch(getMaterialStoreThunk());
    dispatch(clearStepperIndex());
  }, []);

  return (
    // <div>
    //   <NewSideMenuLayout>
    //     <WelcomePopUp
    //       iconUrl={`${process.env.REACT_APP_CDN_PVT_URL}MobileAppAssets/bi_materials.png`}
    //       isOpen={isWelcomePopupOpen}
    //       descriptionArray={[
    //         "Add your Material Type",
    //         "List your Products and add to Marketplace",
    //         "View Leads in “My Leads” Section",
    //       ]}
    //       location="Materials Store"
    //       onButtonClick={() => {
    //         setIsWelcomePopupOpen(false);
    //       }}
    //     />

    //     <ChooseMaterialStorePopup />

    //     <Box margin={"22px 32px"}>
    //       {/* title */}
    //       <Flex justifyContent={"space-between"}>
    //         <Flex direction={"column"}>
    //           <p className="material-type-store__title">Material Store List</p>
    //           <p className="material-type-store__sub-title">
    //             Add your material and list them on marketplace
    //           </p>
    //         </Flex>

    //         <button
    //           className="material-type-store__add-button"
    //           onClick={() => {
    //             dispatch(openMaterialTypeModal());
    //           }}
    //         >
    //           + Add
    //         </button>
    //       </Flex>

    //       {/*cards container */}
    //       <Flex height={"100%"} maxH={"450px"} overflowY={"scroll"}>
    //         {listresult > 0 ? (
    //           <Flex flexWrap={"wrap"} gap={"42px"} margin={"22px 0"}>
    //             {materialstoreData.map((item) => (
    //               <Flex
    //                 className="material-type-store__card-layout"
    //                 key={uuid()}
    //               >
    //                 <Flex
    //                   width={"100%"}
    //                   height={"100%"}
    //                   justifyContent={"center"}
    //                   alignItems={"center"}
    //                 >
    //                   <div className="material-type-store__card-layout__image-container">
    //                     <img
    //                       src={`${process.env.REACT_APP_CDN_PVT_URL}MobileAppAssets/Material/${item.MaterialTypeIconFileRef}`}
    //                       alt="material-type"
    //                       className="material-type-store__card-layout__image"
    //                     />
    //                   </div>
    //                 </Flex>

    //                 <Flex
    //                   width={"100%"}
    //                   height={"100%"}
    //                   justifyContent={"center"}
    //                   alignItems={"100%"}
    //                   direction={"column"}
    //                 >
    //                   <p className="material-type-store__card-layout__material-type-name">
    //                     {item?.MaterialTypeValue}
    //                   </p>

    //                   <p className="material-type-store__card-layout__quantity-added">
    //                     {item?.AddedOnStore} Added{" "}
    //                   </p>

    //                   <p className="material-type-store__card-layout__added-on-market-place">
    //                     {item?.AddedOnMarketplace} On Marketplace
    //                   </p>

    //                   <div className="material-type-store__card-layout__added-on-seprator"></div>

    //                   <button
    //                     className="material-type-store__card-layout__view-store-button"
    //                     onClick={() => {
    //                       navigate(
    //                         `/materials/?type=${convertToBase64(
    //                           item.MaterialTypeValue
    //                         )}`
    //                       );
    //                     }}
    //                   >
    //                     <p>View</p>
    //                   </button>
    //                 </Flex>
    //               </Flex>
    //             ))}
    //           </Flex>
    //         ) : (
    //           <Flex
    //             justifyContent={"center"}
    //             alignItems={"center"}
    //             width={"100%"}
    //             height={"100%"}
    //           >
    //             <p className="material-type-store__card-container--empty-msg">
    //               No Store Created Yet
    //             </p>
    //           </Flex>
    //         )}
    //       </Flex>
    //     </Box>
    //   </NewSideMenuLayout>
    // </div>
    <V5SidebarLayout>
      <WelcomePopUp
        iconUrl={`${process.env.REACT_APP_CDN_PVT_URL}MobileAppAssets/bi_materials.png`}
        isOpen={isWelcomePopupOpen}
        descriptionArray={[
          "Add your Material Type",
          "List your Products and add to Marketplace",
          "View Leads in “My Leads” Section",
        ]}
        location="Materials Store"
        onButtonClick={() => {
          setIsWelcomePopupOpen(false);
        }}
      />

      <ChooseMaterialStorePopup />

      <Flex justifyContent={"space-between"} padding="0 22px">
        <Flex direction={"column"}>
          <p className="material-type-store__title">Material Store List</p>
          <p className="material-type-store__sub-title">
            Add your material and list them on marketplace
          </p>
        </Flex>

        <button
          className="material-type-store__add-button"
          onClick={() => {
            dispatch(openMaterialTypeModal());
          }}
        >
          + Add
        </button>
      </Flex>
      <Flex>
        {listresult > 0 ? (
          <Flex flexWrap={"wrap"} gap={"42px"} margin={"22px 0"}>
            {materialstoreData.map((item) => (
              <Flex className="material-type-store__card-layout" key={uuid()}>
                <Flex
                  width={"100%"}
                  height={"100%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <div className="material-type-store__card-layout__image-container">
                    <img
                      src={`${process.env.REACT_APP_CDN_PVT_URL}MobileAppAssets/Material/${item.MaterialTypeIconFileRef}`}
                      alt="material-type"
                      className="material-type-store__card-layout__image"
                    />
                  </div>
                </Flex>

                <Flex
                  width={"100%"}
                  height={"100%"}
                  justifyContent={"center"}
                  alignItems={"100%"}
                  direction={"column"}
                >
                  <p className="material-type-store__card-layout__material-type-name">
                    {item?.MaterialTypeValue}
                  </p>

                  <p className="material-type-store__card-layout__quantity-added">
                    {item?.AddedOnStore} Added{" "}
                  </p>

                  <p className="material-type-store__card-layout__added-on-market-place">
                    {item?.AddedOnMarketplace} On Marketplace
                  </p>

                  <div className="material-type-store__card-layout__added-on-seprator"></div>

                  <button
                    className="material-type-store__card-layout__view-store-button"
                    onClick={() => {
                      navigate(
                        `/materials/?type=${convertToBase64(
                          item.MaterialTypeValue
                        )}`
                      );
                    }}
                  >
                    <p>View</p>
                  </button>
                </Flex>
              </Flex>
            ))}
          </Flex>
        ) : (
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
          >
            <p className="material-type-store__card-container--empty-msg">
              No Store Created Yet
            </p>
          </Flex>
        )}
      </Flex>
    </V5SidebarLayout>
  );
};
