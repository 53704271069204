import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../helpers/initialState";
import { setSelectedCountryThunk } from "../thunk/setSelectedCountryThunk";
import { setSelectedLanguageThunk } from "../thunk/setSelectedLanguageThunk";
import { APP_CONFIG_LOCAL_STORE } from "../constants/localStoreConstants";
import { setLocalStorageItems } from "../../../../helpers/localStorage/localStorage";

export const AppSlice = createSlice({
    name: "app",
    initialState,
    reducers : {
        setCountry: (state, action) => { 
            state.country = action.payload
            setLocalStorageItems(APP_CONFIG_LOCAL_STORE.APP_COUNTRY, action.payload)
        }, 
        setLanguage: (state, action) => {
            state.language = action.payload
            setLocalStorageItems(APP_CONFIG_LOCAL_STORE.APP_LANGUAGE, action.payload)
        },
        setTheme: (state, action) => {
            state.theme = action.payload
            setLocalStorageItems(APP_CONFIG_LOCAL_STORE.APP_THEME, action.payload)
        },
        setOtpTimer : (state, action) => {
            state.otpimer = action.payload
            
        } 

    },
    extraReducers : (builder) => {
        builder
        .addCase(setSelectedLanguageThunk.pending, (state, action) => {
            state.loading = true
        })
        .addCase(setSelectedLanguageThunk.fulfilled, (state, action) => {
            state.loading = false
            state.language = action.payload
            setLocalStorageItems(APP_CONFIG_LOCAL_STORE.APP_LANGUAGE, action.payload)
        })
        .addCase(setSelectedLanguageThunk.rejected, (state, action) => {
            state.loading = false
            state.language = null
         
            state.error = action.error
        })
        .addCase(setSelectedCountryThunk.pending, (state, action) => {
            state.loading = true
            
        })
        .addCase(setSelectedCountryThunk.fulfilled, (state, action) => {
            state.loading = false
            state.country = action.payload
            setLocalStorageItems(APP_CONFIG_LOCAL_STORE.APP_COUNTRY, action.payload)
        })
        
    }
    
})

export const {setCountry, setLanguage, setTheme, setOtpTimer} = AppSlice.actions

export default AppSlice.reducer