import { API_CONSTANTS } from "../../../constants/apiConstants";
import { convertToIdValuePairs } from "../../helpers/dataConversion/convertToIdValuePairs";
import {fetchHandler} from "../../../index"

export const getSalaryTypes = async () => {
  try {
    const { data } = await fetchHandler(API_CONSTANTS.GET_SALARY_TYPE_LIST);
    if (data.Status === 200 && Object.keys(data.Data > 0)) {
      const requiredData = data.Data.SalaryTypeList.map(
        ({ SalaryTypeId, SalaryTypeValue }) =>
          convertToIdValuePairs(SalaryTypeId, SalaryTypeValue)
          
      );
      return requiredData;
    }
    return null;
  } catch (error) {
    throw error;
  }
};

export const getProvidentFundTypes = async () => {
  try {
    const { data } = await fetchHandler(API_CONSTANTS.GET_PROVIDENT_FUND_LIST);
    if (data.Status === 200 && Object.keys(data.Data > 0)) {
      const requiredData = data.Data.ProvidentFundList.map(
        ({ ProvidentFundId, ProvidentFundValue }) =>
          convertToIdValuePairs(ProvidentFundId, ProvidentFundValue)
      );
      return requiredData;
    }
    return null;
  } catch (error) {
    throw error;
  }
};

export const getPayCycleTypes = async() => {
    try {
        const { data } = await fetchHandler(API_CONSTANTS.GET_PAY_CYCLE_LIST);
        if (data.Status === 200 && Object.keys(data.Data > 0)) {
          const requiredData = data.Data.PayCycleList.map(
            ({ PayCycleId, PayCycleValue }) =>
              convertToIdValuePairs(PayCycleId, PayCycleValue)
          );
          return requiredData;
        }
        return null;
      } catch (error) {
        throw error;
      }
}