import { API_CONSTANTS } from "../../../constants/apiConstants"
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData";
import fetchHandler from "../fetchHandler"


export const getStoreDetails = async () => {
    try {
        const {data} = await fetchHandler(API_CONSTANTS.GET_STORE_DETAILS);
        if(data.Status === 200 && Object.keys(data.Data.StoreDetails).length > 0){
            return data.Data.StoreDetails ;
        }
        return null ;
    } catch (error) {
        throw error ;
    }
}

export const getCompanyStaffDetail = async (taxNumber) => {
    try {
        const {data} = await fetchHandler(`${API_CONSTANTS.GET_COMPANY_STAFF_DETAILS}?TaxNumber=${taxNumber}`);
        if(data.Status === 200 && Object.keys(data.Data.StaffDetails).length > 0){
            return data.Data.StaffDetails
        }
        else{
            return null
        }
    } catch (error) {
        throw error ;
    }
}

export const postStoreDetails = async (payload) => {
    try {
        const {data} = await fetchHandler(
        API_CONSTANTS.POST_STORE_DETAILS,
        {},
        {},
        convertToFormData(payload),
        API_CONSTANTS.HTT_POST
        );
        if(data.Status === 204 && Object.keys(data.Data).length > 0){
            return {StoreId : data.Data.StoreId, message : data.Message.SuccessMessage } ;
        }
        else{
            return {message : data.Message.FailMessage} ;
        }
    } catch (error) {
        throw error ;
    }
}

export const postStoreManagerDetails = async (payload) =>  {
    try {
        const {data} = await fetchHandler(
            API_CONSTANTS.POST_STORE_MANAGER_DETAILS,
            {},
            {},
            convertToFormData(payload),
            API_CONSTANTS.HTT_POST
        );
        if(data.Status === 200 & Object.keys(data.Data).length >0){
            return {StoreManagerDetails : data.Data.StoreManagerDetails , message : data.Message.SuccessMessage} ;
        }else{
            return {message : data.Message.FailMessage};
        }
    } catch (error) {
        throw error ;
    }
}
