import { Box, Button, Flex, Text } from "@chakra-ui/react";
import pdfIcon from "../../../assets/webapp/common/pdf-icon.svg";

export const ImageViewWithTitle = ({
  title = "",
  mediaUrl = "",
  onDeleteImageClick = () => {},
  type = "image",
}) => {
  return (
    <Box
      height="200px"
      width={type === "video" ? "350px" : "200px"}
      borderRadius="0.5em"
      border="2px solid #EEEEEE"
      flexShrink="0"
    >
      <Flex
        flexDirection="column"
        height="100%"
        width="100%"
        justifyContent="space-between"
      >
        <Box position="relative" height="80%">
          {mediaUrl &&
          (mediaUrl.split(".")[mediaUrl.split(".").length - 1] === "jpg" ||
            mediaUrl.split(".")[mediaUrl.split(".").length - 1] === "jpeg" ||
            mediaUrl.split(".")[mediaUrl.split(".").length - 1] === "png" ||
            mediaUrl.split(".")[mediaUrl.split(".").length - 1] === "webp" ||
            mediaUrl.split(".")[mediaUrl.split(".").length - 1] === "heic") ? (
            <img
              src={mediaUrl}
              style={{
                height: "100%",
                width: "100%",
                borderTopRightRadius: "0.4em",
                borderTopLeftRadius: "0.4em",
              }}
            />
          ) : mediaUrl &&
            (mediaUrl.split(".")[mediaUrl.split(".").length - 1] === "mp4" ||
              mediaUrl.split(".")[mediaUrl.split(".").length - 1] === "avi") ? (
            <video
              src={mediaUrl}
              style={{
                height: "100%",
                width: "100%",
                borderTopRightRadius: "0.4em",
                borderTopLeftRadius: "0.4em",
              }}
              controls={true}
            />
          ) : mediaUrl &&
            mediaUrl.split(".")[mediaUrl.split(".").length - 1] === "pdf" ? (
            <img
              src={pdfIcon}
              style={{
                height: "100%",
                width: "100%",
                borderTopRightRadius: "0.4em",
                borderTopLeftRadius: "0.4em",
              }}
            />
          ) : (
            <></>
          )}
          <Box borderTopRightRadius="0.4em" borderTopLeftRadius="0.4em"></Box>
          <Box
            textAlign="center"
            position="absolute"
            top="0"
            left="0"
            backgroundColor="#2c2c2c70"
            width="100%"
            height="30px"
            borderTopRightRadius="0.4em"
            borderTopLeftRadius="0.4em"
          >
            <Text color="#fff" fontSize="18px" fontWeight="700">
              {title ? title : ""}
            </Text>
          </Box>
        </Box>
        <Flex
          height="20%"
          width="100%"
          backgroundColor="#FFF"
          borderBottomRightRadius="0.4em"
          borderBottomLeftRadius="0.4em"
          gap="0.25em"
          justifyContent="center"
        >
          {/* <Button
            isDisabled={!image && !imagePreview}
            onClick={editImage}
          >
            Edit
          </Button> */}
          <Button
            isDisabled={!mediaUrl}
            onClick={onDeleteImageClick}
            boxShadow="none"
            outline="none"
            background="transparent"
            _hover={{ background: "transparent" }}
          >
            <img
              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/delete.svg`}
              style={{ height: "20px", width: "20px" }}
            />
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};
