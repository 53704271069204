import { createSlice } from "@reduxjs/toolkit";
import { getDealershipMarketplaceInitialDataThunk } from "../thunks/getDealershipMarketplaceInitialDataThunk";
import { dealershipInitialFilteredDataThunk } from "../thunks/dealershipInitialFilteredDataThunk";
import { getDealershipLoadMoreDataThunk } from "../thunks/getDealershipLoadMoreDataThunk";
import { loadMoreFilteredData } from "../thunks/loadMoreFilteredDataThunk";

export const dealershipMarketplaceSlice = createSlice({
    name : "dealershipMarketplace",
    initialState : {
        data: null,
        paginationCount: 0,
        endOfData: false,
        cardCount: 12,
        initialLoading: false,
        paginationLoading: false,
        filterByEquipmentMake : "",
        filterByCountry: "",
        filterByState: "",
        filteredDataRequired: false,
        filterModalOpen: false,
        error: null,
        errorMessage: null,
        connectDealership : false,
        dealershipCount : "",
        isOpenConnectDealershipModal:false,
        likeStatusData : null,
        likeMergedData : null,
    },
    reducers: {
        openConnectDealershipModal: (state) => {
          state.isOpenConnectDealershipModal = true;
        },
        closeConnectDealershipModal: (state) => {
          state.isOpenConnectDealershipModal = false;
        },
        setData: (state, action) => {
          state.data = action.payload;
        },
        setPaginationCount: (state, action) => {
          state.paginationCount = action.payload;
        },
        setEndOfData: (state, action) => {
          state.endOfData = action.payload;
        },
        setInitialLoading: (state, action) => {
          state.initialLoading = action.payload;
        },
        setPaginationLoading: (state, action) => {
          state.paginationLoading = action.payload;
        },
        setFilteredDataRequired: (state, action) => {
          state.filteredDataRequired = action.payload;
        },
        setFilterModalOpen: (state, action) => {
          state.filterModalOpen = action.payload;
        },
        setFilterByCountry: (state, action) => {
          // console.log(action.payload)
          state.filterByCountry = action.payload;
        },
        setFilterByState: (state, action) => {
          state.filterByState = action.payload;
        },
        setFilterByEquipmentMake : (state, action) =>{
            state.filterByEquipmentMake = action.payload
        },
        resetFilters: (state) => {
          state.filterByEquipmentMake = null;
          state.filterByCountry = null;
          state.filterByState = null;
          state.filteredDataRequired = false;
          state.filterModalOpen = false;
        },
        setError: (state, action) => {
          state.error = action.payload;
        },
        setErrorMessage: (state, action) => {
          state.errorMessage = action.payload;
        },
        setConnectDealership : (state, action) =>{
          state.connectDealership = action.payload;
        },
        setDealershipCount : (state , {payload}) =>{
          state.dealershipCount = payload
        },
        setLikeStatusData : (state,{payload}) => {
          if(payload){
            state.likeStatusData = payload?.E9DealershipMpLikeList
          }
        },
        setLikeMergedData : (state,{payload}) => {
          if(payload){
            state.likeMergedData = payload
          }
        }
        
      },

    extraReducers : (builder) =>{
        builder
         .addCase(getDealershipMarketplaceInitialDataThunk.pending, (state) =>{
            state.data = null;
            state.paginationCount = 0;
            state.endOfData = false;
            state.cardCount = 12;
            state.initialLoading = true;
            state.paginationLoading = false;
            state.error = null;
            state.errorMessage = null;
         })
         .addCase(getDealershipMarketplaceInitialDataThunk.fulfilled, (state, action) =>{
          // console.log("Payload" , action);
            state.data = action.payload
            state.dealershipCount = action.payload?.length
            state.paginationCount = 1;
            state.initialLoading = false;
            state.paginationLoading = false;
            state.error = null;
            state.errorMessage = null;
            if (action.payload?.E9DsMarketplaceList < state.cardCount) {
                state.endOfData = true;
            }
         })
         .addCase(getDealershipLoadMoreDataThunk.pending, (state)=>{
            state.paginationLoading = true;
            state.error = false ;
            state.errorMessage = null;
         })
         .addCase(getDealershipLoadMoreDataThunk.fulfilled, (state,action)=>{
            state.error = false ;
            state.errorMessage = null ;
            state.paginationLoading = false;
            if(action.payload?.data?.length >= state.cardCount){
              state.data = [...state.data , ...action.payload?.data];
                state.paginationCount += 1;
            }else{
                state.endOfData = true;
         }
         })
         .addCase(dealershipInitialFilteredDataThunk.pending,(state)=>{
          // console.log("FILTERED STATE",state);

            state.data = null;
            state.initialLoading = true;
            state.paginationLoading = false;
            state.error = false;
            state.errorMessage = false;
            state.paginationCount = 1;
            state.endOfData = false;
         })
         .addCase(dealershipInitialFilteredDataThunk.fulfilled, (state, action) => {
            // console.log("Marketplace  Filter Data" , action);
            state.data = action.payload;
            state.dealershipCount = action.payload?.length
            state.paginationCount = 1;
            state.initialLoading = false;
            state.error = false;
            state.errorMessage = null;
            if(action.payload?.length < state.cardCount){
              state.endOfData = true
            }
          })

          .addCase(loadMoreFilteredData.pending, (state)=>{
            state.paginationLoading = true;
            state.error = false;
            state.errorMessage = null;
            state.error = false;
            state.errorMessage = null;    
          })
          .addCase(loadMoreFilteredData.fulfilled, (state, action) => {
            state.error = false;
            state.dealershipCount = action.payload?.length
            state.errorMessage = null;
            state.paginationLoading = false;
            if (action.payload?.length >= state.cardCount) {
              state.data = [...state.data, ...action.payload];
              state.paginationCount += 1;
            } else {
              state.endOfData = true;
            }
          })
    },
});

export const {
    setData,
    setPaginationCount,
    setEndOfData,
    setInitialLoading,
    setPaginationLoading,
    setFilteredDataRequired,
    setFilterByEquipmentMake,
    setFilterByCountry,
    setFilterByState,
    setFilterModalOpen,
    resetFilters,
    setError,
    setErrorMessage,
    setConnectDealership,
    setDealershipCount,
    openConnectDealershipModal,
    closeConnectDealershipModal,
    setLikeStatusData,
    setLikeMergedData
} = dealershipMarketplaceSlice.actions ;