import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllLanguages, getAllLanguagesJson } from "../../../../services/masterData/masterData";
import languageConstant from "../../../../constants/languageConstant";

export const getAllLanguagesThunk = createAsyncThunk(
    'languages/get',
    async (isdCode, { rejectWithValue }) => {
      try {

        const data = await getAllLanguages(isdCode);      
        return data 
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

    export const getAllLanguageJsonThunk = createAsyncThunk(
  'languages/Json',
  async  (selectedLanguage,  {}) =>  {
    try {
      const data = await getAllLanguagesJson(selectedLanguage);
      if (data) {
        if (data) {
        return data;
      } else {
        return languageConstant;
      }
      } else {
        return languageConstant;
      }
    } catch (error) {
      return languageConstant;
    }
  }
);
;
