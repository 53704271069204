import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetUserDealershipList } from "../../../services/dealershipListing/dealershipListing";


export const dealershipListingLoadMoreThunk = createAsyncThunk("dealershipListingLoadMore/getLoadCards",async(_,{getState}) =>{
    try {
        const {cardCount,paginationCount} = getState().dealershipAssests
        const data = await GetUserDealershipList(cardCount,cardCount * paginationCount);
        if(data?.Status === 200){
            return data?.Data?.UserDealershipList;
        }
        return null ;
    } catch (error) {
        
    }
})