import "./MyBusinessStore.css";
import React from "react";
import { SideMenuLayout } from "../../../layout/sideMenuLayout/SideMenuLayout";
import { DashboardNavBar } from "../../DashboardNavBar/DashboardNavBar";
import { MidSection } from "../../../../sharedComponent/midSection/MidSection";
import { ListingInformation } from "./ListingInformation/ListingInformation";
import { ShowMarketPlaceCard } from "./AddtomarketplaceCard/ShowMarketCard";
import { useParams, useLocation } from "react-router-dom";
import { AddMarketPlaceStep1 } from "./AddMarketPlace/AddMarketPlace";
import { useNavigate } from "react-router-dom";
export const MyBusinessStore = () => {
  const {MaterialTypeId}=useParams();
  const navigate=useNavigate();

  const onhandleNavigate=()=>{
    navigate(`/my-business-material-add-material-form-step-one/${MaterialTypeId}`,{
    })
    
  };
  
  return (
    <SideMenuLayout>
      <DashboardNavBar />
      <MidSection>
        <div>
          <div className="spare-list-layout">
            <p className="spare-list-heading">{localStorage.getItem('MaterailTypeValue')} Store Details</p>
            <div className="subheading-layout">
              <p className="Material-store-subheading">
                Add your {localStorage.getItem('MaterailTypeValue')} and list them on Marketplace
              </p>
              <div   style={{cursor:"pointer"}} className="add-button-marketplace" onClick={onhandleNavigate}>
                <p className="add-store-text ">+ Add</p>
              </div>
            </div>
          </div>
          <ListingInformation />
          <ShowMarketPlaceCard />
          
        </div>
      </MidSection>
    </SideMenuLayout>
  );
};
