import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Avatar from "../../../assets/webapp/doc_flow/My-profile.png";
import "./SideMenuProfile.css";
import { ReactSVG } from "react-svg";
import { Tooltip } from "@chakra-ui/react";

export const SideMenuProfile = () => {
  const userData = useSelector((state) => state.user);
  const loginData = useSelector((state) => state.login);
  let displayValue =
    userData?.accountDetails?.UserDetails?.E9UserRole?.UserRoleTypeName;
  let finalValue = displayValue !== undefined ? displayValue : "NA";

  return (
    <Box
      borderBottom="1px solid #2c2c2c40"
      padding="0.75em 0.5em"
      paddingBottom="2em"
    >
      <Flex gap="0.75em">
        <Image
          h="65px"
          w="65px"
          borderRadius="50%"
          border="1px solid #000000"
          src={
            userData.accountDetails?.UserDetails?.ProfilePicture
              ?.ProfilePictureFileReference
              ? `${process.env.REACT_APP_CDN_PVT_URL}UserProfilePicture/${userData.accountDetails.UserDetails.ProfilePicture.ProfilePictureFileReference}`
              : Avatar
          }
        />
        <Flex flexDirection="column">
          <Text fontSize="18px" fontWeight="600" mx="1">
            {userData.accountDetails
              ? `${userData?.accountDetails?.UserDetails?.PersonalDetails?.FirstName}`
              : "User"}{" "}
          </Text>
          <Text>
            {userData.accountDetails
              ? `+${userData?.accountDetails?.UserDetails?.CredentialDetails?.IsdCode} ${userData.accountDetails?.UserDetails?.CredentialDetails?.MobileNumber}`
              : "N.A."}{" "}
          </Text>
          <Text
            textTransform="uppercase"
            color="#F79C00"
            fontSize="12px"
            fontWeight="600"
          >
            {loginData?.selectedAccount
              ? `${loginData?.selectedAccount?.BusinessTypeValue}`
              : "N.A."}
          </Text>
          <Tooltip label={`${finalValue}`}>
            <Text
              textTransform="capitalize"
              color="#1E3264"
              fontSize="12px"
              fontWeight="600"
            >
              {`${finalValue.substring(0, 14)}...`}
            </Text>
          </Tooltip>
        </Flex>
      </Flex>
      <div className="user-rating-previous">
        <ReactSVG
          className="user-rating-icon"
          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/star.svg`}
        />
        <p className="user-rating-text">
          {
            userData?.accountDetails?.UserDetails?.CredentialDetails
              ?.OverAllRating
          }
        </p>
      </div>
    </Box>
  );
};
