import './AddsPhotopopup.css';
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import {
  AlertDialog,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import { useQuery, useQueryClient } from "react-query";
import * as Yup from "yup";
import { Formik, Form, useField, Field } from "formik";
import {
  getPhotoType
} from "../../../../../../../ApiEquip9/CommonApi";
import './AddsPhotopopup.css';
import { postMaterialImages, updateMaterialImges } from '../../../../../../services/materials/materials';

export const MaterailPhotoUpload = ({
  isLogoutModalOpenPopUp,
  setIsLogoutModalOpenPopUp,
  MaterialId,
  setPhotoUploaded,
}) => {
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const onCloseLogoutModal = () => {setIsLogoutModalOpenPopUp(false);
    setSelectedPhotoTypeId("");
    setFilePreview(null);
  }
  const cancelReff = React.useRef();
  const [ListPhotoType, setListPhotoType] = useState(null);
  const [selectedPhotoTypeId, setSelectedPhotoTypeId] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [tempFileData, setTempFileData] = useState(null);
  const [fileType, setFileType] = useState("");
  const fileInputRef = useRef();
  const toast = useToast();
    /**************use query */
    const { data: getphotoType } = useQuery("getphotoType", () =>
    getPhotoType(userData.token)
  );
    /***** update result set */
    useEffect(() => {
      setListPhotoType(getphotoType?.data?.Data?.PhotoTypeList);
    }, [getphotoType]);

  const handleFileChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setFieldValue("file", file);
      setFileType(file.type);

      // Check local Storage
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setTempFileData(base64String); // Store the Base64 string temporarily
      };


      if (file.type.startsWith("image/")) {
        reader.readAsDataURL(file);
        setFilePreview(URL.createObjectURL(file));
      } else if (file.type === "application/pdf") {
        // For PDF, use a URL to the file
        setFilePreview(URL.createObjectURL(file));
      } else if ([".xls", ".xlsx"].some((ext) => file.name.endsWith(ext))) {
        // For Excel, set a generic icon or message
        setFilePreview("Excel file selected"); // Replace with an appropriate icon or message
      } else {
        // For other file types
        setFilePreview("File selected");
      }
    }
  };


/***********selected when selectedPhotoTypeId is set  */
  useEffect(() => {
    if (selectedPhotoTypeId && tempFileData) {
      localStorage.setItem(`photoType_${selectedPhotoTypeId}`, tempFileData);
      setTempFileData(null); // Clear temporary data
    }
  }, [selectedPhotoTypeId, tempFileData]);

  /***************** */

  const FileUploadSchema = Yup.object().shape({
    file: Yup.mixed()
      .required("Image is required")
      .test(
        "fileSize",
        "File too large",
        (value) => value && value.size <= 1048576
      )
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) =>
          value && ["image/jpeg", "image/png", "image/gif"].includes(value.type)
      ),
    seletedPhotoId: Yup.string().required("Please select Photo type"),
  });


  const LastId=parseInt(localStorage.getItem('LastId'))
  /*********Submmit Form  */
  const submitForm = async (value, { resetForm }) => {
    if(LastId){
      const payload = {
        MaterialId:LastId ,
        ImageTypeId: parseInt(selectedPhotoTypeId),
        ImageFile: value.file,
      };
      // console.log(payload)
      try {
        const data = await postMaterialImages(payload);
        if (data.Status === 201) {
          toast({
            title: "",
            description: `${data?.Message?.SuccessMessage}`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          setFilePreview(null);
          setPhotoUploaded(true);
          resetForm();
        }
  
        onCloseLogoutModal();
      } catch (error) {
        toast({
          title: "",
          description: `Error while uploading the image`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }

    }
    else{
      
        const payload = {
          MaterialId: parseInt(MaterialId) ,
          ImageTypeId: parseInt(selectedPhotoTypeId),
          ImageFile: value.file,
        }; 
        // console.log(payload);
      debugger
        try {
          const result = await updateMaterialImges(payload);
          debugger
          if (result.data.Status === 201) {
            toast({
              title: "",
              description: `${result?.data?.Message?.SuccessMessage}`,
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
            setFilePreview(null);
            setPhotoUploaded(true);
          }
    
          onCloseLogoutModal();
        } catch (error) {
          toast({
            title: "",
            description: `Error while uploading the image`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }

    
  }

  
  
  };
  return (
    <div>
      <AlertDialog
        isOpen={isLogoutModalOpenPopUp}
        leastDestructiveRef={cancelReff}
        onClose={onCloseLogoutModal}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              <div
                className="material-pop-up-close"
                onClick={onCloseLogoutModal}
              >
                <ReactSVG
                  style={{ cursor: "pointer" }}
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Materialpopclose.svg`}
                />
              </div>
              <div className="Material-pop-header-layout-Image">
                <ReactSVG
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}WebApp/Advertisements/LargeAdvertisments/icons/UploadMaterial.svg`}
                />
                <p className="Material-pop-header-heading">Upload Photo</p>
                <p className="Material-pop-header-subheading-materail">
                  Upload a photo and Select a Name
                </p>
              </div>
            </AlertDialogHeader>
            <div>
              <Formik
                initialValues={{ file: "", seletedPhotoId: "" }}
                validationSchema={FileUploadSchema}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                  submitForm(values, { resetForm });
                  setSubmitting(false);
                }}
              >
                {({
                  isSubmitting,
                  isValid,
                  dirty,
                  setFieldValue,
                  touched,
                  errors,
                }) => (
                  <div className="form-submit-button">
                    <div>
                      <Form>
                        <div
                          className="form-submit-button"
                          style={{
                            border: "1px solid var(--Light-50, #E3E5E8)",
                            maxWidth: "360px",
                            height: "200px",
                            maxHeight: "200px",
                            marginLeft: "10%",
                            marginTop: "12px",
                            background: " var(--Light-30, #F5F5F5)",
                          }}
                        >
                          {fileType.startsWith("image/") && filePreview  ? (
                            <img
                              src={filePreview}
                              alt="Preview"
                              style={{
                                maxWidth: "360px",
                                maxHeight: "200px",
                                objectFit: "cover",
                              }}
                            />
                          ) :(<>
                          <div
                          style={{
                            maxWidth: "360px",
                            maxHeight: "200px",
                            marginTop:"85px"
                            
                          }}
                          >
                           No Photo Added
                          </div>
                          
                          </>)}
                          {fileType === "application/pdf" && filePreview && (
                            <embed
                              src={filePreview}
                              type="application/pdf"
                              width="100"
                              height="100"
                              style={{ border: "1px solid black" }}
                            />
                          )}
                        </div>
                        <br />

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <label htmlFor="file" className={filePreview?"docbutton-ongoing":"docbutton-ongoing-change"}>
                          <p className={filePreview ? "upload-text-black" : "upload-text"}>
                             Upload
                        </p>
                          </label>
                        </div>
                        <input
                          id="file"
                          name="file"
                          type="file"
                          style={{ display: "none" }} // Hide the default input
                          onChange={(event) =>
                            handleFileChange(event, setFieldValue)
                          }
                        />
                        {touched.file && errors.file && (
                          <div className="error-text-ongoing">
                            {errors.file}
                          </div>
                        )}

                        <div className="work-description-layout">
                          <label
                            htmlFor="description"
                            className="work-description-text"
                          >
                            Name of Your Photo
                            <span className="error-text-ongoing-star">*</span>
                          </label>
                          <br />
                          <Field
                            as="select"
                            id="description"
                            name="seletedPhotoId"
                            className="upload-type-photo-image"
                            value={selectedPhotoTypeId}
                            onChange={(e) => {
                              setSelectedPhotoTypeId(e.target.value);
                              setFieldValue("seletedPhotoId", e.target.value);
                            }}
                          >
                            <option value="">Select an Option</option>
                            {ListPhotoType?.map((item, index) => (
                              <option key={index} value={item.PhotoTypeId}>
                                {item.PhotoTypeValue}
                              </option>
                            ))}
                          </Field>
                          {touched.seletedPhotoId && errors.seletedPhotoId && (
                            <div className="error-text-ongoing">
                              {errors.seletedPhotoId}
                            </div>
                          )}
                        </div>
                        <div className="submit-reject">
                          <button
                            style={{ marginTop: "2px" }}
                            type="submit"
                            className="reject-button-ongoing"
                            onClick={onCloseLogoutModal}
                          >
                            <p className="accept-job-text">No Thanks</p>
                          </button>
                          <button
                            type="submit"
                            className="accept-button-ongoing"
                            disabled={isSubmitting}
                          >
                            <p className="canceljob-text">Submit</p>
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};
