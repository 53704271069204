import { Box, Flex, Text } from "@chakra-ui/react";

export const NoPartnerAppliedPlaceholder = ({
  orderID = 0,
  partnerCount = 0,
  jobType = "Operator Partner Job",
}) => {
  return (
    <Flex
      flexDirection="column"
      gap="1rem"
      border="1px solid #F67C00"
      borderRadius="4px"
      padding="1rem 2rem"
    >
      <Text fontSize="18px" fontWeight="600">
        Dear Business Owner, Thank You for posting a job on EQUIP9™ digital
        platform app. Please find the details of your order.
      </Text>
      <Box>
        <ul>
          <li style={{ fontSize: "18px" }}>
            <span style={{ color: "#767676" }}>Order Id :</span>{" "}
            <span style={{ fontWeight: 600 }}>{orderID}</span>
          </li>
          {/* <li style={{ fontSize: "18px" }}>
            <span style={{ color: "#767676" }}>Job Type :</span>{" "}
            <span style={{ fontWeight: 600 }}>{jobType}</span>
          </li> */}
          <li style={{ fontSize: "18px" }}>
            <span style={{ color: "#767676" }}>
              Number of Partners Accepted your Job :
            </span>{" "}
            <span style={{ fontWeight: 600 }}>{partnerCount}</span>
          </li>
        </ul>
      </Box>
      <Text>
        EQUIP9™ app will notify you once any Maintenance Partner accepts your
        job.
      </Text>
      <Text>
        You can check the current status of your job under{" "}
        <span style={{ fontWeight: "600" }}>
          My Order {" -> "} In Process tab{" "}
        </span>
        .
      </Text>
      <Text>
        EQUIP9™ app will notify you once any Maintenance Partner accepts your
        job.
      </Text>
      <Text>To create more job please click the below buttons.</Text>
    </Flex>
  );
};
