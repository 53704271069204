import React, { useEffect, useState } from "react";

import { V5SidebarLayout } from "../../../layout/V5SidebarLayout/V5SidebarLayout";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { initialIndustryThunk } from "../thunk/initialIndustryThunk";
import { useDispatch, useSelector } from "react-redux";
import IndustryRequirementCard from "../../../components/ImageViewWithTitle/IndustryRequirementCard";
import { MarketplaceHoc } from "../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { loadMoreIndustryThunk } from "../thunk/loadMoreIndustryThunk";
import CommonHeader from "../../../components/CommonHeader/CommonHeader";
import { FilterPopUp } from "../../marketplace/hoc/FilterPopUp/FilterPopUp";
import {
  resetRequirement,
  setConnectModal,
  setFilterByCategory,
  setFilterByCity,
  setFilterByCountry,
  setFilterByInputMethod,
  setFilterByState,
  setFilterByTimeRange,
  setFilteredDataRequired,
  setFilterModalOpen,
  setInputMethodId,
  setInputModal,
  setLikeMergedData,
  setLikeStatusData,
  setReportModal,
  setRequirementConnectList,
} from "../slice/industryRequirementSlice";
import { MARKETPLACE_TYPES } from "../../../constants/e9Constants";
import { initialFilterIndustryThunk } from "../thunk/initialFilterIndustryThunk";
import { loadMoreFilterIndustryThunk } from "../thunk/loadMoreFilterIndustryThunk";
import { useNavigate } from "react-router-dom";
import { SinglePurposePopup } from "../../../components/SinglePurposePopup/SinglePurposePopUp";
import { CustomInputModal } from "../../../components/CustomInputModal/CustomInputModal";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { FormikInput } from "../../../components/Input/FormikInput";
import {
  checkRequirementConnectList,
  connectRequirementApi,
  getIndustryLikeList,
  getInputTypeList,
  industryReport,
} from "../../../services/industryRequirement/industryRequirement";
import { convertToFormData } from "../../../helpers/dataConversion/convertToFormData";
import { SelectCardWrapper } from "../../../components/SelectCardWrapper/SelectCardWrapper";
import { imageSelector } from "../../../helpers/categoryImageHandler/categoryImageHandler";
import { openQrModal } from "../../dashboard/slice/dashboardSlice";
import { QrPopUp } from "../../../components/ScanQrCodeModal/QrPopUp";
import { CustomGeneralModal } from "../../../components/CustomGeneralModal/CustomGeneralModal";
import doubleTick from "../../../../assets/webapp/common/double-tick.svg";
import { ReactSVG } from "react-svg";
import { WelcomePopUp } from "../../../components/WelcomePopup/WelcomePopup";
import { fetchHandler } from "../../../..";
import { API_CONSTANTS } from "../../../../constants/apiConstants";

const IndustryRequirementListing = () => {
  const {
    data,
    endOfData,
    paginationCount,
    initialLoading,
    paginationLoading,
    isFilterOpen,
    filteredDataRequired,
    isReportPopUpopen,
    selectInputTypeModal,
    connectModal,
    likeStatusData,
    likeMergedData
  } = useSelector((state) => state.industryRequirement);
  const { accountDetails } = useSelector((state) => state.user);

  const [RequirementDetailsId, setRequirementDetailsId] = useState();
  const [inputList, setInputList] = useState();
  const [selectedInput, setSelectedInput] = useState(null);
  const [connectBody, setConnectBody] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [charCount, setCharCount] = useState(500);
  const [isWelcomePopupOpen, setIsWelcomePopupOpen] = useState(true);



  useEffect(()=>{
    (()=>{
      dispatch(resetRequirement());
    })()
  },[])


  // Like Handling

  useEffect(()=>{
    getRequirementLikeList() ;
  },[])

  useEffect(()=>{
    mergedData() ;
  },[likeStatusData, data])

  const mergedData = async () => {
    try {
      const likedData = await Promise.all(
        data.map(async (x) => {
          const matchingLike = likeStatusData?.find(
            (y) => y?.RequirementDetailsId === x?.RequirementDetailsId
          );
          return {
            ...x,
            likeStatus: matchingLike ? true : false,
          };
        })
      );
  
      dispatch(setLikeMergedData(likedData));
    } catch (error) {

      const errorData = data?.map((x) => ({
        ...x,
        likeStatus: false,
      }));
      dispatch(setLikeMergedData(errorData));
    }
  };
  

  const getRequirementLikeList = async () =>{
    const data = await getIndustryLikeList() ;
    if(data && data?.length > 0){
      dispatch(setLikeStatusData(data))
    } else {
      dispatch(setLikeStatusData(data)) ;
    }
  } 


  const getIndustryRequirementList = async () => {
    await dispatch(initialIndustryThunk());
  };
  const getInputMethodList = async () => {
    const data = await getInputTypeList();
    if (data?.length > 0) {
      setInputList(data);
    }
  };
  useEffect(() => {
    getIndustryRequirementList();
    getInputMethodList();
    getConnectList();
  }, []);

  const filterFunction = (values) => {
    dispatch(setFilterByCity(values.city));
    dispatch(setFilterByCountry(values.country));
    dispatch(setFilterByState(values.state));
    dispatch(setFilterByInputMethod(values.inputMethod));
    dispatch(setFilterByCategory(values.requirementCategory));
    dispatch(setFilterByTimeRange(values.requirementTimeRange));

    if (
      values.inputMethod ||
      values.requirementCategory ||
      values.requirementTimeRange ||
      values.city ||
      values.state ||
      values.country
    ) {
      dispatch(setFilteredDataRequired(true));
    } else {
      dispatch(setFilteredDataRequired(false));
    }
    dispatch(initialFilterIndustryThunk());
    dispatch(setFilterModalOpen(false));
  };

  const handleReport = async (values) => {
    const payload = {
      RequirementDetailsId: RequirementDetailsId,
    };
    const data = await industryReport(values, payload);
    if (data?.Status === 204) {
      toast({
        status: "success",
        description: "Requirement has been reported",
        position: "top-right",
        isClosable: true,
      });
    }

    dispatch(setReportModal(false));
  };

  const getConnectList = async () => {
    const data = await checkRequirementConnectList();
    if (data?.Success === true) {
      dispatch(setRequirementConnectList(data?.RequirementDetailsId));
    }
  };

  // Connect
  const handleConnect = async (ownerId,requirementDetailsId) => {
    if (
      accountDetails?.UserDetails?.CredentialDetails?.UserCredentialId ===
      ownerId
        ? true
        : false
    ) {
      navigate('/industry-requirement-leads?marketplace-type=industry-requirement&request-type=sent')
    } else {
      dispatch(setConnectModal(true));
      const payload = {
        "RequirementDetailsId" : requirementDetailsId,
        "OwnerId" : ownerId,
        "ResponseTypeId" : 1
      }
      setConnectBody(payload) ;
    }
  };

  const renderCards = (array) => {
    const renderJSX =
      array && array.length > 0 ? (
        array.map((data) => (
          <>
            <IndustryRequirementCard
            requestCount = {data?.RequestCount}
            imageData={data?.ImageList}
              ownerId={
                accountDetails?.UserDetails?.CredentialDetails
                  ?.UserCredentialId === data?.OwnerId
                  ? true
                  : false
              }
              connectId={data?.RequirementDetailsId}
              CreatedDateTime={data?.CreatedDateTime}
              ExpiredOn={data?.ExpiredOn}
              ProfilePhoto={data?.ProfilePhoto}
              UserName={data?.UserName}
              BusinessTypeValue={data?.BusinessTypeValue}
              Requirement={data?.Requirement}
              RequestCount={data?.RequestCount}
              RequirementCategoryValue={data?.RequirementCategoryValue}
              City={data?.City}
              State={data?.State}
              onReportButtonClick={() => {
                setRequirementDetailsId(data?.RequirementDetailsId);
                dispatch(setReportModal(true));
              }}
              onConnectButtonClick={() => handleConnect(data?.OwnerId,data?.RequirementDetailsId)}
            isLiked = {data?.likeStatus}
            onLikeButtonClick = {async (value,event)=>{
              event.stopPropagation();

              try{
              await fetchHandler(
                  "/PostRequirementLike",
                  {},
                  {},
                 convertToFormData({
                   RequirementDetailsId: data.RequirementDetailsId,
                   likeStatus : value,
                   OwnerUserCredentialId : data?.OwnerId
                 }),
                 API_CONSTANTS.HTT_POST,
                 {isAuthRequired : true}
               )
              }
              catch(error){

              }

            }}
           />
          </>
        ))
      ) : (
        <Flex alignItems='center' justifyContent='center' height='50vh'>
          <Text>No Requirement Found</Text>
        </Flex>
      );

    return renderJSX;
  };

  return (
    <V5SidebarLayout>
        <WelcomePopUp
        iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/requirementPopup.svg`}
        isOpen={isWelcomePopupOpen}
        descriptionArray={[
          "View Requirements in the Industry",
          "List your Requirements and post them",
          "View Leads in “My Leads” Section",
        ]}
        location="Industry Requirements"
        onButtonClick={() => {
          setIsWelcomePopupOpen(false);
        }}
      />
      <QrPopUp />
      <FilterPopUp
        marketplaceType={MARKETPLACE_TYPES.INDUSTRY_REQUIREMENT}
        isModalOpen={isFilterOpen}
        onSubmitButtonClick={filterFunction}
        onCloseButtonClick={() => {
          dispatch(setFilterModalOpen(false));
        }}
      />
      {/* Post Button */}
      <Box position="absolute" right="20px" bottom="30px" zIndex="1" >
        <Button
          width={"3.5em"}
          height={"3.5em"}
          borderRadius="50%"
          background="linear-gradient(296.05deg, #F79C00 23.18%, #FED401 94.52%)"
          color="white"
          onClick={() => {
            dispatch(setInputModal(true));
          }}
          _hover={{
            background:
              "linear-gradient(296.05deg, #F79C00 23.18%, #FED401 94.52%)",
          }}
        >
          <AddIcon height={"1.5em"} w={"2em"}/>
        </Button>
      </Box>

      {/* Header */}
      <Flex direction="column" gap="1em">
        <CommonHeader
          header="Requirement in Industry"
          subHeader="See the Requirements Posted by E9 Users"
        />

        {/* Analytics */}
        <Flex>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            padding="1em 1em"
          >
            <Flex gap="1em">
              <Button
                leftIcon={
                  <img
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/analytics.svg`}
                  />
                }
                color="#002961"
                border="1px solid #002961"
                backgroundColor="white"
                onClick={() => {
                  toast({
                    status: "info",
                    description: "Comming Soon",
                    position: "top-right",
                    isClosable: true,
                  });
                }}
              >
                Analytics
              </Button>
              <Button
                leftIcon={
                  <img
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/requirement.svg`}
                  />
                }
                color="#002961"
                border="1px solid #002961"
                backgroundColor="white"
                onClick={() => navigate("/my-requirement")}
              >
                My Requirements
              </Button>
              <Button
                color="#F79C00"
                border="1px solid #F79C00"
                backgroundColor="white"
                onClick={() => navigate("/industry-requirement")}
              >
                Posts
              </Button>
            </Flex>

            <Button
              leftIcon={
                <img
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/filter.svg`}
                />
              }
              color="#002961"
              fontWeight="700"
              border="1px solid #002961"
              backgroundColor="white"
              onClick={() => {
                dispatch(setFilterModalOpen(true));
              }}
            >
              Filters
            </Button>
          </Box>
        </Flex>

        {/* Industry Cards */}

        <MarketplaceHoc
          marketplaceName="Industry Requirement"
          marketplaceType="industry"
          dataArray={likeMergedData ? likeMergedData : []}
          endOfData={endOfData}
          loadMoreAction={() => {
            filteredDataRequired
              ? dispatch(loadMoreFilterIndustryThunk())
              : dispatch(loadMoreIndustryThunk(paginationCount));
          }}
          initialLoading={initialLoading}
          paginationLoading={paginationLoading}
          cardsRenderFunction={renderCards}
        />
      </Flex>

      {/* Report */}
      <CustomInputModal
        isOpen={isReportPopUpopen}
        icon={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/report.svg`}
        hasCloseButton={true}
        onCloseButtonClick={() => {
          dispatch(setReportModal(false));
        }}
        heading={
          <Text color={"#D60000"} fontSize={"20px"} fontWeight={"700"}>
            Report Issue
          </Text>
        }
        subHeading={
          "Please provide details of any reports or issues you've encountered."
        }
        primaryActionText={"Report"}
        secondaryActionText={"No Thanks"}
        secondaryAction={() => {
          dispatch(setReportModal(false));
        }}
        formId="inputForm"
      >
        <Flex>
          <Formik
            initialValues={{ report: "" }}
            onSubmit={(values) => {
              handleReport(values);
            }}
            validationSchema={Yup.object({
              report: Yup.string().max(500, "Max 500 Characters Allowed"),
            })}
          >
            {({ values, isSubmitting, setFieldValue }) => (
              <Form
                id="inputForm"
                style={{
                  width: "100%",
                }}
              >
                <Flex justifyContent="space-between">
                  <FormLabel fontSize="14px" fontWeight="600">
                    Report an Issue or Provide Feedback{" "}
                  </FormLabel>
                  <Text
                    color="#4A5A82"
                    fontSize="12px"
                    fontWeight="600"
                    as="span"
                  >
                    {charCount} Char
                  </Text>
                </Flex>
                <FormikInput
                  type="text"
                  name="report"
                  textArea={true}
                  label=""
                  maxLength={500}
                  value={values.report}
                  onChangeAction={(e) => {
                    const inputValue = e.target.value ;
                    setFieldValue("report", e.target.value);
                    setCharCount(500 - inputValue.length);
                  }}
                />
              </Form>
            )}
          </Formik>
        </Flex>
      </CustomInputModal>

      {/* Add PYR */}
      <CustomGeneralModal
        isOpen={selectInputTypeModal}
        hasCloseButton={true}
        onCloseButtonClick={() => {
          dispatch(setInputModal(false));
        }}
        iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/input.svg`}
        heading={"Select Input Method"}
        primaryActionText={"Okay"}
        primaryButtonIsDisable={selectedInput === null || selectedInput === 2}
        primaryAction={() => {
          dispatch(setInputMethodId(selectedInput));
          dispatch(setInputModal(false));
          navigate("/requirement-category");
        }}
      >
        <Flex direction="column">
          {inputList?.length > 0 &&
            inputList.map((input) => (
              <SelectCardWrapper
                key={input?.InputMethodId}
                height="70px"
                width="100%"
                value={input?.InputValue ? input?.InputValue : ""}
                icon={imageSelector(input?.InputValue)}
                isSelected={selectedInput === input?.InputMethodId}
                onClick={() => {
                  if (input?.InputMethodId?.toString()?.toLowerCase() === "2") {
                    dispatch(openQrModal());
                    setSelectedInput(null);
                  } else {
                    setSelectedInput(input?.InputMethodId);
                  }
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                isActiveIcon={true}
              >
                <Flex
                  justifyContent="space-between"
                  paddingLeft="60px"
                  width="100%"
                >
                  <Text
                    fontSize={{ base: "lg", md: "xl" }}
                    fontWeight="semibold"
                    textAlign="center"
                  >
                    {input?.InputValue}
                  </Text>

                  {selectedInput === input?.InputMethodId && (
                    <div style={{ textAlign: "right" }}>
                      <ReactSVG
                        src={doubleTick}
                        beforeInjection={(svg) => {
                          svg.setAttribute(
                            "style",
                            "height: 20px; width: 18px"
                          );
                        }}
                      />
                    </div>
                  )}
                </Flex>
              </SelectCardWrapper>
            ))}
        </Flex>
      </CustomGeneralModal>

      {/* Connect PopUp */}
      <CustomGeneralModal
        isOpen={connectModal}
        hasCloseButton={true}
        onCloseButtonClick={() => {
          dispatch(setConnectModal(false));
        }}
        iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/connectRequirement.svg`}
        heading={"Connect"}
        subHeading={"Do you want to Connect with this User?"}
        primaryActionText={"Connect"}
        primaryAction={async () => {
          try {
          const data = await connectRequirementApi(connectBody);
          if(data?.Status === 201){
            toast({
              status: "success",
              description: "Request Sent for requirement",
              position: "top-right",
              isClosable: true,
            });
          }
          dispatch(setConnectModal(false));
          getConnectList();
          } catch (error) {
          dispatch(setConnectModal(false));
          }
        }}
        secondaryActionText={"No Thanks"}
        secondaryAction={() => {
          dispatch(setConnectModal(false));
        }}
      ></CustomGeneralModal>
    </V5SidebarLayout>
  );
};

export default IndustryRequirementListing;
