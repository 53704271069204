import { getSelectedAccountInLocalStorage } from "../localStorage/localStorage";

export const initialState = {
    isSuccessModalOpen: false,
    isContinueModalOpen: false,
    accountList: null,
    selectedAccount: getSelectedAccountInLocalStorage(),
    isLoading: false,
    isError: false,
    errorMessage: "",
}