import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllPremiumTypes } from "../../../services/premium/premium";

export const premiumTypeThunk = createAsyncThunk("/purchasePremium/premiumType", async() => {
    try{
    const data = await getAllPremiumTypes()
    console.log(data)
    return data;
}
catch(error){
    console.log(error)
}
})