import axios from "axios";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import { Box, Flex, Button, useToast } from "@chakra-ui/react";
import { MultiAdDivider } from "../../../../layout/multiAdLayout/MultiAdLayout";
import { FormikInput } from "../../../../components/Input/FormikInput";
import { FormikPhoneNumberInput } from "../../../../components/Input/PhoneNumberInput/FormikPhoneNumberInput";
import { getHelpFormSchemaValidation } from "./constants/form/schemaValidation";
import { getDefaultAppLanguage } from "../../../global/mtData/helpers/localStorage";
import { createSupportTicketUnregistred } from "../../../../services/getHelp/getHelp";
import { V5MultiAdLayout } from "../../../../layout/multiAdLayout/V5MultiAdLayout";

export const V5GetHelpPage = () => {
  const masterData = useSelector((state) => state.masterData);
  const appConfig = useSelector((state) => state.appConfig);
  const toast = useToast();
  const defaultLanguage = getDefaultAppLanguage();

  const SubmitButtonClickHandler = async (form) => {
    try {
      const data = await createSupportTicketUnregistred(form);

      if (data.APIResponseCode === 200) {
        toast({
          status: "success",
          position: "top-right",
          title: "Ticket raised successfully",
          isClosable: true,
        });
      } else {
        toast({
          status: "error",
          position: "top-right",
          title: "Support ticket could not be created. Please try again",
          isClosable: true,
        });
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <V5MultiAdLayout
      heading={defaultLanguage?.Get_Help}
      subHeading="We have a strategy for serving things right!"
      stepper={false}
    >
      {/* <Flex
        flexDirection="column"
        gap="1em"
        maxW="374px"
        margin="0 auto"
        maxH="350px"
        overflowY="scroll"
      > */}
        <Formik
          initialValues={{
            name: "",
            mobileNumber: "",
            email: "",
            subject: "",
            description: "",
            countryCode: appConfig.country
              ? appConfig.country.CountryIsdCode
              : "",
          }}
          validationSchema={getHelpFormSchemaValidation}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            await SubmitButtonClickHandler({
              SupportTicket: {
                Name: values.name,
                MobileNumber: values.mobileNumber,
                Email: values.email,
                Subject: values.subject,
                Description: values.description,
              },
            });
            resetForm();
            setSubmitting(false);
          }}
        >
          {({ values, handleSubmit }) => (
            <form onSubmit={handleSubmit} id="get-help-form">
               <Flex
                flexDirection="column"
                gap="1em"
                maxW="80%"
                m="0 auto"
                maxH="350px"
                overflowY="scroll"
                padding="0 0.5em"
              >
              <Flex flexDirection={["column", "column", "column", "row"]} justifyContent="space-between" gap="1em">
                <FormikInput
                  isRequired={true}
                  label="Name"
                  type="text"
                  name="name"
                  placeholder="Enter your full name here..."
                />

                <FormikPhoneNumberInput
                  isRequired={true}
                  label="Mobile Number"
                  type="text"
                  name="mobileNumber"
                  countrycodename="countryCode"
                  dropdownValues={
                    masterData.Countries ? masterData.Countries : []
                  }
                  value={values.countryCode}
                  placeholder="E.g. 9876543210"
                />
              </Flex>
              <Flex flexDirection={["column", "column", "column", "row"]} justifyContent="space-between" gap="1em">
                <FormikInput
                  isRequired={true}
                  label="Email"
                  type="text"
                  name="email"
                  placeholder="E.g. name_123@equip9.com"
                />

                <FormikInput
                  isRequired={true}
                  label="Subject"
                  type="text"
                  name="subject"
                  placeholder="Enter subject line here..."
                />
              </Flex>
              <Flex>
                <FormikInput
                  isRequired={true}
                  textArea={true}
                  label="Description"
                  type="text"
                  name="description"
                  placeholder="Write your query here..."
                />
              </Flex>
              </Flex>
            </form>
          )}
        </Formik>
      
      <Box>
        <Flex
          justifyContent="center"
          alignItems="center"
          bgColor="#fff"
          h="78px"
          style={{ boxShadow: "0px -10px 15px #2c2c2c0f" }}
        >
          <Button
            variant="gradient"
            type="submit"
            form="get-help-form"
            width="80%"
            margin="0 auto"
            maxW="347px"
          >
            {masterData?.defaultLanguage?.Continue}
          </Button>
        </Flex>
      </Box>
    </V5MultiAdLayout>
  );
};
