import { createAsyncThunk } from "@reduxjs/toolkit";
import { createOperatorJobSearchFilter } from "./createOperatorJobSearchFilter";
import { createOperatorJobDetails } from "../../../services/jobsPosted/jobsPosted";

export const createOperatorJobDetailsThunk = createAsyncThunk(
  "/opSearch/createOperatorJobDetails",
  async (_, { getState, dispatch }) => {
    const {
      equipmentAge,
      jobTitle,
      salaryType,
      providentFund,
      foodBenefits,
      accomodationProvided,
      inHandSalary,
      overTimePay,
      shiftType,
      workingHours,
      lunchHours,
      jobDuration,
      jobDurationType,
      workType,
      insurance,
      travelAllowance,
      payCycle,
    } = getState().operatorSearch;

    const { UserTypeValue, accountDetails } = getState().user;

    try {
      const body = {
        EquipmentAgeId: parseInt(equipmentAge),
        JobTitle: jobTitle,
        SalaryType: parseInt(salaryType),
        Salary: parseFloat(parseInt(inHandSalary)).toFixed(2),
        PFProvided: parseInt(providentFund),
        PayCycleId: parseInt(payCycle),
        FoodBenefitsId: parseInt(foodBenefits),
        AccomodationId: parseInt(accomodationProvided),
        NatureofWorkId: parseInt(workType),
        OvertimePerHourRate: overTimePay ? parseInt(overTimePay) : "",
        InsuranceTypeId: parseInt(insurance),
        TravelAllowance: parseInt(travelAllowance),
        WorkingShift: shiftType,
        WorkingsHour: parseInt(workingHours),
        LunchBreak: parseInt(lunchHours),
        JobDurationValue: parseInt(jobDuration) + " " +jobDurationType,
        // JobDurationValue: [parseInt(jobDuration), parseInt(jobDurationType)],
      };
      if(UserTypeValue === "Company"){
        body.TaxNumber = accountDetails.CompanyDetails.TaxNumber 
      }

      const data = await createOperatorJobDetails(body);

      if (
        data.Status === 201 &&
        Object.keys(data.Data).length > 0
      )
        try {
          await dispatch(
            createOperatorJobSearchFilter(
              data.Data.LastOperatorJobDetailId
            )
          ).unwrap();
        } catch {}
    } catch (error) {
    }
  }
);
