import React, { useState, useEffect } from "react";
import "./InviteMemberList.css";
import { tableMemberSubscription } from "../../../../../../../../../../staticData/staticData";
import {
  getCompanyTeamMembersList,
  updateTeamVerificationStatus,
  updateCompanyTeamMemberStatus
} from "../../../../../../../../../../ApiEquip9/CommonApi";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import { Skeleton } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { Popupconformation } from "../../../../../../../../../../sharedComponent/PopUp/ConfomationPopUp/ConformationUp";
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'

export const InvitedMemberList = () => {
  const userData = useSelector((state) => state.user);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showActive,setShowActive]=useState(false);
  const [currentMember, setCurrentMember] = useState(null);
  const [currentActive, setCurrentActive] = useState(null);
  const queryClient = useQueryClient();
  const toast = useToast();

  const handleCheckboxChange = (item) => {
    setCurrentMember(item);
    setShowConfirm(true);
  };

  const handleActiveCheckboxChange = (item) => {
    setCurrentActive(item);
    setShowActive(true)
  }


  const {
    data: getTeamMember,
    isLoading,
    isError,
  } = useQuery("getcompnayMemberlist", () => getCompanyTeamMembersList(userData.token));


  const getMember = getTeamMember?.data?.Data?.TeamMembersList;
  const updateVerifiactionStatus = async (payload) => {
    try {
      const result = await updateTeamVerificationStatus(
        userData.token,
        payload

      );
      if (result?.data?.Status === 204) {
        toast({
          title: "",
          description: `${result?.data?.Message?.SuccessMessage}`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        queryClient.invalidateQueries('getcompnayMemberlist');
      } else {
        toast({
          title: "Error while uploading",
          description: `${result?.data?.Message?.FailMessage}`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }

      return result;
    } catch (error) {

      toast({
        title: "Error while uploading",
        description: `${error?.data?.Message?.FailMessage}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      return null;
    }
  };

  const handleConfirm = () => {
const payLoad = {
      CompanyTeamMemberVerifyStatus: {
        TeamMemberUserCredentialId: currentMember?.UserCredentialId,
        ActiveStatus: currentMember.IsTeamMemeberVerified ? 0:1
      },
    };
    updateVerifiactionStatus(payLoad);
    setShowConfirm(false);
    setCurrentMember(null);
  };

  const handleCancel = () => {
    setShowConfirm(false);
    setCurrentMember(null);
  };

const updateActiveStatus=async(payload)=>{
    try{
        const response=await updateCompanyTeamMemberStatus(userData.token,payload);

        if(response?.data?.Status===204){
            toast({
                title: "",
                description: `${response?.data?.Message?.SuccessMessage}`,
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "top-right",
              }); 
              queryClient.invalidateQueries('getcompnayMemberlist');
        }
        else{
            toast({
                title: "Error while uploading",
                description: `${response?.data?.Message?.FailMessage}`,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
              });
        }
         return response;
    }
    catch(err){
        toast({
            title: "Error while uploading",
            description: `${err?.data?.Message?.FailMessage}`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });

          return null;
    }
    
}

  const handleActiveConfirm=()=>{

    const  payLoad={
        CompanyTeamMemberStatus: {
            TeamMemberUserCredentialId: currentActive?.UserCredentialId,          
            ActiveStatus:currentActive?.IsTeamMemberActive ?0:1   
        }
       }
       updateActiveStatus(payLoad);

    setShowActive(false);
    setCurrentActive(null);
  }

  const handleActiveCancel=()=>{
    setShowActive(false);
    setCurrentActive(null);
  }
  

  return (
    <>
      <div className="subcrption-table">
        <div className="service-heading">
          {tableMemberSubscription?.map((item, index) => (
            <div className="table-heading" key={index}>
              <p className="E9-text-member">{item.name}</p>
            </div>
          ))}
        </div>
      </div>
      <div>
        <div>
          <div>
            {isLoading ? (
              <>
                <div style={{ margin: "2% 3.5%", width:"85%" }}>
                  <Skeleton height="70px" /> <br /> <Skeleton height="70px" />{" "}
                  <br /> <Skeleton height="70px" /> <br />{" "}
                  <Skeleton height="70px" /> <br /> <Skeleton height="70px" />
                </div>
              </>
            ) : (
              <>
                {getMember?.map((item, index) => (
                  <div className="subcrption-table-company">
                    <p className="E9-text-companyDetail">{item.FirstName}</p>
                    <p className="E9-text-companyDetail">
                      {item.CompanyDesignationTypeName}
                    </p>
                    <p className="E9-text-companyDetail">
                      {item.TotalFreeRedeem}
                    </p>
                    <p className="E9-text-companyDetail">
                      {item.IsInvited === 1 ? (
                        <div>invited</div>
                      ) : (
                                <div className="checbox-style">
                            <Checkbox 
                             size='lg'
                              colorScheme="green"
                             isChecked={item.IsTeamMemeberVerified}
                             onChange={() => handleCheckboxChange(item)}  />

                             <Checkbox 
                            size='lg'
                              colorScheme="green"
                             isChecked={item.IsTeamMemberActive}
                             onChange={() => handleActiveCheckboxChange(item)}  />
                          </div>
                      )}
                    </p>
                  
                
                  </div>
                ))}
                 <Popupconformation
                  isOpen={showConfirm}
                  onClose={handleCancel}
                  onConfirm={handleConfirm}
                  message={`Do You want to ${currentMember?.IsTeamMemeberVerified === 0 ? "Verify" : "UnVerify"} this member ?`}
                />
                <Popupconformation
                isOpen={showActive}
                onClose={ handleActiveCancel}
                onConfirm={handleActiveConfirm}
                message={`Do You want to ${currentActive?.IsTeamMemberActive === 0 ? "Active" : "Inactive"} this member ?`}
                />
              </>
            )}

          </div>
        </div>
      </div>
    </>
  );
};
