import React, { useState } from "react";
import { withPopup } from "../../../../../../../../../../sharedComponent/PopUp/PopupHOC";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import * as Yup from "yup";
import "./InviteTeam.css";
import { useQuery, useQueryClient } from "react-query";
import {
  companyDesignation,
  createInviteTeam,
  getCompanyTeamMembersList,
} from "../../../../../../../../../../ApiEquip9/CommonApi";
import { Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react";

const InviteTeam = (props) => {
  const userData = useSelector((state) => state.user);
  const [selectedId, setSelectedId] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [fileType, setFileType] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [buttonText, setButtonText] = useState("Submit");

  const toast = useToast();
  const queryClient = useQueryClient();

  const isdCode =
    userData?.accountDetails?.CompanyOwnerDetails?.CredentialDetails?.IsdCode;
  const handleClose = () => {
    setIsOpen(false);
  };

  // Get Company Team Member

  const intialValues = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Please provide a first name"),
    lastName: Yup.string().required("Please Provide a last name"),
    mobileNumber: Yup.string()
      .required("Mobile number is required")
      .max(10, "Mobile number is not greater then 10")
      .matches(phoneRegExp, "Phone number is not valid"),
  });
  const copyToClipboard = (text) => {
    // Using the Clipboard API
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(function () {
        })
        .catch(function (err) {
          console.error("Unable to copy", err);
        });
    } else {
      // Fallback for older browsers
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("Unable to copy", err);
      }
      document.body.removeChild(textarea);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    const Payload = {
      FirstName: values.firstName,
      LastName: values.lastName,
      MobileNumber: values.mobileNumber,
      CompanyDesignationTypeId: selectedId,
      IsdCode: isdCode,
    };
    
    try {
      const sentInvite = await createInviteTeam(userData.token, Payload);
      if (sentInvite.data?.Status === 201) {
        toast({
          title: "",
          description: `Invitation link copied successfully. Please share with your team member(s) to get them onboarded on Equip9`,
          status: "success",
          duration: 15000,
          isClosable: true,
          position: "top-right",
        });
        queryClient.invalidateQueries("getcompnayMemberlist");
        setIsSubmitted(true);
        copyToClipboard("https://equip9.com/login/login-form");
        props.onClose();
      } else {
        toast({
          // title: "Error while uploading",
          description: `Mobile Number has already used`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
      // resetForm();
      return sentInvite;
    } catch (err) {
      toast({
        title: "Error while uploading",
        description: `${err?.data?.Message?.FailMessage}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return null;
    }
  };

  const handleSelect = (id) => {
    setSelectedId(id);
  };
  const {
    data: getCompany,
    isLoading,
    isError,
  } = useQuery("getCompanyDesignation", () =>
    companyDesignation(userData.token)
  );

  const getDesignationlist = getCompany?.data.Data.CompanyDesignationTypeList;



  return (
    <>
      <Formik
        initialValues={intialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <button className="popup-cardd-close" onClick={props.onClose}>
              <ReactSVG
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Cross.svg`}
              />
            </button>
            <h1 className="InviteTeam-hero">Invite Team</h1>
            <label htmlFor="inputField">
              Name<span className="asterisk">*</span>
            </label>
            <div></div>

            <div className="name-filed">
              <div>
                <Field
                  type="text"
                  name="firstName"
                  placeholder="      First Name"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                  style={{
                    backgroundColor: "#F9FAFB",
                    border: "1px solid #2C2C2C14",
                    width: "256px",
                    height: "32px",
                  }}
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="error-message"
                />
              </div>
              <div>
                <Field
                  type="text"
                  name="lastName"
                  placeholder="    Last Name"
                  style={{
                    backgroundColor: "#F9FAFB",
                    border: "1px solid #2C2C2C14",
                    width: "256px",
                    height: "32px",
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                />

                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="error-message"
                />
              </div>
              <div></div>
            </div>

            <div className="space"></div>
            <div className="mobile-number">
              <label htmlFor="inputField">
                Mobile Number <span className="asterisk">*</span>
              </label>
              <Field
                className="input-filed-mobile"
                type="text"
                name="mobileNumber"
                placeholder="  Mobile Number"
                style={{
                  backgroundColor: "#F9FAFB",
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") e.preventDefault();
                }}
              />
              <ErrorMessage
                name="mobileNumber"
                component="div"
                className="error-message"
              />
            </div>
            <div className="space"></div>
            <label htmlFor="inputField">
              Staff Type <span className="asterisk">*</span>
            </label>
            <div>
              {isLoading ? (
                <>
                  <Skeleton height="50px" />
                </>
              ) : (
                <>
                  <div className="companyDesignation">
                    {getDesignationlist?.map((item) => (
                      <div
                        key={item.CompanyDesignationTypeId}
                        onClick={() =>
                          handleSelect(item.CompanyDesignationTypeId)
                        }
                      >
                        <div className="button-property">
                          {item.CompanyDesignationTypeName}
                          {selectedId === item.CompanyDesignationTypeId && (
                            <ReactSVG
                              className="button-icon-style"
                              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconselected.svg`}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
            <div className="space"></div>
            <div className="design-invite">
              <div>
                <ReactSVG
                  className="icon-shift"
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Invite.svg`}
                />
              </div>
            </div>

            <div className="buttonspace">
              <button className="copy-link" disabled={isSubmitting}>
                <p className="copy-link-test">
                  {isSubmitted ? "Copy link" : "Submit"}
                </p>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default withPopup(InviteTeam);
