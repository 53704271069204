import { Button, Flex, Text, Box } from "@chakra-ui/react";
import { V5SidebarLayout } from "../../../layout/V5SidebarLayout/V5SidebarLayout";
import { MarketplaceHoc } from "../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { communityInitialPostThunk } from "../thunks/communityInitialPostThunk";
import { CommunityPost } from "./CommunityPost";
import { v4 as uuid } from "uuid";
import {
  PostCommunityLikes,
  postViewCommunityCount,
} from "../../../services/community/community";
import { communityLoadMorePostThunk } from "../thunks/communityLoadMorePostThunk";
import { AddIcon } from "@chakra-ui/icons";
import { ChooseContentTypePopup } from "../../addCommunityPost/screens/ChooseContentTypePopup";
import { ChooseAudiencePopup } from "../../addCommunityPost/screens/ChooseAudiencePopup";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { setContentType, setContentTypeModalOpen } from "../../addCommunityPost/slice/addCommunitySlice";

export const CommunityPage = () => {
  const dispatch = useDispatch();
  const navigate =useNavigate();
 
  const {
    data: initialData,
    endOfData,
    initialLoading,
    paginationLoading,
  } = useSelector((state) => state.community);

  useEffect(() => {
    dispatch(communityInitialPostThunk());
  }, []);

  const ViewPost = async (id) => {
    const res = await postViewCommunityCount(id);
    console.log("view post response",res);
   
  };
  const likePost = async (id) => {
    const res = await PostCommunityLikes(id);
    console.log("like post response",res);
   
  };

  const renderCards = (array) => {
    const renderJsx =
      array && array.length > 0 ? (
        array.map((post) => {
          return (
            <CommunityPost
              key={uuid()}
              Thumbnail={post.Thumbnail}
              ContentTitle={post.ContentTitle}
              ContentValue={post.ContentValue}
              LikesCount={post.LikesCount}
              ViewCount={post.ViewCount}
              CreatedDateTime={post.CreatedDateTime}
              IsLiked={post.IsLiked}
              IsPromoted={post.IsPromoted}
              IsE9Learning={post.IsE9Learning}
              Posted={post.Posted}
              CommunityVideoFileRef={post.CommunityVideoFileRef}
              handleViewPost={() => {
                ViewPost(post?.CommunityId);
                window.open(post?.ContentLink);
              }}
              handleLikeUnlikePost={() => {
                likePost(post?.CommunityId);
              }}
              handleViewCount={()=>{
                ViewPost(post?.CommunityId)
              }}
            />
          );
        })
      ) : (
        <p>No Community Posts</p>
      );
    return renderJsx;
  };

  return (
    <>
    <ChooseAudiencePopup/>
    <ChooseContentTypePopup/>
      <V5SidebarLayout>
        {/* floating add btn */}
        <Box position="absolute" right="80px" bottom="30px" zIndex={"1"}>
          <Button
            width={"3.5em"}
            height={"3.5em"}
            borderRadius="50%"
            background="linear-gradient(296.05deg, #F79C00 23.18%, #FED401 94.52%)"
            color="white"
            onClick={() => {
              dispatch(setContentType(null));
              dispatch(setContentTypeModalOpen(true));
            }}
          >
            <AddIcon height={"1.5em"} w={"2em"} />
          </Button>
        </Box>

        <Flex gap={"10px"} direction={"column"} margin={"0 52px"}>
          {/* header section */}
          <Flex direction={"column"}>
            <Text fontSize={"24px"} fontWeight={"700"} color={"#1A1E21"}>
              Community
            </Text>
            <Text fontSize={"20px"} fontWeight={"400"} color={"#767676"}>
              You can post all your content for the heavy equipment industry
            </Text>
          </Flex>

          {/* action buttons */}
          <Flex justifyContent={"space-between"}>
            <Flex gap={"10px"}>
            <Button
                variant="outline"
                color={"#F79C00"}
                border={"1px solid #F79C00"}
                onClick={() => {}}
                leftIcon={
                  <ReactSVG
                    alt="my_content"
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/community/communityGlobe.svg`}
                    style={{ filter: "brightness(0) saturate(100%) invert(53%) sepia(100%) saturate(1771%) hue-rotate(10deg) brightness(108%) contrast(102%)" }}
                  />
             
                }
              >
                Community
              </Button>
              <Button
                variant="outline"
                color={"#002961"}
                border={"1px solid #002961"}
                onClick={() => {
                navigate("/community/my-content");
                }}
                leftIcon={
                  <img
                    alt="my_content"
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/community/myContent.svg`}
                  />
                }
              >
                My Content
              </Button>
             
            </Flex>
            {/* <Button
              variant="outline"
              color={"#002961"}
              border={"1px solid #002961"}
              onClick={() => {}}
              leftIcon={
                <Text fontSize={"24px"} color={"#000000"}>
                  &#43;
                </Text>
              }
            >
              Add Yours
            </Button> */}
          </Flex>

          {/* displaying posts */}
          <Box marginTop={"20px"}>
            <MarketplaceHoc
              marketplaceType="Dealership Listing"
              dataArray={initialData || []}
              endOfData={endOfData}
              loadMoreAction={() => {
                dispatch(communityLoadMorePostThunk());
              }}
              initialLoading={initialLoading}
              paginationLoading={paginationLoading}
              cardsRenderFunction={renderCards}
            />
          </Box>
        </Flex>
      </V5SidebarLayout>
    </>
  );
};
