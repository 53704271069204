import React from "react";
import { Formik, Form } from "formik";
import { SideMenuLayout } from "../../layout/sideMenuLayout/SideMenuLayout";
import { DashboardNavBar } from "../../components/DashboardNavBar/DashboardNavBar";
import { MidSection } from "../../../sharedComponent/midSection/MidSection";
import { useSelector } from "react-redux";
import { hashToMd5 } from "../../helpers/dataConversion/convertToMd5";
import { useToast } from "@chakra-ui/react";
import * as Yup from "yup";
import { FormikPasswordInput } from "../../components/Input/FormikPasswordInput";
import { changePassword } from "../../services/settings/settings";
import { V5SidebarLayout } from "../../layout/V5SidebarLayout/V5SidebarLayout";

export const ChangePassword = () => {
  const toast = useToast();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const payload = JSON.stringify({
        PasswordData: {
          OldPassword: `${hashToMd5(values.oldPassword)}`,
          NewPassword: `${hashToMd5(values.newPassword)}`,
          ConfirmPassword: `${hashToMd5(values.reEnterPassword)}`,
        },
      });

      try {
        const data = await changePassword(payload);
        if (data?.APIResponseCode === 200) {
          toast({
            status: "success",
            description: `${data?.Message?.SuccessMessage}`,
            position: "top-right",
            isClosable: true,
            duration: 5000,
          });
        } else {
          toast({
            status: "error",
            description: `Plese Enter the correct Password`,
            position: "top-right",
            isClosable: true,
            duration: 5000,
          });
          // console.error("API error:", response?.data?.Message?.ErrorMessage);
        }
        resetForm();
        return data;
      } catch (error) {
        console.error("Error:", error);
        toast({
          status: "error",
          description: `Please Enter the correct Password`,

          position: "top-right",
          isClosable: true,
        });
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <V5SidebarLayout>
      <Formik
        initialValues={{
          oldPassword: "",
          newPassword: "",
          reEnterPassword: "",
        }}
        validationSchema={Yup.object({
          oldPassword: Yup.string()
            .required("Password Required")
            .min(6, "Minimum 6 characters required")
            .max(15, "Maximum 15 characters allowed"),
          newPassword: Yup.string()
            .required("New Password Required")
            .min(6, "Minimum 6 characters required")
            .max(15, "Maximum 15 characters allowed"),
          reEnterPassword: Yup.string()
            .required("Re-enter Password Required")
            .min(6, "Minimum 6 characters required")
            .max(15, "Maximum 15 characters allowed")
            .oneOf([Yup.ref("newPassword"), null], "Password must match"),
        })}
        onSubmit={handleSubmit}
      >
        <div className="settings-password-container">
          <div className="settings-password-heading">
            <h5 className="collapsed-div-header">Change Password</h5>
            <p
              style={{
                alignSelf: "flex-start",
                marginLeft: "2%",
                fontSize: "18px",
              }}
            >
              Change your current password from here
            </p>
          </div>
          <div className="settings-password-body">
            <Form className="collapsed-div">
              <FormikPasswordInput
                className="collapsed-div-field"
                type="password"
                name="oldPassword"
                placeholder="Old Password"
              ></FormikPasswordInput>
              <FormikPasswordInput
                className="collapsed-div-field"
                type="password"
                name="newPassword"
                placeholder="New Password"
              ></FormikPasswordInput>
              <FormikPasswordInput
                className="collapsed-div-field"
                type="password"
                name="reEnterPassword"
                placeholder="Re-enter Password"
              ></FormikPasswordInput>
              <button type="submit">Submit</button>
            </Form>
          </div>
        </div>
      </Formik>
    </V5SidebarLayout>
  );
};
