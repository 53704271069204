import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPremiumRedeemCountById } from "../../../services/premium/premium";

export const getMaterialListingPremiumLeadsThunk = createAsyncThunk("/premiumLeads/materialListing", async() => {
    try{
      const data = await getPremiumRedeemCountById(9);
      return data;
    }
    catch(error){
        throw error
    }
})