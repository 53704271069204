import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { getEquipmentMappingDetails } from "../../../services/equipments/equipments";
import { Field, Formik } from "formik";
import { Accordion, Flex, useToast } from "@chakra-ui/react";
import { AccordianElement } from "../../../components/AccordianElement/AccordianElement";
import { FormikInput } from "../../../components/Input/FormikInput";
import { registerNumbersAndDecimal} from "../../../../utils/form/form";
import { RadioParent } from "../../../components/RadioParent/RadioParent";
import { createAssetOtherPropertyDetailsThunk } from "../thunks/createAssetOtherPropertyDetailsThunk";
import { increaseStepperIndex, setIsLoading } from "../slice/equipmentListingSlice";
import { updateAssetOtherPropertyDetailsThunk } from "../thunks/updateAssetOtherPropertyDetails";

export const EquipmentOtherDetailsForm = ({ editMode = false }) => {
  const { equipmentType, otherEquipmentDetails } = useSelector(
    (state) => state.addEquipment
  );
  const [formDataRendering, setFormDataRending] = useState(null);
  const [allFileds, setFields] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [formValidationObject, setFormVaildationObject] = useState(null);
  const [metricsValidationObject, setMetricsValidationObject] = useState({});
  const toast = useToast();
  const dispatch = useDispatch();

  const getFormToRender = async (id) => {
    try {
      const data = await getEquipmentMappingDetails(id);
      // const allFields = data.map(({ fields }) => fields);
      const allFieldsWithSections = data.map(({ fields, name }) => {
        const field = fields.map((field) => ({ ...field, section: name }));
        return field;
      });
      // console.log(allFieldsWithSections.flat());
      // //console.log(allFields)
      setFields(allFieldsWithSections.flat());
      setFormDataRending(data);
    } catch (error) {
      
    }
  };

  const handleInitialValues = (fieldsArray) => {
    const values = fieldsArray.reduce((accumulator, currentValue, index) => {
      const key = `_${fieldsArray[index].label
        .toString()
        .toLowerCase()
        .split(" ")
        .join("_")}`;
      const keyMetric = `_${fieldsArray[index].label
        .toString()
        .toLowerCase()
        .split(" ")
        .join("_")}_metric`;
      if (
        currentValue.type === "Number" &&
        currentValue.metrics &&
        currentValue.metrics.length > 0
      ) {
        accumulator[keyMetric] = "";
      }
      accumulator[key] = "";
      return accumulator;
    }, {});
    setInitialValues(values);
    return values;
  };

  const handleValidaton = (initialValuesObject) => {
    const initialValuesArray = Object.keys(initialValuesObject);
    const validationObject = initialValuesArray.reduce(
      (accumulator, currentValue, index) => {
        if (
          currentValue.length > 8 &&
          currentValue
            .slice(currentValue.length - 6, currentValue.length)
            .toLowerCase() === "metric".toLowerCase()
        ) {
          accumulator.metricValues[Object.keys(initialValuesObject)[index]] =
            Yup.number();
        } else {
          accumulator.values[Object.keys(initialValuesObject)[index]] =
            Yup.string();
        }
        return accumulator;
      },
      { values: {}, metricValues: {} }
    );
    setFormVaildationObject(validationObject.values);
    setMetricsValidationObject(validationObject.metricValues);
  };
  const converLowerCaseToCapital = (string = "") => {
    if (!string) {
      return "";
    }
    const firstUpperLetter = String.fromCharCode(string.charCodeAt(0) - 32);
    return firstUpperLetter + string.substring(1, string.length);
  };
  useEffect(() => {
    if (equipmentType) {
      getFormToRender(parseInt(equipmentType));
    }
  }, [equipmentType]);
  useEffect(() => {
    if (allFileds) {
      handleInitialValues(allFileds);
    }
  }, [allFileds]);

  // Create post values for values with metric
  const createPostValuelabel = (key) => {
    const keyToCompare = key
      .split("_")
      .map((string) => string)
      .join(" ");
    const keyWithoutSpaces = keyToCompare.substring(1, keyToCompare.length);
    const requiredField = allFileds.find((field) => {
      return (
        field.label.toLowerCase() ===
        keyWithoutSpaces.substring(0, keyWithoutSpaces.length - 7)
      );
    });

    // //console.log(requiredField);
    if (requiredField) {
      return `${requiredField.section}_${requiredField.label}`;
    }
    return "";
  };

  const getFieldByName = (name) => {
    const keyToCompare = name
      .split("_")
      .map((string) => string)
      .join(" ");
    const keyWithoutSpaces = keyToCompare.substring(1, keyToCompare.length);
    const requiredField = allFileds.find((field) => {
      return field.label.toLowerCase() === keyWithoutSpaces.toLowerCase();
    });
    if (requiredField) {
      return requiredField;
    }
    return null;
  };

  const createPostLabelWithoutMetric = (key) => {
    const requiredField = getFieldByName(key);
    if (requiredField) {
      return `${requiredField.section}_${requiredField.label}`;
    }
    return "";
  };

  const getMetricValues = (key) => {
    const requiredField = getFieldByName(key);
    return requiredField.metrics;
  };

  const postOtherDetails = async (body) => {
    try {
      const data = await dispatch(
        createAssetOtherPropertyDetailsThunk(body)
      ).unwrap();
      toast({
        title: data.message,
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      dispatch(increaseStepperIndex());
    } catch (error) {
      toast({
        title: error.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  // create other details json for edit
  const convertOtherDetailsDataToOjects = (data) => {
    // console.log(data)
    //  console.log(Object.keys(data))
    const requiredDetails = Object.keys(data).map((element) => {
      const value = data[element].split("_")[0]
        ? data[element].split("_")[0]
        : "";
      const metricValue = data[element].split("_")[1]
        ? data[element].split("_")[1]
        : "";
      const section = element.split("_")[0] ? element.split("_")[0] : "";
      const label = element.split("_")[1] ? element.split("_")[1] : "";

      return { section, label, value, metricValue };
    });
    return requiredDetails;
  };

  // convert other available data to keyValue pairs
  const createOtherInitialValues = (allFields, createdData) => {
    const intialValues = allFields.reduce(
      (accumulator, currentValue, index) => {
        const key = `_${allFields[index].label
          .toString()
          .toLowerCase()
          .split(" ")
          .join("_")}`;
        const keyMetric = `_${allFields[index].label
          .toString()
          .toLowerCase()
          .split(" ")
          .join("_")}_metric`;
        const existingField = createdData.find(
          ({ section, label }) =>
            currentValue.section === section && currentValue.label === label
        );
        if (currentValue.type === "Number" && existingField) {
          const requiredMetric = currentValue.metrics.find(
            ({ value }) => value === existingField.metricValue
          );
          if (requiredMetric) {
            accumulator[keyMetric] = requiredMetric ? requiredMetric.id : "";
            accumulator[key] = existingField.value;
          }
        } else if (currentValue.type === "Number") {
          accumulator[keyMetric] = "";
          accumulator[key] = "";
        } else if (currentValue.type === "Selector" && existingField) {
          const requiredValue = currentValue.metrics.find(
            ({ value }) => value === existingField.value
          );
          accumulator[key] = requiredValue ? requiredValue.id : "";
        } else {
          accumulator[key] = "";
        }
        // console.log(accumulator)
        return accumulator;
      },
      {}
    );
    // console.log(initialValues)
    setInitialValues(intialValues)
    return initialValues
  };

  const updateOtherDetails = async (body) => {
    try {
      const data = await dispatch(
        updateAssetOtherPropertyDetailsThunk(body)
      ).unwrap();
      toast({
        title: data.message,
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      dispatch(increaseStepperIndex());
    } catch (error) {
      toast({
        title: error.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const postForm = async (body) => {
    try {
      if (otherEquipmentDetails) {
        await updateOtherDetails(body);
      } else {
        await postOtherDetails(body);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (initialValues) {
      handleValidaton(initialValues);
    }
  }, [initialValues]);

  useEffect(() => {
    if (otherEquipmentDetails && editMode && allFileds) {
      const othersData = convertOtherDetailsDataToOjects(otherEquipmentDetails);
      const initialValues = createOtherInitialValues(allFileds, othersData);

      // console.log(othersData);
      // console.log(allFileds);
    }
  }, [otherEquipmentDetails, allFileds]);

  return (
    <Formik
      initialValues={initialValues ? initialValues : {}}
      validationSchema={Yup.object().shape(
        formValidationObject && metricsValidationObject
          ? { ...formValidationObject, ...metricsValidationObject }
          : formValidationObject
          ? formValidationObject
          : {}
      )}
      onSubmit={async (values, { setSubmitting }) => {
        // debugger
        const invaildInputs = Object.keys(metricsValidationObject).map(
          (value) => {
            // if value is mentioned and metric is not mentioned.

            if (
              values[value.substring(0, value.length - 7)] &&
              !values[value]
            ) {
              return value;
            }
            // if metric is present but value is not present
            if (
              value.substring(value.length - 7, value.length) &&
              value.substring(value.length - 6, value.length) === "metric" &&
              values[value] &&
              !values[value.substring(0, value.length - 7)]
            ) {
              return value.substring(0, value.length - 7);
            }
          }
        );

        const requiredFieldsArray = invaildInputs.filter((element) => element);
        const errorObject = requiredFieldsArray.reduce(
          (accumulator, currentValue) => {
            accumulator[currentValue] = "This value is required.";
            return accumulator;
          },
          {}
        );

        const errorValuesTouched = requiredFieldsArray.reduce(
          (accumulator, currentValue) => {
            accumulator[currentValue] = true;
            return accumulator;
          },
          {}
        );
        // If there is error
        if (Object.keys(errorObject).length > 0) {
          const firstErrorKey = Object.keys(errorObject)[0];
          const errorName = firstErrorKey
            .split("_")
            .map((string) => converLowerCaseToCapital(string))
            .join(" ");
          let description = "";
          if (
            firstErrorKey.length > 7 &&
            firstErrorKey.substring(
              firstErrorKey.length - 6,
              firstErrorKey.length
            ) === "metric"
          ) {
            description = "If value is selected, it's metic is required";
          } else {
            description = "If metric is selected, it's value is required";
          }
          toast({
            title: `${errorName} is required`,
            description,
            isClosable: true,
            status: "error",
            position: "top-right",
          });
          setSubmitting(false);
        }
         else if (Object.keys(values).length > 0) {
          // Filter values without metric
          const valuesWithoutMetric = Object.keys(values).filter(
            (element) =>
              element.split("_")[element.split("_").length - 1] !== "metric"
          );

          const body = valuesWithoutMetric.reduce(
            (accumulator, currentValue) => {
              const metricId = values[`${currentValue}_metric`];
              const keyValue = createPostLabelWithoutMetric(currentValue);
              accumulator.push(keyValue);
              const metrics = getMetricValues(currentValue);

              if (!metricId) {
                const requiredValue = metrics.find(
                  ({ id }) => parseInt(id) === parseInt(values[currentValue])
                );

               requiredValue ? accumulator.push(requiredValue.value) : accumulator.push("");
              } else {
                const requiredValue = metrics.find(
                  ({ id }) => parseInt(id) === parseInt(metricId)
                );
                accumulator.push(
                  `${values[currentValue]}_${requiredValue.value}`
                );
              }

              return accumulator;
            },
            []
          );
          // CALL POST API HERE AND INCREASE STEPPER INDEX
          // console.log(body);x
          dispatch(setIsLoading(true));
          await postForm(body);
          dispatch(setIsLoading(false));
        } 
        else {
          dispatch(increaseStepperIndex());
        }
      }}
      enableReinitialize={editMode}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id="equipment-other-details">
          <Accordion allowMultiple>
            <Flex flexDirection="column" gap="1em">
              {formDataRendering && formDataRendering.length > 0 ? (
                formDataRendering.map((section) => (
                  <AccordianElement
                    key={section.name.toLowerCase().split(" ").join("")}
                    title={`${section.name}`}
                  >
                    <Flex
                      wrap="wrap"
                      rowGap="2em"
                      justifyContent="space-between"
                    >
                      {section.fields && section.fields.length > 0 ? (
                        section.fields.map((input) => {
                          return input.type.toLowerCase() ===
                            "Number".toLowerCase() &&
                            input.metrics &&
                            input.metrics.length > 0 ? (
                            <>
                              <FormikInput
                                key={`_${input.label
                                  .toLowerCase()
                                  .split(" ")
                                  .join("_")}`}
                                name={`_${input.label
                                  .toLowerCase()
                                  .split(" ")
                                  .join("_")}`}
                                onKeyDown={registerNumbersAndDecimal}
                                label={input.label}
                                placeholder={`Please enter ${input.label.toLowerCase()}...`}
                                maxWidth="450px"
                                maxLength={8}
                              />{" "}
                              <Field
                                name={`_${input.label
                                  .toLowerCase()
                                  .split(" ")
                                  .join("_")}_metric`}
                              >
                                {({ field }) => (
                                  <RadioParent
                                    label={`${input.label} Metric`}
                                    name={field.name}
                                    options={input.metrics}
                                    maxWidth="450px"
                                  />
                                )}
                              </Field>{" "}
                            </>
                          ) : input.type.toLowerCase() ===
                            "Selector".toLowerCase() ? (
                            <Field
                              name={`_${input.label
                                .toLowerCase()
                                .split(" ")
                                .join("_")}`}
                            >
                              {({ field }) => (
                                <RadioParent
                                  key={field.name}
                                  label={`${input.label}`}
                                  name={field.name}
                                  options={input.metrics}
                                  maxWidth="675px"
                                />
                              )}
                            </Field>
                          ) : (
                            <></>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </Flex>
                  </AccordianElement>
                ))
              ) : (
                <></>
              )}
            </Flex>
          </Accordion>
        </form>
      )}
    </Formik>
  );
};
