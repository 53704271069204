import { createSlice } from "@reduxjs/toolkit";
import { setSessionStorageItems } from "../../../helpers/sessionStorage/sessionStorage";
import { mpSearchInitialState } from "../initialState/mpSearchInitialState";

const maintenanceJobPostSlice = createSlice({
  name: "maintenanceJobPost",
  initialState: mpSearchInitialState,
  reducers: {
    setMpServiceCategory: (state, {payload}) => {
        state.serviceDetails = payload;
        setSessionStorageItems("MAINTENANCE_SEARCH_SERVICE_DETAILS", payload);
        },
    setJobType: (state, { payload }) => {
      state.jobType = payload;
      setSessionStorageItems("MAINTENANCE_SEARCH_JOB_TYPE", payload);
    },
    setMaintenanceType: (state, { payload }) => {
      state.maintenenaceType = payload;
      setSessionStorageItems("MAINTENANCE_SEARCH_MAINTENANCE_TYPE", payload);
    },
    setRepairType: (state, { payload }) => {
      state.repairType = payload;
      setSessionStorageItems("MAINTENANCE_SEARCH_REPAIR_TYPE", payload);
    },
    setEquipmentType: (state, { payload }) => {
      state.equipmentType = payload;
      setSessionStorageItems("MAINTENANCE_SEARCH_EQUIPMENT_TYPE", payload);
    },
    setEquipmentMake: (state, { payload }) => {
      state.equipmentMake = payload;
      setSessionStorageItems("MAINTENANCE_SEARCH_EQUIPMENT_MAKE", payload);
    },
    setEquipmentModel: (state, { payload }) => {
      state.equipmentModel = payload;
      setSessionStorageItems("MAINTENANCE_SEARCH_EQUIPMENT_MODEL", payload);
    },
    setEquipmentAge: (state, { payload }) => {
      state.equipmentAge = payload;
      setSessionStorageItems("MAINTENANCE_SEARCH_EQUIPMENT_AGE", payload);
    },
    setJobTitle: (state, { payload }) => {
      state.jobTitle = payload;
      setSessionStorageItems("MAINTENANCE_SEARCH_JOB_TITLE", payload);
    },
    setSalaryType: (state, { payload }) => {
      // state.salaryType = paylaod;
    },
    setStepperIndex: (state, { payload }) => {
      state.stepperIndex = payload;
      setSessionStorageItems("MAINTENANCE_SEARCH_STEPPER_INDEX", payload);
    },
    setEquipmentNickname: (state,{payload}) =>{
      state.equipmentNickname = payload;
      setSessionStorageItems("MAINTENANCE_SEARCH_EQ_NICKNAME",payload);
    },
    increaseStepperIndex: (state) => {
      if (state.stepperRoute.length <= state.stepperIndex) {
        return;
      }
      state.stepperIndex += 1;
      setSessionStorageItems(
        "MAINTENANCE_SEARCH_STEPPER_INDEX",
        state.stepperIndex
      );
    },
    decreaseStepperIndex: (state) => {
      if (state.stepperIndex === 0) {
        return;
      }
      if (state.stepperIndex < 0) {
        state.stepperIndex = 0;
      }
      state.stepperIndex -= 1;
      setSessionStorageItems(
        "MAINTENANCE_SEARCH_STEPPER_INDEX",
        state.stepperIndex
      );
    },
    resetMaintenanceSearchSlice: (state) => {
       setSessionStorageItems("MAINTENANCE_SEARCH_SERVICE_DETAILS", "");
       setSessionStorageItems("MAINTENANCE_SEARCH_JOB_TYPE", "");
       setSessionStorageItems("MAINTENANCE_SEARCH_MAINTENANCE_TYPE", "");
       setSessionStorageItems("MAINTENANCE_SEARCH_REPAIR_TYPE", "");
       setSessionStorageItems("MAINTENANCE_SEARCH_EQUIPMENT_TYPE", "");
       setSessionStorageItems("MAINTENANCE_SEARCH_EQUIPMENT_MAKE", "");
       setSessionStorageItems("MAINTENANCE_SEARCH_EQUIPMENT_MODEL", "");
       setSessionStorageItems("MAINTENANCE_SEARCH_EQUIPMENT_AGE", "");
       setSessionStorageItems("MAINTENANCE_SEARCH_JOB_TITLE", "");
       setSessionStorageItems("MAINTENANCE_SEARCH_SALARY_TYPE", "");
       setSessionStorageItems("MAINTENANCE_SEARCH_INHAND_SALARY", "");
       setSessionStorageItems("MAINTENANCE_SEARCH_OVERTIME_PAY", "");
       setSessionStorageItems("MAINTENANCE_SEARCH_FOOD_BENEFITS", "");
       setSessionStorageItems("MAINTENANCE_SEARCH_ACCOMODATION_PROVIDED", "");
       setSessionStorageItems("MAINTENANCE_SEARCH_STEPPER_INDEX", 0);
       setSessionStorageItems("MAINTENANCE_SEARCH_EQ_NICKNAME","");
       state.serviceDetails = null;
      state.jobType = null;
      state.maintenenaceType = null;
      state.repairType = null;
      state.equipmentType = null;
      state.equipmentMake = null;
      state.equipmentModel = null;
      state.equipmentAge = null;
      state.jobTitle = null;
      state.salaryType = null;
      state.inHandSalary = null;
      state.overtimePay = null;
      state.foodBenefits = null;
      state.accomodationProvided = null;
      state.stepperIndex = 0;
      state.equipmentNickname = "";
    },
    setIsLoading :(state, { payload })=>{
      state.isLoading=payload
    }
  },
});

export const {
  setJobType,
  setMaintenanceType,
  setEquipmentType,
  setRepairType,
  setEquipmentMake,
  setEquipmentModel,
  setEquipmentAge,
  setMpServiceCategory,
  setStepperIndex,
  increaseStepperIndex,
  decreaseStepperIndex,
  resetMaintenanceSearchSlice,
  setEquipmentNickname,
  setIsLoading
} = maintenanceJobPostSlice.actions;

export default maintenanceJobPostSlice.reducer;
