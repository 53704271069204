import { useEffect, useRef, useState } from "react";
import { MultiAdDivider } from "../../../../layout/multiAdLayout/MultiAdLayout";
import { useDispatch, useSelector } from "react-redux";
import { getAllE9RolesThunk } from "../../thunks/getAllE9RolesThunk";
import { selectE9RoleThunk } from "../../thunks/selectE9RolesThunk";
import { Flex, Heading, Text, Box, Button, useToast, Tooltip } from "@chakra-ui/react";
import { SelectCardWrapper } from "../../../../components/SelectCardWrapper/SelectCardWrapper";
import { convertToFormData } from "../../../../helpers/dataConversion/convertToFormData";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { API_CONSTANTS } from "../../../../../constants/apiConstants";
import { increaseRouteIndex } from "../../../global/user/slice/userSlice";
import { increasePageIndex } from "../../slice/docFlowSlice";
import { subString } from "../../../../helpers/dataConversion/substring";
import { createE9UserRole } from "../../../../services/documentFlow/documentFlow";
import { getDefaultAppLanguage } from "../../../global/mtData/helpers/localStorage";
import { V5MultiAdLayout } from "../../../../layout/multiAdLayout/V5MultiAdLayout";
import { V5Card } from "../../../../components/VrCard/V5Card";
import { V5Scroll } from "../../../../components/V5Scroll/V5Scroll";
import { NAVIGATION_CONSTANTS } from "../../../../../constants/naigationContants";

export const SelectE9Role = () => {
  const dispatch = useDispatch();
  const docFlowData = useSelector((state) => state.documentFlow);
  const userData = useSelector((state) => state.user);
  const toast = useToast();
  const navigate = useNavigate();
  const defaultLanguage = getDefaultAppLanguage();
  const cardRefs = useRef([]);
  const [urlParam, setUrlParam] = useSearchParams()
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const id = sessionStorage.getItem("DEFERRED_MARKETPLACE");
    const urlAssetId = urlParam.get(NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID)
    if(id && !urlAssetId){
     setUrlParam(`${NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID}=${id}`)
    }
   }, [])

  const buttonClickHandler = (e) => {
    let value = e.target.value;
    if (!value) {
      value = e.target.parentElement.value;
    }
    dispatch(selectE9RoleThunk(value));
  };

  const postE9UserRole = async () => {
    try {
      setLoading(true)
      const data = await createE9UserRole(
        convertToFormData({
          UserRoleTypeId: docFlowData.selectedE9Role.UserRoleTypeId,
        })
      );

      if (data.Status === 201) {
        toast({
          status: "success",
          title: data.Message.SuccessMessage,
          position: "top-right",
          isClosable: true,
        });
        dispatch(increasePageIndex());
        // dispatch(increaseRouteIndex())
      } else {
        toast({
          status: "error",
          title: data.Message.FailMessage,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        status: "error",
        title: "error.message",
        isClosable: true,
        position: "top-right",
      });
    }
    finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(getAllE9RolesThunk());
  }, []);

  const handleRefClick = (e, index) => {
    e.preventDefault(); 
    let value = cardRefs.current[index].value;
    if (!value) {
      value = e.target.parentElement.value;
    }

    dispatch(selectE9RoleThunk(value));
    e.stopPropagation();
  };

  return (
    <>
      <V5MultiAdLayout
        heading={
          userData.UserTypeValue.toLowerCase() === "Company".toLowerCase()
            ? `${defaultLanguage?.Please_select_your_expertise_area}`
            : `${defaultLanguage?.Please_select_your_Role}`
        }
        subHeading={defaultLanguage?.E9_Roles}
        stepper={true}
      >
        <V5Scroll maxHeight="350px" overflowX="unset">
          <Flex
            wrap="wrap"
            width="100%"
            padding="1.25em"
            justifyContent="space-evenly"
            alignItems="flex-end"
            rowGap="1em"
          >
            {docFlowData.allE9Roles ? (
              docFlowData.allE9Roles.map((role, index) => (
                // <SelectCardWrapper
                //   width="374px"
                //   height="80px"
                //   key={role.UserRoleTypeId}
                //   value={role.UserRoleTypeId}
                //   display="flex"
                //   flexdirection="column"
                //   onClick={buttonClickHandler}
                //   isSelected={
                //     docFlowData.selectedE9Role?.UserRoleTypeId ===
                //     role.UserRoleTypeId
                //   }
                // >
                <V5Card
                boxShadow="0px 0px 8px 0px #00000011"
                  value={role.UserRoleTypeId}
                  isSelected={
                    parseInt(docFlowData.selectedE9Role?.UserRoleTypeId) ===
                    parseInt(role.UserRoleTypeId)
                  }
                  ref={(ref) => (cardRefs.current[index] = ref)}
                >

                  <Flex
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-around"
                    maxHeight={"68px"}
                    height={"100%"}
                    minHeight={"68px"}
                    maxWidth={"290px"}
                    width="100%"
                    minWidth={"290px"}
                    padding="0.5em 0.75em"
                    onClick={(e) => handleRefClick(e, index)}
                  >
                    <Tooltip label={role.UserRoleTypeName}>
                    <Heading
                      width="fit-content"
                      fontSize="xl"
                      fontWeight="semibold"
                      onClick={(e) => handleRefClick(e, index)}
                    >
                      {subString(25, role.UserRoleTypeName)}
                    </Heading>
                      </Tooltip>
                    <Tooltip label={role?.Description}>
                    <Text
                      width="fit-content"
                      fontSize="xs"
                      color="#2c2c2c80"
                      onClick={(e) => handleRefClick(e, index)}
                    >
                      {role.Description
                        ? subString(30, role.Description)
                        : "N.A."}
                    </Text>
                    </Tooltip>
                  </Flex>
                </V5Card>
              ))
            ) : (
              <Text>There is some error please try again</Text>
            )}
          </Flex>
        </V5Scroll>
          <Box>
          <Flex
          justifyContent="center"
          alignItems="center"
          bgColor="#fff"
          h="78px"
          style={{ boxShadow: "0px -10px 15px #2c2c2c0f" }}
          >
        <Button
            variant="gradient"
            fontWeight="semibold"
            width="80%"
            margin="0 auto"
            maxW="347px"
          form="registration-form"
          isDisabled={!docFlowData.selectedE9Role}
          onClick={postE9UserRole}
          isLoading={loading}
        >
          <p style={{ fontSize: "22px" }}>{defaultLanguage?.Next}</p>
        </Button>
        </Flex>
        </Box>
      </V5MultiAdLayout>
    </>
  );
};
