import { useRadio } from "@chakra-ui/radio";
import { Box } from "@chakra-ui/react";
import { useRef } from "react";

export const RadioCard = ({
  width = "unset",
  borderRightRequired = true,
  children,
  ischecked="",
  onChange = () => {},
  ...props
}) => {
  const { getRadioProps, getInputProps } = useRadio(props);
  const input = getInputProps();
  const radio = getRadioProps();
  const inputRef = useRef()

  const handleClickFocus = () => {
   inputRef.current.click()
}
  return (
    <Box
      as="label"
      cursor="pointer"
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRight={borderRightRequired ? "2px dashed #E2E8F080" : ""}
      flex={width}
      onClick={handleClickFocus}
    >
      <input {...input} style={{ width: 0, height: 0 }} />
      <Box
        {...radio}
        ischecked={ischecked.toString()}
        color= {ischecked ? "#2c2c2c" : "#2c2c2c90"}
        fontWeight={ischecked ? 600 : "unset"}
        // _checked={{ color: "#2c2c2c", fontWeight: 600 }}
        onClick={onChange}
        ref={inputRef}
      >
        {children}
      </Box>
    </Box>
  );
};
