import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { GetValueFromConvertedDateTime } from "../helper/helper";
import Equip9NameIcon from "../../../../assets/webapp/quiz/transparent-background-equip9-black-logo.png";
import { QuizStatusTag } from "./QuizStatusTag";
import { ChevronRightIcon } from "@chakra-ui/icons";

export const QuizCard = ({
  name,
  status,
  SponsoredBy,
  QuizPlayedUsersCount,
  startDate,
  expiredOnDate,
  promotedBy,
  imageBaseUrl,
  QuizImageReference,
  IsQuizPlayedByUser,
  PointsScoredByUser,
  TotalQuestPoints,
  onCardClick=()=>{}
}) => {
  return (
    <Flex
      height={"100%"}
      width={"100%"}
      maxW={"550px"}
      boxShadow={"0px 4px 6px -1px #0000001A"}
      border={"1px solid #F5F5F5"}
      borderRadius={"5px"}
      direction={"column"}
      justifyContent={"space-between"}
    >
      <Flex direction={"column"} paddingLeft={"10px"} gap={"5px"}>
        <Flex padding={"10px 8px"} justifyContent={"space-between"}>
          <Text fontSize={"14px"} color={"#767676"}>
            Sponsored :{SponsoredBy}
          </Text>
         {status && <QuizStatusTag status={status} />} 
        </Flex>
        <Flex gap={"10px"} alignItems={"center"}>
          <Box height={"100px"} w={"130px"}>
            <img
              alt="quiz thumbnail"
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "5px",
                objectFit: "fill",
              }}
              src={`${process.env.REACT_APP_CDN_PVT_URL}${imageBaseUrl}${QuizImageReference}`}
            />
          </Box>
          <Flex direction={"column"} gap={"2px"}>
            <Text fontSize={"18px"} fontWeight={"700"} color={"#1A1E21"}>
              {" "}
              {name}
            </Text>
            <Text fontSize={"14px"} fontWeight={"700"} color={"#002961"}>
              {" "}
              Played By : {QuizPlayedUsersCount}
            </Text>
            <Text fontSize={"14px"}>
              {" "}
              Starting On :{GetValueFromConvertedDateTime(startDate, "DATE")}
            </Text>
            <Text fontSize={"14px"}>
              Ending On :{GetValueFromConvertedDateTime(expiredOnDate, "DATE")}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        w={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"8px 15px "}
        backgroundColor={"#FFFBF5"}
      >
        <Flex alignItems={"center"} gap={"5px"}>
          <Text fontSize={"12px"} fontWeight={"700"} color={"#F79C00"}>
            POWERED BY{" "}
          </Text>
          <img
            src={Equip9NameIcon}
            alt="Equip9"
            style={{
              height: "10px",
              width: "50px",
            }}
          />
        </Flex>
        
        {status?.toLowerCase() === "active" || status?.toLowerCase() === "coming soon" ? (
          IsQuizPlayedByUser?.toLowerCase() === "yes" ? (
            <Flex fontSize={"16px"} fontWeight={"700"} color={"#F79C00"} onClick={onCardClick} cursor={"pointer"} minH={"40px"} justifyContent={"center"} alignItems={"center"}>
              Score {PointsScoredByUser}/{TotalQuestPoints}
            </Flex>
          ) : (
            <Button variant={"gradient"} onClick={onCardClick}>
            Play Now
          </Button>
          )
        ) : (
         
          <button
          onClick={onCardClick}
        >
          <Flex 
          alignItems={"center"} 
          padding={"4px 8px"}
          borderRadius={"4px"}
          border={"1px solid"}
          borderColor={`${QuizPlayedUsersCount === 0 ? "#959596" : "#002961" }`}  
          fontSize={"14px"}
          fontWeight={"700"}
          color={`${QuizPlayedUsersCount === 0 ? "#959596" : "#002961" }`}
          >
           View Top Winners
          <ChevronRightIcon h={"20px"} w={"20px"}/>
          </Flex>
        </button>
        )}
      </Flex>
    </Flex>
  );
};
