import React,{useState,useEffect} from "react";
import { ReactSVG } from "react-svg";
import { localimageDetails as staticImageDetails } from "../../../../../../../staticData/staticData";
export const ShowPhotos = ({photoUploaded,setPhotoUploaded}) => {

    const [localimageDetails, setLocalImageDetails] = useState(staticImageDetails);

  const handleDelete = (id) => {
    const updatedPhotos = localimageDetails.map(item => {
        if (item.id === id) {
          return { ...item, src: '' };
        }
        return item;
      });
      // console.log(`Removing photoType_${id} from localStorage`);
      localStorage.removeItem(`photoType_${id}`);
      setLocalImageDetails(updatedPhotos)
  };


  useEffect(() => {
    const updatedPhotos = localimageDetails.map(item => {
        const storedPhoto = localStorage.getItem(`photoType_${item.id}`);
        return { ...item, src: storedPhoto || '' };
    });
    setLocalImageDetails(updatedPhotos);
}, []);

useEffect(() => {
    if (photoUploaded) {
        const updatedPhotos = localimageDetails.map(item => {
            const storedPhoto = localStorage.getItem(`photoType_${item.id}`);
            return { ...item, src: storedPhoto || '' };
        });
        setLocalImageDetails(updatedPhotos);
        setPhotoUploaded(false); 
    }
}, [photoUploaded, setPhotoUploaded, localimageDetails]);

  return (
    <div
      style={{
        marginTop: "22px",
        display: "flex",
        flexDirection: "row",
        gap: "32px",
      }}
    >
      {localimageDetails?.map((item, index) => (
        <div className="store-preview-image" key={index}>
          <div className="image-tag">
            <p className="front-text">{item.name}</p>
          </div>
          <img  style={{border:"1px solid rgba(74, 74, 74, 0.41)"}}  alt={item.alt} className="image-preview" src={item.src || `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/placeHolderimage.png`}
           />
          <div className="image-tag-delete" onClick={()=>handleDelete(item?.id)}>
            <ReactSVG
              style={{
                cursor: "pointer",
                marginLeft: "75px",
                marginTop: "5px",
              }}
              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/garbage.svg`}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
