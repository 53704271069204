import { API_CONSTANTS } from "../../../constants/apiConstants";
import {fetchHandler} from "../../../index"

export const getMtMyBusinessTypeList = async () => {
    try {
    const {data}= await fetchHandler(API_CONSTANTS.GET_MT_MYBUSINESS_TYPE_LIST);
    return data;

    } catch (error) {
      
    }
  }
  