export const primaryKeyEncryption = (primaryKey) => {
const keyArray = primaryKey.toString().split("");
const result = [];
const valueAddition = 97;
for(let i = 0; i < keyArray.length; i++){
   
    result.push(String.fromCharCode(parseInt(keyArray[i]) + valueAddition));
}
return result.join("")
}

export const primaryKeyDecryption = (encryptedMessage) => {
const encryptedArray = encryptedMessage.toString().split("");
const result = [];
const valueDeletion = 97;
for(let i = 0; i < encryptedArray.length; i++){
    result.push(encryptedArray[i].charCodeAt(0) - valueDeletion);
}
return result.join("");
}