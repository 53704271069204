import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFilteredPartners } from "../../../services/jobsPosted/jobsPosted";


export const getRemLoadMoreFilteredDataThunk = createAsyncThunk("/rem/loadMoreFilteredData", async(_, {getState}) => {
    const selectedCountry = getState().rentalEquipmentMarketplace.filterByCountry;
    const selectedState = getState().rentalEquipmentMarketplace.filterByState;
    const selectedVerificationStatus = getState().rentalEquipmentMarketplace.filterByVerificationStatus;
    const selectedEquipmentType = getState().rentalEquipmentMarketplace.filterByEquipmentType;
    const cardCount  = getState().rentalEquipmentMarketplace.cardCount;
    const paginitionCount = getState().rentalEquipmentMarketplace.paginitionCount;

    let query = ""

    if(selectedCountry){
        query += `&FilterByLocationCountry=${selectedCountry}`
    }
    if(selectedState){
        query+= `&FilterByLocationState=${selectedState}`
    }
    if(selectedVerificationStatus){
        query += `&FilterByVerificationStatus=${selectedState}`
    }
    if(selectedEquipmentType){
        query+= `&FilterByEquipmentType=${selectedEquipmentType}`
    }

    try{
    
        const data = await getFilteredPartners(cardCount,(paginitionCount * cardCount),`?FilterType=Rental Equipment${query}`);

        if(data.Status === 200 && Object.keys(data.Data) > 0){
            if(data.Data.TotalRecords > 0){
                return data.Data.RentalEquipmentList
            }
            else {
                return []
            }
        }
        else {
            return []
        }
    }
    catch(error){
    }
})