import { createAsyncThunk } from "@reduxjs/toolkit";
import { selectDetailsUrl } from "../helpers/selectSaveDetailsApi/selectSaveDetailApi";
import { convertToFormData } from "../../../helpers/dataConversion/convertToFormData";
import { saveRegisterDetails } from "../../../services/registration/registration";

export const saveDetailsThunk = createAsyncThunk("saveDetails", async(userDetailsBody, {dispatch, rejectWithValue, getState}) => {
      
    const url = selectDetailsUrl(getState().registration.selectedBusinessType.BusinessTypeValue.toLowerCase())
    try{
        const data = await saveRegisterDetails(url,convertToFormData(userDetailsBody))       
        if(data.Status){
           return {data: data.Data, message: data.Message.SuccessMessage}
        }
        else{
          return rejectWithValue(data.Message.SuccessMessage)
        }

    }
    catch(error){
      return rejectWithValue(error.message)
    }

})