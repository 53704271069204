import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { CustomModal } from "../CustomModal/CustomModal";
import { ArrowBackIcon } from "@chakra-ui/icons";

export const CustomInputModal = ({
  isOpen = false,
  hasCloseButton = false,
  icon = null,
  buttonAlignment = null,
  onCloseButtonClick = () => {},
  primaryActionText,
  isLoading,
  heading,
  subHeading,
  isArrow = false,
  secondaryAction = () => {},
  secondaryActionText,
  formId = "inputForm",
  children,
  ...rest
}) => {
  return (
    <CustomModal
      isOpen={isOpen}
      hasCloseButton={hasCloseButton}
      onCloseButtonClick={onCloseButtonClick}
    >
      <CustomModal.Header textAlign="center">
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
          gap={"16px"}
        >
          {icon && (
            <Box>
              <img
                style={{
                  maxHeight: "50px",
                  maxWidth: "50px",
                  height: "50px",
                  width: "50px",
                }}
                src={icon}
              />
            </Box>
          )}

          <Flex direction={"column"}>
            {heading && (
              <Text
                textAlign="center"
                fontWeight={700}
                fontSize="20px"
                color={"#1A1E21"}
              >
                {heading}
              </Text>
            )}
            {subHeading && (
              <Text
                textAlign="center"
                fontWeight={400}
                fontSize="16px"
                color={"#959596"}
              >
                {subHeading}
              </Text>
            )}
          </Flex>
        </Box>
      </CustomModal.Header>
      <CustomModal.Body>{children}</CustomModal.Body>
      <CustomModal.Footer boxShadow={"0px -10px 10px 0px #2C2C2C0F"}>
        <Flex
          justifyContent={
            secondaryActionText
              ? "space-between"
              : buttonAlignment
              ? buttonAlignment
              : "center"
          }
          width="100%"
        >
          {secondaryActionText && (
            <Button
              outline="none"
              boxShadow="none"
              background="#fff"
              color="#2c2c2c90"
              onClick={secondaryAction}
            >
              <Flex gap={"15px"}>
                {isArrow && (
                  <Box>
                    {" "}
                    <ArrowBackIcon />
                  </Box>
                )}
                <Text>{secondaryActionText}</Text>
              </Flex>
            </Button>
          )}
          <Button type="submit" form={formId} variant="gradient">
            {isLoading ? <Spinner /> : primaryActionText}
          </Button>
        </Flex>
      </CustomModal.Footer>
    </CustomModal>
  );
};
