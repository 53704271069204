import React from "react";
import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  ModalFooter,
} from "@chakra-ui/react";
import SuccessImage from "../../../../../assets/webapp/subscription/success-desktop.png";
import { CustomInfoModal } from "../../../../components/CustomInfoModal/CustomInfoModal";

const OffersApplied = ({
  isOpen,
  currency,
  amount,
  onOkayButtonClick = () => {},
}) => {
  return (
    // <Modal isOpen={isOpen} isCentered>
    //   <ModalOverlay />
    //   <ModalContent>
    //     <ModalBody>
    //       <Flex
    //         alignItems="center"
    //         justifyContent="center"
    //         gap="1em"
    //         direction="column"
    //         minH="200px"
    //       >
    //         <img
    //           style={{ height: "55px", width: "120px" }}
    //           src={SuccessImage}
    //           alt="payment-successful"
    //         />
    //         <Heading
    //           as="h1"
    //           fontSize="34px"
    //           lineHeight="40px"
    //           fontWeight="700"
    //           color="#2c2c2c"
    //         >
    //           {currency}
    //           {amount}
    //         </Heading>
    //         <Flex
    //           direction="column"
    //           gap="0.5em"
    //           alignItems="center"
    //           justifyContent="center"
    //         >
    //           <Text as="p" fontSize="18px" color="#2c2c2cCC">
    //             Congratulations Promo discount of {currency}
    //             {amount} applied successfully
    //           </Text>
    //         </Flex>
    //       </Flex>
    //     </ModalBody>
    //     <ModalFooter
    //       justifyContent="center"
    //       alignItems="center"
    //       boxShadow="0px -10px 15px #2C2C2C0F"
    //     >
    //       <Button variant="gradient" width="80%" onClick={onOkayButtonClick}>
    //         Okay
    //       </Button>
    //     </ModalFooter>
    //   </ModalContent>
    // </Modal>
    <CustomInfoModal
      isOpen={isOpen}
      hasCloseButton={true}
      onCloseButtonClick={onOkayButtonClick}
      primaryActionText={"Okay"}
      primaryAction={onOkayButtonClick}
      iconUrl={SuccessImage}
      heading={`${currency}${amount}`}
      subHeading={`Congratulations Promo discount of ${currency}
              ${amount} applied successfully`}
    />
  );
};

export default OffersApplied;
