import { Flex, Text } from "@chakra-ui/react";
import "./Aboutmaincss.css";

import "./Aboutmaincss.css";

export const AboutMain = () => {
  return (
    <div
      style={{
        background: "#212529",
        paddingBottom: "6rem",
      }}
    >
      <div
        style={{
          margin: "auto",
          textAlign: "center",
          width: "70%",
          maxWidth:"350px",
          minWidth:"224px"
        }}
      >
        <div className="headline">
          <Text
          fontSize={["1.8em","1.8em","1.8em","2.8em","2.8em","2.8em"]}
          >What We Do</Text>
          </div>
        <Text 
        fontSize={["0.8em","0.8em","0.8em","1em","1em","1em"]}
        style={{ color: "#FCFCFB" }}
        >
          EQUIP9 <sup>TM</sup> &nbsp; delivers  more to heavy equipment business so that customers
          just don't do business, they do optimized and futuristic business.
        </Text>
      </div>

      <div className="flx">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            rowGap: "30px",
          }}
        ></div>

        <Flex gap={"3%"} alignItems={"center"} 
        padding={["2rem 1em","2rem 1rem","2rem 4rem","2rem 6rem","2rem 6rem","2rem 8rem"]}
        direction={["column","column","column","column","row","row"]}
        >
          <div
            style={{
              // background: "var(--dark-40)",
              background: "black",
              color: "black",
              // width: "504.76px",
              // height: "398.45px",
            }}
          >
            <iframe
              className="video-frame"
              src="https://www.youtube.com/embed/g37zmCLtPzo"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen={true}
            ></iframe>
          </div>
          <div className="hiden">
            <ul>
              <li>
                Our mission is to provide a 360-degree connection for all
                participants in the heavy equipment industry. We envision being
                a one-stop shop for everything related to heavy equipment, from
                buying and selling to renting and financing. By bringing the
                industry together on one platform, we aim to reduce the barriers
                to entry, increase efficiency, and facilitate growth for all
                stakeholders
              </li>
            </ul>
          </div>
        </Flex>
      </div>
    </div>
  );
};
