import { createAsyncThunk } from "@reduxjs/toolkit";
import { getQuizzes } from "../../../services/quiz/quiz";

export const quizInitialListThunk = createAsyncThunk(
  "quiz/getInitialCards",
  async (_, {getState}) => {
    const cardCount = getState().quiz.cardCount

    try {
      const data = await getQuizzes(cardCount,0);
      return data;
    } catch (error) {
      throw error;
    }
  }
);
