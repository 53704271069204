import styled from "styled-components";

export const StyledCard = styled.div`
  background-color: #fff;
  border: ${({ type }) =>
    type === "jobCard" ||
    type === "partnerCard" ||
    type === "nearMeJobCard" ||
    type === "materialCard"
      ? "1px solid #2c2c2c1a"
      : ""};
  border-radius : ${({ type }) =>
    type === "jobCard"
      ? "5px"
      : "8px"};
  
  height: ${({ type }) =>
    type === "equipmentCard"
      ? "300px"
      : type === "equipmentMarketplaceCard"
      ? "400px"
      : type === "jobCard"
      ? ""
      : type === "partnerCard"
      ? "300px"
      : type === "nearMePartnerCard"
      ? "190px"
      : type === "nearMeJobCard"
      ? "115px"
      : type === "materialCard"
      ? "330px"
      : type === "jobPostedCard"
      ? "224px"
      : "auto"};

  width: ${({ type }) =>
    type === "equipmentCard"
      ? "100%"
      : type === "equipmentMarketplaceCard"
      ? "100%"
      : type === "jobCard"
      ? "380px"
      : type === "partnerCard"
      ? "100%"
      : type === "nearMePartnerCard"
      ? "562.5px"
      : type === "nearMeJobCard"
      ? "345px"
      : type === "materialCard"
      ? "350px"
      : type === "jobPostedCard"
      ? "350px"
      : "auto"};
  min-width: ${({ type }) =>
    type === "equipmentCard"
      ? "275px"
      : type === "equipmentMarketplaceCard"
      ? "386px"
      : type === "jobCard"
      ? "380px"
      : type === "partnerCard"
      ? "211px"
      : type === "nearMePartnerCard"
      ? "562.5px"
      : type === "nearMeJobCard"
      ? "345px"
      : "auto"};
  max-width: ${({ type }) =>
    type === "equipmentCard"
      ? "275px"
      : type === "equipmentMarketplaceCard"
      ? "400px"
      : type === "jobCard"
      ? "462px"
      : type === "partnerCard"
      ? "211px"
      : type === "nearMePartnerCard"
      ? "562.5px"
      : type === "nearMeJobCard"
      ? "345px"
      : "auto"};
      
   box-shadow: ${({ type }) =>
     type === "jobCard"
      ? "0px 0px 4px 0px #00000040"
      : "0px 2px 4px 1px #959596"
      };

  cursor: ${({ type }) => (type === "jobCard" || type === "jobPostedCard" ? "pointer" : "")};
`;
