import { Flex, Text } from "@chakra-ui/react"
import "./CardVerificationBadge.css"


export const VerificationBadge = ({type = "", text = "Verification Text"}) => {
    return(
        // <div className={`verification-badge ${type === "success" ? "verification-badge__success" : type === "fail" ? "verification-badge__failture" : ""}`}>
        //     <p className="verification-badge__text">{text}</p>
        // </div>
        <Flex
        borderRadius={'1.25em'}
        textAlign={"center"}
        border={type === 1 ? '1px solid #F79C00' : 'none'}
        backgroundColor={type === 3 ? "#D6000026" : type === 4 ? "#FFE57059": ""}
        padding={"6px 10px"}
        fontSize={"12px"}
        fontWeight={"700"}
        color={type === 3 ? "#EE6123":type === 4 ? "#CCA700" : "#F79C00"}
        >
            <Text>{text}</Text>
        </Flex>
    )
}