import {
  Grid,
  GridItem,
  Heading,
  Text,
  Flex,
  Box,
  Button,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { RouteStepper } from "../../components/Stepper/Stepper";
// import {
//   decreaseRouteIndex,
//   increaseRouteIndex,
// } from "../../features/global/user/slice/userSlice";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import {
  increasePageIndex,
  decreasePageIndex,
} from "../../features/documentFlow/slice/docFlowSlice";
import { linkData, caroslData2, caroslData3 } from "./StaticCaroseldata";
import { getDefaultAppLanguage } from "../../features/global/mtData/helpers/localStorage";
import V5AdvertisementCarousel from "../../../website/Layout/V5AdvertisementCarousel";
import { V5Navbar } from "../../components/V5Navbar/V5Navbar";

export const V5MultiAdLayout = ({
  children,
  heading,
  subHeading,
  justifyContent = "space-between",
  stepper = false,
  isDisabled = false,
  buttonText = "",
  hasFooter = false,
  onSubmitClick = () => {},
  gap="",
  SlideData = [],
}) => {
  // const userData = useSelector((state) => state.user);
  const documentFlow = useSelector((state) => state.documentFlow);
  const location = useLocation();
  const navigate = useNavigate();
  const disptach = useDispatch();
  const defaultLanguage = getDefaultAppLanguage();

  // Use effect for login page journey to doc flow or dashboard if user is logged in
  useEffect(() => {
    if (documentFlow.documentFlowArray) {
      if (
        location.pathname.split("/")[1] === "login" &&
        documentFlow.documentFlowArray.length > 0 &&
        documentFlow.documentPageIndex < documentFlow.documentFlowArray.length
      ) {
        return navigate(
          documentFlow.documentFlowArray[documentFlow.documentPageIndex].route
        );
      } else {
        return navigate("/dashboard");
      }
    }
  }, [documentFlow.documentFlowArray]);

  // Use effect for transitioning to differentt doc flow pages
  useEffect(() => {
    if (location.pathname.split("/")[1] === "document-flow") {
      if (
        documentFlow.documentFlowArray &&
        documentFlow.documentFlowArray.length !== 0 &&
        parseInt(documentFlow.documentPageIndex) <
          documentFlow.documentFlowArray.length
      ) {
        return navigate(
          documentFlow.documentFlowArray[documentFlow.documentPageIndex].route
        );
      } else {
        return navigate("/dashboard");
      }
    }
  }, [documentFlow.documentPageIndex]);

  return (
    // <Grid
    //     templateAreas={`"header header header header "
    //           "main main main carousel "
    //       `}
    //     gridTemplateRows={"96px 1fr"}
    //     templateColumns={["1fr", "1fr", "repeat(2, 1fr)"]}
    //     height="100vh"
    //     column="100vw"
    //     overflow='hidden'
    //   >
    //     <GridItem area="header" bgColor="#FFF">
    //       <V5Navbar />
    //       {stepper && (
    //         <>
    //           <RouteStepper
    //             activeIndex={
    //               documentFlow.documentPageIndex
    //                 ? documentFlow.documentPageIndex
    //                 : 0
    //             }
    //             height="100%"
    //             maxH="50px"
    //             padding="0 0.75em"
    //             array={
    //               documentFlow.documentFlowArray
    //                 ? [...documentFlow.documentFlowArray]
    //                 : null
    //             }
    //           />
    //         </>
    //       )}
    //     </GridItem>

    //     <GridItem
    //       area="main"
    //       bgColor="#2c2c2c0a"
    //       borderRight="2px solid #9292926b"
    //       width="82vw"

    //     >
    //       <Flex
    //         flexDirection="column"
    //         justifyContent={justifyContent ? `${justifyContent}` : ""}
    //         h="100%"
    //       >
    //         <Box
    //           mt={
    //             location.pathname.split("/")[1] === "document-flow"
    //               ? "2em"
    //               : "4em"
    //           }
    //           textAlign="center"
    //           mb="2em"
    //         >
    // {documentFlow.documentFlowArray &&
    // documentFlow.documentPageIndex <=
    //   documentFlow.documentFlowArray.length - 1 ? (
    //   <Flex
    //     width="90%"
    //     margin="0 auto"
    //     maxH="2em"
    //     justifyContent={
    //       documentFlow.documentFlowArray &&
    //       documentFlow.documentPageIndex <=
    //         documentFlow.documentFlowArray.length &&
    //       documentFlow.documentFlowArray[documentFlow.documentPageIndex]
    //         .isSkippable &&
    //       documentFlow.documentPageIndex > 0
    //         ? "space-between"
    //         : documentFlow.documentFlowArray &&
    //           documentFlow.documentFlowArray[
    //             documentFlow.documentPageIndex
    //           ].isSkippable &&
    //           documentFlow.documentPageIndex === 0
    //         ? "flex-end"
    //         : ""
    //     }
    //   >
    //     {documentFlow.documentFlowArray &&
    //     documentFlow.documentPageIndex > 0 ? (
    //       <Button
    //         boxShadow="none"
    //         variant="link"
    //         onClick={() => {
    //           disptach(decreasePageIndex());
    //           // disptach(decreaseRouteIndex());
    //         }}
    //       >
    //         <ArrowBackIcon /> Back
    //       </Button>
    //     ) : (
    //       <></>
    //     )}
    //     {documentFlow.documentFlowArray &&
    //     documentFlow.documentFlowArray[documentFlow.documentPageIndex]
    //       .isSkippable ? (
    //       <Button
    //         boxShadow="none"
    //         variant="link"
    //         onClick={() => {
    //           disptach(increasePageIndex());
    //           // disptach(increaseRouteIndex());
    //         }}
    //       >
    //         {defaultLanguage?.Skip} <ArrowForwardIcon />
    //       </Button>
    //     ) : (
    //       <></>
    //     )}
    //   </Flex>
    // ) : (
    //   <></>
    // )}
    //           <Heading fontSize="3xl">{heading}</Heading>
    //           <Text fontSize="xl" fontWeight="normal" mt="1em">
    //             {subHeading}
    //           </Text>
    //         </Box>
    //         {children}
    //       </Flex>
    //     </GridItem>
    //     <GridItem area="carousel" bgColor="#92929229" width="18vw" >
    //       <Flex
    //         h="100%"
    //         maxH={stepper ? "calc(100vh - 150px)" : "calc(100vh - 96px)"}
    //         overflowY="scroll"
    //         alignItems="center"
    //         flexDirection="column"
    //         justifyContent="space-around"
    //         gap="0.5em"
    //         // marginTop='1em'
    //         // marginBottom='2em'
    //       >
    //         <Suspense fallback={<div>Loading...</div>}>
    //           <Box maxW="180px" maxH="187px">
    //             <V5AdvertisementCarousel maxHeight="180px" maxWidth="180px" dataArray={linkData} />
    //           </Box>
    //           <Box maxW="180px" maxH="180px">
    //             <V5AdvertisementCarousel maxHeight="180px" maxWidth="180px" dataArray={caroslData2} />
    //           </Box>
    //           <Box maxW="180px" maxH="180px">
    //             <V5AdvertisementCarousel maxHeight="180px" maxWidth="180px" dataArray={caroslData3} />
    //           </Box>
    //         </Suspense>
    //       </Flex>
    //     </GridItem>
    //   </Grid>

    <Grid
      minH={["100vh", "100vh", "100vh", ""]}
      templateColumns={["1fr", "1fr", "repeat(4, 1fr)", "repeat(4, 1fr)"]}
    >
      <GridItem
        gridColumn={"1/4"}
        minHeight="100vh"
        maxHeight="100vh"

        // gridTemplateRows="18% 62% 20%"
        // display="grid"
        // gridAutoFlow="row"
        // height="100%"
        // border="1px solid red"
      >
        <V5Navbar />
        {stepper && (
          <>
            <RouteStepper
              activeIndex={
                documentFlow.documentPageIndex
                  ? documentFlow.documentPageIndex
                  : 0
              }
              height="100%"
              maxH="50px"
              padding="0 0.75em"
              array={
                documentFlow.documentFlowArray
                  ? [...documentFlow.documentFlowArray]
                  : null
              }
            />
          </>
        )}
        <Box
          my={location.pathname.split("/")[1] === "document-flow" ? "2em" : ""}
          textAlign="center"
        >
          {documentFlow.documentFlowArray &&
          documentFlow.documentPageIndex <=
            documentFlow.documentFlowArray.length - 1 ? (
            <Flex
              width="90%"
              margin="0 auto"
              maxH="2em"
              justifyContent={
                documentFlow.documentFlowArray &&
                documentFlow.documentPageIndex <=
                  documentFlow.documentFlowArray.length &&
                documentFlow.documentFlowArray[documentFlow.documentPageIndex]
                  .isSkippable &&
                documentFlow.documentPageIndex > 0
                  ? "space-between"
                  : documentFlow.documentFlowArray &&
                    documentFlow.documentFlowArray[
                      documentFlow.documentPageIndex
                    ].isSkippable &&
                    documentFlow.documentPageIndex === 0
                  ? "flex-end"
                  : ""
              }
            >
              {documentFlow.documentFlowArray &&
              documentFlow.documentPageIndex > 0 ? (
                <Button
                  boxShadow="none"
                  variant="link"
                  onClick={() => {
                    disptach(decreasePageIndex());
                    // disptach(decreaseRouteIndex());
                  }}
                >
                  <ArrowBackIcon /> Back
                </Button>
              ) : (
                <></>
              )}
              {documentFlow.documentFlowArray &&
              documentFlow.documentFlowArray[documentFlow.documentPageIndex]
                .isSkippable ? (
                <Button
                  boxShadow="none"
                  variant="link"
                  onClick={() => {
                    disptach(increasePageIndex());
                    // disptach(increaseRouteIndex());
                  }}
                >
                  {defaultLanguage?.Skip} <ArrowForwardIcon />
                </Button>
              ) : (
                <></>
              )}
            </Flex>
          ) : (
            <></>
          )}
        </Box>

        <Flex
          height={stepper ? "calc(100vh - 235px)" : "calc(100vh - 96px)"}
          flexDirection="column"
          justifyContent={justifyContent}
          gap={gap}
        >
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign={"center"}
            padding={["0.25em 0.8em", "0.25em 0.8em", "0.25em 0.8em", ""]}
          >
            <Heading fontSize="3xl">{heading}</Heading>
            <Text fontSize="xl" fontWeight="normal" mt="1em">
              {subHeading}
            </Text>
          </Flex>
          {children}

          {hasFooter ? (
            <Flex justifyContent="center" alignItems="center">
              <Button
                variant="gradient"
                fontWeight="semibold"
                width="80%"
                margin="0 auto"
                maxW="347px"
                onClick={(e) => onSubmitClick(e)}
                isDisabled={isDisabled}
              >
                {buttonText ? buttonText : "NA"}
              </Button>
            </Flex>
          ) : null}
        </Flex>
      </GridItem>
      <GridItem
        display={["none", "none", "unset"]}
        gridColumn={"4 / 5"}
        height="100vh"
      >
        <Flex
          flexDirection="column"
          justifyContent="space-around"
          alignItems="center"
          height="100%"
        >
          <Box maxW="180px" maxH="187px">
            <V5AdvertisementCarousel
              maxHeight="180px"
              maxWidth="180px"
              dataArray={linkData}
            />
          </Box>
          <Box maxW="180px" maxH="180px">
            <V5AdvertisementCarousel
              maxHeight="180px"
              maxWidth="180px"
              dataArray={caroslData2}
            />
          </Box>
          <Box maxW="180px" maxH="180px">
            <V5AdvertisementCarousel
              maxHeight="180px"
              maxWidth="180px"
              dataArray={caroslData3}
            />
          </Box>
        </Flex>
      </GridItem>
    </Grid>
  );
};
