import { API_CONSTANTS } from "../../../constants/apiConstants";
import fetchHandler from "../fetchHandler";

export const registerUser = async (body) => {
  const { data } = await fetchHandler(
    API_CONSTANTS.REGISTER_USER,
    {},
    {},
    body,
    API_CONSTANTS.HTT_POST,
    { isAuthRequired: false }
  );
  return data;
};

export const saveRegisterDetails = async (url, body) => {
  const { data } = await fetchHandler(
    url,
    {},
    {},
    body,
    API_CONSTANTS.HTT_POST,
    { isAuthRequired: false }
  );
  return data;
};
