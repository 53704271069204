import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../constants/initialState";
import { initialIndustryThunk } from "../thunk/initialIndustryThunk";
import { loadMoreIndustryThunk } from "../thunk/loadMoreIndustryThunk";
import { initialFilterIndustryThunk } from "../thunk/initialFilterIndustryThunk";
import { loadMoreFilterIndustryThunk } from "../thunk/loadMoreFilterIndustryThunk";
import { myRequirementThunk } from "../thunk/myRequirementThunk";
import { loadMoreMyRequirement } from "../thunk/loadMoreMyRequirement";
import {
  convertFileToBase64,
  convertToBase64,
} from "../../../../utils/formatConverter/convertToBase64";

export const industryRequirementSlice = createSlice({
  name: "industryRequirement",
  initialState: initialState,
  reducers: {
    resetRequirement: (state, { payload }) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("IndustryImages");
      sessionStorage.removeItem("REQUIREMENT_CATEGORY");
      sessionStorage.removeItem("REQUIREMENT_CATEGORY_ID");
      sessionStorage.removeItem("REQUIREMENT_METHOD_ID");
    },
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setFilterModalOpen: (state, { payload }) => {
      state.isFilterOpen = payload;
    },
    setFilteredDataRequired: (state, { payload }) => {
      state.filteredDataRequired = payload;
    },
    setFilterByCountry: (state, { payload }) => {
      state.filterByCountry = payload;
    },
    setFilterByState: (state, { payload }) => {
      state.filterByState = payload;
    },
    setFilterByCity: (state, { payload }) => {
      state.filterByCity = payload;
    },
    setFilterByInputMethod: (state, { payload }) => {
      state.filterByInputMethod = payload;
    },
    setFilterByCategory: (state, { payload }) => {
      state.filterByCategory = payload;
    },
    setFilterByTimeRange: (state, { payload }) => {
      state.filterByTimeRange = payload;
    },
    setDeletePopUp: (state, { payload }) => {
      state.isDeletePopUpopen = payload;
    },
    setReportModal: (state, { payload }) => {
      state.isReportPopUpopen = payload;
    },
    setInputModal: (state, { payload }) => {
      state.selectInputTypeModal = payload;
    },
    setInputMethodId: (state, { payload }) => {
      state.inputMethodId = payload;
      sessionStorage.setItem("REQUIREMENT_METHOD_ID", payload);
    },
    setRequirementCategoryId: (state, { payload }) => {
      state.requirementCategoryId = payload;
      sessionStorage.setItem("REQUIREMENT_CATEGORY_ID", payload);
    },
    setRequirementCategoryValue: (state, { payload }) => {
      state.requirementCategoryValue = payload;
      sessionStorage.setItem("REQUIREMENT_CATEGORY", payload);
    },
    setRequirementMethodId: (state, { payload }) => {
      state.requirementMethodId = payload;
    },
    setIsMediaOpen: (state, { payload }) => {
      state.isAddMediaOpen = payload;
    },
    setIndustryImageList: (state, { payload }) => {
      console.log("Payload", payload);
      const data = convertFileToBase64(payload);
      sessionStorage.setItem("industryImageList", JSON.stringify(data));
    },
    setLastId: (state, { payload }) => {
      state.lastId = payload;
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setRequirementConnectList: (state, { payload }) => {
      state.connectRequirementList = payload;
    },
    setRequirementLikeList: (state, { payload }) => {
      state.likeRequirementList = payload;
    },
    setConnectModal: (state, { payload }) => {
      state.connectModal = payload;
    },
    setRequirementSucessModal: (state, { payload }) => {
      state.requirementSuccess = payload;
    },
    setImageArray: (state, { payload }) => {
      state.imageArray = payload;
    },
    setImageLastId: (state, { payload }) => {
      state.lastImageId = payload;
    },
    setLikeStatusData: (state, { payload }) => {
      if (payload) {
        state.likeStatusData = payload;
      }
    },
    setLikeMergedData: (state, { payload }) => {
      if (payload) {
        state.likeMergedData = payload;
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(initialIndustryThunk.pending, (state) => {
        state.data = null;
        state.initialLoading = true;
        state.paginationLoading = false;
        state.error = false;
        state.errorMessage = false;
        state.paginationCount = 1;
        state.endOfData = false;
      })
      .addCase(initialIndustryThunk.fulfilled, (state, { payload }) => {
        state.paginationCount = 1;
        state.initialLoading = false;
        state.error = false;
        state.errorMessage = null;
        state.data = payload;
        if (payload?.length < state.cardCount) {
          state.endOfData = true;
        }
      })

      .addCase(loadMoreIndustryThunk.pending, (state) => {
        state.paginationLoading = true;
        state.error = false;
        state.errorMessage = null;
      })
      .addCase(loadMoreIndustryThunk.fulfilled, (state, { payload }) => {
        state.error = false;
        state.errorMessage = null;
        state.paginationLoading = false;
        if (payload?.length >= state.cardCount) {
          state.data = [...state?.data, ...payload];
          state.paginationCount += 1;
        } else {
          state.endOfData = true;
        }
      })

      .addCase(initialFilterIndustryThunk.pending, (state) => {
        state.data = null;
        state.initialLoading = true;
        state.paginationLoading = false;
        state.error = false;
        state.errorMessage = false;
        state.paginationCount = 1;
        state.endOfData = false;
      })
      .addCase(initialFilterIndustryThunk.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.paginationCount = 1;
        state.initialLoading = false;
        state.error = false;
        state.errorMessage = null;
        if (payload?.length >= state?.cardCount) {
          state.data = [...state.data, ...payload];
          state.paginationCount += 1;
        } else {
          state.endOfData = true;
        }
      })

      .addCase(loadMoreFilterIndustryThunk.pending, (state, { payload }) => {
        state.paginationLoading = true;
        state.error = false;
        state.errorMessage = null;
      })
      .addCase(loadMoreFilterIndustryThunk.fulfilled, (state, { payload }) => {
        state.error = false;
        state.errorMessage = null;
        state.paginationLoading = false;
        if (payload.length >= state?.cardCount) {
          state.data = [...state.data, ...payload];
          state.paginationCount += 1;
        } else {
          state.endOfData = true;
        }
      })

      // My Requirement
      .addCase(myRequirementThunk.pending, (state) => {
        state.data = null;
        state.initialLoading = true;
        state.paginationLoading = false;
        state.error = false;
        state.errorMessage = false;
        state.paginationCount = 1;
        state.endOfData = false;
      })
      .addCase(myRequirementThunk.fulfilled, (state, { payload }) => {
        state.paginationCount = 1;
        state.initialLoading = false;
        state.error = false;
        state.errorMessage = null;
        state.data = payload;
        if (payload?.length < state.cardCount) {
          state.endOfData = true;
        }
      })
      .addCase(loadMoreMyRequirement.pending, (state) => {
        state.paginationLoading = true;
        state.error = false;
        state.errorMessage = null;
      })
      .addCase(loadMoreMyRequirement.fulfilled, (state, { payload }) => {
        state.error = false;
        state.errorMessage = null;
        state.paginationLoading = false;
        if (payload.length >= state?.cardCount) {
          state.data = [...state.data, ...payload];
          state.paginationCount += 1;
        } else {
          state.endOfData = true;
        }
      });
  },
});

export const {
  setData,
  setFilterModalOpen,
  setFilterByCategory,
  setFilterByCity,
  setFilterByCountry,
  setFilterByInputMethod,
  setFilterByState,
  setFilterByTimeRange,
  setFilteredDataRequired,
  setDeletePopUp,
  setReportModal,
  setInputModal,
  setInputMethodId,
  setRequirementCategoryId,
  setRequirementMethodId,
  setRequirementCategoryValue,
  setIsMediaOpen,
  setIndustryImageList,
  setLastId,
  setIsLoading,
  setRequirementConnectList,
  setRequirementLikeList,
  setConnectModal,
  setRequirementSucessModal,
  setImageArray,
  setImageLastId,
  resetRequirement,
  setLikeStatusData,
  setLikeMergedData,
} = industryRequirementSlice.actions;
