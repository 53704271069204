import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../constants/initialState";
import { setIsSubmitLoading } from "../../addMaterial/slice/postMaterialsSlice";


export const addCommunitySlice = createSlice({
  name: "postCommunity",
  initialState: initialState,
  reducers: {
    setContentTypeModalOpen: (state, action) => {
      state.isContentTypeModalOpen = action.payload;
    },
    setAudienceModalOpen: (state, action) => {
      state.isAudienceModalOpen = action.payload;
    },
    setContentType: (state, action) => {
      state.ContentType = action.payload;
    },
    setAudienceTypesData: (state, action) => {
      state.audienceTypes = action.payload;
    },
    setIsSubmitting: (state, action) => {
      state.isSubmitting = action.payload;
    }
  
  },

});

export const {
  setContentTypeModalOpen,
  setAudienceModalOpen,
  setContentType,
  setAudienceTypesData,
  setIsSubmitting
} = addCommunitySlice.actions;
