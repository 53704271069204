import { createAsyncThunk } from "@reduxjs/toolkit";
import { setIsContinueModelOpen,  setIsPasswordChangedSuccessModelOpen } from "../slice/forgotPasswordSlice";
import { updatePasswordAfterForgotPassword } from "../../../services/forgetPassward/forgetPassward";


export const handlePasswordUpdateThunk = createAsyncThunk("post/updatePasswordAfterForgotPassword", async(value, {rejectWithValue, getState, dispatch}) => {
    const forgotPasswordData = getState().forgotPassword;
    const requiredBody = {
        UserCredentialId : forgotPasswordData.selectedAccount.UserCredentialId,
        BusinessTypeId: forgotPasswordData.selectedAccount.BusinessTypeId,
        Password: value
    }
    try{
    const data= await updatePasswordAfterForgotPassword(requiredBody);

    if(data.Status){
        dispatch(setIsPasswordChangedSuccessModelOpen(true))
        dispatch(setIsContinueModelOpen(false))

    }
    else{
        const key = Object.keys(data.Message)[0]
     
        return rejectWithValue(data.Message[`${key}`])
    }
    }
    catch(error){
        return rejectWithValue(error.Message)
    }
}) 