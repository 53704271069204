import { useDispatch, useSelector } from "react-redux"
import { Route, Navigate } from "react-router-dom"
import { setSelectedCountryThunk } from "../features/global/appConfig/thunk/setSelectedCountryThunk";
import { getAllLanguageJsonThunk } from "../features/global/mtData/thunk/getAllLanguagesThunk";
import { setLanguage } from "../features/global/appConfig/slice/appConfigSlice";
import { useEffect } from "react";
import { defaultEnglishJson } from "../../staticData/staticData";

export const AuthRoute = ({children}) => {
    const {token, isAuthenticated} = useSelector((state) => (state.user))
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(setSelectedCountryThunk("91"));
          dispatch(getAllLanguageJsonThunk("english.json"));
          dispatch(setLanguage(defaultEnglishJson));
          dispatch(setLanguage(defaultEnglishJson))
      }, []);

        if (!token && !isAuthenticated) {
            return <Navigate to='/login/login-form' />
          }

        
          return children;
        
    
}