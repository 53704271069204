import { createAsyncThunk } from "@reduxjs/toolkit";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";
import { getMaterialMarketplaceList } from "../../../services/materials/materials";


export const generatorsLoadMoreDataThunk = createAsyncThunk("generator/LoadMoreCards", async(_, {getState}) => {

    const generatorsId = convertToBase64('4')
    const {cardCount , paginationCount} = getState().generatorsMaterialMarketplace.cardCount
   

try{
    
    const data = await getMaterialMarketplaceList(cardCount,(paginationCount * cardCount),generatorsId);
    
    if(data.Status === 200 && Object.keys(data.Data).length > 0){
        return data.Data
    }

   
}
catch(error){

}
})