import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./MaterialDetailCard.css"

export const MaterialDetailCard = (
  { heading = "", description = "", descriptionCursorRequired = false ,listedCount ="", imageUrl = "", onSubmitButtonClick = ()=>{}}
  
  
) => {
  const navigate = useNavigate()
  const {currencyDetails } = useSelector((state)=>state.user)


  return heading ? (
    <div className="new-description-card-material" onClick={onSubmitButtonClick}>
      <img style={{maxHeight:"80px", height:"80px"}} src={imageUrl}></img>
      <h1 className="new-description-card__heading">{heading}</h1>
      <p
        className={`new-description-card__description`}
      >
        {description}+&nbsp;Listings 
      </p>
    </div>
  ) : (
    <></>
  );
};
