import { API_CONSTANTS } from "../../../constants/apiConstants";
import { fetchHandler } from "../../..";

// Countries
export const getAllCountries = async () => {
  try {
    const { data } = await fetchHandler(
      API_CONSTANTS.GET_COUNTRY,
      {},
      {},
      {},
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: false }
    );
    if (data?.Status === 200) {
      return {
        data: data?.Data?.CountriesList,
        message: data?.Message?.SuccessMessage,
      };
    } else {
      const error = new Error();
      return { message: (error.message = data.Message.FailMessage) };
    }
  } catch (error) {}
};

// Languages
export const getAllLanguages = async (isdCode) => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.POST_LANGUAGE}?CountryIsoCode=${isdCode}`,
      {},
      {},
      {},
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: false }
    );
    if (data?.Status === 200) {
      return {
        data: data.Data.LanguageData,
        message: data.Message.SuccessMessage,
      };
    } else {
      const error = new Error();
      return { message: (error.message = data.Message.FailMessage) };
    }
  } catch (error) {}
};
export const getAllLanguagesJson = async (selectedLanguage) => {
  try {
    const url = `${process.env.REACT_APP_CDN_PVT_URL}${API_CONSTANTS.LANGUAGE_TRANSALTION_JSON}/${selectedLanguage}`;
    const response = await fetch(url);
    const data = await response.json();
    return data;
  } catch (error) {}
};

// All Business and User Types
export const getAllBusinessTypes = async () => {
  try {
    const { data } = await fetchHandler(
      API_CONSTANTS.GET_ALL_BUSINESS_TYPES,
      {},
      {},
      {},
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: false }
    );
    return data;
  } catch (error) {}
};
export const getAllUserTypes = async () => {
  try {
    const { data } = await fetchHandler(
      API_CONSTANTS.GET_ALL_USER_TYPES,
      {},
      {},
      {},
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: false }
    );
    return data;
  } catch (error) {}
};
export const generateOtpForRegister = async (body) => {

  try {
    const {data} = await fetchHandler(
      API_CONSTANTS.GENERATE_OTP_FOR_REGISTER,
      {},
      {},
      body,
      API_CONSTANTS.HTT_POST,
      { isAuthRequired: false }
    );
    return data

  } catch (error) {
    throw error;
  }
};
