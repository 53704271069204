import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import {
  countrylist,
  StateCountryId,
  city,
  getYearofExpirence,
  addExpirence,
} from "../../..../../../../../../../../../../ApiEquip9/CommonApi";
import { useToast } from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import * as Yup from "yup";
import "./WorkExpirencePop.css";
import { Checkbox } from "@chakra-ui/react";
import { expirenceMonths } from "../../../../../../../../../../staticData/staticData";

export const AddWorkExpirence = ({
  isOpenForm,
  onCloseForm,
  triggerRefresh,
  workCompanyId,
}) => {
  const userData = useSelector((state) => state.user);
  // const loginData = useSelector((state) => state.login);
  const isdCode =
    userData?.accountDetails?.UserDetails?.CredentialDetails?.IsdCode;
  const [imagePreview, setImagePreview] = useState(null);
  const [fileType, setFileType] = useState("");
  const [isCurrentCompany, setIsCurrentCompany] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  // const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  // const [cityerror, setcityError] = useState("");
  const [countryListData, setCountryListData] = useState(null);
  const [stateListData, setStateListData] = useState(null);
  const [cityListData, setCityListData] = useState(null);
  const toast = useToast();
  const queryClient = useQueryClient();
  const initialValues = {
    CompanyName: "",
    AddressLine1: "",
    ReferenceName: "",
    ReferenceMobileNumber: "",
    VerificationDocumentNumber: "",
    // CountryId:null,
    // StateId:null,
    // CityId:'',
    Zipcode: "",
    // DocumentFileReference: null,
    ReferenceEmail: "",
  };

  /**************Error Function********************************** */

  const getErrorDescription = (err) => {
    let descriptionString = "";
    descriptionString += err?.data?.Message?.AddressLine1
      ? `${err?.data?.Message?.AddressLine1}\n`
      : "";
    descriptionString += err?.data?.Message?.CityId
      ? `Please Enter city`
      : "";
    descriptionString += err?.data?.Message?.CountryId
      ? `Please Enter Country`
      : "";
    descriptionString += err?.data?.Message?.CompanyName
      ? `${err?.data?.Message?.CompanyName}\n`
      : "";
    descriptionString += err?.data?.Message?.ExperienceInMonth
      ? `${err?.data?.Message?.ExperienceInMonth}\n`
      : "";
    descriptionString += err?.data?.Message?.ExperienceInYear
      ? `${err?.data?.Message?.ExperienceInYear}\n`
      : "";
    descriptionString += err?.data?.Message?.ReferenceEmail
      ? `${err?.data?.Message?.ReferenceEmail}\n`
      : "";
    descriptionString += err?.data?.Message?.ReferenceMobileNumber
      ? `${err?.data?.Message?.ReferenceMobileNumber}\n`
      : "";
    descriptionString += err?.data?.Message?.ReferenceName
      ? `${err?.data?.Message?.ReferenceName}\n`
      : "";
    descriptionString += err?.data?.Message?.StateId
      ? `Please Enter State`
      : "";
    descriptionString += err?.data?.Message?.Zipcode
      ? `${err?.data?.Message?.Zipcode}\n`
      : "";
    return descriptionString;
  };


  const onClosePopup=()=>{
    setImagePreview(null);
    onCloseForm();
   
  }

  const handleSubmit = async (values, { resetForm }) => {
    const Payload = {
      CompanyName: values.CompanyName,
      ExperienceInYear: values.ExperienceInYear,
      ExperienceInMonth: values.ExperienceInMonth,
      AddressLine1: values.AddressLine1,
      IsCurrentCompany: isCurrentCompany,
      CountryId: selectedCountry,
      StateId: selectedState,
      CityId: selectedCity,
      Zipcode: values.Zipcode,
      ReferenceName: values.ReferenceName,
      ReferenceEmail: values.ReferenceEmail,
      ReferenceMobileNumber: values.ReferenceMobileNumber,
      IsdCode: isdCode,
      SalarySlip: values.SalarySlip,
    };

    //
    try {
      const response = await addExpirence(Payload);
      if (response?.data?.APIResponseCode === 200) {
        toast({
          title: "",
          description: `${response.data.Message.SuccessMessage}`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        queryClient.invalidateQueries("getWorkDetails");
        triggerRefresh();
        onCloseForm();
        resetForm();
        setImagePreview(null);
      }
      return response;
    } catch (err) {
      const errorDescription = getErrorDescription(err);
      toast({
        title: "Error while uploading",
        description: `${errorDescription}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return null;
    }
  };

  const validationSchema = Yup.object().shape({
    
    CompanyName: Yup.string()
      .required("Company Name is required")
      .min(5, "Company Name cannot be shorter than 5 characters")
      .max(100, "Company Name cannot be longer than 12 characters"),
    AddressLine1: Yup.string()
      .required("Address  is required")
      .max(100, "Company Name cannot be longer than 12 characters"),
    Zipcode: Yup.string()
    .required("Please insert the zipcode")
    .matches(/^[0-9]{6}$/, "Please enter a valid 6-digit Zip code."),
    ReferenceName: Yup.string()
      .required("Please Enter the reference name")
      .min(4, "Referece Name cannot be shorter than 5 characters")
      .max(16, "Referece Name cannot be longer than 5 characters"),
    ReferenceEmail: Yup.string()
      .email()
      .required("Please enter the reference email")
      .max(50, "Company Name cannot be longer than 50 characters"),
    ReferenceMobileNumber: Yup.string()
      .required("Please enter a mobile number")
      .matches(/^[1-9]\d{9}$/, {
        message: "Please enter a valid mobile number.",
        excludeEmptyString: false,
      }),
  });

  const { data: fetchedCountryList } = useQuery("countryList", () =>
    countrylist()
  );
  useEffect(() => {
    setCountryListData(fetchedCountryList?.data?.Data?.CountriesList);
  }, [fetchedCountryList]);

  const { data: fetchedStateList } = useQuery(
    ["getStateList", selectedCountry],
    () => StateCountryId(selectedCountry),
    {
      enabled: !!selectedCountry,
    }
  );
  useEffect(() => {
    setStateListData(fetchedStateList?.data?.Data?.StatesList);
    setCityListData(null);
  }, [fetchedStateList, selectedCountry]);

  const { data: fetchedCityList } = useQuery(
    ["getCitylist", selectedState],
    () => city(selectedState),
    {
      enabled: !!selectedState,
    }
  );
  useEffect(() => {
    setCityListData(fetchedCityList?.data?.Data?.CitiesList);
  }, [fetchedCityList]);

  const { data: getExpirenceofExpirece } = useQuery("getYearExpirence", () =>
    getYearofExpirence()
  );
  const getYearExpirence = getExpirenceofExpirece?.data?.Data.YearOfExperience;

  return (
    isOpenForm && (
      <div className="popup-card-overlay">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form className="popup-card-workpopup">
              <div>
                <button
                  className="popup-card-close"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClosePopup()
                  }}
                >
                  <ReactSVG
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Cross.svg`}
                  />
                </button>
                
                <h1 style={{textAlign:"center"}}>Add Company Details</h1>
                <div className="popup-card-inner">
                  <div>
                    <label htmlFor="inputField">
                      Company Name <span className="asterisk">*</span>
                    </label>
                    <Field
                      type="text"
                      name="CompanyName"
                      placeholder="Company Name"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                      }}
                    />
                    <ErrorMessage
                      name="CompanyName"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <br />
                  <div>
                    <label htmlFor="inputField">
                      Company Address <span className="asterisk">*</span>
                    </label>
                    <Field
                      type="text"
                      name="AddressLine1"
                      placeholder="Company Address"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                      }}
                    />
                    <ErrorMessage
                      name="AddressLine1"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  {/* option field */}
                  <div className="OptionField">
                    <div style={{ marginTop: "23px" }}>
                      <label htmlFor="selectField">
                        Select Country<span className="asterisk">*</span>
                      </label>
                      <Field
                        as="select"
                        name="CountryId"
                        onChange={(e) => {
                          setSelectedCountry(e.target.value);
                          // handleChange(e);
                        }}
                      >
                        <option value="">Select an option</option>
                        {countryListData?.map((item, index) => (
                          <option key={index.id} value={item.CountryId}>
                            {item.CountryName}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div style={{ marginTop: "23px" }}>
                      <label htmlFor="selectField">
                        Select State<span className="asterisk">*</span>
                      </label>
                      <Field
                        as="select"
                        name="StateId"
                        onChange={(e) => {
                          setSelectedState(e.target.value);
                        }}
                      >
                        <option value="">Select an option</option>
                        {stateListData?.map((item, index) => (
                          <option key={index.id} value={item.StateId}>
                            {item.StateName}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                  <div className="OptionField">
                    <div>
                      <label htmlFor="selectField">
                        Select City<span className="asterisk">*</span>
                      </label>
                      <Field
                        as="select"
                        // type="dropdown"
                        name="CityId"
                        onChange={(e) => {
                          const currentValue = e.target.value;
                          setSelectedCity(currentValue);
                        }}
                      >
                        <option value="">Select an option</option>
                        {cityListData?.map((item, index) => (
                          <option key={index.id} value={item.CityId}>
                            {item.CityName}
                          </option>
                        ))}
                      </Field>

                      
                      {/* {cityerror && <div className="error-message">{cityerror}</div>} */}
                      <ErrorMessage
                        name="CityId"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div>
                      <label htmlFor="selectField">
                        Select Pincode<span className="asterisk">*</span>
                      </label>
                      <Field
                        type="text"
                        name="Zipcode"
                        placeholder="Pincode"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                      />
                      <ErrorMessage
                        name="Zipcode"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="inputField">
                      Total Work Expirence With Company{" "}
                      <span className="asterisk">*</span>
                    </label>
                    <div className="OptionField">
                      <div>
                        <label htmlFor="selectField">
                          Year<span className="asterisk">*</span>
                        </label>
                        <Field as="select" name="ExperienceInYear">
                          <option value="">Select an option</option>
                          {getYearExpirence?.map((item, index) => (
                            <option
                              key={index.id}
                              value={item.YearOfExperienceId}
                            >
                              {item.YearOfExperienceValue}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div>
                        <label htmlFor="selectField">
                          Month<span className="asterisk">*</span>
                        </label>
                        <Field as="select" name="ExperienceInMonth">
                          <option value="">Select an option</option>
                          {expirenceMonths?.map((item, index) => (
                            <option
                              key={index.id}
                              value={item.MonthrOfExperienceId}
                            >
                              {item.MonthsOfExperienceValue}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
                <Checkbox
                  size="lg"
                  colorScheme="orange"
                  //    defaultChecked
                  name="IsCurrentCompany"
                  onChange={(e) =>
                    setIsCurrentCompany(isCurrentCompany === 0 ? 1 : 0)
                  }
                >
                  is it your current company?
                </Checkbox>
                <ErrorMessage
                  name="VerificationDocumentName"
                  component="div"
                  className="error-message"
                />
                <br />
                <div>
                  <label htmlFor="inputField">
                    Reference Name <span className="asterisk">*</span>
                  </label>
                  <Field
                    type="text"
                    name="ReferenceName"
                    placeholder="Reference Name"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") e.preventDefault();
                    }}
                  />
                  <ErrorMessage
                    name="ReferenceName"
                    component="div"
                    className="error-message"
                  />
                </div>
                <br />
                <div>
                  <label htmlFor="inputField">
                    Reference Mobile Number <span className="asterisk">*</span>
                  </label>
                  <Field
                    type="text"
                    name="ReferenceMobileNumber"
                    placeholder="Reference Mobile Number"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") e.preventDefault();
                    }}
                  />
                  <ErrorMessage
                    name="ReferenceMobileNumber"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div>
                  <br />
                  <label htmlFor="inputField">
                    Reference Email Id <span className="asterisk">*</span>
                  </label>
                  <Field
                    type="text"
                    name="ReferenceEmail"
                    placeholder="Reference Email Id"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") e.preventDefault();
                    }}
                  />
                  <ErrorMessage
                    name="ReferenceEmail"
                    component="div"
                    className="error-message"
                  />
                </div>
                <br />
                <label htmlFor="inputField">
                  Upload your salary slip <span className="asterisk">*</span>
                </label>
                <div className="file-input-container">
                  <label
                    htmlFor="custom-file-input"
                    className="custom-file-button"
                  >
                    Upload
                  </label>
                  <input
                    className="custom-file-input"
                    id="custom-file-input"
                    type="file"
                    accept="image/jpeg, image/png, application/pdf"
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      setFileType(file.type); // Set the file type here
                      setFieldValue("SalarySlip", file);
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        setImagePreview(reader.result);
                      };
                      if (file) {
                        reader.readAsDataURL(file);
                      }
                      toast({
                        title: "File Uploaded successfully",
                        description: "You have selected a file for upload.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                        position: "top-right",
                      });
                    }}
                  />
                </div>
                <br />
                <div className="preview-container">
                  {imagePreview && fileType === "application/pdf" ? (
                    <embed
                      src={imagePreview}
                      type="application/pdf"
                      width="100%"
                      height="200px"
                    />
                  ) : (
                    <img
                      src={imagePreview}
                      style={{
                        width: "100%",
                        height: "20vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderStyle: "dashed",
                        borderWidth: "2px",
                        borderColor: "#A0AEC0",
                        background: "#ccc",
                      }}
                    />
                  )}
                </div>
                <ErrorMessage
                  name="SalarySlip"
                  component="div"
                  className="error-message"
                />
                <br />
                <button type="submit" disabled={isSubmitting}>
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  );
};
