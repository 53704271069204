import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Flex,
    FormErrorMessage,
    FormLabel,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Text,
    useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "@chakra-ui/icons";

import { ErrorMessage, Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup"
import { closeAddStoreManagerPopUp, closeAddStorePopUp, setStoreFlag } from "../slice/myStoreSlice";
import { FormikInput } from "../../../components/Input/FormikInput";
import { ImageUpload } from "../../../components/ImageUpload/ImageUpload";
import { getCompanyStaffDetail, postStoreManagerDetails } from "../../../services/store/storeServices";
import { v4 as uuid } from "uuid";




export const AddStoreManager = () => {
    const dispatch = useDispatch();
    const { isStoreManagerPopUpOpen , storeId } = useSelector((state) => state.myStore);
    const { token, accountDetails } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const toast = useToast();

    const [staffDetails, setStaffDetails] = useState();
    const [userCredentialID , setUserCredentialId] = useState();

    useEffect(() => {
        (async () => {
           try {
            const response = await getCompanyStaffDetail(accountDetails?.CompanyDetails?.TaxNumber);
            if (response) {
                const filteredData = response.filter(staff => staff.Name !== null) ;
                setStaffDetails(filteredData);
            }
           } catch (error) {
            
           }
        })()
    }, [])

    const initialValues = {
        storeManager: "",
    };

    const validationSchema = Yup.object().shape({
        storeManager: Yup.string().required("Store Manager Is Required"),
    })


    const handleStore = (values) => {
        const payload = {
            StoreId : storeId,
            UserCredentialId : userCredentialID 
        }
        try {
            (async() => {
                const response = await postStoreManagerDetails(payload);
                if(response){
                    toast({
                        title : response.message,
                        position : "top-right",
                        status : "success"
                    })
                    dispatch(setStoreFlag());
                    dispatch(closeAddStoreManagerPopUp());
                }else{
                    toast({
                        title : response.message,
                        position:"top-right",
                        status : "error"
                    })
                }
                dispatch(closeAddStoreManagerPopUp());
                
            })()
        } catch (error) {
        }
    }

    return (
        <Modal isOpen={isStoreManagerPopUpOpen} isCentered >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Box textAlign="right">
                        <Button
                            variant="ghost"
                            onClick={() => {
                                dispatch(closeAddStoreManagerPopUp());
                            }}
                            boxShadow="none"
                        >
                            <CloseIcon />
                        </Button>
                    </Box>
                    <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
                        <img src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/my-business/my-store/add-store.svg`} style={{ maxHeight: "100px", maxWidth: "100px" }} />
                        <Heading
                            fontSize="28px"
                            fontWeight="600"
                            fontFamily="Assistant"
                            lineHeight="52.32px"
                        >
                            Set your store Manager
                        </Heading>
                        <Text>Select a user as the warehouse manager</Text>
                    </Flex>
                </ModalHeader>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleStore} >
                    {({ values, setFieldValue, errors, touched }) => (
                        <Form>
                            <ModalBody height={"160px"} display={"flex"} flexDirection={"column"} justifyContent={"space-evenly"}>
                                <div>
                                    <FormLabel>Select Manager</FormLabel>
                                    <Field
                                        as={Select}
                                        name="storeManager"
                                        onChange={(e) => {
                                            setFieldValue("storeManager", e.target.value);
                                            const selectedManager = staffDetails.find(staff => staff.Name === e.target.value);
                                            setUserCredentialId(selectedManager?.UserCredentialId)
                                        }}
                                    >
                                        <option value="">Select Manager</option>
                                        {staffDetails
                                            ? staffDetails.map((staff) => (
                                                <option
                                                    key={uuid()}
                                                    value={staff ? staff.Name : ""}
                                                >
                                                    {staff ? staff.Name : "Null Manager"}
                                                </option>
                                            ))
                                            : null}
                                    </Field>
                                    <ErrorMessage
                                        name="storeManager"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>

                            </ModalBody>
                            <ModalFooter alignItems={"center"} justifyContent={"center"} borderRadius={"0px 0px 12px 12px"} boxShadow={"0px -10px 15px 0px rgba(44, 44, 44, 0.16)"}>
                                <Flex alignItems={"center"} justifyContent={"center"}  >
                                    <Button backgroundColor={"#F79C00"} color={"white"} width={"128px"} height={"45px"} type="submit">
                                        Next
                                    </Button>
                                </Flex>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>

    );
};
