import React, { useEffect, useState } from "react";
import { V5SidebarLayout } from "../../../../layout/V5SidebarLayout/V5SidebarLayout";
import { Box, Flex, Text } from "@chakra-ui/react";
import CommonHeader from "../../../../components/CommonHeader/CommonHeader";
import { getIndustryLikesListById } from "../../../../services/industryRequirement/industryRequirement";
import { useParams } from "react-router-dom";
import { Height } from "@mui/icons-material";

const IndustryLikePage = () => {
  const { likeId } = useParams();
  const [data, setData] = useState();
  const getLikesList = async () => {
    const data = await getIndustryLikesListById(likeId);
    if (data) {
      setData(data);
    }
  };

  useEffect(() => {
    getLikesList();
  }, [likeId]);

  return (
    <V5SidebarLayout>
      <Flex direction="column" padding='1em 1em' gap='1em'>
        <CommonHeader header="Received Likes" />
        <Flex  gap="1em" flexWrap="wrap">
          {data && data?.length > 0 ? (
            data.map((data) => (
              <Box
                width="532px"
                Height="100px"
                border="1px solid #E3E5E8"
                borderRadius="6px"
                padding="1em 1em"
                boxShadow="0px 6px 15px -3px #00000029"
              >
                <Text color="#2C2C2C" fontSize="16px" fontWeight="600">
                  {data?.UserName} - {data?.BusinessTypeValue} has Liked  your Requirement
                </Text>
                <Text color="#767676" fontSize="14px" fontWeight="600">
                  Request receives on: {data?.ReceivedOn}
                </Text>
              </Box>
            ))
          ) : (
            <Text>No Data Found</Text>
          )}
        </Flex>
      </Flex>
    </V5SidebarLayout>
  );
};

export default IndustryLikePage;
