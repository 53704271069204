import React from 'react';
import { useSelector } from "react-redux";
import "./HerodetailSection.css"

const HeroText = () => {
  const userData = useSelector((state) => state.user);


  return (
    <div className='hero-text-section'>
      {userData?.accountDetails?.UserDetails?.BusinessTypeValue==='Operator Partner'?(
        <>
      <p className='hero-text-heading'>E9 Roles</p>
      <p className='hero-text-subheading'>Please Select Your Roles.</p>
      </>
      ):(
        <>
        <p className='hero-text-heading'>Business expertise</p>
        <p className='hero-text-subheading'>Expertise Area</p>
        </>
      )
}

    </div>
  );
};

export default React.memo(HeroText);