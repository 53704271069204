import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../constants/initialState";
import { sparePartsInitialDeferredThunk } from "../thunk/sparePartsInitialDeferredThunk";
import { sparePartsLoadMoreDeferredThunk } from "../thunk/sparePartsLoadMoreDeferredThunk";


export const sparePartsDeferredSlice = createSlice({
    name:"sparePArtsDeferred",
    initialState: initialState,
    reducers:{
        setData: (state,{payload})=>{
            state.data = payload
        },
        setMaterialMarketplaceId : (state,{payload}) => {
            state.materialTypeId = payload;
        }
    },

    extraReducers:(builder) =>{
        builder
        .addCase(sparePartsInitialDeferredThunk.pending, (state)=>{
            state.data = null;
            state.initialLoading = true;
            state.paginationLoading = false;
            state.error = false;
            state.errorMessage = false;
            state.paginationCount = 1;
            state.endOfData = false
        })
        .addCase(sparePartsInitialDeferredThunk.fulfilled,(state,{payload}) =>{
            state.paginationCount = 1;
            state.initialLoading = false;
            state.error = false;
            state.errorMessage = null;
            state.data = payload;
            state.assetImageReference = payload?.AssetImageBaseReference
            if(payload?.length < state.cardCount){
              state.endOfData = true
            }
        })
        .addCase(sparePartsLoadMoreDeferredThunk.pending, (state)=>{
            state.paginationLoading = true;
            state.error = false ;
            state.errorMessage = null;
         })
         .addCase(sparePartsLoadMoreDeferredThunk.fulfilled, (state,{payload})=>{
            console.log("Payload",payload);
            state.error = false ;
            state.errorMessage = null ;
            state.paginationLoading = false;
            // state.assetImageReference = payload?.AssetImageBaseReference
            if(payload?.length >= state.cardCount){
                state.data = [...state?.data , ...payload];
                state.paginationCount += 1;
            }else{
                state.endOfData = true;
         }
         })
    }
})

export const {
    setData,
    setMaterialMarketplaceId
}= sparePartsDeferredSlice.actions;