import { createSlice } from "@reduxjs/toolkit";

const jobCycleOwnerSlice = createSlice({
    name : "jobCycleOwner",
    initialState : {
        selectedPartners : [],
        viewRatingId : null,
        postRatingsId : null,
        
    },
    reducers : {
        setSelectedPartners : (state, {payload}) => {
            state.selectedPartners = payload;
        },
    }
})

export const {setSelectedPartners} = jobCycleOwnerSlice.actions;

export default jobCycleOwnerSlice.reducer