import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetReceivedRequestDetails } from "../../../services/materials/materials";

export const getMaterialReceiveDataThunk = createAsyncThunk(
  "myLeads/materialReceiveRequestData",
  async (materialTypeId, { getState }) => {
    try {
      const data = await GetReceivedRequestDetails(materialTypeId);
      return data;
    } catch (error) {}
  }
);
