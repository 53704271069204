export const API_CONSTANTS = {
  /** HTTP VERBS START HERE */
  HTTP_DELETE: "DELETE",
  HTTP_GET: "GET",
  HTTP_PATCH: "PATCH",
  HTT_POST: "POST",
  HTTP_PUT: "PUT",
  /** HTTP VERBS END HERE */

  /** HTTP HEADER KEYS START HERE */
  HEADER_TOKEN: "token",
  /** HTTP HEADER KEYS END HERE */

  /** API ENPOINTS START HERE */
  GET_COUNTRY: "/Country",
  GET_STATE: "/State",
  GET_CITY: "/City",
  POST_LANGUAGE: "/GetAllLanguages",
  LANGUAGE_TRANSALTION_JSON: "LanguageJSONData",
  GET_ALL_BUSINESS_TYPES: "/GetAllBusinessType",
  GET_ALL_USER_TYPES: "/GetUserType",
  SUPPORT_TICKET_UNREGISTERED: "/SupportTicketUnregistred/Create",
  SEND_OTP: "/SendOTP",
  REGISTER_USER: "/Register",
  SAVE_OPERATOR_DETAILS: "/SaveOperatorDetails",
  SAVE_MAINTENANCE_PARTNER_DETAILS: "/SaveMaintenancePartnerDetails",
  SAVE_EQUIPMENT_OWNER_INDIVIDUAL_DETAILS: "/SaveEquipmentOwnerDetails",
  SAVE_BUSINESS_OWNER_DETAILS: "/SaveBusinessOwnerDetails",
  SAVE_COMPANY_DETAILS: "/SaveCompanyDetails",
  GET_LOGIN_DETAILS: "/GetLoginDetails",
  GENERATE_JWT_TOKEN: "/GenerateJWTToken",
  GENERATE_OTP_FOR_REGISTER: "/SendOTP",
  GENERATE_OTP_FORGET_PASSWORD: "/SendForgotPasswordOTP",
  SEND_FORGOT_PASSWORD_OTP: "/SendForgotPasswordOTP",
  UPDATE_PASSWORD_AFTER_FORGOT_PASSWORD: "/UpdatePasswordAfterForgotPassword",
  GET_AUTH_USER_DETAILS: "/GetUserDetails",
  GET_ALL_COMPANY_DESIGNATIONS: "/GetCompanyDesignationType",
  UPDATE_AUTH_USER_DESIGNATION: "/UpdateUserCompanyDesignationDetails",
  UPDATE_AUTH_PROFILE_PICTURE: "/UploadUserProfilePicture",
  GET_ALL_E9_ROLES: "/GetAllUserRolesList",
  POST_SELECTED_E9_ROLE: "/CreateE9UserRole",
  GET_ALL_EQUIPMENTS_TYPE: "/GetAllEquipmentType",
  GET_ALL_INDUSTRY: "/GetAllIndustry?EquipmentTypeId=",
  GET_MAKE: "/Make/",
  SAVE_EQUIPMENT_LICENSE: "/CreateEquipmentLicence",
  HAS_NO_LICENSE: "/CreateNoLicenseRecordForPartner",
  GET_LICENSE_TYPE_LIST: "/GetLicenseTypeList",
  UPLOAD_USER_IDENTITY_DOCUMENT: "/UploadUserIdentityDocument",
  GET_VERIFICATION_DOCUMENTS: "/GetVerificationDocumentType",
  UPLOAD_PROFILE_PICTURE: "/UploadUserProfilePicture",
  UPLOAD_EQUIPMENT_DETAILS: "/CreateUserEquipmentDetails",
  UPLOAD_VERIFICATION_DOCUMENT: "/UploadUserIdentityDocument",
  GET_TAX_DOCUMENT_BY_COUNTRY_ID: "/GetTaxDocTypeByCountryId",
  UPDATE_BUSINESS_IDENTIFICAION_DOCUMENT: "/UpdateCompanyUserTaxDetails",
  UPDATE_USER_LANGUAGE: "/SaveUserLanguageDetails",

  GET_DASHBOARD_DETAILS: "/GetDashboardDetails",
  GET_ALL_TOP_OPERATOR_PARTNER_LIST: "/GetAllTopOperatorPartnersList",
  GET_ALL_TOP_MAINTENANCE_PARTNER_LIST: "/GetAllTopMaintenancePartnersList",
  GET_ALL_OPERATOR_JOBS_WAITING_FOR_YOU: "/GetAllOperatorJobsWaitingForYou",
  GET_MARKETPLACE_FILTER: "/GetMarketPlaceFilter",
  GET_ALL_E9DEALERSHIPMARKETPLACELIST: "/GetE9DealershipMarketPlaceList",
  GET_EQUIPMENT_MAKE_ID: "/Make",
  GET_FILTER_MATERIAL_MARKETPLACELIST: "/GetFilterMaterialMarketplaceList",
  GET_DEALERSHIP_DETAILS_BY_ID: "/GetDealershipDetailsById",
  GET_USER_DS_PRODUCT_LIST: "/GetUserDsProductList",
  GET_MATERIAL_DETAILS_LIST: "/GetMaterialDetailsList",
  GET_MATERIAL_TYPE: "/GetMaterialType",
  GET_ALL_MARKETPLACE_SERVICES: "/GetAllMarketPlaceServices",

  POST_CREATE_E9_DS_MP_CONTACT_DETAILS: "/CreateE9DsMpContactDetails",
  GET_E9_DEALERSHIP_MP_CONNECT_STATUS: "/GetE9DealershipMpConnectStatus",
  POST_MATERIAL_CONTACT_DETAILS: "/PostMaterialContactDetails",
  GET_EQUIPMENT_MAKE_LIST: "/GetEquipmentMakeList",

  // My Business
  GET_USER_DEALERSHIP_LIST: "GetUserDealershipList",
  DELETE_DEALERSHIP_DETAILS: "/DeleteDealershipDetails",
  GET_EQUIPMENT_STATUS_TYPE: "/GetEquipmentStatusType",
  GET_INDUSTRY_TYPE_LIST: "/GetIndustryTypeList",
  CREATE_DEALERSHIP_DETAILS: "/CreateDealershipDetails",
  CREATE_DEALERSHIP_IMMAGE_DETAILS: "/CreateDealershipImageDetails",
  CREATE_DEALERSHIP_CERTIFICATE_DETAILS: "/CreateDealershipCertificateDetails",
  CREATE_DEALERSHIP_VIDEO_DETAILS: "/CreateDealershipVideoDetails",
  GET_PHOTO_TYPE: "/GetPhotoType",
  GET_VIDEO_TYPE: "/GetVideoType",
  GET_RESPONSE_OPTION_TYPE: "/GetResponseOptionType",
  UPDATE_DEALERSHIP_DETAILS: "/UpdateDealershipDetails",
  GET_MT_MYBUSINESS_TYPE_LIST: "/GetMtMyBusinessTypeList",
  DELETE_DEALERSHIP_IMAGE: "/DeleteDealershipImage",
  DELETE_DEALERSHIP_VIDEO: "/DeleteDealershipVideo",
  GET_EQUIPMENT_TYPE_FILTER_LIST: "/GetEquipmentTypeFilterList",
  DELETE_DEALERSHIP_CERTIFICATE: "/DeleteDealershipCertificate",
  REMOVE_DEALERSHIP_FROM_MARKETPLACE: "/DeleteE9DsMarketplaceDetails",

  // My Leads
  GET_MY_ORDERS_REQUEST_SENT_LIST: "/GetMyOrdersRequestSentList/12/0",
  GET_MY_ORDERS_REQUEST_RECEIVED_LIST: "/GetMyOrdersRequestReceivedList/12/0",
  GET_RESPONSE_TYPE_DETAILS: "/GetResponseTypeDetails",
  GET_TRANSACTION_MODE_DETAILS: "/GetTransactionModeDetails",
  UPDATE_E9_MARKETPLACE_CONTACT_REDEEMED_DETAILS:
    "/UpdateE9MarketplaceContactRedeemedDetails",
  REVOKE_CONTACT: "/SendUnSendContactRequestForMarketPlaceAsset",
  GET_CONTACT_REQUEST_RECEIVED_LIST:
    "/GetContactRequestReceivedListForE9MarketPlaceAsset/12/0",
  GET_RENTAL_DURATION: "/GetRentalDurationByE9MarketPlaceId",
  GET_EQUIPMENT_AVAILABILITY: "/GetEquipmentAvailability",
  UPDATE_ACCEPT_REJECT_STATUS: "/UpdateE9MarketPlaceContactResponseStatus",
  UPDATE_ACCEPT_REJECT_REEDEM_STATUS:
    "/UpdateE9MarketplaceContactRedeemedDetails",
  GET_MYLEADS_DS_CONNECT_REQUEST_SENT_LIST:
    "/GetMyLeadDsConnectRequestSentList/10/0",
  GET_MYLEADS_DS_CONNECT_REQUEST_RECEIVED_LIST:
    "/GetMyLeadDsConnectRequestReceivedList/10/0",
  GET_MYLEADS_DS_CONNECT_RECEIVED_LIST:
    "/GetMyLeadDsConnectReceivedList/10/0?DealershipMpGuid=",
  CREATE_E9_DS_MARKETPLACE_DETAILS: "/CreateE9DsMarketplaceDetails",

  // MArketplace Dealership
  GET_E9_DEALERSHIP_MP_LIKE_LIST: "/GetE9DealershipMpLikeList",
  CREATE_E9_DS_MP_LIKE_DETAILS: "/CreateE9DsMpLikeDetails",

  /** Leads Api  */
  CREATE_E9_FREE_REDEEMED_TRANSACTION: "/CreateE9FreeRedeemedTransaction",
  CREATE_E9_SUBSCRIPTION_REDEEMED_TRANSACTION:
    "/CreateE9RedeemedTransactionDetails",

  /** NEAR ME JOBS  */

  /** E9 SERVICES */
  GET_E9_SERVICE_CATEGORY_DETAILS: "/GetE9ServiceCategoryDetails",
  GET_EQUIPMENT_NICK_NAME_LIST: "/GetEquipmentNickNameList",
  GET_ALL_EQUIPMENT_TYPE_LIST: "/GetAllEquipmentType",
  GET_ALL_EQUIPMENT_AGE_LIST: "/GetEquipmentAgeList",
  GET_EQUIPMENT_MODEL: "/Model",
  GET_SALARY_TYPE_LIST: "/GetSalaryTypeList",
  GET_PROVIDENT_FUND_LIST: "/GetProvidentFundList",
  GET_PAY_CYCLE_LIST: "/GetPayCycleList",
  GET_FOOD_BENEFIT_LIST: "/GetFoodBenifitsList",
  GET_ACCOMODATION_TYPE_LIST: "/GetAccomodationTypeList",
  GET_SHIFT_TYPE_LIST: "/GetShiftTypeList",
  GET_WORKING_HOUR_TYPE_LIST: "/GetWorkingHourTypeList",
  GET_LUNCH_BREAK_TYPE_LIST: "/GetLunchBreakTypeList",
  GET_JOB_DURATION_LIST: "/GetJobDurationList",
  GET_NATURE_OF_WORK_LIST: "/GetNatureOfWorkList",
  GET_TRAVEL_ALLOWANCE_LIST: "/GetTravelAllowanceList",
  GET_INSURANCE_TYPE_LIST: "/GetInsuranceTypeList",
  GET_MAINTENANCE_CATEGORY: "/GetMaintenanceCategory",
  GET_REPAIR_TYPE: "/RepairType",
  CREATE_OPERTOR_JOB_DETAILS: "/CreateOperatorJobDetails",
  CREATE_OPERATOR_JOB_SEARCH_FILTER: "/CreateOperatorJobSearchFilter",
  GET_EQUIPMENT_ENGINE_MAKER_LIST: "/GetEngineMakerList",
  GET_FUEL_TYPE_LIST: "/GetFuelTypeList",
  GET_AUTO_ENGINE_SHUTDOWN_LIST: "/GetEquipmentAutoEngineShutdownStatus",
  GET_ASSET_OWNERSHIP_TYPE_LIST: "/GetAssetOwnershipTypeList",
  CREATE_NEW_ASSET_DETAILS: "/CreateNewAssetDetails",
  GET_EQUIPMENT_MAPPING_DETAILS: "/GetEquipmentMappingDetails",
  CREATE_MAINTENANCE_JOB_DETAIL: "/CreateMaintenanceJobDetail",
  ADD_IMAGE_TO_MAINTENANCE_JOB_DETAIL: "/AddImageToMaintenanceJobDetail",
  IN_PROCESS_JOBS_LIST_FOR_OWNER: "/InProcessJobListForOwner",
  CREATE_ASSET_PURCHASING_DETAILS: "/CreateAssetPurchasingDetails",
  CREATE_ASSET_OTHER_PROPERTY_DETAILS: "/CreateAssetOtherPropertiesDetails",

  /** My Store*/
  GET_STORE_DETAILS: "/GetStoreDetails",
  GET_COMPANY_STAFF_DETAILS: "/GetCompanyStaffDetails",
  POST_STORE_DETAILS: "/PostStoreDetails",
  POST_STORE_MANAGER_DETAILS: "/PostStoreManagerDetails",

  // INdustry Requirement
  GET_REQUIREMENT_LIST: "/GetAllRequirementList",
  GET_INPUT_METHOD: "/GetInputMethod",
  GET_REQUIREMENT_CATEGORY: "/GetRequirementCategory",
  GET_REQUIREMENT_TIME_RANGE: "/GetRequirementtimeRange",
  GET_REQUIREMENT_FILTER_LIST: "/GetRequirementFilterList",
  GET_INDUSTRY_RECEIVED_ANALYTICS: "/GetRequirementRequestReceivedAnalytics",
  GET_INDUSTRY_ACCEPTED_ANALYTICS: "/GetRequirementRequestAcceptedAnalytics",
  GET_INDUSTRY_PENDING_ANALYTICS: "/GetRequirementRequestPendingAnalytics",
  GET_MY_REQUIREMENT_LIST: "/GetMyRequirementList",
  DELETE_MY_REQUIREMENT: "/DeleteRequirement",
  POST_REQUIREMENT_REPORT: "/PostRequirementReport",
  POST_YOUR_REQUIREMENT: "/PostYourRequirement",
  POST_REQUIREMENT_CATEGORY: "/PostRequirementCategory",
    CHECK_REQUIREMENT_CONNECT_LIST : "/CheckIfUserSendRequest",
    POST_REQUIREMENT_CONNECT : "/PostRequirementConnect",
    GET_REQUIREMENT_SENT_LIST :"/GetRequirementSentRequest",
    GET_REQUIREMENT_RECEIVED_LIST :"/GetRequirementReceivedRequest",
    GET_REQUIREMENT_REQUEST_BY_ID : "/GetRequirementReceivedRequestByRequirementDetailsId?RequirementDetailsId=",
    POST_UPDATE_REQUIREMENT_CONNECT_STATUS:"/UpdateRequirementConnectStatus",
    GET_INDUSTRY_LIKE_LIST_BY_ID:"/GetRequirementLiketByRequirementDetailsId?RequirementDetailsId=",
    POST_REQUIREMENT_IMAGE: "/PostRequirementImage",
    GET_REQUIREMENT_LIKE_LIST_BY_USER_ID : '/GetRequirementLikeListByUserId',

  /** API ENPOINTS END HERE */

  /** API BODY PARAMS START HERE */

  /** API BODY PARAMS END HERE */

  /**  BUSINESS ROLES */
  BUSINESS_OWNER: "business owner",
  EQUIPMENT_OWNER: "equipment owner",
  MAINTENCE_PATNER: "maintenance partner",
  OPERATOR_PATNER: "operator partner",

  /**USER TYPE ROLE */
  INDIVIDUAL: "individual",
  COMPANY: "company",

  /**OPERATOR PATNER */
  OPERATOR_PARTNER: "operator partner",

  /**API ENDPOINTS */
  UPDATE_PERSONAL_DETAIL: "/UpdatePersonalDetails",
  GET_REFERRED_TYPE: "/GetReferredType",
  GET_USER_DETAILS: "/GetUserDetails",
  CHANGE_PASSWORD: "/ChangePassword",
  GET_PRIVACY_OPTIONS_FOR_USER: "/GetPrivacyOptionsForUser",
  SET_USER_PRIVACY_SETTINGS: "/SetUserPrivacySettings",

  /**Community**/
  GET_COMMUNITY_DETAILS: "/GetCommunityDetails",
  ThumbnailImages: "ThumbnailImages/",
  CommunityImages: "CommunityImages/",
  POST_COMMUNITY_VIEWS: "/PostCommunityViews",
  POST_COMMUNITY_LIKES: "/PostCommunityLikes",
  GET_AUDIENCE: "/GetAudience",
  GET_CONTENT_TYPES: "/GetContentType",

  GET_E9_OWNER_MARKATPLACE_LIST: "/GetE9OwnerMarketplaceList",
  GET_FILTERED_E9_OWNER_MARKETPLACE_LIST: "/GetFilteredE9OwnerMarketplaceList",
  GET_ALL_USER_ROLES_LIST: "/GetAllUserRolesList",
  GET_COMMUNITY_DETAILS_BY_ID: "/GetCommunityDetailsById",
  DELETE_COMMUNITY_DETAILS: "/DeleteCommunityDetails",
  GET_THUMBNAIL_IMAGES: "/GetThumbnailImages",
  POST_COMMUNITY_DETAILS: "/PostCommunityDetails",
  POST_COMMUNITY_VIDEO: "/PostCommunityVideo",
  UPDATE_COMMUNITY_DETAILS: "/UpdateCommunityDetails",
  DELETE_COMMUNITY_VIDEO: "/DeleteCommunityVideo",
  CREATE_E9_OWNER_MARKETPLACE_CONNECT: "/CreateE9OwnerMarketplaceConnect",
  UPDATE_E9_OWNER_MARKETPLACE_CONNECT: "/UpdateE9OwnerMarketplaceConnect",
  GET_E9_OWNER_MP_PROFILE_DETAILS: "/GetE9OwnerMpProfileDetails",
  GET_OWNER_MP_EQUIP_DET_ANALYTICS: "/GetOwnerMpEquipDetAnalytics",
  GET_OWNER_MP_DEALARSHIP_DET_ANALITICS: "/GetOwnerMpDealershipDetAnalytics",
  GET_OWNER_MP_MATERIAL_ANALYTICS: "/GetOwnerMpMaterialsDetAnalytics",
  GET_COUNT_E9_OWNER_MARKETPLACE_LIST:"/GetCountE9OwnerMarketplaceList",
  GET_E9_OWNER_MP_CONNECT_STATUS_BY_ID: "/GetE9OwnerMpConnectStatusById",
  GET_E9_OWNER_MP_MY_LEADS_SENT_LIST : '/GetE9OwnerMpMyLeadsSentList/12/0',
  GET_E9_OWNER_MP_MY_LEADS_RECEIVED_LIST : '/GetE9OwnerMpMyLeadsReceivedList/10/0',
  UPDATE_E9_OWNERS_MARKETPLACE_CONNECT : "/UpdateE9OwnerMarketplaceConnect",

  //redeem
  POST_E9_PREMIUM_REDEEM: "/PostE9PremiumRedeem",
  GET_E9_OWNER_MP_CONNECT_STATUS_LIST:"/GetE9OwnerMpConnectStatusList",
  GET_PREMIUM_REDEEM_COUNT_BY_USERID_SERVICETYPEID:"/GetPremiumRedeemCountByUserIdServiceTypeId",
  POST_MATERIAL_TRANSACTION: "/PostMaterialTransaction",
  UPDATE_MATERIAL_TRANSACTION: "/UpdateMaterialTransaction",
  CREATE_E9_MARKET_PLACE_REDEEM_DETAILS: "/CreateE9MarketPlaceContactRedeemDetails",
  UPDATE_E9_MARKETPLACE_REDEEM_DETAILS: "/UpdateE9MarketplaceContactRedeemedDetails",

  //useLeads
  GET_USER_LEADS_CHECK:"/GetUserLeadsCheck",
  GET_PREMIUM_REDEEM_COUNT_BY_SERVICE_TYPE_ID: "/GetPremiumRedeemCountByServiceTypeId/",
  GET_USER_LEADS_CHECK_FOR_CONTACT_REDEEMED:"/GetUserLeadsCheckForContactRedeemed",
  GET_E9_FREE_REDEEMED_DETAILS_ON_CONTACT_REDEEMED:"/GetE9FreeRedeemedDetailsOnContactRedeemed",
  GET_PREMIUM_REDEEM_COUNT_BY_USER_ID_SERVICE_TYPE_ID:"/GetPremiumRedeemCountByUserIdServiceTypeId",

  //quiz
  GET_QUIZ_LIST :"/GetQuizList",
  GET_QUIZ_QUESTIONS_BY_QUIZ_ID:"/GetQuizQuestionsByQuizId",
  GET_QUIZ_ANSWERS_BY_QUIZ_QUEST_ID:"/GetQuizAnswersByQuizQuestId",
  UPDATE_USER_QUIZ_HISTORY_QUIZ_END_TIME: "/UpdateUserQuizHistoryQuizEndTime",
  CREATE_USER_QUIZ_RESPONSE_DETAILS: "/CreateUserQuizResponseDetails",
  GET_TERMS_AND_CONDITIONS_CMS_PAGE_DATA:"/TermsAndConditionsCmsPageData",
  GET_QUIZ_WINNERS_LIST_BY_QUIZ_ID: "/GetQuizWinnersListByQuizId",
  GET_USER_MY_QUIZZES_LIST: "/GetUserMyQuizzesList",
  CREATE_USER_QUIZ_HISTORY_DETAILS: "/CreateUserQuizHistoryDetails",


//share
  ENCRYPT_SHARE_DATA :'/share/encryptShareData',
  DECRYPT_SHARE_DATA :'/share/decryptShareData',
  

};
