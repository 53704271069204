import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../constants/initialState";

export const myStoreSlice = createSlice({
    name : "myStoreSlice",
    initialState : initialState,
    reducers:{
        openAddStorePopUp : (state) =>{
            state.isStorePopUpOpen = true ;
        },
        closeAddStorePopUp : (state) =>{
            state.isStorePopUpOpen = false ;
        },
        openAddManagerStorePopUp : (state) =>{
            state.isStoreManagerPopUpOpen = true ;
        },
        closeAddStoreManagerPopUp : (state) => {
            state.isStoreManagerPopUpOpen = false ;
        },
        setGetStoreDetails : (state, {payload}) => {
            state.storeData = payload ;
        },
        setStoreFlag : (state) => {
            state.storeFlag = true;
        },
        removeStoreFlag : (state) => {
            state.storeFlag = false;
        },
        setStoreId : (state, {payload}) => {
            state.storeId = payload ;
        },
    }
}) ;

export const {
    openAddStorePopUp,
    closeAddStorePopUp,
    openAddManagerStorePopUp,
    closeAddStoreManagerPopUp,
    setGetStoreDetails,
    setStoreId,
    setStoreFlag,
    removeStoreFlag,
} = myStoreSlice.actions