import { Box, Flex, Text } from "@chakra-ui/react";

export const PremiumTypeBadge = ({
  paymentPlan,
  price,
  paymentCycle,
  value = "",
  discountPrecentage = 0,
  isSelected = false,
  onClick = () => {},
}) => {
  return (
    <Box
      onClick={onClick}
      value={value}
      bgColor={isSelected ? "#FFFBF5" : "#FFFFFF"}
      border="1px solid #E3E5E8"
      position="relative"
      borderRadius="4px"
      boxShadow="0px 2px 4px -1px #0000002C"
      borderColor={isSelected ? "#F79C00" : "#E3E5E8"}
      cursor="pointer"
    >
      <Flex
        height="53px"
        width="271px"
        justifyContent="center"
        alignItems="center"
      >
        <Text fontWeight="600" color={isSelected ? "#F79C00" : "#141619"}>
          {paymentPlan}
        </Text>
      </Flex>
      {discountPrecentage && (
        <Box
          minW="65px"
          backgroundColor="#319F00"
          color="#FFF"
          borderBottomRightRadius="24px"
          padding="0.25rem"
          position="absolute"
          top="0"
        >
          <p style={{ fontSize: "12px" }}>
            <span
              style={{ fontWeight: "700" }}
            >{`${discountPrecentage}% `}</span>{" "}
            off
          </p>
        </Box>
      )}
      <Box
        onClick={onClick}
        value={value}
        height="25px"
        minW="105px"
        width="max-content"
        position="absolute"
        bottom="-10px"
        left="32%"
        right="50%"
        bgColor="#FFFFFF"
        border="1px solid #E3E5E8"
        zIndex="5"
        borderRadius="4px"
        padding="0.1rem 0.15rem"
        borderColor={isSelected ? "#F79C00" : "#E3E5E8"}
      >
        <Text fontWeight="600" color={isSelected ? "#F79C00" : "#141619"}>
          {price} / {paymentCycle}
        </Text>
      </Box>
    </Box>
  );
};
