import { Box, Button, Divider, Flex, Image, Text } from "@chakra-ui/react";
import Avatar from "../../../../assets/webapp/doc_flow/My-profile.png";

export const HeaderSection = ({
  iconUrl,
  title="",
  isIconNeeded=true,
  filteredDataRequired,
  countText="",
  count,
  onClick = () => {},
}) => {
  return (
    <>
      <Box
        position={"sticky"}
        top={"0px"}
        gap={"5px"}
        padding={"1em 52px 0"}
        zIndex={"1"}
        backgroundColor={"#ffff"}
      >
        <Flex
          w={"100%"}
          justifyContent={"space-between"}
          gap={"1em"}
          padding={"10px 0"}
          alignItems={"center"}
        >
          <Flex w={"100%"} justifyContent={"space-between"}>
            {" "}
            <Text fontSize={"24px"} fontWeight={"700"} color={"#1A1E21"}>
              {title}
            </Text>
            <Box>
            {isIconNeeded && <Image
                h="34px"
                w="34px"
                borderRadius="50%"
                border="1px solid #000000"
                src={
                  iconUrl
                    ? `${process.env.REACT_APP_CDN_PVT_URL}UserProfilePicture/${iconUrl}`
                    : Avatar
                }
              /> }
            </Box>
          </Flex>

          <Button
            variant={"outline"}
            rightIcon={
              <img
                src={
                  filteredDataRequired
                    ? `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/filter.svg`
                    : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/filter.svg`
                }
                alt="filter"
              />
            }
            color={filteredDataRequired ? "#F79C00" : "#002961"}
            fontWeight="700"
            border={
              filteredDataRequired ? "1px solid #F79C00" : "1px solid #002961"
            }
            backgroundColor="white"
            onClick={onClick}
          >
            {filteredDataRequired ? "Clear Filter(s)" : "Filter"}
          </Button>
        </Flex>

        <Flex w={"100%"} gap={"30px"}>
          <Flex
            gap={"5px"}
            color={"#4A5A82"}
            fontSize={"20px"}
            fontWeight={"700"}
          >
            <Text> {count}</Text>

            <Text w={"max-content"}>{countText}</Text>
          </Flex>

          <Flex alignItems="center" w="100%">
            <Divider borderColor="#002961" borderWidth="1px" />
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
