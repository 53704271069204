import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllCountries } from "../../../../services/masterData/masterData";

export const getCountriesThunk = createAsyncThunk(
    'countries/get',
    async (_, { rejectWithValue }) => {
      try {
        const data = await getAllCountries();
        return data
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );