import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateAcceptReject } from "../../../services/myLeads/myLeads";
import { updateContactReedemDetailsThunk } from "./updateContactReedemDetailsThunk";

export const updateContactStatusThunk = createAsyncThunk(
  "myLeads/updateContactStatus",
  async (payLoad, { dispatch, getState }) => {
    try {
      const { getRequestTypeData, transactionModeDetails } = getState().myLeads;
      const data = await updateAcceptReject(payLoad);
      const cretaePayload = async () => {
        let e9TransactionId = await transactionModeDetails.find(
          (id) =>
            id?.TransactionModeId === getRequestTypeData?.TransactionModeId
        );

        const payload = {
          E9MarketplaceContactRedeemedDetails: {
            E9MarketPlaceContactId: getRequestTypeData?.E9MarketPlaceContactId,
            ResponseTypeValue: getRequestTypeData?.ResponseTypeValue,
            TransactionModeId: getRequestTypeData?.TransactionModeId,
            ServiceTypeId: getRequestTypeData?.ServiceTypeId,
            SenderUserCredentialId: getRequestTypeData?.SenderUserCredentialId,
            E9TransactionId: e9TransactionId?.TransactionModeId,
            LeadPurchased: "",
            LeadRedeemed: 1,
            LeadRemaining: "",
            // "E9TransactionId": e9TransactionId?.TransactionModeId,
            // "LeadPurchased": selected_subType.PurchasedCount,
            // "LeadRedeemed": 1,
            // "LeadRemaining": selected_subType.PurchasedCount - redeemedNumber
          },
        };
        return payload;
      };
      if (data?.Status === 204) {
        const fetchData = async () => {
          const payload = await cretaePayload();
          dispatch(updateContactReedemDetailsThunk(payload));
        };
        await fetchData();
      }
      return data;
    } catch (error) {}
  }
);
