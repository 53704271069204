import {
  Flex,
  Grid,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import {
  createUserQuizResponseDetails,
  getQuizAnswersByQuizQuestId,
  getQuizQuestionsByQuizId,
  updateUserQuizHistoryQuizEndTime,
} from "../../../../services/quiz/quiz";
import { useDispatch, useSelector } from "react-redux";
import { ConvertSecondsToTime } from "../../helper/helper";
import {
  SetIsQuizSubmittedModalOpen,
  SetIsQuizTimesUpModalOpen,
  setSelectedAnswerData,
  setUserScore,
} from "../../slice/quizSlice";
import { QuizCompletionPopup } from "./QuizCompletionPopup";
import { useNavigate } from "react-router-dom";
import { QuizTimesUpPopup } from "./QuizTimesUpPopup";
import { V5FixedFooterSidebarLayout } from "../../../../layout/V5FixedFooterSidebarLayout/V5FixedFooterSidebarLayout";

export const QuizPage = () => {
  const { selectedQuiz, userScore, selectedAnswerData } = useSelector(
    (state) => state.quiz
  );

  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [quizAnswers, setQuizAnswers] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [quizTimer, setQuizTimer] = useState(selectedQuiz?.QuizTimer);

  const intervalRef = useRef(null);

    // Handle network connectivity issues
    useEffect(() => {
      const handleOnline = () => {
        toast({
          title: "Back online. Submitting quiz...",
          status: "info",
          position:'top-right',
          duration: 3000,
          isClosable: true,
        });
        postQuizAnswers();
        dispatch(SetIsQuizSubmittedModalOpen(true));
      };
  
      const handleOffline = () => {
        toast({
          title: "You are offline. Your quiz progress is saved locally.",
          status: "warning",
          position:'top-right',
          duration: 3000,
          isClosable: true,
        });
      };
  
      window.addEventListener("online", handleOnline);
      window.addEventListener("offline", handleOffline);
  
      return () => {
        window.removeEventListener("online", handleOnline);
        window.removeEventListener("offline", handleOffline);
      };
    }, [selectedAnswerData, quizQuestions]);

    //Tab change
    useEffect(() => {
      const handleVisibilityChange = () => {
          if (document.visibilityState === "hidden") {
            postQuizAnswers();
            dispatch(SetIsQuizSubmittedModalOpen(true));
          } 
      };
      document.addEventListener("visibilitychange", handleVisibilityChange);
      return () => {
          document.removeEventListener("visibilitychange", handleVisibilityChange);
      };
  }, [selectedAnswerData, quizQuestions]);

  useEffect(() => {
    if (!selectedQuiz) {
      navigate("/e9-quizzes");
    }
  }, [selectedQuiz]);

  useEffect(() => {
    if (selectedQuiz?.QuizId) {
      fetchQuizQuestions();
    }

    intervalRef.current = setInterval(() => {
      setQuizTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (quizQuestions?.length > 0) {
      fetchQuizAnswers();
    }
  }, [quizQuestions, currentQuestionIndex]);

  useEffect(() => {
    if (quizTimer === 0) {
      handleTimerEnd();
    }
  }, [quizTimer]);

  useEffect(() => {
    if (
      selectedAnswerData.length >= quizQuestions?.length &&
      quizQuestions?.length > 0
    ) {
      postQuizAnswers();
    }
  }, [selectedAnswerData, quizQuestions]);

  const fetchQuizQuestions = async () => {
    try {
      const data = await getQuizQuestionsByQuizId(selectedQuiz?.QuizId);
      if (data.Status === 200 && data.Data.TotalRecords > 0) {
        setQuizQuestions(data.Data.QuizQuestionsList);
      } else {
        toast({
          title: "No questions found",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error loading questions",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchQuizAnswers = async () => {
    try {
      const data = await getQuizAnswersByQuizQuestId(
        quizQuestions?.[currentQuestionIndex]?.QuizQuestionId
      );
      if (data.Status === 200 && data.Data.TotalRecords > 0) {
        setQuizAnswers(data.Data.QuizAnswers);
      } else {
        toast({
          title: "No Answers found",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error loading answers",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAnswerSelect = (answer) => {
    setSelectedAnswer(answer);
  };

  const handleNextQuestion = () => {
    if (selectedAnswer !== null) {
      if (selectedAnswer?.IsCorrectAnswer) {
        dispatch(
          setUserScore(
            userScore + quizQuestions?.[currentQuestionIndex]?.Points
          )
        );
      }

      dispatch(setSelectedAnswerData(selectedAnswer));

      if (currentQuestionIndex < quizQuestions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setSelectedAnswer(null);
      } else {
        clearInterval(intervalRef.current);
        // postQuizAnswers();
        dispatch(SetIsQuizSubmittedModalOpen(true));
      }
    } else {
      toast({
        title: "Please select an answer",
        status: "warning",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const handleTimerEnd = () => {
    clearInterval(intervalRef.current);
    // setQuizTimeUp(true);

    if (selectedAnswer) {
      if (selectedAnswer?.IsCorrectAnswer) {
        dispatch(
          setUserScore(
            userScore + quizQuestions?.[currentQuestionIndex]?.Points
          )
        );
      }
    }
    postQuizAnswers();
    dispatch(SetIsQuizTimesUpModalOpen(true));
  };

  const postQuizAnswers = async () => {
    handleUserQuizEndTimeWithSeconds();

    const data = await createUserQuizResponseDetails(selectedAnswerData);

    if (data?.Status === 201) {
      // toast({
      //   title: "Record Created",
      //   status: "success",
      //   duration: 3000,
      //   position: "top-right",
      //   isClosable: true,
      // });
    } else {
      toast({
        title: "Something went wrong",
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const handleUserQuizEndTimeWithSeconds = async () => {
    const payload = {
      QuizId: selectedQuiz?.QuizId,
      UserQuizPlayedSeconds: selectedQuiz?.QuizTimer - quizTimer,
    };

    const data = await updateUserQuizHistoryQuizEndTime(payload);

    if (data.Status === 204) {
      // toast({
      //   title: "UpdateUserQuizHistoryQuizEndTime Record Updated",
      //   status: "success",
      //   duration: 3000,
      //   position: "top-right",
      //   isClosable: true,
      // });
    } else {
      toast({
        title: "Something went wrong",
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  let convertedSecondsToTime = ConvertSecondsToTime(quizTimer);

  return (
    <>
      <QuizCompletionPopup />
      <QuizTimesUpPopup />
      <V5FixedFooterSidebarLayout
      primaryButtonWidth={"389px"}
      primaryButtonText={currentQuestionIndex + 1 === quizQuestions?.length
        ? "Submit"
        : "Next"}
      primaryButtonClickAction={handleNextQuestion}
      primaryButtonVariant="gradient"
      >
        <Flex direction={"column"} minH={"86vh"} padding={""}>
          <Flex
            flex={"1"}
            direction={"column"}
            margin={"0 52px 20px 52px"}
            gap={"1em"}
          >
            {/* Title section */}
            <Flex
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"6px"}
            >
              <Text fontSize={"24px"} fontWeight={"700"} color={"#1A1E21"}>
                {selectedQuiz?.QuizName}
              </Text>
              <Text fontSize={"18px"} fontWeight={"600"} color={"#767676"}>
                Sponsored By : {selectedQuiz?.SponsoredBy}
              </Text>
            </Flex>

            {/* quiz box */}
            {isLoading ? (
              <Flex flex={"1"} justifyContent={"center"} alignItems={"center"}>
                <Spinner />
              </Flex>
            ) : (
              <Flex
                flex={"1"}
                boxShadow={"0px 0px 4px 0px #00000040"}
                borderRadius={"6px"}
                padding={"20px"}
                gap={"1.5em"}
                direction={"column"}
                alignItems={"center"}
              >
                <Flex
                  justifyContent={"space-between"}
                  w={"100%"}
                  h={"max-content"}
                  fontSize={"24px"}
                  fontWeight={"700"}
                >
                  <Text color={"#F79C00"}>
                    Question {currentQuestionIndex + 1} of{" "}
                    {quizQuestions?.length}
                  </Text>
                  <Text color={"#767676"}>
                    {quizQuestions?.[currentQuestionIndex]?.Points} Points
                  </Text>
                </Flex>
                <Flex justifyContent={"center"}>
                  <Flex
                    borderRadius={"50%"}
                    border={"2px solid #4A5A82"}
                    padding={"0.5em"}
                    fontSize={"24px"}
                    fontWeight={"700"}
                    color={"#002961"}
                    minW={"72px"}
                    minH={"72px"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    {quizTimer > 0
                      ? convertedSecondsToTime.m +
                        ":" +
                        convertedSecondsToTime?.s
                      : "Times Up!"}
                  </Flex>
                </Flex>

                <Flex
                  direction={"column"}
                  gap={"60px"}
                  alignItems={"center"}
                  maxW={"950px"}
                  w={"100%"}
                >
                  <Text fontSize={"24px"} fontWeight={"700"} color={"#002961"}>
                    {quizQuestions?.[currentQuestionIndex]?.QuizQuestion}
                  </Text>
                  <Grid templateColumns="repeat(2, 1fr)" gap="30px" w={"100%"}>
                    {quizAnswers?.map((answer, index) => (
                      <Flex
                        key={index}
                        padding={"16px 28px"}
                        border={`1px solid ${
                          selectedAnswer === answer ? "#F79C00" : "#E3E5E8"
                        }`}
                        cursor={"pointer"}
                        borderRadius={"6px"}
                        backgroundColor={
                          selectedAnswer === answer ? "#FEF8EC" : ""
                        }
                        onClick={() => handleAnswerSelect(answer)}
                        alignItems={"center"}
                        gap={"5px"}
                      >
                        <Text
                          fontSize={"24px"}
                          fontWeight={"700"}
                          color={"#F79C00"}
                        >
                          {index + 1}.{" "}
                        </Text>
                        <Text
                          fontSize={"18px"}
                          fontWeight={"600"}
                          color={"#1A1E21"}
                        >
                          {answer?.QuizAnswerValue}
                        </Text>
                      </Flex>
                    ))}
                  </Grid>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </V5FixedFooterSidebarLayout>
    </>
  );
};
