import { createSlice } from "@reduxjs/toolkit";
import { sendForgetPasswordThunk } from "../thunk/forgotPasswordThunk";
import { getLocalStorageItems, setLocalStorageItems } from "../../../helpers/localStorage/localStorage";
import { STORAGE_CONSTANTS } from "../../../../constants/storageConstants";

export const forgotPasswordSlice = createSlice({
    name: "forgotPassword",
    initialState : {
        mobileNumber: getLocalStorageItems(STORAGE_CONSTANTS.MOBILE_NUMBER) || null,
        accountList : getLocalStorageItems(STORAGE_CONSTANTS.ACCOUNT_LIST) || null,
        selectedAccount: getLocalStorageItems(STORAGE_CONSTANTS.SELECTED_ACCOUNT) || null,
        newPassword: getLocalStorageItems(STORAGE_CONSTANTS.NEW_PASSWORD) ? getLocalStorageItems(STORAGE_CONSTANTS.NEW_PASSWORD) : null,
        isSelectAccountModelOpen : null,
        isContinueModalOpen: null,
        success: null,
        error: null,
        errorMessage: null,
        loading: null,
    },
    reducers : {
        setAccountList : (state, action) => {
            state.accountList = action.payload;
            setLocalStorageItems(STORAGE_CONSTANTS.ACCOUNT_LIST, action.payload)
        },
        setSelectedAccount : (state, action) => {
            state.selectedAccount = action.payload;
            setLocalStorageItems(STORAGE_CONSTANTS.SELECTED_ACCOUNT, action.payload)
        },
        setNewPassword: (state, action) => {
            state.newPassword = action.payload;
            setLocalStorageItems(STORAGE_CONSTANTS.NEW_PASSWORD, action.payload)
        },
        setMobileNumber : (state, action) => {
            state.mobileNumber = action.payload;
            setLocalStorageItems(STORAGE_CONSTANTS.MOBILE_NUMBER, action.payload)
        },
        setIsContinueModelOpen : (state, action) => {
            state.isContinueModalOpen = action.payload;
        },
        setIsPasswordChangedSuccessModelOpen : (state, action) => {
            state.isPasswordChangedSuccessModelOpen = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(sendForgetPasswordThunk.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.errorMessage= null;
            state.newPassword = null;
            state.selectedAccount = null;
            state.accountList = null;
            state.success = false;
            
        })
        .addCase(sendForgetPasswordThunk.fulfilled, (state, action) => {
            if (action.payload.accountList.length > 1) {
                state.accountList = action.payload.accountList;
                state.selectedAccount = null;
                setLocalStorageItems(STORAGE_CONSTANTS.ACCOUNT_LIST, action.payload.accountList);
                setLocalStorageItems(STORAGE_CONSTANTS.SELECTED_ACCOUNT,null)

              } else {
                state.accountList = action.payload.accountList;
                state.selectedAccount = action.payload.accountList[0];
                setLocalStorageItems(STORAGE_CONSTANTS.ACCOUNT_LIST, action.payload.accountList);
                setLocalStorageItems(STORAGE_CONSTANTS.SELECTED_ACCOUNT,action.payload.accountList[0])
              }
        })
    }

})
export const {setAccountList, setMobileNumber, setSelectedAccount, setNewPassword, setIsContinueModelOpen, setIsPasswordChangedSuccessModelOpen} = forgotPasswordSlice.actions

export default forgotPasswordSlice.reducer