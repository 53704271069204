import { createAsyncThunk } from "@reduxjs/toolkit";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";
import { getMaterialListById } from "../../../services/materials/materials";

export const myMaterialsInitialDataThunk = createAsyncThunk("Materials/getInitialCards", async(materialTypeId) => {

    try {
        const data= await getMaterialListById(convertToBase64(materialTypeId))
        return data;
    } catch (error) {
        throw(error);
    }
     
})