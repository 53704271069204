import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";

import { NewSideMenuLayout } from "../../../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { SelectCardWrapper } from "../../../../components/SelectCardWrapper/SelectCardWrapper";
import {
  resetCart,
  resetCheckoutDetails,
  setAddressModal,
  setSelectedSubcriptionType,
  setTranactionState,
} from "../../slice/subscriptionSlice";
import E9ListIcon from "../../../../../assets/webapp/subscription/e9-list-item-icon.svg";
import "./SubscriptionType.css";
import { ReactSVG } from "react-svg";
import {
  getAllSubscriptionForCountryForBusiness,
  getTransactionState,
} from "../../../../services/subscription/subscription";
import UpdateAdressModal from "./UpdateAdressModal";
import { V5FixedFooterSidebarLayout } from "../../../../layout/V5FixedFooterSidebarLayout/V5FixedFooterSidebarLayout";
import { setIsPremium, setPremiumId } from "../../../premium/premiumSlice";
import { getUserPremiumDetails } from "../../../../services/premium/premium";
import { getPremiumUserDetailsThunk } from "../../../premium/thunks/getPremiumUserDetailsThunk";

export const SubscriptionType = () => {
  const { defaultLanguage } = useSelector((state) => state.masterData);
  const { accountDetails } = useSelector((state) => state.user);
  const subscriptionData = useSelector((state) => state.subscription);
  const { isPremium, premiumId, premiumDaysRemainingToExpire } = useSelector((state) => state.premium);
  const [subscriptionTypes, setSubscriptionTypes] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const cardClickFunction = (id) => {
    const selectedId = subscriptionTypes.find(
      (subscriptionType) => subscriptionType.SUBSCRIPTION_ID === id
    );
    return dispatch(setSelectedSubcriptionType(selectedId));
  };

  const handlePremiumUserDetails = async () => {
    try {
      const data = await getUserPremiumDetails();
      dispatch(setIsPremium(data.Data.IsUserPremium));
      dispatch(setPremiumId(data.Data.PremiumUserId));
    } catch (error) {
      setIsPremium(false);
      setPremiumId("");
      // console.log(error)
    }
  };

  useEffect(() => {
    dispatch(resetCart());
    dispatch(resetCheckoutDetails());
    handlePremiumUserDetails();
  }, []);

  // useEffect to check address
  useEffect(() => {
    let address;
    if (accountDetails) {
      address = accountDetails.UserDetails.ResidenceAddress;
    }
    if (!address) {
      dispatch(setAddressModal(true));
    }
    // console.log(address);
  }, []);

  // get All Subscription Types
  useEffect(() => {
    (async () => {
      try {
        const data = await getAllSubscriptionForCountryForBusiness();

        if (data.Status) {
          setSubscriptionTypes(data.Data.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const data = await getTransactionState();

        if (data.Status) {
          dispatch(setTranactionState(data.Data.data));
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    if(isPremium){
      dispatch(getPremiumUserDetailsThunk())
    }
  }, [])

  const benefitsOfSubscription = (substriptionType) => {
    let benefits;
    switch (substriptionType) {
      case "Contact Based":
        benefits = [
          "Avail for Contacts",
          "Get Quick Connect",
          "E9 Verified Contacts",
          "Easy Payment Options",
        ];
        break;
      case "Dedicated Service":
        benefits = [
          "Dedicated E9 Manager",
          "Reduced Search Time",
          "Assured Deployment",
          "100% Refund*",
        ];
        break;
      default:
        benefits = [];
    }

    return benefits;
  };

  return (
    <V5FixedFooterSidebarLayout
      primaryButtonVariant="gradient"
      primaryButtonDisabled={!subscriptionData.selectedSubscriptionType}
      primaryButtonClickAction={() => {
        if (
          isPremium &&
          subscriptionData &&
          subscriptionData.selectedSubscriptionType &&
          (subscriptionData.selectedSubscriptionType?.SUBSCRIPTION).toLowerCase() ===
            "Premium".toLowerCase() && premiumDaysRemainingToExpire > 7
        ) {
          return toast({
            title: "You are already a premium member",
            position: "top-right",
            status: "error",
          });
        }
        subscriptionData &&
        subscriptionData.selectedSubscriptionType &&
        (subscriptionData.selectedSubscriptionType?.SUBSCRIPTION).toLowerCase() ===
          "Premium".toLowerCase()
          ? navigate("/subscription/buy-premium")
          : navigate(
              `/subscription-category/${subscriptionData.selectedSubscriptionType?.SUBSCRIPTION_ID}`
            );
      }}
      primaryButtonText={defaultLanguage?.Next}
      primaryButtonMaxWidth="347px"
      primaryButtonWidth="100%"
    >
      <UpdateAdressModal isOpen={subscriptionData?.addressModal} />
      <div
        style={{
          margin: "0px 52px",
          backgroundColor: "#92929204",
          paddingTop: "1em",
        }}
      >
        <div style={{ marginBottom: "32px" }}>
          <h1 style={{ fontSize: "32px", fontWeight: 700 }}>
            {defaultLanguage?.choose_your_subscription_plan}
          </h1>
          <p style={{ fontSize: "22px", color: "#2c2c2c80" }}>
            We have {subscriptionTypes ? subscriptionTypes.length : 0}{" "}
            {subscriptionTypes && subscriptionTypes.length > 1
              ? "categories "
              : "category "}{" "}
            as listed below
          </p>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "0.75em" }}>
          {subscriptionTypes && subscriptionTypes.length > 0 ? (
            subscriptionTypes.map((subscription, index) => {
              const {
                SUBSCRIPTION_ID,
                SUBSCRIPTION,
                Description,
                SubscriptionFeatureData,
              } = subscription;
              // return SUBSCRIPTION.toLowerCase() !== "Premium".toLowerCase() ? (
              return (
                <SelectCardWrapper
                  maxWidth="460px"
                  width="100%"
                  isSelected={
                    subscriptionData.selectedSubscriptionType
                      ?.SUBSCRIPTION_ID === SUBSCRIPTION_ID
                  }
                  value={SUBSCRIPTION_ID}
                  key={uuid()}
                  onClick={() => {
                    cardClickFunction(SUBSCRIPTION_ID);
                  }}
                >
                  <div className="subscription-type-card">
                    <p className="subscription-type-card__text subscription-type-card__text--uppercase">{`Plan ${
                      index + 1
                    }`}</p>
                    <h1
                      className={`subscription-type-card__heading ${
                        subscriptionData.selectedSubscriptionType
                          ?.SUBSCRIPTION_ID === SUBSCRIPTION_ID
                          ? "subscription-type-card__heading--selected"
                          : ""
                      }`}
                    >
                      {SUBSCRIPTION}
                    </h1>
                    <p className="subscription-type-card__text">
                      {SUBSCRIPTION.toLowerCase() !== "Premium".toLowerCase()
                        ? "Best for a small agency or Person"
                        : Description}
                    </p>
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <ul
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {SUBSCRIPTION.toLowerCase() === "Premium".toLowerCase()
                        ? SubscriptionFeatureData.map((feature) => (
                            <li key={uuid()} style={{ fontWeight: 600 }}>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "0.35em",
                                  alignItems: "center",
                                }}
                              >
                                <ReactSVG src={E9ListIcon} />{" "}
                                {feature.SubscriptionFeature
                                  ? feature.SubscriptionFeature
                                  : "N.A."}
                              </div>
                            </li>
                          ))
                        : benefitsOfSubscription(SUBSCRIPTION).map(
                            (benefit) => (
                              <li key={uuid()} style={{ fontWeight: 600 }}>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "0.35em",
                                    alignItems: "center",
                                  }}
                                >
                                  <ReactSVG src={E9ListIcon} /> {benefit}
                                </div>
                              </li>
                            )
                          )}
                    </ul>
                  </div>
                </SelectCardWrapper>
              );
            })
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxHeight: "78px",
        }}
      >
        {/* <Button
          variant="gradient"
          w="347px"
          isDisabled={!subscriptionData.selectedSubscriptionType}
          onClick={() => {
            navigate(
              `/subscription-category/${subscriptionData.selectedSubscriptionType?.SUBSCRIPTION_ID}`
            );
          }}
        >
          {defaultLanguage?.Next}
        </Button> */}
      </div>
    </V5FixedFooterSidebarLayout>
  );
};
