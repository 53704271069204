import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../constants/initialState";
import { communityInitialPostThunk } from "../thunks/communityInitialPostThunk";
import { communityLoadMorePostThunk } from "../thunks/communityLoadMorePostThunk";
import { myContentPostsThunk } from "../thunks/myContentPostsThunk";

export const communitySlice = createSlice({
  name: "community",
  initialState: initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    filterMyContent: (state, action) => {
      state.myContentDataFiltered = state.myContentData.filter(
        (item) => item.ContentTypeId === action.payload
      );
    },
    deleteMyContent: (state, action) => {
      state.myContentDataFiltered = state.myContentDataFiltered.filter(
        (item) => item.CommunityId !== action.payload
      );
    },
    setEditModalOpen: (state, action) => {
      state.isEditModalOpen = action.payload;
    },
    setDeleteModalOpen: (state, action) => {
      state.isDeleteModalOpen = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(communityInitialPostThunk.pending, (state) => {
        state.data = null;
        state.initialLoading = true;
        state.paginationLoading = true;
        state.error = false;
        state.errorMessage = false;
        state.paginationCount = 0;
        state.endOfData = false;
      })
      .addCase(communityInitialPostThunk.fulfilled, (state, action) => {
        state.data = action.payload?.Data?.CommunityDetails;
        state.initialLoading = false;
        state.paginationLoading = false;
        state.paginationCount = 1;
        state.error = false;
        state.errorMessage = null;
        if (action.payload?.Data?.CommunityDetails?.length < state.cardCount) {
          state.endOfData = true;
        }
      })
      .addCase(communityInitialPostThunk.rejected, (state, action) => {
        state.data = null;
        state.initialLoading = false;
        state.paginationLoading = false;
        state.error = true;
        state.errorMessage = action.payload;
        state.paginationCount = 0;
        state.endOfData = true;
      })
      .addCase(communityLoadMorePostThunk.pending, (state) => {
        state.paginationLoading = true;
        state.error = false;
        state.errorMessage = null;
      })
      .addCase(communityLoadMorePostThunk.fulfilled, (state, action) => {
        state.error = false;
        state.errorMessage = null;
        state.paginationLoading = false;
        state.data = [...state.data, ...action.payload?.Data?.CommunityDetails];
        if (action.payload?.Data?.CommunityDetails?.length >= state.cardCount) {
          state.paginationCount += 1;
        } else {
          state.endOfData = true;
        }
      })
      .addCase(myContentPostsThunk.pending, (state) => {
        state.error = false;
        state.errorMessage = false;
        state.myContentData = null;
      })
      .addCase(myContentPostsThunk.fulfilled, (state, action) => {
        state.myContentData = action.payload?.Data?.CommunityDetails;
        state.myContentDataFiltered = state.myContentData.filter(
          (item) => item.ContentTypeId === 1
        );
        state.error = false;
        state.errorMessage = false;
      })
      .addCase(myContentPostsThunk.rejected, (state, action) => {
        state.error = true;
        state.errorMessage = action.payload;
        state.myContentData = null;
      });
  },
});

export const {
  setData,
  filterMyContent,
  deleteMyContent,
  setEditModalOpen,
  setDeleteModalOpen,
} = communitySlice.actions;
