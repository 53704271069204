import { Flex, Text } from "@chakra-ui/react"
import Equip9Loader from "../../../../assets/loader/loader.png"
import "./BrandLoader.css"
export const BrandLoader = ({description = "Please wait and don't close the tab"}) => {
    return(
        <Flex flexDirection="column" gap="1.5em" justifyContent="center" alignItems="center" height="100vh" width="100vw">
            <div>
            <img style={{maxHeight:"200px", maxWidth: "200px"}} src={Equip9Loader} className="rotate"/>
            </div>
            <Text color = "#2c2c2c" fontSize="22px"> Loading ...</Text>
            <Text color = "#2c2c2c90" fontSize="22px">{description}</Text>
        </Flex>
    )
}