import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMaterialConnectsCountById } from "../../../services/materials/materials";


export const getMaterialConnectsCountByIdThunk=createAsyncThunk("my-material/get-connects-count",async (materialTypeId)=>{
    try {
        const data= await getMaterialConnectsCountById(materialTypeId);
        return data;
    } catch (error) {
        throw(error);
    }

})