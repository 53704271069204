import { Field, Formik } from "formik";
import { Flex, Accordion, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";

import { AccordianElement } from "../../../components/AccordianElement/AccordianElement";
import { FormikInput } from "../../../components/Input/FormikInput";
import { FormikDropdown } from "../../../components/Dropdown/FormikDropdown";
import { RadioParent } from "../../../components/RadioParent/RadioParent";
import { getCitiesById } from "../../../services/cities/cities";
import { getStatesById } from "../../../services/states/states";
import { getInsuranceTypes } from "../../../services/insurances/insurances";
import {
  getEquipmentMakesById,
  getEquipmentModelById,
  getEquipmentTypes,
} from "../../../services/equipments/equipments";
import {
  getAutoEngineShutDownTypes,
  getEngineMakes,
  getFuelTypes,
} from "../../../services/engines/engines";
import { createNewAssetDetailsThunk } from "../thunks/createNewAssetDetailsThunk";
import {
  increaseStepperIndex,
  resetEquipmentListingSlice,
  setAutoEngineShutdown,
  setChassisNumber,
  setCity,
  setCountry,
  setCreatedAssetId,
  setEngineMake,
  setEquipmentLocation,
  setEquipmentMake,
  setEquipmentModel,
  setEquipmentNickname,
  setEquipmentType,
  setFuelType,
  setInsuranceActiveDate,
  setIsInsuranceActive,
  setIsLoading,
  setManufacturingYear,
  setMeterReading,
  setOtherMake,
  setOtherModel,
  setPincode,
  setState,
} from "../slice/equipmentListingSlice";
import { useNavigate } from "react-router-dom";
import { registerNumbersOnly } from "../../../../utils/form/form";
import { updateNewAssetDetailsThunk } from "../thunks/updateNewAssetDetailsThunk";
import { getUserAssets } from "../../../services/userAssets/userAssets";

export const EquipmentDetailsForm = ({ editMode = false }) => {
  const [equipmentTypesList, setEquipmentTypeList] = useState(null);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null);
  const [equipmentMakeList, setEquipmentMakeList] = useState(null);
  const [selectedMake, setSelectedMake] = useState(null);
  const [equipmentModelList, setEquipmentModelList] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedCounty, setSelectedCountry] = useState(null);
  const [stateList, setStateList] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [cityList, setCityList] = useState(null);
  const [insuranceList, setInsuranceList] = useState(null);
  const [selectedInsuranceType, setSelectedinsuranceType] = useState(null);
  const [engineMakeList, setEngineMakeList] = useState(null);
  const [engineTypeList, setEngineTypeList] = useState(null);
  const [autoShutdownList, setAutoShutdownList] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [editLoading, setEditLoading] = useState(false);
  const { Countries } = useSelector((state) => state.masterData);
  const dispatch = useDispatch();
  const toast = useToast();
  const {
    equipmentNickname,
    equipmentType,
    equipmentMake,
    otherMake,
    equipmentModel,
    otherModel,
    manufacturingYear,
    meterReading,
    equipmentLocation,
    country,
    state,
    city,
    pincode,
    isInsuranceActive,
    insuranceActiveDate,
    engineMake,
    chassisNumber,
    fuelType,
    autoEngineShutdown,
    engineName,
    countryName,
    stateName,
    cityName,
  } = useSelector((state) => state.addEquipment);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);

  const getEquipmentTypeList = async () => {
    try {
      const data = await getEquipmentTypes();
      setEquipmentTypeList(data);
      return data;
    } catch (error) {
      setEquipmentTypeList(null);
      return null;
    }
  };

  const getEquipmentMakeList = async (id) => {
    try {
      const data = await getEquipmentMakesById(id);
      setEquipmentMakeList(data);
      return data;
    } catch (error) {
      // toast({
      //   title: error.message,
      //   position: "top-right",
      //   isClosable: true,
      //   status: "error"
      // })
      setEquipmentMakeList(null);
      return null;
    }
  };

  const getEquipmentModelList = async (id) => {
    // debugger
    try {
      const data = await getEquipmentModelById(id);
      setEquipmentModelList(data);
      return data;
    } catch (error) {
      toast({
        title: error.message,
        position: "top-right",
        isClosable: true,
        status: "error",
      });
      setEquipmentModelList(null);
      return null;
    }
  };
  const getStatesList = async (id) => {
    try {
      const data = await getStatesById(id);
      setStateList(data);
      return data;
    } catch (error) {
      return null;
    }
  };
  const getCityList = async (id) => {
    try {
      const data = await getCitiesById(id);
      setCityList(data);
      return data;
    } catch (error) {
      return null;
    }
  };
  const getInsurancesList = async () => {
    try {
      const data = await getInsuranceTypes();
      setInsuranceList(data);
      return data;
    } catch (error) {
      setInsuranceList(null);
      return null;
    }
  };
  const getEngineMakeList = async () => {
    try {
      const data = await getEngineMakes();
      setEngineMakeList(data);
      return data;
    } catch (error) {
      setEngineMakeList(null);
      return null;
    }
  };
  const getEngineTypeList = async () => {
    try {
      const data = await getFuelTypes();
      setEngineTypeList(data);
      return data;
    } catch (error) {
      setEngineTypeList(null);
      return null;
    }
  };
  const getAutoShutdownList = async () => {
    try {
      const data = await getAutoEngineShutDownTypes();
      setAutoShutdownList(data);
      return data;
    } catch (error) {
      setAutoShutdownList(null);
      return null;
    }
  };

  const getCurrentYear = () => {
    const date = new Date();
    setCurrentYear(date.getFullYear());
    return date.getFullYear();
  };

  const createNewAsset = async () => {
    try {
      const data = await dispatch(createNewAssetDetailsThunk()).unwrap();
      return data;
    } catch (error) {
      const data = await getUserAssetsList();
      const commonAsset = data.data.find(
        ({ AssetNickName }) => AssetNickName === equipmentNickname
      );
      if (commonAsset) {
        error.message = "Please enter unique Nickname.";
      }
      toast({
        title: `${error.message}`,
        isClosable: true,
        position: "top-right",
        status: "error",
      });
      throw error;
    }
  };

  const updateNewAsset = async () => {
    try {
      // const requiredId =
      const data = await dispatch(
        updateNewAssetDetailsThunk(queryParams.get("assetId"))
      ).unwrap();
      // console.log(data);
      return { ...data, id: queryParams.get("assetId") };
    } catch (error) {
    }
  };
  const submitForm = async () => {
    try {
      let data;
      if (editMode) {
        data = await updateNewAsset();
      } else {
        data = await createNewAsset();
        // console.log(data)
      }
      return data;
    } catch (error) {
      throw error;
    }
  };

  const getEquipmentExistingDetailsForEdit = async () => {
    setEditLoading(true);
    try {
      setSelectedEquipmentType(equipmentType);
      const equipmentMakes = await getEquipmentMakeList(equipmentType);
      // console.log(equipmentMakes);
      const existingMake = equipmentMakes.find(({ id }) => {
        return parseInt(id) === parseInt(equipmentMake);
      });
      setSelectedMake(existingMake);
      const equipmentModels = await getEquipmentModelList(existingMake.id);
      if (equipmentModels) {
        const existingModel = equipmentModels.map(
          ({ id }) => parseInt(id) === parseInt(equipmentModel)
        );
        setSelectedModel(existingModel);
      }
    } catch (error) {
    }
    setEditLoading(false);
  };
  const getEquipmentEngineDetails = async (engineName) => {
    try {
      const data = await getEngineMakeList();
      const requiredEngineMake = data.find(({ value }) => engineName === value);
      dispatch(setEngineMake(requiredEngineMake.id));
    } catch (error) {}
  };

  const getUserAssetsList = async () => {
    try {
      const data = await getUserAssets(12, 1);
      return data;
    } catch (error) {
    }
  };
  useEffect(() => {
    const initialApiCall = async () => {
      await getEquipmentTypeList();
      await getInsurancesList();
      await getEngineTypeList();
      await getEngineMakeList();
      await getAutoShutdownList();
      getCurrentYear();
    };
    initialApiCall();
  }, []);
  useEffect(() => {
    if (equipmentType) {
      setSelectedEquipmentType(parseInt(equipmentType));
    } else {
      setSelectedEquipmentType(null);
    }
  }, [equipmentType]);

  useEffect(() => {
    const apiCall = async (id) => {
      await getEquipmentMakeList(id);
    };
    if (selectedEquipmentType) {
      apiCall(selectedEquipmentType);
    }
  }, [selectedEquipmentType]);

  useEffect(() => {
    const apiCall = async (id) => {
      await getEquipmentModelList(id);
    };
    if (selectedMake) {
      apiCall(selectedMake.id);
    }
  }, [selectedMake]);

  useEffect(() => {
    if (selectedCounty) {
      getStatesList(selectedCounty);
    } else {
      setStateList(null);
    }
  }, [selectedCounty]);

  useEffect(() => {
    if (selectedState) {
      getCityList(selectedState);
    } else {
      setCityList(null);
    }
  }, [selectedState]);
  // check if url is edit or create
  useEffect(() => {
    if (queryParams.get("mode")) {
      if (queryParams.get("mode") === "edit") {
        // console.log(queryParams.get("mode"))
        // console.log(equipmentMake)
        // console.log(equipmentModel)
        getEquipmentExistingDetailsForEdit();
      } else {
        dispatch(resetEquipmentListingSlice());
        navigate("/e9-services/equipment-listing");
      }
    }
  }, [equipmentType]);

  // for edit get Country

  useEffect(() => {
    const getExistingCountry = async (countryName) => {
      try {
        const data = Countries.find(
          ({ CountryName }) => CountryName === countryName
        );
        if (data) {
          setSelectedCountry(data.CountryId);
          dispatch(setCountry(data.CountryId));
        }
      } catch (error) {}
    };

    if (editMode && countryName) {
      getExistingCountry(countryName);
    }
  }, [countryName]);

  // for existing State
  useEffect(() => {
    const getExistingState = async (stateName) => {
      try {
        const data = await getStatesById(selectedCounty);
        const requriedState = data.find(({ value }) => value === stateName);
        if (requriedState) {
          setSelectedState(requriedState.id);
          dispatch(setState(requriedState.id));
        }
      } catch (error) {}
    };

    if (editMode && selectedCounty && stateName) {
      getExistingState(stateName);
    }
  }, [stateName, selectedCounty]);

  useEffect(() => {
    const getExistingCity = async (cityName) => {
      try {
        const data = await getCitiesById(selectedState);
        const requriedCity = data.find(({ value }) => value === cityName);
        if (requriedCity) {
          //  setSelectedState(requriedState.id);
          dispatch(setCity(requriedCity.id));
        }
      } catch (error) {}
    };

    if (editMode && selectedCounty && stateName && cityName) {
      getExistingCity(cityName);
    }
  }, [cityName, selectedState]);

  // for existing engine name
  useEffect(() => {
    if (engineName && editMode) {
      getEquipmentEngineDetails(engineName);
    }
  }, [engineName]);

  // get existing details and then update form

  // useEffect(() => {

  // }, [])

  // Insurance type
  useEffect(() => {
    if (!isNaN(parseInt(isInsuranceActive)) && insuranceList) {
      const requiredValue = insuranceList.find(
        ({ id }) => parseInt(isInsuranceActive) === parseInt(id)
      );
      setSelectedinsuranceType(requiredValue);
    }
  }, [isInsuranceActive, insuranceList]);

  return (
    <Formik
      initialValues={{
        _equipmentNickname: equipmentNickname || "",
        _equipmentType: equipmentType || "",
        _equipmentMake: equipmentMake || "",
        _otherMake: otherMake || "",
        _equipmentModel: equipmentModel || "",
        _otherModel: otherModel || "",
        _manufacturingYear: manufacturingYear
          ? parseInt(manufacturingYear)
          : "",
        _meterReading: parseInt(meterReading) || "",
        _equipmentLocation: equipmentLocation || "",
        _country: country || "",
        _state: state || "",
        _city: city || "",
        _pincode: pincode || "",
        _insuranceActive: isInsuranceActive || "",
        _insuranceVaildityDate: insuranceActiveDate || "",
        _engineMake: engineMake || "",
        _chassisNumber: chassisNumber || "",
        _engineType: fuelType || "",
        _autoShutdown: autoEngineShutdown || "",
      }}
      validationSchema={
        // other make , other Model, insurance date left
        Yup.object().shape({
          _equipmentNickname: Yup.string().required(
            "Equipment Nickname is required."
          ),
          _equipmentType: Yup.string().required("Equipment Type is required."),
          _equipmentMake: Yup.string().required("Equipment Make is required."),
          _equipmentModel: Yup.string().required(
            "Equipment Model is required."
          ),
          _manufacturingYear: Yup.number()
            .required("Manufacturing Year is required.")
            .max(
              currentYear ? currentYear : 0,
              "Manufacturing year has to be less than current year"
            )
            .min(
              currentYear ? currentYear - 20 : 0,
              "Please enter equipment not older than 20 years"
            ),
          _equipmentLocation: Yup.string().max(
            300,
            "Location must not have more than 300 character(s)."
          ),
          _country: Yup.string().required("Country is required."),
          _state: Yup.string().required("State is required."),
          _city: Yup.string().required("City is required."),
          _pincode: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(6, "Pincode must be exactly 6 characters")
            .max(6, "Pincode must be exactly 6 characters"),
          _insuranceActive: Yup.number().required(
            "is Active must be selected."
          ),

          _meterReading: Yup.number().min(
            1,
            "Meter reading should be greater than zero."
          ),
          // _engineMake: Yup.string().required("Equipment Make is required."),
          // _chassisNumber: Yup.string().required("Chassis Number is required").min(5, "Chassis number must have atleast 5 values").max(16, "Chassis number can have 16 values at max."),
          // _engineType: Yup.string().required("Engine Type is required"),
          // _autoShutdown: Yup.string().required("Auto shutdown value must be selected.")
        })
      }
      onSubmit={async (values) => {
        // other make , other Model, insurance date left

        dispatch(setEquipmentNickname(values._equipmentNickname));
        dispatch(setEquipmentType(values._equipmentType));
        dispatch(setEquipmentMake(values._equipmentMake));
        dispatch(setEquipmentModel(values._equipmentModel));
        dispatch(setManufacturingYear(values._manufacturingYear));
        dispatch(setMeterReading(values._meterReading));
        dispatch(setEquipmentLocation(values._equipmentLocation));
        dispatch(setCountry(values._country));
        dispatch(setState(values._state));
        dispatch(setCity(values._city));
        dispatch(setPincode(values._pincode));
        dispatch(setIsInsuranceActive(values._insuranceActive));
        dispatch(setEngineMake(values._engineMake));
        dispatch(setChassisNumber(values._chassisNumber));
        dispatch(setFuelType(values._engineType));
        dispatch(setAutoEngineShutdown(values._autoShutdown));
        if (values._meterReading) {
          dispatch(setMeterReading(values._meterReading));
        } else {
          dispatch(setMeterReading(0));
        }
        if (values._otherMake) {
          dispatch(setOtherMake(values._otherMake));
        }
        if(values._insuranceVaildityDate){
          dispatch(setInsuranceActiveDate(values._insuranceVaildityDate))
        }
        if (values._otherModel) {
          dispatch(setOtherModel(values._otherModel));
        }
        try {
          dispatch(setIsLoading(true));
          const data = await submitForm();
          // console.log(data)

          dispatch(setCreatedAssetId(data.id));
          toast({
            title: data.message,
            status: "success",
            position: "top-right",
            isClosable: true,
          });
          dispatch(increaseStepperIndex());
        } catch (error) {
        }
        finally{
          dispatch(setIsLoading(false));
        }
      }}
      enableReinitialize
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <form id="equipment-details-form" onSubmit={handleSubmit}>
          <Accordion allowMultiple>
            <Flex flexDirection="column" gap="1em">
              <AccordianElement title="Equipment Details">
                <Flex wrap="wrap" justifyContent="space-between" rowGap="2em">
                  <FormikInput
                    maxWidth="450px"
                    label="Equipment Nickname"
                    isRequired={true}
                    name="_equipmentNickname"
                    placeholder="Please enter equipment's nickname"
                    maxLength={16}
                  />
                  <FormikDropdown
                    name="_equipmentType"
                    label="Equipment Type"
                    value={parseInt(values._equipmentType)}
                    defaultValue={
                      values._equipmentType
                        ? parseInt(values._equipmentType)
                        : ""
                    }
                    isRequired={true}
                    isDisabled={!equipmentTypesList}
                    onChange={(e) => {
                      const selectedValue = parseInt(e.target.value);
                      setFieldValue("_equipmentType", selectedValue);
                      setFieldValue("_equipmentMake", "");
                      setFieldValue("_equipmentModel", "");
                      setFieldValue("_otherMake", "");
                      setFieldValue("_otherModel", "");
                      setSelectedEquipmentType(selectedValue);
                      setSelectedMake(null);
                      setSelectedModel(null);
                    }}
                    maxWidth="450px"
                  >
                    {equipmentTypesList ? (
                      equipmentTypesList.map(({ id, value }) => (
                        <option key={uuid()} value={id}>
                          {value}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                  </FormikDropdown>
                  <FormikDropdown
                    name="_equipmentMake"
                    label="Equipment Make"
                    value={values._equipmentMake}
                    defaultValue={parseInt(values._equipmentMake)}
                    isRequired={true}
                    isDisabled={!equipmentMakeList}
                    onChange={(e) => {
                      if (e.target.value) {
                        const selectedValue = equipmentMakeList.find(
                          ({ id }) => parseInt(id) === parseInt(e.target.value)
                        );
                        setFieldValue("_equipmentMake", e.target.value);
                        setFieldValue("_equipmentModel", "");
                        setFieldValue("_otherModel", "");
                        setSelectedModel(null);
                        setSelectedMake(selectedValue);
                      } else {
                        setFieldValue("_equipmentMake", "");
                        setFieldValue("_equipmentModel", "");
                        setSelectedMake(null);
                      }
                    }}
                    maxWidth="450px"
                  >
                    {equipmentMakeList &&
                      equipmentMakeList.map((make) => (
                        <option key={uuid()} value={make.id}>
                          {make.value}
                        </option>
                      ))}
                  </FormikDropdown>

                  {selectedMake &&
                  (selectedMake.value.toLowerCase() ===
                    "Others".toLowerCase() ||
                    selectedMake.value.toLowerCase() ===
                      "Other".toLowerCase()) ? (
                    <FormikInput
                      name="_otherMake"
                      label="Equipment (Other) Make"
                      placeholder="Please enter equipment make"
                      maxLength={50}
                      isRequired={true}
                      maxWidth="450px"
                    />
                  ) : (
                    <></>
                  )}

                  <FormikDropdown
                    name="_equipmentModel"
                    label="Equipment Model"
                    value={values._equipmentModel}
                    isRequired={true}
                    isDisabled={!equipmentModelList}
                    onChange={(e) => {
                      if (e.target.value) {
                        const selectedValue = equipmentMakeList.find(
                          ({ id }) => parseInt(id) === parseInt(e.target.value)
                        );
                        setSelectedModel(selectedValue);
                        setFieldValue("_equipmentModel", e.target.value);
                      } else {
                        setSelectedModel(null);
                        setFieldValue("_equipmentModel", "");
                      }
                    }}
                    maxWidth="450px"
                  >
                    {equipmentModelList &&
                      equipmentModelList.map((model) => (
                        <option key={uuid()} value={model.id}>
                          {model.value}
                        </option>
                      ))}
                  </FormikDropdown>
                  {selectedMake &&
                  (selectedMake.value.toLowerCase() ===
                    "Others".toLowerCase() ||
                    selectedMake.value.toLowerCase() ===
                      "Other".toLowerCase()) ? (
                    <FormikInput
                      name="_otherModel"
                      label="Equipment (Other) Model"
                      placeholder="Please enter equipment model"
                      maxLength={50}
                      isRequired={true}
                      maxWidth="450px"
                    />
                  ) : (
                    <></>
                  )}
                  <FormikInput
                    maxWidth="450px"
                    name="_manufacturingYear"
                    label="Manufacturing Year"
                    placeholder="Please enter manufacturing year"
                    isRequired={true}
                    maxLength={4}
                  />
                  <FormikInput
                    maxWidth="450px"
                    name="_meterReading"
                    label="Meter Reading / Hours of Working"
                    placeholder="Please enter current meter reading"
                    maxLength={7}
                    isRequired={false}
                    onKeyDown={registerNumbersOnly}
                  />
                </Flex>
              </AccordianElement>
              <AccordianElement title="Equipment Location">
                <Flex wrap="wrap" justifyContent="space-between" rowGap="2em">
                  <FormikInput
                    name="_equipmentLocation"
                    label="Location"
                    placeholder="Enter equipment location"
                    isRequired={false}
                    maxWidth="800px"
                    maxLength={300}
                  />
                  <FormikDropdown
                    name="_country"
                    value={values._country}
                    label="Select Country"
                    maxWidth="450px"
                    onChange={(e) => {
                      if (e.target.value) {
                        setFieldValue("_country", e.target.value);
                        setSelectedCountry(e.target.value);
                      } else {
                        setFieldValue("_country", "");
                        setSelectedCountry(null);
                      }
                      setFieldValue("_state", "");
                      setFieldValue("_city", "");
                      setFieldValue("_pincode", "");
                      setSelectedState(null);
                      setCityList(null);
                    }}
                  >
                    {Countries && Countries.length > 0 ? (
                      Countries.map(({ CountryId, CountryName }) => (
                        <option key={uuid()} value={CountryId}>
                          {CountryName}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                  </FormikDropdown>
                  <FormikDropdown
                    name="_state"
                    value={values._state}
                    label="Select State"
                    maxWidth="450px"
                    isRequired={true}
                    isDisabled={!stateList}
                    onChange={(e) => {
                      if (e.target.value) {
                        setFieldValue("_state", e.target.value);
                        setSelectedState(e.target.value);
                      } else {
                        setFieldValue("_state", "");
                        setSelectedState(null);
                      }
                      setFieldValue("_city", "");
                      setFieldValue("_pincode", "");
                    }}
                  >
                    {stateList && stateList.length > 0 ? (
                      stateList.map(({ id, value }) => (
                        <option key={uuid()} value={id}>
                          {value}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                  </FormikDropdown>
                  <FormikDropdown
                    name="_city"
                    value={values._city}
                    label="Select City"
                    maxWidth="450px"
                    isDisabled={!cityList}
                    onChange={(e) => {
                      if (e.target.value) {
                        setFieldValue("_city", e.target.value);
                      } else {
                        setFieldValue("_city", "");
                      }
                      setFieldValue("_pincode", "");
                    }}
                  >
                    {cityList && cityList.length > 0 ? (
                      cityList.map(({ id, value }) => (
                        <option key={uuid()} value={id}>
                          {value}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                  </FormikDropdown>
                  <FormikInput
                    name="_pincode"
                    label="Pincode"
                    maxLength={6}
                    placeholder="Please enter pincode"
                    isRequired={false}
                    maxWidth="450px"
                    onKeyDown={registerNumbersOnly}
                  />
                </Flex>
              </AccordianElement>
              <AccordianElement title="Insurance">
                <Flex wrap="wrap" justifyContent="space-between" rowGap="2em">
                  <Field name="_insuranceActive">
                    {({ field }) => (
                      <RadioParent
                        name={field.name}
                        options={insuranceList}
                        label="Is Active ?"
                        isRequired={false}
                        isFormik={true}
                        maxWidth="450px"
                        onChange={(value) => {
                          if (value) {
                            const requiredValue = insuranceList.find(
                              ({ id }) => parseInt(id) === parseInt(value)
                            );
                            setSelectedinsuranceType(requiredValue);
                          }
                        }}
                      />
                    )}
                  </Field>
                  {selectedInsuranceType &&
                  selectedInsuranceType.value.toLowerCase() ===
                    "Yes".toLowerCase() ? (
                    <FormikInput
                      name="_insuranceVaildityDate"
                      type="date"
                      label="Valid Upto"
                      maxWidth="450px"
                      isRequired={true}
                    />
                  ) : (
                    <></>
                  )}
                </Flex>
              </AccordianElement>
              <AccordianElement title="Engine Details">
                <Flex wrap="wrap" justifyContent="space-between" rowGap="2em">
                  <FormikDropdown
                    name="_engineMake"
                    label="Engine Make"
                    isRequired={false}
                    maxWidth="450px"
                    value={values._engineMake}
                    onChange={(e) => {
                      if (e.target.value) {
                        setFieldValue("_engineMake", e.target.value);
                      } else {
                        setFieldValue("_engineMake", "");
                      }
                    }}
                  >
                    {engineMakeList && engineMakeList.length > 0 ? (
                      engineMakeList.map(({ id, value }) => (
                        <option key={uuid()} value={id}>
                          {value}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                  </FormikDropdown>
                  <FormikInput
                    name="_chassisNumber"
                    label="Chassis Number"
                    placeholder="Please enter chassis number"
                    maxLength={16}
                    isRequired={false}
                    maxWidth="450px"
                  />
                  <Field name="_engineType">
                    {({ field }) => (
                      <RadioParent
                        name={field.name}
                        options={engineTypeList}
                        label="Engine Type"
                        isRequired={false}
                        isFormik={true}
                        maxWidth="450px"
                      />
                    )}
                  </Field>
                  <Field name="_autoShutdown">
                    {({ field }) => (
                      <RadioParent
                        name={field.name}
                        options={autoShutdownList}
                        label="Auto Shutdown"
                        isRequired={false}
                        isFormik={true}
                        maxWidth="450px"
                      />
                    )}
                  </Field>
                </Flex>
              </AccordianElement>
            </Flex>
          </Accordion>
        </form>
      )}
    </Formik>
  );
};
