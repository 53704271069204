import jobStatus from "../assets/webapp/dashboard/jobStatus.svg"
import jobRequest from "../assets/webapp/dashboard/jobRequest.svg"
import overseasOpp from "../assets/webapp/dashboard/overseas.svg"
import experience from "../assets/webapp/dashboard/experience.svg"
import asset from "../assets/webapp/dashboard/project.png"
import e9Services from "../assets/webapp/dashboard/e9-services.png"
import store from "../assets/webapp/dashboard/store.png"
import leads from "../assets/webapp/dashboard/leads.png"


export const DASHBOARD_BUTTONS = {
BUSINESS_OWNER : [
    {
        buttonText : "My Projects",
        route: "",
        image: asset
    },
    {
        buttonText : "E9 Services",
        route: "/e9-services",
        image: e9Services
    },
    {
        buttonText : "My Business",
        route: "/my-business",
        image: store
    },
    {
        buttonText : "My Leads",
        route: "/my-leads",
        image: leads
    }
],
EQUIPMENT_OWNER : [
    {
        buttonText : "Add Asset",
        route: "",
        image: asset
    },
    {
        buttonText : "E9 Services",
        route: "/e9-services",
        image: e9Services
    },
    {
        buttonText : "My Business",
        route: "/my-business",
        image: store
    },
    {
        buttonText : "My Leads",
        route: "/my-leads",
        image: leads
    }
],
MAINTENANCE_PARTNER : [
    {
        buttonText : "New Job Request",
        route: "",
        image: jobRequest
    },
    {
        buttonText : "Job Status",
        route: "/job-status",
        image: jobStatus  
    },
    {
        buttonText : "Your Experience",
        route: "",
        image: experience
    },
    {
        buttonText : "Overseas Opportunity",
        route: "",
        image: overseasOpp
    }
],
OPERATOR_PARTNER : [
    {
        buttonText : "New Job Request",
        route: "",
        image: jobRequest
    },
    {
        buttonText : "Job Status",
        route: "/job-status",
        image: jobStatus
    },
    {
        buttonText : "Your Experience",
        route: "",
        image: experience
    },
    {
        buttonText : "Overseas Opportunity",
        route: "",
        image: overseasOpp
    }
]
}