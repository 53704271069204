import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const PremiumBanner = () => {
    const navigate = useNavigate()
    const {UserTypeValue , accountDetails} = useSelector(state => state.user)
    const toast = useToast();
  return (
    <Flex
      bgGradient="linear(45deg, #000000, #121111, #464646, #0D0D0D, #5F5F5F)"
      minHeight="40px"
      minWidth="405px"
      borderRadius="4px"
      justifyContent="center"
      alignItems="center"
      padding="2px 20px"
      gap="1rem"
    >
      <Box>
        <Text
          fontWeight="700"
          fontSize="18px"
          lineHeight="20.56px"
          letterSpacing="1px"
          color="transparent"
          bgClip="text"
          bgGradient="radial(#ECCC85, #F2D691, #F6D69A, #BB841B, #EEC678, #E3B352, #D3993E)"
        >
          Become Premium Member
        </Text>
      </Box>
      <Button
        bgGradient="linear(92deg, #ECCC85, #F2D691, #F6D69A, #BB841B, #EEC678, #E3B352, #D3993E)"
        color="#000000"
        fontWeight="700"
        minW="90px"
        maxH="1.5rem"
        borderRadius="2px"
        _hover={{
          bgGradient:
            "linear(92deg, #ECCC85, #F2D691, #F6D69A, #BB841B, #EEC678, #E3B352, #D3993E)",
          color: "#000000",
        }}
        onClick={() =>{
          if(UserTypeValue === 'Company' && !accountDetails?.CompanyDetails?.TaxNumber && !accountDetails?.CompanyDetails?.IsE9VerifiedTaxNumber ){
            toast({
              status: "error",
              description: "Please update GST Number to access the feature.",
              position: "top-right",
              isClosable: true,
            });
          }else{
            (navigate("/subscription/subscription-type"))
          }
        } }
      >
        Join Now
      </Button>
    </Flex>
  );
};
