import { getCountryFromLocalStore, getLanguageFromLocalStore } from "../localStorage"

export const initialState =  {
    country: getCountryFromLocalStore(),
    language: getLanguageFromLocalStore(),
    theme: null, 
    appVersion: "1.0.0",
    appName: "Equip9",
    companyName: "Equip9 Internet Solution Private Limited",
    loading: false,
    error: null,
    success: true

}