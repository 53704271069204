import React, { useEffect, useState } from "react";
import {
  postRequirementCategoryAPI,
  requirementCategoryList,
} from "../../../services/industryRequirement/industryRequirement";
import { V5FormFooterLayout } from "../../../layout/V5FormFooterLayout/V5FormFooterLayout";
import { Button, Flex } from "@chakra-ui/react";
import { SelectCardWrapper } from "../../../components/SelectCardWrapper/SelectCardWrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  setLastId,
  setRequirementCategoryId,
  setRequirementCategoryValue,
} from "../slice/industryRequirementSlice";
import { useNavigate } from "react-router-dom";

const RequirementCategory = () => {
  const [requirementCategory, setRequirementCategory] = useState();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState(null);
  const { inputMethodId, requirementCategoryId } = useSelector(
    (state) => state.industryRequirement
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getRequirementCategoryList = async () => {
    const data = await requirementCategoryList();
    setRequirementCategory(data);
  };
  const handleCategoryApi = async () => {
    const payload = {
      RequirementCategoryId: requirementCategoryId,
      RequirementMethodId: inputMethodId,
    };
    const data = await postRequirementCategoryAPI(payload);
    dispatch(setLastId(data));
  };
  useEffect(() => {
    getRequirementCategoryList();
  }, []);
  useEffect(() => {
    handleCategoryApi();
  }, [requirementCategoryId]);

  return (
    <V5FormFooterLayout
      title="Requirement Category"
      subtitle="Select Your Need"
      isStepperFalse={false}
      primaryButtonText="Next"
      isPrimaryButtonDisabled={selectedCategory === null}
      primaryButtonClickAction={async () => {
        dispatch(setRequirementCategoryId(selectedCategory));
        dispatch(setRequirementCategoryValue(selectedCategoryValue));
        await handleCategoryApi();
        navigate("/post-requirement");
      }}
    >
      <Flex>
        <Flex gap="1.5em" flexWrap="wrap">
          {requirementCategory?.length > 0 &&
            requirementCategory.map((category) => (
              <SelectCardWrapper
                height="40px"
                width="350px"
                value={
                  category?.RequirementCategoryId
                    ? category?.RequirementCategoryId
                    : ""
                }
                isSelected={
                  selectedCategory === category?.RequirementCategoryId
                }
                onClick={() => {
                  setSelectedCategory(category?.RequirementCategoryId);
                  setSelectedCategoryValue(category?.RequirementCategoryValue);
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                //    isActiveIcon={true}
              >
                <Button
                  width="100%"
                  backgroundColor="transparent"
                  justifyContent="space-between"
                  _hover={{
                    backgroundColor: "transparent",
                  }}
                  rightIcon={
                    <img
                      src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/arrowRight.svg`}
                    />
                  }
                >
                  {category?.RequirementCategoryValue}
                </Button>
              </SelectCardWrapper>
            ))}
        </Flex>
      </Flex>
    </V5FormFooterLayout>
  );
};

export default RequirementCategory;
