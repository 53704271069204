import { createAsyncThunk } from "@reduxjs/toolkit";
import { setBusinessGuuid, setPostGuuid } from "../slice/myBusinessDealershipSlice";
import axios from "axios";
import { API_CONSTANTS } from "../../../../constants/apiConstants";
import {fetchHandler} from "../../../../index";
import { convertToFormData } from "../../../helpers/dataConversion/convertToFormData";
import { createDealershipDetails } from "../../../services/postDealership/postDealership";



export const postDealershipStepOneThunk = createAsyncThunk(
    "myBusiness/postDealershipStepOne",async(_, {getState,fulfillWithValue,rejectWithValue,dispatch}) =>{
        const {
            oemID,
            inndustryId,
            equipmentStatusId,
            name,
            email,
            website,
            contact,
            location,
            latitude ,
            longitude ,
            isdCode,
            taxNumber

        } = getState().myBusinessDealership ;

     
        try {
            const payload = {
                            DealerOemId: oemID,
                            DealerIndustryId: inndustryId,
                            DealerTypeId: equipmentStatusId,
                            Name: name,
                            Location: location,
                            latitude: latitude,
                            longitude: longitude,
                            IsdCode: isdCode,
                            ContactNumber: contact,
                            Email: email,
                            website: website,
                            TaxNumber : taxNumber,
                        }
            const data = await createDealershipDetails(payload);

            if(data?.Status === 201 && data?.Data?.Success &&
                Object.keys(data.Data).length > 0) {
                    dispatch(setPostGuuid(data.Data.DealersShipGuid))
                    return {message: data?.Message?.SuccessMessage, dealershipId: data.Data.DealersShipGuid }
                }else{
                   return {message : data?.Message?.FailMessage}
                }
        } catch (error) {
            throw error
        }
    }
)