import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllLanguagesThunk } from "../../mtData/thunk/getAllLanguagesThunk";

export const setSelectedLanguageThunk = createAsyncThunk("setSelectedLanguage", async(value, {getState, dispatch}) => {
    const masterData = getState().masterData
    if(!masterData.Langauges){
        const formData = new FormData();
        formData.append("CountryIsoCode", "IND")
       await dispatch(getAllLanguagesThunk(formData))
    }
    const selectedLanguage = getState().masterData.Languages.find((language) => parseInt(language.LanguageOptionTypeId) === parseInt(value));
    return selectedLanguage
}) 