import React,{useEffect, useState} from 'react'
import { SideMenuLayout } from '../../../../../../../layout/sideMenuLayout/SideMenuLayout';
import { DashboardNavBar } from '../../../../../../../components/DashboardNavBar/DashboardNavBar';
import { MidSection } from '../../../../../../../../sharedComponent/midSection/MidSection';
import { EquipmentDeatilsCard } from './EquipmetDetailsCard/EquipmentDetailsCard';
import { EditEquipmentDeatilsCard } from './EquipmetDetailsCard/EditEquipmentDetailCard/EditEquipmentDetailCard';
import './EquipmentDeatils.css'
import { OpEquipmentDetails } from './EquipmentDetail/OpEquipmentDetail';
import { useSelector } from "react-redux";
import './EquipmentDeatils.css';

export const EquipmentDetails=()=>{
    const [isPopupOpen, setPopupOpen] = useState(false);
    const[isAddformpopup, setAddformpopup] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const [selectedEquipmentTypeId, setSelectedEquipmentTypeId] = useState(null);
    const userData = useSelector((state) => state.user);
    const verification=userData?.accountDetails?.UserDetails?.E9VerificationMessage

    const openPopup = () => {
        setPopupOpen(true);
      };

      const closePopup = () => {
        setPopupOpen(false);
      }

      const triggerRefresh = () => {  
        setRefreshKey(prev => prev + 1);
      }

      const openformPopup=()=>{
        setAddformpopup(true);
       }

       const closeFormPopup = ()=>{
        setAddformpopup(false);
       }
      useEffect(()=>{
      },[selectedEquipmentTypeId])
  
    
    return(
    
        <div>
        <SideMenuLayout >
         <DashboardNavBar />
         <MidSection >
         {verification === "Not Verified" ? (
          <div
            className="Not-Verfied-tag"
          >
            <p className="Verifi-tex">
              Your profile is not verified.Please complete and update your
              profile for verifications
             
            </p>
          </div>
        ) : (
          <div
            className="Verfied-tag"
            // style={{ backgroundColor: verification === 'Not Verified' ? 'green' : 'green' }}
          >
            <p className="Verifi-text">
              Your profile is  verified.
              {/* <p>{verification}</p> */}
            </p>
          </div>
        )}

         <div className="verification-details">
                  <p className='your-verification-details'>YOUR EQUIPMENT EXPERTISE</p>
                  <hr  className='seprator-equip'></hr>
                  <div className='add-verification-equipment' onClick={openPopup} >
                  <p className='add-verification-detail-text-equipment-details'>+ Add Equipment Details</p>
                </div>
                </div>
                <EquipmentDeatilsCard isOpenForm={isPopupOpen} onCloseForm={closePopup} triggerRefresh={triggerRefresh}    selectedEquipmentTypeId={selectedEquipmentTypeId}  />
                <OpEquipmentDetails triggerRefresh={triggerRefresh}  onEdit={openformPopup} setSelectedEquipmentId={setSelectedEquipmentTypeId}    />
                <EditEquipmentDeatilsCard isOpenFormEdit={isAddformpopup}   onCloseFormEdit={closeFormPopup}  selectedEquipmentTypeId={selectedEquipmentTypeId}   />
         </MidSection>
        </SideMenuLayout>
        </div>
    )
}