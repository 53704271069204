export const initialState = {
    financeData : null,
    // Step one
    financeInstitutionName : "",
    financeLocation: "",
    mobileNumber:  "",
    email: "",
    website: "",
    financeType:"",
   

   

    //step2
    industryType:"",
    regulatoryStatus:"",
    serviceDescription:"",
    termsAndConditions:""
    
}