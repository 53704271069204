import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_CONSTANTS } from "../../../../constants/apiConstants";
import { getAllTopMaintenancePartnersList } from "../../../services/jobsPosted/jobsPosted";

export const getInitialDataThunk = createAsyncThunk("nearMeOp/initialData", async(_, {getState}) => {
    try{
    const cardCount = getState().maintenancePartnerNearMe.cardCount;
    const pageNumber =  getState().maintenancePartnerNearMe.paginationCount;

        const data = await getAllTopMaintenancePartnersList(cardCount,(pageNumber * cardCount));

        if(data.Status === 200 && data.Data.TotalRecords > 0){
            return {data: data.Data.NearMeDetails, imageBaseUrl: data.Data.ImageBaseUrl}
        }
    }
    catch(error){
    }
})