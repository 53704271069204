
import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../helpers/initialState";
// import { getCountriesThunk,  getUserTypesThunk, getBusinessTypesThunk, getAllLanguagesThunk } from "../thunk/mtDataThunk";
import { getCountriesThunk } from "../thunk/getCountriesThunk";
import { getBusinessTypesThunk } from "../thunk/getBusinessTypesThunk";
import { getUserTypesThunk } from "../thunk/getUserTypesThunk";
import { getAllLanguageJsonThunk, getAllLanguagesThunk } from "../thunk/getAllLanguagesThunk";
import { setBusinessTypesToLocalStore, setCountriesToLocalStore, setDefaultAppLanguage, setLanguagesToLocalStore, setUserTypesToLocalStore } from "../helpers/localStorage";
import { getAllLanguagesJson } from "../../../../services/masterData/masterData";



const setLoadingState = (state) => {
  state.loading = true;
  state.error = "";
};

const setFulfilledState = (state, action) => {
  state.loading = false;
  state.error = "";
};

const setRejectedState = (state, action) => {
  state.loading = false;
  state.error = action.error;
};

const updateFulfilledStateAndLocalStore = (state, action, localStoreFunction) => {
  if (action?.payload) {
    setFulfilledState(state);
    localStoreFunction(action.payload.data)
  }
}

export const mtDataSlice = createSlice({
  name: "mtData",
  initialState,
  reducers: {
    setCountries: (state, action) => {
      state.Countries = action.payload;

    },
    setLanguages: (state, action) => {
      state.Languages = action.payload;

    },
    setBusinessTypes: (state, action) => {
      state.BusinessTypes = action.payload;

    },
    setUserTypes: (state, action) => {
      state.UserTypes = action.payload;

    },
  },
  extraReducers: (builder) => {
    builder
      // All Countries
      .addCase(getCountriesThunk.pending, setLoadingState)

      .addCase(getCountriesThunk.fulfilled, (state, action) => {
        updateFulfilledStateAndLocalStore(state, action, setCountriesToLocalStore)
        state.Countries = action?.payload?.data;
      })

      .addCase(getCountriesThunk.rejected, setRejectedState)


      // All Business Types
      .addCase(getBusinessTypesThunk.pending, setLoadingState)
      .addCase(getBusinessTypesThunk.fulfilled, (state, action) => {
        updateFulfilledStateAndLocalStore(state, action, setBusinessTypesToLocalStore)
        state.BusinessTypes = action.payload.data
      })
      .addCase(getBusinessTypesThunk.rejected, setRejectedState)

      // All User Types
      .addCase(getUserTypesThunk.pending, setLoadingState)

      .addCase(getUserTypesThunk.fulfilled, (state, action) => {
        updateFulfilledStateAndLocalStore(state, action, setUserTypesToLocalStore)
        state.UserTypes = action.payload.data
      })
      .addCase(getUserTypesThunk.rejected, setRejectedState)

      // All language Types
      .addCase(getAllLanguagesThunk.pending, setLoadingState)

      .addCase(getAllLanguagesThunk.fulfilled, (state, action) => {
      if(action?.payload?.data){
        updateFulfilledStateAndLocalStore(state, action, setLanguagesToLocalStore)
        state.Languages = action.payload.data
      }
    })
      .addCase(getAllLanguagesThunk.rejected, setRejectedState)

      .addCase(getAllLanguageJsonThunk.fulfilled,(state,{payload}) =>{
        // console.log("PAYLOAD JSON",payload);
        if(payload){
          setDefaultAppLanguage(payload)
          state.defaultLanguage = payload;
        }else{
          setDefaultAppLanguage("")
        }
      })
      
  },

})


export const { setCountries, setLanguage, setBusinessTypes, setUserTypes } = mtDataSlice.actions;

export default mtDataSlice.reducer

