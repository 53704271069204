import React from 'react'
import logo from "../../../../assets/logo/equip9_logo_dark.png"
import { Box } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const BrandHeader = () => {
  
  return (
    <Link to="/">
    <Box
      position="sticky"
      top = "0"
      height = "69.5px"
      backgroundColor = "#FFF"
      zIndex = "100"
      padding = "1em .75em"
      margin="1em 2em"
  >
    <img src={logo} style={{ maxHeight: "50px" }} alt="logo" />
  </Box>
  </Link>
  )
}

export default BrandHeader