import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

export const useFirebase = () => {
   const config = {
    apiKey: process.env.REACT_APP_GOOGLE_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_GOOGLE_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_GOOGLE_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_GOOGLE_FIREBASE_STORAGE_BUCKET ,
    messagingSenderId: process.env.REACT_APP_GOOGLE_FIREBASE_MESSAGING_SENDER_ID ,
    appId: process.env.REACT_APP_GOOGLE_FIREBASE_APP_ID ,
    measurementId: process.env.REACT_APP_GOOGLE_FIREBASE_MEASUREMENT_ID
  };
  const app = initializeApp(config);
  const analytics = getAnalytics(app);

  return {analytics}
}