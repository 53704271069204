import { createAsyncThunk } from "@reduxjs/toolkit"
import { generateJwtToken } from "../../../services/login/login"

export const handleJWTTokenThunk = createAsyncThunk("/login/generateJWT", async (browserDetails = {}, { getState, rejectWithValue }) => {
  const loginData = getState().login
  const appConfig = getState().appConfig

  try {

    const body = JSON.stringify({
      UserCredentialId: loginData.selectedAccount.UserCredentialId,
      BusinessTypeId: loginData.selectedAccount.BusinessTypeId,
      DeviceId: Math.floor(Math.random() * 1000000),
      IsdCode: appConfig.country.CountryIsdCode
    })
    const data  = await generateJwtToken(body, browserDetails);
    return data;
  
  }
  catch (error) {
    rejectWithValue(error.message)
    return error
  }

})