import React, { useEffect, useRef, useState } from "react";
import { Box, Flex, Image, Text, Button } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";

const ThumbnailUpload = ({
  name,
  thumbnails = [],
  formikProps,
  defaultImageUrl,
}) => {
  const { setFieldValue, errors, touched } = formikProps;
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [error, setError] = useState(null);
  const inputRef = useRef();

  const handleThumbnailClick = (thumbnail) => {
    setSelectedThumbnail(thumbnail);
    setUploadedFile(null);
    setFieldValue(name, thumbnail.Url);
    setError(null);
  };

  useEffect(() => {
    if(defaultImageUrl){
      setSelectedThumbnail(defaultImageUrl);
      setUploadedFile(null);
      setFieldValue(name, defaultImageUrl?.Url);
      setError(null);
    }
  }, [defaultImageUrl]);

  const handleInputChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const validFormats = ["image/jpeg", "image/png"];
      const maxSizeInMB = 5;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (!validFormats.includes(file.type)) {
        setError("Invalid file format. Please upload a JPG or PNG file.");
        return;
      }

      if (file.size > maxSizeInBytes) {
        setError("File size exceeds 5MB. Please upload a smaller file.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const fileUrl = reader.result;
        setSelectedThumbnail({ Url: fileUrl });
        setUploadedFile(file);
        setFieldValue(name, file);
        setError(null);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadClick = () => {
    inputRef.current.click();
  };

  const deleteButtonClickHandler = () => {
    setSelectedThumbnail(null);
    setUploadedFile(null);
    setFieldValue(name, "");
    inputRef.current.value = "";
    setError(null);
  };

  return (
    <Flex
      gap="1em"
      w={"100%"}
      border={"1px dashed #C6C7C8"}
      backgroundColor={"#F5F5F5"}
      borderRadius={"4px"}
    >
      <Box w={"50%"}>
        <Flex
          wrap="wrap"
          gap="1em"
          maxW={"600px"}
          borderRight={"1px dashed #C6C7C8"}
          borderRadius={"4px"}
          padding={"20px 50px"}
          maxH={"250px"}
          overflowY={"scroll"}
        >
          {Array.isArray(thumbnails) && thumbnails.length > 0 ? (
            thumbnails.map((thumbnail, index) => (
              <Box
                key={index}
                onClick={() => handleThumbnailClick(thumbnail)}
                cursor="pointer"
                flexBasis="calc(50% - 1em)"
                mb="1em"
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Image
                  src={thumbnail.Url}
                  width={"170px"}
                  height={"90px"}
                  objectFit="cover"
                  border={
                    selectedThumbnail === thumbnail ? "2px solid #000" : "none"
                  }
                />      
              </Box>
            ))
          ) : (
            <Text>No thumbnails available</Text>
          )}
        </Flex>
      </Box>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        direction={"column"}
        w={"50%"}
      >
        <input
          type="file"
          ref={inputRef}
          hidden
          onChange={handleInputChange}
          accept="image/jpeg, image/png"
        />
        {selectedThumbnail && (
          <Box>
            <Image
              src={selectedThumbnail.Url}
              width={"340px"}
              height={"170px"}
              objectFit="cover"
            />
          </Box>
        )}
        {touched[name] && errors[name] && (
          <Text color="red.500">{errors[name]}</Text>
        )}
        {error && <Text color="red.500">{error}</Text>}
        <Flex>
          {!selectedThumbnail && (
            <Flex
              onClick={handleUploadClick}
              cursor={"pointer"}
              direction={"column"}
              justifyContent={"space-around"}
              alignItems={"center"}
            >
              <Box height={"24px"} w={"24px"}>
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  alt="upload icon"
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/popup/upload.svg`}
                />
              </Box>
              <Text
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "50",
                }}
              >
                {/* Upload Document */}
                <p style={{ fontSize: "14px" }}> Upload Thumbnail</p>
                <p style={{ fontSize: "14px" }}>{`(Upto 5mb file size)`}</p>
              </Text>
            </Flex>
          )}
       
          {selectedThumbnail && (
               <Flex  width={"340px"} backgroundColor={"white"} justifyContent={"center"} padding={"5px"}>
            <button onClick={deleteButtonClickHandler} >
              <DeleteIcon color={"#D60000"}/>
            </button>
            </Flex>
          )}
         
          
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ThumbnailUpload;
