export const subString = (length, string= "") => {
if(!string) return "N.A."
if(string.length <= length) return string
return `${string.toString().substring(0, length)}...`
}

export const dateConverstion = (input) => {
    if(!input){
        return ""
    }
    const dateString = input.split("-")
    return dateString.reverse().join("-")
}

export const maskMobileNumber = (input, maskingString = "x") => {
if(!input){
    return "N.A."
}
if(input.length <= 2){
 return input
}
const inputString = input.toString()
let mask = ""
for(let i = 1; i <= inputString.length - 2; i++){
 mask += maskingString
}
return inputString.substring(0, 1) + mask + inputString.substring(inputString.length - 1, inputString.length)
}