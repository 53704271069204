import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../constants/initialState";
import { safetyInitialDeferredThunk } from "../thunk/safetyInitialDeferredThunk";
import { safetyLoadMoreDeferredThunk } from "../thunk/safetyLoadMoreDeferredThunk";




export const safetyDeferredSlice = createSlice({
    name:"safetyDeferredSlice",
    initialState: initialState,
    reducers:{
        setData: (state,{payload})=>{
            state.data = payload
        },
        setMaterialMarketplaceId : (state,{payload}) => {
            state.materialTypeId = payload;
        }
    },

    extraReducers:(builder) =>{
        builder
        .addCase(safetyInitialDeferredThunk.pending, (state)=>{
            state.data = null;
            state.initialLoading = true;
            state.paginationLoading = false;
            state.error = false;
            state.errorMessage = false;
            state.paginationCount = 1;
            state.endOfData = false
        })
        .addCase(safetyInitialDeferredThunk.fulfilled,(state,{payload}) =>{
            state.paginationCount = 1;
            state.initialLoading = false;
            state.error = false;
            state.errorMessage = null;
            state.data = payload;
            state.assetImageReference = payload?.AssetImageBaseReference
            if(payload?.length < state.cardCount){
              state.endOfData = true
            }
        })
        .addCase(safetyLoadMoreDeferredThunk.pending, (state)=>{
            state.paginationLoading = true;
            state.error = false ;
            state.errorMessage = null;
         })
         .addCase(safetyLoadMoreDeferredThunk.fulfilled, (state,{payload})=>{
            state.error = false ;
            state.errorMessage = null ;
            state.paginationLoading = false;
            // state.assetImageReference = payload?.AssetImageBaseReference
            if(payload?.length >= state.cardCount){
                state.data = [...state?.data , ...payload];
                state.paginationCount += 1;
            }else{
                state.endOfData = true;
         }
         })
    }
})

export const {
    setData,
    setMaterialMarketplaceId
}= safetyDeferredSlice.actions;