import { Box } from "@chakra-ui/layout"
import { useState, useEffect } from "react"

export const Carousel = ({array = []}) => {
    const [imageNumber, setImageNumber] = useState(0);
    let id;
    useEffect(() => {
         id = setTimeout(() => {
            if(imageNumber < array.length - 1){
                setImageNumber((prevState) => (prevState + 1))

            }
            else{
                setImageNumber(0)
            }
        }, 3000)
   return () => {clearInterval(id)}
    }, [imageNumber])
 return(
    <Box>
        <img style={{objectFit: "cover", width: "100vw",  height: "70vh"}} src ={array[imageNumber]} alt={'Premium heavy construction equipment available at Equip9. Explore our diverse inventory, including new and used machinery, to meet your project requirements. Trust Equip9 for top-notch solutions in the construction industry.'}/>
    </Box>
 )
}