import "./Aboutmaincss.css"
import { IMAGE_CONSTANTS } from "../../constants/imageConstants";
import Slider from "react-slick";
import { clientList } from "../../../staticData/staticData"; 
import { Flex, Text } from "@chakra-ui/react";
// clientList
function Client(){
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false, // Remove arrow signs
        responsive: [
            {
              breakpoint: 1024, 
              settings: {
                slidesToShow: 2, 
              },
            },
            {
              breakpoint: 600, 
              settings: {
                slidesToShow: 1, 
              },
            },
          ],
      };
    return (
        <div className="silver-gradient" style={{padding:"1.5em"}}>
            <Flex
            fontSize={["1.8em","1.8em","1.8em","2.8em","2.8em","2.8em"]}
            style={{
                color: "var(--primary)",
                fontWeight: "bold",
                textAlign: "center",
                          
            }}>
                <Text w={"100%"}>Our Clients</Text>
                
            </Flex>
            <Flex direction={"column"}  >
            <Slider  {...settings}>
             
                {clientList.map((client, index) => {
                    return (
                        <Flex key={index}>
                            <div style={{height:"155px", width:"155px",marginLeft:"10px"}}>
                            <img 
                            style={{height:"100%", width:"100%"}}
                            src={client.clientName} alt={client.name} />
                            </div>
                           
                        </Flex>
                    );
                })}
            </Slider>
            </Flex>
        </div>
    );
}

export default Client;