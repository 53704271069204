import { API_CONSTANTS } from "../../../constants/apiConstants";
import {fetchHandler} from "../../../index";

export const createSupportTicketUnregistred = async (form) => {
    try {
      const {data}= await fetchHandler(API_CONSTANTS.SUPPORT_TICKET_UNREGISTERED,{},{},JSON.stringify(form),API_CONSTANTS.HTT_POST, { isAuthRequired: false });
      return data;
  
    } catch (error) {
      throw error
    }
  }