import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFilteredPartners } from "../../../services/jobsPosted/jobsPosted";

export const getUemLoadMoreFilteredDataThunk = createAsyncThunk("uem/loadMoreFilteredData",async(_, {getState}) => {
    const selectedCountry = getState().usedEquipmentMarketplace.filterByCountry;
    const selectedState = getState().usedEquipmentMarketplace.filterByState;
    const selectedVerificationStatus = getState().usedEquipmentMarketplace.filterByVerificationStatus;
    const selectedEquipmentType = getState().usedEquipmentMarketplace.filterByEquipmentType;
    const cardCount  = getState().usedEquipmentMarketplace.cardCount;
    const paginationCount = getState().usedEquipmentMarketplace.paginationCount;

    let query = ""

    if(selectedCountry){
        query += `&FilterByLocationCountry=${selectedCountry}`
    }
    if(selectedState){
        query+= `&FilterByLocationState=${selectedState}`
    }
    if(selectedVerificationStatus){
        query += `&FilterByVerificationStatus=${selectedVerificationStatus}`
    }
    if(selectedEquipmentType){
        query+= `&FilterByEquipmentType=${selectedEquipmentType}`
    }

    try{
       
        const data = await getFilteredPartners(cardCount,(paginationCount * cardCount),`?FilterType=Used Equipment${query}`);

        if(data.Status === 200 && Object.keys(data.Data) > 0){
            if(data.Data.TotalRecords > 0){
                return data.Data.UsedEquipmentList
            }
            else {
                return []
            }
        }
        else {
            return []
        }
    }
    catch(error){
    }

    
})