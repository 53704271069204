import React from 'react'
import "./Footer.css"
import { Link } from 'react-router-dom';

import Logo from "../../../assets/logo/equip9_logo_light.png";
import Instagram from "./assest/instagram.png"
import Facebook from "./assest/facebook.png"
import Twitter from "./assest/twitter.png"
import LinkedIn from "./assest/linkedIn.png"
import { Flex } from '@chakra-ui/react';

const Footer = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <div className='footer-wrapper-container'>
            <div className="footer-element">

                <Flex className="company-address footer-links footer__container" textAlign={"center"} display={["flex","flex","flex","flex","none","none"]} borderBottom={"0.5px solid white"}>
                    <div className='company-address-map' style={{ width: "100%" }}><iframe width="100%" height="200px" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=416,%20The%20Address%20Commercia,%20Shankar%20Kalat%20Nagar,%20Wakad,%20Pune,%20Maharashtra%20411057+(EQUIP9%E2%84%A2%EF%B8%8F%20-%20Connecting%20Equipment%20To%20Your%20Business)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/population/">Population Estimator map</a></iframe></div>
                    <h1>416, The Address Commercia, Wakad, Pune, Maharashtra 411057</h1>
                </Flex>
                <div className="footer-header">

                    <div className="company__info__logo">
                        <img className="footer-logo" src={Logo} alt="Equip9" />
                    </div>
                    <Flex style={{ color: "var(--primary)", fontWeight: "600" }} fontSize={["0.8em","0.8em","0.8em","1em","1.050em","1.050em",]} textAlign={"center"}>
                        <p>
                            To aggregate the heavy equipment industry on a digital platform.
                        </p>
                    </Flex>

                </div>

                <div className="footer-social">
                   <a href='https://instagram.com/equip9_?igshid=OGQ5ZDc2ODk2ZA== ' target='_blank'><img className="footer-social-img" src={Instagram} /></a>
                   <a href='https://www.facebook.com/futurebizequip9?mibextid=ZbWKwL' target='_blank'><img className="footer-social-img" src={Facebook} /></a>
                   <a href='https://twitter.com/futurebizequip9' target='_blank'><img className="footer-social-img" src={Twitter} /></a>
                   <a href='https://www.linkedin.com/company/futurebizequip9/' target='_blank'><img className="footer-social-img" src={LinkedIn} /></a>
                </div>

                <div className="footer-body" style={{
                    borderBottom:"0.5px solid white"
                }}>
                    
                <Flex className="company-address footer-links footer__container" textAlign={"center"} display={["none","none","none","none","flex","flex"]}>
                    <div className='company-address-map' style={{ width: "100%" }}><iframe width="100%" height="200px" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=416,%20The%20Address%20Commercia,%20Shankar%20Kalat%20Nagar,%20Wakad,%20Pune,%20Maharashtra%20411057+(EQUIP9%E2%84%A2%EF%B8%8F%20-%20Connecting%20Equipment%20To%20Your%20Business)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/population/">Population Estimator map</a></iframe></div>
                    <h1>416, The Address Commercia, Wakad, Pune, Maharashtra 411057</h1>
                </Flex>
                    <Flex className="footer-links footer__container" fontSize={["0.8em","0.8em","0.8em","1em","1.050em","1.050em"]}>
                        <h2 style={{ color: "var(--primary)", fontWeight: "600" }}>Pages</h2>
                        <div>
                            <ul className="footer-links__list">

                                <li onClick={scrollToTop}><Link to="/" className="footer-link">Home<title>One Industry | One App: Operators, Rentals,Mechanics, Spare Parts and More</title></Link></li>
                                <li onClick={scrollToTop}><Link to="/about" className="footer-link">About Us</Link></li>
                                <li onClick={scrollToTop}><Link to="/our-product" className="footer-link">Product</Link></li>
                                {/* <li onClick={scrollToTop}><Link to="/our-blog" className="footer-link">Blog</Link></li> */}
                                <li onClick={scrollToTop}><Link to="/contact" className="footer-link">Contact</Link></li>

                            </ul>
                        </div>
                    </Flex>

                    <Flex className="footer-links footer__container" fontSize={["0.8em","0.8em","0.8em","1em","1.050em","1.050em",]}>
                        <h2 style={{ color: "var(--primary)", fontWeight: "600" }}>Support</h2>
                        <div>
                            <ul className="footer-links__list">
                                <li onClick={scrollToTop}>
                                    <Link className="footer-link" to="/terms-and-conditions">Terms & Conditions</Link>
                                </li>
                                <li onClick={scrollToTop}>
                                    <Link className="footer-link" to="/privacy-policy">Privacy Policy</Link>
                                </li>
                                <li onClick={scrollToTop}>
                                    <Link className="footer-link" to="/refund-policy">Refund Policy</Link>
                                </li>
                                <li onClick={scrollToTop}>
                                    <Link className="footer-link" to="/rating-system">Rating System</Link>
                                </li>
                                <li onClick={scrollToTop}>
                                    <Link className="footer-link" to="/cookies-notice">Cookies Notice</Link>
                                </li>
                            </ul>
                        </div>
                    </Flex>

                </div>

               

                <Flex className="footer-company-credentials" fontSize={["0.8em","0.8em","0.8em","1em","1.050em","1.050em"]} textAlign={"center"}>
                    <div className="company-credentials">
                        <p>
                            Copyright © 2023 Equip{" "}
                            <span style={{ color: "var(--primary)" }}>9 &#8482;</span> | All
                            Rights Reserved | GSTIN - 27AAFCE7105E1ZX | CIN: U51901PN2019PTC187811{" "}
                        </p>
                    </div>
                </Flex>

            </div>
        </div>
    )
}

export default Footer