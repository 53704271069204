
export const opInitialState = {
    data: null,
    category: "partner",
    subCategory: "operator",
    paginationCount: 0,
    endOfData: false,
    cardCount: 12,
    initialLoading: false,
    paginationLoading: false,
    error: null,
    errorMessage: null,
    assetImageReference : null,
}