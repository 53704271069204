import { Box, Flex, Heading, Icon, Image, Text, Tooltip } from "@chakra-ui/react";
import defaultImage from "../../../assets/webapp/marketplace/defaultAssetImage.png";
import locationIcon from "../../../assets/webapp/dashboard/location_icon.svg"
import { ReactSVG } from "react-svg";

export const V5DashboardAssetCard = ({
  height = "",
  width = "",
  children,
  ...rest
}) => {
  return (
    <Box height={height} width={width} borderRadius="6px" {...rest}>
      {children}
    </Box>
  );
};

const CardHeader = ({ children, ...rest }) => {
  return (
    <Box
    borderTopRightRadius="6px"
    borderTopLeftRadius="6px"
      height="100%"
      width="100%"
      {...rest}
    >
      {children}
    </Box>
  );
};

const CardBody = ({ children, ...rest }) => {
  return <Box {...rest}>{children}</Box>;
};

const CardFooter = ({ children, ...rest }) => {
  return <Box {...rest}>{children}</Box>;
};

const CardHeading = ({ tooltip,  children }) => {
  return (
    <Tooltip label={tooltip}>
    <Heading fontSize="18px" lineHeight="23px">
      {children}
    </Heading>
    </Tooltip>
  );
};

const HighlightedText = ({ children }) => {
  return <Text color="#002961" fontWeight={700} fontSize="18px">{children}</Text>;
};

const LocationText = ({ tooltip,children }) => {
  return (
   <Flex gap="0.25rem" alignItems="center">
      <LocationIcon/>
      <Tooltip  label={tooltip}>
      <Text display="inline-block">{children}</Text>
      </Tooltip>
      </Flex>
  );
};

const CardImage = ({ src }) => {
  return (
    <Image
      src={src}
      borderTopRightRadius="6px"
      borderTopLeftRadius="6px"
      height="100%"
      width="100%"
      fallbackSrc={defaultImage}
    />
  );
};

const LocationIcon = () => {
return <ReactSVG  style={{display : "inline-block"}} src={locationIcon}/>
}

const CardText = ({children}) => {
return <Text>{children}</Text>
}

V5DashboardAssetCard.Header = CardHeader;
V5DashboardAssetCard.Body = CardBody;
V5DashboardAssetCard.Footer = CardFooter;
V5DashboardAssetCard.Heading = CardHeading;
V5DashboardAssetCard.HighlightedText = HighlightedText;
V5DashboardAssetCard.Image = CardImage;
V5DashboardAssetCard.Location = LocationText;
V5DashboardAssetCard.Text = CardText