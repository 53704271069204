import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFilteredPartners } from "../../../services/jobsPosted/jobsPosted";


export const getOperatorPartnerFilteredLoadMoreDataThunk = createAsyncThunk("opertorPartner/loadMoreFilteredData", async(_, {getState}) => {
 
    const {cardCount, filterByVerificationStatus, filterByEquipmentType, filterByLicenseType,  paginationCount} = getState().operatorPartnerNearMe;
 
    let query = "?FilterType=operator"
    if(filterByEquipmentType){
        query += `&FilterByEquipmentType=${filterByEquipmentType}`

    }
    if(filterByLicenseType){
        query+= `&FilterByLicenseType=${filterByLicenseType}`
    }
    if(filterByVerificationStatus){
        query+= `&FilterByIsCertified${filterByVerificationStatus}`
    }
    try{

    const data= await getFilteredPartners(cardCount,paginationCount,query);

    if(data.Status === 200 && Object.keys(data.Data).length > 0){
        if(data.Data.TotalRecords > 0){
            return data.Data.FilteredAllTopMpOperators
        }
        else{
            return []
        }
     }
     else{
       return []
     }
    }
    catch(error){
    }
})