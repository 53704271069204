import { createAsyncThunk } from "@reduxjs/toolkit";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";
import { getMaterialMarketplaceList } from "../../../services/materials/materials";


export const sparePartLoadMoreDataThunk = createAsyncThunk("materials/loadMoreCard",async(_,{getState})=>{
    const sparePartsId = convertToBase64('1')
    const {cardCount , paginationCount} = getState().sparePartsMaterialMarketplace ;
   

    try {
        
        const data = await getMaterialMarketplaceList(cardCount,(paginationCount * cardCount),sparePartsId);

        if(data.Status === 200 && Object.keys(data.Data).length > 0){
            return {data : data.Data} ;
            
        }
    } catch (error) {
        
    }
})