import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./CompanyIndiProfile.css";
import { ReactSVG } from "react-svg";
import Avatar from "../../assets/webapp/doc_flow/My-profile.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useToast } from "@chakra-ui/react";
import { updateProfilePicture } from "../../ApiEquip9/CommonApi";
import { ProfileVerificationNew } from "../../webapp/components/ProfileVerication/ProfileVerificationNew";
export const CompanyIndiProfile = () => {
  const userData = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileType, setFileType] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const toast = useToast();
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const initialValues = {
    DocumentFileReference: null,
  };

  const UserType = userData?.UserTypeValue?.toLowerCase();
  const UserValue = "Company".toLowerCase();
  const role =
    userData?.accountDetails?.CompanyDetails?.CompanyDesignationTypeName;
  const handleSubmit = async (values, { resetForm }) => {
    const Payload = {
      UserProfilePicture: values.DocumentFileReference,
    };

    try {
      const res = await updateProfilePicture(userData.token, Payload);
      if (res?.data?.Status === 201) {
        toast({
          title: "",
          description: `${res?.data?.Message?.SuccessMessage}`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        toggleModal();
        setImagePreview(null);
        window.location.reload();
      } else {
        toast({
          title: "",
          description: `${res?.data?.Message?.FailMessage}`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
      return res;
    } catch (err) {
      toast({
        title: "Error while uploading",
        description: `${err.data.Message.FailMessage}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return null;
    }
  };

  return (
    <>
      <div className="Company-Indi-Profile">
    

        <div style={{display:'flex', flexDirection:"row",marginLeft:"12px",width:"100%",padding:'15px 0px'}}>
        <div
          className="profile-image"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{ position: "relative" }}
        >
          <img
            className="Profileimg"
            src={
              userData.accountDetails?.UserDetails?.ProfilePicture
                ?.ProfilePictureFileReference
                ? `${process.env.REACT_APP_CDN_PVT_URL}UserProfilePicture/${userData?.accountDetails?.UserDetails?.ProfilePicture?.ProfilePictureFileReference}`
                : Avatar
            }
            alt="Profile"
          />
          {isHovered && (
            <div
              style={{
                position: "absolute",
                top: "28%",
                left: "38%",
              }}
            >
              <button
                style={{ height: "23px", width: "23px" }}
                onClick={toggleModal}
              >
                <ReactSVG
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/cameraedit.svg`}
                  alt="edit"
                />
              </button>
            </div>
          )}
          <div>
            <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
  <div className="user-rating-profile-section">
                <ReactSVG
                  className="user-rating-icon"
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/star.svg`}
                />
                <p className="user-rating-text">
                  {
                    userData?.accountDetails?.UserDetails?.CredentialDetails
                      ?.OverAllRating
                  }
                </p>
              </div>
              <div></div>
            </div>
          </div>
        </div>
          <div >
          <div className="userDetail" style={{marginLeft:"23px"}}>
                  <div className="CompanyName">
            {`${userData?.accountDetails?.UserDetails?.PersonalDetails?.FirstName}`}
          </div>
          <div className="useraccount-details">
            <ReactSVG
              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/phone.svg`}
              alt="Phone"
              className="phone-img"
            />
            <p>
              {
                userData?.accountDetails?.UserDetails?.CredentialDetails
                  ?.MobileNumber
              }
            </p>
          </div>

          <div>
            <div className="busineerOwner">{userData?.BusinessTypeValue}</div>
            {UserType === UserValue && (
              <>
                <p className="role-text">Role:{role}</p>
              </>
            )}
          </div>
          <br />
          <div className="e9role">
            E9 Role:{" "}
            {
              userData?.accountDetails?.UserDetails?.E9UserRole
                ?.UserRoleTypeName
            }
          </div>
        </div>
           </div> 
           
           <div style={{marginLeft:'56%'}}>
           <ProfileVerificationNew />
            </div>

        </div>

        
        {isModalOpen && (
          <div className="overlayStyles">
            <div className="modalStyles">
              <p className="SeturFeature">Set your Profile Picture</p>
              
              <div
                style={{ marginLeft: "244px", marginTop: "-64px" }}
              >
                <ReactSVG
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Cross.svg`}
                  alt="Phone"
                  className="phone-img"
                  onClick={toggleModal}
                />
              </div>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                // validationSchema={validationSchema}
              >
                {({ isSubmitting, setFieldValue }) => (
                  <Form>
                    <input
                      className="custom-file-input-compnay"
                      id="custom-file-input"
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        setFileType(file.type); // Set the file type here
                        setFieldValue("DocumentFileReference", file);
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          setImagePreview(reader.result);
                        };
                        if (file) {
                          reader.readAsDataURL(file);
                        }
                        toast({
                          title: "File Uploaded successfully",
                          description: "You have selected a file for upload.",
                          status: "success",
                          duration: 5000,
                          isClosable: true,
                          position: "top-right",
                        });
                        setImageUploaded(true);
                      }}
                    />
                    <div className="preview-container-company">
                      {/* imagePreview */}
                      <img
                        src={
                          imageUploaded
                            ? imagePreview
                            : `${process.env.REACT_APP_CDN_PVT_URL}UserProfilePicture/${userData?.accountDetails?.UserDetails?.ProfilePicture?.ProfilePictureFileReference}`
                        }
                        placeholder="Image Preview"
                        style={{
                          width: "220px",
                          height: "220px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderStyle: "solid",
                          borderWidth: "0.5px",
                          borderColor: "black",
                          background: "#2C2C2C14",
                          borderRadius: "50%",
                          marginLeft: "23px",
                          marginTop: "57px",
                        }}
                      />
                    </div>
                    <div className="button-placement">
                      <label
                        htmlFor="custom-file-input"
                        className="custom-file-input-compnay-Profile"
                      >
                        Upload
                      </label>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="custom-file-input-compnay-Profile"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
