import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserAssets } from "../../../services/userAssets/userAssets";

export const getInitialUserAssetsThunk = createAsyncThunk("/equipment-store/get-initial-user-assets",async(_, {getState, rejectWithValue}) => {
try{
    const data = await getUserAssets(12, 0)
    return data;
}
catch(error){
    rejectWithValue(error);
}
})