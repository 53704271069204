import React, { useState ,useEffect} from "react";
import { SideMenuLayout } from "../../../../../layout/sideMenuLayout/SideMenuLayout";
import { DashboardNavBar } from "../../../../DashboardNavBar/DashboardNavBar";
import { MidSection } from "../../../../../../sharedComponent/midSection/MidSection";
import { useParams } from "react-router-dom";
import "./MarketPlaceSteps1.css";
import { MaterailPhotoUpload } from "./AddPhotos/AddsPhotopopup";
import { MaterailVideoUpload } from "./AddPhotos/AddVideosPop";
import { ShowPhotos } from "./AddPhotos/ShowPhotos.";
import { ShowVideos } from "./AddPhotos/ShowVideos";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ReactSVG } from "react-svg";
import {
  weightDimension,
  measureDimension,
} from "../../../../../../staticData/staticData";
import { useToast, Textarea } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  clearStepperIndex,
  clearMaterialData,
} from "./slice/postMaterialSlice";
import { clearIndexedDBData } from "../../../../../../staticData/CommonMethod";
import { postMaterialDetails2 } from "../../../../../services/materials/materials";

export const MaterialSteps2 = () => {
  const dispatch = useDispatch();
  const [isMaterialStorePopupOpen, setIsMaterialStorePopupOpen] =
    useState(false);
  const [isMaterialStoreVidosPopupOpen, setisMaterialStoreVidosPopupOpen] =
    useState(false);
  const userData = useSelector((state) => state.user);
  const [selectWeight, setSelectWeight] = useState(null);
  const [isLength, setisLength] = useState(null);
  const [isWidth, setisWidth] = useState(null);
  const [isHeight, setisHeight] = useState(null);
  const [isRadius, setisRadius] = useState(null);
  const [refreshVideos, setRefreshVideos] = useState(0);
  const { MaterialId } = useParams();
  const [photoUploaded, setPhotoUploaded] = useState(false);

  const handlePhotoUpload = () => {
      setPhotoUploaded(true);
  }
  const toast = useToast();
  const location = useLocation();

const navigate = useNavigate();

const triggerRefresh = () => {
  setRefreshVideos((prev) => prev + 1); // Increment the counter
};
  const Front = localStorage.getItem(`photoType_1`);
  const Back = localStorage.getItem(`photoType_2`);
  const Other = localStorage.getItem(`photoType_3`);
  const Left = localStorage.getItem(`photoType_4`);
  const Right = localStorage.getItem(`photoType_5`);

  /**validation */

  //  const validationSchema = Yup.object().shape({
  //     ProductWeight: Yup.number()
  //       .required('Weight is required')
  //       .positive('Weight must be a positive number'),
  //     ProductLength: Yup.number()
  //       .required('Length is required')
  //       .positive('Length must be a positive number'),
  //     ProductWidth: Yup.number()
  //       .required('Width is required')
  //       .positive('Width must be a positive number'),
  //     ProductHeight: Yup.number()
  //       .required('Height is required')
  //       .positive('Height must be a positive number'),
  //     ProductRadius: Yup.number()
  //       .required('Radius is required')
  //       .positive('Radius must be a positive number'),
  //     additionaldetails: Yup.string()
  //       .required('Additional details are required')
  //       .max(100, 'Maximum 100 characters allowed'),
  //     weightDimension: Yup.string().required('Weight dimension is required').notOneOf([""], "Weight dimension must be selected"),
  //     lengthDimension: Yup.string().required('Length dimension is required'),
  //     WidthDimension: Yup.string().required('Width dimension is required'),
  //     heightDimension: Yup.string().required('Height dimension is required'),
  //     RadiusDimesion: Yup.string().required('Radius dimension is required'),
  //   });

   const validateLength = (Length, lengthDimension) => {
    let error;
    if (isNaN(Length)) {
      error = 'Length  must be a number';
    }
    else if (Length && Length.length > 3) {
      error = 'Length must be less than 1000';
    }
    else if (Length && Length.length <= 3 && (!lengthDimension || lengthDimension === "Select")) {
      error = 'Length dimension is required';
    }
    return error;
   };

  const validateWeight = (weight, weightDimension) => {
    let error;
    if (isNaN(weight)) {
      error = 'Weight must be a number';
    }
    else if (weight && weight.length > 3) {
      error = 'Weight must be less than 1000';
    }
    else if (weight && weight.length <= 3 && (!weightDimension || weightDimension === "Select")) {
      error = 'Weight dimension is required';
    }
    return error;
  };
  // WidthDimension heightDimension RadiusDimesion
  const validateWidthDimension=(width,WidthDimension)=>{
    let error;
    if (isNaN(width)) {
      error = 'width must be a number';
    }
    else if (width && width.length > 3) {
      error = 'Width must be less than 1000';
    }
    else if (width && width.length <= 3 && (!WidthDimension || WidthDimension === "Select")) {
      error = 'Width dimension is required';
    }
    return error;
  }

  const validateheight=(height,heightDimension)=>
  {
    let error;
    if (isNaN(height)) {
      error = 'Height must be a number';
    }
    else if (height && height.length > 3) {
      error = 'Height must be less than 1000';
    }
    else if (height && height.length <= 3 && (!heightDimension || heightDimension === "Select")) {
      error = 'Height  dimension is required';
    }
    return error;
  }


  const validateRadiusDimesion=(radius,RadiusDimesion)=>
  {
    let error;
    if (isNaN(radius)) {
      error = 'Radius must be a number';
    }
    else if (radius && radius.length > 3) {
      error = ' Radius must be less than 1000';
    }
    else if (radius && radius.length <= 3 && (!RadiusDimesion || RadiusDimesion === "Select")) {
      error = 'Radius dimension is required';
    }
    return error;
  }




  // *******upload Video */
  const handleUploadComplete = () => {
    setRefreshVideos(true);
  };

  const onCheckImage = () => {
    const images = [
      localStorage.getItem(`photoType_1`),
      localStorage.getItem(`photoType_2`),
      localStorage.getItem(`photoType_3`),
      localStorage.getItem(`photoType_4`),
      localStorage.getItem(`photoType_5`),
    ];
    if (images.every((image) => image === null)) {
      toast({
        title: "Error",
        description: "Please upload at least one photo.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return false;
    }
    return true;
  };

  const onreset = () => {
    localStorage.removeItem(`photoType_1`);
    localStorage.removeItem(`photoType_2`);
    localStorage.removeItem(`photoType_3`);
    localStorage.removeItem(`photoType_4`);
    localStorage.removeItem(`photoType_5`);
  };

  const handleSubmitStep2 = async (value) => {
    if (!onCheckImage()) {
      return;
    }

    const payload = {
      MaterialId: parseInt(localStorage.getItem("LastId")),
      Weight: parseInt(value.ProductWeight) || null,
      WeightUnit: selectWeight || null,
      SLength: parseInt(value.ProductLength) || null,
      LengthUnit: isLength || null,
      Width: parseInt(value.ProductWidth) || null,
      WidthUnit: isWidth || null,
      Height: parseInt(value.ProductHeight) || null,
      HeigthtUnit: isHeight || null,
      Radius: parseInt(value.ProductRadius) || null,
      RadiusUnit: isRadius || null,
      AdditionalDetail: value.additionaldetails || null,
    };


    const data = await postMaterialDetails2(payload);

    try {
      if (data.Status === 201) {
        toast({
          title: "",
          description: `Material Added Successfully`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });

        dispatch(clearStepperIndex());
        dispatch(clearMaterialData());
        sessionStorage.clear();
        onreset();
         await clearIndexedDBData();
         navigate("/materials-store-list", { replace: true })
        localStorage.removeItem("LastId")
        navigate(`/material/${ btoa(unescape(encodeURIComponent(localStorage.getItem("MaterialTypeId"))))}`);
        localStorage.removeItem("MaterialTypeId")
       
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Please upload  photo.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <>
      <div className="Step-part-details-heading">
        <p className="step-2-spare-part-details-heading">{localStorage.getItem('MaterailTypeValue')} Details</p>
        <p className="step-2-spare-part-details-subhheding">
          Add your Material Details here
        </p>
        <div className="photo-galary">
          <p ><span className="Product-photo-heroheading">Product Photo</span> <span className="must" style={{marginLeft:"-2px"}}>*</span></p>
          <div
            className="Product-photo-herobutton"
            onClick={() => setIsMaterialStorePopupOpen(true)}
          >
            <p className="Product-photo-buttontext">+ Add</p>
          </div>
        </div>
        <div>
          <ShowPhotos  
        photoUploaded={photoUploaded}
        setPhotoUploaded={setPhotoUploaded} />
        </div>
        <br />
        <br />
        <div className="photo-galary">
        <p ><span className="Product-photo-heroheading">Product Videos</span> <span className="must" style={{marginLeft:"-2px"}}>*</span></p>
          <div
            className="Product-photo-herobutton"
            onClick={() => setisMaterialStoreVidosPopupOpen(true)}
          >
            <p className="Product-photo-buttontext">+ Add</p>
          </div>
        </div>
        <div>
          <ShowVideos
            refreshVideos={refreshVideos}
          />
        </div>
        <br /> <br />
        <div>
          <Formik
            initialValues={{
              ProductWeight: "",
              ProductLength: "",
              ProductWidth: "",
              ProductHeight: "",
              ProductRadius: "",
              additionaldetails: "",
              weightDimension: "",
              lengthDimension: "",
              WidthDimension: "",
              heightDimension: "",
              RadiusDimesion: "",
            }}
              // validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              handleSubmitStep2(values);
            }}
          >
            {({ errors, touched, isSubmitting, setFieldValue,values }) => (
              <Form id="post-material-step-two">
                <div>
                  <p className="Additional-details-text">Weight</p>
                  <div className="weight-section">
                    <Field
                      className="dimension-input-field"
                      type="text"
                      name="ProductWeight"
                      placeholder="Enter Weight"
                      validate={() => validateWeight(values.ProductWeight, values.weightDimension)}
                    />
                    <Field
                      as="select"
                      id="weightDimension"
                      name="weightDimension"
                      className="dimension-input-field-2"
                      value={selectWeight}
                      onChange={(e) => {
                        setSelectWeight(e.target.value);
                        setFieldValue("weightDimension", e.target.value);
                      }}
                      // validate={validateWeightDimension}
                    >
                      <option value="">Select</option>
                      {weightDimension?.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="material-error-boundry">
                    {errors.ProductWeight && touched.ProductWeight && (
                      <div className="material-error-text-boundry">
                        {errors.ProductWeight}
                      </div>
                    )}
                    {errors.weightDimension && touched.weightDimension && (
                      <div className="material-error-text-boundry">
                        {errors.weightDimension}
                      </div>
                    )}
                  </div>
                </div>
                <div style={{ marginTop: "23px" }}>
                  <p className="Additional-details-text">Dimensions</p>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>
                      <div className="weight-section">
                        <Field
                          className="dimension-input-field"
                          type="text"
                          name="ProductLength"
                          placeholder="Enter Length"
                          validate={() => validateLength(values.ProductLength, values.lengthDimension)}
                        />
                        <Field
                          as="select"
                          id="lengthDimension"
                          name="lengthDimension"
                          value={isLength}
                          className="dimension-input-field-2"
                          onChange={(e) => {
                            setisLength(e.target.value);
                            setFieldValue("lengthDimension", e.target.value);
                          }}
                        >
                          <option value="">Select</option>
                          {measureDimension?.map((item, index) => (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="material-error-boundry-dimension">
                        {errors.ProductLength && touched.ProductLength && (
                          <div className="material-error-text-boundry">
                            {errors.ProductLength}
                          </div>
                        )}
                        {errors.lengthDimension && touched.lengthDimension && (
                          <div className="material-error-text-boundry">
                            {errors.lengthDimension}
                          </div>
                        )}
                      </div>
                      <div className="weight-section">
                        <Field
                          placeholder="Enter Width"
                          className="dimension-input-field"
                          type="text"
                          name="ProductWidth"
                          validate={() => validateWidthDimension(values.ProductWidth, values.WidthDimension)}
                          // validate={(value) => validateWidthDimension(value, value.WidthDimension)}
                        />
                        <Field
                          as="select"
                          id="WidthDimension"
                          name="WidthDimension"
                          className="dimension-input-field-2"
                          value={isWidth}
                          onChange={(e) => {
                            setisWidth(e.target.value);
                            setFieldValue("WidthDimension", e.target.value);
                          }}
                        >
                          <option value="">Select</option>
                          {measureDimension?.map((item, index) => (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="material-error-boundry-dimension">
                        {errors.ProductWidth && touched.ProductWidth && (
                          <div className="material-error-text-boundry">
                            {errors.ProductWidth}
                          </div>
                        )}
                        {errors.WidthDimension && touched.WidthDimension && (
                          <div className="material-error-text-boundry">
                            {errors.WidthDimension}
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="weight-section">
                        <Field
                          placeholder="Enter Height"
                          className="dimension-input-field"
                          type="text"
                          name="ProductHeight"
                          validate={() => validateheight(values.ProductHeight, values.heightDimension)}
                          // validate={(value) => validateheightDimension(value, value.heightDimension)}
                        />  
                        <Field
                          as="select"
                          id="heightDimension"
                          name="heightDimension"
                          className="dimension-input-field-2"
                          value={isHeight}
                          onChange={(e) => {
                            setisHeight(e.target.value);
                            setFieldValue("heightDimension", e.target.value);
                          }}
                        >
                          <option value="">Select</option>
                          {measureDimension?.map((item, index) => (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="material-error-boundry-dimension">
                        {errors.ProductWidth && touched.ProductWidth && (
                          <div className="material-error-text-boundry">
                            {errors.ProductWidth}
                          </div>
                        )}
                        {errors.heightDimension && touched.heightDimension && (
                          <div className="material-error-text-boundry">
                            {errors.heightDimension}
                          </div>
                        )}
                      </div>
                      <div className="weight-section">
                        <Field
                          placeholder="Enter Radius"
                          className="dimension-input-field"
                          type="text"
                          name="ProductRadius"

                        />
                        <Field
                          as="select"
                          id="RadiusDimesion"
                          name="RadiusDimesion"
                          value={isRadius}
                          className="dimension-input-field-2"
                          onChange={(e) => {
                            setisRadius(e.target.value);
                            setFieldValue("RadiusDimesion", e.target.value);
                          }}
                          validate={() => validateRadiusDimesion(values.ProductRadius, values.RadiusDimesion)}
                          // validate={(value) => validateRadiusDimesion(value, value.RadiusDimesion)}
                        >
                          <option value="">Select</option>
                          {measureDimension?.map((item, index) => (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="material-error-boundry-dimension">
                        {errors.ProductRadius && touched.ProductRadius && (
                          <div className="material-error-text-boundry">
                            {errors.ProductRadius}
                          </div>
                        )}
                        {errors.RadiusDimesion && touched.RadiusDimesion && (
                          <div className="material-error-text-boundry">
                            {errors.RadiusDimesion}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="Additional-details-price">
                      <p className="Additional-details-text">
                        Additional Details
                      </p>
                      <p className="no-of-char">100 Chars</p>
                    </div>
                    <div>
                      <Field
                        as="textarea"
                        id="additionaldetails"
                        name="additionaldetails"
                        className="additional-details-textarea"
                        placeholder="Add additional details"
                      />
                    </div>
                    {errors.additionaldetails && touched.additionaldetails && (
                      <div className="material-error-text-boundry">
                        {errors.additionaldetails}
                      </div>
                    )}

                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
            
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <MaterailPhotoUpload
        MaterialId={MaterialId}
        isLogoutModalOpenPopUp={isMaterialStorePopupOpen}
        setIsLogoutModalOpenPopUp={setIsMaterialStorePopupOpen}
        setPhotoUploaded={setPhotoUploaded}
      />
      <MaterailVideoUpload
        MaterialId={MaterialId}
        onUploadComplete={triggerRefresh}
        isLogoutModalOpenPopUp={isMaterialStoreVidosPopupOpen}
        setIsLogoutModalOpenPopUp={setisMaterialStoreVidosPopupOpen}
      />
    </>
  );
};
