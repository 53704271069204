import { createAsyncThunk } from "@reduxjs/toolkit";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";
import { getMaterialMarketplaceList } from "../../../services/materials/materials";


export const safetyEquipmentInitialDataThunk = createAsyncThunk("safetyEquipment/getInitialCards", async(_, {getState}) => {

    const safetyEquipmentId = convertToBase64('5')
    const {cardCount } = getState().safetyEquipmentMaterialMarketplace


try{
   
    const data = await getMaterialMarketplaceList(cardCount,0,safetyEquipmentId);
    
    
    if(data.Status === 200 && Object.keys(data.Data).length > 0){
        return data.Data
    }

   
}
catch(error){
}
})