import { useState } from "react";
import "./DealershipDetailsCard.css";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

export const DealershipDetailsCard = ({
  heading = "",
  description = "",
  imgSrc = "",
  videoUrl = "",
  videoType = "",
  formatType = "",
  onDescriptionClick = () => {},
  descriptionCursorRequired = true,
}) => {
  const [videoUrlModal, setVideoUrlModal] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const onOpen = () => {
    setIsPopupOpen(true);
  };

  const onClose = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="dealership-card">
      {imgSrc && (
        <img
          className="dealership-description-card-img"
          style={{ height: "60px" }}
          src={`${imgSrc}`}
        />
      )}

      {videoUrl && (
        <>
          <h1
            style={{
              fontSize: "23px",
              fontWeight: "700",
              color: "#F79C00",
            }}
          >
            {videoType}
          </h1>
          <div className="dealership-description-card-video" onClick={onOpen}>
            {videoUrl && (
              <div className="dealership-description-card-video-element">
                <video
                  className=""
                  controls
                  style={{ height: "100%", width: "100%" }}
                >
                  <source src={videoUrl} type="video/mp4" />
                </video>
              </div>
            )}
          </div>
        </>
      )}

      <>

        <Modal isCentered isOpen={isPopupOpen}>
          <ModalOverlay />
          <ModalContent>
            <Box position={"absolute"} top={"-20px"} right={"15px"}>
              <Button
                onClick={onClose}
                boxShadow="0px 1.5px 3px 0px #0000000F"
                borderRadius={"50%"}
                backgroundColor={"white"}
                height={"42px"}
                width={"42px"}
              >
                <CloseIcon width={"9px"} stroke={"#141619"} />
              </Button>
            </Box>
            <ModalHeader textAlign={"center"}></ModalHeader>
            <ModalBody maxH={"400px"}>
              <video
                className=""
                controls
                style={{
                  height: "100%",
                  width: "100%",
                  maxHeight: "350px",
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
              >
                <source src={videoUrl} type="video/mp4" />
              </video>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>

      <h1 className="dealership-card__heading">{heading}</h1>
      <p
        className={`dealership-description-card__description ${
          descriptionCursorRequired
            ? "dealership-cursor--pointer"
            : "dealership-description-card__description--not-available"
        }`}
        onClick={onDescriptionClick}
      >
        {description}
      </p>
    </div>
  );
};
