import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'
import { inputAnatomy } from '@chakra-ui/anatomy'
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const customStyles = definePartsStyle({
  field: {
    borderRadius: '4px',
    border: '1px solid',
    borderColor: "#2c2c2c14",
    bg: '#2C2C2C05',
    fontSize: '20px',
    lineHeight: '30px',
    padding: '16px 13px 16px 20px',
    h: '52px',
    marginRight: "unset"
  },
})

export const inputTheme = defineMultiStyleConfig({
  baseStyle: customStyles,
})