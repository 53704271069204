import { convertObjectsToArrays } from "../../helpers/dataConversion/convertObjectsToArrays";
import {fetchHandler} from "../../../index"

export const getUsedEquipmentStoreAnalytics = async () => {
  try {
    const { data } = await fetchHandler("/GetMyStoreAnalytics", {
      MarketplaceType: btoa("Used"),
    });
    if (data.Status === 200 && Object.keys(data.Data).length > 0) {
      const objectArray = Object.keys(data.Data.UsedAnalytics[0]);
      const requiredData = objectArray.map((element) => ({
        name:
          element === "ConnectsOnMP"
            ? "Connects"
            : element === "ConnectsPending"
            ? "Pending Requests"
            : element === "LikesOnMP"
            ? "Likes"
            : element === "RequestReceived"
            ? "Requests"
            : "Analytics",
        value: data.Data.UsedAnalytics[0][element],
      }));
      return requiredData;
    } else {
      const error = new Error();
      error.message = data.Message.FailMessage;
      throw error;
    }
  } catch (error) {
    throw error;
  }
};
export const getRentalEquipmentStoreAnalytics = async () => {
  try {
    const { data } = await fetchHandler("/GetMyStoreAnalytics", {
      MarketplaceType: btoa("Rental"),
    });
    if (data.Status === 200 && Object.keys(data.Data).length > 0) {
      const objectArray = Object.keys(data.Data.RentalAnalytics[0]);
      const requiredData = objectArray.map((element) => ({
        name:
          element === "ConnectsOnMP"
            ? "Connects"
            : element === "ConnectsPending"
            ? "Pending Requests"
            : element === "LikesOnMP"
            ? "Likes"
            : element === "RequestReceived"
            ? "Requests"
            : "Analytics",
        value: data.Data.RentalAnalytics[0][element],
      }));
      return requiredData;
    } else {
      const error = new Error();
      error.message = data.Message.FailMessage;
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

export const getRentalMyStoreMarketplace = async () => {
  try {
    const { data } = await fetchHandler(`/GetMyStoreMarketPlaceList/12/0`, {
      MarketplaceType: btoa("Rental"),
    });
    if (data.Status === 200 && Object.keys(data.Data).length > 0) {
      return data.Data.RentalMarketPlaceList;
    } else {
      const error = new Error();
      error.message = data.Message.FailMessage;
    }
  } catch (error) {
    throw error;
  }
};
export const getUsedMyStoreMarketplace = async () => {
  try {
    const { data } = await fetchHandler(`/GetMyStoreMarketPlaceList/12/0`, {
      MarketplaceType: btoa("Used"),
    });
    if (data.Status === 200 && Object.keys(data.Data).length > 0) {
      return data.Data.UsedMarketPlaceList;
    } else {
      const error = new Error();
      error.message = data.Message.FailMessage;
    }
  } catch (error) {
    throw error;
  }
};
