import { createAsyncThunk } from "@reduxjs/toolkit";
import { userDetailsThunk } from "../../global/user/thunks/userDetailThunk";
import { createUserAddressDetails } from "../../../services/subscription/subscription";

export const addIndividualAddressThunk =  createAsyncThunk("/subscription/addIndividualAddress", async(body, {getState, dispatch, rejectWithValue}) => {

try{
    
    const data = await createUserAddressDetails(body);

    if(data.Status === 201 || data.Status === 204){
        try{
            const data = await dispatch(userDetailsThunk()).unwrap();
          
        }
        catch(error){
            console.log(error);
        }
    }
    else{
    rejectWithValue({error: "Some error happened"})
    }
}
catch(error){
    console.log(error)
}
}) 