export const initialState = {
    data: null,
    initialLoading: false,
    error: null,
    errorMessage: null,
    paginationCount: 0,
    endOfData: false,
    paginationLoading: false,
    cardCount: 12,

    imageBaseUrl:null,
    selectedQuiz:null,
    isComingSoonModalOpen:false,
    isQuizNotPlayedByAnyonePopupOpen:false,
    isActiveQuizPopupOpen:false,
    isAlreadyPlayedByUserPopupOpen:false,
    isQuizSubmittedPopupOpen:false,
    isQuizTimesUpPopupOpen:false,

    userScore:0,
    selectedAnswerData:[],

    myPlayedQuizzes:null
  };
  