import { Flex, Text } from "@chakra-ui/react"

export const Banner = ({ headingText = "Heading", highlightedText = "Highlighted", description = "Description", bannerImage = "" }) => {
  return (
    <Flex className="silver-gradient" style={{ justifyContent: "space-between", alignItems: "center", height: "232px", color: "#FBFBFC" }} paddingLeft={["1.8em", "1.8em", "1.8em", "3.12em", "3.75em"]}>
      <div style={{ display: "flex", flexDirection: "column", alignSelf: "center", textTransform: "uppercase" }} >
        <Text fontSize={["1.5em", "1.5em", "1.5em", "1.75em", "2.5em", "3em"]} fontWeight={"700"}><span style={{ color: "var(--primary" }} >{headingText}</span> {highlightedText}</Text>
        <Text fontSize={["0.8em", "0.8em", "0.8em", "1em", "1.12em", "1.12em"]}>{description}</Text>
      </div>
      <div style={{ background: `url(${bannerImage})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", maxHeight: "508px", maxWidth: "507px", height: "100%", width: "100%" }}> </div>

    </Flex>
  )

}