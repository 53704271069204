import { API_CONSTANTS } from "../../../constants/apiConstants";
import { fetchHandler } from "../../../index";
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData";

export const sendForgotPasswordOTP = async (payload) => {
  try {
    const { data } = await fetchHandler(
      API_CONSTANTS.SEND_FORGOT_PASSWORD_OTP,
      {},
      {},
      convertToFormData(payload),
      API_CONSTANTS.HTT_POST,
      { isAuthRequired: false }
    );

    return data;
  } catch (error) {
    throw error?.response?.data?.Message

  }
};

export const updatePasswordAfterForgotPassword = async (payload) => {
  try {
    const { data } = await fetchHandler(
      API_CONSTANTS.UPDATE_PASSWORD_AFTER_FORGOT_PASSWORD,
      {},
      {},
      convertToFormData(payload),
      API_CONSTANTS.HTT_POST,
      { isAuthRequired: false }
    );
    return data;
  } catch (error) {}
};
