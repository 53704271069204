import { API_CONSTANTS } from "../../../constants/apiConstants"
import {fetchHandler} from "../../../index"
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData"

export const getCompanyDesignationType=async ()=>{
    try {
        const {data}=await fetchHandler(API_CONSTANTS.GET_ALL_COMPANY_DESIGNATIONS,{},{},{},API_CONSTANTS.HTTP_GET)
        return data
    }catch (error){
        throw error
    }
}

export const updateUserCompanyDesignationDetails=async (body)=>{
    try {
        const {data}=await fetchHandler(API_CONSTANTS.UPDATE_AUTH_USER_DESIGNATION,{},{},body,API_CONSTANTS.HTTP_PUT)
        return data
    }catch (error){
        throw error
    }
}

export const uploadUserProfilePicture=async (body)=>{
    try {
        const {data}=await fetchHandler(API_CONSTANTS.UPDATE_AUTH_PROFILE_PICTURE,{},{},body,API_CONSTANTS.HTT_POST)
        return data
    }catch (error){
        throw error
    }
}

export const createE9UserRole=async (body)=>{
    try {
        const {data}=await fetchHandler(API_CONSTANTS.POST_SELECTED_E9_ROLE,{},{},body,API_CONSTANTS.HTT_POST)
        return data
    }catch (error){
        throw error
    }
}

export const getTaxDocTypeByCountryId=async (queryParam)=>{
    try {
        const {data}=await fetchHandler(API_CONSTANTS.GET_TAX_DOCUMENT_BY_COUNTRY_ID,queryParam,{},{},API_CONSTANTS.HTTP_GET)
        return data
    }catch (error){
        throw error
    }
}

export const uploadUserIdentityDocument=async (body)=>{
    try {
        const {data}=await fetchHandler(API_CONSTANTS.UPLOAD_VERIFICATION_DOCUMENT,{},{},body,API_CONSTANTS.HTT_POST)
        return data
    }catch (error){
        throw error
    }
}

export const updateCompanyUserTaxDetails=async (body)=>{
    try {
        const {data}=await fetchHandler(API_CONSTANTS.UPDATE_BUSINESS_IDENTIFICAION_DOCUMENT,{},{},body,API_CONSTANTS.HTTP_PUT)
        return data
    }catch (error){
        throw error
    }
}

export const getVerificationDocumentType=async ()=>{
    try {
        const {data}=await fetchHandler(API_CONSTANTS.GET_VERIFICATION_DOCUMENTS,{},{},{},API_CONSTANTS.HTTP_GET)
        return data
    }catch (error){
        throw error
    }
}

export const getAllUserRolesList=async ()=>{
    try {
        const {data}=await fetchHandler(API_CONSTANTS.GET_ALL_E9_ROLES)
        return data
    }catch (error){
        throw error
    }
}

export const createEquipmentLicence=async (payload)=>{
    try {
        const {data}=await fetchHandler(API_CONSTANTS.SAVE_EQUIPMENT_LICENSE,{},{},convertToFormData(payload),API_CONSTANTS.HTT_POST);

        return data
    }catch (error){
        throw error
    }
}

export const createNoLicenseRecordForPartner=async ()=>{
    try {
        const {data}= await fetchHandler(API_CONSTANTS.HAS_NO_LICENSE,{},{},{},API_CONSTANTS.HTT_POST);
        
        return data
    }catch (error){
        throw error
    }
}

export const getLicenseTypeList=async ()=>{
    try {
        const {data}= await fetchHandler(API_CONSTANTS.GET_LICENSE_TYPE_LIST);
        return data
    }catch (error){
        throw error
    }
}

export const createUserEquipmentDetails=async (payload)=>{
    try {
        const {data}= await fetchHandler(API_CONSTANTS.UPLOAD_EQUIPMENT_DETAILS,{},{},payload,API_CONSTANTS.HTT_POST);
        return data
    }catch (error){
        throw error
    }
}

export const getMaintenanceExpertiseTypeList=async ()=>{
    try {
        const {data}= await fetchHandler("/GetMaintenanceExpertiseTypeList");
        return data
    }catch (error){
        throw error
    }
}

export const getAllIndustry=async (selectedEquipmentType)=>{
    try {
        const {data}= await fetchHandler(`${API_CONSTANTS.GET_ALL_INDUSTRY}${selectedEquipmentType}`);
        return data
    }catch (error){
        throw error
    }
}


