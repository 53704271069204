import { createAsyncThunk } from "@reduxjs/toolkit";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";
import { getMaterialMarketplaceList } from "../../../services/materials/materials";

export const oilInitialDataThunk = createAsyncThunk("oilMaterialMarketplace/getInitialCards", async(_, {getState}) => {

    const oilId = convertToBase64('2')
    const cardCount = getState().oilMaterialMarketplace.cardCount

try{
  
    const data = await getMaterialMarketplaceList(cardCount,0,oilId);
    
    if(data.Status === 200 && Object.keys(data.Data).length > 0){
        // console.log("oilId Data: ",data);
        return data.Data
    }

   
}
catch(error){
    // console.log("MATERIALERROR: ",error);

}
})