
// import StepperComponent from "../../../../../Website/CommonComponents/Stepper/stepper"

import { Formik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { Button, Flex } from "@chakra-ui/react";
import * as Yup from "yup"
import { FormikPhoneNumberInput } from "../../../../components/Input/PhoneNumberInput/FormikPhoneNumberInput";
import { setMobileNumber } from "../../slice/otpSlice";
import { getDefaultAppLanguage } from "../../../global/mtData/helpers/localStorage";

export const GenerateOtpHoc = ({onSuccessfulValidation = () => {}, isLoading = false}) => {
    const masterData = useSelector((state) => state.masterData);
    const appConfig = useSelector((state) => state.appConfig);
    const dispatch = useDispatch();
    const defaultLanguage = getDefaultAppLanguage();
    
    return(
      
        <Flex
  direction="column"
  gap="1.5em"
 
 alignItems="center"
 maxW={["90%", ""]}
 margin={["0 auto", ""]}
>
  <Formik
    initialValues={{ countryCode: appConfig.country ? appConfig.country.CountryIsdCode : "", mobileNumber: "" }}
    validationSchema={Yup.object({
      mobileNumber: Yup.string().required("Mobile Number Required").length(10, "Exactly 10 digits allowed"),
      countryCode: Yup.string().required("Country Code required"),
    })}
    onSubmit={(values, { setSubmitting }) => {
      dispatch(setMobileNumber(values.mobileNumber));
      onSuccessfulValidation(values);
      setSubmitting(false);
    }}
  >
    {({ values, handleSubmit }) => (
      <form onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }} id="generate-otp" style={{ width: '100%', display: "flex", justifyContent: "center" }}>

        <FormikPhoneNumberInput
          isRequired={true}
          label="Mobile Number"
          type="text"
          name="mobileNumber"
          countrycodename="countryCode"
          dropdownValues={masterData.Countries ? masterData.Countries : []}
          placeholder="E.g. 9876543210"
          value={values.countryCode}
        />

      </form>
    )}
  </Formik>
  <Button 
    type="submit" 
    form="generate-otp" 
    variant="gradient" 
    width={["100%"]}
    maxWidth={["374px"]}
    isLoading={isLoading}
   
  >
    {defaultLanguage?.Send_OTP}
  </Button>
</Flex>

 
        
    )

}