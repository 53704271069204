import React, { useEffect, useState } from "react";
import { V5SidebarLayout } from "../../../layout/V5SidebarLayout/V5SidebarLayout";
import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import CommonHeader from "../../../components/CommonHeader/CommonHeader";
import { AddIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import {
  deleteRequirement,
  getIndustryAcceptedAnalytics,
  getIndustryPendingAnalytics,
  getIndustryReceivedAnalytics,
  getInputTypeList,
} from "../../../services/industryRequirement/industryRequirement";
import { MarketplaceHoc } from "../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { useDispatch, useSelector } from "react-redux";
import { myRequirementThunk } from "../thunk/myRequirementThunk";
import IndustryRequirementCard from "../../../components/ImageViewWithTitle/IndustryRequirementCard";
import { loadMoreMyRequirement } from "../thunk/loadMoreMyRequirement";
import { SinglePurposePopup } from "../../../components/SinglePurposePopup/SinglePurposePopUp";
import {
  resetRequirement,
  setDeletePopUp,
  setInputMethodId,
  setInputModal,
} from "../slice/industryRequirementSlice";
import { SelectCardWrapper } from "../../../components/SelectCardWrapper/SelectCardWrapper";
import { CustomGeneralModal } from "../../../components/CustomGeneralModal/CustomGeneralModal";
import { imageSelector } from "../../../helpers/categoryImageHandler/categoryImageHandler";
import { openQrModal } from "../../dashboard/slice/dashboardSlice";
import { ReactSVG } from "react-svg";
import doubleTick from "../../../../assets/webapp/common/double-tick.svg";

const MyRequirement = () => {
  const {
    data,
    endOfData,
    initialLoading,
    paginationLoading,
    isDeletePopUpopen,
    selectInputTypeModal,
  } = useSelector((state) => state.industryRequirement);
  const [receivedAnalytics, setReceivedAnalytics] = useState();
  const [acceptedAnalytics, setAcceptedAnalytics] = useState();
  const [pendingAnalytics, setPendingAnalytics] = useState();
  const [deleteId, setDeleteId] = useState();
  const [selectedInput, setSelectedInput] = useState(null);
  const [inputList, setInputList] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  useEffect(() => {
    (() => {
      dispatch(resetRequirement());
    })();
  }, []);

  const getMyRequirementList = async () => {
    dispatch(myRequirementThunk());
  };
  //   Analytics
  const getRequestReceived = async () => {
    try {
      const data = await getIndustryReceivedAnalytics();
      if (data) {
        setReceivedAnalytics(data);
      }
    } catch (error) {}
  };
  const getRequestAccepted = async () => {
    try {
      const data = await getIndustryAcceptedAnalytics();
      if (data) {
        setAcceptedAnalytics(data);
      }
    } catch (error) {}
  };
  const getRequestPending = async () => {
    try {
      const data = await getIndustryPendingAnalytics();
      if (data) {
        setPendingAnalytics(data);
      }
    } catch (error) {}
  };
  const getInputMethodList = async () => {
    const data = await getInputTypeList();
    if (data?.length > 0) {
      setInputList(data);
    }
  };

  useEffect(() => {
    getMyRequirementList();
    getRequestReceived();
    getRequestAccepted();
    getRequestPending();
    getInputMethodList();
  }, []);

  const renderCards = (array) => {
    const renderJSX =
      array && array.length > 0 ? (
        array.map((data) => (
          <IndustryRequirementCard
            imageData={data?.ImageList}
            CreatedDateTime={data?.CreatedDateTime}
            ExpiredOn={data?.ExpiredOn}
            ProfilePhoto={data?.ProfilePhoto}
            UserName={data?.UserName}
            BusinessTypeValue={data?.BusinessTypeValue}
            Requirement={data?.Requirement}
            RequestCount={data?.RequestCount}
            RequirementCategoryValue={data?.RequirementCategoryValue}
            City={data?.City}
            State={data?.State}
            isOwnRequirement={true}
            onDeleteButtonClick={() => {
              setDeleteId(data?.RequirementDetailsId);
              dispatch(setDeletePopUp(true));
            }}
          />
        ))
      ) : (
        <Flex alignItems="center" justifyContent="center" height="50vh">
          <Text>No Requirement Posted</Text>
        </Flex>
      );

    return renderJSX;
  };

  return (
    <V5SidebarLayout>
      <SinglePurposePopup
        isOpen={isDeletePopUpopen}
        iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/my-business/materials/delete-popup.svg`}
        bodyText="Are you sure you want to Delete this Requirement permanently?"
        primaryActionText={"Delete It"}
        secondaryActionText={"Keep It"}
        continueAction={async () => {
          try {
            const data = await deleteRequirement(deleteId);
            if (data?.Status === 204) {
              toast({
                status: "success",
                description: "Requirement Deleted Successfully",
                position: "top-right",
                isClosable: true,
              });
              dispatch(setDeletePopUp(false));
              getMyRequirementList();
            }
          } catch (error) {
            dispatch(setDeletePopUp(false));
          }
        }}
        cancelAction={async () => {
          console.log("Cancle");
          dispatch(setDeletePopUp(false));
        }}
      />

      <Box position="absolute" right="20px" bottom="30px">
        <Button
          borderRadius="50%"
          background="linear-gradient(296.05deg, #F79C00 23.18%, #FED401 94.52%)"
          color="white"
          onClick={() => {
            dispatch(setInputModal(true));
          }}
          zIndex='1'
          _hover={{
            background:
              "linear-gradient(296.05deg, #F79C00 23.18%, #FED401 94.52%)",
          }}
        >
          <AddIcon />
        </Button>
      </Box>

      <Flex direction="column" gap="1em">
        <CommonHeader
          header="My Requirement"
          subHeader="See the Requirements Posted by You"
        />

        <Flex>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            padding="1em 1em"
          >
            <Flex gap="1em">
              <Button
                leftIcon={
                  <img
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/analytics.svg`}
                  />
                }
                color="#002961"
                border="1px solid #002961"
                backgroundColor="white"
                onClick={() => {
                  toast({
                    status: "info",
                    description: "Comming Soon",
                    position: "top-right",
                    isClosable: true,
                  });
                }}
              >
                Analytics
              </Button>
              <Button
                leftIcon={
                  <img
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/requirement.svg`}
                    style={{
                      filter:
                        "invert(49%) sepia(99%) saturate(823%) hue-rotate(9deg) brightness(105%) contrast(107%)",
                    }}
                  />
                }
                color="#F79C00"
                border="1px solid #F79C00"
                backgroundColor="white"
                onClick={() => navigate("/my-requirement")}
              >
                My Requirements
              </Button>
              <Button
                color="#002961"
                border="1px solid #002961"
                backgroundColor="white"
                onClick={() => navigate("/industry-requirement")}
              >
                Posts
              </Button>
            </Flex>
          </Box>
        </Flex>

        {/* Analytics */}

        <Flex gap="2em">
          <Flex
            boxShadow="0px 0px 9.27px -1.54px #0000001A"
            height="50px"
            width="250px"
            borderRadius="6px"
            alignItems="center"
            justifyContent="center"
            gap="1em"
          >
            <Box>
              <img
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/received.svg`}
              />
            </Box>
            <Text color="#333333" fontSize="22px" fontWeight="600">
              {receivedAnalytics ? receivedAnalytics : "0"}
            </Text>

            <Text color="#484848" fontSize="14px" fontWeight="400">
              Request Received
            </Text>
          </Flex>
          <Flex
            boxShadow="0px 0px 9.27px -1.54px #0000001A"
            height="50px"
            width="250px"
            borderRadius="6px"
            alignItems="center"
            justifyContent="center"
            gap="1em"
          >
            <Box>
              <img
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/connects.svg`}
              />
            </Box>
            <Text color="#333333" fontSize="22px" fontWeight="600">
              {acceptedAnalytics ? acceptedAnalytics : "0"}
            </Text>

            <Text color="#484848" fontSize="14px" fontWeight="400">
              Connects Made
            </Text>
          </Flex>
          <Flex
            boxShadow="0px 0px 9.27px -1.54px #0000001A"
            height="50px"
            width="250px"
            borderRadius="6px"
            alignItems="center"
            justifyContent="center"
            gap="1em"
          >
            <Box>
              <img
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/pending.svg`}
              />
            </Box>
            <Text color="#333333" fontSize="22px" fontWeight="600">
              {pendingAnalytics ? pendingAnalytics : "0"}
            </Text>

            <Text color="#484848" fontSize="14px" fontWeight="400">
              Pending Request
            </Text>
          </Flex>
        </Flex>

        {/* Requirement Cards */}
        <MarketplaceHoc
          marketplaceName="Industry Requirement"
          marketplaceType="industry"
          dataArray={data}
          endOfData={endOfData}
          loadMoreAction={() => dispatch(loadMoreMyRequirement())}
          initialLoading={initialLoading}
          paginationLoading={paginationLoading}
          cardsRenderFunction={renderCards}
        />
      </Flex>

      {/* Add PYR */}
      <CustomGeneralModal
        isOpen={selectInputTypeModal}
        hasCloseButton={true}
        onCloseButtonClick={() => {
          dispatch(setInputModal(false));
        }}
        icon={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/input.svg`}
        heading={"Select Input Method"}
        primaryActionText={"Okay"}
        primaryButtonIsDisable={selectedInput === null}
        primaryAction={() => {
          dispatch(setInputMethodId(selectedInput));
          dispatch(setInputModal(false));
          navigate("/requirement-category");
        }}
      >
        <Flex direction="column">
          {inputList?.length > 0 &&
            inputList.map((input) => (
              <SelectCardWrapper
                key={input?.InputMethodId}
                height="70px"
                width="100%"
                value={input?.InputValue ? input?.InputValue : ""}
                icon={imageSelector(input?.InputValue)}
                isSelected={selectedInput === input?.InputMethodId}
                onClick={() => {
                  if (input?.InputMethodId?.toString()?.toLowerCase() === "2") {
                    dispatch(openQrModal());
                    setSelectedInput(null);
                  } else {
                    setSelectedInput(input?.InputMethodId);
                  }
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                isActiveIcon={true}
              >
                <Flex
                  justifyContent="space-between"
                  paddingLeft="60px"
                  width="100%"
                >
                  <Text
                    fontSize={{ base: "lg", md: "xl" }}
                    fontWeight="semibold"
                    textAlign="center"
                  >
                    {input?.InputValue}
                  </Text>

                  {selectedInput === input?.InputMethodId && (
                    <div style={{ textAlign: "right" }}>
                      <ReactSVG
                        src={doubleTick}
                        beforeInjection={(svg) => {
                          svg.setAttribute(
                            "style",
                            "height: 20px; width: 18px"
                          );
                        }}
                      />
                    </div>
                  )}
                </Flex>
              </SelectCardWrapper>
            ))}
        </Flex>
      </CustomGeneralModal>
    </V5SidebarLayout>
  );
};

export default MyRequirement;
