import React from "react";
import {
    Box,
    Button,
    Flex,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "@chakra-ui/icons";

import { closeRemoveMarketplaceModal } from "../slice/myBusinessDealershipSlice";
import { removeDealershipFromMarketplace } from "../../../services/postDealership/postDealership";

export const DeleteFromMarketplacePopUp = ({ id = "" }) => {

    const dispatch = useDispatch();
    const toast = useToast();
    const postDealershipData = useSelector(
        (state) => state.myBusinessDealership
    );

    const handleDelete = async () => {

try {
    const {data} = await removeDealershipFromMarketplace(id)

    if (data?.Status === 201) {
        toast({
            title: "",
            description: `Dealership removed from marketplace`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
        });
        window.location.reload();
    }
    else {
        toast({
            title: "",
            description: `Dealership removed from marketplace`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
        });
        window.location.reload();
    }
} catch (error) {

}
     }

return (
    <>
        <Modal isOpen={postDealershipData.isMaketplaceDelete} isCentered >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Box textAlign="right">
                        <Button
                            variant="ghost"
                            onClick={() => {
                                dispatch(closeRemoveMarketplaceModal());
                            }}
                            boxShadow="none"
                        >
                            <CloseIcon />
                        </Button>
                    </Box>
                    <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
                        <img src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/delete-primary.svg`} style={{ maxHeight: "40px", maxWidth: "40px" }} />
                        <Heading
                            fontSize="28px"
                            fontWeight="600"
                            fontFamily="Assistant"
                            lineHeight="52.32px"
                        >
                            Are you sure you want to remove dealership from marketplace ?
                        </Heading>
                    </Flex>
                </ModalHeader>

                <ModalBody>

                    <Flex alignItems={"center"} justifyContent={"space-between"} margin={"1em"}>
                        <Button onClick={handleDelete}>
                            Delete It
                        </Button>
                        <Button backgroundColor={"#F79C00"} color={"white"} onClick={() => dispatch(closeRemoveMarketplaceModal())}>
                            Keep It
                        </Button>
                    </Flex>

                </ModalBody>
            </ModalContent>
        </Modal>
    </>
);
};
