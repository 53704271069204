import { createSlice } from "@reduxjs/toolkit";
import { getIndustryLeadThunk } from "../thunks/getIndustryLeadThunk";


export const industryLeadSlice = createSlice({
    name:"industryLead",
    initialState : {
        purchasedLeadsData : null,
        selectedSubscription : null,
        purchasedLeads: 0,
        totalLeads: 0,
        purchasedLeadsLoading : false,
        leadsRemaining : null,
        data : null,
    },
    reducers:{
        setData : (state, {payload}) =>{
            state.data = payload ;
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(getIndustryLeadThunk.pending,(state) =>{
            state.purchasedLeadsLoading = true;
            state.purchasedLeadsData = null;
            state.selectedSubscription = null;
            state.purchasedLeads = 0;
            state.totalLeads = 0
        })
        builder.addCase(getIndustryLeadThunk.fulfilled,(state,{payload}) =>{
            if(payload && payload.length > 0){
                let totalSubLeads = 0;
                state.purchasedLeadsLoading = false;
                state.purchasedLeadsData = payload; 
                state.totalLeads = payload.map((lead) => totalSubLeads = totalSubLeads + lead.LeadRemaining) 
                state.totalLeads = totalSubLeads           
                state.purchasedLeads = payload.reduce((accumulator, currentValue) => {return accumulator + currentValue.PurchasedCount}, 0)
                state.leadsRemaining = payload.reduce((accumulator, currentValue) => {return accumulator + currentValue.LeadRemaining}, 0)
               
                if(payload.length === 0){
                    return
                }
                if(payload.length === 1){
                  state.selectedSubscription = payload[0]
                } else{
                    const sortLeadsByDaysRemaining = payload.sort((a, b) => a.DaysRemaining - b.DaysRemaining)
                    const subscriptionWithLeadsRemaining = sortLeadsByDaysRemaining.filter(({LeadRemaining}) => (LeadRemaining > 0))
                    state.selectedSubscription = subscriptionWithLeadsRemaining[0]
                }
            }
        })
    }
})

export const {setData} = industryLeadSlice.actions