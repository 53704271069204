import { useDispatch, useSelector } from "react-redux";
import { VerifyOtpHoc } from "../../../otp/hoc/verifyOtpHoc/VerifyOtpHoc";
import { setRegistrationMobileNumber } from "../../slice/registrationSlice";
import { generateOtpThunk } from "../../../otp/thunk/otpThunk";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetMobileNumber, resetOtp } from "../../../otp/slice/otpSlice";
import { setSelectedCountryThunk } from "../../../global/appConfig/thunk/setSelectedCountryThunk";
import { getAllLanguageJsonThunk } from "../../../global/mtData/thunk/getAllLanguagesThunk";
import { useEffect } from "react";
import { V5MultiAdLayout } from "../../../../layout/multiAdLayout/V5MultiAdLayout";
import { NAVIGATION_CONSTANTS } from "../../../../../constants/naigationContants";

export const VerifyOtp = () => {
  const appConfig = useSelector((state) => state.appConfig);
  const registrationData = useSelector((state) => state.registration);
  const otpData = useSelector((state) => state.otp);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { defaultLanguage } = useSelector((state) => state.masterData);
  const assetIdParams = searchParams.get(
    NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID
  );

  useEffect(() => {
    if (!defaultLanguage) {
      dispatch(setSelectedCountryThunk("91"));
      dispatch(getAllLanguageJsonThunk("english.json"));
    }
  }, [!defaultLanguage]);

  const otpMatchHandler = (value) => {
    dispatch(setRegistrationMobileNumber(value));
    navigate(
      assetIdParams
        ? `/register/register?${NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID}=${assetIdParams}`
        : "/register/register"
    );
  };
  const resendOtpFunction = () => {
    dispatch(
      generateOtpThunk({
        UserTypeId: registrationData.selectedUserType.UserTypeId,
        BusinessTypeId: registrationData.selectedBusinessType.BusinessTypeId,
        MobileNumber: otpData.mobileNumber,
        CountryIsdCode: appConfig.country.CountryIsdCode,
      })
    );
  };

  const onResendOtpError = () => {
    dispatch(resetOtp());
    dispatch(resetMobileNumber());
    navigate("/register/generate-otp");
  };
  return (
    <V5MultiAdLayout
      justifyContent=""
      gap="2em"
      heading={defaultLanguage?.Lets_Get_You_Registered}
      subHeading={`${defaultLanguage?.Weve_sent_you_the_OTP_on} +${appConfig.country.CountryIsdCode} ${otpData.mobileNumber}.`}
    >
      <VerifyOtpHoc
        onOtpMatch={otpMatchHandler}
        resendOtpFunction={resendOtpFunction}
        onResendOtpError={onResendOtpError}
      />
    </V5MultiAdLayout>
  );
};
