import React,{useState,useRef, useEffect} from 'react';
import { useSelector } from "react-redux";
import { useNavigate} from "react-router-dom";
import {
  AlertDialog,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import { useQuery, useQueryClient} from "react-query";
import * as Yup from "yup";
import { Formik, Form,useField, Field } from "formik";
import {getVideotype  } from '../../../../../../../ApiEquip9/CommonApi';
import { openIndexedDB } from '../../../../../../../staticData/CommonMethod';
import { postMaterialVideos } from '../../../../../../services/materials/materials';

export const MaterailVideoUpload=({ isLogoutModalOpenPopUp,
    setIsLogoutModalOpenPopUp,MaterialId, onUploadComplete})=>{
    const userData = useSelector((state) => state.user);
    const onCloseLogoutModal = () => setIsLogoutModalOpenPopUp(false); 
    const cancelReff = React.useRef(); 
    const [ListVideoType,setListVideoType]=useState(null);
    const [selectedPhotoTypeId,setSelectedPhotoTypeId]=useState("");
    const [imagePreview, setImagePreview] = useState(null); 
    const [filePreview, setFilePreview] = useState(null);
    const [fileType, setFileType] = useState("");
    const [tempFile, setTempFile] = useState(null);
    const [selectedPhotoTypeName, setSelectedPhotoTypeName] = useState("");
    const fileInputRef = useRef();
    const toast=useToast();

    const handleFileChange = (event, setFieldValue) => {
        const file = event.currentTarget.files[0];
        const maxFileSize = 15 * 1024 * 1024; // 15 MB in bytes

        if (file && file.type.startsWith("video/") && file.size <= maxFileSize) {
            setFieldValue("file", file);
            setFileType(file.type);
            if (filePreview) {
                URL.revokeObjectURL(filePreview); // Release the old preview
            }
            setFilePreview(URL.createObjectURL(file));
            setTempFile(file); // Store the file temporarily 
        } else {
            if (file && file.size > maxFileSize) {
                // console.log("File Size exceeds");
            }
            setFieldValue("file", null);
            if (filePreview) {
                URL.revokeObjectURL(filePreview); // Release the old preview
            }
            setFilePreview(null);
        }
    };

/**************use query */

     const {data:getvideoType}=useQuery(
        'getvideoType',()=>getVideotype(userData.token)
    );
  
    /***** update result set */
     useEffect(()=>{
      setListVideoType(getvideoType?.data?.Data?.VideoTypeList);
     },[getvideoType]);

/*****************Store Video file index Db after selection of selectedPhotoTypeId  */  
    
useEffect(() => {
    const storeFileInIndexedDB = async () => {
      if (selectedPhotoTypeId && selectedPhotoTypeName && tempFile) {
        const reader = new FileReader();
  
        reader.onloadend = async () => {
          const base64String = reader.result;
  
          const db = await openIndexedDB();
          const transaction = db.transaction(["videos"], "readwrite");
          const store = transaction.objectStore("videos");
  
          // Including the ID, name, and Base64 string of the file in the object to be stored
          const request = store.put({
            id: selectedPhotoTypeId,
            name: selectedPhotoTypeName,
            file: base64String, // Storing the Base64 string
            position: parseInt(selectedPhotoTypeId)
          });
  
          request.onsuccess = () => {
            // console.log("File stored successfully");
            setTempFile(null); // Clear the temporary file after saving
          };
  
          request.onerror = (event) => {
            // console.log("Error storing file: " + event.target.error);
          };
        };
  
        // Read the file as a data URL (Base64 string)
        reader.readAsDataURL(tempFile);
      }
    };
  
    storeFileInIndexedDB();
  }, [selectedPhotoTypeId, selectedPhotoTypeName, tempFile]);
  
  
  



    /***************** */

    const FileUploadSchema = Yup.object().shape({
      file: Yup.mixed()
        .required(" Video is required"),
      seletedPhotoId: Yup.string().required("Please select video type")
    });


    /*********Submmit Form  */
    const submitForm = async (value,{ resetForm }) => {
      const payload={
        MaterialId:parseInt(localStorage.getItem('MaterialTypeId')),
        VideoTypeId:parseInt(selectedPhotoTypeId),  
        VideoFile:value.file
      }

      // debugger
      try{
        const result = await  postMaterialVideos(userData.token,payload);
        // console.log(result)
         if(result.data.Status===201){
          toast({
            title: "",
            description: `${result?.data?.Message?.SuccessMessage}`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          onUploadComplete();
          setFilePreview(null);
          setIsLogoutModalOpenPopUp(false);
          resetForm(); // Reset the form
          setSelectedPhotoTypeId(null); // Reset the selectedPhotoTypeId
          setSelectedPhotoTypeName("");

          
         }

      }
      catch(error){
     
        toast({
          title: "",
          description: `Error while uploading the Video`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    };


//    console.log("ListVideoType",ListVideoType)
    return(
        <div>
           <AlertDialog
        isOpen={isLogoutModalOpenPopUp}
        leastDestructiveRef={cancelReff}
        onClose={onCloseLogoutModal}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              <div
                className="material-pop-up-close"
                onClick={onCloseLogoutModal}
              >
                <ReactSVG
                   style={{cursor:'pointer'}}
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Materialpopclose.svg`}
                />
              </div>
              <div className="Material-pop-header-layout-Image">
                <ReactSVG
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}WebApp/Advertisements/LargeAdvertisments/icons/UploadMaterial.svg`}
                />
                <p className="Material-pop-header-heading">Upload Videos</p>
                <p className="Material-pop-header-subheading">
                Upload a video and select a name
                </p>
              </div> 
            </AlertDialogHeader>
            <div>
                <Formik
                  initialValues={{ file: "", seletedPhotoId: ""  }}
                  validationSchema={FileUploadSchema}
                  onSubmit={(values, { resetForm ,setSubmitting }) => {
                    submitForm(values, { resetForm })
                    //  console.log("Values",values)
                    setSubmitting(false);
                  }}
                >
                  {({ isSubmitting, isValid, dirty, setFieldValue, touched, errors }) => (
                    < div className="form-submit-button">
                        <div>
                        <Form>
                      <div className="form-submit-button" style={{border:"1px solid var(--Light-50, #E3E5E8)", maxWidth:"360px",height:"200px", maxHeight: '200px',marginLeft:"10%",marginTop:"12px" ,background:" var(--Light-30, #F5F5F5)"}}   >
                      {fileType.startsWith("video/") && filePreview ?(
                       <video width="100%" height="100%" controls
                          style={{maxWidth:"100%",maxHeight:"196px"}}
                        >
                       <source src={filePreview} type={fileType} />
                       Your browser does not support the video tag.
                     </video>
                      ):(<>
                      <div
                          style={{
                            maxWidth: "360px",
                            maxHeight: "200px",
                            marginTop:"85px"
                            
                          }}
                          >
                           No Video Added
                          </div>
                      </>)}
                     
                      </div>
                      <br />
                     
                      <div style={{display:'flex',alignItems:"center",justifyContent:"center"}}>
                      <label htmlFor="file" className={filePreview?"docbutton-ongoing":"docbutton-ongoing-change"}>
                   <p className={filePreview ? "upload-text-black" : "upload-text"}> Upload</p>
                </label>
                </div>
                      <input
                        id="file"
                        name="file"
                        type="file"
                         style={{ display: 'none' }} // Hide the default input
                         onChange={(event) => handleFileChange(event, setFieldValue)}
                      />
                      {touched.file && errors.file && <div className="error-text-ongoing">{errors.file}</div>}
                      
                      <div className="work-description-layout">
                      <label htmlFor="description" className="work-description-text">Name of Your Videos<span className="error-text-ongoing-star">*</span></label>
                      <br />
                      <Field
                        as="select"
                        id="description"
                        name="seletedPhotoId"
                        className="upload-type-photo-image"
                        value={`${selectedPhotoTypeId}__${selectedPhotoTypeName}`}
                        onChange={(e) => {
                            const [typeId, typeName] = e.target.value.split("__");
                            setSelectedPhotoTypeId(typeId);
                            setSelectedPhotoTypeName(typeName);
                            setFieldValue('seletedPhotoId', typeId);
                        }}
                      >
                       <option value="">Select an Option</option>
                       {ListVideoType?.map((item,index)=>(
                        <option key={index} value={`${item.VideoTypeId}__${item.VideoTypeValue}`}>
                        {item.VideoTypeValue}
                        </option>
                       ))

                       }

                      </Field>
                      {touched.seletedPhotoId && errors.seletedPhotoId && (
                        <div className="error-text-ongoing">{errors.seletedPhotoId}</div>
                      )}
                      </div>
                      <div className="submit-reject">
                      <button style={{marginTop:'2px'}} type="submit"  className="reject-button-ongoing" onClick={onCloseLogoutModal}>
                       <p className="accept-job-text">No Thanks</p> 
                      </button>
                      <button type="submit" className="accept-button-ongoing" disabled={isSubmitting}>
                      <p className="canceljob-text">Submit</p>
                      </button>
                      </div>
                      
                    </Form>
                        </div>
                   
                    </div>
                  )}
                </Formik>
              </div>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
        </div>
    )

}