import { createAsyncThunk } from "@reduxjs/toolkit";
import fetchHandler from "../../../services/fetchHandler";
import { API_CONSTANTS } from "../../../../constants/apiConstants";

export const initialFilterIndustryThunk = createAsyncThunk("industry/initialFilteredData",async(_, {getState}) => {
   
    const {filterByCountry ,filterByState,filterByCity,filterByInputMethod,filterByCategory,filterByTimeRange } = getState().industryRequirement


    let query = ""

    if(filterByInputMethod){
        query+= `&RequirementCategoryId=${filterByInputMethod}`
    }
    if(filterByCategory){
        query+= `&RequirementCategoryId=${filterByCategory}`
    }
    if(filterByTimeRange){
        query+= `&PostedOn=${filterByTimeRange}`
    }
    if(filterByCity){
        query+= `&City=${filterByCity}`
    }
    if(filterByCountry){
        query += `&Country=${filterByCountry}`
    }
    if(filterByState){
        query+= `&State=${filterByState}`
    }
   
    try{
        const {data} = await fetchHandler(
            `${API_CONSTANTS.GET_REQUIREMENT_FILTER_LIST}/12/0/?${query}`)
            if(data?.Status === 200){
                return data?.Data?.RequirementFilterList ;
            }
    }
    catch(error){
    }

    
})