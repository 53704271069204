import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  updateReedemAcceptReject,
} from "../../../services/myLeads/myLeads";

export const updateContactReedemDetailsThunk = createAsyncThunk(
  "myLeads/updateReedemContactDetails",
  async (payLoad, {}) => {
    try {
      const data = await updateReedemAcceptReject(payLoad);
      return data;
    } catch (error) {}
  }
);
