import React, { useState } from "react";
// import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { SideMenuLayout } from "../../layout/sideMenuLayout/SideMenuLayout";
import { DashboardNavBar } from "../../components/DashboardNavBar/DashboardNavBar";
import { MidSection } from "../../../sharedComponent/midSection/MidSection";
import { Box, Checkbox, Flex, useToast } from "@chakra-ui/react";
import { useDispatch} from "react-redux";
import { logoutUser } from "../global/user/slice/userSlice";
import { resetDocumentFlow } from "../documentFlow/slice/docFlowSlice";
import { useMutation } from "react-query";
import { Popupconformation } from "../../../sharedComponent/PopUp/ConfomationPopUp/ConformationUp";
import "./Settings.css";
import { deleteAccount } from "../../services/settings/settings";
import { V5SidebarLayout } from "../../layout/V5SidebarLayout/V5SidebarLayout";

export const DeleteAccount = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleCancel = () => {
    setShowConfirm(false);
    setIsCheckboxChecked(false);
  };

  const deleteUserAccount = useMutation(async () => {
    const data = await deleteAccount();
    if (data?.Status === 204) {
      toast({
        title: "",
        description: `Account deleted successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      localStorage.removeItem("token");
      dispatch(logoutUser());
      dispatch(resetDocumentFlow());
      navigate("/");
    } else {
      toast({
        title: "",
        description: `Erorr`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  });  const handleDeleteAccount = () => {
    deleteUserAccount.mutate();
    setIsCheckboxChecked(true)
  };

  const handleCheckboxChange = () => {
    setShowConfirm(true); // Show confirmation popup without changing checkbox state
  };
  return (
    <div>
      <V5SidebarLayout>
          <Box 
          >
            <h1 className="collapsed-div-header">Account Settings</h1>
            <h3
              style={{
                alignSelf: "flex-start",
                marginLeft: "2%",
                fontSize: "18px",
                font: "normal normal normal 18px/28px Assistant",
                letterSpacing: "0px",
                color: "#2C2C2CCC",
                opacity: "1",
              }}
            >
              Choose to delete your account
            </h3>
          
          <Flex borderRadius={"4px"}  padding={"2%"}                      >
           <Flex   
            border="1px solid #E3E5E8"
            boxShadow="0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)"
           p={"12px 20px"} w={"100%"} borderRadius={"4px"} justifyContent={"space-between"} >
           <h2
              style={{
                fontSize: "18px",
                font: "bold 18px/28px Assistant",
                color: "#002961",
                opacity: "1",
              }}
            >
              Do you want to delete your account ?
            </h2>
            <Box className="checkbox-manage"

            >
            <Checkbox
          colorScheme="green"
          onChange={handleCheckboxChange}
          isChecked={isCheckboxChecked} // Controlled by state
        />
              <div>Yes</div>
            </Box>
           </Flex>
          </Flex>
          </Box>
        <Popupconformation
          isOpen={showConfirm}
          onClose={handleCancel}
          onConfirm={handleDeleteAccount}
          message={"Do you want to delete your account?"}
        />
      </V5SidebarLayout>
    </div>
  );
};
