import React, { useState, useEffect, useRef } from "react";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  getUploadedVerificationDocumentList,
  getWorkDetail,
  deleteworkdetail,
} from "../../../../../../../../../../ApiEquip9/CommonApi";
import "./WorkExpirencegetDetail.css";
import { LoadingSkelaton } from "../../../../../../../../../components/LoadingSkaleton/LoadingSkelton";
export const GetWorkExpirenceDetails = ({
  onEdit,
  triggerRefresh,
  setWorkCompanyId,
}) => {
  const userData = useSelector((state) => state.user);
  const queryClient = useQueryClient();
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [isDocumentPopupOpen, setIsDocumentPopupOpen] = useState(false);
  const [documentImageUrl, setDocumentImageUrl] = useState("");
  const [documentPath, setDocumentPath] = useState("");
  const [documents, setDocuments] = useState([]);
  const [documentType, setDocumentType] = useState("");
  const [expandedCardId, setExpandedCardId] = useState(null);
  const [isCardExpanded, setIsCardExpanded] = useState(false);

  const toggleCard = () => {
    setIsCardExpanded(!isCardExpanded)
  };
  const yesButtonRef = useRef(null);
  const noButtonRef=useRef(null);

  const {
    data: getUploadedVerification,
    isLoading,
    isError,
  } = useQuery("getUploadedVerification", () =>
    getUploadedVerificationDocumentList()
  );

  // work detail  get list
  const { data: getworkDetails } = useQuery("getWorkDetails", () =>
    getWorkDetail()
  );

    const workDetail=getworkDetails?.data?.Data?.WorkCompanyExperienceList;

  const handleToggleExpansion = (id) => {
    if (expandedCardId === id) {
      setExpandedCardId(null);
    } else {
      setExpandedCardId(id);
    }
  };
  const handleEditClick = (equipmentId) => {
    setWorkCompanyId(equipmentId);
    queryClient.resetQueries("getCompanyExpirence"); // Set the equipment ID for editing.
    onEdit();
  };

  useEffect(() => {
    if (getworkDetails?.data?.Data?.WorkCompanyExperienceList) {
      setDocuments(getworkDetails?.data?.Data?.WorkCompanyExperienceList);
    }
  }, [getworkDetails]);

  const deleteVerificationMutation = useMutation(
    (WorkCompanyExperienceId) =>
      deleteworkdetail(WorkCompanyExperienceId),
    {
      onMutate: async (deletedId) => {
        await queryClient.cancelQueries("getWorkDetails");

        setDocuments((prevDocs) =>
          prevDocs.filter((item) => item.WorkCompanyExperienceId !== deletedId)
        );

        const previousData = queryClient.getQueryData("getWorkDetails");

        if (previousData) {
          queryClient.setQueryData("getWorkDetails", (oldData) => {
            const newData = { ...oldData };
            newData.data.Data.WorkCompanyExperienceList =
              newData.data.Data.WorkCompanyExperienceList.filter(
                (item) => item.WorkCompanyExperienceId !== deletedId
              );
            return newData;
          });
        }
        return { previousData };
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          queryClient.setQueryData("getWorkDetails", context.previousData);
          setDocuments(
            context.previousData.data.Data.WorkCompanyExperienceList
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries("getWorkDetails");
        triggerRefresh();
      },
    }
  );

  const openDocumentPopup = (imageUrl) => {
    const fileExtension = imageUrl.split(".").pop().toLowerCase();

    if (["jpg", "jpeg", "png"].includes(fileExtension)) {
      setDocumentType("image");
    } else if (fileExtension === "pdf") {
      setDocumentType("pdf");
    } else {
      return;
    }

    setDocumentImageUrl(imageUrl);
    setIsDocumentPopupOpen(true);
  };

  const closeDocumentPopup = () => {
    setIsDocumentPopupOpen(false);
  };

  const openConfirmationDialog = (WorkCompanyExperienceId) => {
    setDocumentToDelete(WorkCompanyExperienceId);
    setConfirmationDialog(true);
  };

  const closeConfirmationDialog = () => {
    setDocumentToDelete(null);
    setConfirmationDialog(false);
  };

  const deleteDocument = async () => {
    if (documentToDelete) {
      await deleteVerificationMutation.mutateAsync(documentToDelete);
      closeConfirmationDialog();
    }
  };


  return (
    <>
      {isLoading ? (
        <div style={{margin:"33px 33px"}}><LoadingSkelaton /></div>
      ) : isError ? (
        <div className="work-detail-error">
          No Work Details  added yet
          </div>
      ) : (
        <>
          <div className="card-layout">
            {documents?.map((item) => (
              <React.Fragment key={item.WorkCompanyExperienceId}>
                <div>
                  <div className={`get-verify-card ${isCardExpanded ? 'expanded' : ''}`}>
                    <div className="get-icon">

                      <div className="added-delete-verified">
                        <div
                          className="editButton"
                          onClick={() =>
                            handleEditClick(item.WorkCompanyExperienceId)
                          }
                        >
                          <div className='edit-button' style={{cursor:'pointer'}}>
                            <ReactSVG src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Edit.svg`} />
                          </div>
                        </div>
                        <div className="deleteButton"
                        style={{cursor:'pointer'}}
                          onClick={() =>
                            openConfirmationDialog(item.WorkCompanyExperienceId)
                          }
                        >
                          <ReactSVG
                            src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/garbage.svg`}
                          />
                        </div>
                        <div
                          style={{
                            height: "23px",
                            width: "75px",
                            borderRadius: "23px",
                            fontSize: "10px",
                            textAlign: "center",
                            paddingTop: "5px",
                            background:
                              item.IsE9Verified === 0 ? "rgba(214, 0, 0, 0.376)" : "#319f00",
                            color: "white",
                          }}
                        >
                          {item.IsE9Verified === 0
                            ? "Not Verified"
                            : "Verified"}
                        </div>
                      </div>
                      <div className="doc-support">
                        <div className="Doc-support-block">
                          <div className="get-document-name">
                            <div className="hero-document">
                              Company Name:
                            </div>
                            <div className="value-document-name">
                              {item.CompanyName}
                            </div>
          
                          </div>
                        <div className="space-given"></div>
                          <div >
                            <div className="hero-document">Location:</div>

                            <div className="value-document-name">
                              {`${item.AddressLine1},${item.CityName},${item.StateName},${item.CountryName} ${item.Zipcode}`}
                            </div>
                            <div className="space-given"></div>
                            <div>
                              <span  className="hero-document">
                                {" "}
                                Expirence:{" "}
                              </span>{" "}
                              <span className="value-document-name">
                                {" "}
                                {`${item.ExperienceInYear} Year, ${item.ExperienceInMonth} Month`}
                              </span>
                            </div>
                            <div className="space-given"></div>
                            <div>
                              <span className="hero-document">Is it your current company :</span>
                              {item.IsCurrentCompany === 0 ? (
                                <span className="check-current-compnay">
                                  <span className="value-document-name"> Yes</span>
                                </span>
                              ) : (
                                <span className="check-current-compnay">
                                  <span className="value-document-name">No</span>
                                </span>
                              )}
                            </div>
                            {item.IsCurrentCompany === 0 && (
                              <>
                                <div className="expand-card-work">
                                  <button
                                    onClick={() =>
                                      handleToggleExpansion(
                                        item.WorkCompanyExperienceId
                                      )
                                    }
                                  >
                                    {expandedCardId ===
                                      item.WorkCompanyExperienceId ? (
                                      <div className="view-document-detai" onClick={toggleCard}>
                                        View less
                                      </div>
                                    ) : (
                                      <div className="view-document-detai" onClick={toggleCard}>
                                        View More ...
                                      </div>
                                    )}
                                  </button>
                                  {expandedCardId ===
                                    item.WorkCompanyExperienceId && (
                                    <div className="extended-document-content">
                                      <div className="hero-document">
                                        Reference Name:
                                        <span className="value-document-name">
                                          {item.ReferenceName}
                                        </span>
                                      </div>
                                      <div className="hero-document">
                                        Reference Mobile Number:
                                        <span className="value-document-name">
                                          {item.ReferenceMobileNumber}
                                        </span>
                                      </div>
                                      <div className="hero-document">
                                        Reference Email Id:
                                        <span className="value-document-name">
                                          {item.ReferenceEmail}
                                        </span>
                                      </div>
                                      <div
                                        className="view-document-detai"
                                        onClick={() =>
                                          openDocumentPopup(
                                            `${process.env.REACT_APP_CDN_PVT_URL}SalarySlip/${item.AttachSalarySlipFileReference}`
                                          )
                                        }
                                      >
                                        View Salary Slip
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div></div>
                  </div>
                  {/*
                   */}
                </div>
              </React.Fragment>
            ))}
          </div>
          {
                     confirmationDialog && (
                      <div className='modal-backdrop'>
                          <div className='confirmation-dialog'>
                          <div className='deleteHeading'>
                            <ReactSVG
                                          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/HeadDelete.svg`}
                                      />
                            </div>
                              <div style={{marginTop:"12px"}}>
                              <p className="conformation-text">
                    Are you sure you want to delete this document?
                  </p>

                              </div>
                              
                              <button 
                                  ref={yesButtonRef}
                                  style={{
                                      // background: '#C6C7C8',
                                      transition: '0.3s'
                                  }}
                                  onMouseOver={() => {
                                      yesButtonRef.current.style.background = 'linear-gradient(90deg, var(--primary), var(--tertiary))';
                                  }}
                                  onMouseOut={() => {
                                       yesButtonRef.current.style.background = '#C6C7C8';
                                  }}
                                  onClick={deleteDocument}
                              >
                                  Yes
                              </button>
              
                              <button 
                                  ref={noButtonRef}
                                  style={{
                                      background: '#C6C7C8',
                                      transition: '0.3s'
                                  }}
                                  onMouseOver={() => {
                                    noButtonRef.current.style.background = 'linear-gradient(90deg, var(--primary), var(--tertiary))';
                                  }}
                                  onMouseOut={() => {
                                    noButtonRef.current.style.background = '#C6C7C8';
                                  }}
                                  onClick={closeConfirmationDialog}
                              >
                                  No
                              </button>
                          </div>
                      </div>
                  )
                }

          {isDocumentPopupOpen && (
            <div className="document-popup-container">
              <div className="document-popup">
                {documentType === "pdf" ? (
                  <embed
                    src={documentImageUrl}
                    type="application/pdf"
                    width="100%"
                    height="200px"
                  />
                ) : (
                  <img
                    src={documentImageUrl}
                    alt="Document"
                    style={{ width: "300px", height: "200px" }}
                  />
                )}
                <button className="close-button" onClick={closeDocumentPopup}>
                  <ReactSVG
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Cross.svg`}
                  />
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
