import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "@chakra-ui/icons";
import {
  setRegionModalClose,
  setRegionPayload,
} from "../../../features/MyBusinessDealershipDetail/slice/myBusinessDealershipSlice";

import "../DealershipPopUpCss.css";
import { useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { getCitiesById } from "../../../services/cities/cities";
import { getStatesById } from "../../../services/states/states";
import { CustomInfoModal } from "../../CustomInfoModal/CustomInfoModal";
import { CustomInputModal } from "../../CustomInputModal/CustomInputModal";

export const RegionModal = () => {
  const dispatch = useDispatch();
  const { isRegionOpen } = useSelector((state) => state.myBusinessDealership);
  const [stateList, setStateList] = useState();
  const [cityList, setCityList] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [regionStateData, setRegionStateData] = useState();
  const [regionCityData, setregionCityData] = useState();
  const userData = useSelector((state) => state.user);
  const toast = useToast();

  useEffect(() => {
    (async () => {
      const data = await getStatesById(99);
      setStateList(data);
    })();
  }, []);

  useEffect(() => {
    if (selectedState) {
      (async () => {
        const data = await getCitiesById(selectedState);
        setCityList(data);
      })();
    }
  }, [selectedState]);

  const initialValues = {
    state: "",
    city: "",
  };

  const validationSchema = Yup.object().shape({
    state: Yup.string().required("State Is Required"),
    city: Yup.string().required("City Is Required"),
  });
  const handleSubmit = async (values) => {
    const regionData = [
      {
        states: { id: regionStateData?.id, value: regionStateData?.value },
        cities: [{ id: regionCityData?.id, value: regionCityData?.value }],
      },
    ];
    dispatch(setRegionPayload(regionData));
    dispatch(setRegionModalClose());
  };

  const handleCityDelete = (city) => {};

  return (
    <>
      {/* <Modal isOpen={isRegionOpen} isCentered >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Box textAlign="right">
                            <Button
                                variant="ghost"
                                onClick={() => {
                                    dispatch(setRegionModalClose());
                                }}
                                boxShadow="none"
                            >
                                <CloseIcon />
                            </Button>
                        </Box>
                        <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
                            <img src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/Postdealership/region-modal.svg`} style={{ maxHeight: "40px", maxWidth: "40px" }} />
                        </Flex>

                        <Box>
                            <Text className="additional-details-header-text" fontSize={"28px"} textAlign={"center"}>Select Your Region</Text>
                            <Text color={"#333"} fontSize={"24px"} fontWeight={"600"} textAlign={"center"}>Select Your State and Multiple Cities</Text>
                        </Box>

                    </ModalHeader>

                    <ModalBody>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            validationSchema={validationSchema}
                        >
                            {({ values, isSubmitting, setFieldValue }) => (
                                <Form>
                                    <>
                                    <div>
                                            <FormLabel>State</FormLabel>
                                            <Field
                                                as={Select}
                                                name="state"
                                                onChange={(e) => {
                                                    if (e.target.value) {
                                                        const state = stateList.find(
                                                            (state) => state.value === e.target.value
                                                        );
                                                        setSelectedState(state.id);
                                                        setFieldValue("state", state.value);
                                                        setFieldValue("city", "");
                                                        setRegionStateData(state)
                                                        setregionCityData(null);
                                                    } else {
                                                        setSelectedState(null);
                                                        setFieldValue("state", "");
                                                        setFieldValue("city", "");
                                                        setRegionStateData(null)
                                                        setregionCityData(null)
                                                    }
                                                }}
                                            >
                                                <option value="">
                                                    {stateList ? "Select State" : "No State List Found"}
                                                </option>
                                                {stateList
                                                    ? stateList.map((state) => (
                                                        <option value={state.value} key={state.id}>
                                                            {state.value}
                                                        </option>
                                                    ))
                                                    : null}
                                            </Field>
                                        <ErrorMessage
                                                name="state"
                                                component="div"
                                                className="error-message"
                                            />
                                            </div>

                                            <FormLabel>City</FormLabel>
                                            <Field
                                                as={Select}
                                                name="city"
                                                onChange={(e) => {
                                                    const selectedCity = cityList.find((city) => city.value === e.target.value);
                                                    setregionCityData(selectedCity);
                                                    setFieldValue("city", e.target.value);
                                                }}
                                            >
                                                <option value="">
                                                    {cityList ? "Select City" : "No City List Found"}
                                                </option>
                                                {cityList
                                                    ? cityList.map((city) => (
                                                        <option value={city.value} key={city.value}>
                                                            {city.value}
                                                        </option>
                                                    ))
                                                    : null}
                                            </Field>
                                            <ErrorMessage
                                                name="city"
                                                component="div"
                                                className="error-message"
                                            />

                                        <Flex alignItems={"center"} justifyContent={"center"} margin={"1em"}>
                                            <Button backgroundColor={"#F79C00"} color={"white"} type="submit">
                                                Submit
                                            </Button>
                                        </Flex>
                                    </>
                                </Form>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal> */}

      <CustomInputModal
        isOpen={isRegionOpen}
        hasCloseButton={true}
        onCloseButtonClick={() => {
          dispatch(setRegionModalClose());
        }}
        heading={"Select Your Region"}
        subHeading={"Select Your State and Multiple Cities"}
        icon={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/Postdealership/region-modal.svg`}
      primaryActionText={"Submit"}
      >
        <Flex direction={"column"} 
         justifyContent={"center"}
         alignItems={"center"}
         >
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <Form id="inputForm"

            style={{
                width:"100%",
                maxWidth:"350px"
            }}
            >
              <>
                <div>
                  <FormLabel>State</FormLabel>
                  <Field
                    as={Select}
                    name="state"
                    onChange={(e) => {
                      if (e.target.value) {
                        const state = stateList.find(
                          (state) => state.value === e.target.value
                        );
                        setSelectedState(state.id);
                        setFieldValue("state", state.value);
                        setFieldValue("city", "");
                        setRegionStateData(state);
                        setregionCityData(null);
                      } else {
                        setSelectedState(null);
                        setFieldValue("state", "");
                        setFieldValue("city", "");
                        setRegionStateData(null);
                        setregionCityData(null);
                      }
                    }}
                  >
                    <option value="">
                      {stateList ? "Select State" : "No State List Found"}
                    </option>
                    {stateList
                      ? stateList.map((state) => (
                          <option value={state.value} key={state.id}>
                            {state.value}
                          </option>
                        ))
                      : null}
                  </Field>
                  <ErrorMessage
                    name="state"
                    component="div"
                    className="error-message"
                  />
                </div>

                <FormLabel>City</FormLabel>
                <Field
                  as={Select}
                  name="city"
                  onChange={(e) => {
                    const selectedCity = cityList.find(
                      (city) => city.value === e.target.value
                    );
                    setregionCityData(selectedCity);
                    setFieldValue("city", e.target.value);
                  }}
                >
                  <option value="">
                    {cityList ? "Select City" : "No City List Found"}
                  </option>
                  {cityList
                    ? cityList.map((city) => (
                        <option value={city.value} key={city.value}>
                          {city.value}
                        </option>
                      ))
                    : null}
                </Field>
                <ErrorMessage
                  name="city"
                  component="div"
                  className="error-message"
                />

               
              </>
            </Form>
          )}
        </Formik>
        </Flex>
      </CustomInputModal>
    </>
  );
};
