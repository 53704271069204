import { API_CONSTANTS } from "../../../constants/apiConstants"
import {fetchHandler} from "../../../index"
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData"

export const getPostedJobsList = async(pageIndex, count) => {
    if(!pageIndex && !count && isNaN(parseInt(pageIndex)) && isNaN(parseInt(count))){
        throw new Error("pageIndex and count is required")
    }
    try{
       
        const {data} = await fetchHandler(`${API_CONSTANTS.IN_PROCESS_JOBS_LIST_FOR_OWNER}/${count}/${pageIndex}`)
        if(data.Status === 200 && Object.keys(data.Data).length > 0){
            return {data: data.Data.NewJobs, totalJobs: data.Data.TotalJobs}

        }
        else{
            
            throw new Error(data.Message.FailMessage)
        }
    }
    catch(error){
        throw error
    }
}

export const getPostedJobsListForCompany = async(pageIndex, count, taxNumber) => {
    if(!pageIndex && !count && isNaN(parseInt(pageIndex)) && isNaN(parseInt(count))){
        throw new Error("pageIndex and count is required.")
    }
    if(!taxNumber){
        throw new Error("Tax Number is required.")
    }
    try{
       
        const {data} = await fetchHandler(`${API_CONSTANTS.IN_PROCESS_JOBS_LIST_FOR_OWNER}/${count}/${pageIndex}`, {TaxNumber : btoa(taxNumber)});
        if(data.Status === 200 && Object.keys(data.Data).length > 0){
            return {data: data.Data.NewJobs, totalJobs: data.Data.TotalJobs}

        }
        else{
            throw new Error(data.Message.FailMessage)
        }
    }
    catch(error){
        throw error
    }
}


export const getFilteredPartners=async (cardCount,offset,query)=>{
    try {
        const {data} = await fetchHandler(`${API_CONSTANTS.GET_MARKETPLACE_FILTER}/${cardCount}/${offset}${query}`)
        return data;

    } catch (error) {
        throw error;
    }
}

export const acceptRejectMpJobRequest = async (payload) => {
    try {

    const {data} = await fetchHandler("/AcceptRejectNewJobRequestOfMaintenance",{},{},payload,API_CONSTANTS.HTTP_PUT)
    return data;

    }
    catch (err) {
      throw err.response;
    }
  }

  export const acceptRejectOpJobRequest = async (payload) => {
    try {
    const {data} = await fetchHandler("/AcceptRejectNewJobRequestOfOperator",{},{},payload,API_CONSTANTS.HTTP_PUT);
    return data;

    }
    catch (err) {
      throw err.response;
    }
  }

  export const viewJobRequestOfMaintenance = async (cardId) => {
    try {
    const {data} = await fetchHandler(`/ViewJobRequestOfMaintenance/${cardId}`);
    return data;

    }
    catch (err) {
      throw err.response;
    }
  }

  export const viewNewJobRequestOfOperator = async (cardId) => {
    try {
    const {data} = await fetchHandler(`/ViewNewJobRequestOfOperator/${cardId}`);
    return data;

    }
    catch (err) {
      throw err.response;
    }
  }

  export const viewOperatorMantenancePartnerProfile = async (partnerId) => {
    try {
    const {data} = await fetchHandler(`/ViewOperatorMantenancePartnerProfile/${partnerId}`);
    return data;

    }
    catch (err) {
      throw err;
    }
  }

  export const getAllMaintenanceJobsWaitingForYou = async (cardCount,offset) => {
    try {
    const {data} = await fetchHandler(`/GetAllMaintenanceJobsWaitingForYou/${cardCount}/${offset}`);
    return data;

    }
    catch (err) {
      throw err;
    }
  }

  export const getAllTopMaintenancePartnersList = async (cardCount,offset) => {
    try {
    const {data} = await fetchHandler(`${API_CONSTANTS.GET_ALL_TOP_MAINTENANCE_PARTNER_LIST}/${cardCount}/${offset}`);
    return data;

    }
    catch (err) {
      throw err;
    }
  }

  export const getAllOperatorJobsWaitingForYou = async (cardCount,offset) => {
    try {
    const {data} = await fetchHandler(`${API_CONSTANTS.GET_ALL_OPERATOR_JOBS_WAITING_FOR_YOU}/${cardCount}/${offset}`);
    return data;

    }
    catch (err) {
      throw err;
    }
  }

  export const getAllTopOperatorPartnersList = async (cardCount,offset) => {
    try {
    const {data} = await fetchHandler(`${API_CONSTANTS.GET_ALL_TOP_OPERATOR_PARTNER_LIST}/${cardCount}/${offset}`);
    return data;

    }
    catch (err) {
      throw err;
    }
  }

  export const createOperatorJobDetails = async (body) => {
    try {
      const {data}= await fetchHandler(API_CONSTANTS.CREATE_OPERTOR_JOB_DETAILS,{},{},convertToFormData(body),API_CONSTANTS.HTT_POST);
    return data;

    }
    catch (err) {
      throw err;
    }
  }

  export const createOperatorJobSearchFilters = async (body) => {
    try {
      const {data}= await fetchHandler(API_CONSTANTS.CREATE_OPERATOR_JOB_SEARCH_FILTER,{},{},convertToFormData(body),API_CONSTANTS.HTT_POST);
    return data;

    }
    catch (err) {
      throw err;
    }
  }

  
  export const MPcompletedfulltimejobs = async () => {
    try {
   
      const response = await fetchHandler("/GetFullTimeMaintenanceCompletedJobList/4/0");
      return response;
    }
    catch (error) {
      throw error.response
    }
  }

  export const MPcompletedQuickjobs = async () => {
    try {
      const response = await fetchHandler("/GetQuickMaintenanceCompletedJobList/4/0");
      return response;
    }
    catch (error) {
      throw error.response
    }
  }

  export const OPcompletedjob = async () => {
    try {
      const response = await fetchHandler("/GetOperatorCompletedJobList/6/0");
      return response;
    }
    catch (error) {
      throw error.response
    }
  }

  export const MPCancelledJobList = async () => {
    try {
      const response = await fetchHandler("/GetFullTimeMaintenanceCancelledJobList/3/0");
      return response;
    }
    catch (error) {
      throw error.response
    }
  }

  export const OPCancelledJobList = async () => {
    try {
      const response = await fetchHandler("/GetOperatorCancelledJobList/3/0");
      return response;
    }
    catch (error) {
      throw error.response
    }
  }

  

export const opUpComingJobList = async () => {
  try {
    const response = await fetchHandler(
      "/GetOperatorUpcomingJobList/1/0",
      {},
      {},
      {},
      API_CONSTANTS.HTTP_GET,
      {isAuthRequired : true}
    );
    return response;
  }
  catch (error) {
    throw error.response
  }
}



export const MPUpComingJobList = async () => {
  try {
    const response = await fetchHandler("/GetFullTimeMaintenanceUpcomingJobList/3/0");
    return response;
  }
  catch (error) {
    throw error.response
  }
}

export const MPQucikUpComingJobList = async () => {
  try {
    const response = await fetchHandler("/GetQuickMaintenanceUpcomingJobList/3/0");
    return response;
  }
  catch (error) {
    throw error.response
  }
}


export const getUpcominglistMP = async () => {
  try {
    const response = await fetchHandler("/GetFullTimeMaintenanceOngoingJobList/4/0");
    return response;
  }
  catch (error) {
    throw error.response
  }
}

export const getongoinglistOP = async () => {
  try {
    const response = await fetchHandler("/GetOperatorOngoingJobList/4/0");
    return response;
  }
  catch (error) {
    throw error.response
  }
}

export const getOngoingMPQuickJobs = async () => {
  try {
  
    const response = await fetchHandler("/GetQuickMaintenanceOngoingJobList/10/0");
    return response;
  }
  catch (error) {
    throw error.response
  }
}


export const getCompletedMPOPjobDescription = async (JobId) => {
  try {
    const response = await fetchHandler(`/GetOPMPCompletedJobDetailsForPartnerByJobId/${JobId}`);
    return response
  }
  catch (error) {
    throw error.response;
  }

}


export const getOwnerJobDetailsbyID = async (JobId) => {
  try {
    const response = await fetchHandler(`/GetConfirmedJobOwnerDetails/${JobId}`);
    return response
  }
  catch (error) {
    throw error.response;
  }
}

export const getupcomingMPjobDescription = async (JobId) => {
  try {
    const response= await fetchHandler(`/GetOPMPOngoingJobDetailsForPartnerByJobId/${JobId}`);
    return response
  }
  catch (error) {
    throw error.response;
  }

}

export const jobDescriptionUpcomingMP = async (JobId) => {
  try {
    const response = await fetchHandler(`/GetOPMPUpcomingJobDetailsForPartnerByJobId/${JobId}`);
    return response
  }
  catch (error) {
    throw error.response;
  }
}