
import React, { useEffect, useState } from 'react'
import { MarketPlaceLayout } from '../../../layout/dealershipMarketplaceLayout/MarketPlaceLayout'
import { Box, Divider, Flex, Text, useToast } from '@chakra-ui/react'

import { MaterialDetailCard } from './MaterialDetailCard'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getLocalStorageItems } from '../../../helpers/localStorage/localStorage'
import { convertToBase64 } from '../../../../utils/formatConverter/convertToBase64'
import { getAllMarketPlaceServices } from '../../../services/materials/materials'
import { QrPopUp } from '../../../components/ScanQrCodeModal/QrPopUp'
import { openQrModal } from '../../dashboard/slice/dashboardSlice'

export const MaterialAssestPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {token} = useSelector((state)=>state.user);
    const [materialList,setMaterialList] = useState();
    const userData = useSelector((state)=>state.user)
    const gstTaxNumber = userData?.accountDetails?.CompanyDetails?.TaxNumber;
    const [listedCount,setListedCount] = useState();
    const toast = useToast()
    const useTypeValue = getLocalStorageItems("USER_SELECTED_USER_TYPE");
    // console.log("useTypeValue",useTypeValue);


    // useEffect(()=>{
    //   (async()=>{
    //     const response = await getMaterialType(token)
    //     if(response?.data?.Status === 200){
    //       setMaterialList(response?.data?.Data?.MaterialType)
    //     }
    //   })()
    // },[])

    useEffect(()=>{
      (async()=>{
        const data = await getAllMarketPlaceServices();
        if(data?.Status === 200){
          setMaterialList(data?.Data?.MaterialList)
        }
      })()
    },[])
    const handleNavigation = (MaterialTypeValue, MaterialTypeId) => {
      // if (useTypeValue.toLowerCase() === "company".toLowerCase() && !gstTaxNumber) {
      //   toast({
      //     title: "",
      //     description: "Please Update Your Tax Number",
      //     status: "error",
      //     duration: 5000,
      //     isClosable: true,
      //     position: "top-right",
      //   });
      //   return; 
      // }
    
      const materialPath =
        MaterialTypeValue.toLowerCase() === "spare parts"
          ? "spare-parts"
          : MaterialTypeValue.toLowerCase() === "oil"
          ? "oil"
          : MaterialTypeValue.toLowerCase() === "concrete"
          ? "concrete"
          : MaterialTypeValue.toLowerCase() === "generators"
          ? "generators"
          : MaterialTypeValue.toLowerCase() === "safety equipment"
          ? "safety-equipment"
          : "";
    
      if (materialPath) {
        navigate(`/materials/${materialPath}?materialTypeId=${convertToBase64(MaterialTypeId)}`);
      }else{
        dispatch(openQrModal())
      }
    };
  return (
    <>
     <QrPopUp />
    <MarketPlaceLayout>
                <div  className='material-assest-container'>
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Material 
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box>
                    <Flex
                      gap="2em"
                      flexDirection="row"
                      flexWrap="wrap"
                      padding="1em 0"
                    >
                      {materialList ? materialList.map((material) =>(
                        <MaterialDetailCard
                        imageUrl={`${process.env.REACT_APP_CDN_PVT_URL}MobileAppAssets/Material/${material.MaterialTypeIconFileRef}`}
                        heading={material.MaterialTypeValue}
                        onSubmitButtonClick={()=>{
                          handleNavigation(material.MaterialTypeValue,material?.
                            MaterialTypeId)
                        }}
                        description={material?.MaterialCount}
                      />
                      )) : (
                        <p>No Material Found</p>
                      )}
                      
                    </Flex>
                  </Box>
                </div>
    </MarketPlaceLayout>
    </>
  )
}
