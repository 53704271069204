export const DOCUMENT_FLOW_PAGES = {
  PARTNER: {
    PROFILE_PICTURE: {
      route: "/document-flow/upload-profile-picture",
      name: "Profile Picture",
      isSkippable: true,
    },
    E9_ROLE: {
      route: "/document-flow/expertise",
      name: "E9 Roles",
      isSkippable: true,
    },
    EQUIPMENT_SKILL: {
      route: "/document-flow/upload-equipment-skill",
      name: "Equipment Info",
      isSkippable: false,
    },
    VERIFICATION_DOCUMENT: {
      route: "/document-flow/upload-verification-document",
      name: "Verification Document",
      isSkippable: false,
    },
    LICENSES: {
      route: "/document-flow/upload-license",
      name: "Driving License",
      isSkippable: false,
    },
  },
  OWNER_INDIVIDUAL: {
    PROFILE_PICTURE: {
      route: "/document-flow/upload-profile-picture",
      name: "Profile Picture",
      isSkippable: true,
    },
    EQUIPMENT_SKILL: {
      route: "/document-flow/upload-equipment-skill",
      name: "Equipment Info",
      isSkippable: true,
    },
    VERIFICATION_DOCUMENT: {
      route: "/document-flow/upload-verification-document",
      name: "Verification Document",
      isSkippable: true,
    },

    
  },
  
  OWNER_COMPANY: {
    DESIGNATION: {
      route: "/document-flow/select-designation",
      name: "Designation",
      isSkippable: false,
    },
    PROFILE_PICTURE: {
      route: "/document-flow/upload-profile-picture",
      name: "Profile Picture",
      isSkippable: true,
    },
    E9_ROLE: {
      route: "/document-flow/expertise",
      name: "Expertise",
      isSkippable: true,
    },
    BUSINESS_IDENTIFICATION_DOCUMENT: {
      route: "/document-flow/upload-business-identification",
      name: "Business Identification",
      isSkippable: true,
    },
    EQUIPMENT_SKILL: {
      route: "/document-flow/upload-equipment-skill",
      name: "Equipment Info",
      isSkippable: true,
    },
    VERIFICATION_DOCUMENT: {
      route: "/document-flow/upload-verification-document",
      name: "Verification Document",
      isSkippable: true,
    }
    
  },
  PARTNER_COMPANY:{
    DESIGNATION: {
      route: "/document-flow/select-designation",
      name: "Designation",
      isSkippable: false,
    },
    PROFILE_PICTURE: {
      route: "/document-flow/upload-profile-picture",
      name: "Profile Picture",
      isSkippable: true,
    },
    E9_ROLE: {
      route: "/document-flow/expertise",
      name: "E9 Roles",
      isSkippable: true,
    },
    BUSINESS_IDENTIFICATION_DOCUMENT: {
      route: "/document-flow/upload-business-identification",
      name: "Business Identification",
      isSkippable: true
    },
    EQUIPMENT_SKILL: {
      route: "/document-flow/upload-equipment-skill",
      name: "Equipment Info",
      isSkippable: false,
    },
    VERIFICATION_DOCUMENT: {
      route: "/document-flow/upload-verification-document",
      name: "Verification Document",
      isSkippable: false,
    },
    


  }
};



