import { createAsyncThunk } from "@reduxjs/toolkit";
import { getE9MarketPlaceUsedEquipmentList } from "../../../services/equipments/equipments";

export const getUemInitialDataThunk = createAsyncThunk("uem/getInitialCards", async(_, {getState}) => {
const cardCount = getState().usedEquipmentMarketplace.cardCount
try{
    const data = await getE9MarketPlaceUsedEquipmentList(cardCount,0);
    
    if(data.Status === 200 && Object.keys(data.Data).length > 0){
        return data.Data
    }

   
}
catch(error){
}
})