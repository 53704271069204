import React from "react";
import "./DealershipProductCard.css";
import likeIconUnliked from "../../../assets/webapp/marketplace/like-icon-unliked.svg";

const DealershipProductCard = ({
  imageUrl,
  isPromoted,
  location,
  equipmentName,
  financeAvailable,
  productUniqueId,
  updatedDateTime,
  equipmentMakeValue,
}) => {
  // console.log("Updated",updatedDateTime.split(' ')[0].trim());
  return (
    <div className="dealership-product-card-container">
      <div className="dealership-product-card-img">
        <img
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            borderRadius: "4px",
          }}
          alt="image"
          src={`${process.env.REACT_APP_CDN_PVT_URL}${imageUrl}`}
          className="dealership-product-card-image"
        />
      </div>

      <div className="dealership-body-product-card-container">
        <div className="dealership-body-product-card-container1">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span className="materials-product-promoted-text">
              {isPromoted === 1 ? "isPromoted" : "Not Promoted"}
            </span>
            <img className="" src={likeIconUnliked} />
          </div>
          <h1 className="dealership-text3">{location}</h1>
          <h1 className="dealership-text3">
            {equipmentName} | Finance available :
            {financeAvailable ? financeAvailable : "NA"}
          </h1>
        </div>

        <div>
          <div className="dealership-details-section">
            <div className="dealership-text-row">
              <label className="dealership-text2">Id: </label>
              <span className="dealership-text3">
                {productUniqueId ? productUniqueId : "NA"}
              </span>
              <span className="dealership-text3" style={{ marginLeft: "10px" }}>
                {equipmentMakeValue ? equipmentMakeValue : "NA"}
              </span>
            </div>
            <div className="dealership-text-row">
              <label className="dealership-text2">Added on: </label>
              <span className="dealership-text3">
                {updatedDateTime ? updatedDateTime?.split(" ")[0].trim() : "NA"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealershipProductCard;
