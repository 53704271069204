import "./HighlightedElement.css"

export const HighlightedElement = ({stats, category, image}) => {
    return(
    <div className="highlighted-element__wrapper">

      <div className="highlighted-element">
        <img src={image} alt={`${category}-icon`} />
       <h1 className="highlighted-element__heading">{stats}</h1>
       <p className="highlighted-element__para">{category}</p>
      </div>
    </div>
    )
}