import { Flex, Text } from "@chakra-ui/layout";
import { v4 as uuid } from "uuid";
import { CustomInfoModal } from "../CustomInfoModal/CustomInfoModal";

export const WelcomePopUp = ({
  iconUrl = "",
  isOpen = false,
  descriptionArray = [],
  location = "store",
  onButtonClick = () => {},
}) => {


  const listIcons=[
    `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/popup/addStore.svg`,
    `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/popup/listMarketplace.svg`,
   `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/popup/viewLeads.svg`
  ]

  return (
    <CustomInfoModal
      isOpen={isOpen}
      iconUrl={iconUrl}
      heading={`Welcome to ${location}`}
      primaryAction={onButtonClick}
      primaryActionText={"Next"}
      hasCloseButton={true}
      onCloseButtonClick={onButtonClick}
    >
      <Flex maxH="250px" overflowY="scroll" flexDirection="column" gap="1.25em" marginBottom={"20px"}>
        {descriptionArray && descriptionArray.length > 0 ? (
          descriptionArray.map((description, index) => (
            <Flex key={uuid()}>
              <Flex
              minW={"100px"}
                color="#484848"
                fontSize="16px"
                lineHeight="21px"
                fontWeight="700"
                gap={"10px"}
              >
                <Flex w={"20px"} h={"20px"}>
                  <img src={listIcons[index]}/>
                </Flex>
                {`Step ${index + 1}:`}</Flex>{" "}
              <Text
                color="#000000"
                fontWeight={"400"}
                fontSize="16px"
                lineHeight="21px"
              >{`${description}`}</Text>
            </Flex>
          ))
        ) : (
          <Text>Please close the pop up to access the feature</Text>
        )}
      </Flex>
    </CustomInfoModal>
  );
};
