import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useField } from "formik";

export const FormikDoubleInputField = ({
  firstLabel,
  firstPlaceholder,
  firstInputName,
  secondLabel,
  secondPlaceholder,
  secondInputName,
  type,
  isRequired,
  maxLength,
}) => {
  const [firstField, firstMeta, firstHelpers] = useField(firstInputName);
  const [secondField, secondMeta, secondHelpers] = useField(secondInputName);

  return (
    <Flex gap="1em" maxW="374px">
      <FormControl
        isInvalid={firstMeta.touched && firstMeta.error}
        isRequired={isRequired}
      >
        <FormLabel>{firstLabel}</FormLabel>
        <Input
          name={firstInputName}
          type={type}
          value={firstField.value}
          placeholder={firstPlaceholder}
          onChange={firstField.onChange}
          maxLength={maxLength}
          h="52px"
          border="1px solid #2c2c2c14"
          focusBorderColor="#2c2c2c"
          bgColor={firstField.value ? "#fff" : "#2c2c2c05"}
          {...firstField}
        />
        <FormErrorMessage>
          {firstMeta.touched && firstMeta.error ? firstMeta.error : " "}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={secondMeta.touched && secondMeta.error}
        isRequired={isRequired}
      >
        <FormLabel sx={{ visibility: "hidden" }}>{secondLabel}</FormLabel>
        <Input
          name={secondInputName}
          type={type}
          value={secondField.value}
          placeholder={secondPlaceholder}
          onChange={secondField.onChange}
          maxLength={maxLength}
          h="52px"
          border="1px solid #2c2c2c14"
          focusBorderColor="#2c2c2c"
          bgColor={secondField.value ? "#fff" : "#2c2c2c05"}
          {...secondField}
        />
        <FormErrorMessage>
          {secondMeta.touched && secondMeta.error ? secondMeta.error : " "}
        </FormErrorMessage>
      </FormControl>
    </Flex>
  );
};
