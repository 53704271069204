import { Card } from "../Card/Card";
import { subString } from "../../helpers/dataConversion/substring";
import shareIcon from "../../../assets/webapp/dashboard/share.svg";
import defaultIcon from "../../../assets/webapp/marketplace/defaultAssetImage.png";
import AvatarIcon from "../../../assets/user.jpg";
import { ReactSVG } from "react-svg";
import "./EquipmentMarketplaceCard.css";
import { convertPrice } from "../../helpers/dataConversion/convertPrice";
import { Like } from "../Like/Like";
import { Flex } from "@chakra-ui/react";

export const EquipmentMarketplaceCard = ({
  imageUrl,
  userImageUrl,
  equipmentTypeValue,
  oemName,
  modelName,
  price = 100000,
  paymentCycle,
  listedTime,
  status,
  ownerName,
  state = "",
  country = "",
  equipmentId = "",
  buttonText = "Response",
  buttonColor = "#F67C00",
  isPromoted,
  isVerified,
  onClick,
  isLiked = false,
  onShareClick = () => {},
  onLikeClick = () => {},
  onContactClick = () => {},
  isCtaButton = true,
  isShareEnabled = true,
  isVerifiedEnabled = true,
  isProfileEnabled = true,
}) => {
  return (
    <>
      <Card type="equipmentMarketplaceCard">
        <div className="equipment-marketplace-card">
          <div className="equipment-marketplace-card__header-section">
            <p className="equipment-marketplace-card__header-section__text">
              {subString(
                40,
                ` Id:${[
                  equipmentId,
                  equipmentTypeValue,
                  oemName,
                  modelName,
                ].join(" • ")}`
              )}
            </p>

            <div style={{display: isShareEnabled ? "" : "none"}}>
              <button onClick={onShareClick}>
                <ReactSVG
                  src={shareIcon}
                  beforeInjection={(svg) => {
                    svg.setAttribute("style", "height: 20px");
                  }}
                />
              </button>
              {/* <button 
                   onClick={onLikeClick}
                  > */}
              {/* <ReactSVG
                      src={likeIconUnliked}
                      beforeInjection={(svg) => {
                        svg.setAttribute("style", "height: 20px");
                      }}
                    /> */}
              <Like
                isLiked={isLiked}
                onClick={onLikeClick}
                // onDislikeClick={onDislikeClick}
              />
              {/* </button> */}
            </div>
          </div>
          <div className="equipment-marketplace-card__image-section">
            <Flex justifyContent={"space-between"} width={"100%"}>
            {isPromoted? <div className="equipment-marketplace-card__image-section-tags">Promoted</div>:<></>}
              <div className="equipment-marketplace-card__image-section-tags" style={{ right: 0,backgroundColor:isVerified? "#00b33c":"#D6000050", display: isVerifiedEnabled ? "" : "none"}}>{isVerified?"Verified":"Not Verified"}</div>
            </Flex>
            <img
              className="equipment-marketplace-card__image-section__image"
              src={imageUrl}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = defaultIcon;
              }}
            />
          </div>
          <div
            className="equipment-marketplace-card__detail-section"
            onClick={onClick}
          >
            <p className="equipment-marketplace-card__detail-section_rate">
              {/* {`${paymentCycle ? `${} / ${paymentCycle}` : `convertPrice(parseInt(price)`)}} */}
              {paymentCycle
                ? `${convertPrice(parseInt(price))} / ${paymentCycle}`
                : convertPrice(parseInt(price))}
            </p>
            <p className="equipment-marketplace-card__detail-section_location">
              {subString(30, [state, country].join(", "))}
            </p>
            <div className="equipment-marketplace-card__detail-section_status-info">
              <p>
                <span>Status:</span>{" "}
                <span style={{ fontWeight: "600", color: "black" }}>
                  {status}
                </span>
              </p>
              <span style={{ fontWeight: "600", color: "black" }}>•</span>

              <p>
                <span>Listed:</span>{" "}
                <span style={{ fontWeight: "600", color: "black" }}>
                  {listedTime}
                </span>
              </p>
            </div>
          </div>
          <div className="equipment-marketplace-card__footer-section">
            <div className="equipment-marketplace-card__footer-section__profile" >
              <img
                className="equipment-marketplace-card__footer-section__image--blur"
                src={userImageUrl}
                style={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  border: "1px solid #2c2c2c80",
                  display: isProfileEnabled ? "" : "none"
                }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = AvatarIcon;
                }}
              />

              <span>
                {ownerName?.split(" ")[0]?.charAt(0).toUpperCase()}
                {ownerName?.split(" ")[1]?.charAt(0).toUpperCase()}
              </span>
            </div>
            {isCtaButton ? (
              <button
                className="equipment-marketplace-card__footer-section__contact-btn"
                style={{ borderColor: buttonColor, color: buttonColor }}
                onClick={(e) => {
                  onContactClick();
                  e.stopPropagation();
                }}
              >
                {buttonText}
              </button>
            ) : (
              <p style={{ color: buttonColor, padding: "0 8px" }}>
                {buttonText}
              </p>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};
