import { ReactSVG } from "react-svg";
import { Card } from "../Card/Card";
import { VerificationBadge } from "../CardVerificationBadge/CardVerificationBadge";
import shareIcon from "../../../assets/webapp/dashboard/share.svg";
import "./JobMarketplaceCard.css";
import { Box, Flex, Text } from "@chakra-ui/react";

export const JobMarketplaceCard = ({
  verificationText = "",
  verificationStatus,
  jobId = "",
  postedOn = "",
  name = "",
  location = "",
  money = "NA",
  cycle = "NA",
  onClick = () => {},
  onShareButtonClick = () => {},
}) => {
  return (
    <Box
    boxShadow={"0px 0px 12px 0px #00000029"}
    borderRadius={"6px"}
    maxWidth={"550px"}
    padding={"10px"}
      onClick={(e) => {
        onClick();
        e.stopPropagation();
      }}
    >
      <div className="job-marketplace-card">
        <div className="job-marketplace-card__verification-badge-container">
        <p className="job-marketplace__text--dark">Job ID: {jobId}</p>
          <VerificationBadge
            text={verificationText}
            type={verificationStatus}
          /> 
        </div>
        <div className="job-marketplace__container">
          <Flex gap={"10px"} direction={"column"} w="100%">
          
          
          <Flex direction={"column"} gap={"5px"} >
            <Flex justifyContent={"space-between"} > 
              <p className="job-marketplace__text--light">Job Name: {name}</p>
              <Flex padding={"5px"}>
            <ReactSVG
              src={shareIcon}
              beforeInjection={(svg) => {
                svg.setAttribute("style", "height: 18px");
              }}
              onClick={(e) => {
                onShareButtonClick();
                e.stopPropagation();
              }}
            />
          </Flex>
              </Flex>
         

            <Text fontSize={"14px"} fontWeight={"600"} color={"#2c2c2c"}>
              Job Location: {location}
            </Text>
          </Flex>

            <p className="job-marketplace__text--dark">
              Posted on: {postedOn}
            </p>
            <Flex gap={"5px"} className="job-marketplace__text--dark" 
            alignItems={"center"}
            >
            Salary: <p className="job-marketplace-card__text--price">
              {money} / {cycle}
            </p></Flex> 
           
          </Flex>
         
        </div>
      </div>
    </Box>
  );
};
