import { WebPageLayout } from "../Layout/WebpageLayout";
import "./Terms.css";
import { MobileResponsiveFooter } from "../components/MobileResposiveFooter/MobileResponsiveFooter";
import { MobileHeader } from "../components/MobileHeader/MobileHeader";
import useWindowWidth from "../../sharedComponent/isMobile/CheckMobile";
import { Flex } from "@chakra-ui/react";
function RefundPolicy() {
   const isMobile=useWindowWidth();
    return (
      <>
      {isMobile <768 ?(<>
        <MobileHeader />
        <Flex>
        <div className="resposive-terms">
            <h2 className="terms-heading">Refund Policy</h2>
            <div className="terms-content">
            Thanks for purchasing our products (or subscribing to our services) at EQUIP9™️. We offer a full money-back guarantee for all purchases made on our app. If you are not satisfied with the product that you have purchased from us, you can get your money back no questions asked. You are eligible for a full reimbursement within 14 calendar days of your purchase. After the 14-day period, you will no longer be eligible and won't be able to receive a refund. We encourage our customers to try the product (or service) in the first two weeks after their purchase to ensure it fits your needs. The money will be refunded to the original payment method you’ve used during the purchase. For credit card payments it may take 5 to 10 business days for a refund to show up on your credit card statement.If you have any additional questions or would like to request a refund, feel free to contact us.
            </div>

            <div className="terms-link">
                <a href="mailto:contact@equip9.com">contact@equip9.com</a>
            </div>
        </div>
        </Flex>

        <MobileResponsiveFooter />

      </>):(<>
        <WebPageLayout>
        <div className="terms">
            <h2 className="terms-heading">Refund Policy</h2>
            <div className="terms-content">
            Thanks for purchasing our products (or subscribing to our services) at EQUIP9™️. We offer a full money-back guarantee for all purchases made on our app. If you are not satisfied with the product that you have purchased from us, you can get your money back no questions asked. You are eligible for a full reimbursement within 14 calendar days of your purchase. After the 14-day period, you will no longer be eligible and won't be able to receive a refund. We encourage our customers to try the product (or service) in the first two weeks after their purchase to ensure it fits your needs. The money will be refunded to the original payment method you’ve used during the purchase. For credit card payments it may take 5 to 10 business days for a refund to show up on your credit card statement.If you have any additional questions or would like to request a refund, feel free to contact us.
            </div>

            <div className="terms-link">
                <a href="mailto:contact@equip9.com">contact@equip9.com</a>
            </div>
        </div>
        </WebPageLayout>
      </>)}
      </>
    );
}

export default RefundPolicy;