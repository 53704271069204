import { createSlice } from "@reduxjs/toolkit";
import { getMpDeferredThunk } from "../thunk/getMpDeferredThunk";
import { mpInitialState } from "../constants/mpInitialState";
import { mpLoadMoreDeferred } from "../thunk/mpLoadMoreDeferred";

export const mpDeferredSlice = createSlice({
    name:"mpDeferred",
    initialState:mpInitialState ,
    reducers:{
        setData: (state,{payload})=>{
            state.data = payload
        }
    },

    extraReducers:(builder) =>{
        builder
        // REM Marketplace
        .addCase(getMpDeferredThunk.pending, (state)=>{
            state.data = null;
            state.initialLoading = true;
            state.paginationLoading = false;
            state.error = false;
            state.errorMessage = false;
            state.paginationCount = 1;
            state.endOfData = false
        })
        .addCase(getMpDeferredThunk.fulfilled,(state,{payload}) =>{
            state.paginationCount = 1;
            state.initialLoading = false;
            state.error = false;
            state.errorMessage = null;
            state.data = payload?.NearMeDetails;
            if(payload?.NearMeDetails?.length < state.cardCount){
              state.endOfData = true
            }
        })

                .addCase(mpLoadMoreDeferred.pending, (state)=>{
            state.paginationLoading = true;
            state.error = false ;
            state.errorMessage = null;
         })
         .addCase(mpLoadMoreDeferred.fulfilled, (state,{payload})=>{
            state.error = false ;
            state.errorMessage = null ;
            state.paginationLoading = false;
            state.assetImageReference = payload?.NearMeDetails
            if(payload?.NearMeDetails?.length >= state.cardCount){
                state.data = [...state?.data , ...payload?.NearMeDetails];
                state.paginationCount += 1;
            }else{
                state.endOfData = true;
         }
         })
    }
})

export const {
    setData
}= mpDeferredSlice.actions;