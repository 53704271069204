import { createAsyncThunk } from "@reduxjs/toolkit";
import { postOtherEquipmentDetails } from "../../../services/equipments/equipments";

export const createAssetOtherPropertyDetailsThunk = createAsyncThunk("/equipmentListing/createOtherPropertyDetails", async(otherDetails, {getState, rejectWithValue}) => {
    const {equipmentType, createdAssetId} = getState().addEquipment;
    /**
   *  formData.append("EquipmentTypeId", this.state.selectedEquipmentTypeID);
        formData.append("AssetId", this.state.AssetId);
        formData.append("DynamicColumns", OtherDetailsBlob.toString());
   */
    const body = {
        EquipmentTypeId: equipmentType,
        AssetId: createdAssetId,
        DynamicColumns: otherDetails.toString()

    }
   try{
          const data = await postOtherEquipmentDetails(body);
          return data
   }
   catch(error){
    throw error
   }
})