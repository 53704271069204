import { createAsyncThunk } from "@reduxjs/toolkit";
import {fetchHandler} from "../../../../../index";

export const getCurrencyForUserThunk = createAsyncThunk("/user/currency", async(_, {getState}) => {

    try{
        const {data}=await fetchHandler("/GetCurrencyForUser")
        if(data.Status === 200 && data.Data.CurrencyList){
            return data.Data.CurrencyList;
        }
    }
    catch(error){
    }
})