import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchHandler } from "../../../../../../src/index";
import { API_CONSTANTS } from "../../../../../constants/apiConstants";
import { DetailsCard } from "../../../../../webapp/components/DetailsCard/DetailsCard";
import { Box, Button, Divider, Flex, Grid, Text } from "@chakra-ui/react";
import logo from "../../../../../assets/logo/equip9_logo_dark.png";
import { ExclamationSquareFill } from "react-bootstrap-icons";
import { LoginPopUp } from "../../../../components/PopUps/LoginPopUp";
import BrandHeader from "../../screens/BrandHeader";

export const OpenJobDetailPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { category: subcategory , jobId } = useParams();
  const [data, setData] = useState(null);
  const [encryptedAssetId, setEncryptedAssetId] = useState("");
  const navigate = useNavigate();
  const [encryptSubcategory,setEncryptSubcategory] = useState();

  const category = "job";

  useEffect(()=>{
    (()=>{
      if(subcategory.toLowerCase() === 'maintenance'){
        setEncryptSubcategory('mpJobs')
      }else{
        setEncryptSubcategory('opJobs')
      }
    })()
  },[subcategory])

  const getJobDetails = async () => {
    
    const id = jobId;
    const { data } = await fetchHandler(
      "/deferredLogin/marketplace",
      { category, id, subcategory },
      "",
      "",
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: false }
    );
    return data;
  };

  useEffect(() => {
    (async () => {
      const data = await getJobDetails();
      if (data?.Status == 200) {
        if(subcategory=="operator"){
          setData(data?.Data?.ViewNewOperatorJob);
        }else if(subcategory=="maintenance"){
          setData(data?.Data?.MaintenanceQuickJobDetails);
        } else{
          setData(null);
        }
       
      }
    })();
  }, []);

  const encryptAssetDetails = async (assetId) => {
    try {
      const { data } = await fetchHandler(
        API_CONSTANTS.ENCRYPT_SHARE_DATA,
        {},
        "",
        { marketplaceCategory: category,marketplaceSubCategory:encryptSubcategory,assetId: assetId },
        API_CONSTANTS.HTT_POST,
        { isAuthRequired: false }
      );
      setEncryptedAssetId(data.Data.shareData);
    } catch (error) {
      console.log(error);
    }
  };

  const DetailsHeader = ({ text = "", children }) => {
    return (
      <Box margin="0 auto">
        <Flex alignItems="center">
          <Flex w="100%" alignItems="center" gap="0.5em">
            <Box minWidth="max-content">
              <Text color="#011f6b" fontWeight="semibold" fontSize="2xl">
                {text}
              </Text>
            </Box>
            <Flex alignItems="center" w="100%">
              <Divider borderColor="#011f6b" borderWidth="0.1em" />
            </Flex>
          </Flex>
        </Flex>

        <Box>
          <Flex
            gap="2em"
            flexDirection="row"
            overflowX="scroll"
            padding="1em 0 1em 1em"
          >
            {children}
          </Flex>
        </Box>
      </Box>
    );
  };

  return (
    <>
    <LoginPopUp
        isModalOpen={modalOpen}
        closeModal={() => {
          setModalOpen(false);
          setEncryptedAssetId("");
        }}
        onLoginClick={() => {
          sessionStorage.setItem("DEFERRED_MARKETPLACE",encryptedAssetId);
          navigate(`/login/login-form?id=${encryptedAssetId}`);
        }}
        onRegisterClick={() => {
          sessionStorage.setItem("DEFERRED_MARKETPLACE",encryptedAssetId);
          navigate(`/welcome/select-language?id=${encryptedAssetId}`);
        }}
      />
      <Grid 
      templateRows="auto 1fr auto"
      height="100vh"
      >
      <BrandHeader />
      {data ? (
        <div
        style={{overflowY:"scroll"}}
        >
          {/* logo */}
          <div style={{ padding: "10px 52px" ,overflowY:"scroll"}}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              borderBottom={"1px solid #333333"}
              marginBottom={"1em"}
            >
              <h1 
              style={{ 
                fontSize: "32px", 
                fontWeight: "700",
                marginBottom: "0.5em", }}>
                {data?.JobTitle}
              </h1>
            </Flex>

            <DetailsHeader text="Equipment Details">
              <DetailsCard
                heading={data.EquipmentTypeName}
                description="Equipment Type"
              />
              <DetailsCard
                heading={data.EquipmentMakeName}
                description="Equipment Make"
              />
              <DetailsCard
                heading={data.EquipmentModelName}
                description="Equipment Model"
              />
              <DetailsCard
                heading={data.EquipmentAge}
                description="Equipment Age"
              />
            </DetailsHeader>

            {/* Job Details */}
            <DetailsHeader text="Job Details">
              <DetailsCard
                heading={data.WorkLocation}
                description="Job Location"
              />
            </DetailsHeader>

            {/* IN HAND SALARY */}
            <DetailsHeader text="In Hand Salary">
              <DetailsCard
                heading={`${data.Salary ? data.Salary : "NA"} / ${
                  data.SalaryTypeValue ? data.SalaryTypeValue : "NA"
                }`}
                description="In Hand Salary"
              />
              <DetailsCard heading={data.PFValue} description="PF Provided" />
              <DetailsCard
                heading={
                  data.PayCycleValue ? `${data.PayCycleValue} week` : "NA week"
                }
                description="Pay Cycle"
              />
            </DetailsHeader>

            {/* Food and accomodation */}
            <DetailsHeader text="Food & Accomodation">
              <DetailsCard
                heading={data.FoodBenefitValue ? data.FoodBenefitValue : "NA"}
                description="Food"
              />
              <DetailsCard
                heading={data.AccomodationValue ? data.AccomodationValue : "NA"}
                description="Accomodtion"
              />
            </DetailsHeader>

            {/* Working Shift */}
            {subcategory !== "maintenance" ? (
              <>
                <DetailsHeader text="Working Shift">
                  <DetailsCard
                    heading={data.WorkShiftValue ? data.WorkShiftValue : "NA"}
                    description="Shift Type"
                  />
                  <DetailsCard
                    heading={
                      data.LunchBreakValue
                        ? `${data.WorkingHourValue} + ${data.LunchBreakValue}`
                        : data.WorkingHourValue
                        ? data.WorkingHourValue
                        : "NA"
                    }
                    description="Working Shift"
                  />
                  <DetailsCard
                    heading={
                      data.JobDurationValue ? data.JobDurationValue : "NA"
                    }
                    description="Job Duration"
                  />
                </DetailsHeader>

                <DetailsHeader text="Nature Of Work">
                  <DetailsCard
                    heading={data.WorkTypeValue ? data.WorkTypeValue : "NA"}
                    description="Work Type"
                  />
                  <DetailsCard
                    heading={data.Insurance ? data.Insurance : "NA"}
                    description="Insurance"
                  />
                  <DetailsCard
                    heading={data.TravelAllowance ? data.TravelAllowance : "NA"}
                    description="Travel Allowance"
                  />
                </DetailsHeader>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <Flex
          padding={"0 52px"}
          marginTop={"80px"}
          justifyContent={"center"}
          alignItems={"center"}
          minH={"50vh"}
        >
          <p style={{ fontSize: "22px", fontWeight: 600 }}>Nothing to show</p>
        </Flex>
      )}

      <Flex
        height={"75px"}
        justifyContent={"center"}
        alignItems={"center"}
        color={"#F79C00"}
        boxShadow={"22px -3px 10px 0px #00000040"}
      >
        <button
          style={{
            padding: "8px 16px 8px 16px",
            borderRadius: "4px",
            border: "1px solid #F79C00",
          }}
          onClick={async ()=>{
            await encryptAssetDetails(jobId);
            setModalOpen(true)
          }}
        >
          <Text fontSize={"20px"} fontWeight={"600"}>
            Checkout all Job Opportinities
          </Text>
        </button>
      </Flex>

      </Grid>
    </>
  );
};
