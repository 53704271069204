import { HighlightedSection } from "../HighlighedSection/HighlightedSection";
import { HighlightedElement } from "../../CommonComponents/HighlightedElement/HighlightedElement";
import { Flexbox } from "../../CommonComponents/Flexbox/Flexbox";
import { StyledLink } from "../../CommonComponents/Button/StyledButton";
import { Flex, Text } from "@chakra-ui/react";
export const Showcase = ({
  heading,
  subheading,
  description,
  mainImage,
  highlightAreaArray = [],
}) => {
  return (
    <div
      className="float section"
      style={{ textAlign: "center", paddingTop: "2em" }}
    >
      <Text
        style={{fontWeight: "700", lineHeight: "47.09px" }}
        fontSize={["1.12em","1.12em","1.12em","1.75em","2.25em","2.25em"]}
      >
        {heading}
      </Text>
      <Text
        style={{
          fontWeight: "600",
          lineHeight: "31.39px",
          letterSpacing: "1",
          color: "var(--primary)",
        }}
        fontSize={["0.75em","0.75em","0.75em","1.25em","1.5em","1.5em"]}
      >
        {subheading}
      </Text>
      <Text
        style={{
          // fontSize: "24px",
          lineHeight: "31.39px",
          fontWeight: 600,
          letterSpacing: "1%",
        }}
        fontSize={["0.75em","0.75em","0.75em","1.25em","1.5em","1.5em"]}
      >
        {description}
      </Text>
      <Flex className="float container" padding={"1em 0"}>
        <Flex display={["none","none","none","none","flex","flex"]}>
          {/* image of phone */}
          <img
            src={mainImage}
            style={{ height: "555px", width: "279px" }}
            alt="buy heavy equipment"
          />
        </Flex>
        {/* Box for displaying Stats */}
        <Flexbox style={{ gap: "2em" }}>
          <HighlightedSection>
            {highlightAreaArray && highlightAreaArray.length > 0 ? (
              highlightAreaArray.map((data) => (
                <HighlightedElement
                  image={data.image ? data.image : ""}
                  stats={data.stats ? data.stats : ""}
                  category={data.category ? data.category : ""}
                />
              ))
            ) : (
              <></>
            )}
          </HighlightedSection>
          
          <StyledLink
            // onClick={() => {throw new Error("Test Error")}}
            style={{ alignSelf: "center" }}
            to="/contact"
            size="large"
          >
            Connect Now
          </StyledLink>
        </Flexbox>
      </Flex>
    </div>
  );
};
