import axios from "axios"
import { API_CONSTANTS } from "../../../constants/apiConstants"
import {fetchHandler} from "../../../index"
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData"


// Add Dealership Pop Up
export const getEquipmentStatusType = async () => {
    try {
      const response = await fetchHandler(API_CONSTANTS.GET_EQUIPMENT_STATUS_TYPE);
      return response
    } catch (error) {
      // console.log(error);
    }
  }

  export const getIndustryTypeList = async () => {
    try {
      const response = await fetchHandler(API_CONSTANTS.GET_INDUSTRY_TYPE_LIST);
      return response
    } catch (error) {
      // console.log(error);
    }
  }


// Add Dalership Step 1
export const getDealershipDetailsById = async (guuid) => {
    try {
      const {data} = await fetchHandler(`${API_CONSTANTS.GET_DEALERSHIP_DETAILS_BY_ID}?DealersShipGuid=${guuid}`)
      return data;
    }
    catch (err) {
      //  console.log(err);
    }
  }
  
  export const updateDealershipDetails = async (payload) => {
    try {
      const {data}= await fetchHandler(`/${API_CONSTANTS.UPDATE_DEALERSHIP_DETAILS}`,{},{},payload,API_CONSTANTS.HTTP_PUT);
      return data;
    } catch (error) {
      // console.log(error);
    }
  };

// Add Dealership Step 3
export const getResponseOptionType = async () => {
    try {
      const {data}= await fetchHandler(API_CONSTANTS.GET_RESPONSE_OPTION_TYPE);
      return data;
    } catch (error) {
      // console.log(error);
    }
  }

  export const getPhotoType = async (token) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/${API_CONSTANTS.GET_PHOTO_TYPE}`, {
        headers: {
          'Content-Type': 'application/json',
          'token': token
        }
      });
      return response;
    } catch (error) {
      // console.log(error);
    }
  }

  export const deleteDealershipImage = async (id) => {
    try {
    
      const {data}= await fetchHandler(`/${API_CONSTANTS.DELETE_DEALERSHIP_IMAGE}/${id}`,{},{},{},API_CONSTANTS.HTTP_DELETE);
      return data;
    }
    catch (err) {
      // console.log(err);
    }
  }

  export const deleteDealershipVideo = async (id) => {
    try {
      
      const {data} = await fetchHandler(`/${API_CONSTANTS.DELETE_DEALERSHIP_VIDEO}/${id}`,{},{},{},API_CONSTANTS.HTTP_DELETE);
      return data;
    }
    catch (err) {
      // console.log(err);
    }
  }

  export const deleteDealershipCertificate = async (id) => {
    try {
      const {data}=await fetchHandler(`/${API_CONSTANTS.DELETE_DEALERSHIP_CERTIFICATE}/${id}`,{},{},{},API_CONSTANTS.HTTP_DELETE);
      return data;
    } catch (error) {
      
    }
  }

  export const getVideoType = async (token) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/${API_CONSTANTS.GET_VIDEO_TYPE}`, {
        headers: {
          'Content-Type': 'application/json',
          'token': token
        }
      });
      return response;
    } catch (error) {
      // console.log(error);
    }
  }

  // Add to MArketplace : 
export const dealerhipFreeReedemTransaction = async(body) =>{
  try {
    const { data } = await fetchHandler(
      API_CONSTANTS.CREATE_E9_FREE_REDEEMED_TRANSACTION,
      {},
      {},
      body,
      API_CONSTANTS.HTT_POST
    );
      return data ;
  } catch (error) {
  }
}
export const dealerhipSubscriptionReedemTransaction = async(body) =>{
  try {
    const data = await fetchHandler(
      API_CONSTANTS.CREATE_E9_SUBSCRIPTION_REDEEMED_TRANSACTION,
      {},
      {},
      body,
      API_CONSTANTS.HTT_POST
    );
      return data ;
  } catch (error) {
  }
}


export const createE9DsMarketplaceDetails = async(payload) =>{
  try {
    const {data} = await fetchHandler(
      API_CONSTANTS.CREATE_E9_DS_MARKETPLACE_DETAILS,
      {},
      {},
      payload,
      API_CONSTANTS.HTT_POST
    );
    return data;
  } catch (error) {
    
  }
}

// Delete Dealership From Marketplace 
export const removeDealershipFromMarketplace = async (id) =>{
 try {
  const {data} = await fetchHandler(
    `${API_CONSTANTS.REMOVE_DEALERSHIP_FROM_MARKETPLACE}/${id}`,
    {},
    {},
    {},
    API_CONSTANTS.HTTP_DELETE
    
  );
  return data
 } catch (error) {
  
 }
}

export const createDealershipDetails = async (body) =>{
  try {
   const {data} = await fetchHandler(
     API_CONSTANTS.CREATE_DEALERSHIP_DETAILS,
     {},
     {},
     convertToFormData(body),
     API_CONSTANTS.HTT_POST
   );
   return data
  } catch (error) {
   
  }
 }
