export const e9ServiceIconHandling = (name = "") => {
    switch(name.toLowerCase()){
        case "Equipment Listing".toLowerCase(): 
        return "Webapp/icons/e9-services/equipment_listing_icon.svg";
        case "Maintenance Search".toLowerCase():
        return "Webapp/icons/e9-services/maintenance_search_icon.svg";
        case "Dealership Listing".toLowerCase():
        return "Webapp/icons/e9-services/dealerships_icon.svg";
        case "Material Listing".toLowerCase():
            return "Webapp/icons/e9-services/materials_icon.svg";
        case"Operator Search".toLowerCase() :
        return "Webapp/icons/e9-services/operator_serch_icon.svg";
        default: 
        return "";    
    }
}