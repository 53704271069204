import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Flex, Box, Divider, Text, Button } from "@chakra-ui/react";
import { NewSideMenuLayout } from "../../../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { useNavigate } from "react-router";
import {fetchHandler} from "../../../../../index";
import UpdateAdressModal from "../SubscriptionType/UpdateAdressModal";
import { V5SidebarLayout } from "../../../../layout/V5SidebarLayout/V5SidebarLayout";

export const PurchasedSubscriptions = () => {
  const {defaultLanguage} = useSelector(state => state.masterData)
  const {UserTypeValue, accountDetails } = useSelector(
    (state) => state.user
  );
  const {addressModal} = useSelector(state => state.subscription);
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      let endpoint =
        UserTypeValue.toLowerCase() === "Company".toLowerCase()
          ? `/GetCompanySubsTransHistory/12/0?TaxNumber=${btoa(
            accountDetails.CompanyDetails.TaxNumber
          )}`
          : `/GetUserSubscriptionTransactionHistory/12/0`;
   
      try {
       
        const {data}=await fetchHandler(endpoint);

        if (data.Status === 200 && Object.keys(data.Data).length > 0) {
          return setData(data.Data);
        }
        else {
          return setData(null)
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleRedeem = (serviceTypeValue) => {
    switch (serviceTypeValue.toLowerCase()) {

      case "Used Equipment".toLowerCase():
        navigate("/marketplace/MarketPlaceUEM")
        
        break;

      case "Equipment Listing".toLowerCase() : 
        navigate("/equipment-store")
        break;

      case "Maintenance Search".toLowerCase():
        navigate("/near-me/MarketPlaceMP")
        break;

      case "Material Listing".toLowerCase():
        navigate("/materials-type-store-list")
        break;

      case "Operator Search".toLowerCase():
        navigate("/near-me/MarketPlaceOP")
        break;

      case "Dealership Listing".toLowerCase():
        navigate("/my-business")
        break;

      case "Rental Equipment".toLowerCase():
        navigate("/marketplace/MarketPlaceRM")
        break;

      case "Material contact".toLowerCase():
        navigate("/materials-type-store-list")
        break;

      default:
        break;
    }

  }

  return (
    <V5SidebarLayout>
      <UpdateAdressModal isOpen={addressModal}/>
      <div
        style={{
          margin: "0px 52px",
          backgroundColor: "#92929204",
          paddingTop: "1em",
        }}
      >
        <div style={{ marginBottom: "32px" }}>
          <h1 style={{ fontSize: "32px", fontWeight: 700 }}>
            {defaultLanguage?.purchased_subscriptions}
          </h1>
          <p style={{ fontSize: "22px", color: "#2c2c2c80" }}>
            {defaultLanguage?.Redeem_your_Free_Service_Today}
          </p>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "1em", maxHeight: "100%", overflowY: "scroll" }}>
          {data && data.length > 0 ? (
            data.map((order) => (
              <div>
                <Flex alignItems="center">
                  <Flex w="100%" alignItems="center" gap="0.5em">
                    <Box minWidth="max-content">
                      <Text
                        color="#011f6b"
                        fontWeight="semibold"
                        fontSize="2xl"
                      >
                        {order.TransactionOrderId}

                      </Text>
                    </Box>
                    <Flex alignItems="center" w="100%">
                      <Divider borderColor="#011f6b" borderWidth="0.1em" />
                    </Flex>
                  </Flex>
                </Flex>
                <p style={{ color: "#2c2c2c95" }}>
                  {order.TransactionDateTime}
                </p>
                <table
                  style={{
                    backgroundColor: "#ffffff",
                    boxShadow: "0px 10px 30px #2c2c2c1A",
                    width: "100%",
                    borderRadius: "4px",
                    border: "1px solid #2C2C2C1A",
                    maxHeight: "calc(100vh - 230px)",
                    overflowY: "scroll",
                  }}
                >
                  <thead
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      textAlign: "left",
                      color: "#1E3264",
                      borderBottom: "1px solid #2c2c2c1a",
                    }}
                  >
                    <th
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        paddingLeft: "20px",
                        width: "30%",
                      }}
                    >
                      E9 Services
                    </th>
                    <th
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        width: "25%",
                      }}
                    >
                      Remaining Services / Total Services
                    </th>
                    <th
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        width: "25%",
                        textAlign: "center",
                      }}
                    >
                      Days Remaining
                    </th>
                    <th
                      style={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        width: "20%",

                      }}
                    >
                      Action
                    </th>
                  </thead>
                  <tbody>
                    {order.ServiceList && order.ServiceList.length > 0 ? (
                      order.ServiceList.map((serviceType) => {
                        return <tr
                          style={{
                            borderBottom: "1px solid #2c2c2c1a",
                            fontSize: "16px",
                          }}
                        >
                          <td
                            style={{
                              paddingTop: "20px",
                              paddingBottom: "20px",
                              paddingLeft: "20px",
                              width: "30%",
                              backgroundColor: serviceType.DaysRemaining ? "" : "#2c2c2c10"
                            }}
                          >
                            {serviceType.ServiceTypeValue}
                          </td>
                          <td
                            style={{
                              paddingTop: "20px",
                              paddingBottom: "20px",
                              textAlign: "center",
                              width: "25%",
                              backgroundColor: serviceType.DaysRemaining ? "" : "#2c2c2c10"
                            }}
                          >
                            {serviceType.LeadRemaining} /{" "}
                            {serviceType.LeadRemaining + serviceType.LeadRedeemed}
                          </td>
                          <td
                            style={{
                              paddingTop: "20px",
                              paddingBottom: "20px",
                              textAlign: "center",
                              width: "25%",
                              backgroundColor: serviceType.DaysRemaining ? "" : "#2c2c2c10"
                            }}
                          >
                            {serviceType.DaysRemaining}
                          </td>
                          <td
                            style={{ backgroundColor: serviceType.DaysRemaining ? "" : "#2c2c2c10" }}
                          >
                            <div style={{ display: "flex", gap: "1em" }}>
                              {serviceType.LeadRemaining <= 0.2 * (serviceType.LeadRemaining + serviceType.LeadRedeemed) || serviceType?.DaysRemaining === 0 ? (
                                <Button border="1px solid #F97C00" bgColor="#ffff" color="#F67C00" onClick={()=>navigate("/subscription/subscription-type")}>Buy More</Button>
                              ) : (
                                <Button variant="gradient" onClick={() => {handleRedeem(serviceType.ServiceTypeValue)}}>Redeem</Button>
                              )}
                              {/* <Button border="1px solid #F97C00" bgColor="#ffff"color="#F67C00" >Buy More</Button> */}
                            </div>
                          </td>
                        </tr>
                      })
                    ) : (
                      <div style={{ textAlign: "center", width: "100%", fontStyle: "italic" }}>
                        <p>No Services Available</p>
                      </div>

                    )}
                  </tbody>
                </table>
              </div>
            ))
          ) : (
            <p>No Data Available ...</p>
          )}
        </div>
      </div>
    </V5SidebarLayout>
  );
};
