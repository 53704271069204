import { Input, Flex, Button, Box, Image, useToast } from "@chakra-ui/react"
import { useState,  useRef, useEffect }  from "react"
import { useDispatch } from "react-redux"
import Avatar from "../../../../../assets/webapp/doc_flow/My-profile.png"
import { convertToFormData } from "../../../../helpers/dataConversion/convertToFormData"
// import { increaseRouteIndex } from "../../../global/user/slice/userSlice"
import { increasePageIndex } from "../../slice/docFlowSlice"
import {uploadUserProfilePicture } from "../../../../services/documentFlow/documentFlow"
import { getDefaultAppLanguage } from "../../../global/mtData/helpers/localStorage"
import { V5MultiAdLayout } from "../../../../layout/multiAdLayout/V5MultiAdLayout"
import uploadImageIcon from "../../../../../assets/webapp/doc_flow/image-upload-icon.svg" 
import { ReactSVG } from "react-svg"
import { useSearchParams } from "react-router-dom"
import { NAVIGATION_CONSTANTS } from "../../../../../constants/naigationContants"

export const UploadProfilePicture = () => {
    const [imagePreviewLink, setImagePreviewLink] = useState(null);
    const [imageFile,setImageFile] = useState(null);
    const uploadRef = useRef(null);
    const toast = useToast();
    const dispatch = useDispatch()
    const defaultLanguage = getDefaultAppLanguage();
    const [urlParam, setUrlParam] = useSearchParams()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      const id = sessionStorage.getItem("DEFERRED_MARKETPLACE");
      const urlAssetId = urlParam.get(NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID)
      if(id && !urlAssetId){
       setUrlParam(`${NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID}=${id}`)
      }
     }, [])
     
    const handleUploadImageButtonClick = () => {
        
        uploadRef.current.click();
    }

    const handleImageChange = (e) => {
        if(e.target.files){
            setImageFile(e.target.files[0]);
            setImagePreviewLink(URL.createObjectURL(e.target.files[0]))
        }
    }
    
    const handleNextPageButtonClick = async() => {
      
      if(!imageFile){
        return  toast({
          title: `${defaultLanguage?.Please_upload_your_selfie_below}`,
          position: "top-right",
          isClosable: true,
          status: "warning"
        })
      }
      const imgType = imageFile.type
      if(imgType.toString() !== "image/png".toString() && imgType.toString() !== "image/jpg".toString() && imgType.toString() !== "image/jpeg".toString()){
            return  toast({
              title: `Please enter a valid Image format (image/png, image/jpg or image/jpeg)`,
              position: "top-right",
              isClosable: true,
              status: "error"
             })
        }

    try{
      setLoading(true)
         const data= await uploadUserProfilePicture(convertToFormData({"UserProfilePicture": imageFile}))
         if(data.Status === 201){
            toast({
                title: `${data.Message.SuccessMessage}`,
                status: "success",
                isClosable: true,
                position: "top-right"
            })
            dispatch(increasePageIndex())
            // dispatch(increaseRouteIndex())
         }
            else{
                toast({
                title: `${data.Message.FailMessage}`,
                status: "error",
                isClosable: true,
                position: "top-right"
                })
            }
    }

    catch(error){
        return error;
    }
    finally{
      setLoading(false)
    }
     
    }

    return(
        <V5MultiAdLayout
        heading={defaultLanguage?.Please_upload_your_selfie_below}
        subHeading="Even a selfie of yours will help us identify you better."
        stepper={true}
        >
        <Flex maxW="400px" width="100%" margin="0 auto" flexDirection="column" gap="1em" alignItems="center">
         <Image src={imagePreviewLink ? imagePreviewLink : Avatar} height="175px" width="175px" borderRadius="50%"/>
        
        <Input  type="file" onChange={handleImageChange} ref={uploadRef} display="none"/>
        <Button
        border="2px solid #F79C00"
        backgroundColor="#fff"
        maxWidth="374px"
        width="100%"
        height="66px"
        onClick={handleUploadImageButtonClick}
        >
          <ReactSVG src={uploadImageIcon} style={{marginRight: "1rem"}}/> {"   "}{defaultLanguage?.Upload_from_gallery}</Button>
        </Flex>
        <Box>
        <Flex
          justifyContent="center"
          alignItems="center"
          bgColor="#fff"
          h="78px"
          style={{ boxShadow: "0px -10px 15px #2c2c2c0f" }}
        >
          <Button
            type="submit"
            variant="gradient"
            fontWeight="semibold"
            width="800px"
            margin="0 auto"
            maxW="400px"
            form="registration-form"
            onClick={handleNextPageButtonClick}
            isDisabled={!imageFile}
            isLoading={loading}
          >
            <p style={{fontSize:'22px'}}>{defaultLanguage?.Next}</p>
          </Button>
        </Flex>
      </Box>
        </V5MultiAdLayout>
    )
} 