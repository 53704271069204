import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Box, Text, Wrap, Flex, Button, useToast } from "@chakra-ui/react";
import {
  getAllLanguageJsonThunk,
  getAllLanguagesThunk,
} from "../global/mtData/thunk/getAllLanguagesThunk";
import { getCountriesThunk } from "../global/mtData/thunk/getCountriesThunk";
import { SelectCardWrapper } from "../../components/SelectCardWrapper/SelectCardWrapper";
import { setLanguage } from "../global/appConfig/slice/appConfigSlice";
import { useNavigate } from "react-router-dom";
import { convertToBase64 } from "../../../utils/formatConverter/convertToBase64";
import { APP_CONFIG_LOCAL_STORE } from "../global/appConfig/constants/localStoreConstants";
import { setSelectedCountryThunk } from "../global/appConfig/thunk/setSelectedCountryThunk";
import { setCountry } from "../global/appConfig/slice/appConfigSlice";
import { NewSideMenuLayout } from "../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { V5SidebarLayout } from "../../layout/V5SidebarLayout/V5SidebarLayout";

export const ChangeLanguage = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const masterData = useSelector((state) => state.masterData);
  const appConfig = useSelector((state) => state.appConfig);
  const allLanguages = localStorage.getItem(
    APP_CONFIG_LOCAL_STORE.ALL_LANGUAGES
  );
  const [localLanguage, setLocalLanguage] = useState();
  const [e9AppLanguage, setAppLanguage] = useState();

  const languageButtonClickHandler = async (e) => {
    const selectedLanguage = await localLanguage.find(
      (language) => parseInt(e.target.value) === language.LanguageOptionTypeId
    );

    if (selectedLanguage?.IsDisabled !== 1) {
      setAppLanguage(selectedLanguage);
    } else {
      toast({
        title: "This language is not supported yet.",
        status: "info",
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const updateUserLanguage = async () => {
    if (e9AppLanguage) {
      dispatch(setLanguage(e9AppLanguage));
      dispatch(
        getAllLanguageJsonThunk(e9AppLanguage?.JsonFileName.toLowerCase())
      );
    }
  };

  useEffect(() => {
    if (!masterData?.defaultLanguage) {
      dispatch(setSelectedCountryThunk("91"));
      dispatch(getAllLanguageJsonThunk("english.json"));
    }
  }, [!masterData?.defaultLanguage]);

  useEffect(() => {
    dispatch(getCountriesThunk());
    dispatch(getAllLanguagesThunk(convertToBase64("IND")));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllLanguagesThunk(convertToBase64("IND")));
  }, [appConfig.country]);

  useEffect(() => {
    if (masterData.Countries && !appConfig.Country) {
      const defaultCountry = masterData.Countries.find(
        (country) => parseInt(country.CountryIsdCode) === 91
      );
      dispatch(setCountry(defaultCountry));
    }
  }, [masterData.Countries]);

  useEffect(() => {
    if (allLanguages) {
      setLocalLanguage(JSON.parse(allLanguages));
    }
  }, [allLanguages]);

  useEffect(() => {
    if (!e9AppLanguage) {
      setAppLanguage(appConfig?.language);
    }
  }, [e9AppLanguage]);

  return (

    <V5SidebarLayout>
        <Flex
          w="100%"
          h="100%"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Flex
            display="flex"
            flexDirection="column"
            gap="3em"
            margin="1em 1em"
          >
            <div>  
              <h1 style={{
                 color: "#2c2c2c",
                 font: "normal normal bold 26px/34px Assistant",
                  color:" #2C2C2C",
                  opacity: 1,
              }}>Choose Language</h1>
              <div>
                <Text color="#333" fontSize="24px" fontWeight="600">
                  {
                    masterData?.defaultLanguage
                      ?.Select_the_language_that_youre_comfortable_with
                  }
                </Text>
              </div>
            </div>

            <Wrap overflowY="scroll">
              {localLanguage && localLanguage.length > 0 ? (
                localLanguage.map((language) => (
                  <SelectCardWrapper
                    width="156px"
                    height="104px"
                    key={language.LanguageOptionTypeId}
                    value={language.LanguageOptionTypeId}
                    onClick={languageButtonClickHandler}
                    isSelected={
                      parseInt(language.LanguageOptionTypeId) ===
                      e9AppLanguage.LanguageOptionTypeId
                        ? true
                        : false
                    }
                    fontWeight={600}
                    fontSize="18px"
                  >
                    {language.LanguageOptionValue}
                  </SelectCardWrapper>
                ))
              ) : (
                <>
                  <h1>Loading ... </h1>
                </>
              )}
            </Wrap>
          </Flex>

          <Box>
            <Flex
              justifyContent="center"
              alignItems="center"
              bgColor="#fff"
              h="78px"
              style={{ boxShadow: "0px -10px 15px #2c2c2c0f" }}
            >
              <Button
                variant="gradient"
                fontWeight="semibold"
                width="80%"
                margin="0 auto"
                maxW="347px"
                onClick={updateUserLanguage}
                isDisabled={!e9AppLanguage}
              >
                {masterData?.defaultLanguage?.Continue}
              </Button>
            </Flex>
          </Box>
        </Flex>
        </V5SidebarLayout>
  );
};
