import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { useCurrentLocation } from "../../hooks/useCurrentLocation";
import { CheckIcon, CloseIcon, WarningIcon } from "@chakra-ui/icons";
import { useGeocode } from "../../hooks/useGeocode";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setModalOpen } from "../../features/location/slice/slice";
import { PlaceSuggestionsModal } from "../PlaceSuggestionModal/PlaceSuggestionsModal";
import { useField } from "formik";

export const LocationInput = ({
    errorMessage = "",
    isRequired = true,
    label = "",
    type = "text",
    placeholder = "Please click to enter location...",
    isInvalid = false,
    value = "",
    name = "",
    onChange = () => {},
    maxLength = "",
    maxWidth = "",
    width="",
    height=""
    
  }) => {
    const {
      latitude,
      longitude,
      error: locationError,
      handleGeoLocation,
    } = useCurrentLocation();
    const {
      geoCodeLocation,
      loading,
      error: geoCodeError,
      handleGeocoding,
    } = useGeocode();
    const dispatch = useDispatch();
    const [autocompleteValue, setAutocompleteValue] = useState(null);
    const [field, meta, helpers] = useField(name || "_location");
    const handleCurrentLocation = () => {
      handleGeoLocation();
    };
  
    const handleGeoCode = async (longitude, latitude) => {
      if (longitude && latitude) await handleGeocoding(longitude, latitude);
    };
  
    useEffect(() => {
      if (autocompleteValue) {
        helpers.setValue(autocompleteValue);
        setAutocompleteValue(autocompleteValue)
      } else if (longitude && latitude && geoCodeLocation) {
        helpers.setValue({ lat: latitude, lng: longitude, name: geoCodeLocation });
      }
    }, [autocompleteValue, geoCodeLocation]);
  
    useEffect(() => {
      if (longitude && latitude) handleGeoCode(longitude, latitude);
    }, [longitude, latitude]);
  
    return (
      <>
        <PlaceSuggestionsModal
          onChange={(value) => {
            setAutocompleteValue(value);
          }}
        />
        <FormControl
          isInvalid={errorMessage.trim() === "" ? false : true}
          isRequired={isRequired}
          maxWidth={maxWidth}
        >
          <FormLabel>{label}</FormLabel>
          <Input
            {...field}
            type={type}
            placeholder={placeholder}
            isInvalid={isInvalid}
            value={
               value ? value :
              autocompleteValue
                ? autocompleteValue.name
                : geoCodeLocation
                ? geoCodeLocation
                : ""
            }
            onChange={(e) => {
              field.onChange(e);
              onChange(e.target.value);
            }}
            onKeyDown={() => {
              return null;
            }}
            maxLength={maxLength}
            h="52px"
            width={width}
            height={height}
            border="1px solid #2c2c2c14"
            focusBorderColor="#2c2c2c"
            bgColor={value ? "#fff" : "#2c2c2c05"}
            onClick={() => {
              dispatch(setModalOpen());
            }}
          />
          {autocompleteValue || (latitude && longitude) ? <></> : (
            <Text
              onClick={handleCurrentLocation}
              cursor="pointer"
              color={
                latitude && longitude
                  ? "#319F00"
                  : locationError
                  ? "#D60000"
                  : "#1E3264"
              }
              fontWeight={600}
            >
              {" "}
              {latitude && longitude ? (
                <CheckIcon color="#319F00" height="12px" width="12px" />
              ) : locationError ? (
                <CloseIcon color="#D60000" height="12px" width="12px" />
              ) : (
                <></>
              )}{" "}
              {latitude && longitude
                ? "Location Added"
                : locationError
                ? locationError
                : "Use Current Location"}
            </Text>
          )}
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl>
      </>
    );
  };