import { useDispatch, useSelector } from "react-redux";
import { CustomInfoModal } from "../../../components/CustomInfoModal/CustomInfoModal";
import { SetIsComingSoonModalOpen } from "../slice/quizSlice";
import { GetValueFromConvertedDateTime } from "../helper/helper";

export const ComingSoonPopup = () => {
  const { isComingSoonModalOpen, selectedQuiz } = useSelector((state) => state.quiz);
  const dispatch = useDispatch();
  const onButtonClick = () => {
    dispatch(SetIsComingSoonModalOpen(false));
  };

  return (
    <>
      <CustomInfoModal
        isOpen={isComingSoonModalOpen}
        iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/popup/circularArrow.svg`}
        heading={selectedQuiz?.QuizName}
        subHeading={`Quiz will be active on ${selectedQuiz && GetValueFromConvertedDateTime(selectedQuiz?.QuizStartDate,"DATE")}`}
        primaryAction={onButtonClick}
        primaryActionText={"Okay"}
        hasCloseButton={true}
        onCloseButtonClick={onButtonClick}
        headerSectionMargin={"0 70px"}
      ></CustomInfoModal>
    </>
  );
};
