import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector } from "react-redux";
import { Flex, useToast } from "@chakra-ui/react";
import * as Yup from "yup";
import { useQuery, useQueryClient } from "react-query";
import "./Equip9SupportRaiseTicket.css";
import { ReactSVG } from "react-svg";
import uploadLogToS3 from "../../../../../../../KibanaLogging/KibanaLogging";
import { jsonLog } from "../../../../../../../staticData/staticData";
import {
  SupportTicketReason,
  supportCreateTicket,
} from "../../../../../../services/e9support/e9support";
import { CustomInputModal } from "../../../../../../components/CustomInputModal/CustomInputModal";

export const TicketRaiseSupport = ({ isOpen, onClose }) => {
  const userData = useSelector((state) => state.user);
  const queryClient = useQueryClient();
  const [SupportTicket, setSupprtTicket] = useState(null);
  const toast = useToast();
  const initialValues = {
    SupportTicketReasonId: "",
    Subject: "",
    description: "",
  };
  const validationSchema = Yup.object().shape({
    SupportTicketReasonId: Yup.string().required("Support id is required"),
    Subject: Yup.string()
      .required("Subject is required")
      .min(5, "Subject than 5 characters")
      .max(25, "Subject than cannot be longer than 25 characters"),
    description: Yup.string().required("Description  is required"),
  });

  const {
    data: getSupportReason,
    isLoading,
    isError,
  } = useQuery("getSupportReasonlist", () => SupportTicketReason());

  const ReasonList = getSupportReason?.data?.Data?.SupportTicketReason;

  const handleSubmit = async (values, { resetForm }) => {
    const payload = {
      SupportTicket: {
        SupportTicketReasonId: parseInt(SupportTicket),
        Subject: values.Subject,
        Description: values.description,
      },
    };

    try {
      const data = await supportCreateTicket(payload);
      if (data?.APIResponseCode === 200) {
        toast({
          title: "",
          description: `${data.Message.SuccessMessage}`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        queryClient.invalidateQueries("getRaisedTicketdata");
        onClose();
        resetForm();
        uploadLogToS3(
          jsonLog(
            userData?.accountDetails?.UserDetails?.CredentialDetails
              ?.UserCredentialId,
            "INFO",
            payload,
            "POST",
            `SupportTicket/Create`,
            `${process.env.REACT_APP_API_ENDPOINT}/SupportTicket/Create`,
            "Create Ticket SuccessFully",
            `${data?.APIResponseCode}`,
            `${data.Message.SuccessMessage}`,
            data
          ),
          `CreatUserTicket_${new Date().toISOString().split("T")[0]}.json`
        );
      } else {
        toast({
          title: "",
          description: `${data.Message.FailMessage}`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        uploadLogToS3(
          jsonLog(
            userData?.accountDetails?.UserDetails?.CredentialDetails
              ?.UserCredentialId,
            "ERROR",
            payload,
            "POST",
            `SupportTicket/Create`,
            `${process.env.REACT_APP_API_ENDPOINT}/SupportTicket/Create`,
            "Ticket has not been created",
            `${data?.APIResponseCode}`,
            `${data.Message.FailMessage}`,
            data
          ),
          `CreatUserTicket_${new Date().toISOString().split("T")[0]}.json`
        );
      }
    } catch (err) {
      toast({
        title: "",
        description: `${err.data.Message.FailMessage}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      // FailMessage
    }
  };
  return (
    <div>
      <>
        {/* {isOpen && (
          <>
            <div className="popup-card-over-Ticket">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({ isSubmitting, setFieldValue, values }) => (
                  <Form className="popup-cardd-Ticket">
                    <button className="onClose" onClick={onClose}>
                      <ReactSVG
                        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Cross.svg`}
                      />
                    </button>
                    <div className="headoPhone">
                      <ReactSVG
                        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/headphone.svg`}
                      />
                    </div>

                    <p className="hero-heading">Raise A ticket </p>
                    <div>
                      <label className="label-heading" htmlFor="selectField">
                        Select Reason<span className="asterisk">*</span>
                      </label>
                      <div>
                        <Field
                          as="select"
                          className="select-option"
                          name="SupportTicketReasonId"
                          onChange={(e) => {
                            setSupprtTicket(e.target.value);
                            setFieldValue(
                              "SupportTicketReasonId",
                              e.target.value
                            );
                          }}
                        >
                          <option className="select-option" value="">
                            Select an option
                          </option>
                          {ReasonList?.map((item, index) => (
                            <option
                              key={index}
                              value={item.SupportTicketReasonId}
                            >
                              {item.SupportTicketReasonValue}
                            </option>
                          ))}
                        </Field>

                        <ErrorMessage
                          name="SupportTicketReasonId"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div></div>
                    <label className="label-heading" htmlFor="inputField">
                      Subject<span className="asterisk">*</span>
                    </label>
                    <Field
                      className="add-subject"
                      type="text"
                      name="Subject"
                      placeholder="Add Subject here"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                      }}
                    />
                    <ErrorMessage
                      name="Subject"
                      component="div"
                      className="error-message"
                    />

                    <label className="label-heading" htmlFor="inputField">
                      Description<span className="asterisk">*</span>
                    </label>

                    {values.SupportTicketReasonId === "2" && (
                      <div className="information-class">
                        <span className="information-background">
                          <span className="information-icon">&#8505;</span>
                        </span>
                        <span className="order-id-informtion">
                          Please Provide order id in description
                        </span>
                      </div>
                    )}
                    <Field
                      as="textarea"
                      id="description"
                      className="add-description"
                      name="description"
                      placeholder="Write the Query Description here... "
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="error-message"
                    />

                    <button
                      className="submit-complaintent"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <p className="submit-text">Submit</p>
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        )} */}

        <CustomInputModal
          isOpen={isOpen}
          formId="inputForm"
          hasCloseButton={true}
          onCloseButtonClick={onClose}
          primaryActionText={"Submit"}
          heading={"Raise A ticket"}
          icon={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/headphone.svg`}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form id="inputForm">
                <Flex direction={"column"} gap={"8px"}>
                <div>
                  <label className="label-heading" htmlFor="selectField">
                    Select Reason<span className="asterisk">*</span>
                  </label>
                  <div>
                    <Field
                      as="select"
                      className="select-option"
                      name="SupportTicketReasonId"
                      onChange={(e) => {
                        setSupprtTicket(e.target.value);
                        setFieldValue("SupportTicketReasonId", e.target.value);
                      }}
                    >
                      <option className="select-option" value="">
                        Select an option
                      </option>
                      {ReasonList?.map((item, index) => (
                        <option key={index} value={item.SupportTicketReasonId}>
                          {item.SupportTicketReasonValue}
                        </option>
                      ))}
                    </Field>

                    <ErrorMessage
                      name="SupportTicketReasonId"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>
  
                <label className="label-heading" htmlFor="inputField">
                  Subject<span className="asterisk">*</span>
                </label>
                <Field
                  className="add-subject"
                  type="text"
                  name="Subject"
                  placeholder="Add Subject here"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                />
                <ErrorMessage
                  name="Subject"
                  component="div"
                  className="error-message"
                />

                <label className="label-heading" htmlFor="inputField">
                  Description<span className="asterisk">*</span>
                </label>

                {values.SupportTicketReasonId === "2" && (
                  <div className="information-class">
                    <span className="information-background">
                      <span className="information-icon">&#8505;</span>
                    </span>
                    <span className="order-id-informtion">
                      Please Provide order id in description
                    </span>
                  </div>
                )}
                <Field
                  as="textarea"
                  id="description"
                  className="add-description"
                  name="description"
                  placeholder="Write the Query Description here... "
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="error-message"
                />
                </Flex>
              </Form>
            )}
          </Formik>
        </CustomInputModal>
      </>
    </div>
  );
};
