import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateDealershipDetails } from "../../../services/postDealership/postDealership";

export const updateDealershipStepOneThunk = createAsyncThunk(
    "myBusiness/editDealershipStepOne", async (_, { getState }) => {
        const {
            editGuuid,
            name,
            email,
            website,
            contact,
            location,
            latitude,
            longitude,
            isdCode,
            taxNumber

        } = getState().myBusinessDealership;
        const regionPayload = JSON.parse(localStorage.getItem("POST_DEALERSHIP_REGION"));


        const { token } = getState().user;

        const payLoad = JSON.stringify({
            DealershipDetails: {
                DealersShipGuid: editGuuid,
                Name: name,
                Location: location,
                latitude: "18.5961526",
                longitude: "73.7174975",
                IsdCode: isdCode,
                ContactNumber: contact,
                Email: email,
                website: website,
                TaxNumber: taxNumber,
                Region: JSON.stringify(regionPayload),
            }
        });
        // console.log("Payload" , payLoad);

        try {
            const data  = await updateDealershipDetails(payLoad)
            if (data?.Status === 201 && data?.Data?.Success &&
                Object.keys(data.Data).length > 0) {
                // console.log("Response Thunk ====>", data);
                // dispatch(setPostGuuid())
                return { message: data?.Message?.SuccessMessage, dealershipId: data.Data.DealersShipGuid }
            } else {
                return { message: data?.Message?.FailMessage }
            }
        } catch (error) {

        }

    }
)