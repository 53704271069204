import { Image } from "@chakra-ui/react";
import logo from "../../../assets/logo/equip9_logo_dark.png";
import { NewSideMenuProfile } from "./NewSideMenuProfile";
import { NewSideMenu } from "./NewSideMenu";
import { NewDashboardNavBar } from "./NewDashboardNavbar";
import { useSelector } from "react-redux";
import "./NewSideMenuLayout.css";

export const NewSideMenuLayout = ({
  children,
  justifyContent = "",
  gridTemplateRows = "80px 1fr 80px",
  backgroundColor = "#92929210",
}) => {
  const { defaultLanguage } = useSelector((state) => state.masterData);
  const currentYear = new Date().getFullYear();
  return (
    <div
      style={{
        display: "flex",
        maxHeight: "100vh",
        height: "100vh",
        justifyContent: { justifyContent },
      }}
    >
      <div className="side-menu">
        {/* Menu-Heading */}
        <div className="side-menu-wrapper">
          <div className="side-menu-container">
            <div className="side-menu-container-logo">
              <Image src={logo} height="48px" width="80px" />
            </div>

            <div className="side-menu-container-info">
              <NewSideMenuProfile />
            </div>
          </div>
        </div>

        {/* Menu-Body */}
        <div className="side-menu-body-wrapper" style={{ flex: "0 0 65%" }}>
          <NewSideMenu defaultLanguage={defaultLanguage} />
        </div>

        {/* Menu-Footer */}
        <div className="new-menu-layout-footer">
          <p>
            &copy; {currentYear} EQUIP9<span>&#8482;</span>
            {process.env.REACT_APP_VERSION}
          </p>
        </div>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateRows: gridTemplateRows,
          backgroundColor: backgroundColor,
          width: "100%",
        }}
      >
        {/* NAVBAR */}
        <div style={{ maxHeight: "80px", backgroundColor: "#FFFFFF" }}>
          <NewDashboardNavBar />
        </div>
        {children}
      </div>
    </div>
  );
};
