import React from "react";
import "./DealrshipHeaderCard.css";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "./DealershipHeaderCard.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
// use Standard Format Data in common File
import { formatDate } from "../../../staticData/CommonMethod";
import { Like } from "../Like/Like";
import { subString } from "../../helpers/dataConversion/substring";
import { Tooltip } from "@chakra-ui/react";
const DealrshipHeaderCard = ({
  imageUrl,
  isPromoted,
  location,
  dealerName,
  financeAvailable,
  dealershipUniqueId,
  updatedDateTime,
  IsVerified,
  like,
  onHandleLikeClick = (value) => {},
}) => {
  return (
    <div className="dealership-header-card-container">
      <Swiper
        pagination={{ clickable: true }}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        style={{ height: "254px", width: "605px", position: 'relative', marginLeft: "-2px"  }}
      >
        <div className="dealership-header-card-img">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="dealership-tag" style={{ position: "absolute", zIndex: "10", height: "33px", width: "49px" }}>NEW</div>
            <div className="verification-badge-head">
              {IsVerified ? <div className="verify-text-delar">Verifed</div> : <div className="verify-text-delar">Not Verified</div>}{" "}
            </div>
          </div>

        </div>

        {imageUrl && imageUrl.length > 0 ? (
          imageUrl.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                style={{ height: "100%", width: "100%", objectFit: "fill" }}
                src={`${process.env.REACT_APP_CDN_PVT_URL}${image.ImageFileRef}`}
                className="dealership-header-card-image"
              />
            </SwiperSlide>
          ))
        ) : (
        
          <SwiperSlide>
            <img
              style={{ height: "100%", width: "100%", objectFit: "fill" }}
              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/DelarshipDefaultImage.png`}
              className="dealership-header-card-image"
            />
          </SwiperSlide>
        )}
      </Swiper>

      <div className="dealership-body-card-container">
        <div className="dealership-body-card-container1">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span className="">{isPromoted === 1 ? "isPromoted" : ""}</span>
            <button>
            <Like 
            isLiked={like}
            onClick={(value) => onHandleLikeClick(value)}
            />
            </button>
          </div>
          <Tooltip label={dealerName?.length > 30 ? dealerName: null}>
          <h1 className="dealership-text-name" style={{
            maxWidth:"200px"
          }}>
          {dealerName ? subString(30,dealerName):"NA"}
          </h1>
          </Tooltip>
          
          <div className="dealership-text-row">
            <label className="dealership-text2-new">Finance available : </label>
            <span className="dealership-text3-new">
              {financeAvailable ? financeAvailable : "NA"}
            </span>
          </div>
        </div>

        <div>
          <div className="dealership-details-section">
            <div className="dealership-text-row">
              <label className="dealership-text2-new">Id: </label>
              <span className="dealership-text3-new">
                {dealershipUniqueId ? dealershipUniqueId : "NA"}
              </span>
            </div>
            <div className="dealership-text-row">
              <label className="dealership-text2-new">Added on: </label>
              <span className="dealership-text3-new">
                {updatedDateTime ? formatDate(updatedDateTime) : "NA"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealrshipHeaderCard;
