import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Box, Flex, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import "./PostDealershipStepsCSS.css";

import {
  closeCertificateModal,
  closePhotoModal,
  closeVideoModal,
  increaseStepperIndex,
  openCertificateModal,
  openConfirmMediaModal,
  openPhotoModal,
  openVideoModal,
  setAllDealersshipDataById,
  setCertificateListById,
  setGetAllImageById,
  setGetAllVideosById,
  setRegionPayload,
} from "../../MyBusinessDealershipDetail/slice/myBusinessDealershipSlice";
import {
  deleteDealershipCertificate,
  deleteDealershipImage,
  deleteDealershipVideo,
  getDealershipDetailsById,
} from "../../../services/postDealership/postDealership";
import { ConfirmMediaPopUp } from "../../../components/MyBuisinessDealershipPopUp/postDealershipStepsPopUp/ConfirmMediaPopUp";
import { getSessionStorageItems } from "../../../helpers/sessionStorage/sessionStorage";
import { QrPopUp } from "../../../components/ScanQrCodeModal/QrPopUp";
import { UploadMediaPopUp } from "../../../components/UploadMediaPopUp/UploadMediaPopUp";
import { MultipleImageUpload } from "../../../components/MultipleImageUpload/MultipleImageUpload";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";
import { apiErrors } from "../../../../constants/apiErrors";
import {
  CreateDealershipCertificateDetails,
  createDealershipImageDetails,
  createDealershipVideoDetails,
} from "../../../services/dealershipListing/dealershipListing";

const AddDealershipStepTwo = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const {
    postGuuid,
    isPhotoOpen,
    getDealershipDataById,
    isVideoOpen,
    isCertificateOpen,
    photoTypeList,
    getImageList,
    videoTypeList,
    getCertificateList,
  } = useSelector((state) => state.myBusinessDealership);
  const [imageList, setImageList] = useState();
  const [videoList, setVideoList] = useState();
  const [isMediaUploading, setIsMediaUploading] = useState(false);
  const [certificateList, setCertificateList] = useState();
  const editGuuid = getSessionStorageItems("EDIT_DEALERSHIP_GUUID");

  // Local Storages
  const getLocalImageList = localStorage.getItem("POST_DEALERSHIP_IMAGE_LIST");
  const getLocalVideoList = localStorage.getItem("POST_DEALERSHIP_VIDEO_LIST");

  const validationSchema = Yup.object({});
  const initialValues = {};

  // useEffect(() => {
  //     (async () => {
  //         await getPhotoTypeList();
  //         await getVideoTypeList();
  //     })()
  // }, [getLocalImageList, getLocalVideoList])

  useEffect(() => {
    (async () => {
      getDealershipDetails();
      getUploadedImages(getDealershipDataById);
      getUploadedVideos(getDealershipDataById);
      getUploadedCertificate(getDealershipDataById);
    })();
  }, []);

  useEffect(() => {
    getUploadedImages(getDealershipDataById);
    getUploadedVideos(getDealershipDataById);
    getUploadedCertificate(getDealershipDataById);
  }, [getDealershipDataById, getDealershipDataById?.DealershipData]);

  const getDealershipDetails = async () => {
    if (editGuuid) {
      const data = await getDealershipDetailsById(editGuuid, token);
      if (data?.Status === 200) {
        dispatch(setAllDealersshipDataById(data?.Data?.DealershipDetails));
        const regionPayload = data?.Data?.DealershipDetails?.RegionDetails
          ? JSON.parse(data?.Data?.DealershipDetails?.RegionDetails)
          : "";
        dispatch(setRegionPayload(regionPayload));
      }
    } else {
      const data = await getDealershipDetailsById(postGuuid, token);
      if (data?.Status === 200) {
        dispatch(setAllDealersshipDataById(data?.Data?.DealershipDetails));
        const regionPayload = data?.Data?.DealershipDetails?.RegionDetails
          ? JSON.parse(data?.Data?.DealershipDetails?.RegionDetails)
          : "";
        dispatch(setRegionPayload(regionPayload));
      }
    }
  };

  const handleMediaSubmit = async () => {
    if (!getLocalImageList || getLocalImageList.length === 0) {
      dispatch(openConfirmMediaModal());
    } else {
      // await handleMediaAPI()
      dispatch(increaseStepperIndex());
    }
  };

  // Media Images

  const getUploadedImages = (getDealershipDataById) => {
    if (!getDealershipDataById?.DealershipData?.ImageList) {
      setImageList(null);
      localStorage.removeItem("POST_DEALERSHIP_IMAGE_LIST");
      return null;
    }
    const imageDetails = getDealershipDataById?.DealershipData?.ImageList.map(
      (element) => ({
        id: element.ImageTypeId,
        value: element.ImageTypeValue,
        url: `${process.env.REACT_APP_CDN_PVT_URL}${element.ImageFileRef}`,
        imageId: element.ImageId,
      })
    );
    setImageList(imageDetails);
    dispatch(setGetAllImageById(imageDetails));

    return imageDetails;
  };

  const handleUploadImage = async (values) => {
    setIsMediaUploading(true);
    const selectedPhoto = values.selectValue;
    const photoValue = photoTypeList.find(
      (photo) => photo.value === selectedPhoto
    );
    let payload = "";
    const setPayload = () => {
      if (editGuuid) {
        return (payload = {
          DealersShipGuid: editGuuid,
          ImageTypeId: photoValue.id,
          ImageFile: values.document,
        });
      } else {
        return (payload = {
          DealersShipGuid: postGuuid,
          ImageTypeId: photoValue.id,
          ImageFile: values.document,
        });
      }
    };

    try {
      let payload = setPayload();
      const data = await createDealershipImageDetails(payload);
      if (data?.Status === 201) {
        toast({
          title: "Successfully Uploaded",
          position: "top-right",
          status: "success",
        });
        getDealershipDetails();
        getUploadedImages(getDealershipDataById);
      } else {
        toast({
          title: "Error In Uploading",
          position: "top-right",
          status: "error",
        });
        getDealershipDetails();
        getUploadedImages(getDealershipDataById);
      }
    } catch (error) {
      if (error?.request?.status === apiErrors.INTERNAL_SERVER_ERROR) {
        toast({
          title: "Some Error Occured Please Try Again Later",
          position: "top-right",
          status: "error",
        });
      }
    } finally {
      setIsMediaUploading(false);
      dispatch(closePhotoModal());
    }
  };

  const handleImageDelete = async (values) => {
    const deletRecord = JSON.parse(getLocalImageList);
    const idToDelete = deletRecord?.find((image) => image.id === values);
    try {
      const data = await deleteDealershipImage(
        convertToBase64(idToDelete.imageId)
      );
      if (data?.Status === 204) {
        toast({
          title: "Deleted Successfully",
          position: "top-right",
          status: "success",
        });
        getDealershipDetails();
        getUploadedImages(getDealershipDataById);
      } else {
        toast({
          title: "Error In Deleting",
          position: "top-right",
          status: "success",
        });
        getDealershipDetails();
        getUploadedImages(getDealershipDataById);
      }
    } catch (error) {}
  };

  // Media Video

  const getUploadedVideos = (getDealershipDataById) => {
    if (!getDealershipDataById?.DealershipData?.VideoList) {
      setVideoList(null);
      localStorage.removeItem("POST_DEALERSHIP_VIDEO_LIST");
      return null;
    }
    const videoDetails = getDealershipDataById?.DealershipData?.VideoList.map(
      (element) => ({
        id: element.VideoTypeId,
        value: element.VideoTypeValue,
        url: `${process.env.REACT_APP_CDN_PVT_URL}${element.VideoFileRef}`,
        imageId: element.VideoId,
      })
    );
    setVideoList(videoDetails);
    dispatch(setGetAllVideosById(videoDetails));

    return videoDetails;
  };

  const handleUploadVideo = async (values) => {
    setIsMediaUploading(true);
    const selectedVideo = values.selectValue;
    const videoValue = videoTypeList.find(
      (video) => video.value === selectedVideo
    );
    let payload = "";
    const setPayload = () => {
      if (editGuuid) {
        return (payload = {
          DealersShipGuid: editGuuid,
          VideoFile: values.document,
          VideoTypeId: videoValue.id,
        });
      } else {
        return (payload = {
          DealersShipGuid: postGuuid,
          VideoFile: values.document,
          VideoTypeId: videoValue.id,
        });
      }
    };

    try {
      let payload = setPayload();
      const data = await createDealershipVideoDetails(payload);
      if (data?.Status === 201) {
        toast({
          title: "Successfully Uploaded",
          position: "top-right",
          status: "success",
        });
        getDealershipDetails();
        getUploadedVideos(getDealershipDataById);
      } else {
        toast({
          title: "Error In Uploading",
          position: "top-right",
          status: "error",
        });
        getDealershipDetails();
        getUploadedVideos(getDealershipDataById);
      }
    } catch (error) {
      if (error?.request?.status === apiErrors.INTERNAL_SERVER_ERROR) {
        toast({
          title: "Some Error Occured Please Try Again Later",
          position: "top-right",
          status: "error",
        });
      }
    } finally {
      setIsMediaUploading(false);
    }
  };

  const handleVideoDelete = async (values) => {
    const deletRecord = JSON.parse(getLocalVideoList);
    const idToDelete = deletRecord?.find((video) => video.id === values);

    try {
      const data = await deleteDealershipVideo(
        convertToBase64(idToDelete.imageId)
      );
      if (data?.Status === 204) {
        toast({
          title: "Deleted Successfully",
          position: "top-right",
          status: "success",
        });
        getDealershipDetails();
        getUploadedVideos(getDealershipDataById);
      } else {
        toast({
          title: "Error In Deleting",
          position: "top-right",
          status: "success",
        });
        getDealershipDetails();
        getUploadedVideos(getDealershipDataById);
      }
    } catch (error) {}
  };

  // Certificate

  const getUploadedCertificate = (getDealershipDataById) => {
    if (!getDealershipDataById?.DealershipData?.CertificateList) {
      setCertificateList(null);
      dispatch(setCertificateListById(""));
      return null;
    }
    const certificateDetails =
      getDealershipDataById?.DealershipData?.CertificateList.map((element) => ({
        id: element.CertificateId,
        url: `${process.env.REACT_APP_CDN_PVT_URL}${element.CertificateFileRef}`,
      }));
    setCertificateList(certificateDetails);
    dispatch(setCertificateListById(certificateDetails));

    return certificateDetails;
  };

  const handleUploadCertificate = async (values) => {
    setIsMediaUploading(true);
    let payload = "";
    const setPayload = () => {
      if (editGuuid) {
        return (payload = {
          DealersShipGuid: editGuuid,
          CertificateFile: values.document,
        });
      } else {
        return (payload = {
          DealersShipGuid: postGuuid,
          CertificateFile: values.document,
        });
      }
    };

    try {
      let payload = setPayload();
      const data = await CreateDealershipCertificateDetails(payload);
      if (data?.Status === 201) {
        toast({
          title: "Successfully Uploaded",
          position: "top-right",
          status: "success",
        });
        getDealershipDetails();
        getUploadedCertificate(getDealershipDataById);
      } else {
        toast({
          title: "Error In Uploading",
          position: "top-right",
          status: "error",
        });
        getDealershipDetails();
        getUploadedCertificate(getDealershipDataById);
      }
    } catch (error) {
    } finally {
      setIsMediaUploading(true);
    }
  };

  const handleCertificateDelete = async (values) => {
    const idToDelete = getCertificateList[0]?.id;

    try {
      const data = await deleteDealershipCertificate(
        convertToBase64(idToDelete)
      );
      if (data?.Status === 204) {
        toast({
          title: "Deleted Successfully",
          position: "top-right",
          status: "success",
        });
        getDealershipDetails();
        getUploadedCertificate(getDealershipDataById);
      } else {
        toast({
          title: "Error In Deleting",
          position: "top-right",
          status: "success",
        });
        getDealershipDetails();
        getUploadedCertificate(getDealershipDataById);
      }
    } catch (error) {}
  };

  return (
    <>
      <ConfirmMediaPopUp />
      <QrPopUp />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleMediaSubmit}
        enableReinitialize
      >
        {({ values }) => (
          <Form id="post-dealership-step-two">
            <Flex
              alignItems={"center"}
              justifyContent={"space-between"}
              flexWrap={"wrap"}
              padding={"2em"}
              gap={"1em"}
            >
              {/* Image Field */}
              <div className="media-display-container">
                <UploadMediaPopUp
                  modalTitle="Upload Photo"
                  documentType="Photo"
                  isModalOpen={isPhotoOpen}
                  mediaComponentTitle="Upload a photo and select a name"
                  mediaExtentionsAllowed={["jpg", "jpeg", "img"]}
                  maxFileSizeinMb={parseInt(5)}
                  handleSubmit={(values) => {
                    handleUploadImage(values);
                  }}
                  onCloseButtonClick={() => {
                    dispatch(closePhotoModal());
                  }}
                  dropdownValues={photoTypeList}
                  valuesToDeactivate={imageList}
                  isLoading={isMediaUploading}
                />
                <MultipleImageUpload
                  title="Store Photos"
                  maxWidth="100%"
                  onAddButtonClick={() => {
                    dispatch(openPhotoModal());
                  }}
                  mediaArray={JSON.parse(getLocalImageList)}
                  isDisabled={
                    Array.isArray(photoTypeList) &&
                    Array.isArray(getLocalImageList) &&
                    getLocalImageList.length === photoTypeList.length
                  }
                  deleteAction={async (value) => {
                    await handleImageDelete(value);
                  }}
                />
              </div>

              {/* Video Filed */}
              <div className="media-display-container">
                <UploadMediaPopUp
                  modalTitle="Upload Video"
                  documentType="Video"
                  isModalOpen={isVideoOpen}
                  mediaComponentTitle="Upload a video and select a name"
                  mediaExtentionsAllowed={["mp4", "mkv", "avi"]}
                  maxFileSizeinMb="10"
                  handleSubmit={(values) => {
                    handleUploadVideo(values);
                    dispatch(closeVideoModal());
                  }}
                  onCloseButtonClick={() => {
                    dispatch(closeVideoModal());
                  }}
                  dropdownValues={videoTypeList}
                  valuesToDeactivate={videoList}
                  isLoading={isMediaUploading}
                />
                <MultipleImageUpload
                  title="Store Videos"
                  maxWidth="100%"
                  onAddButtonClick={() => {
                    dispatch(openVideoModal());
                  }}
                  type="video"
                  mediaArray={JSON.parse(getLocalVideoList)}
                  isDisabled={
                    Array.isArray(videoTypeList) &&
                    Array.isArray(getLocalVideoList) &&
                    getLocalVideoList.length === videoTypeList.length
                  }
                  deleteAction={async (value) => {
                    await handleVideoDelete(value);
                  }}
                />
              </div>

              {/* Certificate Field */}
              <div className="media-display-container">
                <UploadMediaPopUp
                  modalTitle="Dealership Certificate"
                  isModalOpen={isCertificateOpen}
                  mediaComponentTitle="Upload Certificate"
                  mediaExtentionsAllowed={["jpg", "jpeg", "png", "pdf"]}
                  maxFileSizeinMb="5"
                  handleSubmit={(values) => {
                    handleUploadCertificate(values);
                    dispatch(closeCertificateModal());
                  }}
                  onCloseButtonClick={() => {
                    dispatch(closeCertificateModal());
                  }}
                  hasDropdown={false}
                  isLoading={isMediaUploading}
                />
                <MultipleImageUpload
                  title="Dealership Certificate"
                  maxWidth="100%"
                  mediaArray={certificateList}
                  onAddButtonClick={() => {
                    dispatch(openCertificateModal());
                  }}
                  deleteAction={(value) => handleCertificateDelete(value)}
                  isDisabled={getCertificateList?.length > 0}
                />
              </div>
            </Flex>
          </Form>
        )}
      </Formik>
      {/* </Box>
        </Flex>
      </Flex> */}
    </>
  );
};

export default AddDealershipStepTwo;
