import { createAsyncThunk } from "@reduxjs/toolkit";
import { getContactRequestReceivedListForE9MarketPlaceAsset } from "../../../services/myLeads/myLeads";

export const getContactRequestListThunk = createAsyncThunk(
  "myLeads/getContactRequestReceived",
  async (_, { getState }) => {
    try {
      const { E9MarketPlaceId } = getState().myLeads;
      const data = await getContactRequestReceivedListForE9MarketPlaceAsset(
        E9MarketPlaceId
      );
      return data;
    } catch (error) {}
  }
);
