// SideMenuProfile.js

import React from "react";
import { useSelector } from "react-redux";
import Avatar from "../../../assets/webapp/doc_flow/My-profile.png";
import { ReactSVG } from "react-svg";
import "./NewSideMenuProfile.css";

export const NewSideMenuProfile = () => {
  const userData = useSelector((state) => state.user);
  const loginData = useSelector((state) => state.login);

  const userRole =
    userData?.accountDetails?.UserDetails?.E9UserRole?.UserRoleTypeName;
  const firstName =
    userData?.accountDetails?.UserDetails?.PersonalDetails?.FirstName;
  const lastName =
    userData?.accountDetails?.UserDetails?.PersonalDetails?.LastName;

  const fullName = firstName && lastName ? `${firstName} ${lastName}` : null;

  // console.log("USER DATA : ",userRole);

  return (
    <>
      <div className="side-menu-profile-container">
        <div className="side-menu-profile-left">
          <div className="side-menu-profile-left-img-container">
            <img
              className="side-menu-profile-left-img"
              src={
                userData.accountDetails?.UserDetails?.ProfilePicture
                  ?.ProfilePictureFileReference
                  ? `${process.env.REACT_APP_CDN_PVT_URL}UserProfilePicture/${userData.accountDetails.UserDetails.ProfilePicture.ProfilePictureFileReference}`
                  : Avatar
              }
              alt="Profile"
            />
          </div>

          <div className="side-menu-profile-left-rating">
            <ReactSVG
              className="user-rating-icon"
              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/star.svg`}
            />
            <p className="user-rating-text">
              {
                userData?.accountDetails?.UserDetails?.CredentialDetails
                  ?.OverAllRating
              }
            </p>
          </div>
        </div>

        <div className="side-menu-profile-right">
          {/* Details Profile */}
          <p className="side-menu-profile-name">{fullName || "User"}</p>

          <p className="side-menu-profile-contact">
            {userData.accountDetails
              ? `+${userData.accountDetails?.UserDetails.CredentialDetails.IsdCode} ${userData.accountDetails?.UserDetails.CredentialDetails.MobileNumber}`
              : "N.A."}
          </p>

          <p className="side-menu-profile-type">
            {loginData.selectedAccount
              ? `${loginData.selectedAccount.BusinessTypeValue}`
              : "N.A."}
          </p>
          <p className="side-menu-profile-role">
            {userRole
              ? userRole.length > 10
                ? `${userRole.substring(0, 10)}...`
                : userRole
              : "Not Selected"}
          </p>
        </div>
      </div>
    </>
  );
};
