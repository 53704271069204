import * as Yup from "yup";
import { Formik } from "formik";
import { Link, useSearchParams } from "react-router-dom";
import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { handleLoginThunk } from "../../thunks/loginThunk";
import { hashToMd5 } from "../../../../helpers/dataConversion/convertToMd5";
import { LoginSuccessModal } from "../../components/LoginSuccessModal/LoginSuccessModal";
import { ChooseAccountModal } from "../../components/ContinuetoLoginModal/ChooseAccountModal";
import { FormikPhoneNumberInput } from "../../../../components/Input/PhoneNumberInput/FormikPhoneNumberInput";
import { FormikPasswordInput } from "../../../../components/Input/FormikPasswordInput";
import { setSelectedCountryThunk } from "../../../global/appConfig/thunk/setSelectedCountryThunk";
import { clearLoginData } from "../../slice/loginSlice";
import "../../../../../website/Pages/Home/Home.css";
import { defaultEnglishJson } from "../../../../../staticData/staticData";
import { getDefaultAppLanguage } from "../../../global/mtData/helpers/localStorage";
import { getAllLanguageJsonThunk } from "../../../global/mtData/thunk/getAllLanguagesThunk";
import { setLanguage } from "../../../global/appConfig/slice/appConfigSlice";
import { V5MultiAdLayout } from "../../../../layout/multiAdLayout/V5MultiAdLayout";
import { NAVIGATION_CONSTANTS } from "../../../../../constants/naigationContants";
import { Helmet } from "react-helmet"; 

export const Login = () => {
  const masterData = useSelector((state) => state.masterData);
  const appConfig = useSelector((state) => state.appConfig);
  const loginData = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const toast = useToast();
  // const isMobile = useWindowWidth();
  const defaultLanguage = getDefaultAppLanguage();
  let appLanguage = "";
  const [searchParams, setSearchParams] = useSearchParams();
  const assetIdParam = searchParams.get(NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID);
  const [loading, setLoading] = useState(false)

  const setAppLanguage = () =>{
    appLanguage = JSON.parse(localStorage.getItem("E9_APP_LANG"));
  }
  useEffect(()=>{
    setAppLanguage();
  },[!appLanguage])
  const handleLoginError = () => {
    return toast({
      status: "error",
      title: `${loginData.errorMessage}`,
      isClosable: true,
      position: "top-right",
    });
  };
  useEffect(() => {
    if (!defaultLanguage) {
      dispatch(setSelectedCountryThunk("91"));
      dispatch(getAllLanguageJsonThunk("english.json"));
    }
  }, [!defaultLanguage]);

  // useEffect(() => {
  //   const selectedAccountData = getSelectedAccountInLocalStorage()
  //   storeUserIP();

  // }, []);

  useEffect(() => {
    if (loginData.errorMessage) {
      handleLoginError();
    }
  }, [loginData.errorMessage]);

  useEffect(() => {
    dispatch(setSelectedCountryThunk("91"));
    dispatch(clearLoginData());
    handleDefaultLanguage();
    // dispatch(resetRouteArray())
  }, []);
  const handleDefaultLanguage = () => {
    if (appLanguage) {
      dispatch(getAllLanguageJsonThunk(appLanguage?.JsonFileName));
      dispatch(setLanguage(appLanguage));
    } else {
      dispatch(getAllLanguageJsonThunk("english.json"));
      dispatch(setLanguage(defaultEnglishJson));
    }
  };
  return (
    <>
   <Helmet>
      <link
        rel="Login page"
        href="https://www.equip9.com/login/login-form"
      />
      <meta name="keywords" content="heavy equipment machinery"/>
      <title>Equip9 Operator Match: Register as Equipment Owner</title>
      <meta name="description" content="Join Equip9 to connect with heavy equipment professionals. 
      Explore new purchases and rentals on our platform."/>
      
      <h1>Welcome to Equip9: Connecting Heavy Equipment Machinery Professionals</h1>
      <h2>Streamline Your Heavy Equipment Machinery Network with Equip9</h2>
      <h3>Unlock Opportunities for Buying and Renting Heavy Equipment Machinery</h3>
      </Helmet>
      
    <V5MultiAdLayout
      heading={defaultLanguage?.Lets_Login}
      subHeading={defaultLanguage?.Its_quick_and_easy}
    >
      <LoginSuccessModal />
      <ChooseAccountModal />
      <Formik
        initialValues={{
          mobileNumber: "",
          password: "",
          countryCode: appConfig.country
            ? appConfig.country.CountryIsdCode
            : "",
        }}
        validationSchema={Yup.object({
          mobileNumber: Yup.string()
            .required("Mobile Number Required")
            .length(10, "Exactly 10 digits allowed"),
          password: Yup.string()
            .required("Password Required")
            .min(6, "Minimum 6 characters required"),
          countryCode: Yup.string().required("Country Code required"),
        })}
        onSubmit={async (values) =>{
          setLoading(true)
          dispatch(
            handleLoginThunk({
              IsRegisterWith: "0",
              Username: parseInt(values.mobileNumber),
              IsdCode: values.countryCode,
              Password: hashToMd5(values.password),
            })
          )
          setLoading(false)
        }
      }
      >
        {({ values, handleSubmit }) => (
          <form onSubmit={handleSubmit} id="login-form">
            <Flex
              flexDirection="column"
              alignItems="center"
              maxW="60%"
              gap="2em"
              m="0 auto"
              maxH="258px"
              overflowY="scroll"
            >
              <FormikPhoneNumberInput
                isRequired={true}
                label="Mobile Number"
                name="mobileNumber"
                countrycodename="countryCode"
                placeholder="E.g. 9876543210"
                dropdownValues={masterData.Countries}
                value={values.countryCode}
              />
              <FormikPasswordInput
                isRequired={true}
                label="Password"
                name="password"
                type="password"
                placeholder="Must have at least 6 characters..."
                hasForgetPassword={true}
              />
            </Flex>
          </form>
        )}
      </Formik>
      <Box textAlign="center">
        <Text fontWeight="semibold" color="#2c2c2c80">
          {defaultLanguage?.Dont_have_an_account}{" "}
          <Link
            to="/register/select-business-type"
            style={{ color: "#f79c00", fontWeight: 700 }}
          >
            {defaultLanguage?.SignUP}
          </Link>
        </Text>
      </Box>

      <Flex
        justifyContent="center"
        alignItems="center"
        bgColor="#fff"
        h="78px"
        style={{ boxShadow: "0px -10px 15px #2c2c2c0f" }}
      >
        <Button
          variant="gradient"
          type="submit"
          form="login-form"
          width="80%"
          margin="0 auto"
          maxW="347px"
          isLoading={loading}
        >
          {defaultLanguage?.Continue}
        </Button>
      </Flex>
    </V5MultiAdLayout>
    </>
  );
};
