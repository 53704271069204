export const calculateTotalLeads = (array) =>
  array.reduce((accumulator, currentValue) => {
    if (currentValue.isSelected) {
      accumulator += currentValue.quantitySelected;
    }
    return accumulator;
  }, 0);


  export const calculateTotalAmountWithoutServiceDiscount = (array) => array.reduce((accumulator, currentValue) => {
    if(currentValue.isSelected){
        accumulator += parseFloat(currentValue.quantitySelected * currentValue.ServiceContactUnitPrice);
    }
    return accumulator;
  }, 0)

  export const calculateTotalDiscountValue = (array) => array.reduce((accumulator, currentValue) => {
    if(currentValue.isSelected && currentValue.ServiceDiscountTypeValue){
         accumulator += parseFloat(((currentValue.ServiceContactUnitPrice * currentValue.quantitySelected) *  currentValue.ServiceDiscountTypeValue) / 100)
    }
    
    return accumulator;
  }, 0)

  export const convertDateTimeToMilliseconds = (time) => {
    const timeInMs = new Date(time).getTime();
    return timeInMs;
  }