import { combineReducers } from "redux";
import { remLeadsSlice } from "./remLeadsSlice";
import { uemLeadsSlice } from "./uemLeadsSlice";
import { dealershipLeadSlice } from "./dealershipLeadSlice";
import { materialLeadSlice } from "./materialLeadSlice";
import { industryLeadSlice } from "./industryLeadSlice";
import { purchasedLeadByIdSlice } from "./purchasedLeadByIdSlice";

export const purchasedLeadSlice = combineReducers({
    rentalEquipment: remLeadsSlice.reducer,
    usedEquipment: uemLeadsSlice.reducer,
    dealership : dealershipLeadSlice.reducer,
    material : materialLeadSlice.reducer,
    industry : industryLeadSlice.reducer,
    leadsById  : purchasedLeadByIdSlice.reducer
})