import React from "react";
import { useNavigate } from "react-router-dom";

import "./Settings.css";
import { settingsData } from "../../../staticData/staticData";
import { v4 as uuid } from "uuid";
import { Box, Flex, Text } from "@chakra-ui/react";
import { V5SidebarLayout } from "../../layout/V5SidebarLayout/V5SidebarLayout";

export const Settings = () => {
  const navigate = useNavigate();

  
  return (
      <V5SidebarLayout>
          <Flex flexDirection="column" gap="2em" padding="2em 1em">
            {settingsData &&
              settingsData.map((item) => (
                <Flex
                  key={uuid}
                  onClick={() => navigate(`${item.settingRoute}`)}
                  borderRadius="10px"
                  border="1px solid #E3E5E8"
                  background="#FFF"
                  boxShadow="0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)"
                  alignItems="center"
                  justifyContent="space-between"
                  padding="1em 1em"
                  cursor="pointer"
                  _hover={{
                    border: "1px solid #F79C00",
                    background: "#FFFBF5",
                  }}
                >
                  <Box display="flex" alignItems="center" gap="2em">
                    <img
                      src={item?.settingHeroIcon}
                      _hover={{ filter: "brightness(1.5)", color: "#F79C00" }}
                    />
                    <Text color="#767676" fontSize="22px" fontWeight="700">
                      {item?.settingHeading}
                    </Text>
                  </Box>

                  <Box>
                    <img src={item?.arrowIcon} />
                  </Box>
                </Flex>
              ))}
          </Flex>
          </V5SidebarLayout>
  );
};
