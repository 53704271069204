import  React from 'react';
import { SideMenuLayout } from '../../webapp/layout/sideMenuLayout/SideMenuLayout';
import { DashboardNavBar } from '../../webapp/components/DashboardNavBar/DashboardNavBar';
export const LoadingComponent=()=>{
    return(
        <>
        <SideMenuLayout>
        <DashboardNavBar />
        <div>
            Loading
        </div>

        </SideMenuLayout>
        </>
    )
}