import { Box, Button, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { V5Sidebar } from "../../components/V5Sidebar/V5Sidebar";
import { NewDashboardNavBar } from "../NewSideMenuLayout/NewDashboardNavbar";

export const V5FixedFooterSidebarLayout = ({
  footerAlignItems = "center",
  primaryButtonText = "Primary",
  primaryButtonBorderColor = "",
  primaryButtonTextColor = "",
  primaryButtonBackground = "",
  primaryButtonIcon = '',
  primaryButtonVariant = "ghost",
  primaryButtonClickAction = () => {},
  primaryButtonDisabled = false,
  primaryButtonMaxWidth = "",
  primaryButtonWidth = "",
  primaryButtonLoading = false,
  secondaryText = "",
  secondaryElement = "text",
  secondaryButtonClickAction = () => {},
  secondaryButtonLoading = false,
  secondaryButtonVarient = 'ghost',
  secondaryButtonBorder='',
  secondaryButtonColor='',
  secondaryButtonLeftIcon='',
  formName = "",
  children,
}) => {
  return (
    <Grid
      gridTemplateColumns={[
        "repeat(4, 1fr)",
        "repeat(4, 1fr)",
        "repeat(4, 1fr)",
        "repeat(8, 1fr)",
        "repeat(10, 1fr)",
        "repeat(12, 1fr)",
      ]}
      gridTemplateRows={[
        "80px 1fr 80px",
        "80px 1fr 80px",
        "80px 1fr 80px",
        "80px 1fr 80px",
        "80px  1fr 80px",
        "80px  1fr 80px",
      ]}
      gap="4"
      minHeight="100vh"
      height="100vh"
      maxW="1536px"
      margin="0 auto"
    >
      <GridItem
        gridRow="1/4"
        gridColumn={["0", "0", "0", "0", "span 2", "span 2"]}
        display={["none", "none", "none", "none", "block", "block"]}
        boxShadow="md"
      >
        <V5Sidebar />
      </GridItem>
      <GridItem
        gridRow="1/2"
        gridColumn={[
          "span 4",
          "span 4",
          "span 4",
          "span 8",
          "span 8",
          "span 10",
        ]}
        boxShadow="md"
      >
        <NewDashboardNavBar />
      </GridItem>
      <GridItem
        gridRow="2/3"
        gridColumn={[
          "span 4",
          "span 4",
          "span 4",
          "span 8",
          "span 8",
          "span 10",
        ]}
        maxW="100%"
        overflowY="scroll"
      >
        {children}
      </GridItem>
      <GridItem
        gridRow="3/4"
        boxShadow="md"
        gridColumn={[
          "span 4",
          "span 4",
          "span 4",
          "span 8",
          "span 8",
          "span 10",
        ]}
      >
        <Flex
          height="100%"
          width="100%"
          alignItems="center"
          boxShadow="0 -4px 6px -1px rgba(0, 0, 0, 0.1),0 -2px 4px -1px rgba(0, 0, 0, 0.06)"
          justifyContent={secondaryText ? "space-between" : "center"}
          padding="1rem 3rem"
        >
          {secondaryText && secondaryElement === "button" ? <Button 
          variant={secondaryButtonVarient}
          border={secondaryButtonBorder}
          color={secondaryButtonColor}
          leftIcon={secondaryButtonLeftIcon}
          isLoading={secondaryButtonLoading} onClick={secondaryButtonClickAction}>{secondaryText}</Button>  : secondaryText ?  <Text
              padding="0.5rem 1rem"
              border="1px solid #011f6b"
              color="#011f6b"
              fontWeight="600"
              borderRadius="0.25rem"
            >
              {secondaryText}
            </Text> : <></>}
          {/* {secondaryText && (
            <Text
              padding="0.5rem 1rem"
              border="1px solid #011f6b"
              color="#011f6b"
              fontWeight="600"
              borderRadius="0.25rem"
            >
              {secondaryText}
            </Text>
          )} */}
          <Button
            onClick={primaryButtonClickAction}
            border={primaryButtonVariant === "gradient" ? "" : "1px solid"}
            color={
              primaryButtonVariant === "gradient"
                ? "#FFF"
                : primaryButtonTextColor
            }
            borderColor={primaryButtonBorderColor}
            variant={primaryButtonVariant}
            isDisabled={primaryButtonDisabled}
            maxWidth={primaryButtonMaxWidth}
            width={primaryButtonWidth}
            form={formName}
            isLoading={primaryButtonLoading}
            backgroundColor = {primaryButtonBackground}
            leftIcon={primaryButtonIcon ? <img src={`${process.env.REACT_APP_CDN_STATIC_URL}${primaryButtonIcon}`} /> : null}
          >
            {primaryButtonText}
          </Button>
        </Flex>
      </GridItem>
    </Grid>
  );
};
