export const dealershipInitialState= {
    data: null,
    paginationCount: 0,
    endOfData: false,
    cardCount: 12,
    initialLoading: false,
    paginationLoading: false,
    error: null,
    errorMessage: null,
    assetImageReference : null,
}