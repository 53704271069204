import React  from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./ImageCarouseImprove.css";
import placeholderImage from "../../../../assets/logo/equip9_logo_light.png";

export const ImageCarouselImprove = ({
  images = []
}) => {
  const handleImageClick = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  return (
    <div style={{ width: '100%', height: 'auto', position: 'relative' }}>
    <Carousel
      infiniteLoop
      showIndicators={false}
      showArrows={false}
      autoPlay={true}
      showStatus={false}
      showThumbs={false}
      transitionTime={2000}
    >
      {images.map((image, index) => (
        <div
          key={index}
          style={{ cursor: "pointer", width: '100%', height: 'auto', objectFit: 'cover' }}
          onClick={() => handleImageClick(image.link)}
        >
          <img
            src={image.url}
            style={{ width: '100%', height: 'auto' }} // Responsive image sizing
            loading="lazy" // Enable lazy loading
            alt={`image-${index + 1}`}
            onError={(e) => {
              e.target.src = placeholderImage;
            }}
          />
        </div>
      ))}
    </Carousel>
  </div>

  );
};
