import { useNavigate, useParams } from "react-router-dom";
import { MarketplaceHeader } from "../../../../webapp/components/MarketplacHeader/MarketplaceHeader";
import { MarketplaceHoc } from "../../../../webapp/features/marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import fetchHandler from "../../../../webapp/services/fetchHandler";
import { API_CONSTANTS } from "../../../../constants/apiConstants";
import { useEffect, useState } from "react";
import { LoginPopUp } from "../../../components/PopUps/LoginPopUp";
import { useDispatch, useSelector } from "react-redux";
import { NearMePartnerMarketplaceCard } from "../../../../webapp/components/NearMePartnerMarketplaceCard/NearMePartnerMarketplaceCard";
import { subString } from "../../../../webapp/helpers/dataConversion/substring";
import { getOpDeferredThunk } from "../../Product/thunk/getOpDeferredThunk";
import { openQrModal } from "../../../../webapp/features/dashboard/slice/dashboardSlice";
import { QrPopUp } from "../../../../webapp/components/ScanQrCodeModal/QrPopUp";
import { opLoadMoreDeferred } from "../../Product/thunk/opLoadMoreDeferred";
import logo from "../../../../assets/logo/equip9_logo_dark.png";
import { primaryKeyEncryption } from "../../../../webapp/helpers/dataConversion/primaryKeyEncryption";
import BrandHeader from "./BrandHeader";

export const OperatorDeferredMarketplace = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [encryptedAssetId, setEncryptedAssetId] = useState("");
  const opDeferred = useSelector((state) => state.opDeferred);
  const { subcategory } = useParams();
  const category = "partner";
  const subCategory = "nearmeoperator"

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOpDeferredThunk());
  }, []);

  const encryptAssetDetails = async (assetId) => {
    try {
      const { data } = await fetchHandler(
        API_CONSTANTS.ENCRYPT_SHARE_DATA,
        {},
        "",
        {
          marketplaceCategory: category,
          marketplaceSubCategory: subCategory,
          assetId: assetId,
        },
        API_CONSTANTS.HTT_POST,
        { isAuthRequired: false }
      );
      setEncryptedAssetId(data.Data.shareData);
    } catch (error) {
    }
  };
  const renderCards = (array = []) => {
    const renderJsx =
      array && array.length > 0 ? (
        array.map((nearMeOperator) => (
          <NearMePartnerMarketplaceCard
            certificateIssuerName={
              nearMeOperator.CertificateIssuerName
                ? subString(25, nearMeOperator.CertificateIssuerName.toString())
                : "N.A."
            }
            equipmentTypeValue={
              nearMeOperator.EquipmentTypeValue
                ? subString(25, nearMeOperator.EquipmentTypeValue)
                : "N.A."
            }
            experienceMonth={nearMeOperator.ExperienceMonth}
            experienceYear={nearMeOperator.ExperienceYear}
            imageBaseUrl={"UserProfilePicture/"}
            profilePictureUrl={nearMeOperator?.ProfilePictureFileReference}
            isdCode={nearMeOperator.IsdCode}
            name={nearMeOperator.PartnerName}
            rating={nearMeOperator.OverAllRating}
            salary={nearMeOperator.MonthlySalary}
            verificationStatus={nearMeOperator.PartnerVerificationStatus}
            onClick={() => {
              // dispatch(openQrModal());
              navigate(`/deferred/marketplace/partners/Cards/${subCategory}/${primaryKeyEncryption(nearMeOperator?.UserCredentialId)}`)
            }}
            onContact={async () => {
              await encryptAssetDetails(nearMeOperator.UserCredentialId);
              setModalOpen(true);
            }}
            // onContact={() => dispatch(openQrModal())}
            isShareEnabled={false}
            isContactDisabled={false}
          />
        ))
      ) : (
        <p>No data to display</p>
      );
    return renderJsx;
  };

  return (
    <>
      <QrPopUp />
      <LoginPopUp
        isModalOpen={modalOpen}
        closeModal={() => {
          setModalOpen(false);
          setEncryptedAssetId("");
        }}
        onLoginClick={() => {
          sessionStorage.setItem("DEFERRED_MARKETPLACE", encryptedAssetId);
          navigate(`/login/login-form?id=${encryptedAssetId}`);
        }}
        onRegisterClick={() => {
          sessionStorage.setItem("DEFERRED_MARKETPLACE", encryptedAssetId);
          navigate(`/welcome/select-language?id=${encryptedAssetId}`);
        }}
      />
      <BrandHeader />

      <MarketplaceHeader
        heading={"Operator Partners"}
        filterButtonRequired={false}
      />
      <MarketplaceHoc
        marketplaceName="Deferred Marketplace"
        marketplaceType={"partners"}
        dataArray={opDeferred?.data}
        // userImageBaseReference={
        //   opDeferred?. ? uemData.userImageReference : ""
        // }
        assetImageBaseReference={
          opDeferred ? opDeferred?.AssetImageBaseReference : ""
        }
        endOfData={opDeferred?.endOfData}
        loadMoreAction={() => {
          dispatch(opLoadMoreDeferred(opDeferred?.paginationCount));
        }}
        initialLoading={opDeferred?.initialLoading}
        paginationLoading={opDeferred?.paginationLoading}
        cardsRenderFunction={renderCards}
      />
    </>
  );
};
