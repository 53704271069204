import axios from "axios";
import { API_CONSTANTS } from "../../../constants/apiConstants";
import { fetchHandler } from "../../../index";
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData";
import { convertDateTimeToMilliseconds } from "../../features/subscription/helpers/helpers";

export const getLoginDetails = async (body) => {
  try {
    const { data } = await fetchHandler(
      API_CONSTANTS.GET_LOGIN_DETAILS,
      {},
      {},
      body,
      API_CONSTANTS.HTT_POST,
      { isAuthRequired: false }
    );
    return data;
  } catch (error) {}
};

export const getUserDetails = async () => {
  try {
    const response = await fetchHandler(API_CONSTANTS.GET_USER_DETAILS);
    return response;
  } catch (error) {}
};

export const generateJwtToken = async (body) => {
  try {
    const { data } = await fetchHandler(
      API_CONSTANTS.GENERATE_JWT_TOKEN,
      {},
      {},
      body,
      API_CONSTANTS.HTT_POST,
      { isAuthRequired: false }
    );
    if (
      data &&
      Object.keys(data).length > 0 &&
      data.Data &&
      Object.keys(data.Data).length > 0 &&
      data.Status &&
      data.Message.SuccessMessage
    ) {
      return {
        token: data.Data.Token,
        issuedAt: convertDateTimeToMilliseconds(
          `${data.Data.InitializedTime} UTC`
        ),
        expiryTime: parseInt(data.Data.ExpiryTimeInMinute) * 60 * 1000,
      };
    } else {
      const error = new Error(data.Message.FailMessage);
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

export const updateUserLanguage = async (languageId) => {
  try {
    const data = fetchHandler(
      API_CONSTANTS.UPDATE_USER_LANGUAGE,
      {},
      {},
      convertToFormData({ LanguageId: languageId }),
      API_CONSTANTS.HTT_POST
    );
    return data;
  } catch (error) {
    return null;
  }
};
