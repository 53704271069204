import { createAsyncThunk } from "@reduxjs/toolkit";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";
import { getMaterialMarketplaceList } from "../../../services/materials/materials";


export const safetyEquipmentLoadMoreDataThunk = createAsyncThunk("safetyEquipment/loadMoreCards", async(_, {getState}) => {

    const safetyEquipmentId = convertToBase64('5')
    const {paginationCount , cardCount} = getState().safetyEquipmentMaterialMarketplace
   


try{
   
    const data = await getMaterialMarketplaceList(cardCount,(paginationCount * cardCount),safetyEquipmentId);
    
    if(data.Status === 200 && Object.keys(data.Data).length > 0){
        return data.Data
    }

   
}
catch(error){
}
})