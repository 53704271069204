import { createSlice } from "@reduxjs/toolkit";
import { mpJobInitialState } from "../constants/mpJobInitialState";
import { getMpJobDeferredThunk } from "../thunk/getMpJobDeferredThunk";
import { mpJobLoadMoreDeferred } from "../thunk/mpJobLoadMoreDeferred";


export const mpJobDeferredSlice = createSlice({
    name:"mpJobDeferred",
    initialState: mpJobInitialState,
    reducers:{
        setData: (state,{payload})=>{
            state.data = payload
        }
    },

    extraReducers:(builder) =>{
        builder
        .addCase(getMpJobDeferredThunk.pending, (state)=>{
            state.data = null;
            state.initialLoading = true;
            state.paginationLoading = false;
            state.error = false;
            state.errorMessage = false;
            state.paginationCount = 0;
            state.endOfData = false
        })
        .addCase(getMpJobDeferredThunk.fulfilled,(state,{payload}) =>{
            console.log("fulfiled mp",payload?.JobsDetails);
            state.paginationCount = 1;
            state.initialLoading = false;
            state.error = false;
            state.errorMessage = null;
            state.data = payload?.JobsDetails;
            if(payload?.JobsDetails?.length < state.cardCount){
              state.endOfData = true
            }
        })
        .addCase(mpJobLoadMoreDeferred.pending, (state)=>{
            state.paginationLoading = true;
            state.error = false ;
            state.errorMessage = null;
         })
         .addCase(mpJobLoadMoreDeferred.fulfilled, (state,{payload})=>{
            state.error = false ;
            state.errorMessage = null ;
            state.paginationLoading = false;
            state.assetImageReference = payload?.AssetImageBaseReference
            if(payload?.JobsDetails?.length >= state.cardCount){
                state.data = [...state?.data , ...payload?.JobsDetails];
                state.paginationCount += 1;
            }else{
                state.endOfData = true;
         }
         })
    }
})

export const {
    setData
}= mpJobDeferredSlice.actions;