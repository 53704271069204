import { getLocalStorageItems , setLocalStorageItems } from "../../../../../helpers/localStorage/localStorage"
import { STORAGE_CONSTANTS } from "../../../../../../constants/storageConstants"

export const getCountriesFromLocalStore = () => {
return getLocalStorageItems(STORAGE_CONSTANTS.ALL_COUNTRIES)
}

export const getBusinessTypesFromLocalStore = () => {
    return getLocalStorageItems(STORAGE_CONSTANTS.ALL_BUSINESS_TYPES)
}

export const getUserTypesFromLocalStore = () => {
    return getLocalStorageItems(STORAGE_CONSTANTS.ALL_USER_TYPES)
}

export const getAllLangaugesFromLocalStore = () => {
    return getLocalStorageItems(STORAGE_CONSTANTS.ALL_LANGUAGES)
}

export const setCountriesToLocalStore = (data) => {
    return setLocalStorageItems(STORAGE_CONSTANTS.ALL_COUNTRIES, data);
}

export const setLanguagesToLocalStore = (data) => {
    return setLocalStorageItems(STORAGE_CONSTANTS.ALL_LANGUAGES, data)
}

export const setBusinessTypesToLocalStore = (data) => {
    return setLocalStorageItems(STORAGE_CONSTANTS.ALL_BUSINESS_TYPES, data);
} 

export const setUserTypesToLocalStore = (data) => {
    return setLocalStorageItems(STORAGE_CONSTANTS.ALL_USER_TYPES, data);
}

export const getDefaultAppLanguage = (data) =>{
    return getLocalStorageItems(STORAGE_CONSTANTS.APP_DEFAULT_LANGUAGE, data)
}
export const setDefaultAppLanguage = (data) => {
    return setLocalStorageItems(STORAGE_CONSTANTS.APP_DEFAULT_LANGUAGE, data)
}