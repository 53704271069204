import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOwnerMarketplaceList } from "../../../services/ownerMarketplace/ownerMarketplace";

export const getInitialDataThunk = createAsyncThunk(
  "owners/initialData",
  async (_, { getState }) => {
    try {
      const cardCount = getState().ownersMarketplace.cardCount;
      const pageNumber = getState().ownersMarketplace.paginationCount;

      const data = await getOwnerMarketplaceList(
        cardCount,
        pageNumber * cardCount
      );

      if (data.Status === 200 && data.Data.E9OwnerMarketplaceList.length > 0) {
        return { data: data.Data.E9OwnerMarketplaceList };
      }
    } catch (error) {
      throw error;
    }
  }
);
