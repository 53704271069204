import React from 'react'
import { WebPageLayout } from '../../Layout/WebpageLayout';
import { Helmet } from 'react-helmet';
// import { GET_ALL_MEMBERS_IMAGE_LINKS, IS_MEMEBER_FEMALE } from '../../helpers/getS3Images';
import "./OurProduct.css"
import { ProductCarousel } from './ProductCarousel';
import { Productbanner } from './ProductBanner';
import { ourProduct } from '../../../staticData/staticData';
import "../Home/Home.css"
import { Flex } from '@chakra-ui/layout';
import { MobileResponsiveFooter } from '../../components/MobileResposiveFooter/MobileResponsiveFooter';
import product from '../../../assets/logo/our-product-banner-cut.png'
import sitemap from './seo/Product.xml';

export const OurProduct = () => {

  return (
    <>

      <div>
        <WebPageLayout>
          <Helmet>
            <title>Our Product | Heavy Equipment Industry Digital Marketplace</title>
            <meta name="description" content=" Search Heavy Equipment Operator or Rental Equipment, or Used Equipment or Contractor" />
            <meta name="keyword" content=" 
          skilled jcb operator,crane operator,dumper driver,engine mechanic,
          welder,electrical work,bucket,backhoe,wheel loader,excavator,breaker,
          hydraulic oil,engine oil,ropes,spare parts.
          " />
            <link rel="Our Product" href="https://equip9.com/our-product"></link>
          </Helmet>
        <Helmet>
          <title>Equip9|New & Used Construction Equipment for sale </title>
          <meta name="keywords" content="heavy equipment"/>
          <meta name="description" content="Equip9: Explore new and used JCB, Volvo, Tata Hitachi, or Mahindra construction equipment. 
          Get what you need, from rentals to reselling."/>
          <h1>Equip9 - Your Gateway 
            to Growth in the Heavy Construction Equipment Industry</h1>
          <h2>Find Top-Quality Construction Equipment for Sale, Access a wide range of rental options and 
            pre-owned equipment JCB, Volvo, Mahindra, or Tata Hitachi.</h2>
          <h3>Explore Our Range of Heavy Equipment Rental Options</h3>
          <link rel="sitemap" type="application/xml" title="Sitemap" href={sitemap}/>
        </Helmet>

          <div className='banner-hero-section-product'>
            <ProductCarousel
              array={[
                `${process.env.REACT_APP_CDN_STATIC_URL}Website/Product/ourProductHero.png`,
              ]}
            />
          </div>
          <div className='seperator-product'>

          </div>

          <div className='product-item'>

            {ourProduct.map(product => (
              <Productbanner
                key={product.id}
                type = {product?.type}
                headingText={product.headingText}
                highlightedText={product.highlightedText}
                description={product.description}
                bannerImage={product.bannerImage}
                marketplaceLink={product.marketplaceLink}
              />
            ))}
          </div>
        </WebPageLayout>
      </div>

    </>


  );
};


export default OurProduct