import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../constants/initialState";

export const materialMarketplaceSlice = createSlice({
  name: "materialMarketplace",
  initialState: initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },   
    setFilteredDataRequired: (state, action) => {
      state.filteredDataRequired = action.payload;
    },
    setFilterModalOpen: (state, action) => {
      state.filterModalOpen = action.payload;
    },
    setFilterByCountry: (state, action) => {
      state.filterByCountry = action.payload;
    },
    setFilterByState: (state, action) => {
      state.filterByState = action.payload;
    },
  },
  extraReducers: (builder) => {
    // builder
    //   .addCase(sparePartsInitialData.pending, (state) => {
    //     state.data = null;
    //     state.initialLoading = true;
    //     state.paginationLoading = false;
    //     state.error = false;
    //     state.errorMessage = false;
    //     state.paginationCount = 1;
    //     state.endOfData = false
    //   })
    //   .addCase(sparePartsInitialData.fulfilled, (state, action) => {
    //     console.log("Spare Parts STORE Slice",action);
    //     state.data = action.payload.MaterialMarketplaceList;
    //     state.assetImageReference = action.payload.AssetImageBaseReference;
    //     state.userImageReference = action.payload.UserProfilePicBaseReference;
    //     state.paginationCount = 1;
    //     state.initialLoading = false;
    //     state.error = false;
    //     state.errorMessage = null;
    //     if(action.payload.MaterialMarketplaceList.length < state.cardCount){
    //       state.endOfData = true
    //     }
    //   })
  },
});

export const {
  setData,
  setFilterModalOpen,
  setFilteredDataRequired,
  setFilterByCountry,
  setFilterByState,
} = materialMarketplaceSlice.actions;
