import React from "react";
import { SideMenuLayout } from "../../layout/sideMenuLayout/SideMenuLayout";
import { DashboardNavBar } from "../../components/DashboardNavBar/DashboardNavBar";
import { MidSection } from "../../../sharedComponent/midSection/MidSection";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Text } from "@chakra-ui/react";
import { UpcomingJobs } from "./JobStatusScrren/Ongoing/Ongoing";
import { Cancelled } from "./JobStatusScrren/Cancelled/Cancelled";
import { OnGoing } from "./JobStatusScrren/Upcoming/Upcoming";
import { Completedjob } from "./Completed/Completed";

import './JobStatus.css';
import { V5SidebarLayout } from "../../layout/V5SidebarLayout/V5SidebarLayout";
export const JobStatus = () => {
  return (
    <div>
      <V5SidebarLayout>
          <div className="tabs-section">
            <Tabs colorScheme='#F79C00'>
              <TabList>
                <Tab
               _selected={{ color: "#F79C00" , borderBottom : '1px solid #F79C00'}}
                width='25%'>
                  <Text fontWeight='700' fontSize='14px'>
                  Upcoming Jobs
                  </Text>
                  </Tab>
                <Tab 
               _selected={{ color: "#F79C00", borderBottom : '1px solid #F79C00'}}
                
                width='25%'>
                  <Text fontWeight='700' fontSize='14px'>
                  Ongoing Jobs
                  </Text>

                </Tab>
                <Tab 
               _selected={{ color: "#F79C00" , borderBottom : '1px solid #F79C00'}}
                
                width='25%'>
                                    <Text fontWeight='700' fontSize='14px'>
                  Completed Jobs
                  </Text>
                </Tab>
                <Tab 
               _selected={{ color: "#F79C00" , borderBottom : '1px solid #F79C00'}}
                
                width='25%'>
                                    <Text fontWeight='700' fontSize='14px'>
                  Cancelled Jobs
                  </Text>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <UpcomingJobs />
                </TabPanel>
                <TabPanel>
                  <OnGoing />
                </TabPanel>
                <TabPanel>
                   <Completedjob />
                </TabPanel>
                <TabPanel>
                  <Cancelled />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>

      </V5SidebarLayout>
    </div>
  );
};
