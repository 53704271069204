import { createSlice } from "@reduxjs/toolkit";
import { getSessionStorageItems, setSessionStorageItems } from "../../../../../../helpers/sessionStorage/sessionStorage";
import { object } from "prop-types";
import { initialState } from "../../../../../../features/rentalEquipmentMarketplace/constants/initialState";


export const postMaterialSlice = createSlice({
    name: "postMaterials",
    initialState : {
        stepperIndex: getSessionStorageItems("POST_MATERIAL_STEPPER_INDEX") ? parseInt(getSessionStorageItems("POST_MATERIAL_STEPPER_INDEX")) : 0,
        stepperRoute: [{ name: "Step 1" }, { name: "Step 2" }],
    },

    reducers : {
        increaseStepperIndex: (state) => {
            if(state.stepperRoute.length <= state.stepperIndex){
              return
            }
            state.stepperIndex += 1;
            setSessionStorageItems(
              "POST_MATERIAL_STEPPER_INDEX",
              (state.stepperIndex)
            );
          },
          decreaseStepperIndex: (state) => {
            if (state.stepperIndex === 0) {
              return;
            }
            if (state.stepperIndex < 0) {
              state.stepperIndex = 0;
            }
            state.stepperIndex -= 1;
            setSessionStorageItems(
              "POST_MATERIAL_STEPPER_INDEX",
              state.stepperIndex
            );
          },
          clearStepperIndex : (state) =>{
            state.stepperIndex = 0
          },
          clearMaterialData:(state)=>{
            Object.assign(state,initialState)
          }
    }
})

export const {
    increaseStepperIndex,
    decreaseStepperIndex,
    clearStepperIndex,
    clearMaterialData
} = postMaterialSlice.actions