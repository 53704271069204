import { createSlice } from "@reduxjs/toolkit";
import { getLocalStorageItems, setLocalStorageItems } from "../../../helpers/localStorage/localStorage";
import { STORAGE_CONSTANTS } from "../../../../constants/storageConstants";
import { generateOtpThunk } from "../thunk/otpThunk";
import { sendForgetPasswordThunk } from "../../forgetPassword/thunk/forgotPasswordThunk";



export const otpSlice = createSlice({
    name : "otp", 
    initialState: {
    mobileNumber : getLocalStorageItems(STORAGE_CONSTANTS.MOBILE_NUMBER),
    otp: getLocalStorageItems(STORAGE_CONSTANTS.OTP)  ?  getLocalStorageItems(STORAGE_CONSTANTS.OTP) : null,
    enteredOtp: "",
    otpTimerMinutes: "2",
    otpTimerSeconds: "120",
    isMatch: false,
    loading: false,
    error: "",

    },
    reducers: {
        setOtp: (state, action) => {
            state.otp = action.payload
            setLocalStorageItems(STORAGE_CONSTANTS.OTP, action.payload)
        },
        setMobileNumber: (state, action) => {
            state.mobileNumber = action.payload
            setLocalStorageItems(STORAGE_CONSTANTS.MOBILE_NUMBER, action.payload)
        },
        setIsMatch : (state, action) => {
            state.isMatch = action.payload
        },
        resetOtp : (state) => {
            state.otp = null
            setLocalStorageItems(STORAGE_CONSTANTS.OTP, null)
        },
        resetMobileNumber: (state) => {
            state.mobileNumber = null
            setLocalStorageItems(STORAGE_CONSTANTS.MOBILE_NUMBER, null)
        },
        setOtpError: (state, action) => {
            state.error= action.payload
        },
        setEnteredOtp : (state, action) => {
            state.enteredOtp = action.payload
            if(action.payload !== state.otp){
                state.isMatch = false
            }
        }
        
    },

    extraReducers : (builder) => {
        builder
        .addCase(generateOtpThunk.pending, (state) => {
            state.loading = true
            state.error = ""
            state.otp = null
        })
        .addCase(generateOtpThunk.fulfilled, (state, action) => {
            state.otp = action.payload;
            state.loading = false;
            state.error = null
            setLocalStorageItems(STORAGE_CONSTANTS.OTP, action.payload)
            
        })
        .addCase(generateOtpThunk.rejected, (state, action) => {
            
           
            state.otp = null;
            state.loading = false;
            state.error = action.payload
            state.mobileNumber = null
            setLocalStorageItems(STORAGE_CONSTANTS.OTP, null);
            setLocalStorageItems(STORAGE_CONSTANTS.MOBILE_NUMBER, null)
        })
        .addCase(sendForgetPasswordThunk.pending, (state) => {
            state.loading = true
            state.error = ""
            state.otp = null
            setLocalStorageItems(STORAGE_CONSTANTS.OTP, null)
        })
        .addCase(sendForgetPasswordThunk.fulfilled, (state, action) => {
            state.otp = action.payload.otp;
            state.loading = false;
            state.error = null
            setLocalStorageItems(STORAGE_CONSTANTS.OTP, action.payload.otp);
            

        })
        .addCase(sendForgetPasswordThunk.rejected, (state, action) => {
            state.otp = null;
            state.loading = false;
            state.error = action.payload
            state.mobileNumber = null
            setLocalStorageItems(STORAGE_CONSTANTS.OTP, null);
            setLocalStorageItems(STORAGE_CONSTANTS.MOBILE_NUMBER, null)
        })
    }
       


})

export default otpSlice.reducer

export const {setOtp, setMobileNumber, setIsMatch, resetOtp, resetMobileNumber, setOtpError} = otpSlice.actions