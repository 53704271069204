import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "@chakra-ui/icons";
import {
  closeAddDealershipModal,
  openVirtualDealershipModal,
  setEquipmentStatusId,
} from "../../features/MyBusinessDealershipDetail/slice/myBusinessDealershipSlice";

import "./DealershipPopUpCss.css";
import { getEquipmentStatusType } from "../../services/postDealership/postDealership";
import { v4 as uuid } from "uuid";
import { SelectCardWrapper } from "../SelectCardWrapper/SelectCardWrapper";
import { CustomGeneralModal } from "../CustomGeneralModal/CustomGeneralModal";

export const AddDealershipPopUp = () => {
  const dispatch = useDispatch();
  const postDealershipData = useSelector((state) => state.myBusinessDealership);
  const userData = useSelector((state) => state.user);
  const [selectedEquipmentStatusId, setSelectedEquipmentStatusId] =
    useState(null);
  const [validationError, setValidationError] = useState(null);

  // Equipment Status Id
  const [equipmentStatusType, setEquipmentStatusType] = useState();
  const [s3BaseRefrence, setS3BaseRefrence] = useState();

  useEffect(() => {
    (async () => {
      const response = await getEquipmentStatusType();
      if (response?.data?.Data?.Success === true) {
        setEquipmentStatusType(response?.data?.Data?.EquipmentStatusType);
        setS3BaseRefrence(response?.data?.Data?.S3BaseReference);
      }
    })();
  }, []);

  // console.log("equipmentStatusType", equipmentStatusType);
  // console.log("postDealershipData", postDealershipData);
  // console.log("IMAGE RESPONSE" , s3BaseRefrence);

  const handleSubmitClick = () => {
    if (selectedEquipmentStatusId != null) {
      dispatch(setEquipmentStatusId(selectedEquipmentStatusId));
      dispatch(closeAddDealershipModal());
      dispatch(openVirtualDealershipModal(true));
      setValidationError(null);
      setSelectedEquipmentStatusId(null);
    } else {
      setValidationError("Select Dealer Type.");
    }
  };
  const imageSelector = (input) => {
    if (input) {
      return (
        <img
          style={{ width: "90%", height: "90%" }}
          src={`${process.env.REACT_APP_CDN_PVT_URL}${s3BaseRefrence}${input}`}
        />
      );
    }
    return "icon";
  };

  return (
    // <Modal isOpen={postDealershipData.isAddDealershipModalOpen} isCentered >
    //     <ModalOverlay />
    //     <ModalContent>
    //         <ModalHeader>
    //             <Box textAlign="right">
    //                 <Button
    //                     variant="ghost"
    //                     onClick={() => {
    //                         dispatch(closeAddDealershipModal());
    //                     }}
    //                     boxShadow="none"
    //                 >
    //                     <CloseIcon />
    //                 </Button>
    //             </Box>
    //             <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
    //                 <img src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/connectIcon.svg`} style={{ maxHeight: "40px", maxWidth: "40px" }} />
    //                 <Heading
    //                     fontSize="28px"
    //                     fontWeight="600"
    //                     fontFamily="Assistant"
    //                     lineHeight="52.32px"
    //                 >
    //                     Add Dealerships Details
    //                 </Heading>
    //                 <Text>
    //                     Choose your Dealerships Type
    //                 </Text>
    //             </Flex>
    //         </ModalHeader>

    //         <ModalBody>

    //         {validationError && (
    //                         <Text textAlign="center" color="red">{validationError}</Text>
    //                 )}
    //             <div className="steps-containers" >

    //                 {equipmentStatusType?.length > 0 &&
    //                     equipmentStatusType.map((equipment) => (
    //                         <div className='input-section-container' style={{ margin: "1em" }} key={uuid()}>
    //                             <SelectCardWrapper
    //                                 height="80px"
    //                                 width="370px"
    //                                 value={equipment?.EquipmentStatusId ? equipment?.EquipmentStatusId : ""}
    //                                 icon={imageSelector(equipment?.EquipmentStatusFileRef)}
    //                                 isSelected={selectedEquipmentStatusId === equipment?.EquipmentStatusId}
    //                                 onClick={() => {
    //                                     setSelectedEquipmentStatusId(equipment?.EquipmentStatusId)
    //                                     setValidationError(null)
    //                                 }}
    //                                 display="flex"
    //                                 alignItems="center"
    //                                 justifyContent="center"
    //                                 isActiveIcon={true}
    //                             >

    //                                 <Text
    //                                     fontSize={{ base: "lg", md: "xl" }}
    //                                     fontWeight="semibold"
    //                                     textAlign="center"
    //                                 >
    //                                     {equipment?.EquipmentStatusValue}
    //                                 </Text>
    //                             </SelectCardWrapper>
    //                         </div>
    //                     ))
    //                 }
    //             </div>
    //         </ModalBody>
    //         <ModalFooter alignItems={"center"} justifyContent={"center"} borderRadius={"0px 0px 12px 12px"} boxShadow={"0px -10px 15px 0px rgba(44, 44, 44, 0.16)"}>
    //         <Flex alignItems={"center"} justifyContent={"center"}  >
    //                 <Button backgroundColor={"#F79C00"} color={"white"} width={"128px"} height={"45px"} onClick={() => handleSubmitClick()} _hover={{backgroundColor:"#F79C00"}}>
    //                     Next
    //                 </Button>
    //             </Flex>
    //         </ModalFooter>
    //     </ModalContent>
    // </Modal>

    <CustomGeneralModal
      isOpen={postDealershipData.isAddDealershipModalOpen}
      hasCloseButton={true}
      onCloseButtonClick={() => {
        dispatch(closeAddDealershipModal());
      }}
      heading={"Virtual Dealership"}
      subHeading={<>Choose your Dealership Type <p style={{color:"red",display:"inline-block"}}>*</p></>}
      primaryActionText={"Next"}
      footerShadow={true}
      primaryAction={() => handleSubmitClick()}
      iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/connectIcon.svg`}
    >
      {validationError && (
        <Text textAlign="center" color="red">
          {validationError}
        </Text>
      )}
      <Flex direction={"column"} maxW={"338px"} w={"338px"} margin={"0 35px"} marginBottom={"10px"}>
        {equipmentStatusType?.length > 0 &&
          equipmentStatusType.map((equipment) => (
            <Flex key={uuid()}>
              <SelectCardWrapper
                height="70px"
                width="100%"
                value={
                  equipment?.EquipmentStatusId
                    ? equipment?.EquipmentStatusId
                    : ""
                }
                icon={imageSelector(equipment?.EquipmentStatusFileRef)}
                isSelected={
                  selectedEquipmentStatusId === equipment?.EquipmentStatusId
                }
                onClick={() => {
                  setSelectedEquipmentStatusId(equipment?.EquipmentStatusId);
                  setValidationError(null);
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                isActiveIcon={true}
              >
                <Text
                  fontSize={{ base: "lg", md: "xl" }}
                  fontWeight="semibold"
                  textAlign="center"
                >
                  {equipment?.EquipmentStatusValue}
                </Text>
              </SelectCardWrapper>
            </Flex>
          ))}
      </Flex>
    </CustomGeneralModal>
  );
};
