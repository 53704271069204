import { Text } from "@chakra-ui/react"
import { StyledLink } from "../../CommonComponents/Button/StyledButton"
import "./BlogBanner.css"

export const BlogBanner = ({ headingText = "Heading", highlightedText = "HighlightedText", description = "Description", bannerImage = "",isExpanded
}) => {
  return (
    <>
      <div className='blog-container' style={{cursor:"pointer"}}>

      <div className='blog-body-img' style={{

}}>
  <img src={`${bannerImage}`} alt="Premium heavy construction equipment available at Equip9. Explore our diverse inventory, including new and used machinery, to meet your project requirements. Trust Equip9 for top-notch solutions in the construction industry." />

  <StyledLink className="read-more"  variant="outlined" size="small" style={{marginBottom:"1em"}}>{isExpanded ? "Read Less" : "Read More"}</StyledLink>
</div>

      <div className='blog-body-heding'>
          <div className="blog-body-heding-text">
            <Text fontSize={["1.5em","1.5em","1.5em","1.5em","1.5em","2.25em"]} fontWeight={"700"} textTransform={"uppercase"} color={"var(--primary)"}>{headingText}</Text>
            <Text fontSize={["1.4em","1.4em","1.4em","1.4em","1.4em","2em"]} >{highlightedText}</Text>
          </div>

          <div className="blog-body-heding-description">
            <Text fontSize={["0.75em","0.75em","0.75em","1em","1.2em","1.2em"]}>{description}</Text>
          </div>

        </div>

      </div>
    </>
  )
}