import { createAsyncThunk } from "@reduxjs/toolkit";
import { convertToFormData } from "../../../helpers/dataConversion/convertToFormData";
import { saveDetailsThunk } from "./saveDetailsThunk";
import { registerUser } from "../../../services/registration/registration";


export const registrationThunk = createAsyncThunk("register", async({registrationBody, userDetailsBody}, {dispatch, rejectWithValue}) => {
    
     try{
        const data = await registerUser(convertToFormData(registrationBody));
        if(data.Status === 201){
           const userDetails = {...userDetailsBody,UserCredentialId : data.Data.UserCredentialsId}
            const userDetailsCall = dispatch(saveDetailsThunk(userDetails));
            return userDetailsCall
        }

        else{

           return rejectWithValue(data.Message.SuccessMessage)
        }
        
     }
     catch(error){
             rejectWithValue(error.message)
     }
})

