import { createAsyncThunk } from "@reduxjs/toolkit"
import { getAllUserRolesList } from "../../../services/documentFlow/documentFlow";

export const getAllE9RolesThunk = createAsyncThunk("get/e9-roles", async(_,{rejectWithValue, getState}) => {
   
    try{

    const data = await getAllUserRolesList();

   if(data.Status === 200){
    return data.Data.UserRoleTypeList;
   }
   else{
    rejectWithValue(data.Message.FailMessage)
   }
}
catch(error){
}
}) 