import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMyRequirementList } from "../../../services/industryRequirement/industryRequirement";


export const myRequirementThunk = createAsyncThunk('myRequirement',async()=>{
    try {
    const data = await getMyRequirementList();
        return data ;
    } catch (error) {
        
    }
})