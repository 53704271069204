export const registerNumbersOnly = (event) => {
    const allowedKeys = ["Backspace", "Enter", "Tab", "ArrowRight", "ArrowLeft"];
    const allowedKeysWithControl = ["a", "c", "v", "x", "r"];
    
    
    if(event.ctrlKey && allowedKeysWithControl.includes(event.key.toString().toLowerCase())) return

    if (!/\d/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  export const registerNumbersAndDecimal = (event) => {
    const allowedKeys = ["Backspace", "Enter", "Tab", "ArrowRight", "ArrowLeft"];
    const allowedKeysWithControl = ["a", "c", "v", "x", "r"];
  
    if (event.ctrlKey && allowedKeysWithControl.includes(event.key.toLowerCase())) return;
  
    if (event.key === "." && !event.target.value.includes(".")) return;
  
    if (!/\d/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };