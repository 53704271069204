import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFreeLeadsForIndividual } from "../../../services/freeLeads/freeLeads";

export const getFreeLeadsForIndividualThunk = createAsyncThunk(
  "/free-leads/user",
  async (_, { rejectWithValue }) => {
    try {
      const data = await getFreeLeadsForIndividual();
      // console.log(data)
      return data;
    } catch (error) {
      rejectWithValue(error);
      throw error;
    }
  }
);
