import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import {
  getVerificationDocumentType,
  userUploadIndentityDocument,
} from "../../../ApiEquip9/CommonApi";
import { Flex, FormLabel, useToast } from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import * as Yup from "yup";
import "./PopupCard.css";
import { convertToFormData } from "../../../webapp/helpers/dataConversion/convertToFormData";
import { CustomInputModal } from "../../../webapp/components/CustomInputModal/CustomInputModal";
export const PopupCard = ({ isOpen, onClose, triggerRefresh }) => {
  const userData = useSelector((state) => state.user);
  const [imagePreview, setImagePreview] = useState(null);
  const [fileType, setFileType] = useState("");
  const toast = useToast();
  const queryClient = useQueryClient();

  const initialValues = {
    VerificationDocumentName: "",
    VerificationDocumentNumber: "",
    DocumentFileReference: null,
  };

  const validationSchema = Yup.object().shape({
    VerificationDocumentName: Yup.string().required(
      "Document Type is required"
    ),
    VerificationDocumentNumber: Yup.string()
      .required("Document  is required")
      .min(5, "Document Name cannot be shorter than 5 characters")
      .max(18, "Document Name cannot be longer than 12 characters")
      .matches(/^[a-zA-Z0-9 ]+$/, "Special characters not allowed"),
    DocumentFileReference: Yup.mixed().required("Document Photo is required"),
  });

  const onClosePopup = () => {
    setImagePreview(null);
    onClose();
  };

  const handleSubmit = async (values, { resetForm }) => {
    const Payload = {
      VerificationDocumentNumber: values.VerificationDocumentNumber,
      DocumentFileReference: values.DocumentFileReference,
      VerificationDocumentName: values.VerificationDocumentName,
    };

    try {
      const response = await userUploadIndentityDocument(
        convertToFormData(Payload)
      );
      if (response.data.Status === 201) {
        toast({
          title: "",
          description: `${response?.data?.Message?.SuccessMessage}`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        queryClient.invalidateQueries("getUploadedVerification");
        triggerRefresh();
        onClose();
        resetForm();
        setImagePreview(null);
      } else {
        toast({
          title: "Error while uploading",
          description: `${response?.data?.Message?.FailMessage}`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
      return response;
    } catch (error) {
      toast({
        title: "Error while uploading",
        description: `${error?.data?.Message?.FailMessage}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return null;
    }
  };

  const { data: GetVerficationDicType } = useQuery(
    "getVerificationDocument",
    () => getVerificationDocumentType(userData.token)
  );
  const GetDocList = GetVerficationDicType?.data?.Data;

  return (
    // isOpen && (
    //   <div className='popup-card-over'>
    //     <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
    //       {({ isSubmitting, setFieldValue }) => (
    //         <Form className='popup-cardd'>
    //           <button className='popup-carddd-close' onClick={onClosePopup}>
    //             <ReactSVG src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Cross.svg`}   />
    //           </button>
    //           <div className='licenseDetails'>
    //           <ReactSVG src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/LiceseDetails.svg`}   />
    //           </div>
    //           <h1>Add Verification Document</h1>
    //           <div >
    //             <label htmlFor="selectField">Verification Document Type<span className='asterisk'  >*</span></label>
    //             <Field as="select" name="VerificationDocumentName">
    //               <option value="">Select an option</option>
    //               {GetDocList?.map((item, index) => (
    //                 <option key={index.id} value={item.VerificationDocumentName}>
    //                   {item.VerificationDocumentName}
    //                 </option>
    //               ))}
    //             </Field>

    //           </div>
    //           <ErrorMessage name="VerificationDocumentName" component="div" className="error-message" />
    //           <div>
    //             <label htmlFor="inputField" >Document Number <span className='asterisk'  >*</span></label>
    //             <Field type="text" name="VerificationDocumentNumber" className="vertifictionDocInput"  placeholder='  Ex- CET0001' />
    //             <ErrorMessage name="VerificationDocumentNumber" component="div" className="error-message" />
    //           </div>
    //           <label htmlFor="inputField" >Upload Documents<span className='asterisk'  >*</span></label>
    //       <div className="file-input-container-pop-card">
    //             <label htmlFor="custom-file-input" className="custom-file-button">
    //               Upload
    //             </label>
    //             <input
    //               className="custom-file-input"
    //               id="custom-file-input"
    //               type="file"
    //               accept="image/jpeg, image/png, application/pdf"
    //               onChange={(event) => {
    //                 const file = event.currentTarget.files[0];
    //                 setFileType(file.type);  // Set the file type here
    //                 setFieldValue('DocumentFileReference', file);
    //                 const reader = new FileReader();
    //                 reader.onloadend = () => {
    //                   setImagePreview(reader.result);
    //                 };
    //                 if (file) {
    //                   reader.readAsDataURL(file);
    //                 }
    //                 toast({
    //                   title: "File Uploaded successfully",
    //                   description: "You have selected a file for upload.",
    //                   status: "success",
    //                   duration: 5000,
    //                   isClosable: true,
    //                   position: "top-right"
    //                 });
    //               }}
    //             />

    //           </div>
    //           <div className="preview-container">
    //             {imagePreview && fileType === "application/pdf" ? (
    //               <embed src={imagePreview} type="application/pdf" width="100%" height="200px" />
    //             ) : (
    //               <img src={imagePreview}  placeholder='Image Preview'  style={{ width: '100%', height: '20vh',display:'flex',alignItems:'center',justifyContent:'center',borderStyle:"dashed",borderWidth:"2px",borderColor:"#A0AEC0",background:"#ccc" }} />
    //             )}

    //           </div>
    //            <br/>
    //           <button type="submit" disabled={isSubmitting}>
    //             Submit
    //           </button>

    //         </Form>
    //       )}
    //     </Formik>
    //   </div>
    // )
    <CustomInputModal
      isOpen={isOpen}
      hasCloseButton={true}
      onCloseButtonClick={onClosePopup}
      icon={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/LiceseDetails.svg`}
      heading={"Add Verification Document"}
      primaryActionText={"Submit"}
      w={""}
    >
      <Flex justifyContent={"center"} alignItems={"center"}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form className="popup-cardd" id="inputForm">
              <div>
                <FormLabel
                  htmlFor="selectField"
                  fontSize={"14px"}
                  fontWeight={"600"}
                >
                  Verification Document Type<span className="asterisk">*</span>
                </FormLabel>
                <Field
                  as="select"
                  name="VerificationDocumentName"
                  fontWeight={"600"}
                  fontSize={"16px"}
                >
                  <option value="" w={"350px"} h={"44px"} maxW={"350px"}>
                    Select an option
                  </option>
                  {GetDocList?.map((item, index) => (
                    <option
                      key={index.id}
                      value={item.VerificationDocumentName}
                      w={"350px"}
                      h={"44px"}
                      maxW={"350px"}
                    >
                      {item.VerificationDocumentName}
                    </option>
                  ))}
                </Field>
              </div>
              <ErrorMessage
                name="VerificationDocumentName"
                component="div"
                className="error-message"
              />
              <div>
                <FormLabel
                  htmlFor="inputField"
                  fontSize={"14px"}
                  fontWeight={"600"}
                >
                  Document Number <span className="asterisk">*</span>
                </FormLabel>
                <Field
                  fontWeight={"600"}
                  fontSize={"16px"}
                  type="text"
                  name="VerificationDocumentNumber"
                  className="vertifictionDocInput"
                  placeholder="  Ex- CET0001"
                />
                <ErrorMessage
                  name="VerificationDocumentNumber"
                  component="div"
                  className="error-message"
                />
              </div>

              <div>
                <FormLabel
                  fontSize={"14px"}
                  fontWeight={"600"}
                  htmlFor="inputField"
                >
                  Upload Documents<span className="asterisk">*</span>
                </FormLabel>
                <div className="file-input-container-pop-card">
                  <FormLabel
                    fontSize={"14px"}
                    fontWeight={"600"}
                    htmlFor="custom-file-input"
                    className="custom-file-button"
                  >
                    Upload
                  </FormLabel>
                  <input
                    className="custom-file-input"
                    id="custom-file-input"
                    type="file"
                    accept="image/jpeg, image/png, application/pdf"
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      setFileType(file.type);
                      setFieldValue("DocumentFileReference", file);
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        setImagePreview(reader.result);
                      };
                      if (file) {
                        reader.readAsDataURL(file);
                      }
                      toast({
                        title: "File Uploaded successfully",
                        description: "You have selected a file for upload.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                        position: "top-right",
                      });
                    }}
                  />
                </div>
              </div>
              <div className="preview-container">
                {imagePreview && fileType === "application/pdf" ? (
                  <embed
                    src={imagePreview}
                    type="application/pdf"
                    width="100%"
                    height="200px"
                  />
                ) : (
                  <img
                    src={imagePreview}
                    placeholder="Image Preview"
                    style={{
                      width: "100%",
                      height: "20vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderStyle: "dashed",
                      borderWidth: "2px",
                      borderColor: "#A0AEC0",
                      background: "#ccc",
                    }}
                  />
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Flex>
    </CustomInputModal>
  );
};
