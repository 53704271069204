import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetUserDealershipList } from "../../../services/dealershipListing/dealershipListing";



export const dealershipListingInitialThunk = createAsyncThunk("dealershipListingInitial/getInitialCards",async(_,{getState}) =>{

    try {
        const data = await GetUserDealershipList(12,0);
        if(data?.Status === 200){
            return data?.Data?.UserDealershipList;
        }
        return null ;
    } catch (error) {
        
    }
})