import { Button } from "@chakra-ui/button";
import { NewSideMenuLayout } from "../../../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { EquipmentDetailsForm } from "../../components/EquipmentDetailsForm/EquipmentDetailsForm";
import { RouteStepper } from "../../../../components/Stepper/Stepper";
import { Box, Flex } from "@chakra-ui/layout";
import { QuickMaintenanceForm } from "../../components/QuickMaintenanceForm/QuickMaintenanceForm";
import { FullTimeMaintenanceForm } from "../../components/FullTimeMaintenanceForm/FullTimeMaintenanceForm";
import { useDispatch, useSelector } from "react-redux";
import { decreaseStepperIndex } from "../../slice/maintenanceJobPostSlice";
import { Helmet } from "react-helmet";
import { V5FormFooterLayout } from "../../../../layout/V5FormFooterLayout/V5FormFooterLayout";

export const MaintenanceJobPost = () => {
  const { stepperRoute, stepperIndex, jobType,isLoading } = useSelector(
    (state) => state.maintenanceSearch
  );
  const dispatch = useDispatch();

  const handleFormRender = (stepperIndex, jobType) => {
    // switch(stepperIndex, maintenanceType){
    //   case
    // }
    if (stepperIndex === 0) {
      return <EquipmentDetailsForm />;
    } else if (stepperIndex === 1 && jobType.value === "Quick Maintenance") {
      return <QuickMaintenanceForm />;
    } else if (stepperIndex === 1 && jobType.value === "Full Time") {
      return <FullTimeMaintenanceForm />;
    } else {
      return <p>Some error happened! Please try Later</p>;
    }
  };

  const formIdHandler = (stepperIndex, jobType) => {
    if (stepperIndex === 0) {
      return "equipment-details-form";
    } else if (stepperIndex === 1 && jobType.value === "Quick Maintenance") {
      return "quick-maintenance-form";
    } else if (stepperIndex === 1 && jobType.value === "Full Time") {
      return "full-time-maintenance-form";
    } else {
      return "";
    }
  };
  return (
    //     <NewSideMenuLayout justifyContent="space-between">

    // <Helmet>
    //       <link
    //         rel="Business Owner- Maintenance Partner Page"
    //         href="equip9.com/e9-services/maintenance-search/job-post"
    //       />

    //       <meta name="keywords" content="heavy equipment repairs"/>
    //       <title>Optimize Business: Expert Heavy Equipment Repairs</title>
    //       <meta name="description" content="Find reliable maintenance partners for heavy equipment
    //       repairs to ensure smooth operations and business growth."/>
    //       <h1>Expert Heavy Equipment Repairs: Your Key to Business Continuity and Growth</h1>
    //       <h2>Why Choose Us for Your Heavy Equipment Repair Needs?</h2>
    //       <h3>Find Your Reliable Maintenance Partner for Heavy Equipment Repairs</h3>
    //       </Helmet>

    //       <div
    //         style={{
    //           margin: "0px 52px",
    //           backgroundColor: "#92929204",
    //           paddingTop: "1em",
    //         }}
    //       >
    //         <div style={{ marginBottom: "32px" }}>
    //           <h1 style={{ fontSize: "32px", fontWeight: 700 }}>
    //             Find Maintenance Partner
    //           </h1>
    //         </div>
    //         <Flex flexDirection="column" gap="1em" height="100%">
    //           <Box h="54px" dropShadow="0px 10px 30px #2C2C2C14" bgColor="#FFFFFF">
    //             <RouteStepper
    //               array={stepperRoute}
    //               activeIndex={stepperIndex}
    //               padding="1em"
    //             />
    //           </Box>
    //           <Box
    //             maxH="347px"
    //             h="100%"
    //             overflowY="scroll"
    //             padding="1em 1.5em"
    //           >
    //             {handleFormRender(stepperIndex, jobType)}
    //           </Box>
    //         </Flex>
    //       </div>

    //       <div
    //         style={{
    //           width: "100%",
    //           backgroundColor: "white",
    //           display: "grid",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           maxHeight: "78px",
    //           gridTemplateColumns: stepperIndex !== 0 ? "1fr 2fr" : ""
    //         }}
    //       >
    //         {
    //           stepperIndex !== 0 && <Button border="none" background="none" boxShadow="none" dropShadow="none" _hover={{bacckground: "none"}}  maxW="max-content" onClick={() => {dispatch(decreaseStepperIndex())}}> Go Back</Button>
    //         }

    //         <Button
    //           type="submit"
    //           variant="gradient"
    //           w="347px"
    //             form={formIdHandler(stepperIndex, jobType)}
    //         >
    //           Next
    //         </Button>
    //       </div>
    //     </NewSideMenuLayout>
    <V5FormFooterLayout
    title="Find Maintenance Partner"
      stepperArray={stepperRoute}
      stepperIndex={stepperIndex}
      formId={formIdHandler(stepperIndex, jobType)}
      primaryButtonText={
        stepperIndex < stepperRoute.length - 1 ? "Next" : "Finish"
      }
      secondaryButtonText={stepperIndex > 0 ? "Go Back" : ""}
      secondaryButtonClickAction={() => {
        dispatch(decreaseStepperIndex());
      }}
      isLoading={isLoading}
    >
      {handleFormRender(stepperIndex, jobType)}
    </V5FormFooterLayout>
  );
};
