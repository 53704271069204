import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { convertFromBase64 } from "../../../../../utils/formatConverter/convertToBase64";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import defaultIcon from "../../../../../assets/webapp/marketplace/defaultAssetImage.png";
import { NewSideMenuLayout } from "../../../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { Flex, Box, Divider, Text, Tooltip } from "@chakra-ui/react";
import { DetailsCard } from "../../../../components/DetailsCard/DetailsCard";
import {
  getMaterialAdditionalDetails,
  getMaterialBasicDetails,
} from "../../../../services/materials/materials";
import { subString } from "../../../../helpers/dataConversion/substring";

export const MaterialDetailPage = () => {
  const {state}=useLocation();
  const material=state?.material;
  const { materialId } = useParams();
  const [materialDetails, setMaterialDetails] = useState(null);
  const [materialBasicDetails, setMaterialBasicDeatails] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const data = await getMaterialBasicDetails(
          convertFromBase64(materialId)
        );
        setMaterialBasicDeatails(data?.Data?.MaterialData[0]);
      } catch (error) {
        console.error("Error while fetching material details", error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const data = await getMaterialAdditionalDetails(
          convertFromBase64(materialId)
        );

        setMaterialDetails(data?.Data?.MaterialData[0]);
        setImageList(JSON.parse(data?.Data?.MaterialData[0].ImageList));
        setVideoList(JSON.parse(data?.Data?.MaterialData[0].VideoList));
      } catch (error) {
        console.error("Error while fetching material details", error);
      }
    })();
  }, []);

  return (
    <>
      <NewSideMenuLayout>
        <div style={{ margin: "0 52px" }}>
          <Box maxH="610px" h="610px" overflowY="scroll" padding="1em 1.5em">
            <div
              style={{
                width: "100%",
                height: "200px",
                display: "flex",
                overflow: "hidden",
                borderRadius: "10px",
                background: "#FFF",
                alignItems: "center",
                marginBottom: "1em",
                boxShadow: "0px 0px 4px 0px #00000040",
              }}
            >
              <div
                style={{
                  maxWidth: "400px",
                  width: "400px",
                  height: "220px",
                  border: "1px solid #EEEEEE",
                }}
              >
                <Swiper
                  pagination={{ clickable: true }}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                  style={{ height: "100%", width: "100%" }}
                >
                  {imageList && imageList.length > 0 ? (
                    imageList.map((imageObj, index) => (
                      <div key={index}>
                        {imageObj.ImageFileRef &&
                        imageObj.ImageId &&
                        imageObj.ImageTypeId ? (
                          <SwiperSlide>
                            <img
                              src={`${process.env.REACT_APP_CDN_PVT_URL}${imageObj.ImageFileRef}`}
                              alt={`Image ${index}`}
                            />
                          </SwiperSlide>
                        ) : null}
                      </div>
                    ))
                  ) : (
                    <SwiperSlide>
                      <img src={defaultIcon} alt={`default`} />
                    </SwiperSlide>
                  )}

                  {videoList && videoList.length > 0
                    ? videoList.map((videoObj, index) => (
                        <div key={index}>
                          {videoObj.VideoFileRef && (
                            <SwiperSlide>
                              <video controls preload="none" width="100%">
                                <source
                                  src={`${process.env.REACT_APP_CDN_PVT_URL}${videoObj.VideoFileRef}`}
                                />
                              </video>
                            </SwiperSlide>
                          )}
                        </div>
                      ))
                    : null}
                </Swiper>
              </div>

              <div
                style={{
                  display: "flex",
                  padding: "30px 60px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.3em",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <div>
                      <div style={{ display: "flex" }}>
                        <Tooltip label={materialBasicDetails?.ProductName?.length>50 ? materialBasicDetails?.ProductName : null }>
                        <p style={{ fontSize: "22px", fontWeight: "700" }}>
                          {subString(50,materialBasicDetails?.ProductName)}
                        </p>
                        </Tooltip>
                      </div>
                      <p style={{ fontWeight: "700", fontSize: "22px" }}>
                        <span>
                          {`₹${materialBasicDetails?.MinPriceRange}`}/-
                        </span>{" "}
                        to{" "}
                        <span>
                          {`₹${materialBasicDetails?.MaxPriceRange}`}/-
                        </span>{" "}
                      </p>
                    </div>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "700",
                        color: "#002961",
                      }}
                    >
                       {subString(
                          50,
                          ` PNo-${[
                            material?.ProductNumber ? material.ProductNumber : "NA",
                            material?.EquipmentTypeValue ?material.EquipmentTypeValue :"NA",
                            material?.OEMName? material.OEMName:"NA",
                            material?.ModelName? material.ModelName:"NA",
                          ].join(" • ")}`
                        )}
                    </p>

                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "700",
                        color: "#333333",
                      }}
                    >
                      {subString(
                        50,
                        ` ${
                          materialBasicDetails?.Location
                            ? materialBasicDetails?.Location
                            : "NA"
                        }`
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {materialDetails ? (
              <>
                <Box margin="0 auto">
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Part Details
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box>
                    <Flex
                      gap="2em"
                      overflowX="scroll"
                      padding="1em 0"
                      paddingLeft={"1em"}
                    >
                      <DetailsCard
                        heading={`₹${materialBasicDetails?.MinPriceRange}/- to ₹${materialBasicDetails?.MaxPriceRange}/-`}
                        description={"Price Range"}
                      />
                      <DetailsCard
                        heading={`${
                          materialDetails?.Height
                            ? materialDetails.Height
                            : "NA"
                        }${
                          materialDetails?.HeigthtUnit
                            ? materialDetails.HeigthtUnit
                            : ""
                        }`}
                        description={"Height"}
                      />
                      <DetailsCard
                        heading={`${
                          materialDetails?.Radius
                            ? materialDetails.Radius
                            : "NA"
                        }${
                          materialDetails?.RadiusUnit
                            ? materialDetails.RadiusUnit
                            : ""
                        }`}
                        description={"Radius"}
                      />
                      <DetailsCard
                        heading={`${
                          materialDetails?.Weight
                            ? materialDetails.Weight
                            : "NA"
                        }${
                          materialDetails?.WeightUnit
                            ? materialDetails.WeightUnit
                            : ""
                        }`}
                        description={"Weight"}
                      />
                      <DetailsCard
                        heading={`${
                          materialDetails?.Width ? materialDetails.Width : "NA"
                        }${
                          materialDetails?.WidthUnit
                            ? materialDetails.WidthUnit
                            : ""
                        }`}
                        description={"Width"}
                      />

                      <DetailsCard
                        heading={`${
                          materialDetails?.SLength
                            ? materialDetails.SLength
                            : "NA"
                        }${
                          materialDetails?.LengthUnit
                            ? materialDetails.LengthUnit
                            : ""
                        }`}
                        description={"Length"}
                      />
                    </Flex>
                  </Box>
                </Box>

               {materialDetails?.AdditionalDetails && (<Box margin="0 auto">
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Additional Details
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box>
                    <Flex
                      gap="2em"
                      overflowX="scroll"
                      padding="1em 0"
                      paddingLeft={"1em"}
                      visibility={
                        materialDetails?.AdditionalDetails
                          ? "visible"
                          : "hidden"
                      }
                    >
                      <DetailsCard
                        heading={" "}
                        description={
                          materialDetails?.AdditionalDetails
                            ? materialDetails.AdditionalDetails
                            : ""
                        }
                      />
                    </Flex>
                  </Box>
                </Box>)}
              </>
            ) : (
              <p>Material data not found</p>
            )}
          </Box>
        </div>
      </NewSideMenuLayout>
    </>
  );
};
