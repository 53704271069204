import { extendTheme } from "@chakra-ui/react";
import { inputTheme } from "./input/input";
import { stepperTheme } from "./stepper";
import { selectTheme } from "./select/select";
import { gradientButtonTheme } from "./button/gradientButtonTheme";
import { breakpoints } from "./themeConstants/themeConstants";

export const chTheme = extendTheme({
  breakpoints : breakpoints,
  "html, body": {
    margin: 0,
    padding: 0,
    boxSizing: "border-box",
  },

  colors: {
    primary: {
      50: "#ffda99",
      100: "#ffb433",
      200: "#f79c00",
      300: "#b87400",
      400: "#7a4e00",
    },
    secondary: {
      50: "#66a7ff",
      100: "#3389ff",
      200: "#0068f7",
      300: "#004bb2",
      400: "#002961",
    },
  },
  fonts: {
    heading: "Assistant, san-serif",
    body: "Assistant, san-serif",
  },
  components: {
    FormErrorMessage: {
      baseStyle: {
        color: "red",
        fontSize: "14px",
        marginTop: "0.5em",
        fontWeight: "500",
        display: "none",
      },
    },
    FormControl: {
      baseStyle: {
        display: "flex",
        flexDirection: "column",
        maxWidth: ["373px", "374px", "325px", "374px"],
        width: "100%",
      },
    },
    FormLabel: {
      baseStyle: {
        color: "#2C2C2C",
        fontSize: "18px",
        marginBottom: "0.5em",
        fontWeight: "700",
      },
    },
    
    Input : inputTheme,
    Select: selectTheme,
    
    Stepper : stepperTheme,

    Button: {
      baseStyle: {
        boxShadow: "0px 4px 4px 0px #2C2C2C0A",
        transition: "box-shadow 0.2s",
      },

      variants: {
        gradient: gradientButtonTheme
      },
    },
    Progress : {
      baseStyle: {
        filledTrack: {
          bg: "linear-gradient(to right, #f67c00, #FED401)"
        }
      }, 
      variants : {
        gradient : {
          filledTrack: {
            bg: "linear-gradient(to right, #f67c00, #FED401)"
          }
        }, 
        green : {
          filledTrack: {
            bg: "green"
          }
        }
      }
    }, 
    Switch : {
      baseStyle : {
        thumb : {
         bg : "#FEF8EC"
        },
        track : {
          _checked : {
            bg: "#57BB35"
          }
        }
      }
    }
  },
});
