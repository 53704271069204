import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMyPlayedQuizzes } from "../../../services/quiz/quiz";

export const myQuizListLoadMoreThunk = createAsyncThunk(
  "myQuiz/getLoadMoreCards",
  async (_, {getState}) => {
    const {cardCount,paginationCount} = getState().quiz

    try {
      const data = await getMyPlayedQuizzes(cardCount,(paginationCount * cardCount));
      return data;
    } catch (error) {
      throw error;
    }
  }
);