import { Carousel } from "react-responsive-carousel"
import {v4 as uuid} from "uuid"
import placeholderImage from "../../../assets/logo/equip9_logo_dark.png"
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './ImageCarousel.css'

export const ImageCarousel = ({slides, imageHeight="768px", carouselHeight="100%", carouselWidth="100%"}) => {

  const handleImageClick = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };
return(
   
    <Carousel
    infiniteLoop
    showIndicators={false}
    showArrows={false}
    autoPlay={true}
    showStatus={false}
    showThumbs={false}
    transitionTime={2000} 

    >
      {slides.map((slide) => {
        return (
          <div key={uuid()} style={{cursor: "pointer",width:'100%',height:'100%',objectFit:'cover',display:"flex",alignItems:"center",justifyContent:"center"}} onClick={() => handleImageClick(slide.link ? `${slide.link}` : "https://play.google.com/store/apps/details?id=com.equip9&hl=en_IN&gl=US")}>         
             <img
            src={slide.image}
            width="auto"
            height="100%"
            objectFit="cover"
            objectPosition="center"
            onError={(e) => {
            e.target.src = placeholderImage
            }}
            alt="image"
            style={{ maxWidth:'100%',maxHeight:'100%'}}
          />
          </div>

        );
      })}
    </Carousel>
    
  
  );
};
    
 