import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_CONSTANTS } from "../../../../constants/apiConstants";
import {fetchHandler} from "../../../../index";

export const getE9ServiceCategoryThunk = createAsyncThunk("/e9-services/getE9Services" , async(_, {getState}) => {

    try{
        const {data}= await fetchHandler(API_CONSTANTS.GET_E9_SERVICE_CATEGORY_DETAILS);
        if(data.Status === 200){
            let requiredData = [];
            for (let key in data.Data) {
              requiredData.push({ name: key, data: data.Data[key] });
            }
            return requiredData
          }
          else {
            return []
          }
        }
    
    catch(error){
    }
    
})