import { createSlice } from "@reduxjs/toolkit";
import { setSessionStorageItems } from "../../../helpers/sessionStorage/sessionStorage";
import { equipmentListingInitialState } from "../initialState/equipmentListingInitialState";
import { getEditAssetDetailsThunk } from "../thunks/getEditAssetDetailsThunk";

export const equipmentListingSlice = createSlice({
  name: "equipmentListing",
  initialState: equipmentListingInitialState,
  reducers: {
    setEquipmentNickname: (state, { payload }) => {
      state.equipmentNickname = payload;
      setSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_NICKNAME", payload);
    },
    setEquipmentType: (state, { payload }) => {
      state.equipmentType = payload;
      setSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_TYPE", payload);
    },
    setEquipmentMake: (state, { payload }) => {
      state.equipmentMake = payload;
      setSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_MAKE", payload);
    },
    setOtherMake: (state, { payload }) => {
      state.otherMake = payload;
      setSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_OTHER_MAKE", payload);
    },
    setEquipmentModel: (state, { payload }) => {
      state.equipmentModel = payload;
      setSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_MODEL", payload);
    },
    setOtherModel: (state, { payload }) => {
      state.otherModel = payload;
      setSessionStorageItems(
        "EQUIPMENT_LISTING_EQUIPMENT_OTHER_MODEL",
        payload
      );
    },
    setManufacturingYear: (state, { payload }) => {
      state.manufacturingYear = payload;
      setSessionStorageItems(
        "EQUIPMENT_LISTING_EQUIPMENT_MANUFACTURING_YEAR",
        payload
      );
    },
    setMeterReading: (state, { payload }) => {
      state.meterReading = payload;
      setSessionStorageItems(
        "EQUIPMENT_LISTING_EQUIPMENT_METER_READING",
        payload
      );
    },
    setEquipmentLocation: (state, { payload }) => {
      state.equipmentLocation = payload;
      setSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_LOCATION", payload);
    },
    setCountry: (state, { payload }) => {
      state.country = payload;
      setSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_COUNTRY", payload);
    },
    setState: (state, { payload }) => {
      state.state = payload;
      setSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_STATE", payload);
    },
    setCity: (state, { payload }) => {
      state.city = payload;
      setSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_CITY", payload);
    },
    setPincode: (state, { payload }) => {
      state.pincode = payload;
      setSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_PINCODE", payload);
    },
    setIsInsuranceActive: (state, { payload }) => {
      state.isInsuranceActive = payload;
      setSessionStorageItems(
        "EQUIPMENT_LISTING_EQUIPMENT_INSURANCE_ACTIVE",
        payload
      );
    },

    setInsuranceActiveDate: (state, { payload }) => {
      state.insuranceActiveDate = payload;
      setSessionStorageItems(
        "EQUIPMENT_LISTING_EQUIPMENT_INSURANCE_DATE",
        payload
      );
    },
    setEngineMake: (state, { payload }) => {
      state.engineMake = payload;
      setSessionStorageItems(
        "EQUIPMENT_LISTING_EQUIPMENT_ENGINE_MAKE",
        payload
      );
    },
    setChassisNumber: (state, { payload }) => {
      state.chassisNumber = payload;
      setSessionStorageItems(
        "EQUIPMENT_LISTING_EQUIPMENT_CHASSIS_NUMBER",
        payload
      );
    },
    setFuelType: (state, { payload }) => {
      state.fuelType = payload;
      setSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_FUEL_TYPE", payload);
    },
    setAutoEngineShutdown: (state, { payload }) => {
      state.autoEngineShutdown = payload;
      setSessionStorageItems(
        "EQUIPMENT_LISTING_EQUIPMENT_AUTO_SHUT_DOWN",
        payload
      );
    },
    setCreatedAssetId: (state, { payload }) => {
      state.createdAssetId = payload;
      setSessionStorageItems(
        "EQUIPMENT_LISTING_EQUIPMENT_CREATED_ASSET_ID",
        payload
      );
    },
    // PURCHASING FORM
    setPurchasingYear: (state, { payload }) => {
      state.purchasingYear = payload;
      setSessionStorageItems("EQUIPMENT_LISTING_PURCHASING_YEAR", payload);
    },
    setRegisteredCountry: (state, { payload }) => {
      state.registeredCountry = payload;
      setSessionStorageItems("EQUIPMENT_LISTING_REGISTERED_COUNTRY", payload);
    },
    setRegisteredState: (state, { payload }) => {
      state.registeredState = payload;
      setSessionStorageItems("EQUIPMENT_LISTING_REGISTERED_STATE", payload);
    },
    setOwnershipStatus: (state, { payload }) => {
      state.ownerShipStatus = payload;
      setSessionStorageItems("EQUIPMENT_LISTING_OWNERSHIP_STATUS", payload);
    },

    // STEPPER HANDLING
    increaseStepperIndex: (state) => {
      if (state.stepperRoute.length <= state.stepperIndex) {
        return;
      }
      state.stepperIndex += 1;
      setSessionStorageItems(
        "EQUIPMENT_LISTING_STEPPER_INDEX",
        state.stepperIndex
      );
    },
    decreaseStepperIndex: (state) => {
      if (state.stepperIndex === 0) {
        return;
      }
      if (state.stepperIndex < 0) {
        state.stepperIndex = 0;
      }
      state.stepperIndex -= 1;
      setSessionStorageItems(
        "EQUIPMENT_LISTING_STEPPER_INDEX",
        state.stepperIndex
      );
    },
    setStepperIndex: (state, { payload }) => {
      state.stepperIndex = parseInt(payload);
      setSessionStorageItems("EQUIPMENT_LISTING_STEPPER_INDEX", payload);
    },
    // RESET FORM
    resetEquipmentListingSlice: (state) => {
      sessionStorage.removeItem("EQUIPMENT_LISTING_EQUIPMENT_NICKNAME");
      sessionStorage.removeItem("EQUIPMENT_LISTING_EQUIPMENT_TYPE");
      sessionStorage.removeItem("EQUIPMENT_LISTING_EQUIPMENT_MAKE");
      sessionStorage.removeItem("EQUIPMENT_LISTING_EQUIPMENT_OTHER_MAKE");
      sessionStorage.removeItem("EQUIPMENT_LISTING_EQUIPMENT_MODEL");
      sessionStorage.removeItem("EQUIPMENT_LISTING_EQUIPMENT_OTHER_MODEL");
      sessionStorage.removeItem(
        "EQUIPMENT_LISTING_EQUIPMENT_MANUFACTURING_YEAR"
      );
      sessionStorage.removeItem("EQUIPMENT_LISTING_EQUIPMENT_METER_READING");
      sessionStorage.removeItem("EQUIPMENT_LISTING_EQUIPMENT_LOCATION");
      sessionStorage.removeItem("EQUIPMENT_LISTING_EQUIPMENT_COUNTRY");
      sessionStorage.removeItem("EQUIPMENT_LISTING_EQUIPMENT_STATE");
      sessionStorage.removeItem("EQUIPMENT_LISTING_EQUIPMENT_CITY");
      sessionStorage.removeItem("EQUIPMENT_LISTING_EQUIPMENT_PINCODE");
      sessionStorage.removeItem("EQUIPMENT_LISTING_EQUIPMENT_INSURANCE_ACTIVE");
      sessionStorage.removeItem("EQUIPMENT_LISTING_EQUIPMENT_INSURANCE_DATE");
      sessionStorage.removeItem("EQUIPMENT_LISTING_EQUIPMENT_ENGINE_MAKE");
      sessionStorage.removeItem("EQUIPMENT_LISTING_EQUIPMENT_CHASSIS_NUMBER");
      sessionStorage.removeItem("EQUIPMENT_LISTING_EQUIPMENT_FUEL_TYPE");
      sessionStorage.removeItem("EQUIPMENT_LISTING_EQUIPMENT_AUTO_SHUT_DOWN");
      sessionStorage.removeItem("EQUIPMENT_LISTING_EQUIPMENT_CREATED_ASSET_ID");
      sessionStorage.removeItem("EQUIPMENT_LISTING_ASSET_OTHER_DETAILS");
      sessionStorage.removeItem("EQUIPMENT_LISTING_PURCHASING_YEAR");
      sessionStorage.removeItem("EQUIPMENT_LISTING_PURCHASING_PRICE");
      sessionStorage.removeItem("EQUIPMENT_LISTING_REGISTERED_COUNTRY");
      sessionStorage.removeItem("EQUIPMENT_LISTING_REGISTERED_STATE");
      sessionStorage.removeItem("EQUIPMENT_LISTING_OWNERSHIP_STATUS");
      sessionStorage.removeItem(
        "EQUIPMENT_LISTING_ASSET_PURCHAING_COUNTRY_NAME"
      );
      sessionStorage.removeItem("EQUIPMENT_LISTING_ASSET_OWNERSHIP_NAME");
      sessionStorage.removeItem("EQUIPMENT_LISTING_PURCHASING_YEAR");
      sessionStorage.removeItem("EQUIPMENT_LISTING_ASSET_PURCHAING_STATE_NAME");
      sessionStorage.removeItem("EQUIPMENT_LISTING_STEPPER_INDEX", 0);
      state.equipmentNickname = null;
      state.equipmentType = null;
      state.equipmentMake = null;
      state.otherMake = null;
      state.equipmentModel = null;
      state.otherModel = null;
      state.manufacturingYear = null;
      state.meterReading = null;
      state.equipmentLocation = null;
      state.country = null;
      state.countryName = null;
      state.state = null;
      state.stateName = null
      state.city = null;
      state.cityName = null
      state.pincode = null;
      state.isInsuranceActive = null;
      state.insuranceActiveDate = null;
      state.engineMake = null;
      state.chassisNumber = null;
      state.fuelType = null;
      state.autoEngineShutdown = null;
      state.createdAssetId = null;
      state.stepperIndex = 0;
      state.purchasingYear = null;
      state.purchasingPrice = null;
      state.registeredCountry = null;
      state.registeredState = null;
      state.ownerShipStatus = null;
      state.otherEquipmentDetails = null;
      state.purchasingYear = null;
      state.ownershipName = null;
      state.purchasingCountryName = null;
      state.purchasingStateName = null;
    },
    setIsLoading :(state, { payload })=>{
      state.isLoading=payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEditAssetDetailsThunk.pending, (state) => {})
      .addCase(getEditAssetDetailsThunk.fulfilled, (state, { payload }) => {
        // Nick name
        state.equipmentNickname = payload.AssetDetails.AssetNickName;
        setSessionStorageItems(
          "EQUIPMENT_LISTING_EQUIPMENT_NICKNAME",
          payload.AssetDetails.AssetNickName
        );

        // Equipment Type
        state.equipmentType = payload.AssetDetails.EquipmentTypeId.toString();
        setSessionStorageItems(
          "EQUIPMENT_LISTING_EQUIPMENT_TYPE",
          payload.AssetDetails.EquipmentTypeId
        );
        // Equipment Make
        state.equipmentMake = payload.AssetDetails.EquipmentMakeId.toString();
        setSessionStorageItems(
          "EQUIPMENT_LISTING_EQUIPMENT_MAKE",
          payload.AssetDetails.EquipmentMakeId
        );
        // Other Make
        if (payload.AssetDetails.OtherOemName) {
          state.otherMake = payload.AssetDetails.OtherOemName;
          setSessionStorageItems(
            "EQUIPMENT_LISTING_EQUIPMENT_OTHER_MAKE",
            payload.AssetDetails.OtherOemName
          );
        }

        // Equipment Model
        state.equipmentModel = payload.AssetDetails.EquipmentModelId.toString();
        setSessionStorageItems(
          "EQUIPMENT_LISTING_EQUIPMENT_MODEL",
          payload.AssetDetails.EquipmentModelId
        );

        // Other Model
        if (payload.AssetDetails.OtherModelName) {
          state.otherModel = payload.AssetDetails.OtherModelName;
          setSessionStorageItems(
            "EQUIPMENT_LISTING_EQUIPMENT_OTHER_MODEL",
            payload.AssetDetails.OtherModelName
          );
        }
        // Location
        if (payload.AssetDetails.AssetLocationAddress) {
          state.equipmentLocation = payload.AssetDetails.AssetLocationAddress;
          setSessionStorageItems(
            "EQUIPMENT_LISTING_EQUIPMENT_LOCATION",
            payload.AssetDetails.AssetLocationAddress
          );
        }
        // Country
        state.countryName = payload.AssetDetails.CountryName;
        state.manufacturingYear = payload.AssetDetails.AssetManufacturingYear;
        state.meterReading = payload.AssetDetails.AssetCurrentMeterReading;

        state.stateName = payload.AssetDetails.StateName;
        state.cityName = payload.AssetDetails.CityName;

        setSessionStorageItems(
          "EQUIPMENT_LISTING_EQUIPMENT_MANUFACTURING_YEAR",
          payload.AssetDetails.AssetManufacturingYear
        );
        setSessionStorageItems(
          "EQUIPMENT_LISTING_EQUIPMENT_METER_READING",
          payload.AssetDetails.AssetCurrentMeterReading
        );

        if (payload.AssetDetails.AssetChassisNumber) {
          state.chassisNumber = payload.AssetDetails.AssetChassisNumber;
          setSessionStorageItems(
            "EQUIPMENT_LISTING_EQUIPMENT_CHASSIS_NUMBER",
            payload.AssetDetails.AssetChassisNumber
          );
        }

        if (payload.AssetDetails.AssetLocationZipCode) {
          state.pincode = payload.AssetDetails.AssetLocationZipCode;
          setSessionStorageItems(
            "EQUIPMENT_LISTING_EQUIPMENT_PINCODE",
            payload.AssetDetails.AssetLocationZipCode
          );
        }
        if (typeof payload.AssetDetails.AssetInsuranceTypeId === "number") {
          state.isInsuranceActive = payload.AssetDetails.AssetInsuranceTypeId;
          setSessionStorageItems(
            "EQUIPMENT_LISTING_EQUIPMENT_INSURANCE_ACTIVE",
            payload.AssetDetails.AssetInsuranceTypeId
          );
        }
        if (payload.AssetDetails.AssetInsuranceValidityDate) {
          const requiredDate =
            payload.AssetDetails.AssetInsuranceValidityDate.split("-")
              .reverse()
              .join("-");
          state.insuranceActiveDate = requiredDate;
          setSessionStorageItems(
            "EQUIPMENT_LISTING_EQUIPMENT_INSURANCE_DATE",
            requiredDate
          );
        }
        if (payload.AssetDetails.EquipmentEngineMakeValue) {
          state.engineName = payload.AssetDetails.EquipmentEngineMakeValue;
        }
        if (typeof payload.AssetDetails.AssetFuelTypeId === "number") {
          state.fuelType = payload.AssetDetails.AssetFuelTypeId;
          setSessionStorageItems(
            "EQUIPMENT_LISTING_EQUIPMENT_FUEL_TYPE",
            payload.AssetDetails.AssetFuelTypeId
          );
        }
        if (
          typeof payload.AssetDetails.AssetAutoEngineShutdownId === "number"
        ) {
          state.autoEngineShutdown =
            payload.AssetDetails.AssetAutoEngineShutdownId;
          setSessionStorageItems(
            "EQUIPMENT_LISTING_EQUIPMENT_AUTO_SHUT_DOWN",
            payload.AssetDetails.AssetAutoEngineShutdownId
          );
        }
        if (payload.AssetPurchaseDetails) {
          if (payload.AssetPurchaseDetails.PurchasedYear) {
            state.purchasingYear = payload.AssetPurchaseDetails.PurchasedYear;
            setSessionStorageItems(
              "EQUIPMENT_LISTING_PURCHASING_YEAR",
              payload.AssetPurchaseDetails.PurchasedYear
            );
          }
          if (payload.AssetPurchaseDetails.AssetOwnershipTypeValue) {
            state.ownershipName =
              payload.AssetPurchaseDetails.AssetOwnershipTypeValue;
            setSessionStorageItems(
              "EQUIPMENT_LISTING_ASSET_OWNERSHIP_NAME",
              payload.AssetPurchaseDetails.AssetOwnershipTypeValue
            );
          }
          if (payload.AssetPurchaseDetails.CountryName) {
            state.purchasingCountryName =
              payload.AssetPurchaseDetails.CountryName;
            setSessionStorageItems(
              "EQUIPMENT_LISTING_ASSET_PURCHAING_COUNTRY_NAME",
              payload.AssetPurchaseDetails.CountryName
            );
          }
          if (payload.AssetPurchaseDetails.StateName) {
            state.purchasingStateName = payload.AssetPurchaseDetails.StateName;
            setSessionStorageItems(
              "EQUIPMENT_LISTING_ASSET_PURCHAING_STATE_NAME",
              payload.AssetPurchaseDetails.StateName
            );
          }
        }
        if (payload.AssetOtherDetails) {
          state.otherEquipmentDetails =
            payload.AssetOtherDetails.AssetsOtherPropertyDetails;
          setSessionStorageItems(
            "EQUIPMENT_LISTING_ASSET_OTHER_DETAILS",
            payload.AssetOtherDetails.AssetsOtherPropertyDetails
          );
        }
      });
  },
});

export const {
  setCity,
  setCountry,
  setEquipmentLocation,
  setEquipmentMake,
  setEquipmentModel,
  setEquipmentNickname,
  setEquipmentType,
  setManufacturingYear,
  setMeterReading,
  setOtherMake,
  setOtherModel,
  setPincode,
  setState,
  setAutoEngineShutdown,
  setChassisNumber,
  setEngineMake,
  setFuelType,
  setInsuranceActiveDate,
  setIsInsuranceActive,
  setCreatedAssetId,
  setPurchasingYear,
  setRegisteredCountry,
  setRegisteredState,
  setOwnershipStatus,
  increaseStepperIndex,
  decreaseStepperIndex,
  setStepperIndex,
  resetEquipmentListingSlice,
  setIsLoading
} = equipmentListingSlice.actions;

export default equipmentListingSlice.reducer;
