import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFilteredPartners } from "../../../services/jobsPosted/jobsPosted";

export const getLoadMoreFilteredMaintenanceData = createAsyncThunk("/mpJobs/loadMoreFilteredData", async(_, {getState}) => {
   
    const {cardCount, filterByEquipmentType, filterByCountry, filterByState, filterByCity } = getState().maintenanceJobsNearMe;
    let query = "?FilterType=maintenance job";
    if(filterByEquipmentType){
    query +=  `&FilterByEquipmentType=${filterByEquipmentType}`
    }
    if(filterByCountry){
        query += `&FilterByLocationCountry=${filterByCountry}`
    }
    if(filterByState){
        query += `&FilterByLocationState=${filterByState}`
    }
    if(filterByCity){
        query += `&FilterByLocationCity=${filterByCity}`
    }
   
    try{
    
        const data= await getFilteredPartners(cardCount,0,query);

        if(data.Status === 200 && Object.keys(data.Data).length > 0){

            if(data.Data.TotalRecords > 0){
                return data.Data.GetFilteredMaintenanceJobMarketplace
            }
            else{
                return [];
            }
        }
        else{
            return [];
        }
    }
    catch(error){
    }
})