import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMaterialInitialDeferredData } from "../../services/materials";

export const concreteInitialDeferredThunk = createAsyncThunk("concreteDeferred/getInitialCards", async(id, {getState}) => {
try{
    const data = await getMaterialInitialDeferredData(id);
    if(data){
        return data?.MaterialMarketplaceList
    }

   
}
catch(error){
    // console.log("MATERIALERROR: ",error);

}
})