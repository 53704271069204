import { createSlice } from "@reduxjs/toolkit";
import { getOpDeferredThunk } from "../thunk/getOpDeferredThunk";
import { opInitialState } from "../constants/opInitialState";
import { opLoadMoreDeferred } from "../thunk/opLoadMoreDeferred";

export const opDeferredSlice = createSlice({
    name:"opDeferred",
    initialState: opInitialState,
    reducers:{
        setData: (state,{payload})=>{
            state.data = payload
        }
    },

    extraReducers:(builder) =>{
        builder
        // REM Marketplace
        .addCase(getOpDeferredThunk.pending, (state)=>{
            state.data = null;
            state.initialLoading = true;
            state.paginationLoading = false;
            state.error = false;
            state.errorMessage = false;
            state.paginationCount = 1;
            state.endOfData = false
        })
        .addCase(getOpDeferredThunk.fulfilled,(state,{payload}) =>{
            state.paginationCount = 1;
            state.initialLoading = false;
            state.error = false;
            state.errorMessage = null;
            state.data = payload?.NearMeDetails;
            if(payload?.NearMeDetails?.length < state.cardCount){
              state.endOfData = true
            }
        })

                .addCase(opLoadMoreDeferred.pending, (state)=>{
            state.paginationLoading = true;
            state.error = false ;
            state.errorMessage = null;
         })
         .addCase(opLoadMoreDeferred.fulfilled, (state,{payload})=>{
            state.error = false ;
            state.errorMessage = null ;
            state.paginationLoading = false;
            state.assetImageReference = payload?.AssetImageBaseReference
            if(payload?.NearMeDetails?.length >= state.cardCount){
                state.data = [...state?.data , ...payload?.NearMeDetails];
                state.paginationCount += 1;
            }else{
                state.endOfData = true;
         }
         })
    }
})

export const {
    setData
}= opDeferredSlice.actions;