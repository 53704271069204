import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSentRequirementList } from "../../../../services/industryRequirement/industryRequirement";

export const getSentRequirementCards = createAsyncThunk('/getSentRequirementCards',async ()=>{
    try{
        const data = await getSentRequirementList()
        return data ;
    }catch(error){

    }
}) 