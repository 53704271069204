import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFilteredOwners } from "../../../services/ownerMarketplace/ownerMarketplace";

export const getInitialFilteredDataThunk = createAsyncThunk(
  "owner/initialFilteredData",
  async (_, { getState }) => {
    const selectedCountry = getState().ownersMarketplace.filterByCountry;
    const selectedState = getState().ownersMarketplace.filterByState;
    const selectedCity = getState().ownersMarketplace.filterByCity;
    const selectedBusinessExpertiseTypeValue =
      getState().ownersMarketplace.filterByBusinessExpertiseTypeValue;

    const cardCount = getState().ownersMarketplace.cardCount;

    let query = "";

    if (selectedBusinessExpertiseTypeValue) {
      query += `BusinessExpertiseTypeValue=${selectedBusinessExpertiseTypeValue}`;
    }
    if (selectedCountry) {
      query += `&CountryName=${selectedCountry}`;
    }
    if (selectedState) {
      query += `&StateName=${selectedState}`;
    }
    if (selectedCity) {
      query += `&CityName=${selectedCity}`;
    }

    try {
      const data = await getFilteredOwners(cardCount, 0, query);

      if (data.Status === 200) {
        if (data.Data.FilteredE9OwnerMarketplaceList.length > 0) {
          return data.Data.FilteredE9OwnerMarketplaceList;
        } else {
          return [];
        }
      }
    } catch (error) {}
  }
);
