import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPostedJobsList, getPostedJobsListForCompany } from "../../../services/jobsPosted/jobsPosted";

export const getInitialPostedJobsThunk = createAsyncThunk("/jobsPosted/initialJobs", async(_, {getState}) => {
    const {cardsCount} = getState().jobsPosted;
    const {accountDetails, UserTypeValue} = getState().user;
    try{
      const data = await getPostedJobsList(0, 100)
      return data;
    }
    catch(error){
      return error.message
    }
})