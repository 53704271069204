import React from 'react';
import './ErrorPage.css'
import { useNavigate } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
function NotAuthorized() {
  const navigate =useNavigate()
  return (
    <div className="error-container">
      <div className="error-message">
        <h1 className="error-message-heading">Page Not Accessible!</h1>
        <div>
        <p className="error-message-message">This marketplace isn't accessible with your current role.</p>
        <p className="error-message-message">Please try accessing it with another role.</p>
        </div>
        <Button width="200px" variant="gradient" onClick={()=>navigate('/dashboard')}>Go to Dashboard</Button>
      </div>
      <div className="error-message-img">
        <img
          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/ErrorPages/NotAuthorized.png`}
          alt="Not Authorized"
          className="error-message-img"
        />
      </div>
    </div>
  );
}

export default NotAuthorized;
