import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMyPremium } from "../../../services/premium/premium";


export const getMyPremiumThunk = createAsyncThunk('/getMyPremium' ,async ()=>{
    try{
        const data =await getMyPremium() ;
        return data ;
    }catch (error){
        
    }
})