import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { ReactSVG } from "react-svg";
import { useLocation, useNavigate } from "react-router-dom";
import "./NewDashboardNavbar.css";
import { QrPopUp } from "../../components/ScanQrCodeModal/QrPopUp";
import { openQrModal } from "../../features/dashboard/slice/dashboardSlice";
import { PremiumBanner } from "../../components/PremiumBanner/PremiumBanner";

export const NewDashboardNavBar = () => {
  const loginData = useSelector((state) => state.login);
  const userData = useSelector((state) => state.user);
  const { premiumId, isPremium } = useSelector((state) => state.premium);
  const dispatch = useDispatch();
  const [isAndroid, setIsAndroid] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const toggleCard = (index) => {
    setExpandedIndex(index === expandedIndex ? -1 : index);
  };

  const location = useLocation();
  const navigate = useNavigate();

  const goToDashboard = () => {
    navigate("/dashboard");
  };

  // const dynamicPathPrefix = "/my-business-material-add-material-form-step-one";
  // const isDynamicPath = location.pathname.startsWith(dynamicPathPrefix);

  // const editDynamic='/my-business-Edit-material';
  // const  isEditDynamicPath=location.pathname.startsWith(editDynamic);

  //   const goToMaterialList=()=>{
  //     const gotomaterialCard = "/material/";
  //     const isgotomaterialCard=location.pathname.startsWith(gotomaterialCard);
  //     // console.log("isgotomaterialCard",isgotomaterialCard)
  //     navigate(-1);
  // }

  const renderWelcomeMessage = () => {
    // if (isDynamicPath) {
    //   return (
    //     <div style={{ display: 'flex', flexDirection: 'row', gap: "6px", cursor: "pointer",marginLeft:'-18px'}} onClick={goToMaterialList}>
    //     <ReactSVG className="navbar__arrow-icon"
    //     style={{marginTop:"6px"}}
    //       src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Arrow-Left.svg`}
    //     />
    //     <div style={{marginTop:"0px",color:"var(--Dark-90, #141619)",fontFamily:"Assistant",
    //     fontStyle:"normal",fontWeight:"700",lineHeight:"normal",fontSize:"18px"
    //   }} className="navbar__message-text">MATERIAL</div>
    //   </div>
    //   );
    // }

    //  if(isEditDynamicPath){
    //   return (
    //     <div style={{ display: 'flex', flexDirection: 'row', gap: "6px", cursor: "pointer",marginLeft:'-18px'}} onClick={goToMaterialList}>
    //     <ReactSVG className="navbar__arrow-icon"
    //     style={{marginTop:"6px"}}
    //       src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Arrow-Left.svg`}
    //     />
    //     <div style={{marginTop:"0px",color:"var(--Dark-90, #141619)",fontFamily:"Assistant",
    //     fontStyle:"normal",fontWeight:"700",lineHeight:"normal",fontSize:"18px"
    //   }} className="navbar__message-text">EDIT MATERIAL</div>
    //   </div>
    //   );

    //  }
    // const pathToMessageMapping = {
    //   // "/business-expertise": "E9 Role",
    //   // "/my-profile-details": "Personal Details",
    //   // "/work-expirence": "Company Details",
    //   // "/equipment-details": "Equipment Details",
    //   // "/license-details": "License Details",
    //   // "/verification-detail": "Verification Details ",
    //   // "/operatorpatner-education-details": "Education details",
    //   // "/company-profile": "Subscription Details",
    //   // "/company-member-details": "Company Member Details",
    //   // "/subcription-details": "Subscription Details",
    //   // "/raisesupport": "Customer Support",
    //   // "/my-business":"MY BUSINESS",
    //   // "/materials/spare-parts":"SPARE PART"

    // };

    // if (pathToMessageMapping[location.pathname]) {
    //   return (
    //     <div style={{ display: 'flex', flexDirection: 'row', gap: "6px", cursor: "pointer",marginLeft:'-18px'}} onClick={goToDashboard}>
    //       <ReactSVG className="navbar__arrow-icon"
    //       style={{marginTop:"6px"}}
    //         src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Arrow-Left.svg`}
    //       />
    //       <div style={{marginTop:"0px",color:"var(--Dark-90, #141619)",fontFamily:"Assistant",
    //       fontStyle:"normal",fontWeight:"700",lineHeight:"normal",fontSize:"18px"
    //     }} className="navbar__message-text">{pathToMessageMapping[location.pathname]}</div>
    //     </div>
    //   );
    // }

    return `WELCOME ${
      userData.accountDetails
        ? `${userData.accountDetails?.UserDetails.PersonalDetails.FirstName} ${userData.accountDetails.UserDetails?.PersonalDetails.LastName}`
        : "User"
    } !`;
  };

  // useEffect(() => {
  //   dispatch(userDetailsThunk());
  // }, []);

  return (
    <>
      <QrPopUp />
      <ul className="navbar-container">
        <li className="navbar__heading nav-items">
          <h1 className="navbar__greeting">{`WELCOME ${
            userData.accountDetails
              ? `${userData.accountDetails?.UserDetails.PersonalDetails.FirstName} ${userData.accountDetails.UserDetails?.PersonalDetails.LastName}`
              : "User"
          } !`}</h1>
        </li>

        <li className="navbar__notification">
          {location.pathname.split("/").includes("dashboard") &&
          (userData.BusinessTypeValue === "Business Owner" ||
            userData.BusinessTypeValue === "Equipment Owner") &&
          !isPremium ? (
            <PremiumBanner />
          ) : (
            <></>
          )}
          <ReactSVG
            src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/SOS.svg`}
            alt="img"
            className="navbar__list-icon nav-items"
            onClick={() => {
              dispatch(openQrModal());
            }}
          />
          <ReactSVG
            src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Notification.svg`}
            alt="img"
            className="navbar__list-icon nav-items"
            onClick={() => {
              dispatch(openQrModal());
            }}
          />
          {/* <div className="navbar__user-image nav-items">
        <img
          className="nav-user-Img"
          src={
            userData.accountDetails?.UserDetails?.ProfilePicture?.ProfilePictureFileReference
              ? `${process.env.REACT_APP_CDN_PVT_URL}UserProfilePicture/${userData.accountDetails.UserDetails.ProfilePicture.ProfilePictureFileReference}`
              : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Avatar.png`
          }
          onClick={()=>{
            dispatch(openQrModal());
          }}
        />
      </div> */}
        </li>
      </ul>
    </>
  );
};
