import { API_CONSTANTS } from "../../../constants/apiConstants"
import {fetchHandler} from "../../../index"
import { convertToBase64 } from "../../../utils/formatConverter/convertToBase64";
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData";


export const CreateDealershipCertificateDetails = async (payload) => {
    try {
    
    const {data}= await fetchHandler(API_CONSTANTS.CREATE_DEALERSHIP_CERTIFICATE_DETAILS,{},{},convertToFormData(payload),API_CONSTANTS.HTT_POST)
    return data;
    }
    catch (err) {
     throw err;
    }
  }

  export const createDealershipImageDetails = async (payload) => {
    try {
    const {data} = await fetchHandler(API_CONSTANTS.CREATE_DEALERSHIP_IMMAGE_DETAILS,{},{},convertToFormData(payload),API_CONSTANTS.HTT_POST)
    return data;
    }
    catch (err) {
      throw err
    }
  }

  export const createDealershipVideoDetails = async (payload) => {
    try {
    const {data} = await fetchHandler(API_CONSTANTS.CREATE_DEALERSHIP_VIDEO_DETAILS,{},{},convertToFormData(payload),API_CONSTANTS.HTT_POST)
    return data;
    }
    catch (err) {
      // console.log(err);
    }
  }

  export const GetUserDealershipListCompany = async (limit,offset,taxNumber) => {
    try {
    const {data}=await fetchHandler(`/${API_CONSTANTS.GET_USER_DEALERSHIP_LIST}/${limit}/${offset}`,{
        TaxNumber:convertToBase64(taxNumber)
    },{},{},API_CONSTANTS.HTTP_GET)
    return data;
    } catch (error) {
      // console.log("GetUserDealershipList EROR", error);
    }
  }
  
  export const GetUserDealershipList = async (limit,offset) => {
    try {
    const {data}=await fetchHandler(`/${API_CONSTANTS.GET_USER_DEALERSHIP_LIST}/${limit}/${offset}`)
    return data;
    } catch (error) {
      // console.log("GetUserDealershipList EROR", error);
    }
  }

  export const deleteDealershipDetails = async (guuid) => {
    try {
    
      const {data}= await fetchHandler(`${API_CONSTANTS.DELETE_DEALERSHIP_DETAILS}/${guuid}`,{},{},{},API_CONSTANTS.HTTP_DELETE);
      return data;

    } catch (error) {
      throw error;
    }
  }