import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Like } from "../Like/Like";
import { useSelector } from "react-redux";

const IndustryRequirementCard = ({
  imageData,
  ProfilePhoto,
  CreatedDateTime,
  ExpiredOn,
  UserName,
  BusinessTypeValue,
  Requirement,
  RequestCount,
  RequirementCategoryValue,
  City,
  State,
  isOwnRequirement = false,
  ownerId = false,
  isMyLeads = false,
  connectId,
  onDeleteButtonClick = () => {},
  onReportButtonClick = () => {},
  onConnectButtonClick = () => {},
  onLikeButtonClick = () => {},
  isLiked,
  requestCount = null,
}) => {
  const [imageObject, setImageObject] = useState(null);
  useEffect(() => {
    if (imageData && imageData?.length > 0) {
      setImageObject(JSON.parse(imageData));
    }
  }, [imageData]);

  const { isOpen, onToggle } = useDisclosure();
  const { connectRequirementList } = useSelector(
    (state) => state.industryRequirement
  );
  const [isConnected, setIsConnected] = useState(null);
  const [isAccepted, setIsAccepted] = useState(false);

  const checkConnectList = () => {


    const connect = connectRequirementList?.find(
      (item) => item.RequirementDetailsId === connectId
    )
      ? true
      : false;
        setIsConnected(connect);
        if(!connect){
          setIsAccepted(true)
        }
      
  };

  useEffect(() => {
    checkConnectList();
  }, [connectRequirementList]);

  return (
    <Flex>
      <Box
        width="100%"
        boxShadow="0px 0px 5px 0px #00000040"
        padding="0em 1em"
        margin="0em 1em"
        display="flex"
        flexDirection="column"
        gap="5px"
        borderRadius="4px"
      >
        {/* Header */}
        <Flex direction="column">
          <Flex justifyContent="space-between" width="100%" alignItems="center">
            <Box>
              <Text color="#767676">
                Posted On: {CreatedDateTime ? CreatedDateTime : "N.A"} |{" "}
                <Text as="span">
                  Expires On: {ExpiredOn ? ExpiredOn : "N.A"}
                </Text>
              </Text>
            </Box>
            {isOwnRequirement ? null : ownerId ? null : (
              <Button
                leftIcon={
                  <img
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/report.svg`}
                  />
                }
                color="#D60000"
                fontWeight="700"
                border="none"
                backgroundColor="transparent"
                boxShadow="none"
                onClick={onReportButtonClick}
                _hover={{
                  backgroundColor: "none",
                }}
              >
                Report
              </Button>
            )}
          </Flex>
          <Divider />
        </Flex>

        {/* Industry Body */}
        <Flex gap="1em" padding="0.5em 0.5em">
          <Box
            maxH="100px"
            maxW="150px"
            minH="100px"
            minW="150px"
            borderRadius="4px"
          >
            <img
              style={{ height: "100%", width: "100%", borderRadius: "4px" }}
              src={
                imageObject && imageObject?.length > 0
                  ? `${process.env.REACT_APP_CDN_PVT_URL}${imageObject?.[0]?.ImageFileRef}`
                  : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/DelarshipDefaultImage.png`
              }
            />
          </Box>

          <Box width="100%">
            <Box display="flex" flexDirection="column" gap="1em">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Flex alignItems="center" gap="0.50em">
                  <img
                    style={{
                      maxHeight: "42px",
                      maxWidth: "42px",
                      minHeight: "42px",
                      minWidth: "42px",
                      borderRadius: "50%",
                    }}
                    src={
                      ProfilePhoto
                        ? `${process.env.REACT_APP_CDN_PVT_URL}UserProfilePicture/${ProfilePhoto}`
                        : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Avatar.png`
                    }
                  />

                  <Box>
                    <Text color="#141619" fontWeight="700" fontSize="18px">
                      {UserName ? UserName : "N.A"}
                    </Text>
                    <Text color="#484848" fontSize="16px">
                      {BusinessTypeValue ? BusinessTypeValue : "N.A"} |{" "}
                      <Text as="span" color="#767676" fontSize="12px">
                        {City ? City : "N.A"}, {State ? State : "N.A"}
                      </Text>
                    </Text>
                  </Box>
                </Flex>

                {isOwnRequirement ? (
                  <Button
                    leftIcon={
                      <img
                        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/delete.svg`}
                      />
                    }
                    color="#002961"
                    fontWeight="700"
                    border="none"
                    backgroundColor="transparent"
                    boxShadow="none"
                    _hover={{
                      backgroundColor: "none",
                    }}
                    onClick={onDeleteButtonClick}
                  ></Button>
                ) : (
                  <Flex alignItems="center" direction="column">
                    <Flex gap="1em">
                      <Like isLiked={isLiked} onClick={onLikeButtonClick} />
                      {ownerId ? (
                        <Button
                          color={"#002961"}
                          border={"1px solid #002961"}
                          backgroundColor="transparent"
                          onClick={onConnectButtonClick}
                        >
                          View Leads
                        </Button>
                      ) : isConnected ? (
                        <Text color="#F79C00">Request Sent</Text>
                      ) : (
                        <Button
                          color={"#F79C00"}
                          border={isConnected ? "none" : "1px solid #F79C00"}
                          backgroundColor="transparent"
                          onClick={onConnectButtonClick}
                        >
                          Connect
                        </Button>
                      )}
                    </Flex>
                    <Button
                      leftIcon={
                        <img
                          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/connect.svg`}
                        />
                      }
                      color="#002961"
                      fontWeight="700"
                      border="none"
                      backgroundColor="transparent"
                      boxShadow="none"
                    >
                      {RequestCount} {ownerId ? "Requests" : "Connects"}
                    </Button>
                  </Flex>
                )}
              </Box>

              <Box>
                <Text fontSize="16px" fontWeight="700" color="#002961">
                  {RequirementCategoryValue ? RequirementCategoryValue : "N.A"}
                </Text>
                <Flex align="center" justify="space-between">
                  <Box flex="1" overflow="hidden">
                    <Text
                      color="#767676"
                      fontSize="14px"
                      fontWeight="600"
                      noOfLines={isOpen ? undefined : 1}
                      maxWidth="920px"
                      minW="900px"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace={isOpen ? "normal" : "nowrap"}
                    >
                      {Requirement}
                    </Text>
                  </Box>
                  <Button
                    rightIcon={
                      isOpen ? (
                        <img
                          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/arrowUp.svg`}
                        />
                      ) : (
                        <img
                          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/arrowDown.svg`}
                        />
                      )
                    }
                    color="#002961"
                    border="none"
                    backgroundColor="transparent"
                    boxShadow="none"
                    _hover={{
                      border: "none",
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    }}
                    size="sm"
                    onClick={onToggle}
                  >
                    {isOpen ? "Read Less" : "Read More"}
                  </Button>
                </Flex>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default IndustryRequirementCard;
