import React, { useState, useEffect } from "react";
import "./EquipmentDetailsCard.css";
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Select,
  useToast,
} from "@chakra-ui/react";
// import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field } from "formik";
import { useQuery, useQueryClient } from "react-query";
import { ReactSVG } from "react-svg";
// import { useSelector } from "react-redux";
// import { getVerificationDocumentType, userUploadIndentityDocument } from '../../../ApiEquip9/CommonApi';
import {
  getAlltypeofEquipmentTy,
  getIndustryMaker,
  getAllEquipmentTypeswithEquipId,
} from "../../../../../../../../../ApiEquip9/CommonApi";
// import { useToast } from "@chakra-ui/react";
import { API_CONSTANTS } from "../../../../../../../../../constants/apiConstants";
import {
  MAINTENANCE_CERTIFIED_VALIDATIONS,
  MAINTENANCE_NOT_CERTIFIED_VALIDATIONS,
  OPERATOR_CERTIFIED_VALIDATIONS,
  OPERATOR_NOT_CERTIFIED_VALIDATIONS,
} from "./../../../../../../../../features/documentFlow/screens/uploadEquipmentSkill/formValues";
import { dateConverstion } from "../../../../../../../../helpers/dataConversion/substring";
// import "./UploadEquipmentSkill.css";
import { SelectCardWrapper } from "../../../../../../../../components/SelectCardWrapper/SelectCardWrapper";
import { FormikInput } from "../../../../../../../../components/Input/FormikInput";
import { ImageUpload } from "../../../../../../../../components/ImageUpload/ImageUpload";
import { convertToFormData } from "../../../../../../../../helpers/dataConversion/convertToFormData";
import { v4 as uuid } from "uuid";
import { increasePageIndex } from "../../../../../../../documentFlow/slice/docFlowSlice";
export const EquipmentDeatilsCard = ({
  isOpenForm,
  onCloseForm,
  triggerRefresh,
}) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const [imagePreview, setImagePreview] = useState(null);
  // const [selectedEquipmentTypeId, setSelectedEquipmentTypeId] = useState(null);
  // const [fileType, setFileType] = useState("");
  // const [isCertified, setIsCertified] = useState(null);
  const [allEquipmentTypes, setAllEquipmentTypes] = useState(null);
  const [industryTypeList, setIndustryTypeList] = useState(null);
  const [industryMakeList, setIndustryMakeList] = useState(null);
  const [expertiseAreaList, setExpertiseAreaList] = useState(null);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null);
  const [selectedOption, setSelectedOption] = useState("No");
  const [certificateValidDate, setCertificateValidDate] = useState("");
  // const [certificateImageFile, setCertificateImageFile] = useState(null);
  const [certificateIssuer, setCertificateIssuer] = useState(null);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const  [EquipmentList,setEquipmentList]=useState(null);
  const [OemList,setOemList]=useState(null);
  const [industryList,setIndustryList]=useState(null)


  const currentDate = new Date();
  const tomorrow = new Date(currentDate);
  tomorrow.setDate(currentDate.getDate() + 1);
  
  // Convert dates to YYYY-MM-DD format
  const minDate = currentDate.toISOString().split('T')[0];
  const maxDate = tomorrow.toISOString().split('T')[0];

  const toast = useToast();
  const queryClient = useQueryClient();

  // const {
  //   data: equipmentData,
  // } = useQuery(
  //   ["editEquipment", selectedEquipmentTypeId],
  //   () => EditEquipment(userData.token, selectedEquipmentTypeId),
  //   {
  //     enabled: !!selectedEquipmentTypeId, // only run the query if selectedEquipmentTypeId is provided
  //   }
  // );
  // const equipdata = equipmentData?.data?.Data?.UserEquipment;


  const initialValues = 
     {
        equipmentType: null,
        industryType: null,
        equipmentMake: null,
        expertise: null,
        certificateNumber: null,
        certificateIssuer: null,
        certificateIssueDate: null,
        certificateImage: null,
      };


  const openEditPopup = () => {
    setIsEditPopupOpen(true);
  };

  const closeEditPopup = () => {
    setIsEditPopupOpen(false);
  };

  /*********************Equpment List*******************************/

  const { data: fetchedEqupmentList } = useQuery("AlltypeofEquipmentTy", () =>
  getAlltypeofEquipmentTy(userData.token)
);

useEffect(()=>{
  setEquipmentList(fetchedEqupmentList?.data?.Data?.EquipmentsList)
});

  /*********************fetch OemType*******************************/

const { data: fetchOemType } = useQuery(
  ["IndustryMaker", selectedEquipmentType],
  () => getIndustryMaker(userData.token, selectedEquipmentType),
  {
    enabled: !!selectedEquipmentType,
  }
);

useEffect(() => {
  setOemList(fetchOemType?.data?.Data?.OEMList);
}, [fetchOemType, selectedEquipmentType]);



 /*********************Industry Type******************************/
const { data:fetchIndustryType} = useQuery(
  ["IndustryType", selectedEquipmentType],
  () => getAllEquipmentTypeswithEquipId(userData.token, selectedEquipmentType),
  {
    enabled: !!selectedEquipmentType,
  }
);

useEffect(()=>{
  setIndustryList(fetchIndustryType?.data?.Data?.IndustriesList)
},[fetchIndustryType,selectedEquipmentType]);

  /******************************************************* */

  const getAllEquipmentTypes = async () => {
    const authHeaders = new Headers();
    authHeaders.append("token", userData.token);
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}${API_CONSTANTS.GET_ALL_EQUIPMENTS_TYPE}`,
      {
        headers: authHeaders,
      }
    );
    const data = await response.json();
    if (data.Status === 200) {
      setAllEquipmentTypes([...data.Data.EquipmentsList]);
    }
  };

  const getIndustryMake = async () => {
    const authHeaders = new Headers();
    authHeaders.append("token", userData.token);
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}${API_CONSTANTS.GET_MAKE}${selectedEquipmentType}`,
      {
        headers: authHeaders,
      }
    );
    const data = await response.json();
    if (data.Status) {
      setIndustryMakeList(data.Data.OEMList);
    }
  };


  const today = new Date();
const   formattedToday = today.toISOString().split('T')[0];

  const getIndustryType = async () => {
    const authHeaders = new Headers();
    authHeaders.append("token", userData.token);
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}${API_CONSTANTS.GET_ALL_INDUSTRY}${selectedEquipmentType}`,
      { 
        headers: authHeaders,
      }
    );
    const data = await response.json();
    if (data.Status) {
      setIndustryTypeList(data.Data.IndustriesList);
    }
  };
  const getAllExpertiseArea = async () => {
    const authHeaders = new Headers();
    authHeaders.append("token", userData.token);
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/GetMaintenanceExpertiseTypeList`,
      {
        headers: authHeaders,
      }
    );
    const data = await response.json();
    if (data.Status === 200) {
      setExpertiseAreaList(data.Data.MaintenanceExpertiseTypes);
    }
  };

  const postEquipmentSkills = async (body) => {

    const authHeaders = new Headers();
    authHeaders.append("token", userData.token);

    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}${API_CONSTANTS.UPLOAD_EQUIPMENT_DETAILS}`,
      {
        method: API_CONSTANTS.HTT_POST,
        headers: authHeaders,
        body: body,
      }
    );
    const data = await response.json();
    if (data.Status === 201) {
      toast({
        status: "success",
        title: data.Message.SuccessMessage,
        isClosable: true,
        position: "top-right",
      });
      queryClient.invalidateQueries("getEducationDetails");
      triggerRefresh();
      onCloseForm();
      setImagePreview(null);
      dispatch(increasePageIndex());
    } else {
      toast({
        status: "error",
        title: data.Message.FailMessage,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    (async () => {
      await getAllEquipmentTypes();
    })();
  }, []);

  useEffect(() => {
    if (selectedEquipmentType) {
      (async () => {
        await getIndustryMake();
        await getIndustryType();
        await getAllExpertiseArea();
      })();
    } else {
      setIndustryMakeList(null);
      setIndustryTypeList(null);
    }
  }, [selectedEquipmentType]);

  return (
    isOpenForm && (
      <div className="popup-card-overlay">
        <div className="popup-card-Equipment">
          <div className="closeButton" onClick={onCloseForm}>
            <ReactSVG
              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Cross.svg`}
            />
          </div>
          <div className="AddEquipment">
          <ReactSVG
              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/AddEquipment.svg`}
            />
          </div>
          <div className="herosection-text">Add Equipment Detail</div>
          <Formik
            initialValues={initialValues}
            validationSchema={
              userData.BusinessTypeValue.toLowerCase() ===
                "Maintenance Partner".toLowerCase() && selectedOption === "No"
                ? MAINTENANCE_NOT_CERTIFIED_VALIDATIONS
                : userData.BusinessTypeValue.toLowerCase() ===
                    "Maintenance Partner".toLowerCase() &&
                  selectedOption === "Yes"
                ? MAINTENANCE_CERTIFIED_VALIDATIONS
                : userData.BusinessTypeValue.toLowerCase() ===
                    "Operator Partner".toLowerCase() && selectedOption === "No"
                ? OPERATOR_NOT_CERTIFIED_VALIDATIONS
                : OPERATOR_CERTIFIED_VALIDATIONS
            }
            onSubmit={async (values) => {
              const body = {
                IndustryId: values.industryType,
                EquipmentTypeId: values.equipmentType,
                EquipmentMakeId: values.equipmentMake,
                IsNeverExpire: 1,
                
              };
              if (selectedOption === "No") {
                body.AreYouCertified = 0;
              } else {
                body.AreYouCertified = 1;
                body.CertificateNumber = values.certificateNumber;
                body.IssuerName = values.certificateIssuer;
                body.ValidDate = dateConverstion(values.certificateIssueDate);
                body.UserEquipmentCertificates = values.certificateImage;
              }
              if (
                userData.BusinessTypeValue.toLowerCase() ===
                "Maintenance Partner".toLowerCase()
              ) {
                body.ExpertiseArea = values.expertise;
              }
              await postEquipmentSkills(convertToFormData(body));
            }}
          >
            {({ values, handleSubmit, setFieldValue, errors, touched }) => (
              <form id="equipment-skill-form" onSubmit={handleSubmit}>
                <Flex
                  flexDirection="column"
                  gap="2em"
                  maxH="300px"
                  overflowY="scroll"
                >
                  <Flex
                    gap="2em"
                    width="80%"
                    margin="0 auto"
                    padding="0 0.25em"
                    flexWrap="wrap"
                  >
                    <FormControl isRequired={true} maxW="374px" width="100%">
                      <FormLabel>Equipment Type</FormLabel>
                      <Field
                         as="select"
                        name="equipmentType"
                        // value={values.equipmentType}
                        // values.equipmentType
                        h="52px"
                        border="1px solid #2c2c2c14"
                        focusBorderColor="#2c2c2c"
                        backgroundColor={
                          values.equipmentType ? "#ffff" : "#2c2c2c05"
                        }
                        maxW="374px"
                        onChange={(e) => {
                          setSelectedEquipmentType(e.target.value);
                          setFieldValue("equipmentType", e.target.value);
                        }}
                      >
                        <option value="" isDisabled>
                          Select
                        </option>
                        {EquipmentList && EquipmentList.length > 0
                          ? EquipmentList.map((equipmentType) => (
                              <option
                                key={equipmentType.EquipmentTypeId}
                                value={equipmentType.EquipmentTypeId}
                              >
                                {equipmentType.EquipmentTypeValue}
                              </option>
                            ))
                          : null}
                      </Field>
                    </FormControl>

                    <FormControl
                      isDisabled={!industryTypeList}
                      isRequired={true}
                      maxW="374px"
                      width="100%"
                    >
                      <FormLabel>Industry Type</FormLabel>
                      <Select
                        name="industryType"
                        value={values?.industryType || ""}
                        h="52px"
                        border="1px solid #2c2c2c14"
                        focusBorderColor="#2c2c2c"
                        backgroundColor={
                          values.industryType ? "#ffff" : "#2c2c2c05"
                        }
                        maxW="374px"
                        onChange={(e) =>
                          setFieldValue("industryType", e.target.value)
                        }
                      >
                        <option value="" isDisabled>
                          Select
                        </option>
                        {industryList && industryList.length > 0
                          ? industryList.map((industryType) => (
                              <option
                                key={industryType.IndustryId}
                                value={industryType.IndustryId}
                              >
                                {industryType.IndustryValue}
                              </option>
                            ))
                          : null}
                      </Select>
                    </FormControl>

                    <FormControl
                      isDisabled={!industryMakeList}
                      isRequired={true}
                      maxW="374px"
                      width="100%"
                    >
                      <FormLabel>Industry Make</FormLabel>
                      <Select
                        h="52px"
                        border="1px solid #2c2c2c14"
                        focusBorderColor="#2c2c2c"
                        backgroundColor={
                          values.equipmentMake ? "#ffff" : "#2c2c2c05"
                        }
                        maxW="374px"
                        value={values.equipmentMake}
                        onChange={(e) =>
                          setFieldValue("equipmentMake", e.target.value)
                        }
                      >
                        <option value="" isDisabled>
                          Select
                        </option>
                        {OemList && OemList.length > 0
                          ? OemList.map((make) => (
                              <option key={make.OEMId} value={make.OEMId}>
                                {make.OEMName}
                              </option>
                            ))
                          : null}
                      </Select>
                    </FormControl>

                    {userData.BusinessTypeValue.toLowerCase() ===
                      "Maintenance Partner".toLowerCase() && (
                      <FormControl
                        isDisabled={!expertiseAreaList}
                        isRequired={true}
                        maxW="374px"
                        width="100%"
                      >
                        <FormLabel>Expertise</FormLabel>
                        <Select
                          name="expertise"
                          h="52px"
                          border="1px solid #2c2c2c14"
                          focusBorderColor="#2c2c2c"
                          backgroundColor={
                            values.expertise ? "#ffff" : "#2c2c2c05"
                          }
                          maxW="374px"
                          value={values.expertise}
                          onChange={(e) =>
                            setFieldValue("expertise", e.target.value)
                          }
                        >
                          <option value="" isDisabled>
                            Select
                          </option>
                          {expertiseAreaList && expertiseAreaList.length > 0
                            ? expertiseAreaList.map((equipmentArea) => (
                                <option
                                  key={uuid()}
                                  value={
                                    equipmentArea.MaintenanceExpertiseTypeId
                                  }
                                >
                                  {equipmentArea.MaintenanceExpertiseTypeValue}
                                </option>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    )}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <FormLabel>
                        Are you certified for this equipment&#63;
                      </FormLabel>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "12px",
                        }}
                      >
                        <SelectCardWrapper
                          width="178px"
                          height="52px"
                          padding="0"
                          fontWeight="600"
                          isSelected={selectedOption === "No" ? true : false}
                          onClick={(e) => {
                            setSelectedOption(
                              e.target.innerText.split(" ")[
                                e.target.innerText.split(" ").length - 1
                              ]
                            );
                          }}
                        >
                          &#10005; No
                        </SelectCardWrapper>
                        <SelectCardWrapper
                          width="178px"
                          height="52px"
                          padding="0"
                          fontWeight="600"
                          isSelected={selectedOption === "Yes" ? true : false}
                          onClick={(e) => {
                            setSelectedOption(
                              e.target.innerText.split(" ")[
                                e.target.innerText.split(" ").length - 1
                              ]
                            );
                          }}
                        >
                          &#10004; Yes
                        </SelectCardWrapper>
                      </div>

                      {selectedOption === "Yes" && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              gap: "2em",
                              flexWrap: "wrap",
                              marginTop: "2em",
                            }}
                          >
                            <FormikInput
                              label="Certificate Number"
                              placeholder="Example : CET00001"
                              name="certificateNumber"
                              isRequired={true}
                            />
                            <FormikInput
                              label="Name of Certificate Issuer"
                              isRequired={true}
                              placeholder="Enter issuer's name here ..."
                              name="certificateIssuer"
                              onChange={(e) =>
                                setCertificateIssuer(e.target.value)
                              }
                            />
                            <FormikInput
                              label="Certificate Valid Date"
                              isRequired={true}
                              placeholder="Select Date"
                              type="date"  
                              name="certificateIssueDate"
                              min={minDate}
                              onChange={(e) =>
                                setCertificateValidDate(e.target.value)
                              }
                            />

                            <ImageUpload
                              label="Certificate Image"
                              name="certificateImage"
                              helperText=""
                              formikProps={{
                                setFieldValue,
                                errors,
                                touched,
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </Flex>
                </Flex>
              </form>
            )}
          </Formik>

          <Box>
            <Flex
              justifyContent="center"
              alignItems="center"
              bgColor="#fff"
              h="78px"
              style={{ boxShadow: "0px -10px 15px #2c2c2c0f" }}
            >
              <Button
                type="submit"
                variant="gradient"
                fontWeight="semibold"
                width="400px"
                margin="0 auto"
                maxW="400px"
                form="equipment-skill-form"
              >
                Submit
              </Button>
            </Flex>
          </Box>
        </div>
      </div>
    )
  );
};