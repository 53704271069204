import { Flex, Text } from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";
import { ReactSVG } from "react-svg";

export const AnalyticsCard = ({
  height = "100px",
  width = "184px",
  backgroundColor = "#FEFEFE",
  iconUrl = "",
  isLoading = false,
  analytics = 0,
  description = "Description",
}) => {
  return (
    <Flex
      dropShadow=""
      height={height}
      width={width}
      background={backgroundColor}
      borderRadius="1.25em"
      flexDirection="column"
      gap="0.5em"
      boxShadow="0px 0px 9px -1px #2c2c2c20"
      border="1px solid #E3E5E8"
      justifyContent="center"
      alignItems="center"
    >
      <Flex gap="0.5em">
        <ReactSVG src={iconUrl} />
        {isLoading ? <Skeleton width="45px" height="42px"/> :  <Text  fontSize="36px" lineHeight="42px" letterSpacing="5%">{analytics > 9 ? analytics : `0${analytics}`}</Text>}
       
      </Flex>
      {isLoading ?  <Skeleton width="80px" height="19px"/>:  <Text fontSize="19px" lineHeight="26px" letterSpacing="5%">{description}</Text>}
     
    </Flex>
  );
};
