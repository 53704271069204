import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserPremiumDetails } from "../../../../services/premium/premium";

export const getUserPremiumStatusThunk = createAsyncThunk("/user/premiumStatus", async(_, {rejectWithValue}) => {
 try{
    const data = await getUserPremiumDetails();
    return data;
   
 }
 catch(error){
rejectWithValue(error)
 }
})