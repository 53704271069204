import { createSlice } from "@reduxjs/toolkit";
import { getMaterialListingPremiumLeadsThunk } from "../thunks/getMaterialListingPremiumLeads";

export const materialListingPremiumLeadsSlice = createSlice({
    name : "materialListingPremiumLeads",
    initialState : {
        totalLeads : 0,
        redeemedLeads : 0,
        remainingLeads : 0,
        isLoading : false
    },
    reducers : {
        setTotalLeads : (state, {payload}) => {
            state.totalLeads = payload
        }
    },
    extraReducers : (builder) => {
        builder
        .addCase(getMaterialListingPremiumLeadsThunk.pending, (state) => {
            state.totalLeads = 0
            state.redeemedLeads = 0
            state.remainingLeads = 0
            state.isLoading = true
        })
        .addCase(getMaterialListingPremiumLeadsThunk.fulfilled, (state, {payload}) => {
            state.totalLeads = payload.TotalCount
            state.redeemedLeads = 0
            state.remainingLeads = payload.RemainingCount
            state.isLoading = false
        })
        .addCase(getMaterialListingPremiumLeadsThunk.rejected, (state) => {
            state.totalLeads = 0
            state.redeemedLeads = 0
            state.remainingLeads = 0
            state.isLoading = false
        })
    }
    
})

