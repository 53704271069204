import React, { useState, useEffect,  useRef   } from 'react';
import './GetVerifyDetails.css';
import { ReactSVG } from 'react-svg';
import { useSelector } from 'react-redux';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { getUploadedVerificationDocumentList, deleteVerificationDocuments } from '../../../../../../../../ApiEquip9/CommonApi';
import { LoadingSkelaton } from '../../../../../../../components/LoadingSkaleton/LoadingSkelton';

export const GetVerifyDetails = ({triggerRefresh}) => {
  const userData = useSelector((state) => state.user);
  const queryClient = useQueryClient();
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [isDocumentPopupOpen, setIsDocumentPopupOpen] = useState(false);
  const [documentImageUrl, setDocumentImageUrl] = useState('');
  // const [documentPath, setDocumentPath] = useState('');
  const [documents, setDocuments] = useState([]); 
  const [documentType, setDocumentType] = useState('');  
  const yesButtonRef = useRef(null);
  const noButtonRef=useRef(null);
  
  const { data: getUploadedVerification, isLoading, isError } = useQuery(
    'getUploadedVerification',
    () => getUploadedVerificationDocumentList(userData.token)
  );

  useEffect(() => {
    if (getUploadedVerification?.data?.Data?.VerificationDocuments) {
      setDocuments(getUploadedVerification?.data?.Data?.VerificationDocuments);
    }
  }, [getUploadedVerification]);


    
  const deleteVerificationMutation = useMutation(
    (UserVerificationDocumentId) => deleteVerificationDocuments(UserVerificationDocumentId, userData.token),
    {
      onMutate: async (deletedId) => {
        await queryClient.cancelQueries('getUploadedVerification');
  
        setDocuments(prevDocs => prevDocs.filter(item => item.UserVerificationDocumentId !== deletedId));
  
        const previousData = queryClient.getQueryData('getUploadedVerification');
  
        if (previousData) {
          queryClient.setQueryData('getUploadedVerification', (oldData) => {
            const newData = { ...oldData };
            newData.data.Data.VerificationDocuments = newData.data.Data.VerificationDocuments.filter(
              (item) => item.UserVerificationDocumentId !== deletedId
            );
            return newData;
          });
        }
        return { previousData };
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          queryClient.setQueryData('getUploadedVerification', context.previousData);
          setDocuments(context.previousData.data.Data.VerificationDocuments);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('getUploadedVerification');
        triggerRefresh();
      },
    }
  );

  
  

  const openDocumentPopup = (imageUrl) => {
    const fileExtension = imageUrl.split('.').pop().toLowerCase();
    
    if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
        setDocumentType('image');
    } else if (fileExtension === 'pdf') {
        setDocumentType('pdf');
    } else {
        return;
    }
    
    setDocumentImageUrl(imageUrl);
    setIsDocumentPopupOpen(true);
};





  const closeDocumentPopup = () => {
    setIsDocumentPopupOpen(false);
  };

  const openConfirmationDialog = (UserVerificationDocumentId) => {
    setDocumentToDelete(UserVerificationDocumentId);
    setConfirmationDialog(true);
  };

  const closeConfirmationDialog = () => {
    setDocumentToDelete(null);
    setConfirmationDialog(false);
  };

  const deleteDocument = async () => {
    if (documentToDelete) {
      await deleteVerificationMutation.mutateAsync(documentToDelete);
      closeConfirmationDialog();
    }
  };


  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    return `${day}-${month}-${year.slice(-2)}`;
};

  return (
    <>
        {isLoading ? (
            <div style={{margin:"33px 33px"}}><LoadingSkelaton/></div>
        ) : isError ? (
            <div><div className="no-documents-message">No verification documents added yet.</div></div>
        ) : (
            <>
              <div className='card'>
                {
                  documents &&   documents?.length >0 ?(<>
                  {
                    documents?.map((item) => (
                      <React.Fragment key={item.UserVerificationDocumentId}>
                        <div className='card-information'>
                        <div >
                          <div className='added-date-and-verified'> 
                            <div><span className='added-on'>Added On:</span><span className='added-on-date'>{formatDate(item.AddedOn.split(' ')[0])}</span></div>
                          <div style={{ 
                            height:"23px",width:"75px",borderRadius:"23px",fontSize:"10px",textAlign:'center',paddingTop:'5px',
                          background: item.IsVerifiedByEquip9 === 0 ? 'rgba(214, 0, 0, 0.376)' : '#319f00', color:"white"
                          
                      }}>{item.IsVerifiedByEquip9 === 0 ? 'Not Verified' : 'Verified'}</div>
                          </div>
                                <div className='added-card-information'>
                                <div>
                                  <img  style={{ filter: 'blur(5px)' }}  
                                   src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Rectangle.png`} />
                              </div>
                              <div>
                              <div className='Doc-support-block'>
                                  <div className='get-document-name'>
                                      <div className='hero-document'>Document Name</div>
                                      <div  className='value-document-name'>
                                      {item.VerificationDocumentName}
                                      </div>
                                       
                                  </div>
                                  <div className='get-document-number'>
                                      <div className='hero-document'>Document Number</div>
                                      
                                      <div className='value-document-name'>
                                      {item.DocumentNumber}
                                      </div>
                                  </div>
                                 
                              </div>
                          
                              </div>
                          
                        </div>
                        <div>
                          <div className='upload-and-deletedoc' >
                                   
                                      <div
                                          className='view-document-detai-verification'
                                          onClick={() => openDocumentPopup(`${process.env.REACT_APP_CDN_PVT_URL}VerificationDocument/${item.DocumentFileReference}`)}
                                      >
                                          View Document
                                      </div>
                                       <div
                                        className='delete-icon'
                                         onClick={() => openConfirmationDialog(item.UserVerificationDocumentId)}>
                                      <ReactSVG
                                          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/garbage.svg`}
                                      />
                                  </div>
                                  </div>
                              </div>
                        </div>
                  
                        </div>
                        
                        <div>
                        </div>
                      </React.Fragment>
                  ))
                  }
                  </>):(<>
                    <div className="no-documents-message">No verification documents added yet.</div>
                  </>)             
                  }
                </div>
                {
                     confirmationDialog && (
                      <div className='modal-backdrop'>
                          <div className='confirmation-dialog'>
                            <div className='deleteHeading'>
                            <ReactSVG
                                          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/HeadDelete.svg`}
                                      />
                            </div>
                              <div style={{marginTop:"12px"}}>
                              <p className='conformation-text'>Are you sure you want to delete this document?</p>
                              </div>
                            
                              
                              <button 
                                  ref={yesButtonRef}
                                  style={{
                                      background: '#C6C7C8',
                                      transition: '0.3s'
                                  }}
                                  onMouseOver={() => {
                                      yesButtonRef.current.style.background = 'linear-gradient(90deg, var(--primary), var(--tertiary))';
                                  }}
                                  onMouseOut={() => {
                                       yesButtonRef.current.style.background = '#C6C7C8';
                                  }}
                                  onClick={deleteDocument}
                              >
                                  Yes
                              </button>
              
                              <button 
                                ref={noButtonRef}
                                 style={{
                                  background: '#C6C7C8',
                                  transition: '0.3s'
                              }}
                              onMouseOver={() => {
                                noButtonRef.current.style.background = 'linear-gradient(90deg, var(--primary), var(--tertiary))';
                              }}
                              onMouseOut={() => {
                                noButtonRef.current.style.background = '#C6C7C8';
                              }}
                                  onClick={closeConfirmationDialog}
                              >
                                  No
                              </button>
                          </div>
                      </div>
                  )
                }

                {isDocumentPopupOpen && (
                    <div className='document-popup-container'>
                        <div className='document-popup'>
                          <div><p>Verification Document</p></div>
                          <br/>
                            {documentType === 'pdf' ? (
                                <embed src={documentImageUrl} type="application/pdf" width="100%" height="200px" />
                            ) : (
                                <img
                                    src={documentImageUrl}
                                    alt='Document'
                                    style={{ width: '300px', height: '200px' }}
                                />
                            )}
                            <button className='close-button' onClick={closeDocumentPopup}>
                            <ReactSVG
                                        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Cross.svg`}
                                    />
                            </button>
                        </div>
                    </div>
                )}
            </>
        )}
    </>
);
};
