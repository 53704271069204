import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOwnerMarketplaceSentList } from "../../../services/ownerMarketplace/ownerMarketplace";


export const  getOwnersSentCardsThunk = createAsyncThunk("/getOwnersSentCards" ,async () =>{
    try{
        const data =await getOwnerMarketplaceSentList();
        return data ;
    }catch (error){

    }
})