import { WebPageLayout } from "../Layout/WebpageLayout";
import "./Terms.css";
import useWindowWidth from "../../sharedComponent/isMobile/CheckMobile";
import { MobileResponsiveFooter } from "../components/MobileResposiveFooter/MobileResponsiveFooter";
import { MobileHeader } from "../components/MobileHeader/MobileHeader";
import { Flex } from "@chakra-ui/react";
function RatingSystem() {
  const isMobile = useWindowWidth();
  return (
    <div>
     {isMobile <768 ?(<>
       <MobileHeader />
        <Flex>
        <div className="resposive-terms">
        <h2 className="terms-heading">Rating System</h2>

        <div className="terms-content">
          <h3 className="rating-h3">HOW IS MY RATING DETERMINED?</h3>
          After each job request, owners and partners have the opportunity to
          rate one another from 1 to 5 stars, based on their behaviour, payment
          on time, and safety conditions for owners and work, behaviour, and
          punctuality for partners.A partner or owner's overall rating is
          displayed as an average. For example, a highly-rated partner might
          have 4.9 stars.Ratings are anonymous. Neither owners nor partners see
          individual ratings tied to a particular job or person. Candid,
          constructive, and respectful feedback is beneficial for
          everyone.Providing a rating fosters mutual respect between owners and
          partners. This strengthens our community and helps everyone get the
          most from EQUIP9™. We appreciate your participation.
          <h5 className="rating-h5">BE A 5 STAR Owner</h5>
          Operator or Maintenance Partners frequently consider these 3 areas
          when rating an Owner: Behaviour : Be ready to welcome when partners
          arrive at the work location. Be sure the location you entered is
          actually where you'll be. Respect every human being. Payment on Time :
          Treat partners and their services the way they'd expect to be treated
          themselves. Safety Conditions at Work : Operator or Maintenance
          Partners want to make sure everyone in their work condition is safe,
          and suitable for human conditions to work.
          <h5 className="rating-h5">BE A 5 STAR Partner</h5>
          Business or Equipment Owners frequently consider these 3 areas when
          rating an Owner: Work : Be ready to give your best when you accept
          your job. Always arrive at the work location on time. Start your job
          with OTP. Be sure you end your job on time. Behaviour : Be ready to
          welcome when you arrive at the work location. Respect every human
          being. Punctuality : Business or Equipment Owners want to make sure
          they get their work completed on time. Be a hero and always finish
          your services on time.
        </div>

        <div className="terms-link">
          <a href="mailto:contact@equip9.com">contact@equip9.com</a>
        
        </div>
        <br/>
      </div>
      
        </Flex>
       
       <MobileResponsiveFooter />
     </>):(<>
      <WebPageLayout>
      <div className="terms">
        <h2 className="terms-heading">Rating System</h2>

        <div className="terms-content">
          <h3 className="rating-h3">HOW IS MY RATING DETERMINED?</h3>
          After each job request, owners and partners have the opportunity to
          rate one another from 1 to 5 stars, based on their behaviour, payment
          on time, and safety conditions for owners and work, behaviour, and
          punctuality for partners.A partner or owner's overall rating is
          displayed as an average. For example, a highly-rated partner might
          have 4.9 stars.Ratings are anonymous. Neither owners nor partners see
          individual ratings tied to a particular job or person. Candid,
          constructive, and respectful feedback is beneficial for
          everyone.Providing a rating fosters mutual respect between owners and
          partners. This strengthens our community and helps everyone get the
          most from EQUIP9™. We appreciate your participation.
          <h5 className="rating-h5">BE A 5 STAR Owner</h5>
          Operator or Maintenance Partners frequently consider these 3 areas
          when rating an Owner: Behaviour : Be ready to welcome when partners
          arrive at the work location. Be sure the location you entered is
          actually where you'll be. Respect every human being. Payment on Time :
          Treat partners and their services the way they'd expect to be treated
          themselves. Safety Conditions at Work : Operator or Maintenance
          Partners want to make sure everyone in their work condition is safe,
          and suitable for human conditions to work.
          <h5 className="rating-h5">BE A 5 STAR Partner</h5>
          Business or Equipment Owners frequently consider these 3 areas when
          rating an Owner: Work : Be ready to give your best when you accept
          your job. Always arrive at the work location on time. Start your job
          with OTP. Be sure you end your job on time. Behaviour : Be ready to
          welcome when you arrive at the work location. Respect every human
          being. Punctuality : Business or Equipment Owners want to make sure
          they get their work completed on time. Be a hero and always finish
          your services on time.
        </div>

        <div className="terms-link">
          <a href="mailto:contact@equip9.com">contact@equip9.com</a>
        </div>
      </div>
    </WebPageLayout>
     </>)}
    </div>
    
  );
}

export default RatingSystem;
