import { ArrowBackIcon } from '@chakra-ui/icons';
import { Button, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAddressModal } from '../../slice/subscriptionSlice';

const UpdateAdressModal = ({isOpen}) => {

    const {addressModal} = useSelector(state=> state.subscription);
    const navigate = useNavigate();
    const dispatch = useDispatch();

  return (
    <Modal isOpen={isOpen} isCentered>
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader>Address Not Found!</ModalHeader>
      <ModalBody>
        <Text>
          You need to update your address to proceed ahead. Do you want to
          continue?
        </Text>
      </ModalBody>
      <ModalFooter>
        <Flex justifyContent="space-between" w="100%">
          <Button
            variant="link"
            onClick={() => {
              dispatch(setAddressModal(false));
              navigate("/dashboard");
            }}
          >
            <ArrowBackIcon /> Go Back to Dashboard
          </Button>
          <Button
            variant="gradient"
            dropShadow="none"
            boxShadow="none"
            onClick={() => {
              dispatch(setAddressModal(false));
              navigate("/subscription/update-address");
            }}
          >
            Continue
          </Button>
        </Flex>
      </ModalFooter>
    </ModalContent>
  </Modal>
  )
}

export default UpdateAdressModal