export const PartnerDetailsCard = ({
  imageLink = "",
  onClick = () => {},
  headingKey,
  headingValue,
  descriptionKey,
  descriptionValue,
  subDescriptionKey,
  subDescriptionValue,
  certificateName = "View Certificate",
  isDeferred = false,
  isDiscription = false,
}) => {
  return (
    <div style={{ minWidth: "300px", border: "4px" }}>
      <div style={{ border: "1px solid #2c2c2c50", padding: "1em 0.75em" }}>
        <div
          style={{
            borderBottom: "1px solid #2c2c2c40",
            paddingBottom: "0.55em",
          }}
        >
          <h1 style={{ fontSize: "18px", fontWeight: "600" }}>
            <span style={{color: "#2c2c2c90", fontWeight: 500}}>{headingKey}:</span> {headingValue}
          </h1>
          {isDiscription ? (
            null
          ) : (
            <p style={{ fontSize: "18px", fontWeight: "600" }}>
            <span style={{color: "#2c2c2c90", fontWeight: 500}}> {descriptionKey}: </span> {descriptionValue}
            </p>
          )}
          {subDescriptionKey && (
            <p style={{ fontSize: "18px", fontWeight: "600" }}>
               <span style={{color: "#2c2c2c90", fontWeight: 500}}>{subDescriptionKey}:</span> {subDescriptionValue}
            </p>
          )}
        </div>

          {isDeferred ? (
              <div
            style={{height:"20px"}}
            >

            </div>
          ) : (
            <div>
            {imageLink ? (
              <p
                style={{ fontWeight: 600, color: "#1E3264", cursor: "pointer" }}
                onClick={onClick}
              >
                {certificateName}
              </p>
            ) : (
              <p style={{ fontWeight: 600, color: "#D60000" }}>
                Document not available
              </p>
            )}
            </div>
          )}
      </div>
    </div>
  );
};
