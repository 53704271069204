
import { Text } from "@chakra-ui/react";
import pt from "../../../assets/pattn.png"
import BannerImage from "../../../assets/pattn.png"

function Pattern(){
    return(
<>
       
          <div className="silver-gradient" style={{display: "flex", justifyContent: "space-between", alignItems: "center",height:"232px", paddingLeft: "60px", color: "#FBFBFC"}}>
          <div style={{display: "flex", flexDirection: "column", alignSelf: "center"}}>
            <Text fontSize={['1em','1.5em','2em','2.5em','2.5em','3em']} style={{fontWeight: 700, textTransform: "uppercase"}}><span style={{color:"var(--primary"}}>When patterns are broken, new worlds emerge.</span></Text>
            <Text fontSize={['0.8em','1em','1em','1em','1em','1.12em']} style={{ lineHeight:"23.5px", fontWeight: "600"}}> Mindset | Startup | Building Zero to One</Text>
          </div>
          <div> 
            <img src={BannerImage} style={{ objectFit: "cover", height: "100%", width: "100%" }}/>
          </div>
          </div>
          </>
    )
}

export default Pattern;
