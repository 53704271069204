import { getSessionStorageItems } from "../../../helpers/sessionStorage/sessionStorage";

export const equipmentListingInitialState = {
  equipmentNickname:
    getSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_NICKNAME") || "",
  equipmentType:
    getSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_TYPE") || "",
  equipmentMake:
    getSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_MAKE") || "",
  otherMake:
    getSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_OTHER_MAKE") || "",
  equipmentModel:
    getSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_MODEL") || "",
  otherModel:
    getSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_OTHER_MODEL") || "",
  manufacturingYear:
    getSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_MANUFACTURING_YEAR") ||
    "",
  meterReading:
    getSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_METER_READING") || "",
  equipmentLocation:
    getSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_LOCATION") || "",
  country: getSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_COUNTRY") || "",
  state: getSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_STATE") || "",
  city: getSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_CITY") || "",
  pincode: getSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_PINCODE") || "",
  isInsuranceActive:
    getSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_INSURANCE_ACTIVE") ||
    "",
  insuranceActiveDate:
    getSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_INSURANCE_DATE") || "",
  engineMake:
    getSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_ENGINE_MAKE") || "",
  chassisNumber:
    getSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_CHASSIS_NUMBER") || "",
  fuelType:
    getSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_FUEL_TYPE") || "",
  autoEngineShutdown:
    getSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_AUTO_SHUT_DOWN") || "",
  createdAssetId:
    getSessionStorageItems("EQUIPMENT_LISTING_EQUIPMENT_CREATED_ASSET_ID") ||
    "",
  // PURCHASING FORM
  purchasingYear:
    getSessionStorageItems("EQUIPMENT_LISTING_PURCHASING_YEAR") || "",
  purchasingPrice:
    getSessionStorageItems("EQUIPMENT_LISTING_PURCHASING_PRICE") || "",
  registeredCountry:
    getSessionStorageItems("EQUIPMENT_LISTING_REGISTERED_COUNTRY") || "",
  registeredState:
    getSessionStorageItems("EQUIPMENT_LISTING_REGISTERED_STATE") || "",
  ownerShipStatus:
    getSessionStorageItems("EQUIPMENT_LISTING_OWNERSHIP_STATUS") || "",
  ownershipName:
    getSessionStorageItems("EQUIPMENT_LISTING_ASSET_OWNERSHIP_NAME") || "",
  purchasingStateName:
    getSessionStorageItems("EQUIPMENT_LISTING_ASSET_STATE_NAME") || "",
  purchasingCountryName:
    getSessionStorageItems("EQUIPMENT_LISTING_ASSET_COUNTRY_NAME") || "",
  otherEquipmentDetails:
    getSessionStorageItems("EQUIPMENT_LISTING_ASSET_OTHER_DETAILS") || "",
  stepperIndex: getSessionStorageItems("EQUIPMENT_LISTING_STEPPER_INDEX")
    ? parseInt(getSessionStorageItems("EQUIPMENT_LISTING_STEPPER_INDEX"))
    : 0,
  stepperRoute: [
    { name: "Step 1" },
    { name: "Step 2" },
    { name: "Step 3" },
    { name: "Step 4" },
  ],
  isLoading:false
};
