import React, { useState, useEffect, useMemo } from 'react';
import './BusinessDetail.css';
import { SideMenuLayout } from '../../../../../../layout/sideMenuLayout/SideMenuLayout';
import { DashboardNavBar } from '../../../../../../components/DashboardNavBar/DashboardNavBar';
import { useDispatch, useSelector } from "react-redux";
import { GetAllUserRolesList, getUpdateE9UserRole,createUserTypeRole } from '../../../../../../../ApiEquip9/CommonApi';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { LoadingComponent } from '../../../../../../../sharedComponent/Loading/LoadingComponent';
import { ReactSVG } from 'react-svg';
import { MidSection } from '../../../../../../../sharedComponent/midSection/MidSection';
import { useToast } from "@chakra-ui/react";
import HerodetailSection from './HerodetailSection';

export const BusinessDetail = () => {
  const userData = useSelector((state) => state.user);
  const queryClient = useQueryClient();

  // Optimize by using useMemo for defaultUserRoleTypeId
  const defaultUserRoleTypeId = useMemo(() => {
  
    return userData?.accountDetails?.UserDetails?.E9UserRole?.E9UserRoleId;
  }, [userData]);

  const [selectedUserRoleTypeId, setSelectedUserRoleTypeId] = useState(defaultUserRoleTypeId);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const toast = useToast();

  // useEffect(() => {
  //   // Optimize by using a separate useEffect for userDetailsThunk
  //   dispatch(userDetailsThunk());
  // }, [dispatch]);
  const checkUserRoleId=userData?.accountDetails?.UserDetails?.E9UserRole;

  const handleUserRoleTypeClick = (userRoleTypeId) => {
    setSelectedUserRoleTypeId(userRoleTypeId);
    setShowConfirmation(true);
  };

  // const createUseRole=async(userRoleTypeId)=>{
  //   const payload={
  //     UserRoleTypeId:userRoleTypeId
  //   }
  //   const response=await createUserTypeRole(userData.token,payload);
  //   return response;
  // }
  
  // Create a mutation function
  const updateUserRole = async () => {
   const  createPayload={
      UserRoleTypeId:selectedUserRoleTypeId
    }
    const payload = {
      E9UserRole: {
        UserRoleTypeId: selectedUserRoleTypeId,
        E9UserRoleId: userData?.accountDetails?.UserDetails?.E9UserRole?.E9UserRoleId
      }
    };

    if(checkUserRoleId===null){
     const createUser=await createUserTypeRole(userData.token,createPayload).then((response)=>{

      
       if(response?.data?.Status===201){
        toast({
          status: "success",
          title: `${response?.data?.Message?.SuccessMessage}`,
          position: "top-right",
          isClosable: "true",
        });
      }
      else{
        toast({
          status: "success",
          title: `${response?.data?.Message?.FailMessage}`,
          position: "top-right",
          isClosable: "true",
        });
      }
      queryClient.resetQueries('userRolesList');
      setShowConfirmation(false);
     }).catch(()=>{
      setShowConfirmation(false);
     });
  queryClient.resetQueries('userRolesList');
    
    }
    else{
      const response = await getUpdateE9UserRole(payload, userData.token);
     if ( response.data.Status === 204) {
      toast({
        status: "success",
        title: `${response?.data?.Message?.SuccessMessage}`,
        position: "top-right",
        isClosable: "true",
      });

      // Reset the query after the update
      queryClient.resetQueries('userRolesList');
      setShowConfirmation(false);
    }

   
  }

   
   
  };

  // Create a mutation using useMutation
  const mutation = useMutation(updateUserRole);

  const { data: userRolesList, isLoading, isError } = useQuery(
    'userRolesList',
    () => GetAllUserRolesList()
  );

  if (isLoading) {
    return (<LoadingComponent />);
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  const userRoleTypeList = userRolesList.data.Data.UserRoleTypeList;


  return (
    <>
    <div>
      <SideMenuLayout>
        <DashboardNavBar />
        <MidSection>
          <HerodetailSection />
          <div className="business-expertise-container">
            {userRoleTypeList?.map((item, index) => (
              <div key={index} onClick={() => handleUserRoleTypeClick(item.UserRoleTypeId)}>
                <div className={`business-expertise-card ${selectedUserRoleTypeId === item.UserRoleTypeId ? 'red' : ''}`}>
                  <div className="business-expertise-card-section">
                  <div className='userRole-type-name'>{item.UserRoleTypeName}</div>

                  <div className='icon-selected'>
                    {userData.accountDetails?.UserDetails?.E9UserRole?.UserRoleTypeId === item.UserRoleTypeId ? (
                      <ReactSVG
                        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/SelectArrow.svg`}
                        alt="Your Image"
                        className="your-image-class"
                      />
                    ) : (
                      <>
                        
                      </>
                    )}

                  </div>
                  </div>
                  <div className='userRole-type-descrption'>Description: {item.Description}</div>

                </div>
              </div>
            ))}
          </div>
        </MidSection>
      </SideMenuLayout>
      {showConfirmation && (
        <div className="custom-modal">
          <div className="modal-content">
            <p className='conformation-text'>Are you sure you want to update the E9 user role?</p>
            <button className="modal-button" onClick={() => mutation.mutate()}>Yes</button>
            <button className="modal-button" onClick={() => setShowConfirmation(false)}>No</button>
          </div>
        </div>
      )}
    </div>
    </>
  );
};
