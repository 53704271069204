import {
  FormLabel,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/form-control";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { Flex } from "@chakra-ui/layout";
import { useField } from "formik";
import { Link } from "react-router-dom";
import { ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { Button } from "@chakra-ui/button";
import { getDefaultAppLanguage } from "../../features/global/mtData/helpers/localStorage";

export const FormikPasswordInput = ({
  label,
  hasForgetPassword,
  placeholder,
  isRequired,
  isInvalid,
  name,
  errorMessage = " ",
  maxLength,
}) => {
  const [field, meta] = useField(name);
  const [showPassword, setShowPassword] = useState(false);
  const defaultLanguage = getDefaultAppLanguage();
  return (
    <FormControl
      isInvalid={meta.touched && meta.error}
      isRequired={isRequired}
      maxW="374px"
    >
      <Flex justifyContent={hasForgetPassword ? "space-between" : ""}>
        <FormLabel>{label}</FormLabel>
        {hasForgetPassword && (
          <Link
            to="/forgot-password/generate-otp"
            style={{ fontWeight: "700", color: "#1c3677" }}
          >
            {defaultLanguage?.Forgot_Password}
          </Link>
        )}
      </Flex>
      <InputGroup>
        <Input
          type={showPassword ? "text" : "password"}
          placeholder={placeholder}
          isInvalid={isInvalid || (meta.touched && meta.error)}
          {...field}
          maxLength={maxLength}
          h="52px"
          border="1px solid #2c2c2c14"
          focusBorderColor="#2c2c2c"
          bgColor={field.value ? "#fff" : "#2c2c2c05"}
        />
        <InputRightElement marginTop="0.45em">
          <Button
            marginLeft="0.1em"
            variant="link"
            boxShadow="none"
            backgroundColor="transparent"
            onClick={() => {
              setShowPassword((prevState) => !prevState);
            }}
          >
            {showPassword ? <ViewOffIcon /> : <ViewIcon />}
          </Button>
        </InputRightElement>
      </InputGroup>

      <FormErrorMessage>
        {meta.touched && meta.error ? meta.error : errorMessage}
      </FormErrorMessage>
    </FormControl>
  );
};
