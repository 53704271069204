import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DashboardNavBar } from "../../../../components/DashboardNavBar/DashboardNavBar";
import { SideMenuLayout } from "../../../../layout/sideMenuLayout/SideMenuLayout";
import {
  maskMobileNumber,
  subString,
} from "../../../../helpers/dataConversion/substring";
import { NearMePartnerMarketplaceCard } from "../../../../components/NearMePartnerMarketplaceCard/NearMePartnerMarketplaceCard";
import { useNavigate } from "react-router-dom";
import { getInitialDataThunk } from "../../thunks/getInitialDataThunk";
import { MarketplaceHoc } from "../../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { getLoadMoreDataThunk } from "../../thunks/getLoadMoreDataThunk";
import { MarketplaceHeader } from "../../../../components/MarketplacHeader/MarketplaceHeader";
import { FilterPopUp } from "../../../marketplace/hoc/FilterPopUp/FilterPopUp";
import {
  resetFilters,
  setFilterByEquipmentType,
  setFilterByLicenseType,
  setFilterByVerificationStatus,
  setFilterModalOpen,
  setFilteredDataRequired,
} from "../../slice/operatorNearMeSlice";
import { getOperatorPartnerInitialFilteredDataThunk } from "../../thunks/getOperatorPartnerFIlteredInitialDataThunk";
import { MARKETPLACE_TYPES } from "../../../../constants/e9Constants";
import { getOperatorPartnerFilteredLoadMoreDataThunk } from "../../thunks/getOperatorPartnerFilteredLoadMoreDataThunk";
import { openQrModal } from "../../../dashboard/slice/dashboardSlice";
import { QrPopUp } from "../../../../components/ScanQrCodeModal/QrPopUp";
import { useClipboard } from "../../../../hooks/useClipboard";
import fetchHandler from "../../../../services/fetchHandler";
import { API_CONSTANTS } from "../../../../../constants/apiConstants";
import { Helmet } from "react-helmet";
import { encryptShareData } from "../../../../services/share/share";
import { useToast } from "@chakra-ui/react";
import { convertToBase64 } from "../../../../../utils/formatConverter/convertToBase64";

export const OperatorNeaMeMarketplace = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast=useToast();
  const {
    data,
    imageBaseUrl,
    initialLoading,
    paginationLoading,
    endOfData,
    filterModalOpen,
    filteredDataRequired,
    filterByVerificationStatus,
    filterByEquipmentType,
    filterByLicenseType,
  } = useSelector((state) => state.operatorPartnerNearMe);
  const {copyToClipboard} = useClipboard();



  const encryptPartnerDetails = async (partnerId) => {
    try {
      const payload = {
        marketplaceCategory: "workforce",
        marketplaceSubCategory: "op",
        assetId: partnerId,
      };

      //  const { data } = await fetchHandler(
      //    "/share/encryptAssetId",
      //    {},
      //    "",
      //    { MarketplaceType: "patners", SubCategory: "Operator Partner", AssetId: partnerId },
      //    API_CONSTANTS.HTT_POST,
      //    { isAuthRequired: false }
      //  )
      //  console.log(data.Data.Token)
      //  return data.Data.Token

      // const shareLink = await encryptShareData(payload);
      // await copyToClipboard(
      //   `${process.env.REACT_APP_WEBSITE_URL}share?id=${shareLink}&mode=share&utm=web`
      // );
      await copyToClipboard(
        `${process.env.REACT_APP_WEBSITE_URL}share?category=${"workforce"}&subCategory=${"op"}&id=${convertToBase64(partnerId)}`
      );

      toast({
        title: "Link Copied!",
        description: "Share link has been successfully copied to clipboard.",
        status: "success",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        description: "Failed to share. Please try again.",
        position: "top-right",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    filteredDataRequired
      ? dispatch(getOperatorPartnerInitialFilteredDataThunk())
      : dispatch(getInitialDataThunk());
  }, [filterModalOpen]);

  const filterFunction = (values) => {
    dispatch(setFilterByEquipmentType(values.equipmentType));
    dispatch(setFilterByVerificationStatus(values.verificationStatus));
    dispatch(setFilterByLicenseType(values.license));
    if (
      values.equipmentType ||
      values.country ||
      values.state ||
      values.verificationStatus ||
      values.license
    ) {
      dispatch(setFilteredDataRequired(true));
      dispatch(getOperatorPartnerInitialFilteredDataThunk());
    } else {
      dispatch(setFilteredDataRequired(false));
      dispatch(getInitialDataThunk());
    }
    dispatch(setFilterModalOpen(false));
  };

  const renderFunction = (array = []) => {
    const renderJsx =
      array && array.length > 0 ? (
        array.map((nearMeOperator) => (
          <NearMePartnerMarketplaceCard
            certificateIssuerName={
              nearMeOperator.CertificateIssuerName
                ? subString(25, nearMeOperator.CertificateIssuerName.toString())
                : "N.A."
            }
            equipmentTypeValue={
              nearMeOperator.EquipmentTypeValue
                ? subString(25, nearMeOperator.EquipmentTypeValue)
                : "N.A."
            }
            experienceMonth={nearMeOperator.ExperienceMonth}
            experienceYear={nearMeOperator.ExperienceYear}
            imageBaseUrl={imageBaseUrl}
            isdCode={nearMeOperator.IsdCode}
            mobileNumber={maskMobileNumber(nearMeOperator.MobileNumber)}
            name={nearMeOperator.PartnerName}
            profilePictureUrl={nearMeOperator.ProfilePictureFileReference}
            rating={nearMeOperator.OverAllRating}
            salary={nearMeOperator.MonthlySalary}
            verificationStatus={nearMeOperator.PartnerVerificationStatus}
            onClick={() => {
              navigate(
                `/near-me/MarketPlaceOP/Card/${nearMeOperator.UserCredentialId}`
              );
            }}
            onContact={() => dispatch(openQrModal())}
            onShare={async (e) => {
              e.stopPropagation(); 
              await encryptPartnerDetails(nearMeOperator?.UserCredentialId);
            }}
          />
        ))
      ) : (
        <p>No data to display</p>
      );
    return renderJsx;
  };
  return (
    <SideMenuLayout>
      
      <Helmet>
      <link
        rel="Operators Near Me page"
        href="https://www.equip9.com/near-me/MarketPlaceOP"
      />
      <meta name="keywords" content="jcb operators jcb operator contact number"/>
      <title>Find Skilled JCB Operators Near You - Equip9</title>
      <meta name="description" content="Connect with skilled JCB operators nearby through Equip9. 
      Find reliable professionals for your heavy equipment needs today!"/>
      <h1>Skilled JCB Operators Near You</h1>
      <h2>Why Choose Equip9 for JCB Operators?</h2>
      <h3>How Equip9 Connects You with Reliable JCB Operators</h3>
      </Helmet>

      <QrPopUp />
      <FilterPopUp
        marketplaceType={MARKETPLACE_TYPES.NEAR_ME_PARTNER_OPERATOR}
        isModalOpen={filterModalOpen}
        onResetFilterClick={() => {
          dispatch(resetFilters());
          dispatch(setFilterModalOpen(false));
        }}
        initialEquipmentValue={filterByEquipmentType}
        initialLicenseValue={filterByLicenseType}
        initialVerificationValue={filterByVerificationStatus}
        onSubmitButtonClick={filterFunction}
        onCloseButtonClick={() => {
          dispatch(setFilterModalOpen(false));
        }}
      />
      <DashboardNavBar />

      <MarketplaceHeader
        heading="Operator Partner Near Me"
        filterButtonSelected={filteredDataRequired}
        filterButtonClickAction={() => {
          dispatch(setFilterModalOpen(true));
        }}
      />
      <MarketplaceHoc
        marketplaceType="partner"
        dataArray={data}
        cardsRenderFunction={renderFunction}
        initialLoading={initialLoading}
        paginationLoading={paginationLoading}
        endOfData={endOfData}
        loadMoreAction={() => {
          filteredDataRequired
            ? dispatch(getOperatorPartnerFilteredLoadMoreDataThunk())
            : dispatch(getLoadMoreDataThunk());
        }}
      />
    </SideMenuLayout>
  );
};
