import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { NewSideMenuLayout } from "../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { RouteStepper } from "../../components/Stepper/Stepper";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { getMaterialStore } from "../../services/materials/materials";
import React, { useEffect } from "react";
import { convertFromBase64 } from "../../../utils/formatConverter/convertToBase64";
import { MaterialBasicDetails } from "./MaterialBasicDetails";
import { MaterialAdditionalDetails } from "./MaterialAdditionalDetails";
import { V5FormFooterLayout } from "../../layout/V5FormFooterLayout/V5FormFooterLayout";

export const PostMaterialsSteps = () => {
  const { stepperIndex, stepperRoute,isSubmitLoading } = useSelector(
    (state) => state.postMaterials
  );

  const navigate = useNavigate();
  const { materialTypeValue } = useParams();
  const MaterialTypeValue = convertFromBase64(materialTypeValue);

  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get("mode");
  const materialId = queryParams.get("materialId");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getMaterialStore();
        const foundItem = data.find(
          (item) => item.MaterialTypeValue === MaterialTypeValue
        );
        if (foundItem) {
        } else {
          navigate("/materials-type-store-list");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const renderForm = (index) => {
    switch (index) {
      case 0:
        return <MaterialBasicDetails mode={mode} materialid={materialId} />;

      case 1:
        return (
          <MaterialAdditionalDetails mode={mode} materialid={materialId} />
        );

      default:
        return <p>No Data Available</p>;
    }
  };

  const formId = (index) => {
    switch (index) {
      case 0:
        return "post-material-step-one";

      case 1:
        return "post-material-step-two";

      default:
        return <p>No Data</p>;
    }
  };
  return (
    // <NewSideMenuLayout gridTemplateRows="80px 1fr 72px">
    //   <div
    //     style={{
    //       margin: "0px 52px",
    //       backgroundColor: "#92929204",
    //       paddingTop: "1em",
    //     }}
    //   >
    //     <div style={{ marginBottom: "1em" }}>
    //       <Text fontSize={"24px"} fontWeight={700} color={"#141619"}>
    //         {MaterialTypeValue} Details
    //       </Text>
    //       {stepperIndex === 0 ? (
    //         <Text fontSize={"20px"} fontWeight={400} color={"#767676"}>
    //           {" "}
    //           Add your Material Details here
    //         </Text>
    //       ) : (
    //         <Text fontSize={"20px"} fontWeight={400} color={"#767676"}>
    //           {" "}
    //           Upload {MaterialTypeValue} Images and photos
    //         </Text>
    //       )}
    //     </div>
    //     <Flex flexDirection="column" gap="1em">
    //       <Box h="54px" dropShadow="0px 10px 30px #2C2C2C14" bgColor="#FFFFFF">
    //         <RouteStepper
    //           width="100%"
    //           array={stepperRoute}
    //           activeIndex={stepperIndex}
    //           padding="1em"
    //         />
    //       </Box>
    //       <Box maxH="360px" h="100%" overflowY="scroll" padding="1em 1.5em">
    //         {renderForm(stepperIndex)}
    //       </Box>
    //     </Flex>
    //   </div>

    //   <div
    //     style={{
    //       width: "100%",
    //       backgroundColor: "white",
    //       display: "flex",
    //       justifyContent: "center",
    //       alignItems: "center",
    //       maxHeight: "78px",
    //     }}
    //   >
    //     <Button
    //       type="submit"
    //       variant="gradient"
    //       w="347px"
    //       form={formId(stepperIndex)}
    //       isDisabled={isSubmitLoading}
    //     >
    //      {isSubmitLoading? <Spinner/>: "Next"}
    //     </Button>
    //   </div>
    // </NewSideMenuLayout>
    <V5FormFooterLayout
    title={`${MaterialTypeValue} Details`}
    subtitle={stepperIndex === 0  ? "Add your Material Details here" : `Upload ${MaterialTypeValue} Images and photos`}
    stepperArray={stepperRoute}
    stepperIndex={stepperIndex}
    primaryButtonText="Next"
    formId={formId(stepperIndex)}
    isLoading={isSubmitLoading}
    >
       {renderForm(stepperIndex)}
    </V5FormFooterLayout>
  );
};
