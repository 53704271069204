import React, { useEffect, useState } from 'react'
import { BlogBanner } from '../BlogBannner'
import { v4 as uuid } from 'uuid';
import axios from 'axios';

const BlogThird = () => {

    const [listData, setListData] = useState(false)
    const [expandedSections, setExpandedSections] = useState([]);
    const [blogData3,setBlogData] = useState();


    useEffect(()=>{
        const fetchData = async ()=>{
            const response = await axios.get("https://dev-static.equip9.com/Website/Blog/BlogJson/blogData3.json")
            if(response?.status === 200){
                setBlogData(response?.data?.blogData3)
            }
        }
        fetchData();
    },[])

    useEffect(() => {
        if(blogData3){
            setListData(blogData3[0]?.nestedList)
            const initialExpandedState = new Array(blogData3.length).fill(false);
            setExpandedSections(initialExpandedState);
        }

    }, [blogData3])

    const handleContainerClick = (id) => {

        setExpandedSections((prevExpanded) => {
            const index = blogData3.findIndex((entry) => entry.id === id);
            const newExpanded = [...prevExpanded];
            newExpanded[index] = !newExpanded[index];
            return newExpanded;
        });
    };
    return (
        <>

            {blogData3 ? blogData3.map((section, index) => (
                <div onClick={() => handleContainerClick(section.id)}>
                    <BlogBanner
                        key={section.id}
                        headingText={section.title}
                        highlightedText={section.subTitle}
                        description={section.content}
                        bannerImage={`${process.env.REACT_APP_CDN_STATIC_URL}Website/Blog/blogThree.png`}
                        isExpanded={expandedSections[index]}
                    />



                    <div className={`blog-container-wrapper ${expandedSections[index] ? 'expanded' : ''}`}>

                        {blogData3 && blogData3.map((data) => (
                            <div className="blog-section">
                                <h2 className='blog-header' style={{ color: "#F79C00" }}>{data?.secondTitle}</h2>
                                <p>
                                    {data?.secondContent}
                                </p>
                                <p>{data?.secondFooter}</p>
                            </div>
                        ))}

                        {blogData3 && blogData3.map((data) => (
                            <div className="blog-section">
                                <h2 className='blog-header' style={{ color: "#F79C00" }}>{data?.thirdTitle}</h2>
                                <p>{data?.thirdContent}</p>
                            </div>
                        ))}

                        {blogData3 && blogData3.map((data) => (
                            <div className="blog-section">
                                <h2 className='blog-header' style={{ color: "#F79C00" }}>{data?.fourthTitle}</h2>
                                <p>
                                    {data?.fourthContent}
                                </p>
                            </div>
                        ))}

                        {listData && listData.map((list) => (
                            <>
                                <div className="blog-section" key={uuid()}>
                                    <h2 className='blog-header' style={{ color: "#F79C00" }}>{list?.listTitle}</h2> <span>{list?.listSubTitle}</span>
                                    <ol className='blog-list-container'>
                                        {list.listPoints.map((listPoint) => (
                                            <li className='blog-list' key={uuid()}>
                                                <strong>{listPoint?.listHeader}</strong> <p className='blog-list-content'>
                                                    {listPoint?.listContent}
                                                </p>
                                            </li>
                                        ))}
                                    </ol>
                                </div>
                            </>
                        ))}

                        {blogData3 && blogData3.map((data) => (
                            <div className="blog-section">
                                <h2 className='blog-header' style={{ color: "#F79C00" }}>{data?.conclusionHeader}</h2>
                                <p>
                                    {data?.conclusionContent}
                                </p>

                                <p>{data?.conclusionSubContent}</p>
                            </div>
                        ))}

                        {blogData3 && blogData3.map((data) => (
                            <div className="blog-section" style={{ display: "flex", gap: "1em", justifyContent: "space-between" }}>
                                <div><h1 className='blog-list'>Author Name :</h1><p> {data.authorName}</p></div>
                                <div> <h1 className='blog-list'>Location :</h1><p>{data.location}</p></div>
                            </div>
                        ))}

                    </div>
                </div>

            )) : (
                null
            )}
        </>
    )
}

export default BlogThird