export const initialState = {
    data: null,
    requestReceivedData:null,
    requestReceivedLoading:null,
    connectsCountData:null,
    connectsCountLoading:false,
    connectsPendingData:null,
    connectsPendingLoading:false,
    likesCountData:null,
    likesCountLoading:false,
    initialLoading: false,
    error: null,
    errorMessage: null,
    paginationCount: 0,
    endOfData: false,
    paginationLoading: false,
    cardCount:12
  };