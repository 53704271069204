import React, { useState,useEffect } from "react";
import { SideMenuLayout } from "../../layout/sideMenuLayout/SideMenuLayout";
import { DashboardNavBar } from "../../components/DashboardNavBar/DashboardNavBar";
import { MidSection } from "../../../sharedComponent/midSection/MidSection";
import { MaterialStorePopup } from "../../components/MaterailStoreList/MaterialStorelist";
import { ShowStoreList } from "../../components/MaterailStoreList/ShowStorelist/ShowStorelist";
import { WelcomeStore } from "../../components/MaterailStoreList/MyBusinessStore/WelcomeStore/WelcomeStore";
import { GetMaterialType } from "../../../ApiEquip9/CommonApi";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, useQueryClient} from "react-query";
import { clearMaterialData, clearStepperIndex } from "../../components/MaterailStoreList/MyBusinessStore/AddMarketPlace/MarketPlaceSteps/slice/postMaterialSlice";  
import { useLocation ,useParams} from "react-router-dom";
import "./MaterialStorelist.css";
// import { useLocation,useParams } from "react-router-dom";


export const Materialstorelist = () => {
  const [isMaterialStorePopupOpen, setIsMaterialStorePopupOpen] =
    useState(false)
    const params=useParams();
    const loca=useLocation();
  const [isMaterailStoreWelcomePopOpen,setisMaterailStoreWelcomePopOpen]  =useState(false);
  const [isFirstLogin, setIsFirstLogin] = useState(false);

  const userData = useSelector((state) => state.user);

  const para=useParams();
 const chekckPopup=loca?.state?.Flag ? true :false

  // console.log({"para":loca.state.Flag})
  const MobileNumber= userData?.accountDetails?.UserDetails?.CredentialDetails?.MobileNumber
 

  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const handleOpenMaterialStorePopup = () => {
    setIsMaterialStorePopupOpen(true);
  };
  const maxLoginToShowWelcome = 5; 


  const { data: getMaterialList } = useQuery("getmateriallistt", () =>
  GetMaterialType(userData.token)
);




const location=useLocation()
// console.log("location",location);
// console.log("params",params)



const getMaterialength=getMaterialList?.data?.Data?.MaterialType



// const prevLocation = usePreviousLocation();
// const location = useLocation();

// Clear Stepper 
useEffect(()=>{
  dispatch(clearStepperIndex());
  dispatch(clearMaterialData());
},[]) 


useEffect(() => {
  if(chekckPopup===true){
    const userKey = MobileNumber;
      const loginCountKey = `loginCount_${userKey}`;
      const loginCount = parseInt(localStorage.getItem(loginCountKey) || '0');
  
      if (
          (userData?.UserTypeValue.toLowerCase() === 'Company'.toLowerCase() ||
              userData?.UserTypeValue.toLowerCase() === 'Individual'.toLowerCase()) &&
          loginCount < maxLoginToShowWelcome
      ) {
          setisMaterailStoreWelcomePopOpen(true);
          localStorage.setItem(loginCountKey, (loginCount + 1).toString());
      }

  }
}, [MobileNumber]); 



  return (
    <div>
      <SideMenuLayout>
        <DashboardNavBar />
        <MidSection>
          <div className="Material-store-layout">
            <p className="Material-store-headline">Material Store List</p>
            <div className="add-storelist-layout">
              <p className="Material-store-subheading">
                Add your material and list them on marketplace
              </p>
              <div className="Add-button" 
                 onClick={()=>{
                  setIsMaterialStorePopupOpen(true)
                 }}>
                <p
                  className="Add-text"
                >
                  + Add
                </p>
              </div>
            </div>
          </div>
          <div>
            <ShowStoreList />
          </div>
        </MidSection>
        <MaterialStorePopup
          isLogoutModalOpenPopUp={isMaterialStorePopupOpen}
          setIsLogoutModalOpenPopUp={setIsMaterialStorePopupOpen}
        />
        <WelcomeStore
        isLogoutModalWelcomeOpenPopUp={isMaterailStoreWelcomePopOpen}
        setIsLogoutModalWelcomeOpenPopUp={setisMaterailStoreWelcomePopOpen}
        onOpenMaterialStorePopup={handleOpenMaterialStorePopup}
        />
      </SideMenuLayout>
    </div>
  );
};
