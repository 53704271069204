import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFilteredPartners } from "../../../services/jobsPosted/jobsPosted";

export const getInitialFilteredDataThunk = createAsyncThunk("mp/initialFilteredData",async(_, {getState}) => {
    const selectedCountry = getState().maintenancePartnerNearMe.filterByCountry;
    const selectedState = getState().maintenancePartnerNearMe.filterByState;
    const selectedVerificationStatus = getState().maintenancePartnerNearMe.filterByVerificationStatus;
    const selectedEquipmentType = getState().maintenancePartnerNearMe.filterByEquipmentType;
    const cardCount  = getState().maintenancePartnerNearMe.cardCount;

    let query = ""

    if(selectedCountry){
        query += `&FilterByLocationCountry=${selectedCountry}`
    }
    if(selectedState){
        query+= `&FilterByLocationState=${selectedState}`
    }
   
    if(selectedEquipmentType){
        query+= `&FilterByEquipmentType=${selectedEquipmentType}`
    }

    try{
      
        const data= await getFilteredPartners(cardCount,0,`?FilterType=Maintenance${query}`);

        if(data.Status === 200){
           if(data.Data.TotalRecords > 0){
            return data.Data.GetFilteredAllTopMpMaintenancePartners;
           }
           else{
            return []
           }
        }
    }
    catch(error){
    }

    
})