import axios from "axios";
import { API_CONSTANTS } from "../../../constants/apiConstants";
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData";
import fetchHandler from "../fetchHandler";


export const getMaterialType = async () => {
    try {
        const {data} = await fetchHandler(API_CONSTANTS.GET_MATERIAL_TYPE);

        if(data.Status === 200 && Object.keys(data.Data.MaterialType).length > 0){
            return data.Data.MaterialType ;
        }
        return null ;
    } catch (error) {
        throw error ;
    }
}

// UEM 
export const getMyOrdersRequestSentList = async (id) => {
    try {
        const {data} = await fetchHandler(`${API_CONSTANTS.GET_MY_ORDERS_REQUEST_SENT_LIST}?MarketPlaceTypeValue=${id}`);
        if(data?.Status === 200 && Object.keys(data?.Data !== undefined)) {
            return data.Data ;
        }
    } catch (error) {
        
    }
}

export const getMyOrdersRequestReceivedList = async (id) => {
    try {
        const {data} = await fetchHandler(`${API_CONSTANTS.GET_MY_ORDERS_REQUEST_RECEIVED_LIST}?MarketPlaceTypeValue=${id}`);
        if(data?.Status === 200 && Object.keys(data?.Data !== undefined)) {
            return data.Data ;
        }
    } catch (error) {
        
    }
}

export const getTransactionModeDetails = async () => {
    try {
        const {data} = await fetchHandler(API_CONSTANTS.GET_TRANSACTION_MODE_DETAILS)
        return data.Data
    } catch (error) {
        
    }
}

// export const updateE9MarketplaceContactRedeemedDetails = async (payload) => {
//     try {
//         const {data} = await fetchHandler(`${API_CONSTANTS.UPDATE_E9_MARKETPLACE_CONTACT_REDEEMED_DETAILS}${payload}`)
//         return data.Data
//     } catch (error) {
        
//     }
// }
export const updateE9MarketplaceContactRedeemedDetails = async (body) => {
    try {
      const { data } = await fetchHandler(
        API_CONSTANTS.UPDATE_E9_MARKETPLACE_CONTACT_REDEEMED_DETAILS,
        {},
        {},
        body,
        API_CONSTANTS.HTTP_PUT
      );
        return data ;
    } catch (error) {
    }
  };

export const updateAssetContactRequest = async (payload) => {
    try {
      const {data}=await fetchHandler(API_CONSTANTS.REVOKE_CONTACT,{},{},payload,API_CONSTANTS.HTTP_PATCH)
      return data;
    } catch (error) {
      throw error.response;
    }
  };

  export const getContactRequestReceivedListForE9MarketPlaceAsset = async (id) => {
    try {
        const {data} = await fetchHandler(`${API_CONSTANTS.GET_CONTACT_REQUEST_RECEIVED_LIST}?E9MarketPlaceId=${id}`);
        if(data?.Status === 200 && Object.keys(data?.Data !== undefined)) {
            return data.Data ;
        }
    } catch (error) {
        
    }
}
  export const getRentalDurationByE9MarketPlaceId = async (id) => {
    try {
        const {data} = await fetchHandler(`${API_CONSTANTS.GET_RENTAL_DURATION}/${id}`);
        return data;
        // if(data?.Status === 200 && Object.keys(data?.Data !== undefined)) {
        //     return data.Data ;
        // }
    } catch (error) {
        
    }
}

export const getEquipmentAvailability = async () =>{
    try {
        const {data} = await fetchHandler("/GetEquipmentAvailability");
        if(data?.Status === 200){
          return data?.Data?.EquipmentAvailabilityData ;
        }
    } catch (error) {
        
    }
}

// Update Accept Reject 
  export const updateAcceptReject = async (body) => {
    try {
      const { data } = await fetchHandler(
        API_CONSTANTS.UPDATE_ACCEPT_REJECT_STATUS,
        {},
        {},
        body,
        API_CONSTANTS.HTTP_PUT
      );
        return data ;
    } catch (error) {
    }
  };
  export const updateReedemAcceptReject = async (body) => {
    try {
      const { data } = await fetchHandler(
        API_CONSTANTS.UPDATE_ACCEPT_REJECT_REEDEM_STATUS,
        {},
        {},
        body,
        API_CONSTANTS.HTTP_PUT
      );
        return data ;
    } catch (error) {
    }
  };

export const getMyLeadDsConnectRequestSentList = async () =>{
    const {data} =await fetchHandler(API_CONSTANTS.GET_MYLEADS_DS_CONNECT_REQUEST_SENT_LIST);
    if(data?.Status === 200){
        return data?.Data?.E9DsMyLeadsSentList;
    }
    return data
}
export const getMyLeadDsConnectRequestReceivedList = async () =>{
    const {data} =await fetchHandler(API_CONSTANTS.GET_MYLEADS_DS_CONNECT_REQUEST_RECEIVED_LIST);
    if(data?.Status === 200){
        return data?.Data?.E9DsMyLeadsReceivedList
        ;
    }
}

export const getMyLeadsDsConnectReceivedList = async (id) =>{
    const {data} = await fetchHandler(`${API_CONSTANTS.GET_MYLEADS_DS_CONNECT_RECEIVED_LIST}${id}`)
    if(data?.Status === 200){
        return data?.Data?.MyLeadDsConnectReceivedList;
    }
    return null ;
}