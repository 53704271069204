import React, { useState } from "react";
import { SideMenuLayout } from "../../../../../../../../layout/sideMenuLayout/SideMenuLayout";
import { DashboardNavBar } from "../../../../../../../../components/DashboardNavBar/DashboardNavBar";
import { MidSection } from "../../../../../../../../../sharedComponent/midSection/MidSection";
import InviteTeam from "./InviteTeam/InviteTeam";
import "./ComapnyMemberDetails.css";
import { useSelector } from "react-redux";
import {InvitedMemberList} from './InviteMemberList/InviteMemberList'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons'
export const CompanyMemberDetails = () => {
  const userData = useSelector((state) => state.user);
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  
  return (
    <>
      <SideMenuLayout>
        <DashboardNavBar />
        <MidSection>
          <div className="ProfileCompany">
          <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
  <BreadcrumbItem>
    <BreadcrumbLink href='/my-profile'>Profile</BreadcrumbLink>
  </BreadcrumbItem>

  <BreadcrumbItem>
    <BreadcrumbLink href='/company-profile'>Company Details</BreadcrumbLink>
  </BreadcrumbItem>

  <BreadcrumbItem isCurrentPage>
    <BreadcrumbLink href='#'>Company Member Details</BreadcrumbLink>
  </BreadcrumbItem>
</Breadcrumb>
          </div>
          <div className="companyMemberDetails">
            <div>
              <p className="Company-member-hero">Company Member Details</p>
              <p className="Company-member-subheading">Team Member Summary</p>
            </div>
            <div>
              <button className="button-invite-team" onClick={toggleVisibility}>
                <p className="Invite-Team">+ Invite Team</p>
              </button>
            </div>
           
          </div>
          <InvitedMemberList />
        </MidSection>
        {isVisible && <InviteTeam onClose={toggleVisibility} />}
      </SideMenuLayout>
    </>
  );
};;
