export const initialState = {
  data: null,
  userImageReference: null,
  assetImageReference: null,
  filterIconImageReference: null,
  equipmentTypeFilterList: null,
  paginationCount: 0,
  filterModalOpen: false,
  filteredDataRequired: false,
  filterByCountry: "",
  filterByState: "",
  filterByVerificationStatus: "",
  filterByEquipmentType: "",
  endOfData: false,
  cardCount: 12,
  initialLoading: false,
  paginationLoading: false,
  equipmentFilterListLoading: false,
  error: null,
  errorMessage: null,
  isOpenRentalDurationModal:false
};
