import { Button } from '@chakra-ui/react';
import { Box } from '@chakra-ui/layout';

export const SelectCardWrapper = ({
  isSelected = false,
  width = '',
  height = '',
  padding = '20px',
  margin = '0px',
  children,
  onClick,
  icon = null,
  isActiveIcon = false,
  flexdirection = '',
  display = '',
  margintop = '',
  backgroundcolor = '',
  border = "",
  value = '',
  boxShadow = '',
  fontWeight = '',
  fontSize = '',
  maxHeight = '',
  maxWidth = '',
  minHeight = "",
  minWidth = "",
  justifyContent = "",
  alignItems = "",
  gap = " "
}) => {
  return (
    <Box
      as="button"
      type='button'
      onClick={onClick}
      value={value}
      maxW={maxWidth}
      minW={minWidth}
      width={width}
      maxH={maxHeight}
      minH={minHeight}
      height={height}
      padding={padding}
      paddingLeft={icon ? "9%" : ""}
      margin={margin}
      marginTop={icon ? "40px" : margintop ? margintop : "0px"}
      flexDirection={flexdirection}
      display={display}
      justifyContent={justifyContent}
      alignItems={alignItems}
      gap={gap}
      backgroundColor={isSelected ? "#ffff" : backgroundcolor ? `${backgroundcolor}` : "white"}
      border={isSelected ? "1px solid #f79c00" : border ? border  : "1px solid #E3E5E8"}
      borderRadius="4px"
      boxShadow={boxShadow}
      fontWeight={fontWeight}
      fontSize={fontSize}
      position={icon ? "relative" : ""}
      cursor="pointer"
      _selected={{
        color: '#f79c00',
        backgroundColor: '#ffffff',
        borderColor: '#f79c00',
      }}

      _focus={{ outline: 'none' }}

      className={isSelected ? 'selected' : ''}
    >
      {icon && (
        <Box
          // maxH={{ base: "40px", md: "50px", lg: "60px" }} // Responsive max height
          // maxW={{ base: "40px", md: "50px", lg: "60px" }} // Responsive max width
          maxH="60px"
          maxW="60px"
          height="100%"
          width="100%"
          border={isSelected ? "1px solid #f79c00":"1px solid #E3E5E8"}
          backgroundColor="#FAFAFA"
          borderRadius="4px"
          position="absolute"
          top="-25px"  // Responsive top position
          left="10px" // Responsive left position
          display="flex"
          alignItems="center"
          justifyContent="center"
          boxShadow="1px 1px 3px #2c2c2c2c"
          // mx={{ base: "1em", sm: "2em", md: "3em", lg: "4em" }}
          
        >
          {icon}
        </Box>
      )}
      {children}
    </Box>
  );
};