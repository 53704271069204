import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPurchasedLeadsById } from "../../../services/leadCheck/leadCheck";

export const getAssetListingLeadCheckThunk = createAsyncThunk("/leadCheck/assetLising", async(_, {rejectWithValue}) => {
    try{
      const data = await getPurchasedLeadsById(3);
      return data
    }
    catch(error){
       rejectWithValue(error.message)
      throw error
    }
})