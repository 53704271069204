import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCommunityPost } from "../../../services/community/community";

export const communityLoadMorePostThunk = createAsyncThunk(
  "community/getLoadMoreCards",
  async (_, {getState}) => {
    const {cardCount , paginationCount} = getState().community

    try {
      const data = await getCommunityPost(cardCount,(paginationCount * cardCount));
      return data;
    } catch (error) {
      throw error;
    }
  }
);
