import { API_CONSTANTS } from "../../../constants/apiConstants";
import {fetchHandler} from "../../../index"
import { convertToBase64 } from "../../../utils/formatConverter/convertToBase64";
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData";

export const getMaterialTypes=async ()=>{
    try {

        const {data}=await fetchHandler("/GetMaterialType");
        if(data.Status === 200 && Object.keys(data.Data).length> 0 ){
            const requiredData=data?.Data?.MaterialType;
            return requiredData;
        }
        
    } catch (error) {
        throw error;
    }
}

export const getMaterialStore= async ()=>{

    try {

        const {data}=await fetchHandler("/GetMaterialStore");
        if (data.Status === 200 && Object.keys(data.Data).length> 0){
            const requiredData=data?.Data?.MaterialStore;
            return requiredData;
        }else{
            throw new Error("Failed to fetch Material Store data");
        }

    } catch (error) {
        throw error;
    }

}

export const createMaterialStore=async (value)=>{
    try {
        const {data} = await fetchHandler("/CreateMaterialStore", {}, {}, convertToFormData({MaterialTypeId: value}), API_CONSTANTS.HTT_POST)
        return data;

    } catch (error) {
        throw error;
    }
}

export const getMaterialListById=async (materialTypeId)=>{
    try {
        const {data} = await fetchHandler(`/GetMaterialList/12/0`,{
            MaterialTypeId: materialTypeId,
        })
        if (data.Status === 200 && Object.keys(data.Data).length> 0){
            return data;
        }else{
            throw new Error("Failed to fetch Material List");
        }
    } catch (error) {
        throw error;
    }
}

export const postMaterialDetails=async (payload)=>{
  try {
    const {data} = await fetchHandler(`/PostMaterialsStep1`,{}, {}, convertToFormData(payload), API_CONSTANTS.HTT_POST)
    return data;
  } catch (error) {
    throw error;
  }
}

export const getMaterialDataForUpdateStep2ById=async(materialId)=>{
    try {
        const {data}=await fetchHandler(`/GetMaterialDataForUpdateStep2`,{
            MaterialId:materialId
        },{},{},API_CONSTANTS.HTTP_GET);
        return data;
    } catch (error) {
        throw error;
    }
}

export const postMaterialDetails2=async (payload)=>{
    try {
        const {data}= await fetchHandler("/PostMaterialsStep2",{}, {}, convertToFormData(payload), API_CONSTANTS.HTT_POST);
        return data;
    } catch (error) {
        throw error;
    }
}

export const getPhotoTypes=async ()=>{
    try {
        const {data}=await fetchHandler(`/${API_CONSTANTS.GET_PHOTO_TYPE}`,{},{},{},API_CONSTANTS.HTTP_GET);
        return data;
    } catch (error) {
        throw error;
    }
}

export const getVideotypes=async ()=>{
    try {
        const {data}=await fetchHandler(`/${API_CONSTANTS.GET_VIDEO_TYPE}`,{},{},{},API_CONSTANTS.HTTP_GET);
        return data;
    } catch (error) {
        throw error;
    }
}

export const postMaterialImages=async (payload)=>{
    try {
        const {data}=await fetchHandler(`/PostMaterialImages`,{},{},convertToFormData(payload),API_CONSTANTS.HTT_POST);
        return data;
    } catch (error) {
        throw error;
    }
}

export const postMaterialVideos=async (payload)=>{
    try {
        const {data}=await fetchHandler(`/PostMaterialVideos`,{},{},convertToFormData(payload),API_CONSTANTS.HTT_POST);
        return data;
    } catch (error) {
        throw error;
    }
}

export const deleteMaterialImage=async (materialId,imageTypeId)=>{
    try {
        const {data}=await fetchHandler(`/DeleteMaterialImages/${convertToBase64(materialId)}/${imageTypeId}`,{},{},{},API_CONSTANTS.HTTP_DELETE);
        return data;
    } catch (error) {
        throw error;
    }
}

export const deleteMaterialVideo=async (materialId,videoTypeId)=>{
    try {
        const {data}=await fetchHandler(`/DeleteMaterialVideos/${convertToBase64(materialId)}/${videoTypeId}`,{},{},{},API_CONSTANTS.HTTP_DELETE);
        return data;
    } catch (error) {
        throw error;
    }
}

export const getMaterialRequestReceivedCountById=async (materialTypeId)=>{
    try {

        const {data}=await fetchHandler(`/GetMaterialRequestReceivedCount`,{
            MaterialTypeId:materialTypeId
        },{},{},API_CONSTANTS.HTTP_GET);

        return data;
        
    } catch (error) {
        throw error;
    }

}

export const getMaterialConnectsCountById=async (materialTypeId)=>{
    try {

        const {data}=await fetchHandler(`/GetMaterialConnectsCount`,{
            MaterialTypeId:materialTypeId
        },{},{},API_CONSTANTS.HTTP_GET);

        return data;
        
    } catch (error) {
        throw error;
    }
}

export const getMaterialConnectsPendingCountById=async (materialTypeId)=>{
    try {

        const {data}=await fetchHandler(`/GetMaterialConnectsPendingCount`,{
            MaterialTypeId:materialTypeId
        },{},{},API_CONSTANTS.HTTP_GET);

        return data;
        
    } catch (error) {
        throw error;
    }
}

export const getMaterialLikesCountById=async (materialTypeId)=>{
    try {

        const {data}=await fetchHandler(`/GetMaterialLikesCount`,{
            MaterialTypeId:materialTypeId
        },{},{},API_CONSTANTS.HTTP_GET);

        return data;
        
    } catch (error) {
        throw error;
    }
}

export const deleteMaterialDetails = async (id) => {
    try {
        const {data}=await fetchHandler(`/DeleteMaterial/${id}`,{
        },{},{},API_CONSTANTS.HTTP_DELETE);
        return data;
    
    } catch (error) {
      throw error;
    }
  };

export const getMaterialBasicDetails = async (MaterialTypeId) => {
    try {

        const {data}=await fetchHandler(`/GetMaterialDataForUpdateStep1?MaterialId=${MaterialTypeId}`,{
        },{},{},API_CONSTANTS.HTTP_GET);
        return data;
    }
    catch (error) {
      throw error.response;
    }
  }
  
export const getMaterialAdditionalDetails = async (MaterialTypeId) => {
    try {
        const {data}=await fetchHandler(`/GetMaterialDataForUpdateStep2`,{
            MaterialId : MaterialTypeId
        },{},{},API_CONSTANTS.HTTP_GET);
        return data;
  
    }
    catch (error) {
      throw error.response;
    }
  }

  export const getMaterialDataForUpdateStep1byId=async (materialId)=>{
try {

    const {data}=await fetchHandler("/GetMaterialDataForUpdateStep1",{
        MaterialId:materialId
    },{},{},API_CONSTANTS.HTTP_GET);
    return data;
    
} catch (error) {
    throw error;
}
  }


  export const updateMaterialStep1=async (payload)=>{
    try {
      const {data} = await fetchHandler(`/UpdateMaterialStep1`,{}, {}, payload, API_CONSTANTS.HTTP_PUT)
      return data;
    } catch (error) {
      throw error;
    }
  }

  export const updateMaterialStep2=async (payload)=>{
    try {
      const {data} = await fetchHandler(`/UpdateMaterialStep2`,{}, {}, payload, API_CONSTANTS.HTTP_PUT)
      return data;
    } catch (error) {
      throw error;
    }
  }



export const materialFreeReedemTransaction = async(body) =>{
    try {
      const { data } = await fetchHandler(
        API_CONSTANTS.CREATE_E9_FREE_REDEEMED_TRANSACTION,
        {},
        {},
        body,
        API_CONSTANTS.HTT_POST
      );
        return data ;
    } catch (error) {
    }
  }

  export const postMaterialMarketplace = async(payload) =>{
    try {
      const {data} = await fetchHandler("/PostMaterialMarketplace",
        {},
        {},
        payload,
        API_CONSTANTS.HTT_POST
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  export const materialSubscriptionReedemTransaction = async(body) =>{
    try {
      const data = await fetchHandler(
        API_CONSTANTS.CREATE_E9_SUBSCRIPTION_REDEEMED_TRANSACTION,
        {},
        {},
        body,
        API_CONSTANTS.HTT_POST
      );
        return data ;
    } catch (error) {
        throw error;

    }
  }

  export const removeMaterialFromMarketplace = async (materialTypeId,materialId) =>{
    try {
     const {data} = await fetchHandler(
       `/DeleteMaterialMarketplace/${convertToBase64(materialTypeId)}/${convertToBase64(materialId)}`,
       {},
       {},
       {},
       API_CONSTANTS.HTTP_DELETE
       
     );
     return data
    } catch (error) {
     
    }
   }

   export const getMaterialLikeList = async (materialTypeId) =>{
    const {data} = await fetchHandler("/GetMaterialLikesByUserCredential",{
        MaterialTypeId:materialTypeId
    },{},{},API_CONSTANTS.HTTP_GET)
    if(data?.Status === 200){
        return data;
    }
    return null;
    }    
    
    export const postMaterialLikes =async (body)=>{
        const {data}=await fetchHandler("/PostMaterialLikes",{},{},convertToFormData(body),API_CONSTANTS.HTT_POST)
        return data;
    }

    const formatContactSellerPayload = (body) => {
        const response = {
            payload: body,
            endPoint: "/UpdateMaterialContactDetails"
        }
        switch(body.ResponseTypeId) {
            case null:
                response.payload.ResponseTypeId = 1;
                response.endPoint = "/PostMaterialContactDetails";  
                break;
            case 4:
                response.payload.ResponseTypeId = 1;
                break;
            
            case 1:
                response.payload.ResponseTypeId = 4;
                break;
        
        }
        return response;
    }

    export const contactSeller =async (body)=>{
        const {endPoint, payload} = formatContactSellerPayload(body)
        const {data} =await fetchHandler(endPoint,{},{},convertToFormData(payload),API_CONSTANTS.HTT_POST)
        return { data, payload};
    }

    export const PostMaterialContactDetails=async (body)=>{
        const {data}=await fetchHandler("/PostMaterialContactDetails",{},{},convertToFormData(body),API_CONSTANTS.HTT_POST)
        return data;
    }

    export const UpdateMaterialContactDetails=async (body)=>{
        const {data}=await fetchHandler("/UpdateMaterialContactDetails",{},{},convertToFormData(body),API_CONSTANTS.HTT_POST)
        return data;
    }

    export const UpdateAcceptRejectContactDetails=async (body)=>{
        const {data}=await fetchHandler("/UpdateAcceptRejectContactDetails",{},{},convertToFormData(body),API_CONSTANTS.HTT_POST)
        return data;
    }
    
    export const GetReceivedRequestDetailsByMaterialId=async (materialId)=>{
        const {data}=await fetchHandler("/GetReceivedRequestDetailsByMaterialId",{
            MaterialId:materialId
        },{},{},API_CONSTANTS.HTTP_GET)
        return data;
    }
    
    export const GetSentRequestDetails=async (materialTypeId)=>{
        const {data}=await fetchHandler("/GetSentRequestDetails",{
            MaterialTypeId:materialTypeId
        },{},{},API_CONSTANTS.HTTP_GET)
        return data;
    }

    export const GetReceivedRequestDetails=async (materialTypeId)=>{
        const {data}=await fetchHandler("/GetReceivedRequestDetails",{
            MaterialTypeId:materialTypeId
        },{},{},API_CONSTANTS.HTTP_GET)
        return data;
    }


    export const updateMaterialImges = async (payload) => {
        try {
     
        const {data} = await fetchHandler("/UpdateMaterialImages",{},{},convertToFormData(payload),API_CONSTANTS.HTT_POST);
        return data;
        }
        catch (err) {
          throw err.response;
        }
      }

      export const getAllMarketPlaceServices = async () => {
        try {
     
        const {data} = await fetchHandler(API_CONSTANTS.GET_ALL_MARKETPLACE_SERVICES);
        return data;
        }
        catch (err) {
          throw err.response;
        }
      }
    
      export const getMaterialMarketplaceList = async (cardCount,offset,materialTypeId) => {
        try {
     
        const {data}= await fetchHandler(`/GetMaterialMarketplaceList/${cardCount}/${offset}`,{
                MaterialTypeId:materialTypeId
            })
        return data;
        }
        catch (err) {
          throw err.response;
        }
      }

      export const getFilterMaterialMarketplaceList = async (cardCount,offset,query) => {
        try {
     
        const {data}= await fetchHandler(`${API_CONSTANTS.GET_FILTER_MATERIAL_MARKETPLACELIST}/${cardCount}/${offset}?${query}`)
        return data;
        }
        catch (err) {
          throw err.response;
        }
      }

      
      export const getMaterialDetails = async (MaterialId) => {
        try {
     
        const {data}= await fetchHandler(`${API_CONSTANTS.GET_MATERIAL_DETAILS_LIST}/?MaterialId=${MaterialId}`)
        return data;
        }
        catch (err) {
          throw err.response;
        }
      }


      export const getResponseTypeDetails = async () => {
        try {
          const { data } = await fetchHandler(
            API_CONSTANTS.GET_RESPONSE_TYPE_DETAILS
          );
          return data.Data;
        } catch (error) {}
      };


      export const postMaterialTransaction = async (body) => {
        try {
          const { data } = await fetchHandler(
            API_CONSTANTS.POST_MATERIAL_TRANSACTION,
            {},
            {},
            convertToFormData(body),
            API_CONSTANTS.HTT_POST
          );
          return data;
        } catch (error) {
          throw error;
        }
      };

      export const updateMaterialTransaction = async (body) => {
        try {
          const { data } = await fetchHandler(
            API_CONSTANTS.UPDATE_MATERIAL_TRANSACTION,
            {},
            {},
            convertToFormData(body),
            API_CONSTANTS.HTT_POST
          );
          return data;
        } catch (error) {
          throw error;
        }
      };



