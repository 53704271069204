import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchHandler } from "../../../..";
import { API_CONSTANTS } from "../../../../constants/apiConstants";


export const mpJobLoadMoreDeferred = createAsyncThunk("deferred/mpJobLoadMore",async(_,{getState}) =>{

    try {

        const {category,subCategory,cardCount,paginationCount} = getState().mpJobDeferred;
        const {data} = await fetchHandler(
            "/deferredLogin/marketplace",
            { category : category,subcategory : subCategory,limit : cardCount,offset : cardCount * paginationCount  },
            "",
            "",
            API_CONSTANTS.HTTP_GET,
            { isAuthRequired: false }
        )
        if(data?.Status === 200){

            return data?.Data;
        }
        return null ;
    } catch (error) {
        console.log("Load More Error",error);
        
    }
})