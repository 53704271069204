import { useState } from "react";
import { NewSideMenuLayout } from "../../../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "@chakra-ui/react";
import fetchHandler from "../../../../services/fetchHandler";
import { V5SidebarLayout } from "../../../../layout/V5SidebarLayout/V5SidebarLayout";

export const TransactionHistory = () => {
  const [timeZone, setTimeZone] = useState(null);
  const { token, UserTypeValue, accountDetails } = useSelector(
    (state) => state.user
  );
  const [transactionHistory, setTransactionHistory] = useState(null);

  useEffect(() => {
    getTimeZone();
  }, []);

  useEffect(() => {
    if (timeZone) {
      (async () => {
        await getSubscriptionHistory();
      })();
    }
  }, [timeZone]);

  const getTimeZone = () => {
    const date = new Date();
    const offset = date.getTimezoneOffset();

    if (offset < 0) {
      const positiveOffset = -offset;
      const cal = Math.floor(positiveOffset / 60) + ":" + (positiveOffset % 60);
      return setTimeZone(cal);
    } else {
      const cal = Math.floor(offset / 60) + ":" + (offset % 60);
      const positiveCal = -cal;
      return setTimeZone(positiveCal);
    }
  };

  const getSubscriptionHistory = async () => {
    let endpoint =
      UserTypeValue.toLowerCase() === "Company".toLowerCase()
        ? `/GetCompanySubsTransHistory/12/0?TaxNumber=${btoa(
            accountDetails.CompanyDetails.TaxNumber
          )}`
        : `/GetUserSubscriptionTransactionHistory/12/0`;
    const headers = new Headers();
    // headers.append("token", token);
    try {
      const {data} = await fetchHandler(
        `${endpoint}`,
        {},
        {},
        {},
      );

      if (data.Status === 200 && !data.Message.FailMessage) {
        setTransactionHistory(data.Data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <V5SidebarLayout>
      <div
        style={{
          margin: "0px 52px",
          backgroundColor: "#92929204",
          paddingTop: "1em",
          maxHeight: "calc(100vh - 230px)",
          overflowY: "scroll"
        }}
      >
        <div style={{ marginBottom: "32px" }}>
          <h1 style={{ fontSize: "32px", fontWeight: 700 }}>
            Subscription History
          </h1>
          <p style={{ fontSize: "22px", color: "#2c2c2c80" }}>
            Manage your Transactions here ....
          </p>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto auto",
            gap: "1em",
          }}
        >
          {transactionHistory ? (
            transactionHistory.map((transaction) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "0.25em",
                  padding: "1em 0.75em",
                  maxWidth: "450px",
                  minHeight: "170px",
                  maxHeight: "170px",
                  justifyContent: "space-between",
                  border: "1px solid #2c2c2c60",
                  boxShadow: "0px 2px 4px 1px #2c2c2c40",
                }}
              >
                <p style={{ color: "#2c2c2c95" }}>
                  <span style={{ fontWeight: 600, color: "#2c2c2c" }}>
                    Date:
                  </span>{" "}
                  {transaction.TransactionDateTime}
                </p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p style={{ fontWeight: 600 }}>OrderId:</p>
                    <p style={{ color: "#2c2c2c95" }}>
                      {transaction.TransactionOrderId}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p style={{ fontWeight: 600 }}>Transaction Id:</p>
                    <p style={{ color: "#2c2c2c95" }}>
                      {transaction.E9TransactionId}
                    </p>
                  </div>
                </div>
                <Button
                  width="100%"
                  margin="0 auto"
                  maxW="347px"
                  bgColor="#1E3264"
                  _hover={{ bgColor: "#1E326490" }}
                  color="#FFFFFF"
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_CDN_PVT_URL}${transaction.InvoiceFilePath}${transaction.InvoiceFileRef}`,
                      "_blank"
                    );
                  }}
                  // onClick={() => {
                  //   throw new Error("New test error")
                  // }}
                >
                  Download Invoice
                </Button>
              </div>
            ))
          ) : (
            <p>No data available!</p>
          )}
        </div>
      </div>
    </V5SidebarLayout>
  );
};
