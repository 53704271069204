import { Box, Image } from "@chakra-ui/react";

export const DashboardButton = ({ onClick, imageUrl, buttonText }) => {
  return (
    <Box
      maxW="16.85rem"
      minW="12.5rem"
      w="100%"
      height="6.25rem"
      padding="1.2rem"
      bgColor="#FFFBF5"
      color="#484848"
      display="flex"
      gap="1rem"
      alignItems="center"
      fontSize="1.25rem"
      lineHeight="1.5"
      cursor="pointer"
      borderRadius="0.5rem"
      fontWeight={600}
      border="1px solid #F79C00"
      transition= "all 0.15s ease-in-out"
      _hover={{
        color: "#F79C00",
        fontWeight: "700",
        fontSize:"22px",
        transition:"all 0.15s ease-in-out"
      }}
      onClick={onClick}
    >
      <Box
        w="5rem"
        h="5rem"
        // bgColor="#FFFFFF"
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="0.25rem"
      >
        <Image src={imageUrl} h="2.75rem" w="2.75rem" />
      </Box>
      <p>{buttonText}</p>
    </Box>
  );
};
