import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserSubscriptionDetails } from "../../../services/subscription/subscription";


export const getUserSubscriptionDetailsThunk = createAsyncThunk("/subscriptions/getUserSubscriptionDetailsThunk", async(_, {getState, rejectWithValue}) => {
    const {orderId} = getState().subscription
   
    try{
       
        const data = await getUserSubscriptionDetails(orderId.VirtualSubsOrderId);

        if(data.Status === 200){
            return data.Data
        }
        else{
            return rejectWithValue({error: "Something went wrong!"})
        }
    }
    catch(error){
        return error
    }
})