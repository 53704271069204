import React from "react";
import "../myprofile/MyProfile.css";
import { useSelector } from "react-redux";
import { DashboardNavBar } from "../../../../../components/DashboardNavBar/DashboardNavBar";
import { SideMenuLayout } from "../../../../../../webapp/layout/sideMenuLayout/SideMenuLayout";
import { MyProfilesectionlist } from "./myProfileSection/MyProfileSection";
import { CompanyIndiProfile } from "../../../../../../sharedComponent/Loading/CompanyIndiProfile.jsx";
import { MidSection } from "../../../../../../sharedComponent/midSection/MidSection";
import { Helmet } from "react-helmet";
export const MyProfile = () => {
  const userData = useSelector((state) => state.user);
  const loginData = useSelector((state) => state.login);
  // const verification =
  //   userData?.accountDetails?.UserDetails?.E9VerificationMessage;

  return (
    <SideMenuLayout>
      <Helmet>
      <link
        rel="Business Owner  Login,  Maintenance Partner Near Me"
        href="equip9.com/my-profile"
      />
      <meta name="keywords" content="heavy industries"/>
      <title>Boost Business: Heavy Industries Growth</title>
      <meta name="description" content="Unlock the potential of your business with heavy industries. Discover how our services can 
      fuel growth and success for business owners. Explore now!!"/>
      <h1>Heavy Industries: Empowering Business Owners for Growth and Success</h1>
      <h2>Why Choose Heavy Industries for Your Business?</h2>
      <h3>Transform Your Business with Heavy Industries Solutions</h3>
      </Helmet>

      <DashboardNavBar />
      <MidSection>
        {/* <ProfileVerification /> */}
        <CompanyIndiProfile />
        <MyProfilesectionlist />
      </MidSection>
    </SideMenuLayout>
  );
};
