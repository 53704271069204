import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getMyLeadDsConnectRequestReceivedList,
  getMyLeadDsConnectRequestSentList,
  getMyLeadsDsConnectReceivedList,
} from "../../../services/myLeads/myLeads";

export const getDealershipSentDataThunk = createAsyncThunk(
  "myLeads/sentDealershipRequestData",
  async (_, { getState }) => {
    try {
      const data = await getMyLeadDsConnectRequestSentList();
      return data;
    } catch (error) {}
  }
);

export const getDealershipReceivedDataThunk = createAsyncThunk(
  "myLeads/receivedDealershipRequestData",
  async (_, { getState }) => {
    try {
      const data = await getMyLeadDsConnectRequestReceivedList();
      return data;
    } catch (error) {}
  }
);

export const getMyLeadsDealershipConnectList = createAsyncThunk(
  "myLeads/connectDealershipList",
  async (id) => {
    try {
      const data = await getMyLeadsDsConnectReceivedList(id);
      return data;
    } catch (error) {}
  }
);
