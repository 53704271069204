import { useSelector } from "react-redux";
import { MultipleImageUpload } from "../../../components/MultipleImageUpload/MultipleImageUpload";
import { getUserAssetById } from "../../../services/userAssets/userAssets";
import { useEffect, useState } from "react";
import { getDocumentTypes } from "../../../services/equipments/equipments";
import { UploadMediaPopUp } from "../../../components/UploadMediaPopUp/UploadMediaPopUp";
import { convertToFormData } from "../../../helpers/dataConversion/convertToFormData";
import fetchHandler from "../../../services/fetchHandler";
import { API_CONSTANTS } from "../../../../constants/apiConstants";

export const EquipmentMediaDetails = () => {
  const { createdAssetId } = useSelector((state) => state.addEquipment);
  const [assetDetails, setAssetDetails] = useState(null);
  const [documentTypeList, setDocumentsTypeList] = useState(null);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [documentModelOpen, setDocumentModelOpen] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [documentFiles, setDocumentFiles] = useState(null);
  const [uploadedVideos, setUploadedVideos] = useState(null);
  const [uploadedImages, setUploadedImages] = useState(null);
  const [mediaSubmitting, setMediaSubmitting] = useState(false);
  const allEquipmentImages = [
    { id: 1, value: "Front Image", key: "AssetImageFront" },
    { id: 2, value: "Right Image", key: "AssetImageRight" },
    { id: 3, value: "Left Image", key: "AssetImageLeft" },
    { id: 4, value: "Back Image", key: "AssetImageBack" },
  ];

  const getSelectedDocumentObject = (objectName) => {
    if (!objectName) {
      throw new Error("parameter cannot be empty");
    }
    const selectedDocument = documentTypeList.find(
      ({ value }) => value.toLowerCase() === objectName.toLowerCase()
    );
    return selectedDocument;
  };
  const handleUploadDocuments = async (body) => {
    setMediaSubmitting(true);
    const requiredBody = {
      AssetDocumentTypeId: getSelectedDocumentObject(body.selectValue).id,
      AssetId: assetDetails.AssetDetails.AssetId,
      AssetDocument: body.document,
    };
    // console.log(requiredBody);
    try {
      const { data } = await fetchHandler(
        `/CreateNewAssetDocumentDetails`,
        {},
        {},
        convertToFormData(requiredBody),
        API_CONSTANTS.HTT_POST
      );
      await getAssetDetails(createdAssetId); // Thunk will be called
    } catch (error) {
    } finally {
      setMediaSubmitting(false);
    }
  };

  const handleDeleteDocument = async (object) => {
    try {
      const { data } = await fetchHandler(
        `/DeleteNewAssetsDocumentDetails/${object.AssetsDocumentId}`,
        {},
        {},
        {},
        API_CONSTANTS.HTTP_DELETE
      );
      // console.log(data);
      await getAssetDetails(createdAssetId);
    } catch (error) {
    }
  };

  const handleVideoUpload = async (file) => {
    setMediaSubmitting(false);
    try {
      const { data } = await fetchHandler(
        `/CreateAssetVideoDetails`,
        {},
        {},
        convertToFormData({ AssetId: createdAssetId, AssetVideo: file }),
        API_CONSTANTS.HTT_POST
      );
      // console.log(data);
      setVideoModalOpen(false);
      await getAssetDetails(createdAssetId);
    } catch (error) {
    } finally {
      setMediaSubmitting(false);
    }
  };

  const getAssetDetails = async (id) => {
    try {
      const data = await getUserAssetById(id);
      setAssetDetails(data);
      return data;
      //  setDocumentFiles(data.AssetDocumentDetails)
      //  const require
    } catch (error) {
    }
  };

  const getAssetDocumentTypeList = async () => {
    try {
      const data = await getDocumentTypes();
      setDocumentsTypeList(data);
    } catch (error) {
    }
  };

  const getUploadedDocuments = (assetData) => {
    if (!assetData) {
      setDocumentFiles(null);
      return null;
    }
    if (!assetData.AssetDocumentDetails) {
      setDocumentFiles(null);
      return null;
    }
    const documentLinks = assetData.AssetDocumentDetails.map((element) => ({
      id: element.AssetDocumentTypeId,
      value: element.AssetDocumentTypeValue,
      url: `${process.env.REACT_APP_CDN_PVT_URL}${assetData.AssetDocumentReference}${element.DocumentFileReference}`,
    }));
    setDocumentFiles(documentLinks);
    return documentLinks;
  };

  const getDocumentToDelete = (documentName) => {
    const selectedDocument = assetDetails.AssetDocumentDetails.find(
      ({ AssetDocumentTypeId }) => AssetDocumentTypeId === documentName
    );
    return selectedDocument;
  };

  const getUploadedVideos = (assetData) => {
    if (!assetData) {
      setUploadedVideos(null);
      return null;
    }
    if (!assetData?.AssetVideoDetails) {
      setUploadedVideos(null);
      return null;
    }
    const requiredData = {
      id: assetData?.AssetVideoDetails,
      title: "",
      url: `${process.env.REACT_APP_CDN_PVT_URL}${assetData?.AssetVideoBaseReference}${assetData?.AssetVideoDetails?.VideoFileReference}`,
    };
    setUploadedVideos([requiredData]);
    return [requiredData];
  };

  const handleVideoDelete = async (assetVideoObject) => {
    if (!assetVideoObject) {
      return null;
    }
    try {
      const { data } = await fetchHandler(
        `/DeleteAssetVideoDetails/${assetVideoObject.AssetVideoId}`,
        {},
        {},
        {},
        API_CONSTANTS.HTTP_DELETE
      );
      await getAssetDetails(createdAssetId);
    } catch (error) {}
  };

  const getUploadedImages = (assetDetails) => {
    if (!assetDetails || !assetDetails.AssetImageDetails) {
      setUploadedImages(null);
      return null;
    }
    const { AssetImageDetails } = assetDetails;
    const imageArray = Object.keys(AssetImageDetails).filter(
      (fileRef) =>
        fileRef.split("_")[2] === "FileRef" &&
        AssetImageDetails[fileRef] !== null
    );
    return createUploadedImagesArray(imageArray);
  };
  const createUploadedImageObjects = (imageKey) => {
    switch (imageKey) {
      case "Image_Front_FileRef":
        return {
          id: 1,
          value: "Front Image",
          url: `${process.env.REACT_APP_CDN_PVT_URL}AssetsImage/${assetDetails.AssetImageDetails[imageKey]}`,
        };
      case "Image_Back_FileRef":
        return {
          id: 4,
          value: "Back Image",
          url: `${process.env.REACT_APP_CDN_PVT_URL}AssetsImage/${assetDetails.AssetImageDetails[imageKey]}`,
        };
      case "Image_Left_FileRef":
        return {
          id: 3,
          value: "Left Image",
          url: `${process.env.REACT_APP_CDN_PVT_URL}AssetsImage/${assetDetails.AssetImageDetails[imageKey]}`,
        };
      case "Image_Right_FileRef":
        return {
          id: 2,
          value: "Right Image",
          url: `${process.env.REACT_APP_CDN_PVT_URL}AssetsImage/${assetDetails.AssetImageDetails[imageKey]}`,
        };
    }
  };

  const createUploadedImagesArray = (array) => {
    if (array && array.length >= 0) {
      const requiredArray = array.map((imageKey) =>
        createUploadedImageObjects(imageKey)
      );
      setUploadedImages(requiredArray);
      return requiredArray;
    }
    setUploadedImages(null);
    return null;
  };
  const getSelectedImage = (selectedId) => {
    const requiredImage = uploadedImages.find(
      ({ id }) => parseInt(id) === parseInt(selectedId)
    );
    return requiredImage;
  };
  const handleImageDelete = async (id) => {
    const selectedImage = getSelectedImage(id);
    const body = {
      AssetId: createdAssetId,
      ImageName: selectedImage.value.toUpperCase().split(" ").join("_"),
    };
    let apiBody = Object.keys(body).reduce(
      (accumulator, currentValue, index) => {
        accumulator.push(
          `${encodeURIComponent(currentValue)}=${encodeURIComponent(
            body[currentValue]
          )}`
        );
        return accumulator;
      },
      []
    );

    try {
      const { data } = await fetchHandler(
        "/DeleteNewAssetsImageByName",
        {},
        {},
        apiBody.join("&"),
        API_CONSTANTS.HTTP_DELETE
      );
      if (data.Status === 204) {
        await getAssetDetails(createdAssetId);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    if (createdAssetId) {
      getAssetDetails(createdAssetId);
    }
  }, [createdAssetId]);

  useEffect(() => {
    if (assetDetails) {
      getUploadedDocuments(assetDetails);
      getUploadedVideos(assetDetails);
      getUploadedImages(assetDetails);
    }
  }, [assetDetails]);

  useEffect(() => {
    getAssetDocumentTypeList();
  }, []);

  return (
    <>
      <UploadMediaPopUp
        isModalOpen={documentModelOpen}
        modalTitle="Upload Documents"
        mediaComponentTitle="Upload a document and Select a Name"
        mediaExtentionsAllowed={["jpg", "jpeg", "png", "pdf"]}
        maxFileSizeinMb={5}
        handleSubmit={async (values) => {
          await handleUploadDocuments(values);
          setDocumentModelOpen(false);
        }}
        dropdownValues={documentTypeList}
        valuesToDeactivate={documentFiles ? documentFiles : []}
        onCloseButtonClick={() => {
          setDocumentModelOpen(false);
        }}
        isLoading={mediaSubmitting}
      />
      <UploadMediaPopUp
        modalTitle="Upload Video"
        documentType="Video"
        isModalOpen={videoModalOpen}
        mediaComponentTitle="Upload a video"
        mediaExtentionsAllowed={["mp4", "avi"]}
        maxFileSizeinMb={10}
        handleSubmit={async (values) => {
          await handleVideoUpload(values.document);
        }}
        onCloseButtonClick={() => {
          setVideoModalOpen(false);
        }}
        hasDropdown={false}
        isLoading={mediaSubmitting}
      />
      {/*  Image PopUp */}
      <UploadMediaPopUp
        modalTitle="Upload Image"
        documentType="Photo"
        isModalOpen={imageModalOpen}
        mediaComponentTitle="Upload a Photos and select a Name"
        mediaExtentionsAllowed={["jpg", "jpeg", "png", "heic"]}
        maxFileSizeinMb={5}
        dropdownValues={allEquipmentImages}
        valuesToDeactivate={uploadedImages ? uploadedImages : []}
        handleSubmit={async (values) => {
          setMediaSubmitting(true);
          const { document, selectValue } = values;
          const selectedPhoto = allEquipmentImages.find(
            ({ value }) => value === selectValue
          );
          const setName = `IMAGE_${createdAssetId}_${selectedPhoto.value
            .toUpperCase()
            .split(" ")
            .join("||")}.${document.type.split("/")[1]}`;
          // console.log(setName);
          const newImageObject = new File([document], setName, {
            type: document.type,
          });
          const imageKey = selectedPhoto.key;
          // console.log({ AssetId: createdAssetId, [imageKey]: newImageObject });
          try {
            const { data } = await fetchHandler(
              "/CreateNewAssetImageDetails",
              {},
              {},
              convertToFormData({
                AssetId: createdAssetId,
                [imageKey]: newImageObject,
              }),
              API_CONSTANTS.HTT_POST
            );
            await getAssetDetails(createdAssetId);
            setImageModalOpen(false);
          } catch (error) {
          } finally {
            setMediaSubmitting(false);
          }
        }}
        onCloseButtonClick={() => {
          setImageModalOpen(false);
        }}
        isLoading={mediaSubmitting}
      />
      <MultipleImageUpload
        title="Equipment Images"
        maxWidth="100%"
        onAddButtonClick={() => {
          setImageModalOpen(true);
        }}
        mediaArray={uploadedImages}
        isDisabled={
          uploadedImages &&
          allEquipmentImages &&
          allEquipmentImages.length === uploadedImages.length
        }
        deleteAction={(value) => handleImageDelete(value)}
      />

      <MultipleImageUpload
        title="Equipment Documents"
        maxWidth="100%"
        mediaArray={documentFiles}
        onAddButtonClick={() => {
          setDocumentModelOpen(true);
        }}
        deleteAction={(value) => {
          if (value) {
            // console.log(value);
            const object = getDocumentToDelete(value);
            // console.log(object);
            handleDeleteDocument(object);
          }
        }}
        isDisabled={
          Array.isArray(documentFiles) &&
          Array.isArray(documentTypeList) &&
          documentFiles.length === documentTypeList.length
        }
        type="document"
      />
      <MultipleImageUpload
        title="Equipment Videos"
        maxWidth="100%"
        onAddButtonClick={() => {
          setVideoModalOpen(true);
        }}
        isDisabled={assetDetails?.AssetVideoDetails}
        mediaArray={uploadedVideos}
        type="video"
        deleteAction={async (value) => {
          await handleVideoDelete(value);
        }}
      />
    </>
  );
};
