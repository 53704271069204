import { STORAGE_CONSTANTS } from "../../../../constants/storageConstants";
import { getLocalStorageItems } from "../../../helpers/localStorage/localStorage";

export const operatorPartnerInitialState = {
  data: null,
  imageBaseUrl: getLocalStorageItems(STORAGE_CONSTANTS.OPERATOR_PARTNER_MARKETPLACE_IMAGE_URL) || null,
  paginationCount: 0,
  endOfData: false,
  cardCount: 12,
  filterModalOpen: false,
  filteredDataRequired: false,
  filterByVerificationStatus: "",
  filterByEquipmentType: "",
  filterByLicenseType: "",
  initialLoading: false,
  paginationLoading: false,
  error: null,
  errorMessage: null,
};
