import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFilteredPartners } from "../../../services/jobsPosted/jobsPosted";


export const getRemInitialFilteredDataThunk = createAsyncThunk("/rem/initialFilteredData", async(_, {getState}) => {
    const selectedCountry = getState().rentalEquipmentMarketplace.filterByCountry;
    const selectedState = getState().rentalEquipmentMarketplace.filterByState;
    const selectedVerificationStatus = getState().rentalEquipmentMarketplace.filterByVerificationStatus;
    const selectedEquipmentType = getState().rentalEquipmentMarketplace.filterByEquipmentType;
    const cardCount  = getState().rentalEquipmentMarketplace.cardCount;

    let query = ""

    if(selectedCountry){
        query += `&FilterByLocationCountry=${selectedCountry}`
    }
    if(selectedState){
        query+= `&FilterByLocationState=${selectedState}`
    }
    if(selectedVerificationStatus){
        query += `&FilterByVerificationStatus=${selectedState}`
    }
    if(selectedEquipmentType){
        query+= `&FilterByEquipmentType=${selectedEquipmentType}`
    }

    try{
       
        const data = await getFilteredPartners(cardCount,0,`?FilterType=Rental Equipment${query}`);

        if(data.Status === 200){
            return data.Data
        }
    }
    catch(error){
    }

})