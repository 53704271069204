import { createAsyncThunk } from "@reduxjs/toolkit";
import {getUsedMyStoreMarketplace } from "../../../services/equipmentStore/equipmentStore";

export const getInitialUsedEquipmentsThunk  = createAsyncThunk("/equipment-store/initial-used-equipments-thunk", async(_, {rejectWithValue}) => {
    try{
       const data = await getUsedMyStoreMarketplace()
       return data
    }
    catch(error){

    }
})