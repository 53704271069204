import { Flex, Box, Image } from "@chakra-ui/react";
import { SideMenu } from "../../components/SideMenu/SideMenu";
import { SideMenuProfile } from "../../components/SideMenuProfile/SideMenuProfile";
import logo from "../../../assets/logo/equip9_logo_dark.png";
import "./SideMenuLayout.css";
import { useEffect } from "react";

export const SideMenuLayout = ({ defaultLanguage, children }) => {
  //console.log("Inside Side menu Layout");
  useEffect(() => {}, [defaultLanguage]);
  const d = new Date();
  return (
    <Flex width="100vw">
      <Box
        area="side-menu"
        minH="100vh"
        maxW="240px"
        width="100%"
        boxShadow="0 4px 4px 0 #2c2c2c80"
        position="sticky"
        top="0"
        height="100vh"
        zIndex="1"
      >
        <Flex height="48px" marginTop="1.5em" justifyContent="center">
          <Image src={logo} height="48px" width="80px" />
        </Flex>
        <SideMenuProfile />
        <SideMenu defaultLanguage={defaultLanguage} />
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            background: "#f7f7f7",
            borderTop: " 1px solid #e0e0e0",
            height: "37px",
            fontStyle: "normal",
            fontFamily: "Assistant",
            fontWeight: "800",
            marginTop: "2px",
            position: "absolute",
            top: "95%",
          }}
        >
          <p>
            {" "}
            ©{d.getFullYear()} EQUIP9<span>&#8482;</span>
            {process.env.REACT_APP_VERSION}
          </p>
          <p></p>
        </div>
      </Box>
      <Box area="main" minH="100vh" maxW="calc(100vw - 240px)" width="100%">
        {children}
      </Box>
    </Flex>
  );
};
