import { useField } from "formik";
import {
  FormControl,
  FormLabel,
  Stack,
  Select,
  Input,
  FormErrorMessage,
  useBreakpointValue,
  Flex
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { setSelectedCountryThunk } from "../../../features/global/appConfig/thunk/setSelectedCountryThunk";
import { useDispatch } from "react-redux";

export const FormikPhoneNumberInput = ({
  dropdownValues = [],
  isRequired = false,
  value,
  maxWidth = "",
  ...props
}) => {
  const [field, meta, helpers] = useField(props.name);
  const [countryCodeField, countryCodeMeta, countryCodeHelpers] = useField(
    props.countrycodename
  );
  // const [selectValue, setSelectValue] = useState("91");
  const dispatch = useDispatch();

  const generateDropdownValue = (array) => {
    if (!array || array.length === 0) return null;

    return array.map((option) => (
      <option key={option.CountryIsdCode} value={option.CountryIsdCode}>
        {`+${option.CountryIsdCode} ${option.CountryIsoCode}`}
      </option>
    ));
  };


  // const stackDirection = useBreakpointValue({ base: 'column', md: 'row' });
  // const selectMaxWidth = useBreakpointValue({ base: '100%', md: '120px' });

  const onKeyDown = (event) => {
    const allowedKeys = ["Backspace", "Enter", "Tab", "ArrowRight", "ArrowLeft"];
    const allowedKeysWithControl = ["a", "c", "v", "x", "r"];
    
    
    if(event.ctrlKey && allowedKeysWithControl.includes(event.key.toString().toLowerCase())) return

    if (!/\d/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleSelectChange = (e) => {
    
    countryCodeHelpers.setValue(e.target.value);
    dispatch(setSelectedCountryThunk(e.target.value));
  };

  const showError = meta.touched && (meta.error || countryCodeMeta.error);

  return (
    <FormControl isRequired={isRequired} isInvalid={showError} maxW={maxWidth ? maxWidth : "374px" }>
      <FormLabel htmlFor={props.id || props.name}>Mobile Number</FormLabel>
      <Flex gap="0.25em">
        <Select
          value={value}
          onChange={handleSelectChange}
          bgColor={field.value ? "#fff" : "#2c2c2c05"}
          icon={<ChevronDownIcon />}
          placeholder="Select"
          maxWidth="120px"
          h="52px"
          border="1px solid #2c2c2c14"
          focusBorderColor="#2c2c2c"
        >
          {generateDropdownValue(dropdownValues)}
        </Select>
        <Input
          {...field}
          {...props}
          value={field.value || ""}
          h="52px"
          border="1px solid #2c2c2c14"
          focusBorderColor="#2c2c2c"
          bgColor={field.value ? "#fff" : "#2c2c2c05"}
          onKeyDown={onKeyDown}
          onChange={(e) => helpers.setValue(e.target.value)}
          maxLength="10"
        />
     </Flex>
      {showError && (
        <FormErrorMessage>
          {meta.error || countryCodeMeta.error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
