import { DashboardNavBar } from "../../components/DashboardNavBar/DashboardNavBar"
import { SideMenuLayout } from "../sideMenuLayout/SideMenuLayout"

export const EquipmentRentalLayout = ({children}) => {
    return(
        <SideMenuLayout>
             <DashboardNavBar/>
             {children}
        </SideMenuLayout>
    )
}