import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateEquipmentPurchasingDetails } from "../../../services/equipments/equipments";

export const updateAssetPurchasingDetailsThunk = createAsyncThunk(
  "/equipmentListing/updateAssetPurchasingDetails",
  async ({assetId, assetDetails}, { getState, fulfillWithValue, rejectWithValue }) => {
    try {
      const data = await updateEquipmentPurchasingDetails(assetId, assetDetails);
      fulfillWithValue(data);
      return data;
    } catch (error) {
      rejectWithValue(error.message);
      throw error;
    }
  }
);
