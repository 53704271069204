import { getSessionStorageItems } from "../../../helpers/sessionStorage/sessionStorage";

export const operatorSearchInitialState = {
  stepperIndex:
    getSessionStorageItems("OPERATOR_SEARCH_STEPPER_INDEX") &&
    parseInt(getSessionStorageItems("OPERATOR_SEARCH_STEPPER_INDEX")) > 0
      ? parseInt(getSessionStorageItems("OPERATOR_SEARCH_STEPPER_INDEX"))
      : 0,
  equipmentNickname : getSessionStorageItems("OPERATOR_SEARCH_EQ_NICKNAME") || "",
  equipmentType: getSessionStorageItems("OPERATOR_SEARCH_EQ_TYPE") || "",
  equipmentModel: getSessionStorageItems("OPERATOR_SEARCH_EQ_MODEL") || "",
  equipmentMake: getSessionStorageItems("OPERATOR_SEARCH_EQ_MAKE") || "",
  equipmentAge: getSessionStorageItems("OPERATOR_SEARCH_EQ_AGE") || "",
  jobTitle: getSessionStorageItems("OPERATOR_SEARCH_JOB_TITLE") || "",
  salaryType: getSessionStorageItems("OPERATOR_SEARCH_SALARY_TYPE") || "",
  providentFund: getSessionStorageItems("OPERATOR_SEARCH_PROVIDENT_FUND") || "",
  foodBenefits: getSessionStorageItems("OPERATOR_SEARCH_FOOD_BENEFITS") || "",
  accomodationProvided:
    getSessionStorageItems("OPERATOR_SEARCH_ACCOMODATION_TYPE") || "",
  inHandSalary: getSessionStorageItems("OPERATOR_SEARCH_IN_HAND_SALARY") || "",
  overTimePay: getSessionStorageItems("OPERATOR_SEARCH_OVERTIME_PAY") || "",
  shiftType: getSessionStorageItems("OPERATOR_SEARCH_SHIFT_TYPE") || "",
  workingHours: getSessionStorageItems("OPERATOR_SEARCH_WORKING_HOURS") || "",
  lunchHours: getSessionStorageItems("OPERATOR_SEARCH_LUNCH_HOURS") || "",
  jobDuration: getSessionStorageItems("OPERATOR_SEARCH_JOB_DURATION") || "",
  jobDurationType : getSessionStorageItems("OPERATOR_SEARCH_JOB_DURATION_TYPE") || "",
  workType: getSessionStorageItems("OPERATOR_SEARCH_WORK_TYPE") || "",
  insurance: getSessionStorageItems("OPERATOR_SEARCH_INSURANCE") || "",
  travelAllowance:
    getSessionStorageItems("OPERATOR_SEARCH_TRAVEL_ALLOWANCE") || "",
    payCycle:  getSessionStorageItems("OPERATOR_SEARCH_PAY_CYCYLE") || "",
    jobLocation: getSessionStorageItems("OPERATOR_SEARCH_JOB_LOCATION") || "",
    serviceDetails: getSessionStorageItems("OPERATOR_SEARCH_SERVICE_DETAILS") || "",

  stepperRoute: [{ name: "Step 1" }, { name: "Step 2" }, { name: "Step 3" }],
  isLoading:false
};
