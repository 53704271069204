import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCommunityPost } from "../../../services/community/community";

export const communityInitialPostThunk = createAsyncThunk(
  "community/getInitialCards",
  async (_, {getState}) => {
    const cardCount = getState().community.cardCount

    try {
      const data = await getCommunityPost(cardCount,0);
      return data;
    } catch (error) {
      throw error;
    }
  }
);
