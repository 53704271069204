import { API_CONSTANTS } from "../../../../../constants/apiConstants";

export const selectDetailsUrl = (businessType) => {
    switch(businessType.toLowerCase()){
        case "Operator Partner".toLowerCase():
            return API_CONSTANTS.SAVE_OPERATOR_DETAILS;
          
          case "Maintenance Partner".toLowerCase():
            return API_CONSTANTS.SAVE_MAINTENANCE_PARTNER_DETAILS;
         
          case "Business Owner".toLowerCase():
            return API_CONSTANTS.SAVE_BUSINESS_OWNER_DETAILS;
           
          case "Equipment Owner".toLowerCase():
            return API_CONSTANTS.SAVE_EQUIPMENT_OWNER_INDIVIDUAL_DETAILS;
         default: 
         throw new Error(`${businessType} is not a validBusinessType`)
    }
    
  };