import React from 'react';
import './ErrorPage.css'
import { useNavigate } from 'react-router-dom';
import { Button } from '@chakra-ui/react';

function Warning() {
    const navigate = useNavigate();

    const handleGoBack = () =>{
        navigate('/')
    }

    const handleContactUs = () =>{
        navigate('/contact')
    }

  return (
    <div className="error-container">
      <div className="error-message">
        <h1 className="error-message-heading">404 - Not Found</h1>
        <p className="error-message-message">The page you are looking for does not exist.</p>
        <Button width="142px" variant="gradient" onClick={handleGoBack}>Go Back</Button>
      </div>
      <div className="error-message-img">
        <img
          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/ErrorPages/Warning.png`}
          alt="Not Authorized"
          className="error-message-img"
        />
      </div>
    </div>
  );
}

export default Warning;
