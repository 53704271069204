import React, { useState, useEffect } from "react";
import "./EditEquipmentDetailCard.css";
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
// import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { Formik, Field, useFormikContext } from "formik";
import { useQuery, useQueryClient } from "react-query";
import { ReactSVG } from "react-svg";
// import { useSelector } from "react-redux";
// import { getVerificationDocumentType, userUploadIndentityDocument } from '../../../ApiEquip9/CommonApi';
import {
  getAlltypeofEquipmentTy,
  getIndustryMaker,
  EditEquipment,
  getAllEquipmentTypeswithEquipId,
} from "../../../../../../../../../../ApiEquip9/CommonApi";
// import { useToast } from "@chakra-ui/react";
import { API_CONSTANTS } from "../../../../../../../../../../constants/apiConstants";
import {
  MAINTENANCE_CERTIFIED_VALIDATIONS,
  MAINTENANCE_NOT_CERTIFIED_VALIDATIONS,
  OPERATOR_CERTIFIED_VALIDATIONS,
  OPERATOR_NOT_CERTIFIED_VALIDATIONS,
} from "./../../../../../../../../../features/documentFlow/screens/uploadEquipmentSkill/formValues";
import { dateConverstion } from "../../../../../../../../../helpers/dataConversion/substring";
// import "./UploadEquipmentSkill.css";
import { SelectCardWrapper } from "../../../../../../../../../components/SelectCardWrapper/SelectCardWrapper";
import { convertToFormData } from "../../../../../../../../../helpers/dataConversion/convertToFormData";
// import { increasePageIndex } from "../../slice/docFlowSlice";
import { increasePageIndex } from "../../../../../../../../documentFlow/slice/docFlowSlice";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Checkbox,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
export const EditEquipmentDeatilsCard = ({
  isOpenFormEdit,
  onCloseFormEdit,
  triggerRefresh,
  selectedEquipmentTypeId,
}) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const [imagePreview, setImagePreview] = useState(null);
  // const [selectedEquipmentTypeId, setSelectedEquipmentTypeId] = useState(null);
  // const [fileType, setFileType] = useState("");
  // const [isCertified, setIsCertified] = useState(null);
  const [allEquipmentTypes, setAllEquipmentTypes] = useState(null);
  const [industryTypeList, setIndustryTypeList] = useState(null);
  const [industryMakeList, setIndustryMakeList] = useState(null);
  const [expertiseAreaList, setExpertiseAreaList] = useState(null);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null);

  // const [selectedOption, setSelectedOption] = useState("No");
  // const [certificateValidDate, setCertificateValidDate] = useState("");
  // const [certificateImageFile, setCertificateImageFile] = useState(null);
  // const [certificateIssuer, setCertificateIssuer] = useState(null);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [EquipmentList, setEquipmentList] = useState(null);
  const [OemList, setOemList] = useState(null);
  const [industryList, setIndustryList] = useState(null);
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedExpertise, setSelectedExpertise] = useState([]);

  // const [imagePreview, setImagePreview] = useState(null);
  //******* */
  const [prefetchData, setprefetchData] = useState(null);
  const [checkprefetchData, setcheckprefetchData] = useState(null);

  const currentDate = new Date();
  const tomorrow = new Date(currentDate);
  tomorrow.setDate(currentDate.getDate() + 1);

  // Convert dates to YYYY-MM-DD format
  const minDate = currentDate.toISOString().split("T")[0];
  const maxDate = tomorrow.toISOString().split("T")[0];

  const toast = useToast();
  const queryClient = useQueryClient();
  const formikContext = useFormikContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await EditEquipment(
          userData.token,
          selectedEquipmentTypeId
        );
        setprefetchData(response?.data?.Data?.UserEquipment);
        setcheckprefetchData(response?.data?.Data?.UserEquipment);

        if (response?.data?.Data?.UserEquipment?.ExpertiseArea) {
          const expertiseIds =
            response?.data?.Data?.UserEquipment?.ExpertiseArea?.split(",").map(
              Number
            );
          setSelectedExpertise(expertiseIds);
        }
      } catch (error) {
        console.error("Error fetching equipment data", error);
      }
    };

    if (selectedEquipmentTypeId) {
      fetchData();
    }
  }, [selectedEquipmentTypeId, userData.token]);

  useEffect(() => {
    if (prefetchData) {
      setSelectedEquipmentType(prefetchData?.EquipmentTypeId);
    }
  }, [prefetchData]);

  useEffect(() => {
    if (prefetchData && typeof prefetchData.ExpertiseArea === "string") {
      const expertiseIds = prefetchData.ExpertiseArea.split(",").map(Number);
      setSelectedExpertise(expertiseIds);
    }
  }, [prefetchData]);

  useEffect(() => {
    if (formikContext && selectedExpertise.length > 0) {
      formikContext.setFieldValue("expertise", selectedExpertise);
    }
  }, [selectedExpertise, formikContext]);

  const initialValues = checkprefetchData
    ? {
        equipmentType: checkprefetchData?.EquipmentTypeId || "",
        industryType: checkprefetchData?.IndustryId || "",
        equipmentMake: checkprefetchData?.EquipmentMakeId || "",
        expertise: selectedExpertise || [],
        certificateNumber: checkprefetchData?.CertificateNumber || null,
        certificateIssuer: checkprefetchData?.IssuerName || "",
        certificateIssueDate: checkprefetchData?.ValidDate || "",
        certificateImage: checkprefetchData?.CertificateFileReference,
      }
    : {
        equipmentType: null,
        industryType: null,
        equipmentMake: null,
        expertise: [],
        certificateNumber: null,
        certificateIssuer: null,
        certificateIssueDate: null,
        certificateImage: null,
      };

  const openEditPopup = () => {
    setIsEditPopupOpen(true);
  };

  const closeEditPopup = () => {
    setIsEditPopupOpen(false);
  };

  useEffect(() => {
    if (formikContext && selectedExpertise.length > 0) {
      formikContext.setFieldValue("expertise", selectedExpertise);
    }
  }, [selectedExpertise, formikContext]);

  /*********************Equpment List*******************************/

  const { data: EditfetchedEqupmentList } = useQuery(
    "EditAlltypeofEquipmentTy",
    () => getAlltypeofEquipmentTy(userData.token)
  );

  useEffect(() => {
    setEquipmentList(EditfetchedEqupmentList?.data?.Data?.EquipmentsList);
  });

  /*********************fetch OemType*******************************/

  const { data: EditfetchOemType } = useQuery(
    ["EditIndustryMaker", selectedEquipmentType],
    () => getIndustryMaker(userData.token, selectedEquipmentType),
    {
      enabled: !!selectedEquipmentType,
    }
  );

  useEffect(() => {
    setOemList(EditfetchOemType?.data?.Data?.OEMList);
  }, [EditfetchOemType, selectedEquipmentType]);

  /*********************Industry Type******************************/
  const { data: fetchIndustryType } = useQuery(
    ["EditIndustryType", selectedEquipmentType],
    () =>
      getAllEquipmentTypeswithEquipId(userData.token, selectedEquipmentType),
    {
      enabled: !!selectedEquipmentType,
    }
  );

  useEffect(() => {
    setIndustryList(fetchIndustryType?.data?.Data?.IndustriesList);
  }, [fetchIndustryType, selectedEquipmentType]);

  useEffect(() => {
    setSelectedOption(checkprefetchData?.AreYouCertified === 1 ? "Yes" : "No");
  }, [checkprefetchData]);

  const [selectedOption, setSelectedOption] = useState(
    checkprefetchData?.AreYouCertified === 1 ? "Yes" : "No"
  );

  /******************************************************* */

  const getAllEquipmentTypes = async () => {
    const authHeaders = new Headers();
    authHeaders.append("token", userData.token);
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}${API_CONSTANTS.GET_ALL_EQUIPMENTS_TYPE}`,
      {
        headers: authHeaders,
      }
    );
    const data = await response.json();
    if (data.Status === 200) {
      setAllEquipmentTypes([...data.Data.EquipmentsList]);
    }
  };

  const getIndustryMake = async () => {
    const authHeaders = new Headers();
    authHeaders.append("token", userData.token);
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}${API_CONSTANTS.GET_MAKE}${selectedEquipmentType}`,
      {
        headers: authHeaders,
      }
    );
    const data = await response.json();
    if (data.Status) {
      setIndustryMakeList(data.Data.OEMList);
    }
  };

  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];

  const getIndustryType = async () => {
    const authHeaders = new Headers();
    authHeaders.append("token", userData.token);
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}${API_CONSTANTS.GET_ALL_INDUSTRY}${selectedEquipmentType}`,
      {
        headers: authHeaders,
      }
    );
    const data = await response.json();
    if (data.Status) {
      setIndustryTypeList(data.Data.IndustriesList);
    }
  };
  const getAllExpertiseArea = async () => {
    const authHeaders = new Headers();
    authHeaders.append("token", userData.token);
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/GetMaintenanceExpertiseTypeList`,
      {
        headers: authHeaders,
      }
    );
    const data = await response.json();
    if (data.Status === 200) {
      setExpertiseAreaList(data.Data.MaintenanceExpertiseTypes);
    }
  };

  const postEquipmentSkills = async (body) => {
    const authHeaders = new Headers();
    authHeaders.append("token", userData.token);

    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/UpdateUserEquipment`,
      {
        method: API_CONSTANTS.HTT_POST,
        headers: authHeaders,
        body: body,
      }
    );
    const data = await response.json();

    if (data.Status === true) {
      toast({
        status: "success",
        title: data?.Message?.SuccessMessage,
        isClosable: true,
        position: "top-right",
      });
      queryClient.invalidateQueries("getEducationDetails");
      triggerRefresh();
      setImagePreview(null);
      onCloseFormEdit();
      dispatch(increasePageIndex());
    } else {
      toast({
        status: "error",
        title: data.Message.FailMessage,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    (async () => {
      await getAllEquipmentTypes();
    })();
  }, []);

  useEffect(() => {
    if (selectedEquipmentType) {
      (async () => {
        await getIndustryMake();
        await getIndustryType();
        await getAllExpertiseArea();
      })();
    } else {
      setIndustryMakeList(null);
      setIndustryTypeList(null);
    }
  }, [selectedEquipmentType]);

  return (
    isOpenFormEdit && (
      <div className="popup-card-overlay">
        <div className="popup-card-Equipment">
          <div className="closeButton" onClick={onCloseFormEdit}>
            <ReactSVG
              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Cross.svg`}
            />
          </div>
          <div className="AddEquipment">
            <ReactSVG
              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/AddEquipment.svg`}
            />
          </div>
          <div className="herosection-text">Add Equipment Detail</div>
          <Formik
            initialValues={initialValues}
            validationSchema={
              userData.BusinessTypeValue.toLowerCase() ===
                "Maintenance Partner".toLowerCase() && selectedOption === "No"
                ? MAINTENANCE_NOT_CERTIFIED_VALIDATIONS
                : userData.BusinessTypeValue.toLowerCase() ===
                    "Maintenance Partner".toLowerCase() &&
                  selectedOption === "Yes"
                ? MAINTENANCE_CERTIFIED_VALIDATIONS
                : userData.BusinessTypeValue.toLowerCase() ===
                    "Operator Partner".toLowerCase() && selectedOption === "No"
                ? OPERATOR_NOT_CERTIFIED_VALIDATIONS
                : OPERATOR_CERTIFIED_VALIDATIONS
            }
            onSubmit={async (values, { setFieldValue }) => {
              const body = {
                IndustryId: checkprefetchData.IndustryId,
                EquipmentTypeId: checkprefetchData.EquipmentTypeId,
                UserEquipmentId: selectedEquipmentTypeId || null,
                EquipmentMakeId: checkprefetchData?.EquipmentMakeId,
                EquipmentModelId: checkprefetchData?.EquipmentModelId,
                // ExpertiseArea :checkprefetchData?.ExpertiseArea,
                IsNeverExpire: checkprefetchData?.IsNeverExpire,
              };
              if (selectedOption === "No") {
                body.AreYouCertified = 0;
              } else {
                body.AreYouCertified = 1;
                body.CertificateNumber = checkprefetchData.CertificateNumber;
                body.IssuerName = checkprefetchData.IssuerName;
                body.ValidDate = dateConverstion(checkprefetchData.ValidDate);
                body.UserEquipmentCertificates =
                  checkprefetchData?.CertificateFileReference;
                // body.EquipmentModelId=checkprefetchData?EquipmentModelId;
                // body.ExpertiseArea=checkprefetchData?.ExpertiseArea;
                // body.IsNeverExpire=checkprefetchData?IsNeverExpire
              }
              if (
                userData.BusinessTypeValue.toLowerCase() ===
                "Maintenance Partner".toLowerCase()
              ) {
                body.ExpertiseArea = values.expertise;
              }

              //
              const ressss = await postEquipmentSkills(convertToFormData(body));
            }}
          >
            {({ values, handleSubmit, setFieldValue, errors, touched }) => (
              <>
                <form id="equipment-skill-form" onSubmit={handleSubmit}>
                  <Flex
                    flexDirection="column"
                    gap="2em"
                    maxH="300px"
                    overflowY="scroll"
                  >
                    <Flex
                      gap="2em"
                      width="80%"
                      margin="0 auto"
                      padding="0 0.25em"
                      flexWrap="wrap"
                    >
                      <FormControl isRequired={true} maxW="374px" width="100%">
                        <FormLabel>Equipment Type</FormLabel>
                        <Field
                          as="select"
                          name="equipmentType"
                          value={checkprefetchData?.EquipmentTypeId}
                          h="52px"
                          border="1px solid #2c2c2c14"
                          focusBorderColor="#2c2c2c"
                          backgroundColor={
                            values.equipmentType ? "#ffff" : "#2c2c2c05"
                          }
                          maxW="374px"
                          onChange={(e) => {
                            // setSelectedEquipmentType(e.target.value)
                            setcheckprefetchData((prevUserDetails) => {
                              return {
                                ...prevUserDetails,
                                EquipmentTypeId: e.target.value,
                              };
                            });
                          }}
                        >
                          <option value="" isDisabled>
                            Select
                          </option>
                          {EquipmentList && EquipmentList?.length > 0
                            ? EquipmentList?.map((equipmentType) => (
                                <option
                                  key={equipmentType.EquipmentTypeId}
                                  value={equipmentType.EquipmentTypeId}
                                >
                                  {equipmentType.EquipmentTypeValue}
                                </option>
                              ))
                            : null}
                        </Field>
                      </FormControl>

                      <FormControl
                        isDisabled={!industryTypeList}
                        isRequired={true}
                        maxW="374px"
                        width="100%"
                      >
                        <FormLabel>Industry Type</FormLabel>
                        <Field
                          as="select"
                          name="industryType"
                          value={checkprefetchData?.IndustryId}
                          h="52px"
                          border="1px solid #2c2c2c14"
                          focusBorderColor="#2c2c2c"
                          backgroundColor={
                            values.industryType ? "#ffff" : "#2c2c2c05"
                          }
                          maxW="374px"
                          onChange={(e) => {
                            setcheckprefetchData((prevUserDetails) => {
                              return {
                                ...prevUserDetails,
                                IndustryId: e.target.value,
                              };
                            });
                          }}
                        >
                          <option value="" isDisabled>
                            Select
                          </option>
                          {industryList && industryList.length > 0
                            ? industryList.map((industryType) => (
                                <option
                                  key={industryType.IndustryId}
                                  value={industryType.IndustryId}
                                >
                                  {industryType.IndustryValue}
                                </option>
                              ))
                            : null}
                        </Field>
                      </FormControl>

                      <FormControl
                        isDisabled={!industryMakeList}
                        isRequired={true}
                        maxW="374px"
                        width="100%"
                      >
                        <FormLabel>Industry Make</FormLabel>
                        <Field
                          as="select"
                          h="52px"
                          border="1px solid #2c2c2c14"
                          focusBorderColor="#2c2c2c"
                          backgroundColor={
                            values.equipmentMake ? "#ffff" : "#2c2c2c05"
                          }
                          maxW="374px"
                          // EquipmentMakeId
                          value={checkprefetchData?.EquipmentMakeId}
                          onChange={(e) => {
                            setcheckprefetchData((prevUserDetails) => {
                              return {
                                ...prevUserDetails,
                                EquipmentMakeId: e.target.value,
                              };
                            });
                          }}
                        >
                          <option value="" isDisabled>
                            Select
                          </option>
                          {OemList && OemList.length > 0
                            ? OemList.map((make) => (
                                <option key={make.OEMId} value={make.OEMId}>
                                  {make.OEMName}
                                </option>
                              ))
                            : null}
                        </Field>
                      </FormControl>

                      {userData.BusinessTypeValue.toLowerCase() ===
                        "Maintenance Partner".toLowerCase() && (
                        <>
                          <FormLabel>Expertise</FormLabel>
                          <Menu>
                            <MenuButton
                              marginTop="-8"
                              as={Button}
                              width="375px"
                              height="55px"
                              rightIcon={<ChevronDownIcon />}
                            >
                              <Flex
                                justifyContent="space-between"
                                width="100%"
                                height="100%"
                                alignItems="center"
                              >
                                <Text isTruncated maxW="calc(100% - 40px)">
                                  {selectedExpertise?.length > 0
                                    ? expertiseAreaList
                                        ?.filter((area) =>
                                          selectedExpertise.includes(
                                            area.MaintenanceExpertiseTypeId
                                          )
                                        )
                                        .map(
                                          (area) =>
                                            area.MaintenanceExpertiseTypeValue
                                        )
                                        .join(", ")
                                    : "Select Expertise Areas"}
                                </Text>
                              </Flex>
                            </MenuButton>
                            <MenuList maxH="200px" overflowY="auto">
                              {expertiseAreaList?.map((equipmentArea) => (
                                <MenuItem
                                  key={equipmentArea.MaintenanceExpertiseTypeId}
                                  minH="48px"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <Checkbox
                                    colorScheme="green"
                                    name="expertise"
                                    isChecked={selectedExpertise.includes(
                                      equipmentArea.MaintenanceExpertiseTypeId
                                    )}
                                    onChange={(e) => {
                                      const expertiseId =
                                        equipmentArea.MaintenanceExpertiseTypeId;
                                      let updatedExpertiseArray;

                                      if (
                                        selectedExpertise.includes(expertiseId)
                                      ) {
                                        updatedExpertiseArray =
                                          selectedExpertise.filter(
                                            (id) => id !== expertiseId
                                          );
                                      } else {
                                        updatedExpertiseArray = [
                                          ...selectedExpertise,
                                          expertiseId,
                                        ];
                                      }

                                      setSelectedExpertise(
                                        updatedExpertiseArray
                                      );
                                      setFieldValue(
                                        "expertise",
                                        updatedExpertiseArray
                                      );
                                    }}
                                  >
                                    {
                                      equipmentArea.MaintenanceExpertiseTypeValue
                                    }
                                  </Checkbox>
                                </MenuItem>
                              ))}
                            </MenuList>
                          </Menu>

                          <div>
                            {errors.expertise && touched.expertise && (
                              <Box color="red.500" fontSize="sm" mt="1">
                                {errors.expertise}
                              </Box>
                            )}
                          </div>
                        </>
                      )}

                      <br />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <FormLabel>
                          Are you certified for this equipment&#63;
                        </FormLabel>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "12px",
                          }}
                        >
                          <SelectCardWrapper
                            width="178px"
                            height="52px"
                            padding="0"
                            fontWeight="600"
                            // isSelected={selectedOption === 'No'}
                            onClick={() => setSelectedOption("No")}
                          >
                            &#10005; No
                          </SelectCardWrapper>
                          <SelectCardWrapper
                            width="178px"
                            height="52px"
                            padding="0"
                            fontWeight="600"
                            isSelected={selectedOption === "Yes"}
                            onClick={() => setSelectedOption("Yes")}
                          >
                            &#10004; Yes
                          </SelectCardWrapper>
                        </div>

                        {selectedOption === "Yes" && (
                          <>
                            <div>
                              <div style={{ marginTop: "12px" }}>
                                <label htmlFor="inputField">
                                  Certificate Number
                                  <span className="asterisk">*</span>
                                </label>

                                <Field
                                  style={{
                                    backgroundColor: "#FAFAFA",
                                    borderColor: "#bfbfbf",
                                    borderWidth: "1px",
                                    borderStyle: "solid",
                                    width: "366px",
                                    marginTop: "10px",
                                    height: "53px",
                                    borderRadius: "5px",
                                  }}
                                  label="Certificate Number"
                                  placeholder="Example : CET00001"
                                  name="certificateNumber"
                                  isRequired={true}
                                  onChange={(e) => {
                                    setcheckprefetchData((prevUserDetails) => {
                                      return {
                                        ...prevUserDetails,
                                        CertificateNumber: e.target.value,
                                      };
                                    });
                                  }}
                                  value={checkprefetchData?.CertificateNumber}
                                />
                              </div>

                              <div style={{ marginTop: "12px" }}>
                                <label htmlFor="inputField">
                                  Name of Certificate Issuer
                                  <span className="asterisk">*</span>
                                </label>
                                <Field
                                  style={{
                                    backgroundColor: "#FAFAFA",
                                    borderColor: "#bfbfbf",
                                    borderWidth: "1px",
                                    borderStyle: "solid",
                                    width: "366px",
                                    marginTop: "10px",
                                    height: "53px",
                                    borderRadius: "5px",
                                  }}
                                  type="text"
                                  label="Name of Certificate Issuer"
                                  isRequired={true}
                                  placeholder="Enter issuer's name here ..."
                                  name="certificateIssuer"
                                  value={checkprefetchData?.IssuerName}
                                  onChange={(e) => {
                                    setcheckprefetchData((prevUserDetails) => {
                                      return {
                                        ...prevUserDetails,
                                        IssuerName: e.target.value,
                                      };
                                    });
                                  }}
                                />
                              </div>

                              <div style={{ marginTop: "12px" }}>
                                <label htmlFor="inputField">
                                  Certificate Valid Date
                                  <span className="asterisk">*</span>
                                </label>

                                <input
                                  style={{
                                    backgroundColor: "#FAFAFA",
                                    borderColor: "#bfbfbf",
                                    borderWidth: "1px",
                                    borderStyle: "solid",
                                    width: "366px",
                                    marginTop: "10px",
                                    height: "53px",
                                    borderRadius: "5px",
                                  }}
                                  label="Certificate Valid Date"
                                  isRequired={true}
                                  placeholder="Select Date"
                                  type="date"
                                  name="certificateIssueDate"
                                  value={checkprefetchData?.ValidDate}
                                  min={minDate}
                                  onChange={(e) => {
                                    setcheckprefetchData((prevUserDetails) => {
                                      return {
                                        ...prevUserDetails,
                                        ValidDate: e.target.value,
                                      };
                                    });
                                  }}
                                />
                              </div>
                              <br />
                              <div style={{ marginTop: "12px" }}>
                                <label htmlFor="inputField">
                                  Uploaded Certificate
                                  <span className="asterisk">*</span>
                                </label>
                              </div>
                              <img
                                alt="Hello"
                                src={`${process.env.REACT_APP_CDN_PVT_URL}UserEquipmentCertificates/${checkprefetchData?.CertificateFileReference}`}
                                //  src={checkprefetchData?.CertificateFileReference}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "368px",
                                  height: "192px",
                                  marginTop: "12px",
                                  // marginLeft: " 44px",
                                }}
                              />

                              {/* <ImageUpload
                              label="Certificate Image"
                              name="certificateImage"
                              src={checkprefetchData?.CertificateFileReference}
                              helperText=""
                              formikProps={{
                                setFieldValue,
                                errors,
                                touched,
                              }}
                            /> */}
                            </div>
                          </>
                        )}
                      </div>
                    </Flex>
                  </Flex>
                </form>
              </>
            )}
          </Formik>

          <Box>
            <Flex
              justifyContent="center"
              alignItems="center"
              bgColor="#fff"
              h="78px"
              style={{ boxShadow: "0px -10px 15px #2c2c2c0f" }}
            >
              <Button
                type="submit"
                variant="gradient"
                fontWeight="semibold"
                width="400px"
                margin="0 auto"
                maxW="400px"
                form="equipment-skill-form"
              >
                Submit
              </Button>
            </Flex>
          </Box>
        </div>
      </div>
    )
  );
};
