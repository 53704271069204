import { DOCUMENT_FLOW_PAGES } from "../../../constants/navigationConstants";
import {fetchHandler} from "../../../../index";

export const INDIVIDUAL_ROUTE_CALL_PROMISES = [{url: "/GetAllUserEquipments", documentName: "Expertise"}, {url: "/GetUserAllUploadedDocument", documentName: "Verification Document"},{url: "/GetEquipmentLicenceDetails", name : "License"}]

export const getDocument = async (api) => {
    try {
      const {data} = await fetchHandler(api);

      if (data.Status === 200) {
        return data;
      } else {
        throw data;
      }
    } catch (error) {
      throw error;
    }
  };

export const INDIVIDUAL_CALLS = async (array, token) => {
    try {
      const promises = array.map((object) => getDocument(object.url));
      const responses = await Promise.allSettled(promises);
      const responseData = responses.map((response) => {
        if(response.status === "fulfilled"){
            return response.value.Data
        }
      
      })
      
      const [expertiseDocument, verificationDocument, licenses] = responseData
      return {expertiseDocument, verificationDocument, licenses}
    } catch (error) {
      console.error("Error:", error);
    }
  };

export const generateIndividualDocumentRouting = (documentObject) => { 
  const routeArray = [];
 if(!documentObject.ProfilePicture){
  routeArray.push(DOCUMENT_FLOW_PAGES.PARTNER.PROFILE_PICTURE)
 }
 if(!documentObject.E9UserRole){
  routeArray.push(DOCUMENT_FLOW_PAGES.PARTNER.E9_ROLE)
 }
 if(documentObject.expertiseDocument && documentObject.expertiseDocument.length === 0){
  routeArray.push(DOCUMENT_FLOW_PAGES.PARTNER.EQUIPMENT_SKILL)
 }
 if(documentObject.verificationDocument && Array.from(Object.keys(documentObject.verificationDocument)).length === 0){
  routeArray.push(DOCUMENT_FLOW_PAGES.PARTNER.VERIFICATION_DOCUMENT)
 }
 if(documentObject.licenses && Array.from(Object.keys(documentObject.licenses)).length === 0 ){
  routeArray.push(DOCUMENT_FLOW_PAGES.PARTNER.LICENSES)
 }
return routeArray;

}


  