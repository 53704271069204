import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPremiumDetailsById } from "../../../services/premium/premium";

export const getPremiumDetailsThunk = createAsyncThunk("/purchasePremium/premiumDetailsById", async(id, {rejectWithValue}) =>{
    try{
     const data = await getPremiumDetailsById(id);
     return data
    }
    catch(error){
    rejectWithValue(error)
    }
})