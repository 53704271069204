import { convertFromBase64, convertToBase64 } from "../../../utils/formatConverter/convertToBase64";

const setSessionStorageItems = (key, value) => {
  if (!value) {
    return sessionStorage.setItem(key, "")
  }
  if (typeof (value) === "object") {
    return sessionStorage.setItem(convertToBase64(key), JSON.stringify(value))
  }
  return sessionStorage.setItem(convertToBase64(key), value);
}

const getSessionStorageItems = (key) => {

  if (sessionStorage.getItem(convertToBase64(key))) {
    if (sessionStorage.getItem(convertToBase64(key)).indexOf("[") > -1 || sessionStorage.getItem(convertToBase64(key)).indexOf("{") > -1) {
      return JSON.parse(sessionStorage.getItem(convertToBase64(key)));
    }
    else {
      return sessionStorage.getItem(convertToBase64(key));
    }
  }
  else {
    return null;
  }
}


export { setSessionStorageItems, getSessionStorageItems };