import { 
    getCountriesFromLocalStore,
    getBusinessTypesFromLocalStore, 
    getUserTypesFromLocalStore, 
    getAllLangaugesFromLocalStore,
    getDefaultAppLanguage
 } from "../localStorage";

export const initialState = {
    Countries: getCountriesFromLocalStore() || null,
    Languages:  getAllLangaugesFromLocalStore() || null,
    BusinessTypes: getBusinessTypesFromLocalStore() || null,
    UserTypes: getUserTypesFromLocalStore() || null,
    loading: false,
    error: "",
    defaultLanguage : getDefaultAppLanguage() || null,
  };