import { useEffect, useState } from "react";
import { NewSideMenuLayout } from "../../../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { Box, Button, Flex } from "@chakra-ui/react";
import { RouteStepper } from "../../../../components/Stepper/Stepper";
import { useDispatch, useSelector } from "react-redux";
import { EquipmentDetailsForm } from "../../components/EquipmentDetailsForm";
import { EquipmentOtherDetailsForm } from "../../components/EquipmentOtherDetailsForm";
import { EquipmentPurchaseDetailsForm } from "../../components/EquipmentPurchaseDetailsForm";
import { EquipmentMediaDetails } from "../../components/EquipmentMediaDetailsPage";
import { useNavigate } from "react-router";
import { resetEquipmentListingSlice } from "../../slice/equipmentListingSlice";
import { getEditAssetDetailsThunk } from "../../thunks/getEditAssetDetailsThunk";
import { useSearchParams } from "react-router-dom";

import { Helmet } from "react-helmet";
import { V5FormFooterLayout } from "../../../../layout/V5FormFooterLayout/V5FormFooterLayout";

export const EquipmentListingForm = () => {
  const { stepperRoute, stepperIndex,isLoading} = useSelector(
    (state) => state.addEquipment
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    handleFormRender(stepperIndex);
    handleButtonId(stepperIndex);
  }, [stepperIndex]);

  useEffect(() => {
    if (searchParams.get("mode") && searchParams.get("assetId")) {
      if (searchParams.get("mode") === "edit") {
        setIsEdit(true);
        const assetId = searchParams.get("assetId");
        // dispatch(setStepperIndex(-1))
        try {
          (async () => {
            await dispatch(getEditAssetDetailsThunk(assetId)).unwrap();
            // dispatch(setStepperIndex(0))
          })();
        } catch (error) {}
      } else {
        setIsEdit(false);
        dispatch(resetEquipmentListingSlice());
        navigate("/equipment-store");
      }
    } else {
      setIsEdit(false);
      dispatch(resetEquipmentListingSlice());
      // navigate("/equipment-store");
    }
  }, [searchParams]);

  const handleFormRender = (index) => {
    switch (parseInt(index)) {
      case 0:
        return <EquipmentDetailsForm editMode={isEdit} />;
      case 1:
        return <EquipmentOtherDetailsForm editMode={isEdit} />;
      case 2:
        return <EquipmentPurchaseDetailsForm editMode={isEdit} />;
      case 3:
        return <EquipmentMediaDetails />;
    }
  };

  const handleButtonId = (index) => {
    switch (parseInt(index)) {
      case 0:
        return "equipment-details-form";
      case 1:
        return "equipment-other-details";
      case 2:
        return "equipment-purchase-details";
    }
  };

  return (
    // <NewSideMenuLayout gridTemplateRows="80px 1fr 78px">

    //    <Helmet>
    //   <link
    //     rel="Business Owner Login, Equipment Listing"
    //     href="equip9.com/e9-services/equipment-listing"
    //   />
    //   <meta name="keywords" content="equipment rentals"/>
    //   <title>Explore Top Equipment Rentals: Empower Your Business Growth</title>
    //   <meta name="description" content="Find the best equipment rentals to fuel your business growth.
    //    Browse our extensive listings and elevate your operations today!"/>
    //   <h1>Discover Quality Equipment Rentals to Elevate Your Business!</h1>
    //   <h2>Discover the Advantages of Our Equipment Rentals for Your Business</h2>
    //   <h3>Explore Our Wide Range of Equipment Rentals for Business Owners</h3>
    //   </Helmet>

    //   <div
    //     style={{
    //       margin: "0px 52px",
    //       backgroundColor: "#92929204",
    //       paddingTop: "1em",
    //     }}
    //   >
    //     <div style={{ marginBottom: "32px" }}>
    //       <h1 style={{ fontSize: "32px", fontWeight: 700 }}>
    //         {isEdit ? "Edit Asset" : "Add Asset"}
    //       </h1>
    //     </div>
    //     <Flex flexDirection="column" gap="1em" height="100%">
    //       <Box
    //         h="54px"
    //         dropShadow="0px 10px 30px #2C2C2C14"
    //         bgColor="#FFFFFF"
    //         paddingRight="1em"
    //         paddingLeft="1em"
    //       >
    //         <RouteStepper array={stepperRoute} activeIndex={stepperIndex} />
    //       </Box>
    //       <Box maxH="347px" h="100%" overflowY="scroll" padding="1em 1.5em">
    //         {handleFormRender(stepperIndex)}
    //       </Box>
    //     </Flex>
    //   </div>

    //   <div
    //     style={{
    //       width: "100%",
    //       backgroundColor: "white",
    //       display: "flex",
    //       justifyContent: "center",
    //       alignItems: "center",
    //       maxHeight: "78px",
    //     }}
    //   >
    //     {stepperIndex < stepperRoute.length - 1 ? (
    //       <Button
    //         type="submit"
    //         variant="gradient"
    //         w="347px"
    //         form={handleButtonId(stepperIndex)}
    //       >
    //         {" "}
    //         {stepperIndex < stepperRoute.length - 1 ? "Next" : "Finish"}
    //       </Button>
    //     ) : (
    //       <Button
    //         // type="submit"
    //         variant="gradient"
    //         w="347px"
    //         form={handleButtonId(stepperIndex)}
    //         onClick={() => {
    //           dispatch(resetEquipmentListingSlice());
    //           navigate("/equipment-store");
    //         }}
    //       >
    //         {stepperIndex < stepperRoute.length - 1 ? "Next" : "Finish"}
    //       </Button>
    //     )}
    //   </div>
    // </NewSideMenuLayout>
    <V5FormFooterLayout
      title={isEdit ? "Edit Asset" : "Add Asset"}
      stepperArray={stepperRoute}
      stepperIndex={stepperIndex}
      primaryButtonText={
        stepperIndex < stepperRoute.length - 1 ? "Next" : "Finish"
      }
      primaryButtonClickAction={
        stepperIndex < stepperRoute.length - 1
          ? () => {}
          : () => {
              dispatch(resetEquipmentListingSlice());
              navigate("/equipment-store");
            }
      }
      formId={handleButtonId(stepperIndex)}
      isLoading={isLoading}
    >
      {handleFormRender(stepperIndex)}
    </V5FormFooterLayout>
  );
};
