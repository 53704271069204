import React, { useState, useEffect } from "react";
import { openIndexedDB } from "../../../../../../../staticData/CommonMethod";
import { ReactSVG } from "react-svg";
import { background } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { deleteMaterialVideo, getMaterialAdditionalDetails } from "../../../../../../services/materials/materials";

export const EditShowVideos = ({ refreshVideos }) => {
    const userData = useSelector((state) => state.user);
  const [storeVideos, setStoreVideos] = useState([]);
  const [editPhoto, seteditPhoto] = useState(null);

  useEffect(() => {
    const fetchMaterialStep2 = async () => {
      try {
        const response = await getMaterialAdditionalDetails(
          userData.token,
          parseInt(localStorage.getItem("EDIT_MATERIAL_ID"))
        );
        // console.log(response, "responseMaterila");
        seteditPhoto(response?.data?.Data?.MaterialData[0]?.VideoList);
      } catch (err) {
        throw new Error(err);
      }
    };
    fetchMaterialStep2();
  }, []);

//   console.log(editPhoto,"editVideos")

  const convertObjectintoVideo = JSON.parse(editPhoto);

  // console.log(convertObjectintoVideo,"editVideos")

  /********************** */

  const logDataFromIndexedDB = async () => {
    try {
      const db = await openIndexedDB();
      const transaction = db.transaction(["videos"], "readonly");
      const store = transaction.objectStore("videos");
      const request = store.getAll();

      request.onsuccess = (event) => {
        setStoreVideos(event.target.result);
      };

      request.onerror = (event) => {
        console.error("Error reading data:", event.target.error);
      };
    } catch (error) {
      console.error("Error opening database:", error);
    }
  };

  useEffect(() => {
    logDataFromIndexedDB();
  }, [refreshVideos]);

  const handleDeleteFile = async (videoId) => {
    if (!videoId && videoId !== 0) {
      console.error("Invalid video ID for deletion");
      return;
    }

    try {
      const db = await openIndexedDB();
      const transaction = db.transaction(["videos"], "readwrite");
      const store = transaction.objectStore("videos");
      const request = store.delete(videoId);

      request.onsuccess = () => {
        // console.log("Video deleted from IndexedDB:", videoId);
        setStoreVideos(prevVideos => prevVideos.filter(video => video.id !== videoId));
      };

      request.onerror = (event) => {
        console.error("Error deleting video from IndexedDB:", event.target.error);
      };
    } catch (error) {
      console.error("Error accessing IndexedDB:", error);
    }
  };

   const videoPositions = storeVideos.map(video => video.position);

  const placeholders = [
    { id: '1', name: "Front Video" },
    { id: '2', name: "Back Video" },
    { id: '3', name: "Left Video" },
    { id: '4', name: "Right Video" },
    { id: '5', name: "Top Video" },
    { id: '6', name: "Bottom Video" },
    { id: '7', name: "Other" },
  ];


  const sortedVideos = [...storeVideos].sort(
    (a, b) => (a.position || 0) - (b.position || 0)
  );


  const base64ToBlobUrl = (base64String) => {
    const byteCharacters = atob(base64String.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'video/mp4' });
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  };


  const videosById = new Map(storeVideos.map(video => [video.id, video]));

//   console.log(videosById);


  const displayList = placeholders.map(placeholder => {
    const video = videosById.get(placeholder.id);
    return video ? {
      ...video, 
      isPlaceholder: false,
      video: base64ToBlobUrl(video.file) // Convert Base64 to Blob URL
    } : {
      ...placeholder, 
      isPlaceholder: true, 
      video: null
    };
  });

  const MaterialId=parseInt(localStorage.getItem("EDIT_MATERIAL_ID"));


  const handleVideoDelete=async(videoTypeId)=>{
 
    const data=await deleteMaterialVideo(MaterialId,videoTypeId);
  }

  return (
    <div
      style={{
        marginTop: "22px",
        display: "flex",
        flexDirection: "row",
        gap: "32px",
        overflowX: "auto",
        maxWidth: "1130px",
        height: "242px",
      }}
    >
        {
            convertObjectintoVideo?.length>0 ?(<>
            {
                convertObjectintoVideo?.map((item,index)=>(<div
                    className="store-preview-videos"
                    key={index}
                    style={{ minWidth: "198px" }}
                  >
                    <div className="image-tag">
                      {item.name && <p className="front-text-videos">{item.name}</p>}
                    </div>
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        border: "1px solid var(--Light-50, #e3e5e8)",
                        borderRadius: "3px 3px 0px 0px",
                      }}
                    >
                      {item.VideoFileRef ? (
                        <video style={{ width: "100%", height: "100%" }} controls>
                          <source src={`${process.env.REACT_APP_CDN_PVT_URL}${item?.VideoFileRef}`} />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <p className="add-video-placeholder-tag">Add Video</p>
                      )}
                    </div>
                    <div
                      className="image-tag-delete"
                      onClick={() => handleVideoDelete(item?.VideoTypeId)}
                    >
                      <ReactSVG
                        style={{
                          cursor: "pointer",
                          marginLeft: "75px",
                          marginTop: "5px",
                        }}
                        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/garbage.svg`}
                      />
                    </div>
                  </div>))

            }

            </>):(<>

                {displayList.map((item, index) => (
        <div
          className="store-preview-videos"
          key={index}
          style={{ minWidth: "198px", flexGrow: 1 }}
        >
          <div className="image-tag">
            {/* Only display the name if it's not empty */}
            {item.name && <p className="front-text-videos">{item.name}</p>}
          </div>
          <div
            style={{
              height: "100%",
              width: "100%",
              border: "1px solid var(--Light-50, #e3e5e8)",
              borderRadius: "3px 3px 0px 0px",
            }}
          >
            {item.video ? (
              <video style={{ width: "100%", height: "100%" }} controls>
                <source src={item.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <p className="add-video-placeholder-tag">Add Video</p>
            )}
          </div>
          <div
            className="image-tag-delete"
            onClick={() => handleDeleteFile(item.id)}
          >
            <ReactSVG
              style={{
                cursor: "pointer",
                marginLeft: "75px",
                marginTop: "5px",
              }}
              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/garbage.svg`}
            />
          </div>
        </div>
      ))}
            </>)
        }
    
    </div>
  );


};
