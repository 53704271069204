import { API_CONSTANTS } from "../../../constants/apiConstants"
import { convertToIdValuePairs } from "../../helpers/dataConversion/convertToIdValuePairs";
import {fetchHandler} from "../../../index"

export const getWorkingShiftTypes = async() => {
    try{
      const {data} = await fetchHandler(API_CONSTANTS.GET_SHIFT_TYPE_LIST);
      if(data.Status === 200 && Object.keys(data.Data).length > 0){
        const requiredData = data.Data.ShiftTypeList.map(({ShiftTypeId, ShiftTypeValue}) => (convertToIdValuePairs(ShiftTypeId, ShiftTypeValue)));
        return requiredData
      }
      return null;
    }
    catch(error){
        throw error
    }
}

export const getJobDurations = async() => {
  try{
    const {data} = await fetchHandler(API_CONSTANTS.GET_JOB_DURATION_LIST);
    if(data.Status === 200 && Object.keys(data.Data).length > 0){
      const requiredData = data.Data.JobDurationList.map(({JobDurationId, JobDurationValue}) => (convertToIdValuePairs(JobDurationId, JobDurationValue)));
      return requiredData
    }
    return null;
  }
  catch(error){
      throw error
  }
}

export const getWorkingHourTypes = async() => {
  try{
    const {data} = await fetchHandler(API_CONSTANTS.GET_WORKING_HOUR_TYPE_LIST);
    if(data.Status === 200 && Object.keys(data.Data).length > 0){
      const requiredData = data.Data.WorkingHourTypeList.map(({WorkingHourId, WorkingHourValue}) => (convertToIdValuePairs(WorkingHourId, WorkingHourValue)));
      return requiredData
    }
    return null;
  }
  catch(error){
      throw error
  }

}

export const getLunchBreakTypes = async() => {
  try{
    const {data} = await fetchHandler(API_CONSTANTS.GET_LUNCH_BREAK_TYPE_LIST);
    if(data.Status === 200 && Object.keys(data.Data).length > 0){
      const requiredData = data.Data.LunchBreakTypeList.map(({LunchBreakTypeId, LunchBreakTypeValue}) => (convertToIdValuePairs(LunchBreakTypeId, LunchBreakTypeValue)));
      return requiredData
    }
    return null;
  }
  catch(error){
      throw error
  }
}