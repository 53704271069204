import { Navbar } from "../components/Navbar/Navbar"
import "../../App.css"
import Footer from "../components/Footer/Footer"


export const WebPageLayout = ({children}) => {
    return(
        <>
        <Navbar/>
        {children}
        <Footer />
        </>

    )
}