import { Box, Button, Flex, Text } from "@chakra-ui/react";
import React from "react";

const OwnersSentCards = ({
  isVerified,
  E9OwnerMpConnectStatusId,
  MobileNumber,
  OwnerName,
  UserTypeValue,
  UserRole,
  ResponseTypeValue,
  RequestSentDate,
  StateName,
  CountryName,
  isReceivedCards = false,
  RequestReceivedDate,
  onAcceptButtonClick = () => {},
  onRejectButtonClick = () => {},
  onRevokeRequestClick = () => {},
}) => {


  const handlePhoneNumber = (number, responseType) => {
    if (responseType?.toLowerCase().includes("accepted")) {
      return (
        <> {"+"} {number}</>
      );
    } else {
      return (
        <>
         {"+" +
              number
                .toString()
                .replace(number.toString().slice(2, 11), "XXXXXXXXX")}
        </>
      );
    }
  };

  return (
    <Box
      maxW="540px"
      width="530px"
      border="1px solid #E3E5E8"
      maxH="180px"
      minH="170px"
      boxShadow="0px 6px 15px -3px #00000029"
      borderRadius="4px"
    >
      <Box display="flex" flexDir="column">
        <Flex
          justifyContent="space-between"
          width="100%"
          padding="0.50em 1em"
          alignItems="center"
        >
          <Text></Text>
          <Button
            borderRadius="100px"
            width="110px"
            height="30px"
            backgroundColor={isVerified ? "#319F0060" : "rgba(214, 0, 0, 0.40)"}
          >
            <Text color="#fff" fontSize="12px" fontWeight="600">
              {isVerified ? "Verified" : "Not Verified"}
            </Text>
          </Button>
        </Flex>

        <Flex padding="0em 1em" gap="1em">
          <Box maxH="80px" minH="75px" maxW="80px" minw="75px">
            <img
              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/DelarshipDefaultImage.png`}
              style={{ height: "100%", width: "100%" }}
            />
          </Box>

          <Flex direction="column" width="100%" gap="0.50em">
            <Box>
              {isReceivedCards ? (
                <Text color="#141619" fontWeight="600" fontSize="14px">
                  BO/EO {OwnerName} • {UserTypeValue} • {UserRole} Has Requested
                  To Contact You{" "}
                </Text>
              ) : (
                <Text color="#141619" fontWeight="600" fontSize="14px">
                  BO/EO {OwnerName} • {UserTypeValue} • {UserRole}{" "}
                </Text>
              )}

              {isReceivedCards ? (
                <Text color="#767676" fontWeight="600" fontSize="16px">
                  {" "}
                </Text>
              ) : (
                <Text color="#767676" fontWeight="600" fontSize="16px">
                  {" "}
                  Location :{" "}
                  {StateName ? `${StateName}, ${CountryName}` : "N.A"}
                </Text>
              )}

              {isReceivedCards ? (
                <Text color="#767676" fontWeight="600" fontSize="14px">
                  {" "}
                  Request receives on: {RequestReceivedDate}{" "}
                </Text>
              ) : (
                <Text color="#767676" fontWeight="600" fontSize="14px">
                  {" "}
                  Request receives on: {RequestSentDate}{" "}
                </Text>
              )}
            </Box>

            <Flex width="100%" justifyContent="space-between">
              <Button
                leftIcon={
                  <img
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/call.svg`}
                  />
                }
                color="#002961"
                fontWeight="700"
                border="none"
                backgroundColor="transparent"
                boxShadow="none"
                _hover={{
                  backgroundColor: "none",
                }}
              >
                &nbsp;
                {handlePhoneNumber(MobileNumber, ResponseTypeValue)}  

                {/* {ResponseTypeValue.toLowerCase() ===
                "Request Received".toLowerCase()
                  ? MobileNumber.toString().replace(
                      /(\d{2})\d+(\d{1})/,
                      "$1XXXXXXXXX$2"
                    )
                  : MobileNumber}{" "} */}
              </Button>

              {isReceivedCards ? (
                ResponseTypeValue?.toString()?.toLowerCase() ===
                "request accepted" ? (
                  <Button
                    backgroundColor="transparent"
                    border="none"
                    boxShadow="none"
                    color="#319F00"
                    fontSize="14px"
                    fontStyle="600"
                    _hover={{
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    }}
                    leftIcon={
                      <img
                        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/tick.svg`}
                      />
                    }
                  >
                    {ResponseTypeValue}
                  </Button>
                ) : ResponseTypeValue?.toString()?.toLowerCase() ===
                  "request rejected" ? (
                  <Button
                    backgroundColor="transparent"
                    border="none"
                    boxShadow="none"
                    color="#D60000"
                    fontSize="14px"
                    fontStyle="600"
                    _hover={{
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    }}
                    leftIcon={
                      <img
                        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/cross.svg`}
                      />
                    }
                  >
                    {ResponseTypeValue}
                  </Button>
                ) : (
                  <Flex gap="1em">
                    <Button
                      width="100px"
                      color="#D60000"
                      fontWeight="700"
                      border="1px solid #D60000"
                      backgroundColor="transparent"
                      boxShadow="none"
                      onClick={onRejectButtonClick}
                      _hover={{
                        backgroundColor: "none",
                      }}
                    >
                      Reject
                    </Button>
                    <Button
                      width="100px"
                      color="#319F00"
                      fontWeight="700"
                      border="1px solid #319F00"
                      backgroundColor="transparent"
                      boxShadow="none"
                      _hover={{
                        backgroundColor: "none",
                      }}
                      onClick={onAcceptButtonClick}
                    >
                      Accept
                    </Button>
                  </Flex>
                )
              ) : ResponseTypeValue?.toString()?.toLowerCase() ===
                "request accepted" ? (
                <Button
                  backgroundColor="transparent"
                  border="none"
                  boxShadow="none"
                  color="#319F00"
                  fontSize="14px"
                  fontStyle="600"
                  _hover={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  }}
                  leftIcon={
                    <img
                      src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/tick.svg`}
                    />
                  }
                >
                  {ResponseTypeValue}
                </Button>
              ) : ResponseTypeValue?.toString()?.toLowerCase() ===
                "request rejected" ? (
                <Button
                  backgroundColor="transparent"
                  border="none"
                  boxShadow="none"
                  color="#D60000"
                  fontSize="14px"
                  fontStyle="600"
                  _hover={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  }}
                  leftIcon={
                    <img
                      src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/cross.svg`}
                    />
                  }
                >
                  {ResponseTypeValue}
                </Button>
              ) : (
                <Button
                  color="#F79C00"
                  fontWeight="700"
                  border="1px solid #F79C00"
                  backgroundColor="transparent"
                  boxShadow="none"
                  _hover={{
                    backgroundColor: "none",
                  }}
                  onClick={onRevokeRequestClick}
                >
                  {ResponseTypeValue}
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default OwnersSentCards;
