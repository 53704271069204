import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Box, Flex, FormErrorMessage, useToast } from "@chakra-ui/react";
import { FormikInput } from "../../../components/Input/FormikInput";
import { useDispatch, useSelector } from "react-redux";
import { FormikPhoneNumberInput } from "../../../components/Input/PhoneNumberInput/FormikPhoneNumberInput";
import {
  increaseStepperIndex,
  setAllDealersshipDataById,
  setContact,
  setDealerName,
  setEmail,
  setISDCode,
  setIsLoading,
  setLatitude,
  setLocation,
  setLongitude,
  setRegionPayload,
  setTaxNumber,
  setWebsite,
} from "../../MyBusinessDealershipDetail/slice/myBusinessDealershipSlice";
import { postDealershipStepOneThunk } from "../../MyBusinessDealershipDetail/thunk/postDealershipStepOne";
import "./PostDealershipStepsCSS.css";
import { getDealershipDetailsById } from "../../../services/postDealership/postDealership";
import { getSessionStorageItems } from "../../../helpers/sessionStorage/sessionStorage";
import { updateDealershipStepOneThunk } from "../../MyBusinessDealershipDetail/thunk/updateDealershipStepOneThunk";
import {
  REGEX_EMAIL_CHECKER,
  REGEX_WEBSITE_CHECKER,
} from "../../../helpers/regex";
import { LocationInput } from "../../../components/LocationInput/LocationInput";

const AddDealershipStepOne = () => {
  const { Countries } = useSelector((state) => state.masterData);
  const appConfig = useSelector((state) => state.appConfig);
  const { token, accountDetails } = useSelector((state) => state.user);
  const { name, location, contact, email, website } = useSelector(
    (state) => state.myBusinessDealership
  );
  const gstTaxNumber = accountDetails?.CompanyDetails?.TaxNumber;
  const [dealershipDetails, setDealershipDetails] = useState();
  const editGuuid = getSessionStorageItems("EDIT_DEALERSHIP_GUUID");

  const toast = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const data = await getDealershipDetailsById(editGuuid, token);
      if (data?.Status === 200) {
        setDealershipDetails(data?.Data?.DealershipDetails);
        dispatch(setAllDealersshipDataById(data?.Data?.DealershipDetails));
        const regionPayload = JSON.parse(
          data?.Data?.DealershipDetails?.RegionDetails
        );
        if (regionPayload) {
          dispatch(setRegionPayload(regionPayload));
        }
      }
    })();
  }, [editGuuid]);

  const validationSchema = Yup.object({
    dealershipName: Yup.string()
      .required("Dealership Name is required")
      .max(100, "Max 100 Characters Allowed"),
    dealershipContact: Yup.number().required("Dealership Contact is required"),
    email: Yup.string().matches(REGEX_EMAIL_CHECKER, "Invalid Email"),
    website: Yup.string().matches(REGEX_WEBSITE_CHECKER, "Invalid Website"),
  });

  const initialValues = {
    dealershipName: name ? name : dealershipDetails?.DealershipData?.DealerName,
    dealershipLocation: location
      ? location
      : dealershipDetails?.DealershipData?.Location,
    dealershipContact: contact
      ? contact
      : dealershipDetails?.DealershipData?.OwnerMobileNumber,
    email: dealershipDetails?.DealershipData?.Email
      ? dealershipDetails?.DealershipData?.Email
      : email,
    website: dealershipDetails?.DealershipData?.Website
      ? dealershipDetails?.DealershipData?.Website
      : website,
    countryCode: appConfig.country ? appConfig.country.CountryIsdCode : "",
  };

  const handleSubmit = (values) => {
    if (editGuuid) {
      updateDealerrship(values);
    } else {
      postDealership(values);
    }

  };

  const updateDealerrship = async (values) => {
    dispatch(setDealerName(values.dealershipName));
    dispatch(setLocation(values.dealershipLocation.name));
    dispatch(setLatitude(values.dealershipLocation.lat));
    dispatch(setLongitude(values.dealershipLocation.lng));
    dispatch(setISDCode(values.countryCode));
    dispatch(setContact(values.dealershipContact));
    dispatch(setEmail(values.email));
    dispatch(setWebsite(values.website));
    dispatch(increaseStepperIndex());
    dispatch(setTaxNumber(gstTaxNumber));

    try {
      dispatch(setIsLoading(true));
      await dispatch(updateDealershipStepOneThunk()).unwrap();
      toast({
        title: "Details Updated Successfully",
        position: "top-right",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Failed To Update Details",
        position: "top-right",
        status: "error",
      });
    }finally{
      dispatch(setIsLoading(false));

    }
  };
  const postDealership = async (values) => {
    dispatch(setDealerName(values.dealershipName));
    dispatch(setLocation(values.dealershipLocation.name));
    dispatch(setLatitude(values.dealershipLocation.lat));
    dispatch(setLongitude(values.dealershipLocation.lng));
    dispatch(setISDCode(values.countryCode));
    dispatch(setContact(values.dealershipContact));
    dispatch(setEmail(values.email));
    dispatch(setWebsite(values.website));
    dispatch(increaseStepperIndex());
    dispatch(setTaxNumber(gstTaxNumber));
    try {
      dispatch(setIsLoading(true));

      await dispatch(postDealershipStepOneThunk()).unwrap();
      toast({
        title: "Details Saved Successfully",
        position: "top-right",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Failed ",
        position: "top-right",
        status: "error",
      });
    }finally{
      dispatch(setIsLoading(false));

    }
  };

  return (
    // <>
    //     <Flex className='form-one-demo' flexDirection={"column"} gap={"1em"}>
    //         <div
    //             style={{
    //                 margin: "0px 52px",
    //                 backgroundColor: "#92929204",
    //                 paddingTop: "1em",
    //             }}>
    //             <div >
    //                 <h1 style={{ fontSize: "32px", fontWeight: 700 }}>
    //                     Your Dealership Details
    //                 </h1>
    //                 <p className="my-business-text-description">Add Your Dealerships are here</p>
    //             </div>
    //         </div>

    //         <Flex flexDirection="column" gap="1em" height="100%">
    //             <Box
    //                 maxH="347px"
    //                 h="100%"
    //                 overflowY={"scroll"}
    //             >
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue }) => (
        <Form id="post-dealership-step-one">
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            padding={"2em"}
            gap={"1em"}
          >
            <FormikInput
              label="Dealership Name"
              name="dealershipName"
              placeholder="Enter Dealership Name ..."
              maxWidth="450px"
              isRequired={true}
              maxLength={100}
            />

            <FormErrorMessage />

            <FormikPhoneNumberInput
              label="Dealership Contact"
              name="dealershipContact"
              countrycodename="countryCode"
              placeholder="Enter Your Contact Here"
              dropdownValues={Countries}
              value={values.countryCode}
              maxWidth="450px"
              isRequired={true}
            />
            <FormErrorMessage />

            <Field name="dealershipLocation">
              {({ field }) => (
                <LocationInput
                  name={field.name}
                  label="Dealership Location"
                  placeholder="Enter Location Here"
                  width="450px"
                  maxWidth="450px"
                  value={
                    values.dealershipLocation?.name
                      ? values?.dealershipLocation?.name
                      : values?.dealershipLocation
                  }
                  isRequired={true}
                  onChange={(value) => {
                    setFieldValue("dealershipLocation", value);
                  }}
                />
              )}
            </Field>

            <FormErrorMessage />

            <FormikInput
              label="Email"
              name="email"
              placeholder="Enter Email Here"
              maxWidth="450px"
            />

            <FormikInput
              label="Website"
              name="website"
              placeholder="Enter Website Here"
              maxWidth="450px"
            />
          </Flex>
        </Form>
      )}
    </Formik>
    //             </Box>
    //         </Flex>
    //     </Flex>
    // </>
  );
};

export default AddDealershipStepOne;
