import axios from "axios";
import { useEffect, useState } from "react";

/**
 * Custom React hook for geocoding based on latitude and longitude using the Google Geocoding API.
 *
 * @param {number} longitude - The longitude of the location for geocoding.
 * @param {number} latitude - The latitude of the location for geocoding.
 * @returns {Object} An object containing geocoded location, loading state, error, and geocoding function.
 * @property {string|null} geoCodeLocation - The geocoded location, represented as a string, or null if not available.
 * @property {boolean|null} loading - Indicates whether the geocoding process is in progress. True if loading, false if not, or null if not applicable.
 * @property {Error|null} error - An Error object representing any errors that occurred during the geocoding process, or null if no errors.
 * @property {function} handleGeocoding - A function to trigger the geocoding process. It takes longitude and latitude as parameters and updates the state accordingly.
 * @example
 * // Usage in a React component
 * const { geoCodeLocation, loading, error, handleGeocoding } = useGeocode(-73.935242, 40.730610);
 * // You can use geoCodeLocation, loading, and error in your component based on the geocoding results.
 * // Call handleGeocoding whenever you want to trigger the geocoding process.
 */

export const useGeocode = (longitude, latitude) => {
  const [geoCodeLocation, setGeoCodeLocation] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  
  useEffect(() => {
    if (longitude && latitude) {
      handleGeocoding(longitude, latitude);
    }
  }, [longitude, latitude]);

  /**
   * Function to trigger the geocoding process.
   *
   * @param {number} longitude - The longitude of the location for geocoding.
   * @param {number} latitude - The latitude of the location for geocoding.
   * @returns {void}
   */

  const handleGeocoding = async (longitude, latitude) => {
    setLoading(true);
    try {
      const data = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_LOCATION_API_KEY}`
      );
      setGeoCodeLocation(data.data.plus_code.compound_code);
      setError(null);
    } catch (error) {
      setError(error);
      setGeoCodeLocation(null);
    } finally {
      setLoading(false);
    }
  };
  return { geoCodeLocation, loading, error, handleGeocoding };
};
