import { AboutMain } from "./AboutMain";
import Pattern from "./Pattern";
import Client from "./Client";
// import Carousel from "./Carousel";
import { Carousel } from "../../components/Carousel/Carousel";
import Sectionfour from "./Sectionfour";
import Members from "./Members";
import { useEffect, useState } from "react";
// import {
//   GET_ALL_MEMBERS_IMAGE_LINKS,
//   IS_MEMEBER_FEMALE,
// } from "../../helpers/getS3Images";
import { WebPageLayout } from "../../Layout/WebpageLayout";
import { Helmet } from "react-helmet";
import "../Home/Home.css";
import { ReactSVG } from "react-svg";
import { Pagination } from "swiper/modules";
import { MobileCarousel } from "../../components/MobileCarosel/MobileCarosel";
import { MobileResponsiveFooter } from "../../components/MobileResposiveFooter/MobileResponsiveFooter";
import { MobileHeader } from "../../components/MobileHeader/MobileHeader";
import { ImageSlider } from "../../components/ImageSlider/ImageSlider";
import aboutsitemap from "./seo/About.xml";

export const About = () => {

  // useEffect(() => {
  //   (async () => {
  //     const members = await GET_ALL_MEMBERS_IMAGE_LINKS();
  //     const arrangedMembers = members.reduce(
  //       (allMembers, currentMember) => {
  //         if (IS_MEMEBER_FEMALE(currentMember)) {
  //           allMembers.females.push(currentMember);
  //         } else {
  //           allMembers.males.push(currentMember);
  //         }
  //         return allMembers;
  //       },
  //       { females: [], males: [] }
  //     );
  //     setMemberImages([...arrangedMembers.females, ...arrangedMembers.males]);
  //   })();
  // }, []);

  return (
    <>
    
          <WebPageLayout>
            <Helmet>
              <title>
                About Us - EQUIP9TM | Revolutionizing the Heavy Equipment
                Industry
              </title>
              <meta
                name="keyword"
                content=" Our all-in-one platform connects operators, rentals, mechanics, and more in a single app. Explore used equipment, e-learning, and spare parts with ease."
              />
              <link rel="About Us" href="https://equip9.com/about"></link>
            </Helmet>

            <Helmet>
            <title>Equip9 - Connecting Businesses with Skilled Heavy Equipment Operators</title>
            <meta name="keywords" content="heavy equipment operator"/>
            <meta name="description" content="EQUIP9: Your go-to for heavy equipment industry growth. Whether you're an operator, mechanic, rental service, dealer, 
            or part of any sector, we've got you covered." />
            <h1>Whether you're looking to hire skilled operators, find quality used equipment, or expand your knowledge 
              with e-learning courses, EQUIP9 has you covered</h1>
            <h2>Efficient Heavy Equipment Operator Services for Your Business Needs</h2>
            <h3>Discover Skilled Operators on Equip9's Online Platform</h3>
            <link rel="sitemap" type="application/xml" title="Sitemap" href={aboutsitemap} />


            </Helmet>
            
            <Carousel
              array={[
                `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/HeroCarousel/image3.png`,
                `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/HeroCarousel/image4.png`,
              ]}
            />
            <AboutMain />
            <Pattern />
            <Sectionfour />
            <Client />

            <Members/>
          </WebPageLayout>
      
    </>
  );
};
