
import {getE9FreeRedeemedDetailsOnContactRedeemed, getPremiumRedeemCountByUserIdServiceTypeId, getUserFreeRedeemDetailsById, getUserLeadsCheckForContactRedeemed, getUserPremiumRedeemCountById, getUserPurchasedLeadsById } from "../../../services/leadCheck/leadCheck";

async function processUserLeads(service_id) {
    const userLeads = await getUserPurchasedLeadsById(service_id);
    const freeRedeemed = await getUserFreeRedeemDetailsById(service_id);
    const premiumData = await getUserPremiumRedeemCountById(service_id);
  
    let subItems = [];
    let totalSubLeads = 0;
  
    if (userLeads.Data.LeadsData && userLeads.Data.LeadsData.length > 0) {
      subItems = userLeads.Data.LeadsData.map((item) => {
        totalSubLeads += item.LeadRemaining;
        return item;
      }).sort((a, b) => a.DaysRemaining - b.DaysRemaining);
    }
  
    return {
      subscription: {
        LeadsData: subItems,
        isSelected: false,
        LeadRemaining: totalSubLeads,
      },
      freeData: {
        LeadRemaining: freeRedeemed.Data.RemainingFreeContact,
        isSelected: !!freeRedeemed.Data.RemainingFreeContact,
      },
      premium: {
        LeadsData: premiumData[0],
        LeadRemaining: premiumData[0]?.RemainingCount || 0,
        isSelected: false,
      },
    };
  }
  
  async function processUserLeadsMP(service_id, uid) {
    const userLeads = await getUserLeadsCheckForContactRedeemed(service_id,uid); 
    const freeRedeemed = await getE9FreeRedeemedDetailsOnContactRedeemed(service_id);
    const premiumData = await getPremiumRedeemCountByUserIdServiceTypeId(service_id, uid);
  
    let subItems = [];
    let totalSubLeads = 0;
  
    if (userLeads.Data.LeadsData && userLeads.Data.LeadsData.length > 0) {
      subItems = userLeads.Data.LeadsData.map((item) => {
        totalSubLeads += item.LeadRemaining;
        return item;
      }).sort((a, b) => a.DaysRemaining - b.DaysRemaining);
    }
  
    return {
      subscription: {
        LeadsData: subItems,
        isSelected: false,
        LeadRemaining: totalSubLeads,
        ContactRedeemCount: userLeads.Data.ContactRedeemCount,
      },
      freeData: {
        LeadRemaining: freeRedeemed.Data.RemainingFreeContact,
        isSelected: false,
      },
      premium: {
        LeadsData: premiumData,
        LeadRemaining: premiumData?.RemainingCount || 0,
        isSelected: false,
      },
    };
  }

  
  export const getCheckUserLead = async (service_id, checkType, uid) => {
    const data = checkType === "normal"
      ? await processUserLeads(service_id)
      : await processUserLeadsMP(service_id, uid);
  
    return data;
  };
  