import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Textarea,
} from "@chakra-ui/react";
import { useField } from "formik";

export const FormikInput = ({
  label,
  type,
  value,
  placeholder,
  isRequired,
  isDisabled,
  isInvalid,
  name = "",
  errorMessage = " ",
  maxLength,
  maxWidth = "",
  h="52px",
  textArea = false,
  onKeyDown = () => {},
  prefixText = "",
}) => {
  const [field, meta] = useField(name);

  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  return (
    <FormControl
      isDisabled={isDisabled}
      isInvalid={meta.touched && meta.error}
      isRequired={isRequired}
      maxW={maxWidth ? maxWidth : textArea ? "" : "374px"}
    >
      <FormLabel>{label}</FormLabel>
      {textArea ? (
        <Textarea
          placeholder={placeholder}
          isInvalid={isInvalid || (meta.touched && meta.error)}
          maxLength={maxLength}
          h={h}
          border="1px solid #2c2c2c14"
          focusBorderColor="#2c2c2c"
          bgColor={field.value ? "#fff" : "#2c2c2c05"}
          {...field}
          onKeyDown={onKeyDown}
        />
      ) : prefixText ? (
        <InputGroup>
          <InputLeftAddon
            children={prefixText}
            height="52px"
            bgColor="#2c2c2c05"
          />

          <Input
            type={type}
            value={value}
            min={getCurrentDate()}
            placeholder={placeholder}
            isInvalid={isInvalid || (meta.touched && meta.error)}
            {...field}
            maxLength={maxLength}
            h="52px"
            border="1px solid #2c2c2c14"
            focusBorderColor="#2c2c2c"
            bgColor={field.value ? "#fff" : "#2c2c2c05"}
            onKeyDown={onKeyDown}
          />
        </InputGroup>
      ) : (
        <Input
          type={type}
          value={value}
          min={getCurrentDate()}
          placeholder={placeholder}
          isInvalid={isInvalid || (meta.touched && meta.error)}
          {...field}
          maxLength={maxLength}
          h="52px"
          border="1px solid #2c2c2c14"
          focusBorderColor="#2c2c2c"
          bgColor={field.value ? "#fff" : "#2c2c2c05"}
          onKeyDown={onKeyDown}
        />
      )}
      <FormErrorMessage>
        {meta.touched && meta.error ? meta.error : errorMessage}
      </FormErrorMessage>
    </FormControl>
  );
};
