import { createAsyncThunk } from "@reduxjs/toolkit";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";
import { getMaterialMarketplaceList } from "../../../services/materials/materials";


export const oilLoadMoreDataThunk = createAsyncThunk("materials/loadMoreCards", async(_, {getState}) => {

    const oilId = convertToBase64('2')
    const {cardCount , paginationCount} = getState().oilMaterialMarketplace

try{
  
    const data = await getMaterialMarketplaceList(cardCount,(paginationCount * cardCount),oilId);
    
    
    if(data.Status === 200 && Object.keys(data.Data).length > 0){
        return data.Data
    }

   
}
catch(error){
}
})