import { QrPopUp } from "../../../../webapp/components/ScanQrCodeModal/QrPopUp";
import logo from "../../../../assets/logo/equip9_logo_dark.png";
import { MarketplaceHeader } from "../../../../webapp/components/MarketplacHeader/MarketplaceHeader";
import { MarketplaceHoc } from "../../../../webapp/features/marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { useDispatch, useSelector } from "react-redux";
import { opJobLoadMoreDeferred } from "../../Product/thunk/opJobLoadMoreDeferred";
import { getOpJobDeferredThunk } from "../../Product/thunk/getOpJobDeferredThunk";
import { useEffect } from "react";
import { v4 as uuid } from "uuid";
import { openQrModal } from "../../../../webapp/features/dashboard/slice/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { OpenJobMarketplaceCard } from "../job/openJobMarketplaceCard/OpenJobMarketplaceCard";
import { Flex } from "@chakra-ui/react";
import BrandHeader from "./BrandHeader";

export const OperatorJobDeferredMarketplace = () => {
  const opJobDeferred = useSelector((state) => state.opJobDeferred);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getOpJobDeferredThunk());
  }, []);

  const renderCards = (array = []) => {
    const renderJsx =
      array && array.length > 0 ? (
        array.map((operatorJobs) => (
          <OpenJobMarketplaceCard
            key={uuid()}
            jobId={operatorJobs.JobId}
            name={operatorJobs.JobTitle}
            location={`${operatorJobs.CityName},${operatorJobs.StateName}, ${operatorJobs.CountryName}`}
            money={operatorJobs.Salary ? `₹${operatorJobs.Salary}` : "N.A."}
            cycle={
              operatorJobs.SalaryTypeValue
                ? `${operatorJobs.SalaryTypeValue}`
                : "N.A."
            }
            onClick={() => {
              // dispatch(openQrModal());
              navigate(
                `/deferred/marketplace/Job/operator/${operatorJobs.JobId}`
              );
            }}
            postedOn={
              operatorJobs.JobPostedDateTime
                ? operatorJobs.JobPostedDateTime.split(",")[1]
                : "NA"
            }
          />
        ))
      ) : (
        <p>No data to display</p>
      );
    return renderJsx;
  };

  return (
    <>
      <QrPopUp />
      <BrandHeader />

      <div style={{ padding: "10px 52px" }}>
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          borderBottom={"1px solid #333333"}
          marginBottom={"1em"}
        >
          <h1
            style={{
              fontSize: "32px",
              fontWeight: "700",
              marginBottom: "0.5em",
            }}
          >
            {"Jobs For Operator Partners"}
          </h1>
        </Flex>

        <MarketplaceHoc
          marketplaceName="Deferred Marketplace"
          marketplaceType={"partners"}
          dataArray={opJobDeferred?.data}
          assetImageBaseReference={
            opJobDeferred ? opJobDeferred?.AssetImageBaseReference : ""
          }
          endOfData={opJobDeferred?.endOfData}
          loadMoreAction={() => {
            dispatch(opJobLoadMoreDeferred(opJobDeferred?.paginationCount));
          }}
          initialLoading={opJobDeferred?.initialLoading}
          paginationLoading={opJobDeferred?.paginationLoading}
          cardsRenderFunction={renderCards}
        />
      </div>
    </>
  );
};
