import AWS from 'aws-sdk';
const uploadLogToS3 = (jsonLog,logFileKey) => {
  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_REGION,
  });
  const jsonLogToUpload = {
    ...jsonLog,
    timestamp: new Date().toISOString(),
  };
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); 
  const logFileKeyString = `${year}/${month}/${JSON.stringify(logFileKey)}`;
  s3.upload(
    {
      Bucket:process.env.REACT_APP_KIBANA_LOGGING_BUCKET_NAME,
      Key: logFileKeyString,
      Body: JSON.stringify(jsonLogToUpload),
    },
    function (err, data) {
      if (err) {
        throw new Error("Error While uploading File:",err)
      } else {
      }
    }
  );
};
export default uploadLogToS3;