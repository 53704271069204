import { Box, Button, Flex } from "@chakra-ui/react";
import { NewSideMenuLayout } from "../../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { RouteStepper } from "../../../components/Stepper/Stepper";

import { useDispatch, useSelector } from "react-redux";
import AddDealershipStepOne from "./AddDealershipStepOne";
import AddDealershipStepTwo from "./AddDealershipStepTwo";
import AddDealershipStepThree from "./AddDealershipStepThree";
import { useEffect } from "react";
import { setPhotoTypeList, setVideoTypeList } from "../../MyBusinessDealershipDetail/slice/myBusinessDealershipSlice";
import { getPhotoType, getVideoType } from "../../../services/other/other";
import { V5FormFooterLayout } from "../../../layout/V5FormFooterLayout/V5FormFooterLayout";


export const PostDealershipSteps = () => {
  const { stepperIndex, stepperRoute, isLoading} = useSelector((state) => state.myBusinessDealership);
  const dispatch = useDispatch();

  useEffect(()=>{
    getPhotoTypeList();
    getVideoTypeList();
  },[])
  const getPhotoTypeList = async () => {
    try {
        const data = await getPhotoType();
        if (data?.Status === 200 && Object.keys(data?.Data).length > 0) {
            const photoList = data?.Data?.PhotoTypeList.map((element) => ({
                id: element.PhotoTypeId,
                value: element.PhotoTypeValue,
            }));
            dispatch(setPhotoTypeList(photoList));
            return photoList;
        }
    } catch (error) {

    }
}

const getVideoTypeList = async () => {

  const data = await getVideoType();
  if (data?.Status === 200 && Object.keys(data?.Data).length > 0) {
      const videoList = data?.Data?.VideoTypeList.map((element) => ({
          id: element.VideoTypeId,
          value: element.VideoTypeValue,
      }));
      dispatch(setVideoTypeList(videoList));
      return videoList;
  }
}


  const renderForm = (index) => {
    switch (index) {
      case 0:
        return <AddDealershipStepOne />;

      case 1:
        return  <AddDealershipStepTwo /> ;

      case 2: 
      return <AddDealershipStepThree />;

      default:
        return <p>No Data Available</p>;
    }
  };

  const formId = (index) => {
    switch(index){
      case  0 :
        return "post-dealership-step-one"

      case 1 :
        return "post-dealership-step-two" 

      case 2 : 
      return "post-dealership-step-three" 

        default : 
        return ""
    }

  }

  const headingRenderer = (index) => {
    switch(index){
      case 0 : 
      return "Your Dealership Details";
      case 1 : 
      return "Additional Details"
      case 2 : 
      return "Additional Details"
      default :
      return ""
    }
  }
  const subHeadingRenderer = (index) => {
    switch(index){
      case 0 : 
      return "Add Your Dealerships are here";
      case 1 : 
      return "Upload Dealerships Images and photos"
      case 2 : 
      return "pload Your Region and Other Details"
      default :
      return ""
    }
  }
  return (
    // <NewSideMenuLayout justifyContent="space-between">
    //   <div
    //     style={{
    //       margin: "0px 52px",
    //       backgroundColor: "#92929204",
    //       paddingTop: "1em",
    //     }}
    //   >
    //     <Flex flexDirection="column" gap="1em" height="100%">
    //       <Box h="54px" dropShadow="0px 10px 30px #2C2C2C14" bgColor="#FFFFFF">
    //         <RouteStepper width="100%" array={stepperRoute} activeIndex={stepperIndex} padding="1em"/>
    //       </Box>
    //       <Box
    //         maxH="347px"
    //         h="100%"
    //       >
    //         {renderForm(stepperIndex)}
    //       </Box>
    //     </Flex>
    //   </div>

    //   <div
    //     style={{
    //       width: "100%",
    //       backgroundColor: "white",
    //       display: "flex",
    //       justifyContent: "center",
    //       alignItems: "center",
    //       maxHeight: "78px",
    //     }}
    //   >
    //     <Button
    //       type="submit"
    //       variant="gradient"
    //       w="347px"
    //       form={formId(stepperIndex)}         
    //     >
    //       Next
    //     </Button>
    //   </div>
    // </NewSideMenuLayout>
    <V5FormFooterLayout
    title={headingRenderer(stepperIndex)}
    subtitle={subHeadingRenderer(stepperIndex)}
    primaryButtonText="Next"
    formId={formId(stepperIndex)}
    stepperArray={stepperRoute}
    stepperIndex={stepperIndex}
    isLoading={isLoading}
    >
{renderForm(stepperIndex)}
    </V5FormFooterLayout>

  );
};
