import React  from 'react';
import { Box } from '@chakra-ui/react';
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'

export const LoadingSkelaton=()=>{
    // const { data, loading, error } = useRemoteData()
    // return <Box children='error' />
    return (
        <Box padding='10' boxShadow='lg' bg='white'>
        <SkeletonCircle size='10' />
        <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
      </Box>
      )
}