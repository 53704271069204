import { useNavigate } from "react-router-dom";
import { V5SidebarLayout } from "../../../layout/V5SidebarLayout/V5SidebarLayout";
import { Box, Divider, Flex, Text} from "@chakra-ui/react";
import "./AllMarketplaces.css";
import { AllMarketplacesCard } from "../../../components/AllMarketplaces/AllMarketplacesCard";
import { useEffect, useState } from "react";
import { QrPopUp } from "../../../components/ScanQrCodeModal/QrPopUp";
import { GetAllMarketPlaceServices } from "../../../services/ownerMarketplace/ownerMarketplace";
import { useDispatch, useSelector } from "react-redux";
import { BrandLoader } from "../../../components/loaders/brandLoader/BrandLoader";
import { openQrModal } from "../../dashboard/slice/dashboardSlice";
import { ByPremiumPopup } from "../../ownerMarketplace/screens/ByPremiumPopup";

export const AllMarketplaces = () => {
  const { accountDetails, UserTypeValue } = useSelector((state) => state.user);
  //premium
  const { isPremium} = useSelector((state) => state.premium);

  const navigate = useNavigate();
  const dispatch=useDispatch();
  const [marketplaces, setMarketplace] = useState([]);
  const [marketplacesWithIcons, setMarketplaceWithIcons] = useState(null);
  const [isDataLoading,setIsDataLoading]=useState(null);
  const [isBuyPremiumPopupOpen,setIsBuyPremiumPopupOpen]=useState(false);

  const navigation = (MarketPlaceValue) => {
      switch (MarketPlaceValue) {
        case "Operator":
          navigate("/near-me/MarketPlaceOP");
          break;
        case "Maintenance":
          navigate("/near-me/MarketPlaceMP");
          break;
        case "Owners":
          if (isPremium) {
            navigate("/partners-nearby");
          } else {
            setIsBuyPremiumPopupOpen(true)
          }
          break;
        case "Used Equipment":
          navigate("/marketplace/MarketPlaceUEM");
          break;
        case "Rental Equipment":
          navigate("/marketplace/MarketPlaceRM");
          break;
        case "Dealership":
          navigate("/marketplace/DealershipMarketplaceHome");
          break;
        case "Material":
          navigate("/marketplace/MaterialMarketplace");
          break;
        case "Spare Parts":
          navigate("/materials/spare-parts?materialTypeId=MQ==");
          break;
          case "Oil":
          navigate("/materials/oil?materialTypeId=Mg==");
          break;
          case "Concrete":
          navigate("/materials/concrete?materialTypeId=Mw==");
          break;
          case "Generators":
          navigate("/materials/generators?materialTypeId=NA==");
          break;
          case "Safety Equipment":
          navigate("/materials/safety-equipment?materialTypeId=NQ==");
          break;
        default:
          dispatch(openQrModal());
          break;
      }
    
  };

  const convertToDesiredFormat = (response) => {
    const transformed = [];

    for (const categoryKey in response.Data.E9Marketplace
      .MarketplaceCategories) {
      const category =
        response.Data.E9Marketplace.MarketplaceCategories[categoryKey];
      const transformedCategory = {
        MarketplaceCategoryId: category.MarketplaceCategoryId,
        MarketPlaceCategoryValue: category.MarketPlaceCategoryValue,
        Marketplaces: [],
      };

      for (const marketplaceKey in category.Marketplaces) {
        transformedCategory.Marketplaces.push(
          category.Marketplaces[marketplaceKey]
        );
      }

      if (category.MarketplaceCategoryId === 3) {
        transformedCategory.Marketplaces = response.Data.MaterialList.map(
          (material) => ({
            count: material.MaterialCount,
            IconFileRef: `${process.env.REACT_APP_CDN_PVT_URL}MobileAppAssets/Material/${material.MaterialTypeIconFileRef}`,
            MarketplaceId: material.MaterialTypeId,
            MarketPlaceValue: material.MaterialTypeValue,
          })
        );
      }
      transformed.push(transformedCategory);
    }

    return transformed;
  };

  const iconMapping = () => {
    const icons = [
      {
        id: 1,
        iconFileRef: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/community/staringWheel.svg`,
      },
      {
        id: 2,
        iconFileRef: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/community/maintenanceTools.svg`,
      },
      {
        id: 4,
        iconFileRef: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/e9-services/usedEquipment.svg`,
      },
      {
        id: 5,
        iconFileRef: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/e9-services/rentalEquipment.svg`,
      },
      {
        id: 6,
        iconFileRef: `${process.env.REACT_APP_CDN_PVT_URL}MobileAppAssets/bi_dealerships.png`,
      },
      {
        id: 10,
        iconFileRef: `${process.env.REACT_APP_CDN_PVT_URL}MobileAppAssets/bi_dealerships.png`,
      },
      {
        id: 13,
        iconFileRef: `${process.env.REACT_APP_CDN_PVT_URL}MobileAppAssets/bi_insurance.png`,
      },
      {
        id: 17,
        iconFileRef: `${process.env.REACT_APP_CDN_PVT_URL}MobileAppAssets/bi_insurance.png`,
      },
      {
        id: 21,
        iconFileRef: `${process.env.REACT_APP_CDN_PVT_URL}MobileAppAssets/bi_finances.png`,
      },
    ];

    const iconLookup = {};
    icons.forEach((icon) => {
      iconLookup[icon.id] = icon.iconFileRef;
    });

    const appendIconFileRef = (marketplaces) => {
      marketplaces.forEach((marketplace) => {
        if (marketplace.MarketPlaceServiceId !== undefined) {
          marketplace.IconFileRef =
            iconLookup[marketplace.MarketPlaceServiceId] || null;
        } else if (marketplace.MaterialCount !== undefined) {
          marketplace.IconFileRef =
            iconLookup[marketplace.MarketPlaceServiceId] || null;
        }
      });
    };

    marketplaces.forEach((category) => {
      appendIconFileRef(category.Marketplaces);
    });

    setMarketplaceWithIcons(marketplaces);

    console.log(JSON.stringify(marketplaces, null, 4));
  };

  const getAllMarketPlaceServices = async () => {

    try {
      setIsDataLoading(true);
      const data = await GetAllMarketPlaceServices();
      if (data?.Status === 200) {
        setMarketplace(convertToDesiredFormat(data));
      }
    } catch (error) {
      
    } finally{
      setIsDataLoading(false);
    }
   
  };

  useEffect(() => {
    getAllMarketPlaceServices();
  }, []);

  useEffect(() => {
    iconMapping();
  }, [setMarketplace, marketplaces]);

  return (
    isDataLoading===true ? <BrandLoader/> :
     <V5SidebarLayout>
      <ByPremiumPopup 
      isOpen={isBuyPremiumPopupOpen}
      primaryAction={()=>{
        setIsBuyPremiumPopupOpen(false);
        navigate("/subscription/subscription-type");
      }}
      secondaryAction={()=>{
        setIsBuyPremiumPopupOpen(false);
      }}
      
      />
      <QrPopUp />
      <Flex gap={"20px"} direction={"column"} margin={"0 52px"}>
        <Box>
          <Text fontSize={"24px"} fontWeight={"700"} color={"#141619"}>
            E9 Marketplace
          </Text>
          <Text fontSize={"20px"} fontWeight={"400"} color={"#767676"}>
            Connecting Equipment To Your Businesses.
          </Text>
        </Box>

        <Flex gap={"30px"} direction={"column"}>
          {marketplacesWithIcons &&
            marketplacesWithIcons.map((item) => {
              return (
                <Flex direction={"column"} gap={"1em"}>
                  <Flex w="100%" alignItems="center" gap="0.5em">
                    <Box minWidth="max-content">
                      <Text
                        color="#011f6b"
                        fontWeight="semibold"
                        fontSize="2xl"
                      >
                        {item?.MarketPlaceCategoryValue}
                      </Text>
                    </Box>
                    <Flex alignItems="center" w="100%">
                      <Divider borderColor="#011f6b" borderWidth="0.1em" />
                    </Flex>
                  </Flex>

                  <Flex gap={"20px"}>
                    {item?.Marketplaces ? (
                      item?.Marketplaces.map((item) => (
                        <AllMarketplacesCard
                          name={item.MarketPlaceValue}
                          count={item.count}
                          icon={item.IconFileRef}
                          onnavigationclick={() => {
                            navigation(item.MarketPlaceValue);
                          }}
                        />
                      ))
                    ) : (
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        height="100px"
                        width="100%"
                      >
                        <p>No data available.</p>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              );
            })}
        </Flex>
      </Flex>
    </V5SidebarLayout>
  );
};
