import { Button } from "@chakra-ui/button";
import "./OurProduct";
import { Box, Flex, Skeleton, Text, Tooltip } from "@chakra-ui/react";
import { V5DashboardAssetCard } from "../../../webapp/components/V5DashboardAssetCard/V5DashboardAssetCard";
import { subString } from "../../../webapp/helpers/dataConversion/substring";
import { ArrowRightIcon } from "@chakra-ui/icons";
import { V5DashboardPartnerCard } from "../../../webapp/components/V5DashboardPartnerCard/V5DashboardPartnerCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDeferredUemThunk } from "./thunk/getDeferredUemThunk";
import { getDeferredRemThunk } from "./thunk/getDeferredRemThunk";
import { getOpDeferredThunk } from "./thunk/getOpDeferredThunk";
import { getMpDeferredThunk } from "./thunk/getMpDeferredThunk";
import { dealershipDeferredThunk } from "./thunk/dealership/dealershipDeferredThunk";
import DealershipCard from "../../../webapp/components/DealershipMarketplaceCard/DealershipCard";
import { getOpJobDeferredThunk } from "./thunk/getOpJobDeferredThunk";
import { getMpJobDeferredThunk } from "./thunk/getMpJobDeferredThunk";
import { OpenJobCard } from "../openMarketplace/job/openJobCard/OpenJobCard";
import fetchHandler from "../../../webapp/services/fetchHandler";
import { API_CONSTANTS } from "../../../constants/apiConstants";
import { Navigate } from "react-router-dom";

export const Productbanner = ({
  headingText = "Heading",
  highlightedText = "HighlightedText",
  description = "Description",
  bannerImage = "",
  marketplaceLink = "",
  type = "",
}) => {
  const uemDeferred = useSelector((state) => state.uemDeferred);
  const remDeferred = useSelector((state) => state.remDeferred);
  const opDeferred = useSelector((state) => state.opDeferred);
  const mpDeferred = useSelector((state) => state.mpDeferred);
  const dealershipDeferred = useSelector((state) => state.dealershipDeferred);
  const opJobDeferred = useSelector((state) => state.opJobDeferred);
  const mpJobDeferred = useSelector((state) => state.mpJobDeferred);

  const [materials, setMaterials] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getDeferredMarketplaceCards();
  }, [type]);

  const getDeferredMarketplaceCards = () => {
    switch (type) {
      case "Rental Marketplace":
        dispatch(getDeferredRemThunk());
        break;
      case "Used Marketplace":
        dispatch(getDeferredUemThunk());
        break;
      case "Dealership Marketplace":
        dispatch(dealershipDeferredThunk());
        break;
      case "Material Marketplace":
        getCommonMaterials();
        break;
      case "Operator Marketplace":
        dispatch(getOpDeferredThunk());
        break;
      case "Mechanic Marketplace":
        dispatch(getMpDeferredThunk());
        break;
      case "OperatorJob Marketplace":
        dispatch(getOpJobDeferredThunk());
        break;
      case "MaintenanceJob Marketplace":
        dispatch(getMpJobDeferredThunk());
        break;
      default:
        break;
    }
  };

  const getCommonMaterials = async () => {
    try {
      const { data } = await fetchHandler(
        "/deferredLogin/marketplace",
        { category: "material" },

        "",
        "",
        API_CONSTANTS.HTTP_GET,
        { isAuthRequired: false }
      );
      if (data?.Status === 200) {
        setMaterials(data?.Data?.MarketPlaceDetails);
      }
    } catch (error) {}
  };
  // Cards Rendered
  const renderMarketplace = (array, loading) => {
    const renderJsx =
      array && array.length > 0 ? (
        array.map((item) => {
          return (
            <V5DashboardAssetCard
              key={item.AssetUniqueId}
              onClick={() => window.open(marketplaceLink, "_blank")}
              minW={["400px", "400px", "344px", "295px", "262px", "272px"]}
              maxW="275px"
              width="100%"
              height="272px"
              display="flex"
              flexDirection="column"
              boxShadow="0px 6px 15px -3px #00000029"
            >
              <V5DashboardAssetCard.Header width="100%" height="150px">
                <V5DashboardAssetCard.Image
                  src={`${process.env.REACT_APP_CDN_PVT_URL}AssetsImage/${item?.AssetImages?.Image_Front_FileRef}`}
                />
              </V5DashboardAssetCard.Header>
              <V5DashboardAssetCard.Body
                display="flex"
                height="122px"
                flexDirection="column"
                padding="0.5em 0.5em"
                color="black"
                background="#fff"
                borderBottomRightRadius="6px"
                borderBottomLeftRadius="6px"
              >
                <V5DashboardAssetCard.Heading
                  tooltip={`${item?.OEMName} ${item?.EquipmentTypeValue} - ${item?.EquipmentTypeValue}`}
                >
                  {subString(
                    18,
                    `${item?.OEMName} ${item?.EquipmentTypeValue} - ${item?.EquipmentTypeValue}`
                  )}
                </V5DashboardAssetCard.Heading>

                <V5DashboardAssetCard.HighlightedText>
                  &#x20B9; {item.ExpectedPrice ? item.ExpectedPrice : "NA"}{" "}
                  {item?.ExpectedRentalTypeValue
                    ? "/ " + item?.ExpectedRentalTypeValue
                    : null}
                </V5DashboardAssetCard.HighlightedText>
                  <V5DashboardAssetCard.Location tooltip={[item?.CityName, item?.StateName, item?.CountryName].filter(Boolean).join(", ") || item?.AssetLocationAddress || ""}>
                    {subString(25, [item?.CityName, item?.StateName, item?.CountryName].filter(Boolean).join(", ") || item?.AssetLocationAddress || "NA")}
                  </V5DashboardAssetCard.Location>
                <V5DashboardAssetCard.Text>
                  {item?.AssetManufacturingYear
                    ? `Manafactured On Date ${item?.AssetManufacturingYear}`
                    : "Manufacturing Year Not Available"}
                </V5DashboardAssetCard.Text>
              </V5DashboardAssetCard.Body>
            </V5DashboardAssetCard>
          );
        })
      ) : (
        <>
          <Skeleton width="275px" height="300px" borderRadius="8px" />
          <Skeleton width="275px" height="300px" borderRadius="8px" />
          <Skeleton width="275px" height="300px" borderRadius="8px" />
          <Skeleton width="275px" height="300px" borderRadius="8px" />
        </>
      );
    return renderJsx;
  };

  const renderOpMpMarketplace = (array) => {
    const renderJsx =
      array && array.length > 0 ? (
        array.map((item) => {
          return (
            <V5DashboardPartnerCard
              onClick={() => window.open(marketplaceLink, "_blank")}
              background="#fff"
              color="black"
              isButtonHidden="true"
              borderRadius="6px"
            >
              <V5DashboardPartnerCard.Image
                src={
                  item?.ProfilePictureFileReference
                    ? `${process.env.REACT_APP_CDN_PVT_URL}UserProfilePicture/${item?.ProfilePictureFileReference}`
                    : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Avatar.png`
                }
              />
              <Flex
                flexDirection="column"
                gap="0.75em"
                marginTop="1em"
                alignItems="center"
              >
                <V5DashboardPartnerCard.Heading>
                  {subString(10, item?.PartnerName)}
                </V5DashboardPartnerCard.Heading>
                <V5DashboardPartnerCard.Text color="#002961">
                  {subString(10, item?.EquipmentTypeValue)}
                </V5DashboardPartnerCard.Text>
                <V5DashboardPartnerCard.Text>{`${item?.ExperienceYear}.${item?.ExperienceMonth} Years Experienced`}</V5DashboardPartnerCard.Text>
              </Flex>
            </V5DashboardPartnerCard>
          );
        })
      ) : (
        <>
          <Skeleton width="275px" height="300px" borderRadius="8px" />
          <Skeleton width="275px" height="300px" borderRadius="8px" />
          <Skeleton width="275px" height="300px" borderRadius="8px" />
          <Skeleton width="275px" height="300px" borderRadius="8px" />
        </>
      );
    return renderJsx;
  };

  const renderOpMpJobCards = (array) => {
    const renderJSX =
      array && array.length > 0 ? (
        array.map((operatorJob) => (
          <OpenJobCard
            onClick={() => window.open(marketplaceLink, "_blank")}
            heading={operatorJob?.JobTitle}
            salary={`₹ ${operatorJob?.Salary}`}
            location={`${operatorJob?.CityName}, ${operatorJob?.StateName}, ${operatorJob?.CountryName}`}
            lastDate={operatorJob?.JobExpiryDate.split(",")[1]
              .split("-")
              .join(" ")}
          />
        ))
      ) : (
        <>
          <Skeleton width="380px" height="152px" borderRadius="5px" />
          <Skeleton width="380px" height="152px" borderRadius="5px" />
          <Skeleton width="380px" height="152px" borderRadius="5px" />
          <Skeleton width="380px" height="152px" borderRadius="5px" />
        </>
      );

    return renderJSX;
  };

  const renderDealershipCards = (array) => {
    const renderJSX =
      array && array.length > 0 ? (
        array.map((item) => (
          <V5DashboardAssetCard
            onClick={() => window.open(marketplaceLink, "_blank")}
            key={item.AssetUniqueId}
            minW={["400px", "400px", "344px", "295px", "262px", "272px"]}
            maxW="275px"
            width="100%"
            height="272px"
            display="flex"
            flexDirection="column"
            boxShadow="0px 6px 15px -3px #00000029"
          >
            <V5DashboardAssetCard.Header width="100%" height="150px">
              <V5DashboardAssetCard.Image
                src={
                  item.ImageList &&
                  item.ImageList.length > 0 &&
                  `${process.env.REACT_APP_CDN_PVT_URL}${item.ImageList[0]?.ImageFileRef}`
                }
              />
            </V5DashboardAssetCard.Header>
            <V5DashboardAssetCard.Body
              display="flex"
              height="122px"
              flexDirection="column"
              padding="0.5em 0.5em"
              color="black"
              background="#fff"
              borderBottomRightRadius="6px"
              borderBottomLeftRadius="6px"
            >
              <V5DashboardAssetCard.Heading tooltip={`${item?.DealerName}`}>
                {subString(18, `${item?.DealerName}`)}
              </V5DashboardAssetCard.Heading>

              <V5DashboardAssetCard.HighlightedText>
                {item.EquipmentMakeValue}
              </V5DashboardAssetCard.HighlightedText>
              <Tooltip label={item?.EquipmentMakeValue}>
                <V5DashboardAssetCard.Location tooltip={[item?.GoogleLocation?.City, item?.GoogleLocation?.State, item?.GoogleLocation?.Country].filter(Boolean).join(", ") || item?.Location || ""}>
                  {subString(25, [item?.GoogleLocation?.City, item?.GoogleLocation?.State, item?.GoogleLocation?.Country].filter(Boolean).join(", ") || item?.Location || "NA")}
                </V5DashboardAssetCard.Location>
              </Tooltip>
              <V5DashboardAssetCard.Text>
                {item?.EquipmentStatusValue} Equipment
              </V5DashboardAssetCard.Text>
            </V5DashboardAssetCard.Body>
          </V5DashboardAssetCard>
        ))
      ) : (
        <>
          <Skeleton width="275px" height="300px" borderRadius="8px" />
          <Skeleton width="275px" height="300px" borderRadius="8px" />
          <Skeleton width="275px" height="300px" borderRadius="8px" />
          <Skeleton width="275px" height="300px" borderRadius="8px" />
        </>
      );

    return renderJSX;
  };
  const renderMaterialsCards = (array) => {
    const renderJSX =
      array && array.length > 0 ? (
        array.map((item) => (
          <V5DashboardAssetCard
            key={item.MaterialId}
            minW={["400px", "400px", "344px", "295px", "262px", "272px"]}
            maxW="275px"
            width="100%"
            height="272px"
            display="flex"
            flexDirection="column"
            boxShadow="0px 6px 15px -3px #00000029"
            onClick={() => window.open(marketplaceLink, "_blank")}
          >
            <V5DashboardAssetCard.Header width="100%" height="150px">
              <V5DashboardAssetCard.Image
                src={`${process.env.REACT_APP_CDN_PVT_URL}${item.ImageFileReference}`}
              />
            </V5DashboardAssetCard.Header>
            <V5DashboardAssetCard.Body
              display="flex"
              height="122px"
              flexDirection="column"
              padding="0.5em 0.5em"
              color="black"
              background="#fff"
              borderBottomRightRadius="6px"
              borderBottomLeftRadius="6px"
            >
              <V5DashboardAssetCard.Heading tooltip={`${item?.HeaderTitle}`}>
                {subString(18, `${item?.HeaderTitle}`)}
              </V5DashboardAssetCard.Heading>
              {/* 
          <V5DashboardAssetCard.HighlightedText>
            {item.MaxPriceRange}
          </V5DashboardAssetCard.HighlightedText> */}
              <Tooltip label={item?.MinPriceRange}>
                <V5DashboardAssetCard.Location tooltip={item?.SubHeader2}>
                  {subString(25, item?.SubHeader2)}
                </V5DashboardAssetCard.Location>
              </Tooltip>
              <V5DashboardAssetCard.Text>
                {item?.SubHeader1}
              </V5DashboardAssetCard.Text>
            </V5DashboardAssetCard.Body>
          </V5DashboardAssetCard>
        ))
      ) : (
        <>
          <Skeleton width="275px" height="300px" borderRadius="8px" />
          <Skeleton width="275px" height="300px" borderRadius="8px" />
          <Skeleton width="275px" height="300px" borderRadius="8px" />
          <Skeleton width="275px" height="300px" borderRadius="8px" />
        </>
      );

    return renderJSX;
  };

  return (
    <>
      <Box className="product-banner-main">
        <Flex
          direction={"column"}
          padding="2.25em 2em"
          borderBottom="3px solid #F79C00"
          background="linear-gradient(90deg, #28292B 0%, #484B4D 50%, #212529 100%)"
          color="#fff"
          gap={["20px", "20px", "20px", "20px", "20px", "20px"]}
        >
          <Box>
            <Text
              fontWeight={"700"}
              color={"#FECA00"}
              textTransform={"uppercase"}
              fontSize={["18px", "18px", "18px", "26px", "28px", "28px"]}
            >
              {headingText}
            </Text>
            <Text fontSize={["12px", "12px", "12px", "20px", "20px", "20px"]}>
              {highlightedText}
            </Text>
          </Box>

          <Text fontSize={["16px", "16px", "16px", "16px", "16px", "16px"]}>
            {description}
          </Text>

          <Flex
            className="demoBanner"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex overflow="scroll" gap="2em">
              {type === "Rental Marketplace"
                ? renderMarketplace(remDeferred?.data)
                : type === "Used Marketplace"
                ? renderMarketplace(uemDeferred?.data)
                : type === "Operator Marketplace"
                ? renderOpMpMarketplace(opDeferred?.data)
                : type === "Mechanic Marketplace"
                ? renderOpMpMarketplace(mpDeferred?.data)
                : type === "Dealership Marketplace"
                ? renderDealershipCards(dealershipDeferred?.data)
                : type === "Material Marketplace"
                ? renderMaterialsCards(materials)
                : type === "OperatorJob Marketplace"
                ? renderOpMpJobCards(opJobDeferred?.data)
                : type === "MaintenanceJob Marketplace"
                ? renderOpMpJobCards(mpJobDeferred?.data)
                : null}
            </Flex>
            <Button
              display={!marketplaceLink ? "none" : ""}
              background="transparent"
              color="#fff"
              isDisabled={!marketplaceLink}
              onClick={() => window.open(marketplaceLink, "_blank")}
              _hover={{ background: "transparent" }}
            >
              View All <ArrowRightIcon />
            </Button>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
