import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ReactSVG } from "react-svg";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { editIncreaseStepperIndex } from "./EditMaterialSlice/EditMaterialSlice";
import { LocationInput } from "../../../../../LocationInput/LocationInput";
import { useToast } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { convertFromBase64 } from "../../../../../../../utils/formatConverter/convertToBase64";
import { useQueryClient } from "react-query";
import { getEquipmentMakesById, getEquipmentModelById, getEquipmentTypeList } from "../../../../../../services/equipments/equipments";
import { getMaterialBasicDetails, updateMaterialStep1 } from "../../../../../../services/materials/materials";


/*****Validation Schema */
const validationSchema = Yup.object().shape({
  productName: Yup.string()
    .required("Product Name is required")
    .min(3, "Minumum 3 characters required")
    .max(100, "Number of Characters exceeding"),
  productnumber: Yup.string()
    .required("Product Number is required")
    .min(3, "Minumum 3 characters required")
    .max(25, "Number of Characters exceeding"),
  pricerangeminimum: Yup.string().required("Minimum is required"),
  pricerangemaximum: Yup.string().required("Maximum is required"),
  pricerangeminimum: Yup.number()
    .required("Minimum price is required")
    .positive("Minimum price must be a positive number")
    .max(9999999, "Maximum 7 digits allowed")
    .test(
      "is-less",
      "Minimum price should be less than maximum price",
      function (value) {
        const { pricerangemaximum } = this.parent;
        return value < pricerangemaximum;
      }
    ),
  pricerangemaximum: Yup.number()
    .required("Maximum price is required")
    .positive("Maximum price must be a positive number")
    .max(9999999, "Maximum 7 digits allowed")
    .test(
      "is-greater",
      "Maximum price should be greater than minimum price",
      function (value) {
        const { pricerangeminimum } = this.parent;
        return value > pricerangeminimum;
      }
    ),
  equipmentTypeSelection: Yup.string()
    .required("Equipment type selection is required")
    .oneOf(["specific", "any"], "Invalid equipment type selection"),
  isOriginalProduct: Yup.number()
    .nullable()
    .oneOf([0, 1], "Select Yes or No for Original Product")
    .required("Select Yes or No for Original Product"),
});

export const EditMaterialPlaceSteps1 = () => {
  const para = useParams();
  const { MaterialTypeStperId } = useParams();
  const userData = useSelector((state) => state.user);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedTypeOEM, setSelectedTypeOEM] = useState(null);
  const [selectedTypeModel, setSelectedTypeModel] = useState(null);
  const [EquipmentList, setEquipmentList] = useState(null);
  const [EquipmentMake, setEquipmentMake] = useState(null);
  const [EquipmentModel, setEquipmentModel] = useState(null);
  const [SelectedEquipmentType, setSelectedEquipmentType] = useState(null);
  const [SelectedEquipmentModel, setSelectedEquipmentModel] = useState(null);
  const [SelectedEquipmentmake, setSelectedEquipmentmake] = useState(null);
  const [isOriginalProduct, setisOriginalProduct] = useState(null);
  const [anyOEM, setanyOEM] = useState(0);
  const [anyEquipment, setanyEquipment] = useState(0);
  const [anyModel, setanyModel] = useState(0);
  const [NavigateMaterialType, setNavigateMaterialType] = useState(null);
  const [equipmentTypeSelection, setEquipmentTypeSelection] = useState("");
  const [equipmentOEMSelection, setEquipmentOEMSelection] = useState("");
  const [equipmentModelSelection, setEquipmentModelSelection] = useState("");
  const [editMaterial, setEditMaterial] = useState(null);
  const [editEquipmentTypeId, seteditEquipmentTypeId] = useState(null);
  const [editEqipmentMakeId, seteditEquipmentMakeId] = useState(null);
  const [editEqipmentModelId, seteditEquipmentModelId] = useState(null);


  // const [productName,setProductName]=useState(null);

  /***Hooks */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();
  const queryClient = useQueryClient();

  /****convrt from base 64 to Id */
  const deCodeed=convertFromBase64(MaterialTypeStperId );
  // console.log("MaterialTypeStperIddeodd",deCodeed)

  /**Select Model type */
  const handleSelectType = (type) => {
    setSelectedType(type);
    setEquipmentTypeSelection(type);
    if (type === "specific") {
      setanyEquipment(1);
    }
  };

  const handleSelectTypeOEM = (type) => {
    setSelectedTypeOEM(type);
    if (type === "specificOEM") {
      setanyOEM(1);
    }
  };

  const handleSelectTypeModel = (type) => {
    setSelectedTypeModel(type);
    if (type === "specificModel") {
      setanyModel(1);
    }
  };

  const handleOriginalProductYes = () => setisOriginalProduct(1);
  const handleOriginalProductNo = () => setisOriginalProduct(0);

  /****selecting Type, Make, model  */

  useEffect(() => {
    const fetchEquipmentList = async () => {
      try {
        const data = await getEquipmentTypeList();
        setEquipmentList(data?.Data?.EquipmentsList);
      } catch (error) {
        console.error("Error fetching equipment list", error);
      }
    };
    fetchEquipmentList();
  }, []);

  useEffect(() => {
    if (editEquipmentTypeId) {
      const fetchEquipmentMake = async () => {
        // debugger;
        try {
          const data = await getEquipmentMakesById(
            editEquipmentTypeId
          );
          // console.log(response, "response  by OEM");
          setEquipmentMake(data?.Data?.OEMList);
        } catch (error) {
          console.error("Error fetching equipment make", error);
        }
      };
      fetchEquipmentMake();
    } else {
      setEquipmentMake(null);
    }
  }, [editEquipmentTypeId]);
  /*********************** */
  useEffect(() => {
    if (editEqipmentMakeId) {
      const fetchEquipmentModel = async () => {
        try {
          const data = await getEquipmentModelById(
            editEqipmentMakeId
          );
          setEquipmentModel(data?.Data?.ModelList);
        } catch (error) {
          console.error("Error fetching equipment model", error);
        }
      };
      fetchEquipmentModel();
    } else {
      setEquipmentModel(null);
    }
  }, [editEqipmentMakeId]);

  /******Edit Func useQuery cal**** */

  useEffect(() => {
    const fetchEditMaterial = async () => {
      try {
        const response = await getMaterialBasicDetails(
          parseInt(deCodeed)
        );
        // console.log( parseInt(deCodeed))
        const materialData = response?.data?.Data?.MaterialData[0];
        setEditMaterial(materialData);
        if (materialData) {
          const equipmentType =
            materialData.AnyEquipment === 0 ? "any" : "specific";
          setSelectedType(equipmentType);
          setEquipmentTypeSelection(equipmentType);
        }
        if (materialData) {
          const oemType = materialData.AnyOEM === 0 ? "anyOEM" : "specificOEM";
          setSelectedTypeOEM(oemType);
          setEquipmentOEMSelection(oemType);
        }
        if (materialData) {
          const modelType =
            materialData.AnyModel === 0 ? "anyModel" : "specificModel";
          setEquipmentModelSelection(modelType);
          setSelectedTypeModel(modelType);
        }
        if (materialData) {
          const isOriginal = materialData.IsOriginal === 0 ? 0 : 1;
          setisOriginalProduct(isOriginal);
        }
      
      } catch (error) {
        console.error("Error fetching edit material:", error);
      }
    };
    fetchEditMaterial();
  }, [userData.token, MaterialTypeStperId]);


  useEffect(()=>{

    seteditEquipmentTypeId(editMaterial?.EquipmentTypeId);
    seteditEquipmentMakeId(editMaterial?.OEMId);
    seteditEquipmentModelId(editMaterial?.ModelId);

  },[editMaterial])



/**
 * The `handleSubmit` function is used to handle the submission of a form in a React component, and it
 * sends a payload object to update material details.
 */
  const handleSubmit = async (values) => {
    const payload = {
      MaterialDetails: {
        MaterialTypeId: parseInt(localStorage.getItem("MaterialTypeId")),
        ProductName: editMaterial?editMaterial.ProductName: values.productName,
        ProductNumber: editMaterial?editMaterial?.ProductNumber : values.productnumber,
        MinPriceRange:
        editMaterial? editMaterial?.MinPriceRange : parseInt(values.pricerangeminimum),
        MaxPriceRange:
        editMaterial? editMaterial?.MaxPriceRange : parseInt(values.pricerangemaximum),
        Location:  editMaterial? editMaterial?.Location : values._jobLocation.name,
        AnyEquipment: editMaterial? editMaterial.AnyEquipment : anyEquipment,
        EquipmentTypeId: parseInt(editEquipmentTypeId),
        AnyOEM:    editMaterial? editMaterial?.AnyOEM :anyOEM,
        AnyModel:  editMaterial? editMaterial.AnyModel : anyModel,
        OEMId: parseInt(editEqipmentMakeId),
        ModelId: parseInt(editEqipmentModelId),
        IsOriginal: isOriginalProduct,
        Latitude: 78.6,
        Longitude: 45.9,
      },
    };
    const payloadObj = JSON.stringify(payload);
    // console.log(payloadObj)
    try {
      const data = await updateMaterialStep1(payloadObj);
      if (data.Status === 204) {
        toast({
          title: "",
          description: `Material update Successfully`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        queryClient.invalidateQueries("getmaterailcard")
        dispatch(editIncreaseStepperIndex());
      }
    } catch (err) {
      toast({
        title: "",
        description: `Error`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <Formik
      initialValues={{
        productName: "",
        productnumber: "",
        pricerangeminimum: "",
        pricerangemaximum: "",
        _jobLocation: "",
        specificEquipment: "",
        equipmentTypeSelection: equipmentTypeSelection,
        specificEquipmentMake: "",
        specificEquipmentModel: "",
        isOriginalProduct: null,
      }}
      // validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        handleSubmit(values);
      }}
    >
      {({ isSubmitting, setFieldValue, touched, errors }) => (
        <div>
          <p className="material-part-detail">
            {localStorage.getItem("MaterailTypeValue")} Details
          </p>
          <p className="material-part-detail-subhero">
            Add your Material Details here
          </p>
          <Form id="Edit-post-material-step-one">
            <div>
              <div>
                <div className="name-of-product-number">
                  <div className="grid-item">
                    <p>
                      <span className="fieldHeading">Name of Product</span>
                      <span className="must">*</span>
                    </p>
                    <Field
                      className="filed-input"
                      type="text"
                      name="productName"
                      value={editMaterial?.ProductName}
                      onChange={(e) => {
                        setEditMaterial((prevDetail) => {
                          return {
                            ...prevDetail,
                            ProductName: e.target.value,
                          };
                        });

                        setFieldValue("productName", e.target.value);
                      }}
                      placeholder="Product Name"
                    />
                    <ErrorMessage
                      name="productName"
                      component="div"
                      style={{
                        color: "red",
                        fontFamily: "Assistant",
                        fontSize: "12.989px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                        letterSpacing: "0.22px",
                        marginTop: "5px",
                      }}
                    />
                  </div>
                  <div lassName="grid-item">
                    <p>
                      <span className="fieldHeading">Product Number</span>
                      <span className="must">*</span>
                    </p>

                    <Field
                      className="filed-input"
                      type="text"
                      name="productnumber"
                      placeholder="Product Number"
                      value={editMaterial?.ProductNumber}
                      onChange={(e) => {
                        setEditMaterial((prevDetail) => {
                          return {
                            ...prevDetail,
                            ProductNumber: e.target.value,
                          };
                        });

                        setFieldValue("productnumber", e.target.value);
                      }}
                    />
                    <ErrorMessage
                      name="productName"
                      component="div"
                      style={{
                        color: "red",
                        fontFamily: "Assistant",
                        fontSize: "12.989px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                        letterSpacing: "0.22px",
                        marginTop: "5px",
                      }}
                    />
                  </div>
                </div>
                <div className="name-of-product-number">
                  <div style={{ marginTop: "17px", width: "486.778px" }}>
                    <p>
                      <span className="fieldHeading">Price Range</span>
                      <span className="must">*</span>
                    </p>
                    <div className="price-range-filed">
                      <Field
                        className="filed-input-price-range"
                        type="text"
                        name="pricerangeminimum"
                        value={editMaterial?.MinPriceRange}
                        onChange={(e) => {
                          setEditMaterial((prevDetail) => {
                            return {
                              ...prevDetail,
                              MinPriceRange: e.target.value,
                            };
                          });

                          setFieldValue("pricerangeminimum", e.target.value);
                        }}
                        placeholder="&#x20b9; Enter Minimum Price"
                      />
                      <Field
                        className="filed-input-price-range"
                        type="text"
                        name="pricerangemaximum"
                        value={editMaterial?.MaxPriceRange}
                        onChange={(e) => {
                          setEditMaterial((prevDetail) => {
                            return {
                              ...prevDetail,
                              MaxPriceRange: e.target.value,
                            };
                          });

                          setFieldValue("pricerangemaximum", e.target.value);
                        }}
                        placeholder="&#x20b9; Enter Maximum Price"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "133px",
                      }}
                    >
                      <ErrorMessage
                        name="pricerangeminimum"
                        component="div"
                        style={{
                          color: "red",
                          fontFamily: "Assistant",
                          fontSize: "12.989px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "normal",
                          letterSpacing: "0.22px",
                          marginTop: "5px",
                        }}
                      />
                      <ErrorMessage
                        name="pricerangemaximum"
                        component="div"
                        style={{
                          color: "red",
                          fontFamily: "Assistant",
                          fontSize: "12.989px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "normal",
                          letterSpacing: "0.22px",
                          marginTop: "5px",
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className="NameofProductField"
                    style={{ marginTop: "17px" }}
                  >
                    <Field name="_jobLocation" className="filed-input">
                      {({ field }) => (
                        <LocationInput
                          placeholder="Enter Location.."
                          className="filed-input"
                          name="_jobLocation"
                          label="Available Location"
                          isRequired={true}
                          width="484px"
                          value={editMaterial?.Location}
                          height="59.193px"
                          onChange={(e) => {
                            setEditMaterial((prevDetail) => {
                              return {
                                ...prevDetail,
                                // console.log(e.target.value)
                                Location: e.target.value,
                              };
                            });
                            setFieldValue("_jobLocation", e.target.value);
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="_jobLocation"
                      component="div"
                      style={{
                        color: "red",
                        fontFamily: "Assistant",
                        fontSize: "12.989px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                        letterSpacing: "0.22px",
                        marginTop: "5px",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div>
                <div className="NameofProductField">
                  <div className="equipment-type-layout">
                    <div>
                      <p>
                        <span className="fieldHeading">Equipment Type</span>
                        <span className="must">*</span>
                      </p>
                      <div className="equipment-type-button">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "22px",
                          }}
                        >
                          {/* For Any Equipment Option */}
                          <div
                            className={`For-any-Equipment-option ${
                              selectedType === "any" ? "selected" : ""
                            }`}
                            onClick={() => {
                              handleSelectType("any");
                              setFieldValue("equipmentTypeSelection", "any");
                            }}
                          >
                            <p
                              className={`${
                                selectedType === "any"
                                  ? "For-any-Equipment-text-any"
                                  : "For-any-Equipment-text"
                              }`}
                            >
                              For Any Equipment
                              {selectedType === "any" && (
                                <ReactSVG
                                  src={
                                    "https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/SelectedCard.svg"
                                  }
                                />
                              )}
                            </p>
                          </div>

                          {/* Separator */}
                          <ReactSVG
                            src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/filedpathSeprator.svg`}
                          />

                          {/* For Specific Equipment Option */}
                          <div
                            className={`For-any-Equipment-option ${
                              selectedType === "specific" ? "selected" : ""
                            }`}
                            onClick={() => {
                              handleSelectType("specific");
                              setFieldValue(
                                "equipmentTypeSelection",
                                "specific"
                              );
                            }}
                          >
                            <p
                              className={`${
                                selectedType === "specific"
                                  ? "For-any-Equipment-text-specific"
                                  : "For-any-Equipment-text"
                              }`}
                            >
                              For Specific Equipment
                              {selectedType === "specific" && (
                                <ReactSVG
                                  src={
                                    "https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/SelectedCard.svg"
                                  }
                                />
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      {selectedType === "specific" && (
                        <div className="specific-equipment-input">
                          <p>
                            <span className="fieldHeading">
                              Specify Equipment Type
                            </span>
                            <span className="must">*</span>
                          </p>
                          {/* <p className="fieldHeading">Specify Equipment Type</p> */}
                          <Field
                            className="filed-input"
                            as="select"
                            name="specificEquipment"
                            value={editEquipmentTypeId}
                            onChange={(e) => {
                              seteditEquipmentTypeId(e.target.value);
                              setFieldValue(
                                "specificEquipment",
                                e.target.value
                              );
                            }}
                          >
                            <option value="">Select an option</option>
                            {EquipmentList?.map((item, index) => (
                              <option
                                key={index.id}
                                value={item.EquipmentTypeId}
                              >
                                {item?.EquipmentTypeValue}
                              </option>
                            ))}
                          </Field>
                        </div>
                      )}
                    </div>
                  </div>
                  {errors.equipmentTypeSelection &&
                    touched.equipmentTypeSelection && (
                      <div
                        style={{
                          color: "red",
                          fontFamily: "Assistant",
                          fontSize: "12.989px",
                          fontWeight: 600,
                          marginTop: "5px",
                        }}
                      >
                        {errors.equipmentTypeSelection}
                      </div>
                    )}
                  {editEquipmentTypeId ? (
                    <div className="equipment-type-layout">
                      <div>
                        {/* <p className="fieldHeading">Equipment Make*</p> */}
                        <p>
                          <span className="fieldHeading">Equipment Make</span>
                          <span className="must">*</span>
                        </p>

                        <div className="equipment-type-button">
                          <div
                            className={`For-any-Equipment-option ${
                              selectedTypeOEM === "anyOEM" ? "selectedOEM" : ""
                            }`}
                            onClick={() => handleSelectTypeOEM("anyOEM")}
                          >
                            <p
                              className={`${
                                selectedTypeOEM === "anyOEM"
                                  ? "For-any-Equipment-text-make"
                                  : "For-any-Equipment-anymake"
                              }`}
                            >
                              For Any OEM
                            </p>
                            {selectedTypeOEM === "anyOEM" && (
                              <ReactSVG
                                src={
                                  "https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/SelectedCard.svg"
                                }
                              />
                            )}
                          </div>

                          <ReactSVG
                            src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/filedpathSeprator.svg`}
                          />
                          <div
                            className={`For-any-Equipment-option ${
                              selectedTypeOEM === "specificOEM"
                                ? "selectedOEM"
                                : ""
                            }`}
                            onClick={() => handleSelectTypeOEM("specificOEM")}
                          >
                            <p
                              className={`${
                                selectedTypeOEM === "specificOEM"
                                  ? "For-any-Equipment-text-make"
                                  : "For-any-Equipment-anymake"
                              }`}
                            >
                              For Specific OEM
                            </p>
                            {selectedTypeOEM === "specificOEM" && (
                              <ReactSVG
                                src={
                                  "https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/SelectedCard.svg"
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      {selectedTypeOEM === "specificOEM" && (
                        <div className="specific-equipment-input">
                          <p>
                            <span className="fieldHeading">
                              Specify Equipment Make
                            </span>
                            <span className="must">*</span>
                          </p>
                          {/* <p className="fieldHeading">Specify Equipment Make</p> */}
                          <Field
                            className="filed-input"
                            as="select"
                            name="specificEquipmentMake"
                            value={editEqipmentMakeId}
                            onChange={(e) => {
                              seteditEquipmentMakeId(e.target.value);
                            }}
                          >
                            <option value="">Select an option</option>
                            {EquipmentMake?.map((item, index) => (
                              <option key={index.id} value={item.OEMId}>
                                {item.OEMName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="specificEquipment"
                            component="div"
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <div>
                <div className="NameofProductField">
                  {editEquipmentTypeId && editEqipmentMakeId ? (
                    <div className="equipment-type-layout">
                      <div>
                        <p>
                          <span className="fieldHeading">Equipment Model</span>
                          <span className="must">*</span>
                        </p>
                        {/* <p className="fieldHeading">Equipment Model*</p> */}
                        <div className="equipment-type-button">
                          <div
                            className={`For-any-Equipment-option ${
                              selectedTypeModel === "anyModel"
                                ? "selectedModel"
                                : ""
                            }`}
                            onClick={() => handleSelectTypeModel("anyModel")}
                          >
                            <p
                              className={`${
                                selectedTypeModel === "anyModel"
                                  ? "For-any-Equipment-text-any"
                                  : "For-any-Equipment-text"
                              }`}
                            >
                              For Any Model
                            </p>
                            {selectedTypeModel === "anyModel" && (
                              <ReactSVG
                                src={
                                  "https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/SelectedCard.svg"
                                }
                              />
                            )}
                          </div>

                          <ReactSVG
                            src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/filedpathSeprator.svg`}
                          />
                          <div
                            className={`For-any-Equipment-option ${
                              selectedTypeModel === "specificModel"
                                ? "selectedModel"
                                : ""
                            }`}
                            onClick={() =>
                              handleSelectTypeModel("specificModel")
                            }
                          >
                            <p
                              className={`${
                                selectedTypeModel === "specificModel"
                                  ? "For-any-Equipment-text-specific"
                                  : "For-any-Equipment-text"
                              }`}
                            >
                              For Specific Model
                            </p>
                            {selectedTypeModel === "specificModel" && (
                              <ReactSVG
                                src={
                                  "https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/SelectedCard.svg"
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      {selectedTypeModel === "specificModel" && (
                        <div className="specific-equipment-input">
                          <p>
                            <span className="fieldHeading">
                              Specify Equipment Model
                            </span>
                            <span className="must">*</span>
                          </p>
                          {/* <p className="fieldHeading">Specify Equipment Model</p> */}
                          <Field
                            className="filed-input"
                            as="select"
                            name="specificEquipmentModel"
                            value={editEqipmentModelId}
                            onChange={(e) => {
                              seteditEquipmentModelId(e.target.value);
                            }}
                          >
                            <option value="">Select an option</option>
                            {EquipmentModel?.length > 0 &&
                              EquipmentModel?.map((item, index) => (
                                <option key={index.id} value={item?.ModelId}>
                                  {item?.ModelName}
                                </option>
                              ))}
                          </Field>
                          <ErrorMessage
                            name="specificEquipment"
                            component="div"
                            style={{
                              color: "red",
                              fontFamily: "Assistant",
                              fontSize: "12.989px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "normal",
                              letterSpacing: "0.22px",
                              marginTop: "5px",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <br />
                <div className="NameofProductField">
                  <p>
                    <span className="fieldHeading">Original Product</span>
                    <span className="must">*</span>
                  </p>
                  <div
                    className="yes-no-button"
                    style={{ marginTop: "12px", marginBottom: "12px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "22px",
                        marginTop: "6px",
                        marginLeft: "38px",
                      }}
                    >
                      <p
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "row",
                          gap: "5px",
                          width: "45px",
                        }}
                        className={`${
                          isOriginalProduct === 1
                            ? "For-any-Equipment"
                            : "For-any-Equipment-original"
                        }`}
                        onClick={() => {
                          setisOriginalProduct(1);
                          setFieldValue("isOriginalProduct", 1);
                        }}
                      >
                        Yes
                        {isOriginalProduct === 1 && (
                          <ReactSVG src="https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/SelectedCard.svg" />
                        )}
                      </p>
                    </div>
                    <ReactSVG
                      src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/filedpathSeprator.svg`}
                    />
                    <p
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        gap: "5px",
                        width: "45px",
                        marginTop: "6px",
                        marginRight: "27px",
                      }}
                      className={`${
                        isOriginalProduct === 0
                          ? "For-any-Equipment"
                          : "For-any-Equipment-original"
                      }`}
                      onClick={() => {
                        setisOriginalProduct(0);
                        setFieldValue("isOriginalProduct", 0);
                      }}
                    >
                      No
                      {isOriginalProduct === 0 && (
                        <ReactSVG src="https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/SelectedCard.svg" />
                      )}
                    </p>
                  </div>
                </div>
                <Field type="hidden" name="isOriginalProduct" />

                {errors.isOriginalProduct && touched.isOriginalProduct && (
                  <div
                    style={{
                      color: "red",
                      fontFamily: "Assistant",
                      fontSize: "12.989px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                      letterSpacing: "0.22px",
                      marginTop: "5px",
                    }}
                  >
                    {errors.isOriginalProduct}
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};
