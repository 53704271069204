import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import fetchHandler from "../../../../../webapp/services/fetchHandler";
import { convertFromBase64 } from "../../../../../utils/formatConverter/convertToBase64";
import { API_CONSTANTS } from "../../../../../constants/apiConstants";
import DealrshipHeaderCard from "../../../../../webapp/components/DealershipDetailsCard/DealershipHeaderCard";
import defaultImage from "../../../../../assets/webapp/marketplace/defaultAssetImage.png";
import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import { v4 as uuid } from "uuid";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { DealershipDetailsCard } from "../../../../../webapp/components/DealershipDetailsCard/DealershipDetailsCard";
import DealershipProductCard from "../../../../../webapp/components/DealershipProductCard/DealershipProductCard";
import BrandHeader from "../BrandHeader";
import { DealershipDownloadCard } from "../../../../../webapp/components/DealershipDetailsCard/DealershipDownloadCard";
import { LoginPopUp } from "../../../../components/PopUps/LoginPopUp";

const DealershipDeferredDetails = () => {
  const { dealershipGuid , dealershipMpGuid} = useParams();
  const id = convertFromBase64(dealershipGuid);
  const mpGuid = convertFromBase64(dealershipMpGuid);
  const [assetData, setAssetData] = useState(null);
  const [regionData, setRegionData] = useState(null);
  const [product, setProductsData] = useState(null);
  const [encryptedAssetId, setEncryptedAssetId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getMarketplaceDetails();
  }, [id]);

  const getMarketplaceDetails = async () => {
    const category = "dealership";
    const { data } = await fetchHandler(
      "/deferredLogin/marketplace",
      { category, id: id },
      "",
      "",
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: false }
    );
    if (data?.Status === 200) {
      setAssetData(data?.Data?.DealershipDetails?.DealershipData);
      setRegionData(JSON.parse(data.Data?.DealershipDetails?.RegionDetails));
    }
  };

  const encryptAssetDetails = async (id) => {
    try {
      const { data } = await fetchHandler(
        API_CONSTANTS.ENCRYPT_SHARE_DATA,
        {},
        "",
        { marketplaceCategory: 'dealership', marketplaceSubCategory: "dealership", assetId: id , SecondaryId : mpGuid },
        API_CONSTANTS.HTT_POST,
        { isAuthRequired: false }
      );
      setEncryptedAssetId(data.Data.shareData);
    } catch (error) {
    }
  };

  return (
    <>
      <LoginPopUp
        isModalOpen={modalOpen}
        closeModal={() => {
          setModalOpen(false);
          setEncryptedAssetId("");
        }}
        onLoginClick={() => {
          sessionStorage.setItem("DEFERRED_MARKETPLACE", encryptedAssetId);
          navigate(`/login/login-form?id=${encryptedAssetId}`);
        }}
        onRegisterClick={() => {
          sessionStorage.setItem("DEFERRED_MARKETPLACE", encryptedAssetId);
          navigate(`/welcome/select-language?id=${encryptedAssetId}`);
        }}
      />

      <BrandHeader />
      <Flex direction="column" padding="2em 2em" gap="2em">
        <Flex
          borderRadius="1em"
          background="#FFF"
          boxShadow="0px 0px 7.82px 0px rgba(0, 0, 0, 0.25)"
          gap="2em"
        >
          <Box
            maxW="630px"
            minW="600px"
            maxH="270px"
            minH="260px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            padding="1em 1em"
          >
            <Swiper
              pagination={{ clickable: true }}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
              style={{
                height: "254px",
                width: "605px",
                position: "relative",
                marginLeft: "-2px",
                borderRadius: "10px 0px 0px 10px",
              }}
            >
              {assetData?.ImageList && assetData?.ImageList?.length > 0 ? (
                assetData?.ImageList.map((image, index) => (
                  <SwiperSlide key={index}>
                    <img
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "fill",
                      }}
                      src={`${process.env.REACT_APP_CDN_PVT_URL}${image.ImageFileRef}`}
                      className="dealership-header-card-image"
                    />
                  </SwiperSlide>
                ))
              ) : (
                <SwiperSlide>
                  <img
                    style={{ height: "100%", width: "100%", objectFit: "fill" }}
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/DelarshipDefaultImage.png`}
                    className="dealership-header-card-image"
                  />
                </SwiperSlide>
              )}
            </Swiper>
          </Box>

          <Flex flexDirection="column" justifyContent="center">
            <Text color="#333" fontSize="28px" fontWeight="700">
              Chaitanya
            </Text>
            <Text color="#333" fontSize="28px" fontWeight="700">
              Finance Available :{" "}
              {assetData?.FinanceProvidedValue
                ? assetData?.FinanceProvidedValue
                : "NO"}{" "}
            </Text>
            <Text color="#333" fontSize="24px" fontWeight="400">
              Added On :{" "}
              <Text as="span" color="#333" fontSize="28px" fontWeight="700">
                {assetData?.UpdatedDateTime}
              </Text>
            </Text>
          </Flex>
        </Flex>

        <Box>
          <div
            onClick={() => {
              ("Called");
              encryptAssetDetails(id);
              setModalOpen(true);
            }}
            style={{
              cursor: "pointer",
            }}
          >
            {/* Regions */}
            <Box width="100%" className="dealership-main-container">
              <Flex alignItems="center">
                <Flex w="100%" alignItems="center" gap="0.5em">
                  <Box minWidth="max-content">
                    <Text color="#011f6b" fontWeight="semibold" fontSize="2xl">
                      Regions
                    </Text>
                  </Box>
                  <Flex alignItems="center" w="100%">
                    <Divider borderColor="#333" borderWidth="0.1em" />
                  </Flex>
                </Flex>
              </Flex>
              <Box
                height={"100%"}
                display={"flex"}
                className="dealership-blur-pointer"
              >
                <Flex
                  gap="2em"
                  flexDirection="row"
                  overflowX="scroll"
                  padding="1em 0"
                >
                  <DealershipDetailsCard
                    heading={"City"}
                    description={"State"}
                  />
                </Flex>
              </Box>
            </Box>

            {/* Videos */}
            <Box width="100%" className="dealership-main-container">
              <Flex alignItems="center">
                <Flex w="100%" alignItems="center" gap="0.5em">
                  <Box minWidth="max-content">
                    <Text color="#011f6b" fontWeight="semibold" fontSize="2xl">
                      Dealership Videos
                    </Text>
                  </Box>
                  <Flex alignItems="center" w="100%">
                    <Divider borderColor="#333" borderWidth="0.1em" />
                  </Flex>
                </Flex>
              </Flex>
              <Box
                height={"100%"}
                display={"flex"}
                className="dealership-blur-pointer"
              >
                <Flex
                  gap="2em"
                  flexDirection="row"
                  overflowX="scroll"
                  padding="1em 0"
                >
                  {/* <DealershipDetailsCard
                        videoUrl={defaultImage}
                        videoType={"NA"} /> */}
                </Flex>
              </Box>
            </Box>

            {/* Products */}
            <Box width="100%" className="dealership-main-container">
              <Flex alignItems="center">
                <Flex w="100%" alignItems="center" gap="0.5em">
                  <Box minWidth="max-content">
                    <Text color="#011f6b" fontWeight="semibold" fontSize="2xl">
                      Products
                    </Text>
                  </Box>
                  <Flex alignItems="center" w="100%">
                    <Divider borderColor="#333" borderWidth="0.1em" />
                  </Flex>
                </Flex>
              </Flex>
              <Box
                height={"100%"}
                display={"flex"}
                className="dealership-blur-pointer"
              >
                <Flex
                  gap="2em"
                  flexDirection="row"
                  overflowX="scroll"
                  padding="1em 0"
                >
                  <DealershipProductCard imageUrl={defaultImage} />
                </Flex>
              </Box>
            </Box>
          </div>
        </Box>
      </Flex>
    </>
  );
};

export default DealershipDeferredDetails;
