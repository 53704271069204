import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFilteredOwners } from "../../../services/ownerMarketplace/ownerMarketplace";

export const getLoadMoreFilteredDataThunk = createAsyncThunk(
  "owner/loadMoreFilteredData",
  async (_, { getState }) => {
    const selectedCountry = getState().ownersMarketplace.filterByCountry;
    const selectedState = getState().ownersMarketplace.filterByState;
    const selectedCity = getState().ownersMarketplace.filterByCity;

    const selectBusinessExpertiseTypeValue =
      getState().ownersMarketplace.filterByBusinessExpertiseTypeValue;
    const cardCount = getState().ownersMarketplace.cardCount;

    const paginationCount = getState().ownersMarketplace.paginationCount;

    let query = "";

    if (selectBusinessExpertiseTypeValue) {
      query += `BusinessExpertiseTypeValue=${selectBusinessExpertiseTypeValue}`;
    }

    if (selectedCountry) {
      query += `&CountryName=${selectedCountry}`;
    }
    if (selectedState) {
      query += `&StateName=${selectedState}`;
    }
    if (selectedCity) {
      query += `&CityName=${selectedCity}`;
    }

    try {
      const data = await getFilteredOwners(
        cardCount,
        paginationCount * cardCount,
        `${query}`
      );

      if (data.Status === 200) {
        if (data.Data.FilteredE9OwnerMarketplaceList.length > 0) {
          return data.Data.FilteredE9OwnerMarketplaceList;
        } else {
          return [];
        }
      }
    } catch (error) {}
  }
);
