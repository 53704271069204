import { CustomModal } from "../CustomModal/CustomModal";
import { Box, Button, Flex,Text } from "@chakra-ui/react";


export const CustomInfoModal = ({
  iconUrl="",
  isOpen = false,
  hasCloseButton,
  onCloseButtonClick,
  headerSectionMargin=null,
  heading,
  subHeading,
  primaryActionText,
  primaryAction = () => {},
  children,
  ...rest
}) => {
  return (
    <CustomModal isOpen={isOpen} w={""} 
    hasCloseButton={hasCloseButton}
    onCloseButtonClick={onCloseButtonClick}
    >
        <CustomModal.Header>
          <Flex flexDirection="column" gap={"16px"} margin={headerSectionMargin}>
          {iconUrl !== "" && (
            <Flex justifyContent="center" alignItems="center" padding={"5px"}>
              <img style={{ height: "50px" }} src={iconUrl} />
            </Flex>
          )}
          <Flex direction={"column"}>
           {heading && <Text textAlign="center" fontWeight={700} fontSize="20px" color={"#1A1E21"}>
                {heading}
            </Text>} 
            {subHeading &&<Text textAlign="center" fontWeight={400} fontSize="16px" color={"#959596"}>
                {subHeading}
            </Text>}
            </Flex>
          </Flex>
        </CustomModal.Header>
        <CustomModal.Body { ...rest}>
        {children}
        </CustomModal.Body>
       {primaryActionText && <CustomModal.Footer justifyContent="center" alignItems="center" maxHeight={"60px"}
        boxShadow={"0px -10px 15px 0px #2C2C2C28"}
        >
          <Button
            variant="gradient"
            onClick={primaryAction}
          >
            {primaryActionText}
          </Button>
        </CustomModal.Footer>} 
    </CustomModal>
  );
};
