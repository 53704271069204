import { API_CONSTANTS } from "../../../constants/apiConstants";
import { convertToIdValuePairs } from "../../helpers/dataConversion/convertToIdValuePairs";
import { fetchHandler } from "../../../index";

export const getStatesById = async (countryId) => {
  try {
    if (!countryId) {
      throw new Error("Country Id cannot be undefined");
    }
    const { data } = await fetchHandler(
      API_CONSTANTS.GET_STATE,
      { CountryId: countryId },
      {},
      {},
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: false }
    );
    if (data.Status === 200 && Object.keys(data.Data).length > 0) {
      const requiredData = data.Data.StatesList.map(({ StateId, StateName }) =>
        convertToIdValuePairs(StateId, StateName)
      );
      return requiredData;
    }
    return null;
  } catch (error) {
    throw error;
  }
};
