import React, { useEffect, useState } from "react";
import { V5SidebarLayout } from "../../../layout/V5SidebarLayout/V5SidebarLayout";
import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getOwnersSentCardsThunk } from "../thunks/getOwnersSentCardsThunk";
import { getOwnersReceivedCardsThunk } from "../thunks/getOwnersReceivedCardsThunk";
import OwnersSentCards from "../../../components/OwnerMarketplaceCard/OwnersSentCards";
import {
  updateContactRequest,
  updateOwnersConnect,
} from "../../../services/ownerMarketplace/ownerMarketplace";

const OwnersLeads = () => {
  const [marketplaceType, setMarketplaceType] = useState();
  const { defaultLanguage } = useSelector((state) => state.masterData);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sentCards, receivedCards, S3UserProfilePicBaseRef } = useSelector(
    (state) => state.ownersMarketplace
  );
  const { accountDetails } = useSelector((state) => state.user);
  const toast = useToast();

  useEffect(() => {
    handleCardsOnLocation();
  }, [location]);

  const handleCardsOnLocation = () => {
    const searchParams = new URLSearchParams(location.search);
    if (
      searchParams.get("marketplace-type") === "owners" &&
      searchParams.get("request-type") === "sent"
    ) {
      setMarketplaceType(searchParams.get("marketplace-type"));
      dispatch(getOwnersSentCardsThunk());
    } else if (
      searchParams.get("marketplace-type") === "owners" &&
      searchParams.get("request-type") === "received"
    ) {
      setMarketplaceType(searchParams.get("marketplace-type"));
      dispatch(getOwnersReceivedCardsThunk());
    } else {
      navigate("/dashboard");
    }
  };

  const handleAccept = async (data) => {
    const payload = {
      E9OwnerMpDetails: {
        E9OwnerMpConnectStatusId: data?.E9OwnerMpConnectStatusId,
        ResponseTypeId: data?.ResponseTypeValue?.toString()?.includes(
          "Request Received"
        )
          ? 2
          : 3,
        ResponseTypeValue: data?.ResponseTypeValue?.toString()?.includes(
          "Request Received"
        )
          ? "Request Accepted"
          : "Request Rejected",
        SenderUserCredentialId: data?.SenderUserCredId,
        OwnerUserCredentialId: data?.OwnerUserCredId,
        OwnerTaxNumber: data?.UserTypeValue?.toString()?.includes("Company")
          ? accountDetails?.CompanyDetails?.TaxNumber
          : null,
        SenderTaxNumber: data?.UserTypeValue?.toString()?.includes("Company")
          ? data?.SenderTaxNumber
          : null,
      },
    };

    const response = await updateOwnersConnect(payload);
    if (response) {
      toast({
        title: "Request Accepted Successfully!",
        isClosable: true,
        position: "top-right",
        status: "success",
      });
      handleCardsOnLocation();
    }
  };
  const handleReject = async (data) => {
    const payload = {
      E9OwnerMpDetails: {
        E9OwnerMpConnectStatusId: data?.E9OwnerMpConnectStatusId,
        ResponseTypeId: data?.ResponseTypeValue?.toString()?.includes(
          "Request Received"
        )
          ? 3
          : 2,
        ResponseTypeValue: data?.ResponseTypeValue?.toString()?.includes(
          "Request Received"
        )
          ? "Request Rejected"
          : "Request Accepted",
        SenderUserCredentialId: data?.SenderUserCredId,
        OwnerUserCredentialId: data?.OwnerUserCredId,
        OwnerTaxNumber: data?.UserTypeValue?.toString()?.includes("Company")
          ? accountDetails?.CompanyDetails?.TaxNumber
          : null,
        SenderTaxNumber: data?.UserTypeValue?.toString()?.includes("Company")
          ? data?.SenderTaxNumber
          : null,
      },
    };

    const response = await updateOwnersConnect(payload);
    if (response) {
      toast({
        title: "Request Rejected Successfully!",
        isClosable: true,
        position: "top-right",
        status: "success",
      });
      handleCardsOnLocation();
    }
  };

  const handleRevoke = async (data) => {
    const resTypeId = 4;
    const resTypeValue = "Request Revoked";

    const payload = {
      E9OwnerMpDetails: {
        E9OwnerMpConnectStatusId: data?.E9OwnerMpConnectStatusId,
        ResponseTypeId: resTypeId,
        ResponseTypeValue: resTypeValue,
        SenderUserCredentialId: Number(
          accountDetails?.UserDetails?.CredentialDetails?.UserCredentialId
        ),
        OwnerUserCredentialId: data?.SenderUserCredId,
        OwnerTaxNumber: data?.UserTypeValue?.toString()?.includes("Company")
          ? accountDetails?.CompanyDetails?.TaxNumber
          : null,
      },
    };

    const response = await updateContactRequest(payload);
    if (response) {
      toast({
        title : 'Request Revoked Successfully !',
        position: 'top-right',
        status : 'success',
        isClosable : true
      })
      handleCardsOnLocation();
    }
  };
  const renderSentCards = (array) => {
    const renderJSX =
      array && array.length > 0 ? (
        array.map((data) => (
          <OwnersSentCards
            isVerified={data?.isVerified}
            E9OwnerMpConnectStatusId={data?.E9OwnerMpConnectStatusId}
            MobileNumber={data?.MobileNumber}
            OwnerName={data?.OwnerName}
            UserTypeValue={data?.UserTypeValue}
            UserRole={data?.UserRole}
            ResponseTypeValue={data?.ResponseTypeValue}
            RequestSentDate={data?.RequestSentDate}
            StateName={data?.StateName}
            CountryName={data?.CountryName}
            onRevokeRequestClick={() => {
              handleRevoke(data);
            }}
          />
        ))
      ) : (
        <Text>No Records Found!</Text>
      );

    return renderJSX;
  };

  const renderReceivedCards = (array) => {
    const renderJSX =
      array && array.length > 0 ? (
        array.map((data) => (
          <OwnersSentCards
            isReceivedCards={true}
            isVerified={data?.isVerified}
            E9OwnerMpConnectStatusId={data?.E9OwnerMpConnectStatusId}
            MobileNumber={data?.MobileNumber}
            OwnerName={data?.OwnerName}
            UserTypeValue={data?.UserTypeValue}
            UserRole={data?.UserRole}
            ResponseTypeValue={data?.ResponseTypeValue}
            RequestReceivedDate={data?.RequestReceivedDate}
            StateName={data?.StateName}
            CountryName={data?.CountryName}
            onAcceptButtonClick={() => {
              handleAccept(data);
            }}
            onRejectButtonClick={() => {
              handleReject(data);
            }}
          />
        ))
      ) : (
        <Text>No Records Found!</Text>
      );

    return renderJSX;
  };

  return (
    <V5SidebarLayout>
      <Tabs isFitted variant="enclosed">
        <TabList>
          <Link
            style={{ width: "100%" }}
            to={`/owners-leads?marketplace-type=${marketplaceType}&request-type=sent`}
          >
            <Tab
              _selected={{ color: "white", bg: "#F79C00", width: "100%" }}
              width="100%"
            >
              {defaultLanguage?.Sent}
            </Tab>
          </Link>

          <Link
            style={{ width: "100%" }}
            to={`/owners-leads?marketplace-type=${marketplaceType}&request-type=received`}
          >
            <Tab
              _selected={{ color: "white", bg: "#F79C00", width: "100%" }}
              width="100%"
            >
              {defaultLanguage?.Received}
            </Tab>
          </Link>
        </TabList>

        <div>
          <TabPanels>
            <TabPanel>
              <Flex
                gap="1em"
                flexWrap="wrap"
                justifyContent={sentCards?.length <= 1 ? "" : "space-between"}
              >
                {renderSentCards(sentCards)}
              </Flex>
            </TabPanel>

            <TabPanel>
              <Flex
                gap="1em"
                flexWrap="wrap"
                justifyContent={
                  receivedCards?.length <= 1 ? "" : "space-between"
                }
              >
                {renderReceivedCards(receivedCards)}
              </Flex>
            </TabPanel>
          </TabPanels>
        </div>
      </Tabs>
    </V5SidebarLayout>
  );
};

export default OwnersLeads;
