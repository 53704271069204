import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_CONSTANTS } from "../../../../../constants/apiConstants";
import fetchHandler from "../../../../../webapp/services/fetchHandler";


export const delaershipLoadMoreDeferredThunk = createAsyncThunk("dealershipLoadMoreDeferredMarketplace/getLoadCards",async(_,{getState}) =>{

    try {
        const {category,cardCount,paginationCount} = getState().dealershipDeferred;
        const {data} = await fetchHandler(
            "/deferredLogin/marketplace",
            { category : category,limit : cardCount,offset : cardCount * paginationCount  },
            
            "",
            "",
            API_CONSTANTS.HTTP_GET,
            { isAuthRequired: false }
        )
        if(data?.Status === 200){

            return data?.Data;
        }
        return null ;
    } catch (error) {
        
    }
})