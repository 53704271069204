import { FormControl, FormErrorMessage, FormLabel, Input, Textarea } from "@chakra-ui/react";
import { useField } from "formik";

export const FormikInput = ({
  label,
  type,
  placeholder,
  isRequired,
  isDisabled,
  isInvalid,
  name,
  errorMessage = " ",
  maxLength,
  textArea = false
}) => {
  const [field, meta] = useField(name);

  return (
    <FormControl isDisabled={isDisabled} isInvalid={meta.touched && meta.error} isRequired={isRequired} maxW={textArea ? "" : "374px"}>
      <FormLabel color="#FECA00">{label}</FormLabel>
      {textArea ? 
       <Textarea
       placeholder={placeholder}
       isInvalid={isInvalid || (meta.touched && meta.error)}
       maxLength={maxLength}
       h="152px"
       border="1px solid #2c2c2c14"
       focusBorderColor="#2c2c2c"
       bgColor="#212529"
       color="#FFF"
       fontSize="16px"
       resize={"none"}
       {...field}

     />
     :
      <Input
        type={type}
        placeholder={placeholder}
        isInvalid={isInvalid || (meta.touched && meta.error)}
        maxLength={maxLength}
        h="72px"
        w="256px"
        border="1px solid #2c2c2c14"
        focusBorderColor="#2c2c2c"
        bgColor="#212529"
        color="#FFF"
        fontSize="16px"
        {...field}
        
      />
      
     }
      <FormErrorMessage>
        {meta.touched && meta.error ? meta.error : errorMessage}
      </FormErrorMessage>
    </FormControl>
  );
};