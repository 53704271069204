import React, { useState } from 'react';
import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Stack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { StyledLink } from '../../CommonComponents/Button/StyledButton';
import Logo from '../../../assets/logo/equip9_logo_light.png';
import { ReactSVG } from 'react-svg';
import Hemberger from '../../../assets/logo/hemburger.svg';
import './Navbar.css';
import { DrawerOptions } from '../../../staticData/staticData';

export const Navbar = ({ logo, navlinks }) => {
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setDrawerOpen(!isDrawerOpen);
    };

    return (
        <div className="navbar-wrapper">
            <div className="navbar float">
                <div className="logo-container">
                    <Link to="/">
                        <img src={Logo} alt="Equip9" />
                    </Link>
                </div>
                <Box className="cta-container" display={["none" , "none" , "none" , "flex" , "flex" ,"flex"]}>
                    <div className="navlinks-container">
                        <ul className="navlinks">
                            <li><Link className="link" to="/">Home</Link></li>
                            <li><Link className="link" to="/about">About</Link></li>
                            <li><Link className="link" to="/our-product">Product</Link></li>
                            <li><Link className="link" to="/our-blog">Blog</Link></li>
                            <li><Link className="link" to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                    <div className="buttons-container">
                        <StyledLink to="/login/login-form" variant="outlined" size="small">Login</StyledLink>
                        <StyledLink to="/welcome/select-language" size="small">Register</StyledLink>
                    </div>
                    
                   
                   
                
                </Box>
                <Flex display={["flex" , "flex" ,"flex","none" , "none" , "none"]}>
                    <ReactSVG src={Hemberger} onClick={toggleDrawer} />
                    </Flex>
            </div>

            {/* Drawer */}
            <Drawer isOpen={isDrawerOpen} placement="right" onClose={toggleDrawer}>
                <DrawerOverlay />
                <DrawerContent bg="#333333" color={"white"}>
                    <Flex borderBottom={"0.5px solid #FECA00"}>
                    <DrawerCloseButton />
                    <DrawerHeader >
                        {/* Navigation */}
                      
                        <ReactSVG src={Hemberger} onClick={toggleDrawer} />
                
                    </DrawerHeader>
                    </Flex>
                    
                    <DrawerBody>
                        <Stack spacing='24px'>
                            {DrawerOptions?.length > 0 ? DrawerOptions.map((item, list) => (
                                <>
                                    <Link to={item.Route} className="link">
                                        <Flex gap={"1em"} >
                                            <ReactSVG src={item.icon} />
                                            <p >{item.OptionName}</p>
                                        </Flex>
                                    </Link>
                                </>
                            )) : <p>No Data</p>}

                            {/* <Link className="link" to="/" onClick={toggleDrawer}>Home</Link>
                            <Link className="link" to="/about" onClick={toggleDrawer}>About</Link>
                            <Link className="link" to="/our-product" onClick={toggleDrawer}>Product</Link>
                            <Link className="link" to="/our-blog" onClick={toggleDrawer}>Blog</Link>
                            <Link className="link" to="/contact" onClick={toggleDrawer}>Contact</Link> */}
                            {/* <Link className="link" to="/login/login-form" onClick={toggleDrawer}>Login</Link>
                            <Link className="link" to="/welcome/select-language" onClick={toggleDrawer}>Register</Link> */}
                            {/* <StyledLink to="/login/login-form" variant="outlined" size="small" onClick={toggleDrawer}>Login</StyledLink>
                            <StyledLink to="/welcome/select-language" size="small" onClick={toggleDrawer}>Register</StyledLink> */}
                        </Stack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </div>
    );
};
