import { useDispatch, useSelector } from "react-redux";
import { SetIsAlreadyPlayedByUserModalOpen } from "../slice/quizSlice";
import { Flex, Text } from "@chakra-ui/react";
import { CustomGeneralModal } from "../../../components/CustomGeneralModal/CustomGeneralModal";

export const AlreadyPlayedByUserPopup = () => {
  const { isAlreadyPlayedByUserPopupOpen, selectedQuiz } = useSelector(
    (state) => state.quiz
  );
  const dispatch = useDispatch();
  const onButtonClick = () => {
    dispatch(SetIsAlreadyPlayedByUserModalOpen(false));
};

  return (
    <>
      <CustomGeneralModal
        isOpen={isAlreadyPlayedByUserPopupOpen}
        iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/popup/quizDone.svg`}
        heading={selectedQuiz?.QuizName}
        iconHeight="50px"
        iconWidth="115px"
        iconMaxWidth="115px"
        iconMaxHeight="50px"
        primaryAction={onButtonClick}
        primaryActionText={"Okay"}
        hasCloseButton={true}
        onCloseButtonClick={onButtonClick}
        margin={"0 60px"}
        footerShadow={true}
        isArrow={false}
      >
        <Flex direction={"column"} gap={"12px"}>
        <Flex direction={"column"} fontSize={"18px"} fontWeight={"600"} color={"#2C2C2C"} alignItems={"center"}> 
            <Text>You have already played this quiz</Text>
            <Text>and  have scored <span style={{color:"#002961",fontWeight:"700"}}>{selectedQuiz?.PointsScoredByUser}/{selectedQuiz?.TotalQuestPoints}</span></Text>
        </Flex>
        <Text fontSize={"16px"} fontWeight={"600"} color={"#767676"}>Quiz results will be declared in {selectedQuiz?.DaysRemainedForResults} day!</Text></Flex>
      </CustomGeneralModal>
    </>
  );
};
