import { createSlice } from "@reduxjs/toolkit";
import { getInitialDataThunk } from "../thunks/getInitialDataThunk";
import { getLoadMoreDataThunk } from "../thunks/GetLoadMoreDataThunk";
import { getInitialFilteredDataThunk } from "../thunks/getInitialFilteredDataThunk";
import { getLoadMoreFilteredDataThunk } from "../thunks/getLoadMoreFilteredDataThunk";

export const maintenancePartnerSlice = createSlice({
  name: "maintenancePartnernearMe",
  initialState: {
    data: null,
    imageBaseUrl: null,
    paginationCount: 0,
    endOfData: false,
    cardCount: 12,
    initialLoading: false,
    paginationLoading: false,
    filterByEquipmentType: "",
    filterByVerificationStatus: "",
    filterByCountry: "",
    filterByState: "",
    filteredDataRequired: null,
    filterModalOpen: false,
    error: null,
    errorMessage: null,
    
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setImageBaseUrl: (state, action) => {
      state.imageBaseUrl = action.payload;
    },
    setPaginationCount: (state, action) => {
      state.paginationCount = action.payload;
    },
    setEndOfData: (state, action) => {
      state.endOfData = action.payload;
    },
    setInitialLoading: (state, action) => {
      state.initialLoading = action.payload;
    },
    setPaginationLoading: (state, action) => {
      state.paginationLoading = action.payload;
    },
    setFilteredDataRequired: (state, action) => {
      state.filteredDataRequired = action.payload;
    },
    setFilterModalOpen: (state, action) => {
      state.filterModalOpen = action.payload;
    },
    setFilterByCountry: (state, action) => {
      state.filterByCountry = action.payload;
    },
    setFilterByState: (state, action) => {
      state.filterByState = action.payload;
    },
    setFilterByVerificationStatus: (state, action) => {
      state.filterByVerificationStatus = action.payload;
    },
    setFilterByEquipmentType: (state, action) => {
      state.filterByEquipmentType = action.payload;
    },
    resetFilters: (state) => {
      state.filterByEquipmentType = null;
      state.filterByCountry = null;
      state.filterByState = null;
      state.filterByVerificationStatus = null;
      state.filteredDataRequired = false;
      state.filterModalOpen = false;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInitialDataThunk.pending, (state) => {
        state.data = null;
        state.imageBaseUrl = null;
        state.paginationCount = 0;
        state.endOfData = false;
        state.cardCount = 12;
        state.initialLoading = true;
        state.paginationLoading = false;
        state.error = null;
        state.errorMessage = null;
      })
      .addCase(getInitialDataThunk.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.imageBaseUrl = action.payload.imageBaseUrl;
        state.paginationCount = 1;
        state.initialLoading = false;
        state.paginationLoading = false;
        state.error = null;
        state.errorMessage = null;
        if (action.payload.data < state.cardCount) {
          state.endOfData = true;
        }
      })
      .addCase(getLoadMoreDataThunk.pending, (state) => {
        state.paginationLoading = true;
        state.error = null;
        state.errorMessage = null;
      })
      .addCase(getLoadMoreDataThunk.fulfilled, (state, action) => {
        state.data = [...state.data, ...action.payload];
        state.paginationCount += 1;
        state.paginationLoading = false;
        state.initialLoading = false;
        state.error = null;
        state.errorMessage = null;
        if (action.payload < state.cardCount) {
          state.endOfData = true;
        }
      })
      .addCase(getInitialFilteredDataThunk.pending, (state) => {
        state.data = null;
        // state.imageBaseUrl = null;
        state.paginationCount = 0;
        state.endOfData = false;
        state.cardCount = 12;
        state.initialLoading = true;
        state.paginationLoading = false;
        state.error = null;
        state.errorMessage = null;
      })
      .addCase(getInitialFilteredDataThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.paginationCount = 1;
        state.initialLoading = false;
        state.error = false;
        state.errorMessage = null;
        if (action.payload.length < state.cardCount) {
          state.endOfData = true;
        }
      })
      .addCase(getLoadMoreFilteredDataThunk.pending, (state) => {
        state.paginationLoading = true;
        state.error = null;
        state.errorMessage = null;
      })
      .addCase(getLoadMoreFilteredDataThunk.fulfilled, (state, action) => {
        if (action.payload && action.payload.length > 0) {
          state.data = [
            ...state.data,
            ...action.payload,
          ];
        }
        state.paginationCount += 1;
        state.paginationLoading = false;
        state.initialLoading = false;
        state.error = null;
        state.errorMessage = null;
        if (action.payload.length < state.cardCount) {
          state.endOfData = true;
        }
      });
  },
});

export const {
  setData,
  setImageBaseUrl,
  setPaginationCount,
  setEndOfData,
  setInitialLoading,
  setPaginationLoading,
  setFilteredDataRequired,
  setFilterByEquipmentType,
  setFilterByCountry,
  setFilterByState,
  setFilterByVerificationStatus,
  setFilterModalOpen,
  resetFilters,
  setError,
  setErrorMessage,
} = maintenancePartnerSlice.actions;
