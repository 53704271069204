import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserTaxDetailsThunk } from "./getUserTaxDetailsThunks";
import { getCompanySubscriptionTransactionDetails } from "../../../services/subscription/subscription";


export const getCompanySubscriptionTransactionDetailsThunk = createAsyncThunk("/subscription/getCompanySubscriptionTransactionDetails", async(_, {getState, dispatch}) => {
    const {orderId} = getState().subscription
  
    try{ 
   
        const data = await getCompanySubscriptionTransactionDetails(orderId.VirtualSubsOrderId)

        if(data.Status === 200){
            await dispatch(getUserTaxDetailsThunk(data.Data[0].NetPayableAmountWithoutTax)).unwrap()
            return data.Data[0]            
        }
        
    }
    catch(error){
        console.log(error)
        return error
    }
})