import React from 'react';
import { SideMenuLayout } from '../../../../../layout/sideMenuLayout/SideMenuLayout';
import { DashboardNavBar } from '../../../../../components/DashboardNavBar/DashboardNavBar';
import { MidSection } from '../../../../../../sharedComponent/midSection/MidSection';
import "../LegalPolicy/LegalPolicy.css";

const ContactUs = () => {
  return (
    <>
      <SideMenuLayout>
        <DashboardNavBar />
        <MidSection>
          <div className="new-terms">
            <h2 className="new-terms-heading">Contact Us</h2>

            <div className="new-terms-content-grid">
              <div className="new-terms-content">
                Thank You for choosing the EQUIP9™ digital app.
              </div>

              <div className="new-terms-content">
                You can reach out to us on our Twitter page:&nbsp;
                <a href="https://twitter.com/futurebizequip9" target="_blank" className="terms-link" style={{ color: 'blue', textDecoration: 'underline' }}>
                  <i className="fab fa-twitter"></i>Twitter
                </a>
              </div>

              <div className="new-terms-content">
                Or You can reach out to us on our Facebook page:&nbsp;
                <a href="https://www.facebook.com/futurebizequip9" target="_blank" className="terms-link" style={{ color: 'blue', textDecoration: 'underline' }}>
                  <i className="fab fa-facebook"></i>Facebook
                </a>
              </div>

              <div className="new-terms-content">
                Or You can reach out to us on our LinkedIn page:&nbsp;
                <a href="https://www.linkedin.com/company/futurebizequip9" target="_blank" className="terms-link" style={{ color: 'blue', textDecoration: 'underline' }}>
                  <i className="fab fa-linkedin"></i>LinkedIn
                </a>
              </div>

              <div className="new-terms-content">
                Or You can reach out to us by writing your query using our website:&nbsp;
                <a href="https://equip9.com/contacts" target="_blank" className="terms-link" style={{ color: 'blue', textDecoration: 'underline' }}>
                  <i className="fas fa-envelope"></i>Email Us
                </a>
              </div>

              <div className="new-terms-content">
                Or You can connect with us on What's App or over Call on +91-7822095128
              </div>
            </div>
          </div>
        </MidSection>
      </SideMenuLayout>
    </>
  );
};

export default ContactUs;
