import { useEffect, useState } from "react";
import { V5SidebarLayout } from "../../../../layout/V5SidebarLayout/V5SidebarLayout";
import { getQuizWinnerList } from "../../../../services/quiz/quiz";
import { useLocation, useParams } from "react-router-dom";
import { Flex, Text } from "@chakra-ui/react";

export const QuizWinnersPage = () => {
  const location = useLocation();
  const sponseredBy = location.state?.sponseredBy;
  const { quizId } = useParams();
  const [userScore, setUserScore] = useState(null);
  const [winnerList, setWinnerList] = useState([]);

  useEffect(() => {
    const fetchWinners = async () => {
      const data = await getQuizWinnerList(quizId);
      setUserScore(data?.Data?.QuizUserScore);
      setWinnerList(data?.Data?.QuizTopWinnersList);
    };

    fetchWinners();
  }, [quizId]);

  return (
    <>
      <V5SidebarLayout>
        <Flex gap={"20px"} direction={"column"} margin={"0 52px"}>
          {/* header section */}
          <Flex direction={"column"}>
            <Text fontSize={"24px"} fontWeight={"700"} color={"#1A1E21"}>
              E9-Quiz Winners
            </Text>
            <Text fontSize={"20px"} fontWeight={"400"} color={"#767676"}>
              Get a chance to win exciting prices
            </Text>
          </Flex>

          <Flex direction={"column"} gap={"10px"}>
            <Flex justifyContent={"space-between"}>
              <Text fontSize={"18px"} fontWeight={"700"} color={"#4A5A82"}>
                YOUR SCORE
              </Text>
              <Text fontSize={"16px"} fontWeight={"600"} color={"#767676"}>
                Sponsored :{sponseredBy}
              </Text>
            </Flex>

            <Flex>
              {userScore ? (
                <Flex
                  boxShadow={"0px 20px 25px -5px #0000001A"}
                  border={"2px solid #F5F5F5"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  borderRadius={"5px"}
                  padding={"30px 114px 30px 17px"}
                  gap={"12px"}
                >
                  <img
                    src={
                      userScore?.ProfilePictureFileReference
                        ? `${process.env.REACT_APP_CDN_PVT_URL}UserProfilePicture/${userScore?.ProfilePictureFileReference}`
                        : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Avatar.png`
                    }
                    alt="User Avatar"
                    style={{
                      width: "66px",
                      height: "66px",
                      borderRadius: "50%",
                    }}
                  />

                  <Flex direction={"column"}>
                    <Text
                      fontSize={"14px"}
                      fontWeight={"700"}
                      color={"#1A1E21"}
                    >
                      {userScore?.FullName}
                    </Text>
                    <Text
                      fontSize={"16px"}
                      fontWeight={"700"}
                      color={"#002961"}
                    >
                      Rank : {userScore?.Rank}
                    </Text>
                    <Text
                      fontSize={"14px"}
                      fontWeight={"600"}
                      color={"#484848"}
                    >
                      Total Points:{" "}
                      <span style={{ fontWeight: 700 }}>
                        {" "}
                        {userScore?.UserQuizScoredPoint}/
                        {userScore?.TotalQuizPoints}
                      </span>
                    </Text>
                    <Text
                      fontSize={"14px"}
                      fontWeight={"600"}
                      color={"#484848"}
                    >
                      Total Time:
                      <span style={{ fontWeight: 700 }}>
                        {userScore?.QuizTimeInMinSec}m
                      </span>
                    </Text>
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  w={"244px"}
                  h={"81px"}
                  border={"2px dashed #002961"}
                  borderRadius={"5px"}
                  boxShadow={"0px 10px 10px -5px #0000000A"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Text fontSize={"16px"} fontWeight={"700"} color={"#002961"}>
                    You missed the Quiz
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
          <Flex direction={"column"} gap={"10px"}>
            <Text fontSize={"18px"} fontWeight={"700"} color={"#4A5A82"}>
              TOP WINNERS
            </Text>

            <Flex gap={"16px"}>
              {winnerList && winnerList?.length > 0 ? (
                winnerList.map((item) => (
                  <Flex
                    boxShadow={"0px 20px 25px -5px #0000001A"}
                    border={"2px solid #E3E5E8"}
                    borderRadius={"5px"}
                    direction={"column"}
                  >
                    <Flex justifyContent={"end"} padding={"8px"} w={"384px"}>
                      <Flex
                        backgroundColor={"#319F00A6"}
                        padding={"4px 48px"}
                        borderRadius={"20px"}
                        color={"#FEFEFE"}
                        fontSize={"12px"}
                        fontWeight={"600"}
                      >
                        {item?.Rank === 1
                          ? "Winner"
                          : item?.Rank === 2
                          ? "Runner-up"
                          : "2nd Runner-up"}
                      </Flex>
                    </Flex>
                    <Flex
                      gap={"12px"}
                      padding={"0 114px 30px 17px"}
                      alignItems={"center"}
                    >
                      <img
                        src={
                          item?.ProfilePictureFileReference
                            ? `${process.env.REACT_APP_CDN_PVT_URL}UserProfilePicture/${item?.ProfilePictureFileReference}`
                            : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Avatar.png`
                        }
                        alt="User Avatar"
                        style={{
                          width: "66px",
                          height: "66px",
                          borderRadius: "50%",
                        }}
                      />

                      <Flex direction={"column"}>
                        <Text
                          fontSize={"14px"}
                          fontWeight={"700"}
                          color={"#1A1E21"}
                        >
                          {item?.FullName}
                        </Text>
                        <Text
                          fontSize={"16px"}
                          fontWeight={"700"}
                          color={"#002961"}
                        >
                          Rank : {item?.Rank}
                        </Text>
                        <Text
                          fontSize={"14px"}
                          fontWeight={"600"}
                          color={"#484848"}
                        >
                          Total Points:{" "}
                          <span style={{ fontWeight: 700 }}>
                            {" "}
                            {item?.UserQuizScoredPoint}/{item?.TotalQuizPoints}
                          </span>
                        </Text>
                        <Text
                          fontSize={"14px"}
                          fontWeight={"600"}
                          color={"#484848"}
                        >
                          Total Time:
                          <span style={{ fontWeight: 700 }}>
                            {item?.QuizTimeInMinSec}m
                          </span>
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                ))
              ) : (
                <></>
              )}
            </Flex>
          </Flex>
        </Flex>
      </V5SidebarLayout>
    </>
  );
};
