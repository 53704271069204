import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { CustomModal } from "../CustomModal/CustomModal";
import { ArrowBackIcon } from "@chakra-ui/icons";

export const CustomGeneralModal = ({
  isOpen = false,
  hasCloseButton = false,
  iconUrl = null,
  onCloseButtonClick = () => {},
  primaryActionText,
  footerShadow = false,
  isArrow = true,
  primaryAction = () => {},
  secondaryAction = () => {},
  primaryButtonIsDisable = false,
  ButtonAlignment = null,
  buttonMinWidth = null,
  secondaryActionText,
  heading,
  subHeading,
  extraHeading = "",
  children,
  iconMaxHeight = "",
  iconMaxWidth = "",
  iconHeight = "",
  iconWidth = "",
  ...rest
}) => {
  return (
    <CustomModal
      isOpen={isOpen}
      hasCloseButton={hasCloseButton}
      onCloseButtonClick={onCloseButtonClick}
      w={""}
    >
      <CustomModal.Header textAlign="center">
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
          gap={"16px"}
          margin={"0 50px"}
        >
          {iconUrl && (
            <Box>
              <img
                style={{
                  maxHeight: iconMaxHeight ? iconMaxHeight : "50px",
                  maxWidth: iconMaxWidth ? iconMaxWidth : "50px",
                  height: iconHeight ? iconHeight : "50px",
                  width: iconWidth ? iconWidth : "50px",
                }}
                src={iconUrl}
                alt="icon"
              />
            </Box>
          )}
          <Flex direction={"column"}>
            {heading && (
              <Text
                textAlign="center"
                fontWeight={700}
                fontSize="20px"
                color={"#1A1E21"}
              >
                {heading}
              </Text>
            )}
            {subHeading && (
              <Text
                textAlign="center"
                fontWeight={400}
                fontSize="16px"
                color={"#959596"}
              >
                {subHeading}
              </Text>
            )}
            {extraHeading}
          </Flex>
        </Box>
      </CustomModal.Header>
      <CustomModal.Body {...rest}>{children}</CustomModal.Body>
      <CustomModal.Footer
        style={{
          boxShadow: footerShadow ? "0px -10px 10px 0px #2C2C2C0F" : null,
        }}
      >
        <Flex
          justifyContent={
            secondaryActionText
              ? "space-between"
              : ButtonAlignment
              ? ButtonAlignment
              : "center"
          }
          width="100%"
        >
          {secondaryActionText && (
            <Button
              outline="none"
              boxShadow="none"
              background="#fff"
              color="#2c2c2c90"
              onClick={secondaryAction}
            >
              <Flex gap={"15px"}>
                {isArrow && (
                  <Box>
                    {" "}
                    <ArrowBackIcon />
                  </Box>
                )}
                <Text>{secondaryActionText}</Text>
              </Flex>
            </Button>
          )}

          <Button
            variant="gradient"
            isDisabled={primaryButtonIsDisable}
            minW={buttonMinWidth}
            onClick={primaryAction}
          >
            {primaryActionText}
          </Button>
        </Flex>
      </CustomModal.Footer>
    </CustomModal>
  );
};
