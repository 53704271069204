import { Button, Flex, Text } from "@chakra-ui/react";
import { V5SidebarLayout } from "../../../layout/V5SidebarLayout/V5SidebarLayout";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { quizInitialListThunk } from "../thunks/quizInitialListThunk";
import { MarketplaceHoc } from "../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { QuizCard } from "./QuizCard";
import { quizListLoadMoreThunk } from "../thunks/quizListLoadMoreThunk";
import { WelcomePopUp } from "../../../components/WelcomePopup/WelcomePopup";
import { ComingSoonPopup } from "./ComingSoonPopup";
import { SetIsActiveQuizModalOpen, SetIsAlreadyPlayedByUserModalOpen, SetIsComingSoonModalOpen, SetIsQuizNotPlayedByAnyoneModalOpen, setSelectedQuiz } from "../slice/quizSlice";
import { QuizNotPlayedByAnyonePopup } from "./QuizNotPlayedByAnyonePopup";
import { ActiveQuizPopup } from "./ActiveQuizPopup";
import { AlreadyPlayedByUserPopup } from "./AlreadyPlayedByUserPopup";
import { useNavigate } from "react-router-dom";

export const QuizListing = () => {
  const navigate=useNavigate();
  const dispatch = useDispatch();
  const { defaultLanguage } = useSelector((state) => state.masterData);

  const [isWelcomePopupOpen, setIsWelcomePopupOpen] = useState(true);

  const {
    data: initialData,
    endOfData,
    initialLoading,
    paginationLoading,
    imageBaseUrl,
  } = useSelector((state) => state.quiz);

  useEffect(() => {
    dispatch(quizInitialListThunk());
  }, []);

  const handleQuizCardClick = (item) => {
   dispatch(setSelectedQuiz(item));
				switch (item.QuizStatus.toLowerCase()) {
					case "Active".toLowerCase():
						if (item.IsQuizPlayedByUser.toLowerCase() === "yes") {
              dispatch(SetIsAlreadyPlayedByUserModalOpen(true));
              
						} else {
              dispatch(SetIsActiveQuizModalOpen(true));
						}
						break;

					case "Expired".toLowerCase():
						if (item.QuizPlayedUsersCount > 0 && item.TotalQuizScorers > 0) {

              navigate(`/quiz/${item?.QuizId}/winners`, {
                state: { sponseredBy: item?.SponsoredBy },
              });
              
						} else {
              dispatch(SetIsQuizNotPlayedByAnyoneModalOpen(true));
						}
						break;
					case "Coming Soon".toLowerCase():
            dispatch(SetIsComingSoonModalOpen(true));
						break;
          default :
            break;
				}
		
	};

  const renderCards = (array) => {
    const renderJsx =
      array && array.length > 0 ? (
        array.map((quiz) => {
          return (
            <QuizCard
              key={uuid()}
              name={quiz.QuizName}
              status={quiz.QuizStatus}
              startDate={quiz.QuizStartDate}
              expiredOnDate={quiz.ExpiringOn}
              promotedBy={quiz.PromotedBy}
              SponsoredBy={quiz.SponsoredBy}
              imageBaseUrl={imageBaseUrl}
              QuizImageReference={quiz.QuizImageReference}
              QuizPlayedUsersCount={quiz.QuizPlayedUsersCount}
              IsQuizPlayedByUser={quiz.IsQuizPlayedByUser}
              PointsScoredByUser={quiz.PointsScoredByUser}
              TotalQuestPoints={quiz.TotalQuestPoints}
              onCardClick={()=>{handleQuizCardClick(quiz)}}
            />
          );
        })
      ) : (
        <p>No Quiz Record Found !</p>
      );
    return renderJsx;
  };

  return (
    <>
    <ComingSoonPopup/>
    <QuizNotPlayedByAnyonePopup/>
    <ActiveQuizPopup/>
    <AlreadyPlayedByUserPopup/>
      <WelcomePopUp
        iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/popup/lightBulb.svg`}
        isOpen={isWelcomePopupOpen}
        descriptionArray={[
          "Ace construction quizzes and win prizes!",
          "Test your knowledge, score high, and win!",
          "Compete in quizzes, top the charts, earn rewards!",
        ]}
        location="Quiz"
        onButtonClick={() => {
          setIsWelcomePopupOpen(false);
        }}
      />
      <V5SidebarLayout>
        <Flex gap={"10px"} direction={"column"} margin={"0 52px"}>
          {/* header section */}
          <Flex direction={"column"}>
            <Text fontSize={"24px"} fontWeight={"700"} color={"#1A1E21"}>
              {defaultLanguage.Equip9_Quizzes}
            </Text>
            <Text fontSize={"20px"} fontWeight={"400"} color={"#767676"}>
              {defaultLanguage.Play_today_and_win_exciting_Prizes}
            </Text>
          </Flex>

          {/* action buttons */}
          <Flex justifyContent={"space-between"}>
            <Flex gap={"10px"}>
              <Button
                variant="outline"
                color={"#F79C00"}
                border={"1px solid #F79C00"}
                onClick={() => {}}
              >
                Equip9 Quizzes
              </Button>
              <Button
                variant="outline"
                color={"#002961"}
                border={"1px solid #002961"}
                onClick={() => {
                  navigate("/my-quizzes");
                }}
              >
                Played Quizzes
              </Button>
            </Flex>
          </Flex>
        </Flex>
        

       {initialData ? <MarketplaceHoc
          marketplaceType="jobs"
          isNewNavbar={true}
          dataArray={initialData || []}
          endOfData={endOfData}
          loadMoreAction={() => {
            dispatch(quizListLoadMoreThunk());
          }}
          initialLoading={initialLoading}
          paginationLoading={paginationLoading}
          cardsRenderFunction={renderCards}
        /> : <Flex direction={"column"} fontSize={"16px"} fontWeight={"400"} color={"#484848"} margin={"0 52px"} h={"60vh"} justifyContent={"center"} alignItems={"center"}>No Quizzes Available</Flex>} 

      </V5SidebarLayout>
    </>
  );
};
