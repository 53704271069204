import { Button } from "@chakra-ui/button";
import { CloseIcon } from "@chakra-ui/icons";
import { Flex, Text, Box } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { useSelector } from "react-redux";
import { CustomInfoModal } from "../../../../components/CustomInfoModal/CustomInfoModal";

export const OffersModal = ({
  isOpen = false,
  dataArray,
  textValue,
  onCloseButtonClick = () => {},
  onApplyButtonClick = () => {},
}) => {
  const { checkoutTransactionDetails } = useSelector(
    (state) => state.subscription
  );
  return (
    // <Modal isOpen={isOpen} isCentered>
    //     <ModalOverlay />
    //     <ModalContent>
    //         <ModalHeader>
    //             <Flex justifyContent="flex-end">
    //                 <Button
    //                     variant="ghost"
    //                     onClick={onCloseButtonClick}
    //                     boxShadow="none"
    //                 >
    //                     <CloseIcon />
    //                 </Button>
    //             </Flex>

    //             <Flex alignItems="center" justifyContent="center">
    //                 <Text
    //                     textTransform="uppercase"
    //                     color="#1E3264"
    //                     fontSize="22px"
    //                     fontWeight="700"
    //                     letterSpacing="1px"
    //                 >
    //                     {" "}
    //                     Offers and Promocode
    //                 </Text>
    //             </Flex>
    //         </ModalHeader>
    //         <ModalBody>
    //             <Flex
    //                 maxH="250px"
    //                 overflowY="scroll"
    //                 flexDirection="column"
    //                 gap="1.75em"
    //             >
    //       <Flex gap="0.5em" flexDir="column">
    //         {dataArray && dataArray.length > 0 ? (
    //           dataArray.map((offer) => {
    //             const { Description, OfferCode, OfferId, OfferName } =
    //               offer;

    //             return (
    //               <Flex
    //                 key={OfferId}
    //                 alignItems="center"
    //                 justifyContent="space-between"
    //               >
    //                 <Box>
    //                   <Box>
    //                     <img src=""></img>
    //                   </Box>{" "}
    //                   <Flex flexDirection="column">
    //                     <Text color="#2c2c2c" fontWeight="700">
    //                       {OfferName}
    //                     </Text>
    //                     <Text color="#2c2c2c90" fontSize="16px">
    //                       {Description}
    //                     </Text>
    //                   </Flex>
    //                 </Box>{" "}
    //                 <Button
    //                   outline="none"
    //                   boxShadow="none"
    //                   background="transparent"
    //                   color= {`${offer?.OfferId === checkoutTransactionDetails?.OfferId && textValue ? "#319F00" : "#F79C00" }` }
    //                   onClick={()=>onApplyButtonClick(offer)}
    //                 >
    //                   {offer?.OfferId === checkoutTransactionDetails?.OfferId && textValue ? "Applied" : "Apply"}
    //                 </Button>
    //               </Flex>
    //             );
    //           })
    //         ) : (
    //           <Text>No offers available</Text>
    //         )}
    //       </Flex>

    //             </Flex>
    //         </ModalBody>
    //     </ModalContent>
    // </Modal>
    <CustomInfoModal
      isOpen={isOpen}
      hasCloseButton={true}
      onCloseButtonClick={onCloseButtonClick}
      heading={"Offers and Promocode"}
      width="400px"
    >
      <Flex margin={"0 50px 20px"} justifyContent={"center"} alignItems={"center"}>
      <Flex minW="250px" overflowY="scroll" flexDirection="column" gap="1.75em" >
        <Flex gap="0.5em" flexDir="column">
          {dataArray && dataArray.length > 0 ? (
            dataArray.map((offer) => {
              const { Description, OfferCode, OfferId, OfferName } = offer;

              return (
                <Flex
                  key={OfferId}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Box>
                      <img src=""></img>
                    </Box>{" "}
                    <Flex flexDirection="column">
                      <Text color="#2c2c2c" fontWeight="700">
                        {OfferName}
                      </Text>
                      <Text color="#2c2c2c90" fontSize="16px">
                        {Description}
                      </Text>
                    </Flex>
                  </Box>{" "}
                  <Button
                    outline="none"
                    boxShadow="none"
                    background="transparent"
                    color={`${
                      offer?.OfferId === checkoutTransactionDetails?.OfferId &&
                      textValue
                        ? "#319F00"
                        : "#F79C00"
                    }`}
                    onClick={() => onApplyButtonClick(offer)}
                  >
                    {offer?.OfferId === checkoutTransactionDetails?.OfferId &&
                    textValue
                      ? "Applied"
                      : "Apply"}
                  </Button>
                </Flex>
              );
            })
          ) : (
            <Text>No offers available</Text>
          )}
        </Flex>
      </Flex>
      </Flex>
    </CustomInfoModal>
  );
};
