import { Box, Text, Flex } from "@chakra-ui/react";
export const OwnerMarketplaceDetailsCard = ({ count, type, icon, fileRef }) => (
  <div>
    <Flex direction="column" alignItems="center">
      <Flex gap={"4px"}>
        <Box>
          <img
            src={`${process.env.REACT_APP_CDN_PVT_URL}${fileRef}${icon}`}
            alt={`logo`}
            style={{
              width: "20px",
              height: "20px",
            }}
          />
        </Box>

        <Text fontSize={"18px"} color={"#1A1E21"} fontWeight={"600"}>
          {count}
        </Text>
      </Flex>

      <Box fontSize={"16px"} color={"#484848"} fontWeight={"600"}>
        <Text textAlign={"center"}>{type}</Text>
      </Box>
    </Flex>
  </div>
);
