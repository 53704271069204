import { createAsyncThunk } from "@reduxjs/toolkit";
import { createNewAsset } from "../../../services/equipments/equipments";

export const createNewAssetDetailsThunk = createAsyncThunk(
  "/equipmentListing/createNewAssetDetails",
  async (_, { getState, rejectWithValue }) => {
    const {
      equipmentNickname,
      equipmentType,
      equipmentMake,
      otherMake,
      equipmentModel,
      otherModel,
      manufacturingYear,
      meterReading,
      equipmentLocation,
      country,
      state,
      city,
      pincode,
      isInsuranceActive,
      insuranceActiveDate,
      engineMake,
      chassisNumber,
      fuelType,
      autoEngineShutdown,
    } = getState().addEquipment;

    const body = {
      AssetNickname: equipmentNickname,
      EquipmentTypeId: parseInt(equipmentType),
      EquipmentMakeId: parseInt(equipmentMake),
      EquipmentModelId: parseInt(equipmentModel),
      AssetManufacturingYear: parseInt(manufacturingYear),
      // AssetCurrentMeterReading: parseInt(meterReading),
      AssetLocationAddress: equipmentLocation,
      AssetLocationCountryId: parseInt(country),
      AssetLocationStateId: parseInt(state),
      AssetLocationCityId: parseInt(city),
      AssetLocationZipCode: pincode ? parseInt(pincode) : "",
      AssetInsuranceTypeId: isInsuranceActive ? parseInt(isInsuranceActive) : "",
      AssetInsuranceValidityDate: insuranceActiveDate
        ? insuranceActiveDate
        : "",
      AssetEngineMakeId: engineMake ? parseInt(engineMake) : "",
      AssetChassisNumber: chassisNumber,
      AssetFuelTypeId :  fuelType ? parseInt(fuelType) : "",
      AssetAutoEngineShutdownId: autoEngineShutdown ? parseInt(autoEngineShutdown) : "",
      OtherOemName: otherMake ? otherMake : "",
      OtherModelName: otherModel ? otherModel : "",
    };
    if(meterReading && parseInt(meterReading) > 0){
      body.AssetCurrentMeterReading = parseInt(meterReading)
    }
    
    try{
        const data = await createNewAsset(body);
       return data 
    }
    catch(error){
      rejectWithValue(error)
      throw error;
    }
  }
);
