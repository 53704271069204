import {
  Flex,
  Box,
  Button,
  Heading,
  Text,
  useToast,
  Image,
  Tooltip,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getBusinessTypesThunk } from "../../../global/mtData/thunk/getBusinessTypesThunk";
import { ContainerWithSeperator } from "../../../../components/ContainerWithSeperator/ContainerWithSeperator";
import { SelectCardWrapper } from "../../../../components/SelectCardWrapper/SelectCardWrapper";
import { imageSelector } from "../../../../helpers/categoryImageHandler/categoryImageHandler";
import {
  setSelectedBusinessType,
  setSelectedUserType,
} from "../../slice/registrationSlice";
import { subString } from "../../../../helpers/dataConversion/substring";
import { getAllLanguageJsonThunk } from "../../../global/mtData/thunk/getAllLanguagesThunk";
import { setSelectedCountryThunk } from "../../../global/appConfig/thunk/setSelectedCountryThunk";
import { V5MultiAdLayout } from "../../../../layout/multiAdLayout/V5MultiAdLayout";
import { NAVIGATION_CONSTANTS } from "../../../../../constants/naigationContants";
import { V5Scroll } from "../../../../components/V5Scroll/V5Scroll";
import { V5Card } from "../../../../components/VrCard/V5Card";
import GuestLogo from "../../../../../assets/webapp/registartion/Guest.png";
import { openQrModal } from "../../../dashboard/slice/dashboardSlice";
import { QrPopUp } from "../../../../components/ScanQrCodeModal/QrPopUp";

import { Helmet } from "react-helmet";
import regsitemap from "./seo/Registration.xml";
export const SelectBusinessType = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const masterData = useSelector((state) => state.masterData);
  const registrationData = useSelector((state) => state.registration);
  const [partnerOptions, setPartnerOptions] = useState(null);
  const [ownerOptions, setOwnerOptions] = useState(null);
  const [otherOptions, setOtherOptions] = useState(null);
  const { defaultLanguage } = useSelector((state) => state.masterData);
  const [searchParams, setSearchParams] = useSearchParams();
  const assetIdParam = searchParams.get(
    NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID
  );
  const cardRefs = useRef([]);

  useEffect(() => {
    if (!defaultLanguage) {
      dispatch(setSelectedCountryThunk("91"));
      dispatch(getAllLanguageJsonThunk("english.json"));
    }
  }, [!defaultLanguage]);

  const filterBusinessTypes = (array, keyValue, string = "") => {
    if (!array || array.length === 0) {
      return null;
    }
    const filteredArray = array.filter(
      (item) =>
        item[`${keyValue}`].toLowerCase().indexOf(string.toLowerCase()) > -1
    );
    return filteredArray;
  };

  const filterOtherBusinessTypes = (array) => {
    if (!array || array.length === 0) {
      return null;
    }

    const otherBusinessTypes = array.filter(
      (item) =>
        item.BusinessTypeValue.indexOf("Partner") === -1 &&
        item.BusinessTypeValue.indexOf("Owner") === -1
    );
    return otherBusinessTypes;
  };

  const categoriseBusinessTypes = (array, keyValue, string) => {
    if (!array || array.length === 0) {
      return null;
    }

    const categorisedBusinessTypeArray = filterBusinessTypes(
      array,
      keyValue,
      string
    );
    return categorisedBusinessTypeArray;
  };

  const handleRefClick = (e, index) => {
    e.preventDefault();
    const selectedBusinessType = masterData.BusinessTypes.find(
      (item) =>
        item.BusinessTypeValue.toLowerCase() ===
        cardRefs.current[index].value.toLowerCase()
    );
    if (selectedBusinessType.BusinessTypeValue === "Guest") {
      return toast({
        title: "Guest User not available yet.",
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    } else if (selectedBusinessType.BusinessTypeValue === "Operator Partner") {
      dispatch(setSelectedBusinessType(selectedBusinessType));
      dispatch(
        setSelectedUserType({
          UserTypeId: 1,
          UserTypeValue: "Individual",
          UserTypeDescription: "I am a small company. (Quick to register)",
        })
      );
    } else {
      dispatch(setSelectedBusinessType(selectedBusinessType));
      dispatch(setSelectedUserType(null));
    }
    e.stopPropagation();
  };

  const handleClick = (e) => {
    let value = e.target.value;
    if (!value) {
      value = e.target.parentElement.value;
    }
    const selectedBusinessType = masterData.BusinessTypes.find(
      (item) => item.BusinessTypeId === parseInt(value)
    );
    if (selectedBusinessType.BusinessTypeValue === "Guest") {
      return toast({
        title: "Guest User not available yet.",
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    } else if (selectedBusinessType.BusinessTypeValue === "Operator Partner") {
      dispatch(setSelectedBusinessType(selectedBusinessType));
      dispatch(
        setSelectedUserType({
          UserTypeId: 1,
          UserTypeValue: "Individual",
          UserTypeDescription: "I am a small company. (Quick to register)",
        })
      );
    } else {
      dispatch(setSelectedBusinessType(selectedBusinessType));
      dispatch(setSelectedUserType(null));
    }
  };

  useEffect(() => {
    dispatch(getBusinessTypesThunk());
  }, []);

  useEffect(() => {
    setPartnerOptions(
      categoriseBusinessTypes(
        masterData.BusinessTypes,
        "BusinessTypeValue",
        "Partner"
      )
    );
    setOwnerOptions(
      categoriseBusinessTypes(
        masterData.BusinessTypes,
        "BusinessTypeValue",
        "Owner"
      )
    );
    setOtherOptions(filterOtherBusinessTypes(masterData.BusinessTypes));
  }, [masterData]);
  return (
    <>
    <Helmet>
    <link
        rel="Registration page"
        href="https://www.equip9.com/register/select-business-type"
      />
      <meta name="keywords" content="heavy equipment machine"/>
      <title>Grow Your Heavy Equipment Business Faster with EQUIP9</title>
      <meta name="description" content="Equip9: Secure login for business and equipment owners. 
      Streamline your heavy equipment management effortlessly."/>
      <link rel="sitemap" type="application/xml" title="Sitemap" 
      href={regsitemap} />
      <h1>Log in to Equip9 for Heavy Equipment Solutions</h1>
      <h2>Your Gateway to Efficient Heavy Equipment Management</h2>
      <h3>Secure Login for Equip9 Users</h3>
            
    </Helmet>

    <V5MultiAdLayout
      heading={defaultLanguage?.I_am}
      subHeading={defaultLanguage?.You_can_select_any_one_of_the_following}
    >
      {/*<Box>
         <Box maxH="250px" overflowY="scroll">
          {ownerOptions ? (
            <ContainerWithSeperator heading="Owner">
              <Flex justifyContent="space-between" wrap="wrap">
                {ownerOptions.map((option) => (
                  <SelectCardWrapper
                    width="374px"
                    height="80px"
                    value={option.BusinessTypeId}
                    icon={imageSelector(option.BusinessTypeValue)}
                    isSelected={
                      registrationData.selectedBusinessType
                        ?.BusinessTypeValue === option.BusinessTypeValue
                        ? true
                        : false
                    }
                    onClick={handleClick}
                    display="flex"
                    flexdirection="column"
                  >
                    <Heading
                      width="fit-content"
                      fontSize="xl"
                      fontWeight="semibold"
                    >
                      {subString(25, option.BusinessTypeValue)}
                    </Heading>
                    <Text width="fit-content" fontSize="xs">
                      {subString(30, option.BusinessTypeDescription)}
                    </Text>
                  </SelectCardWrapper>
                ))}
              </Flex>
            </ContainerWithSeperator>
          ) : (
            <></>
          )}

          {partnerOptions ? (
            <ContainerWithSeperator heading="Partner">
              <Flex justifyContent="space-between" wrap="wrap">
                {partnerOptions.map((option) => (
                  <SelectCardWrapper
                    width="374px"
                    height="80px"
                    value={option.BusinessTypeId}
                    icon={imageSelector(option.BusinessTypeValue)}
                    isSelected={
                      registrationData.selectedBusinessType
                        ?.BusinessTypeValue === option.BusinessTypeValue
                        ? true
                        : false
                    }
                    onClick={handleClick}
                    display="flex"
                    flexdirection="column"
                  >
                    <Heading
                      width="fit-content"
                      fontSize="xl"
                      fontWeight="semibold"
                    >
                      {subString(25, option.BusinessTypeValue)}
                    </Heading>
                    <Text width="fit-content" fontSize="xs">
                      {subString(30, option.BusinessTypeDescription)}
                    </Text>
                  </SelectCardWrapper>
                ))}
              </Flex>
            </ContainerWithSeperator>
          ) : (
            <></>
          )}

          {otherOptions ? (
            <ContainerWithSeperator heading="Others">
              <Flex
                justifyContent={otherOptions.length > 0 ? "space-between" : ""}
                wrap="wrap"
              >
                {otherOptions.map((option) => (
                  <SelectCardWrapper
                    width="374px"
                    height="80px"
                    value={option.BusinessTypeId}
                    icon={imageSelector(option.BusinessTypeValue)}
                    isSelected={
                      registrationData.selectedBusinessType
                        ?.BusinessTypeValue === option.BusinessTypeValue
                        ? true
                        : false
                    }
                    onClick={handleClick}
                    display="flex"
                    flexdirection="column"
                  >
                    <Heading
                      width="fit-content"
                      fontSize="xl"
                      fontWeight="semibold"
                    >
                      {subString(25, option.BusinessTypeValue)}
                    </Heading>
                    <Text width="fit-content" fontSize="xs">
                      {subString(30, option.BusinessTypeDescription)}
                    </Text>
                  </SelectCardWrapper>
                ))}
              </Flex>
            </ContainerWithSeperator>
          ) : (
            <></>
          )}
        </Box>
      </Box> */}

      <V5Scroll maxHeight="350px" overflowX="unset">
        <Flex
          wrap="wrap"
          width="100%"
          padding="0.25em"
          justifyContent="space-evenly"
          alignItems="flex-end"
          rowGap="1em"
        >
          {masterData.BusinessTypes && masterData.BusinessTypes.length > 0 ? (
            masterData.BusinessTypes.map(
              ({ BusinessTypeValue, BusinessTypeDescription }, index) =>
                BusinessTypeValue.toString().toLowerCase() !==
                "Guest".toLowerCase() ? (
                  <V5Card
                    boxShadow="0px 0px 8px 0px #00000011"
                    value={BusinessTypeValue}
                    ref={(ref) => (cardRefs.current[index] = ref)}
                    isSelected={
                      BusinessTypeValue ===
                      registrationData.selectedBusinessType?.BusinessTypeValue
                    }
                  >
                    <Flex
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="space-around"
                      maxHeight={[
                        "193.89px",
                        "171.69px",
                        "171.69px",
                        "211pxpx",
                        "211px",
                      ]}
                      height={"100%"}
                      minHeight={[
                        "193.89px",
                        "171.69px",
                        "171.69px",
                        "211pxpx",
                        "211px",
                      ]}
                      maxWidth={[
                        "173.68px",
                        "153.75px",
                        "154.6px",
                        "190px",
                        "191px",
                      ]}
                      width="95%"
                      minWidth={[
                        "173.68px",
                        "153.75px",
                        "154.6px",
                        "190px",
                        "191px",
                      ]}
                      padding="0.5em 0.75em"
                      onClick={(e) => handleRefClick(e, index)}
                    >
                      {imageSelector(
                        BusinessTypeValue,
                        (e) => {
                          handleRefClick(e, index);
                        },
                        { height: "48px", width: "48px" }
                      )}
                      <Heading
                        width="fit-content"
                        fontSize="xl"
                        fontWeight="semibold"
                        onClick={(e) => handleRefClick(e, index)}
                      >
                        {subString(25, BusinessTypeValue)}
                      </Heading>

                      <Tooltip
                        label={BusinessTypeDescription}
                        fontSize="medium"
                      >
                        <Text
                          width="fit-content"
                          fontSize="xs"
                          onClick={(e) => handleRefClick(e, index)}
                        >
                          {subString(30, BusinessTypeDescription)}
                        </Text>
                      </Tooltip>
                    </Flex>
                  </V5Card>
                ) : (
                  <></>
                )
            )
          ) : (
            <></>
          )}

          <Flex
            width="90%"
            height="88px"
            borderRadius="10px"
            border="1px solid transparent"
            background="#fff"
            boxShadow="0px 0px 6px 0px #00000011;"
            alignItems="center"
            justifyContent="center"
            gap="3em"
            marginTop="1em"
            onClick={()=>dispatch(openQrModal())}
            cursor="pointer"
          >
            <QrPopUp />
            <Box maxH="54px" maxW="54px">
              <Image src={GuestLogo} height="100%" width="100%" />
            </Box>

            <Box>
              <Text color="#2C2C2C" fontWeight="700" fontSize="16px">
                As a Guest
              </Text>
              <Text fontSize="14px">I am not sure</Text>
            </Box>
          </Flex>
        </Flex>
      </V5Scroll>

      <Box>
        <Flex
          justifyContent="center"
          alignItems="center"
          bgColor="#fff"
          h="78px"
          style={{ boxShadow: "0px -10px 15px #2c2c2c0f" }}
        >
          <Button
            variant="gradient"
            fontWeight="semibold"
            width="80%"
            margin="0 auto"
            maxW="347px"
            onClick={() => {
              registrationData.selectedBusinessType.BusinessTypeValue ===
              "Operator Partner"
                ? navigate(
                    assetIdParam
                      ? `/register/generate-Otp?${NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID}=${assetIdParam}`
                      : "/register/generate-Otp"
                  )
                : navigate(
                    assetIdParam
                      ? `/register/select-user-type?${NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID}=${assetIdParam}`
                      : "/register/select-user-type"
                  );
            }}
            isDisabled={!registrationData.selectedBusinessType ? true : false}
          >
            {defaultLanguage?.Continue}
          </Button>
        </Flex>
        {/* <Box bg="#4A4A4A" w="100%" h="29px"></Box> */}
      </Box>
    </V5MultiAdLayout>
    </>
  );
};
