import { Box, Flex, Text } from "@chakra-ui/react";
import "./Membercss.css";

function Members({
  imageArray = [
    "Website/About/Members/SeniorConsultant/Sakshi-f.png",
    "Website/About/Members/SeniorConsultant/Ankita-f.png",
    "Website/About/Members/Consultant/Falguni-f.png",
    "Website/About/Members/SeniorConsultant/Samruddhi-f.png",
    "Website/About/Members/SeniorConsultant/Pravin-m.png",

    "Website/About/Members/Intern/Kritika-f.png",
    "Website/About/Members/Associate/Komal-f.png",
    "Website/About/Members/Associate/Nisarga-f.png",
    "Website/About/Members/Associate/Srushti-f.png",
    "Website/About/Members/Associate/Nisha-f.png",
    "Website/About/Members/Associate/Priyanka-f.png",
    "Website/About/Members/Associate/Rashmi-f.png",
    "Website/About/Members/Associate/Sanket-m.png",
    "Website/About/Members/Associate/Pratik-m.png",
    "Website/About/Members/Associate/Rohit-m.png",
    "Website/About/Members/Associate/Gaurav-m.png",

    "Website/About/Members/Consultant/Dhruv-m.png",
    "Website/About/Members/Consultant/Dikshant-m.png",
    "Website/About/Members/Consultant/Ajit-m.png",
    "Website/About/Members/Consultant/Ayush-m.png",
    "Website/About/Members/Founder/Gaurav-m.png",
    "Website/About/Members/Founder/Kishan-m.png",
  ],
}) {

  return (
    <div
      style={{
        background: "var(--dark-40)",
      }}
    >
      <Flex padding={"0.8em"} direction={"column"}>
      <Flex color={"#F79C00"} paddingTop={"2em"} textAlign={"center"} fontWeight={"700"} >
        <Text fontSize={["1.8em","1.8em","1.8em","2.8em","2.8em","2.8em"]} w={"100%"}>Meet our Team Members</Text>
      </Flex>
      <Text
        fontSize={["0.8em","0.8em","0.8em","1em","1em","1em"]}
       className="tagline">
        A team of passionate individuals making an extraordinary effort to shape
        a 129 billion-dollar global industry.
      </Text>
      </Flex>
      <div className="mem">
        {imageArray && imageArray?.length > 0 ? (
          imageArray?.map((imageLink, index) => {
            return (
              <Box maxH="160px" maxW="160px">
              <img
                key={`teamMember-${index}`}
                className="tem"
                src={`${process.env.REACT_APP_CDN_STATIC_URL}${imageLink}`}
                alt="team member"
              />
              </Box>

            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Members;
