import { Box, Text } from '@chakra-ui/react'
import React from 'react'

const CommonHeader = ({header,subHeader}) => {
  return (
    <Box>
    <Text color="#1A1E21" fontWeight="700" fontSize="24px">
      {header}
    </Text>
    <Text color="#767676" fontSize="20px">
      {subHeader}
    </Text>
  </Box>
  )
}

export default CommonHeader