import React, { useState } from "react";

export const withPopup = (WrappedComponent) => {
  return (props) => {
    const [isOpen, setIsOpen] = useState(true);

    const handleClose = () => {
      setIsOpen(false);
    };

    if (!isOpen) return null;

    return (
      <>
        <div
          onClick={handleClose}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
            borderRadius:"9px"
          }}
        ></div>
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            width: "39%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
            backgroundColor: "white",
            padding: "20px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.5)",
            borderRadius:"9px"

          }}
        >
          <WrappedComponent {...props} />
        </div>
      </>
    );
  };
};
