import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_CONSTANTS } from "../../../../constants/apiConstants";
import { getAllE9DealershipMarketPlaceList } from "../../../services/dealershipMarketplace/dealershipMarketplace";

export const getDealershipMarketplaceInitialDataThunk = createAsyncThunk("dealerships/initialData", async(_,{getState}) =>{
    try {
        const cardCount = getState().dealershipMarketplace.cardCount;
        const pageNumber = getState().dealershipMarketplace.paginationCount;
      
    
        const data = await getAllE9DealershipMarketPlaceList(cardCount,(pageNumber * cardCount));

      
        if(data.Status === 200 && Object.keys(data.Data).length > 0){
            return data.Data.E9DsMarketplaceList ;
        }
    } catch (error) {
    }
})  