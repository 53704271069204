import { createAsyncThunk } from "@reduxjs/toolkit";
import { postEquipmentPurchasingDetails } from "../../../services/equipments/equipments";

export const createPurchasingDetailsThunk = createAsyncThunk("/equipment-listing/purchasing-details", async(body, {getState, rejectWithValue, fulfillWithValue}) => {
    const {createdAssetId} = getState().addEquipment

   
    try{
        const data = await postEquipmentPurchasingDetails({...body, AssetId : createdAssetId})
        fulfillWithValue(data)
        return data;
    }
    catch(error){
        rejectWithValue(error.message)
    throw error
    }
})