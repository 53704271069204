import { useNavigate, useParams } from "react-router-dom";
import { V5SidebarLayout } from "../../../layout/V5SidebarLayout/V5SidebarLayout";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FormikInput } from "../../../components/Input/FormikInput";
import {
  DeleteCommunityVideo,
  GetThumbnailImages,
  PostCommunityDetails,
  PostCommunityVideo,
  UpdateCommunityDetails,
} from "../../../services/community/community";
import ThumbnailUpload from "./ThumbnailUpload";
import VideoUpload from "./VideoUpload";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { API_CONSTANTS } from "../../../../constants/apiConstants";
import { setIsSubmitting } from "../slice/addCommunitySlice";

export const CommunityForm = () => {
  const { action, id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();
  const { ContentType, audienceTypes, isSubmitting } = useSelector(
    (state) => state.postCommunity
  );
  const { myContentData } = useSelector((state) => state.community);
  const [postDetails, setPostDetails] = useState(null);
  const [selectedThumbnail, setSelectedThumbnail] = useState("");
  const [defaultImage, setDefaultImage] = useState(null);
  const [defaultVideo, setDefaultVideo] = useState(null);

  const audienceNames = audienceTypes
    ?.map((audience) => audience.audienceName)
    .join(",");
  const audienceIds = audienceTypes
    ?.map((audience) => audience.audienceId)
    .join(",");

  useEffect(() => {
    if (id) {
      const foundPost = myContentData?.find(
        (item) => item.CommunityId === Number(id)
      );
      setPostDetails(foundPost);
      if (foundPost?.CommunityVideoFileRef) {
        setDefaultVideo(
          `${process.env.REACT_APP_CDN_PVT_URL}${foundPost?.CommunityVideoFileRef}`
        );
      }
    }
    (async () => {
      try {
        const data = await GetThumbnailImages();
        setSelectedThumbnail(data?.Data?.ThumbnailDetails);
      } catch (error) {}
    })();
  }, [myContentData, id]);

  useEffect(() => {
    if (
      selectedThumbnail &&
      postDetails?.Thumbnail !== "null" &&
      postDetails?.Thumbnail
    ) {
      let defaultSelectedThumbnailObj;

      if (
        postDetails?.Thumbnail &&
        postDetails.Thumbnail.toLowerCase().includes("thumb")
      ) {
        defaultSelectedThumbnailObj = selectedThumbnail.find(
          (thumbnail) => thumbnail.ThumbnailImageName === postDetails?.Thumbnail
        );
      } else {
        defaultSelectedThumbnailObj = {
          ThumbnailImageName: postDetails?.Thumbnail,
          Url: `${process.env.REACT_APP_CDN_STATIC_URL}${API_CONSTANTS.CommunityImages}${postDetails?.Thumbnail}`,
        };
      }
      setDefaultImage(defaultSelectedThumbnailObj);
    }
  }, [selectedThumbnail, postDetails]);

  useEffect(() => {
    if (!ContentType) {
      navigate("/community");
    }
  }, [ContentType, navigate]);

  const initialValues = {
    title: postDetails?.ContentTitle || "",
    thumbnail: postDetails?.Thumbnail || "",
    ContentLink: postDetails?.ContentLink || "",
    CommunityVideoFileRef: postDetails?.CommunityVideoFileRef || "",
    BusinessTypeValues: audienceNames
      ? audienceNames
      : postDetails?.BusinessTypeValues || "",
    IsE9Learning: postDetails?.IsE9Learning || 0,
  };

  const urlRegex =
    /^(https?:\/\/)?(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(:\d+)?(\/[-a-zA-Z0-9@:%._\+~#=]{1,256})*(\?.*)?(#.*)?$/;

  const validationSchema = Yup.object().shape({
    ContentLink: Yup.string().matches(urlRegex, "Must be a valid URL"),
  });

  const postCommunityDetails = async (payload) => {
    const response = await PostCommunityDetails(payload);
    if (response.Status !== 201)
      throw new Error(
        response.Message?.ErrorMessage || "Failed to post community details"
      );
    return response;
  };

  const postCommunityVideo = async (payload) => {
    const response = await PostCommunityVideo(payload);
    if (response.Status !== 201)
      throw new Error(
        response.Message?.ErrorMessage || "Failed to post community video"
      );
    return response;
  };

  const updateCommunityDetails = async (payload) => {
    const response = await UpdateCommunityDetails(payload);
    if (response.Status !== 204)
      throw new Error(
        response.Message?.ErrorMessage || "Failed to update community details"
      );
    return response;
  };

  const deleteCommunityVideo = async (communityId) => {
    const response = await DeleteCommunityVideo(communityId);
    if (response.Status !== 204)
      throw new Error(
        response.Message?.ErrorMessage || "Failed to delete community video"
      );
    return response;
  };

  const displayToast = (message, status) => {
    toast({
      title: "",
      description: message,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const onSubmit = async (values) => {
    if (values.ContentLink && values.CommunityVideoFileRef) {
      displayToast("You can either upload a video or provide a link", "info");
      return;
    }
    let thumbnailImageName = "";
    let communityImages = "";

    if (typeof values.thumbnail === "string") {
      const urlParts = values.thumbnail.split("/");
      thumbnailImageName = urlParts[urlParts.length - 1];
    } else if (values.thumbnail instanceof File) {
      communityImages = values.thumbnail;
    }
    const payload = {
      ContentTypeId: ContentType?.ContentId,
      ContentTitle: values?.title,
      ContentLink: values?.ContentLink,
      BusinessTypeId: audienceIds,
      ThumbnailImage: thumbnailImageName,
      CommunityImages: communityImages,
      IsE9Learning: values?.IsE9Learning,
      IsPromoted: 0,
    };

    try {
      dispatch(setIsSubmitting(true));
      let response;
      if (id) {
        const updatePayload = {
          CommunityId: id,
          ContentTitle: values?.title,
          ContentLink: values?.ContentLink,
          BusinessTypeId: audienceIds,
          ThumbnailImage: thumbnailImageName,
          CommunityImages: communityImages,
          IsE9Learning: values?.IsE9Learning,
          IsPromoted: 0,
        };

        response = await updateCommunityDetails(updatePayload);
        displayToast(response?.Message?.SuccessMessage, "success");

        if (
          postDetails?.CommunityVideoFileRef !== values.CommunityVideoFileRef
        ) {
          await deleteCommunityVideo(id);
        }

        if (values.CommunityVideoFileRef) {
          const videoPayload = {
            CommunityId: id,
            CommunityVideos: values?.CommunityVideoFileRef,
          };
          const videoResponse = await postCommunityVideo(videoPayload);
          displayToast(videoResponse?.Message?.SuccessMessage, "success");
        }
      } else {
        response = await postCommunityDetails(payload);
        displayToast(response?.Message?.SuccessMessage, "success");

        if (values.CommunityVideoFileRef) {
          const videoPayload = {
            CommunityId: response?.Data?.CommunityId,
            CommunityVideos: values?.CommunityVideoFileRef,
          };
          const videoResponse = await postCommunityVideo(videoPayload);
          displayToast(videoResponse?.Message?.SuccessMessage, "success");
        }
      }

      navigate("/community");
    } catch (error) {
      console.error("Submission failed", error);
    } finally {
      dispatch(setIsSubmitting(false));
    }
  };
  const getBusinessTypesArray = (businessTypeValues) => {
    return businessTypeValues.split(",").map((type) => type.trim());
  };

  return (
    <>
      <V5SidebarLayout>
        <Flex
          gap={"10px"}
          direction={"column"}
          margin={"0 52px"}
          maxH={"76vh"}
          overflowY={"scroll"}
        >
          {/* header section */}
          <Flex direction={"column"}>
            <Text fontSize={"24px"} fontWeight={"700"} color={"#1A1E21"}>
              {action === "edit-post" ? "Edit" : "Add"} Your{" "}
              {ContentType?.ContentValue}
            </Text>
            <Text fontSize={"20px"} fontWeight={"400"} color={"#767676"}>
              You can {action === "edit-post" ? "Edit" : "Add"} your own{" "}
              {ContentType?.ContentValue} here
            </Text>
          </Flex>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ values, setFieldValue, errors, touched, handleSubmit }) => (
              <Form id="community-post">
                <Flex direction={"column"} gap={"1em"} >
                  <Box>
                    <FormLabel
                      fontSize={"18px"}
                      fontWeight={"600"}
                      color={"#1A1E21"}
                    >
                      Selected Audience
                    </FormLabel>

                    <Flex margin={"0.5em 0"} gap={"1em"}>
                      {getBusinessTypesArray(values.BusinessTypeValues).map(
                        (type, index) => (
                          <Box key={index}>
                            <Badge
                              variant="solid"
                              backgroundColor={"#FEF8EC"}
                              color={"#F79C00"}
                              padding={"4px 8px"}
                              border={"1px solid #F79C00"}
                              borderRadius={"4px"}
                            >
                              {type}
                            </Badge>
                          </Box>
                        )
                      )}
                    </Flex>
                  </Box>

                  <FormikInput
                    label={
                      <Text
                        fontSize={"18px"}
                        fontWeight={"600"}
                        color={"#1A1E21"}
                        display={"inline-block"}
                      >
                        Add A Title For Your {ContentType?.ContentValue}
                      </Text>
                    }
                    name="title"
                    value={values.title}
                    placeholder="Enter Title Here (Up to 50 Character)"
                    maxWidth="450px"
                    maxLength={50}
                    h={"46px"}
                    isRequired={true}
                  />

                  <Box>
                    <FormLabel
                      fontSize={"18px"}
                      fontWeight={"600"}
                      color={"#1A1E21"}
                    >
                      Upload Thumbnail{" "}
                      <Text color={"red"} display={"inline-block"}>
                        *
                      </Text>
                    </FormLabel>
                    <Text
                      fontSize={"14px"}
                      fontWeight={"400"}
                      color={"#767676"}
                      marginBottom={"0.5em"}
                    >
                      Select or upload your thumbnail{" "}
                    </Text>

                    <ThumbnailUpload
                      name="thumbnail"
                      thumbnails={selectedThumbnail}
                      formikProps={{
                        setFieldValue,
                        errors,
                        touched,
                      }}
                      defaultImageUrl={defaultImage}
                    />
                  </Box>

                  {ContentType?.ContentValue === "Video" && (
                    <Box>
                      <FormControl mb={4}>
                        <Field name="IsE9Learning">
                          {({ field, form }) => (
                            <Checkbox
                              {...field}
                              id="IsE9Learning"
                              colorScheme="green"
                              fontSize={"18px"}
                              fontWeight={"600"}
                              color={"#1A1E21"}
                              isChecked={field.value === 1}
                              onChange={(e) => {
                                form.setFieldValue(
                                  field.name,
                                  e.target.checked ? 1 : 0
                                );
                              }}
                            >
                              Is It a Learning Video?
                            </Checkbox>
                          )}
                        </Field>
                        {errors.agreeTerms && touched.agreeTerms ? (
                          <Text color="red">{errors.agreeTerms}</Text>
                        ) : null}
                      </FormControl>
                    </Box>
                  )}

                  <Flex gap={"5px"} alignItems={"center"}>
                    <InfoOutlineIcon color={"#002961"} />{" "}
                    <Text
                      color={"#002961"}
                      fontSize={"16px"}
                      fontWeight={"600"}
                    >
                      You can either upload a video or provide a link
                    </Text>
                  </Flex>

                  <Flex mb={"1em"}>
                    <Flex width={"590px"} justifyContent={"space-between"}>
                      <FormikInput
                        label={
                          <Text
                            fontSize={"18px"}
                            fontWeight={"600"}
                            color={"#1A1E21"}
                            display={"inline-block"}
                          >
                            Add The Link To Your Video
                          </Text>
                        }
                        name="ContentLink"
                        value={values.ContentLink}
                        placeholder="Enter Video Link"
                        maxWidth="450px"
                        maxLength={100}
                        h={"46px"}
                        isRequired={!values.CommunityVideoFileRef}
                      />
                      <Flex
                        direction={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Box
                          border={"2px solid #C6C7C8"}
                          w={"2px"}
                          h={"100px"}
                        />
                        <Text>OR</Text>
                        <Box
                          border={"2px solid #C6C7C8"}
                          w={"2px"}
                          h={"100px"}
                        />
                      </Flex>
                    </Flex>

                    <Flex width={"590px"} justifyContent={"center"}>
                      <Box >
                        <VideoUpload
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                          defaultVideo={defaultVideo}
                        />
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              </Form>
            )}
          </Formik>
        </Flex>

        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"5em"}
          boxShadow={"0px -10px 15px 0px #2C2C2C28"}
          padding={"1em"}
        >
          <Button
            outline="none"
            boxShadow="none"
            background="#fff"
            fontWeight={"700"}
            color="#2c2c2c90"
            onClick={() => {
              navigate("/community");
            }}
          >
            No Thanks
          </Button>
          <Button
            type="submit"
            form={"community-post"}
            variant="gradient"
            isLoading={isSubmitting}
          >
            Submit
          </Button>
        </Flex>
      </V5SidebarLayout>
    </>
  );
};
