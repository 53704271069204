import React, { useState } from "react";
import { SideMenuLayout } from "../../../../../../../../layout/sideMenuLayout/SideMenuLayout";
import { DashboardNavBar } from "../../../../../../../../components/DashboardNavBar/DashboardNavBar";
import { MidSection } from "../../../../../../../../../sharedComponent/midSection/MidSection";
import { LicenseDetailPopCard } from "./LicenseDetailPop/LicenseDetailPopCard";
import { GetLicenseDetails } from "./LicenseDetailCard/LicenseDetailCard";
import { AdditionalDetails } from "./AdditionalDetail/AdditionalDetail";
import { ProfileVerification } from "../../../../../../../../components/ProfileVerication/ProfileVerification";
import { ShowAdditionalDetails } from "./AdditionalDetail/ShowAdditionalDetails/ShowAdditionalDetails";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
// import './LicenseDetail.css';
import "./LicenseDetail.css";
import { useSelector } from "react-redux";

export const LicenseDetails = () => {
  const customStyle = {
    color: "#F79C00", // Specify your desired hex color code
    // You can add more custom styling properties here if needed
  };
  const userData = useSelector((state) => state.user);

  const verification =
    userData?.accountDetails?.UserDetails?.E9VerificationMessage;
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [isAdditonalOpen, setisAdditionalOpen] = useState(false);

  const openDetailPopup = () => {
    setisAdditionalOpen(true);
  };

  const closeDetailPopup = () => {
    setisAdditionalOpen(false);
  };

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const triggerRefresh = () => {
    setRefreshKey((prev) => prev + 1);
  };

  return (
    <>
      <SideMenuLayout>
        <DashboardNavBar />
        <MidSection>
          <ProfileVerification />
          <div className="verification-details">
            <p className="your-verification-details">Your License Details</p>
            <hr className="seprator-liceseDetail"></hr>
            <p className="additional-detail-button" onClick={openPopup}>
              <p className="add-verification-detail-text">
                + Add License Details
              </p>
            </p>
            <p className="additional-detail-button" onClick={openDetailPopup}>
              <p className="add-verification-detail-text">
                + Additional Details
              </p>
            </p>
          </div>
          <div className="add-detail"></div>

          <LicenseDetailPopCard
            isOpen={isPopupOpen}
            onClose={closePopup}
            triggerRefresh={triggerRefresh}
          />

          {isAdditonalOpen && <AdditionalDetails onClose={closeDetailPopup} />}
          <div className="tab-switch">
            <Tabs colorScheme="orange">
              <TabList>
                <Tab className="tab-text">License Details</Tab>
                <Tab className="tab-text">Additional Details</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <GetLicenseDetails triggerRefresh={triggerRefresh} />
                </TabPanel>
                <TabPanel>
                  <ShowAdditionalDetails />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        </MidSection>
      </SideMenuLayout>
    </>
  );
};
