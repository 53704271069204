import {
  Flex,
  Box,
  Button,
  Heading,
  Text,
  Image,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUserTypesThunk } from "../../../global/mtData/thunk/getUserTypesThunk";
import { SelectCardWrapper } from "../../../../components/SelectCardWrapper/SelectCardWrapper";
import { imageSelector } from "../../../../helpers/categoryImageHandler/categoryImageHandler";
import { setSelectedUserType } from "../../slice/registrationSlice";
import { subString } from "../../../../helpers/dataConversion/substring";
import { setSelectedCountryThunk } from "../../../global/appConfig/thunk/setSelectedCountryThunk";
import { getAllLanguageJsonThunk } from "../../../global/mtData/thunk/getAllLanguagesThunk";
import { V5MultiAdLayout } from "../../../../layout/multiAdLayout/V5MultiAdLayout";
import { NAVIGATION_CONSTANTS } from "../../../../../constants/naigationContants";
import { V5Scroll } from "../../../../components/V5Scroll/V5Scroll";
import { V5Card } from "../../../../components/VrCard/V5Card";import { Helmet } from "react-helmet";
import Bsitemap from "./seo/Businessowner.xml";
import Esitemap from "./seo/Equipmentowner.xml";


export const SelectUserType = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const masterData = useSelector((state) => state.masterData);
  const registrationData = useSelector((state) => state.registration);
  const { defaultLanguage } = useSelector((state) => state.masterData);
  const [searchParams, setSearchParams] = useSearchParams();
  const assetIdParam = searchParams.get(
    NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID
  );
  const cardRefs = useRef([]);
  const toast = useToast();
  useEffect(() => {
    if (!defaultLanguage) {
      dispatch(setSelectedCountryThunk("91"));
      dispatch(getAllLanguageJsonThunk("english.json"));
    }
  }, [!defaultLanguage]);

  const handleClick = (e) => {
    if (!e.target.value) return;
    const selectedUserType = masterData.UserTypes.find(
      (item) => item.UserTypeId === parseInt(e.target.value)
    );
    dispatch(setSelectedUserType(selectedUserType));
  };
  const handleChildClick = (e) => {
    const selectedUserType = masterData.UserTypes.find(
      (item) => item.UserTypeId === parseInt(e.target.parentElement.value)
    );
    dispatch(setSelectedUserType(selectedUserType));
  };
  useEffect(() => {
    dispatch(getUserTypesThunk());
  }, [dispatch]);

  const handleRefClick = (e, index) => {
    e.preventDefault();
    const selectedUserType = masterData.UserTypes.find(
      (item) => item.UserTypeId === parseInt(cardRefs.current[index].value)
    );

    dispatch(setSelectedUserType(selectedUserType));
    e.stopPropagation();
  };

  return (
    <>
    
    <Helmet>
    <link
        rel="Equipment owner Registration"
        href="https://www.equip9.com/register/select-user-type"
      />
      <meta name="keywords" content="heavy machinery"/>
      <title>Equip9 Business Login: Manage Heavy Machinery Effectively</title>
      <meta name="description" content="Equip9 Business Login: Effortlessly manage heavy machinery. Secure access for business owners. 
      Simplify your equipment operations with Equip9."/>
      <link rel="sitemap" type="application/xml" title="Sitemap" 
      href={Bsitemap} />
      <h1>Business Owner Login: Access Equip9 for Heavy Machinery Solutions</h1>
      <h2>Streamlined Access for Business Owners in Heavy Machinery Management</h2>
      <h3>Secure Login for Equip9 Business Users</h3>
            
    </Helmet>
    <Helmet>
      <link
        rel="Equipment owner Registration"
        href="https://www.equip9.com/register/select-user-type"
      />
      <meta name="keywords" content="construction equipment operator"/>
      <title>Equip9 Operator Match: Register as Equipment Owner</title>
      <meta name="description" content="Equip9's registration: Find skilled construction equipment operators 
      and mechanics for your heavy equipment. Connect effortlessly.."/>
      <link rel="sitemap" type="application/xml" title="Sitemap" 
      href={Esitemap} />
      <h1>Connect with Skilled Construction Equipment Operators - Equip9</h1>
      <h2>Register as an Equipment Owner: Find Your Operator</h2>
      <h3>Equip9: Bridging Equipment Owners with Expert Construction Equipment Operators</h3>
            
    </Helmet>
    
    <V5MultiAdLayout
      heading={defaultLanguage?.Nature_of_Your_Business}
      subHeading={defaultLanguage?.You_can_select_any_one_of_the_following}
    >
      <V5Scroll maxHeight="350px" overflowX="unset">
        <Flex
          wrap="wrap"
          width="100%"
          padding="1.25em"
          justifyContent="space-evenly"
          alignItems="flex-end"
          rowGap="1em"
        >
          {masterData.UserTypes ? (
            masterData.UserTypes.map((option, index) =>
              option.UserTypeValue === "Anonymous" ? (
                <></>
              ) : (
                <V5Card
                  boxShadow="0px 0px 8px 0px #00000011"
                  value={option.UserTypeId}
                  onClick={handleClick}
                  ref={(ref) => (cardRefs.current[index] = ref)}
                  isSelected={
                    parseInt(option.UserTypeId) ===
                    parseInt(registrationData?.selectedUserType?.UserTypeId)
                  }

                >
                  <Flex
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-around"
                    maxHeight={[
                      "193.89px",
                      "171.69px",
                      "171.69px",
                      "211pxpx",
                      "211px",
                    ]}
                    height={"100%"}
                    minHeight={[
                      "193.89px",
                      "171.69px",
                      "171.69px",
                      "211pxpx",
                      "211px",
                    ]}
                    maxWidth={[
                      "173.68px",
                      "153.75px",
                      "154.6px",
                      "190px",
                      "191px",
                    ]}
                    width="95%"
                    minWidth={[
                      "173.68px",
                      "153.75px",
                      "154.6px",
                      "190px",
                      "191px",
                    ]}
                    padding="0.5em 0.75em"
                    onClick={(e) => handleRefClick(e, index)}
                  >
                   
                    {imageSelector(
                      option?.UserTypeValue,
                      (e) => {
                        handleRefClick(e, index);
                      },
                      { height: "48px", width: "48px" }
                    )}
                    <Heading
                      fontSize="xl"
                      fontWeight="semibold"
                      onClick={handleChildClick}
                    >
                      {subString(25, option.UserTypeValue)}
                    </Heading>
                    <Tooltip label={option?.UserTypeDescription}>
                    <Text fontSize="xs" onClick={handleChildClick}>
                      {subString(30, option.UserTypeDescription)}
                    </Text>
                    </Tooltip>
                  </Flex>
                </V5Card>
              )
            )
          ) : (
            <></>
          )}
        </Flex>
      </V5Scroll>

      <Box>
        <Flex
          justifyContent="center"
          alignItems="center"
          bgColor="#fff"
          h="78px"
          style={{ boxShadow: "0px -10px 15px #2c2c2c0f" }}
        >
          <Button
            variant="gradient"
            fontWeight="semibold"
            width="80%"
            margin="0 auto"
            maxW="347px"
            isDisabled={registrationData.selectedUserType ? false : true}
            onClick={() => {
              navigate(
                assetIdParam
                  ? `/register/generate-otp?${NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID}=${assetIdParam}`
                  : "/register/generate-otp"
              );
            }}
          >
            {defaultLanguage?.Continue}
          </Button>
        </Flex>
      </Box>
    </V5MultiAdLayout>
    </>
  );
};
