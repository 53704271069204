import "./UserEquipmentPage.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { EquipmentRentalLayout } from "../../../layout/equipmentRentalLayout/EquipmentRentalLayout";
import { ReactSVG } from "react-svg";
import {
  Flex,
  Box,
  Button,
  Divider,
  Text,
  Modal,
  ModalContent,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  Heading,
  useToast,
} from "@chakra-ui/react";
import { DetailsCard } from "../../../components/DetailsCard/DetailsCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "../../../components/AssetDetailsPage/AssetDetailsPage.css";
import { QrPopUp } from "../../../components/ScanQrCodeModal/QrPopUp";
import { openQrModal } from "../../dashboard/slice/dashboardSlice";
import defaultImage from "../../../../assets/webapp/marketplace/defaultAssetImage.png";
import { primaryKeyDecryption } from "../../../helpers/dataConversion/primaryKeyEncryption";
import {
  deleteAllEquipmentDetails,
  getAssetMarketplaceListedDetails,
  getUserAssetById,
} from "../../../services/userAssets/userAssets";
import editPopUpIcon from "../../../../assets/webapp/pop-up/edit-popup.svg";
import { SinglePurposePopup } from "../../../components/SinglePurposePopup/SinglePurposePopUp";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";

export const UserEquipmentPage = () => {
  const [assetData, setAssetData] = useState(null);
  const [assetMarketplaceeDetails, setAssetMarketplaceDetails] = useState(null);
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { assetId } = useParams();
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const convertedId = primaryKeyDecryption(assetId);
  const [isEditPopUpopen, setIsEditPopupOpen] = useState(false);
  const [isDeletePopUpopen, setIsDeletePopupOpen] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();

  const handleDeleteUserEquipment = async (id) => {
    try {
      await deleteAllEquipmentDetails(id);
      toast({
        status: "success",
        description: "Asset deleted successfully!",
        position: "top-right",
        isClosable: true,
      });
      navigate("/equipment-store");
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    (async () => {

      const data= await getUserAssetById(convertedId);

      setAssetData(data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const data = await getAssetMarketplaceListedDetails(convertedId);
        // console.log(data);
        setAssetMarketplaceDetails(data);
      } catch (error) {
      }
    })();
  }, []);

  return (
    <>
      <SinglePurposePopup
        isOpen={isEditPopUpopen}
        iconUrl={editPopUpIcon}
        bodyText="Are you sure you want to edit the equipment?"
        cancelAction={() => {
          setIsEditPopupOpen(false);
        }}
        continueAction={() =>
          navigate(
            `/e9-services/equipment-listing?mode=edit&assetId=${assetData?.AssetDetails?.AssetId}`
          )
        }
      />
      <SinglePurposePopup
        isOpen={isDeletePopUpopen}
        iconUrl={editPopUpIcon}
        bodyText="Are you sure you want to delete the equipment?"
        continueAction={async () => {
          await handleDeleteUserEquipment(assetData?.AssetDetails?.AssetId);
          //   setSelectedEquipmentId(null);
          setIsDeletePopupOpen(false);
        }}
        cancelAction={() => {
          //   setSelectedEquipmentId(null);
          setIsDeletePopupOpen(false);
        }}
      />
      <QrPopUp />
      <Modal isCentered isOpen={modalOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex justifyContent="space-between">
              <Heading size="26px">
                {selectedDocument && selectedDocument.name
                  ? selectedDocument.name
                  : "Document"}
              </Heading>
              <ReactSVG
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Cross.svg`}
                onClick={() => setModalOpen(false)}
                style={{ cursor: "pointer" }}
              />
            </Flex>
          </ModalHeader>
          <ModalBody>
            {assetData && assetData.AssetDocumentReference ? (
              <img
                src={`${process.env.REACT_APP_CDN_PVT_URL}${
                  assetData.AssetDocumentReference
                }${selectedDocument ? selectedDocument.url : ""}`}
                style={{
                  height: "90%",
                  width: "90%",
                  borderRadius: "8px",
                  margin: "0 auto",
                  marginBottom: "1em",
                }}
              />
            ) : (
              <></>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <EquipmentRentalLayout>
        <div style={{ margin: "0 52px", marginTop: "110px" }}>
          <Box maxH="510px" h="510px" overflowY="scroll" padding="1em 1.5em">
            <div className="user-equipment__card-container">
              <div
                style={{
                  width: "430px",
                  border: "1px solid #EEEEEE",
                }}
              >
                <Swiper
                  pagination={{ clickable: true }}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                  style={{ height: "226px", width: "430px" }}
                >
                  {/* video */}
                  {assetData?.AssetVideoDetails &&
                  Object.keys(assetData.AssetVideoDetails).length > 0 ? (
                    Object.entries(assetData.AssetVideoDetails).map(
                      ([key, value], index) =>
                        value !== null &&
                        key !== "AssetsImageId" &&
                        key !== "UserCredentialId" &&
                        key !== "NewAssetId" ? (
                          <SwiperSlide key={index}>
                            <video controls preload="none" width="100%">
                              <source
                                src={`${process.env.REACT_APP_CDN_PVT_URL}${assetData.AssetVideoBaseReference}${value}`}
                              />
                            </video>
                          </SwiperSlide>
                        ) : null
                    )
                  ) : (
                    <></>
                  )}

                  {/* images */}
                  {assetData?.AssetImageDetails &&
                  Object.keys(assetData.AssetImageDetails).length > 0 ? (
                    Object.entries(assetData.AssetImageDetails).map(
                      ([key, value], index) =>
                        value !== null &&
                        key !== "AssetsImageId" &&
                        key !== "UserCredentialId" &&
                        key !== "NewAssetId" ? (
                          <SwiperSlide key={index}>
                            <img
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "fill",
                              }}
                              alt="image"
                              src={`${process.env.REACT_APP_CDN_PVT_URL}${assetData.AssetImageBaseReference}${value}`}
                              className="user-equipment__card-container-image"
                            />
                          </SwiperSlide>
                        ) : null
                    )
                  ) : (
                    <SwiperSlide>
                      <img alt="default" src={defaultImage} />
                    </SwiperSlide>
                  )}
                </Swiper>
              </div>

              <div className="user-equipment__card-container__info">
                <div className="user-equipment__card-container__info-container">
                  <div>
                    <h1 className="user-equipment__card-container__info-container-text">
                      {assetData?.AssetDetails?.EquipmentTypeValue
                        ? assetData.AssetDetails.EquipmentTypeValue
                        : "Equipment Type"}
                    </h1>
                  </div>
                </div>

                <div class="user-equipment__card-container__info-action">
                  <Button
                    background="#FBF5F5"
                    isDisabled={
                      assetMarketplaceeDetails &&
                      (assetMarketplaceeDetails[0].IsCurrentlyAvailable ||
                        assetMarketplaceeDetails[1].IsCurrentlyAvailable)
                        ? true
                        : false
                    }
                    onClick={() => setIsDeletePopupOpen(true)}
                  >
                    <img
                      src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/delete.svg`}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </Button>
                  <Button
                    background="#F7FAF5"
                    onClick={() => setIsEditPopupOpen(true)}
                    isDisabled={
                      assetMarketplaceeDetails &&
                      (assetMarketplaceeDetails[0].IsCurrentlyAvailable ||
                        assetMarketplaceeDetails[1].IsCurrentlyAvailable)
                        ? true
                        : false
                    }
                  >
                    <img
                      src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/edit.svg`}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </Button>
                </div>
              </div>
            </div>

            {assetData ? (
              <div>
                <div>
                  <Box margin="0 auto">
                    <Flex alignItems="center">
                      <Flex w="100%" alignItems="center" gap="0.5em">
                        <Box minWidth="max-content">
                          <Text
                            color="#011f6b"
                            fontWeight="semibold"
                            fontSize="2xl"
                          >
                            LISTED MARKETPLACE
                          </Text>
                        </Box>
                        <Flex alignItems="center" w="100%">
                          <Divider borderColor="#011f6b" borderWidth="0.1em" />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Box>
                      <Flex gap="2em" overflowX="scroll" padding="1em 0">
                        <DetailsCard
                          heading={"Used Equipment"}
                          description={`Listed On: ${
                            assetData?.MarketPlaceList?.length > 0 &&
                            assetData.MarketPlaceList[0]?.ListedDateTime
                              ? assetData.MarketPlaceList[0].ListedDateTime
                              : "NA"
                          }`}
                        />
                        <DetailsCard
                          heading={"Rental Equipment"}
                          description={`Listed On: ${
                            assetData?.MarketPlaceList?.length > 1 &&
                            assetData.MarketPlaceList[1]?.ListedDateTime
                              ? assetData.MarketPlaceList[0].ListedDateTime
                              : "NA"
                          }`}
                        />
                      </Flex>
                    </Box>
                  </Box>

                  {/* LISTED MARKETPLACE */}
                  <Box margin="0 auto">
                    <Flex alignItems="center">
                      <Flex w="100%" alignItems="center" gap="0.5em">
                        <Box minWidth="max-content">
                          <Text
                            color="#011f6b"
                            fontWeight="semibold"
                            fontSize="2xl"
                          >
                            EQUIPMENT DETAILS
                          </Text>
                        </Box>
                        <Flex alignItems="center" w="100%">
                          <Divider borderColor="#011f6b" borderWidth="0.1em" />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Box>
                      <Flex
                        gap="2em"
                        flexDirection="row"
                        overflowX="scroll"
                        padding="1em 0"
                      >
                        <DetailsCard
                          heading={
                            assetData?.AssetDetails
                              ? assetData.AssetDetails.EquipmentTypeValue
                              : "NA"
                          }
                          description="Equipment Type"
                        />
                        <DetailsCard
                          heading={
                            assetData?.AssetDetails?.OEMName
                              ? assetData.AssetDetails.OEMName
                              : "NA"
                          }
                          description="Equipment Make"
                        />
                        <DetailsCard
                          heading={
                            assetData?.AssetDetails?.ModelName
                              ? assetData.AssetDetails.ModelName
                              : "NA"
                          }
                          description="Equipment Model"
                        />
                        <DetailsCard
                          heading={
                            assetData?.AssetDetails?.AssetManufacturingYear
                              ? assetData.AssetDetails.AssetManufacturingYear
                              : "NA"
                          }
                          description="Manufacturing year"
                        />
                        <DetailsCard
                          heading={
                            assetData?.AssetDetails?.AssetCurrentMeterReading
                              ? assetData.AssetDetails.AssetCurrentMeterReading
                              : "NA"
                          }
                          description="Meter Reading/Hour of Working"
                        />
                      </Flex>
                    </Box>
                  </Box>

                  {/* ENGINE DETAILS */}
                  <Box margin="0 auto">
                    <Flex alignItems="center">
                      <Flex w="100%" alignItems="center" gap="0.5em">
                        <Box minWidth="max-content">
                          <Text
                            color="#011f6b"
                            fontWeight="semibold"
                            fontSize="2xl"
                          >
                            ENGINE DETAILS
                          </Text>
                        </Box>
                        <Flex alignItems="center" w="100%">
                          <Divider borderColor="#011f6b" borderWidth="0.1em" />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Box>
                      <Flex
                        gap="2em"
                        flexDirection="row"
                        overflowX="scroll"
                        padding="1em 0"
                      >
                        <DetailsCard
                          heading={
                            assetData?.AssetDetails?.EquipmentEngineMakeValue
                              ? assetData.AssetDetails.EquipmentEngineMakeValue
                              : "NA"
                          }
                          description="Engine Make"
                        />
                        <DetailsCard
                          heading={
                            assetData?.AssetDetails?.AssetChassisNumber
                              ? assetData.AssetDetails.AssetChassisNumber
                              : "NA"
                          }
                          description="Classis Number"
                        />
                        <DetailsCard
                          heading={
                            assetData?.AssetDetails?.FuelTypeValue
                              ? assetData.AssetDetails.FuelTypeValue
                              : "NA"
                          }
                          description="Fuel type"
                        />
                        <DetailsCard
                          heading={
                            assetData?.AssetDetails?.AssetAutoEngineShutdownId
                              ? assetData.AssetDetails
                                  .AssetAutoEngineShutdownId === 1
                                ? "Yes"
                                : "No"
                              : "NA"
                          }
                          description="Auto Engine ShutDown"
                        />
                      </Flex>
                    </Box>
                  </Box>

                  {/* INSURENCE */}
                  <Box margin="0 auto">
                    <Flex alignItems="center">
                      <Flex w="100%" alignItems="center" gap="0.5em">
                        <Box minWidth="max-content">
                          <Text
                            color="#011f6b"
                            fontWeight="semibold"
                            fontSize="2xl"
                          >
                            INSURENCE
                          </Text>
                        </Box>
                        <Flex alignItems="center" w="100%">
                          <Divider borderColor="#011f6b" borderWidth="0.1em" />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Box>
                      <Flex gap="2em" overflowX="scroll" padding="1em 0">
                        <DetailsCard
                          heading={
                            assetData?.AssetDetails?.AssetInsuranceTypeId
                              ? assetData.AssetDetails.AssetInsuranceTypeId ===
                                1
                                ? "Yes"
                                : "No"
                              : "NA"
                          }
                          description="Active Status"
                        />
                        <DetailsCard
                          heading={
                            assetData?.AssetDetails?.AssetInsuranceValidityDate
                              ? assetData.AssetDetails
                                  .AssetInsuranceValidityDate
                              : "NA"
                          }
                          description="Valid upto"
                        />
                      </Flex>
                    </Box>
                  </Box>

                  {/* PURCHASING DETAILS */}
                  <Box margin="0 auto">
                    <Flex alignItems="center">
                      <Flex w="100%" alignItems="center" gap="0.5em">
                        <Box minWidth="max-content">
                          <Text
                            color="#011f6b"
                            fontWeight="semibold"
                            fontSize="2xl"
                          >
                            PURCHASING DETAILS
                          </Text>
                        </Box>
                        <Flex alignItems="center" w="100%">
                          <Divider borderColor="#011f6b" borderWidth="0.1em" />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Box>
                      <Flex gap="2em" overflowX="scroll" padding="1em 0">
                        <DetailsCard
                          heading={
                            assetData?.AssetPurchaseDetails?.PurchasedYear
                              ? assetData.AssetPurchaseDetails.PurchasedYear
                              : "NA"
                          }
                          description="Purchase Year"
                        />
                        <DetailsCard
                          heading={
                            assetData.AssetPurchaseDetails?.CountryName
                              ? assetData.AssetPurchaseDetails.CountryName
                              : "NA"
                          }
                          description="Registered Country"
                        />
                        <DetailsCard
                          heading={
                            assetData.AssetPurchaseDetails?.StateName
                              ? assetData.AssetPurchaseDetails.StateName
                              : "NA"
                          }
                          description="Registered State"
                        />
                        <DetailsCard
                          heading={
                            assetData.AssetPurchaseDetails
                              ?.AssetOwnershipTypeValue
                              ? assetData.AssetPurchaseDetails
                                  .AssetOwnershipTypeValue
                              : "NA"
                          }
                          description="Ownership"
                        />
                      </Flex>
                    </Box>
                  </Box>

                  {/* EQUIPMENT LOCATION */}
                  <Box margin="0 auto">
                    <Flex alignItems="center">
                      <Flex w="100%" alignItems="center" gap="0.5em">
                        <Box minWidth="max-content">
                          <Text
                            color="#011f6b"
                            fontWeight="semibold"
                            fontSize="2xl"
                          >
                            EQUIPMENT LOCATION
                          </Text>
                        </Box>
                        <Flex alignItems="center" w="100%">
                          <Divider borderColor="#011f6b" borderWidth="0.1em" />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Box>
                      <Flex gap="2em" overflowX="scroll" padding="1em 0">
                        <DetailsCard
                          heading={
                            assetData.AssetDetails?.AssetLocationAddress
                              ? assetData.AssetDetails.AssetLocationAddress
                              : "NA"
                          }
                          description="Equipment Location"
                        />
                      </Flex>
                    </Box>
                  </Box>

                  {/* Equipement Document */}
                  <Box margin="0 auto">
                    <Flex alignItems="center">
                      <Flex w="100%" alignItems="center" gap="0.5em">
                        <Box minWidth="max-content">
                          <Text
                            color="#011f6b"
                            fontWeight="semibold"
                            fontSize="2xl"
                          >
                            EQUIPMENT DOCUMENT
                          </Text>
                        </Box>
                        <Flex alignItems="center" w="100%">
                          <Divider borderColor="#011f6b" borderWidth="0.1em" />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Box>
                      <Flex gap="2em" overflowX="scroll" padding="1em 0">
                        {assetData?.AssetDocumentDetails &&
                        Object.keys(assetData.AssetDocumentDetails).length >
                          0 ? (
                          Object.entries(assetData.AssetDocumentDetails).map(
                            ([key, value], index) => (
                              <DetailsCard
                                heading={`${value.AssetDocumentTypeValue}`}
                                description={"View Document"}
                                onDescriptionClick={() => {
                                  setSelectedDocument({
                                    url: value.DocumentFileReference,
                                    name: value.AssetDocumentTypeValue,
                                  });
                                  setModalOpen(true);
                                }}
                              />
                            )
                          )
                        ) : (
                          <p>No Document Details</p>
                        )}
                      </Flex>
                    </Box>
                  </Box>
                </div>
              </div>
            ) : (
              <p>Equipment Data Not Found.</p>
            )}
          </Box>
        </div>

        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            maxHeight: "78px",
            boxShadow: "22px -3px 10px 0px #00000040",
            padding: "26px 73px 26px 73px",
          }}
        >
          <button
            className="user-equipment__footer-btn"
            onClick={() => dispatch(openQrModal())}
          >
            View Analytics
          </button>
          <button
            className="user-equipment__footer-btn"
            onClick={()=> navigate(`/e9-services/operator-search/job-post/${convertToBase64(assetData?.AssetDetails?.AssetNickName)}/${convertToBase64(assetData?.AssetDetails?.EquipmentTypeId)}/${convertToBase64(assetData?.AssetDetails?.EquipmentMakeId)}/${convertToBase64(assetData?.AssetDetails?.EquipmentModelId)}`)}
          >
            Find Operator
          </button>
          <button
            className="user-equipment__footer-btn"
            onClick={()=> navigate(`/e9-services/maintenance-search/job-post/${convertToBase64(assetData?.AssetDetails?.AssetNickName)}/${convertToBase64(assetData?.AssetDetails?.EquipmentTypeId)}/${convertToBase64(assetData?.AssetDetails?.EquipmentMakeId)}/${convertToBase64(assetData?.AssetDetails?.EquipmentModelId)}`)}
          >
            Find Maintainace
          </button>
          <button
            className="user-equipment__footer-btn"
            onClick={() => dispatch(openQrModal())}
          >
            Live Tracking
          </button>
        </div>
      </EquipmentRentalLayout>
    </>
  );
};
