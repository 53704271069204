import * as Yup from "yup";

export const REGISTRATION_FORM_VALIDATION_OPERATOR = Yup.object({
  firstName: Yup.string()
    .required("First Name Required")
    .min(2, "Minimum 2 charracters required.")
    .max(15, "Maximum 15 characters allowed")
    .matches(/^[a-zA-Z0-9 ]+$/, "Special characters not allowed"),
  lastName: Yup.string()
    .required("Last Name Required")
    .min(2, "Minimum 2 charracters required.")
    .max(15, "Maximum 15 characters allowed")
    .matches(/^[a-zA-Z0-9 ]+$/, "Special characters not allowed"),
  password: Yup.string()
    .required("Password Required")
    .min(6, "Minimum 6 characters required"),
});

export const REGISTRATION_FORM_VALIDATION_NOT_OPERATOR = Yup.object({
  enterpriseName: Yup.string()
    .required("Required")
    .min(6, "Minimum 6 characters required")
    .max(30, "Maximim 30 characters allowed")
    .matches(/^[a-zA-Z0-9 ]+$/, "Special characters not allowed"),
  firstName: Yup.string()
    .required("First Name Required")
    .min(2, "Minimum 2 charracters required.")
    .max(15, "Maximum 15 characters allowed")
    .matches(/^[a-zA-Z0-9 ]+$/, "Special characters not allowed"),
  lastName: Yup.string()
    .required("Last Name Required")
    .min(2, "Minimum 2 charracters required.")
    .max(15, "Maximum 15 characters allowed")
    .matches(/^[a-zA-Z0-9 ]+$/, "Special characters not allowed"),
  password: Yup.string()
    .required("Password Required")
    .min(6, "Minimum 6 characters required"),
});
