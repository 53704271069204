import { useDispatch, useSelector } from "react-redux";
import { MultiAdDivider } from "../../../../layout/multiAdLayout/MultiAdLayout";
import { VerifyOtpHoc } from "../../../otp/hoc/verifyOtpHoc/VerifyOtpHoc";
import { useNavigate } from "react-router-dom";
import { sendForgetPasswordThunk } from "../../thunk/forgotPasswordThunk";
import { setMobileNumber } from "../../slice/forgotPasswordSlice";
import useWindowWidth from "../../../../../sharedComponent/isMobile/CheckMobile";
import { MobileHeader } from "../../../../../website/components/MobileHeader/MobileHeader";
import { MobileCarousel } from "../../../../../website/components/MobileCarosel/MobileCarosel";
import { Button, Flex, Box, Text, useToast } from "@chakra-ui/react";
import { V5MultiAdLayout } from "../../../../layout/multiAdLayout/V5MultiAdLayout";

export const VerifyForgotPasswordOtp = () => {
  const otpData = useSelector((state) => state.otp);
  const appConfig = useSelector((state) => state.appConfig);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useWindowWidth();
  const onResendOtpError = () => {
    return navigate("/forgot-password/generate-otp");
  };

  const onOtpMatch = () => {
    dispatch(setMobileNumber(otpData.mobileNumber));
    navigate("/forgot-password/generate-new-password");
  };

  return (
    <V5MultiAdLayout
      heading="Verify your mobile number to reset password"
      subHeading={`We've sent you the OTP on +${appConfig.country.CountryIsdCode} ${otpData.mobileNumber}.`}
      justifyContent=""
    >
      <VerifyOtpHoc
        onResendOtpError={onResendOtpError}
        onOtpMatch={onOtpMatch}
        resendOtpFunction={() =>
          dispatch(
            sendForgetPasswordThunk({
              MobileNumber: otpData.mobileNumber,
              IsdCode: appConfig.country.CountryIsdCode,
            })
          )
        }
      />
    </V5MultiAdLayout>
  );
};
