import { createAsyncThunk } from "@reduxjs/toolkit";
import { createOperatorJobSearchFilters } from "../../../services/jobsPosted/jobsPosted";

export const createOperatorJobSearchFilter = createAsyncThunk(
  "/operatorSearch/createOperatorJobSearchFilter",
  async (jobId, { getState, rejectWithValue, fulfillWithValue }) => {
    const {
      equipmentType,
      equipmentModel,
      equipmentMake,
      jobLocation,
      serviceDetails,
      equipmentNickname,
    } = getState().operatorSearch;
    const { token, accountDetails, UserTypeValue } = getState().user;

    const body = {
      OperatorJobDetailId: jobId,
      ReportingLocation: jobLocation.name,
      ReportLocationLatitude: jobLocation.lat,
      ReportLocationLongitude: jobLocation.lng,
      EquipmentNickName: equipmentNickname,
      EquipmentTypeId: equipmentType ? parseInt(equipmentType.id) : "",
      EquipmentMakeId: equipmentMake,
      EquipmentModelId: equipmentModel,
      ServiceTypeId: serviceDetails.ServiceTypeId,
    };
    if (UserTypeValue === "Company") {
      body.TaxNumber = accountDetails?.CompanyDetails?.TaxNumber;
    }

    try {
      const data = await createOperatorJobSearchFilters(body);

      if (
        data.APIResponseCode === 200 &&
        data.Status &&
        Object.keys(data.Data).length > 0
      ) {
        fulfillWithValue(data.Message.SuccessMessage);
      } else {
        rejectWithValue(data.Message.FailMessage);
      }
    } catch (error) {
      rejectWithValue("Some error occured, Please Try Again..");
    }
  }
);
