import { createAsyncThunk } from "@reduxjs/toolkit";
import fetchHandler from "../../../services/fetchHandler";
import { API_CONSTANTS } from "../../../../constants/apiConstants";


export const initialIndustryThunk = createAsyncThunk('industryRequirement/getInitialCards',async(_,{getState}) => {
    try {
        const {data} = await fetchHandler(
            `${API_CONSTANTS.GET_REQUIREMENT_LIST}/12/0`,
            {},
            {},
            {},
            API_CONSTANTS.HTTP_GET,
            {isAuthRequired : true}
        )
        if(data?.Status === 200 && data?.Data?.RequirementList?.length > 0){
            return data?.Data?.RequirementList
        }
    } catch (error) {
        throw error ;
    }
})