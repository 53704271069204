import { useEffect, useRef, useState } from "react";
import {
  Box,
  FormLabel,
  Button,
  Text,
  IconButton,
  Input,
  Flex,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";

const VideoUpload = ({ setFieldValue, errors, touched, defaultVideo }) => {
  const [videoPreview, setVideoPreview] = useState(null);
  const fileInputRef = useRef(null);
  const [error, setError] = useState(null);

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (defaultVideo) {
      setFieldValue("CommunityVideoFileRef", null);
      setVideoPreview(defaultVideo);
      setError(null);
      setError(null);
    }
  }, [defaultVideo]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const maxSizeInMB = 125;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (file.size > maxSizeInBytes) {
        setError("File size exceeds 125MB. Please upload a smaller file.");
        setFieldValue("CommunityVideoFileRef", null);
        setVideoPreview(null);
        return;
      }

      setFieldValue("CommunityVideoFileRef", file);
      setVideoPreview(URL.createObjectURL(file));
      setError(null);
    }
  };

  const handleRemoveVideo = () => {
    setFieldValue("CommunityVideoFileRef", null);
    setVideoPreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  return (
    <Flex direction={"column"}>
      <Flex justifyContent={"space-between"} width={"350px"}>
        <FormLabel fontSize={"18px"} fontWeight={"600"} color={"#1A1E21"}>
          Upload Video File
        </FormLabel>
        <Button
          onClick={handleFileSelect}
          variant="outline"
         color={"#002961"}
         border={"1px solid #002961"}
          mb={4}
          leftIcon={<>+</>}
        >
          Add
        </Button>
        <Input
          ref={fileInputRef}
          id="CommunityVideoFileRef"
          name="CommunityVideoFileRef"
          type="file"
          accept="video/*"
          onChange={handleFileChange}
          display="none" 
        />
      </Flex>

      {errors.CommunityVideoFileRef && touched.CommunityVideoFileRef ? (
        <Text color="red">{errors.CommunityVideoFileRef}</Text>
      ) : null}
      {error && <Text color="red">{error}</Text>}
      {videoPreview && (
       
        <>
          <Box mt={4} width={"350px"} height={"170px"} overflow="hidden">
            <Box
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <video
                style={{
                  objectFit: "cover",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
                controls
              >
                <source src={videoPreview} type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
            </Box>
          </Box>
          <IconButton
            aria-label="Remove video"
            variant="text"
            color={"#D60000"}
            icon={<DeleteIcon />}
            onClick={handleRemoveVideo}
            size="sm"
          />
        </>
      )}
      {!videoPreview && (
        <Box
          w={"100%"}
          h={"200px"}
          border={"1px solid #00000033"}
          background={"#F5F5F5"}
        >
          <Flex
            direction={"column"}
            justifyContent={"space-around"}
            alignItems={"center"}
            h={"200px"}
          >
            <Box height={"60px"} w={"60px"}>
              <img
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/community/play.svg`}
                alt="video upload"
              />
            </Box>
            <Text
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "50",
              }}
            >
              {/* Upload Document */}
              <p style={{ fontSize: "14px" }}>Upload Video</p>
              <p style={{ fontSize: "14px" }}>{`(Upto 125mb file size)`}</p>
            </Text>
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

export default VideoUpload;
