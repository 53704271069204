export const initialState = {
    data: null,
    filteredDataRequired: false,
    filterModalOpen: false,
    filterByCountry: "",
    filterByState: "",
    filterByEquipmentType: "",
    paginationCount: 0,
    endOfData: false,
    cardCount: 12,
    initialLoading: false,
    paginationLoading: false,
    error: null,
    errorMessage: null,
  };
  