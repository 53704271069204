import { WebPageLayout } from '../../Layout/WebpageLayout';
import { Helmet } from 'react-helmet';
import "..//Product/OurProduct.css"
import BlogFirst from './blogs/BlogFirst';
import BlogSecond from './blogs/BlogSecond';
import BlogThird from './blogs/BlogThird';


export const OurBlog = () => {

  return (
    <>
      <WebPageLayout>
        <Helmet>
          <title>Our Product | Heavy Equipment Industry Digital Marketplace</title>
          <meta name="description" content=" Search Heavy Equipment Operator or Rental Equipment, or Used Equipment or Contractor" />
          <meta name="keyword" content=" 
          skilled jcb operator,crane operator,dumper driver,engine mechanic,
          welder,electrical work,bucket,backhoe,wheel loader,excavator,breaker,
          hydraulic oil,engine oil,ropes,spare parts.
          " />
          <link rel="Our Product" href="https://equip9.com/our-product"></link>
        </Helmet>

        <BlogFirst />
        <BlogSecond />
        <BlogThird />
      </WebPageLayout>

    </>
  );
};


export default OurBlog