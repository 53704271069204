import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Text } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { NewSideMenuLayout } from "../../../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { SubscriptionTableRow } from "../../../../components/SubscriptionTableRow/SubscriptionTableRow";
import { editSubscriptionTypeStatus } from "../../slice/subscriptionSlice";
import { getSubscriptionTypeThunk } from "../../thunks/getSubscriptionTypeThunk";
import { createCompanySubscriptionThunk } from "../../thunks/createCompanySubscriptionThunk";
import { calculateTotalLeads } from "../../helpers/helpers";
import { createUserSubscriptionThunk } from "../../thunks/createUserSubscriptionDetails";
import { V5FixedFooterSidebarLayout } from "../../../../layout/V5FixedFooterSidebarLayout/V5FixedFooterSidebarLayout";
import { V5GridFooterSidebarLayout } from "../../../../layout/V5FixedFooterLayoutGrid/V5FixedFooterLayoutGrid";

export const SubscriptionServices = () => {
  const { subscriptionType } = useParams();
  const subscriptionData = useSelector((state) => state.subscription);
  const { subscriptionTableData, transactionState } = useSelector(
    (state) => state.subscription
  );
  const { UserTypeValue, currencyDetails } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading,setLoading]= useState(false);

  useEffect(() => {
    (async () => {
      if (subscriptionTableData.length < 1) {
        dispatch(getSubscriptionTypeThunk(subscriptionType));
      }
    })();
  }, []);

  useEffect(() => {
    totalCartLeads();
    totalCartValue();
  }, [subscriptionTableData]);

  const totalCartLeads = () => calculateTotalLeads(subscriptionTableData);

  const totalCartValue = () => {
    const totalValue = subscriptionTableData.reduce(
      (accumulator, currentValue) => {
        if (currentValue.isSelected) {
          if (currentValue.ServiceDiscountTypeValue) {
            accumulator +=
              (1 - currentValue.ServiceDiscountTypeValue / 100) *
              currentValue.ServiceContactUnitPrice *
              (currentValue.quantitySelected
                ? currentValue.quantitySelected
                : currentValue.minimumQuantity);
          } else {
            accumulator +=
              (currentValue.quantitySelected
                ? currentValue.quantitySelected
                : currentValue.minimumQuantity) *
              currentValue.ServiceContactUnitPrice;
          }
        }
        return accumulator;
      },
      0
    );
    return totalValue.toFixed(2);
  };

  const isItemSelected = (serviceName) => {
    const selectedValue = subscriptionTableData.find(
      (cartItem) => cartItem.ServiceTypeValue === serviceName
    );
    return selectedValue ? selectedValue.isSelected : false;
  };

  const getSelectedSubscriptions = () => {
    const selectedSubsciptions = subscriptionTableData.filter(
      (subscriptionItem) => subscriptionItem.isSelected
    );
    return selectedSubsciptions;
  };

  const cancelButtonClick = () => {
    const selectedSubsciptions = getSelectedSubscriptions();
    if (selectedSubsciptions.length > 0) {
      dispatch(
        createCompanySubscriptionThunk({
          body: selectedSubsciptions,
          TransactionStateId: transactionState[0].TransactionStateId,
        })
      )
        .unwrap()
        .then((data) => {
          navigate("/dashboard");
        });
    } else {
      navigate("/dashboard");
    }
  };

  const checkoutButtonClickHandler = async (transactionStateId) => {
    setLoading(true);
    const selectedSubscriptionList = getSelectedSubscriptions();
    if (selectedSubscriptionList.length > 0) {
      if (UserTypeValue.toLowerCase() === "Company".toLowerCase()) {
        await dispatch(
          createCompanySubscriptionThunk({
            body: selectedSubscriptionList,
            transactionStateId,
          })
        )
          .unwrap()
          .then((data) => {
            return navigate("/subscription/review-subscription-details");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        await dispatch(
          createUserSubscriptionThunk({
            body: selectedSubscriptionList,
            transactionStateId,
          })
        )
          .unwrap()
          .then((data) => {
            return navigate("/subscription/review-subscription-details");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    setLoading(false);
  };

  return (
    <V5GridFooterSidebarLayout

    isLoading={loading}
      primaryButtonValue={
        transactionState && transactionState[1]
          ? transactionState[1].TransactionStateId
          : ""
      }
      primaryButtonVariant="gradient"
      primaryButtonMaxWidth="347px"
      primaryButtonWidth="100%"
      primaryButtonClickAction={(e) => {
        checkoutButtonClickHandler(e.target.value);
      }}
      primaryButtonText={
        transactionState && transactionState[1]
          ? transactionState[1].TransactionStateValue
          : ""
      }
      primaryButtonDisabled={totalCartLeads() === 0}
      secondaryText={
        transactionState && transactionState[0]
          ? transactionState[0].TransactionStateValue
          : "Text"
      }
      secondaryButtonValue={
        transactionState && transactionState[0]
          ? transactionState[0].TransactionStateId
          : ""
      }
      secondaryButtonClick={() => {
        cancelButtonClick();
      }}
    >
      <div style={{ margin: "0px 52px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "1em",
          }}
        >
          <h1 style={{ fontSize: "32px", fontWeight: 700 }}>
            {subscriptionData.selectedSubscriptionType
              ? subscriptionData.selectedSubscriptionType.SUBSCRIPTION
              : ""}
          </h1>
          <div
            style={{
              display: "flex",
              backgroundColor: "#fff",
              gap: "0.5em",
              border: "1px solid, #2c2c2c1a",
              boxShadow: "0px 4px 20px #2C2C2C14",
              borderRadius: "4px",
              padding: "10px 23px 9px 24px",
            }}
          >
            <div>
              <p
                style={{ color: "#1E3264", fontSize: "14px", fontWeight: 600 }}
              >
                Total Contacts:
              </p>
              <Text
                bgGradient="linear(to-r, #f67c00, #FED401)"
                bgClip="text"
                fontSize="1.2em"
                fontWeight="700"
                textAlign="center"
              >
                {totalCartLeads()}
              </Text>
            </div>
            <div>
              <p
                style={{ color: "#1E3264", fontSize: "14px", fontWeight: 600 }}
              >
                Total Amount:
              </p>
              <Text
                bgGradient="linear(to-r, #f67c00, #FED401)"
                bgClip="text"
                fontSize="1.2em"
                fontWeight="700"
                textAlign="center"
              >{`${
                currencyDetails ? currencyDetails.CurrencyValue : ""
              } ${totalCartValue()}`}</Text>
            </div>
          </div>
        </div>
        {/* table */}
        <div
          style={{ maxHeight: "375px", overflowY: "scroll", marginTop: "2em" }}
        >
          <table
            style={{
              backgroundColor: "#ffffff",
              boxShadow: "0px 10px 30px #2c2c2c1A",
              width: "100%",
              borderRadius: "4px",
              border: "1px solid #2C2C2C1A",
            }}
          >
            <thead
              style={{
                fontSize: "18px",
                fontWeight: "600",
                textAlign: "left",
                color: "#1E3264",
              }}
            >
              <tr style={{ borderBottom: "1px solid #2C2C2C1A" }}>
                <th
                  style={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  E9 Services
                </th>
                <th style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  Rate
                </th>
                <th style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  Validity
                </th>
                <th style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  Free Contacts
                </th>
                <th
                  style={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  Quantity
                </th>
                <th
                  style={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  Amount
                </th>
                <th
                  style={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  Add to Cart
                </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "18px" }}>
              {subscriptionTableData && subscriptionTableData.length > 0 ? (
                subscriptionTableData.map((service) => {
                  const {
                    ServiceTypeValue,
                    ServiceContactUnitPrice,
                    ValidFor,
                    ServiceFreeNumber,
                    ServiceMinNumber,
                    ServiceMaxNumber,
                    ServiceNumberIncrement,
                    ServiceDiscountTypeValue,
                  } = service;
                  return (
                    <SubscriptionTableRow
                      key={ServiceTypeValue}
                      name={ServiceTypeValue}
                      pricePerUnit={ServiceContactUnitPrice}
                      currencySymbol={
                        currencyDetails ? currencyDetails.CurrencyValue : ""
                      }
                      vaildityDays={ValidFor}
                      freeServiceCount={ServiceFreeNumber}
                      minimumQuantity={ServiceMinNumber}
                      maximumQuantity={ServiceMaxNumber}
                      iterationValue={ServiceNumberIncrement}
                      discountPercentage={ServiceDiscountTypeValue}
                      checkedAction={() =>
                        dispatch(
                          editSubscriptionTypeStatus({
                            name: ServiceTypeValue,
                            isSelected: false,
                          })
                        )
                      }
                      unCheckedAction={() =>
                        dispatch(
                          editSubscriptionTypeStatus({
                            name: ServiceTypeValue,
                            isSelected: true,
                          })
                        )
                      }
                      selected={isItemSelected(ServiceTypeValue)}
                    />
                  );
                })
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* <div
        style={{
          width: "100%",
          backgroundColor: "white",
          display: "grid",
          gridTemplateColumns: "100px 1fr",
          alignItems: "center",
          maxHeight: "78px",
        }}
      >
        <Button
          variant="link"
          boxShadow="none"
          onClick={cancelButtonClick}
          value={
            transactionState && transactionState[0]
              ? transactionState[0].TransactionStateId
              : ""
          }
        >
          <ArrowBackIcon marginRight="0.15em" />
          {transactionState && transactionState[0]
            ? transactionState[0].TransactionStateValue
            : "Text"}
        </Button>
        <Button
          variant="gradient"
          w="347px"
          onClick={(e) => checkoutButtonClickHandler(e.target.value)}
          justifySelf="center"
          value={
            transactionState && transactionState[1]
              ? transactionState[1].TransactionStateId
              : ""
          }
          isDisabled={totalCartLeads() === 0}
        >
          {transactionState && transactionState[1]
            ? transactionState[1].TransactionStateValue
            : "Checkout"}
        </Button>
      </div> */}
    </V5GridFooterSidebarLayout>
  );
};
