import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCountriesThunk } from "../../mtData/thunk/getCountriesThunk";

export const setSelectedCountryThunk = createAsyncThunk("setSelectedCountry", async(value, {getState, dispatch}) => {
    const masterData = getState().masterData;
    if(!masterData.Countries){
        await dispatch(getCountriesThunk())
    }
    
    const selectedCountry = getState().masterData.Countries.find((country) => parseInt(country.CountryIsdCode) === parseInt(value))
    return selectedCountry
})