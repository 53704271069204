import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../constants/initialState";
import { myMaterialsInitialDataThunk } from "../thunk/myMaterialsInitialDataThunk";
import { getMaterialRequestReceivedCountByIdThunk } from "../thunk/getMaterialRequestReceivedCountByIdThunk";
import { getMaterialConnectsCountByIdThunk } from "../thunk/getMaterialConnectsCountByIdThunk";
import { getMaterialConnectsPendingCountByIdThunk } from "../thunk/getMaterialConnectsPendingCountByIdThunk";
import { getMaterialLikesCountByIdThunk } from "../thunk/getMaterialLikesCountByIdThunk";
import { myMaterialsLoadMoreDataThunk } from "../thunk/myMaterialsLoadMoreDataThunk";

export const myMaterialsSlice = createSlice({
    name:"myMaterials",
    initialState:initialState,
    reducers:{
        setData:(state,action)=>{
            state.data = action.payload;
        },
        updateRemoveFromMarketPlace: (state,{payload})=>{
            state.data = state.data.map(d => {
              if (d.MaterialId === payload.MaterialId) {
                  return {
                      ...d,
                      IsOnMarketplace: 0
                  };
              }
              return d;
          });
        },
        
    },
  
  extraReducers: (builder) => {
    builder
      .addCase(myMaterialsInitialDataThunk.pending, (state) => {
        state.data = null;
        state.initialLoading = true;
        state.paginationLoading = true;
        state.error = false;
        state.errorMessage = false;
        state.paginationCount = 0;
        state.endOfData = false;
      })
      .addCase(myMaterialsInitialDataThunk.fulfilled, (state, action) => {
        state.data = action.payload?.Data?.MaterialList;
        state.initialLoading = false;
        state.paginationLoading = false;
        state.paginationCount = 1;
        state.error = false;
        state.errorMessage = null;
        if (action.payload?.Data?.MaterialList?.length < state.cardCount) {
          state.endOfData = true;
        }
      })
      .addCase(myMaterialsInitialDataThunk.rejected, (state, action) => {
        state.data = null;
        state.initialLoading = false;
        state.paginationLoading = false;
        state.error = true;
        state.errorMessage = action.payload;
        state.paginationCount = 0;
        state.endOfData = true;
      })
      .addCase(myMaterialsLoadMoreDataThunk.pending, (state) => {
        state.paginationLoading = true;
        state.error = false;
        state.errorMessage = null;
      })
      .addCase(myMaterialsLoadMoreDataThunk.fulfilled, (state, action) => {
        state.error = false;
        state.errorMessage = null;
        state.paginationLoading = false;
        state.data = [...state.data, ...action.payload?.Data?.MaterialList];
        if (action.payload?.Data?.MaterialList?.length >= state.cardCount) {
          state.paginationCount += 1;
        } else {
          state.endOfData = true;
        }
      })
      .addCase(getMaterialRequestReceivedCountByIdThunk.pending, (state) => {
        state.requestReceivedData = null;
        state.requestReceivedLoading = true;
      })
      .addCase(
        getMaterialRequestReceivedCountByIdThunk.fulfilled,
        (state, action) => {
          state.requestReceivedData =
            action.payload?.Data?.RequestReceivedCount[0].RequestReceivedCount;
          state.requestReceivedLoading = false;
        }
      )
      .addCase(getMaterialConnectsCountByIdThunk.pending, (state) => {
        state.connectsCountData = null;
        state.connectsCountLoading = true;
      })
      .addCase(getMaterialConnectsCountByIdThunk.fulfilled, (state, action) => {
        state.connectsCountData =
          action.payload?.Data?.ConnectsCount[0].ConnectsCount;
        state.connectsCountLoading = false;
      })
      .addCase(getMaterialConnectsPendingCountByIdThunk.pending, (state) => {
        state.connectsPendingData = null;
        state.connectsPendingLoading = true;
      })
      .addCase(
        getMaterialConnectsPendingCountByIdThunk.fulfilled,
        (state, action) => {
          state.connectsPendingData =
            action.payload?.Data?.ConnectsPendingCount[0].ConnectsPendingCount;
          state.connectsPendingLoading = false;
        }
      )
      .addCase(getMaterialLikesCountByIdThunk.pending, (state) => {
        state.likesCountData = null;
        state.likesCountLoading = true;
      })
      .addCase(getMaterialLikesCountByIdThunk.fulfilled, (state, action) => {
        state.likesCountData = action.payload?.Data?.LikesCount[0].LikeCount;
        state.likesCountLoading = false;
      });
  },
});



export const {
    setData,
    updateRemoveFromMarketPlace
}=myMaterialsSlice.actions;
