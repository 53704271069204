import React, {useState }  from 'react';
import { SideMenuLayout } from '../../../../../../../../layout/sideMenuLayout/SideMenuLayout';
import { DashboardNavBar } from '../../../../../../../../components/DashboardNavBar/DashboardNavBar';
import { MidSection } from '../../../../../../../../../sharedComponent/midSection/MidSection';
import './WorkExpirence.css';
import { AddWorkExpirence } from './WorkExpirencePopCard/WorkExpirencePopCard';
import { GetWorkExpirenceDetails } from './WorkExpirencegetDetail/WorkExpirencegetDetail';
import { useSelector } from "react-redux";
import { EditExpirence } from './EditWorkExpirence/EditWorkExpirence';
// GetWorkExpirenceDetails
export const WorkExpirence=()=>{

    const [isPopupOpen, setPopupOpen] = useState(false);
    const[isAddformpopup, setAddformpopup] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0); 
     const userData = useSelector((state) => state.user);
     const [workCompanyId, setWorkCompanyId] = useState(null);
    const verification = userData?.accountDetails?.UserDetails?.E9VerificationMessage

    const openPopup = () => {
        setPopupOpen(true);
      };
    
      const closePopup = () => {
        setPopupOpen(false);
      }
  
      const triggerRefresh = () => {  
        setRefreshKey(prev => prev + 1);
      }

       const openformPopup=()=>{
        setAddformpopup(true);
       }

       const closeFormPopup = ()=>{
        setAddformpopup(false);
       }

      return(
        <>
         <SideMenuLayout>
        <DashboardNavBar />
        <MidSection  >


        {verification === "Not Verified" ? (
          <div
            className="Not-Verfied-tag"
            // style={{ backgroundColor: verification === 'Not Verified' ? 'green' : 'green' }}
          >
            <p className="Verifi-tex">
            Your profile is not verified.Please complete and update your
              profile for verifications
              {/* <p>{verification}</p> */}
            </p>
          </div>
        ) : (
          <div
            className="Verfied-tag"
            // style={{ backgroundColor: verification === 'Not Verified' ? 'green' : 'green' }}
          >
            <p className="Verifi-text">
              Your profile is  verified.
              {/* <p>{verification}</p> */}
            </p>
          </div>
        )}
                <div className="verification-details">
                    <p className='your-verification-details'>Your Company Details</p>
                    <hr  className='seprator-work'></hr>
                    <div className='add-verification-work' onClick={openformPopup}>
                  <p className='add-workd-detail-text'>+ Add Work Details</p>
                </div>
                </div>
                

                <AddWorkExpirence isOpenForm={isAddformpopup} onCloseForm={closeFormPopup} triggerRefresh={triggerRefresh}  workCompanyId={workCompanyId}   />
                <GetWorkExpirenceDetails triggerRefresh={triggerRefresh}  onEdit={openPopup }  setWorkCompanyId={setWorkCompanyId} />
                <EditExpirence isOpen={isPopupOpen} onClose={closePopup} triggerRefresh={triggerRefresh}  workCompanyId={workCompanyId}  />

        </MidSection>
       </SideMenuLayout>
        
        </>
      );
}

