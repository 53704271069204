import { createSlice } from "@reduxjs/toolkit";

import { setSessionStorageItems } from "../../../helpers/sessionStorage/sessionStorage";
import { operatorSearchInitialState } from "../initialState/initialState";
import { createOperatorJobSearchFilter } from "../thunks/createOperatorJobSearchFilter";

const operatorSearchSlice = createSlice({
  name: "operatorSearch",
  initialState: operatorSearchInitialState,
  reducers: {
    setOpServiceCategory: (state, {payload}) => {
    state.serviceDetails = payload;
    setSessionStorageItems("OPERATOR_SEARCH_SERVICE_DETAILS", payload);
    },
    setEquipmentNickname: (state,{payload}) =>{
      state.equipmentNickname = payload;
      setSessionStorageItems("OPERATOR_SEARCH_EQ_NICKNAME",payload);
    },
    setEquipmentType: (state, { payload }) => {
      state.equipmentType = payload;
      setSessionStorageItems("OPERATOR_SEARCH_EQ_TYPE", payload);
    },
    setEquipmentModel: (state, { payload }) => {
      state.equipmentModel = payload;
      setSessionStorageItems("OPERATOR_SEARCH_EQ_MODEL", payload);
    },
    setEquipmentMake: (state, { payload }) => {
      state.equipmentMake = payload;
      setSessionStorageItems("OPERATOR_SEARCH_EQ_MAKE", payload);
    },
    setEquipmentAge: (state, { payload }) => {
      state.equipmentAge = payload;
      setSessionStorageItems("OPERATOR_SEARCH_EQ_AGE", payload);
    },
    setJobTitle: (state, { payload }) => {
      state.jobTitle = payload;
      setSessionStorageItems("OPERATOR_SEARCH_JOB_TITLE", payload);
    },
    setJobLocation: (state, {payload}) => {
      state.jobLocation= payload;
      setSessionStorageItems("OPERATOR_SEARCH_JOB_LOCATION", payload);
    },
    setSalaryType: (state, { payload }) => {
      state.salaryType = payload;
      setSessionStorageItems("OPERATOR_SEARCH_SALARY_TYPE", payload);
    },
    setInhandSalary: (state, { payload }) => {
      state.inHandSalary = payload;
      setSessionStorageItems("OPERATOR_SEARCH_IN_HAND_SALARY", payload);
    },
    setOverTimeSalary: (state, { payload }) => {
      state.overTimePay = payload;
      setSessionStorageItems("OPERATOR_SEARCH_OVERTIME_PAY", payload);
    },
    setFoodBenefits: (state, { payload }) => {
      state.foodBenefits = payload;
      setSessionStorageItems("OPERATOR_SEARCH_FOOD_BENEFITS", payload);
    },
    setProvidentFund: (state, { payload }) => {
      state.providentFund = payload;
      setSessionStorageItems("OPERATOR_SEARCH_PROVIDENT_FUND", payload);
    },
    setAccomodationProvided: (state, { payload }) => {
      state.accomodationProvided = payload;
      setSessionStorageItems("OPERATOR_SEARCH_ACCOMODATION_TYPE", payload);
    },
    setShiftType: (state, { payload }) => {
      state.shiftType = payload;
      setSessionStorageItems("OPERATOR_SEARCH_SHIFT_TYPE", payload);
    },
    setWorkingHours: (state, { payload }) => {
      state.workingHours = payload;
      setSessionStorageItems("OPERATOR_SEARCH_WORKING_HOURS", payload);
    },
    setLunchHours: (state, { payload }) => {
      state.lunchHours = payload;
      setSessionStorageItems("OPERATOR_SEARCH_LUNCH_HOURS", payload);
    },
    setJobDuration: (state, { payload }) => {
      state.jobDuration = payload;
      setSessionStorageItems("OPERATOR_SEARCH_JOB_DURATION", payload);
    },
    setJobDurationType : (state, {payload}) =>{
      state.jobDurationType = payload;
      setSessionStorageItems("OPERATOR_SEARCH_JOB_DURATION_TYPE" , payload)
    },
    setWorkType: (state, { payload }) => {
      state.workType = payload;
      setSessionStorageItems("OPERATOR_SEARCH_WORK_TYPE", payload);
    },
    setInsuranceType: (state, { payload }) => {
      state.insurance = payload;
      setSessionStorageItems("OPERATOR_SEARCH_INSURANCE", payload);
    },
    setTravelAllowance: (state, { payload }) => {
      state.travelAllowance = payload;
      setSessionStorageItems("OPERATOR_SEARCH_TRAVEL_ALLOWANCE", payload);
    },
    setPayCycle: (state, { payload }) => {
      state.payCycle = payload;
      setSessionStorageItems("OPERATOR_SEARCH_PAY_CYCYLE", payload);
    },
    increaseStepperIndex: (state) => {
      if (state.stepperRoute.length <= state.stepperIndex) {
        return;
      }
      state.stepperIndex += 1;
      setSessionStorageItems(
        "OPERATOR_SEARCH_STEPPER_INDEX",
        state.stepperIndex
      );
    },
    decreaseStepperIndex: (state) => {
      if (state.stepperIndex === 0) {
        return;
      }
      if (state.stepperIndex < 0) {
        state.stepperIndex = 0;
      }
      state.stepperIndex -= 1;
      setSessionStorageItems(
        "OPERATOR_SEARCH_STEPPER_INDEX",
        state.stepperIndex
      );
    },
    resetOperatorSearch: (state) => {
      setSessionStorageItems("OPERATOR_SEARCH_EQ_TYPE", "");
      setSessionStorageItems("OPERATOR_SEARCH_EQ_MODEL", "");
      setSessionStorageItems("OPERATOR_SEARCH_EQ_MAKE", "");
      setSessionStorageItems("OPERATOR_SEARCH_EQ_AGE", "");
      setSessionStorageItems("OPERATOR_SEARCH_JOB_TITLE", "");
      setSessionStorageItems("OPERATOR_SEARCH_SALARY_TYPE", "");
      setSessionStorageItems("OPERATOR_SEARCH_IN_HAND_SALARY", "");
      setSessionStorageItems("OPERATOR_SEARCH_OVERTIME_PAY", "");
      setSessionStorageItems("OPERATOR_SEARCH_FOOD_BENEFITS", "");
      setSessionStorageItems("OPERATOR_SEARCH_PROVIDENT_FUND", "");
      setSessionStorageItems("OPERATOR_SEARCH_ACCOMODATION_TYPE", "");
      setSessionStorageItems("OPERATOR_SEARCH_SHIFT_TYPE", "");
      setSessionStorageItems("OPERATOR_SEARCH_WORKING_HOURS", "");
      setSessionStorageItems("OPERATOR_SEARCH_LUNCH_HOURS", "");
      setSessionStorageItems("OPERATOR_SEARCH_JOB_DURATION", "");
      setSessionStorageItems("OPERATOR_SEARCH_WORK_TYPE", "");
      setSessionStorageItems("OPERATOR_SEARCH_INSURANCE", "");
      setSessionStorageItems("OPERATOR_SEARCH_TRAVEL_ALLOWANCE", "");
      setSessionStorageItems("OPERATOR_SEARCH_PAY_CYCYLE", "");
      setSessionStorageItems("OPERATOR_SEARCH_JOB_LOCATION", "");
      setSessionStorageItems("OPERATOR_SEARCH_EQ_NICKNAME","");

      state.stepperIndex = 0;
      state.equipmentType = "";
      state.equipmentModel = "";
      state.equipmentMake = "";
      state.equipmentAge = "";
      state.jobTitle = "";
      state.salaryType = "";
      state.providentFund = "";
      state.foodBenefits = "";
      state.accomodationProvided = "";
      state.inHandSalary = "";
      state.overTimePay = "";
      state.shiftType = "";
      state.workingHours = "";
      state.lunchHours = "";
      state.jobDuration = "";
      state.workType = "";
      state.insurance = "";
      state.travelAllowance = "";
      state.payCycle = "";
      state.jobLocation = "" ;
      state.equipmentNickname = "";
    },
    setIsLoading :(state, { payload })=>{
      state.isLoading=payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createOperatorJobSearchFilter.fulfilled, (state) => {
      sessionStorage.clear();
      state = null;
    });
    builder.addCase(createOperatorJobSearchFilter.rejected, (state) => {});
  },
});

export const {
  setOpServiceCategory,
  setEquipmentAge,
  setEquipmentMake,
  setEquipmentModel,
  setEquipmentType,
  setAccomodationProvided,
  setFoodBenefits,
  setInhandSalary,
  setJobTitle,
  setJobLocation,
  setOverTimeSalary,
  setProvidentFund,
  setSalaryType,
  setInsuranceType,
  setJobDuration,
  setJobDurationType,
  setLunchHours,
  setShiftType,
  setTravelAllowance,
  setWorkType,
  setWorkingHours,
  setPayCycle,
  increaseStepperIndex,
  decreaseStepperIndex,
  resetOperatorSearch,
  setEquipmentNickname,
  setIsLoading
} = operatorSearchSlice.actions;

export default operatorSearchSlice.reducer;
