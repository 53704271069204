import {
  Accordion,
  Flex,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  increaseStepperIndex,
  setAccomodationProvided,
  setFoodBenefits,
  setInhandSalary,
  setJobLocation,
  setJobTitle,
  setOverTimeSalary,
  setPayCycle,
  setProvidentFund,
  setSalaryType,
} from "../slice/operatorSearchSlice";
import { FormikInput } from "../../../components/Input/FormikInput";
import { RadioParent } from "../../../components/RadioParent/RadioParent";
import { registerNumbersOnly } from "../../../../utils/form/form";
import {
  getPayCycleTypes,
  getProvidentFundTypes,
  getSalaryTypes,
} from "../../../services/salary/salary";
import { getAccomodationTypes } from "../../../services/accomodation/accomodation";
import { getFoodBenefitTypes } from "../../../services/foodBenefits/foodBenefits";
import { LocationInput } from "../../../components/LocationInput/LocationInput";
import { AccordianElement } from "../../../components/AccordianElement/AccordianElement";

export const JobDetailsForm = () => {
  const { currencyDetails } = useSelector((state) => state.user);
  const {
    equipmentType,
    inHandSalary,
    overTimePay,
    salaryType,
    providentFund,
    foodBenefits,
    accomodationProvided,
    payCycle,
    jobLocation
  } = useSelector((state) => state.operatorSearch);
  const [salaryTypeList, setSalaryTypeList] = useState(null);
  const [providentFundList, setProvidentFundList] = useState(null);
  const [payCycleList, setPayCycleList] = useState(null);
  const [foodBenefitList, setFoodBenefitList] = useState(null);
  const [accomodationTypeList, setAccomodationTypeList] = useState(null);
  const dispatch = useDispatch();

  const getSalaryTypeList = async () => {
    try {
      const data = await getSalaryTypes();
      return setSalaryTypeList(data);
    } catch (error) {
      setSalaryTypeList(null);
      return error;
    }
  };
  const getProvidentFundList = async () => {
    try {
      const data = await getProvidentFundTypes();
      return setProvidentFundList(data);
    } catch (error) {
      setProvidentFundList(null);
      return error;
    }
  };

  const getPayCycleList = async () => {
    try {
      const data = await getPayCycleTypes();
      return setPayCycleList(data);
    } catch (error) {
      setPayCycleList(null);
      return error;
    }
  };

  const getAccomodationTypeList = async () => {
    try {
      const data = await getAccomodationTypes();
      return setAccomodationTypeList(data);
    } catch (error) {
      setAccomodationTypeList(null);
      return error;
    }
  };
  const getFoodBenefitsList = async () => {
    try {
      const data = await getFoodBenefitTypes();
      return setFoodBenefitList(data);
    } catch (error) {
      setFoodBenefitList(null);
      return error;
    }
  };

  useEffect(() => {
    (async () => {
      await getSalaryTypeList();
      await getProvidentFundList();
      await getPayCycleList();
      await getFoodBenefitsList();
      await getAccomodationTypeList();
    })();
  }, []);

  return (
    <Formik
      initialValues={{
        _jobTitle: equipmentType ? `${equipmentType.value} Operator` : "",
        _salaryType: salaryType ? salaryType : "",
        _providentFund: providentFund ? providentFund : "",
        _foodBenefits: foodBenefits ? foodBenefits : "",
        _accomodationProvided: accomodationProvided ? accomodationProvided : "",
        _inHandSalary: inHandSalary ? inHandSalary : "",
        _overTimePay: overTimePay ? overTimePay : "",
        _payCycle: payCycle ? payCycle : "",
        _jobLocation: jobLocation ? jobLocation: ""
      }}
      validationSchema={Yup.object().shape({
        _jobTitle: Yup.string().required("Job Title is Required"),
        _salaryType: Yup.string().required("Salary Type is Required"),
        _providentFund: Yup.string().required("Provident Fund is Required"),
        _foodBenefits: Yup.string().required("Food Benefits is Required"),
        _accomodationProvided: Yup.string().required(
          "Accomodation Provided is Required"
        ),
        _inHandSalary: Yup.number().required("In-Hand Salary is Required").min(1000, "Salary must be between 1000 - 999999").max(999999, "Salary must be between 1000 - 999999"),
        _payCycle: Yup.number().required("Pay Cycle is Required"),
        _jobLocation: Yup.object().required("Job Location is Required"),
      })}
      onSubmit={(values) => {
        dispatch(setJobTitle(values._jobTitle));
        dispatch(setSalaryType(values._salaryType));
        dispatch(setFoodBenefits(values._foodBenefits));
        dispatch(setAccomodationProvided(values._accomodationProvided));
        dispatch(setInhandSalary(values._inHandSalary));
        dispatch(setOverTimeSalary(values._overTimePay));
        dispatch(setPayCycle(values._payCycle));
        dispatch(setJobLocation(values._jobLocation));
          dispatch(setProvidentFund(values._providentFund));
        dispatch(increaseStepperIndex());
      }}
      
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <form id="job-details-form" onSubmit={handleSubmit}>
          <Accordion defaultIndex={[0]} allowMultiple>
            <Flex flexDirection="column" gap="0.5em">
            
                  <AccordianElement title="Job Details">
                  <Flex wrap="wrap" gap="1em" justifyContent="space-between">
                    <FormikInput
                      label="Job Title"
                      name="_jobTitle"
                      isRequired={true}
                      placeholder="Enter Job Title ..."
                      maxWidth="450px"
                      maxLength={50}
                    />

                    <Field name="_jobLocation">
                      {({ field }) => (
                        <LocationInput
                          name={field.name}
                          label="Job Location"
                          isRequired={true}
                          value={values._jobLocation ? values._jobLocation.name : ""}
                          onChange={(value) =>
                            setFieldValue("_jobLocation", value)
                          }
                          maxWidth="450px"
                          width="100%"
                          height="52px"
                        />
                      )}
                    </Field>
                 </Flex>
                
              </AccordianElement>
                  <AccordianElement title="In-Hand Salary Details">
                  <Flex wrap="wrap" gap="1em" justifyContent="space-between">
                    <Field name="_salaryType">
                      {({ field }) => {
                        return (
                          <RadioParent
                            name={field.name}
                            options={salaryTypeList}
                            maxWidth="450px"
                            isFormik={true}
                            label="Salary Type"
                            isRequired={true}
                          />
                        );
                      }}
                    </Field>
                    <FormikInput
                      label="In-Hand Salary"
                      name="_inHandSalary"
                      isRequired={true}
                      placeholder="Enter in-hand salary here..."
                      maxWidth="450px"
                      onKeyDown={registerNumbersOnly}
                      maxLength={6}
                      prefixText={
                        currencyDetails.CurrencyValue
                          ? currencyDetails.CurrencyValue
                          : ""
                      }
                    />
                    <FormikInput
                      label="Over Time Rate per hour (if any)"
                      name="_overTimePay"
                      isRequired={false}
                      placeholder="Enter overtime rate/hour here..."
                      maxWidth="450px"
                      onKeyDown={registerNumbersOnly}
                      maxLength={3}
                      prefixText={
                        currencyDetails.CurrencyValue
                          ? currencyDetails.CurrencyValue
                          : ""
                      }
                    />

                    <Field name="_providentFund">
                      {({ field }) => {
                        return (
                          <RadioParent
                          label="PF Provided"
                          name={field.name}
                          isRequired={true}
                            options={providentFundList}
                            maxWidth="450px"
                            isFormik={true}
                          />
                        );
                      }}
                    </Field>
                    <Field name="_payCycle">
                      {({ field }) => {
                        return (
                          <RadioParent
                            name={field.name}
                            options={payCycleList}
                            maxWidth="450px"
                            isFormik={true}
                            label="Pay Cycle(Week)"
                            isRequired={true}
                          />
                        );
                      }}
                    </Field>
                    </Flex>
              </AccordianElement>
                    <AccordianElement title="Food and Accomodation">
                    <Flex wrap="wrap" gap="1em" justifyContent="space-between">
                    <Field name="_foodBenefits">
                      {({ field }) => {
                        return (
                          <RadioParent
                            name={field.name}
                            options={foodBenefitList}
                            maxWidth="450px"
                            isFormik={true}
                            label="Food Benefits"
                            isRequired={true}
                          />
                        );
                      }}
                    </Field>
                    <Field name="_accomodationProvided">
                      {({ field }) => {
                        return (
                          <RadioParent
                            name={field.name}
                            options={accomodationTypeList}
                            maxWidth="450px"
                            isFormik={true}
                            label="Accomodation Provided"
                            isRequired={true}
                          />
                        );
                      }}
                    </Field>
                    </Flex>
                    </AccordianElement>
            </Flex>
          </Accordion>
        </form>
      )}
    </Formik>
  );
};
