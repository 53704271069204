import { OverallRatingIcon } from "../OverallRatingIcon/OverallRatingIcon";
import { Box, Button, Flex, Text } from "@chakra-ui/react";

export const OwnerMarketplaceCard = ({
  MobileNumber,
  ProfilePictureFileReference,
  EnterpriseName,
  UserTypeValue,
  UserRole,
  FirstName,
  LastName,
  OverAllRating,
  isVerified,
  IsPremium = 0,
  BusinessExpertiseValue,
  ConnectResponse,
  isLoading=false,
  onContactOwner=()=>{},
  onHandleClick = () => {},
}) => {
  return (
    <Flex
      w={"100%"}
      maxW={"550px"}
      borderRadius={"4px"}
      border={"1px solid white"}
      boxShadow={"0px 0px 4px 0px #2c2c2c80"}
      padding={"8px 16px"}
      direction={"column"}
      position={"relative"}
      _hover={{
        backgroundColor: "#FFFBF5",
        border: "1px solid #F79C00",
      }}
     
    >
      {Number(IsPremium) === 1 && (
        <img
          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/e9-services/premiumTag.svg`}
          alt="Premium Badge"
          style={{
            width: "20px",
            height: "20px",
            position: "absolute",
            top: "0px",
            right: "160px",
          }}
        />
      )}

      <Flex gap={"12px"}>
        <Flex direction={"column"} w={"80px"} alignItems={"center"} gap={"5px"}>
          <img
            src={
              ProfilePictureFileReference
                ? `${process.env.REACT_APP_CDN_PVT_URL}UserProfilePicture/${ProfilePictureFileReference}`
                : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Avatar.png`
            }
            alt="User Avatar"
            style={{
              width: "66px",
              height: "66px",
              borderRadius: "50%",
            }}
          />

          <Flex w={"46px"}>
            <OverallRatingIcon
              color="#FEFEFE"
              boxShadow="0px 1px 3px 0px #0000001A"
              padding="0.25em 0.50em"
              ratings={OverAllRating}
              textcolour={"black"}
            />
          </Flex>
        </Flex>
        <Flex w={"100%"} direction={"column"} gap={"5px"}  onClick={onHandleClick}  cursor={"pointer"}>
          <Flex justifyContent={"space-between"}>
            <Flex direction={"column"} w={"max-content"} gap={"4px"}>
              <Text fontSize={"18px"} fontWeight={"700"}>
                {EnterpriseName}
              </Text>
              <Text
                fontSize={"14px"}
                fontWeight={"600"}
                textTransform={"uppercase"}
                color={"#2c2c2c80"}
              >
                {FirstName} {LastName}
              </Text>
            </Flex>

            <Flex>
              <Box
                backgroundColor={isVerified === 1 ? "#319F0080" : "#FF7070B2"}
                h={"max-content"}
                borderRadius={"100px"}
                padding={"3px 16px"}
              >
                <Text fontSize={"12px"} fontWeight={"600"} color={"#ffff"}>
                  {isVerified === 1 ? "Verified" : "Not Verified"}
                </Text>
              </Box>
            </Flex>
          </Flex>

          <Flex
            borderTop={"1px solid #2c2c2c50"}
            direction={"column"}
            paddingTop={"5px"}
            color={"#959596"}
            fontSize={"16px"}
            fontWeight={"600"}
          >
            <Flex alignItems={"center"} gap={"8px"}>
              <img
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/e9-services/verifiedTick.svg`}
                alt="verified"
                style={{
                  width: "14px",
                  height: "14px",
                }}
              />

              <p>
                {UserTypeValue ? UserTypeValue : "NA"} | role:{" "}
                {UserRole ? UserRole : "NA"}
              </p>
            </Flex>

            <Flex alignItems={"center"} gap={"8px"}>
              <img
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/e9-services/bag.svg`}
                alt="bag"
                style={{
                  width: "14px",
                  height: "14px",
                }}
              />
              <p>
                business expertise:
                {BusinessExpertiseValue ? BusinessExpertiseValue : "NA"}
              </p>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Box>
          <Text color={"#002961"} fontSize={"16px"} fontWeight={"600"}>
            {MobileNumber}
          </Text>
        </Box>
        <Button
        variant={"outline"}
        isLoading={isLoading}
        style={{
          border: ConnectResponse?.ResponseTypeId === 2 || ConnectResponse?.ResponseTypeId === 3 ? 'none' : (ConnectResponse?.ResponseTypeId === 1 ? '1px solid #319F00' : '1px solid #F79C00'),
          color:ConnectResponse?.ResponseTypeId===1 ? "#319F00":"#F79C00",
          cursor: ConnectResponse?.ResponseTypeId === 2 || ConnectResponse?.ResponseTypeId === 3 ? 'auto':"pointer",
          fontSize:"14px",
          fontWeight:"600",
          // padding:"5px 10px",
          borderRadius:"4px"
        }}
        onClick={onContactOwner}
        
        >
        {Object.keys(ConnectResponse).length === 0 || ConnectResponse.ResponseTypeId === 4 ? 'Contact owner' : ConnectResponse?.ResponseTypeValue}
        </Button>
      </Flex>
    </Flex>
  );
};
