import "./DashboardFallbackCard.css";

export const DashboardFallbackCard = () => {
  return (
    <div className="dashboard-fallback-card">
      <p className="dashboard-fallback-card__description">
        Service not Available{" "}
      </p>
    </div>
  );
};
