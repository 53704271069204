import React, { useEffect, useState } from "react";
import fetchHandler from "../../../../webapp/services/fetchHandler";
import { API_CONSTANTS } from "../../../../constants/apiConstants";
import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import { MaterialDetailCard } from "../../../../webapp/features/MaterialMarketplace/screens/MaterialDetailCard";
import { useNavigate } from "react-router-dom";
import { openQrModal } from "../../../../webapp/features/dashboard/slice/dashboardSlice";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";
import { useDispatch } from "react-redux";
import { QrPopUp } from "../../../../webapp/components/ScanQrCodeModal/QrPopUp";
import logo from "../../../../assets/logo/equip9_logo_dark.png"
import BrandHeader from "../screens/BrandHeader";

const MaterialTypesMarketplace = () => {
  const [materialType, setMaterialType] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getMaterialTypes = async () => {
    try {
      const { data } = await fetchHandler(
        "/deferredLogin/materialTypes",
        {},
        "",
        "",
        API_CONSTANTS.HTTP_GET,
        { isAuthRequired: false }
      );
      if (data?.Status === 200) {
        setMaterialType(data?.Data?.MaterialTypeList);
      }
    } catch (error) {}
  };

  const handleNavigation = (MaterialTypeValue, MaterialTypeId) => {

    const materialType = MaterialTypeValue.toLowerCase();
    switch (MaterialTypeValue.toLowerCase()) {
        case "spare parts":
            navigate(`/deferred/marketplace/materials/spare-parts/${MaterialTypeValue}/${convertToBase64(MaterialTypeId)}`);
            break;
        case "oil":
            navigate(`/deferred/marketplace/materials/oil/${MaterialTypeValue}/${convertToBase64(MaterialTypeId)}`);
            break;
        case "concrete":
            navigate(`/deferred/marketplace/materials/concrete/${MaterialTypeValue}/${convertToBase64(MaterialTypeId)}`);
            break;
        case "generators":
            navigate(`/deferred/marketplace/materials/generators/${MaterialTypeValue}/${convertToBase64(MaterialTypeId)}`);
            break;
        case "safety equipment":
            navigate(`/deferred/marketplace/materials/safety-equipment/${MaterialTypeValue}/${convertToBase64(MaterialTypeId)}`);
            break;
        default:
            dispatch(openQrModal());
    }
    
    
  
  };
  useEffect(() => {
    getMaterialTypes();
  }, []);

  return (
    <>
    <BrandHeader />

    <Box padding="2em 2em">
        <QrPopUp />
      <Flex alignItems="center" w="100%" direction="column">
        <Text
          alignSelf="flex-start"
          color="#2C2C2C"
          fontSize="28px"
          fontWeight="700"
        >
          Material Marketplace
        </Text>
        <Divider borderColor="#011f6b" borderWidth="0.1em" />
      </Flex>

      <Flex
        gap="2em"
        flexDirection="row"
        flexWrap="wrap"
        padding="1em 0"
        cursor="pointer"
      >
        {materialType ? (
          materialType.map((material) => (
            <Flex
              gap="2em"
              onClick={() => handleNavigation(material?.MaterialTypeValue , material?.MaterialTypeId)}
              maxW="500px"
              maxH="175px"
              minH="165px"
              minW="450px"
              alignItems="center"
              justifyContent="center"
              bg="#FEFEFE"
              border="1px solid #E3E5E8"
              borderRadius="8px"
              boxShadow="0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)"
            >
              <Box maxH="140px" maxW="110px" minH="120px">
                <img
                  src={`${process.env.REACT_APP_CDN_PVT_URL}MobileAppAssets/Material/${material?.MaterialTypeIconFileRef}`}
                  style={{ height: "100%", width: "100%" }}
                />
              </Box>

              <Flex
                direction="column"
                alignItems="center"
                justifyContent="center"
                gap="1em"
              >
                <Text color="#002961" fontSize="24px" fontWeight="700">
                  {material?.MaterialTypeValue}
                </Text>
                <Divider
                  borderColor="#011f6b"
                  borderWidth="0.1em"
                  maxW="70px"
                  minW="60px"
                />
                <Text color="#959596" fontSize="16px" fontWeight="700">
                  {material?.MaterialCount} + Listing
                </Text>
              </Flex>
            </Flex>
          ))
        ) : (
          <p>No Material Found</p>
        )}
      </Flex>
    </Box>

    </>
  );
};

export default MaterialTypesMarketplace;
