import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Flex,
  Image,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { ReactSVG } from "react-svg";

import logo from "../../../assets/logo/equip9_logo_dark.png";
import { SideMenuProfile } from "../SideMenuProfile/SideMenuProfile";
import { sideBarOptionsByRole } from "../../constants/sidebar";
import { openQrModal } from "../../features/dashboard/slice/dashboardSlice";
import { V5SidebarItem } from "../V5SidebarItem/V5SidebarItem";
import { logoutUser } from "../../features/global/user/slice/userSlice";
import { resetDocumentFlow } from "../../features/documentFlow/slice/docFlowSlice";
import { setAddressModal } from "../../features/subscription/slice/subscriptionSlice";
import UpdateAdressModal from "../../features/subscription/screens/SubscriptionType/UpdateAdressModal";
import { CtaModal } from "../Modal/CtaModal/CtaModal";

export const V5Sidebar = () => {
  const { UserTypeValue, BusinessTypeValue, accountDetails } = useSelector(
    (state) => state.user
  );
  const { defaultLanguage } = useSelector((state) => state.masterData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const onCloseLogoutModal = () => setIsLogoutModalOpen(false);
  const cancelRef = useRef();
  const toast = useToast();
  const { addressModal } = useSelector((state) => state.subscription);


  const handleLogoutClick = () => {
    setIsLogoutModalOpen(true);
  };

  const confirmLogout = () => {
    setIsLogoutModalOpen(false);
    dispatch(logoutUser());
    dispatch(resetDocumentFlow());
    navigate("/");
  };

  const handleVerification = (name) => {  
    if (
      (name.toString().toLowerCase() === "my_leads".toLocaleLowerCase() ||
        name.toString().toLowerCase() ===
          "Buy_New_Subscription".toLocaleLowerCase()) &&
     ( UserTypeValue === "Company" ) &&
     ( !accountDetails?.CompanyDetails?.TaxNumber )
    ) {
      toast({
        status: "error",
        description: "Please update GST Number to access the feature.",
        position: "top-right",
        isClosable: true,
      });

      return true;
    }

    if (
      (name.toString().toLowerCase() === "my_leads".toLocaleLowerCase() || name.toString().toLowerCase() === "requirements".toLocaleLowerCase() ||
        name.toString().toLowerCase() ===
          "Buy_New_Subscription".toLocaleLowerCase()) &&
      UserTypeValue === "Company" &&
      !accountDetails?.CompanyDetails?.IsE9VerifiedTaxNumber
    ) {
      toast({
        status: "error",
        description:
          "Company verification under review for the user verified from admin",
        position: "top-right",
        isClosable: true,
      });
      return true;
    }

    if (
      name.toString().toLowerCase() ===
        "purchased_subscriptions".toLocaleLowerCase() &&
      UserTypeValue === "Company" &&
      !accountDetails.UserDetails.ResidenceAddress
    ) {
      dispatch(setAddressModal(true));
      return true;
    }

    return false;
  };

  const handleMenuItemClick = (name, route) => {
    const isNotVerified = handleVerification(name);

    if (name.toString().toLowerCase() === "Logout".toLowerCase()) {
      return handleLogoutClick();
    }

    if (isNotVerified) {
      return;
    } else {
      if (route) {
        navigate(route);
      } else {
        dispatch(openQrModal());
      }
    }
  };

  const renderBusinessOwnerSidebaroptions = useCallback(() => {
    const sidebarData = sideBarOptionsByRole(
      BusinessTypeValue,
      UserTypeValue
    ).map(({ name, route, subMenu, iconhover }, index) =>
      subMenu && subMenu.length > 0 ? (
        <AccordionItem key={uuid()} border="none">
          <h2>
            <AccordionButton
              height="60px"
              _hover={{
                backgroundColor: "#fffbf5",
                color: "#f79c00",
                fontWeight: "600",
              }}
            >
              <Flex alignItems="center" gap="1em">
                <ReactSVG src={iconhover} />
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  {defaultLanguage[name] ? defaultLanguage[name] : name}
                  <AccordionIcon />
                </Flex>
              </Flex>
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <Flex flexDirection="column" gap="1em">
              {subMenu.map(({ name, route }) => (
                <V5SidebarItem
                  key={uuid()}
                  onClick={() => handleMenuItemClick(name, route)}
                >
                  {defaultLanguage[name] ? defaultLanguage[name] : name}
                </V5SidebarItem>
              ))}
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      ) : (
        <V5SidebarItem
          key={uuid()}
          onClick={() => handleMenuItemClick(name, route)}
        >
          <ReactSVG src={iconhover} alt="icon" />
          {defaultLanguage[name] ? defaultLanguage[name] : name}
        </V5SidebarItem>
      )
    );
    return sidebarData;
  }, [UserTypeValue, BusinessTypeValue, defaultLanguage, accountDetails]);

  const year = new Date().getFullYear();

  return (
    <>
      <UpdateAdressModal isOpen={addressModal} />
      <CtaModal
      isOpen={isLogoutModalOpen}
        header={
          <Flex justifyContent="center">
            <ReactSVG
              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Logout.svg`}
            />
          </Flex>
        }
        body={
          <Flex justifyContent="center">
        <Text fontWeight="700" fontSize="20px">Are you sure you want to log out</Text>
        </Flex>
      }
        secondayButtonText="No"
        primaryButtonText="Yes"
        onPrimaryButtonClick={confirmLogout}
        onCloseIconClick={onCloseLogoutModal}
        onSecondayButtonClick={onCloseLogoutModal}
      />

      <Flex
        height="100%"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Flex
            padding="1.5rem 1rem 0 1rem"
            justifyContent="center"
            alignItems="center"
          >
            <Image src={logo} alt="logo" height="48px" width="80px" />
          </Flex>
          <SideMenuProfile />
        </Box>

        <Accordion w="100%" allowMultiple={true}>
          <Flex
            flexDirection="column"
            maxH="calc(100vh - 240px)"
            overflowY="scroll"
            gap="1em"
          >
            {renderBusinessOwnerSidebaroptions()}
          </Flex>
        </Accordion>
        <Box
          w="100%"
          bgColor="#f7f7f7"
          marginTop="2px"
          fontWeight={700}
          textAlign="center"
        >
          <p>
            {" "}
            © {year} Equip9<span>&#8482;</span> {process.env.REACT_APP_VERSION}
          </p>
        </Box>
      </Flex>
    </>
  );
};
