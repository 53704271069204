import { CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Divider, Flex, Heading, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import loginIcon from "../../../assets/webapp/login/loginIcon.svg"

export const LoginPopUp = ({ isModalOpen = true, closeModal = () => {}, onLoginClick = () => {}, onRegisterClick = () => {} }) => {
  return (
    <Modal isCentered isOpen={isModalOpen}>
      <ModalOverlay />
      <ModalContent>
        <Box position={"absolute"} top={"-20px"} right={"15px"}>
          <Button
            onClick={closeModal}
            boxShadow="0px 1.5px 3px 0px #0000000F"
            borderRadius={"50%"}
            backgroundColor={"white"}
            height={"42px"}
            width={"42px"}
          >
            <CloseIcon width={"9px"} stroke={"#141619"} />
          </Button>
        </Box>
        <ModalHeader>
          <Flex
            alignItems="center"
            justifyContent="center"
            direction="column"
            gap="1em"
          >
            <Heading fontSize="22px" fontWeight="700" color="#2C2C2C">
              Welcome To Equip{" "}
              <Text as="span" color="#F79C00">
                9TM
              </Text>
            </Heading>
            <img
              src={loginIcon}
              style={{ maxHeight: "65px", maxWidth: "65px" }}
            />
            <Text color="#2C2C2C" fontSize="20px" fontWeight="700">
              Please Login in or Register to view all details
            </Text>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Box marginBottom="1em">
            <Flex flexDirection="column" alignItems="center" gap="1em">
              <Flex
                className="demoj"
                justifyContent={"center"}
                alignItems={"center"}
                w="100%"
              >
                <Button
                  w="80%"
                  variant="gradient"
                  fontSize="18px"
                  fontWeight="normal"
                  onClick={onRegisterClick}
                >
                  Create an account
                </Button>
              </Flex>

              <Flex gap="0.5em" w="100%">
                <Flex w="45%" alignItems="center">
                  <Divider borderColor="#011f6b" borderWidth="0.1em" />
                </Flex>
                <Text fontSize="xl" color="#011f6b" fontWeight="semibold">
                  OR
                </Text>
                <Flex w="45%" alignItems="center">
                  <Divider borderColor="#011f6b" borderWidth="0.1em" />
                </Flex>
              </Flex>

              <Flex justifyContent={"center"} alignItems={"center"} w="100%">
                <Button
                  w="80%"
                  bgColor="#FFFFFF"
                  border="2px solid #f79c00"
                  fontSize="18px"
                  fontWeight="normal"
                  transition="box-shadow 0.2s"
                  color="#f79c00"
                  _hover={{
                    bgColor: "#FFFFFF",
                    boxShadow: "0px 4px 4px 0px #F79C00CC",
                  }}
                  onClick={onLoginClick}
                >
                  Continue to Login
                </Button>
              </Flex>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
