import { createSlice } from "@reduxjs/toolkit";
import { getE9ServiceCategoryThunk } from "../thunks/getE9ServiceCategoryDetailsThunk";

export const e9ServiceSlice = createSlice({
  name: "e9Services",
  initialState: {
    categoryList: null,
    selectedCategory: null,
    isLoading: false,
    isError: false,
    errorMessage: null,
  },
  reducers: {
    setCategorytList: (state, { payload }) => {
      state.categoryList = payload;
    },
    setSelectedCategory: (state, { payload }) => {
      state.selectedCategory = payload;
    },
    resetSelectedE9Service: (state) => {
      state.selectedCategory = null;
    },
    resetE9Services: (state) => {
      state.categoryList = null;
      state.selectedCategory = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getE9ServiceCategoryThunk.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getE9ServiceCategoryThunk.fulfilled, (state, { payload }) => {
        state.categoryList = payload;
        state.isError = false;
        state.isLoading = false;
      });
  },
});

export const {
  setCategorytList,
  setSelectedCategory,
  resetE9Services,
  resetSelectedE9Service,
} = e9ServiceSlice.actions;

export default e9ServiceSlice.reducer;
