import {
  Flex,
  Container,
  Heading,
  Button,
  Divider,
  AbsoluteCenter,
  Box,
  Text,
} from "@chakra-ui/react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ImageCarouselImprove } from "../../../../components/ImageCarousel/imageCarousel/ImageCarouselimpriove";
import ARImage from "../../../../../assets/feature-carousel/Ar-image_office.png";
import RentSellImage from "../../../../../assets/feature-carousel/Sell-your_eqip.png";
import FindEquipmentImage from "../../../../../assets/feature-carousel/Free_registration.png";
import nextJobImage from "../../../../../assets/feature-carousel/Next-Job2.png";
import "./Welcome.css";
import { getAllLanguageJsonThunk } from "../../../global/mtData/thunk/getAllLanguagesThunk";
import { setSelectedCountryThunk } from "../../../global/appConfig/thunk/setSelectedCountryThunk";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { V5AdDividerLayout } from "../../../../layout/v5AdDividerLayout/V5AdDividerLayout";
import { NAVIGATION_CONSTANTS } from "../../../../../constants/naigationContants";

const slides = [
  {
    url: ARImage,
    link: "https://dev.equip9.com/",
  },
  {
    url: RentSellImage,
    link: "https://dev.equip9.com/",
  },
  {
    url: FindEquipmentImage,
    link: "https://dev.equip9.com/",
  },
  {
    url: nextJobImage,
    link: "https://dev.equip9.com/",
  },
];

export const V5Welcome = () => {
  const { defaultLanguage } = useSelector((state) => state.masterData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const nextUrlParam = searchParams.get(
    NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID
  );


  useEffect(() => {
    if (!defaultLanguage) {
      dispatch(setSelectedCountryThunk("91"));
      dispatch(getAllLanguageJsonThunk("english.json"));
    }
  }, [!defaultLanguage]);

  return (
    <V5AdDividerLayout>
      <Flex>
        <Container
          boxShadow={"0px 12px 20px 12px #0000000A"}
          borderRadius={"10px"}
          padding={"80px 31px 80px 31px"}
        >
          <Box maxH="32vh">
            <ImageCarouselImprove images={slides} />
          </Box>

          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            marginTop="0.5em"
          >
            <Heading>{defaultLanguage?.Welcome_to_EQUIP9}</Heading>
          </Flex>
          <Flex flexDirection="column" gap="1.5em" mt="3em">
          
          <Flex justifyContent={"center"} alignItems={"center"}>
              <Button
                w="80%"
                variant="gradient"
                fontSize="18px"
                fontWeight="normal"
                onClick={() =>
                  navigate(
                    nextUrlParam
                      ? `/register/select-business-type?${NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID}=${nextUrlParam}`
                      : "/register/select-business-type"
                  )
                }
              >
                {defaultLanguage?.Create_an_Account}
              </Button>
            </Flex>

            <Flex gap="0.5em">
              <Flex w="45%" alignItems="center">
                <Divider borderColor="#011f6b" borderWidth="0.1em" />
              </Flex>
              <Text fontSize="xl" color="#011f6b" fontWeight="semibold">
                OR
              </Text>
              <Flex w="45%" alignItems="center">
                <Divider borderColor="#011f6b" borderWidth="0.1em" />
              </Flex>
            </Flex>

            <Flex justifyContent={"center"} alignItems={"center"}>
              <Button
                w="80%"
                bgColor="#FFFFFF"
                border="2px solid #f79c00"
                fontSize="18px"
                fontWeight="normal"
                transition="box-shadow 0.2s"
                color="#f79c00"
                _hover={{
                  bgColor: "#FFFFFF",
                  boxShadow: "0px 4px 4px 0px #F79C00CC",
                }}
                onClick={() =>
                  navigate(
                    nextUrlParam
                      ? `/login/login-form?${NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID}=${nextUrlParam}`
                      : "/login/login-form"
                  )
                }
              >
                {defaultLanguage?.Continue_to_Login}
              </Button>
            </Flex>
          </Flex>
        </Container>
      </Flex>
    </V5AdDividerLayout>
  );
};
