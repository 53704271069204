import {Flex } from "@chakra-ui/react"
import { v4 as uuid } from "uuid";
import useWindowWidth from "../../../sharedComponent/isMobile/CheckMobile";

export const ImageSlider = ({slideDirection, imageGap="1em", slideHeight, imageArray=[]}) => {
  const isMobile=useWindowWidth();
  const mobileStyle = {
    gap: '0.5em',
    height: '150px', // Example: adjust based on your design needs
};

// Styles for desktop
const desktopStyle = {
    gap: imageGap,
    height: slideHeight,
};

const imageStyle = {
    borderRadius: '4px',
    maxWidth: '100%',
    height: 'auto',
};
    return(
      <>
      {isMobile <768 ?(<>
        <Flex 
            flexDirection={slideDirection} 
            maxWidth="100vw" 
            width="100%" 
            overflowX="scroll"
            style={isMobile ? mobileStyle : desktopStyle}
        >
            {imageArray.length > 0 && imageArray.map((imageUrl) => (
                <img key={uuid()} src={imageUrl} alt="Slider Image" style={imageStyle} />
            ))}
        </Flex>
      
      </>):(<>
        <Flex flexDirection={slideDirection} height={slideHeight} gap={imageGap} maxWidth="100vw" width="100%" overflowX="scroll">
     
     {imageArray && imageArray.length > 0 ? imageArray.map((imageUrl) => <img key={uuid()} style={{borderRadius: "4px"}}src={imageUrl} alt={imageUrl}/>) : <></>}
     
   </Flex>
      </>)}
      
      </>

   
        )
}