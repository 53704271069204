import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  INDIVIDUAL_CALLS,
  INDIVIDUAL_ROUTE_CALL_PROMISES,
  generateIndividualDocumentRouting,
} from "../promises/individualDataPromise";
import {
  OWNER_INDIVIDUAL_CALLS,
  OWNER_INDIVIDUAL_ROUTE_CALL_PROMISES,
  generateOwnerIndividualRouting,
} from "../promises/companyIndividualPromise";
import {
  OWNER_COMPANY_CALLS,
  OWNER_COMPANY_ROUTE_CALL_PROMISES,
  generateOwnerCompanyRouting,
} from "../promises/ownerCompanyPromises";
import {
  COMPANY_CALLS,
  COMPANY_ROUTE_CALL_PROMISES,
  generateMaintenanceCompanyDocumentRouting
} from '../promises/maintenacePatnerCompany';
import { getUserDetails, updateUserLanguage } from "../../../services/login/login";
import { convertToFormData } from "../../../helpers/dataConversion/convertToFormData";
import { setLanguage } from "../../global/appConfig/slice/appConfigSlice";


export const createDocumentFlowThunk = createAsyncThunk(
  "/login/createDocumentFlow",
  async (_, { getState, rejectWithValue,dispatch}) => {
    const loginData = getState().login;
    const appConfig = getState().appConfig;
    const userData = getState().user;
    const languageID = JSON.parse(localStorage.getItem("E9_APP_LANG"));
    let documentObject = {};
    let routeArray = [];

    try {

      const response = await updateUserLanguage(languageID ? languageID?.LanguageOptionTypeId : 2);

      const { data } = await getUserDetails();

      if (data.Status) {

        const {UserLanguageDetails} = data?.Data ;

        dispatch(setLanguage(UserLanguageDetails));

        const { E9UserRole, ProfilePicture } = data.Data;
        documentObject.E9UserRole = E9UserRole;
        documentObject.ProfilePicture = ProfilePicture;
        // documentObject.BusinessIdentification = BusinessIdentification;
        if (loginData.selectedAccount.UserTypeValue === "Company") {

          documentObject.Designation =
            data.CompanyDetails.CompanyDesignationTypeName;
          documentObject.BusinessIdentificationDocument = data.CompanyDetails.TaxNumber
        }

        // INDIVIDUAL PROFILE PROFILE FILTERATION
        if (
          loginData.selectedAccount.UserTypeValue === "Individual" &&
          (loginData.selectedAccount.BusinessTypeValue === "Operator Partner" ||
            loginData.selectedAccount.BusinessTypeValue ===
            "Maintenance Partner")
        ) {
          try {
            const individualDocumentResponse = await INDIVIDUAL_CALLS(
              INDIVIDUAL_ROUTE_CALL_PROMISES,
              // token
            );
            const { expertiseDocument, verificationDocument, licenses } =
              individualDocumentResponse;
            documentObject.expertiseDocument = expertiseDocument;
            documentObject.verificationDocument = verificationDocument;
            documentObject.licenses = licenses;
            routeArray = generateIndividualDocumentRouting(documentObject);
          } catch (error) { }
        }
        else if (
          loginData.selectedAccount.UserTypeValue === "Individual" &&
          (loginData.selectedAccount.BusinessTypeValue === "Business Owner" ||
            loginData.selectedAccount.BusinessTypeValue === "Equipment Owner")
        ) {
          try {
            const individualCompanyDocumentResponse =
              await OWNER_INDIVIDUAL_CALLS(
                OWNER_INDIVIDUAL_ROUTE_CALL_PROMISES,
                // token
              );
            const { expertiseDocument, verificationDocument } =
              individualCompanyDocumentResponse;
            documentObject.expertiseDocument = expertiseDocument;
            documentObject.verificationDocument = verificationDocument;
            routeArray = generateOwnerIndividualRouting(documentObject);
          } catch (error) { }
        }
        else if (
          loginData.selectedAccount.UserTypeValue === "Company" &&
          loginData.selectedAccount.BusinessTypeValue === "Maintenance Partner"
        ) {
          try {
            const CompanyDocumentResponse =
              await COMPANY_CALLS(
                COMPANY_ROUTE_CALL_PROMISES,
                // token
              );
            const { expertiseDocument, verificationDocument } =
              CompanyDocumentResponse;
            documentObject.expertiseDocument = expertiseDocument;
            documentObject.verificationDocument = verificationDocument;
            routeArray = generateMaintenanceCompanyDocumentRouting(documentObject);
          } catch (error) { }
        } else {
          try {
            const ownerCompanyDocumentResponse = await OWNER_COMPANY_CALLS(
              OWNER_COMPANY_ROUTE_CALL_PROMISES,
              // token
            );
            documentObject.verificationDocument =
              ownerCompanyDocumentResponse.verificationDocument;
            routeArray = generateOwnerCompanyRouting(documentObject);
          } catch (error) { }
        }
        return routeArray;
      } else {
        rejectWithValue(data.Message.FailMessage);
      }
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);
