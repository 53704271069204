import React, { useEffect } from "react";
import { V5SidebarLayout } from "../../../layout/V5SidebarLayout/V5SidebarLayout";
import CommonHeader from "../../../components/CommonHeader/CommonHeader";
import {
  Box,
  Button,
  Flex,
  Skeleton,
  SkeletonText,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { getMyPremiumThunk } from "../thunks/getMyPremiumThunk";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BrandLoader } from "../../../components/loaders/brandLoader/BrandLoader";
import { openQrModal } from "../../dashboard/slice/dashboardSlice";

const MyPremium = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { myPremiumDetails, isLoading } = useSelector((state) => state.premium);
  const skelatonItems = 7;

  useEffect(() => {
    dispatch(getMyPremiumThunk());
  }, []);

  const handleRedeem = (serviceTypeValue) => {

    switch (serviceTypeValue.toLowerCase()) {
      case "Used Equipment".toLowerCase():
        navigate("/marketplace/MarketPlaceUEM");

        break;

      case "Equipment Listing".toLowerCase():
        navigate("/equipment-store");
        break;

      case "Maintenance Search".toLowerCase():
        navigate("/near-me/MarketPlaceMP");
        break;

      case "Material Listing".toLowerCase():
        navigate("/materials-type-store-list");
        break;

      case "Operator Search".toLowerCase():
        navigate("/near-me/MarketPlaceOP");
        break;

      case "Dealership Listing".toLowerCase():
        navigate("/my-business");
        break;

      case "Rental Equipment".toLowerCase():
        navigate("/marketplace/MarketPlaceRM");
        break;

      case "Material contact".toLowerCase():
        navigate("/materials-type-store-list");
        break;
      case "Owner Search".toLowerCase():
        navigate("/partners-nearby");
        break;
      case "Requirement Connect".toLowerCase():
        navigate("/industry-requirement");
        break;

      default:
        dispatch(openQrModal());
        break;
    }
  };
  return (
    <V5SidebarLayout>
      {isLoading ? (
        <Flex>
          <BrandLoader />
        </Flex>
      ) : (
        <Box>
          <CommonHeader
            header="My Premium"
            subHeader="Redeem Your Premium Services Now!"
          />

          <Flex
            boxShadow="0px 0px 4.5px 2px #00000040"
            position="relative"
            margin="1em 2em"
            height="80px"
            backgroundColor="#FEF8EC"
            borderRadius="4px"
            gap="1em"
            alignItems="center"
            padding="0em 1em"
          >
            <Box height="40px" alignSelf="flex-start">
              <img
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/premium/myPremium.svg`}
                style={{ height: "100%", width: "100%" }}
              />
            </Box>

            <Flex direction="column">
              <Box>
                <Text color="#F79C00" fontSize="20px" fontWeight="700">
                  {myPremiumDetails?.PaymentPlanValue} PREMIUM{" "}
                  <Text
                    as="span"
                    color="#767676"
                    fontSize="16px"
                    fontWeight="600"
                  >
                    Current Plan
                  </Text>
                </Text>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="1em"
              >
                <Text color="#2C2C2C" fontSize="14px" fontWeight="400">
                  Purchased On{" "}
                  <Text
                    as="span"
                    color="#2C2C2C"
                    fontSize="16px"
                    fontWeight="700"
                  >
                    {myPremiumDetails?.PlanPurchasedDate}
                  </Text>
                </Text>

                <Text color="#2C2C2C" fontSize="14px" fontWeight="400">
                  Expires On{" "}
                  <Text
                    as="span"
                    color="#2C2C2C"
                    fontSize="16px"
                    fontWeight="700"
                  >
                    {myPremiumDetails?.PlanExpiryDate}
                  </Text>
                </Text>
              </Box>
            </Flex>

            <Box position="absolute" right="15px" bottom="0">
              <Flex
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  height="20px"
                  width="20px"
                  cursor="pointer"
                  onClick={() => {
                    myPremiumDetails?.InvoiceName
                      ? window.open(
                          `${process.env.REACT_APP_CDN_PVT_URL}${myPremiumDetails?.InvoiceName}`,
                          "_blank"
                        )
                      : toast({
                          title: "No Invoice Available",
                          position: "top-right",
                          status: "error",
                          isClosable: true,
                        });
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/premium/invoice.svg`}
                    style={{ height: "100%" }}
                  />
                </Box>
                <Text color="#2C2C2C" fontSize="16px" fontWeight="600">
                  Invoice
                </Text>
              </Flex>
            </Box>
          </Flex>

          <Flex margin="1em 5em">
            <table
              style={{
                width: "100%",
                borderRadius: "4px",
              }}
            >
              <thead
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  textAlign: "left",
                  color: "#1E3264",
                  paddingBottom: "10px",
                  // backgroundColor: "#ffffff",
                }}
              >
                <th
                  style={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    paddingLeft: "20px",
                    width: "30%",
                    textAlign: "center",
                    border: "1px solid #FFD48A",
                  }}
                >
                  E9 Services
                </th>
                <th
                  style={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    width: "30%",
                    textAlign: "center",
                    border: "1px solid #FFD48A",
                  }}
                >
                  Remaining Services / Total Services
                </th>

                <th
                  style={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    width: "30%",
                    textAlign: "center",
                    border: "1px solid #FFD48A",
                  }}
                >
                  Redeem
                </th>
              </thead>
              <tbody style={{ maxWidth: "500px" }}>
                {myPremiumDetails &&
                myPremiumDetails?.MyPremiumDetails?.length > 0 ? (
                  myPremiumDetails?.MyPremiumDetails?.map((premium, index) => (
                    <tr
                      key={index}
                      style={{ border: "none", position: "relative" }}
                    >
                      <td colSpan="3" style={{ padding: "0" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "20px",
                            marginBottom: "20px",
                            border:
                              premium?.RemainingCount <= 1
                                ? "1px solid #FF7070"
                                : "1px solid #E3E5E8",
                            backgroundColor:
                              premium?.RemainingCount <= 1
                                ? "#FBF5F5"
                                : "transparent",
                            borderRadius: "4px",
                            fontSize: "16px",
                          }}
                        >
                          <div style={{ width: "30%", paddingLeft: "20px" }}>
                            <Text
                              color="#002961"
                              fontSize="18px"
                              fontWeight="600"
                            >
                              {premium?.E9Services}
                            </Text>
                          </div>
                          <div
                            style={{
                              width: "30%",
                              textAlign: "center",
                              paddingLeft: "20px",
                            }}
                          >
                            <Text
                              color="#002961"
                              fontSize="20px"
                              fontWeight="600"
                            >
                              <Text
                                as="span"
                                color={
                                  premium?.RemainingCount >= 2
                                    ? "#319F00"
                                    : premium?.RemainingCount <= 1
                                    ? "#D60000"
                                    : "#002961"
                                }
                              >
                                {premium?.RemainingCount}
                              </Text>{" "}
                              / {premium?.TotalCount}
                            </Text>
                          </div>
                          <div
                            style={{
                              width: "30%",
                              textAlign: "center",
                              paddingLeft: "20px",
                            }}
                          >
                            {premium?.RemainingCount <= 0 ? (
                              <Button
                                border="1px solid #002961"
                                bgColor="#ffff"
                                color="#002961"
                                onClick={() =>
                                  navigate("/subscription/subscription-type")
                                }
                              >
                                Buy Now
                              </Button>
                            ) : (
                              <Button
                                variant="gradient"
                                onClick={() => {
                                  handleRedeem(premium?.E9Services);
                                }}
                              >
                                Redeem
                              </Button>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <Text>No Premium Purchased Yet</Text>
                )}
              </tbody>
            </table>
          </Flex>
        </Box>
      )}
    </V5SidebarLayout>
  );
};

export default MyPremium;
