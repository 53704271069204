import {
  Box,
  Button,
  Flex,
  Image,
  ModalFooter,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { API_CONSTANTS } from "../../../../constants/apiConstants";
import { GetDaysFromDates } from "../../../helpers/getDaysFromDates/getDaysFromDates";
import shareIcon from "../../../../assets/webapp/dashboard/share.svg";
import { Like } from "../../../components/Like/Like";
import defaultImage from "../../../../assets/webapp/marketplace/defaultAssetImage.png";
import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { subString } from "../../../helpers/dataConversion/substring";

export const CommunityPost = ({
  Thumbnail,
  ContentTitle,
  ContentValue,
  LikesCount,
  ViewCount,
  CreatedDateTime,
  IsLiked,
  IsPromoted,
  IsE9Learning,
  Posted,
  CommunityVideoFileRef,
  isMyPost = false,
  handleViewPost = () => {},
  handleLikeUnlikePost = () => {},
  onEditClick = () => {},
  onDeleteClick = () => {},
  handleViewCount=()=>{}
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleViewVideo = () => {
    handleViewCount();
    setIsPopupOpen(true);
  };

  const onClose = () => {
    setIsPopupOpen(false);
  };

  const daysFromDates = GetDaysFromDates(CreatedDateTime).replace("-", "");
  const daysAgo =  daysFromDates === "today" ? 0 : parseInt(daysFromDates.replace(" days ago", ""));



  return (
    <>
      <Modal isCentered isOpen={isPopupOpen}>
        <ModalOverlay />
        <ModalContent>
          <Box position={"absolute"} top={"-20px"} right={"15px"}>
            <Button
              onClick={onClose}
              boxShadow="0px 1.5px 3px 0px #0000000F"
              borderRadius={"50%"}
              backgroundColor={"white"}
              height={"42px"}
              width={"42px"}
            >
              <CloseIcon width={"9px"} stroke={"#141619"} />
            </Button>
          </Box>
          <ModalHeader textAlign={"center"}></ModalHeader>
          <ModalBody maxH={"400px"}>
            <video
              className=""
              controls
              style={{
                height: "100%",
                width: "100%",
                maxHeight: "350px",
                maxWidth: "100%",
                objectFit: "contain",
              }}
            >
              <source
                src={`${process.env.REACT_APP_CDN_PVT_URL}${CommunityVideoFileRef}`}
                type="video/mp4"
              />
            </video>
          </ModalBody>
          <ModalFooter textAlign={"center"}></ModalFooter>
        </ModalContent>
      </Modal>

      <Flex
        height={"100%"}
        width={"100%"}
        maxW={"360px"}
        // maxH={"248px"}
        minW={"360px"}
        direction={"column"}
        shadow={"0px 10px 30px 0px #2C2C2C14"}
        borderRadius={"4px"}
        // overflow={"hidden"}
      >
        <Box
          maxH={"140px"}
          minH={"140px"}
          position="relative"
          backgroundRepeat="no-repeat"
          backgroundWidth="100%"
          backgroundSize="cover"
          borderRadius={"4px 4px 0 0"}
          backgroundImage={
            Thumbnail
              ? Thumbnail.toLowerCase().includes("thumb")
                ? `url(${process.env.REACT_APP_CDN_STATIC_URL}${API_CONSTANTS.ThumbnailImages}${Thumbnail})`
                : `url(${process.env.REACT_APP_CDN_STATIC_URL}${API_CONSTANTS.CommunityImages}${Thumbnail})`
              : `url(${defaultImage})`
          }
          cursor={"pointer"}
          onClick={CommunityVideoFileRef ? handleViewVideo : handleViewPost}
        >
          {IsPromoted === 1 && (
            <Flex
              position="absolute"
              top="-10px"
              left={"10px"}
              borderRadius={"7px"}
              padding={"2px 10px"}
              backgroundColor={"#FED401"}
            >
              <Text fontSize={"9px"} fontWeight={"600"}>
                {" "}
                Promoted
              </Text>
            </Flex>
          )}

          {CommunityVideoFileRef && (
            <Box
              position="absolute"
              top="25%"
              left={"40%"}
              padding={"2px 10px"}
            >
              <img
                alt="play"
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/community/play.svg`}
              />
            </Box>
          )}

          <Flex
            position="absolute"
            top="10px"
            right={"10px"}
            borderRadius={"7px"}
            padding={"2px 10px"}
            backgroundColor={"#319F0080"}
          >
            <Text fontSize={"9px"} fontWeight={"600"} color={"white"}>
              Submitted
            </Text>
          </Flex>

          {IsE9Learning === 1 && (
            <>
              <Flex
                position="absolute"
                top="40px"
                right={"-5px"}
                height={"26px"}
                w={"40px"}
                borderRadius={"2px 2px 0 2px"}
                backgroundColor={"#FF9D00"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  <img
                    alt="E Learning"
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/community/eLearningCap.svg`}
                  />
                </Box>
              </Flex>
              <Flex
                position="absolute"
                top="66px"
                right={"-5px"}
                h={0}
                w={0}
                borderTop={"0px solid white"}
                borderLeft={"5px solid transparent"}
                borderBottom={"5px solid white"}
                backgroundColor={"#A66900"}
              ></Flex>
            </>
          )}

          {/* newly added */}
          {daysAgo <= 5 && (
            <>
              <Flex
                position="absolute"
                top="83px"
                right={"-5px"}
                padding={"2px 10px"}
                borderRadius={"2px 2px 0 2px"}
                backgroundColor={"#0068F7"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Text fontSize={"10px"} fontWeight={"700"} color={"white"}>
                  Newly Added
                </Text>
              </Flex>
              <Flex
                position="absolute"
                top="102px"
                right={"-5px"}
                h={0}
                w={0}
                borderTop={"0px solid white"}
                borderLeft={"5px solid transparent"}
                borderBottom={"5px solid white"}
                backgroundColor={"#0048AC"}
              ></Flex>{" "}
            </>
          )}

          <Flex
            w={"100%"}
            position="absolute"
            bottom="0"
            fontSize={"14px"}
            padding={"2px 10px"}
            background={
              "linear-gradient(90.08deg, rgba(254, 212, 1, 0.5) -2.44%, rgba(247, 156, 0, 0.5) 107.96%)"
            }
          >
            {" "}
            {IsPromoted === 1 ? (
              <>
                <Text fontWeight={"700"}>Sponsored By : </Text> <> EQUIP9™</>
              </>
            ) : (
              <>
                <Text fontWeight={"700"}>Posted By : </Text> {Posted}
              </>
            )}
          </Flex>
        </Box>

        <Flex
          borderBottom={"1px solid #E7E7E7"}
          padding={"10px"}
          direction={"column"}
          w={"100%"}
        >
          <Flex>
            <Tooltip label={ContentTitle?.length > 50 ? ContentTitle : null}>
              <Text fontSize={"14px"} fontWeight={"700"} color={"#1A1E21"}>
                {subString(50, ContentTitle)}
              </Text>
            </Tooltip>
          </Flex>
          <Flex justifyContent={"flex-end"}>
            {" "}
            <Text fontSize={"14px"} fontWeight={"700"} color={"#F79C00"}>
              {ContentValue}
            </Text>
          </Flex>
        </Flex>

        <Flex padding={"10px"} justifyContent={"space-between"}>
          <Flex>
            {" "}
            <Text>
              {LikesCount} likes &#x2022; {ViewCount} views &#x2022;{" "}
              {daysFromDates}
            </Text>
          </Flex>
          {isMyPost === false ? (
            <Flex>
              <Flex alignItems="center" justifyContent="center" gap="1em">
                <Button
                  backgroundColor="transparent"
                  border="none"
                  boxShadow="none"
                  maxH="30px"
                  maxW="50px"
                  onClick={(event) => {
                    event.stopPropagation();
                    //   dispatch(openQrModal());
                  }}
                >
                  <Image src={shareIcon} height="30px" width="50px" />
                </Button>

                <Like
                  onClick={(value, event) => {
                    event.stopPropagation();
                    handleLikeUnlikePost();
                  }}
                  isLiked={IsLiked}
                />
              </Flex>
            </Flex>
          ) : (
            <Flex>
              <Flex alignItems="center" justifyContent="center" gap="0.5em">
                <button
                  style={{
                    width: "28px",
                    height: "28px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "4px",
                    backgroundColor: "#F5F5F5",
                  }}
                  onClick={onEditClick}
                >
                  <Image
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/edit.svg`}
                    height="16px"
                    width="16px"
                  />
                </button>
                <button
                  style={{
                    width: "28px",
                    height: "28px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "4px",
                    backgroundColor: "#F5F5F5",
                  }}
                  onClick={onDeleteClick}
                >
                  <Image
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/delete.svg`}
                    height="16px"
                    width="16px"
                  />
                </button>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};
