import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../constants/initialState";
import { getMaterialStoreThunk } from "../thunks/getMaterialStoreThunk";

export const materialsSlice = createSlice({
  name: "materials",
  initialState: initialState,
  reducers: {
    openMaterialTypeModal:(state)=>{
      state.isChooseMaterialtypeModelOpen=true;
    },
    closeMaterialTypeModal:(state)=>{
      state.isChooseMaterialtypeModelOpen=false;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
  },

  extraReducers : (builder) => {
    builder.addCase(getMaterialStoreThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
    }).addCase(getMaterialStoreThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
    }).addCase(getMaterialStoreThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
    })}


});

export const {
    openMaterialTypeModal,
    closeMaterialTypeModal,
    setData,
} = materialsSlice.actions;
