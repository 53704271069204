import { Button } from "@chakra-ui/button";
import { Flex, Text, Box } from "@chakra-ui/layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { handleJWTTokenThunk } from "../../thunks/handleJWTTokenThunk";
import {
  setBusinessTypeValue,
  setUserTypeValue,
} from "../../../global/user/slice/userSlice";
import { createDocumentFlowThunk } from "../../thunks/createDocumentFlowThunk";
import { useToast } from "@chakra-ui/react";
import { clearLoginData } from "../../slice/loginSlice";
import { getDefaultAppLanguage } from "../../../global/mtData/helpers/localStorage";
import { useBrowserInfo } from "../../../../hooks/useNavigator";
import { NAVIGATION_CONSTANTS } from "../../../../../constants/naigationContants";
import { setAssetId } from "../../../myLeads/Slice/myLeadsSlice";
import { setAssetIdDocFlow } from "../../../documentFlow/slice/docFlowSlice";
import { CustomModal } from "../../../../components/CustomModal/CustomModal";

export const LoginSuccessModal = () => {
  const loginData = useSelector((state) => state.login);
  const appConfig = useSelector((state) => state.appConfig);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const documentFlow = useSelector((state) => state.documentFlow);
  const toast = useToast();
  const defaultLanguage = getDefaultAppLanguage();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const { userAgent, appCodeName, appName, appVersion, vendor, platform } =
    useBrowserInfo();
  const assetIdParam = searchParams.get(
    NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID
  );

  const getUserAgentDetails = () => {
    return {
      "x-appcodename": appCodeName,
      "x-appname": appName,
      "x-appversion": appVersion,
      "x-useragent": userAgent,
      "x-vendor":
        !vendor && userAgent && userAgent.indexOf("Mozilla") !== -1
          ? vendor
          : "Mozilla Firefox",
      "x-platform": platform,
      "x-client-type": "Web",
    };
  };

  const isRejectedAccount = (loginDetails) =>
    loginDetails.selectedAccount &&
    parseInt(loginDetails.selectedAccount.IsRejected) === 1;

  // const createAuthorisationToken = async () => {
  //   try {
  //     const data = await generateJwtToken(
  //       {
  //         UserCredentialId: loginData.selectedAccount.UserCredentialId,
  //         BusinessTypeId: loginData.selectedAccount.BusinessTypeId,
  //         DeviceId: Math.floor(Math.random() * 1000000),
  //         IsdCode: appConfig.country.CountryIsdCode,
  //       },
  //       getUserAgentDetails()
  //     );
  //   } catch (error) {}
  // };

  const generateDocumentFlow = async (browserDetails) => {
    try {
      const data  = await dispatch(
        handleJWTTokenThunk(browserDetails)
      ).unwrap();
      await dispatch(createDocumentFlowThunk())
        .unwrap()
        .then((data) => {});
      dispatch(setUserTypeValue(loginData.selectedAccount.UserTypeValue));
      dispatch(
        setBusinessTypeValue(loginData.selectedAccount.BusinessTypeValue)
      );
      if (assetIdParam) dispatch(setAssetIdDocFlow(assetIdParam));
    } catch (error) {
    }
  };

  const handleContinueButtonClick = async () => {
    setLoading(true)
    if (isRejectedAccount(loginData)) {
      toast({
        title: "Your account is rejected.",
        isClosable: true,
        position: "top-right",
        status: "error",
      });
      return dispatch(clearLoginData());
    } else {
      await generateDocumentFlow(getUserAgentDetails());
    }
    setLoading(false)
  };

  useEffect(() => {
    if (
      documentFlow.documentFlowArray &&
      documentFlow.documentFlowArray.length > documentFlow.documentPageIndex
    ) {
      navigate(
        assetIdParam
          ? `${
              documentFlow.documentFlowArray[documentFlow.documentPageIndex]
                .route
            }?${NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID}=${assetIdParam}`
          : documentFlow.documentFlowArray[documentFlow.documentPageIndex].route
      );

      // navigate("document-flow/upload-profile-picture")
    }
  }, [documentFlow.documentFlowArray]);

  return (
    <CustomModal isOpen={loginData.isSuccessModalOpen} maxWidth={"23em"}>
      <CustomModal.Body textAlign={"center"} padding={"14px 0px"}>
        <Flex gap={"1.3em"} direction={"column"}>
          <Box>
            <Text fontWeight="700" fontSize="20px" color="#2c2c2c">
              {defaultLanguage?.Hello} {loginData.selectedAccount?.FirstName}
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={"28px"}
              fontWeight={"400px"}
              color="#F97C00"
              fontFamily="'Berkshire Swash', cursive"
            >
              Welcome
            </Text>
            <Text fontSize={"18px"} color="#565656" fontWeight="600">
              To Equip9&trade; !
            </Text>
          </Box>
          <Text fontWeight="400" fontSize={"16px"} color="#2C2C2CCC">
            {defaultLanguage?.Lets_Know_more_about_you}
          </Text>
        </Flex>
      </CustomModal.Body>
      <CustomModal.Footer
        justifyContent={"center"}
        boxShadow={"0px -10px 10px 0px #2C2C2C0F"}
        padding={"10px 32px"}
      >
        <Button
          minW={"11em"}
          variant="gradient"
          onClick={handleContinueButtonClick}
          isLoading={loading}
        >
          Okay
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
};
