import "./DealershipDetailsCard.css";

export const DealershipDownloadCard = ({
  description = "",
  imgSrc = "",
  onDownloadClick = () => {},
}) => {
  return (
    <div className="dealership-card">
      {imgSrc && (
        <img
          className="dealership-description-card-img"
          style={{ height: "60px", cursor: "pointer" }}
          src={`${imgSrc}`}
          onClick={onDownloadClick}
        />
      )}
      <button>{description}</button>
    </div>
  );
};
