import { createAsyncThunk } from "@reduxjs/toolkit";
import { getNewUserTaxDetails } from "../../../services/subscription/subscription";


export const getUserTaxDetailsThunk = createAsyncThunk("/sunscription/getUserTaxDetails", async(totalAmount = 0, {getState, rejectWithValue}) => {
   
    try{
      
        const data = await getNewUserTaxDetails();

        if(data.Status === 200){
         
         const taxData = data.Data.map((item) => ({...item, taxAmount: parseFloat(totalAmount * item.TaxSubCategoryPercentage / 100).toFixed(2)}));
         return taxData
        }
        else{
            rejectWithValue({error: "Some Error occured"})
        }
    }
    catch(error){
        // console.log(error)
        return error
    }
})