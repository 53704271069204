import { createAsyncThunk } from "@reduxjs/toolkit";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";
import { getMaterialMarketplaceList } from "../../../services/materials/materials";

export const concreteLoadMoreDataThunk = createAsyncThunk("concrete/LoadMoreCard", async(_, {getState}) => {
    const concreteId = convertToBase64('3')
    const {cardCount , paginationCount} = getState().concreteMaterialMarketplace

try{

    const data = await getMaterialMarketplaceList(cardCount,(paginationCount * cardCount),concreteId)
    
    if(data.Status === 200 && Object.keys(data.Data).length > 0){
        return data.Data
    }

}
catch(error){
}
})