import {fetchHandler} from "../../../"
import { API_CONSTANTS } from "../../../constants/apiConstants";
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData";

export const getOffersForSubscription = async () => {
try{
   const {data} = await fetchHandler("/GetOfferList");
   if(data.Status === 200 && data.Data && data.Data.length > 0){
       return data.Data
   }

}
catch(error){
    throw error
}
}

export const createUserSubscriptionTransactionOfferDetails = async (payload,url) =>{
    try {
        const {data} = await fetchHandler(`${url}`,{},{},convertToFormData(payload),API_CONSTANTS.HTT_POST);
        return data;
    } catch (error) {
        throw error ;
    }
}

export const deleteSelectedUserSubscriptionTransactionOffer = async (payload,url) => {
    try {
        const {data} = await fetchHandler(`${url}`,{},{},JSON.stringify(payload),API_CONSTANTS.HTTP_PUT);
        return data ;
    } catch (error) {
        throw error ;
    }
}



export const createUserAddressDetails = async (body) => {
    try {
        const {data}= await fetchHandler("/CreateUserAddressDetails",{},{},convertToFormData({...body, AddressTypeValue: "Residence", Latitude: 76.458965, Longitude: 78.562345}),API_CONSTANTS.HTT_POST);
        return data;
     
    } catch (error) {
      throw error;
    }
  };

  export const createCompSubscriptionDetails = async (body) => {
    try {
        const {data}= await fetchHandler("/CreateCompSubscriptionDetails",{},{},JSON.stringify(body),API_CONSTANTS.HTT_POST);
        return data;

    } catch (error) {
      throw error;
    }
  };


  export const createCompSubscriptionTransactionDetails = async (body) => {
    try {
        const {data}= await fetchHandler("/CreateCompSubscriptionTransactionDetails",{},{},convertToFormData(body),API_CONSTANTS.HTT_POST);

        return data;

    } catch (error) {
      throw error;
    }
  };

  export const createUserSubscriptionDetails = async (body) => {
    try {
        const {data}= await fetchHandler("/CreateUserSubscriptionDetails",{},{},JSON.stringify(body),API_CONSTANTS.HTT_POST);

        return data;

    } catch (error) {
      throw error;
    }
  };

  export const createUserSubscriptionTransactionDetail = async (body) => {
    try {
        const {data}= await fetchHandler("/CreateUserSubscriptionTransactionDetails",{},{},convertToFormData(body),API_CONSTANTS.HTT_POST);

        return data;

    } catch (error) {
      throw error;
    }
  };

  export const getCompanySubscriptionDetail = async (id) => {
    try {
        const {data}=await fetchHandler("/GetCompanySubscriptionDetails",{
            VirtualSubsOrderId:btoa(id)
        });

        return data;

    } catch (error) {
      throw error;
    }
  };

  export const getCompanySubscriptionTransactionDetails = async (id) => {
    try {
        const {data}=await fetchHandler("/GetCompanySubscriptionTransactionDetails",{
            VirtualSubsOrderId:btoa(id)
        });

        return data;

    } catch (error) {
      throw error;
    }
  };

  export const getAllServiceBySUBID = async (id) => {
    try {
        const {data}=await fetchHandler("/GetAllServiceBySUBID",{
            SubscriptionTypeId:id
        });

        return data;

    } catch (error) {
      throw error;
    }
  };

  export const getUserSubscriptionDetails = async (id) => {
    try {
        const {data}= await fetchHandler("/GetUserSubscriptionDetails",{
            VirtualSubsOrderId:btoa(id)
        });

        return data;

    } catch (error) {
      throw error;
    }
  };

  export const getUserSubscriptionTransactionDetails = async (id) => {
    try {
        const {data}=await fetchHandler("/GetUserSubscriptionTransactionDetails",{
            VirtualSubsOrderId:btoa(id)
        });

        return data;

    } catch (error) {
      throw error;
    }
  };

  
  export const getNewUserTaxDetails = async () => {
    try {
        const {data}= await fetchHandler("/GetNewUserTaxDetails");
        return data;

    } catch (error) {
      throw error;
    }
  };

  export const saveCompanyAddressDetails = async (body,TaxNumber) => {
    try {
        const {data}=await fetchHandler("/SaveCompanyAddressDetails",{},{},convertToFormData({...body, AddressTypeValue: "Residence", Latitude: 76.458965, Longitude: 78.562345, TaxNumber}),API_CONSTANTS.HTT_POST);

        return data;

    } catch (error) {
      throw error;
    }
  };

  export const getE9TransactionInvoice = async (id,transactionModeId) => {
    try {
        const {data}=await fetchHandler("/GetE9TransactionInvoice",{
            VirtualOrderId:btoa(id),
            TransactionModeId:transactionModeId
          });

        return data;

    } catch (error) {
      throw error;
    }
  };

  export const getAllSubscriptionForCountryForBusiness = async () => {
    try {
        const {data}= await fetchHandler("/GetAllSubscriptionForCountryForBusiness");

        return data;

    } catch (error) {
      throw error;
    }
  };

  export const getTransactionState = async () => {
    try {
        const {data} = await fetchHandler("/GetTransactionState");
        return data;

    } catch (error) {
      throw error;
    }
  };

  export const updateTransactionDetails = async (payload,url) =>{
    try {
        const {data} = await fetchHandler(`${url}`,{},{},JSON.stringify(payload),API_CONSTANTS.HTTP_PUT);
        return data;
    } catch (error) {
        throw error ;
    }
}

  export const updateUserSubscriptionTransactionTaxDetails = async (payload,url) =>{
    try {
        const {data} = await fetchHandler(`${url}`,{},{},JSON.stringify(payload),API_CONSTANTS.HTTP_PUT);
        return data;
    } catch (error) {
        throw error ;
    }
}
  
