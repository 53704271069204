import { createAsyncThunk } from "@reduxjs/toolkit";
import { getE9MarketPlaceUsedEquipmentList } from "../../../services/equipments/equipments";

export const getUemLoadMoreDataThunk = createAsyncThunk("uem/loadMoreCards", async(paginitionCount, {getState}) => {
   
    const {cardCount, paginationCount} = getState().usedEquipmentMarketplace;
    
    try{
        const data = await getE9MarketPlaceUsedEquipmentList(cardCount,(paginationCount * cardCount));
        
        if(data.Status === 200 && Object.keys(data.Data).length > 0){
             if(data.Data.UsedEquipmentList){
                 return data.Data.UsedEquipmentList;
             }
             else{
                return []
             }
        }
        else{
            return []
        }
    }
    catch(error){
    }
    
})