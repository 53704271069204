import { API_CONSTANTS } from "../../../constants/apiConstants"
import { convertToIdValuePairs } from "../../helpers/dataConversion/convertToIdValuePairs";
import {fetchHandler} from "../../../index"

export const getInsuranceTypes = async() => {
    try{
    const {data} = await fetchHandler(API_CONSTANTS.GET_INSURANCE_TYPE_LIST);
    if(data.Status === 200 && Object.keys(data.Data).length > 0){
        const requiredData = data.Data.InsuranceTypeList.map(({InsuranceTypeId, InsuranceTypeValue}) => (convertToIdValuePairs(InsuranceTypeId, InsuranceTypeValue)))
       return requiredData;
    }
    }
    catch(error){
        throw error;
    }
}