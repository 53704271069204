import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPostedJobsList } from "../../../services/jobsPosted/jobsPosted";

export const getLoadMorePostedJobsThunk = createAsyncThunk("/jobsPosted/loadMore", async(_, {getState}) => {
    const {cardsCount, pageNumber} = getState().jobsPosted
    try{
      const data = await getPostedJobsList(pageNumber, cardsCount)
      return data;
    }
    catch(error){

    }
})