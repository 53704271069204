import { API_CONSTANTS } from "../../../../../constants/apiConstants"
import fetchHandler from "../../../../../webapp/services/fetchHandler"


export const getMaterialInitialDeferredData = async (materialTypeId) =>{
    try {
        const {data} = await fetchHandler(
          "/deferredLogin/marketplace",
          { category : "material" , materialTypeId : materialTypeId  },
          
          "",
          "",
          API_CONSTANTS.HTTP_GET,
          { isAuthRequired: false }
      )
      if(data?.Status === 200){
        return data?.Data
      }
      return data ;
      } catch (error) {
        
      }
}
