import { Box, Button, Flex } from "@chakra-ui/react";
import { NewSideMenuLayout } from "../../../../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { RouteStepper } from "../../../../Stepper/Stepper";

import { useDispatch, useSelector } from "react-redux";
import { MarketPlacestep1 } from "./MaterialPlaceSteps1";
import { MaterialSteps2 } from "./MaterialSteps2";


export const PostMaterialSteps = () => {
  const { stepperIndex, stepperRoute } = useSelector((state) => state.postMaterial);
  const dispatch = useDispatch()

  const renderForm = (index) => {
    switch (index) {
      case 0:
        return <MarketPlacestep1 />;

      case 1:
        return  <MaterialSteps2 /> ;


      default:
        return <p>No Data Available</p>;
    }
  };

  const formId = (index) => {
    switch(index){
      case  0 :
        return "post-material-step-one"

      case 1 :
        return "post-material-step-two" 

        default : 
        return ""
    }

  }
  return (
    <NewSideMenuLayout justifyContent="space-between">
      <div
        style={{
          margin: "0px 52px",
          backgroundColor: "#92929204",
          paddingTop: "1em",
          overflowY:"scroll"
        }}
      >
        <Flex flexDirection="column" gap="1em" height="100%">
          <Box h="54px" dropShadow="0px 10px 30px #2C2C2C14" bgColor="#FFFFFF">
            <RouteStepper width="100%" array={stepperRoute} activeIndex={stepperIndex} padding="1em"/>
          </Box>
          <Box
            maxH="347px"
            h="100%"
          >
            {renderForm(stepperIndex)}
          </Box>
        </Flex>
      </div>

      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxHeight: "78px",
        }}
      >
        <Button
          type="submit"
          variant="gradient"
          w="347px"
          form={formId(stepperIndex)}         
        >
          Next
        </Button>
      </div>
    </NewSideMenuLayout>

  );
};
