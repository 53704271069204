export const useClipboard = () => {
const navigator = window.navigator;


const copyToClipboard = async (value) => {
    try {
        await navigator.clipboard.writeText(`${value}`);
    } catch (error) { }
}

return {copyToClipboard}
}