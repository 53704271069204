import { createAsyncThunk } from "@reduxjs/toolkit";
import {getUsedEquipmentStoreAnalytics } from "../../../services/equipmentStore/equipmentStore";

export const getUsedEquipmentAnalyticsThunk = createAsyncThunk("/equipment-store/get-analytics", async(_, {rejectWithValue}) => {
    try{
        const data = await getUsedEquipmentStoreAnalytics()
        return  data
    }
    catch(error){
        rejectWithValue(error)
    }
})