import { createSlice } from "@reduxjs/toolkit";
import { getMaterialLeadCheckThunk } from "../thunks/getMaterialLeadCheckThunk";

export const materialLeadSlice = createSlice({
    name: "materialLeads",
    initialState : {
        purchasedLeadsData : null,
        selectedSubscription : null,
        purchasedLeads: 0,
        totalLeads: 0,
        purchasedLeadsLoading : false

    },
    reducers: {
        setData : (state, {payload}) => {state.data = payload}
    },
    extraReducers : (builder) =>{
        builder.addCase(getMaterialLeadCheckThunk.pending , (state) =>{
            state.purchasedLeadsLoading = true;
            state.purchasedLeadsData = null;
            state.selectedSubscription = null;
            state.purchasedLeads = 0;
            state.totalLeads = 0
        })
        builder.addCase(getMaterialLeadCheckThunk.rejected, (state) =>{
            state.purchasedLeadsLoading = false;
            state.purchasedLeadsData = null;
            state.purchasedLeads = 0
            state.leadsRemaining = 0
            state.selectedSubscription = null
        })
        builder.addCase(getMaterialLeadCheckThunk.fulfilled, (state,{payload}) =>{
            if(payload && Array.isArray(payload)){
                state.purchasedLeadsLoading = false;
                state.purchasedLeadsData = payload;
                state.purchasedLeads = payload.reduce((accumulator, currentValue) => {return accumulator + currentValue.PurchasedCount}, 0)
                state.leadsRemaining = payload.reduce((accumulator, currentValue) => {return accumulator + currentValue.LeadRemaining}, 0)
               
                
                if(payload.length === 0){
                    return
                }
                if(payload.length === 1){
                  state.selectedSubscription = payload[0]
                } else{
                    const sortLeadsByDaysRemaining = payload.sort((a, b) => (a.DaysRemaining - b.DaysRemaining))
                    const subscriptionWithLeadsRemaining = sortLeadsByDaysRemaining.filter(({LeadRemaining}) => (LeadRemaining > 0))
                    state.selectedSubscription = subscriptionWithLeadsRemaining[0]
                }
            }

        })
    }
})

export const {setData} = materialLeadSlice.actions