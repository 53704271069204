import { API_CONSTANTS } from "../../../constants/apiConstants";
import { convertToIdValuePairs } from "../../helpers/dataConversion/convertToIdValuePairs";
import {fetchHandler} from "../../../index"

export const getNatureOfWorkTypes = async() => {
    try{
        const {data} = await fetchHandler(API_CONSTANTS.GET_NATURE_OF_WORK_LIST);
        if(data.Status === 200 && Object.keys(data.Data).length > 0){
          const requiredData = data.Data.NatureOfWorkList.map(({WorkTypeId, WorkTypeValue}) => (convertToIdValuePairs(WorkTypeId, WorkTypeValue)));
          return requiredData
        }
        return null;
      }
      catch(error){
          throw error
      }
}

export const getTravelAllowanceTypes = async() => {
    try{
        const {data} = await fetchHandler(API_CONSTANTS.GET_TRAVEL_ALLOWANCE_LIST);
        if(data.Status === 200 && Object.keys(data.Data).length > 0){
          const requiredData = data.Data.TravelAllowanceList.map(({TravelAllowanceTypeId, TravelAllowanceTypeValue}) => (convertToIdValuePairs(TravelAllowanceTypeId, TravelAllowanceTypeValue)));
          return requiredData
        }
        return null;
      }
      catch(error){
          throw error
      }
}