import { API_CONSTANTS } from "../../../constants/apiConstants"
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData";
import fetchHandler from "../fetchHandler"

export const GetE9DealershipMpLikeList = async () =>{
    try {
        const {data} = await fetchHandler(API_CONSTANTS.GET_E9_DEALERSHIP_MP_LIKE_LIST)
        if(data?.Status === 200){
            return data;
        }
        return null;   
    } catch (error) {
        throw error
    }
}
export const sendLikeToDealership = async (body) =>{
    try {
        const {data} = await fetchHandler(API_CONSTANTS.CREATE_E9_DS_MP_LIKE_DETAILS,{},{},convertToFormData(body),API_CONSTANTS.HTT_POST)  
      return data;
    } catch (error) {
        throw error
    }
}

export const getE9DealershipMpConnectStatus = async (mpGuid) => {
 
    try {
    const {data} = await fetchHandler(`${API_CONSTANTS.GET_E9_DEALERSHIP_MP_CONNECT_STATUS}?DealershipMpGuid=${mpGuid}`);
    return data;

    } catch (error) {
      throw error;
    }
  };

  export const createE9DsMpContactDetails = async (formData) => {
    try {

    const {data} = await fetchHandler(API_CONSTANTS.POST_CREATE_E9_DS_MP_CONTACT_DETAILS,{},{},convertToFormData(formData),API_CONSTANTS.HTT_POST);
    return data;

    } catch (error) {
      // throw error;
    }
  };

  export const getUserDsProductList = async (cardCount,offset,query) => {
    try {
    const {data} = await fetchHandler(`${API_CONSTANTS.GET_USER_DS_PRODUCT_LIST}/${cardCount}/${offset}${query}`);
    return data;

    } catch (error) {
      throw error;
    }
  };

  export const getFilterE9DealershipMarketPlaceList = async (cardCount,offset,query) => {
    try {
    const {data} = await fetchHandler(`/GetFilterE9DealershipMarketPlaceList/${cardCount}/${offset}?${query}`);

    return data;

    } catch (error) {
      throw error.response;
    }
  };

  export const getAllE9DealershipMarketPlaceList = async (cardCount,offset) => {
    try {
    const {data} = await fetchHandler(`${API_CONSTANTS.GET_ALL_E9DEALERSHIPMARKETPLACELIST}/${cardCount}/${offset}`);

    return data;

    } catch (error) {
      throw error;
    }
  };

  export const getDealershipEquipmentMakeList = async () => {
    try {
     
      const {data} = await fetchHandler(API_CONSTANTS.GET_EQUIPMENT_MAKE_LIST);
      return data;

    } catch (error) {
  
    }
  }

