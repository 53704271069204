import React from "react";
import { Box, Button, Flex } from "@chakra-ui/react";
import { NewSideMenuLayout } from "../../../../../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { RouteStepper } from "../../../../../Stepper/Stepper";
/***importing redux */
import { useDispatch, useSelector } from "react-redux";
/***Edit Stepeer Form */
import { EditMaterialPlaceSteps1 } from "./EditMaterialPlaceSteps1";
import { EditMaterialPlaceSteps2 } from "./EditMaterialPlaceSteps2";
export const PostEditMaterialPlaceStep = () => {
  const { editStepperIndex, editStepperRoute } = useSelector(
    (state) => 
    state.EditpostMaterial
  );

  /*** Add Components*****8*/
  const editRenderForm = (index) => {

    switch (index) {
        case 0:
          return <EditMaterialPlaceSteps1 />;
        case 1:
          return  <EditMaterialPlaceSteps2 /> ;
        default:
          return <p>No Data Available</p>;
      }
  };

  /*** form Id******/

  const editFormId = (index) => {
    switch(index){
        case  0 :
          return "Edit-post-material-step-one"
  
        case 1 :
          return "Edit-post-material-step-two" 
  
          default : 
          return ""
      }
  };

  /****Reder UI */
  return (
    <NewSideMenuLayout justifyContent="space-between">
      <div
        style={{
          margin: "0px 52px",
          backgroundColor: "#92929204",
          paddingTop: "1em",
          overflowY: "scroll",
        }}
      >
        <Flex flexDirection="column" gap="1em" height="100%">
          <Box h="54px" dropShadow="0px 10px 30px #2C2C2C14" bgColor="#FFFFFF">
            <RouteStepper
              width="100%"
            //   editStepperRoute
              array={editStepperRoute}
              activeIndex={editStepperIndex}
              padding="1em"
            />
          </Box>
          <Box maxH="347px" h="100%">
            {editRenderForm(editStepperIndex)}
          </Box>
        </Flex>
      </div>

      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxHeight: "78px",
        }}
      >
        <Button
          type="submit"
          variant="gradient"
          w="347px"
          form={editFormId(editStepperIndex)}
        >
          Next
        </Button>
      </div>
    </NewSideMenuLayout>
  );
};
