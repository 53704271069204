import { v4 as uuid } from "uuid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EquipmentMarketplaceCard } from "../../../components/EquipmentMarketplaceCard/EquipmentMarketplaceCard";
import { EquipmentRentalLayout } from "../../../layout/equipmentRentalLayout/EquipmentRentalLayout";
import { openQrModal } from "../../dashboard/slice/dashboardSlice";
import { QrPopUp } from "../../../components/ScanQrCodeModal/QrPopUp";
import { primaryKeyEncryption } from "../../../helpers/dataConversion/primaryKeyEncryption";
import { MarketplaceHeader } from "../../../components/MarketplacHeader/MarketplaceHeader";
import { FilterPopUp } from "../../marketplace/hoc/FilterPopUp/FilterPopUp";
import { getRemInitialDataThunk } from "../thunks/getRemInitialDataThunk";
import { getRemLoadMoreDataThunk } from "../thunks/getRemLoadMoreDataThunk";
import { MarketplaceHoc } from "../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import {
  closeRentalDurationModal,
  openRentalDurationModal,
  resetFilters,
  setFilterByCountry,
  setFilterByEquipmentType,
  setFilterByState,
  setFilterByVerificationStatus,
  setFilterModalOpen,
  setFilteredDataRequired,
  sendConnectRequest,
  sendRevokeRequest,
  updateE9MarketPlaceContactId,
} from "../slice/rentalEquipmentMartketplace";
import { MARKETPLACE_TYPES } from "../../../constants/e9Constants";
import { getRemInitialFilteredDataThunk } from "../thunks/getRemInitialFilteredDataThunk";
import { getRemFilterImagesDataThunk } from "../thunks/getRemFilterImagesDataThunk";
import { RentalDurationPopUp } from "../../../components/RentalDurationPopUp/RentalDurationPopUp";
import { NativeCarousel } from "../../../components/Carousel/NativeCarousel";
import {
  sendLikeRequestToEquipment,
  sendRentalDurationRequest,
} from "../../../services/equipmentMarketplace/equipmentMarketplace";
import { NoLeadsPopUp } from "../../../components/NoLeadsPopUp/NoLeadsPopUp";
import { getRemConnectLeadCheckThunk } from "../../purchasedLeadsCheck/thunks/getRemConnectLeadsCheckThunk";
import { getFreeLeadsForCompanyThunk } from "../../freeLeads/thunks/getFreeLeadsForCompanyThunk";
import { getFreeLeadsForIndividualThunk } from "../../freeLeads/thunks/getFreeLeadsForUserThunk";
import { updateAssetContactRequest } from "../../../services/myLeads/myLeads";
import { getInitialUserAssetsThunk } from "../../equipmentStore/thunks/getInitialUserAssetsThunk";
import { getRemLoadMoreFilteredDataThunk } from "../thunks/getRemLoadMoreFilteredDataThunk";
import { useToast } from "@chakra-ui/react";
import { getCheckUserLead } from "../../userLeadsCheck/helper/userLeadsCheck";
import { setUserLeadsData } from "../../userLeadsCheck/slices/userLeadsCheckSlice";
import { getTransactionModeDetailsThunk } from "../../myLeads/thunk/getTransactionModeDetailsThunk";
import { createE9MarketPlaceContactRedeemDetails, updateE9MarketplaceContactRedeemedDetails } from "../../../services/userAssets/userAssets";
import { encryptShareData } from "../../../services/share/share";
import { useClipboard } from "../../../hooks/useClipboard";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";

export const RentalEquipmentMarketplace = () => {
  const dispatch = useDispatch();
  const {copyToClipboard} = useClipboard();

  const remData = useSelector((state) => state.rentalEquipmentMarketplace);
  const { data:userLeads} = useSelector((state) => state.userLeads);

  const advertisements = useSelector((state) => state.advertisement);
  const { transactionModeDetails } = useSelector((state) => state.myLeads);

  const { accountDetails, token, UserTypeValue } = useSelector(
    (state) => state.user
  );
  const userCredentialId =
    accountDetails?.UserDetails?.CredentialDetails?.UserCredentialId;
  const navigate = useNavigate();
  const [selectedImageFilterButton, setSelectedImageFilterButton] =
    useState(null);
  const [responseTypes, setResponseTypes] = useState(null);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [recieversId, setRecieversId] = useState(null);
  const [isNoLeadModalOpen, setIsNoLeadsModalOpen] = useState(false);
  const { freeLeadsData } = useSelector((state) => state.freeLeads);
  const { leadsRemaining } = useSelector(
    (state) => state.purchasedLeads.rentalEquipment
  );
  const [freeLeadsCount, setFreeLeadsCount] = useState(0);
  const [equipmentData,setEquipmentData]= useState(null);

  const { data: companyEquipments } = useSelector(
    (state) => state.equipmentStore
  );
  const [companyEquipmentIds, setCompanyEquipmentIds] = useState(null);
  const toast = useToast();
  const filterFunction = (values) => {
    dispatch(setFilterByEquipmentType(values.equipmentType));
    dispatch(setFilterByCountry(values.country));
    dispatch(setFilterByState(values.state));
    dispatch(setFilterByVerificationStatus(values.verificationStatus));
    if (
      values.equipmentType ||
      values.country ||
      values.state ||
      values.verificationStatus
    ) {
      dispatch(setFilteredDataRequired(true));
    } else {
      dispatch(setFilteredDataRequired(false));
    }
    dispatch(getRemInitialFilteredDataThunk());
    dispatch(setFilterModalOpen(false));
  };

  const handleRentalDuration = async (value) => {
    // console.log(value);
    try {
      const data = await sendRentalDurationRequest({
        RentDurationId: value.RentalDurationId,
        E9MarketplaceId: selectedEquipment,
      });
      // console.log(data);
      await handleContactSend();
      setSelectedEquipment(null);
      setRecieversId(null);
      dispatch(closeRentalDurationModal());
    } catch (error) {
    }
  };

  
  const createRedeemDetails= async (E9MarketPlaceContactId,item)=>{

    if (E9MarketPlaceContactId == null) {
      toast({
        title: "",
        description: "Redeem Failed",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return ;
    }

    const Payload = {
      E9MpContactId: E9MarketPlaceContactId,
      ResponseTypeId: 1,
      ServiceTypeId: 5,
    };

    
    const freeContactRemaining =userLeads?.freeData?.LeadRemaining
    const premiumContactRemaining=userLeads?.premium?.LeadsData?.[0]?.RemainingCount
    const subscriptionContactRemaining=userLeads?.subscription?.LeadRemaining

    const getTransactionModeId = () => {
      if (freeContactRemaining > 0) {
        const foundItem = transactionModeDetails.find(
          (item) => item.TransactionModeValue === "Free"
        );
        return foundItem?.TransactionModeId || null;
      }
  
      if (premiumContactRemaining > 0) {
        const foundItem = transactionModeDetails.find(
          (item) => item.TransactionModeValue === "Premium"
        );
        return foundItem?.TransactionModeId || null;
      }
  
      if (subscriptionContactRemaining > 0) {
        const foundItem = transactionModeDetails.find(
          (item) => item.TransactionModeValue === "Subscription"
        );
        return foundItem?.TransactionModeId || null;
      }
      return null;
    };
    Payload.TransactionModeId = getTransactionModeId();

    const response= await createE9MarketPlaceContactRedeemDetails(Payload);

  }

  const updateRedeemDetails= async (E9MarketPlaceContactId,item)=>{
    
    if (E9MarketPlaceContactId == null) {
      toast({
        title: "",
        description: "Redeem Failed",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return ;
    }

    var raw_payload = {
      E9MarketplaceContactRedeemedDetails: {
        E9MarketPlaceContactId: item?.E9MarketPlaceContactId,
        ResponseTypeValue: item?.RequestTypeId === 4 ? "Request Sent" : "Request Revoked",
        TransactionModeId: "",
        SenderUserCredentialId: userCredentialId,
      },
    };

    const response = await updateE9MarketplaceContactRedeemedDetails(raw_payload);

  }

  const handleContactSend = async () => {
    const body = {
      E9MarketPlaceId: selectedEquipment,
      ReceiverUserCredentialId: recieversId,
      ContactStatus: 1,
      RequestTypeId: 1,
    };
    let payload = [];
    for (const key in body) {
      const requiredKey = encodeURIComponent(key);
      const requiredValue = encodeURIComponent(body[key]);
      payload.push(`${requiredKey}=${requiredValue}`);
    }

    const finalPayload = payload.join("&");
    try {
      const data = await updateAssetContactRequest(finalPayload);

      dispatch(sendConnectRequest(selectedEquipment));

      if (data.Status === 201 || data.Status === 204) {        
        if(!equipmentData?.E9MarketPlaceContactId){
          dispatch(updateE9MarketPlaceContactId({
            AssetUniqueId: equipmentData?.AssetUniqueId,
            LastE9MarketplaceContactId : data?.Data?.LastE9MarketplaceContactId
          }))
          createRedeemDetails(data?.Data?.LastE9MarketplaceContactId,equipmentData);
        }else{
          updateRedeemDetails(equipmentData?.E9MarketPlaceContactId,equipmentData);
        }
        
      }

    } catch (error) {
      // Handle errors here
    }
  };

  const handleContact = async (equipmentId, recieversAddress) => {
    if(UserTypeValue?.toLowerCase() === "company" && !accountDetails?.CompanyDetails?.TaxNumber){
      toast({
        status: "error",
        description: "Please update GST Number to Contact Owner.",
        position: "top-right",
        isClosable: true,
      })
      
    }else
    if (equipmentId) {
          console.log("Clicked");
      setSelectedEquipment(equipmentId);
      setRecieversId(recieversAddress);
      dispatch(openRentalDurationModal());
    }
  };

  const handleRevoke = async (equipmentMarketplaceId, recieverCredentials,equipment) => {
    const body = {
      E9MarketPlaceId: equipmentMarketplaceId,
      ReceiverUserCredentialId: recieverCredentials,
      ContactStatus: 0,
      RequestTypeId: 4,
    };
    let payload = [];
    for (const key in body) {
      const requiredKey = encodeURIComponent(key);
      const requiredValue = encodeURIComponent(body[key]);
      payload.push(`${requiredKey}=${requiredValue}`);
    }

    const finalPayload = payload.join("&");
    try {
      const data = await updateAssetContactRequest(finalPayload);

      dispatch(sendRevokeRequest(equipmentMarketplaceId));

      if (data.Status === 201 || data.Status === 204) {
        updateRedeemDetails(equipment?.E9MarketPlaceContactId,equipment);
    }
    } catch (error) {
      // Handle errors here
    }
  };

  const handleLike = async (body) => {
    try {
      const data = await sendLikeRequestToEquipment(body);
    } catch (error) {}
  };

  const getResponseTypesList = async () => {
    try {
      const data = await getResponseTypesList();
      setResponseTypes(data);
      return data;
    } catch (error) {
      setResponseTypes(null);
      return null;
    }
  };

  const getRentalEquipmentSubscriptionLeads = (freeSubscriptionData) => {
    const selectedDetails = freeLeadsData.find(
      ({ ServiceTypeValue, SubscriptionTypeValue }) =>
        ServiceTypeValue === "Rental Equipment" &&
        SubscriptionTypeValue === "Contact Based"
    );
    if (selectedDetails) {
      setFreeLeadsCount(selectedDetails.ContactRemaining);
      return selectedDetails.ServiceTypeId;
    } else {
      return null;
    }
  };

  const encryptRentalEquipmentDetails = async (E9MarketPlaceId) => {
    try {
      // const payload = {
      //   marketplaceCategory: "equipment",
      //   marketplaceSubCategory: "used",
      //   assetId: E9MarketPlaceId,
      // };

      // const shareLink = await encryptShareData(payload);
      // await copyToClipboard(
      //   `${process.env.REACT_APP_WEBSITE_URL}share?id=${shareLink}&mode=share&utm=web`
      // );
      await copyToClipboard(
        `${process.env.REACT_APP_WEBSITE_URL}share?category=${"equipment"}&subCategory=${"rental"}&id=${convertToBase64(E9MarketPlaceId)}`
      );

      toast({
        title: "Link Copied!",
        description: "Share link has been successfully copied to clipboard.",
        status: "success",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        description: "Failed to share. Please try again.",
        position: "top-right",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const renderRentalEquipmentCards = (array, interval = 6) => {
    const renderJSX =
      array && array.length > 0 ? (
        array.map((equipment, index) => (
          <>
            <EquipmentMarketplaceCard
              key={uuid()}
              imageUrl={`${process.env.REACT_APP_CDN_PVT_URL}${remData.assetImageReference}${equipment.AssetImages.Image_Front_FileRef}`}
              equipmentTypeValue={equipment.EquipmentTypeValue}
              oemName={equipment.OEMName}
              modelName={equipment.ModelName}
              price={equipment.ExpectedRentalPrice}
              paymentCycle={equipment.ExpectedRentalTypeValue}
              status={equipment.IsCurrentlyAvailable ? "Available" : "Sold"}
              ownerName={equipment.OwnerName}
              listedTime={equipment.Listed}
              city={equipment.CityName}
              state={equipment.StateName}
              country={equipment.CountryName}
              userImageUrl={`${process.env.REACT_APP_CDN_PVT_URL}${remData.userImageReference}${equipment.ProfilePictureFileReference}`}
              isPromoted={equipment.IsPromoted}
              isVerified={equipment.IsVerified}
              onClick={() => {
                navigate(
                  `/marketplace/MarketPlaceREM/Cards/${primaryKeyEncryption(
                    equipment.E9MarketPlaceId
                  )}`
                );
              }}
              isCtaButton={
                (userCredentialId &&
                  parseInt(equipment.AssetOwnerUserCredentialId) ===
                    parseInt(userCredentialId)) ||
                equipment.ResponseTypeValue === "Request Revoked" ||
                equipment.ResponseTypeValue === "Request Sent" ||
                !equipment.ResponseTypeValue
              }
              buttonText={
                (userCredentialId &&
                  parseInt(equipment.AssetOwnerUserCredentialId) ===
                    parseInt(userCredentialId)) ||
                (companyEquipmentIds &&
                  companyEquipmentIds.includes(parseInt(equipment.AssetId)))
                  ? "My Asset"
                  : equipment.ResponseTypeValue &&
                    equipment.ResponseTypeValue !== "Request Revoked"
                  ? equipment.ResponseTypeValue
                  : "Contact Owner"
              }
              buttonColor={
                (userCredentialId &&
                  parseInt(equipment.AssetOwnerUserCredentialId) ===
                    parseInt(userCredentialId)) ||
                (companyEquipmentIds &&
                  companyEquipmentIds.includes(parseInt(equipment.AssetId)))
                  ? "#1E3264"
                  : equipment.ResponseTypeValue === "Request Rejected"
                  ? "#D60000"
                  : equipment.ResponseTypeValue === "Request Sent" ||
                    equipment.ResponseTypeValue === "Request Accepted"
                  ? "#319F00"
                  : "#F67C00"
              }
              onShareClick={ async (e) => {
                e.stopPropagation(); 
                await encryptRentalEquipmentDetails(primaryKeyEncryption(equipment?.E9MarketPlaceId));
              }}
              onLikeClick={async (value) => {
                await handleLike({
                  E9MarketPlaceId: equipment.E9MarketPlaceId,
                  LikeStatus: value ? 1 : 0,
                });
              }}
              onContactClick={() => {
                //setting equipement data
                setEquipmentData(equipment);

                (userCredentialId &&
                  parseInt(equipment.AssetOwnerUserCredentialId) ===
                    parseInt(userCredentialId)) ||
                (companyEquipmentIds &&
                  companyEquipmentIds.includes(parseInt(equipment.AssetId)))
                  ? navigate("/equipment-store")
                  : !freeLeadsCount && !leadsRemaining && !userLeads?.premium?.LeadsData[0]?.RemainingCount
                  ? setIsNoLeadsModalOpen(true)
                  : equipment.ResponseTypeValue === "Request Sent"
                  ? handleRevoke(
                      equipment.E9MarketPlaceId,
                      equipment.AssetOwnerUserCredentialId,
                      equipment
                    )
                  : !equipment.ResponseTypeValue ||
                    equipment.ResponseTypeValue === "Request Revoked"
                  ? handleContact(
                      equipment.E9MarketPlaceId,
                      equipment.AssetOwnerUserCredentialId
                    )
                  : (() => {
                      return null;
                    })();
                // parseInt(equipment.AssetOwnerUserCredentialId) ===
                // parseInt(userCredentialId)
                //   ? navigate("/equipment-store")
                //   : !freeLeadsCount && !leadsRemaining
                //   ? setIsNoLeadsModalOpen(true)
                //   : equipment.ResponseTypeValue &&
                //     equipment.ResponseTypeValue === "Request Sent"
                //   ? handleRevoke(
                //       equipment.E9MarketPlaceId,
                //       equipment.AssetOwnerUserCredentialId
                //     )
                //   : !equipment.ResponseTypeValue ||
                //     (equipment.ResponseTypeValue &&
                //       equipment.ResponseTypeValue === "Request Revoked")
                //   ? handleContactSend(
                //       equipment.E9MarketPlaceId,
                //       equipment.AssetOwnerUserCredentialId
                //     )
                //   : (() => {
                //       return null;
                //     })();
              }}
            />

            {(index + 1) % interval === 0 && (
              <div
                key={`${index}`}
                style={{
                  width: "100%",
                  gridColumn: "span 3",
                  borderRadius: "8px",
                  boxShadow: "0px 0px 8px 0px #00000040",
                  cursor: "pointer",
                }}
              >
                <NativeCarousel
                  images={
                    advertisements ? advertisements.data?.requiredImgUrl : []
                  }
                  redirectionUrl={
                    advertisements
                      ? advertisements.data?.requiredRedirectUrl
                      : []
                  }
                  speed="3000"
                  height="350px"
                  width="100%"
                />
              </div>
            )}
          </>
        ))
      ) : (
        <p>No data available</p>
      );
    return renderJSX;
  };

  useEffect(() => {
    dispatch(getRemFilterImagesDataThunk());
    getResponseTypesList();
    if (remData.filteredDataRequired) {
      dispatch(getRemInitialFilteredDataThunk());
    } else {
      dispatch(getRemInitialDataThunk());
    }
  }, []);

  useEffect(() => {
    if (accountDetails && UserTypeValue === "Company") {
      dispatch(getFreeLeadsForCompanyThunk());
    } else {
      dispatch(getFreeLeadsForIndividualThunk());
    }
  }, []);

  useEffect(() => {
    if (freeLeadsData) {
      const serviceId = getRentalEquipmentSubscriptionLeads();
      if (serviceId) {
        dispatch(getRemConnectLeadCheckThunk(serviceId));
      }
    }
  }, [freeLeadsData]);

  useEffect(() => {
    if (
      UserTypeValue &&
      UserTypeValue.toLowerCase() === "company".toLowerCase()
    ) {
      dispatch(getInitialUserAssetsThunk());
    }
  }, []);

  useEffect(() => {
    if (companyEquipments) {
      const assetIds = companyEquipments.map(({ AssetId }) => AssetId);
      setCompanyEquipmentIds(assetIds);
    }
  }, [companyEquipments]);

  useEffect(()=>{
    (async ()=>{
      const userLeadsData=await getCheckUserLead(5,"",userCredentialId);
      dispatch(setUserLeadsData(userLeadsData));
      dispatch(getTransactionModeDetailsThunk());
    })()
    
  },[])

  return (
    <>
      <FilterPopUp
        marketplaceType={MARKETPLACE_TYPES.EQUIPMENT_MARKETPLACE}
        isModalOpen={remData.filterModalOpen}
        initialEquipmentValue={remData.filterByEquipmentType}
        initialVerificationValue={remData.filterByVerificationStatus}
        initialCountryValue={remData.filterByCountry}
        initialStateValue={remData.filterByState}
        onResetFilterClick={() => {
          dispatch(resetFilters());
        }}
        onSubmitButtonClick={filterFunction}
        onCloseButtonClick={() => {
          dispatch(setFilterModalOpen(false));
        }}
      />
      <QrPopUp />
      <NoLeadsPopUp
        isOpen={isNoLeadModalOpen}
        onCloseClick={() => {
          setIsNoLeadsModalOpen(false);
        }}
        onButtonClick={() => navigate("/subscription/subscription-type")}
      />
      <RentalDurationPopUp onSubmit={handleRentalDuration} />
      <EquipmentRentalLayout>
        <MarketplaceHeader
          heading="Rental Equipments Near me"
          filterButtonSelected={remData.filteredDataRequired}
          filterButtonClickAction={() => {
            dispatch(setFilterModalOpen(true));
          }}
          filterImagesArray={
            remData.equipmentTypeFilterList
              ? remData.equipmentTypeFilterList
              : []
          }
          filterImagesReference={
            remData.filterIconImageReference
              ? remData.filterIconImageReference
              : ""
          }
          filterImageButtonClickAction={(EquipmentType) => {
            dispatch(setFilterByEquipmentType(EquipmentType));
            dispatch(getRemInitialFilteredDataThunk());

            if (selectedImageFilterButton === EquipmentType) {
              setSelectedImageFilterButton(null);
              dispatch(setFilteredDataRequired(false));
              dispatch(getRemInitialDataThunk());
            } else {
              setSelectedImageFilterButton(EquipmentType);
              dispatch(setFilteredDataRequired(true));
            }
          }}
          selectedImageFilterButton={selectedImageFilterButton}
        />
        <MarketplaceHoc
          marketplaceName="Rental Marketplace"
          dataArray={remData.data ? remData.data : []}
          userImageBaseReference={
            remData.userImageReference ? remData.userImageReference : ""
          }
          assetImageBaseReference={
            remData.assetImageReference ? remData.assetImageReference : ""
          }
          endOfData={remData.endOfData}
          loadMoreAction={() =>
            remData.filteredDataRequired
              ? dispatch(getRemLoadMoreFilteredDataThunk())
              : dispatch(getRemLoadMoreDataThunk())
          }
          initialLoading={remData.initialLoading}
          paginationLoading={remData.paginationLoading}
          cardsRenderFunction={renderRentalEquipmentCards}
          filterButtonImageReference={remData.filterIconImageReference}
        />
      </EquipmentRentalLayout>
    </>
  );
};
