import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserAssetById } from "../../../services/userAssets/userAssets.js";

export const getEditAssetDetailsThunk = createAsyncThunk("/equipment-listing/get-asset-details", async(assetId) => {
    // API is already made
    try{
        if(!assetId){
            throw new Error("parameter cannot be empty")
        }

        const data = await getUserAssetById(assetId);
        return data
    }
    catch(error){
        throw error;
    }
})