import { API_CONSTANTS } from "../../../constants/apiConstants";
import {fetchHandler} from "../../../index";


export const supportCreateTicket = async (payLoad) => {
    try {

    const {data}= await fetchHandler("/SupportTicket/Create",{},{},payLoad,API_CONSTANTS.HTT_POST)
    return data;
  
    }
    catch (err) {
      throw err.response;
    }
  }


  export const SupportTicketReason = async () => {
    try {
        const response=await fetchHandler("/SupportTicket/SupportTicketReason")
        return response;
    }
    catch (err) {
      throw err.response;
    }
  }

  export const getRaisedTicket = async () => {
    try {
      const response=await fetchHandler("/SupportTicket/MySupportTicket/10/0")
        
      return response;

    }
    catch (err) {
      throw err.response;
    }
  }

