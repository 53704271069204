import React from "react";
import {
    Box,
    Button,
    Flex,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "@chakra-ui/icons";
import {
    closeDeleteModal,
    closeDealershipModal as closeWelcomeModal, openAddDealershipModal,
} from "../../features/MyBusinessDealershipDetail/slice/myBusinessDealershipSlice";

import "./DealershipPopUpCss.css"
import { useEffect } from "react";
import { DeleteDealershipDetails } from "../../../ApiEquip9/CommonApi";
import { deleteDealershipDetails } from "../../services/dealershipListing/dealershipListing";

export const DeleteDealershipPopUp = () => {

    const dispatch = useDispatch();
    const postDealershipData = useSelector(
        (state) => state.myBusinessDealership
    );
    const userData = useSelector((state) =>state.user)
    const guuid = postDealershipData?.guuid ;
    const toast = useToast();

    const handleDelete = ()=>{
        (async()=>{

            try {
                const data = await deleteDealershipDetails(guuid);

                if(data?.Data?.Status === true){
                   
                    toast({
                        title: "",
                        description: `${data?.Message?.SuccessMessage}`,
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                        position: "top-right",
                      });
                    window.location.reload();
                }
                else{
                    toast({
                        title: "",
                        description: `${data?.Message?.SuccessMessage}`,
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                        position: "top-right",
                      });
                    window.location.reload();
                }
            } catch (error) {
                
            }
        })()
     }

    return (
        <>
        <Modal isOpen={postDealershipData.isDeleteOpen} isCentered >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Box textAlign="right">
                        <Button
                            variant="ghost"
                            onClick={() => {
                                dispatch(closeDeleteModal());
                            }}
                            boxShadow="none"
                        >
                            <CloseIcon />
                        </Button>
                    </Box>
                    <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
                    <img src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/delete-primary.svg`} style={{ maxHeight: "40px", maxWidth: "40px" }} />
                    <Heading
                        fontSize="28px"
                        fontWeight="600"
                        fontFamily="Assistant"
                        lineHeight="52.32px"
                    >
                        Are you sure you want to delete this dealership Permannently ?
                    </Heading>
                    </Flex>
                </ModalHeader>

                <ModalBody>

                    <Flex alignItems={"center"} justifyContent={"space-between"} margin={"1em"}>
                    <Button  onClick={handleDelete}>
                        Delete It 
                    </Button>
                    <Button backgroundColor={"#F79C00"} color={"white"} onClick={()=>dispatch(closeDeleteModal())}>
                        Keep It
                    </Button>
                    </Flex>

                </ModalBody>
            </ModalContent>
        </Modal>
        </>
    );
};
