import { Box, Flex } from "@chakra-ui/react";
import { V5SidebarLayout } from "../../../layout/V5SidebarLayout/V5SidebarLayout";
import { Link, useSearchParams } from "react-router-dom";
import { NoPartnerAppliedPlaceholder } from "../../../components/NoPartnerAppliedPlaceholder/NoPartnerAppliedPloceholder";
import { useState, useEffect } from "react";
import { NewPartners } from "../components/newPartners";
import { ConfirmedPartners } from "../components/confirmedPartners";
import { OngoingPartners } from "../components/ongoingPartners";
import { CompletedPartners } from "../components/completedPartners";
import { CancelledPartners } from "../components/cancelledPartners";

const partnerTypes = [
  {
    name: "New Partners",
    link : "new"
  },
  {
    name: "Confirmed Partners",
    link : "confirmed"
  },
  {
    name: "Ongoing Partners",
    link : "ongoing"
  },
  {
    name: "Completed Partners",
    link : "completed"
  },
  {
    name: "Cancelled Partners",
    link: "cancelled"
  },
];

export const JobCycle = () => {
  const [params, setParams] = useSearchParams();
  const [jobId, setJobId] = useState(0);
  const [partnerType, setPartnerType] = useState("");
  const [jobType, setJobType] = useState(0)


  const handleNewJobs = (array = []) => {
    
  }

  useEffect(() => {
    if (params) {
      const paramsArray = params.toString().split("&");
      let paramsObject = {};
      paramsArray.forEach((param) => {
        const key = param.split("=")[0];
        const value = param.split("=")[1];
        paramsObject[`${key}`] = value;
      });
      console.log(paramsObject)
      setJobId(paramsObject.orderId);
      setPartnerType(paramsObject.partnerType);
      setJobType(paramsObject.jobType)
    }
  }, [params]);

  const renderContent = (partnerType) => {
    switch (partnerType) {
      case "new":
        return <NewPartners/>; 
      case "confirmed":
        return <ConfirmedPartners/>; 
      case "ongoing":
        return <OngoingPartners/>; 
      case "completed":
        return <CompletedPartners/>; 
      case "cancelled":
        return <CancelledPartners/>; 
      default:
        return <NoPartnerAppliedPlaceholder orderID={jobId} />;
    }
  };

  return (
    // <V5SidebarLayout>
    //   <div style={{ marginRight: "1rem" }}>
    //     <Flex
    //       boxShadow="0px 4px 4px 0px #2c2c2c20"
    //       border="1px solid #E3E5E8"
    //       width="100%"
    //       justifyContent="space-between"
    //       alignItems="center"
    //       padding="1em 2.5em"
    //       // marginTop="1em"
    //       display="flex"
    //       justifyItems="space-between"
    //     >
    //       {partnerTypes.map(({ name, link }) => {
    //         return (
    //           <Link
    //             key={name}
    //             to={`/job-cycle?orderId=${jobId}&partnerType=${link}`}
    //             style={{
    //               color: name.toLowerCase().includes(jobType) ? "#F79C00" : "",
    //               borderBottom: name.toLowerCase().includes(jobType)
    //                 ? "2px solid #F79C00"
    //                 : "none",
    //               fontWeight: 600,
    //             }}
    //           >
    //             {name}
    //           </Link>
    //         );
    //       })}
    //     </Flex>
    //     <Box marginTop="1rem">

     renderContent(partnerType)
    //     </Box>
    //   </div>
    // </V5SidebarLayout>
  );
};
