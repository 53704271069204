import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  calculateTotalAmountWithoutServiceDiscount,
  calculateTotalDiscountValue,
  calculateTotalLeads,
} from "../helpers/helpers";
import { createCompSubscriptionTransactionDetails } from "../../../services/subscription/subscription";



export const createCompanySubscriptionTransactionThunk = createAsyncThunk(
  "/subscription/createCompanySubscriptionTransaction",
  async (body, { getState, rejectWithValue }) => {
    const {accountDetails } = getState().user;
    const { selectedSubscriptionType} =
      getState().subscription;
    try {
      const companyTransactionBody = {
        TransactionStateId: parseInt(body.transactionStateId),
        SubscriptionTypeId: selectedSubscriptionType.SUBSCRIPTION_ID,
        ListofSelectedService: body.ListofSelectedService,
        CompanyTaxNumber: accountDetails.CompanyDetails.TaxNumber,
        TotalServiceCount: body.ListofSelectedService.toString().split(",").length,
        TotalContactPurchased: calculateTotalLeads(body.body),
        TotalAmountWithoutSD: calculateTotalAmountWithoutServiceDiscount(
          body.body
        ).toFixed(2),
        ServiceDiscountAmount: calculateTotalDiscountValue(body.body).toFixed(
          2
        ),
        TotalAmountAfterDiscount: (
          calculateTotalAmountWithoutServiceDiscount(body.body) -
          calculateTotalDiscountValue(body.body)
        ).toFixed(2),
        NetPayableAmountWithoutTax: (
          calculateTotalAmountWithoutServiceDiscount(body.body) -
          calculateTotalDiscountValue(body.body)
        ).toFixed(2),
      };

      const data = await createCompSubscriptionTransactionDetails(companyTransactionBody);

      if (data.Status === 201) {
        return data.Data;   // update order id
      } else {
        rejectWithValue({ errorMessage: "Some Error occured" });
      }
    } catch (error) {
      rejectWithValue({ errorMessage: "Some Error occured" });
    }
  }
);
