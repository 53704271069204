import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../constants/initialState";
import { getUsedEquipmentSentDataThunk } from "../thunk/getUsedEquipmentSentDataThunk";
import { getUemReceivedData } from "../thunk/getUemReceivedData";
import { getRemSentDataThunk } from "../thunk/getRemSentDataThunk";
import { getRemReceivedDataThunk } from "../thunk/getRemReceivedDataThunk";
import { getTransactionModeDetailsThunk } from "../thunk/getTransactionModeDetailsThunk";
import { getContactRequestListThunk } from "../thunk/getContactRequestListThunk";
import { getEquipmentAvailabilityThunk, getRentalDurationThunk } from "../thunk/getRentalDurationThunk";
import { updateContactStatusThunk } from "../thunk/updateContactStatusThunk";
import { getDealershipReceivedDataThunk, getDealershipSentDataThunk, getMyLeadsDealershipConnectList } from "../thunk/getDealershipSentDataThunk";
import { getMaterialSentDataThunk, getMyLeadsMaterialConnectListThunk } from "../thunk/getMaterialSentDataThunk";
import { getMaterialReceiveDataThunk } from "../thunk/getMaterialReceiveDataThunk";

export const myLeadsSlice = createSlice({
    name: "myLeads",
    initialState: initialState,

    reducers: {
        setData: (state, { payload }) => {
            state.data = payload;
        },
        setE9MarketPlaceId: (state,{payload}) => {
            state.E9MarketPlaceId = payload;
        },
        setModelName: (state,{payload}) => {
            state.ModelName = payload;
        },
        setEquipmentTypeValue: (state,{payload}) =>{
            state.EquipmentTypeValue = payload;
        },
        setAssetId: (state,{payload}) =>{
            state.assetId = payload;
        },
        setOEMName: (state,{payload}) =>{
            state.OEMName = payload;
        },
        openAcceptModal : (state) => {
            state.acceptModal = true;
        },
        closeAcceptModal: (state) =>{
            state.acceptModal = false;
        },
        setImageFileReference : (state,{payload}) => {
            state.ImageFileReference = payload;
        },
        setEquipmentData : (state,{payload}) => {
            state.isMaterialData=false;
            state.equipmentData = payload
        },
        setMaterialData : (state,{payload}) => {
            state.isMaterialData=true;
            state.materialData = payload;
        },
        setRequestTypeData : (state,{payload}) => {
            state.getRequestTypeData = payload;
        },
        setMaterialTypeData :(state,{payload})=>{
            const materialTypeValues = payload.map(item => item.MaterialTypeValue);
            state.materialTypesData=materialTypeValues;
        },
        updateAcceptRejectMaterial: (state, { payload }) => {
            if(state.getContactRequestReceivedList) {
                const material = state.getContactRequestReceivedList.find(m => m.MaterialId === payload.MaterialId);
                if(material) {
                    material.ResponseTypeValue = payload.ResponseTypeValue
                }
            }
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(getUsedEquipmentSentDataThunk.pending, (state) => {
                state.initialLoading=true;
                state.uemSentCardData = null;
            })
        builder
            .addCase(getUsedEquipmentSentDataThunk.fulfilled, (state, { payload }) => {
                state.initialLoading=false;
                state.AssetImageBaseReference = payload.AssetImageBaseReference;
                if (payload?.RequestSentList?.length > 0) {
                    state.uemSentCardData = payload.RequestSentList;
                   
                }
            })
        builder.addCase(getUemReceivedData.pending, (state) => {
            state.initialLoading=true;
            state.uemReceivedCardData = null;
        })
        builder.addCase(getUemReceivedData.fulfilled, (state, { payload }) => {
            state.initialLoading=false;
            if (payload?.RequestReceivedList?.length > 0) {
                state.uemReceivedCardData = payload.RequestReceivedList;
                state.receivedCount = payload.RequestReceivedList.length
               
            }
        })

        builder
            .addCase(getRemSentDataThunk.pending, (state) => {
                state.initialLoading=true;
                state.remSentCardData = null;
            })
        builder
            .addCase(getRemSentDataThunk.fulfilled, (state, { payload }) => {
                state.AssetImageBaseReference = payload?.AssetImageBaseReference;
                state.initialLoading=false;
                if (payload?.RequestSentList?.length > 0) {
                    state.remSentCardData = payload.RequestSentList;
                    
                }
            })
        builder.addCase(getRemReceivedDataThunk.pending, (state) => {
            state.initialLoading=true;
            state.remReceivedCardData = null;
        })
        builder.addCase(getRemReceivedDataThunk.fulfilled, (state, { payload }) => {
            state.initialLoading=false;
            if (payload?.RequestReceivedList?.length > 0 ) {
                state.remReceivedCardData = payload.RequestReceivedList;
                state.receivedCount = payload.RequestReceivedList.length
               
            }
        })
        builder.addCase(getTransactionModeDetailsThunk.pending, (state) =>{
            state.transactionModeDetails = null ;
        })
        builder.addCase(getTransactionModeDetailsThunk.fulfilled, (state,{payload}) =>{
            if(payload && payload.length > 0){
                state.transactionModeDetails = payload ;
            }
        })

        // Get Contact Request Received List
        builder.addCase(getContactRequestListThunk.pending, (state) =>{
            state.initialLoading=true;
            state.getContactRequestReceivedList = null ;
        })
        builder.addCase(getContactRequestListThunk.fulfilled, (state,{payload}) =>{
            state.initialLoading=false;
            if(payload?.ContactRequestReceivedList?.length > 0){
                state.getContactRequestReceivedList = payload.ContactRequestReceivedList ;
            }
        })

        // Accept Handling
        builder.addCase(getRentalDurationThunk.fulfilled, (state,{payload}) =>{
            state.getRentalDurationData = payload;
        })
        builder.addCase(updateContactStatusThunk.fulfilled,(state,{payload}) =>{
            state.contactMessage = payload?.Message?.SuccessMessage
        })
        builder.addCase(getEquipmentAvailabilityThunk.fulfilled,(state,{payload}) =>{
            if(payload?.length > 0){
                state.equipmentAvaibility = payload;
            }
        })

        // Dealership
        builder.addCase(getDealershipSentDataThunk.fulfilled , (state,{payload}) =>{
            state.initialLoading=false;
            if(payload?.length > 0){
                state.dealershipSentCardData = payload
            }
        })
        builder.addCase(getDealershipReceivedDataThunk.fulfilled , (state,{payload}) =>{
            state.initialLoading=false;
            if(payload?.length > 0){
                state.dealershipReceivedCardData = payload
            }
        })
        builder.addCase(getMyLeadsDealershipConnectList.fulfilled , (state,{payload}) =>{
            state.initialLoading=false;
            if(payload?.length > 0){
                state.getContactRequestReceivedList = payload
            }
        })
        //material 
        builder.addCase(getMaterialSentDataThunk.pending, (state) => {
            state.initialLoading=true;
            state.materialSentCardData = [];
        })
        builder.addCase(getMaterialSentDataThunk.fulfilled, (state, { payload }) => {
            state.initialLoading=false;
            if (payload?.Data?.SentRequestDetails?.length > 0) {
                state.materialSentCardData = payload?.Data?.SentRequestDetails;
               
            }
        })
        builder.addCase(getMaterialSentDataThunk.rejected, (state) => {
            state.initialLoading=false;
            state.materialSentCardData = [];
        })

        builder.addCase(getMaterialReceiveDataThunk.pending, (state) => {
            state.initialLoading=true;
            state.materialReceivedCardData = [];
         
        })
        builder.addCase(getMaterialReceiveDataThunk.fulfilled, (state, { payload }) => {
            state.initialLoading=false;
            if (payload?.Data?.ReceivedRequestDetails?.length > 0) {
                state.materialReceivedCardData = payload?.Data?.ReceivedRequestDetails;
               
            }
        })
        builder.addCase(getMaterialReceiveDataThunk.rejected, (state) => {
            state.initialLoading=false;
            state.materialReceivedCardData = [];
        })

        builder.addCase(getMyLeadsMaterialConnectListThunk.pending, (state) => {
            state.initialLoading=true;
            state.getContactRequestReceivedList = [];
         
        })
        builder.addCase(getMyLeadsMaterialConnectListThunk.fulfilled, (state, { payload }) => {  
            state.initialLoading=false;
            if (payload?.Data?.ReceivedRequestDetailsByMaterialId?.length > 0) {
                const data=payload?.Data?.ReceivedRequestDetailsByMaterialId
                const updatedData = data.map(obj => ({
                    ...obj,
                    Message:`Mr. ${obj?.SenderName} has requested to contact you for ${obj?.materialTypeValue}`,
                    SenderContactNumber :obj?.mobileNumber,
                    equipmentImage:
                        obj?.ImageList
                        ? JSON.parse(obj.ImageList).find(obj => obj.ImageTypeId === 1)?.ImageFileRef
                        : null
                      ,
                    isMaterialLead:1
                  }));
                state.getContactRequestReceivedList=updatedData;
            }
        })
        builder.addCase(getMyLeadsMaterialConnectListThunk.rejected, (state) => {
            state.initialLoading=false;
            state.getContactRequestReceivedList = [];
        })
    }
})

export const {
    setData,
    setE9MarketPlaceId,
    setModelName,
    setEquipmentTypeValue,
    setAssetId,
    setOEMName,
    openAcceptModal,
    closeAcceptModal,
    setImageFileReference,
    setEquipmentData,
    setMaterialData,
    setRequestTypeData,
    setMaterialTypeData,
    updateAcceptRejectMaterial
} = myLeadsSlice.actions