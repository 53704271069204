import './ListingInformation.css'
import React from "react";
import { ReactSVG } from 'react-svg';
import { useLocation,useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { useSelector } from "react-redux";
import { useQuery} from "react-query";
import { getMaterialConnectsCountforMarketplace,
    getMaterialConnectsPendingCountforMarketplace,
    getMaterialLikesCountforMarketplace,
    getMaterialConnectsRevievedforMarketplace,
    getE9FreeMaterialAvilableforUser
 } from '../../../../../ApiEquip9/CommonApi';

export const ListingInformation = () => {
    const vai=useParams();
    const userData = useSelector((state) => state.user);
    const { MaterialTypeId } = useParams();
    const {data:Requestsreceivedonmarketplace}=useQuery("getRequestrecievemarketplace",()=>getMaterialConnectsCountforMarketplace(userData.token,MaterialTypeId)); 
    const {data:PendingCountforMarketPlace}=useQuery("pendingCountforMarketPlace",()=>getMaterialConnectsPendingCountforMarketplace(userData.token,MaterialTypeId));
    const {data:LikesCountforMarketplace}=useQuery("likesCountforMarketplace",()=>getMaterialLikesCountforMarketplace(userData.token,MaterialTypeId));
    const {data:getMaterialConnectsPendingCountforMarketplace}=useQuery("GEtMaterialConnectsPendingCountforMarketplace",()=>getMaterialConnectsRevievedforMarketplace(userData.token,MaterialTypeId));
    const {data:GetE9FreeMaterialAvilableforUser}=useQuery("GETE9FreeMaterialAvilableforUser",()=>getE9FreeMaterialAvilableforUser(userData.token));
    

    /***************request Recieved  */
    //  get Count
    const getRequestsreceivedonmarketplace=Requestsreceivedonmarketplace?.data?.Data?.ConnectsCount[0]?.ConnectsCount;
    const getLikesCountforMarketplace=LikesCountforMarketplace?.data?.Data?.LikesCount[0]?.LikeCount;
    const getPendingCountforMarketPlace=PendingCountforMarketPlace?.data?.Data?.ConnectsPendingCount[0]?.ConnectsPendingCount;
    const connectsPendingCountforMarketplace=getMaterialConnectsPendingCountforMarketplace?.data?.Data?.RequestReceivedCount[0]?.RequestReceivedCount;

    // get Analytics
    const getFreeMaterial=GetE9FreeMaterialAvilableforUser?.data?.Data?.E9FreeServicesForUserList;


    // const {}
    // console.log({})
  return(
   <div>
    <div className="listing-layout">
       <div className='free-listing-layout'>
        <p className='Subscription-Remaining'>Free Listings Remaining - <span>25</span></p>
        <div className='listing-progress-bar'></div>
        <div className='listing-progress-bar-number'>
            <p>00</p>
            <p>00</p>
        </div>
       </div>
       <div className='free-listing-layout'>
        <p ><span className='Subscription-Remaining'>Subscription Remaining</span> - <span className='Subscription-Remaining'>00</span></p>
        <div className='listing-progress-bar-subscription'></div>
        <div className='listing-progress-bar-number'>
            <p>00</p>
            <p>00</p>
        </div>
       </div>
       <div className='request-recieve-block'>
        <div className='up-down-request'>
         <ReactSVG
        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Requestdown.svg`} />
                 <ReactSVG
        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Requestdown.svg`} />
        <p className='list-quantity-text'>{getRequestsreceivedonmarketplace}</p>
      
        </div>
        <div>
        <div >
            <p className='request-text'>Requests Received on Marketplace</p>
        </div>
        </div>
       </div>
       <div className='request-recieve-block'>
        <div className='up-down-request'>
         <ReactSVG
        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Requestdown.svg`} />
                 <ReactSVG
        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/RequestUp.svg`} />
        <p className='list-quantity-text'>{connectsPendingCountforMarketplace}</p>
      
        </div>
        <div>
        <div >
            <p className='request-text'>Connects on Marketplace</p>
        </div>
        </div>
       </div>
       <div className='request-recieve-block'>
        <div className='up-down-request'>
         <ReactSVG
        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/RequestArrowupRed.svg`} />
                 <ReactSVG
        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/RequestArrowupRed.svg`} />
        <p className='list-quantity-text'>{connectsPendingCountforMarketplace}</p>
      
        </div>
        <div>
        <div >
            <p className='request-text'>Connects Pending</p>
        </div>
        </div>
       </div>
       <div className='request-recieve-block'>
        <div className='up-down-request'>
         <ReactSVG
        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Likerequest.svg`} />

        <p className='list-quantity-text'>{getLikesCountforMarketplace}</p>
      
        </div>
        <div>
        <div >
            <p className='request-text'>Likes on Rental Marketplace</p>
        </div>
        </div>
       </div>
    </div>
  </div>);
};
