
import { API_CONSTANTS } from "../../../constants/apiConstants";
import {fetchHandler} from "../../../index";

export const startWorkOTP = async (body) => {
    try {
      const {data}= await fetchHandler("/StartUpcomingJobForOperatorMaintenance",{},{},body,API_CONSTANTS.HTTP_PUT);
      return data;
  
    } catch (error) {
      throw error
    }
  }

  export const endWorkOTPMP = async (body) => {
    try {
    
    const {data}= await fetchHandler("/EndOngoingJobForOperatorMaintenance",{},{},body,API_CONSTANTS.HTTP_PUT);

    return data;

    }
    catch (error) {
      throw error.response
    }
  }


  