import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "@chakra-ui/icons";
import { closeAcceptModal } from "../../../features/myLeads/Slice/myLeadsSlice";
import "./AcceptRejectModal.css";
import { SelectCardWrapper } from "../../SelectCardWrapper/SelectCardWrapper";
import { updateContactStatusThunk } from "../../../features/myLeads/thunk/updateContactStatusThunk";
import { getContactRequestListThunk } from "../../../features/myLeads/thunk/getContactRequestListThunk";
import { getResponseTypeDetails } from "../../../services/materials/materials";
import { CustomModal } from "../../CustomModal/CustomModal";

export const AcceptRejectModal = () => {
  const dispatch = useDispatch();
  const { acceptModal } = useSelector((state) => state.myLeads);
  const {
    equipmentData,
    getRequestTypeData,
    contactMessage,
    equipmentAvaibility,
  } = useSelector((state) => state.myLeads);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [responseTypeId, setResponseTypeId] = useState();
  const [responseTypeDetails, setResponseTypeDetails] = useState(null);

  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getResponseTypeDetails();
        setResponseTypeDetails(data?.ResponseTypes);
      } catch (error) {
        console.error("Error fetching response type details:", error);
      }
    };
    fetchData();
  }, []);

  const createPayload = async (selectedEquipment) => {
    let idToSent = null;
    await responseTypeDetails.find((response) => {
      if (response?.ResponseTypeId === selectedEquipment) {
        if (
          response?.ResponseTypeValue === "Request Sent" ||
          response?.ResponseTypeValue === "Request Accepted"
        ) {
          idToSent = responseTypeDetails.find(
            (item) => item?.ResponseTypeValue === "Request Accepted"
          );
        } else if (response?.ResponseTypeValue === "Request Rejected") {
          idToSent = responseTypeDetails.find(
            (item) => item?.ResponseTypeValue === "Request Rejected"
          );
        }
      }

      return idToSent !== null;
    });

    const payload = {
      E9MarketPlaceContactResponseStatus: {
        E9MarketPlaceContactId: getRequestTypeData?.E9MarketPlaceContactId,
        ResponseTypeId: idToSent?.ResponseTypeId,
      },
    };
    return payload;
  };
  const handleSubmitClick = async () => {
    const payload = await createPayload(selectedEquipment);
    await dispatch(updateContactStatusThunk(payload));

    if (contactMessage) {
      toast({
        title: "Request Accepted Successfully",
        position: "top-right",
        status: "success",
      });
      dispatch(closeAcceptModal());
      dispatch(getContactRequestListThunk());
    } else {
      toast({
        title: "Request Accepted Successfully",
        position: "top-right",
        status: "success",
      });
      dispatch(closeAcceptModal());
      dispatch(getContactRequestListThunk());
    }
  };
  return (
    <CustomModal
      isOpen={acceptModal}
      hasCloseButton={true}
      onCloseButtonClick={() => {
        setResponseTypeId("");
        setSelectedEquipment("");
        dispatch(closeAcceptModal());
      }}
    >
      <CustomModal.Header textAlign="center">
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
          gap={"16px"}
        >
          <img
            style={{
              maxHeight: "50px",
              maxWidth: "50px",
              height: "50px",
              width: "50px",
            }}
            src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/connectIcon.svg`}
          />
          <Text
            textAlign="center"
            fontWeight={700}
            fontSize="20px"
            color={"#1A1E21"}
          >
            {"Equipment Availability"}
          </Text>
        </Box>
      </CustomModal.Header>
      <CustomModal.Body >
        <Flex direction={"column"} justifyContent={"center"} alignItems={"center"}>
        <Flex direction="column" gap="1em" maxW={"338px"}>
          <div
            className="accept-reject-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="accept-reject-card__section">
              <div className="accept-reject-card__image-overlay">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "98%",
                    margin: "0 auto",
                    marginTop: "0.15em",
                  }}
                ></div>

                <div className="accept-reject__details-section">
                  <p className="accept-reject__details-section__text">
                    {equipmentData?.E9MarketPlaceUniqueId}&bull;
                    {equipmentData?.EquipmentTypeValue}&bull;
                    {equipmentData?.OEMName}&bull;
                    {equipmentData?.ModelName}
                  </p>
                </div>
              </div>

              <div className="accept-reject__section--image ">
                <img
                  className="accept-reject__section--image__image"
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/DelarshipDefaultImage.png`}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                  }}
                />
              </div>
            </div>
          </div>
          <Flex direction="column" gap="0.50em"  justifyContent={"center"}
        alignItems={"center"}>
            {equipmentAvaibility
              ? equipmentAvaibility.map((option) => (
                  <SelectCardWrapper
                  padding="5px 20px"
                  width="338px"
                  height="57px"
                    key={option.EquipAvailabilityId}
                    value={option.EquipAvailabilityValue}
                    isSelected={
                      selectedEquipment === option?.EquipAvailabilityId
                    }
                    onClick={() =>
                      setSelectedEquipment(option?.EquipAvailabilityId)
                    }
                    display="flex"
                    flexdirection="column"
                    gap={"0.2em"}
                  >
                    <Heading
                      width="fit-content"
                        fontSize="16px"
                      fontWeight="semibold"
                    >
                      {option.EquipAvailabilityValue}
                    </Heading>
                    <Text width="fit-content" fontSize="14px" color="#002961">
                      {option.Edescription}
                    </Text>
                  </SelectCardWrapper>
                ))
              : null}
          </Flex>
        </Flex>
        </Flex>
      </CustomModal.Body>
     
      <CustomModal.Footer boxShadow={"0px -10px 10px 0px #2C2C2C0F"}>
        <Flex 
        justifyContent={"center" } 
        width="100%">
        
          <Button
             variant="gradient"
            onClick={handleSubmitClick}
            isDisabled={!selectedEquipment}
          >
            Next
          </Button>
        </Flex>
      </CustomModal.Footer>
    </CustomModal>
  );
};
