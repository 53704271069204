import React from "react";
import { v4 as uuid } from "uuid";
import "./DealershipCard.css";
import defaultImage from "../../../assets/webapp/marketplace/defaultAssetImage.png";
import { Like } from "../Like/Like";
import { subString } from "../../helpers/dataConversion/substring";
import { Text, Tooltip } from "@chakra-ui/react";

const DealershipCard = ({
  imageUrl,
  isVerified,
  equipmentStatusValue,
  like,
  isPromoted,
  location,
  dealerName,
  financeProvidedValue,
  dealershipUniqueId,
  listedDateTime,
  onNavigateClick = () => {},
  onLikeClick = () => {},
  deferredHeader,
  deferredLocation,
  isDeferred = false,
  onDeferredClick = () => {},
}) => {
  const isDateNew = (dateStr) => {
    const formattedDateStr = dateStr
      .replace(",", "")
      .replace("th-", "-")
      .split(" ")
      .slice(0, 5)
      .join(" ");
    const parsedDate = new Date(formattedDateStr);

    const today = new Date();
    const daysDifference =
      Math.abs(today.getTime() - parsedDate.getTime()) / (1000 * 3600 * 24);

    return daysDifference < 5;
  };
  const newlyAdded = isDateNew(listedDateTime);

  const handleLikeClick = (event) => {
    event.stopPropagation();
    onLikeClick();
  };

  return (
    <div
      className="dealership-container"
      key={uuid()}
      style={{ cursor: "pointer" }}
    >
      <div className="dealership-blog-post-card1" onClick={onDeferredClick}>
        {!isDeferred ? (
                  <div className="dealership-header">
                  <div
                    className="verification-badge"
                    style={{ background: isVerified ? "#319F0060" : "#D6000060" }}
                  >
                    <p>{isVerified ? "Verified" : "Not Verified"}</p>
                  </div>
                  {/* <button >
                    <ReactSVG src={likeIconUnliked}
                      beforeInjection={(svg) => {
                        svg.setAttribute('style', 'height: 27px')
                      }}
                    />
                  </button> */}
                  <button>
                    <Like isLiked={like} onClick={onLikeClick} />
                  </button>
                </div>
        ) : null}
        <div className="dealership-image-container" onClick={onNavigateClick}>
        {!isDeferred ? (
                    <div className="dealership-image-status-tag">
                    <h1>{equipmentStatusValue}</h1>
                  </div>
        ) : null}
          <img
            alt="image"
            src={
              imageUrl
                ? imageUrl
                : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/DelarshipDefaultImage.png`
            }
            className="dealership-image"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = defaultImage;
            }}
          />
        </div>
        <div className="dealership-container1" onClick={onNavigateClick}>
            {!isDeferred ? (
                        <div className="dealership-container2">
                        <span className="dealership-text">
                          {isPromoted ? "Promoted" : "Not Promoted"}
                        </span>
                        {newlyAdded && (
                          <span className="dealership-new-added">
                            {newlyAdded ? "New Added" : ""}
                          </span>
                        )}
                      </div>
            ) : (
              <Text color="#002961" fontSize="20px" fontWeight="700">{subString(18,`${deferredHeader}`)}</Text>
            )}

          <div className="dealership-text-container-wrapper">
           {!isDeferred ? (
             <p className="dealership-text-label">
             Location :
             <span className="dealership-text3">
               {location
                 ? location.length > 0
                   ? `${location.substring(0, 22)}...`
                   : location
                 : "NA"}
             </span>
           </p>
           ) : (
            <Text color="#333" fontSize="18px" fontWeight="600">{subString(18,deferredLocation)}</Text>
           )}

           {!isDeferred ? (
                        <Tooltip label={dealerName.length > 20? dealerName: null} >
                        <p className="dealership-text-label">
                          Name :<span className="dealership-text3">{dealerName ? dealerName.length > 0 ? subString(18,dealerName):dealerName : "NA"}</span>
                        </p>
                        </Tooltip>
           ) : null}

           {!isDeferred ? (
                        <p className="dealership-text-label">
                        Finance Available :
                        <span className="dealership-text3">
                          {financeProvidedValue ? "Yes" : "No"}
                        </span>
                      </p>
           ) : (
            <Text>Finance Available : <Text as="span" color="#484848" fontSize="16px" fontWeight="600">{financeProvidedValue} </Text></Text>
           )}

           {!isDeferred ? (
                        <p className="dealership-text-label">
                        ID :<span className="dealership-text3">{dealershipUniqueId}</span>
                      </p>
           ) : null}

           {!isDeferred ? (
                        <p className="dealership-text-label">
                        Added On :
                        <span className="dealership-text3">
                          {listedDateTime.split(",")[1].trim()}
                        </span>
                      </p>
           ) : (
            <Text>Added On : <Text as="span" color="#484848" fontSize="16px" fontWeight="600">
            {listedDateTime.split(",")[1].trim()}
        </Text></Text>

           )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealershipCard;
