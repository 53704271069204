import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import marketplaceIcon from "../../../assets/webapp/pop-up/marketplace-icon.svg";
import { SelectCardWrapper } from "../SelectCardWrapper/SelectCardWrapper";
import { Link } from "react-router-dom";
import { CloseIcon } from "@chakra-ui/icons";
import usedEquipmentIcon from "../../../assets/webapp/common/used-equipment.svg";
import rentalEquipmentIcon from "../../../assets/webapp/common/rental-equipment.svg";
import { CustomModal } from "../CustomModal/CustomModal";
import { CustomGeneralModal } from "../CustomGeneralModal/CustomGeneralModal";

export const AddToMarketplacePopUp = ({
  primaryButtonText = "",
  heading = "",
  iconUrl ,
  isOpen,
  onCloseButtonClick = () => {},
  freeListingCount = 0,
  subscriptionListingCount = 0,
  premiumListingCount = 0,
  onUsedMarketplace = false,
  onRentalMarketplace = false,
  listedDateRental = "",
  listedDateUsed = "",
  usedButtonClick = () => {},
  rentalButtonClick = () => {},
  isEquipment = false,
  onSubmitButtonClick = (selectedLeadType) => {},
}) => {
  const toast = useToast();
  const [selectedLeadType, setSelectedLeadType] = useState("none");

  useEffect(() => {
    if (freeListingCount > 0) {
      setSelectedLeadType("free");
    } else if (subscriptionListingCount > 0) {
      setSelectedLeadType("subscription");
    } else {
      setSelectedLeadType("none");
    }
  }, [freeListingCount, subscriptionListingCount]);

  return (
    <CustomModal isOpen={isOpen} 
    w=""
    hasCloseButton={true}
    onCloseButtonClick={onCloseButtonClick}>
    
        <CustomModal.Header>
        <Flex flexDirection="column" gap={"16px"}>
     
            <Flex justifyContent="center" alignItems="center" padding={"5px"}>
              <img style={{ height: "50px" }} src={iconUrl ? iconUrl :  marketplaceIcon} />
            </Flex>
      
           <Text textAlign="center" fontWeight={700} fontSize="20px" color={"#1A1E21"}>
           {heading ? heading : "Marketplace"}
            </Text> 
         
          </Flex>
        </CustomModal.Header>
        <CustomModal.Body minWidth={"350px"} margin={"0 50px"}>
          <Flex justifyContent="center" alignItems="center">
            <CheckboxGroup value={[selectedLeadType]}>
              <Flex flexDirection="column" gap="1em" width="100%">
                <Flex width="100%" justifyContent="space-between">
                  <Checkbox
                    fontWeight={700}
                    isDisabled={!subscriptionListingCount}
                    colorScheme="green"
                    value="subscription"
                    onChange={(e) => {
                      setSelectedLeadType(
                        e.target.checked ? "subscription" : "none"
                      );
                    }}
                  >
                    Your Current Subscription
                  </Checkbox>
                  <Text>{subscriptionListingCount}</Text>
                </Flex>
                <Flex width="100%" justifyContent="space-between">
                  <Checkbox
                    fontWeight={700}
                    isDisabled={!freeListingCount}
                    colorScheme="green"
                    value="free"
                    onChange={(e) => {
                      setSelectedLeadType(e.target.checked ? "free" : "none");
                    }}
                  >
                    Free Listing Available
                  </Checkbox>
                  <Text>{freeListingCount}</Text>
                </Flex>
                <Flex width="100%" justifyContent="space-between">
                  <Checkbox
                    fontWeight={700}
                    isDisabled={!premiumListingCount}
                    colorScheme="green"
                    value="premium"
                    onChange={(e) => {
                      setSelectedLeadType(e.target.checked ? "premium" : "none");
                    }}
                  >
                    Your Premium Available
                  </Checkbox>
                  <Text>{premiumListingCount}</Text>
                </Flex>
              </Flex>
            </CheckboxGroup>
          </Flex>

          {isEquipment ? (
            <Flex
              flexDirection="column"
              gap="1em"
              justifyContent="center"
              alignItems="center"
            >
              <SelectCardWrapper
                maxWidth="338px"
                width="100%"
                height="57px"
                padding="0px"
                icon={
                  <img
                    src={usedEquipmentIcon}
                    style={{ height: "90%", width: "90%" }}
                  />
                }
                onClick={() => {
                  if (!selectedLeadType || selectedLeadType === "none") {
                    toast({
                      status: "error",
                      description: "Please select type of lead",
                      position: "top-right",
                      isClosable: true,
                    });
                    return;
                  }
                  if (onUsedMarketplace) {
                    toast({
                      status: "error",
                      description: "Equipment already listed",
                      position: "top-right",
                      isClosable: true,
                    });
                    return;
                  }
                  usedButtonClick({
                    lead: selectedLeadType,
                    marketplace: "used",
                  });
                }}
              >
                <Text fontSize="18px" fontWeight="600">
                  Used Equipment
                </Text>
                {onUsedMarketplace && (
                  <Text fontSize="14px" fontStyle="italic">
                    Listed on : <p style={{fontWeight:"700",display:"inline-block"}}>{listedDateUsed.split(',')[1].trim()}</p>
                  </Text>
                )}
              </SelectCardWrapper>
              <SelectCardWrapper
                icon={
                  <img
                    src={rentalEquipmentIcon}
                    style={{ height: "90%", width: "90%" }}
                  />
                }
                maxWidth="338px"
                width="100%"
                height="57px"
                 padding="0px"
                onClick={() => {
                  if (!selectedLeadType || selectedLeadType === "none") {
                    toast({
                      status: "error",
                      description: "Please select type of lead",
                      position: "top-right",
                      isClosable: true,
                    });
                    return;
                  }
                  if (onRentalMarketplace) {
                    toast({
                      status: "error",
                      description: "Equipment already listed",
                      position: "top-right",
                      isClosable: true,
                    });
                    return;
                  }
                  rentalButtonClick({
                    lead: selectedLeadType,
                    marketplace: "rental",
                  });
                }}
              >
                <Text fontSize="18px" fontWeight="600">
                  Rental Equipment
                </Text>
                {onRentalMarketplace && (
                  <Text fontSize="14px" fontStyle="italic">
                    Listed on : <p style={{fontWeight:"700",display:"inline-block"}}>{listedDateRental.split(',')[1].trim()}</p>
                  </Text>
                )}
              </SelectCardWrapper>
            </Flex>
          ) : null}
        </CustomModal.Body>
        <CustomModal.Footer>
          {!freeListingCount && !subscriptionListingCount && !premiumListingCount? (
            <Text fontWeight="700">
              Buy More Subscription{" "}
              <Link
                style={{ fontWeight: "700", color: "#002961" }}
                to="/subscription/subscription-type"
              >
                Click Here
              </Link>
            </Text>
          ) : !isEquipment ? (
            <Flex alignItems={"center"} justifyContent={"center"}>
              <Button
                variant="gradient"
                onClick={() => {
                  onSubmitButtonClick(selectedLeadType)  
                }}
                isDisabled={selectedLeadType=== "none" ? true:false}
              >
                {primaryButtonText ? primaryButtonText : "getSubscriptionLeadsCheck"}
              </Button>
            </Flex>
          ) : (
            <></>
          )}
        </CustomModal.Footer>
  
    </CustomModal>
  );
};
