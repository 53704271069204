import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    borderRadius: "4px",
    border: "1px solid",
    borderColor: "#2c2c2c",
    bg: "#2C2C2C05",
    fontSize: "20px",
    lineHeight: "30px",
    // padding: '16px 13px 16px 20px',
    h: "52px",
    // boxShadow: "0 0 0 1px #000",
    _focusWithin: {
      ringColor: "black.200",
      ring: "2px",
      ringOffset: "1px",
      ringOffsetColor: "black.100",
      border: "1px solid",
      borderColor: "#2c2c2c",
      boxShadow: "0 0 0 1px #000",
    },
    _focusVisible: {
      ringColor: "black.200",
      ring: "2px",
      ringOffset: "1px",
      ringOffsetColor: "black.100",
      border: "1px solid",
      borderColor: "#2c2c2c",
      boxShadow: "0 0 0 1px #000",
    },
    _focus: {
        ringColor: "black.200",
        ring: "2px",
        ringOffset: "1px",
        ringOffsetColor: "black.100",
        border: "1px solid",
        borderColor: "#2c2c2c",
        boxShadow: "0 0 0 1px #000",
    }
  },
});

export const selectTheme = defineMultiStyleConfig({ baseStyle });
