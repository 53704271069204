import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";

export const FormInput = ({
  label,
  type,
  placeholder,
  isDisabled,
  isRequired,
  isInvalid,
  name,
  value,
  errorMessage = " ",
  onChange,
  maxLength,
  backgroundColor = ""
}) => {
  return (
    <FormControl
      isDisabled={isDisabled}
      isInvalid={errorMessage.trim() === "" ? false : true}
      isRequired={isRequired}
    >
      <FormLabel>{label}</FormLabel>
      <Input
        type={type}
        placeholder={placeholder}
        isInvalid={isInvalid}
        value={value}
        name={name}
        onChange={onChange}
        maxLength={maxLength}
        h="52px"
        border="1px solid #2c2c2c14"
        focusBorderColor="#2c2c2c"
        bgColor={value ? "#fff" : backgroundColor ? backgroundColor : "#2c2c2c05"}
      />

      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
