import React, { useState, useEffect ,useRef} from 'react';
import { ReactSVG } from 'react-svg';
import { useSelector } from 'react-redux';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { deleteEducationalDocument, getEducationalDetail } from '../../../../../../../../../../ApiEquip9/CommonApi';
import './OpgetEducationDetails.css';
import { LoadingSkelaton } from '../../../../../../../../../components/LoadingSkaleton/LoadingSkelton';
export const OpgetEducationDetails = ({ triggerRefresh }) => {
    const userData = useSelector((state) => state.user);
    const queryClient = useQueryClient();
    const [confirmationDialog, setConfirmationDialog] = useState(false);
    const [documentToDelete, setDocumentToDelete] = useState(null);
    const [isDocumentPopupOpen, setIsDocumentPopupOpen] = useState(false);
    const [documentImageUrl, setDocumentImageUrl] = useState('');
    // const [documentPath, setDocumentPath] = useState('');
    const [documents, setDocuments] = useState([]);
    const [documentType, setDocumentType] = useState('');
    const yesButtonRef = useRef(null);
    const noButtonRef=useRef(null);


    const { data: getEducationDetails, isLoading, isError  } = useQuery(
        'getEducationDetails',
        () => getEducationalDetail(userData.token)
    );

    const GetEducationDetailsList = getEducationDetails?.data?.Data?.EducationDocumentDetails;

    useEffect(() => {
        if (GetEducationDetailsList) {
            setDocuments(GetEducationDetailsList);
        }
    }, [GetEducationDetailsList]);

    const deleteVerificationMutation = useMutation(
        (EducationalDocumentId) => deleteEducationalDocument(EducationalDocumentId, userData.token),
        {
            onMutate: async (deletedId) => {
                await queryClient.cancelQueries('getEducationDetails');
                const previousData = queryClient.getQueryData('getEducationDetails');

                if (previousData) {
                    queryClient.setQueryData('getEducationDetails', (oldData) => {
                        const newData = { ...oldData };
                        newData.data.Data.EducationDocumentDetails = newData.data.Data.EducationDocumentDetails.filter(
                            (item) => item.EducationalDocumentId !== deletedId
                        );
                        return newData;
                    });
                }
                return { previousData };
            },
            onError: (err, variables, context) => {
                if (context?.previousData) {
                    queryClient.setQueryData('getEducationDetails', context.previousData);
                }
            },
            onSuccess: () => {
                setDocuments(prevDocs => prevDocs.filter(item => item.EducationalDocumentId !== documentToDelete));
            },
            onSettled: () => {
                queryClient.invalidateQueries('getEducationDetails');
                triggerRefresh();
            },
        }
    );

    const openDocumentPopup = (imageUrl) => {
        const fileExtension = imageUrl.split('.').pop().toLowerCase();
        if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
            setDocumentType('image');
        } else if (fileExtension === 'pdf') {
            setDocumentType('pdf');
        } else {
            return;
        }
        setDocumentImageUrl(imageUrl);
        setIsDocumentPopupOpen(true);
    };

    const closeDocumentPopup = () => {
        setIsDocumentPopupOpen(false);
    };

    const openConfirmationDialog = (EducationalDocumentId) => {
        setDocumentToDelete(EducationalDocumentId);
        setConfirmationDialog(true);
    };

    const closeConfirmationDialog = () => {
        setDocumentToDelete(null);
        setConfirmationDialog(false);
    };

    const deleteDocument = async () => {
        if (documentToDelete) {
            await deleteVerificationMutation.mutateAsync(documentToDelete);
            closeConfirmationDialog();
        }
    };


    const formatDate = (dateStr) => {
        const [year, month, day] = dateStr.split("-");
        return `${day}-${month}-${year.slice(-2)}`;
    };

    return (
        <>
            {isLoading ? (
                <div style={{margin:"33px 33px"}}><LoadingSkelaton/></div>
            ) : isError ? (
                <div>Error fetching data</div>
            ) : (
                <>

                {
                    documents?.length>0?(<>
                    
                    <div className='card'>
                    {documents?.map((item) => (
                        <React.Fragment key={item.EducationalDocumentId}>
                        <div className='card-information'>
                      <div >
                        <div className='added-date-and-verified'> 
                        <div><span className='added-on'>Added On:</span><span className='added-on-date'>{formatDate(item.AddedOn.split(' ')[0])}</span></div>
                        <div style={{ 
                          height:"23px",width:"75px",borderRadius:"23px",fontSize:"10px",textAlign:'center',paddingTop:'5px',
                        background: item.IsE9Verified
                        === 0 ? 'rgba(214, 0, 0, 0.376)' : '#319f00', color:"white"
                        
                    }}>{item.IsE9Verified === 0 ? 'Not Verified' : 'Verified'}</div>
                        </div>
                              <div className='added-card-informatio'>
                              <div>
                                <img  style={{ filter: 'blur(5px)' }}  
                                 src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Rectangle.png`} />
                            </div>
                            <div>
                            <div className='education-support-block'>
                                <div className='get-document-name'>
                                    <div className='hero-document'>Education Document:</div>
                                    <div  className='value-document-name'>
                                    {item.DegreeName}
                                    </div>
                                     
                                </div>
                                <div className='get-document-country-name'>
                                    <div className='hero-document'>Country Name :</div>
                                    
                                    <div className='value-document-name'>
                                    {item.CountryName}
                                    </div>
                                </div>
                               
                            </div>
                        
                            </div>
                        
                      </div>
                      <div>
                        <div className='delete-upload-education-doc' >
                                 
                                    <div
                                        className='view-document-detai'
                                        onClick={() => openDocumentPopup(`${process.env.REACT_APP_CDN_PVT_URL}EducationalDocument/${item.DocumentFileReference}`)}
                                    >
                                        View Document
                                    </div>
                                     <div
                                      className='delete-icon'
                                       onClick={() => openConfirmationDialog(item.EducationalDocumentId)}>
                                        {/* <p>Hello</p> */}
                                    <ReactSVG
                                        src={`${process.env.REACT_APP_CDN_STATIC_URL}/Webapp/Advertisements/LargeAdvertisments/icons/garbagee.svg`}
                                    />
                                </div>
                                </div>
                            </div>
                      </div>
                
                      </div>
                      
                      <div>
                      </div>
                        </React.Fragment>
                    ))}
                    </div>
                    </>):(<>
                    <p className='no-education-background'>No Education Certificate Added Yet</p>
                    
                    </>)

                }
                
                  
                    {
                     confirmationDialog && (
                      <div className='modal-backdrop'>
                          <div className='confirmation-dialog'>

                          <div className='deleteHeading'>
                            <ReactSVG
                                          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/HeadDelete.svg`}
                                      />
                            </div>
                            <div style={{marginTop:"12px"}}>
                            <p className='conformation-text'>Are you sure you want to delete this document?</p>
                            </div>
                              
                              
                              <button 
                                  ref={yesButtonRef}
                                  style={{
                                      background: '#C6C7C8',
                                      transition: '0.3s'
                                  }}
                                  onMouseOver={() => {
                                      yesButtonRef.current.style.background = 'linear-gradient(90deg, var(--primary), var(--tertiary))';
                                  }}
                                  onMouseOut={() => {
                                       yesButtonRef.current.style.background = '#C6C7C8';
                                  }}
                                  onClick={deleteDocument}
                              >
                                  Yes
                              </button>
              
                              <button 
                               ref={noButtonRef}
                               style={{
                                background: '#C6C7C8',
                                transition: '0.3s'
                            }}
                            onMouseOver={() => {
                                noButtonRef.current.style.background = 'linear-gradient(90deg, var(--primary), var(--tertiary))';
                            }}
                            onMouseOut={() => {
                                noButtonRef.current.style.background = '#C6C7C8';
                            }}
                                  onClick={closeConfirmationDialog}
                              >
                                  No
                              </button>
                          </div>
                      </div>
                  )
                }

                    {isDocumentPopupOpen && (
                        <div className='document-popup-container'>
                            <div className='document-popup'>
                                <div><p style={{font:'normal normal bold 18px/22px Assistant'}}>Education Details</p></div>
                                {documentType === 'pdf' ? (
                                    <embed src={documentImageUrl} type="application/pdf" width="100%" height="200px" />
                                ) : (
                                    <img
                                        src={documentImageUrl}
                                        alt='Document'
                                        style={{ width: '300px', height: '200px' }}
                                    />
                                )}
                                <button className='close-button' onClick={closeDocumentPopup}>
                                <ReactSVG
                                        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Cross.svg`}
                                    />
                                </button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}
