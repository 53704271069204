import defaultImage from "../../../assets/webapp/marketplace/defaultAssetImage.png";
import "./Sticky.css";

export const Sticky = ({ imageUrl, heading, highlightedText, subHeading, onClick = () => {} }) => {
  return (
    <div className="sticky" style={{ backgroundImage: `url(${imageUrl})` }}>
      <div className="sticky__container">
        <h1 className="sticky__heading">{heading}</h1>
        <p className="sticky__highlighted-text">{highlightedText}</p>
        <p className="sticky__sub-heading">{subHeading}</p>
        <button className="sticky__cta" onClick={onClick}>Know More</button>
      </div>
    </div>
  );
};