export const initialState = {
    data: null,
    category: "industry",
    paginationCount: 0,
    endOfData: false,
    cardCount: 12,
    initialLoading: false,
    paginationLoading: false,
    error: null,
    errorMessage: null,
    assetImageReference : null,


    isFilterOpen : false,
    filteredDataRequired : false,
    filterByCountry: "",
    filterByState: "",
    filterByCity :"",
    filterByInputMethod:"",
    filterByCategory:"",
    filterByTimeRange: "",

    isDeletePopUpopen : false,
    isReportPopUpopen : false,
    selectInputTypeModal : false,

    inputMethodId : "",
    requirementCategoryId : "",
    requirementCategoryValue : "",
    requirementMethodId : "",
    isAddMediaOpen : false,
    lastId : "",
    isLoading : false,

    connectRequirementList : null,
    likeRequirementList : null,
    connectModal : false,
    requirementSuccess : false,

    imageArray : [],
    lastImageId : "",

    likeStatusData : null,
    likeMergedData : null,

}