import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

export const PremiumBenefitsTable = ({ children }) => {
  return <Table paddingBottom="1rem">{children}</Table>;
};

const TableHeader = ({ headings = [] }) => {
  return (
    <Thead border="1px solid #F79C00">
      <Tr>
        {headings.map((heading) => (
          <Th
            fontWeight="700"
            fontSize="16px"
            textTransform="capitalize"
            color="#002961"
            key={heading}
          >
            {heading}
          </Th>
        ))}
      </Tr>
    </Thead>
  );
};

const TableBody = ({ content = [] }) => {
  return (
    <Tbody border="1px solid #F79C00" borderRadius="4px">
      {content && content.length > 0 ? (
        content.map(({ ServiceTypeValue, Count, Price }, index) => {
          return (
            <Tr key={index}>
              <Td fontWeight={600} color="#141619">
                {ServiceTypeValue}
              </Td>
              <Td fontWeight={600} color="#F79C00">
                {Count}
              </Td>
              {/* <Td fontWeight={600} color="#141619">{Price}</Td> */}
            </Tr>
          );
        })
      ) : (
        <Tr></Tr>
      )}
    </Tbody>
  );
};

PremiumBenefitsTable.Header = TableHeader;
PremiumBenefitsTable.Body = TableBody;
