import { createSlice } from "@reduxjs/toolkit";
import { getPremiumLeadsByIdThunk } from "../thunks/getPremiumLeadsById";

export const getPremiumLeadsByIdSlice = createSlice({
    name : "premiumLeadsById",
    initialState : {
        totalLeads : 0,
        redeemedLeads : 0,
        remainingLeads : 0,
        isLoading : false,
        premiumDetails : null
    },
    reducers : {
        setTotalLeads : (state, {payload}) => {
            state.totalLeads = payload
        }
    },
    extraReducers : (builder) => {
        builder
        .addCase(getPremiumLeadsByIdThunk.pending, (state) => {
            state.totalLeads = 0
            state.redeemedLeads = 0
            state.remainingLeads = 0
            state.isLoading = true
        })
        .addCase(getPremiumLeadsByIdThunk.fulfilled, (state, {payload}) => {
            state.totalLeads = payload.TotalCount
            state.redeemedLeads = 0
            state.remainingLeads = payload.RemainingCount
            state.isLoading = false
            if(payload){
                state.premiumDetails = payload
            }
        })
        .addCase(getPremiumLeadsByIdThunk.rejected, (state) => {
            state.totalLeads = 0
            state.redeemedLeads = 0
            state.remainingLeads = 0
            state.isLoading = false
        })
    }
    
})

