import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";

export const AccordianElement = ({
  title = "Title",
  color = "#1E3264",
  children,
  paddingBottom = "",
}) => {
  return (
    <AccordionItem bgColor="#FFFFFF">
      <h2>
        <AccordionButton>
          <Box
            as="span"
            flex="1"
            textAlign="left"
            color={color}
            textTransform="uppercase"
            fontSize="22px"
            fontWeight="600"
          >
            {title}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={paddingBottom ? paddingBottom : "1em"}>{children}</AccordionPanel>
    </AccordionItem>
  );
};
