import { configureStore, compose } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import { mtDataSlice } from "../features/global/mtData/slice/mtDataSlice";
import { AppSlice } from "../features/global/appConfig/slice/appConfigSlice";
import { registrationSlice } from "../features/registration/slice/registrationSlice";
import { otpSlice } from "../features/otp/slice/otpSlice";
import { loginSlice } from "../features/login/slice/loginSlice";
import { UserSlice } from "../features/global/user/slice/userSlice";
import { documentFlowSlice } from "../features/documentFlow/slice/docFlowSlice";
import { forgotPasswordSlice } from "../features/forgetPassword/slice/forgotPasswordSlice";
import { dashboardSlice } from "../features/dashboard/slice/dashboardSlice";
import { rentalEquipmentMarketplaceSlice } from "../features/rentalEquipmentMarketplace/slice/rentalEquipmentMartketplace";
import { operatorJobsNearMeSlice } from "../features/operatorJobs/slice/operatorJobsNearMeSlice";
import { maintenancePartnerJobsSlice } from "../features/maintenanceJobs/slice/maintenancePartnerJobsSlice";
import { operatorNearMeSlice } from "../features/operatorNearMe/slice/operatorNearMeSlice";
import { maintenancePartnerSlice } from "../features/maintenancePartnerNearMe/slice/maintenancePartnerSlice";
import { usedEquipmentMarketplaceSlice } from "../features/usedEquipmentMarketplace/slice/usedEquipmentMarketplaceSlice";
import { subscriptionSlice } from "../features/subscription/slice/subscriptionSlice";
import { e9ServiceSlice } from "../features/e9Services/slice/e9ServiceSlice";
import operatorSearchSlice from "../features/operatorSearch/slice/operatorSearchSlice";
import { dealershipMarketplaceSlice } from "../features/dealershipMarketplace/slice/dealershipMarketplaceSlice";
import { materialMarketplaceSlice } from "../features/MaterialMarketplace/slice/materialsMarketplaceSlice";
import { concreteMaterialMarketplaceSlice } from "../features/concreteMaterialMarketplace/slice/concreteMaterialMarketplaceSlice";
import { generatorsMaterialMarketplaceSlice } from "../features/generatorsMaterialMarketplace/slice/generatorsMaterialMarketplaceSlice";
import { oilMaterialMarketplaceSlice } from "../features/oilMaterialMarketplace/slice/oilMaterialMarketplaceSlice";
import { sparePartsMaterialMarketplaceSlice } from "../features/sparePartsMaterialMarketplace/slice/sparePartsMaterialMarketplace";
import { safetyEquipmentMaterialMarketplaceSlice } from "../features/safetyEquipmentMaterialMarketplace/slice/safetyEquipmentMaterialMarketplaceSlice";
import { myBusinessDealershipSlice } from "../features/MyBusinessDealershipDetail/slice/myBusinessDealershipSlice";
// import {equipmentListingSlice }from "../features/equipmentListing/slice/equipmentListingSlice";
import maintenanceJobPostSlice from "../features/maintenanceJobPost/slice/maintenanceJobPostSlice";
import locationSlice from "../features/location/slice/slice";
import { postMaterialSlice } from "../components/MaterailStoreList/MyBusinessStore/AddMarketPlace/MarketPlaceSteps/slice/postMaterialSlice";
import { editPostMaterialSlice } from "../components/MaterailStoreList/MyBusinessStore/AddMarketPlace/MarketPlaceSteps/EditMarketPlace/EditMaterialSlice/EditMaterialSlice";
import jobsPostedSlice from "../features/postedJobs/slice/jobsPostedSlice";
import { myBusinessFinanceSlice } from "../features/MyBusinessFinanceDetail/Slice/MyBusinessFinanceSlice";
import { myStoreSlice } from "../features/myStore/slice/myStoreSlice";
import equipmentStoreSlice from "../features/equipmentStore/slice/equipmentStoreSlice";
import usedEquipmentStoreSlice from "../features/equipmentStore/slice/usedEquipmentStoreSlice";
import rentalEquipmentStoreSlice from "../features/equipmentStore/slice/rentalEquipmentStoreSlice";
import { freeLeadsSlice } from "../features/freeLeads/slice/slice";
import equipmentListingSlice from "../features/equipmentListing/slice/equipmentListingSlice";
import { purchasedLeadsSlice } from "../features/purchasedLeadsCheck/slice/slice";
import { myLeadsSlice } from "../features/myLeads/Slice/myLeadsSlice";
import advertisementSlice from "../features/advertisement/slice/advertisementSlice";
import { materialsSlice } from "../features/materials/slice/materialsSlice";
import { postMaterialsSlice } from "../features/addMaterial/slice/postMaterialsSlice";
import { purchasedLeadSlice } from "../features/purchasedLeadsCheck/slice";
import { myMaterialsSlice } from "../features/myMaterials/slice/myMaterialsSlice";
import { uemDeferedSlice } from "../../website/Pages/Product/slice/uemDeferedSlice";
import { RemDeferedSlice } from "../../website/Pages/Product/slice/RemDeferedSlice";
import { opDeferredSlice } from "../../website/Pages/Product/slice/opDeferredSlice";
import { mpDeferredSlice } from "../../website/Pages/Product/slice/mpDeferredSlice";
import { dealershipDeferedSlice } from "../../website/Pages/Product/slice/dealershipDeferedSlice";
import { opJobDeferredSlice } from "../../website/Pages/Product/slice/opJobDeferredSlice";
import { mpJobDeferredSlice } from "../../website/Pages/Product/slice/mpJobDeferredSlice";
import { sparePartsDeferredSlice } from "../../website/Pages/openMarketplace/materials/sparePartsMaterial/slice/sparePartsDeferredSlice";
import { oilDeferredSlice } from "../../website/Pages/openMarketplace/materials/oilMaterial/slice/oilDeferredSlice";
import { concreteDeferredSlice } from "../../website/Pages/openMarketplace/materials/concreteMaterial/slice/concreteDeferredSlice";
import { generatorsDeferredSlice } from "../../website/Pages/openMarketplace/materials/generatorsMaterial/slice/generatorsDeferredSlice";
import { safetyDeferredSlice } from "../../website/Pages/openMarketplace/materials/safetyMaterial/slice/safetyDeferredSlice";
import { myBuisinessDealershipAssetSlice } from "../features/MyBusinessDealershipDetail/slice/myBuisinessDealershipAssetSlice";
import buyPremiumSlice from "../features/buyPremium/slice/premiumSlice";
import { industryRequirementSlice } from "../features/industryRequirement/slice/industryRequirementSlice";
import { communitySlice } from "../features/community/slice/communitySlice";
import { ownerMarketplaceSlice } from "../features/ownerMarketplace/slice/ownerMarketplaceSlice";
import { addCommunitySlice } from "../features/addCommunityPost/slice/addCommunitySlice";
import premiumSlice from "../features/premium/premiumSlice";
import { myLeadsIndustryRequirementSlice } from "../features/industryRequirement/slice/myLeadsIndustryRequirementSlice";
import { premiumLeadsSlice } from "../features/premiumLeads/slice";
import userLeadsCheckSlice from "../features/userLeadsCheck/slices/userLeadsCheckSlice";
import { quizSlice } from "../features/quiz/slice/quizSlice";
import jobCycleOwnerSlice from "../features/jobCycleOwner/slice/jobCycleOwnerSlice";

export const store = configureStore({
  reducer: {
    masterData: mtDataSlice.reducer,
    appConfig: AppSlice.reducer,
    user: UserSlice.reducer,

    addEquipment: equipmentListingSlice,
    advertisement: advertisementSlice,
    buyPremium: buyPremiumSlice,
    community: communitySlice.reducer,
    concreteDeferred: concreteDeferredSlice.reducer,
    concreteMaterialMarketplace: concreteMaterialMarketplaceSlice.reducer,
    dashboard: dashboardSlice.reducer,
    dealershipAssests: myBuisinessDealershipAssetSlice.reducer,
    dealershipDeferred: dealershipDeferedSlice.reducer,
    dealershipMarketplace: dealershipMarketplaceSlice.reducer,
    documentFlow: documentFlowSlice.reducer,
    EditpostMaterial: editPostMaterialSlice.reducer,
    equipmentStore: equipmentStoreSlice,
    e9Services: e9ServiceSlice.reducer,
    freeLeads: freeLeadsSlice.reducer,
    forgotPassword: forgotPasswordSlice.reducer,
    generatorsDeferred: generatorsDeferredSlice.reducer,
    generatorsMaterialMarketplace: generatorsMaterialMarketplaceSlice.reducer,
    industryRequirement: industryRequirementSlice.reducer,
    jobsPosted: jobsPostedSlice,
    jobCycleOwner : jobCycleOwnerSlice,
    location: locationSlice,
    login: loginSlice.reducer,
    maintenanceJobsNearMe: maintenancePartnerJobsSlice.reducer,
    maintenancePartnerNearMe: maintenancePartnerSlice.reducer,
    maintenanceSearch: maintenanceJobPostSlice,
    materialMarketplace: materialMarketplaceSlice.reducer,
    materials: materialsSlice.reducer,
    mpJobDeferred: mpJobDeferredSlice.reducer,
    mpDeferred: mpDeferredSlice.reducer,
    myBusinessDealership: myBusinessDealershipSlice.reducer,
    myFinance: myBusinessFinanceSlice.reducer,
    myLeads: myLeadsSlice.reducer,
    myLeadsIndustry: myLeadsIndustryRequirementSlice.reducer,
    myMaterials: myMaterialsSlice.reducer,
    myStore: myStoreSlice.reducer,
    oilDeferred: oilDeferredSlice.reducer,
    oilMaterialMarketplace: oilMaterialMarketplaceSlice.reducer,
    opDeferred: opDeferredSlice.reducer,
    opJobDeferred: opJobDeferredSlice.reducer,
    operatorJobsNearMe: operatorJobsNearMeSlice.reducer,
    operatorPartnerNearMe: operatorNearMeSlice.reducer,
    operatorSearch: operatorSearchSlice,
    otp: otpSlice.reducer,
    ownersMarketplace: ownerMarketplaceSlice.reducer,
    postCommunity: addCommunitySlice.reducer,
    postMaterial: postMaterialSlice.reducer,
    postMaterials: postMaterialsSlice.reducer,
    premium: premiumSlice,
    premiumLeads: premiumLeadsSlice,
    purchasedLeads: purchasedLeadSlice,
    quiz:quizSlice.reducer,
    registration: registrationSlice.reducer,
    remDeferred: RemDeferedSlice.reducer,
    rentalEquipmentMarketplace: rentalEquipmentMarketplaceSlice.reducer,
    rentalEquipmentStore: rentalEquipmentStoreSlice,
    safetyDeferred: safetyDeferredSlice.reducer,
    safetyEquipmentMaterialMarketplace:
    safetyEquipmentMaterialMarketplaceSlice.reducer,
    sparePartsDeferred: sparePartsDeferredSlice.reducer,
    sparePartsMaterialMarketplace: sparePartsMaterialMarketplaceSlice.reducer,
    subscription: subscriptionSlice.reducer,
    subscriptionLeadsCheck: purchasedLeadsSlice.reducer,
    uemDeferred: uemDeferedSlice.reducer,
    usedEquipmentMarketplace: usedEquipmentMarketplaceSlice.reducer,
    usedEquipmentStore: usedEquipmentStoreSlice,
    userLeads:userLeadsCheckSlice


    // Deferred Login Start
    // Deferred Login End


    // INdustry Requirement
    //communty
    //owner marketplace
  },
});
