import { createAsyncThunk } from "@reduxjs/toolkit";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";
import { getMaterialMarketplaceList } from "../../../services/materials/materials";


export const sparePartsInitialData = createAsyncThunk("materials/getInitialCards", async(_, {getState}) => {

    const sparePartsId = convertToBase64('1')
    const cardCount = getState().sparePartsMaterialMarketplace.cardCount
   

try{
    const data = await getMaterialMarketplaceList(cardCount,0,sparePartsId);
    
    if(data.Status === 200 && Object.keys(data.Data).length > 0){
        return data.Data
    }

   
}
catch(error){
    // console.log("MATERIALERROR: ",error);

}
})