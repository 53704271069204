import React, { useEffect, useState } from "react";
import { V5FormFooterLayout } from "../../../layout/V5FormFooterLayout/V5FormFooterLayout";
import { Box, Button, Flex, FormLabel, Text, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { FormikInput } from "../../../components/Input/FormikInput";
import { values } from "lodash";
import { UploadMediaPopUp } from "../../../components/UploadMediaPopUp/UploadMediaPopUp";
import { MultipleImageUpload } from "../../../components/MultipleImageUpload/MultipleImageUpload";
import {
  setImageArray,
  setImageLastId,
  setIndustryImageList,
  setIsLoading,
  setIsMediaOpen,
  setRequirementSucessModal,
} from "../slice/industryRequirementSlice";
import {
  convertBase64ToFile,
  convertFromBase64,
  readFileAsBase64,
} from "../../../../utils/formatConverter/convertToBase64";
import { openQrModal } from "../../dashboard/slice/dashboardSlice";
import { QrPopUp } from "../../../components/ScanQrCodeModal/QrPopUp";
import {
  industryPostYourRequirement,
  postRequirementImage,
} from "../../../services/industryRequirement/industryRequirement";
import { useNavigate } from "react-router-dom";
import { CustomGeneralModal } from "../../../components/CustomGeneralModal/CustomGeneralModal";
import { LocationInput } from "../../../components/LocationInput/LocationInput";
import CommonHeader from "../../../components/CommonHeader/CommonHeader";

const PostYourRequirement = () => {
  const { isAddMediaOpen, isLoading, requirementSuccess, imageArray } =
    useSelector((state) => state.industryRequirement);
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const getReqirementImageList = JSON.stringify(
    sessionStorage.getItem("industryImageList")
  );
  const requirementCategoryValue = sessionStorage.getItem(
    "REQUIREMENT_CATEGORY"
  );
  const requirementCategoryId = sessionStorage.getItem(
    "REQUIREMENT_CATEGORY_ID"
  );
  const inputMethodId = sessionStorage.getItem("REQUIREMENT_METHOD_ID");

  const [imageList, setImageList] = useState([]);
  const [charCount, setCharCount] = useState(1000);

  const storeFileInSessionStorage = async (file) => {
    try {
      const fileBase64 = await readFileAsBase64(file);
      const fileData = {
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
        base64: fileBase64,
      };
      const storedFiles =
        JSON.parse(sessionStorage.getItem("IndustryImages")) || [];
      storedFiles.push(fileData);
      sessionStorage.setItem("IndustryImages", JSON.stringify(storedFiles));
      dispatch(setImageArray(storedFiles));
    } catch (error) {}
  };

  const base64ToFile = (base64, filename, mimeType) => {
    const byteString = atob(base64.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([ab], filename, { type: mimeType });
  };

  useEffect(() => {
    if (getReqirementImageList !== null) {
      setImageList(JSON.parse(sessionStorage.getItem("IndustryImages")) || []);
      dispatch(
        setImageArray(
          JSON.parse(sessionStorage.getItem("IndustryImages")) || []
        )
      );
    }
  }, [getReqirementImageList]);

  const handleImage = (values) => {
    storeFileInSessionStorage(values.document);
    dispatch(setIsMediaOpen(false));
  };

  const handleRequirementImages = async (id) => {
    const uploadPromises = imageArray.map((image) => {
      const imageFile = base64ToFile(image.base64, image.name, image.type);

      const formData = new FormData();
      formData.append("RequirementDetailsId", id);
      formData.append("ImageTypeId", 1);
      formData.append("ImageFile", imageFile);

      return postRequirementImage(formData);
    });

    try {
      const results = await Promise.all(uploadPromises);
    } catch (error) {}
  };

  const handleSubmit = async (values) => {
    dispatch(setIsLoading(true));

    const payload = {
      Requirement: values.requirement,
      InputMethodId: inputMethodId,
      RequirementCategoryId: requirementCategoryId,
      Latitude: values?.industryLocation?.lat,
      Longitude: values?.industryLocation?.lng,
    };

    try {
      const data = await industryPostYourRequirement(payload);
      if (data?.Status === 400) {
        dispatch(setIsLoading(false));
        toast({
          status: "error",
          description: "Error In Posting Your Requirement",
          position: "top-right",
          isClosable: true,
        });
        navigate("/industry-requirement");
      } else if (data?.Status === 201) {
        handleRequirementImages(data?.Data?.LastId);
        dispatch(setIsLoading(false));
        dispatch(setImageLastId(data?.Data?.LastId));
        toast({
          status: "success",
          description: "Requirement Posted Successfully",
          position: "top-right",
          isClosable: true,
        });
        dispatch(setRequirementSucessModal(true));
      } else {
        dispatch(setIsLoading(false));
        navigate("/industry-requirement");
      }
    } catch (error) {
      dispatch(setIsLoading(false));
      toast({
        status: "error",
        description: "Error In Posting Your Requirement",
        position: "top-right",
        isClosable: true,
      });
      navigate("/industry-requirement");
    }

    dispatch(setIsLoading(false));
  };

  const deleteImage = (index) => {
    const updatedImageList = imageArray.filter((_, i) => i !== index);
    console.log("INDEX DELETE", updatedImageList);

    // setImageList(updatedImageList);
    sessionStorage.setItem("IndustryImages", JSON.stringify(updatedImageList));
    dispatch(setImageArray(updatedImageList));
  };

  return (
    <V5FormFooterLayout
      isStepperFalse={false}
      title="Requirement Category"
      subtitle="Select Your Need"
      formId="postRequirement"
      primaryButtonText="Submit"
      primaryButtonClickAction={() => {}}
      isLoading={isLoading}
    >
      <QrPopUp />
      <Flex direction="column" gap="1em">
        <Box>
          <Formik
            initialValues={{ requirement: "", industryLocation: "" }}
            validationSchema={Yup.object({
              requirement: Yup.string().max(
                1000,
                "Maximum 1000 Characters Allowed"
              ),
            })}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form id="postRequirement">
                <Flex direction="column">
                  <Flex justifyContent="space-between">
                    <Box>
                      <Text color="#141619" fontSize="16px" fontWeight="600">
                        Your Selected Requirement Category
                      </Text>
                      <Button
                        backgroundColor="transparent"
                        border="1px solid #E3E5E8"
                        borderRadius="4px"
                        _hover={{ backgroundColor: "transparent" }}
                      >
                        <Text color="#767676">{requirementCategoryValue}</Text>
                      </Button>
                    </Box>

                    <Field name="industryLocation">
                      {({ field }) => (
                        <LocationInput
                          name={field.name}
                          label="Your Location"
                          placeholder="Enter Location Here"
                          width="450px"
                          maxWidth="450px"
                          height="2.5rem"
                          value={
                            values.industryLocation?.name
                              ? values?.industryLocation?.name
                              : values?.industryLocation
                          }
                          isRequired={true}
                          onChange={(value) => {
                            setFieldValue("industryLocation", value);
                          }}
                        />
                      )}
                    </Field>
                  </Flex>
                  <Flex justifyContent="space-between">
                    <FormLabel fontSize="16px" fontWeight="600">
                      Please Type your Requirement Description Here
                    </FormLabel>
                    <Text
                      color="#4A5A82"
                      fontSize="14px"
                      fontWeight="600"
                      as="span"
                    >
                      {" "}
                      {charCount} Char
                    </Text>
                  </Flex>

                  <Flex direction="column" gap="1em">
                    <FormikInput
                      maxWidth="100%"
                      maxLength={1000}
                      type="text"
                      textArea={true}
                      name="requirement"
                      placeholder="Please Type your Requirement Description Here"
                      label=""
                      value={values.requirement}
                      onChangeAction={(e) => {
                        const inputValue = e.target.value;
                        setFieldValue("requirement", inputValue);
                        setCharCount(1000 - inputValue.length);
                      }}
                    />

                    <UploadMediaPopUp
                      isModalOpen={isAddMediaOpen}
                      hasDropdown={false}
                      modalTitle="Upload Photo"
                      onCloseButtonClick={() => {
                        dispatch(setIsMediaOpen(false));
                      }}
                      handleSubmit={(values) => {
                        handleImage(values);
                      }}
                    />
                    <MultipleImageUpload
                      maxWidth="100%"
                      title={`If You Have a Photo Please Upload It ${imageArray?.length}/5`}
                      onAddButtonClick={() => {
                        dispatch(setIsMediaOpen(true));
                      }}
                      isDisabled={imageArray?.length >= 5}
                      isRequirement={true}
                    />

                    <Flex gap="1em" marginBottom="10px">
                      {imageArray?.map((image, index) => (
                        <Flex direction="column" border="1.5px solid #E3E5E8">
                          <Box key={index}>
                            <img
                              src={image.base64}
                              alt={image.name}
                              style={{
                                height: "120px",
                                width: "150px",
                                borderRadius: "4px",
                              }}
                            />
                          </Box>
                          <Button
                            maxH="40px"
                            width="100%"
                            backgroundColor="transparent"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            _hover={{ backgroundColor: "transparent" }}
                            onClick={() => deleteImage(index)}
                          >
                            <img
                              src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/delete.svg`}
                              style={{ height: "30px", width: "20px" }}
                            />
                          </Button>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                </Flex>
              </Form>
            )}
          </Formik>
        </Box>
      </Flex>

      {/* Success Modal */}
      <CustomGeneralModal
        isOpen={requirementSuccess}
        heading="Congratulations! Your Requirement is Posted"
        iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/industryRequirements/success.svg`}
        primaryActionText="Explore"
        onCloseButtonClick={() => {
          dispatch(setRequirementSucessModal(false));
          navigate("/industry-requirement");
        }}
        hasCloseButton={true}
        primaryAction={() => {
          dispatch(setIsLoading(false));
          navigate("/industry-requirement");
        }}
        iconMaxHeight="50px"
        iconMaxWidth="160px"
        iconHeight="50px"
        iconWidth="160px"
      />
    </V5FormFooterLayout>
  );
};

export default PostYourRequirement;
