import { API_CONSTANTS } from "../../../constants/apiConstants"
import { convertToBase64 } from "../../../utils/formatConverter/convertToBase64"
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData"
import fetchHandler from "../fetchHandler"


export const getIndustryLikeList = async () =>{
  try{
    const {data} = await fetchHandler(API_CONSTANTS.GET_REQUIREMENT_LIKE_LIST_BY_USER_ID);
    if(data?.Status === 200 && data?.Data?.RequirementList.length > 0){
      return data?.Data?.RequirementList
    }
    return null ;
  }catch(error){
    
  }
}

export const postRequirementImage = async (body) => {
  const {data} = await fetchHandler(
    API_CONSTANTS.POST_REQUIREMENT_IMAGE,
    {},
    {},
    body,
    API_CONSTANTS.HTT_POST,
    {isAuthRequired : true}
  )

  console.log("IMAGES DATA ",data) ;
}
// My Leads Start

export const getIndustryLikesListById = async (id) =>{
  try{
    const {data} = await fetchHandler(`${API_CONSTANTS.GET_INDUSTRY_LIKE_LIST_BY_ID}${id}`);
    if(data?.Status === 200 && data?.Data?.RequirementReceivedListById.length > 0){
      return data?.Data?.RequirementReceivedListById
    }
  }catch(error){

  }
}
// Reedem Handling Start

export const industrySubscriptionReedemTransaction = async(body) =>{
  try {
    const data = await fetchHandler(
      API_CONSTANTS.CREATE_E9_SUBSCRIPTION_REDEEMED_TRANSACTION,
      {},
      {},
      body,
      API_CONSTANTS.HTT_POST
    );
      return data ;
  } catch (error) {
  }
}

export const industryFreeReedemTransaction = async(body) =>{
  try {
    const { data } = await fetchHandler(
      API_CONSTANTS.CREATE_E9_FREE_REDEEMED_TRANSACTION,
      {},
      {},
      body,
      API_CONSTANTS.HTT_POST
    );
      return data ;
  } catch (error) {
  }
}
export const updateRequirementConnectStatus = async(body) =>{
  try {
    const { data } = await fetchHandler(
      API_CONSTANTS.POST_UPDATE_REQUIREMENT_CONNECT_STATUS,
      {},
      {},
      convertToFormData(body),
      API_CONSTANTS.HTT_POST
    );
      return data ;
  } catch (error) {
  }
}

// Reedem Handling End
export const getrequirementRequestByIdList = async (id) => {
  try{
    const {data} = await fetchHandler(`${API_CONSTANTS.GET_REQUIREMENT_REQUEST_BY_ID}${id}`);
    if(data?.Status === 200 && data?.Data?.RequirementReceivedListById.length > 0){
      return data?.Data?.RequirementReceivedListById ;
    }
  }catch(error){

  }
}
export const getReceivedRequirementList = async () => {
  try{
    const {data} = await fetchHandler(API_CONSTANTS.GET_REQUIREMENT_RECEIVED_LIST);
    if(data?.Status === 200 && data?.Data?.RequirementReceivedList.length > 0){
      return data?.Data?.RequirementReceivedList ;
    }
  }catch(error){

  }
}
export const getSentRequirementList = async () => {
  try{
    const {data} = await fetchHandler(API_CONSTANTS.GET_REQUIREMENT_SENT_LIST);
    if(data?.Status === 200 && data?.Data?.RequirementSentList.length > 0){
      return data?.Data?.RequirementSentList ;
    }
  }catch(error){

  }
}
// My Leads End
export const connectRequirementApi = async (payload) =>{
    try {
      const {data} = await fetchHandler(
        API_CONSTANTS.POST_REQUIREMENT_CONNECT,
      {},
      {},
      convertToFormData(payload),
      API_CONSTANTS.HTT_POST,
      {isAuthRequired: true}
      )

      if(data?.Status === 201){
        return data ;
      }
    } catch (error) {
      
    }
  }
export const checkRequirementConnectList = async (payload) =>{
    try {
      const {data} = await fetchHandler(
        API_CONSTANTS.CHECK_REQUIREMENT_CONNECT_LIST)

      if(data?.Status === 200){
        return data?.Data ;
      }
    } catch (error) {
      
    }
  }
export const postRequirementCategoryAPI = async (payload) =>{
    const body =await convertToFormData(payload) ;
    try {
      const {data} = await fetchHandler(
        API_CONSTANTS.POST_REQUIREMENT_CATEGORY,
        {},
        {},
        body,
        API_CONSTANTS.HTT_POST,
        {isAuthRequired:true}
      )

      if(data?.Status === 201){
        return data?.Data?.LastId ;
      }
    } catch (error) {
      
    }
  }
export const industryPostYourRequirement = async (payload) =>{
    const body =await convertToFormData(payload) ;

    try {
      const {data} = await fetchHandler(
        API_CONSTANTS.POST_YOUR_REQUIREMENT,
        {},
        {},
        body,
        API_CONSTANTS.HTT_POST,
        {isAuthRequired:true}
      )

      return data
    //   if(data?.Status === 200){
    //     return data?.Data?.RequirementCategory ;
    //   }
    } catch (error) {
      throw error
    }
  }
export const requirementCategoryList = async () =>{
    try {
      const {data} = await fetchHandler(
        API_CONSTANTS.GET_REQUIREMENT_CATEGORY,
        {},
        {},
        {},
        API_CONSTANTS.HTTP_GET,
        {isAuthRequired:true}
      )

      if(data?.Status === 200){
        return data?.Data?.RequirementCategory ;
      }
    } catch (error) {
      
    }
  }

export const getInputTypeList = async ()=>{
    try {
      const {data} = await fetchHandler(
        API_CONSTANTS.GET_INPUT_METHOD,
        {},
        {},
        {},
        API_CONSTANTS.HTTP_GET,
        {isAuthRequired:true}
      )

      if(data?.Status === 200){
        return data?.Data?.InputMethod
      }
    } catch (error) {
      
    }
  }
export const industryReport = async (report,id) =>{
    try{
        const {data} = await fetchHandler(
            API_CONSTANTS.POST_REQUIREMENT_REPORT,
            {},
            {},
            convertToFormData(id),
            API_CONSTANTS.HTT_POST,
            {isAuthRequired:true}
        )
        if(data?.Status === 204){
            return data
        }
    }catch(error){
        
    }
}
export const deleteRequirement = async (id) =>{
    try {
        const {data} = await fetchHandler(
            `${API_CONSTANTS.DELETE_MY_REQUIREMENT}/${convertToBase64(id)}`,
            {},
            {},
            {},
            API_CONSTANTS.HTTP_DELETE,
            {isAuthRequired:true}
        )
        return data
    } catch (error) {
        
    }
}
export const getMyRequirementList = async () =>{
    try{
        const {data} =await fetchHandler(
            `${API_CONSTANTS.GET_MY_REQUIREMENT_LIST}/12/0`
        )
        if(data?.Status === 200 && data?.Data?.RequirementList.length > 0){
            return data?.Data?.RequirementList ;
        }
    }catch(error){

    }
}
export const getMyRequirementLoadMoreList = async (cardCount,paginationCount) =>{
    try{
        const {data} =await fetchHandler(
            `${API_CONSTANTS.GET_MY_REQUIREMENT_LIST}/${cardCount}/${paginationCount}`
        )
        if(data?.Status === 200 && data?.Data?.RequirementList.length > 0){
            return data?.Data?.RequirementList ;
        }
    }catch(error){

    }
}

export const getIndustryReceivedAnalytics = async () =>{
    try {
        const {data} = await fetchHandler(
            API_CONSTANTS.GET_INDUSTRY_RECEIVED_ANALYTICS
        )
        if(data?.Status === 200){
            return data?.Data?.RequestReceivedAnalytics
        }
    } catch (error) {
        
    }
}
export const getIndustryAcceptedAnalytics = async () =>{
    try {
        const {data} = await fetchHandler(
            API_CONSTANTS.GET_INDUSTRY_ACCEPTED_ANALYTICS
        )
        if(data?.Status === 200){
            return data?.Data?.RequestAcceptedAnalytics
        }
    } catch (error) {
        
    }
}
export const getIndustryPendingAnalytics = async () =>{
    try {
        const {data} = await fetchHandler(
            API_CONSTANTS.GET_INDUSTRY_PENDING_ANALYTICS
        )
        if(data?.Status === 200){
            return data?.Data?.RequestPendingAnalytics
        }
    } catch (error) {
        
    }
}

