import { Grid, GridItem } from "@chakra-ui/react";
import { V5Sidebar } from "../../components/V5Sidebar/V5Sidebar";
import { NewDashboardNavBar } from "../NewSideMenuLayout/NewDashboardNavbar";

export const V5SidebarLayout = ({ children }) => {
  return (
    <Grid
      gridTemplateColumns={[
        "repeat(4, 1fr)",
        "repeat(4, 1fr)",
        "repeat(4, 1fr)",
        "repeat(8, 1fr)",
        "repeat(10, 1fr)",
        "repeat(12, 1fr)",
      ]}
      gridTemplateRows={[
        "80px 1fr",
        "80px 1fr",
        "80px 1fr",
        "80px 1fr",
        "80px  calc(100vh - 80px - 1rem)",
        "80px  calc(100vh - 80px - 1rem)",
      ]}
      gap="4"
      minHeight="100vh"
      height="100vh"
      maxW="1536px"
      margin="0 auto"
    >
      <GridItem
        gridRow="1/3"
        gridColumn={["0", "0", "0", "0", "span 2", "span 2"]}
        display={["none", "none", "none", "none", "block", "block"]}
        boxShadow="md"
        borderRight="1px solid #2c2c2c2c"
      >
        <V5Sidebar />
      </GridItem>
      <GridItem
        gridRow="1/2"
        gridColumn={[
          "span 4",
          "span 4",
          "span 4",
          "span 8",
          "span 8",
          "span 10",
        ]}
        boxShadow="md"
      >
        <NewDashboardNavBar />
      </GridItem>
      <GridItem
        gridRow="2/3"
        gridColumn={[
          "span 4",
          "span 4",
          "span 4",
          "span 8",
          "span 8",
          "span 10",
        ]}
        maxW="100%"
        overflowY="scroll"
      >
        {children}
      </GridItem>
    </Grid>
  );
};
