import { createSlice } from "@reduxjs/toolkit";
import { jobsPostedInitialState } from "../intitalState";
import { getInitialPostedJobsThunk } from "../thunks/getInitialPostedJobsThunk";
import { getLoadMorePostedJobsThunk } from "../thunks/getLoadMorePostedJobsThunk";
import { getInitialJobsPostedForCompanyThunk } from "../thunks/getInitialJobsPostedForCompanyThunk";

const jobsPostedSlice = createSlice({
    name : "jobsPosted",
    initialState : jobsPostedInitialState,
    reducers: {
    setData: (state, {payload}) => {
        state.data = payload
    },
    
    },
    extraReducers : (builder) => {
        builder.addCase(getInitialPostedJobsThunk.pending, (state) => {
            state.initialLoading = true;
            state.loadMoreLoading = false;
            state.endOfData = false;
            state.error = false;
            state.data = null;
        })
        builder.addCase(getInitialPostedJobsThunk.fulfilled, (state, {payload}) => {
            state.initialLoading = false;
            state.loadMoreLoading = false;
            state.error = false
            if(payload.length <= state.cardsCount){
                state.endOfData =false; 
            }
            else{
                state.endOfData = true;
            }
            state.data = payload.data;
            state.pageNumber = 1;
              
        })
        builder.addCase(getInitialPostedJobsThunk.rejected, (state) => {
            state.initialLoading = false;
            state.loadMoreLoading = false;
            state.error = true;
            state.data = null
            
              
        })
        builder.addCase(getLoadMorePostedJobsThunk.pending, (state) => {
            state.initialLoading = false;
            state.loadMoreLoading = true;
            state.endOfData = false;
        })
        builder.addCase(getLoadMorePostedJobsThunk.fulfilled, (state, {payload}) => {
            state.initialLoading = false;
            state.loadMoreLoading = false;
            if(payload.length <= state.cardsCount){
                state.endOfData = false; 
            }
            else{
                state.endOfData = true
            }
            state.data = [...state.data, ...payload.data];
            state.pageNumber += 1;
              
        })
        builder.addCase(getLoadMorePostedJobsThunk.rejected, (state) => {
            state.initialLoading = false;
            state.loadMoreLoading = false;
            state.error = true;
            state.data = null
            
              
        })
        builder.addCase(getInitialJobsPostedForCompanyThunk.pending, (state) => {
            state.initialLoading = true;
            state.loadMoreLoading = false;
            state.endOfData = false;
            state.error = false;
            state.data = null;
        })
        builder.addCase(getInitialJobsPostedForCompanyThunk.fulfilled, (state, {payload}) => {
            state.initialLoading = false;
            state.loadMoreLoading = false;
            state.error = false
            if(payload.length <= state.cardsCount){
                state.endOfData = false; 
            }
            else{
                state.endOfData = true;
            }
            state.data = payload.data;
            state.pageNumber = 1;
              
        })
    }
})

export default jobsPostedSlice.reducer