import { createSlice } from "@reduxjs/toolkit";
import { getDashboardDetailsThunk } from "../thunks/getDashboardDetailsThunk";

export const dashboardSlice = createSlice({
    name: "dashboard",
  initialState: {
    isQrModalOpen: false,
    dashboardData: null,
    isError: false,
    isLoading: false,
    errorMessage: null
  },
  reducers: {
    openQrModal: (state) => {
      state.isQrModalOpen = true;
    },
    closeQrModal: (state) => {
      state.isQrModalOpen = false;
    },
    setDashboardData: (state, action) => {
      state.dashboardData = action.payload
    }
  },
  extraReducers : (builder) => {
    builder
    .addCase(getDashboardDetailsThunk.fulfilled, (state, action) => {
        state.dashboardData = action.payload;
        state.isLoading = false;
        state.isError = false;
        state.errorMessage = null;
    })
    .addCase(getDashboardDetailsThunk.pending, (state) => {
        state.dashboardData = null;
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = null;
    })
  }
});

export const { openQrModal, closeQrModal, setDashboardData } = dashboardSlice.actions;

export default dashboardSlice.reducer;
