import { V5SidebarLayout } from "../../../layout/V5SidebarLayout/V5SidebarLayout";
import { MarketplaceHoc } from "../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { useEffect, useState } from "react";
import { OwnerMarketplaceCard } from "../../../components/OwnerMarketplaceCard/OwnerMarketplaceCard";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FilterPopUp } from "../../marketplace/hoc/FilterPopUp/FilterPopUp";
import { MARKETPLACE_TYPES } from "../../../constants/e9Constants";
import { v4 as uuid } from "uuid";
import { getInitialDataThunk } from "../thunks/getInitialDataThunk";
import { getLoadMoreDataThunk } from "../thunks/getLoadMoreDataThunk";
import { useNavigate } from "react-router-dom";

import {
  resetFilters,
  setFilterByCountry,
  setFilterByState,
  setFilterModalOpen,
  setFilteredDataRequired,
  setFilterBusinessExpertiseTypeValue,
  setFilterByCity,
  setConnectList,
  setMergedConnectList,
  updateContactStatus,
  updateReedemCount,
  setLeadRemaining,
} from "../slice/ownerMarketplaceSlice";
import { getInitialFilteredDataThunk } from "../thunks/getInitialFilteredDataThunk";
import { getLoadMoreFilteredDataThunk } from "../thunks/getLoadMoreFilteredDataThunk";
import {
  createContactRequest,
  GetCountE9OwnerMarketplaceList,
  getE9OwnerMpConnectStatusList,
  getPremiumRedeemCountByUserIdServiceTypeId,
  updateContactRequest,
} from "../../../services/ownerMarketplace/ownerMarketplace";
import { getResponseTypeDetails } from "../../../services/materials/materials";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";
import { useToast } from "@chakra-ui/react";
import { HeaderSection } from "./HeaderSection";

export const OwnerMarketplace = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const userData = useSelector((state) => state.user);
  const [ownerCount, setOwnerCount] = useState(null);
  const [loadingOwners, setLoadingOwners] = useState({});

  const {
    data,
    connectList,
    redeemCount,
    leadRemaining,
    mergedConnectList,
    initialLoading,
    paginationLoading,
    endOfData,
    filteredDataRequired,
    filterModalOpen,
    filterByCountry,
    filterByState,
    filterByCity,
    filterByBusinessExpertiseTypeValue,
  } = useSelector((state) => state.ownersMarketplace);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getResponseTypeDetails();
        const obj = {};
        data.ResponseTypes.forEach(({ ResponseTypeId, ResponseTypeValue }) => {
          obj[ResponseTypeId] = ResponseTypeValue;
        });
      } catch (error) {
        console.error("Error fetching response type details:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    (async () => {
      const data = await GetCountE9OwnerMarketplaceList();
      const res = await getPremiumRedeemCountByUserIdServiceTypeId(
        userData?.accountDetails?.UserDetails?.CredentialDetails
          ?.UserCredentialId,
        10
      );
      dispatch(setLeadRemaining(res?.Data?.RedeemDetails?.[0]?.RemainingCount));
      setOwnerCount(data?.Data?.CountE9OwnerMarketplaceList?.TotalCount);
    })();
  }, []);

  useEffect(() => {
    if (filteredDataRequired) {
      dispatch(getInitialFilteredDataThunk());
    } else {
      dispatch(getInitialDataThunk());
    }
  }, [filteredDataRequired, dispatch]);

  useEffect(() => {
    (() => {
      mergeConnectList();
    })();
  }, [data, connectList]);

  useEffect(() => {
    (async () => {
      const data = await getE9OwnerMpConnectStatusList(
        userData?.accountDetails?.CompanyDetails?.TaxNumber
      );
      dispatch(setConnectList(data?.Data?.E9OwnerMpConnectStatusList));
    })();
  }, [userData, dispatch]);

  const filterFunction = (values) => {
    if (values.businessExpertiseTypeValue) {
      dispatch(
        setFilterBusinessExpertiseTypeValue(values.businessExpertiseTypeValue)
      );
    }
    if (values.country) {
      dispatch(setFilterByCountry(values.country));
    }
    if (values.state) {
      dispatch(setFilterByState(values.state));
    }
    if (values.city) {
      dispatch(setFilterByCity(values.city));
    }

    if (
      values.businessExpertiseTypeValue ||
      values.country ||
      values.state ||
      values.city
    ) {
      dispatch(setFilteredDataRequired(true));
    } else {
      dispatch(setFilteredDataRequired(false));
    }
    dispatch(getInitialFilteredDataThunk());
    dispatch(setFilterModalOpen(false));
  };

  const formatPhoneNumber = (phoneNumber) =>
    `+${String(phoneNumber).slice(0, 2)} ${String(phoneNumber)[2]}${"x".repeat(
      String(phoneNumber).length - 4
    )}${String(phoneNumber).slice(-1)}`;

  const mergeConnectList = () => {
    const mergedData = (data || []).map((owner) => {
      const matchingConnect = (connectList || []).find(
        (Item) => Item.OwnerUserCredId === owner.UserCredentialId
      );

      return {
        ...owner,
        ConnectResponse: { ...matchingConnect } || null,
      };
    });

    dispatch(setMergedConnectList(mergedData));
  };

  const handleContactOwner = async (owner) => {

   // Setting loading true for this specific owner 
    setLoadingOwners((prevState) => ({
      ...prevState,
      [owner.UserCredentialId]: true, 
    }))

    const isCompanyOwner = owner?.UserTypeValue === "Company";
    const isCompanyUser = userData?.UserTypeValue?.toLowerCase() === "company";
    const connectResponse = owner?.ConnectResponse;

    if (isCompanyUser && !userData?.accountDetails?.CompanyDetails?.TaxNumber) {
      toast({
        title: "",
        description: `Please update your tax number.`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      // Stop loader for this owner
      setLoadingOwners((prevState) => ({
        ...prevState,
        [owner.UserCredentialId]: false, 
      }));
      return;
    }

    if (
      redeemCount >= leadRemaining &&
      (connectResponse?.ResponseTypeId === 4 ||
        Object.keys(connectResponse).length === 0)
    ) {
      toast({
        title: "",
        description: `You have consumed your limit of ${redeemCount} connects.`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

       // Stop loader for this owner
       setLoadingOwners((prevState) => ({
        ...prevState,
        [owner.UserCredentialId]: false, 
      }));
      return;
    }

    const payload = {
      OwnerUserCredentialId: owner?.UserCredentialId,
      ResponseTypeId: 1,
      ResponseTypeValue: "Request Sent",
      OwnerTaxNumber: isCompanyOwner
        ? owner?.OwnerTaxNumber ?? null
        : undefined,
      SenderTaxNumber: isCompanyUser
        ? userData?.accountDetails?.CompanyDetails?.TaxNumber ?? null
        : undefined,
    };

    if (Object.keys(connectResponse).length === 0) {
      try {
        const res = await createContactRequest(payload);
        if (res?.Status === 201) {
          const updatedOwner = {
            ...owner,
            ConnectResponse: {
              E9OwnerMpConnectStatusId: res?.Data?.LastId,
              OwnerUserCredentialId: owner?.UserCredentialId,
              ResponseTypeId: 1,
              ResponseTypeValue: "Request Sent",
            },
          };
          dispatch(updateContactStatus(updatedOwner));
          dispatch(updateReedemCount("Request Sent"));
        }
      } catch (error) {
        console.error("Error creating contact request:", error);
      }finally{
         // Stop loader for this owner
        setLoadingOwners((prevState) => ({
        ...prevState,
        [owner.UserCredentialId]: false, 
        }));
      }
    } else {
      const resTypeId = connectResponse?.ResponseTypeId === 4 ? 1 : 4;
      const resTypeValue = resTypeId === 1 ? "Request Sent" : "Request Revoked";

      const E9OwnerMpDetails = {
        E9OwnerMpConnectStatusId: connectResponse?.E9OwnerMpConnectStatusId,
        ResponseTypeId: resTypeId,
        ResponseTypeValue: resTypeValue,
        SenderUserCredentialId: Number(
          userData?.accountDetails?.CompanyOwnerDetails?.CredentialDetails
            ?.UserCredentialId
        ),
        OwnerUserCredentialId: owner?.UserCredentialId,
        ...(isCompanyOwner && {
          OwnerTaxNumber: connectResponse?.OwnerTaxNumber,
        }),
      };

      try {
        const resp = await updateContactRequest({ E9OwnerMpDetails });
        if (resp) {
          const updatedOwner = {
            ...owner,
            ConnectResponse: {
              E9OwnerMpConnectStatusId:
                connectResponse?.E9OwnerMpConnectStatusId,
              OwnerUserCredentialId: owner?.UserCredentialId,
              ResponseTypeId: resTypeId,
              ResponseTypeValue: resTypeValue,
            },
          };
          dispatch(updateContactStatus(updatedOwner));
          dispatch(updateReedemCount(resTypeValue));
        }
      } catch (error) {
        console.error("Error updating contact request:", error);
      }finally{
         // Stop loader for this owner
         setLoadingOwners((prevState) => ({
          ...prevState,
          [owner.UserCredentialId]: false, 
          }));
      }
    }
  };

  const renderFunction = (array) => {
    const renderJsx =
      array && array.length > 0 ? (
        array.map((owner) => (
          <OwnerMarketplaceCard
            key={uuid()}
            MobileNumber={formatPhoneNumber(owner.MobileNumber)}
            EnterpriseName={owner.EnterpriseName}
            UserTypeValue={owner.UserTypeValue}
            UserRole={owner.UserRole}
            FirstName={owner.FirstName}
            LastName={owner.LastName}
            OverAllRating={owner.OverAllRating}
            isVerified={owner.isVerified}
            IsPremium={owner.IsPremium}
            ProfilePictureFileReference={owner.ProfilePictureFileReference}
            BusinessExpertiseValue={owner.BusinessExpertiseValue}
            ConnectResponse={owner?.ConnectResponse}
            isLoading={loadingOwners[owner.UserCredentialId]}
            onContactOwner={() => handleContactOwner(owner)}
            onHandleClick={() => {
              const OwnerUserCredentialId = convertToBase64(
                owner.UserCredentialId
              );

              navigate(
                `/owner-marketplace-details/${OwnerUserCredentialId}/${convertToBase64(
                  owner?.OwnerTaxNumber
                )}`
              );
            }}
          />
        ))
      ) : (
        <p>No data to display</p>
      );
    return renderJsx;
  };

  return (
    <V5SidebarLayout>
      <FilterPopUp
        marketplaceType={MARKETPLACE_TYPES.OWNER_MARKETPLACE}
        isModalOpen={filterModalOpen}
        initialBusinessExpertiseTypeValue={filterByBusinessExpertiseTypeValue}
        initialCountryValue={filterByCountry}
        initialStateValue={filterByState}
        initialCityValue={filterByCity}
        onResetFilterClick={() => {
          dispatch(resetFilters());
        }}
        onSubmitButtonClick={filterFunction}
        onCloseButtonClick={() => {
          dispatch(setFilterModalOpen(false));
        }}
      />
      <HeaderSection
        iconUrl={
          userData.accountDetails?.UserDetails?.ProfilePicture
            ?.ProfilePictureFileReference
        }
        title="Business Partners Near By"
        filteredDataRequired={filteredDataRequired}
        count={ownerCount}
        countText={"Owners Listed"}
        onClick={() => {
          dispatch(setFilterModalOpen(true));
        }}
      />

      <MarketplaceHoc
        marketplaceType="partners"
        dataArray={mergedConnectList}
        cardsRenderFunction={renderFunction}
        initialLoading={initialLoading}
        paginationLoading={paginationLoading}
        endOfData={endOfData}
        loadMoreAction={() => {
          filteredDataRequired
            ? dispatch(getLoadMoreFilteredDataThunk())
            : dispatch(getLoadMoreDataThunk());
        }}
      />
    </V5SidebarLayout>
  );
};
