import React from "react";
import './ProfileVerificationNew.css';
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
export const ProfileVerificationNew = () => {
  const userData = useSelector((state) => state.user);
  /**********Console log   */
 
  const BoUserType =
    userData?.accountDetails?.UserDetails?.BusinessTypeValue.toLowerCase();
  const BOUserTypeValue = "Business Owner".toLowerCase();
  const OpUserType =
    userData?.accountDetails?.UserDetails?.BusinessTypeValue.toLowerCase();
  const OpTypeValue = "Operator Partner".toLowerCase();

  const verification =
    userData?.accountDetails?.UserDetails?.E9VerificationMessage?.toLowerCase();
  const verificationByThirdParty = userData?.accountDetails?.UserDetails?.ThirdPartyVerificationMessage?.toLowerCase();
  const e9Verification = "Verified".toLowerCase();
  const isThirdPartyVerification = "Verified".toLowerCase();
  return (
    <>
      {verification !==e9Verification  || isThirdPartyVerification!== e9Verification  ? (
        //  Non verified Tag
        <div className="non-verify-layout-new" >
            <ReactSVG src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/IconNonVerify.svg`} />
          <p style={{fontSize:"17px"}} >
          Complete Verification Process !
          </p>
        </div>
      ) : (
        // Verified 
        <div className="verify-layout-new" >
        <ReactSVG src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/IconVerify.svg`} />
      <p  style={{fontSize:"17px",marginLeft:"8px"}} >
       Verification Completed !
      </p>
    </div>
      )}
    </>
  );
};


