import { createSlice } from "@reduxjs/toolkit";
import { handleLoginThunk } from "../thunks/loginThunk";
import { initialState } from "../helpers/initialState/initialState";
import { setSelectedAccountInLocalStorage } from "../helpers/localStorage/localStorage";

export const loginSlice = createSlice({
  name: "login",

  initialState: initialState,

  reducers: {
    handleSuccessModal: (state, action) => {
      state.isSuccessModalOpen = action.payload;
    },
    handleOpenContinueModal: (state, action) => {
      state.isContinueModalOpen = action.payload;
    },
    handleAccountsList: (state, action) => {
      state.accountList = action.payload;
    },

    selectedAccount: (state, action) => {
      state.selectedAccount = action.payload;
      setSelectedAccountInLocalStorage(action.payload)
    },
    clearLoginData: (state) => {
      state.isSuccessModalOpen= false;
      state.isContinueModalOpen= false;
      state.accountList= null;
      state.selectedAccount= null;
      state.isLoading= false;
      state.isError= false;
      state.errorMessage= "";
      setSelectedAccountInLocalStorage("");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleLoginThunk.pending, (state) => {
        state.isSuccessModalOpen = false;
        state.isContinueModalOpen = false;
        state.accountList = null;
        state.selectedAccount = null;
        state.isError = false;
        state.errorMessage = "";
        state.isLoading = true;
        setSelectedAccountInLocalStorage(null);
      })
      .addCase(handleLoginThunk.fulfilled, (state, action) => {
        state.isSuccessModalOpen = false;
        state.isContinueModalOpen = false;
        state.selectedAccount = null;
        state.isLoading = false;
        state.isError = false;
        state.errorMessage = "";
        if (action.payload.length > 1) {
          state.accountList = action.payload;
          state.isContinueModalOpen = true;
          state.selectedAccount = null;
          state.isSuccessModalOpen = false;
          setSelectedAccountInLocalStorage(null);
        } else {
          state.accountList = action.payload;
          state.isContinueModalOpen = false;
          state.selectedAccount = action.payload[0];
          state.isSuccessModalOpen = true;
          setSelectedAccountInLocalStorage(action.payload[0]);
        }
      })
      .addCase(handleLoginThunk.rejected, (state, action) => {
        state.isSuccessModalOpen = false;
        state.isContinueModalOpen = false;
        state.selectedAccount = null;
        state.isLoading = false;
        state.accountList = null;
        state.isError = true;
        state.errorMessage = action.payload;
        setSelectedAccountInLocalStorage(null);
      });
  },
});

export const {handleSuccessModal, handleOpenContinueModal, handleAccountsList, selectedAccount, clearLoginData} = loginSlice.actions

export default loginSlice.reducer;
