import { createAsyncThunk } from "@reduxjs/toolkit";
import { getQuizzes } from "../../../services/quiz/quiz";

export const quizListLoadMoreThunk = createAsyncThunk(
  "quiz/getLoadMoreCards",
  async (_, {getState}) => {
    const {cardCount,paginationCount} = getState().quiz

    try {
      const data = await getQuizzes(cardCount,(paginationCount * cardCount));
      return data;
    } catch (error) {
      throw error;
    }
  }
);