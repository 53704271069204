import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DashboardNavBar } from "../../../../components/DashboardNavBar/DashboardNavBar";
import { SideMenuLayout } from "../../../../layout/sideMenuLayout/SideMenuLayout";
import { JobMarketplaceCard } from "../../../../components/JobMarketplaceCard/JobMarketplaceCard";
import { useNavigate } from "react-router-dom";
import { MarketplaceHeader } from "../../../../components/MarketplacHeader/MarketplaceHeader";
import { MarketplaceHoc } from "../../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { getInitialFilteredMaintenanceData } from "../../thunks/getInitialFilteredMaintenanceJobs";
import { FilterPopUp } from "../../../marketplace/hoc/FilterPopUp/FilterPopUp";
import { setFilterByCity, setFilterByCountry, setFilterByEquipmentType, setFilterByState, setFilterModalOpen, setFilteredDataRequired, resetFilters } from "../../slice/maintenancePartnerJobsSlice";
import { getLoadMoreFilteredMaintenanceData } from "../../thunks/getLoadMoreFilteredMaintenanceJobs";
import { getLoadMoreMaintenanceDataThunk } from "../../thunks/getLoadMoreMaintenancePartnerJobs";
import { MARKETPLACE_TYPES } from "../../../../constants/e9Constants";
import { getInitialMaintenancePartnerJobs } from "../../thunks/getInitialMaintenancePartnerJobs";
import { Helmet } from "react-helmet";
import { V5SidebarLayout } from "../../../../layout/V5SidebarLayout/V5SidebarLayout";
import { HeaderSection } from "../../../ownerMarketplace/screens/HeaderSection";
import { indianNumberFormatter } from "../../../../helpers/indianNumberFormatter/indianNumberFormatter";
import { useClipboard } from "../../../../hooks/useClipboard";
import { useToast } from "@chakra-ui/react";
import { encryptShareData } from "../../../../services/share/share";
import { convertToBase64 } from "../../../../../utils/formatConverter/convertToBase64";

export const MaintenanceJobsNearMe = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {copyToClipboard} = useClipboard();
  const toast=useToast();

const {
  initialLoading,
  data,
  jobCount,
  endOfData,
  filterModalOpen,
  filterByEquipmentType,
  filterByCountry,
  filterByState,
  filterByCity,
  filterDataRequired,
} = useSelector((state) => state.maintenanceJobsNearMe);



const filterFunction = (values) => {
  dispatch(setFilterByEquipmentType(values.equipmentType));
  dispatch(setFilterByCountry(values.country));
  dispatch(setFilterByState(values.state));
  dispatch(setFilterByCity(values.city));
  
  if (values.equipmentType || values.country || values.state || values.city) {
    dispatch(setFilteredDataRequired(true));
    dispatch(getInitialFilteredMaintenanceData());
  } else {
    dispatch(setFilteredDataRequired(false));
  }
  dispatch(setFilterModalOpen(false));
};


const encryptJobDetails = async (cardId) => {
  try {
    // const payload = {
    //   marketplaceCategory: "job",
    //   marketplaceSubCategory: "mp",
    //   assetId: cardId,
    // };

    // const shareLink = await encryptShareData(payload);
    // await copyToClipboard(
    //   `${process.env.REACT_APP_WEBSITE_URL}share?id=${shareLink}&mode=share&utm=web`
    // );
    await copyToClipboard(
      `${process.env.REACT_APP_WEBSITE_URL}share?category=${"job"}&subCategory=${"mp"}&id=${convertToBase64(cardId)}`
    );

    toast({
      title: "Link Copied!",
      description: "Share link has been successfully copied to clipboard.",
      status: "success",
      position: "top-right",
      duration: 5000,
      isClosable: true,
    });
  } catch (error) {
    console.log(error);
    toast({
      title: "Error",
      description: "Failed to share. Please try again.",
      position: "top-right",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }
};


  const renderFunction = (array) =>
    array && array.length > 0 ? (
      array.map((jobs) => {
       return <JobMarketplaceCard
        verificationStatus={
          jobs.UserResponseStatusId
        }
        verificationText={jobs.UserJobResponseTypeValue}
        jobId={jobs.JobId}
        name={jobs.JobTitle}
        money={`₹ ${indianNumberFormatter(jobs.Salary)}`}
        postedOn={jobs.JobPostedDateTime ? jobs.JobPostedDateTime.split(",")[1] : "NA"}
        location={`${jobs.CityName}, ${jobs.StateName} ,${jobs.CountryName}`}
        cycle={
          jobs.SalaryTypeValue
            ? `${jobs.SalaryTypeValue}`
            : "N.A."
        }
        onClick={() =>
          navigate(`/jobs-near-me/AllJobsNearMeMP/Card/${encodeURIComponent(`${jobs.JobId}`)}`,{
             
              state: { UserJobResponse: jobs.UserJobResponseTypeValue} 
            
          })
          
        }
        onShareButtonClick={async (e) => {
          // e.stopPropagation(); 
          await encryptJobDetails(jobs.JobId);
        }}
        />
})
    ) : (
      <p>No Data Available</p>
    );
 
    useEffect(() => {
      filterDataRequired
        ? dispatch(getInitialFilteredMaintenanceData())
        : dispatch(getInitialMaintenancePartnerJobs());
    }, [filterDataRequired]);

 
  return (
    <V5SidebarLayout>
      <Helmet>
        <link
          rel="MAINTENANCE JOBS page"
          href="https://www.equip9.com/jobs-near-me/AllJobsNearMeMP"
        />
        <meta name="keywords" content="machinery operators jobs" />
        <title>Equip9: Connecting Machinery Operators with Jobs</title>
        <meta
          name="description"
          content="Explore job opportunities for machinery operators on Equip9. Connect 
      with business owners and find employment in the heavy equipment industry"
        />
      </Helmet>

      <FilterPopUp
        marketplaceType={MARKETPLACE_TYPES.NEAR_ME_JOBS_MAINTENANCE}
        isModalOpen={filterModalOpen}
        onSubmitButtonClick={filterFunction}
        onResetFilterClick={() => dispatch(resetFilters())}
        initialEquipmentValue={filterByEquipmentType}
        initialCountryValue={filterByCountry}
        initialStateValue={filterByState}
        initialCityValue={filterByCity}
        onCloseButtonClick={() => {
          dispatch(setFilterModalOpen(false));
        }}
      />
      <HeaderSection
        title="Jobs For Maintenance Partners"
        countText={"Jobs Listed"}
        count={jobCount}
        filteredDataRequired={filterDataRequired}
        isIconNeeded={false}
        onClick={() => {
          dispatch(setFilterModalOpen(true));
        }}
      />

      <MarketplaceHoc
        marketplaceType="jobs"
        isNewNavbar={true}
        initialLoading={initialLoading}
        dataArray={data ? data : []}
        cardsRenderFunction={renderFunction}
        loadMoreAction={() =>
          filterDataRequired
            ? dispatch(getLoadMoreFilteredMaintenanceData())
            : dispatch(getLoadMoreMaintenanceDataThunk())
        }
        endOfData={endOfData}
      />
    </V5SidebarLayout>
  );
};
