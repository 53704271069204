/**
 * // themes/stepper.ts

const baseStyle = {
  // select the indicator part
  indicator: {
    // change the default border radius to 0
    borderRadius: 0,
  },
}

const stepperTheme = {
  baseStyle,
}

const theme = extendTheme({
  components: {
    Stepper: stepperTheme,
  },
})

stepper: Maps to the Stepper component
step: Maps to the Step component
title: Maps to the StepTitle component
description: Maps to the StepDescription component
indicator: Maps to the StepIndicator component
separator: Maps to the StepSeparator component
icon: Maps to the StepIcon component
number: Maps to the StepNumber component
 */

export const stepperTheme = {
    step : {
        background : "#F67C00"
    },
    indicator :{
        background : "#F67C00"
    },
    icon : {
        background : "#F67C00"
    }, 
    number : {
        background : "#F67000"
    }
}