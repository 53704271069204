import { V5SidebarLayout } from "../../../layout/V5SidebarLayout/V5SidebarLayout";
import { Flex, Box, Text, Button, useToast } from "@chakra-ui/react";
import { OwnerMarketplaceDetailsCard } from "../../../components/OwnerMarketplaceCard/OwnerMarketplaceDetailsCard";
import {
  createContactRequest,
  getE9OwnerMpConnectStatusById,
  getE9OwnerMpProfileDetails,
  getOwnerMpDealershipDetAnalytics,
  getOwnerMpEquipDetAnalytics,
  getOwnerMpMaterialsDetAnalytics,
  updateContactRequest,
} from "../../../services/ownerMarketplace/ownerMarketplace";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { OverallRatingIcon } from "../../../components/OverallRatingIcon/OverallRatingIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  convertFromBase64,
  convertToBase64,
} from "../../../../utils/formatConverter/convertToBase64";
import { updateReedemCount } from "../slice/ownerMarketplaceSlice";
export const OwnerMarketplaceDetails = () => {
  const { redeemCount, leadRemaining } = useSelector(
    (state) => state.ownersMarketplace
  );
  const userData = useSelector((state) => state.user);
  const userCredentialId =
    userData?.accountDetails?.UserDetails?.CredentialDetails?.UserCredentialId;

  const toast = useToast();
  const dispatch = useDispatch();

  const { OwnerUserCredentialId, OwnerTaxNumber } = useParams();
  const [connectStatusData, setConnectStatusData] = useState(null);
  const [profileData, setProfileData] = useState();
  const [equipmentData, setEquipmentData] = useState([]);
  const [materialData, setMaterialData] = useState([]);
  const [delarshipdata, setDealarshipData] = useState([]);
  const [fileRef, setFileRef] = useState("");


  const fetchConnectStatusData = async () => {
    try {
      const { Data } = await getE9OwnerMpConnectStatusById(
        OwnerUserCredentialId
      );
      setConnectStatusData(Data?.E9OwnerMpConnectStatusDetails);
    } catch (error) {
      console.error("Error while calling getE9OwnerMpConnectStatusById", error);
    }
  };

  useEffect(() => {
    fetchConnectStatusData();
  }, [OwnerUserCredentialId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getE9OwnerMpProfileDetails(OwnerUserCredentialId);
        setProfileData(data.Data.E9OwnerMpProfileDetails);
      } catch (error) {
        console.error("Error fetching profile details:", error);
      }
    };
    fetchData();
  }, [OwnerUserCredentialId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getOwnerMpMaterialsDetAnalytics(
          OwnerUserCredentialId
        );
        setMaterialData(data.Data.AnalyticsList);
        setFileRef(data?.Data?.S3UserProfilePicBaseRef);
      } catch (error) {
        console.error("Error fetching material details:", error);
      }
    };
    fetchData();
  }, [OwnerUserCredentialId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getOwnerMpDealershipDetAnalytics(
          OwnerUserCredentialId
        );
        setDealarshipData(data.Data.AnalyticsList);
      } catch (error) {
        console.error("Error fetching dealership details:", error);
      }
    };
    fetchData();
  }, [OwnerUserCredentialId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getOwnerMpEquipDetAnalytics(OwnerUserCredentialId);
        setEquipmentData(data.Data.AnalyticsList);
      } catch (error) {
        console.error("Error fetching equipment details:", error);
      }
    };
    fetchData();
  }, [OwnerUserCredentialId]);

  const handleContactOwner = async (connectData) => {
    const isCompanyUser = userData?.UserTypeValue?.toLowerCase() === "company";

    if (isCompanyUser && !userData?.accountDetails?.CompanyDetails?.TaxNumber) {
      toast({
        title: "",
        description: `Please update your tax number.`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (
      redeemCount >= leadRemaining &&
      (connectData?.ResponseTypeId === 4 || !connectStatusData)
    ) {
      toast({
        title: "",
        description: `You have consumed your limit of ${redeemCount} connects.`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      return;
    }

    const payload = {
      OwnerUserCredentialId: Number(convertFromBase64(OwnerUserCredentialId)),
      ResponseTypeId: 1,
      ResponseTypeValue: "Request Sent",
      OwnerTaxNumber: Number(convertFromBase64(OwnerTaxNumber)) || null,
      SenderTaxNumber: isCompanyUser
        ? userData?.accountDetails?.CompanyDetails?.TaxNumber ?? null
        : undefined,
    };

    console.log("contacted", payload);

    if (!connectData) {
      try {
        const res = await createContactRequest(payload);
        if (res?.Status === 201) {
          dispatch(updateReedemCount("Request Sent"));
          fetchConnectStatusData();
        }
      } catch (error) {
        console.error("Error creating contact request:", error);
      }
    } else {
      const resTypeId = connectData?.ResponseTypeId === 4 ? 1 : 4;
      const resTypeValue = resTypeId === 1 ? "Request Sent" : "Request Revoked";

      const E9OwnerMpDetails = {
        E9OwnerMpConnectStatusId: connectData?.E9OwnerMpConnectStatusId,
        ResponseTypeId: resTypeId,
        ResponseTypeValue: resTypeValue,
        SenderUserCredentialId: Number(
          userData?.accountDetails?.CompanyOwnerDetails?.CredentialDetails
            ?.UserCredentialId
        ),
        OwnerUserCredentialId: Number(convertFromBase64(OwnerUserCredentialId)),
        ...(Number(convertFromBase64(OwnerTaxNumber)) && {
          OwnerTaxNumber: Number(convertFromBase64(OwnerTaxNumber)),
        }),
      };

      try {
        const resp = await updateContactRequest({ E9OwnerMpDetails });
        if (resp) {
          dispatch(updateReedemCount(resTypeValue));
          fetchConnectStatusData();
        }
      } catch (error) {
        console.error("Error updating contact request:", error);
      }
    }
  };

  const contactStatus = (data) => {
    switch (data?.ResponseTypeId) {
      case null:
      case 4:
        return (
          <Button
            border="1px solid #F79C00"
            color="orange"
            backgroundColor="white"
          >
            Contact Owner
          </Button>
        );
      case 2:
        return (
          <Button color="#267A00" backgroundColor="white">
            {data?.ResponseTypeValue}
          </Button>
        );
      case 3:
        return (
          <Button color="#D60000" backgroundColor="white">
            {data?.ResponseTypeValue}
          </Button>
        );
      case 1:
        return (
          <Button
            color="#319F00"
            border="1px solid #319F00"
            backgroundColor="white"
          >
            {data?.ResponseTypeValue}
          </Button>
        );
      default:
        return (
          <Button
            border="1px solid #F79C00"
            color="orange"
            backgroundColor="white"
          >
            Contact Owner
          </Button>
        );
    }
  };

  return (
    <>
      <V5SidebarLayout>
        <Flex
          direction="column"
          paddingLeft="25px"
          paddingRight="16px"
          maxH={"76vh"}
          overflowY={"scroll"}
        >
          <Text fontFamily="Assistant" fontSize="24px" mb="3" fontWeight="700">
            Owner Profile
          </Text>

          <Flex direction={"column"} gap={"25px"}>
            <Flex
              direction="row"
              align="center"
              backgroundColor="#FFFBF5"
              borderRadius="8px"
              border={"1px solid #F79C00"}
              padding={"16px 24px"}
              gap={"24px"}
            >
              <Flex direction="column" align="center" gap={"6px"}>
                <img
                  src={
                    profileData?.ProfilePictureFileReference
                      ? `${process.env.REACT_APP_CDN_PVT_URL}UserProfilePicture/${profileData?.ProfilePictureFileReference}`
                      : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Avatar.png`
                  }
                  alt="User Avatar"
                  style={{
                    width: "66px",
                    height: "66px",
                    borderRadius: "50%",
                  }}
                />

                <Flex w={"46px"}>
                  <OverallRatingIcon
                    color="#FFFBF5"
                    padding="0.25em 0.50em"
                    ratings={profileData?.OverallRating}
                    textcolour="black"
                  />
                </Flex>
              </Flex>
              <Box>
                <Text fontSize="2xl" fontWeight="bold">
                  {profileData?.EnterpriseName || "NA"}
                </Text>
                <Flex gap={"25px"}>
                  <Text>
                    {profileData?.FirstName || "NA"}{" "}
                    {profileData?.LastName || "NA"} •{" "}
                    {profileData?.UserRole || "NA"} •{" "}
                    {profileData?.BusinessExpertiseValue || "NA"}
                  </Text>
                  <Text>
                    {profileData?.CityName || "NA"},{" "}
                    {profileData?.StateName || "NA"},{" "}
                    {profileData?.CountryName || "NA"}
                  </Text>
                </Flex>
              </Box>
            </Flex>

            <Flex direction={"column"} gap={"2em"} marginBottom={"1em"}>
              <Flex
                gap={"8px"}
                direction={"column"}
                pt={"1em"}
                borderTop={"1px solid #E3E5E8"}
              >
                <Text fontSize={"18px"} fontWeight={"700"} color={"#141619"}>
                  Material Details
                </Text>
                <Flex gap={"24px"} padding={"8px 0"}>
                  {materialData.map((item) => (
                    <OwnerMarketplaceDetailsCard
                      key={item.TempTableId}
                      count={item.ContentCount}
                      type={item.ContentName}
                      icon={item.ContentIconFileRef}
                      fileRef={fileRef}
                    />
                  ))}
                </Flex>
              </Flex>

              <Flex
                gap={"8px"}
                direction={"column"}
                pt={"1em"}
                borderTop={"1px solid #E3E5E8"}
              >
                <Text fontSize={"18px"} fontWeight={"700"} color={"#141619"}>
                  Equipment Details
                </Text>
                <Flex gap={"24px"} padding={"8px 0"}>
                  {equipmentData.map((item) => (
                    <OwnerMarketplaceDetailsCard
                      key={item.TempTableId}
                      count={item.ContentCount}
                      type={item.ContentName}
                      icon={item.ContentIconFileRef}
                      fileRef={fileRef}
                    />
                  ))}
                </Flex>
              </Flex>

              <Flex
                gap={"8px"}
                direction={"column"}
                pt={"1em"}
                borderTop={"1px solid #E3E5E8"}
              >
                <Text fontSize={"18px"} fontWeight={"700"} color={"#141619"}>
                  Dealership Details
                </Text>
                <Flex gap={"24px"} padding={"8px 0"}>
                  {delarshipdata.map((item) => (
                    <OwnerMarketplaceDetailsCard
                      key={item.TempTableId}
                      count={item.ContentCount}
                      type={item.ContentName}
                      icon={item.ContentIconFileRef}
                      fileRef={fileRef}
                    />
                  ))}
                </Flex>
              </Flex>

              <Flex w={"100%"} borderTop={"1px solid #E3E5E8"} />
            </Flex>
          </Flex>
        </Flex>
        {convertToBase64(userCredentialId) === OwnerUserCredentialId ? null : (
          <Flex
            width="100%"
            backgroundColor="white"
            boxShadow="0 -2px 5px rgba(0, 0, 0, 0.1)"
            padding={"1em"}
            justifyContent={"flex-end"}
          >
            <Box
              onClick={() => {
                handleContactOwner(connectStatusData);
              }}
            >
              {contactStatus(connectStatusData)}
            </Box>
          </Flex>
        )}
      </V5SidebarLayout>
    </>
  );
};
