import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMaterialStore } from "../../../services/materials/materials";

export const getMaterialStoreThunk= createAsyncThunk(
'materials/getMaterialStore',
async ()=>{
    try{
        return await getMaterialStore();
    }catch(error){
        throw error;
    }
}
);