import styled from "styled-components";
import "./Flexbox.css";

const StyledFlexBox = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? "direction" : "column")};
  gap: ${({ gap }) => (gap ? `${gap}em` : "2.5em")};
  z-index: ${({ zIndex }) => (zIndex ? `${zIndex}` : 2)};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? `${justifyContent}` : ""};
  align-items: ${({ alignItems }) => (alignItems ? `${alignItems}` : "")};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "")};
  padding: ${({ padding }) => (padding ? `${padding}` : "")};
  border : ${({border}) => (border) ? `${border}` : ""};
  border-color : ${({borderColor}) => (borderColor) ? `${borderColor}` : ""};
  border-radius : ${({borderRadius}) => (borderRadius) ? `${borderRadius}` : ""};
`;

export const Flexbox = ({
  children,
  gap,
  direction,
  zIndex,
  alignItems,
  justifyContent,
  bgColor,
  padding,
  border,
  borderRadius,
  borderColor
}) => {
  return (
    <StyledFlexBox
      gap={gap}
      direction={direction}
      zIndex={zIndex}
      alignItems={alignItems}
      justifyContent={justifyContent}
      bgColor={bgColor}
      padding={padding}
      border = {border}
      borderRadius = {borderRadius}
      borderColor = {borderColor}
    >
      {children}
    </StyledFlexBox>
  );
};
