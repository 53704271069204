import { Box,Flex, Skeleton} from "@chakra-ui/react";

export const E9ServicePageLoader = () => {
  return (
    <Flex flexDirection="column" gap="1em">
      <Box>
        <Flex w="100%" alignItems="center" gap="0.5em" marginBottom="1em">
          <Box maxW="20%" w="100%">
            <Skeleton height="1.5em" width="100%" />
          </Box>
          <Flex alignItems="center" w="100%">
            <Skeleton height="0.1em" width="100%" />
          </Flex>
        </Flex>
        <Flex wrap="wrap" gap="0.75em">
          <Skeleton width="145px" height="205.5px" />
          <Skeleton width="145px" height="205.5px" />
        </Flex>
      </Box>
      <Box>
        <Flex w="100%" alignItems="center" gap="0.5em" marginBottom="1em">
          <Box maxW="20%" w="100%">
            <Skeleton height="1.5em" width="100%" />
          </Box>
          <Flex alignItems="center" w="100%">
            <Skeleton height="0.1em" width="100%" />
          </Flex>
        </Flex>
        <Flex wrap="wrap" gap="0.75em">
          <Skeleton width="145px" height="205.5px" />
          <Skeleton width="145px" height="205.5px" />
          <Skeleton width="145px" height="205.5px" />
          <Skeleton width="145px" height="205.5px" />
        </Flex>
      </Box>
    </Flex>
  );
};
