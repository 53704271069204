import { API_CONSTANTS } from "../../../constants/apiConstants";
import { fetchHandler } from "../../../index";
import { convertToBase64 } from "../../../utils/formatConverter/convertToBase64";
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData";

export const getUserPremiumDetails = async () => {
  try {
    const { data } = await fetchHandler(
      "/CheckPremiumUser",
      {},
      {},
      {},
      API_CONSTANTS.HTTP_GET
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllPremiumTypes = async () => {
  try {
    const { data } = await fetchHandler(
      "/GetPremiumPaymentPlans",
      {},
      {},
      {},
      API_CONSTANTS.HTTP_GET
    );
    if (data.Status === 200 && Object.keys(data.Data).length > 1) {
      return data.Data.PremiumPaymentPlans;
    } else {
      throw new Error(data.Message.FailMessage);
    }
  } catch (error) {
    throw error;
  }
};
// export const GetPremiumBenefitsByPaymentType

export const getPremiumDetailsById = async (id) => {
  try {
    const { data } = await fetchHandler(
      "/GetPremiumBenefitsByPaymentType",
      { PaymentType: convertToBase64(id) },
      {},
      API_CONSTANTS.HTTP_GET
    );
    if (data.Message.FailMessage) {
      throw new Error(data.Message.FailMessage);
    } else {
      return data.Data;
    }
  } catch (error) {
    throw error;
  }
};

export const saveUserPremiumType = async (
  selectedPremiumPlan,
  transactionStateId
) => {
  try {
    console.log(selectedPremiumPlan);
    const { data } = await fetchHandler(
      "/SaveUserPaymentType",
      {},
      {},
      convertToFormData({
        PaymentType: convertToBase64(selectedPremiumPlan),
        PremiumStateId: transactionStateId,
      }),
      API_CONSTANTS.HTT_POST
    );
    console.log(data);
  } catch (error) {
    throw error;
  }
};

export const savePremiumUser = async (body) => {
  try {
    const { data } = await fetchHandler(
      "/SavePremiumUser",
      {},
      {},
      convertToFormData(body),
      API_CONSTANTS.HTT_POST
    );

    if (data.Status === 201) {
      return data.Data;
    } else {
      throw new Error(data.Data.Message.FailMessage);
    }
  } catch (error) {
    throw error;
  }
};

export const getReviewDetails = async(transactionDetailsId) => {
  if (!transactionDetailsId) {
    throw new Error("TransactionDetailsId is required as a parameter");
  }
  try {
    const { data } = await fetchHandler("/GetReviewDetails", {
      E9PremiumTransactionDetailsId: convertToBase64(transactionDetailsId),
    });
    if (data.Status === 200 && Object.keys(data.Data).length > 0) {
      return data.Data.ReviewDetails[0];
    } else {
      throw new Error(data.Message.FailMessage);
    }
  } catch (error) {
    throw error;
  }
};

export const getPremiumCostBreakup = async(transactionDetailsId) => {
  if (!transactionDetailsId) {
    throw new Error("TransactionDetailsId is required as a parameter");
  }
  try {
    const { data } = await fetchHandler("/GetPremiumPlanCostBreakup", {
      E9PremiumTransactionDetailsId: convertToBase64(transactionDetailsId),
    });
    if (data.Status === 200 && Object.keys(data.Data).length > 0){
      return data.Data;
    } else {
      throw new Error(data.Message.FailMessage);
    }
  } catch (error) {
    throw error;
  }
};

export const createPremiumStagingOrderForIndividual = async(body) => {
  try{
  const {data} = await fetchHandler("/CreateStagingOrderId", {}, {}, convertToFormData(body), API_CONSTANTS.HTT_POST);
  console.log(data)
  return data
  }
  catch(error){
    throw error
  }
}

export const createPremiumStagingOrderForCompany = async(body) => {
  try{
    const {data} = await fetchHandler("/CreateCompanyE9StagingTransactionOrder", {}, {}, convertToFormData(body), API_CONSTANTS.HTT_POST);
    return data
  }
  catch(error){
    throw error
  }
}

export const getMyPremiumDetails = async() => {
  try{
    const {data} = await fetchHandler("/GetMyPremiumDetails"); 
    console.log(data)
    return data
  }
  catch(error){
    throw error;
  }
}


export const getPremiumRedeemCountById = async(serviceTypeId) => {
  if(!serviceTypeId){
    throw new Error("This function requires one parameter.")
  }
  try{
    const {data} = await fetchHandler("/GetPremiumRedeemCountByServiceTypeId", {ServiceTypeId : parseInt(serviceTypeId)});
    return data.Data.RedeemDetails[0];
  }
  catch(error){
    throw error;
  }
}

export const postE9PremiumRedeem = async(body) =>{
  try {
    const data = await fetchHandler(
      API_CONSTANTS.POST_E9_PREMIUM_REDEEM,
      {},
      {},
      convertToFormData(body),
      API_CONSTANTS.HTT_POST,
      {isAuthRequired:true}
    );
      return data ;
  } catch (error) {
      throw error;

  }
}

export const getMyPremium = async () =>{
  try{
    const {data} =await fetchHandler(
      "/GetMyPremiumDetails",
      {},
      {},
      {},
      API_CONSTANTS.HTTP_GET,
      {isAuthRequired: true}
    ) ;
    if(data?.Status === 200){
      return data?.Data
    }
  }catch (error) {

  }
}


export const renewPremiumService = async(body) => {
  try {
    const { data } = await fetchHandler(
      "/SaveNewPremiumUser",
      {},
      {},
      convertToFormData(body),
      API_CONSTANTS.HTT_POST
    );

    if (data.Status === 201) {
      return data.Data;
    } else {
      throw new Error(data.Data.Message.FailMessage);
    }
  } catch (error) {
    throw error;
  }

}