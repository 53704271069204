import { Divider, Flex } from '@chakra-ui/react'
import React from 'react'
import { subString } from '../../helpers/dataConversion/substring';

const TabSentCard = ({
    assetId = "",
    equipmentTypeValue = "",
    equipmentTypeId = "",
    oemName = "",
    modelName = "",
    isVerified = "",
    equipmentImage = "",
    city = "",
    state = "",
    receiverContactNumber = "",
    onRevokeRequest = () => { },
    requestType = "",
    repliedValue = "",
    isDealership = "",
    isMaterial="",
    productName="",
    requestSentOn,
    onNavigateClick = () => {},

}) => {
    const handleRevokeRequestClick = (event) => {
        event.stopPropagation();
        onRevokeRequest();
    }
    return (
        <div className='my-leads-tab-card-container' onClick={onNavigateClick}>
            <div className='my-leads-tab-card-status'>
                <p>{assetId}</p>
                <div
                    className="my-leads-status-badge"
                    style={{ background: isVerified ? "#319F0060" : "#D6000060" }}
                >
                    <p className="status-verification-badge-text">
                        {isVerified ? "Verified" : "Not Verified"}
                    </p>
                </div>
            </div>

            <div className='my-leads-tab-card-body'>
                <div className='my-leads-tab-card-img-container'>
                    {isDealership === 'true' || isMaterial==="true" ? 
                    <img src={equipmentImage ? `${process.env.REACT_APP_CDN_PVT_URL}${equipmentImage}` : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/DelarshipDefaultImage.png`} alt='tab-img' style={{ height: "120px", width: "100%" }} /> : 
                    <img src={equipmentImage ? `${process.env.REACT_APP_CDN_PVT_URL}AssetsImage/${equipmentImage}` : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/DelarshipDefaultImage.png`} alt='tab-img' style={{ height: "120px", width: "100%" }} />
                    }
                    </div>
                <div className='my-leads-tab-card-tab-details'>
                    <h1 className='my-leads-header'>{`${isMaterial==='true'? subString(25,productName)+" •":""}${equipmentTypeValue ? equipmentTypeValue :"NA"} •${oemName ? oemName : "NA"} •${modelName ? modelName : "NA"}`}</h1>
                    <h1 className='my-leads-location'>Location: {city},{state}</h1>
                    {isMaterial && <h1 className='my-leads-location'>Request received on : {new Date(requestSentOn).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '-')}</h1>}
                    <h1 className='my-leads-price'></h1>
                    <div className='my-leads-tab-card-contact'>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <img src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/phone.svg`} alt='contact-details' />
                            <p className='my-leads-contact'>{receiverContactNumber}</p>
                        </div>
                        <div className='my-leads-action-btn' style={{
                            border: requestType === 'Request Accepted' ? "0.817px solid #319F00" :requestType === 'Request Rejected' ? '0.817px solid #D6000060' : "0.817px solid #F19020",
                            visibility : isDealership  ? 'hidden' : ''
                        }}>
                            <button
                            disabled = {requestType === "Request Accepted" || requestType === "Request Rejected"}
                                style={{
                                    color: requestType === 'Request Accepted' ? '#319F0060' : requestType === 'Request Sent' ? '#F19020' : '#D6000060' ,
                                }}
                                className="my-leads-button" onClick={handleRevokeRequestClick}>{requestType}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: !repliedValue ? "none" : ""}}>
                <Divider borderColor="#011f6b" borderWidth="1px" />
                <Flex alignItems="center" w="100%" justifyContent="space-between" h="100%">
                    <p>They Replied</p>
                    <p>{repliedValue}</p>
                </Flex>

            </div>
        </div>
    )
}

export default TabSentCard