import { API_CONSTANTS } from "../../../constants/apiConstants";
import { convertObjectsToArrays } from "../../helpers/dataConversion/convertObjectsToArrays";
import { convertToFormData } from "../../helpers/dataConversion/convertToFormData";
import { convertToIdValuePairs } from "../../helpers/dataConversion/convertToIdValuePairs";
import { fetchHandler } from "../../../index";
import { primaryKeyDecryption } from "../../helpers/dataConversion/primaryKeyEncryption";

export const getEquipmentTypes = async () => {
  try {
    const { data } = await fetchHandler(API_CONSTANTS.GET_ALL_EQUIPMENTS_TYPE);
    if (data.Status === 200 && Object.keys(data.Data).length > 0) {
      const requiredData = data.Data.EquipmentsList.map(
        ({ EquipmentTypeId, EquipmentTypeValue }) => ({
          id: EquipmentTypeId,
          value: EquipmentTypeValue,
        })
      );
      return requiredData;
    }
    return null;
  } catch (error) {
    throw error;
  }
};

export const getEquipmentMakesById = async (id) => {
  try {
    if (!id) {
      throw new Error("Equipment id cannot be undefined");
    }
    const { data } = await fetchHandler(`${API_CONSTANTS.GET_MAKE}${id}`);

    if (data.Status && Object.keys(data.Data).length > 0) {
      const requiredData = data.Data.OEMList.map(({ OEMId, OEMName }) => ({
        id: OEMId,
        value: OEMName,
      }));
      return requiredData;
    }
    return null;
  } catch (error) {
    throw error;
  }
};

export const getEquipmentModelById = async (id) => {
  try {
    if (!id) {
      throw new Error("Equipment id cannot be undefined");
    }
    const {data} = await fetchHandler(
      `${API_CONSTANTS.GET_EQUIPMENT_MODEL}/${id}`
    );
    if (data.Status && Object.keys(data.Data).length > 0) {
      const requiredData = data.Data.ModelList.map(
        ({ ModelId, ModelName }) => ({ id: ModelId, value: ModelName })
      );
      return requiredData;
    } else {
      return null;
    }
  } catch (error) {
    const apiError = new Error();
    apiError.message = error.response.data.Message.FailMessage;
    apiError.code = error.response.status;
    throw apiError;
  }
};

export const getOwnershipTypes = async () => {
  try {
    const { data } = await fetchHandler(
      API_CONSTANTS.GET_ASSET_OWNERSHIP_TYPE_LIST
    );
    if (data.Status === 200 || Object.keys(data.Data).lrngth > 0) {
      const requiredData = data.Data.GetAssetOwnershipTypeList.map(
        ({ AssetOwnershipTypeId, AssetOwnershipTypeValue }) =>
          convertToIdValuePairs(AssetOwnershipTypeId, AssetOwnershipTypeValue)
      );
      return requiredData;
    }
    return null;
  } catch (error) {
    throw error;
  }
};

export const getEquipmentAges = async () => {
  try {
    const { data } = await fetchHandler(
      API_CONSTANTS.GET_ALL_EQUIPMENT_AGE_LIST
    );
    if (data.Status === 200 && Object.keys(data.Data).length > 0) {
      const requiredData = data.Data.EquipmentAgeList.map(
        ({ EquipmentAgeId, EquipmentAgeValue }) =>
          convertToIdValuePairs(EquipmentAgeId, EquipmentAgeValue)
      );
      return requiredData;
    } else {
      throw new Error(data.Message.FailMessage || "Failed to Fetch Data");
    }
  } catch (error) {
    throw error;
  }
};

export const createNewAsset = async (body) => {
  try {
    const { data } = await fetchHandler(
      API_CONSTANTS.CREATE_NEW_ASSET_DETAILS,
      {},
      {},
      convertToFormData(body),
      API_CONSTANTS.HTT_POST
    );
    if (data.Status === 201 && Object.keys(data.Data).length > 0) {
      return { id: data.Data.LastId, message: data.Message.SuccessMessage };
    } else {
      const error = new Error();
      error.message = data.Message.FailMessage
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

export const getEquipmentMappingDetails = async (id) => {
  try {
    const { data } = await fetchHandler(
      `${API_CONSTANTS.GET_EQUIPMENT_MAPPING_DETAILS}/${id}`
    );
    if (data.Status === 200 && Object.keys(data.Data).length > 0) {
      const mappingSectionArray = convertObjectsToArrays(
        data.Data.Equipment.EquipmentSections
      );
      const convertSectionObject = mappingSectionArray.map(
        ({
          EquipmentProperties,
          EquipmentSectionId,
          EquipmentSectionName,
        }) => ({
          id: EquipmentSectionId,
          name: EquipmentSectionName,
          fields: convertObjectsToArrays(EquipmentProperties),
        })
      );

      const requiredData = convertSectionObject.reduce(
        (accumulator, currentValue) => {
          const convertedFields = currentValue.fields.map((field) => ({
            id: field.EquipmentPropertyId,
            type: field.EquipmentPropertyInputType,
            label: field.EquipmentPropertyValue,
            metrics:
              field.MetricTypes && field.MetricTypes.length > 0
                ? field.MetricTypes.map(({ MetricTypeId, MetricTypeValue }) =>
                    convertToIdValuePairs(MetricTypeId, MetricTypeValue)
                  )
                : null,
          }));
          accumulator.push({ ...currentValue, fields: convertedFields });
          return accumulator;
        },
        []
      );

      return requiredData;
    } else {
      throw new Error(data.Message.FailMessage);
    }
  } catch (error) {
    throw error;
  }
};

export const postEquipmentPurchasingDetails = async (body) => {
  try {
    if (!body) {
      throw new Error("Body is required and cannot be undefined");
    }

    const { data } = await fetchHandler(
      API_CONSTANTS.CREATE_ASSET_PURCHASING_DETAILS,
      {},
      {},
      convertToFormData(body),
      API_CONSTANTS.HTT_POST
    );
    if (data.Status === 201 && Object.keys(data.Data).length > 0) {
      return { id: data.Data.LastId, message: data.Message.SuccessMessage };
    } else {
      const newError = new Error();
      newError.message = data.Message.FailMessage;
      throw newError;
    }
  } catch (error) {
    throw error;
  }
};

export const postOtherEquipmentDetails = async (body) => {
  try {
    if (!body) {
      throw new Error("Body is required and cannot be undefined");
    }
    const { data } = await fetchHandler(
      API_CONSTANTS.CREATE_ASSET_OTHER_PROPERTY_DETAILS,
      {},
      {},
      convertToFormData(body),
      API_CONSTANTS.HTT_POST
    );
    if (data.Status === 201 && Object.keys(data.Data).length > 0) {
      return { id: data.Data.LastId, message: data.Message.SuccessMessage };
    } else {
      const newError = new Error();
      newError.message = data.Message.FailMessage;
      throw newError;
    }
  } catch (error) {
    throw error;
  }
};

export const updateEquipmentPurchasingDetails = async (id, body) => {
  try {
    if (!body && !id) {
      throw new Error("Body and assetId are required and cannot be undefined");
    }
    const { data } = await fetchHandler(
      `/UpdateNewAssetPurchasingDetails/${id}`,
      {},
      {},
      JSON.stringify({ NewAssetPurchaseData: body }),
      API_CONSTANTS.HTTP_PUT
    );
    if (data.Status === 204) {
      return { message: data.Message.SuccessMessage, data: data.Data };
    } else {
      const newError = new Error();
      newError.message = data.Message.FailMessage;
      throw newError;
    }
  } catch (error) {
    throw error;
  }
};

export const getDocumentTypes = async () => {
  try {
    const { data } = await fetchHandler("/GetAssetDocumentType");
    if (data.Status === 200 && Object.keys(data.Data > 0)) {
      const requiredData = data.Data.AssetDocumentTypeList.map(
        ({ AssetDocumentTypeId, AssetDocumentTypeValue }) =>
          convertToIdValuePairs(AssetDocumentTypeId, AssetDocumentTypeValue)
      );
      return requiredData;
    } else {
      const newError = new Error();
      newError.message = data.Message.FailMessage;
      throw newError;
    }
  } catch (error) {
    throw error;
  }
};

export const getRentalShiftTypes = async () => {
  try {
    const { data } = await fetchHandler("/GetRentalShiftType");
    if (data.Status === 200 && Object.keys(data.Data > 0)) {
      const requiredData = data.Data.RentalShiftTypes.map(
        ({ RentalShiftId, RentalShiftValue }) =>
          convertToIdValuePairs(RentalShiftId, RentalShiftValue)
      );
      return requiredData;
    } else {
      const newError = new Error();
      newError.message = data.Message.FailMessage;
      throw newError;
    }
  } catch (error) {
    throw error;
  }
};
export const updateEquipmentDetails = async (id, body) => {
  try {
    const { data } = await fetchHandler(
      `/UpdateNewAssetDetails/${id}`,
      {},
      {},
      { NewAssetsData: body },
      API_CONSTANTS.HTTP_PUT
    );
    if (data.Status === 204) {
      return { message: data.Message.SuccessMessage };
    } else {
      const error = new Error();
      error.message = data.Message.FailMessage;
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

export const updateOtherEquipmentDetails = async (id, body) => {
  try {
    const { data } = await fetchHandler(
      `/UpdateAssetOtherPropertiesDetails/${id}`,
      {},
      {},
      body,
      API_CONSTANTS.HTTP_PUT
    );
    if (data.Status === 204) {
      return { message: data.Message.SuccessMessage };
    } else {
      const error = new Error();
      error.message = data.Message.FailMessage;
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

export const getEquipmentTypeList = async () => {
  try {
    const {data}= await fetchHandler(API_CONSTANTS.GET_EQUIPMENT_MAKE_LIST);
    return data;

  } catch (error) {
    throw error.response
  }
}

export const getEquipmentTypeFilterList = async () => {
  try {
    const {data}= await fetchHandler(API_CONSTANTS.GET_EQUIPMENT_TYPE_FILTER_LIST);
    return data;

  } catch (error) {
    throw error.response
  }
}

export const getRentalDuration = async () => {
  try {
    const {data}= await fetchHandler("/GetRentalDuration");
    return data;

  } catch (error) {
    throw error
  }
}

export const getMaintenanceCategory = async () => {
  try {
    const {data}= await fetchHandler(API_CONSTANTS.GET_MAINTENANCE_CATEGORY);
    return data;

  } catch (error) {
    throw error
  }
}

export const getMaintenanceTypeListById = async (id) => {
  try {
    const {data}= await fetchHandler(`/GetMaintenanceTypeList?MaintenanceCategoryId=${id}`);
    return data;

  } catch (error) {
    throw error
  }
}

export const getRepairType = async () => {
  try {
    const {data}= await fetchHandler(API_CONSTANTS.GET_REPAIR_TYPE);
    return data;

  } catch (error) {
    throw error
  }
}

export const getEquipmentNickNameList = async () => {
  try {
    const {data}= await fetchHandler(API_CONSTANTS.GET_EQUIPMENT_NICK_NAME_LIST);
    return data;

  } catch (error) {
    throw error
  }
}

export const getEquipmentAgeList = async () => {
  try {
    const {data}= await fetchHandler(API_CONSTANTS.GET_ALL_EQUIPMENT_AGE_LIST);
    return data;

  } catch (error) {
    throw error
  }
}

export const getE9MarketPlaceRentalEquipmentList = async (cardCount,offset) => {
  try {
    const {data}= await fetchHandler(`/GetE9MarketPlaceRentalEquipmentList/${cardCount}/${offset}`);
    return data;

  } catch (error) {
    throw error
  }
}

export const getE9MarketPlaceUsedEquipmentList = async (cardCount,offset) => {
  try {
    const {data}= await fetchHandler(`/GetE9MarketPlaceUsedEquipmentList/${cardCount}/${offset}`);
    return data;

  } catch (error) {
    throw error
  }
}

export const getE9MarketPlaceDetailsById = async (assetId) => {
  try {
    const {data}= await fetchHandler(`/GetE9MarketPlaceDetailsById/${primaryKeyDecryption(assetId)}`);
    return data;

  } catch (error) {
    throw error
  }
}

export const getNicknameEquipmentList = async (payload) => {
  try {
    const { data } = await fetchHandler(`/GetUserNewAssetList/12/1`);
    if(data?.Status === 200){
      return data?.Data
    }
  } catch (error) {
    
  }
}