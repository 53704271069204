import {
  Button,
  Box,
  Flex,
  FormControl,
  Select,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import { ImageUpload } from "../../../../components/ImageUpload/ImageUpload";
import { MultiAdDivider } from "../../../../layout/multiAdLayout/MultiAdLayout";
import { FormikInput } from "../../../../components/Input/FormikInput";
import { convertToFormData } from "../../../../helpers/dataConversion/convertToFormData";
import { increasePageIndex, setHasTaxVerificationDocument, setTaxVerificationNumber } from "../../slice/docFlowSlice";
import { getVerificationDocumentType, uploadUserIdentityDocument } from "../../../../services/documentFlow/documentFlow";
import { getDefaultAppLanguage } from "../../../global/mtData/helpers/localStorage";
import { V5MultiAdLayout } from "../../../../layout/multiAdLayout/V5MultiAdLayout";
import { V5Scroll } from "../../../../components/V5Scroll/V5Scroll";
import { useSearchParams } from "react-router-dom";
import { NAVIGATION_CONSTANTS } from "../../../../../constants/naigationContants";

export const UploadVerificationDocument = () => {
  const [verificationDocumentTypes, setverificationDocumentTypes] =
    useState(null);
  const toast = useToast();
  const documentFlow = useSelector((state) => state.documentFlow)
  const dispatch = useDispatch();
  const defaultLanguage = getDefaultAppLanguage();
  const [urlParam, setUrlParam] = useSearchParams();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const id = sessionStorage.getItem("DEFERRED_MARKETPLACE");
    const urlAssetId = urlParam.get(NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID)
    if(id && !urlAssetId){
     setUrlParam(`${NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID}=${id}`)
    }
   }, [])

  const getAllverificationDocumentTypes = async () => {
    const data= await getVerificationDocumentType();
    setverificationDocumentTypes(data.Data);
  };

  const postVerificationDetails = async (body) => {
    try {
      const data= await uploadUserIdentityDocument(convertToFormData(body));
      

      if (data.Status === 201) {
        toast({
          status: "success",
          title: `${data.Message.SuccessMessage}`,
          isClosable: true,
          position: "top-right",
        });
        dispatch(setHasTaxVerificationDocument(0))
        dispatch(setTaxVerificationNumber(null))
        dispatch(increasePageIndex());
      
      } else {
        return toast({
          status: "error",
          title: `${data.Message.FailMessage}`,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    (async () => {
      await getAllverificationDocumentTypes();
    })();
  }, []);

  return (
    <V5MultiAdLayout
      heading={defaultLanguage?.Upload_Verification_Documents}
      subHeading={defaultLanguage?.Please_give_your_document_details_for_verification}
      stepper={true}
    >
      <V5Scroll>
      <Box width="80%" margin="0 auto">
        <Formik
          initialValues={{
            documentType: documentFlow.hasTaxVerificationDocument ? "GST Document" : "",
            documentImage: null,
            documentNumber: documentFlow.taxVerificationNumber ? `${documentFlow.taxVerificationNumber}` : "",
          }}
          validationSchema={Yup.object().shape({
            documentType: Yup.string().required("Document Type is required."),
            documentNumber: Yup.string().required(
              "Document Number is required."
            ),
            documentImage: Yup.mixed()
            .required("Required")
            .test(
              "fileSize",
              "File should not be greater than 5 MB",
              (value) => !value || value.size <= 1000000 * 5
            )
            .test(
              "fileFormat",
              "Unsupported Format",
              (value) =>
                value && ["image/jpg", "image/jpeg", "image/png","application/pdf"].includes(value.type)
            ),
          })}
          onSubmit={async (values) => {
            const body = {
              VerificationDocumentName: values.documentType,
              VerificationDocumentNumber: values.documentNumber,
              DocumentFileReference: values.documentImage,
            };
            setLoading(true)
            await postVerificationDetails(body);
            setLoading(false)
          }}
        >
          {({ handleSubmit, errors, touched, values, setFieldValue }) => (
            <form onSubmit={handleSubmit} id="verification-form">
              <Flex
                columnGap="2em"
                rowGap="1em"
                wrap="wrap"
                overflowY="scroll"
                // justifyContent={"space-between"}
                // maxHeight="250px"
                marginBottom="1em"
              >
                <FormControl isRequired={true} maxW="374px">
                  <FormLabel>Document Type</FormLabel>
                  <Select
                    name="documentType"
                    h="52px"
                    border="1px solid #2c2c2c14"
                    focusBorderColor="#2c2c2c"
                    value={values.documentType}
                    isDisabled={documentFlow.hasTaxVerificationDocument}
                    // defaultValue={documentFlow.hasTaxVerificationDocument ? "GST Document" : ""}
                    backgroundColor={
                      values.documentType ? "#ffff" : "#2c2c2c05"
                    }
                    onChange={(e) =>
                      setFieldValue("documentType", e.target.value)
                    }
                  >
                    <option value="">Select an option</option>
                    {verificationDocumentTypes &&
                    verificationDocumentTypes.length > 0 ? (
                      verificationDocumentTypes.map(
                        (verificationDocumentType) => (
                          <option
                            key={uuid()}
                            value={
                              verificationDocumentType.VerificationDocumentName
                            }
                          >
                            {verificationDocumentType.VerificationDocumentName}
                          </option>
                        )
                      )
                    ) : (
                      <></>
                    )}
                  </Select>
                </FormControl>

                <FormikInput
                  name="documentNumber"
                  placeholder="Enter Document Number Here ..."
                  label="Document Number"
                  maxLength={25}
                  isRequired={true}
                  isDisabled={documentFlow.taxVerificationNumber}
                  
                />
                <ImageUpload
                  name="documentImage"
                  label="Document Upload"
                  isRequired={true}
                  helperText={defaultLanguage?.Please_give_your_document_details_for_verification}
                  formikProps={{ setFieldValue, errors, touched }}
                />
              </Flex>
            </form>
          )}
        </Formik>
      </Box>
      </V5Scroll>

      <Box>
        <Flex
          justifyContent="center"
          alignItems="center"
          bgColor="#fff"
          h="78px"
          style={{ boxShadow: "0px -10px 15px #2c2c2c0f" }}
        >
          <Button
            type="submit"
            variant="gradient"
            fontWeight="semibold"
            width="400px"
            margin="0 auto"
            maxW="400px"
            form="verification-form"
            fontSize="22px"
            isLoading={loading}
          >
            {defaultLanguage?.Next}
          </Button>
        </Flex>
      </Box>
    </V5MultiAdLayout>
  );
};
